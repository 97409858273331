import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
//import {Link, useNavigate, Router} from '@gatsbyjs/reach-router';
import { useLocation, Link, useNavigate, Routes, Route, Outlet } from 'react-router-dom';
import LoaderAnimation from './LoaderAnimation';
import StoreStructureDepartments2 from './StoreStructureDepartments2';
import StoreStructureDepartmentCategories2 from './StoreStructureDepartmentCategories2';
import StoreStructureProductCategories2 from './StoreStructureProductCategories2';
import StoreStructureProductSubCategories2 from './StoreStructureProductSubCategories2';
import StoreStructureProducts2 from './StoreStructureProducts2';
import StoreStructureSearchFilters2 from './StoreStructureSearchFilters2';
import StoreStructureSearchTerms2 from './StoreStructureSearchTerms2';
import loadNavLink from './loadNavLinkHelper';
import Footer from './Footer';
import './StoreStructure.css';


interface Props {
    //path:string;
    //children:React.ReactElement[];
}

const StoreStructure: React.FunctionComponent<Props> = (props:Props) => {
    //const {children} = props;
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    //console.log('pathname:', pathname);
    const [mainHeaderName, setMainHeaderName] = useState('');

    const ssNavItemListRefs = useRef<HTMLUListElement[]>([]);
    const ssNavItemListContainerRefs = useRef<HTMLDivElement[]>([]);
    const ssMoreOverviewBtnContainerRefs = useRef<HTMLDivElement[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const [pagePosition, setPagePosition] = useState<{pos:number}>({pos:getPreviousScrollPosition(sessionStorage.getItem('store-structure-scroll-position'))});

    const [initialLoad, setInitialLoad] = useState(true);

    const [showFooter, setShowFooter] = useState(false);

    useEffect(()=>{

        if(initialLoad){
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
        }


        //console.log('pagePosition:', pagePosition, sessionStorage.getItem('store-structure-scroll-position'));
        //console.log('comp');
        if((pathname === '/admin/store-structure' || pathname === '/admin/store-structure/')
            && (pagePosition.pos !== getPreviousScrollPosition(sessionStorage.getItem('store-structure-scroll-position')))){
            const psp = getPreviousScrollPosition(sessionStorage.getItem('store-structure-scroll-position'));
            //console.log('psp:', psp);
            setPagePosition({pos:psp});

            setShowFooter(true);

        } else {
            if((pathname === '/admin/store-structure' || pathname === '/admin/store-structure/')){
                //console.log('comp:', pagePosition);
                setPagePosition({pos:pagePosition.pos});

                setShowFooter(true);
            }
        }
        
        setMainHeaderName(getMainHeaderName(pathname));
        setIsLoading(false);

        setInitialLoad(false);

        
    },[pathname]);


    useEffect(()=>{
            window.scrollTo({top:pagePosition.pos, left:0, behavior:'smooth'});
        
    },[pagePosition]);
    

    function getMainHeaderName(pathname:string):string {
        //console.log('mhn:', pathname);
        if((pathname === '/admin/store-structure' || pathname === '/admin/store-structure/')){
            return 'Store Structure';

        } else {
            return 'other';
        }
    }

    useEffect(()=>{
        window.addEventListener('resize', ssResizeHandler);
        return cleanupSSResizeHandler;
    },[]);

    function cleanupSSResizeHandler():void {
        window.addEventListener('resize', ssResizeHandler);
        sessionStorage.removeItem('store-structure-scroll-position');
    }

    function ssResizeHandler(e:Event):void {
        ssNavItemListContainerRefs.current.forEach((anilcr, i)=>{        
            if(ssMoreOverviewBtnContainerRefs.current[i].textContent === 'more overview'){
                anilcr.style.height = '';

            } else {
                anilcr.style.height = `${ssNavItemListRefs.current[i].offsetHeight + 48}px`;
            }
            
        });

    }

    function getPreviousScrollPosition(prevScrollPosition:string|null):number {
        if(prevScrollPosition === null){
            return 0;

        } else {
            const psp = parseInt(prevScrollPosition, 10);
            return psp;
        }
    }

    function getAdminMoreOverviewBtnContainerRef(el:HTMLDivElement|null):void {   
        if(el !== null && ssMoreOverviewBtnContainerRefs.current.length === 0){
            ssMoreOverviewBtnContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = ssMoreOverviewBtnContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                ssMoreOverviewBtnContainerRefs.current.push(el);
            }

        }

        //console.log('amobcrs:', ssMoreOverviewBtnContainerRefs.current);

    }


    function getAdminNavItemListContainerRef(el:HTMLDivElement|null):void {   
        if(el !== null && ssNavItemListContainerRefs.current.length === 0){
            ssNavItemListContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = ssNavItemListContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                ssNavItemListContainerRefs.current.push(el);
            }

        }

        //console.log('anilcrs:', ssNavItemListContainerRefs.current);

        ssNavItemListContainerRefs.current.forEach((anilcr, i)=>{
            if(anilcr.offsetHeight < ssNavItemListRefs.current[i].offsetHeight){
                //add more overview btn
                ssMoreOverviewBtnContainerRefs.current[i].classList.remove('store-structure-more-overview-btn-container-hide');
                ssMoreOverviewBtnContainerRefs.current[i].classList.add('store-structure-more-overview-btn-container-show');
            }
        });


    }

    function getAdminNavItemListRef(el:HTMLUListElement|null):void {   
        if(el !== null && ssNavItemListRefs.current.length === 0){
            ssNavItemListRefs.current.push(el);

        } else if(el !== null) {
            const found = ssNavItemListRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                ssNavItemListRefs.current.push(el);
            }

        }

        //console.log('anilrs:', ssNavItemListRefs.current);

           
    }


    function moreOverviewBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        const btn = e.currentTarget as HTMLButtonElement;
        const btnText = btn.textContent;

        if(btnText === 'more overview'){
            const parent = btn.closest('.ssni') as HTMLLIElement;

            //console.log('pt:', parent.offsetTop);
            
            const anil = parent.querySelector('.store-structure-nav-item-list') as HTMLUListElement;

            const lc = parent.querySelector('.store-structure-nav-item-list-container') as HTMLDivElement;
            lc.style.transition = 'height 1s ease-in-out';
            lc.style.height = `${anil.getBoundingClientRect().height + 48}px`;
            btn.textContent = 'less overview';

        } else {
            const parent = btn.closest('.ssni') as HTMLLIElement;
            
            const anil = parent.querySelector('.store-structure-nav-item-list') as HTMLUListElement;

            const lc = parent.querySelector('.store-structure-nav-item-list-container') as HTMLDivElement;
            lc.style.transition = 'height 1s ease-in-out .3s';
            lc.style.height = '';
            btn.textContent = 'more overview';
            
            if(parent.getBoundingClientRect().top < 0){
                parent.scrollIntoView();
            }
            
        }
        
    }

    function loadStoreStructureArea(e:React.MouseEvent, storeStructureAreaName:string):void {
        e.preventDefault();
        //console.log('posy14:', window.scrollY);

        window.sessionStorage.setItem('store-structure-scroll-position', `${window.scrollY}`);

        
        let root = document.querySelector(':root');
        root!.setAttribute('style', 'scroll-behavior: auto');
        root!.scrollTo(0,0);
        setMainHeaderName(`${storeStructureAreaName}`);
        setIsLoading(true);

        setShowFooter(false);
        
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            //setIsLoading(false);
        },100);
        
                

        switch(storeStructureAreaName){
            case 'departments':
                navigate('departments');
                break;
            case 'departmentCategories':
                navigate('department-categories');
                break;
            case 'productCategories':
                navigate('product-categories');
                break;
            case 'productSubCategories':
                navigate('product-sub-categories');
                break;
            case 'products':
                navigate('products');
                break;
            case 'searchFilters':
                setMainHeaderName(`${storeStructureAreaName}`);
                navigate('search-filters');
                break;
            case 'searchTerms':
                setMainHeaderName(`${storeStructureAreaName}`);
                navigate('search-terms');
                break;
            default:
                throw new Error('No store structure area found.');
        }

    }

    /*previous child route setup
                        <Routes>
                        <Route path="departments" element={<StoreStructureDepartments2/>}/>
                        <Route path="department-categories" element={<StoreStructureDepartmentCategories2/>}/>
                        <Route path="product-categories" element={<StoreStructureProductCategories2/>}/>
                        <Route path="product-sub-categories" element={<StoreStructureProductSubCategories2/>}/>
                        <Route path="products" element={<StoreStructureProducts2/>}/>
                        <Route path="search-filters" element={<StoreStructureSearchFilters2/>}/>
                        <Route path="search-terms" element={<StoreStructureSearchTerms2/>}/>
                    </Routes>
                    */

    
    return <div className="store-structure-wrapper">

                {
                    mainHeaderName === 'Store Structure'
                    ?
                    <div className="store-structure-layout-wrapper">
    
                        <ul className="store-details-breadcrum-container">
                            <li className="ssi">
                                <Link to="/admin" className="store-details-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="ssi store-details-breadcrum-link-store-name">Store Structure</li>
                        </ul>

                        <h1 className="store-structure-heading">{mainHeaderName}</h1>

                        <div className="store-structure-nav-container">
                            <ul className="store-structure-nav">
                                <li className="ssni">
                                    <h2 className="store-structure-nav-item-heading">Departments</h2>
                                    <div className="store-structure-access-btn-container">
                                        <button type="button" className="store-structure-access-btn">
                                            <Link to="departments" className="store-structure-nav-item-link" onClick={(e:React.MouseEvent)=>loadStoreStructureArea(e, 'departments')}>Access</Link>
                                        </button>
                                    </div>
                                
                                    <div ref={getAdminNavItemListContainerRef} className="store-structure-nav-item-list-container">
                                        <div ref={getAdminMoreOverviewBtnContainerRef} className="store-structure-more-overview-btn-container-hide">
                                            <button type="button" className="store-structure-more-overview-btn"
                                                onClick={moreOverviewBtnHandler}>more overview</button>
                                        </div>
                                        <ul ref={getAdminNavItemListRef} className="store-structure-nav-item-list">
                                            <li>
                                                <p>Add, edit and delete departments.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit department name.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit department image.</p>
                                            </li>
                                            <li>
                                                <p>Show department category shortcut.</p>
                                            </li>
                                            <li>
                                                <p>Department name is automatically added to the main navbar.</p>
                                            </li>
                                            <li>
                                                <p>Search for departments by name.</p>
                                            </li>
                                            <li>
                                                <p>Sort departments by date added.</p>
                                            </li>
                                            <li>
                                                <p>View all products in tabular form with pagination.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to highlight missed form fields.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate names from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate images from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Help message when a search was unsuccessful.</p>
                                            </li> 
                                            <li>
                                                <p>Help message when no departments have been added.</p>
                                            </li> 
                                        </ul>
                                    </div>
                                </li>
                                <li className="ssni">
                                    <h2 className="store-structure-nav-item-heading">Department Categories</h2>
                                    <div className="store-structure-access-btn-container">
                                        <button type="button" className="store-structure-access-btn">
                                            <Link to="department-categories" className="store-structure-nav-item-link" onClick={(e:React.MouseEvent)=>loadStoreStructureArea(e, 'departmentCategories')}>Access</Link>
                                        </button>
                                    </div>
                                
                                    <div ref={getAdminNavItemListContainerRef} className="store-structure-nav-item-list-container">
                                        <div ref={getAdminMoreOverviewBtnContainerRef} className="store-structure-more-overview-btn-container-hide">
                                            <button type="button" className="store-structure-more-overview-btn"
                                                onClick={moreOverviewBtnHandler}>more overview</button>
                                        </div>
                                        <ul ref={getAdminNavItemListRef} className="store-structure-nav-item-list">
                                            <li>
                                                <p>Add, edit and delete department categories.</p>
                                            </li>
                                            <li>
                                                <p>Select department to assign to the department category.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit department category name.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit department category image.</p>
                                            </li>
                                            <li>
                                                <p>Show product category shortcut.</p>
                                            </li>
                                            <li>
                                                <p>Department category name is automatically added to the main navbar.</p>
                                            </li>
                                            <li>
                                                <p>Search for department category by name or department.</p>
                                            </li>
                                            <li>
                                                <p>Sort department categories by date added.</p>
                                            </li>
                                            <li>
                                                <p>View all products in tabular form with pagination.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to highlight missed form fields.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate names from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate images from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Help message when a search was unsuccessful.</p>
                                            </li> 
                                            <li>
                                                <p>Help message when no department categories have been added.</p>
                                            </li> 
                                        </ul>
                                    </div>
                                </li>
                                <li className="ssni">
                                    <h2 className="store-structure-nav-item-heading">Product Categories</h2>
                                    <div className="store-structure-access-btn-container">
                                        <button type="button" className="store-structure-access-btn">
                                            <Link to="product-categories" className="store-structure-nav-item-link" onClick={(e:React.MouseEvent)=>loadStoreStructureArea(e, 'productCategories')}>Access</Link>
                                        </button>
                                    </div>
                                
                                    <div ref={getAdminNavItemListContainerRef} className="store-structure-nav-item-list-container">
                                        <div ref={getAdminMoreOverviewBtnContainerRef} className="store-structure-more-overview-btn-container-hide">
                                            <button type="button" className="store-structure-more-overview-btn"
                                                onClick={moreOverviewBtnHandler}>more overview</button>
                                        </div>
                                        <ul ref={getAdminNavItemListRef} className="store-structure-nav-item-list">
                                            <li>
                                                <p>Add, edit and delete product categories.</p>
                                            </li>
                                            <li>
                                                <p>Select department to assign to the product category.</p>
                                            </li>
                                            <li>
                                                <p>Select department category to assign to the product category.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product category name.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product category image.</p>
                                            </li>
                                            <li>
                                                <p>Show product sub category shortcut.</p>
                                            </li>
                                            <li>
                                                <p>Show related product category shortcut.</p>
                                            </li>
                                            <li>
                                                <p>Search for product category by name, department category or department.</p>
                                            </li>
                                            <li>
                                                <p>Sort product categories by date added.</p>
                                            </li>
                                            <li>
                                                <p>View all products in tabular form with pagination.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to highlight missed form fields.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate names from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate images from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Help message when a search was unsuccessful.</p>
                                            </li> 
                                            <li>
                                                <p>Help message when no product categories have been added.</p>
                                            </li> 
                                        </ul>
                                    </div>
                                </li>
                                <li className="ssni">
                                    <h2 className="store-structure-nav-item-heading">Product Subcategories</h2>
                                    <div className="store-structure-access-btn-container">
                                        <button type="button" className="store-structure-access-btn">
                                            <Link to="product-sub-categories" className="store-structure-nav-item-link" onClick={(e:React.MouseEvent)=>loadStoreStructureArea(e, 'productSubCategories')}>Access</Link>
                                        </button>
                                    </div>
                                
                                    <div ref={getAdminNavItemListContainerRef} className="store-structure-nav-item-list-container">
                                        <div ref={getAdminMoreOverviewBtnContainerRef} className="store-structure-more-overview-btn-container-hide">
                                            <button type="button" className="store-structure-more-overview-btn"
                                                onClick={moreOverviewBtnHandler}>more overview</button>
                                        </div>
                                        <ul ref={getAdminNavItemListRef} className="store-structure-nav-item-list">
                                            <li>
                                                <p>Add, edit and delete product sub categories.</p>
                                            </li>
                                            <li>
                                                <p>Select department to assign to the product sub category.</p>
                                            </li>
                                            <li>
                                                <p>Select department category to assign to the product sub category.</p>
                                            </li>
                                            <li>
                                                <p>Select product category to assign to the product sub category.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product sub category name.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product sub category image.</p>
                                            </li>
                                            <li>
                                                <p>Assign product sub category to search filter</p>
                                            </li>
                                            <li>
                                                <p>Assign product sub category to search term</p>
                                            </li>
                                            <li>
                                                <p>Search for product sub category by product category, department category or department.</p>
                                            </li>
                                            <li>
                                                <p>Sort product sub categories by date added.</p>
                                            </li>
                                            <li>
                                                <p>View all products in tabular form with pagination.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to highlight missed form fields.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate names from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate images from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Help message when a search was unsuccessful.</p>
                                            </li> 
                                            <li>
                                                <p>Help message when no product sub categories have been added.</p>
                                            </li> 
                                        </ul>
                                    </div>
                                </li>
                                <li className="ssni">
                                    <h2 className="store-structure-nav-item-heading">Products</h2>
                                    <div className="store-structure-access-btn-container">
                                        <button type="button" className="store-structure-access-btn">
                                            <Link to="products" className="store-structure-nav-item-link" onClick={(e:React.MouseEvent)=>loadStoreStructureArea(e, 'products')}>Access</Link>
                                        </button>
                                    </div>
                                
                                    <div ref={getAdminNavItemListContainerRef} className="store-structure-nav-item-list-container">
                                        <div ref={getAdminMoreOverviewBtnContainerRef} className="store-structure-more-overview-btn-container-hide">
                                            <button type="button" className="store-structure-more-overview-btn"
                                                onClick={moreOverviewBtnHandler}>more overview</button>
                                        </div>
                                        <ul ref={getAdminNavItemListRef} className="store-structure-nav-item-list">
                                            <li>
                                                <p>Add, edit and delete products.</p>
                                            </li>
                                            <li>
                                                <p>Select department to assign to the product.</p>
                                            </li>
                                            <li>
                                                <p>Select department category to assign to the product.</p>
                                            </li>
                                            <li>
                                                <p>Select product category to assign to the product.</p>
                                            </li>
                                            <li>
                                                <p>Select product sub category to assign to the product.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product name.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product description.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product quantity limit.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product price.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit product image.</p>
                                            </li>
                                            <li>
                                                <p>Assign product sub category to search filter</p>
                                            </li>
                                            <li>
                                                <p>Assign product sub category to search term</p>
                                            </li>
                                            <li>
                                                <p>Enter product weight in pounds or individually (each).</p>
                                            </li>
                                            <li>
                                                <p>Enter product unit of measure in pounds or ounces.</p>
                                            </li>
                                            <li>
                                                <p>Add three types of product images (search results, magnification and shopping cart)</p>
                                            </li>
                                            <li>
                                                <p>Add fourt types of product promotions (primo pick, sale, weekly ad, coupon).</p>
                                            </li>
                                            <li>
                                                <p>Add six types of product verifications (gluten free, go local, heart healthy, select ingredients, SNAP EBT eligible and organic).</p>
                                            </li>
                                            <li>
                                                <p>Add four types of product promotions (primo pick, sale, weekly ad, coupon).</p>
                                            </li>
                                            <li>
                                                <p>Add available search filters by group to each product.</p>
                                            </li>
                                            <li>
                                                <p>Remove existing search filters by group from each product.</p>
                                            </li>
                                            <li>
                                                <p>Filter products by price (individual or range).</p>
                                            </li>
                                            <li>
                                                <p>Search for product name, product category, department category or department.</p>
                                            </li>
                                            <li>
                                                <p>View all products in tabular form with pagination.</p>
                                            </li>
                                            <li>
                                                <p>Help message when a search was unsuccessful.</p>
                                            </li> 
                                            <li>
                                                <p>Help message when no products have been added.</p>
                                            </li> 
                                            <li>
                                                <p>Error handling to prevent duplicate names from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate images from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to highlight missed form fields.</p>
                                            </li>
                                            <li>
                                                <p>Sort by date added</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="ssni">
                                    <h2 className="store-structure-nav-item-heading">Search Filters</h2>
                                    <div className="store-structure-access-btn-container">
                                        <button type="button" className="store-structure-access-btn">
                                            <Link to="search-filters" className="store-structure-nav-item-link" onClick={(e:React.MouseEvent)=>loadStoreStructureArea(e, 'searchFilters')}>Access</Link>
                                        </button>
                                    </div>
                                
                                    <div ref={getAdminNavItemListContainerRef} className="store-structure-nav-item-list-container">
                                        <div ref={getAdminMoreOverviewBtnContainerRef} className="store-structure-more-overview-btn-container-hide">
                                            <button type="button" className="store-structure-more-overview-btn"
                                                onClick={moreOverviewBtnHandler}>more overview</button>
                                        </div>
                                        <ul ref={getAdminNavItemListRef} className="store-structure-nav-item-list">
                                            <li>
                                                <p>Add, edit and delete search filters.</p>
                                            </li>
                                            <li>
                                                <p>Select department to assign to the search filter.</p>
                                            </li>
                                            <li>
                                                <p>Select department category to assign to the search filter.</p>
                                            </li>
                                            <li>
                                                <p>Select product category to assign to the search filter.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit search filter name.</p>
                                            </li>
                                            <li>
                                                <p>Show and hide search filter.</p>
                                            </li>
                                            <li>
                                                <p>Add search terms.</p>
                                            </li>
                                            <li>
                                                <p>Search for filters by name, area or type.</p>
                                            </li>
                                            <li>
                                                <p>View all search filters in tabular form with pagination.</p>
                                            </li>
                                            <li>
                                                <p>Help message when a search was unsuccessful.</p>
                                            </li> 
                                            <li>
                                                <p>Help message when no search filters have been added.</p>
                                            </li> 
                                            <li>
                                                <p>Error handling to prevent duplicate names from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate search terms being assigned.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate search filters being assigned to a filter type or filter area.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to highlight missed form fields.</p>
                                            </li>
                                            <li>
                                                <p>Sort by date added</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="ssni">
                                    <h2 className="store-structure-nav-item-heading">Search Terms</h2>
                                    <div className="store-structure-access-btn-container">
                                        <button type="button" className="store-structure-access-btn">
                                            <Link to="search-terms" className="store-structure-nav-item-link" onClick={(e:React.MouseEvent)=>loadStoreStructureArea(e, 'searchTerms')}>Access</Link>
                                        </button>
                                    </div>
                                
                                    <div ref={getAdminNavItemListContainerRef} className="store-structure-nav-item-list-container">
                                        <div ref={getAdminMoreOverviewBtnContainerRef} className="store-structure-more-overview-btn-container-hide">
                                            <button type="button" className="store-structure-more-overview-btn"
                                                onClick={moreOverviewBtnHandler}>more overview</button>
                                        </div>
                                        <ul ref={getAdminNavItemListRef} className="store-structure-nav-item-list">
                                            <li>
                                                <p>Add, edit and delete search terms.</p>
                                            </li>
                                            <li>
                                                <p>Select department to assign to the search term.</p>
                                            </li>
                                            <li>
                                                <p>Select department category to assign to the search term.</p>
                                            </li>
                                            <li>
                                                <p>Select product category to assign to the search term.</p>
                                            </li>
                                            <li>
                                                <p>Select search filter to assign to the search term.</p>
                                            </li>
                                            <li>
                                                <p>Add and edit search term name.</p>
                                            </li>
                                            <li>
                                                <p>Show and hide search term</p>
                                            </li>
                                            <li>
                                                <p>Search for search terms by name, filter name, filter area or filter type.</p>
                                            </li>
                                            <li>
                                                <p>View all search terms in tabular form with pagination.</p>
                                            </li>
                                            <li>
                                                <p>Help message when a search was unsuccessful.</p>
                                            </li> 
                                            <li>
                                                <p>Help message when no search terms have been added.</p>
                                            </li> 
                                            <li>
                                                <p>Error handling to prevent duplicate names from being entered.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to prevent duplicate search terms being assigned to a search filter, filter type or filter area.</p>
                                            </li>
                                            <li>
                                                <p>Error handling to highlight missed form fields.</p>
                                            </li>
                                            <li>
                                                <p>Sort by date added</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    :
                    null
                }

                {    isLoading
                    ?
                    <div className="ssdcs-loader-animation-container">
                        {/*<LoaderAnimation/>*/}
                    </div>
                    :
                    <Outlet/>
                }


                {
                    showFooter
                    ?
                    <Footer/>
                    :
                    null
                }
                

            </div>
}


export default StoreStructure;