import React, {useState, useEffect, useRef} from 'react';
import { Outlet, useLocation, useMatch } from "react-router-dom";
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
//import AdminLoginDropdownMobile from './AdminLoginDropdownMobile';
import Footer from './Footer';
import FullCartErrorMsg from './FullCartErrorMsg';
import EmptyCartAlertMsg from './EmptyCartAlertMsg';
import {selectHighlightBackground, addHighlightBackground} from './redux/features/highlightBackground/highlightBackgroundSlice';
//import {selectAdminLoginDropdownMobile, showAdminLoginDropdownMobile} from './redux/features/adminLoginDropdownMobile/adminLoginDropdownMobileSlice';
import {selectMobileNav, showMobileNav} from './redux/features/mobileNav/mobileNavSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import PageNotFound from './PageNotFound';
import RelatedProducts2 from './RelatedProducts2';
import GenericErrorMessage from './GenericErrorMessage';
import Recaptcha from './Recaptcha';
import './App.css';

interface Props {
    //path?:string;
    //children: React.ReactElement[];
}

//add footer
//<Footer/>
//original nav bar
//<MainNavbar/>
const App:React.FunctionComponent<Props> = (props: Props) => {
     const highlightBackgroundRedux = useAppSelector(selectHighlightBackground);
     const [keywordDropdownShadeContainer, setKeywordDropdownShadeContainer] = useState('keyword-background-shade-container-hide');
     const appWrapperRef = useRef<HTMLDivElement>(null);
     const kdsContainerRef = useRef<HTMLDivElement>(null);
     const [showFooter, setShowFooter] = useState(false);
     const [showMenuListMobileNav, setShowMenuListMobileNav] = useState(false);
    const [showDefaultKeywordSearchResults, setShowDefaultKeywordSearchResults] = useState(false);
    const [showPageNotFound, setShowPageNotFound] = useState(false);
     //const [showAdminLoginDropdownMobile, setShowAdminLoginDropdownMobile] = useState(false);

     const pathname = useLocation().pathname;
     //console.log('path9:', pathname);
     
     //previous version
     //const sbd = useMatch('/shop/:storeDepartmentName/:storeDepartmentId');
     //const sbdc = useMatch('/shop/:storeDepartmentName/:departmentCategoryName/:storeDepartmentId/:departmentCategoryId');
     //const sbpc = useMatch('/shop/:storeDepartmentName/:departmentCategoryName/:productCategoryName/:storeDepartmentId/:productCategoryId');

     //console.log('sbd:', sbd, 'sbdc:', sbdc, 'sbpc:', sbpc, pathname, showMenuListMobileNav);

     const dispatch = useAppDispatch();

     const [isRecaptchaApproved, setIsRecaptchaApproved] = useState(false);
     const recaptchaRef = useRef<HTMLDivElement>(null);
     const [showErrorMessage, setShowErrorMessage] = useState(false);
    
    const [isLoaded, setIsLoaded] = useState(false);
    const [showRecaptcha, setShowRecaptcha] = useState(false);

    const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);
    const recaptchaIntervalRef = useRef<number>(0);
    
    const [tick, setTick] = useState(false);
    

    useEffect(()=>{
        if(highlightBackgroundRedux.highlightBackground.highlightBackground){
            setKeywordDropdownShadeContainer('keyword-background-shade-container');

        } else {
            setKeywordDropdownShadeContainer('keyword-background-shade-container-hide');
        }

    },[highlightBackgroundRedux.highlightBackground.highlightBackground]);


    useEffect(()=>{

        if(pathname === '/cart' || /^\/admin.*/i.test(pathname) || /^\/search.*/i.test(pathname)){
            setShowFooter(false);

        } else {
            if(isRecaptchaApproved){
                setShowFooter(true);
            }
        }
        

        if(/^\/search$/i.test(pathname)){
            setShowPageNotFound(true);
            setShowDefaultKeywordSearchResults(false);

        } else if(/^\/search\/+$/i.test(pathname)){
            setShowPageNotFound(false);
            setShowDefaultKeywordSearchResults(true);

        } else {
            setShowDefaultKeywordSearchResults(false);
            setShowPageNotFound(false);
        }

        //previous version
        /*
        if(sbd || sbdc || sbpc){
            setShowMenuListMobileNav(false);

        } else {
            setShowMenuListMobileNav(true);
        }
        */

        //need to add the check from session storage to see if ira is true
        /*
        if(!isLoaded && (window.sessionStorage.getItem('ira') === null)){            
            setIsLoaded(true);
            setShowRecaptcha(true);
        }

        if(!isLoaded && (window.sessionStorage.getItem('ira') === 'true')){
            setIsLoaded(true);
            setIsRecaptchaApproved(true);
        }
        */

        //setShowAdminLoginDropdownMobile(true);
        
        //console.log('r28:');
        //window.setTimeout(()=>{
            //previous version
            //dispatch(showMobileNav({showMobileNav:false, linkSelected:false}));
            dispatch(showMobileNav({showMobileNav:false, linkSelected:false, closeMobileNavImmediately:true}));
            //dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:false, linkSelected:false}));
            dispatch(addHighlightBackground({highlightBackground:false}));
        //},0);
        


    },[pathname]);

    useEffect(()=>{

        /*previous version
        if(!isLoaded && (window.sessionStorage.getItem('ira') === null)){            
            setIsLoaded(true);
            setShowRecaptcha(true);
        }
        */

        recaptchaIntervalRef.current = window.setInterval(captchaIntervalHandler,250);

        //console.log('r17:', recaptchaIntervalRef.current);
        //console.log('done:', recaptchaIntervalRef.current);

        return clearReCaptchaIntervalHandler;

    },[tick]);


    function clearReCaptchaIntervalHandler():void {
        //console.log('done:', recaptchaIntervalRef.current);
        window.clearInterval(recaptchaIntervalRef.current);
    }

    function captchaIntervalHandler():void {
        //console.log('done:', isLoaded);
        //need to add the check from session storage to see if ira is true
        if((typeof window !== "undefined") && (typeof window.grecaptcha !== "undefined") && (typeof window.grecaptcha.render === 'function')
            && !isLoaded && (window.sessionStorage.getItem('ira') === 'true')){
            //console.log('yes:');
            setIsLoaded(true);
            setIsRecaptchaApproved(true);
            clearReCaptchaIntervalHandler();
            return;
        }

        //console.log('recaptcha:', 'render:', typeof window.grecaptcha.render === 'undefined', 'grecatcha:', typeof window.grecaptcha === 'undefined')
        if((typeof window !== "undefined") && (typeof window.grecaptcha !== "undefined") && (typeof window.grecaptcha.render === 'function')
            && !isLoaded && (window.sessionStorage.getItem('ira') === null)){
            //console.log('yes:');
            setIsLoaded(true);
            setShowRecaptcha(true);
            setReCaptchaLoaded(true);
            clearReCaptchaIntervalHandler();

            return;
        }

        //console.log('done:');
        setTick(!tick);
    }



    function responseTokenCallback(responseToken:string):void {

        //console.log('rt:', responseToken);
        const params = JSON.stringify({token:responseToken});

        //do what you need based on the response being pass or fail
        fetch('https://server.kando-proto-3.com/recaptcha/',{
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            },
            body:(params)
        })
        .then((res)=>res.json())
        .then(({challenge, errorMsg})=>{
            //console.log('token response:', challenge);
            if(challenge === 'passed'){
                recaptchaRef.current!.style.display = 'none';
                setIsRecaptchaApproved(true);
                setShowFooter(true);

                window.sessionStorage.setItem('ira', 'true');
            }

            if(errorMsg !== 'none'){
                throw errorMsg;
            }

        })
        .catch((err)=>{
            setShowErrorMessage(true);
            //console.log('token response error message:', err.message);
        });
        
    }

    function responseTokenExpiredCallback():void {
        //console.log('rt expired:');
        
    }

    function responseTokenErrorCallback():void {
        //console.log('rt error:');
        setShowErrorMessage(true);
    }


//{children}
    return (
        <div ref={appWrapperRef} className="app-wrapper">
            {/*<TopNavbar/>*/}
            {/*<ProductSearch/>*/}
            {/*<MainNavbar2/>*/}

            {/*
                showMenuListMobileNav
                ?
                <MenuListMobileNav/>
                :
                null
            */}


            {
                showPageNotFound
                ?
                <PageNotFound showNavSearch={true} showFooter={true}/>
                :
                null
            }

            {
                showDefaultKeywordSearchResults
                ?
                <RelatedProducts2/>
                :
                null
            }


            {/*<AdminLoginDropdownMobile/>*/}


            {
                (isLoaded && showRecaptcha && reCaptchaLoaded)
                ?
                <Recaptcha ref={recaptchaRef}
                            dataCallback={responseTokenCallback} 
                            expiredCallback={responseTokenExpiredCallback} 
                            errorCallback={responseTokenErrorCallback}/>
                :
                null
            }



            {
                isRecaptchaApproved
                ?
                <Outlet/>
                :
                null
            }

            {
                showErrorMessage
                ?
                <div className="recaptcha-generic-error-msg-container">
                    <GenericErrorMessage/>
                </div>
                :
                null
            }


            <FullCartErrorMsg/>
            <EmptyCartAlertMsg/>
            <div ref={kdsContainerRef} className={keywordDropdownShadeContainer}></div>

            {
                showFooter
                ?
                <Footer/>
                :
                null
            }

        </div>
    );
}

export default App;