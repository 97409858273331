import React, { useState, useEffect, useRef, useMemo } from 'react';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import HomePageLayoutItem from './homePageLayoutItem';
import Banner from './banner';
import MarketingBanner from './marketingBanner';
import { throttle } from 'lodash';
import loadNavLink from './loadNavLinkHelper';
import './BannerBox.css';

interface Props {
    //path?:string;
    editBanner:boolean;
    banner:Banner;
    layoutAction:string;
    bannerView?:string;
    updateExistingHomePageLayoutItems?:(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string)=>void;
    //setupItemsCallback:(item:HomePageLayoutItem)=>JSX.Element;
}

const BannerBox2:React.FunctionComponent<Props> = (props: Props) => {
    const {updateExistingHomePageLayoutItems} = props;
    //const [banner, setBanner] = useState(props.banner);
    const navigate = useNavigate();
    const banner = useRef<Banner[]>([props.banner]);
    const [editBanner, setEditBanner] = useState(props.editBanner);
    //const [bannerView, setBannerView] = useState(props.bannerView);
    const bannerView = useRef<string[]>([props.bannerView!]);
    const bannerHeadingRef1 = useRef<HTMLDivElement>(null);
    const bannerHeadingRef2 = useRef<HTMLDivElement>(null);
    const bannerCaptionRef = useRef<HTMLParagraphElement>(null);
    const bannerButtonDesktopRef = useRef<HTMLButtonElement>(null);
    const bannerButtonMobileRef = useRef<HTMLButtonElement>(null);
    const [mobileViewWidthSelected, setMobileViewWidthSelected] = useState(767);
    //const [mobileViewHeightSelected, setMobileViewHeightSelected] = useState(767 * 1.16618);
    const [mobileViewHeightSelected, setMobileViewHeightSelected] = useState(767 * (props.banner.paddingBottomMobile / 100));
    const bannerMobileViewContainerRef = useRef<HTMLDivElement>(null);
    const bannerDesktopViewContainerRef = useRef<HTMLDivElement>(null);
    //const bannerMobileViewOptionWrapperRef = useRef<HTMLDivElement>(null);
    const bannerMobileViewOptionFormRef = useRef<HTMLFormElement>(null);
    const bmvcMaxWidth = useRef<number>(mobileViewWidthSelected);
    const bmvcHeight = useRef<number>(mobileViewHeightSelected);

    const bannerHeadingBtnContainerRef = useRef<HTMLDivElement>(null);
    const bannerMobileHeightMeasureContainerRef = useRef<HTMLDivElement>(null);
    const bannerMobileWidthMeasureContainerRef = useRef<HTMLDivElement>(null);
    const bannerMobileViewOptionRangeRef = useRef<HTMLInputElement>(null);
    const bannerMobileWidthMeasureRef = useRef<HTMLInputElement>(null);
    const bannerMobileHeightMeasureRef = useRef<HTMLInputElement>(null);
    const bannerMobileViewWrapperRef = useRef<HTMLDivElement>(null);

    const bannerHeadingBtnWrapperRef = useRef<HTMLDivElement>(null);

    const [bannerChecked, setBannerChecked] = useState(setupBannerCheckbox(props.banner));
    const [layoutAction, setLayoutAction] = useState(props.layoutAction);

    const productionView = useRef<string[]>([]);

    const prv = useRef<number>(0);
    const crv = useRef<number>(0);

    const [numberOfMobileRangeBtns, setNumberOfMobileRangeBtns] = useState(5);

    const bannerLinkRef = useRef<HTMLAnchorElement>(null);

    const portrait = useRef<MediaQueryList[]>([]);


    function setupBannerCheckbox(banner:Banner):boolean {
        //console.log('bannerId:', banner.bannerId, banner.homePageLayoutId);
        return banner.homePageLayoutId > 0 ? true : false;
    }

    function bannerCheckboxHandler(e:React.ChangeEvent):void {
        //const checkboxChecked = (e.target as HTMLInputElement).checked;
        //setCardChecked(checkboxChecked);
        const mb = new MarketingBanner({...banner.current[0],
                                        marketingBannerId:banner.current[0].bannerId})
        updateExistingHomePageLayoutItems!(mb, 'banner-box');
    }

   
    useEffect(()=>{
        //console.log('passed:', 'props.banner:', props.banner, 'banner:', banner);
        if(props.banner !== banner.current[0]){
            //console.log('passed');
            //setBanner(props.banner);
            banner.current[0] = props.banner;


            //clear all css styles
            bannerHeadingRef1.current!.classList.remove('bh-mobile-on-mobile-view-reg');
            bannerHeadingRef1.current!.classList.remove('bh-mobile-on-mobile-view-lg');
            if(bannerHeadingRef2.current! !== null){
                bannerHeadingRef2.current!.classList.remove('bh-mobile-on-mobile-view-reg');
                bannerHeadingRef2.current!.classList.remove('bh-mobile-on-mobile-view-lg');
            }

            if(bannerCaptionRef.current! !== null){
                bannerCaptionRef.current!.classList.remove('ch-mobile-on-mobile-view');
            }
            //console.log('reached');

            if(props.bannerView === 'mobile' && banner.current[0].headingSizeMobile === 'regular'){
                //console.log('reached');
                bannerHeadingRef1.current!.classList.add('bh-mobile-on-mobile-view-reg');
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-mobile-on-mobile-view-reg');
                }

                if(bannerCaptionRef.current! !== null){
                    bannerCaptionRef.current!.classList.add('ch-mobile-on-mobile-view');
                }
            }

            if(props.bannerView === 'mobile' && banner.current[0].headingSizeMobile === 'large'){
                //console.log('reached');
                bannerHeadingRef1.current!.classList.add('bh-mobile-on-mobile-view-lg');
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-mobile-on-mobile-view-lg');
                }

                if(bannerCaptionRef.current! !== null){
                    bannerCaptionRef.current!.classList.add('ch-mobile-on-mobile-view');
                }
            }


            /*
            if(props.bannerView === 'mobile'){
                const bmvww = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
                const bmvcw = bannerMobileViewContainerRef.current!.getBoundingClientRect().width;

                if(bmvcw === bmvww || bmvcw >= 767){
                    console.log('reached:', bannerMobileViewWrapperRef.current!.getBoundingClientRect().width);
                    console.log('reached:', bannerMobileViewOptionRangeRef.current!.max);
                    console.log('reached:', bannerMobileViewOptionRangeRef.current!.value);
                    
                    bannerMobileWidthMeasureRef.current!.value = `${bmvcMaxWidth.current}px`;
                    bannerMobileHeightMeasureRef.current!.value = `${(bmvcMaxWidth.current * 1.16618).toFixed(0)}px`;
                    bannerMobileViewOptionRangeRef.current!.max = `${bmvcMaxWidth.current}`;
                    bannerMobileViewOptionRangeRef.current!.value = `${bmvcMaxWidth.current}`;
                }
                
            }
            */     
           
           
        }
    },[props.banner]);


    useEffect(()=>{
        const dw = document.documentElement.clientWidth;
        //console.log('bv:', bannerHeadingRef1.current!);
        //don't access any references when in production mode
        if(props.bannerView !== 'production'){

            //setup mobile range btn options        
            if(props.bannerView === 'mobile' && dw >= 360){
                const rpa = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
                //console.log('nomrbs:');
                if(dw >= 600){
                    setMobileViewWidthSelected(rpa);
                    setNumberOfMobileRangeBtns(7);

                } else if(dw >= 500){
                    setMobileViewWidthSelected(rpa);
                    setNumberOfMobileRangeBtns(6);

                } else if(dw >= 360) {
                    setMobileViewWidthSelected(rpa);
                    setNumberOfMobileRangeBtns(5);

                }
            }

            //console.log('passed:', 'props.bannerView:', props.bannerView, 'bannerView:', bannerView, bannerHeadingRef1.current!);
            if(props.bannerView !== bannerView.current[0]){
                //console.log('bv:', props.bannerView);
                /*
                if(props.bannerView === 'mobile' && window.innerWidth < 768){
                    bannerHeadingRef1.current!.classList.remove('bh-desktop-on-mobile-view-reg');
                    bannerHeadingRef2.current!.classList.remove('bh-desktop-on-mobile-view-lg');
                    bannerCaptionRef.current!.classList.remove('ch-desktop-on-mobile-view');
                    bannerButtonMobileRef.current!.classList.remove('bb-desktop-on-mobile-view');
                }
                */

                if(props.bannerView === 'mobile'){
                    //remove all existing css classes
                    //desktop
                    bannerHeadingRef1.current!.classList.remove('bh-desktop-on-mobile-view-reg');
                    bannerHeadingRef1.current!.classList.remove('bh-desktop-on-mobile-view-lg');

                    if(bannerHeadingRef2.current! !== null){
                        bannerHeadingRef2.current!.classList.remove('bh-desktop-on-mobile-view-reg');
                        bannerHeadingRef2.current!.classList.remove('bh-desktop-on-mobile-view-lg');
                    }
                    

                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.remove('ch-desktop-on-mobile-view');
                    }
                    
                    bannerButtonMobileRef.current!.classList.remove('bb-desktop-on-mobile-view');

                } else {
                    //console.log('reached');
                    //mobile view container styles
                    bannerHeadingRef1.current!.classList.remove('bh-mobile-view-reg');
                    bannerHeadingRef1.current!.classList.remove('bh-mobile-view-lg');
                    bannerHeadingRef1.current!.classList.remove('bh-mobile-on-mobile-view-reg');
                    bannerHeadingRef1.current!.classList.remove('bh-mobile-on-mobile-view-lg');

                    if(bannerHeadingRef2.current! !== null){
                        bannerHeadingRef2.current!.classList.remove('bh-mobile-view-reg');
                        bannerHeadingRef2.current!.classList.remove('bh-mobile-view-lg');
                        bannerHeadingRef2.current!.classList.remove('bh-mobile-on-mobile-view-reg');
                        bannerHeadingRef2.current!.classList.remove('bh-mobile-on-mobile-view-lg');
                    }
                    
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.remove('ch-mobile-view');
                        bannerCaptionRef.current!.classList.remove('ch-mobile-on-mobile-view');
                    }
                    
                    

                }

                //setBannerView(props.bannerView);
                bannerView.current.length = 0;
                bannerView.current[0] = props.bannerView!;
            }



            //show and hide mobile view options selector
            if(bannerView.current[0] === 'mobile' && dw >= 768){
                //measure container 33px
                //extra width from measurement box 18px
                //padding 16px each side for 32px
                if(dw < 929){
                    //console.log('reached:', bmvcMaxWidth.current, bannerMobileViewContainerRef.current!.getBoundingClientRect().width, bannerMobileViewOptionRangeRef.current!.max, bannerMobileViewOptionRangeRef.current!.value);
                    const wrapperWidth = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
                    let rangePosition = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
                    let rangeMax = parseInt(bannerMobileViewOptionRangeRef.current!.max,10);
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth > rangePosition)){
                        //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                        bmvcMaxWidth.current = wrapperWidth;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;
                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth < rangePosition)){
                        //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                        bmvcMaxWidth.current = wrapperWidth; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;

                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth > rangeMax) && (wrapperWidth > rangePosition)){
                        //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                        bmvcMaxWidth.current = wrapperWidth; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;

                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    }  else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition >= 767)){
                        bmvcMaxWidth.current = 767; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;

                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = 767;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;

                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100) /*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax < 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;

                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    }            
                    
                }

                //if(bannerMobileViewContainerRef.current!.getBoundingClientRect().width + 33 + 33 + 18 + 18 + 27 + 32 < window.innerWidth){
                if(dw >= 929){
                    //console.log('reached:', bmvcMaxWidth.current, bmvcHeight.current, bannerMobileViewContainerRef.current!.getBoundingClientRect().width);
                    const wrapperWidth = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
                    let rangePosition = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
                    let rangeMax = parseInt(bannerMobileViewOptionRangeRef.current!.max,10);
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth > rangePosition)){
                        bmvcMaxWidth.current = wrapperWidth;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;
                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth < rangePosition)){
                        bmvcMaxWidth.current = wrapperWidth;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;
                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100) /*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition >= 767)){
                        bmvcMaxWidth.current = 767;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;
                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = 767;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;
                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax < 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);//1.16618;
                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    }


                }

                

            } else if(bannerView.current[0] === 'mobile' && dw < 768){
                //console.log('reached:', bmvcMaxWidth.current, bmvcHeight.current, bannerMobileViewContainerRef.current!.getBoundingClientRect().width);
                const wrapperWidth = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
                let rangePosition = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
                let rangeMax = parseInt(bannerMobileViewOptionRangeRef.current!.max,10);
                //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth > rangePosition)){
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    bmvcMaxWidth.current = wrapperWidth;
                    bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                    rangeMax = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                    //range position stays the same value
                    bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                    bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                    bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                    //set height measurement 1 and 2
                    const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                    adjustHeightMeasureLineWidth(bw ,rangePosition);

                    const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                    bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                    bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                    const diff = 46 + 11;
                    const margin = 33;
                    bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                    bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                    bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                    //set css variable --bmvw
                    if(rangePosition <= 375){
                        document.documentElement.style.setProperty('--bmvw', `${375}px`);

                    } else {
                        document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                    }

                } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth > rangeMax) && (wrapperWidth > rangePosition)){
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    bmvcMaxWidth.current = wrapperWidth;
                    bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                    rangeMax = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                    //range position stays the same value
                    bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                    bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                    bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                    //set height measurement 1 and 2
                    const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                    adjustHeightMeasureLineWidth(bw ,rangePosition);

                    const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                    bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                    bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                    const diff = 46 + 11;
                    const margin = 33;
                    bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                    bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                    bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                    //set css variable --bmvw
                    if(rangePosition <= 375){
                        document.documentElement.style.setProperty('--bmvw', `${375}px`);

                    } else {
                        document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                    }

                } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth < rangePosition)){
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    bmvcMaxWidth.current = wrapperWidth;
                    bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                    rangeMax = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                    rangePosition = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                    bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                    bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                    //set height measurement 1 and 2
                    const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                    adjustHeightMeasureLineWidth(bw ,rangePosition);

                    const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                    bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                    bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                    const diff = 46 + 11;
                    const margin = 33;
                    bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                    bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                    bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                    //set css variable --bmvw
                    if(rangePosition <= 375){
                        document.documentElement.style.setProperty('--bmvw', `${375}px`);

                    } else {
                        document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                    }

                }


                //this go in bannerview.current[0] === 'mobile' && window.innerWidth < 768
                if(banner.current[0].headingSizeMobile === 'regular'){
                    //console.log('reached');
                    bannerHeadingRef1.current!.classList.add('bh-mobile-on-mobile-view-reg');
                    if(bannerHeadingRef2.current! !== null){
                        bannerHeadingRef2.current!.classList.add('bh-mobile-on-mobile-view-reg');
                    }

                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-on-mobile-view');
                    }
                }

                if(banner.current[0].headingSizeMobile === 'large'){
                    //console.log('reached');
                    bannerHeadingRef1.current!.classList.add('bh-mobile-on-mobile-view-lg');
                    if(bannerHeadingRef2.current! !== null){
                        bannerHeadingRef2.current!.classList.add('bh-mobile-on-mobile-view-lg');
                    }

                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-on-mobile-view');
                    }
                }


            }

                /*previous version
                //set css variable --bmvw
                if(bcw <= 375){
                    document.documentElement.style.setProperty('--bmvw', `${375}px`);

                } else {
                    document.documentElement.style.setProperty('--bmvw', `${bcw}px`);
                }
                */

            


            if(bannerView.current[0] === 'desktop'){
                bannerDesktopViewContainerRef.current!.style.maxWidth = '1440px';
                bannerDesktopViewContainerRef.current!.style.height = '100%';
            }

        }

        
        

    },[props.bannerView]);


    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", resizeHandler);

        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupEventHandlers;
    },[]);

    function screenOrientationChangeHandler(e:Event):void {
        resizeHandler(new Event('test'));
    }


    function resizeHandler(e:Event):void {
        //console.log('resize:', window.innerWidth, bannerView.current[0]);
        //console.log('banner:', banner.current[0]);
        //don't access any references when in production mode
        if(props.bannerView !== 'production'){

            //remove all existing css classes
            //desktop
            if(bannerHeadingRef1.current! !== null){
                bannerHeadingRef1.current!.classList.remove('bh-desktop-on-mobile-view-reg');
                bannerHeadingRef1.current!.classList.remove('bh-desktop-on-mobile-view-lg');
            }


            if(bannerHeadingRef2.current! !== null){
                bannerHeadingRef2.current!.classList.remove('bh-desktop-on-mobile-view-reg');
                bannerHeadingRef2.current!.classList.remove('bh-desktop-on-mobile-view-lg');
            }

            if(bannerCaptionRef.current! !== null){
                bannerCaptionRef.current!.classList.remove('ch-desktop-on-mobile-view');
            }
            
            if(bannerButtonMobileRef.current! !== null){
                bannerButtonMobileRef.current!.classList.remove('bb-desktop-on-mobile-view');
            }
            

            //mobile view container styles
            if(bannerHeadingRef1.current! !== null){
                bannerHeadingRef1.current!.classList.remove('bh-mobile-view-reg');
                bannerHeadingRef1.current!.classList.remove('bh-mobile-view-lg');
                bannerHeadingRef1.current!.classList.remove('bh-mobile-on-mobile-view-reg');
                bannerHeadingRef1.current!.classList.remove('bh-mobile-on-mobile-view-lg');
            }


            if(bannerHeadingRef2.current! !== null){
                bannerHeadingRef2.current!.classList.remove('bh-mobile-view-reg');
                bannerHeadingRef2.current!.classList.remove('bh-mobile-view-lg');
                bannerHeadingRef2.current!.classList.remove('bh-mobile-on-mobile-view-reg');
                bannerHeadingRef2.current!.classList.remove('bh-mobile-on-mobile-view-lg');
            }

            if(bannerCaptionRef.current! !== null){
                bannerCaptionRef.current!.classList.remove('ch-mobile-view');
                bannerCaptionRef.current!.classList.remove('ch-mobile-on-mobile-view');
            }
            
            //remove all banner heading classes
            if(bannerHeadingBtnContainerRef.current! !== null){
                //desktop
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-desktop-top-center');
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-desktop-left-top');
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-desktop-left-center');

                //mobile
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-mobile-top-center');
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-mobile-left-top');
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-mobile-left-center');



                //set banner heading container
                if(bannerView.current[0] === 'desktop' && banner.current[0].headingPositionDesktop === 'top-center'){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-top-center');
                } else if(bannerView.current[0] === 'desktop' && banner.current[0].headingPositionDesktop === 'left-top'){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }if(bannerView.current[0] === 'desktop' && banner.current[0].headingPositionDesktop === 'left-center'){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerView.current[0] === 'mobile' && banner.current[0].headingPositionMobile === 'top-center'){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-top-center');
                } else if(bannerView.current[0] === 'mobile' && banner.current[0].headingPositionMobile === 'left-top'){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }if(bannerView.current[0] === 'mobile' && banner.current[0].headingPositionMobile === 'left-center'){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-center');
                }


            }


            const dw = document.documentElement.clientWidth;
            //set mobile range btn options
            if(bannerView.current[0] === 'mobile' && dw >= 360){
                //console.log('wiw:', document.documentElement.clientWidth);
                const rpa = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
    
                if(dw >= 600){
                    setMobileViewWidthSelected(rpa);
                    setNumberOfMobileRangeBtns(7);
    
                } else if(dw >= 500){
                    setMobileViewWidthSelected(rpa);
                    setNumberOfMobileRangeBtns(6);
    
                } else if(dw >= 360) {
                    setMobileViewWidthSelected(rpa);
                    setNumberOfMobileRangeBtns(5);
    
                }
            }


            //show and hide mobile view options selector
            if(bannerView.current[0] === 'mobile' && dw >= 768){
                //measure container 33px
                //extra width from measurement box 18px
                //padding 16px each side for 32px
                if(dw < 929){
                    //console.log('reached:', bmvcMaxWidth.current, bannerMobileViewContainerRef.current!.getBoundingClientRect().width, bannerMobileViewOptionRangeRef.current!.max, bannerMobileViewOptionRangeRef.current!.value);
                    const wrapperWidth = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
                    let rangePosition = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
                    let rangeMax = parseInt(bannerMobileViewOptionRangeRef.current!.max,10);
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    /*if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth > rangePosition)){
                        console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                        bmvcMaxWidth.current = wrapperWidth;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100);
                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else*/ if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth < rangePosition)){
                        //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                        bmvcMaxWidth.current = wrapperWidth; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;

                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth > rangeMax) && (wrapperWidth > rangePosition)){
                        //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                        bmvcMaxWidth.current = wrapperWidth; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;

                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    }  else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition >= 767)){
                        bmvcMaxWidth.current = 767; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;

                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = 767;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;

                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax < 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767; 
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;

                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);

                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 46 + 11;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    }            
                    
                }

                //if(bannerMobileViewContainerRef.current!.getBoundingClientRect().width + 33 + 33 + 18 + 18 + 27 + 32 < window.innerWidth){
                if(dw >= 929){
                    //console.log('reached:', bmvcMaxWidth.current, bmvcHeight.current, bannerMobileViewContainerRef.current!.getBoundingClientRect().width);
                    const wrapperWidth = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
                    let rangePosition = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
                    let rangeMax = parseInt(bannerMobileViewOptionRangeRef.current!.max,10);
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth > rangePosition)){
                        bmvcMaxWidth.current = wrapperWidth;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        //range position stays the same value
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth < rangePosition)){
                        bmvcMaxWidth.current = wrapperWidth;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                        rangeMax = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = wrapperWidth;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition >= 767)){
                        bmvcMaxWidth.current = 767;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = 767;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax >= 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    } else if(wrapperWidth >= 768 && (rangeMax < 767) && (rangePosition < 767)){
                        bmvcMaxWidth.current = 767;
                        bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                        rangeMax = 767;
                        bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                        rangePosition = rangePosition;
                        bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                        bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                        bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                        //set height measurement 1 and 2
                        const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                        adjustHeightMeasureLineWidth(bw ,rangePosition);
        
                        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                        bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                        bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                        const diff = 0;
                        const margin = 33;
                        bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                        bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                        bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                        //set css variable --bmvw
                        if(rangePosition <= 375){
                            document.documentElement.style.setProperty('--bmvw', `${375}px`);

                        } else {
                            document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                        }

                    }


                }

                

            } else if(bannerView.current[0] === 'mobile' && dw < 768){
                //console.log('reached:', bmvcMaxWidth.current, bmvcHeight.current, bannerMobileViewContainerRef.current!.getBoundingClientRect().width);
                const wrapperWidth = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
                let rangePosition = parseInt(bannerMobileViewOptionRangeRef.current!.value,10);
                let rangeMax = parseInt(bannerMobileViewOptionRangeRef.current!.max,10);
                //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth > rangePosition)){
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    bmvcMaxWidth.current = wrapperWidth;
                    bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                    rangeMax = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                    //range position stays the same value
                    bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                    bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                    bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                    //set height measurement 1 and 2
                    const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                    adjustHeightMeasureLineWidth(bw ,rangePosition);

                    const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                    bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                    bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                    const diff = 46 + 11;
                    const margin = 33;
                    bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                    bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                    bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                    //set css variable --bmvw
                    if(rangePosition <= 375){
                        document.documentElement.style.setProperty('--bmvw', `${375}px`);

                    } else {
                        document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                    }

                } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth > rangeMax) && (wrapperWidth > rangePosition)){
                    //console.log('reached2:', wrapperWidth, rangePosition, rangeMax);
                    bmvcMaxWidth.current = wrapperWidth;
                    bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                    rangeMax = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                    //range position stays the same value
                    bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                    bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                    bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                    //set height measurement 1 and 2
                    const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                    adjustHeightMeasureLineWidth(bw ,rangePosition);

                    const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                    bannerMobileHeightMeasureContainerRef.current!.style.width = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;
                    bannerMobileHeightMeasureContainerRef.current!.style.top = `${((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2) - (bmhmch/2)}px`;
                    const diff = 46 + 11;
                    const margin = 33;
                    bannerMobileHeightMeasureContainerRef.current!.style.left = `${(rangePosition - ((rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/) / 2)) + margin - diff}px`;

                    bannerMobileViewContainerRef.current!.style.maxWidth = `${rangePosition}px`;
                    bannerMobileViewContainerRef.current!.style.height = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/)}px`;

                    //set css variable --bmvw
                    if(rangePosition <= 375){
                        document.documentElement.style.setProperty('--bmvw', `${375}px`);

                    } else {
                        document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                    }

                } else if(wrapperWidth < 768 && wrapperWidth >= 248 && (wrapperWidth < rangeMax) && (wrapperWidth < rangePosition)){
                    //console.log('reached:', wrapperWidth, rangePosition, rangeMax);
                    bmvcMaxWidth.current = wrapperWidth;
                    bmvcHeight.current = bmvcMaxWidth.current * (props.banner.paddingBottomMobile / 100)/*1.16618*/;
                    rangeMax = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.max = `${rangeMax}`;
                    rangePosition = wrapperWidth;
                    bannerMobileViewOptionRangeRef.current!.value = `${rangePosition}`;
                    bannerMobileWidthMeasureRef.current!.value = `${rangePosition}px`;
                    bannerMobileHeightMeasureRef.current!.value = `${(rangePosition * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0)}px`;

                    //set height measurement 1 and 2
                    const bw = wrapperWidth < 767 ? wrapperWidth : 767;
                    adjustHeightMeasureLineWidth(bw ,rangePosition);

                    const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;
                    bannerMobileHeightMeasureContainerRef.current!.style.width = `${bmvcHeight.current}px`;
                    bannerMobileHeightMeasureContainerRef.current!.style.top = `${(bmvcHeight.current / 2) - (bmhmch/2)}px`;
                    const diff = 46 + 11;
                    const margin = 33;
                    bannerMobileHeightMeasureContainerRef.current!.style.left = `${(bmvcMaxWidth.current - (bmvcHeight.current / 2)) + margin - diff}px`;

                    bannerMobileViewContainerRef.current!.style.maxWidth = `${bmvcMaxWidth.current}px`;
                    bannerMobileViewContainerRef.current!.style.height = `${bmvcHeight.current}px`;

                    //set css variable --bmvw
                    if(rangePosition <= 375){
                        document.documentElement.style.setProperty('--bmvw', `${375}px`);

                    } else {
                        document.documentElement.style.setProperty('--bmvw', `${rangePosition}px`);
                    }

                } 


                //console.log('reached:', banner.current[0].headingSizeMobile);
                if(banner.current[0].headingSizeMobile === 'regular'){
                    //console.log('reached');
                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-mobile-on-mobile-view-reg');
                    }
                    
                    if(bannerHeadingRef2.current! !== null){
                        bannerHeadingRef2.current!.classList.add('bh-mobile-on-mobile-view-reg');
                    }

                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-on-mobile-view');
                    }
                }

                if(banner.current[0].headingSizeMobile === 'large'){
                    //console.log('reached');
                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-mobile-on-mobile-view-lg');
                    }
                    
                    if(bannerHeadingRef2.current! !== null){
                        bannerHeadingRef2.current!.classList.add('bh-mobile-on-mobile-view-lg');
                    }

                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-on-mobile-view');
                    }
                }
                

            }


            //set heading and caption classes
            if(banner.current[0].headingTypeDesktop === 'split' || banner.current[0].headingTypeMobile === 'split'){

                //console.log('bv:', bannerView, banner.current[0]);

                if(bannerView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' && dw < 768){
                    //console.log('passed:', banner.current[0]);

                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-desktop-on-mobile-view-reg');
                    }
                    
                    if(bannerHeadingRef2.current! !==null){
                        bannerHeadingRef2.current!.classList.add('bh-desktop-on-mobile-view-reg');
                    }
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-desktop-on-mobile-view');
                    }

                    if(bannerButtonMobileRef.current! !== null){
                        bannerButtonMobileRef.current!.classList.add('bb-desktop-on-mobile-view');
                    }
                    

                } else if(bannerView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' && dw < 768){
                    //console.log('passed:', banner.current[0]);
                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-desktop-on-mobile-view-lg');
                    }
                    
                    if(bannerHeadingRef2.current! !==null){
                        bannerHeadingRef2.current!.classList.add('bh-desktop-on-mobile-view-lg');    
                    }
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-desktop-on-mobile-view');    
                    }

                    if(bannerButtonMobileRef.current! !== null){
                        bannerButtonMobileRef.current!.classList.add('bb-desktop-on-mobile-view');
                    }
                    

                } else if(bannerView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' && dw >= 768) {
                    /*
                    bannerHeadingRef1.current!.classList.add('bh-mobile-on-desktop-view-reg');
                    bannerHeadingRef2.current!.classList.add('bh-mobile-on-desktop-view-reg');
                    bannerCaptionRef.current!.classList.add('ch-mobile-on-desktop-view');
                    */

                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-mobile-view-reg');
                    }
                    
                    if(bannerHeadingRef2.current! !==null){
                        bannerHeadingRef2.current!.classList.add('bh-mobile-view-reg');    
                    }
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-view');    
                    }
                    
                
                } else if(bannerView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' && dw >= 768) {
                    /*
                    bannerHeadingRef1.current!.classList.add('bh-mobile-on-desktop-view-lg');
                    bannerHeadingRef2.current!.classList.add('bh-mobile-on-desktop-view-lg');
                    bannerCaptionRef.current!.classList.add('ch-mobile-on-desktop-view');
                    */
                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-mobile-view-lg');
                    }

                    if(bannerHeadingRef2.current! !==null){
                        bannerHeadingRef2.current!.classList.add('bh-mobile-view-lg');    
                    }
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-view');    
                    }
                    
                }

            } else {

                if(bannerView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' && dw < 768){
                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-desktop-on-mobile-view-reg');
                    }
                    
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-desktop-on-mobile-view');
                    }

                    if(bannerButtonMobileRef.current! !== null){
                        bannerButtonMobileRef.current!.classList.add('bb-desktop-on-mobile-view');
                    }
                    

                } else if(bannerView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' && dw < 768){
                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-desktop-on-mobile-view-lg');
                    }
                    
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-desktop-on-mobile-view');
                    }
                    
                    if(bannerButtonMobileRef.current! !== null){
                        bannerButtonMobileRef.current!.classList.add('bb-desktop-on-mobile-view');
                    }
                    

                } else if(bannerView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' && dw >= 768){
                    /*
                    bannerHeadingRef1.current!.classList.add('bh-mobile-on-desktop-view-reg');
                    bannerCaptionRef.current!.classList.add('ch-mobile-on-desktop-view');
                    */

                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-mobile-view-reg');
                    }
                    
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-view');
                    }
                    

                } else if(bannerView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' && dw >= 768){
                    /*
                    bannerHeadingRef1.current!.classList.add('bh-mobile-on-desktop-view-lg');
                    bannerCaptionRef.current!.classList.add('ch-mobile-on-desktop-view');
                    */

                    if(bannerHeadingRef1.current! !== null){
                        bannerHeadingRef1.current!.classList.add('bh-mobile-view-lg');
                    }

                    
                    if(bannerCaptionRef.current! !== null){
                        bannerCaptionRef.current!.classList.add('ch-mobile-view');
                    }
                }
            }

        } else {

            //const dw = document.documentElement.clientWidth;
            const dw = window.innerWidth;

            //console.log('bv:', bannerView.current[0]);
            if(dw >= 768){
                productionView.current[0] = 'desktop';
                bannerLinkRef.current!.style.paddingBottom = `${props.banner.paddingBottomDesktop}%`;
            } else {
                productionView.current[0] = 'mobile';
                bannerLinkRef.current!.style.paddingBottom = `${props.banner.paddingBottomMobile}%`;
            }
            //console.log('bv:', productionView.current[0], banner.current[0]);
            //need to clear all styles before setting
            //do this below just like above
            if(bannerHeadingRef1.current! !== null){
                bannerHeadingRef1.current!.classList.remove('bh-reg');
                bannerHeadingRef1.current!.classList.remove('bh-lg');
            }

            if(bannerHeadingRef2.current! !== null){
                bannerHeadingRef2.current!.classList.remove('bh-reg');
                bannerHeadingRef2.current!.classList.remove('bh-lg');
            }
            
            if(bannerHeadingBtnWrapperRef.current! !== null){
                bannerHeadingBtnWrapperRef.current!.classList.remove('bhbw-center');
                bannerHeadingBtnWrapperRef.current!.classList.remove('bhbw-left');
            }

            if(bannerHeadingBtnContainerRef.current! !== null){
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-mobile-top-center');
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-mobile-left-top');
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-desktop-left-center');
                bannerHeadingBtnContainerRef.current!.classList.remove('bhbc-desktop-top-center');
            }

           


            if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' 
                && banner.current[0].headingPositionMobile === 'top-center'
                && banner.current[0].headingTypeMobile === 'noSplit'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-top-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' 
                && banner.current[0].headingPositionMobile === 'left-top'
                && banner.current[0].headingTypeMobile === 'noSplit'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' 
                && banner.current[0].headingPositionMobile === 'left-center'
                && banner.current[0].headingTypeMobile === 'noSplit'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                  

            }

            if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' 
                && banner.current[0].headingPositionMobile === 'top-center'
                && banner.current[0].headingTypeMobile === 'noSplit'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-top-center');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' 
                && banner.current[0].headingPositionMobile === 'left-top'
                && banner.current[0].headingTypeMobile === 'noSplit'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' 
                && banner.current[0].headingPositionMobile === 'left-center'
                && banner.current[0].headingTypeMobile === 'noSplit'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                

            }

            if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' 
                && banner.current[0].headingPositionMobile === 'top-center'
                && banner.current[0].headingTypeMobile === 'split'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-top-center');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-reg');
                }
                

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' 
                && banner.current[0].headingPositionMobile === 'left-top'
                && banner.current[0].headingTypeMobile === 'split'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }
                
                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-reg');
                }

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'regular' 
                && banner.current[0].headingPositionMobile === 'left-center'
                && banner.current[0].headingTypeMobile === 'split'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }
                
                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-reg');
                }

            }

            if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' 
                && banner.current[0].headingPositionMobile === 'top-center'
                && banner.current[0].headingTypeMobile === 'split'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }

                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-top-center');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-lg');
                }

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' 
                && banner.current[0].headingPositionMobile === 'left-top'
                && banner.current[0].headingTypeMobile === 'split'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-lg');
                }

            } else if(dw < 768 && productionView.current[0] === 'mobile' && banner.current[0].headingSizeMobile === 'large' 
                && banner.current[0].headingPositionMobile === 'left-center'
                && banner.current[0].headingTypeMobile === 'split'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }
                
                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-lg');
                }

            }


            //styles for desktop
            if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' 
                && banner.current[0].headingPositionDesktop === 'top-center'
                && banner.current[0].headingTypeDesktop === 'noSplit'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
            
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }
                
                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-top-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' 
                && banner.current[0].headingPositionDesktop === 'left-top'
                && banner.current[0].headingTypeDesktop === 'noSplit'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' 
                && banner.current[0].headingPositionDesktop === 'left-center'
                && banner.current[0].headingTypeDesktop === 'noSplit'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }
                
                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                

            }

            if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' 
                && banner.current[0].headingPositionDesktop === 'top-center'
                && banner.current[0].headingTypeDesktop === 'noSplit'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }


                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-top-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
        

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' 
                && banner.current[0].headingPositionDesktop === 'left-top'
                && banner.current[0].headingTypeDesktop === 'noSplit'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' 
                && banner.current[0].headingPositionDesktop === 'left-center'
                && banner.current[0].headingTypeDesktop === 'noSplit'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 !== 'none'){
                        banner.current[0].heading1 = banner.current[0].heading1.concat(' ', banner.current[0].heading2);
                        banner.current[0].heading2 = 'none';
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = '';
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }
                
                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                

            }

            if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' 
                && banner.current[0].headingPositionDesktop === 'top-center'
                && banner.current[0].headingTypeDesktop === 'split'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-top-center');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-reg');
                }                    

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' 
                && banner.current[0].headingPositionDesktop === 'left-top'
                && banner.current[0].headingTypeDesktop === 'split'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }

                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-reg');
                }

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'regular' 
                && banner.current[0].headingPositionDesktop === 'left-center'
                && banner.current[0].headingTypeDesktop === 'split'){
                    //left-top | left-center -- bhbw-left

                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }
                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }
                
                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-reg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-reg');
                }

            }

            if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' 
                && banner.current[0].headingPositionDesktop === 'top-center'
                && banner.current[0].headingTypeDesktop === 'split'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }


                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-center');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-top-center');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-lg');
                }

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' 
                && banner.current[0].headingPositionDesktop === 'left-top'
                && banner.current[0].headingTypeDesktop === 'split'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }

                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-mobile-left-top');
                }

                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-lg');
                }

            } else if(dw >= 768 && productionView.current[0] === 'desktop' && banner.current[0].headingSizeDesktop === 'large' 
                && banner.current[0].headingPositionDesktop === 'left-center'
                && banner.current[0].headingTypeDesktop === 'split'){
                    //left-top | left-center -- bhbw-left
                    
                    if(banner.current[0].heading1 !== 'none' && banner.current[0].heading2 === 'none'){
                        const {heading1, heading2} = headingSplit(banner.current[0], 2);
                        banner.current[0].heading1 = heading1;
                        banner.current[0].heading2 = heading2;
                        bannerHeadingRef1.current!.textContent = banner.current[0].heading1;
                        bannerHeadingRef2.current!.textContent = banner.current[0].heading2;
                    }

                
                if(bannerHeadingBtnWrapperRef.current! !== null){
                    bannerHeadingBtnWrapperRef.current!.classList.add('bhbw-left');
                }

                if(bannerHeadingBtnContainerRef.current! !== null){
                    bannerHeadingBtnContainerRef.current!.classList.add('bhbc-desktop-left-center');
                }
                
                if(bannerHeadingRef1.current! !== null){
                    bannerHeadingRef1.current!.classList.add('bh-lg');
                }
                
                if(bannerHeadingRef2.current! !== null){
                    bannerHeadingRef2.current!.classList.add('bh-lg');
                }

            }



        }
    }




    function cleanupEventHandlers():void {
        window.removeEventListener('resize', resizeHandler);

        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
        portrait.current[0].removeEventListener("change", resizeHandler);


    }


    function handleShopWhatsNewButtonClick(e:React.MouseEvent):void {
        //console.log('click');
        e.preventDefault();
        e.stopPropagation();
        navigate('/whats-new');
    }
    
    function handleShopBtnClick(e:React.MouseEvent):void {
        e.stopPropagation();
        e.preventDefault();
        
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        navigate('/shop/overview');
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            //setIsLoading(false);
        },100);

    }


    function setupHeadingBtnPositionOptions(btnOptions:string[]):JSX.Element[]{
        return btnOptions.map((bo, i)=>{
                            return <option key={i} value={bo}>{bo}</option>
                        });
    }


    function setupHeadingBtnWrapperClass(banner:Banner):string {
        let headingPosition = '';

        if(window.innerWidth >= 768){
            headingPosition = banner.headingPositionDesktop;

        } else {
            headingPosition = banner.headingPositionMobile;
        }

        switch(headingPosition){
            case 'top-center':
                return 'bhbw-center';
            case 'left-center':
            case 'left-top':
                return 'bhbw-left';
            default:
                throw new Error('no heading position found.');
        }

    }


    function setupHeadingBtnDesktopWrapperClass(banner:Banner):string {
        const headingPosition = banner.headingPositionDesktop;

        switch(headingPosition){
            case 'top-center':
                return 'bhbw-center';
            case 'left-center':
            case 'left-top':
                return 'bhbw-left';
            default:
                throw new Error('no heading position found.');
        }

    }

    function setupHeadingBtnMobileWrapperClass(banner:Banner):string {
        const headingPosition = banner.headingPositionMobile;
        
        switch(headingPosition){
            case 'top-center':
                return 'bhbw-center';
            case 'left-center':
            case 'left-top':
                return 'bhbw-left';
            default:
                throw new Error('no heading position found.');
        }

    }

    function setupHeadingBtnContainerClass(banner:Banner):string {
        let headingPosition = '';
        let screenType = '';

        if(window.innerWidth >= 768){
            headingPosition = banner.headingPositionDesktop;
            screenType = 'desktop';

        } else {
            headingPosition = banner.headingPositionMobile;
            screenType = 'mobile';
        }

        switch(screenType){
            case 'mobile':
                switch(headingPosition){
                    case 'top-center':
                        return 'bhbc-mobile-top-center';
                    case 'left-center':
                        return 'bhbc-mobile-left-center';
                    case 'left-top':
                        return 'bhbc-mobile-left-top';
                    default:
                        throw new Error('no mobile heading position found.');
                }

            case 'desktop':
                switch(headingPosition){
                    case 'top-center':
                        return 'bhbc-desktop-top-center';
                    case 'left-center':
                        return 'bhbc-desktop-left-center';
                    case 'left-top':
                        return 'bhbc-desktop-left-top';
                    default:
                        throw new Error('no desktop heading position found.');
                }

            default:
                throw new Error('no screen type found.');
        }
    }

    function setupHeadingBtnDesktopContainerClass(banner:Banner):string {
        let headingPosition = '';
        let screenType = '';

        if(window.innerWidth >= 768){
            headingPosition = banner.headingPositionDesktop;
            screenType = 'desktop';

        } else {
            headingPosition = banner.headingPositionDesktop;
            screenType = 'desktop-view-on-mobile';
        }

        switch(screenType){
            case 'desktop-view-on-mobile':
                switch(headingPosition){
                    case 'top-center':
                        return 'bhbc-mobile-top-center';
                    case 'left-center':
                        return 'bhbc-mobile-left-center';
                    case 'left-top':
                        return 'bhbc-mobile-left-top';
                    default:
                        throw new Error('no mobile heading position found.');
                }

            case 'desktop':
                switch(headingPosition){
                    case 'top-center':
                        return 'bhbc-desktop-top-center';
                    case 'left-center':
                        return 'bhbc-desktop-left-center';
                    case 'left-top':
                        return 'bhbc-desktop-left-top';
                    default:
                        throw new Error('no desktop heading position found.');
                }

            default:
                throw new Error('no screen type found.');
        }

    }

    function setupHeadingBtnMobileContainerClass(banner:Banner):string {
        let headingPosition = '';
        let screenType = '';

        if(window.innerWidth < 768){
            headingPosition = banner.headingPositionMobile;
            screenType = 'mobile';

        } else {
            headingPosition = banner.headingPositionMobile;
            screenType = 'mobile-view-on-desktop';
        }

        //console.log('hp:', headingPosition, 'st:', screenType);

        switch(screenType){
            case 'mobile':
                switch(headingPosition){
                    case 'top-center':
                        return 'bhbc-mobile-top-center';
                    case 'left-center':
                        return 'bhbc-mobile-left-center';
                    case 'left-top':
                        return 'bhbc-mobile-left-top';
                    default:
                        throw new Error('no mobile heading position found.');
                }
            
            case 'mobile-view-on-desktop':
                switch(headingPosition){
                    case 'top-center':
                        return 'bhbc-desktop-top-center bh-mobile-on-desktop-view';
                    case 'left-center':
                        return 'bhbc-desktop-left-center';
                    case 'left-top':
                        return 'bhbc-desktop-left-top';
                    default:
                        throw new Error('no desktop heading position found.');
                }

            default:
                throw new Error('no screen type found.');
        }

    }

    function setupHeadingClass(banner:Banner, screenType:string):string {
        //console.log('run:', screenType);
        if(screenType === 'desktop' && window.innerWidth < 768 && banner.headingSizeDesktop === 'regular'){
            return 'bh-desktop-on-mobile-view-reg';

        } else if(screenType === 'desktop' && window.innerWidth < 768 && banner.headingSizeDesktop === 'large'){
            return 'bh-desktop-on-mobile-view-lg';

        } else if(screenType === 'desktop' && window.innerWidth >= 768) {
            return '';

        }/* else if(screenType === 'mobile' && window.innerWidth >= 768 && banner.headingSizeMobile === 'regular'){
            return 'bh-mobile-on-desktop-view-reg';

        } else if(screenType === 'mobile' && window.innerWidth >= 768 && banner.headingSizeMobile === 'large'){
            return 'bh-mobile-on-desktop-view-lg';

        }*/ else if(screenType === 'mobile' && window.innerWidth >= 768 && banner.headingSizeMobile === 'regular'){
            return 'bh-mobile-view-reg';

        } else if(screenType === 'mobile' && window.innerWidth >= 768 && banner.headingSizeMobile === 'large'){
            return 'bh-mobile-view-lg';

        }  else if(screenType === 'mobile' && window.innerWidth < 768 && banner.headingSizeMobile === 'regular'){
            return 'bh-mobile-on-mobile-view-reg';

        } else if(screenType === 'mobile' && window.innerWidth < 768 && banner.headingSizeMobile === 'large'){
            return 'bh-mobile-on-mobile-view-lg';

        } else {
            return '';
        }
 
        
    }

    function setupCaptionClass(screenType:string):string {
        if(screenType === 'desktop' && window.innerWidth < 768){
            return 'ch-desktop-on-mobile-view';

        } else if(screenType === 'desktop' && window.innerWidth >= 768) {
            return '';

        }/* else if(screenType === 'mobile' && window.innerWidth >= 768){
            return 'ch-mobile-on-desktop-view';

        }*/ else if(screenType === 'mobile' && window.innerWidth >= 768){
            return 'ch-mobile-view';

        } else if(screenType === 'mobile' && window.innerWidth < 768){
            return 'ch-mobile-on-mobile-view';

        } else {
            return '';
        }
    
    }

    function setupButtonDesktopClass():string {
        //console.log('test');
        if(window.innerWidth < 768){
            return 'bb-desktop-on-mobile-view';

        } else {
            return '';
        }
    }

    function setupHeadingSizeClass(banner:Banner, screenType:string):string {
        //console.log('banner:', banner);
        if(screenType === 'desktop' && banner.headingSizeDesktop === 'regular'){
            return 'bh-reg';

        } else if(screenType === 'desktop' && banner.headingSizeDesktop === 'large'){
            return 'bh-lg';

        } else if(screenType === 'mobile' && banner.headingSizeMobile === 'regular'){
            return 'bh-reg';

        }  else {
            return 'bh-lg';
        }
    }

    function setupBannerHeadingType(banner:Banner):JSX.Element {
        const screenWidth = window.innerWidth;
        const screenType = screenWidth >= 768 ? 'desktop' : 'mobile';

        switch(screenType){
            case 'desktop':
                switch(banner.headingTypeDesktop){
                    case 'split':
                        return <div className="banner-heading-double-container">
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'desktop')}`}>{banner.heading1 !== 'none' ? banner.heading1 : ''}</div>}
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(banner, 'desktop')}`}>{banner.heading2 !== 'none' ? banner.heading2 : ''}</div>}
                                    {/*banner.heading1 !== 'none' ? <div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'desktop')}`}>{banner.heading1}</div> : null */}
                                    {/*banner.heading2 !== 'none' ? <div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(banner, 'desktop')}`}>{banner.heading2}</div> : null */}
                                    {banner.caption !== 'none' ? <p style={{color:`${banner.captionColor}`}} ref={bannerCaptionRef} className="banner-caption">{banner.caption}</p> : null}
                                </div>
                    case 'noSplit':
                        return <div className="banner-heading-single-container">
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'desktop')}`}>{banner.heading1 !== 'none' ? banner.heading1 : ''}</div>}
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(banner, 'desktop')}`}>{banner.heading2 !== 'none' ? banner.heading2 : ''}</div>}
                                    {/*banner.heading1 !== 'none' ? <div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'desktop')}`}>{banner.heading1}</div> : null */}
                                    {banner.caption !== 'none' ? <p style={{color:`${banner.captionColor}`}} ref={bannerCaptionRef} className="banner-caption">{banner.caption}</p> : null}
                                </div>
                    default:
                        throw new Error('No desktop heading type found.');
                }
            case 'mobile':
                switch(banner.headingTypeMobile){
                    case 'split':
                        return <div className="banner-heading-double-container">
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'mobile')}`}>{banner.heading1 !== 'none' ? banner.heading1 : ''}</div>}
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(banner, 'mobile')}`}>{banner.heading2 !== 'none' ? banner.heading2 : ''}</div>}
                                    {/*banner.heading1 !== 'none' ? <div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'mobile')}`}>{banner.heading1}</div> : null */}
                                    {/*banner.heading2 !== 'none' ? <div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(banner, 'mobile')}`}>{banner.heading2}</div> : null */}
                                    {banner.caption !== 'none' ? <p style={{color:`${banner.captionColor}`}} ref={bannerCaptionRef} className="banner-caption">{banner.caption}</p> : null}
                                </div>
                        
                    case 'noSplit':
                        return <div className="banner-heading-single-container">
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'mobile')}`}>{banner.heading1 !== 'none' ? banner.heading1 : ''}</div>}
                                    {<div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(banner, 'mobile')}`}>{banner.heading2 !== 'none' ? banner.heading2 : ''}</div>}
                                    {/*banner.heading1 !== 'none' ? <div style={{color:`${banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(banner, 'mobile')}`}>{banner.heading1}</div> : null */}
                                    {banner.caption !== 'none' ? <p style={{color:`${banner.captionColor}`}} ref={bannerCaptionRef} className="banner-caption">{banner.caption}</p> : null}
                                </div>
                    default:
                        throw new Error('No mobile heading type found.');
                }
            default:
                throw new Error('No screen type found.');
        }
    }


    function adjustHeightMeasureLineWidth(maxWidth:number, mvwSelected:number):void {
        const rangeWidthTotal = maxWidth - 248;
        const rangeMoved = maxWidth - mvwSelected;
        const percentMoved = parseInt(((rangeMoved / rangeWidthTotal) * 100).toFixed(0),10);
        const hm1StartValue = 1;
        const hm2StartValue = 99;
        crv.current = percentMoved;

        //console.log('rw::', percentMoved.toFixed(0), rangeMoved, rangeWidthTotal);

        //decrease image size
        if(crv.current > prv.current){
            const hm1 = hm1StartValue + percentMoved;
            const hm2 = hm2StartValue - percentMoved;
            document.documentElement.style.setProperty('--hm1', `${(hm1 >= 99 ? 99 : hm1)}%`);
            document.documentElement.style.setProperty('--hm2', `${(hm2 <= 1 ? 1 : hm2)}%`);
            //console.log('rw test', hm1 >= 99 ? 99 : hm1, hm2 <= 1 ? 1 : hm2);
            
        } 
        
        //increase image size
        if(crv.current < prv.current){
            const hm1 = percentMoved - hm1StartValue;
            const hm2 = hm2StartValue - percentMoved;
            document.documentElement.style.setProperty('--hm1', `${(hm1 <= 1 ? 1 : hm1)}%`);
            document.documentElement.style.setProperty('--hm2', `${(hm2 >= 99 ? 99 : hm2)}%`);

            //console.log('rw test:', hm1 <= 1 ? 1 : hm1, hm2 >= 99 ? 99 : hm2, percentMoved.toFixed(0));
        }


        prv.current = crv.current;
    }


    function setupBannerMobileViewOptionRangeBtnList(nomrbs:number):JSX.Element {
        //console.log('nomrbs:', nomrbs);
        switch(nomrbs){
            case 5:
                return  <ul className="bmv-option-list">
                            <li className="bmv-option-list-item">
                                <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(0))}>0%</button>
                            </li>
                            <li className="bmv-option-list-item">
                                <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(25))}>25%</button>
                            </li>
                            <li className="bmv-option-list-item">
                                <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(50))}>50%</button>
                            </li>
                            <li className="bmv-option-list-item">
                                <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(75))}>75%</button>
                            </li>
                            <li className="bmv-option-list-item">
                                <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(100))}>100%</button>
                            </li>
                        </ul>
                case 6:
                    return  <ul className="bmv-option-list">
                                <li className="bmv-option-list-item">
                                    <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(0))}>0%</button>
                                </li>
                                <li className="bmv-option-list-item">
                                    <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(20))}>20%</button>
                                </li>
                                <li className="bmv-option-list-item">
                                    <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(40))}>40%</button>
                                </li>
                                <li className="bmv-option-list-item">
                                    <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(60))}>60%</button>
                                </li>
                                <li className="bmv-option-list-item">
                                    <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(80))}>80%</button>
                                </li>
                                <li className="bmv-option-list-item">
                                    <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(100))}>100%</button>
                                </li>
                            </ul>
                    case 7:
                        return  <ul className="bmv-option-list">
                                    <li className="bmv-option-list-item">
                                        <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(0))}>0%</button>
                                    </li>
                                    <li className="bmv-option-list-item">
                                        <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(16.7))}>16.7%</button>
                                    </li>
                                    <li className="bmv-option-list-item">
                                        <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(33.4))}>33.4%</button>
                                    </li>
                                    <li className="bmv-option-list-item">
                                        <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(50.1))}>50.1%</button>
                                    </li>
                                    <li className="bmv-option-list-item">
                                        <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(66.8))}>66.8%</button>
                                    </li>
                                    <li className="bmv-option-list-item">
                                        <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(83.5))}>83.5%</button>
                                    </li>
                                    <li className="bmv-option-list-item">
                                        <button type="button" className="bmv-option-list-item-btn" onClick={(e:React.MouseEvent)=>mobileViewWidthHandlerModified(getMobileViewWidthBtnValue(100))}>100%</button>
                                    </li>
                                </ul>

                    default:
                        throw new Error('no mobile range buttons found.');
        }


    }

    function getMobileViewWidthBtnValue(percentOfWidth:number):number {
        const ww = document.documentElement.clientWidth;
        //console.log('aw:', ww);
        switch(percentOfWidth){
            case 0:
                return 248;
            case 16.7:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .167) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .167) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .167) + 248;
                    return Math.floor(amt);
                }

            case 20:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .2) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .2) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .2) + 248;
                    return Math.floor(amt);
                }

            case 25:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .25) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .25) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .25) + 248;
                    return Math.floor(amt);
                }

            case 33.3:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .33) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .33) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .33) + 248;
                    return Math.floor(amt);
                }

            case 33.4:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .334) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .334) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .334) + 248;
                    return Math.floor(amt);
                }

            case 40:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .4) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .4) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .4) + 248;
                    return Math.floor(amt);
                }

            case 50:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .5) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .5) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .5) + 248;
                    return Math.floor(amt);
                }

            case 50.1:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .501) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .501) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .501) + 248;
                    return Math.floor(amt);
                }

            case 60:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .6) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .6) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .6) + 248;
                    return Math.floor(amt);
                }

            case 66.6:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .666) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .666) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .666) + 248;
                    return Math.floor(amt);
                }

            case 66.8:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .668) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .668) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .668) + 248;
                    return Math.floor(amt);
                }

            case 75:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .75) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .75) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .75) + 248;
                    return Math.floor(amt);
                }

            case 80:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .8) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .8) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .8) + 248;
                    return Math.floor(amt);
                }

            case 83.5:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .835) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .835) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .835) + 248;
                    return Math.floor(amt);
                }

            case 99.9:
                if(ww >= 768){
                    const aw = ww - 64;
                    if(aw < 767){
                        const amt = ((aw - 248) * .999) + 248;
                        return Math.floor(amt);

                    } else {
                        const amt = ((767 - 248) * .999) + 248;
                        return Math.floor(amt);
                    }

                } else {
                    const aw = ww - 32;
                    const amt = ((aw - 248) * .999) + 248;
                    return Math.floor(amt);
                }

            case 100:
                if(ww >= 768){
                    const aw = ww - 64;
                    //console.log('aw:', aw);
                    if(aw < 767){
                        //console.log('aw:', aw);
                        return aw;

                    } else {
                        //console.log('aw:', aw);
                        return 767;
                    }

                } else {
                    const aw = ww - 32;
                    return aw;
                }
        
            default:
                throw new Error('no percent of width found.');
        }

    }

    function mobileViewWidthHandlerModified(aw:number):void {
        //console.log('aw:', aw);
        const dw = document.documentElement.clientWidth;

        const mvwSelected = aw; //parseInt((e.currentTarget as HTMLButtonElement).value, 10);
        //console.log('mvws:', mvwSelected, bmvcMaxWidth.current);

        //need to get padding bottom for each banner to calculate the height dynamically based on the width
        const mvhSelected = parseFloat((mvwSelected * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0));
        //console.log('mvhs:', mvhSelected);
  
        bannerMobileViewContainerRef.current!.style.maxWidth = `${mvwSelected}px`;
        bannerMobileViewContainerRef.current!.style.height = `${mvhSelected}px`;

        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;

        bannerMobileHeightMeasureContainerRef.current!.style.width = `${mvhSelected}px`;
        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(mvhSelected / 2) - (bmhmch/2)}px`;

        if(dw < 929){
            const diff = 46 + 11;
            const margin = 33;
            bannerMobileHeightMeasureContainerRef.current!.style.left = `${(mvwSelected - (mvhSelected / 2)) + margin - diff}px`;

        } else {
            const margin = 33;
            bannerMobileHeightMeasureContainerRef.current!.style.left = `${(mvwSelected - (mvhSelected / 2)) + margin}px`;
        }


        bmvcMaxWidth.current = mvwSelected;
        bmvcHeight.current = mvhSelected;

        //set height measurement 1 and 2
        const bmvww = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
        const bw = bmvww < 767 ? bmvww : 767;
        adjustHeightMeasureLineWidth(bw ,mvwSelected);

        //set css variable --bmvw
        if(mvwSelected <= 375){
            document.documentElement.style.setProperty('--bmvw', `${375}px`);

        } else {
            document.documentElement.style.setProperty('--bmvw', `${mvwSelected}px`);
        }
        
   

        setMobileViewWidthSelected(mvwSelected);
        setMobileViewHeightSelected(mvhSelected);

    }


    function mobileViewWidthHandler(e:React.ChangeEvent):void {
        const dw = document.documentElement.clientWidth;
        const mvwSelected = parseInt((e.target as HTMLSelectElement).value, 10);
        //console.log('mvws:', mvwSelected, bmvcMaxWidth.current);

        //need to get padding bottom for each banner to calculate the height dynamically based on the width
        const mvhSelected = parseFloat((mvwSelected * (props.banner.paddingBottomMobile / 100)/*1.16618*/).toFixed(0));
        //console.log('mvhs:', mvhSelected);
  
        bannerMobileViewContainerRef.current!.style.maxWidth = `${mvwSelected}px`;
        bannerMobileViewContainerRef.current!.style.height = `${mvhSelected}px`;

        const bmhmch = bannerMobileHeightMeasureContainerRef.current!.offsetHeight;

        bannerMobileHeightMeasureContainerRef.current!.style.width = `${mvhSelected}px`;
        bannerMobileHeightMeasureContainerRef.current!.style.top = `${(mvhSelected / 2) - (bmhmch/2)}px`;

        if(dw < 929){
            const diff = 46 + 11;
            const margin = 33;
            bannerMobileHeightMeasureContainerRef.current!.style.left = `${(mvwSelected - (mvhSelected / 2)) + margin - diff}px`;

        } else {
            const margin = 33;
            bannerMobileHeightMeasureContainerRef.current!.style.left = `${(mvwSelected - (mvhSelected / 2)) + margin}px`;
        }


        bmvcMaxWidth.current = mvwSelected;
        bmvcHeight.current = mvhSelected;

        //set height measurement 1 and 2
        const bmvww = bannerMobileViewWrapperRef.current!.getBoundingClientRect().width;
        const bw = bmvww < 767 ? bmvww : 767;
        adjustHeightMeasureLineWidth(bw ,mvwSelected);

        //set css variable --bmvw
        if(mvwSelected <= 375){
            document.documentElement.style.setProperty('--bmvw', `${375}px`);

        } else {
            document.documentElement.style.setProperty('--bmvw', `${mvwSelected}px`);
        }
        
   

        setMobileViewWidthSelected(mvwSelected);
        setMobileViewHeightSelected(mvhSelected);

    }

    function setupFullLengthClass(banner:Banner):string {
        //console.log('banner:', banner);
        if(banner.fullLength){
            return 'banner-img-full-length';

        } else {
            return '';
        }
    }


    function headingSplit(b:Banner, numberOfHeadings:number):{heading1:string, heading2:string} {
        if(numberOfHeadings === 1){
            return {heading1: b.heading1, heading2:'none'};
    
        } else {
            const heading = b.heading1;
            const headingWords = heading.split(' ');
            let h1:string[] = [];
            let h2:string[] = [];
    
            for(let i=0; i < headingWords.length; i++){
                if(i < numberOfHeadings){
                    h1.push(headingWords[i]);
    
                } else {
                    h2.push(headingWords[i]);
                }
            }
            
            const heading1 = h1.join(' ');
            const heading2 = h2.join(' ');
    
            return {heading1, heading2};
        }
    
    }


    if(editBanner && props.bannerView === 'desktop'){
        return  <div className="banner-dekstop-view-wrapper">
                    {
                        layoutAction !== 'display'
                        ?
                        <div className="banner-view-header-container">
                            <h2 className="banner-view-heading">Desktop View</h2>
                        </div>
                        : null
                    }
                    <div ref={bannerDesktopViewContainerRef} className="banner-dekstop-view-container">
                        <div className="banner-wrapper">
                            {
                                (layoutAction === 'edit' && props.banner.homePageLayoutId > 0 && !props.banner.selectedForRemoval)
                                ?
                                <form className="hplid-checkbox-form">
                                    <input className="hplid-checkbox-control" type="checkbox" id={`${props.banner.bannerId}`} value="1" checked={bannerChecked} onChange={bannerCheckboxHandler}/>
                                </form>
                                : null
                            }
                            {
                                (layoutAction === 'edit' && (props.banner.homePageLayoutId === 0 || props.banner.selectedForRemoval))
                                ?
                                <div className="ahpl-new-item-label">New</div>
                                : null
                            }
                            <div style={{paddingBottom:`${props.banner.paddingBottomDesktop}%`}} className="banner-link">
                                <img src={`https://server.kando-proto-3.com/${props.banner.desktopSource}`} alt={props.banner.heading1} className={`banner-image ${setupFullLengthClass(props.banner)}`}/>
                                <div className={`banner-heading-btn-wrapper ${setupHeadingBtnDesktopWrapperClass(props.banner)}`}>
                                    <div ref={bannerHeadingBtnContainerRef} className={`banner-heading-btn-container ${setupHeadingBtnDesktopContainerClass(props.banner)}`}>
                                        {
                                            props.banner.headingTypeDesktop === 'noSplit'
                                            ?
                                            <div className="banner-heading-single-container">
                                                {props.banner.heading1 !== 'none' ? <div style={{color:`${props.banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(props.banner, props.bannerView)} ${setupHeadingClass(props.banner, props.bannerView)}`}>{props.banner.heading1}</div> : null }
                                                {props.banner.caption !== 'none' ? <p style={{color:`${props.banner.captionColor}`}} ref={bannerCaptionRef} className={`banner-caption ${setupCaptionClass(props.bannerView)}`}>{props.banner.caption}</p> : null}
                                            </div>
                                            : null
                                        }
                                        {
                                            props.banner.headingTypeDesktop === 'split'
                                            ?
                                            <div className="banner-heading-double-container">
                                                {props.banner.heading1 !== 'none' ? <div style={{color:`${props.banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(props.banner, props.bannerView)} ${setupHeadingClass(props.banner, props.bannerView)}`}>{props.banner.heading1}</div> : null }
                                                {props.banner.heading2 !== 'none' ? <div style={{color:`${props.banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(props.banner, props.bannerView)} ${setupHeadingClass(props.banner, props.bannerView)}`}>{props.banner.heading2}</div> : null }
                                                {props.banner.caption !== 'none' ? <p style={{color:`${props.banner.captionColor}`}} ref={bannerCaptionRef} className={`banner-caption ${setupCaptionClass(props.bannerView)}`}>{props.banner.caption}</p> : null}
                                            </div>
                                            : null
                                        }

                                        <button type="button" style={{backgroundColor:`${props.banner.buttonBackgroundColor}`, color:`${props.banner.buttonColor}`}} ref={bannerButtonMobileRef} className={`logo-container-shop-btn banner-btn-mobile ${setupButtonDesktopClass()}`} onClick={handleShopBtnClick}>
                                            Shop now
                                        </button>
                                        <button type="button" style={{backgroundColor:`${props.banner.buttonBackgroundColor}`, color:`${props.banner.buttonColor}`}} ref={bannerButtonDesktopRef} className="logo-container-shop-btn banner-btn-desktop" onClick={handleShopBtnClick}>
                                            Shop now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

    } else if(editBanner && props.bannerView === 'mobile'){
        return <div ref={bannerMobileViewWrapperRef} className="banner-mobile-view-wrapper">
                    <div className="banner-mobile-view-option-wrapper">
                        <div className="banner-view-header-container">
                            <h2 className="banner-view-heading">Mobile View</h2>
                        </div>
                        <form ref={bannerMobileViewOptionFormRef}  className="banner-mobile-view-option-form">
                            <fieldset className="banner-mobile-view-option-container">
                                <div className="bmv-option-list-container">
                                    {setupBannerMobileViewOptionRangeBtnList(numberOfMobileRangeBtns)}
                                </div>
                                <input ref={bannerMobileViewOptionRangeRef} type="range" className="banner-mobile-view-option-range" min="248" max="767" value={mobileViewWidthSelected} onChange={mobileViewWidthHandler} />
                            </fieldset>
                        </form>
                    </div>
                    <div ref={bannerMobileViewContainerRef} className="banner-mobile-view-container">
                        <div ref={bannerMobileWidthMeasureContainerRef} className="banner-mobile-width-measure-container">
                            <div className="banner-mobile-width-measure-line"></div>
                            <input ref={bannerMobileWidthMeasureRef} className="banner-mobile-width-measure" type="text" value={`${mobileViewWidthSelected}px`} readOnly={true} /*disabled={true}*//>
                            <div className="banner-mobile-width-measure-line"></div>
                        </div>
                        <div ref={bannerMobileHeightMeasureContainerRef} className="banner-mobile-height-measure-container">
                            <div className="banner-mobile-height-measure-line-1"></div>
                            <input ref={bannerMobileHeightMeasureRef} className="banner-mobile-height-measure" type="text" value={`${mobileViewHeightSelected.toFixed(0)}px`} readOnly={true} /*disabled={true}*//>
                            <div className="banner-mobile-height-measure-line-2"></div>
                        </div>
                        <div className="banner-wrapper">
                            {
                                (layoutAction === 'edit' && props.banner.homePageLayoutId > 0 && !props.banner.selectedForRemoval)
                                ?
                                <form className="hplid-checkbox-form">
                                    <input className="hplid-checkbox-control" type="checkbox" id={`${props.banner.bannerId}`} value={props.banner.bannerId} checked={bannerChecked} onChange={bannerCheckboxHandler}/>
                                </form>
                                : null
                            }
                            {
                                (layoutAction === 'edit' && (props.banner.homePageLayoutId === 0 || props.banner.selectedForRemoval))
                                ?
                                <div className="ahpl-new-item-label">New</div>
                                : null
                            }
                            <div style={{paddingBottom:`${props.banner.paddingBottomMobile}%`}} className="banner-link">
                                <img src={`https://server.kando-proto-3.com/${props.banner.mobileSource}`} alt={props.banner.heading1} className={`banner-image ${setupFullLengthClass(props.banner)}`}/>
                                <div className={`banner-heading-btn-wrapper ${setupHeadingBtnMobileWrapperClass(props.banner)}`}>
                                    <div ref={bannerHeadingBtnContainerRef} className={`banner-heading-btn-container ${setupHeadingBtnMobileContainerClass(props.banner)}`}>
                                        {/*setupBannerHeadingType(props.banner)*/}
                                        {
                                            props.banner.headingTypeMobile === 'noSplit'
                                            ?
                                            <div className="banner-heading-single-container">
                                                {props.banner.heading1 !== 'none' ? <div style={{color:`${props.banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(props.banner, props.bannerView)} ${setupHeadingClass(props.banner, props.bannerView)}`}>{props.banner.heading1}</div> : null }
                                                {props.banner.caption !== 'none' ? <p style={{color:`${props.banner.captionColor}`}} ref={bannerCaptionRef} className={`banner-caption ${setupCaptionClass(props.bannerView)}`}>{props.banner.caption}</p> : null}
                                            </div>
                                            : null
                                        }
                                        {
                                            props.banner.headingTypeMobile === 'split'
                                            ?
                                            <div className="banner-heading-double-container">
                                                {props.banner.heading1 !== 'none' ? <div style={{color:`${props.banner.headingColor}`}} ref={bannerHeadingRef1} className={`banner-heading ${setupHeadingSizeClass(props.banner, props.bannerView)} ${setupHeadingClass(props.banner, props.bannerView)}`}>{props.banner.heading1}</div> : null }
                                                {props.banner.heading2 !== 'none' ? <div style={{color:`${props.banner.headingColor}`}} ref={bannerHeadingRef2} className={`banner-heading ${setupHeadingSizeClass(props.banner, props.bannerView)} ${setupHeadingClass(props.banner, props.bannerView)}`}>{props.banner.heading2}</div> : null }
                                                {props.banner.caption !== 'none' ? <p style={{color:`${props.banner.captionColor}`}} ref={bannerCaptionRef} className={`banner-caption ${setupCaptionClass(props.bannerView)}`}>{props.banner.caption}</p> : null}
                                            </div>
                                            : null
                                        }
                                        <button type="button" style={{backgroundColor:`${props.banner.buttonBackgroundColor}`, color:`${props.banner.buttonColor}`}} ref={bannerButtonMobileRef}  className="logo-container-shop-btn banner-btn-mobile" onClick={handleShopBtnClick}>
                                            Shop now
                                        </button>
                                        <button type="button" style={{backgroundColor:`${props.banner.buttonBackgroundColor}`, color:`${props.banner.buttonColor}`}} ref={bannerButtonDesktopRef} className="logo-container-shop-btn banner-btn-desktop" onClick={handleShopBtnClick}>
                                            Shop now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

    } else {

        return <div className="banner-wrapper">
                    <Link ref={bannerLinkRef} to="/" className="banner-link" style={{paddingBottom:`${(window.innerWidth >= 768 ? props.banner.paddingBottomDesktop + '%' : props.banner.paddingBottomMobile + '%')}`}}
                        onClick={(e:React.MouseEvent)=>loadNavLink(e, '/shop/overview', navigate)}>
                        <picture className="banner-picture">
                            <source srcSet={`https://server.kando-proto-3.com/${props.banner.desktopSource}`} media="(min-width:768px)"/>
                            <source srcSet={`https://server.kando-proto-3.com/${props.banner.mobileSource}`}/>
                            <img src={`https://server.kando-proto-3.com/${props.banner.desktopSource}`} alt={props.banner.heading1} className={`banner-image ${setupFullLengthClass(props.banner)}`}/>
                        </picture>
                        <div ref={bannerHeadingBtnWrapperRef} className={`banner-heading-btn-wrapper ${setupHeadingBtnWrapperClass(props.banner)}`}>
                            <div ref={bannerHeadingBtnContainerRef} className={`banner-heading-btn-container ${setupHeadingBtnContainerClass(props.banner)}`}>
                                {setupBannerHeadingType(props.banner)}
                                <button type="button" style={{backgroundColor:`${props.banner.buttonBackgroundColor}`, color:`${props.banner.buttonColor}`}} className="logo-container-shop-btn banner-btn-mobile" onClick={handleShopBtnClick}>
                                    Shop now
                                </button>
                                <button type="button" style={{backgroundColor:`${props.banner.buttonBackgroundColor}`, color:`${props.banner.buttonColor}`}} className="logo-container-shop-btn banner-btn-desktop" onClick={handleShopBtnClick}>
                                    Shop now
                                </button>
                            </div>
                            {/*<div className="shop-whats-new-btn-container">
                                <button type="button" className="shop-whats-new-btn" onClick={handleShopWhatsNewButtonClick}>
                                    What's new!
                                </button>
                            </div>*/}
                        </div>
                    </Link>
                </div>

    }

}

export default BannerBox2;