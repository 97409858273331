import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import ProductCategorySelectControl from './ProductCategorySelectControl';
import DepartmentCategorySelectControl from './DepartmentCategorySelectControl';
import StoreDepartmentSelectControl from './StoreDepartmentSelectControl';
import ProductSubCategorySearchFilterSelectControl from './ProductSubCategorySearchFilterSelectControl';
import ProductSubCategorySearchTermSelectControl from './ProductSubCategorySearchTermSelectControl';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import ProductSubCategory from './productSubCategory';
import {getDepartmentNamesPromise} from './helper-get-store-structure-area-types';
import FormErrorMessage, {FormError, FormErrorType} from './FormErrorMessage';
import DBErrorMessage, {DBError} from './DBErrorMessage';
import GenericInfoMessage from './GenericInfoMessage';
import LoaderAnimation from './LoaderAnimation';
import formatLinkString from './helper-format-link-string';
import './AddProductSubCategory.css';

interface Props {
    //showAddProductSubCategory:boolean;
    showEditProductSubCategory:boolean;
    productSubCategoryToEdit?:ProductSubCategory;
    handleCloseForm:(formType:string, cancel?:boolean)=>void;
}

interface ProductSubCategoryImage {
    source:string;
    path:string;
    type:string;
}

interface ThumbnailImage {
    img:HTMLImageElement;
    name:string;
}

interface EditProductSubCategoryInfo {
    productSubCategoryToEdit?:ProductSubCategory;
    productSubCategoryImages?:ProductSubCategoryImage[];
    relatedDepartments?:StoreDepartment[];
    relatedDepartmentCategories?:DepartmentCategory[];
    relatedProductCategories?:ProductCategory[];
    relatedProductCategorySearchFilterNames?:string[];
    relatedProductCategorySearchTermNames?:string[];
    productSubCategoryToEditError?:Error;
}

interface ProductSubCategoryEditInfo {
    source:string;
    productSubCategory:ProductSubCategory;
}

const AddProductSubCategory: React.FunctionComponent<Props> = (props:Props) => {
    const {showEditProductSubCategory, productSubCategoryToEdit,  handleCloseForm} = props;
    const [departments, setDepartments] = useState<StoreDepartment[]>([]);
    const [departmentNames, setDepartmentNames] = useState<string[]>([]);
    const [departmentNameSelected, setDepartmentNameSelected] = useState('');
    const [showDeptNameErrorMsg, setShowDeptNameErrorMsg] = useState(false);
    const [deptNameErrorMsg, setDeptNameErrorMsg] = useState('Please add a department name.');
    const [departmentCategories, setDepartmentCategories] = useState<DepartmentCategory[]>([]);
    const [departmentCategoryNames, setDepartmentCategoryNames] = useState<string[]>([]);
    const [departmentCategoryNameSelected, setDepartmentCategoryNameSelected] = useState('');
    const [showDeptCategoryNameErrorMsg, setShowDeptCategoryNameErrorMsg] = useState(false);
    const [deptCategoryNameErrorMsg, setDeptCategoryNameErrorMsg] = useState('Please add a department category name.');
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [productCategoryNames, setProductCategoryNames] = useState<string[]>([]);
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState('');
    const [showProductCategoryNameErrorMsg, setShowProductCategoryNameErrorMsg] = useState(false);
    const [productCategoryNameErrorMsg, setProductCategoryNameErrorMsg] = useState('Please add a product category name.');
    const [productSubCategoryName, setProductSubCategoryName] = useState('');
    const [productSubCategoryToEditId, setProductSubCategoryToEditId] = useState(0);
    const [showProductSubCategoryNameSearch, setShowProductSubCategoryNameSearch] = useState(false);
    const [showProductSubCategoryKeywordSearch, setShowProductSubCategoryKeywordSearch] = useState(false);
    const [productSubCategoryThumbnailImages, setProductSubCategoryThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [productSubCategoryImages, setProductSubCategoryImages] = useState<File[]>([]);
    const [productCategorySearchFilterNames, setProductCategorySearchFilterNames] = useState<string[]>([]);
    const [productCategorySearchFilterNameSelected, setProductCategorySearchFilterNameSelected] = useState<string>('none');
    const [showProductCategorySearchFilterNameErrorMsg, setShowProductCategorySearchFilterNameErrorMsg] = useState(false);
    const [productCategorySearchFilterNameErrorMsg, setProductCategorySearchFilterNameErrorMsg] = useState('Please add a product category search filter name.');
    const [productCategorySearchTermNames, setProductCategorySearchTermNames] = useState<string[]>([]);
    const [productCategorySearchTermNameSelected, setProductCategorySearchTermNameSelected] = useState<string>('none');
    const [showProductCategorySearchTermNameErrorMsg, setShowProductCategorySearchTermNameErrorMsg] = useState(false);
    const [productCategorySearchTermNameErrorMsg, setProductCategorySearchTermNameErrorMsg] = useState('Please add a product category search term name.');

    const [editProductSubCategoryLoaded, setEditProductSubCategoryLoaded] = useState(false);

    //department select element reference
    const dSelectElementRef = useRef<{dSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //department category select element reference
    const dcSelectElementRef = useRef<{dcSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //product category select element reference
    const pcSelectElementRef = useRef<{pcSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //product subcategory search filter select element reference
    const pscsfSelectElementRef = useRef<{pscsfSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //product subcategory search term select element reference
    const pscstSelectElementRef = useRef<{pscstSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);

    const [siteIsLoaded, setSiteIsLoaded] = useState(false);
    const dnLabelRef = useRef<HTMLLabelElement>(null);
    const dcnLabelRef = useRef<HTMLLabelElement>(null);
    const pcnLabelRef = useRef<HTMLLabelElement>(null);
    const pcsfnLabelRef = useRef<HTMLLabelElement>(null);
    const pcstnLabelRef = useRef<HTMLLabelElement>(null);
    const pscnLabelRef = useRef<HTMLInputElement>(null);
    const nameSearchRef = useRef<HTMLInputElement>(null);
    const keywordSearchRef = useRef<HTMLInputElement>(null);
    const psciLabelRef = useRef<HTMLLabelElement>(null);
    const imageProductSubCategoryRef = useRef<HTMLInputElement>(null);
    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });
    const [dbError, setDBError] = useState<DBError>({
                                                        productSubCategoryNameError:{hasError:false, area:'none'},
                                                        imageErrors:[]
                                                    });

    const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);
    const dbErrorMsgRef = useRef<{dbemcRef:React.RefObject<HTMLDivElement>}>(null);

    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [genericInfoMessage, setGenericInfoMessage] = useState('No items found.');

    const [showProductSubCategoryNameLimitError, setShowProductSubCategoryNameLimitError] = useState(false);
    const [showProductSubCategoryFileLimitError, setShowProductSubCategoryFileLimitError] = useState(false);
    
    const [hasMatchingFilterTerm, setHasMatchingFilterTerm] = useState(false);
    const [showNoMatchingFilterError, setShowNoMatchingFilterError] = useState(false);
    const [showNoMatchingTermError, setShowNoMatchingTermError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if(showEditProductSubCategory){

            Promise.all([/*getDepartmentNamesPromise(),*/ getProductSubCategoryToEditPromise(productSubCategoryToEdit!)])
            .then(([/*departments,*/ editProductSubCategoryInfo])=>{
                //console.log('ds:', departments);
                //setupDepartmentInfo([...departments]);
                
                //console.log('editProductSubCategoryInfo:', editProductSubCategoryInfo);
                setupDepartmentInfo(editProductSubCategoryInfo.relatedDepartments!);
                setupDepartmentCategoryInfo(editProductSubCategoryInfo.relatedDepartmentCategories!);
                setupProductCategoryInfo(editProductSubCategoryInfo.relatedProductCategories!);
                setProductCategorySearchFilterNames(editProductSubCategoryInfo.relatedProductCategorySearchFilterNames!);
                setProductCategorySearchTermNames(editProductSubCategoryInfo.relatedProductCategorySearchTermNames!);

                //original version
                //setupProductSubCategoryEditInfo(editProductSubCategoryInfo.productSubCategoryToEdit!);
                setupProductSubCategoryEditInfo(editProductSubCategoryInfo.productSubCategoryToEdit!);

                setupProductSubCategoryImages(editProductSubCategoryInfo.productSubCategoryImages!);

            })
            .catch((err)=>console.error('promise all for names error:', err));

        } else {

            Promise.all([getDepartmentNamesPromise() /*getDepartmentCategoryNamesPromise(), getProductCategoryNamesPromise(),*/])
            .then(([departments, /*departmentCategories, productCategories,*/])=>{
                //console.log('ds:', departments);
                //console.log('dcs:', departmentCategories);
                //console.log('pcs:', productCategories);
                //console.log('sfgs:', searchFilterGroups);
                setupDepartmentInfo([...departments]);
                //setupDepartmentCategoryInfo([...departmentCategories]);
                //setupProductCategoryInfo([...productCategories]);

            })
            .catch((err)=>console.error('promise all for names error:', err));
        }
    },[]);

    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
        const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
        dbemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
        sfgemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
    }


    function getProductSubCategoryToEditPromise(psc:ProductSubCategory):Promise<EditProductSubCategoryInfo>{
        return fetch(`https://server.kando-proto-3.com/get-product-sub-category-to-edit-mariadb/${psc.productSubCategoryId}/${psc.productCategoryId}`, {
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({productSubCategoryToEdit, productSubCategoryImages, relatedDepartments, 
                        relatedDepartmentCategories, relatedProductCategories,
                        relatedProductCategorySearchFilterNames, relatedProductCategorySearchTermNames }:EditProductSubCategoryInfo)=>{
                            
                    const pscte = new ProductSubCategory({...productSubCategoryToEdit});
                    //console.log('pscte:', pscte);
                    
                    //console.log('pscis:', productSubCategoryImages);

                    //console.log('rdns:', relatedDepartments);
                    //console.log('rdcns:', relatedDepartmentCategories);
                    //console.log('rpcns:', relatedProductCategories);
                    //console.log('rpcsfns:', relatedProductCategorySearchFilterNames);
                    //console.log('rpcstns:', relatedProductCategorySearchTermNames);

                    return ({
                        productSubCategoryToEdit:pscte,
                        productSubCategoryImages:productSubCategoryImages,
                        relatedDepartments,
                        relatedDepartmentCategories,
                        relatedProductCategories,
                        relatedProductCategorySearchFilterNames,
                        relatedProductCategorySearchTermNames
                    });
                })
                .catch((err)=>{
                    console.error('fetch product sub category to edit error:', err.message);
                    return ({productSubCategoryToEditError:new Error(err.message)})
                });

    }

    function setupDepartmentInfo(ds:StoreDepartment[]):void {
        function addDepartments(ds:StoreDepartment[]):StoreDepartment[]{
            return ds.map((d)=>{
                return new StoreDepartment({...d});
            })
        }

        function addDepartmentNames(ds:StoreDepartment[]):string[]{
            return ds.map((d)=>{
                return d.departmentName;
            })
        }

        const uds = addDepartments(ds);
        setDepartments(uds);
        const dns = addDepartmentNames(uds);
        setDepartmentNames(dns);
    }

    function setupProductSubCategoryEditInfo(psc:ProductSubCategory):void {
        //console.log('test 6-7');
        setDepartmentNameSelected(psc.departmentName);
        setDepartmentCategoryNameSelected(psc.departmentCategoryName);
        setProductCategoryNameSelected(psc.productCategoryName);
        setProductCategorySearchFilterNameSelected(psc.productCategorySearchFilterName);
        setProductCategorySearchTermNameSelected(psc.productCategorySearchTermName);
        setProductSubCategoryName(psc.productSubCategoryName);
        setProductCategorySearchFilterNameSelected(psc.productCategorySearchFilterName);
        setProductCategorySearchTermNameSelected(psc.productCategorySearchTermName);
        setShowProductSubCategoryKeywordSearch(psc.showProductSubCategoryNameSearch);
        setShowProductSubCategoryNameSearch(psc.showProductSubCategoryKeywordSearch);
        setProductSubCategoryToEditId(psc.productSubCategoryId);
        setHasMatchingFilterTerm(psc.hasMatchingFilterTerm);
        setEditProductSubCategoryLoaded(true);
        //need to set form a file image
        //setProductSearchImages(product.productImage);

        //psc.showProductSubCategoryKeywordSearch ? keywordSearchRef.current!.checked = true : keywordSearchRef.current!.checked = false;
        //psc.showProductSubCategoryNameSearch ? nameSearchRef.current!.checked = true : nameSearchRef.current!.checked = false;
        


        //original version
        //window.setTimeout(()=>triggerStoreDepartmentChangeEvent(psc.departmentName), 100);
    
    }

    function handleDeptNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setDepartmentNameSelected(dns);
            setShowDeptNameErrorMsg(false);
            const found = departments.filter((d)=>{
                                        return d.departmentName === dns;
                                    });

            if(found.length === 0){
            throw new Error('no department found.');
            }

            const dId = found[0].departmentId;

            getRelatedCategories('Department', dns, dId);
        } else {
            setDepartmentNameSelected(dns);
            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');
            setProductCategoryNames([]);
            setProductCategoryNameSelected('none');
            setProductCategorySearchFilterNames([]);
            setProductCategorySearchFilterNameSelected('none');
            setProductCategorySearchTermNames([]);
            setProductCategorySearchTermNameSelected('none');
        }



        //if(!siteIsLoaded && showEditProductSubCategory){
            //test this logic after getting all the data first with selected values
            //then populate the form with options and selected values
            //window.setTimeout(()=>triggerDepartmentCategoryChangeEvent(departmentCategoryNameSelected),100);
        //} else {
            //reset product categories
            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');
            setProductCategoryNames([]);
            setProductCategoryNameSelected('none');
            setProductCategorySearchFilterNames([]);
            setProductCategorySearchFilterNameSelected('none');
            setProductCategorySearchTermNames([]);
            setProductCategorySearchTermNameSelected('none');

        //}

        
        
    }

    function handleDeptCategoryNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dcns:', (e.target as HTMLSelectElement).value);
        const dcns = (e.target as HTMLSelectElement).value;
        if(dcns !== 'none'){
            setDepartmentCategoryNameSelected(dcns);
            setShowDeptCategoryNameErrorMsg(false);
            const found = departmentCategories.filter((dc)=>{
                                    return dc.departmentCategoryName === dcns;
                                });

            if(found.length === 0){
                throw new Error('no department category found.');
            }

            const dcId = found[0].departmentCategoryId;

            getRelatedCategories('Department Category', dcns, dcId);
        } else {
            setDepartmentCategoryNameSelected(dcns);
            setProductCategoryNames([]);
            setProductCategoryNameSelected('none');
            setProductCategorySearchFilterNames([]);
            setProductCategorySearchFilterNameSelected('none');
            setProductCategorySearchTermNames([]);
            setProductCategorySearchTermNameSelected('none');
        }

        //setSiteIsLoaded(true);

        //need to create a variable to track if site has already loaded
        //this is for manually changing values after page has initially loaded
        setProductCategoryNames([]);
        setProductCategoryNameSelected('none');
        setProductCategorySearchFilterNames([]);
        setProductCategorySearchFilterNameSelected('none');
        setProductCategorySearchTermNames([]);
        setProductCategorySearchTermNameSelected('none');

    }

    function handleProductCategoryNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('pcns:', (e.target as HTMLSelectElement).value);
        const pcns = (e.target as HTMLSelectElement).value;
        if(pcns !== 'none'){
            setProductCategoryNameSelected(pcns);
            setShowProductCategoryNameErrorMsg(false);
            getRelatedCategories('Product Category', pcns);
        } else {
            setProductCategoryNameSelected(pcns);
            setProductCategorySearchFilterNames([]);
            setProductCategorySearchFilterNameSelected('none');
            setProductCategorySearchTermNames([]);
            setProductCategorySearchTermNameSelected('none');

        }

        //need to create a variable to track if site has already loaded
        //this is for manually changing values after page has initially loaded
        setProductCategorySearchFilterNames([]);
        setProductCategorySearchFilterNameSelected('none');
        setProductCategorySearchTermNames([]);
        setProductCategorySearchTermNameSelected('none');

    }

    function handleProductCategorySearchFilterNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('pcsfns:', (e.target as HTMLSelectElement).value);
        const pcsfns = (e.target as HTMLSelectElement).value;
        if(pcsfns !== 'none'){
            setProductCategorySearchFilterNameSelected(pcsfns);
            setShowProductCategorySearchFilterNameErrorMsg(false);
            setProductCategorySearchTermNameSelected('none');
            //getRelatedCategories('Product Category', pcsfns);
            getRelatedProductCategorySearchTerms('Product Category', productCategoryNameSelected, pcsfns);
        } else {
            setProductCategorySearchFilterNameSelected(pcsfns);
            setProductCategorySearchTermNameSelected('none');
            setProductCategorySearchTermNames([]);
        }
    }

    function handleProductCategorySearchTermNameSelection(e:React.ChangeEvent):void{
        e.stopPropagation();
        //console.log('pcstns:', (e.target as HTMLSelectElement).value);
        const pcstns = (e.target as HTMLSelectElement).value;
        if(pcstns !== 'none'){
            setProductCategorySearchTermNameSelected(pcstns);
            setShowProductCategorySearchTermNameErrorMsg(false);
            //getRelatedCategories('Product Category', pcstns);
        } else {
            setProductCategorySearchTermNameSelected(pcstns);

        }
    }

    function getRelatedCategories(sfat:string, sfa:string, sfaId?:number):void {

        if(sfat === 'Department'){
            //fetch related department categories
            relatedDepartmentCategories(sfa, sfaId as number);

        }

        if(sfat === 'Department Category'){
            //fetch related product categories
            relatedProductCategories(sfa, sfaId as number);
        }

        if(sfat === 'Product Category'){
            //fetch related search filters
            getRelatedProductCategorySearchFilters(sfat, sfa);
        }

    }

    function getRelatedProductCategorySearchFilters(sfat:string, sfa:string):void {
        //console.log('sfat:', sfat, 'sfa:', sfa);
        const params = {sfat, sfa};
        //previous version
        //fetch(`https://server.kando-proto-3.com/get-related-product-category-search-filters/${sfat}/${sfa}`, {
        fetch(`https://server.kando-proto-3.com/get-related-product-category-search-filters`, {
            //method:'GET',
            method:'POST',
            headers:{
                'content-type':'application/json',
                'accept':'application/json'
            },
            body:JSON.stringify(params)
        })
        .then((res)=>res.json())
        .then((res)=>{
            //console.log('response search filters 5-31:', res);
            if(res.length === 0){
                setProductCategorySearchTermNames([]);
                //show generic info message if no search filters are found
                setGenericInfoMessage(`No search filters have been added to the "${sfa}" ${sfat}.
                                        Please add some if you want to use the "${sfa}" ${sfat}.`);

                setProductCategorySearchFilterNames([]);
                setShowNoSearchResultsFound(true);

            } else {
                setProductCategorySearchFilterNames([...res]);
            }
            /*
            setProductCategorySearchFilterNames([...res]);
            if(res.length === 0){
                setProductCategorySearchTermNames([]);
            }
            */
        })
        .catch((err)=>console.error('related product category search filters error message:', err.message));
    }

    //implement this after installing search filters on line#299
    function getRelatedProductCategorySearchTerms(sfat:string, sfa:string, sfn:string):void {
        //console.log('sfat:', sfat, 'sfa:', sfa, 'sfn:', sfn);
        const params = {sfat, sfa, sfn};
        //previous version
        //fetch(`https://server.kando-proto-3.com/get-related-product-category-search-terms/${sfat}/${sfa}/${sfn}`, {
        fetch(`https://server.kando-proto-3.com/get-related-product-category-search-terms`, {
            //method:'GET',
            method:'POST',
            headers:{
                'content-type':'application/json',
                'accept':'application/json'
            },
            body:JSON.stringify(params)
        })
        .then((res)=>res.json())
        .then((res)=>{
            //console.log('response search terms 5-31:', res);
            if(res.length === 0){
                //show generic info message if no search terms are found
                setGenericInfoMessage(`No search terms have been added to the "${sfa}" ${sfat} search filters.
                                        Please add some if you want to use the "${sfa}" ${sfat} search filters.`);

                setProductCategorySearchTermNames([]);                        
                setShowNoSearchResultsFound(true);
            } else {
                setProductCategorySearchTermNames([...res]);
            }
        })
        .catch((err)=>console.error('related product category search terms error message:', err.message));
    }

    function setupDepartmentCategoryInfo(dcs:DepartmentCategory[]):void {
        function addDepartmentCategories(dcs:DepartmentCategory[]):DepartmentCategory[]{
            return dcs.map((dc)=>{
                return new DepartmentCategory({...dc});
            })
        }

        function addDepartmentCategoryNames(dcs:DepartmentCategory[]):string[]{
            return dcs.map((dc)=>{
                return dc.departmentCategoryName;
            })
        }

        const udcs = addDepartmentCategories(dcs);
        setDepartmentCategories(udcs);
        const dcns = addDepartmentCategoryNames(udcs);
        setDepartmentCategoryNames(dcns);

    }
    

    function relatedDepartmentCategories(
                                departmentName:string,
                                departmentId:number
                                ):Promise<DepartmentCategory[]> {

        return fetch(`https://server.kando-proto-3.com/get-related-department-categories/${departmentId.toString()}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((dcs:DepartmentCategory[])=>{
                //console.log('dcs 3-28:', dcs);
                if(dcs.length === 0){
                    //show generic info message if no product categories are found
                    setGenericInfoMessage(`No department categories have been added to the "${departmentName}" department.
                                            Please add some if you want to use the "${departmentName}" department.`);

                    setupDepartmentCategoryInfo([]);
                    setShowNoSearchResultsFound(true);
                } else {
                    setupDepartmentCategoryInfo(dcs);
                }
                
                return;
            })
            .catch((err)=>{
                console.error('err:', err.message)
                return err.message;
            });


    }

    function setupProductCategoryInfo(pcs:ProductCategory[]):void {
        function addProductCategories(pcs:ProductCategory[]):ProductCategory[]{
            return pcs.map((pc)=>{
                return new ProductCategory({...pc});
            })
        }

        function addProductCategoryNames(pcs:ProductCategory[]):string[]{
            return pcs.map((pc)=>{
                return pc.productCategoryName;
            })
        }

        const upcs = addProductCategories(pcs);
        setProductCategories(upcs);
        const pcns = addProductCategoryNames(upcs);
        setProductCategoryNames(pcns);
    }

    function relatedProductCategories(
                            departmentCategoryName:string,
                            departmentCategoryId:number
                            ):Promise<ProductCategory[]> {

        return fetch(`https://server.kando-proto-3.com/get-related-product-categories/${departmentCategoryId.toString()}`, {
                method:'GET',
                headers:{
                    'Accept':'application/json'
                    }
                })
                .then((res)=>{
                    return res.json();
                })
                .then((pcs:ProductCategory[])=>{
                    //console.log('pcs 3-28:', pcs);
                    if(pcs.length === 0){
                        //show generic info message if no product categories are found
                        setGenericInfoMessage(`No product categories have been added to the "${departmentCategoryName}" department category.
                                                Please add some if you want to use the "${departmentCategoryName}" department category.`);

                        setupProductCategoryInfo([]);
                        setShowNoSearchResultsFound(true);

                    } else {
                        setupProductCategoryInfo(pcs);
                        return;
                    }

                })
                .catch((err)=>{
                    console.error('err:', err.message)
                    return err.message;
                });
        


    }


    function setupProductSubCategoryImages(productSubCategoryImages:ProductSubCategoryImage[]):void {
        productSubCategoryImages.forEach((psci)=>{
            //console.log('psci:', psci);
            //add images by category
            const si = new Image();
            //previous version
            //si.src = `${process.env.PUBLIC_URL}${psci.path}`;
            si.src = `https://server.kando-proto-3.com/${psci.path}`;
            //original version
            //setProductSubCategoryThumbnailImages([{img:si, name:psci.path}]);
            setProductSubCategoryThumbnailImages([{img:si, name:getFileName(psci.path)}]);
            //console.log('psci.source s:', psci.source);
            const sbyteCharacters = window.atob(psci.source);
            const sbyteNumbers = new Array(sbyteCharacters.length);
            for (let i = 0; i < sbyteCharacters.length; i++) {
                sbyteNumbers[i] = sbyteCharacters.charCodeAt(i);
            }
            const sbyteArray = new Uint8Array(sbyteNumbers);
            const sBlob = new Blob([sbyteArray], {type:psci.type});
            const sFile = new File([sBlob], psci.path, {type:psci.type});
            //console.log('sFile:', sFile)
            //const sBlob = new Blob([pi.source], {type:pi.type});
            //const sFile = new File([sBlob], pi.path, {type:pi.type});
            setProductSubCategoryImages([sFile]);
        });


    }

    function getFileName(filePath:string):string {
        const lastForwardSlash = filePath.lastIndexOf('/');
        const fileName = filePath.slice(lastForwardSlash + 1);
        return fileName;
    }


    function triggerStoreDepartmentChangeEvent(dn:string):void {
        //console.log('dn:', dn);
        const change = new Event('change', {
            bubbles: true,
            cancelable: true
        });

        const selectRef = dSelectElementRef.current!.dSelectElementRef.current as HTMLSelectElement;
        selectRef.value = dn;
        selectRef.dispatchEvent(change);
    
    }

    function triggerDepartmentCategoryChangeEvent(dcn:string):void {
        //console.log('dcn:', dcn);
        const change = new Event('change', {
            bubbles: true,
            cancelable: true
        });

        const selectRef = dcSelectElementRef.current!.dcSelectElementRef.current as HTMLSelectElement;
        selectRef.value = dcn;
        selectRef.dispatchEvent(change);

    }

    function handleProductSubCategoryNameSearch(e:React.ChangeEvent):void {
        e.stopPropagation();
        const nameSearchChecked = (e.target as HTMLInputElement).checked;
        //console.log('checked:', nameSearchChecked);
        nameSearchChecked ? setShowProductSubCategoryNameSearch(true) : setShowProductSubCategoryNameSearch(false);
    }

    function handleProductSubCategoryKeywordSearch(e:React.ChangeEvent):void {
        e.stopPropagation();
        const keywordSearchChecked = (e.target as HTMLInputElement).checked;
        //console.log('checked:', keywordSearchChecked);
        keywordSearchChecked ? setShowProductSubCategoryKeywordSearch(true) : setShowProductSubCategoryKeywordSearch(false);

    }

    function imageClickHandler(e:React.MouseEvent):void {
        //console.log('e.target:', e.target);
        e.preventDefault();
        //e.stopPropagation();
        imageProductSubCategoryRef.current!.value = '';
        imageProductSubCategoryRef.current!.click();
    }

    function handleFileUpload(e:React.ChangeEvent):void {
        //console.log('file upload:', e.target, (e.target as HTMLInputElement).files);
        const target = e.target as HTMLInputElement;
        const fileList = (e.target as HTMLInputElement).files;
        const files = Array.from(fileList!);

        if(fileList![0].size / (1024 ** 2) <= 1.5){
            addThumbNailImage(files);
            setShowProductSubCategoryFileLimitError(false);           

        } else {
            setShowProductSubCategoryFileLimitError(true);
        }
        
    }

    function addThumbNailImage(files:File[]):void {
        let fileImgs:ThumbnailImage[] = [];

        files.forEach((f)=>{
            const img = new Image();
            img.src = URL.createObjectURL(f);
            //img.height = 40;
            //img.width = 40;
            //not using this function because I need a local copy so
            //the image is found when deleting from list
            //would revoke them in general if I did need to reference a local copy
            //before saving the image to the database
            /*
            img.onload = function(){
                URL.revokeObjectURL(img.src);
            }*/
            
            fileImgs.push({name:f.name, img:img})
        });

        setProductSubCategoryThumbnailImages([...fileImgs]);
        setProductSubCategoryImages([...files]);



                
    }




    function setupProductSubCategoryThumbnailImages(tis:ThumbnailImage[]):JSX.Element {
        //console.log('tis:', tis);
        if(tis.length === 0){
            return  <div className="product-image-search-list-container">No images selected</div>
        } else {
            return  <div className="product-image-search-list-container">
                        <ul className="product-image-search-list">
                            {tis.map((ti, k)=>{
                                return <li key={k} className="image-list-item">
                                            <img src={ti.img.src} className="add-product-sub-category-thumbnail-image"/>
                                            <span className="image-list-item-url-container">
                                                <span>{ti.name}</span>
                                                <span className="image-list-item-close-btn" onClick={(e:React.MouseEvent)=>removeImageFromList2(/*ti.img, ti.name*/)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </span>
                                            </span>
                                        </li>
                            })}
                        </ul>
                    </div>
        }

    }

    function removeImageFromList2():void {
        //remove all thumbnails
        setProductSubCategoryThumbnailImages([]);

        //remove all images
        setProductSubCategoryImages([]);
    }

    function removeImageFromList(img:HTMLImageElement, fileNameSelected:string):void {
        //console.log('img:', img, `/images/product-category/product-subcategory/${fileNameSelected}`);
        
        const imageToDelete = {filePath:`/images/product-category/product-subcategory/${fileNameSelected}`, directory:'product-category/product-subcategory'};

        fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({msg})=>{

                    const stis = productSubCategoryThumbnailImages.filter((i)=>{
                        return i.img !== img;
                    });

                    //original version
                    //setProductSubCategoryThumbnailImages(stis);
                    //remove all thumbnails
                    setProductSubCategoryThumbnailImages([]);

                    const sis = productSubCategoryImages.filter((f)=>{
                                    return f.name !== fileNameSelected;
                                });
                                

                    //original version
                    //setProductSubCategoryImages(sis);
                    //remove all images
                    setProductSubCategoryImages([]);


                    //console.log('product subCategory image removed from form:', msg);
                })
                .catch((err)=>console.error('remove product SubCategory image from public folder error:', err.message));

    }

    function removeImagefromDatabase(searchFilterAreaId:number, filePathDirectorySelected:string):Promise<Response> {
        //original version
        //const imageToDelete = {filePath:`${filePathSelected}`, directory:`${filePathSelectedDirectory}`};

        const imageToDelete = {searchFilterAreaId, filePathDirectorySelected};


        return fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    //body:JSON.stringify(imageToDelete),
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })

    }

    function getNextNumberId(oid:string):number {
        let id = window.parseInt(oid, 10);
        return ++id;
    }


    function handleFormSubmit(e:React.FormEvent, formAction:string):void {
        e.preventDefault();
        const form = e.target as HTMLFormElement;

        dbErrorMsgRef.current!.dbemcRef.current!.style.top = '-2000px';
        sfgErrorMsgRef.current!.sfgemcRef.current!.style.top = '-2000px';

        //rest form errors
        //dnLabelRef.current!.classList.remove('product-info-error');
        dSelectElementRef.current!.dSelectElementRef.current!.classList.remove('product-info-error');

        //dcnLabelRef.current!.classList.remove('product-info-error');
        dcSelectElementRef.current!.dcSelectElementRef.current!.classList.remove('product-info-error');

        //pcnLabelRef.current!.classList.remove('product-info-error');
        pcSelectElementRef.current!.pcSelectElementRef.current!.classList.remove('product-info-error');

        pscsfSelectElementRef.current!.pscsfSelectElementRef.current!.classList.remove('product-info-error');

        pscstSelectElementRef.current!.pscstSelectElementRef.current!.classList.remove('product-info-error');

        pscnLabelRef.current!.classList.remove('product-info-error');

        psciLabelRef.current!.classList.remove('image-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.imageErrors.length = 0;

        //product info
        //department name selected
        const dns = departmentNameSelected;
        if(dns.length === 0 || dns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Department Name'});
        }

        //department category name selected
        const dcns = departmentCategoryNameSelected;
        if(dcns.length === 0 || dcns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Department Category Name'});
        }

        //product category name selected
        const pcns = productCategoryNameSelected;
        if(pcns.length === 0 || pcns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Product Category Name'});
        }

        //product category search filter name selected
        const pcsfns = productCategorySearchFilterNameSelected;
        if(pcsfns.length === 0 || pcsfns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Product Category Search Filter Name'});
        }

        //check if product category search filter name is in list
        const sfsFound = productCategorySearchFilterNames.filter((pcsfn)=>{
                            return pcsfn === pcsfns;
                        });

        //console.log('pcsfns:', pcsfns);

        if(pcsfns.length > 0 && pcsfns !== 'none' && !hasMatchingFilterTerm && sfsFound.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Product Category Search Filter Name'});
            setShowNoMatchingFilterError(true);

        } else {
            setShowNoMatchingFilterError(false);
        }

        //product category search term name selected
        const pcstns = productCategorySearchTermNameSelected;
        if(pcstns.length === 0 || pcstns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Product Category Search Term Name'});
        }

        //check if product category search term name is in list
        const stsFound = productCategorySearchTermNames.filter((pcstn)=>{
            return pcstn === pcstns;
        });

        //console.log('pcsfns:', pcsfns, 'pcstns:', pcstns);

        if(pcstns.length > 0 && pcstns !== 'none' && !hasMatchingFilterTerm && stsFound.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Product Category Search Term Name'});
            setShowNoMatchingTermError(true);

        } else {
            setShowNoMatchingTermError(false);
        }


        //product subcategory name selected
        const pscn = productSubCategoryName;
        const psclns = formatLinkString(pscn);

        //console.log('pscn:', pscn);
        if(pscn.length === 0 || pscn === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Product Subcategory Name'});

        } else {
            setShowProductSubCategoryNameLimitError(false);
        }

        //images-groups
        //product subCategory image
        let pscImages = productSubCategoryImages;
        if(pscImages.length === 0 || pscImages.length > 1){
            setShowProductSubCategoryFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Product Subcategory Image'});

        } else {

            setShowProductSubCategoryFileLimitError(false);

            const fn = pscImages[0].name.toLowerCase();
            //const ufn = fn.replace(/[=?#+%^{}\[\]\\\/,<>"\*$@!|\(\)&~`]/gi, '');
            //const ufn = fn.replace(/[\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=]/gi, '');
            const ufn = fn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nfn = ufn.replace(/ /ig, '-');
    
            let blob = pscImages[0].slice(0, pscImages[0].size, pscImages[0].type);
            const newFile = new File([blob], nfn, {type: blob.type});
            //remove original unfiltered file
            pscImages.length = 0;
            pscImages.push(newFile);
        }

        //console.log('pscImages:', pscImages);


        const pies = formError.productInfoErrors.length > 0 ? true : false;
        const ies = formError.imageErrors.length > 0 ? true : false;

        if(pies || ies){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }


                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }


        setShowProcessingRequestMsg(true);


        if(formAction === 'addProductSubCategory'){

            let productSubCategoryImageName = productSubCategoryImages.length > 0 ? productSubCategoryImages[0].name : 'none';
                //productSubCategoryImageName = formatFilePathName(productSubCategoryImageName).toLowerCase();
                productSubCategoryImageName = productSubCategoryImageName.toLowerCase();

            const pscin = {
                //original version
                //productSubCategoryImageName:productSubCategoryImageName
                productSubCategoryImageName:productSubCategoryImageName
            };

            const urlParams = {productCategoryNameSelected,
                                productSubCategoryName,
                                productSubCategoryImageName
                                };

            const params = JSON.stringify(urlParams);


            //previous version
            //fetch(`http://localhost:9500/get-product-subcategory-info/${productCategoryNameSelected}/${productSubCategoryName}`, {
            fetch(`https://server.kando-proto-3.com/get-product-subcategory-info`, {
                method:'POST',
                //body:JSON.stringify(pscin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({productSubCategoryNameExists, productSubCategoryImageExists, lastProductSubCategoryId, productCategoryId})=>{
                    //console.log('pscne:', productSubCategoryNameExists);
                    //console.log('pscie:', productSubCategoryImageExists);
                    //console.log('lpId:', lastProductSubCategoryId);
                    //console.log('pcId:', productCategoryId, 'productCategoryNameSelected:', productCategoryNameSelected, 'productSubCategoryName:', productSubCategoryName);
                    

                    if(productSubCategoryNameExists || productSubCategoryImageExists){

                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.productSubCategoryNameError =  {hasError:false, area:'none'};


                        if(productSubCategoryImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Product Subcategory Image', fileName:productSubCategoryImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(productSubCategoryNameExists){
                            //console.log('true 6-15');
                            dbError.productSubCategoryNameError =  {hasError:true, area:productSubCategoryName};
                        }

                        dbError.productSubCategoryNameError = (dbError.productSubCategoryNameError.hasError) 
                                                    ? dbError.productSubCategoryNameError 
                                                    : {hasError:false, area:'none'};

                                                    //console.log('db 6-15:', dbError);

                        const de = {...dbError};
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
            
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;


                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }

            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        //const pcId = window.parseInt(productCategoryId, 10);
                        const nextProductSubCategoryId = getNextNumberId(lastProductSubCategoryId);
                        
                        //create new product filled with form data fields
                        const productSubCategory = new ProductSubCategory({
                                                productSubCategoryId:nextProductSubCategoryId,
                                                productSubCategoryName:pscn,
                                                productSubCategoryLinkName:psclns,
                                                productSubCategoryImage:pscin.productSubCategoryImageName,
                                                productCategoryId:productCategoryId,
                                                departmentName:dns,
                                                departmentCategoryName:dcns,
                                                productCategoryName:pcns,
                                                productCategorySearchFilterName:productCategorySearchFilterNameSelected,
                                                productCategorySearchTermName:productCategorySearchTermNameSelected,
                                                showProductSubCategoryKeywordSearch:showProductSubCategoryKeywordSearch,
                                                showProductSubCategoryNameSearch:showProductSubCategoryNameSearch
                                            }); 

                        const fd = new FormData();
                        //add product info
                        fd.append('product-info', JSON.stringify(productSubCategory));

                        //add product subCategory image
                        fd.append('product-subcategory-image', productSubCategoryImages[0]);

                        //console.log('psc:', productSubCategory, productSubCategoryImages[0]);
                        
                        //return;

                        setIsLoading(true);

                        fetch(`https://server.kando-proto-3.com/add-individual-product-subCategory`, {
                            method:'POST',
                            body:fd
                        })
                        .then((res)=>{
                            return res.json();
                        })
                        .then((res)=>{
                            //console.log('add individual product subCategory response:', res);



                            //close form update product list
                            handleCloseForm('addProductSubCategory');
                        })
                        .catch((err)=>console.error('add individual product subCategory fetch error:', err.message));

                    }

                })
                .catch((err)=>{
                    console.error('product search err:', err)
                });
                
                
        } else {

            let productSubCategoryImageName = productSubCategoryImages.length > 0 ? productSubCategoryImages[0].name : 'none';
                //productSubCategoryImageName = formatFilePathName(productSubCategoryImageName).toLowerCase();
                productSubCategoryImageName = productSubCategoryImageName.toLowerCase();
            //console.log('result 6-7', productSubCategoryImageName);

            const pscin = {
                //original version
                //productSubCategoryImageName:productSubCategoryImageName
                productSubCategoryImageName:productSubCategoryImageName
            };

            //console.log('test24:', productSubCategoryToEditId, productSubCategoryName);

            const urlParams = {productCategoryNameSelected, 
                                productSubCategoryNameSelected:productSubCategoryName, 
                                productSubCategoryToEditId,
                                productSubCategoryImageName
                            };

            const params = JSON.stringify(urlParams);

            //previous version
            //fetch(`http://localhost:9500/check-product-subcategory-exists/${productCategoryNameSelected}/${productSubCategoryName}/${productSubCategoryToEditId}`, {
            fetch(`https://server.kando-proto-3.com/check-product-subcategory-exists`, {
                method:'POST',
                //body:JSON.stringify(pscin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({productSubCategoryNameExists, productSubCategoryImageExists, productSubCategoryId, productCategoryId})=>{
                    //console.log('productSubCategoryNameExists:', productSubCategoryNameExists);
                    //console.log('productSubCategoryImageExists:', productSubCategoryImageExists);
                    //console.log('productCategoryId:', productCategoryId);
                    //console.log('productSubCategoryId:', productSubCategoryId);
                    if(productSubCategoryNameExists || productSubCategoryImageExists){

                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.productSubCategoryNameError =  {hasError:false, area:'none'};

                        if(productSubCategoryImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Product Subcategory Image', fileName:productSubCategoryImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(productSubCategoryNameExists){
                            dbError.productSubCategoryNameError =  {hasError:true, area:productSubCategoryName};
                        }

                        dbError.productSubCategoryNameError = (dbError.productSubCategoryNameError!.hasError) 
                                                    ? dbError.productSubCategoryNameError 
                                                    : {hasError:false, area:'none'};


                        const de = {...dbError};
                        
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
        
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;


                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }

            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        removeImagefromDatabase(productSubCategoryId, 'product-subcategory')
                        .then((res)=>res.json())
                        .then(({msg})=>{
                            //console.log('msg 6-26:', msg);
                            
                            if(msg === 'product-subcategory image removed'){

                                //create new product subcategory filled with form data fields
                                const productSubCategory = new ProductSubCategory({
                                    productSubCategoryId:productSubCategoryId,
                                    productSubCategoryName:pscn,
                                    productSubCategoryLinkName:psclns,
                                    productSubCategoryImage:pscin.productSubCategoryImageName,
                                    productCategoryId:productCategoryId,
                                    departmentName:dns,
                                    departmentCategoryName:dcns,
                                    productCategoryName:pcns,
                                    productCategorySearchFilterName:pcsfns,
                                    productCategorySearchTermName:pcstns,
                                    showProductSubCategoryKeywordSearch:showProductSubCategoryKeywordSearch,
                                    showProductSubCategoryNameSearch:showProductSubCategoryNameSearch
                                });               


                                const fd = new FormData();
                                //add product info
                                fd.append('product-info', JSON.stringify(productSubCategory));

                                //add product search image
                                fd.append('product-subcategory-image', productSubCategoryImages[0]);

                                //console.log('product 6-7:', productSubCategory);
                                //console.log('product-subCategory-image 6-7:', productSubCategoryImages[0]);

                                setIsLoading(true);
                                    
                                //update product in database
                                fetch(`https://server.kando-proto-3.com/update-individual-product-subcategory`, {
                                    method:'PUT',
                                    body:fd
                                    })
                                    .then((res)=>{
                                        return res.json();
                                    })
                                    .then((res)=>{

                                        handleCloseForm('editProductSubCategory');
                                    })
                                    .catch((err)=>console.error('update individual product subCategory fetch error:', err.message));

                            } else {
                                throw new Error('file was not removed.');
                            }

                        });


                    }
                    




                })
                .catch((err)=>console.error('check product SubCategory exists fetch error:', err.message));



        }

    }

    function formatFilePathName(filePathName:string):string {
        return filePathName.search(/\//i) === -1 ? `/images/product-category/product-subcategory/${filePathName}` : filePathName;
    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Department Name':
                                //dnLabelRef.current!.classList.add('product-info-error');
                                dSelectElementRef.current!.dSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Department Category Name':
                                //dcnLabelRef.current!.classList.add('product-info-error');
                                dcSelectElementRef.current!.dcSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Category Name':
                                //pcnLabelRef.current!.classList.add('product-info-error');
                                pcSelectElementRef.current!.pcSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Category Search Filter Name':
                                //pcsfnLabelRef.current!.classList.add('product-info-error');
                                pscsfSelectElementRef.current!.pscsfSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Category Search Term Name':
                                //pcstnLabelRef.current!.classList.add('product-info-error');
                                pscstSelectElementRef.current!.pscstSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Subcategory Name':
                                pscnLabelRef.current!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no product info errors found!');
                        }
                    }
                });


        //set image errors
        formError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Product Subcategory Image'){
                //console.log('ie.area:', ie.area);
                psciLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });
    }


    function addDBFormLabelErrors(dbError:DBError): void {
        //set product info errors
        if(dbError.productSubCategoryNameError!.hasError){
            pscnLabelRef.current!.classList.add('product-info-error');
        }
        
        //set image errors
        dbError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Product Subcategory Image'){
                //console.log('ie.area:', ie.area);
                psciLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });

    }


    function handleProductSubCategoryName(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        //console.log('value.length:', value.length);

        if(value.length <= 20){
            setProductSubCategoryName(value);
            setShowProductSubCategoryNameLimitError(false);

        } else {
            setShowProductSubCategoryNameLimitError(true);
        }
        
    }

    function setupFormAction(showEditProductSubCategory:boolean):string {
        return (showEditProductSubCategory) 
                ? 'updateProductSubCategory'
                : 'addProductSubCategory'
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowNoSearchResultsFound(false);
    }


    if(isLoading){
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>
    } else

    if(!showEditProductSubCategory || (showEditProductSubCategory && editProductSubCategoryLoaded)){
        return <div>
                    {
                        (showEditProductSubCategory)
                        ? <h1 className="add-product-sub-category-heading">Edit Product Subcategory</h1>
                        : <h1 className="add-product-sub-category-heading">Add Product Subcategory</h1>
                    }
                    <form ref={formRef} className="add-dpt-category-form" onSubmit={(e:React.FormEvent)=>handleFormSubmit(e, setupFormAction(showEditProductSubCategory))}>
                    <div className="add-product-input-form-group">
                            <label ref={dnLabelRef} htmlFor="dpt-name">Department Name</label>
                            <StoreDepartmentSelectControl ref={dSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={departmentNameSelected}
                                    optionNames={departmentNames}
                                    showErrorMsg={showDeptNameErrorMsg}
                                    errorMsg={deptNameErrorMsg}
                                    selectId={"dpt-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={dcnLabelRef} htmlFor="dpt-category-name">Department Category Name</label>
                            <DepartmentCategorySelectControl ref={dcSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={departmentCategoryNameSelected}
                                    optionNames={departmentCategoryNames}
                                    showErrorMsg={showDeptCategoryNameErrorMsg}
                                    errorMsg={deptCategoryNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptCategoryNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={pcnLabelRef} htmlFor="product-category-name">Product Category Name</label>
                            <ProductCategorySelectControl ref={pcSelectElementRef}
                                    defaultValue={'none'}
                                    optionNameSelected={productCategoryNameSelected}
                                    optionNames={productCategoryNames}
                                    showErrorMsg={showProductCategoryNameErrorMsg}
                                    errorMsg={productCategoryNameErrorMsg}
                                    selectId={"product-category-name"}
                                    handleOptionNameSelection={handleProductCategoryNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={pcsfnLabelRef} htmlFor="product-subcategory-search-filter">Product Category Search Filter Name</label>
                            <ProductSubCategorySearchFilterSelectControl ref={pscsfSelectElementRef}
                                    defaultValue={'none'}
                                    optionNameSelected={productCategorySearchFilterNameSelected}
                                    optionNames={productCategorySearchFilterNames}
                                    showErrorMsg={showProductCategorySearchFilterNameErrorMsg}
                                    errorMsg={productCategorySearchFilterNameErrorMsg}
                                    selectId={"product-subcategory-search-filter"}
                                    handleOptionNameSelection={handleProductCategorySearchFilterNameSelection}
                            />
                            
                            {
                                showNoMatchingFilterError
                                ?
                                <p className="exceed-character-limit-error">{`Product subcategory was removed from its previous search filter.  Select another search filter.`}</p>
                                :
                                null
                            }
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={pcstnLabelRef} htmlFor="product-subcategory-search-term">Product Category Search Term Name</label>
                            <ProductSubCategorySearchTermSelectControl ref={pscstSelectElementRef}
                                    defaultValue={'none'}
                                    optionNameSelected={productCategorySearchTermNameSelected}
                                    optionNames={productCategorySearchTermNames}
                                    showErrorMsg={showProductCategorySearchTermNameErrorMsg}
                                    errorMsg={productCategorySearchTermNameErrorMsg}
                                    selectId={"product-subcategory-search-term"}
                                    handleOptionNameSelection={handleProductCategorySearchTermNameSelection}
                            />
                        
                            {
                                showNoMatchingTermError
                                ?
                                <p className="exceed-character-limit-error">{`Product subcategory was removed from its previous search term.  Select another search term.`}</p>
                                :
                                null
                            }
                        </div>
                        <div className="add-product-sub-category-input-form-group">
                            <label htmlFor="product-sub-category-name">Product Subcategory Name</label>
                            <input ref={pscnLabelRef} name="product-sub-category-name" type="text" id="product-sub-category-name"
                                className="form-input-control" value={productSubCategoryName} onChange={handleProductSubCategoryName}/>
                            {
                                showProductSubCategoryNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (20)</p>
                                :
                                null
                            }


                        </div>
                        <div className="add-product-sub-category-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={psciLabelRef} htmlFor="product-image-search" onClick={imageClickHandler}><span className="product-image-search-label-link">Select Images <span className="no-color-label-link">(product subcategory)</span></span></label>
                                </div>
                                {
                                    showProductSubCategoryFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageProductSubCategoryRef} id="product-image-search" className="product-image-search" name="product-image-search" type="file" onChange={handleFileUpload}/>
                                {setupProductSubCategoryThumbnailImages(productSubCategoryThumbnailImages)}

                            </div>

                        {/*}    
                        <div className="add-product-sub-category-checkbox-form-group">
                            <input className="admin-checkbox-control" ref={nameSearchRef} type="checkbox" checked={showProductSubCategoryNameSearch} name="show-product-sub-category-name-search" 
                                id="show-product-sub-category-name-search" onChange={handleProductSubCategoryNameSearch}/>
                            <label htmlFor="show-product-sub-category-name-search">Show Name Search</label>
                        </div>
                        <div className="add-product-sub-category-checkbox-form-group">
                            <input className="admin-checkbox-control" ref={keywordSearchRef} type="checkbox" checked={showProductSubCategoryKeywordSearch} name="show-product-sub-category-keyword-search" 
                                id="show-product-sub-category-keyword-search" onChange={handleProductSubCategoryKeywordSearch}/>
                            <label htmlFor="show-product-sub-category-keyword-search">Show Keyword Search</label>
                        </div>*/}

                        {
                            (showEditProductSubCategory)
                            ?   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-save-btn" type="submit" value="Save"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('editProductSubCategory')} value="Cancel"/>

                                    {
                                        (showProcessingRequestMsg && !showNoSearchResultsFound)
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            :   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-add-btn" type="submit" value="Add"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('addProductSubCategory', true)} value="Cancel"/>

                                    {
                                        (showProcessingRequestMsg && !showNoSearchResultsFound)
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                   
                        }
                        {
                            showNoSearchResultsFound 
                            ? (<div className="add-product-sub-category-form-info-message-container">
                                <GenericInfoMessage infoMessage={genericInfoMessage} 
                                    showCloseBtn={true} showExtraMsgContainer={true} closeInfoMsgHandler={closeInfoMsgHandler}/>
                                </div>)
                            : null
                        }

                        <DBErrorMessage ref={dbErrorMsgRef} dbError={dbError}/>
                        <FormErrorMessage ref={sfgErrorMsgRef} formError={formError} />

                    </form>
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    {<LoaderAnimation/>}
                </div>
    }
}


export default AddProductSubCategory;