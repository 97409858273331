import React, {useState, useEffect} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SearchFilter, {SearchTerm} from './searchFilter';
import isSearchTermUnique from './helper-add-search-term-db';
import DbResponse from './db-response';
import StoreStructureLoadingMsg from './StoreStructureLoadingMsg';
import StoreStructureAlert from './StoreStructureAlert';
import GenericInfoMessage from './GenericInfoMessage';
import LoaderAnimation from './LoaderAnimation';
import './AddSearchTerm.css';

interface Props {
    showAddSearchTerm:boolean;
    /*showDepartment:boolean;
    showDepartmentCategory:boolean;
    showProductCategory:boolean;*/
    /*deptNameSelected:string;
    deptCategoryNameSelected:string;
    productCategoryNameSelected:string;*/
    searchFilterNameSelected:string;
    searchTermNameSelected:string;
    searchTermSelected:boolean;
    showSearchTerm:boolean;
    showSearchTermImg:boolean;
    searchTermImgSelected:string;
    searchFilterAreaNameSelected:string;
    searchFilterAreaTypeSelected:string;
    searchFilterSelected:boolean;
    showSearchFilter:boolean;
    handleBackToSearchFilter:(
                searchTermNameSelected:string,
                searchTermSelected:boolean,
                showSearchTerm:boolean,
                showSearchTermImg:boolean,
                searchTermImgSelected:string
                            )=>void;
    handleCloseAddSearchTerm:(e:React.MouseEvent)=>void;
}

const AddSearchTerm: React.FunctionComponent<Props> = (props:Props) => {
    const {showAddSearchTerm, handleBackToSearchFilter, handleCloseAddSearchTerm } = props;

    /*const [deptNameSelected, setDeptNameSelected] = useState(props.deptNameSelected);
    const [deptCategoryNameSelected, setDeptCategoryNameSelected] = useState(props.deptCategoryNameSelected);
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState(props.productCategoryNameSelected);*/
    /*const [showDepartment, setShowDepartment] = useState(props.showDepartment);
    const [showDepartmentCategory, setShowDepartmentCategory] = useState(props.showDepartmentCategory);
    const [showProductCategory, setShowProductCategory] = useState(props.showProductCategory);*/
    const [searchFilterAreaNameSelected, setSearchFilterAreaNameSelected] = useState(props.searchFilterAreaNameSelected);
    const [searchFilterAreaTypeSelected, setSearchFilterAreaTypeSelected] = useState(props.searchFilterAreaTypeSelected);
    const [searchFilterNameSelected, setSearchFilterNameSelected] = useState(props.searchFilterNameSelected);
    const [searchTermNameSelected, setSearchTermNameSelected] = useState(props.searchTermNameSelected);
    const [searchTermSelected, setSearchTermSelected] = useState(props.searchTermSelected);
    const [showSearchTerm, setShowSearchTerm] = useState(props.showSearchTerm);
    const [showSearchTermImg, setShowSearchTermImg] = useState(props.showSearchTermImg);
    const [searchTermImgSelected, setSearchTermImgSelected] = useState(props.searchTermImgSelected);
    //const [showSaveSearchFilterSearchTermBtn, setShowSaveSearchFilterSearchTermBtn] = useState(true);
    //const [showDoneAddingSearchTermBtn, setShowDoneAddingSearchTermBtn] = useState(false);
    const [showBackToSearchFilterBtn, setShowBackToSearchFilterBtn] = useState(true);
    const [showSaveSearchFilterSearchTermBtn, setShowSaveSearchFilterSearchTermBtn] = useState(true);
    const [showSaveSearchTermBtn, setShowSaveSearchTermBtn] = useState(false);
    const [searchFilterSearchTermAdded, setSearchFilterSearchTermAdded] = useState(false);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('no msg');
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [showGenericInfoMessage, setShowGenericInfoMessage] = useState(false);
    const [genericeInfoMessage, setGenericInfoMessage] = useState('Search term was saved');
    const [storeStructureLoadingMsg, setStoreStructureLoadingMsg] = useState('saving search term');
    const [showStoreStructureLoadingMsg, setShowStoreStructureLoadingMsg] = useState(false);
    //search filter
    //const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [searchFilterSelected, setSearchFilterSelected] = useState(props.searchFilterSelected);
    const [showSearchFilter, setShowSearchFilter] = useState(props.showSearchFilter);
    const [searchTermNameErrorMsg, setSearchTermNameErrorMsg] = useState('Please add a search term name.');
    const [showSearchTermNameErrorMsg, setShowSearchTermNameErrorMsg] = useState(false);
    const [searchFilterAddedDb, setSearchFilterAddedDb] = useState(false);

    const [showSearchTermNameLimitError, setShowSearchTermNameLimitError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    //console.log('sfats:', searchFilterAreaTypeSelected);
    /*console.log('d:', showDepartment);
    console.log('dc:', showDepartmentCategory);
    console.log('pc:', showProductCategory);*/
    useEffect(()=>{
        //get deptNames
        //console.log('useEFfec()');
    },[]);

    /*
    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        //setShowStoreStructureAlert(false);
    }
    */

    function closeGenericInfoMessageHandler(e:React.MouseEvent):void {
        setShowProcessingRequestMsg(false);
        setShowGenericInfoMessage(false);
    }

    function handleSaveSearchFilterSearchTermForm(e:React.FormEvent):void {
        //console.log('searchFilterSearchTermAdded:', searchFilterSearchTermAdded);
        //console.log('e.currentTarget:', e.currentTarget);
        //console.log('e.target:', e);
        //console.log('submit search filter search term form');
        //console.log('stns:', searchTermNameSelected);
        e.preventDefault();
        //console.log('st saved');

        if(searchTermNameSelected.length === 0){
            /*
            setStoreStructureAlertMsg('need to add a search term name');
            setShowStoreStructureAlert(true);
            setSearchTermNameErrorMsg('search-term-name-error-msg');

            setTimeout(()=>{
                setStoreStructureAlertMsg('no msg');
                setShowStoreStructureAlert(false);
            },2000);
            */
           setShowSearchTermNameErrorMsg(true);
           setSearchTermNameErrorMsg('Please add a search term name')

            return;

        }

        
        setShowSearchTermNameLimitError(false);

        setShowGenericInfoMessage(false);
        setShowProcessingRequestMsg(true);



        const sfToAdd = new SearchFilter({
                            searchFilterArea:searchFilterAreaNameSelected,
                            searchFilterAreaType:searchFilterAreaTypeSelected,
                            filterName:searchFilterNameSelected,
                            showFilter:showSearchFilter,
                            searchFilterSelected:searchFilterSelected
                        });     

        const stToAdd = new SearchTerm({
                        searchFilterArea:searchFilterAreaNameSelected,
                        searchFilterAreaType:searchFilterAreaTypeSelected,
                        searchFilterName:searchFilterNameSelected,
                        searchTermName:searchTermNameSelected,
                        searchTermSelected:searchTermSelected,
                        showSearchTerm:showSearchTerm,    
                        showSearchTermImg:showSearchTermImg,
                        searchTermImg:searchTermImgSelected
                    });


        //console.log('sfToAdd:', sfToAdd);
        //console.log('stToAdd:', stToAdd);
        isSearchTermUnique(stToAdd)
        .then(({dbResponseMsg}:DbResponse)=>{
            //console.log('dbrm:', dbResponseMsg);
            if(dbResponseMsg === 'record found'){
                //show alert that search term already exits

                setShowProcessingRequestMsg(false);

                setShowSearchTermNameErrorMsg(true);
                setSearchTermNameErrorMsg('Search term name already exists');
                /*
                setSearchTermNameErrorMsg('');
                setStoreStructureAlertMsg('search term already exists');
                setShowStoreStructureAlert(true);

                setTimeout(()=>{
                    setStoreStructureAlertMsg('no msg');
                    setShowStoreStructureAlert(false);
                },2000);
                */

            } else {

                //check if search filter is added to database
                if(!searchFilterAddedDb){
                    const searchFilter = JSON.stringify(sfToAdd);
                    //setSearchTermNameErrorMsg('');
                    //setShowStoreStructureLoadingMsg(true);
                    
                    //previous version
                    //fetch(`http://localhost:9500/add-search-filter-only/${searchFilter}`,{
                    fetch(`https://server.kando-proto-3.com/add-search-filter-only`,{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json',
                            'Accept':'application/json'
                        },
                        body:searchFilter
                    })
                    .then(resp=>resp.json())
                    .then(({dbResponseRecords}:DbResponse)=>{
                        //console.log('dbrr:', dbResponseRecords);
                        //setShowStoreStructureLoadingMsg(false);

                        setShowProcessingRequestMsg(false);
    
                        setSearchFilterAddedDb(true);
                        setSearchTermNameSelected('');
                        setSearchTermSelected(false);
                        setShowSearchTerm(false);
                        setShowSearchTermImg(false);
                        setSearchTermImgSelected('');
    
                        setShowBackToSearchFilterBtn(false);
                        setShowSaveSearchFilterSearchTermBtn(false);
                        setShowSaveSearchTermBtn(true);
                        setSearchFilterSearchTermAdded(true);
                        //setShowStoreStructureLoadingMsg(false);
    
                        if(searchFilterSearchTermAdded){
                            //setStoreStructureAlertMsg('search term added');
                            //setShowStoreStructureAlert(true);
                            setGenericInfoMessage(`Search term "${searchTermNameSelected}" was added.`);
                            setShowGenericInfoMessage(true);
            
                            /*
                            setTimeout(()=>{
                                //setStoreStructureAlertMsg('no msg');
                                setGenericInfoMessage('');
                                //setShowStoreStructureAlert(false);
                                setShowGenericInfoMessage(false);
                            },5000);
                            */
    
                        } else {
                            //setStoreStructureAlertMsg('search filter and search term added');
                            //setShowStoreStructureAlert(true);
                            setGenericInfoMessage(`Search filter "${searchFilterNameSelected}" and search term "${searchTermNameSelected}" were added.`);
                            setShowGenericInfoMessage(true);
            
                            /*
                            setTimeout(()=>{
                                //setStoreStructureAlertMsg('no msg');
                                setGenericInfoMessage('');
                                //setShowStoreStructureAlert(false);
                                setShowGenericInfoMessage(false);
                            },5000);
                            */
                        }                    
    
                    })
                    .catch((err)=>console.error('add search term fetch error:', err));

                } else {
                    //setShowStoreStructureLoadingMsg(true);
                    const urlParams = {searchFilterAreaTypeSelected,
                                    searchFilterAreaNameSelected,
                                    searchFilterNameSelected
                                    };
                    
                    const params = JSON.stringify(urlParams);

                    //previous version
                    //fetch(`http://localhost:9500/update-search-filter-search_term-qty-only/${searchFilterAreaTypeSelected}/${searchFilterAreaNameSelected}/${searchFilterNameSelected}`,{
                    fetch(`https://server.kando-proto-3.com/update-search-filter-search_term-qty-only`,{
                        method:'PUT',
                        headers:{
                            'Content-Type':'application/json',
                            'Accept':'application/json'
                        },
                        body:params
                    })
                    .then(resp=>resp.json())
                    .then(({dbResponseMsg}:DbResponse)=>{
                        //console.log('dbrm:', dbResponseMsg);

                        setShowProcessingRequestMsg(false);

                        //setShowStoreStructureLoadingMsg(false);
                        if(dbResponseMsg === 'record updated'){
                            setSearchTermNameSelected('');
                            setSearchTermSelected(false);
                            setShowSearchTerm(false);
                            setShowSearchTermImg(false);
                            setSearchTermImgSelected('');
        
                            //setShowBackToSearchFilterBtn(false);
                            //setShowSaveSearchFilterSearchTermBtn(false);
                            //setShowSaveSearchTermBtn(true);
                            //setSearchFilterSearchTermAdded(true);
                            //setShowStoreStructureLoadingMsg(false);
        
                            if(searchFilterSearchTermAdded){
                                //setStoreStructureAlertMsg('search term added');
                                setGenericInfoMessage(`Search term "${searchTermNameSelected}" was added.`);
                                //setShowStoreStructureAlert(true);
                                setShowGenericInfoMessage(true);
                
                                //setTimeout(()=>{
                                    //setStoreStructureAlertMsg('no msg');
                                    //setShowStoreStructureAlert(false);
                                    //setGenericInfoMessage('');
                                    //setShowGenericInfoMessage(false);
                                //},5000);
        
                            } else {
                                //setStoreStructureAlertMsg('search filter and search term added');
                                //setShowStoreStructureAlert(true);
                                setGenericInfoMessage(`Search filter "${searchFilterNameSelected}" and search terms "${searchTermNameSelected}" were added.`);
                                setShowGenericInfoMessage(true);
                
                                //setTimeout(()=>{
                                    //setStoreStructureAlertMsg('no msg');
                                    //setShowStoreStructureAlert(false);
                                    //setGenericInfoMessage('');
                                    //setShowGenericInfoMessage(false);
                                //},5000);
                            }
    
                        } else {
                            throw new Error('search term recored was not updated.');
                        }                   
    
                    })
                    .catch((err)=>console.error('add search term fetch error:', err));
                }


                
            }


            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

        })
        .catch((err)=>console.error('is search term unique fetch error:', err));

    }

    function handleAddSearchTermName(e:React.ChangeEvent):void {
        const searchTermName:string = (e.target as HTMLInputElement).value;

        /*previous version
        if(searchTermName === ''){
            setShowSearchTermNameErrorMsg(true);
            setSearchTermNameErrorMsg('Please add a search term name');
        } else {
            setShowSearchTermNameErrorMsg(false);
        }
        setSearchTermNameSelected(searchTermName);
        */

        //console.log('value.length:', searchTermName.length);
            
        if(searchTermName.length === 0){
            setShowSearchTermNameLimitError(false);
            setShowSearchTermNameErrorMsg(true);
            setSearchTermNameErrorMsg('Please add a search term name');
            setSearchTermNameSelected(searchTermName);

        } else if(searchTermName.length > 0 && searchTermName.length <= 20){
            setShowSearchTermNameErrorMsg(false);
            setShowSearchTermNameLimitError(false);
            setSearchTermNameSelected(searchTermName);

        } else {
            setShowSearchTermNameErrorMsg(false);
            setShowSearchTermNameLimitError(true);
        }

    }

    function handleAddSearchTermImg(e:React.ChangeEvent):void {
        const stImg = (e.target as HTMLInputElement).value;
        const stFileName = stImg.slice(stImg.lastIndexOf('\\') + 1);
        //console.log('stfn:', stFileName);
        //console.log('search term img:', (e.target as HTMLInputElement).value);
        setSearchTermImgSelected(stFileName);
    }

    function handleSearchTermSelected(e:React.ChangeEvent):void {
        setSearchTermSelected((e.target as HTMLInputElement).checked);
    }

    function handleShowSearchTerm(e:React.ChangeEvent):void {
        setShowSearchTerm((e.target as HTMLInputElement).checked);
    }

    function handleShowSearchTermImg(e:React.ChangeEvent):void {
        setShowSearchTermImg((e.target as HTMLInputElement).checked);
    }

    function setupFilterAreaTypeLabel(searchFilterAreaTypeSelected:string):string {
        switch(searchFilterAreaTypeSelected){
            case 'Department':
                return 'Department Name';
            case 'Department Category':
                return 'Department Category Name';
            case 'Product Category':
                return 'Product Category Name';
            default:
                throw new Error('no search area filter type name found.');
        }
    }

    if(showAddSearchTerm){
        return <div className="add-search-term-container">
                    {/*<StoreStructureLoadingMsg
                        showStoreStructureLoadingMsg={showStoreStructureLoadingMsg}
                        storeStructureLoadingMsg={storeStructureLoadingMsg}
                    />*/}
                    {/*<StoreStructureAlert 
                        showStoreStructureAlert={showStoreStructureAlert}
                        storeStructureAlertMsg={storeStructureAlertMsg}
                        handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />*/}
                    {
                        showGenericInfoMessage
                        ? 
                        <div className="ast-generic-info-message-wrapper">
                            <GenericInfoMessage infoMessage={genericeInfoMessage} showCloseBtn={true}
                                showExtraMsgContainer={true} closeInfoMsgHandler={closeGenericInfoMessageHandler} />
                        </div>
                        : 
                        null
                    }
                    
                    <h5 className="add-search-term-heading">Add Search Term</h5>
                    <form className="add-dpt-category-form" onSubmit={handleSaveSearchFilterSearchTermForm}>
                        <div className="add-search-term-input-form-group">
                            <label htmlFor="dept-name">{setupFilterAreaTypeLabel(searchFilterAreaTypeSelected)}</label>
                            <input type="text" name="dept-name" id="dept-name"
                             className="form-input-control search-term-disable-control" 
                                value={searchFilterAreaNameSelected} readOnly={true}/>
                        </div>
                        <div className="add-search-term-input-form-group">
                            <label htmlFor="search-filter-name">Search Filter Name</label>
                            <input type="text" name="search-filter-name"
                                id="search-filter-name"
                                className="form-input-control search-term-disable-control" 
                                value={searchFilterNameSelected} readOnly={true}/>
                        </div>
                        <div className="add-search-term-input-form-group">
                            <label htmlFor="search-term-name">Search Term Name</label>
                            <input type="text" name="search-term-name" id="search-term-name"
                             className={showSearchTermNameErrorMsg ? 'form-input-control show-add-search-term-name-input-error-border' : 'form-input-control hide-add-search-term-name-input-error-border'}
                             value={searchTermNameSelected} onChange={handleAddSearchTermName}/>
                            {
                                showSearchTermNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (20)</p>
                                :
                                null
                            }

                            {
                                showSearchTermNameErrorMsg 
                                ? <div className="show-add-search-term-name-error-msg">
                                        {searchTermNameErrorMsg}
                                    </div>
                                : 
                                null
                            }
                        </div>
                        {/*<div className="add-search-term-file-form-group">
                            <label htmlFor="search-term-img">Search Term Image</label>
                            <input type="file" name="search-term-img-name" onChange={handleAddSearchTermImg}/>
                        </div>*/}
                        {/*<div className="add-search-term-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="search-term-selected" id="search-term-selected"
                              checked={searchTermSelected} onChange={handleSearchTermSelected}/>
                            <label htmlFor="search-term-selected">Search Term Selected</label>
                        </div>*/}
                        <div className="add-search-term-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="show-search-term" id="show-search-term"
                              checked={showSearchTerm} onChange={handleShowSearchTerm}/>
                            <label htmlFor="show-search-term">Show Search Term</label>
                        </div>
                        {/*<div className="add-search-term-checkbox-form-group">
                            <input type="checkbox" name="show-search-term-img" id="show-search-term-img"
                              checked={showSearchTermImg} onChange={handleShowSearchTermImg}/>
                            <label htmlFor="show-search-term-img">Show Search Term Image</label>
                        </div>*/}
                        <div className="product-form-submit-btn-container">
                            {   
                                showBackToSearchFilterBtn ?
                                <button type="button" 
                                    className="add-search-term-back-btn"
                                    onClick={(e:React.MouseEvent)=>{
                                                    handleBackToSearchFilter(
                                                                    searchTermNameSelected,
                                                                    searchTermSelected,
                                                                    showSearchTerm,
                                                                    showSearchTermImg,
                                                                    searchTermImgSelected
                                                                    );
                                                    }}>
                                    Back
                                </button>
                                :null
                            }

                            { 
                                showSaveSearchFilterSearchTermBtn ?
                                <button type="submit" className="add-search-term-save-btn">
                                    Save Filter and Term
                                </button>
                                : null
                            }

                            {
                                showSaveSearchTermBtn ?
                                <button type="submit" className="add-search-term-save-btn">
                                   Save Search Term
                                </button>
                                : null
                            }

                            {
                                showSaveSearchTermBtn ?
                                <button type="button" className="add-search-term-close-btn"
                                onClick={handleCloseAddSearchTerm}>
                                    Done
                                </button>
                                : null
                            }

                            {
                                (showProcessingRequestMsg && !showGenericInfoMessage)
                                ?
                                <div className="process-request-msg">
                                    Processing...
                                </div>
                                :
                                null
                            }

                        </div>
                    </form>
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    <LoaderAnimation/>
                </div>;
    }
}


export default AddSearchTerm;