import React, { useState, useEffect, useRef } from 'react';
import {connect} from 'react-redux';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import './ProductSearch.css';
import KeywordList from './KeywordList';
//import {fromEvent} from 'rxjs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import StoreSearch from './StoreSearch';
import StoreLocation from './storeLocation';
import StoreSearchParams from './storeSearchParams';
import ChangeStoreSearch from './ChangeStoreSearch';
//import {addStoreSearchParams, AddStoreSearchParams} from './redux/actions';
import {selectStoreLocation} from './redux/features/storeLocation/storeLocationSlice';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {selectMobileNav, showMobileNav} from './redux/features/mobileNav/mobileNavSlice';
import {selectAdminLoginDropdownMobile, showAdminLoginDropdownMobile} from './redux/features/adminLoginDropdownMobile/adminLoginDropdownMobileSlice';
import {selectHighlightBackground, addHighlightBackground} from './redux/features/highlightBackground/highlightBackgroundSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import handleGetCustomerLocation from './helper-get-customer-location';
import KeywordSearch from './KeywordSearch';
import KeywordSearchModal from './KeywordSearchModal';
import ShoppingCart from './shoppingCart';
import ShowTotalProductsAdded from './ShowTotalProductsAdded';

interface Props {
    //storeLocation?:StoreLocation;
    //storeSearchParams?:StoreSearchParams;
    //addStoreSearchParams?:(storeSearchParams:StoreSearchParams)=>AddStoreSearchParams;
    //shoppingCart?:ShoppingCart;
}

/*
interface State {
    storeLocation:StoreLocation;
    storeSearchParams:StoreSearchParams;
    shoppingCart:ShoppingCart;
}

function mapStateToProps(state:State) {
    const { storeLocation, storeSearchParams, shoppingCart } = state;
    console.log('sl:', storeLocation, 'ssp:', storeSearchParams, 'sc:', shoppingCart);
    return {
        storeLocation,
        storeSearchParams,
        shoppingCart
    };

}

const mapDispatchToProps = {
    addStoreSearchParams
};
*/

const ProductSearch:React.FunctionComponent<Props> = (props:Props) => {
    //my store name
    //const {storeLocation, storeSearchParams, addStoreSearchParams} = props;

    //redux objects
    const storeLocation = useAppSelector(selectStoreLocation);
    const shoppingCart = useAppSelector(selectShoppingCart);
    const mobileNav = useAppSelector(selectMobileNav);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    //console.log('storeSearchParams:', storeSearchParams);
    //console.log('addStoreSearchParams:', addStoreSearchParams);
    //console.log('shoppingCart:', props.shoppingCart);
    //console.log('userAgent:', navigator.userAgent);
    const keywordSearchContainer:any = React.createRef<HTMLDivElement>();
    const textInput:React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    const [searchContainerShape, setSearchContainerShape] = useState('search-container-shape');
    const [btnCloseShape, setBtnCloseShape] = useState('btn-close-shape-remove');
    const [btnSearchShape, setBtnSearchShape] = useState('btn-search-shape');
    const [keywords, setKeywords] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [keywordSearchTerm, setKeywordSearchTerm] = useState('');
    const [showKeywords, setShowKeywords] = useState(false);
    const [keywordSearchContainerHeight, setKeywordSearchContainerHeight] = useState(0);
    const [keywordSearchContainerWidth, setKeywordSearchContainerWidth] = useState(0);
    //const [myStoreName, setMyStoreName] = useState((storeLocation as StoreLocation).storeName);
    const [myStoreName, setMyStoreName] = useState('My Store');
    const [changeStoreLocation, setChangeStoreLocation] = useState<StoreLocation | undefined>();
    //test show search container
    //const [showStoreSearch, setShowStoreSearch] = useState('hide-store-search');
    const [showStoreSearch, setShowStoreSearch] = useState(false);
    //const [showChangeStoreSearch, setShowChangeStoreSearch] = useState('hide-change-store-search');
    const [showChangeStoreSearch, setShowChangeStoreSearch] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [findStoreLink, setFindStoreLink] = useState('Find a Store');
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [totalProductsAdded, setTotalProductsAdded] = useState(0);

    const [showKeywordSearchModal, setShowKeywordSearchModal] = useState(false);

    const portrait = useRef<MediaQueryList[]>([]);

    
    /*
   useEffect(()=>{
        //console.log('height:', textInputContainer.current.getBoundingClientRect().bottom);
        //setKeywordListHeight(textInputContainer.current.getBoundingClientRect().bottom);
        setKeywordSearchContainerWidth(keywordSearchContainer.current.getBoundingClientRect().width);
    },[showKeywords]);
    */

   
    useEffect(()=>{
        setTotalOrderAmount(getTotalOrderAmount(shoppingCart/*props.shoppingCart*/ as ShoppingCart));
        const tpa = getTotalProductsAdded(shoppingCart/*props.shoppingCart*/ as ShoppingCart);
        setTotalProductsAdded(tpa);
        //previous version
        //setTotalProductsAdded(getTotalProductsAdded(shoppingCart/*props.shoppingCart*/ as ShoppingCart));
    },[shoppingCart/*props.shoppingCart*/]);


    useEffect(()=>{
        //document.addEventListener('touchstart', productSearchTouchHandler);
        document.addEventListener('click', productSearchClickHandler);

        return cleanupProductSearchClickHandler;
    },[]);

    function cleanupProductSearchClickHandler():void {
        //document.removeEventListener('touchstart', productSearchTouchHandler);
        document.removeEventListener('click', productSearchClickHandler);
    }

    function productSearchClickHandler(e:MouseEvent):void {
        //console.log('e.type8:', e.target);
        //e.stopPropagation();
        //e.stopImmediatePropagation();
        const clickedMobileNavMenuBackgroundShadeContainer = (e.target as HTMLElement).classList.contains('background-shade-container-mobile');

        if(clickedMobileNavMenuBackgroundShadeContainer){
            dispatch(showMobileNav({showMobileNav:false, linkSelected:false}));
            dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:false, linkSelected:false}));
        }

    
    }

    useEffect(()=>{
        if(window.innerWidth >= 480){
            setShowKeywordSearchModal(false);
            document.body.classList.remove('adj-mobile-search');
        } 

        window.addEventListener('resize', productSearchResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", productSearchResizeHandler);

        return cleanupResizeHandler;

    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', productSearchResizeHandler);

        portrait.current[0].removeEventListener("change", productSearchResizeHandler);
    }

    function productSearchResizeHandler(e:Event):void {
        if(window.innerWidth >= 480){
            setShowKeywordSearchModal(false);

            document.body.classList.remove('adj-mobile-search');
        }

    }

    function productSearchMobileSearchBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        //console.log('sksm:');
        setShowKeywordSearchModal(true);
        
        document.body.classList.add('adj-mobile-search');

    }

    //set myStoreName
    /*
    useEffect(()=>{
        console.log('test product Search Effect()');
        console.log('myStoreName:', myStoreName, storeLocation);
        if(storeLocation !== null){
            setMyStoreName((storeLocation as StoreLocation).storeName);
            setFindStoreLink('Change Store');
            setChangeStoreLocation(storeLocation as StoreLocation);
        } 

    },[storeLocation]);
    */
    
    /*
    useEffect(()=>{
        fromEvent(document, 'click')
            .subscribe({
              next(e){
                  console.log('document clicked:', e);
                  setShowKeywords(false);
              }
            });
    },[]);
    */

    function getTotalOrderAmount(shoppingCart:ShoppingCart):number {
        let totalOrderAmount:number = 0;
        shoppingCart.shoppingCartCategories.forEach((shoppingCartCategory)=>{
            shoppingCartCategory.shoppingCartProducts.forEach((shoppingCartProduct)=>{
                totalOrderAmount += shoppingCartProduct.totalPrice;
            });
        });

        return totalOrderAmount;
    }

    function getTotalProductsAdded(shoppingCart:ShoppingCart):number {
        let totalProductsAdded:number = 0;
        shoppingCart.shoppingCartCategories.forEach((shoppingCartCategory)=>{
            shoppingCartCategory.shoppingCartProducts.forEach((shoppingCartProduct)=>{
                if(shoppingCartProduct.weightBy === 'pound' && shoppingCartProduct.shoppingCartQuantity !== 0){
                    totalProductsAdded += 1;
                } else {
                    totalProductsAdded += shoppingCartProduct.shoppingCartQuantity as number;
                }
                
            });
        });

        return totalProductsAdded;
    }
    
    function mouseEnterStoreSearch(e:React.MouseEvent):void {
        //console.log('mouseenter');
        if(timeoutId !== null){
            clearTimeout(timeoutId);
        }
        if(findStoreLink === 'Find a Store'){
            setShowStoreSearch(true);
        } else {
            setShowChangeStoreSearch(true);
        }
        
    }

    function mouseLeaveStoreSearch(e:React.MouseEvent):void {
        //console.log('mouseleave');
        let timeoutId = setTimeout(()=>{
                    if(findStoreLink === 'Find a Store'){
                        setShowStoreSearch(false);
                    } else {
                        setShowChangeStoreSearch(false);
                    }
                    
                },1000);

        setTimeoutId(timeoutId);
        
    }

    function handleCloseStoreSearch():void {
        //console.log('handleCloseStoreSearch productSearch');
        setShowStoreSearch(false);
    } 
    
    function handleCloseChangeStoreSearch():void {
        setShowChangeStoreSearch(false);
    }


    /*
    function keywordSearchHandler(e:React.ChangeEvent<HTMLInputElement>):void {
        console.log('input value:', e.target.value);
        //add close button
        setBtnCloseShape('btn-close-shape');
        setKeyword(e.target.value);
        if(/(!|@|%|\^|\$|#|\?|_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&)/.test(e.target.value)){
            setShowKeywords(false);
        } else if(e.target.value.length > 0){
                //get matching keywords
                getMatchingKeywords(e.target.value);

    
        } else if(e.target.value === ''){
                setShowKeywords(false);
                setBtnCloseShape('btn-close-shape-remove');
        }
    }

    function submitFormHandler(e:React.FormEvent):void {
        e.preventDefault();
        console.log('form submit');
        //show search button clicked
        setBtnSearchShape('btn-search-shape-clicked');
        let productName = keyword;
        let restrictedChars = productName.match(/(!|@|%|\^|\$|#|\?|_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&)/gi);
        console.log('restrictedChars:', restrictedChars);
        if(restrictedChars !== null){
            let productNameChars = productName.split('');
            productName = '';
            for(let i=0; i < productNameChars.length; i++){
                for(let h=0; h < restrictedChars.length; h++){
                    if(hasRestrictedChars(productNameChars, i, restrictedChars, h, 0)){
                        break;
                    } else {
                        productName += productNameChars[i];
                        break;
                    }
                }
            } 
            //console.log('newProductName:', newWord);
            console.log('newProductName adjusted:', productName);
            setKeyword(productName);
            navigate(`/products/${productName}`);
            //get products from database and post results

        } else {
            //get products from database and post results
            console.log('newProductName:', productName);
            setKeyword(productName);
            navigate(`/products/${productName}`);
        }

        
    }

    function hasRestrictedChars(productNameChars:string[], productNameCharsIndex:number, restrictedChars:string[], restrictedCharsIndex:number, position:number):boolean {
        if(position === restrictedChars.length){
            //console.log('done');
            return false;
        }
        if(productNameChars[productNameCharsIndex] === restrictedChars[restrictedCharsIndex]){
            //console.log('item match');
            return true; 
        } else {
            //console.log('no match');
            return hasRestrictedChars(productNameChars, productNameCharsIndex, restrictedChars, ++restrictedCharsIndex, ++position);
        }
    
    }

    function getMatchingKeywords(keyword:string):void {
        setKeywordSearchTerm(keyword);
        //get matching keywords
        fetch(`http://localhost:9500/search/${keyword}`, {
            method:'get',
            headers:{
                'accept':'application/json'
            }
        })
        .then(r => r.json())
        .then(keywords => {
            console.log('response:', keywords);

            //remove previous keywords
            //orignal version
            //setKeywords([]);
            if(!showKeywords){
                console.log('showKeywords:', showKeywords);
                setShowKeywords(true);
            } 

            console.log('keyword test');
            //add new keywords to list
            setKeywords(JSON.parse(keywords));

            

            
    
        })
        .catch(e => console.log('error:', e));    
    }

    function handleCloseBtn(e:React.MouseEvent<HTMLDivElement>):void {
        setBtnCloseShape('btn-close-shape-remove');
        setBtnSearchShape('btn-search-shape');
        setKeyword('');
        (textInput.current as HTMLInputElement).focus();
    }

    function handleInputFocus(e:React.FocusEvent):void {
        console.log('focus');
        setSearchContainerShape('search-container-shape-focus');
    }

    function handleInputBlur(e:React.FocusEvent):void {
        setSearchContainerShape('search-container-shape');
    }
    */

    //original version
    /*
    //finding customer location
    function handleGetCustomerLocation(e:React.MouseEvent):void {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showCustomerPosition, showCustomerPositionError);
          } else {
            console.log("Geolocation is not supported by this browser.");
          }
    }

    function showCustomerPosition(position:Position):void {
        console.log('customer position:', position.coords);
        const {latitude, longitude} = position.coords;
        //set customer search parameters
        (storeSearchParams as StoreSearchParams).lat = latitude;
        (storeSearchParams as StoreSearchParams).lng = longitude;
        (storeSearchParams as StoreSearchParams).currentLocationRequest = true;
        (storeSearchParams as StoreSearchParams).storeSearch = 'current location';
        (storeSearchParams as StoreSearchParams).showStoreLocator = false;
        (addStoreSearchParams as (storeSearchParams:StoreSearchParams)=>AddStoreSearchParams)(storeSearchParams as StoreSearchParams);
        //original version
        //navigate(`/store-locator/${customerLocation.latitude},${customerLocation.longitude}`);
        navigate('/store-locator');

    }

    function showCustomerPositionError(positionError:PositionError):void {
        //assign default location
        switch(positionError.code) {
            case 1:
                console.log("User denied the request for Geolocation.");
                navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
                break;
            case 2:
                console.log("Location information is unavailable.");
                navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
                break;
            case 3:
                console.log("The request to get user location timed out.");
                navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
              break;
            default:
                console.log("An unknown error occurred.");
                navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
                break;
          }
        
        //log positionError message
        console.log('positionError message:', positionError.message);
    }
    */

    function handleCurbsideBtnClick(e:React.MouseEvent):void {
        navigate('/curbside-delivery');
    }

    function handleDeliveryBtnClick(e:React.MouseEvent):void {
        navigate('/curbside-delivery');
    }

    function clickHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        dispatch(showMobileNav({showMobileNav:true, linkSelected:false}));
    }

    function formatTotalOrderAmount(toa:number):string {

        if(toa >= 1000 && toa < 10000){
            let amt = toa.toFixed(2).split('');
            amt.splice(1,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 10000 && toa < 100000){
            let amt = toa.toFixed(2).split('');
            amt.splice(2,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 100000 && toa < 1000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(3,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 1000000 && toa < 10000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(4,0, ',');
            amt.splice(1,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 10000000 && toa < 100000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(5,0, ',');
            amt.splice(2,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 100000000 && toa < 1000000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(6,0, ',');
            amt.splice(3,0, ',');
            return `$${amt.join('')}`;

        } else {
            return `$${toa.toFixed(2)}`;
        }


    }

    function shoppingCartBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        navigate('/cart');
    }


    function hideMobileDropdownMenu(e:React.MouseEvent):void {
        //console.log('helper:');
        dispatch(showMobileNav({showMobileNav:false, linkSelected:false}));
        dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:false, linkSelected:false}));
        dispatch(addHighlightBackground({highlightBackground:false}));
    }



    return (
            <div className="product-search-wrapper">
                <div className="product-search product-search-grid">
                    <button type="button" aria-label="mobile menu" className="product-search-mobile-menu-btn" onClick={clickHandler}>
                        <span className="product-search-mobile-menu-svg-container">
                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.448 0.448 0 1 0H17C17.552 0 18 0.448 18 1C18 1.552 17.552 2 17 2H1C0.448 2 0 1.552 0 1ZM0 7C0 6.448 0.448 6 1 6H17C17.552 6 18 6.448 18 7C18 7.552 17.552 8 17 8H1C0.448 8 0 7.552 0 7ZM1 12C0.448 12 0 12.448 0 13C0 13.552 0.448 14 1 14H17C17.552 14 18 13.552 18 13C18 12.448 17.552 12 17 12H1Z" fill="white"></path></svg>
                        </span>
                    </button>
                    <div className="product-search-logo-container">
                        <Link to="/" className="product-search-logo-link" onClick={hideMobileDropdownMenu}>
                            <div className="product-search-logo">
                                {'shop'.toUpperCase()}
                            </div>
                        </Link>
                    </div>

                    <div className="keyword-search-position-container">
                        <div className="keyword-search-wrapper">
                            <KeywordSearch showKeywordSearch={true} showKeywordSearchModal={showKeywordSearchModal}/>
                        </div>
                        { 
                            showKeywordSearchModal 
                            ? 
                            <KeywordSearchModal setShowKeywordSearchModal={setShowKeywordSearchModal}/> 
                            : null
                        }
                        <div className="curbside-delivery-container">
                            <div className="curbside-btn"
                                onClick={handleCurbsideBtnClick}>
                                <div>
                                    <i className="fa fa-circle curbside-circle" aria-hidden="true">
                                        <i className="fa fa-car" aria-hidden="true"></i>
                                    </i>
                                </div>
                                <div className="">Curbside</div>
                                <div className="">
                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="delivery-btn"
                                onClick={handleDeliveryBtnClick}>
                                <div>
                                    <i className="fa fa-circle delivery-circle" aria-hidden="true">
                                        <i className="fa fa-home" aria-hidden="true"></i>
                                    </i>
                                </div>
                                <div className="">Delivery</div>
                                <div className="">
                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="shopping-cart-btn-container">
                            <button type="button" aria-label="mobile search" className="product-search-mobile-search-btn"
                                onClick={productSearchMobileSearchBtnHandler}>
                                <span className="product-search-mobile-search-btn-svg-container">
                                    <svg className="product-search-mobile-search-btn-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" ><path fill="#fff" fillRule="evenodd" d="M12 7c0 2.761-2.239 5-5 5S2 9.761 2 7s2.239-5 5-5 5 2.239 5 5zm-.808 5.606C10.024 13.482 8.572 14 7 14c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7c0 1.572-.518 3.024-1.394 4.192l3.101 3.1c.39.391.39 1.025 0 1.415-.39.39-1.024.39-1.414 0l-3.1-3.1z" clipRule="evenodd"></path></svg>
                                </span>
                            </button>
                            <div className="shopping-cart-btn" onClick={shoppingCartBtnHandler}>
                                {
                                    totalProductsAdded > 0
                                    ?
                                    <ShowTotalProductsAdded totalProductsAdded={totalProductsAdded}/>
                                    : null
                                }
                                {/*<ShowTotalProductsAdded totalProductsAdded={999999}/>*/}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 6H3.307L5.93936 13.0196L5.05132 15.6838C4.83547 16.3313 5.31744 17 6 17H19C19.5523 17 20 16.5523 20 16C20 15.4477 19.5523 15 19 15H7.38743L7.74016 13.9418L19.083 12.9965C19.5272 12.9595 19.8932 12.6331 19.9806 12.1961L20.9806 7.19612C21.1043 6.57732 20.631 6 20 6H5.443L4.93633 4.64888C4.78997 4.25857 4.41684 4 4 4H2C1.44772 4 1 4.44772 1 5C1 5.55228 1.44772 6 2 6ZM18 22C19.1046 22 20 21.1046 20 20C20 18.8954 19.1046 18 18 18C16.8954 18 16 18.8954 16 20C16 21.1046 16.8954 22 18 22ZM9 20C9 21.1046 8.10457 22 7 22C5.89543 22 5 21.1046 5 20C5 18.8954 5.89543 18 7 18C8.10457 18 9 18.8954 9 20Z" fill="white"></path></svg>
                                {
                                    totalOrderAmount > 0
                                    ?
                                    <div className="shopping-cart-btn-total-order-amount">{formatTotalOrderAmount(totalOrderAmount)}</div>
                                    : null
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

    );
}

export default ProductSearch;
/*original version using connect()
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductSearch);
*/