import React from 'react';

export interface SearchTermFormErrorMessage {
            searchTermNameSelected?:string;
            searchFilterNameSelected:string;
            deptNameSelected:string;
            deptCategoryNameSelected:string;
            productCategoryNameSelected:string;
            isSearchFilterAreaNameSelected:(
                deptNameSelected:string,
                deptCategoryNameSelected:string,
                productCategoryNameSelected:string
                            )=>boolean;
            //setStoreStructureAlertMsg:(value: React.SetStateAction<string>)=>void;
            //setShowStoreStructureAlert:(value: React.SetStateAction<boolean>)=>void;
            setShowSearchFilterAreaNameErrorMsg:(value: React.SetStateAction<boolean>)=>void;
            setSearchFilterNameErrorMsg?:(value: React.SetStateAction<string>)=>void;
            setShowSearchFilterNameErrorMsg?:(value: React.SetStateAction<boolean>)=>void;
            setSearchTermNameErrorMsg?:(value: React.SetStateAction<string>)=>void;
            setShowSearchTermNameErrorMsg?:(value: React.SetStateAction<boolean>)=>void;
            setShowSearchTermNameLimitError?:(value: React.SetStateAction<boolean>)=>void;
}

function showSearchTermFormErrorMsgs(options:SearchTermFormErrorMessage):boolean {
    const {searchTermNameSelected, searchFilterNameSelected, deptNameSelected, 
          deptCategoryNameSelected, productCategoryNameSelected, isSearchFilterAreaNameSelected, 
          setShowSearchFilterAreaNameErrorMsg, setShowSearchFilterNameErrorMsg, 
          setSearchTermNameErrorMsg, setShowSearchTermNameErrorMsg, setShowSearchTermNameLimitError} = options;
            
    const searchFilterAreaNameSelected = isSearchFilterAreaNameSelected(
                                                            deptNameSelected,
                                                            deptCategoryNameSelected,
                                                            productCategoryNameSelected
                                                                        );   
        //console.log('sfns:', searchFilterNameSelected);
        //console.log('stns:', searchTermNameSelected);
        //console.log('sfans', searchFilterAreaNameSelected);
        //initialy reset all error filters
        /*
        setShowStoreStructureAlert(false);
        setShowSearchFilterAreaNameErrorMsg(false);
        setSearchFilterNameErrorMsg!('');
        setSearchTermNameErrorMsg!('');
        */
        //1
    if(searchFilterNameSelected === 'none' && searchTermNameSelected!.length === 0 && !searchFilterAreaNameSelected){
             //console.log('passed1');
             //console.log('sfns:', searchFilterNameSelected);
             //console.log('stns:', searchTermNameSelected);

        //setStoreStructureAlertMsg('add a search term name, add a search filter name and select a search filter area');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(true);
        setShowSearchFilterNameErrorMsg!(true);
        setShowSearchTermNameErrorMsg!(true);
        setSearchTermNameErrorMsg!('Please add a search term name');


        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //2
    } else if(searchFilterNameSelected !== 'none' && searchTermNameSelected!.length === 0 && !searchFilterAreaNameSelected){
                    //console.log('passed2');
        //setStoreStructureAlertMsg('select search filter area and add search term name');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(true);
        setShowSearchFilterNameErrorMsg!(false);
        setShowSearchTermNameErrorMsg!(true);
        setSearchTermNameErrorMsg!('Please add a search term name');

        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //3
    } else if(searchFilterNameSelected === 'none' && searchTermNameSelected!.length > 0 && !searchFilterAreaNameSelected){
                    //console.log('passed3');
        //setStoreStructureAlertMsg('select search filter area and add search filter name');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(true);
        setShowSearchFilterNameErrorMsg!(true);
        setShowSearchTermNameErrorMsg!(false);
        setShowSearchTermNameLimitError!(false);
        
        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //4
    } else if(searchFilterNameSelected === 'none' && searchTermNameSelected!.length === 0 && searchFilterAreaNameSelected){
                //console.log('passed4');
        //setStoreStructureAlertMsg('add search filter name and add search term name');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(false);
        setShowSearchFilterNameErrorMsg!(true);
        setShowSearchTermNameErrorMsg!(true);
        setSearchTermNameErrorMsg!('Please add a search term name');

        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //5
    } else if(searchFilterNameSelected !== 'none' && searchTermNameSelected!.length > 0 && !searchFilterAreaNameSelected){
                //console.log('passed5');
        //setStoreStructureAlertMsg('select search filter area');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(true);
        setShowSearchFilterNameErrorMsg!(false);
        setShowSearchTermNameErrorMsg!(false);
        setShowSearchTermNameLimitError!(false);
        //setSearchTermNameErrorMsg!('');

        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //6
    } else if(searchFilterNameSelected !== 'none' && searchTermNameSelected!.length === 0 && searchFilterAreaNameSelected){
                //console.log('passed6');
        //setStoreStructureAlertMsg('add search term name');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(false);
        setShowSearchFilterNameErrorMsg!(false);
        setShowSearchTermNameErrorMsg!(true);
        setSearchTermNameErrorMsg!('Please add a search term name');


        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //7
    } else if(searchFilterNameSelected === 'none' && searchTermNameSelected!.length > 0 && searchFilterAreaNameSelected){
                //console.log('passed7');
        //setStoreStructureAlertMsg('add search filter name');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(false);
        setShowSearchFilterNameErrorMsg!(true);
        setShowSearchTermNameErrorMsg!(false);
        setShowSearchTermNameLimitError!(false);
        //setSearchTermNameErrorMsg!('');

        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;

    } else {
        setShowSearchTermNameLimitError!(false);
        return false;
    }
}

export default showSearchTermFormErrorMsgs;