import React from 'react';
import {SearchFilterArea} from './relatedProduct';

interface Props {
    searchFilterArea:SearchFilterArea;
    searchFilterAreaSelected:SearchFilterArea;
}

const HighlightSearchFilterAreaText:React.FunctionComponent<Props> = (props:Props)=>{
    const {searchFilterArea:sfa, searchFilterAreaSelected:sfas} = props;
    //console.log('sfah:', sfa.searchFilterAreaName, 'sfas:', sfas, 'h:', sfas.highlightText, 'equal:', sfa.searchFilterAreaName === sfas.searchFilterAreaName);
    //console.log('ht:', sfas.highlightText);

    if((sfa.searchFilterAreaName === sfas.searchFilterAreaName) && sfas.highlightText){
        return <div className="highlight-search-filter-area-text">{sfa.searchFilterAreaName}</div>;

    } else {
        return <div className="no-highlight-search-filter-area-text">{sfa.searchFilterAreaName}</div>;

    }

}

export default HighlightSearchFilterAreaText;