class HomePageLayoutItem {
    public homePageLayoutId:number;
    public selectedForRemoval:boolean;
    public selectedForApproval:boolean;
    public reloaded:boolean;

    constructor(options:{
        homePageLayoutId?:number,
        selectedForRemoval?:boolean,
        selectedForApproval?:boolean,
        reloaded?:boolean
    }={}){
        this.homePageLayoutId = options.homePageLayoutId || 0;
        this.selectedForRemoval = options.selectedForRemoval || false;
        this.selectedForApproval = options.selectedForApproval || false;
        this.reloaded = options.reloaded || false;
    }
}  


export default HomePageLayoutItem;