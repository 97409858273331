class SearchFilterTerm {
    public searchFilterTermId:number;
    public productId:number;
    public searchFilterTermName:string;
    public searchFilterTermValue:string;
    public searchFilterArea:string;
    public searchFilterAreaType:string;
    constructor(options:{
        searchFilterTermId?:number,
        productId?:number,
        searchFilterTermName?:string,
        searchFilterTermValue?:string,
        searchFilterArea?:string,
        searchFilterAreaType?:string
    }={}){
        this.searchFilterTermId = options.searchFilterTermId || 0;
        this.productId = options.productId || 0;
        this.searchFilterTermName = options.searchFilterTermName || '';
        this.searchFilterTermValue = options.searchFilterTermValue || '';
        this.searchFilterArea = options.searchFilterArea || '';
        this.searchFilterAreaType = options.searchFilterAreaType || '';
    }
}

export default SearchFilterTerm;