import SubShopCategory from './subShopCategory';

export default class ShopCategory {
    public shopCategoryId:number;
    public shopCategoryNameId:number;
    public subShopCategories:SubShopCategory[];
    public shopCategoryName:string;
    public shopCategoryLink:string;
    constructor(options: {
        shopCategoryId?:number,
        shopCategoryNameId?:number,
        shopCategoryName?:string,
        subShopCategories?:SubShopCategory[],
        shopCategoryLink?:string;
    }={}){
        this.shopCategoryId = options.shopCategoryId || 0;
        this.shopCategoryNameId = options.shopCategoryNameId || 0;
        this.shopCategoryName = options.shopCategoryName || '';
        this.subShopCategories = options.subShopCategories || [];   
        this.shopCategoryLink = options.shopCategoryLink || '';
    }
}