import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import {getDepartmentNamesPromise, 
    getDepartmentCategoryNamesPromise, 
    getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import './AddDepartmentCategory.css';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import FormErrorMessage, {FormError, FormErrorType} from './FormErrorMessage';
import DBErrorMessage, {DBError} from './DBErrorMessage';
import StoreDepartmentSelectControl from './StoreDepartmentSelectControl';
import ShopCategory from './shopCategory';
import SubShopCategory from './subShopCategory';
import {connect} from 'react-redux';
//import {AddProductShopCategories, addProductShopCategories} from './redux/actions';
import {selectProductShopCategories, addProductShopCategories} from './redux/features/productShopCategories/productShopCategoriesSlice';
import {useAppDispatch} from './redux/hooks';
import LoaderAnimation from './LoaderAnimation';
import formatLinkString from './helper-format-link-string';

/*
const mapDispatchToProps = {
    addProductShopCategories
};
*/

interface Props {
    //showAddDepartmentCategory:boolean;
    showEditDepartmentCategory:boolean;
    departmentCategoryToEdit?:DepartmentCategory;
    handleCloseForm:(formType:string, cancel?:boolean)=>void;
    //addProductShopCategories?:(productShopCategories:ShopCategory[])=>AddProductShopCategories;
}

interface DepartmentCategoryImage {
    source:string;
    path:string;
    type:string;
}

interface ThumbnailImage {
    img:HTMLImageElement;
    name:string;
}

interface EditDepartmentCategoryInfo {
    departmentCategoryToEdit?:DepartmentCategory;
    departmentCategoryImages?:DepartmentCategoryImage[];
    relatedDepartments?:StoreDepartment[];
    departmentCategoryNameSelected?:string;
    departmentCategoryToEditError?:Error;
}

const AddDepartmentCategory: React.FunctionComponent<Props> = (props:Props) => {
    const {showEditDepartmentCategory, departmentCategoryToEdit ,handleCloseForm/*, addProductShopCategories*/} = props;
    const dispatch = useAppDispatch();
    const [departments, setDepartments] = useState<StoreDepartment[]>([]);
    const [departmentNames, setDepartmentNames] = useState<string[]>([]);
    const [departmentNameSelected, setDepartmentNameSelected] = useState('');
    const [showDeptNameErrorMsg, setShowDeptNameErrorMsg] = useState(false);
    const [deptNameErrorMsg, setDeptNameErrorMsg] = useState('Please add a department name.');
    const [departmentCategories, setDepartmentCategories] = useState<DepartmentCategory[]>([]);
    const [departmentCategoryNames, setDepartmentCategoryNames] = useState<string[]>([]);
    const [departmentCategoryNameSelected, setDepartmentCategoryNameSelected] = useState('');
    const [showDeptCategoryNameErrorMsg, setShowDeptCategoryNameErrorMsg] = useState(false);
    const [deptCategoryNameErrorMsg, setDeptCategoryNameErrorMsg] = useState('Please add a department category name.');
    const [departmentCategoryToEditId, setDepartmentCategoryToEditId] = useState(0);
    const [showProductCategoryShortcut, setShowProductCategoryShortcut] = useState(false);
    const [departmentCategoryThumbnailImages, setDepartmentCategoryThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [departmentCategoryImages, setDepartmentCategoryImages] = useState<File[]>([]);
    const [editDepartmentCategoryLoaded, setEditDepartmentCategoryLoaded] = useState(false);

    //department select element reference
    const dSelectElementRef = useRef<{dSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    const dnLabelRef = useRef<HTMLLabelElement>(null);
    const dcnLabelRef = useRef<HTMLInputElement>(null);
    const productCategoryShortcutRef = useRef<HTMLInputElement>(null);
    const relatedProductCategoryShortcutRef = useRef<HTMLInputElement>(null);
    const dciLabelRef = useRef<HTMLLabelElement>(null);
    const imageDepartmentCategoryRef = useRef<HTMLInputElement>(null);
    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });
    const [dbError, setDBError] = useState<DBError>({
                                                departmentCategoryNameError:{hasError:false, area:'none'},
                                                imageErrors:[]
                                                    });

    const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);
    const dbErrorMsgRef = useRef<{dbemcRef:React.RefObject<HTMLDivElement>}>(null);


    const [showDepartmentCategoryNameLimitError, setShowDepartmentCategoryNameLimitError] = useState(false);
    const [showDepartmentCategoryFileLimitError, setShowDepartmentCategoryFileLimitError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if(showEditDepartmentCategory){

            Promise.all([/*getDepartmentNamesPromise(),*/ getDepartmentCategoryToEditPromise(departmentCategoryToEdit!)])
            .then(([/*departments,*/ editDepartmentCategoryInfo])=>{
                //console.log('ds:', departments);
                //setupDepartmentInfo([...departments]);
                
                //console.log('editDepartmentCategoryInfo:', editDepartmentCategoryInfo);
                setupDepartmentInfo(editDepartmentCategoryInfo.relatedDepartments!);
                setDepartmentCategoryNameSelected(editDepartmentCategoryInfo.departmentCategoryNameSelected!);

                //original version
                //setupProductSubCategoryEditInfo(editProductSubCategoryInfo.productSubCategoryToEdit!);
                setupDepartmentCategoryEditInfo(editDepartmentCategoryInfo.departmentCategoryToEdit!);

                setupDepartmentCategoryImages(editDepartmentCategoryInfo.departmentCategoryImages!);

            })
            .catch((err)=>console.error('promise all for names error:', err));

        } else {

            Promise.all([getDepartmentNamesPromise() /*getDepartmentCategoryNamesPromise(), getProductCategoryNamesPromise(),*/])
            .then(([departments, /*departmentCategories, productCategories,*/])=>{
                //console.log('ds:', departments);
                //console.log('dcs:', departmentCategories);
                //console.log('pcs:', productCategories);
                //console.log('sfgs:', searchFilterGroups);
                setupDepartmentInfo([...departments]);
                //setupDepartmentCategoryInfo([...departmentCategories]);
                //setupProductCategoryInfo([...productCategories]);

            })
            .catch((err)=>console.error('promise all for names error:', err));
        }
    },[]);

    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
        const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
        dbemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
        sfgemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
    }


    function getDepartmentCategoryToEditPromise(dc:DepartmentCategory):Promise<EditDepartmentCategoryInfo>{
        return fetch(`https://server.kando-proto-3.com/get-department-category-to-edit-mariadb/${dc.departmentCategoryId}`, {
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({departmentCategoryToEdit, departmentCategoryImages, relatedDepartments, departmentCategoryNameSelected }:EditDepartmentCategoryInfo)=>{
                            
                    const dcte = new DepartmentCategory({...departmentCategoryToEdit});
                    //console.log('dcte:', dcte);
                    
                    //console.log('pcis:', departmentCategoryImages);

                    //console.log('rdns:', relatedDepartments);
                    //console.log('dcns:', departmentCategoryNameSelected);

                    return ({
                        departmentCategoryToEdit:dcte,
                        departmentCategoryImages:departmentCategoryImages,
                        relatedDepartments,
                        departmentCategoryNameSelected
                    });
                })
                .catch((err)=>{
                    console.error('fetch department category to edit error:', err.message);
                    return ({departmentCategoryToEditError:new Error(err.message)})
                });

    }

    function setupDepartmentInfo(ds:StoreDepartment[]):void {
        function addDepartments(ds:StoreDepartment[]):StoreDepartment[]{
            return ds.map((d)=>{
                return new StoreDepartment({...d});
            })
        }

        function addDepartmentNames(ds:StoreDepartment[]):string[]{
            return ds.map((d)=>{
                return d.departmentName;
            })
        }

        const uds = addDepartments(ds);
        setDepartments(uds);
        const dns = addDepartmentNames(uds);
        setDepartmentNames(dns);
    }

    function setupDepartmentCategoryEditInfo(dc:DepartmentCategory):void {
        //console.log('test 6-7');
        setDepartmentNameSelected(dc.departmentName);
        setDepartmentCategoryNameSelected(dc.departmentCategoryName);
        setShowProductCategoryShortcut(dc.showProductCategoryShortcut);
        setDepartmentCategoryToEditId(dc.departmentCategoryId!);
        setEditDepartmentCategoryLoaded(true);
        //need to set form a file image
        //setProductSearchImages(product.productImage);

        //psc.showProductSubCategoryKeywordSearch ? keywordSearchRef.current!.checked = true : keywordSearchRef.current!.checked = false;
        //psc.showProductSubCategoryNameSearch ? nameSearchRef.current!.checked = true : nameSearchRef.current!.checked = false;
        


        //original version
        //window.setTimeout(()=>triggerStoreDepartmentChangeEvent(psc.departmentName), 100);
    
    }

    function handleDeptNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setDepartmentNameSelected(dns);
            setShowDeptNameErrorMsg(false);
            const found = departments.filter((d)=>{
                                        return d.departmentName === dns;
                                    });

            if(found.length === 0){
            throw new Error('no department found.');
            }

            const dId = found[0].departmentId;

        } else {
            setDepartmentNameSelected(dns);
            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');
        }
        
        
    }


    function setupDepartmentCategoryInfo(dcs:DepartmentCategory[]):void {
        function addDepartmentCategories(dcs:DepartmentCategory[]):DepartmentCategory[]{
            return dcs.map((dc)=>{
                return new DepartmentCategory({...dc});
            })
        }

        function addDepartmentCategoryNames(dcs:DepartmentCategory[]):string[]{
            return dcs.map((dc)=>{
                return dc.departmentCategoryName;
            })
        }

        const udcs = addDepartmentCategories(dcs);
        setDepartmentCategories(udcs);
        const dcns = addDepartmentCategoryNames(udcs);
        setDepartmentCategoryNames(dcns);

    }
   

    function relatedDepartmentCategories(departmentId:number):Promise<DepartmentCategory[]> {

        return fetch(`https://server.kando-proto-3.com/get-related-department-categories/${departmentId.toString()}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((dcs:DepartmentCategory[])=>{
                //console.log('dcs 3-28:', dcs);
                setupDepartmentCategoryInfo(dcs);
                return;
            })
            .catch((err)=>{
                //console.error('err:', err.message)
                return err.message;
            });


    }

    function setupDepartmentCategoryImages(departmentCategoryImages:DepartmentCategoryImage[]):void {
        departmentCategoryImages.forEach((dci)=>{
            //console.log('dci:', dci);
            //add images by category
            const si = new Image();
            //previous version
            //si.src = `${process.env.PUBLIC_URL}${dci.path}`;
            si.src = `https://server.kando-proto-3.com/${dci.path}`;
            //original version
            //setProductSubCategoryThumbnailImages([{img:si, name:psci.path}]);
            setDepartmentCategoryThumbnailImages([{img:si, name:getFileName(dci.path)}]);
            //console.log('psci.source s:', dci.source);
            const sbyteCharacters = window.atob(dci.source);
            const sbyteNumbers = new Array(sbyteCharacters.length);
            for (let i = 0; i < sbyteCharacters.length; i++) {
                sbyteNumbers[i] = sbyteCharacters.charCodeAt(i);
            }
            const sbyteArray = new Uint8Array(sbyteNumbers);
            const sBlob = new Blob([sbyteArray], {type:dci.type});
            const sFile = new File([sBlob], dci.path, {type:dci.type});
            //console.log('sFile:', sFile)
            //const sBlob = new Blob([pi.source], {type:pi.type});
            //const sFile = new File([sBlob], pi.path, {type:pi.type});
            setDepartmentCategoryImages([sFile]);
        });


    }

    function getFileName(filePath:string):string {
        const lastForwardSlash = filePath.lastIndexOf('/');
        const fileName = filePath.slice(lastForwardSlash + 1);
        return fileName;
    }

    function handleProductCategoryShortcut(e:React.ChangeEvent):void {
        e.stopPropagation();
        const productCategoryShortcutChecked = (e.target as HTMLInputElement).checked;
        //console.log('checked:', productCategoryShortcutChecked);
        productCategoryShortcutChecked ? setShowProductCategoryShortcut(true) : setShowProductCategoryShortcut(false);

    }


    function imageClickHandler(e:React.MouseEvent):void {
        //console.log('e.target:', e.target);
        e.preventDefault();
        //e.stopPropagation();
        imageDepartmentCategoryRef.current!.value = '';
        imageDepartmentCategoryRef.current!.click();
    }

    function handleFileUpload(e:React.ChangeEvent):void {
        //console.log('file upload:', e.target, (e.target as HTMLInputElement).files);
        const target = e.target as HTMLInputElement;
        const fileList = (e.target as HTMLInputElement).files;
        const files = Array.from(fileList!);

        if(fileList![0].size / (1024 ** 2) <= 1.5){
            addThumbNailImage(files);
            setShowDepartmentCategoryFileLimitError(false);           

        } else {
            setShowDepartmentCategoryFileLimitError(true);
        }
    }

    function addThumbNailImage(files:File[]):void {
        let fileImgs:ThumbnailImage[] = [];

        files.forEach((f)=>{
            const img = new Image();
            img.src = URL.createObjectURL(f);
            //img.height = 40;
            //img.width = 40;
            //not using this function because I need a local copy so
            //the image is found when deleting from list
            //would revoke them in general if I did need to reference a local copy
            //before saving the image to the database
            /*
            img.onload = function(){
                URL.revokeObjectURL(img.src);
            }*/
            
            fileImgs.push({name:f.name, img:img})
        });

        setDepartmentCategoryThumbnailImages([...fileImgs]);
        setDepartmentCategoryImages([...files]);



                
    }



    function setupDepartmentCategoryThumbnailImages(tis:ThumbnailImage[]):JSX.Element {
        //console.log('tis:', tis);
        if(tis.length === 0){
            return  <div className="product-image-search-list-container">No images selected</div>
        } else {
            return  <div className="product-image-search-list-container">
                        <ul className="product-image-search-list">
                            {tis.map((ti, k)=>{
                                return <li key={k} className="image-list-item">
                                            <img src={ti.img.src} className="add-thumbnail-image"/>
                                            <span className="image-list-item-url-container">
                                                <span>{ti.name}</span>
                                                <span className="image-list-item-close-btn" onClick={(e:React.MouseEvent)=>removeImageFromList2(/*ti.img, ti.name*/)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </span>
                                            </span>
                                        </li>
                            })}
                        </ul>
                    </div>
        }

    }

    function removeImageFromList2():void {
        //remove all thumbnails
        setDepartmentCategoryThumbnailImages([]);

        //remove all images
        setDepartmentCategoryImages([]);
    }

    function removeImageFromList(img:HTMLImageElement, fileNameSelected:string):void {
        //console.log('img:', img, `/images/product-category/${fileNameSelected}`);
        
        const imageToDelete = {filePath:`/images/product-category/${fileNameSelected}`, directory:'product-category'};

        fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({msg})=>{

                    const stis = departmentCategoryThumbnailImages.filter((i)=>{
                        return i.img !== img;
                    });

                    //original version
                    //setProductSubCategoryThumbnailImages(stis);
                    //remove all thumbnails
                    setDepartmentCategoryThumbnailImages([]);

                    const sis = departmentCategoryImages.filter((f)=>{
                                    return f.name !== fileNameSelected;
                                });
                                

                    //original version
                    //setProductSubCategoryImages(sis);
                    //remove all images
                    setDepartmentCategoryImages([]);


                    //console.log('department category image removed from form:', msg);
                })
                .catch((err)=>console.error('remove department category image from public folder error:', err.message));

    }

    function removeImagefromDatabase(searchFilterAreaId:number, filePathDirectorySelected:string):Promise<Response> {
        //original version
        //const imageToDelete = {filePath:`${filePathSelected}`, directory:`${filePathSelectedDirectory}`};

        const imageToDelete = {searchFilterAreaId, filePathDirectorySelected};


        return fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    //body:JSON.stringify(imageToDelete),
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })

    }

    function getNextNumberId(oid:string):number {
        let id = window.parseInt(oid, 10);
        return ++id;
    }


    function handleFormSubmit(e:React.FormEvent, formAction:string):void {
        e.preventDefault();
        const form = e.target as HTMLFormElement;

        dbErrorMsgRef.current!.dbemcRef.current!.style.top = '-1000px';
        sfgErrorMsgRef.current!.sfgemcRef.current!.style.top = '-1000px';

        //rest form errors
        //dnLabelRef.current!.classList.remove('product-info-error');
        dSelectElementRef.current!.dSelectElementRef.current!.classList.remove('product-info-error');
        dcnLabelRef.current!.classList.remove('product-info-error');

        dciLabelRef.current!.classList.remove('image-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.imageErrors.length = 0;

        //product info
        //department name selected
        const dns = departmentNameSelected;
        if(dns.length === 0 || dns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Department Name'});
        }

        //department category name selected
        const dcns = departmentCategoryNameSelected;
        const dclns = formatLinkString(dcns);
        //console.log('dclns:', dclns);

        if(dcns.length === 0 || dcns === 'none'){
            setShowDepartmentCategoryNameLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Department Category Name'});

        } else {
            setShowDepartmentCategoryNameLimitError(false);
        }

        //images-groups
        //department category image
        let dcImages = departmentCategoryImages;
        if(dcImages.length === 0 || dcImages.length > 1){
            setShowDepartmentCategoryFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Department Category Image'});

        } else {

            setShowDepartmentCategoryFileLimitError(false);

            const fn = dcImages[0].name.toLowerCase();
            //const ufn = fn.replace(/[=?#+%^{}\[\]\\\/,<>"\*$@!|\(\)&~`]/gi, '');
            const ufn = fn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nfn = ufn.replace(/ /ig, '-');
    
            let blob = dcImages[0].slice(0, dcImages[0].size, dcImages[0].type);
            const newFile = new File([blob], nfn, {type: blob.type});
            //remove original unfiltered file
            dcImages.length = 0;
            dcImages.push(newFile);
        }

        //console.log('dcImages:', dcImages);
        //return;

        const pies = formError.productInfoErrors.length > 0 ? true : false;
        const ies = formError.imageErrors.length > 0 ? true : false;

        if(pies || ies){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }


                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }


        setShowProcessingRequestMsg(true);


        if(formAction === 'addDepartmentCategory'){

            let departmentCategoryImageName = departmentCategoryImages.length > 0 ? departmentCategoryImages[0].name : 'none';
                //departmentCategoryImageName = formatFilePathName(departmentCategoryImageName).toLowerCase();
                departmentCategoryImageName = departmentCategoryImageName.toLowerCase();

            const dcin = {
                //original version
                //productCategoryImageName:productCategoryImageName
                departmentCategoryImageName:departmentCategoryImageName
            };

            //console.log('dcin:', dcin);

            const urlParams = {departmentCategoryNameSelected, departmentCategoryImageName};

            const params = JSON.stringify(urlParams);

            //previous version
            //fetch(`http://localhost:9500/get-department-category-info/${departmentCategoryNameSelected}`, {
            fetch(`https://server.kando-proto-3.com/get-department-category-info`, {
                method:'POST',
                //body:JSON.stringify(dcin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({departmentCategoryNameExists, departmentCategoryImageExists, lastDepartmentCategoryId, departmentCategoryId})=>{
                    //console.log('dcne:', departmentCategoryNameExists);
                    //console.log('dcie:', departmentCategoryImageExists);
                    //console.log('ldcId:', lastDepartmentCategoryId);
                    //console.log('dcId:', departmentCategoryId, 'departmentCategoryNameSelected:', departmentCategoryNameSelected);
                    

                    if(departmentCategoryNameExists || departmentCategoryImageExists){
                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.productCategoryNameError =  {hasError:false, area:'none'};


                        if(departmentCategoryImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Department Category Image', fileName:departmentCategoryImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(departmentCategoryNameExists){
                            dbError.departmentCategoryNameError =  {hasError:true, area:departmentCategoryNameSelected};
                        }

                        dbError.departmentCategoryNameError = (dbError.departmentCategoryNameError!.hasError) 
                                                    ? dbError.departmentCategoryNameError 
                                                    : {hasError:false, area:'none'};

                        const de = {...dbError};
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
            
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;


                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }

            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        //const pcId = window.parseInt(productCategoryId, 10);
                        const nextDepartmentCategoryId = getNextNumberId(lastDepartmentCategoryId);
                        
                        //create new department category filled with form data fields
                        const departmentCategory = new DepartmentCategory({
                                                departmentCategoryId:nextDepartmentCategoryId,
                                                departmentCategoryImage:dcin.departmentCategoryImageName,
                                                departmentName:dns,
                                                departmentCategoryName:dcns,
                                                departmentCategoryLinkName:dclns,
                                                showProductCategoryShortcut:showProductCategoryShortcut
                                            }); 

                        const fd = new FormData();
                        //add product info
                        fd.append('product-info', JSON.stringify(departmentCategory));

                        //add department category image
                        fd.append('department-category-image', departmentCategoryImages[0]);

                        //console.log('dc:', departmentCategory, departmentCategoryImages[0]);
                        
                        setIsLoading(true);

                        fetch(`https://server.kando-proto-3.com/add-individual-department-category`, {
                            method:'POST',
                            body:fd
                        })
                        .then((res)=>{
                            return res.json();
                        })
                        .then((res)=>{
                            //console.log('add individual department category response:', res);
                            const pscs = res.pscs.map((psc:ShopCategory)=>{
                                return addShopCategory(psc);
                            });
                    
                            //console.log('pscs15:', pscs);
                            dispatch(addProductShopCategories([...pscs]));

                            //close form update product list
                            handleCloseForm('addDepartmentCategory');
                        })
                        .catch((err)=>console.error('add individual department category fetch error:', err.message));

                    }

                })
                .catch((err)=>{
                    console.error('product search err:', err)
                });
                
                
        } else {

            let departmentCategoryImageName = departmentCategoryImages.length > 0 ? departmentCategoryImages[0].name : 'none';
                //departmentCategoryImageName = formatFilePathName(departmentCategoryImageName).toLowerCase();
                departmentCategoryImageName = departmentCategoryImageName.toLowerCase();

            //console.log('result 6-7', productCategoryImageName);

            const dcin = {
                //original version
                //productCategoryImageName:productCategoryImageName
                departmentCategoryImageName:departmentCategoryImageName
            };

            const urlParams = {departmentCategoryNameSelected, 
                                departmentCategoryToEditId, 
                                departmentCategoryImageName};

            const params = JSON.stringify(urlParams);

            //previous version
            //fetch(`http://localhost:9500/check-department-category-exists/${departmentCategoryNameSelected}/${departmentCategoryToEditId}`, {
            fetch(`https://server.kando-proto-3.com/check-department-category-exists`, {
                method:'POST',
                //body:JSON.stringify(dcin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({departmentCategoryNameExists, departmentCategoryImageExists, departmentCategoryId})=>{
                    //console.log('departmentCategoryNameExists:', departmentCategoryNameExists);
                    //console.log('departmentCategoryImageExists:', departmentCategoryImageExists);
                    //console.log('departmentCategoryId:', departmentCategoryId);
        
                    if(departmentCategoryNameExists || departmentCategoryImageExists){
                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.departmentCategoryNameError =  {hasError:false, area:'none'};

                        if(departmentCategoryImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Department Category Image', fileName:departmentCategoryImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(departmentCategoryNameExists){
                            dbError.departmentCategoryNameError =  {hasError:true, area:departmentCategoryNameSelected};
                        }

                        dbError.departmentCategoryNameError = (dbError.departmentCategoryNameError!.hasError) 
                                                    ? dbError.departmentCategoryNameError 
                                                    : {hasError:false, area:'none'};


                        const de = {...dbError};
                        
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{
        
                            addDBFormLabelErrors(dbError);

                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;


                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }

            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        removeImagefromDatabase(departmentCategoryId, 'department-category')
                        .then((res)=>res.json())
                        .then(({msg})=>{
                            //console.log('msg:', msg);
                            if(msg === 'department-category image removed'){
                                //console.log('department category image removed from form:', msg);

                                //create new department category filled with form data fields
                                const departmentCategory = new DepartmentCategory({
                                    departmentCategoryId:departmentCategoryId,
                                    departmentCategoryImage:dcin.departmentCategoryImageName,
                                    departmentName:dns,
                                    departmentCategoryName:dcns,
                                    departmentCategoryLinkName:dclns,
                                    showProductCategoryShortcut:showProductCategoryShortcut
                                });               


                                const fd = new FormData();
                                //add product info
                                fd.append('product-info', JSON.stringify(departmentCategory));

                                //add product search image
                                fd.append('department-category-image', departmentCategoryImages[0]);

                                //console.log('product 6-7:', departmentCategory);
                                //console.log('department-category-image 6-7:', departmentCategoryImages[0]);

                                setIsLoading(true);
                                    
                                //update product in database
                                fetch(`https://server.kando-proto-3.com/update-individual-department-category`, {
                                    method:'PUT',
                                    body:fd
                                    })
                                    .then((res)=>{
                                        return res.json();
                                    })
                                    .then((res)=>{
                                        const pscs = res.pscs.map((psc:ShopCategory)=>{
                                            return addShopCategory(psc);
                                        });
                                
                                        //console.log('pscs15:', pscs);
                                        //uncomment after testing
                                        dispatch(addProductShopCategories([...pscs]));

                                        //uncomment after testing
                                        handleCloseForm('editDepartmentCategory');
                                    })
                                    .catch((err)=>console.error('update individual department category fetch error:', err.message));

                            } else {
                                throw new Error('file was not removed.');
                            }
                            
                        })
                        .catch((err)=>console.error('remove department category image from public folder error:', err.message));




                    }
                    




                })
                .catch((err)=>console.error('check department category exists fetch error:', err.message));



        }

    }

    function addShopCategory(sc:ShopCategory):ShopCategory {
        return new ShopCategory({shopCategoryId:sc.shopCategoryId,
                                shopCategoryLink:sc.shopCategoryLink,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                                });
    }

    function addSubShopCategories(sscs:SubShopCategory[]):SubShopCategory[] {
        return sscs.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });

    }

    function formatFilePathName(filePathName:string):string {
        return filePathName.search(/\//i) === -1 ? `/images/department-category/${filePathName}` : filePathName;
    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Department Name':
                                //dnLabelRef.current!.classList.add('product-info-error');
                                dSelectElementRef.current!.dSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Department Category Name':
                                dcnLabelRef.current!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no department category info errors found!');
                        }
                    }
                });


        //set image errors
        formError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Department Category Image'){
                //console.log('ie.area:', ie.area);
                dciLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });
    }

    function addDBFormLabelErrors(dbError:DBError): void {
        //set product info errors
        if(dbError.departmentCategoryNameError!.hasError){
            dcnLabelRef.current!.classList.add('product-info-error');
        }
        
        //set image errors
        dbError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Department Category Image'){
                //console.log('ie.area:', ie.area);
                dciLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });

    }

    function handleDepartmentCategoryName(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        //console.log('value.length:', value.length);

        if(value.length <= 50){
            setDepartmentCategoryNameSelected(value);
            setShowDepartmentCategoryNameLimitError(false);

        } else {
            setShowDepartmentCategoryNameLimitError(true);
        }
    }

    function setupFormAction(showEditDepartmentCategory:boolean):string {
        return (showEditDepartmentCategory) 
                ? 'updateDepartmentCategory'
                : 'addDepartmentCategory'
    }


    if(isLoading){
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>;
    } else
    
    if(!showEditDepartmentCategory || (showEditDepartmentCategory && editDepartmentCategoryLoaded)){
        return <div>
                    {
                        showEditDepartmentCategory
                        ?
                        <h1 className="add-dpt-category-heading">Edit Department Category</h1>
                        :
                        <h1 className="add-dpt-category-heading">Add Department Category</h1>
                    }
                    
                    <form ref={formRef} className="add-dpt-category-form" onSubmit={(e:React.FormEvent)=>handleFormSubmit(e, setupFormAction(showEditDepartmentCategory))}>
                        <div className="add-dpt-category-input-form-group">
                            <label ref={dnLabelRef} htmlFor="dpt-category-name">Department Name</label>
                            <StoreDepartmentSelectControl ref={dSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={departmentNameSelected}
                                    optionNames={departmentNames}
                                    showErrorMsg={showDeptNameErrorMsg}
                                    errorMsg={deptNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        <div className="add-product-sub-category-input-form-group">
                            <label htmlFor="product-sub-category-name">Department Category Name</label>
                            <input ref={dcnLabelRef} name="product-sub-category-name" type="text"
                                id="product-sub-category-name"
                                className="form-input-control" value={departmentCategoryNameSelected} onChange={handleDepartmentCategoryName}/>
                            {
                                showDepartmentCategoryNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                :
                                null
                            }


                        </div>
                        <div className="add-product-sub-category-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={dciLabelRef} htmlFor="product-image-search" onClick={imageClickHandler}><span className="product-image-search-label-link">Select Images <span className="no-color-label-link">(department category)</span></span></label>
                                </div>
                                {
                                    showDepartmentCategoryFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageDepartmentCategoryRef} id="product-image-search" className="product-image-search" name="product-image-search" type="file" onChange={handleFileUpload}/>
                                {setupDepartmentCategoryThumbnailImages(departmentCategoryThumbnailImages)}

                            </div>
                        <div className="add-product-sub-category-checkbox-form-group">
                            <input className="admin-checkbox-control" ref={productCategoryShortcutRef} type="checkbox" checked={showProductCategoryShortcut} name="show-product-sub-category-name-search" 
                                id="show-product-sub-category-name-search" onChange={handleProductCategoryShortcut}/>
                            <label htmlFor="show-product-sub-category-name-search">Show product category shortcut</label>
                        </div>
                        {
                            (showEditDepartmentCategory)
                            ?   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-save-btn" type="submit" value="Save"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('editDepartmentCategory')} value="Cancel"/>

                                    {
                                        showProcessingRequestMsg
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            :   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-add-btn" type="submit" value="Add"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('addDepartmentCategory', true)} value="Cancel"/>

                                    {
                                        showProcessingRequestMsg
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                   
                        }
                        <DBErrorMessage ref={dbErrorMsgRef} dbError={dbError}/>
                        <FormErrorMessage ref={sfgErrorMsgRef} formError={formError} />

                    </form>
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    {<LoaderAnimation/>}
                </div>;
    }
}


export default AddDepartmentCategory;
/*
export default connect(
    null,
    mapDispatchToProps
)(AddDepartmentCategory);
*/