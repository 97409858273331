import React, {useImperativeHandle, useRef, useEffect, forwardRef} from 'react';
import SearchFilter, {SearchFilterGroup} from './searchFilter';
import ProductVerification from './productVerification';
import ProductPromotion from './productPromotion';
import './CheckboxBuilder.css';


interface Props {
    options:ProductVerification[];
    selectedOptions:ProductVerification[];
    title:string;
    handleOptionNameSelection:(e:React.ChangeEvent)=>void;
}

/*
type Ref = {
    pvCheckboxControlsRef:React.RefObject<HTMLInputElement[]>;
};
*/

const ProductVerificationControl2: React.FunctionComponent<Props> = (props:Props) => {
//const ProductVerificationControl2 = forwardRef<Ref, Props>((props, ref)=>{
    const {options, selectedOptions, title, handleOptionNameSelection} = props;
    //console.log('options:', options);

    const controlsRef = useRef<HTMLInputElement[]>([]);
    
    /*
    useImperativeHandle(ref, ()=>({
        
        get pvCheckboxControlsRef(){
            return controlsRef;
        }
        
        })
    );
    */

    useEffect(()=>{
        checkOptions(selectedOptions);
        
    },[selectedOptions]);
    

    function addCheckboxControl(el:HTMLInputElement):void {
        if(el !== null && controlsRef.current.length === 0){
            controlsRef.current.push(el);
        }

        if(el !== null && controlsRef.current.length > 0){
            const found = controlsRef.current.filter((c)=>{
                                return c === el;
                            });

            if(found.length === 0){
                controlsRef.current.push(el);
            }
        }

        //console.log('pv controls:', pvCheckboxControls.current);
    }


    function checkOptions(sos:ProductVerification[]):void {
        sos.forEach((pv)=>{
            controlsRef.current.forEach((control)=>{
                const controlId = window.parseInt(control.id, 10);
                if(pv.productVerificationId === controlId){
                    control.checked = true;
                }
            });
        });
    }

    function setupPVCheckboxControls(options:ProductVerification[]):JSX.Element[] {
        return options.map((pv, i)=>{
                            return  <div key={i} className="add-product-checkbox-form-group product-verification-container">
                                        <input className="admin-checkbox-control" ref={addCheckboxControl} type="checkbox" name={`checkbox ${i}`} id={`${pv.productVerificationId} ${pv.productVerificationName}`} value={`${pv.productVerificationName}`} onChange={handleOptionNameSelection}/>
                                        <label className="product-verification-label" htmlFor={`${pv.productVerificationId} ${pv.productVerificationName}`}>{pv.productVerificationName}</label>
                                    </div>
                        });
    }

    return (
        <fieldset className="verifications-fieldset">
            <legend>{title}</legend>
            {setupPVCheckboxControls(options)}
        </fieldset>
    );


};

export default ProductVerificationControl2;