class ShopProductParams {
    public type:string;
    public storeDepartmentName:string;
    public departmentCategoryName:string;
    public productCategoryName:string;
    public productName:string;
    public productId:string;

    constructor(options:{        
        type?:string,
        storeDepartmentName?:string,
        departmentCategoryName?:string,
        productCategoryName?:string,
        productName?:string,
        productId?:string
    }={}){
        this.type = options.type || '';
        this.storeDepartmentName = options.storeDepartmentName || '';
        this.departmentCategoryName = options.departmentCategoryName || '';
        this.productCategoryName = options.productCategoryName || '';
        this.productName = options.productName || '';
        this.productId = options.productId || '0';
    }
}

export default ShopProductParams;