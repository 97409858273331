export class SearchTerm {
    public searchTermId:number;
    public searchFilterArea:string;
    public searchFilterAreaType:string;
    public searchTermName:string;
    public searchFilterName:string;
    public searchTermQty:number;
    public searchTermImg:string;
    public showSearchTermImg:boolean;
    public showSearchTerm:boolean;
    public searchTermSelected:boolean;
    public searchTermDateAdded:Date;
    constructor(options:{
        searchTermId?:number,
        searchFilterArea?:string,
        searchFilterAreaType?:string,
        searchTermName?:string,
        searchFilterName?:string,
        searchTermQty?:number,
        searchTermImg?:string,
        showSearchTermImg?:boolean,
        showSearchTerm?:boolean,
        searchTermSelected?:boolean,
        searchTermDateAdded?:Date
    }={}){
        this.searchTermId = options.searchTermId || 0;
        this.searchFilterArea = options.searchFilterArea || '';
        this.searchFilterAreaType = options.searchFilterAreaType || '';
        this.searchTermName = options.searchTermName || '';
        this.searchFilterName = options.searchFilterName || '';
        this.searchTermQty = options.searchTermQty || 0;
        this.searchTermImg = options.searchTermImg || '';
        this.showSearchTermImg = options.showSearchTermImg || false;
        this.showSearchTerm = options.showSearchTerm || false;
        this.searchTermSelected = options.searchTermSelected || false;
        this.searchTermDateAdded = options.searchTermDateAdded || new Date();
    }
}

class SearchFilter {
    public searchFilterId:number;
    public searchFilterArea:string;
    public searchFilterAreaType:string;
    public filterName:string;
    public searchTermQty:number;
    public searchTerms:SearchTerm[];
    public showFilter:boolean;
    public searchFilterSelected:boolean;
    public searchFilterDateAdded:Date;
    constructor(options:{
        searchFilterId?:number,
        searchFilterArea?:string,
        searchFilterAreaType?:string,
        filterName?:string,
        searchTermQty?:number,
        searchTerms?:SearchTerm[],
        showFilter?:boolean,
        searchFilterSelected?:boolean,
        searchFilterDateAdded?:Date
    }={}){
        this.searchFilterId = options.searchFilterId || 0;
        this.searchFilterArea = options.searchFilterArea || '';
        this.searchFilterAreaType = options.searchFilterAreaType || '';
        this.filterName = options.filterName || '';
        this.searchTermQty = options.searchTermQty || 0;
        this.searchTerms = options. searchTerms || [];
        this.showFilter = options.showFilter || false;
        this.searchFilterSelected = options.searchFilterSelected || false;
        this.searchFilterDateAdded = options.searchFilterDateAdded || new Date();
    }
}

export class SearchFilterGroup {
            public groupCategoryName:string;
            public groupFilterName:string;
            public searchFilters:SearchFilter[];
            constructor(options:{
                groupCategoryName?:string,
                groupFilterName?:string,
                searchFilters?:SearchFilter[]
            }={}){
                this.groupCategoryName = options.groupCategoryName || 'none';
                this.groupFilterName = options.groupFilterName || 'none';
                this.searchFilters = options.searchFilters || [];
            }
        }

export default SearchFilter;