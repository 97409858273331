import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import ProductKeywordSearch from '../../productKeywordSearch';
import cloneDeep from 'lodash/cloneDeep';

export interface HomePageLayoutParams {
    hideHomePageLayoutBuilder:boolean;
}

const initialState:HomePageLayoutParams = {
    hideHomePageLayoutBuilder:false
};

export const homePageLayoutSlice = createSlice({
  name: 'homePageLayoutParams',
  initialState,
  reducers: {
    addHomePageLayout: (state, action:PayloadAction<HomePageLayoutParams>) => {
      state.hideHomePageLayoutBuilder = action.payload.hideHomePageLayoutBuilder;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addHomePageLayout } = homePageLayoutSlice.actions;

interface RootState {
    homePageLayoutParams: HomePageLayoutParams
}

export const selectHomePageLayoutParams = (state:RootState) =>  {
  //const clone = cloneDeep(state.productKeywordSearch);
  return state.homePageLayoutParams;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default homePageLayoutSlice.reducer;