import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import {getDepartmentNamesPromise, 
    getDepartmentCategoryNamesPromise, 
    getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import './AddDepartmentCategory.css';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import FormErrorMessage, {FormError, FormErrorType} from './FormErrorMessage';
import DBErrorMessage, {DBError} from './DBErrorMessage';
import StoreDepartmentSelectControl from './StoreDepartmentSelectControl';
import ShopCategory from './shopCategory';
import SubShopCategory from './subShopCategory';
import {connect} from 'react-redux';
//import {AddProductShopCategories, addProductShopCategories} from './redux/actions';
import {selectProductShopCategories, addProductShopCategories} from './redux/features/productShopCategories/productShopCategoriesSlice';
import {useAppSelector} from './redux/hooks';
import {useAppDispatch} from './redux/hooks';
import LoaderAnimation from './LoaderAnimation';
import formatLinkString from './helper-format-link-string';

/*
const mapDispatchToProps = {
    addProductShopCategories
};
*/

interface Props {
    //showAddDepartmentCategory:boolean;
    showEditDepartment:boolean;
    departmentToEdit?:StoreDepartment;
    handleCloseForm:(formType:string, cancel?:boolean)=>void;
    //addProductShopCategories?:(productShopCategories:ShopCategory[])=>AddProductShopCategories;
}

interface DepartmentImage {
    source:string;
    path:string;
    type:string;
}

interface ThumbnailImage {
    img:HTMLImageElement;
    name:string;
}

interface EditDepartmentInfo {
    departmentToEdit?:StoreDepartment;
    departmentImages?:DepartmentImage[];
    departmentNameSelected?:string;
    departmentToEditError?:Error;
}

const AddStoreDepartment: React.FunctionComponent<Props> = (props:Props) => {
    const {showEditDepartment, departmentToEdit ,handleCloseForm/*, addProductShopCategories*/} = props;
    const dispatch = useAppDispatch();
    const [departments, setDepartments] = useState<StoreDepartment[]>([]);
    //const [departmentNames, setDepartmentNames] = useState<string[]>([]);
    const [departmentNameSelected, setDepartmentNameSelected] = useState('');
    const [showDeptNameErrorMsg, setShowDeptNameErrorMsg] = useState(false);
    const [deptNameErrorMsg, setDeptNameErrorMsg] = useState('Please add a department name.');
    const [departmentCategories, setDepartmentCategories] = useState<DepartmentCategory[]>([]);
    const [departmentCategoryNames, setDepartmentCategoryNames] = useState<string[]>([]);
    const [departmentCategoryNameSelected, setDepartmentCategoryNameSelected] = useState('');
    const [showDeptCategoryNameErrorMsg, setShowDeptCategoryNameErrorMsg] = useState(false);
    const [deptCategoryNameErrorMsg, setDeptCategoryNameErrorMsg] = useState('Please add a department category name.');
    const [departmentToEditId, setDepartmentToEditId] = useState(0);
    const [showDepartmentCategoryShortcut, setShowDepartmentCategoryShortcut] = useState(false);
    const [departmentThumbnailImages, setDepartmentThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [departmentImages, setDepartmentImages] = useState<File[]>([]);
    const [editDepartmentLoaded, setEditDepartmentLoaded] = useState(false);

    //department select element reference
    const dSelectElementRef = useRef<{dSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    const dnLabelRef = useRef<HTMLInputElement>(null);
    const departmentCategoryShortcutRef = useRef<HTMLInputElement>(null);
    const relatedProductCategoryShortcutRef = useRef<HTMLInputElement>(null);
    const diLabelRef = useRef<HTMLLabelElement>(null);
    const imageDepartmentRef = useRef<HTMLInputElement>(null);
    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });
    const [dbError, setDBError] = useState<DBError>({
                                                departmentNameError:{hasError:false, area:'none'},
                                                imageErrors:[]
                                                    });

    const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);
    const dbErrorMsgRef = useRef<{dbemcRef:React.RefObject<HTMLDivElement>}>(null);


    const [showDepartmentNameLimitError, setShowDepartmentNameLimitError] = useState(false);
    const [showDepartmentFileLimitError, setShowDepartmentFileLimitError] = useState(false);


    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const [isLoading, setIsLoading] = useState(false);


    useEffect(()=>{
        if(showEditDepartment){

            Promise.all([/*getDepartmentNamesPromise(),*/ getDepartmentToEditPromise(departmentToEdit!)])
            .then(([/*departments,*/ editDepartmentInfo])=>{
                //console.log('ds:', departments);
                //setupDepartmentInfo([...departments]);
                
                //console.log('editDepartmentInfo:', editDepartmentInfo);
                //setupDepartmentInfo(editDepartmentInfo.relatedDepartments!);
                setDepartmentNameSelected(editDepartmentInfo.departmentNameSelected!);

                //original version
                //setupProductSubCategoryEditInfo(editProductSubCategoryInfo.productSubCategoryToEdit!);
                setupDepartmentEditInfo(editDepartmentInfo.departmentToEdit!);

                setupDepartmentImages(editDepartmentInfo.departmentImages!);

            })
            .catch((err)=>console.error('promise all for names error:', err));

        } 
        
    },[]);


    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
        const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
        dbemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
        sfgemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
    }


    function getDepartmentToEditPromise(d:StoreDepartment):Promise<EditDepartmentInfo>{
        return fetch(`https://server.kando-proto-3.com/get-department-to-edit-mariadb/${d.departmentId}`, {
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({departmentToEdit, departmentImages, departmentNameSelected }:EditDepartmentInfo)=>{
                            
                    const dte = new StoreDepartment({...departmentToEdit});
                    //console.log('dte:', dte);
                    
                    //console.log('dis:', departmentImages);

                    //console.log('rdns:', relatedDepartments);
                    //console.log('dns:', departmentNameSelected);

                    return ({
                        departmentToEdit:dte,
                        departmentImages:departmentImages,
                        departmentNameSelected
                    });
                })
                .catch((err)=>{
                    console.error('fetch department to edit error:', err.message);
                    return ({departmentToEditError:new Error(err.message)})
                });

    }

    /*
    function setupDepartmentInfo(ds:StoreDepartment[]):void {
        function addDepartments(ds:StoreDepartment[]):StoreDepartment[]{
            return ds.map((d)=>{
                return new StoreDepartment({...d});
            })
        }

        function addDepartmentNames(ds:StoreDepartment[]):string[]{
            return ds.map((d)=>{
                return d.departmentName;
            })
        }

        const uds = addDepartments(ds);
        setDepartments(uds);
        const dns = addDepartmentNames(uds);
        setDepartmentNames(dns);
    }
    */

    function setupDepartmentEditInfo(d:StoreDepartment):void {
        //console.log('test 6-7');
        setDepartmentNameSelected(d.departmentName);
        setShowDepartmentCategoryShortcut(d.showDepartmentCategoryShortcut);
        setDepartmentToEditId(d.departmentId!);
        setEditDepartmentLoaded(true);
        //need to set form a file image
        //setProductSearchImages(product.productImage);

        //psc.showProductSubCategoryKeywordSearch ? keywordSearchRef.current!.checked = true : keywordSearchRef.current!.checked = false;
        //psc.showProductSubCategoryNameSearch ? nameSearchRef.current!.checked = true : nameSearchRef.current!.checked = false;
        


        //original version
        //window.setTimeout(()=>triggerStoreDepartmentChangeEvent(psc.departmentName), 100);
    
    }

    function handleDeptNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setDepartmentNameSelected(dns);
            setShowDeptNameErrorMsg(false);
            const found = departments.filter((d)=>{
                                        return d.departmentName === dns;
                                    });

            if(found.length === 0){
            throw new Error('no department found.');
            }

            const dId = found[0].departmentId;

        } else {
            setDepartmentNameSelected(dns);
            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');
        }
        
        
    }


    function setupDepartmentInfo(dcs:DepartmentCategory[]):void {
        function addDepartmentCategories(dcs:DepartmentCategory[]):DepartmentCategory[]{
            return dcs.map((dc)=>{
                return new DepartmentCategory({...dc});
            })
        }

        function addDepartmentCategoryNames(dcs:DepartmentCategory[]):string[]{
            return dcs.map((dc)=>{
                return dc.departmentCategoryName;
            })
        }

        const udcs = addDepartmentCategories(dcs);
        setDepartmentCategories(udcs);
        const dcns = addDepartmentCategoryNames(udcs);
        setDepartmentCategoryNames(dcns);

    }
   

    /*
    function relatedDepartmentCategories(departmentId:number):Promise<StoreDepartment[]> {

        return fetch(`http://localhost:9500/get-related-departments/${departmentId.toString()}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((ds:StoreDepartment[])=>{
                console.log('dcs 3-28:', ds);
                setupDepartmentInfo(ds);
                return;
            })
            .catch((err)=>{
                console.error('err:', err.message)
                return err.message;
            });


    }
    */

    function setupDepartmentImages(departmentImages:DepartmentImage[]):void {
        departmentImages.forEach((di)=>{
            //console.log('di:', di);
            //add images by category
            const si = new Image();
            //previous version
            //si.src = `${process.env.PUBLIC_URL}${di.path}`;
            si.src = `https://server.kando-proto-3.com/${di.path}`;
            //original version
            //setProductSubCategoryThumbnailImages([{img:si, name:psci.path}]);
            setDepartmentThumbnailImages([{img:si, name:getFileName(di.path)}]);
            //console.log('di.source s:', di.source);
            const sbyteCharacters = window.atob(di.source);
            const sbyteNumbers = new Array(sbyteCharacters.length);
            for (let i = 0; i < sbyteCharacters.length; i++) {
                sbyteNumbers[i] = sbyteCharacters.charCodeAt(i);
            }
            const sbyteArray = new Uint8Array(sbyteNumbers);
            const sBlob = new Blob([sbyteArray], {type:di.type});
            const sFile = new File([sBlob], di.path, {type:di.type});
            //console.log('sFile:', sFile)
            //const sBlob = new Blob([pi.source], {type:pi.type});
            //const sFile = new File([sBlob], pi.path, {type:pi.type});
            setDepartmentImages([sFile]);
        });


    }

    function getFileName(filePath:string):string {
        const lastForwardSlash = filePath.lastIndexOf('/');
        const fileName = filePath.slice(lastForwardSlash + 1);
        return fileName;
    }

    function handleDepartmentCategoryShortcut(e:React.ChangeEvent):void {
        e.stopPropagation();
        const departmentCategoryShortcutChecked = (e.target as HTMLInputElement).checked;
        //console.log('checked:', departmentCategoryShortcutChecked);
        departmentCategoryShortcutChecked ? setShowDepartmentCategoryShortcut(true) : setShowDepartmentCategoryShortcut(false);

    }


    function imageClickHandler(e:React.MouseEvent):void {
        //console.log('e.target:', e.target);
        e.preventDefault();
        //e.stopPropagation();
        imageDepartmentRef.current!.value = '';
        imageDepartmentRef.current!.click();
    }


    function handleFileUpload(e:React.ChangeEvent):void {
        //console.log('file upload:', e.target, (e.target as HTMLInputElement).files);
        const target = e.target as HTMLInputElement;
        const fileList = (e.target as HTMLInputElement).files;
        const files = Array.from(fileList!);

        //console.log('files:', files);

        if(fileList![0].size / (1024 ** 2) <= 1.5){
            addThumbNailImage(files);
            setShowDepartmentFileLimitError(false);           

        } else {
            setShowDepartmentFileLimitError(true);
        }

    }

    function addThumbNailImage(files:File[]):void {
        let fileImgs:ThumbnailImage[] = [];

        files.forEach((f)=>{
            const img = new Image();
            img.src = URL.createObjectURL(f);
            //img.height = 40;
            //img.width = 40;
            //not using this function because I need a local copy so
            //the image is found when deleting from list
            //would revoke them in general if I did need to reference a local copy
            //before saving the image to the database
            /*
            img.onload = function(){
                URL.revokeObjectURL(img.src);
            }*/
            
            fileImgs.push({name:f.name, img:img})
        });

        setDepartmentThumbnailImages([...fileImgs]);
        setDepartmentImages([...files]);



                
    }


    
    function setupDepartmentThumbnailImages(tis:ThumbnailImage[]):JSX.Element {
        //console.log('tis:', tis);
        if(tis.length === 0){
            return  <div className="product-image-search-list-container">No images selected</div>
        } else {
            return  <div className="product-image-search-list-container">
                        <ul className="product-image-search-list">
                            {tis.map((ti, k)=>{
                                return <li key={k} className="image-list-item">
                                            <img src={ti.img.src} className="add-thumbnail-image" />
                                            <span className="image-list-item-url-container">
                                                <span>{ti.name}</span>
                                                <span className="image-list-item-close-btn" onClick={(e:React.MouseEvent)=>removeImageFromList2(/*ti.img, ti.name*/)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </span>
                                            </span>
                                        </li>
                            })}
                        </ul>
                    </div>
        }

    }

    function removeImageFromList2():void {
        //remove all thumbnails
        setDepartmentThumbnailImages([]);

        //remove all images
        setDepartmentImages([]);
    }

    function removeImageFromList(img:HTMLImageElement, fileNameSelected:string):void {
        //console.log('img:', img, `/images/product-category/${fileNameSelected}`);
        
        const imageToDelete = {filePath:`/images/product-category/${fileNameSelected}`, directory:'product-category'};

        fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({msg})=>{

                    const stis = departmentThumbnailImages.filter((i)=>{
                        return i.img !== img;
                    });

                    //original version
                    //setProductSubCategoryThumbnailImages(stis);
                    //remove all thumbnails
                    setDepartmentThumbnailImages([]);

                    const sis = departmentImages.filter((f)=>{
                                    return f.name !== fileNameSelected;
                                });
                                

                    //original version
                    //setProductSubCategoryImages(sis);
                    //remove all images
                    setDepartmentImages([]);


                    //console.log('department image removed from form:', msg);
                })
                .catch((err)=>console.error('remove department image from public folder error:', err.message));

    }

    function removeImagefromDatabase(searchFilterAreaId:number, filePathDirectorySelected:string):Promise<Response> {
        //original version
        //const imageToDelete = {filePath:`${filePathSelected}`, directory:`${filePathSelectedDirectory}`};

        const imageToDelete = {searchFilterAreaId, filePathDirectorySelected};


        return fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    //body:JSON.stringify(imageToDelete),
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })

    }

    function getNextNumberId(oid:string):number {
        let id = window.parseInt(oid, 10);
        return ++id;
    }


    function handleFormSubmit(e:React.FormEvent, formAction:string):void {
        e.preventDefault();
        const form = e.target as HTMLFormElement;

        dbErrorMsgRef.current!.dbemcRef.current!.style.top = '-1000px';
        sfgErrorMsgRef.current!.sfgemcRef.current!.style.top = '-1000px';

        //rest form errors
        dnLabelRef.current!.classList.remove('product-info-error');

        diLabelRef.current!.classList.remove('image-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.imageErrors.length = 0;

        //product info
        //department name selected
        const dns = departmentNameSelected;
        const dlns = formatLinkString(dns);

        if(dns.length === 0 || dns === 'none'){
            setShowDepartmentNameLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Department Name'});

        } else {
            setShowDepartmentNameLimitError(false);
        }

        //images-groups
        //department category image
        let dImages = departmentImages;
        if(dImages.length === 0 || dImages.length > 1){
            setShowDepartmentFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Department Image'});

        } else {

            setShowDepartmentFileLimitError(false);

            const fn = dImages[0].name.toLowerCase();
            //const ufn = fn.replace(/[=?#+%^{}\[\]\\\/,<>"\*$@!|\(\)&~`]/gi, '');
            const ufn = fn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nfn = ufn.replace(/ /ig, '-');
    
            let blob = dImages[0].slice(0, dImages[0].size, dImages[0].type);
            const newFile = new File([blob], nfn, {type: blob.type});
            //remove original unfiltered file
            dImages.length = 0;
            dImages.push(newFile);
        }



        //console.log('dImages:', dImages);
        //return;

        const pies = formError.productInfoErrors.length > 0 ? true : false;
        const ies = formError.imageErrors.length > 0 ? true : false;

        if(pies || ies){

            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh, sfgemc.getBoundingClientRect().height);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;


                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }


        setShowProcessingRequestMsg(true);


        if(formAction === 'addDepartment'){

            let departmentImageName = departmentImages.length > 0 ? departmentImages[0].name : 'none';
                //departmentImageName = formatFilePathName(departmentImageName).toLowerCase();
                departmentImageName = departmentImageName.toLowerCase();

            const din = {
                //original version
                //productCategoryImageName:productCategoryImageName
                departmentImageName:departmentImageName
            };

            //console.log('din:', din);

            const urlParams = {departmentNameSelected, departmentImageName};

            const params = JSON.stringify(urlParams);

            //previous version
            //fetch(`http://localhost:9500/get-department-info/${departmentNameSelected}`, {
            fetch(`https://server.kando-proto-3.com/get-department-info`, {
                method:'POST',
                //body:JSON.stringify(din),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({departmentNameExists, departmentImageExists, lastDepartmentId, departmentId})=>{
                    //console.log('dne:', departmentNameExists);
                    //console.log('die:', departmentImageExists);
                    //console.log('ldId:', lastDepartmentId);
                    //console.log('dId:', departmentId, 'departmentNameSelected:', departmentNameSelected);
                    

                    if(departmentNameExists || departmentImageExists){

                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.departmentNameError =  {hasError:false, area:'none'};


                        if(departmentImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Department Image', fileName:departmentImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(departmentNameExists){
                            dbError.departmentNameError =  {hasError:true, area:departmentNameSelected};
                        }

                        dbError.departmentNameError = (dbError.departmentNameError!.hasError) 
                                                    ? dbError.departmentNameError 
                                                    : {hasError:false, area:'none'};

                        //console.log('dbError:', dbError);

                        const de = {...dbError};
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
            
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh, dbemc.getBoundingClientRect().height);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;

                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }

            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        //const pcId = window.parseInt(productCategoryId, 10);
                        const nextDepartmentId = getNextNumberId(lastDepartmentId);
                        
                        //create new department category filled with form data fields
                        const department = new StoreDepartment({
                                                departmentId:nextDepartmentId,
                                                departmentImage:din.departmentImageName,
                                                departmentName:dns,
                                                departmentLinkName:dlns,
                                                showDepartmentCategoryShortcut:showDepartmentCategoryShortcut
                                            }); 

                        const fd = new FormData();
                        //add product info
                        fd.append('product-info', JSON.stringify(department));

                        //add department category image
                        fd.append('department-image', departmentImages[0]);

                        //console.log('dc:', departmentCategory, departmentCategoryImages[0]);
                        

                        setIsLoading(true);

                        fetch(`https://server.kando-proto-3.com/add-individual-department`, {
                            method:'POST',
                            body:fd
                        })
                        .then((res)=>{
                            return res.json();
                        })
                        .then((res)=>{
                            //console.log('add individual department response:', res);
                            const pscs = res.pscs.map((psc:ShopCategory)=>{
                                        return addShopCategory(psc);
                                    });
                            
                            //console.log('pscs 7-11:', pscs);
                            dispatch(addProductShopCategories([...pscs]));
                            //close form update product list
                            handleCloseForm('addDepartment');
                        })
                        .catch((err)=>console.error('add individual department fetch error:', err.message));

                    }

                })
                .catch((err)=>{
                    console.error('product search err:', err)
                });
                
                
        } else {

            let departmentImageName = departmentImages.length > 0 ? departmentImages[0].name : 'none';
                //departmentImageName = formatFilePathName(departmentImageName).toLowerCase();
                departmentImageName = departmentImageName.toLowerCase();

            //console.log('result 6-7', productCategoryImageName);

            const din = {
                //original version
                //productCategoryImageName:productCategoryImageName
                departmentImageName:departmentImageName
            };

            const urlParams = {departmentNameSelected, 
                                departmentToEditId, 
                                departmentImageName};

            const params = JSON.stringify(urlParams);

            //previous version
            //fetch(`http://localhost:9500/check-department-exists/${departmentNameSelected}/${departmentToEditId}`, {
            fetch(`https://server.kando-proto-3.com/check-department-exists`, {
                method:'POST',
                //body:JSON.stringify(din),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({departmentNameExists, departmentImageExists, departmentId})=>{
                    //console.log('departmentNameExists:', departmentNameExists);
                    //console.log('departmentImageExists:', departmentImageExists);
                    //console.log('departmentId:', departmentId);
                    if(departmentNameExists || departmentImageExists){
                        
                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.departmentNameError =  {hasError:false, area:'none'};
                

                        if(departmentImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Department Image', fileName:departmentImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(departmentNameExists){
                            dbError.departmentNameError =  {hasError:true, area:departmentNameSelected};
                        }

                        dbError.departmentNameError = (dbError.departmentNameError!.hasError) 
                                                    ? dbError.departmentNameError 
                                                    : {hasError:false, area:'none'};


                        const de = {...dbError};
                        
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
        
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;

                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }

            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
        

                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        removeImagefromDatabase(departmentId, 'department')
                        .then((res)=>res.json())
                        .then(({msg})=>{
                            if(msg === 'department image removed'){
                                //console.log('department image removed from form:', msg);

                                //create new department filled with form data fields
                                const department = new StoreDepartment({
                                    departmentId:departmentId,
                                    departmentImage:din.departmentImageName,
                                    departmentName:dns,
                                    departmentLinkName:dlns,
                                    showDepartmentCategoryShortcut:showDepartmentCategoryShortcut
                                });               


                                const fd = new FormData();
                                //add product info
                                fd.append('product-info', JSON.stringify(department));

                                //add product search image
                                fd.append('department-image', departmentImages[0]);

                                //console.log('product 6-7:', departmentCategory);
                                //console.log('department-category-image 6-7:', departmentCategoryImages[0]);

                                setIsLoading(true);
                                    
                                //update product in database
                                fetch(`https://server.kando-proto-3.com/update-individual-department`, {
                                    method:'PUT',
                                    body:fd
                                    })
                                    .then((res)=>{
                                        return res.json();
                                    })
                                    .then((res)=>{
                                        const pscs = res.pscs.map((psc:ShopCategory)=>{
                                            return addShopCategory(psc);
                                        });
                                
                                        //console.log('pscs 5-8:', pscs);
                                        dispatch(addProductShopCategories([...pscs]));
                                        
                                        handleCloseForm('editDepartment');

                                    })
                                    .catch((err)=>{
                                        console.error('update individual department fetch error:', err.message);
                                        return;
                                    });

                            } else {
                                throw new Error('file was not removed.');
                            }
                            
                        })
                        .catch((err)=>{
                            console.error('remove department image from public folder error:', err.message);
                            return;
                        });




                    }
                    




                })
                .catch((err)=>console.error('check department exists fetch error:', err.message));



        }

    }

    function addShopCategory(sc:ShopCategory):ShopCategory {
        return new ShopCategory({shopCategoryId:sc.shopCategoryId,
                                shopCategoryLink:sc.shopCategoryLink,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                                });
    }

    function addSubShopCategories(sscs:SubShopCategory[]):SubShopCategory[] {
        return sscs.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });

    }

    function formatFilePathName(filePathName:string):string {
        return filePathName.search(/\//i) === -1 ? `/images/department/${filePathName}` : filePathName;
    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Department Name':
                                dnLabelRef.current!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no department info errors found!');
                        }
                    }
                });


        //set image errors
        formError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Department Image'){
                //console.log('ie.area:', ie.area);
                diLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });
        
    }

    function addDBFormLabelErrors(dbError:DBError): void {
        //set product info errors
        if(dbError.departmentNameError!.hasError){
            dnLabelRef.current!.classList.add('product-info-error');
        }
        
        //set image errors
        dbError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Department Image'){
                //console.log('ie.area:', ie.area);
                diLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });

    }

    function handleDepartmentName(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        
        //console.log('value.length:', value.length);

        if(value.length <= 50){
            setDepartmentNameSelected(value);
            setShowDepartmentNameLimitError(false);

        } else {
            setShowDepartmentNameLimitError(true);
        }

    }

    function setupFormAction(showEditDepartment:boolean):string {
        return (showEditDepartment) 
                ? 'updateDepartment'
                : 'addDepartment'
    }

    if(isLoading){
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>
    } else
    
    if(!showEditDepartment || (showEditDepartment && editDepartmentLoaded)){
        return <div>
                    {
                        !showEditDepartment
                        ?
                        <h1 className="add-dpt-category-heading">Add Department</h1>
                        :
                        <h1 className="add-dpt-category-heading">Edit Department</h1>
                    }
                    
                    <form ref={formRef} className="add-dpt-category-form" onSubmit={(e:React.FormEvent)=>handleFormSubmit(e, setupFormAction(showEditDepartment))}>
                        <div className="add-product-sub-category-input-form-group">
                            <label htmlFor="product-sub-category-name">Department Name</label>
                            <input ref={dnLabelRef} name="product-sub-category-name" type="text" 
                                id="product-sub-category-name"
                                className="form-input-control" value={departmentNameSelected} onChange={handleDepartmentName}/>
                            {
                                showDepartmentNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                :
                                null
                            }

                        </div>
                        <div className="add-product-sub-category-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={diLabelRef} htmlFor="product-image-search" onClick={imageClickHandler}><span className="product-image-search-label-link">Select Images <span className="no-color-label-link">(department)</span></span></label>
                                </div>
                                {
                                    showDepartmentFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageDepartmentRef} id="product-image-search" className="product-image-search" name="product-image-search" type="file" onChange={handleFileUpload}/>
                                {setupDepartmentThumbnailImages(departmentThumbnailImages)}

                            </div>
                        <div className="add-product-sub-category-checkbox-form-group">
                            <input className="admin-checkbox-control" ref={departmentCategoryShortcutRef} type="checkbox" checked={showDepartmentCategoryShortcut} name="show-product-sub-category-name-search" 
                                id="show-product-sub-category-name-search" onChange={handleDepartmentCategoryShortcut}/>
                            <label htmlFor="show-product-sub-category-name-search">Show department category shortcut</label>
                        </div>
                        {
                            (showEditDepartment)
                            ?   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-save-btn" type="submit" value="Save"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('editDepartment')} value="Cancel"/>

                                    {
                                        showProcessingRequestMsg
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            :   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-add-btn" type="submit" value="Add"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('addDepartment', true)} value="Cancel"/>

                                    {
                                        showProcessingRequestMsg
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                   
                        }
                        <DBErrorMessage ref={dbErrorMsgRef} dbError={dbError}/>
                        <FormErrorMessage ref={sfgErrorMsgRef} formError={formError} />

                    </form>
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    {<LoaderAnimation/>}
                </div>
    }
}


export default AddStoreDepartment;

/*
export default connect(
    null,
    mapDispatchToProps
)(AddStoreDepartment);
*/