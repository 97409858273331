import Product from './product';

export default class ProductSubCategory {
    productSubCategoryId:number;
    productSubCategoryName:string;
    productSubCategoryLinkName:string;
    productCategoryName:string;
    productCategoryId:number;
    productSubCategoryImage:string;
    departmentCategoryName:string;
    departmentName:string;
    products:Product[];
    productCategorySearchFilterName:string;
    productCategorySearchTermName:string;
    showProductSubCategoryNameSearch:boolean;
    showProductSubCategoryKeywordSearch:boolean;
    hasMatchingFilterTerm:boolean;
    hasProductsAttached:boolean;
    productSubCategoryDateAdded:Date;
    constructor(options:{
        productSubCategoryId?:number,
        productSubCategoryName?:string,
        productSubCategoryLinkName?:string,
        productCategoryName?:string,
        productCategoryId?:number,
        productSubCategoryImage?:string,
        departmentCategoryName?:string,
        departmentName?:string,
        products?:Product[],
        productCategorySearchFilterName?:string,
        productCategorySearchTermName?:string,
        showProductSubCategoryNameSearch?:boolean,
        showProductSubCategoryKeywordSearch?:boolean,
        hasMatchingFilterTerm?:boolean,
        hasProductsAttached?:boolean,
        productSubCategoryDateAdded?:Date
        }={}){
        this.productSubCategoryId = options.productSubCategoryId || 0;
        this.productSubCategoryName = options.productSubCategoryName || '';
        this.productSubCategoryLinkName = options.productSubCategoryLinkName || '';
        this.productCategoryName = options.productCategoryName || '';
        this.productCategoryId = options.productCategoryId || 0;
        this.productSubCategoryImage = options.productSubCategoryImage || '';
        this.departmentCategoryName = options.departmentCategoryName || '';
        this.departmentName = options.departmentName || '';
        this.products = options.products || [];
        this.productCategorySearchFilterName = options.productCategorySearchFilterName || '';
        this.productCategorySearchTermName = options.productCategorySearchTermName || '';
        this.showProductSubCategoryNameSearch = options.showProductSubCategoryNameSearch || false;
        this.showProductSubCategoryKeywordSearch = options.showProductSubCategoryKeywordSearch || false;
        this.hasMatchingFilterTerm = options.hasMatchingFilterTerm || false;
        this.hasProductsAttached = options.hasProductsAttached || false;
        this.productSubCategoryDateAdded = options.productSubCategoryDateAdded || new Date();
    }
}