export default function getNotApprovedProductReviews():Promise<Response> {
                    //original version with .json file
                    //return fetch('http://localhost:9500/not-approved-product-reviews', {
                    //test version with mariadb
                    return fetch('https://server.kando-proto-3.com/not-approved-product-reviews-mariadb', {
                                method:'GET',
                                headers:{
                                    'Accept':'application/json'
                                }
                            });

                }

