import React, {useEffect, useState} from 'react';
import {Link, navigate} from '@gatsbyjs/reach-router';
import { Outlet, useLocation } from "react-router-dom";
import ShopOverview from './ShopOverview';
import './Shop.css';

interface Props {
    //path:string;
    //children:React.ReactElement[];
}
//{children}
const Shop:React.FunctionComponent<Props> = (props:Props)=>{
    //const {children, path} = props;
    const location = useLocation();

    if(/^\/shop\/*$/.test(location.pathname)){
        return <div className="shop-container">
                    <ShopOverview/>      
                </div>

    } else {
        return <div className="shop-container">
                <Outlet/>       
            </div>
    }


}

export default Shop;