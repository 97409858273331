function isSearchFilterAreaNameSelected(
                            deptNameSelected:string,
                            deptCategoryNameSelected:string,
                            productCategoryNameSelected:string
                                    ):boolean {
    if(deptNameSelected === 'none' && deptCategoryNameSelected === 'none' && productCategoryNameSelected === 'none'){
        return false;
    } else {
        return true;
    }
}

export default isSearchFilterAreaNameSelected;