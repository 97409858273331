import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import './PhoneNumber.css';

interface Props {
    phoneNumber:string;
    phoneNumberId:string;
    updateStoreLocationPhoneNumber:(phoneNumber: string)=>void;
}

//previous version as a component
//const PhoneNumber: React.FunctionComponent<Props> = (props:Props) => {
type Ref = HTMLInputElement;
    //type Ref = {menuListContainer:React.RefObject<HTMLDivElement>, menuListMobileNav:React.RefObject<HTMLElement>};
    
const PhoneNumber = forwardRef<Ref, Props>((props, ref)=>{
    const {phoneNumberId, updateStoreLocationPhoneNumber} = props;
    const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber.length === 10 ? `(${props.phoneNumber.slice(0,3)}) ${props.phoneNumber.slice(3,6)}-${props.phoneNumber.slice(6,10)}` : '(___) ___-____');
    const clickedOutsideInput = useRef<boolean>(false);
    const ss = useRef<number>(0);
    const pp = useRef<number>(0);
    const pss = useRef<number>(0);
    const adj = useRef<boolean>(false);
    const step = useRef<number|string>(0);
    const begin = useRef<number>(0);
    const finish = useRef<number>(0);
    const prl = useRef<number>(0);
    const noEndSelection = useRef<boolean>(false);
    const [pressedKeys, setPressedKeys] = useState<string[]>([]);
    const pniRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, ()=>pniRef.current!);

    useEffect(()=>{
        document.addEventListener('click', documentClickHandler);

        return cleanupHandler
    });


    function documentClickHandler(e:MouseEvent):void {
        //console.log('tt:', e);
        //console.log('tt:', pniRef.current!.selectionStart, pniRef.current!.selectionEnd, pniRef.current!.value);
        begin.current = pniRef.current!.selectionStart!;
        finish.current = pniRef.current!.selectionEnd!;
        clickedOutsideInput.current = true;

        if(finish.current > begin.current && clickedOutsideInput.current){
            //console.log('tt:');
            noEndSelection.current = true;
    
        }
        
    }

    function cleanupHandler():void {
        document.removeEventListener('click', documentClickHandler);
    }


    function clickHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const pn = e.target as HTMLInputElement;
        //console.log('tt:', pn.selectionStart, pn.selectionEnd, pn.value);
        let r = pn.value.replace(/[^\d]/g, '');
        if(r.length > 10){
            r = r.slice(0,10);
        }

        if(r.length === 0){
            setPhoneNumber('(___) ___-____');
            updateStoreLocationPhoneNumber('(___) ___-____');
        } else {
            setPhoneNumber(formatString(r));
            updateStoreLocationPhoneNumber(formatString(r));
        }
        
    
        begin.current = pn.selectionStart!;
        finish.current = pn.selectionEnd!;
    
        if(((finish.current > begin.current) && (begin.current === 0 || begin.current === 4 || begin.current === 5 || begin.current === 9))
            || (finish.current === 0) || (finish.current === 5 && r.length > 0)){
            //console.log('tt:', r.length, getStringLength2(r), begin.current, finish.current);
            if((getStringLength2(r) > begin.current)){
                adj.current = true;
    
            } else {
                adj.current = false;
            }
    
            switch(begin.current){
                case 0:
                    ss.current = 1;
                    pss.current = 1;
                    finish.current = 1;
                    begin.current = 1;
                    pn.setSelectionRange(1,1);
                    break;
                case 4:
                case 5:
                    ss.current = 6;
                    pss.current = 6;
                    finish.current = 6;
                    begin.current = 6;
                    pn.setSelectionRange(6,6);
                    break;
                case 9:
                    ss.current = 10;
                    pss.current = 10;
                    finish.current = 10;
                    begin.current = 10;
                    pn.setSelectionRange(10,10)
                    break;
                default:
                    ss.current = begin.current;
                    pss.current = begin.current;
                    finish.current = begin.current;
                    pn.setSelectionRange(ss.current,ss.current);
            }
    
            return;
        }
    
    
        //console.log('tt:', r.length, getStringLength2(r), begin.current, finish.current);
        if((getStringLength2(r) === begin.current) 
            && (getStringLength2(r) === 14)
            && (begin.current === 14)){
            adj.current = false;
            ss.current = 14;
            pss.current = 14;
            pn.setSelectionRange(ss.current,ss.current);
    
        } else if(r.length > 0 && (getStringLength2(r) > begin.current) && (begin.current === finish.current)){
            //console.log('tt:', begin.current);
            adj.current = true;
            switch(begin.current){
                case 0:
                    ss.current = 1;
                    pss.current = 1;
                    pn.setSelectionRange(1,1);
                    break;
                case 4:
                case 5:
                    ss.current = 6;
                    pss.current = 6;
                    pn.setSelectionRange(6,6);
                    break;
                case 9:
                    ss.current = 10;
                    pss.current = 10;
                    pn.setSelectionRange(10,10)
                    break;
                default:
                    ss.current = begin.current;
                    pss.current = begin.current;
                    pn.setSelectionRange(ss.current,ss.current);
            }
            //ss = begin;
        } else if(r.length > 0 && (getStringLength2(r) > begin.current) && (getStringLength2(r) > finish.current)
                    && (finish.current > begin.current)){
            //console.log('tt:', begin.current, finish.current, ss.current);
            adj.current = true;
            switch(begin.current){
                case 0:
                    ss.current = 1;
                    pss.current = 1;
                    pn.setSelectionRange(1,1);
                    break;
                case 4:
                case 5:
                    ss.current = 6;
                    pss.current = 6;
                    pn.setSelectionRange(6,6);
                    break;
                case 9:
                    ss.current = 10;
                    pss.current = 10;
                    pn.setSelectionRange(10,10)
                    break;
                default:
                    ss.current = begin.current;
                    pss.current = begin.current;
                    pn.setSelectionRange(ss.current,finish.current);
            }             
    
        } else {
            adj.current = false;
            //console.log('tt:', r.length, getStringLength2(r), begin.current, finish.current, ss.current, pss.current);
            if(r.length === 0){
                pn.setSelectionRange(1,1);
                ss.current = 1;
                pss.current = 1;
                return;
            }
    
            if(r.length === 1 && begin.current >= 2){
                pn.setSelectionRange(r.length + 1, r.length + 1);
                ss.current = 2;
                pss.current = 2;
                return;            
            }
    
            if(r.length === 2 && begin.current >= 3){
                pn.setSelectionRange(r.length + 1, r.length + 1);
                ss.current = 3;
                pss.current = 3;
                return;            
            }
    
            if(r.length === 3 && begin.current >= 4){
                pn.setSelectionRange(r.length + 3, r.length + 3);
                ss.current = 6
                pss.current = 6;
                return;
            }
            
            if(r.length === 4 && begin.current >= 7){
                pn.setSelectionRange(r.length + 3, r.length + 3);
                ss.current = 7;
                pss.current = 7;
                return;
            }
    
            if(r.length === 5 && begin.current >= 8){
                pn.setSelectionRange(r.length + 3, r.length + 3);
                ss.current = 8;
                pss.current = 8;
                return;
            }
            
            if(r.length === 6 && begin.current >= 9){
                pn.setSelectionRange(r.length + 4, r.length + 4);
                ss.current = 10;
                pss.current = 10;
                return;
            }
    
            if(r.length === 7 && begin.current >= 11){
                pn.setSelectionRange(r.length + 4, r.length + 4);
                ss.current = 11;
                pss.current = 11;
                return;
            } 
    
            if(r.length === 8 && begin.current >= 12){
                pn.setSelectionRange(r.length + 4, r.length + 4);
                ss.current = 12;
                pss.current = 12;
                return;
            }
    
            if(r.length === 9 && begin.current >= 13){
                pn.setSelectionRange(r.length + 4, r.length + 4);
                ss.current = 13;
                pss.current = 13;
                return;
            }
    
            if(r.length === 10 && begin.current >= 14){
                pn.setSelectionRange(r.length + 4, r.length + 4);
                ss.current = 14;
                pss.current = 14;
                return;
            }
    
            if(((r.length === 3) || (r.length === 6)) && (begin.current < getStringLength2(r)) && (finish.current >= getStringLength2(r))
                && begin.current !== 6){
                //console.log('tt:', begin.current, finish.current);
                ss.current = begin.current;
                pss.current = begin.current;
                return;
            }
        }
    
        //formatPhoneNumber(e);
    
    }

    function selectHandler(e:React.SyntheticEvent):void {
        const pn = e.target as HTMLInputElement;
        //console.log('tt:', begin.current, finish.current, clickedOutsideInput.current);

        //formatPhoneNumber(e);
        //previous version
        //if(pn.selectionEnd! > pn.selectionStart! && clickedOutsideInput.current){
        /*previous version
        if(finish.current > begin.current && clickedOutsideInput.current){
            console.log('tt:');
            noEndSelection.current = true;
    
        } else {
            clickedOutsideInput.current = false;
            noEndSelection.current = false;
        }
        */

        clickedOutsideInput.current = false;
        noEndSelection.current = false;
    
    }

    function getArrowRightGreaterPosition(tss:number, rLength:number){
        //console.log('key:', tss, rLength);
        switch(rLength){
            case 1:
                return tss === 2 ? true : false;
            case 2:
                return tss === 3 ? true : false;
            case 3:
                return tss === 6 ? true : false;
            case 4:
                return tss === 7 ? true : false;
            case 5:
                return tss === 8 ? true : false;
            case 6:
                return tss === 10 ? true : false;
            case 7:
                return tss === 11 ? true : false;
            case 8:
                return tss === 12 ? true : false;
            case 9:
                return tss === 13 ? true : false;
            case 10:
                return tss === 14 ? true : false;
            default:
                throw new Error('no position found.');
        }
    }

    function getArrowRightLessPosition(tss:number, rLength:number){
        //console.log('key:', tss, rLength);
        switch(rLength){
            case 1:
                adj.current = false;
                //console.log('key:', adj.current);
                return tss === 1 ? true : false;
            case 2:
                if(tss === 2){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 2 ? true : false;
            case 3:
                if(tss === 3){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 3 ? true : false;
            case 4:
                if(tss === 6){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 6 ? true : false;
            case 5:
                if(tss === 7){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 7 ? true : false;
            case 6:
                if(tss === 8){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 8 ? true : false;
            case 7:
                if(tss === 10){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 10 ? true : false;
            case 8:
                if(tss === 11){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 11 ? true : false;
            case 9:
                if(tss === 12){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 12 ? true : false;
            case 10:
                if(tss === 13){
                    adj.current = false;
                    //console.log('key:', adj.current);
                }
                return tss <= 13 ? true : false;
            default:
                throw new Error('no position found.');
        }
    }

    function keydownHandler(e:React.KeyboardEvent):void{
        const pn = e.target as HTMLInputElement;
        const tss = pn.selectionStart!;
        

        //console.log('key:', e.key, pn.selectionStart);

        let r = pn.value.replace(/[^\d]/g, '');
        if(r.length > 10){
            r = r.slice(0,10);
        }

        if(r.length === 0){
            setPhoneNumber('(___) ___-____');
            updateStoreLocationPhoneNumber('(___) ___-____');

        } else {
            setPhoneNumber(formatString(r));
            updateStoreLocationPhoneNumber(formatString(r));
        }
        
    
    
        /*use this for selecting mulitple keys at the 
        same time
        ex)Shift + ArrowLeft
        const keysFound = pressedKeys.filter((pk)=>{
                                    return pk === e.key ;
                                });
    
        if(keysFound.length === 0){
            pressedKeys.push(e.key);
        }
    
        console.log('key:', pressedKeys, tss, ss);
        */
    
    
        if((r.length === 0) && (e.key === 'Delete')){
            //console.log('key:');
            setTimeout(() => {
                pn.setSelectionRange(1,1);
                ss.current = 1;
            }, 0);
    
        }
    
    
        //console.log('key:', r.length, tss, ss);
        //previous version
        //(tss > r.length)
        if((r.length > 0) && (e.key === 'ArrowRight') && getArrowRightGreaterPosition(tss, r.length)){
            //console.log('key:', r.length, tss, ss);
            switch(r.length){
                case 1:
                    setTimeout(() => {
                        pn.setSelectionRange(2,2);
                    },0);
                    ss.current = 2;
                    break;
                case 2:
                    setTimeout(() => {
                        pn.setSelectionRange(3,3);
                    },0);
                    ss.current = 3;
                    break;
                case 3:
                    setTimeout(() => {
                        pn.setSelectionRange(6, 6);
                    },0);
                    ss.current = 6;
                    break;
                case 4:
                    setTimeout(() => {
                        pn.setSelectionRange(7,7);
                    },0);
                    ss.current = 7;
                    break;
                case 5:
                    setTimeout(() => {
                        pn.setSelectionRange(8,8);
                    },0);
                    ss.current = 8;
                    break;
                case 6:
                    setTimeout(() => {
                        pn.setSelectionRange(10,10);
                    },0);
                    ss.current = 10;
                    break;
                case 7:
                    setTimeout(() => {
                        pn.setSelectionRange(11,11);
                    },0);
                    ss.current = 11;
                    break;
                case 8:
                    setTimeout(() => {
                        pn.setSelectionRange(12,12);
                    },0);
                    ss.current = 12;
                    break;
                case 9:
                    setTimeout(() => {
                        pn.setSelectionRange(13,13);
                    },0);
                    ss.current = 13;
                    break;
                case 10:
                    setTimeout(() => {
                        pn.setSelectionRange(14,14);
                    },0);
                    ss.current = 14;
                    break;
                default:
                    throw new Error('no position found.');
                
            }
    
        }
        
        //previous version
        //(tss <= r.length)
        if((r.length > 0) && (e.key === 'ArrowRight') && getArrowRightLessPosition(tss, r.length)){
            //console.log('key:', tss, ss);
            switch(ss.current){
                case 1:
                    setTimeout(() => {
                        pn.setSelectionRange(2, 2);
                    },0);
                    ss.current = 2;
                    break;
                case 2:
                    setTimeout(() => {
                        pn.setSelectionRange(3, 3);
                    },0);
                    ss.current = 3;
                    break;
                case 3:
                    setTimeout(() => {
                        pn.setSelectionRange(6, 6);
                    },0);
                    ss.current = 6;
                    break;
                case 6:
                    setTimeout(() => {
                        pn.setSelectionRange(7,7);
                    },0);
                    ss.current = 7;
                    break;
                case 7:
                    setTimeout(() => {
                        pn.setSelectionRange(8,8);
                    },0);
                    ss.current = 8;
                    break;
                case 8:
                    setTimeout(() => {
                        pn.setSelectionRange(10,10);
                    },0);
                    ss.current = 10;
                    break;
                case 10:
                    setTimeout(() => {
                        pn.setSelectionRange(11,11);
                    },0);
                    ss.current = 11;
                    break;
                case 11:
                    setTimeout(() => {
                        pn.setSelectionRange(12,12);
                    },0);
                    ss.current = 12;
                    break;
                case 12:
                    setTimeout(() => {
                        pn.setSelectionRange(13,13);
                    },0);
                    ss.current = 13;
                    break;
                case 13:
                    setTimeout(() => {
                        pn.setSelectionRange(14,14);
                    },0);
                    ss.current = 14;
                    break;
                default:
                    throw new Error('no position found.');
                
            }
    
        }
    
    
        /*
        if(r.length > 0 && e.key === 'ArrowLeft' && tss === 1){
            console.log('key:', tss, ss);
            setTimeout(() => {
                pn.setSelectionRange(1,1);
            },0);
            ss = 1;
        }
        */
    
        if((r.length > 0) && (e.key === 'ArrowLeft')){
            adj.current = true;
            //console.log('key:', tss, ss.current, adj.current);
            switch(ss.current){
                case 14:
                    setTimeout(() => {
                        pn.setSelectionRange(13, 13);
                    },0);
                    ss.current = 13;
                    break;
                case 13:
                    setTimeout(() => {
                        pn.setSelectionRange(12, 12);
                    },0);
                    ss.current = 12;
                    break;
                case 12:
                    setTimeout(() => {
                        pn.setSelectionRange(11, 11);
                    },0);
                    ss.current = 11;
                    break;
                case 11:
                    setTimeout(() => {
                        pn.setSelectionRange(10, 10);
                    },0);
                    ss.current = 10;
                    break;
                case 10:
                    setTimeout(() => {
                        pn.setSelectionRange(8,8);
                    },0);
                    ss.current = 8;
                    break;
                case 8:
                    setTimeout(() => {
                        pn.setSelectionRange(7,7);
                    },0);
                    ss.current = 7;
                    break;
                case 7:
                    setTimeout(() => {
                        pn.setSelectionRange(6,6);
                    },0);
                    ss.current = 6;
                    break;
                case 6:
                    setTimeout(() => {
                        pn.setSelectionRange(3,3);
                    },0);
                    ss.current = 3;
                    break;
                case 3:
                    setTimeout(() => {
                        pn.setSelectionRange(2,2);
                    },0);
                    ss.current = 2;
                    break;
                case 2:
                    setTimeout(() => {
                        pn.setSelectionRange(1,1);
                    },0);
                    ss.current = 1;
                    break;
                case 1:
                    //console.log('key:', tss, ss);
                    setTimeout(() => {
                        pn.setSelectionRange(1,1);
                    },0);
                    ss.current = 1;
                    break;
                default:
                    throw new Error('no position found.');
                
            }
    
        }
        
    
        
        if(r.length === 0 && ((e.key === 'ArrowUp') || (e.key === 'ArrowDown')
            || (e.key === 'ArrowLeft') || (e.key === 'ArrowRight')
            || (e.key === 'Home') || (e.key === 'End'))){
            //console.log('key:');
            setTimeout(() => {
                pn.setSelectionRange(1,1);
                ss.current = 1;
            }, 0);
    
        }
    
        if(r.length > 0 && ((e.key === 'ArrowUp') || (e.key === 'Home'))){
            adj.current = true;
            //console.log('key:', r.length, tss, ss.current);
            setTimeout(()=>{
                pn.setSelectionRange(1, 1);
                ss.current = 1;
            },0);
        }
    
        if(r.length > 0 && ((e.key === 'ArrowDown') || (e.key === 'End'))){
            //console.log('key:', r.length, tss, ss.current);
            adj.current = false;
            //ex. 3,4,5 & 9
            //use a switch
            switch(r.length){
                case 1:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 1, r.length + 1);
                        ss.current = 2;
                    },0);
                    break;
                case 2:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 1, r.length + 1);
                        ss.current = 3;
                    },0);
                    break;
                case 3:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 3, r.length + 3);
                        ss.current = 6;
                    },0);
                    break;
                case 4:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 3, r.length + 3);
                        ss.current = 7;
                    },0);
                    break;
                case 5:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 3, r.length + 3);
                        ss.current = 8;
                    },0);
                    break;
                case 6:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 4, r.length + 4);
                        ss.current = 10;
                    },0);
    
                    break;
                case 7:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 4, r.length + 4);
                        ss.current = 11;
                    },0);
                    break;
                case 8:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 4, r.length + 4);
                        ss.current = 12;
                    },0);
                    break;
                case 9:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 4, r.length + 4);
                        ss.current = 13;
                    },0);
                    break;
                case 10:
                    setTimeout(()=>{
                        pn.setSelectionRange(r.length + 4, r.length + 4);
                        ss.current = 14;
                    },0);
                    break;
                default:
                    throw new Error('No position found.');
            }
    
        }
    
    
    }

    function changeHandler(e:React.ChangeEvent):void{
        //console.log('input:', e.target.selectionStart);
        //css = e.target.selectionStart;
        //console.log('ss:', css, pss);
        //formatPhoneNumber(e);
        formatPhoneNumber2(e);
    
    }

    //format #2
    function formatPhoneNumber2(e:React.ChangeEvent){
        //console.log('e:', e);
        let input = e.target as HTMLInputElement;
        const inputType = (e.nativeEvent as InputEvent).inputType;
        const charSelected = (e.nativeEvent as InputEvent).data!;
        let r = input.value.replace(/[^\d]/g, '');
        if(r.length > 10){
            r = r.slice(0, 10);
        }
        
        //console.log('tt:', input.value, charSelected);
        //console.log('tt:', inputType, r.length, input.selectionStart, ss.current, begin.current, finish.current, pss.current, adj.current, noEndSelection.current);
        const tss = input.selectionStart!;
        //previous version
        //let inputType = e.inputType;
        input.value = r;


        //highlight numbers without having an end point
        if(inputType === 'insertText' && noEndSelection.current && (r.length === 0)){
            //console.log('tt:', r.length, tss, begin.current, finish.current);
            input.value = '(___) ___-____';
            setPhoneNumber(input.value);
            updateStoreLocationPhoneNumber(input.value);
            ss.current = 1;
            pss.current = 1;
            begin.current = 1;
            finish.current = 1;
            noEndSelection.current = false;
            input.setSelectionRange(1,1);

            return;
        }


        if(inputType === 'insertText' && noEndSelection.current && r.length > 0){
            //console.log('tt:', r.length, tss, begin.current, finish.current);
            const value = (tss > r.length) ? r.length : tss;
            //console.log('tt:', value);
            switch((tss > r.length) ? r.length : tss){
                case 1:
                    if(value === tss && (r.length > 1 || input.value === ' ' || /[^\d]/gi.test(charSelected))){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    //console.log('tt:', adj.current);
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    if(/[^\d]/gi.test(charSelected) && adj.current){
                        ss.current = 1;
                        pss.current = 1;
                        begin.current = 1;
                        finish.current = 1;
                        noEndSelection.current = false;
                        input.setSelectionRange(1,1);
                    } else {
                        ss.current = 2;
                        pss.current = 2;
                        begin.current = 2;
                        finish.current = 2;
                        noEndSelection.current = false;
                        input.setSelectionRange(2,2);
                    }

                    break;
                case 2:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 3;
                    pss.current = 3;
                    begin.current = 3;
                    finish.current = 3;
                    noEndSelection.current = false;
                    input.setSelectionRange(3,3);
                    break;
                case 3:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 6;
                    pss.current = 6;
                    begin.current = 6;
                    finish.current = 6;
                    noEndSelection.current = false;
                    input.setSelectionRange(6,6);
                    break;
                case 4:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 7;
                    pss.current = 7;
                    begin.current = 7;
                    finish.current = 7;
                    noEndSelection.current = false;
                    input.setSelectionRange(7,7);
                    break;
                case 5:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 8;
                    pss.current = 8;
                    begin.current = 8;
                    finish.current = 8;
                    noEndSelection.current = false;
                    input.setSelectionRange(8,8);
                    break;
                case 6:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value)
                    ss.current = 10;
                    pss.current = 10;
                    begin.current = 10;
                    finish.current = 10;
                    noEndSelection.current = false;
                    input.setSelectionRange(10,10);
                    break;
                case 7:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 11;
                    pss.current = 11;
                    begin.current = 11;
                    finish.current = 11;
                    noEndSelection.current = false;
                    input.setSelectionRange(11,11);
                    break;
                case 8:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 12;
                    pss.current = 12;
                    begin.current = 12;
                    finish.current = 12;
                    noEndSelection.current = false;
                    input.setSelectionRange(12,12);
                    break;
                case 9:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 13;
                    pss.current = 13;
                    begin.current = 13;
                    finish.current = 13;
                    noEndSelection.current = false;
                    input.setSelectionRange(13,13);
                    break;
                case 10:
                    if(value === tss){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 14;
                    pss.current = 14;
                    begin.current = 14;
                    finish.current = 14;
                    noEndSelection.current = false;
                    input.setSelectionRange(14,14);
                    break;
                default:
                    throw new Error('no position found.');

            }

            return;
        }


        //highlight numbers without having an end point
        if(inputType === 'deleteContentBackward' && noEndSelection.current && (r.length === 0)){
            //console.log('tt:', r.length, tss, begin.current, finish.current);
            input.value = '(___) ___-____';
            setPhoneNumber(input.value);
            updateStoreLocationPhoneNumber(input.value);
            ss.current = 1;
            pss.current = 1;
            begin.current = 1;
            finish.current = 1;
            noEndSelection.current = false;
            adj.current = false;
            input.setSelectionRange(1,1);

            return;
        }


        if(inputType === 'deleteContentBackward' && noEndSelection.current && r.length > 0){
            //console.log('tt:', r.length, tss, begin.current, finish.current);
            const value = (tss > r.length) ? r.length : tss;
            //console.log('tt:', value);
            switch((tss > r.length) ? r.length : tss){
                case 0:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    //console.log('tt:', adj);
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 1;
                    pss.current = 1;
                    begin.current = 1;
                    finish.current = 1;
                    noEndSelection.current = false;
                    input.setSelectionRange(1,1);
                    break;
                case 1:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    //console.log('tt:', adj.current);
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 2;
                    pss.current = 2;
                    begin.current = 2;
                    finish.current = 2;
                    noEndSelection.current = false;
                    input.setSelectionRange(2,2);
                    break;
                case 2:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 3;
                    pss.current = 3;
                    begin.current = 3;
                    finish.current = 3;
                    noEndSelection.current = false;
                    input.setSelectionRange(3,3);
                    break;
                case 3:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 6;
                    pss.current = 6;
                    begin.current = 6;
                    finish.current = 6;
                    noEndSelection.current = false;
                    input.setSelectionRange(6,6);
                    break;
                case 4:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 7;
                    pss.current = 7;
                    begin.current = 7;
                    finish.current = 7;
                    noEndSelection.current = false;
                    input.setSelectionRange(7,7);
                    break;
                case 5:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 8;
                    pss.current = 8;
                    begin.current = 8;
                    finish.current = 8;
                    noEndSelection.current = false;
                    input.setSelectionRange(8,8);
                    break;
                case 6:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 10;
                    pss.current = 10;
                    begin.current = 10;
                    finish.current = 10;
                    noEndSelection.current = false;
                    input.setSelectionRange(10,10);
                    break;
                case 7:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 11;
                    pss.current = 11;
                    begin.current = 11;
                    finish.current = 11;
                    noEndSelection.current = false;
                    input.setSelectionRange(11,11);
                    break;
                case 8:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r); 
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 12;
                    pss.current = 12;
                    begin.current = 12;
                    finish.current = 12;
                    noEndSelection.current = false;
                    input.setSelectionRange(12,12);
                    break;
                case 9:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 13;
                    pss.current = 13;
                    begin.current = 13;
                    finish.current = 13;
                    noEndSelection.current = false;
                    input.setSelectionRange(13,13);
                    break;
                case 10:
                    if(value === tss && value < r.length){
                        adj.current = true;
                    } else {
                        adj.current = false;
                    }
                    input.value = formatString(r);
                    setPhoneNumber(input.value); 
                    updateStoreLocationPhoneNumber(input.value);
                    ss.current = 14;
                    pss.current = 14;
                    begin.current = 14;
                    finish.current = 14;
                    noEndSelection.current = false;
                    input.setSelectionRange(14,14);
                    break;
                default:
                    throw new Error('no position found.');

            }

            return;
        }

        
        //a character is typed that is not a number
        //need to maintain existing position
        if(inputType === 'insertText' && /[^\d]/g.test(charSelected)){
            if(r.length === 0){
                input.value = '(___) ___-____';
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);

            } else {
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
            }

            if(finish.current > begin.current && tss === 1){
                input.setSelectionRange(1,1);
                ss.current = 1;
                pss.current = 1;
                begin.current = 1;
                finish.current = 1;

            } else {
                input.setSelectionRange(tss - 1,tss - 1);
                ss.current = tss - 1;
                pss.current = tss - 1;
                begin.current = tss - 1;
                finish.current = tss - 1;
                //console.log('stop:', tss - 1, formatString(r));
            }
            

            return;
        }


        if(inputType === 'insertText' && input.value === ' '){
            //console.log('tt:');
            switch(tss){
                case 2:
                    input.value = '(___) ___-____';
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(1,1);
                    ss.current = 1;
                    pss.current = 1;
                    break;
                case 3:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(2,2);
                    ss.current = 2;
                    pss.current = 2;
                    break;
                case 4:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(3,3);
                    ss.current = 3;
                    pss.current = 3;
                    break;
                case 7:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(6,6);
                    ss.current = 6;
                    pss.current = 6;
                    break;
                case 8:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(7,7);
                    ss.current = 7;
                    pss.current = 7;
                    break;
                case 9:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(8,8);
                    ss.current = 8;
                    pss.current = 8;
                    break;
                case 11:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(10,10);
                    ss.current = 10;
                    pss.current = 10;
                    break;
                case 12:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(11,11);
                    ss.current = 11;
                    pss.current = 11;
                    break;
                case 13:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(12,12);
                    ss.current = 12;
                    pss.current = 12;
                    break;
                case 14:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(13,13);
                    ss.current = 13;
                    pss.current = 13;
                    break;
                case 15:
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(14,14);
                    ss.current = 14;
                    pss.current = 14;
                    break;                
                default:
                    throw new Error('no position found.');
            }


            return;
            
        }


        if(inputType === 'deleteContentForward' && !adj.current){
            if(r.length === 0){
                input.value = '(___) ___-____';
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);

            } else {
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
            }


            input.setSelectionRange(tss,tss);
            ss.current = tss;
            pss.current = tss;
            begin.current = tss;
            finish.current = tss;
            //console.log('stop:', tss, formatString(r));
        
            
            return;
        }


        //console.log('r:', adj.current);

        if(adj.current){
            //console.log('tt:', r.length, getStringLength2(r), tss, ss.current, finish.current);
            if(inputType === 'deleteContentForward'){
                switch(ss.current){
                    case 1:
                        if(r.length === 0){
                            input.value = '(___) ___-____';
                            setPhoneNumber(input.value);
                            updateStoreLocationPhoneNumber(input.value);
                            adj.current = false;
                        } else {
                            input.value = formatString(r);
                            setPhoneNumber(input.value);
                            updateStoreLocationPhoneNumber(input.value);
                        }
                        
                        input.setSelectionRange(1,1);
                        ss.current = 1;
                        pss.current = 1;
                        break;
                    case 2:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(2,2);
                        ss.current = 2;
                        pss.current = 2;
                        break;
                    case 3:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(3,3);
                        ss.current = 3;
                        pss.current = 3;
                       //console.log('tt:');
                        break;
                    case 6:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(6,6);
                        ss.current = 6;
                        pss.current = 6;
                        break;
                    case 7:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(7,7);
                        ss.current = 7;
                        pss.current = 7;
                        break;
                    case 8:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(8,8);
                        ss.current = 8;
                        pss.current = 8;
                        break;
                    case 10:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(10,10);
                        ss.current = 10;
                        pss.current = 10;
                        break;
                    case 11:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(11,11);
                        ss.current = 11;
                        pss.current = 11;
                        break;
                    case 12:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(12,12);
                        ss.current = 12;
                        pss.current = 12;
                        break;
                    case 13:
                        if(ss.current >= getStringLength2(r)){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(13,13);
                        ss.current = 13;
                        pss.current = 13;
                        break;
                    default:
                        throw new Error('no position found.');
                }

                return;
                
            }


            if(finish.current > begin.current && inputType === 'insertText'){
                //console.log('tt:', r.length, prl.current, tss, ss.current, finish.current, getStringLength2(r));
                switch(ss.current){
                    case 1:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(2,2);
                        begin.current = 2;
                        finish.current = 2;
                        ss.current = 2;
                        pss.current = 2;
                        break;
                    case 2:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(3,3);
                        begin.current = 3;
                        finish.current = 3;
                        ss.current = 3;
                        pss.current = 3;
                        //console.log('tt:');
                        break;
                    case 3:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(6,6);
                        begin.current = 6;
                        finish.current = 6;
                        ss.current = 6;
                        pss.current = 6;
                        break;
                    case 6:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(7,7);
                        begin.current = 7;
                        finish.current = 7;
                        ss.current = 7;
                        pss.current = 7;
                        break;
                    case 7:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(8,8);
                        begin.current = 8;
                        finish.current = 8;
                        ss.current = 8;
                        pss.current = 8;
                        break;
                    case 8:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(10,10);
                        begin.current = 10;
                        finish.current = 10;
                        ss.current = 10;
                        pss.current = 10;
                        break;
                    case 10:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(11,11);
                        begin.current = 11;
                        finish.current = 11;
                        ss.current = 11;
                        pss.current = 11;
                        break;
                    case 11:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(12,12);
                        begin.current = 12;
                        finish.current = 12;
                        ss.current = 12;
                        pss.current = 12;
                        break;
                    case 12:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(13,13);
                        begin.current = 13;
                        finish.current = 13;
                        ss.current = 13;
                        pss.current = 13;
                        break;
                    case 13:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(14,14);
                        begin.current = 14;
                        finish.current = 14;
                        ss.current = 14;
                        pss.current = 14;
                        break;
                    default:
                        throw new Error('no position found.')                  ;
                }
                
                return;
                
            }



            if(finish.current > begin.current && inputType === 'deleteContentBackward'){
                //console.log('tt:', r.length, prl.current, ss.current, begin.current, finish.current, getStringLength2(r));
                switch(ss.current){
                    case 1:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(1,1);
                        finish.current = 1;
                        ss.current = 1;
                        pss.current = 1;
                        break;
                    case 2:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(2,2);
                        finish.current = 2;
                        ss.current = 2;
                        pss.current = 2;
                        break;
                    case 3:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(3,3);
                        finish.current = 3;
                        ss.current = 3;
                        pss.current = 3;
                        break;
                    case 6:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(6,6);
                        finish.current = 6;
                        ss.current = 6;
                        pss.current = 6;
                        break;
                    case 7:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(7,7);
                        finish.current = 7;
                        ss.current = 7;
                        pss.current = 7;
                        break;
                    case 8:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(8,8);
                        finish.current = 8;
                        ss.current = 8;
                        pss.current = 8;
                        break;
                    case 10:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(10,10);
                        finish.current = 10;
                        ss.current = 10;
                        pss.current = 10;
                        break;
                    case 11:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(11,11);
                        finish.current = 11;
                        ss.current = 11;
                        pss.current = 11;
                        break;
                    case 12:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(12,12);
                        finish.current = 12;
                        ss.current = 12;
                        pss.current = 12;
                        break;
                    case 13:
                        if(finish.current >= prl.current){
                            adj.current = false;
                        }
                        input.value = formatString(r);
                        setPhoneNumber(input.value);
                        updateStoreLocationPhoneNumber(input.value);
                        input.setSelectionRange(13,13);
                        finish.current = 13;
                        ss.current = 13;
                        pss.current = 13;
                        break;
                    default:
                        throw new Error('no position found.')                  ;
                }
                
                return;
                
            }

            
            if((tss === 5 && ss.current === 4) || (tss < ss.current)){
                //console.log('tt:', tss, ss.current);
                
                switch(ss.current){
                    case 13:
                        //console.log('tt:');
                        step.current = '9a';
                        ss.current -= 1;
                        break;
                    case 12:
                        //console.log('tt:');
                        step.current = '8a';
                        ss.current -= 1;
                        break;
                    case 11:
                        //console.log('tt:');
                        step.current = '7a';
                        ss.current -= 1;
                        break;
                    case 10:
                        //console.log('tt:');
                        step.current = '6a';
                        ss.current -= 2;
                        break;
                    case 8:
                        step.current = '5a';
                        ss.current -= 1;
                        break;
                    case 7:
                        step.current = '4a';
                        ss.current -= 3;
                        break;
                    case 6:
                        step.current = '3a';
                        ss.current -= 3;
                        break;
                    case 4:
                        step.current = '3a';
                        ss.current -= 1;
                        break;
                    case 3:
                        step.current = '2a';
                        ss.current -= 1;
                        break;
                    case 2:
                        step.current = '1a';
                        ss.current -= 1;
                        break;
                    case 1:
                        //step = '0a';
                        //console.log('tt:');
                        step.current = '1a';
                        ss.current = 1;
                        break;              
                    default:
                        throw new Error('no steps found.');
                }

            } else {
                //(tss > ss)
                //console.log('tt:', tss, ss.current);

                switch(ss.current){
                    case 1:
                        step.current = 1;
                        ss.current += 1;
                        break;
                    case 2:
                        //console.log('ss:');
                        step.current = 2;
                        ss.current += 1;
                        break;
                    case 3:
                        step.current = 3;
                        ss.current += 3;
                        break;
                    case 6:
                        step.current = 4;
                        ss.current += 1;
                        break;
                    case 7:
                        step.current = 5;
                        ss.current += 1;
                        break;
                    case 8:
                        step.current = 6;
                        //ss += 1;
                        ss.current += 2;
                        break;
                    //case 9:
                    case 10:
                        //console.log('tt:');
                        step.current = 7;
                        ss.current += 1;
                        break;
                    case 11:
                        //console.log('ss:');
                        step.current = 8;
                        ss.current += 1;
                        break;
                    case 12:
                        //console.log('ss:');
                        step.current = 9;
                        ss.current += 1;
                        break;
                    case 13:
                        //console.log('ss:');
                        step.current = 10;
                        ss.current += 1;
                        adj.current = false;
                        break;
                    default:
                        throw new Error('no step found.');
                }
                
            }

                


            //console.log('tt:', step.current, ss.current);

            if(step.current === 0){
                input.value = `(___) ___-____`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current,step.current);
                //console.log('ss:', input.selectionStart);
                pp.current = 0;
                pss.current = input.selectionStart!;
            }

            if(step.current === '0a'){
                if(r.length > 0){
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                } else {
                    input.value = `(___) ___-____`;
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                }
                
                input.setSelectionRange(1,1);
                //console.log('ss:', input.selectionStart);
                pp.current = 0;
                pss.current = input.selectionStart!;
                adj.current = false;
            }
        
            if(step.current === 1){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current + 1,step.current + 1);
                //console.log('ss:', input.selectionStart);
                pp.current = 1;
                //cp = r.length + 1;
                pss.current = input.selectionStart!;
            }

            if(step.current === '1a'){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(1,1);
                //console.log('ss:', input.selectionStart);
                pp.current = 1;
                //cp = r.length + 1;
                pss.current = input.selectionStart!;
            }
        
            if(step.current === 2){
                //console.log('r:', r.length);
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current + 1,step.current + 1);
                //console.log('ss:', input.selectionStart);
                pp.current = 2;
                //cp = r.length + 1;
                pss.current = input.selectionStart!;
            }

            if(step.current === '2a'){
                //console.log('tt:', r.length);
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(2,2);
                //console.log('ss:', input.selectionStart);
                pp.current = 2;
                //cp = r.length + 1;
                pss.current = input.selectionStart!;
            }
        
            if(step.current === 3){
                //console.log('r:', pss.current);
                /*if(pss === 6){
                    e.target.value = `(${r.slice(0,2)}_) ___-____`;
                    input.setSelectionRange(r.length,r.length);
                    pp = 2;
                    //cp = r.length;
                    pss = input.selectionStart;
                } else {*/
                    //console.log('ssss:', r.length, formatString(r));
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(step.current + 3,step.current + 3);
                    pp.current = 3;
                    //cp = r.length + 3;
                    pss.current = input.selectionStart!;
                //}
        
            }

            if(step.current === '3a'){
                //console.log('tt:', r, r.length, formatString(r.slice(0,3)));
                /*previous version
                if(r.length === 5){
                    e.target.value = formatString(r.slice(0,4));
                }

                if(r.length === 4){
                    e.target.value = formatString(r.slice(0,3));
                }
                */
            const mr = r.slice(0,2).concat(r.slice(3));

                input.value = formatString(mr);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
            
                
                input.setSelectionRange(3,3);
                pp.current = 3;
                //cp = r.length + 3;
                pss.current = input.selectionStart!;
            }

        
            if(step.current === 4){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current + 3,step.current + 3);
                pp.current = 4;
                //cp = r.length + 3;
                pss.current = input.selectionStart!;
            }

            if(step.current === '4a'){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(6,6);
                pp.current = 4;
                //cp = r.length + 3;
                pss.current = input.selectionStart!;
            }
        
            if(step.current === 5){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current + 3,step.current + 3);
                pp.current = 5;
                //cp = r.length + 3;
                pss.current = input.selectionStart!;
            }

            if(step.current === '5a'){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(7,7);
                pp.current = 5;
                //cp = r.length + 3;
                pss.current = input.selectionStart!;
            }
        
        
            if(step.current === 6){
                //console.log('r:', pss.current);
                /*if(pss === 10){
                    e.target.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
                    input.setSelectionRange(r.length + 2,r.length + 2);
                    pp = 5;
                    //cp = r.length + 2;
                    pss = input.selectionStart;
                } else {*/
                    input.value = formatString(r);
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(step.current + 4,step.current + 4);
                    pp.current = 6;
                    //cp = r.length + 4;
                    pss.current = input.selectionStart!;
                //}
        
            }

            if(step.current === '6a'){
                //console.log('tt:', r, r.length);
                const mr = r.slice(0,5).concat(r.slice(6));
                //previous version
                //e.target.value = formatString(r.slice(0,6));
                input.value = formatString(mr);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(8,8);
                pp.current = 6;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
        
            if(step.current === 7){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current + 4,step.current + 4);
                pp.current = 7;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
            if(step.current === '7a'){
                //console.log('tt:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(10,10);
                pp.current = 7;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
            if(step.current === 8){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current + 4,step.current + 4);
                pp.current = 8;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }

            if(step.current === '8a'){
                //console.log('tt:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(11,11);
                pp.current = 8;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
        
            if(step.current === 9){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current + 4,step.current + 4);
                pp.current = 9;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
            if(step.current === '9a'){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(12,12);
                pp.current = 9;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
            if(step.current as number >= 10){
                //console.log('r:');
                input.value = formatString(r);
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(step.current as number + 4,step.current as number + 4);
                pp.current = 10;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }

        } else {
            
            if(r.length === 0){
                input.value = `(___) ___-____`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                //previous version was 0,0
                //input.setSelectionRange(r.length,r.length);
                input.setSelectionRange(r.length + 1,r.length + 1);
                ss.current = input.selectionStart!;
                //console.log('ss:', input.selectionStart);
                pp.current = 0;
                pss.current = input.selectionStart!;
            }
        
            if(r.length === 1){
                input.value = `(${r}__) ___-____`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 1,r.length+ 1);
                //console.log('ss:', input.selectionStart);
                ss.current = input.selectionStart!;
                pp.current = 1;
                //cp = r.length + 1;
                pss.current = input.selectionStart!;
            }
        
            if(r.length === 2){
                input.value = `(${r}_) ___-____`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 1,r.length + 1);
                //console.log('ss:', input.selectionStart);
                ss.current = input.selectionStart!;
                pp.current = 2;
                //cp = r.length + 1;
                pss.current = input.selectionStart!;
            }
        
            if(r.length === 3){
                if(pss.current === 6 || ss.current === 6){
                    input.value = `(${r.slice(0,2)}_) ___-____`;
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(r.length,r.length);
                    ss.current = input.selectionStart!;
                    pp.current = 2;
                    //cp = r.length;
                    pss.current = input.selectionStart!;
                } else {
                    input.value = `(${r}) ___-____`;
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(r.length + 3,r.length + 3);
                    ss.current = input.selectionStart!;
                    pp.current = 3;
                    //cp = r.length + 3;
                    pss.current = input.selectionStart!;
                }
        
            }
        
            if(r.length === 4){
                input.value = `(${r.slice(0,3)}) ${r.slice(3)}__-____`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 3,r.length + 3);
                ss.current = input.selectionStart!;
                pp.current = 4;
                //cp = r.length + 3;
                pss.current = input.selectionStart!;
            }
        
            if(r.length === 5){
                input.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 3,r.length + 3);
                ss.current = input.selectionStart!;
                pp.current = 5;
                //cp = r.length + 3;
                pss.current = input.selectionStart!;
            }
        
        
            if(r.length === 6){
                if(pss.current === 10 || ss.current === 10){
                    input.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(r.length + 2,r.length + 2);
                    ss.current = input.selectionStart!;
                    pp.current = 5;
                    //cp = r.length + 2;
                    pss.current = input.selectionStart!;
                } else {
                    input.value = `(${r.slice(0,3)}) ${r.slice(3)}-____`;
                    setPhoneNumber(input.value);
                    updateStoreLocationPhoneNumber(input.value);
                    input.setSelectionRange(r.length + 4,r.length + 4);
                    ss.current = input.selectionStart!;
                    pp.current = 6;
                    //cp = r.length + 4;
                    pss.current = input.selectionStart!;
                }
        
            }
        
            if(r.length === 7){
                input.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,7)}___`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 4,r.length + 4);
                ss.current = input.selectionStart!;
                pp.current = 7;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
            if(r.length === 8){
                input.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,8)}__`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 4,r.length + 4);
                ss.current = input.selectionStart!;
                pp.current = 8;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
        
            if(r.length === 9){
                input.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,9)}_`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 4,r.length + 4);
                ss.current = input.selectionStart!;
                pp.current = 9;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
            if(r.length >= 10){
                input.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,10)}`;
                setPhoneNumber(input.value);
                updateStoreLocationPhoneNumber(input.value);
                input.setSelectionRange(r.length + 4,r.length + 4);
                ss.current = input.selectionStart!;
                pp.current = 10;
                //cp = r.length + 4;
                pss.current = input.selectionStart!;
            }
        }
        
        //prl = r.length;
        prl.current = getStringLength2(r);
        //console.log('pss:', pss.current);
    }


    function formatString(str:string){
        //console.log('str:', str.length);
        switch(str.length){
            case 1:
                return `(${str.slice(0,1)}__) ___-____`;
            case 2:
                return `(${str.slice(0,2)}_) ___-____`;
            case 3:
                return `(${str.slice(0,3)}) ___-____`;
            case 4:
                return `(${str.slice(0,3)}) ${str.slice(3,4)}__-____`;
            case 5:
                return `(${str.slice(0,3)}) ${str.slice(3,5)}_-____`;
            case 6:
                return `(${str.slice(0,3)}) ${str.slice(3,6)}-____`;
            case 7:
                return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,7)}___`;
            case 8:
                return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,8)}__`;
            case 9:
                return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,9)}_`;
            default:
                return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,10)}`;
        }
    }

    function getStringLength2(r:string){
        switch(r.length){
            case 0:
                return 0;
            case 1:
                return 2;
            case 2:
                return 3;
            case 3:
                return 4;
            case 4:
                return 7;
            case 5:
                return 8;
            case 6:
                return 9;
            case 7:
                return 11;
            case 8:
                return 12;
            case 9:
                return 13;
            case 10:
                return 14;
            default:
                throw new Error('no position found.');
        }
    }

/**test phone number entry**/
/*
const pn = document.getElementById('pn');
const start = 0;
const end = 14;
pn.value = `(___) ___-____`;
let pp = 0;
let cp = 0;
let pss = 0;
let css = 0;
let ss = 'ns';
let adj = false;
let step = 0;
let begin = 0;
let finish = 0;
let prl = 0;
let pressedKeys = [];
let noEndSelection = false;
let clickedOutsideInput = false;
*/

/*
document.addEventListener('click', (e)=>{
    console.log('tt:', e);
    console.log('tt:', console.log('tt:', e.target.selectionStart, e.target.selectionEnd, e.target.value));
    clickedOutsideInput = true;
});
*/

/*
pn.addEventListener('click', (e)=>{
    e.stopPropagation();
    console.log('tt:', e.target.selectionStart, e.target.selectionEnd, e.target.value);
    let r = e.target.value.replace(/[^\d]/g, '');
    if(r.length > 10){
        r = r.slice(0,10);
    }

    begin = e.target.selectionStart;
    finish = e.target.selectionEnd;

    if(((finish > begin) && (begin === 0 || begin === 4 || begin === 5 || begin === 9))
        || (finish === 0) || (finish === 5 && r.length > 0)){
        console.log('tt:', r.length, getStringLength2(r), begin, finish);
        if((getStringLength2(r) > begin)){
            adj = true;

        } else {
            adj = false;
        }

        switch(begin){
            case 0:
                ss = 1;
                pss = 1;
                finish = 1;
                begin = 1;
                pn.setSelectionRange(1,1);
                break;
            case 4:
            case 5:
                ss = 6;
                pss = 6;
                finish = 6;
                begin = 6;
                pn.setSelectionRange(6,6);
                break;
            case 9:
                ss = 10;
                pss = 10;
                finish = 10;
                begin = 10;
                pn.setSelectionRange(10,10)
                break;
            default:
                ss = begin;
                pss = begin;
                finish = begin;
                pn.setSelectionRange(ss,ss);
        }

        return;
    }


    console.log('tt:', r.length, getStringLength2(r), begin, finish);
    if((getStringLength2(r) === begin) 
        && (getStringLength2(r) === 14)
        && (begin === 14)){
        adj = false;
        ss = 14;
        pss = 14;
        pn.setSelectionRange(ss,ss);

    } else if(r.length > 0 && (getStringLength2(r) > begin) && (begin === finish)){
        console.log('tt:', begin);
        adj = true;
        switch(begin){
            case 0:
                ss= 1;
                pss = 1;
                pn.setSelectionRange(1,1);
                break;
            case 4:
            case 5:
                ss = 6;
                pss = 6;
                pn.setSelectionRange(6,6);
                break;
            case 9:
                ss = 10;
                pss = 10;
                pn.setSelectionRange(10,10)
                break;
            default:
                ss = begin;
                pss = begin;
                pn.setSelectionRange(ss,ss);
        }
        //ss = begin;
    } else if(r.length > 0 && (getStringLength2(r) > begin) && (getStringLength2(r) > finish)
                && (finish > begin)){
        console.log('tt:', begin, finish);
        adj = true;
        switch(begin){
            case 0:
                ss= 1;
                pss = 1;
                pn.setSelectionRange(1,1);
                break;
            case 4:
            case 5:
                ss = 6;
                pss = 6;
                pn.setSelectionRange(6,6);
                break;
            case 9:
                ss = 10;
                pss = 10;
                pn.setSelectionRange(10,10)
                break;
            default:
                ss = begin;
                pss = begin;
                pn.setSelectionRange(ss,finish);
        }             

    } else {
        adj = false;
        console.log('tt:', r.length, getStringLength2(r), begin, finish, ss, pss);
        if(r.length === 0){
            pn.setSelectionRange(1,1);
            ss = 1;
            pss = 1;
            return;
        }

        if(r.length === 1 && begin >= 2){
            pn.setSelectionRange(r.length + 1, r.length + 1);
            ss = 2;
            pss = 2;
            return;            
        }

        if(r.length === 2 && begin >= 3){
            pn.setSelectionRange(r.length + 1, r.length + 1);
            ss = 3;
            pss = 3;
            return;            
        }

        if(r.length === 3 && begin >= 4){
            pn.setSelectionRange(r.length + 3, r.length + 3);
            ss = 6
            pss = 6;
            return;
        }
        
        if(r.length === 4 && begin >= 7){
            pn.setSelectionRange(r.length + 3, r.length + 3);
            ss = 7;
            pss = 7;
            return;
        }

        if(r.length === 5 && begin >= 8){
            pn.setSelectionRange(r.length + 3, r.length + 3);
            ss = 8;
            pss = 8;
            return;
        }
        
        if(r.length === 6 && begin >= 9){
            pn.setSelectionRange(r.length + 4, r.length + 4);
            ss = 10;
            pss = 10;
            return;
        }

        if(r.length === 7 && begin >= 11){
            pn.setSelectionRange(r.length + 4, r.length + 4);
            ss = 11;
            pss = 11;
            return;
        } 

        if(r.length === 8 && begin >= 12){
            pn.setSelectionRange(r.length + 4, r.length + 4);
            ss = 12;
            pss = 12;
            return;
        }

        if(r.length === 9 && begin >= 13){
            pn.setSelectionRange(r.length + 4, r.length + 4);
            ss = 13;
            pss = 13;
            return;
        }

        if(r.length === 10 && begin >= 14){
            pn.setSelectionRange(r.length + 4, r.length + 4);
            ss = 14;
            pss = 14;
            return;
        }

        if(((r.length === 3) || (r.length === 6)) && (begin < getStringLength2(r)) && (finish >= getStringLength2(r))
            && begin !== 6){
            console.log('tt:', begin, finish);
            ss = begin;
            pss = begin;
            return;
        }
    }

    //formatPhoneNumber(e);

});
*/

/*
pn.addEventListener('select', (e)=>{
    console.log('tt:', e.target.selectionStart, e.target.selectionEnd);
    //formatPhoneNumber(e);
    if(e.target.selectionEnd > e.target.selectionStart && clickedOutsideInput){
        noEndSelection = true;

    } else {
        clickedOutsideInput = false;
        noEndSelection = false;
    }

});
*/

/*
function getArrowRightGreaterPosition(tss, rLength){
    console.log('key:', tss, rLength);
    switch(rLength){
        case 1:
            return tss === 2 ? true : false;
        case 2:
            return tss === 3 ? true : false;
        case 3:
            return tss === 6 ? true : false;
        case 4:
            return tss === 7 ? true : false;
        case 5:
            return tss === 8 ? true : false;
        case 6:
            return tss === 10 ? true : false;
        case 7:
            return tss === 11 ? true : false;
        case 8:
            return tss === 12 ? true : false;
        case 9:
            return tss === 13 ? true : false;
        case 10:
            return tss === 14 ? true : false;
        default:
            throw new Error('no position found.');
    }
}
*/

/*
function getArrowRightLessPosition(tss, rLength){
    console.log('key:', tss, rLength);
    switch(rLength){
        case 1:
            adj = false;
            console.log('key:', adj);
            return tss === 1 ? true : false;
        case 2:
            if(tss === 2){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 2 ? true : false;
        case 3:
            if(tss === 3){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 3 ? true : false;
        case 4:
            if(tss === 6){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 6 ? true : false;
        case 5:
            if(tss === 7){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 7 ? true : false;
        case 6:
            if(tss === 8){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 8 ? true : false;
        case 7:
            if(tss === 10){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 10 ? true : false;
        case 8:
            if(tss === 11){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 11 ? true : false;
        case 9:
            if(tss === 12){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 12 ? true : false;
        case 10:
            if(tss === 13){
                adj = false;
                console.log('key:', adj);
            }
            return tss <= 13 ? true : false;
        default:
            throw new Error('no position found.');
    }
}
*/


/*use this when needing to remove multiple keys that
where pressed.
pn.addEventListener('keyup', (e)=>{
    console.log('key:', e.key);
    pressedKeys = pressedKeys.filter((pk)=>{
                            return pk !== e.key ;
                        });

    console.log('key:', pressedKeys);
    
});
*/

/*
pn.addEventListener('keydown', (e)=>{
    console.log('key:', e.key, e.target.selectionStart);
    const tss = e.target.selectionStart;

    let r = e.target.value.replace(/[^\d]/g, '');
    if(r.length > 10){
        r = r.slice(0,10);
    }


    //use this for selecting mulitple keys at the 
    //same time
    //ex)Shift + ArrowLeft
    const keysFound = pressedKeys.filter((pk)=>{
                                return pk === e.key ;
                            });

    if(keysFound.length === 0){
        pressedKeys.push(e.key);
    }

    console.log('key:', pressedKeys, tss, ss);
    


    if((r.length === 0) && (e.key === 'Delete')){
        console.log('key:');
        setTimeout(() => {
            pn.setSelectionRange(1,1);
            ss = 1;
        }, 0);

    }


    console.log('key:', r.length, tss, ss);
    //previous version
    //(tss > r.length)
    if((r.length > 0) && (e.key === 'ArrowRight') && getArrowRightGreaterPosition(tss, r.length)){
        console.log('key:', r.length, tss, ss);
        switch(r.length){
            case 1:
                setTimeout(() => {
                    pn.setSelectionRange(2,2);
                },0);
                ss = 2;
                break;
            case 2:
                setTimeout(() => {
                    pn.setSelectionRange(3,3);
                },0);
                ss = 3;
                break;
            case 3:
                setTimeout(() => {
                    pn.setSelectionRange(6, 6);
                },0);
                ss = 6;
                break;
            case 4:
                setTimeout(() => {
                    pn.setSelectionRange(7,7);
                },0);
                ss = 7;
                break;
            case 5:
                setTimeout(() => {
                    pn.setSelectionRange(8,8);
                },0);
                ss = 8;
                break;
            case 6:
                setTimeout(() => {
                    pn.setSelectionRange(10,10);
                },0);
                ss = 10;
                break;
            case 7:
                setTimeout(() => {
                    pn.setSelectionRange(11,11);
                },0);
                ss = 11;
                break;
            case 8:
                setTimeout(() => {
                    pn.setSelectionRange(12,12);
                },0);
                ss = 12;
                break;
            case 9:
                setTimeout(() => {
                    pn.setSelectionRange(13,13);
                },0);
                ss = 13;
                break;
            case 10:
                setTimeout(() => {
                    pn.setSelectionRange(14,14);
                },0);
                ss = 14;
                break;
            default:
                throw new Error('no position found.');
            
        }

    }
    
    //previous version
    //(tss <= r.length)
    if((r.length > 0) && (e.key === 'ArrowRight') && getArrowRightLessPosition(tss, r.length)){
        console.log('key:', tss, ss);
        switch(ss){
            case 1:
                setTimeout(() => {
                    pn.setSelectionRange(2, 2);
                },0);
                ss = 2;
                break;
            case 2:
                setTimeout(() => {
                    pn.setSelectionRange(3, 3);
                },0);
                ss = 3;
                break;
            case 3:
                setTimeout(() => {
                    pn.setSelectionRange(6, 6);
                },0);
                ss = 6;
                break;
            case 6:
                setTimeout(() => {
                    pn.setSelectionRange(7,7);
                },0);
                ss = 7;
                break;
            case 7:
                setTimeout(() => {
                    pn.setSelectionRange(8,8);
                },0);
                ss = 8;
                break;
            case 8:
                setTimeout(() => {
                    pn.setSelectionRange(10,10);
                },0);
                ss = 10;
                break;
            case 10:
                setTimeout(() => {
                    pn.setSelectionRange(11,11);
                },0);
                ss = 11;
                break;
            case 11:
                setTimeout(() => {
                    pn.setSelectionRange(12,12);
                },0);
                ss = 12;
                break;
            case 12:
                setTimeout(() => {
                    pn.setSelectionRange(13,13);
                },0);
                ss = 13;
                break;
            case 13:
                setTimeout(() => {
                    pn.setSelectionRange(14,14);
                },0);
                ss = 14;
                break;
            default:
                throw new Error('no position found.');
            
        }

    }



    if((r.length > 0) && (e.key === 'ArrowLeft')){
        adj = true;
        console.log('key:', tss, ss, adj);
        switch(ss){
            case 14:
                setTimeout(() => {
                    pn.setSelectionRange(13, 13);
                },0);
                ss = 13;
                break;
            case 13:
                setTimeout(() => {
                    pn.setSelectionRange(12, 12);
                },0);
                ss = 12;
                break;
            case 12:
                setTimeout(() => {
                    pn.setSelectionRange(11, 11);
                },0);
                ss = 11;
                break;
            case 11:
                setTimeout(() => {
                    pn.setSelectionRange(10, 10);
                },0);
                ss = 10;
                break;
            case 10:
                setTimeout(() => {
                    pn.setSelectionRange(8,8);
                },0);
                ss = 8;
                break;
            case 8:
                setTimeout(() => {
                    pn.setSelectionRange(7,7);
                },0);
                ss = 7;
                break;
            case 7:
                setTimeout(() => {
                    pn.setSelectionRange(6,6);
                },0);
                ss = 6;
                break;
            case 6:
                setTimeout(() => {
                    pn.setSelectionRange(3,3);
                },0);
                ss = 3;
                break;
            case 3:
                setTimeout(() => {
                    pn.setSelectionRange(2,2);
                },0);
                ss = 2;
                break;
            case 2:
                setTimeout(() => {
                    pn.setSelectionRange(1,1);
                },0);
                ss = 1;
                break;
            case 1:
                console.log('key:', tss, ss);
                setTimeout(() => {
                    pn.setSelectionRange(1,1);
                },0);
                ss = 1;
                break;
            default:
                throw new Error('no position found.');
            
        }

    }
    

    
    if(r.length === 0 && ((e.key === 'ArrowUp') || (e.key === 'ArrowDown')
        || (e.key === 'ArrowLeft') || (e.key === 'ArrowRight')
        || (e.key === 'Home') || (e.key === 'End'))){
        console.log('key:');
        setTimeout(() => {
            pn.setSelectionRange(1,1);
            ss = 1;
        }, 0);

    }

    if(r.length > 0 && ((e.key === 'ArrowUp') || (e.key === 'Home'))){
        adj = true;
        console.log('key:', r.length, tss, ss);
        setTimeout(()=>{
            pn.setSelectionRange(1, 1);
            ss = 1;
        },0);
    }

    if(r.length > 0 && ((e.key === 'ArrowDown') || (e.key === 'End'))){
        console.log('key:', r.length, tss, ss);
        adj = false;
        //ex. 3,4,5 & 9
        //use a switch
        switch(r.length){
            case 1:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 1, r.length + 1);
                    ss = 2;
                },0);
                break;
            case 2:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 1, r.length + 1);
                    ss = 3;
                },0);
                break;
            case 3:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 3, r.length + 3);
                    ss = 6;
                },0);
                break;
            case 4:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 3, r.length + 3);
                    ss = 7;
                },0);
                break;
            case 5:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 3, r.length + 3);
                    ss = 8;
                },0);
                break;
            case 6:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 4, r.length + 4);
                    ss = 10;
                },0);

                break;
            case 7:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 4, r.length + 4);
                    ss = 11;
                },0);
                break;
            case 8:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 4, r.length + 4);
                    ss = 12;
                },0);
                break;
            case 9:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 4, r.length + 4);
                    ss = 13;
                },0);
                break;
            case 10:
                setTimeout(()=>{
                    pn.setSelectionRange(r.length + 4, r.length + 4);
                    ss = 14;
                },0);
                break;
            default:
                throw new Error('No position found.');
        }

    }


});
*/

/*
pn.addEventListener('input', (e)=>{
    //console.log('input:', e.target.selectionStart);
    //css = e.target.selectionStart;
    //console.log('ss:', css, pss);
    //formatPhoneNumber(e);
    formatPhoneNumber2(e);

});
*/

//previous version
//first version
/*
function formatPhoneNumber(e){
    let input = e.target;
    let r = e.target.value.replace(/[^\d]/g, '');
    console.log('r:', r, r.length, e.target.value.length);
    
    e.target.value = r;
    if(r.length === 0){
        e.target.value = `(___) ___-____`;
        input.setSelectionRange(r.length,r.length);
        pp = 0;
        cp = r.length + 0;
    }

    if(r.length === 1){
        e.target.value = `(${r}__) ___-____`;
        input.setSelectionRange(r.length + 1,r.length+ 1);
        pp = 1;
        cp = r.length + 1;
    }

    if(r.length === 2){
        e.target.value = `(${r}_) ___-____`;
        input.setSelectionRange(r.length + 1,r.length + 1);
        pp = 2;
        cp = r.length + 1;
    }

    if(r.length === 3){
        if(cp === 6){
            e.target.value = `(${r.slice(0,2)}_) ___-____`;
            input.setSelectionRange(r.length,r.length);
            pp = 2;
            cp = r.length;
        } else {
            e.target.value = `(${r}) ___-____`;
            input.setSelectionRange(r.length + 3,r.length + 3);
            pp = 3;
            cp = r.length + 3;
        }

    }

    if(r.length === 4){
        e.target.value = `(${r.slice(0,3)}) ${r.slice(3)}__-____`;
        input.setSelectionRange(r.length + 3,r.length + 3);
        pp = 4;
        cp = r.length + 3;
    }

    if(r.length === 5){
        e.target.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
        input.setSelectionRange(r.length + 3,r.length + 3);
        pp = 5;
        cp = r.length + 3;
    }


    if(r.length === 6){
        if(cp === 10){
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
            input.setSelectionRange(r.length + 2,r.length + 2);
            pp = 5;
            cp = r.length + 2;
        } else {
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3)}-____`;
            input.setSelectionRange(r.length + 4,r.length + 4);
            pp = 6;
            cp = r.length + 4;
        }

    }

    if(r.length === 7){
        e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,7)}___`;
        input.setSelectionRange(r.length + 4,r.length + 4);
        pp = 7;
        cp = r.length + 4;
    }
    if(r.length === 8){
        e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,8)}__`;
        input.setSelectionRange(r.length + 4,r.length + 4);
        pp = 8;
        cp = r.length + 4;
    }

    if(r.length === 9){
        e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,9)}_`;
        input.setSelectionRange(r.length + 4,r.length + 4);
        pp = 9;
        cp = r.length + 4;
    }
    if(r.length >= 10){
        e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,10)}`;
        input.setSelectionRange(r.length + 4,r.length + 4);
        pp = 10;
        cp = r.length + 4;
    }

    console.log('r:', cp);
}
*/

//format #2
/*main version
function formatPhoneNumber2(e){
    let input = e.target;
    let r = e.target.value.replace(/[^\d]/g, '');
    if(r.length > 10){
        r = r.slice(0, 10);
    }
    
    console.log('tt:',e.inputType, r.length, e.target.selectionStart, ss, begin, finish, pss, adj, noEndSelection);    
    const tss = e.target.selectionStart;
    const inputType = e.inputType;
    e.target.value = r;


    //highlight numbers without having an end point
    if(inputType === 'insertText' && noEndSelection && (r.length === 0)){
        console.log('tt:', r.length, tss, begin, finish);
        e.target.value = '(___) ___-____';
        ss = 1;
        pss = 1;
        begin = 1;
        finish = 1;
        noEndSelection = false;
        input.setSelectionRange(1,1);

        return;
    }

    if(inputType === 'insertText' && noEndSelection && r.length > 0 && /[^\d]/gi.test(e.data)){
        console.log('tt:');

    }


    if(inputType === 'insertText' && noEndSelection && r.length > 0){
        console.log('tt:', r.length, tss, begin, finish);
        const value = (tss > r.length) ? r.length : tss;
        console.log('tt:', value);
        switch((tss > r.length) ? r.length : tss){
            case 1:
                if(value === tss && (r.length > 1 || e.data === ' ' || /[^\d]/gi.test(e.data))){
                    adj = true;
                } else {
                    adj = false;
                }
                console.log('tt:', adj);
                e.target.value = formatString(r);
                if(/[^\d]/gi.test(e.data) && adj){
                    ss = 1;
                    pss = 1;
                    begin = 1;
                    finish = 1;
                    noEndSelection = false;
                    input.setSelectionRange(1,1);
                } else {
                    ss = 2;
                    pss = 2;
                    begin = 2;
                    finish = 2;
                    noEndSelection = false;
                    input.setSelectionRange(2,2);
                }

                break;
            case 2:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 3;
                pss = 3;
                begin = 3;
                finish = 3;
                noEndSelection = false;
                input.setSelectionRange(3,3);
                break;
            case 3:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 6;
                pss = 6;
                begin = 6;
                finish = 6;
                noEndSelection = false;
                input.setSelectionRange(6,6);
                break;
            case 4:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 7;
                pss = 7;
                begin = 7;
                finish = 7;
                noEndSelection = false;
                input.setSelectionRange(7,7);
                break;
            case 5:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 8;
                pss = 8;
                begin = 8;
                finish = 8;
                noEndSelection = false;
                input.setSelectionRange(8,8);
                break;
            case 6:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 10;
                pss = 10;
                begin = 10;
                finish = 10;
                noEndSelection = false;
                input.setSelectionRange(10,10);
                break;
            case 7:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 11;
                pss = 11;
                begin = 11;
                finish = 11;
                noEndSelection = false;
                input.setSelectionRange(11,11);
                break;
            case 8:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 12;
                pss = 12;
                begin = 12;
                finish = 12;
                noEndSelection = false;
                input.setSelectionRange(12,12);
                break;
            case 9:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 13;
                pss = 13;
                begin = 13;
                finish = 13;
                noEndSelection = false;
                input.setSelectionRange(13,13);
                break;
            case 10:
                if(value === tss){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 14;
                pss = 14;
                begin = 14;
                finish = 14;
                noEndSelection = false;
                input.setSelectionRange(14,14);
                break;
            default:
                throw new Error('no position found.');

        }

        return;
    }


    //highlight numbers without having an end point
    if(inputType === 'deleteContentBackward' && noEndSelection && (r.length === 0)){
        console.log('tt:', r.length, tss, begin, finish);
        e.target.value = '(___) ___-____';
        ss = 1;
        pss = 1;
        begin = 1;
        finish = 1;
        noEndSelection = false;
        input.setSelectionRange(1,1);

        return;
    }


    if(inputType === 'deleteContentBackward' && noEndSelection && r.length > 0){
        console.log('tt:', r.length, tss, begin, finish);
        const value = (tss > r.length) ? r.length : tss;
        console.log('tt:', value);
        switch((tss > r.length) ? r.length : tss){
            case 0:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                console.log('tt:', adj);
                e.target.value = formatString(r);
                ss = 1;
                pss = 1;
                begin = 1;
                finish = 1;
                noEndSelection = false;
                input.setSelectionRange(1,1);
                break;
            case 1:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                console.log('tt:', adj);
                e.target.value = formatString(r);
                ss = 2;
                pss = 2;
                begin = 2;
                finish = 2;
                noEndSelection = false;
                input.setSelectionRange(2,2);
                break;
            case 2:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 3;
                pss = 3;
                begin = 3;
                finish = 3;
                noEndSelection = false;
                input.setSelectionRange(3,3);
                break;
            case 3:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 6;
                pss = 6;
                begin = 6;
                finish = 6;
                noEndSelection = false;
                input.setSelectionRange(6,6);
                break;
            case 4:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 7;
                pss = 7;
                begin = 7;
                finish = 7;
                noEndSelection = false;
                input.setSelectionRange(7,7);
                break;
            case 5:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 8;
                pss = 8;
                begin = 8;
                finish = 8;
                noEndSelection = false;
                input.setSelectionRange(8,8);
                break;
            case 6:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 10;
                pss = 10;
                begin = 10;
                finish = 10;
                noEndSelection = false;
                input.setSelectionRange(10,10);
                break;
            case 7:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 11;
                pss = 11;
                begin = 11;
                finish = 11;
                noEndSelection = false;
                input.setSelectionRange(11,11);
                break;
            case 8:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r); 
                ss = 12;
                pss = 12;
                begin = 12;
                finish = 12;
                noEndSelection = false;
                input.setSelectionRange(12,12);
                break;
            case 9:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);
                ss = 13;
                pss = 13;
                begin = 13;
                finish = 13;
                noEndSelection = false;
                input.setSelectionRange(13,13);
                break;
            case 10:
                if(value === tss && value < r.length){
                    adj = true;
                } else {
                    adj = false;
                }
                e.target.value = formatString(r);   
                ss = 14;
                pss = 14;
                begin = 14;
                finish = 14;
                noEndSelection = false;
                input.setSelectionRange(14,14);
                break;
            default:
                throw new Error('no position found.');

        }

        return;
    }

    
    //a character is typed that is not a number
    //need to maintain existing position
    if(inputType === 'insertText' && /[^\d]/g.test(e.data)){
        if(r.length === 0){
            e.target.value = '(___) ___-____';

        } else {
            e.target.value = formatString(r);
        }

        if(finish > begin && tss === 1){
            input.setSelectionRange(1,1);
            ss = 1;
            pss = 1;
            begin = 1;
            finish = 1;

        } else {
            input.setSelectionRange(tss - 1,tss - 1);
            ss = tss - 1;
            pss = tss - 1;
            begin = tss - 1;
            finish = tss - 1;
            console.log('stop:', tss - 1, formatString(r));
        }
        

        return;
    }


    if(inputType === 'insertText' && e.data === ' '){
        console.log('tt:');
        switch(tss){
            case 2:
                e.target.value = '(___) ___-____';
                input.setSelectionRange(1,1);
                ss = 1;
                pss = 1;
                break;
            case 3:
                e.target.value = formatString(r);
                input.setSelectionRange(2,2);
                ss = 2;
                pss = 2;
                break;
            case 4:
                e.target.value = formatString(r);
                input.setSelectionRange(3,3);
                ss = 3;
                pss = 3;
                break;
            case 7:
                e.target.value = formatString(r);
                input.setSelectionRange(6,6);
                ss = 6;
                pss = 6;
                break;
            case 8:
                e.target.value = formatString(r);
                input.setSelectionRange(7,7);
                ss = 7;
                pss = 7;
                break;
            case 9:
                e.target.value = formatString(r);
                input.setSelectionRange(8,8);
                ss = 8;
                pss = 8;
                break;
            case 11:
                e.target.value = formatString(r);
                input.setSelectionRange(10,10);
                ss = 10;
                pss = 10;
                break;
            case 12:
                e.target.value = formatString(r);
                input.setSelectionRange(11,11);
                ss = 11;
                pss = 11;
                break;
            case 13:
                e.target.value = formatString(r);
                input.setSelectionRange(12,12);
                ss = 12;
                pss = 12;
                break;
            case 14:
                e.target.value = formatString(r);
                input.setSelectionRange(13,13);
                ss = 13;
                pss = 13;
                break;
            case 15:
                e.target.value = formatString(r);
                input.setSelectionRange(14,14);
                ss = 14;
                pss = 14;
                break;                
            default:
                throw new Error('no position found.');
        }


        return;
        
    }


    if(inputType === 'deleteContentForward' && !adj){
        if(r.length === 0){
            e.target.value = '(___) ___-____';

        } else {
            e.target.value = formatString(r);
        }


        input.setSelectionRange(tss,tss);
        ss = tss;
        pss = tss;
        begin = tss;
        finish = tss;
        console.log('stop:', tss, formatString(r));
    
        
        return;
    }


    console.log('r:', adj);

    if(adj){
        console.log('tt:', r.length, getStringLength2(r), tss, ss, finish);
        if(inputType === 'deleteContentForward'){
            switch(ss){
                case 1:
                    if(r.length === 0){
                        e.target.value = '(___) ___-____';
                        adj = false;
                    } else {
                        e.target.value = formatString(r);
                    }
                    
                    input.setSelectionRange(1,1);
                    ss = 1;
                    pss = 1;
                    break;
                case 2:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(2,2);
                    ss = 2;
                    pss = 2;
                    break;
                case 3:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(3,3);
                    ss = 3;
                    pss = 3;
                    console.log('tt:');
                    break;
                case 6:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(6,6);
                    ss = 6;
                    pss = 6;
                    break;
                case 7:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(7,7);
                    ss = 7;
                    pss = 7;
                    break;
                case 8:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(8,8);
                    ss = 8;
                    pss = 8;
                    break;
                case 10:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(10,10);
                    ss = 10;
                    pss = 10;
                    break;
                case 11:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(11,11);
                    ss = 11;
                    pss = 11;
                    break;
                case 12:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(12,12);
                    ss = 12;
                    pss = 12;
                    break;
                case 13:
                    if(ss >= getStringLength2(r)){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(13,13);
                    ss = 13;
                    pss = 13;
                    break;
                default:
                    throw new Error('no position found.');
            }

            return;
            
        }


        if(finish > begin && inputType === 'insertText'){
            console.log('tt:', r.length, prl, tss, ss, finish, getStringLength2(r));
            switch(ss){
                case 1:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(2,2);
                    begin = 2;
                    finish = 2;
                    ss = 2;
                    pss = 2;
                    break;
                case 2:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(3,3);
                    begin = 3;
                    finish = 3;
                    ss = 3;
                    pss = 3;
                    break;
                case 3:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(6,6);
                    begin = 6;
                    finish = 6;
                    ss = 6;
                    pss = 6;
                    break;
                case 6:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(7,7);
                    begin = 7;
                    finish = 7;
                    ss = 7;
                    pss = 7;
                    break;
                case 7:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(8,8);
                    begin = 8;
                    finish = 8;
                    ss = 8;
                    pss = 8;
                    break;
                case 8:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(10,10);
                    begin = 10;
                    finish = 10;
                    ss = 10;
                    pss = 10;
                    break;
                case 10:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(11,11);
                    begin = 11;
                    finish = 11;
                    ss = 11;
                    pss = 11;
                    break;
                case 11:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(12,12);
                    begin = 12;
                    finish = 12;
                    ss = 12;
                    pss = 12;
                    break;
                case 12:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(13,13);
                    begin = 13;
                    finish = 13;
                    ss = 13;
                    pss = 13;
                    break;
                case 13:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(14,14);
                    begin = 14;
                    finish = 14;
                    ss = 14;
                    pss = 14;
                    break;
                default:
                    throw new Error('no position found.')                  ;
            }
            
            return;
            
        }



        if(finish > begin && inputType === 'deleteContentBackward'){
            console.log('tt:', r.length, prl, ss, begin, finish, getStringLength2(r));
            switch(ss){
                case 1:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(1,1);
                    finish = 1;
                    ss = 1;
                    pss = 1;
                    break;
                case 2:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(2,2);
                    finish = 2;
                    ss = 2;
                    pss = 2;
                    break;
                case 3:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(3,3);
                    finish = 3;
                    ss = 3;
                    pss = 3;
                    break;
                case 6:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(6,6);
                    finish = 6;
                    ss = 6;
                    pss = 6;
                    break;
                case 7:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(7,7);
                    finish = 7;
                    ss = 7;
                    pss = 7;
                    break;
                case 8:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(8,8);
                    finish = 8;
                    ss = 8;
                    pss = 8;
                    break;
                case 10:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(10,10);
                    finish = 10;
                    ss = 10;
                    pss = 10;
                    break;
                case 11:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(11,11);
                    finish = 11;
                    ss = 11;
                    pss = 11;
                    break;
                case 12:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(12,12);
                    finish = 12;
                    ss = 12;
                    pss = 12;
                    break;
                case 13:
                    if(finish >= prl){
                        adj = false;
                    }
                    e.target.value = formatString(r);
                    input.setSelectionRange(13,13);
                    finish = 13;
                    ss = 13;
                    pss = 13;
                    break;
                default:
                    throw new Error('no position found.')                  ;
            }
            
            return;
            
        }

        
        if((tss === 5 && ss === 4) || (tss < ss)){
            console.log('tt:', tss, ss);
            
            switch(ss){
                case 13:
                    //console.log('tt:');
                    step = '9a';
                    ss -= 1;
                    break;
                case 12:
                    console.log('tt:');
                    step = '8a';
                    ss -= 1;
                    break;
                case 11:
                    //console.log('tt:');
                    step = '7a';
                    ss -= 1;
                    break;
                case 10:
                    console.log('tt:');
                    step = '6a';
                    ss -= 2;
                    break;
                case 8:
                    step = '5a';
                    ss -= 1;
                    break;
                case 7:
                    step = '4a';
                    ss -= 3;
                    break;
                case 6:
                    step = '3a';
                    ss -= 3;
                    break;
                case 4:
                    step = '3a';
                    ss -= 1;
                    break;
                case 3:
                    step = '2a';
                    ss -= 1;
                    break;
                case 2:
                    step = '1a';
                    ss -= 1;
                    break;
                case 1:
                    //step = '0a';
                    console.log('tt:');
                    step = '1a';
                    ss = 1;
                    break;              
                default:
                    throw new Error('no steps found.');
            }

        } else {
            //(tss > ss)
            console.log('tt:', tss, ss);

            switch(ss){
                case 1:
                    step = 1;
                    ss += 1;
                    break;
                case 2:
                    //console.log('ss:');
                    step = 2;
                    ss += 1;
                    break;
                case 3:
                    step = 3;
                    ss += 3;
                    break;
                case 6:
                    step = 4;
                    ss += 1;
                    break;
                case 7:
                    step = 5;
                    ss += 1;
                    break;
                case 8:
                    step = 6;
                    //ss += 1;
                    ss += 2;
                    break;
                //case 9:
                case 10:
                    console.log('tt:');
                    step = 7;
                    ss += 1;
                    break;
                case 11:
                    //console.log('ss:');
                    step = 8;
                    ss += 1;
                    break;
                case 12:
                    //console.log('ss:');
                    step = 9;
                    ss += 1;
                    break;
                case 13:
                    //console.log('ss:');
                    step = 10;
                    ss += 1;
                    adj = false;
                    break;
                default:
                    throw new Error('no step found.');
            }
            
        }

            


        console.log('tt:', step, ss);

        if(step === 0){
            e.target.value = `(___) ___-____`;
            input.setSelectionRange(step,step);
            console.log('ss:', input.selectionStart);
            pp = 0;
            pss = input.selectionStart;
        }

        if(step === '0a'){
            if(r.length > 0){
                e.target.value = formatString(r);
            } else {
                e.target.value = `(___) ___-____`;
            }
            
            input.setSelectionRange(1,1);
            console.log('ss:', input.selectionStart);
            pp = 0;
            pss = input.selectionStart;
            adj = false;
        }
    
        if(step === 1){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(step + 1,step + 1);
            console.log('ss:', input.selectionStart);
            pp = 1;
            //cp = r.length + 1;
            pss = input.selectionStart;
        }

        if(step === '1a'){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(1,1);
            console.log('ss:', input.selectionStart);
            pp = 1;
            //cp = r.length + 1;
            pss = input.selectionStart;
        }
    
        if(step === 2){
            console.log('r:', r.length);
            e.target.value = formatString(r);
            input.setSelectionRange(step + 1,step + 1);
            console.log('ss:', input.selectionStart);
            pp = 2;
            //cp = r.length + 1;
            pss = input.selectionStart;
        }

        if(step === '2a'){
            console.log('tt:', r.length);
            e.target.value = formatString(r);
            input.setSelectionRange(2,2);
            console.log('ss:', input.selectionStart);
            pp = 2;
            //cp = r.length + 1;
            pss = input.selectionStart;
        }
    
        if(step === 3){
            console.log('r:', pss);
            //if(pss === 6){
              //  e.target.value = `(${r.slice(0,2)}_) ___-____`;
               // input.setSelectionRange(r.length,r.length);
               // pp = 2;
                //cp = r.length;
               // pss = input.selectionStart;
            //} else {
                console.log('ssss:', r.length, formatString(r));
                e.target.value = formatString(r);
                input.setSelectionRange(step + 3,step + 3);
                pp = 3;
                //cp = r.length + 3;
                pss = input.selectionStart;
            //}
    
        }

        if(step === '3a'){
            console.log('tt:', r, r.length, formatString(r.slice(0,3)));
            //previous version
            //if(r.length === 5){
              //  e.target.value = formatString(r.slice(0,4));
            //}

            //if(r.length === 4){
            //    e.target.value = formatString(r.slice(0,3));
            //}
            
           const mr = r.slice(0,2).concat(r.slice(3));

            e.target.value = formatString(mr);
           
            
            input.setSelectionRange(3,3);
            pp = 3;
            //cp = r.length + 3;
            pss = input.selectionStart;
        }

    
        if(step === 4){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(step + 3,step + 3);
            pp = 4;
            //cp = r.length + 3;
            pss = input.selectionStart;
        }

        if(step === '4a'){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(6,6);
            pp = 4;
            //cp = r.length + 3;
            pss = input.selectionStart;
        }
    
        if(step === 5){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(step + 3,step + 3);
            pp = 5;
            //cp = r.length + 3;
            pss = input.selectionStart;
        }

        if(step === '5a'){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(7,7);
            pp = 5;
            //cp = r.length + 3;
            pss = input.selectionStart;
        }
    
    
        if(step === 6){
            console.log('r:', pss);
            //if(pss === 10){
                //e.target.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
                //input.setSelectionRange(r.length + 2,r.length + 2);
                //pp = 5;
                //cp = r.length + 2;
              //  pss = input.selectionStart;
            //} else {
                e.target.value = formatString(r);
                input.setSelectionRange(step + 4,step + 4);
                pp = 6;
                //cp = r.length + 4;
                pss = input.selectionStart;
            //}
    
        }

        if(step === '6a'){
            console.log('tt:', r, r.length);
            const mr = r.slice(0,5).concat(r.slice(6));
            //previous version
            //e.target.value = formatString(r.slice(0,6));
            e.target.value = formatString(mr);
            input.setSelectionRange(8,8);
            pp = 6;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
    
        if(step === 7){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(step + 4,step + 4);
            pp = 7;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
        if(step === '7a'){
            console.log('tt:');
            e.target.value = formatString(r);
            input.setSelectionRange(10,10);
            pp = 7;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
        if(step === 8){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(step + 4,step + 4);
            pp = 8;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }

        if(step === '8a'){
            console.log('tt:');
            e.target.value = formatString(r);
            input.setSelectionRange(11,11);
            pp = 8;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
    
        if(step === 9){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(step + 4,step + 4);
            pp = 9;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
        if(step === '9a'){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(12,12);
            pp = 9;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
        if(step >= 10){
            console.log('r:');
            e.target.value = formatString(r);
            input.setSelectionRange(step + 4,step + 4);
            pp = 10;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }

    } else {
        
        if(r.length === 0){
            e.target.value = `(___) ___-____`;
            //previous version was 0,0
            //input.setSelectionRange(r.length,r.length);
            input.setSelectionRange(r.length + 1,r.length + 1);
            ss = input.selectionStart;
            console.log('ss:', input.selectionStart);
            pp = 0;
            pss = input.selectionStart;
        }
    
        if(r.length === 1){
            e.target.value = `(${r}__) ___-____`;
            input.setSelectionRange(r.length + 1,r.length+ 1);
            console.log('ss:', input.selectionStart);
            ss = input.selectionStart;
            pp = 1;
            //cp = r.length + 1;
            pss = input.selectionStart;
        }
    
        if(r.length === 2){
            e.target.value = `(${r}_) ___-____`;
            input.setSelectionRange(r.length + 1,r.length + 1);
            console.log('ss:', input.selectionStart);
            ss = input.selectionStart;
            pp = 2;
            //cp = r.length + 1;
            pss = input.selectionStart;
        }
    
        if(r.length === 3){
            if(pss === 6 || ss === 6){
                e.target.value = `(${r.slice(0,2)}_) ___-____`;
                input.setSelectionRange(r.length,r.length);
                ss = input.selectionStart;
                pp = 2;
                //cp = r.length;
                pss = input.selectionStart;
            } else {
                e.target.value = `(${r}) ___-____`;
                input.setSelectionRange(r.length + 3,r.length + 3);
                ss = input.selectionStart;
                pp = 3;
                //cp = r.length + 3;
                pss = input.selectionStart;
            }
    
        }
    
        if(r.length === 4){
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3)}__-____`;
            input.setSelectionRange(r.length + 3,r.length + 3);
            ss = input.selectionStart;
            pp = 4;
            //cp = r.length + 3;
            pss = input.selectionStart;
        }
    
        if(r.length === 5){
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
            input.setSelectionRange(r.length + 3,r.length + 3);
            ss = input.selectionStart;
            pp = 5;
            //cp = r.length + 3;
            pss = input.selectionStart;
        }
    
    
        if(r.length === 6){
            if(pss === 10 || ss === 10){
                e.target.value = `(${r.slice(0,3)}) ${r.slice(3,5)}_-____`;
                input.setSelectionRange(r.length + 2,r.length + 2);
                ss = input.selectionStart;
                pp = 5;
                //cp = r.length + 2;
                pss = input.selectionStart;
            } else {
                e.target.value = `(${r.slice(0,3)}) ${r.slice(3)}-____`;
                input.setSelectionRange(r.length + 4,r.length + 4);
                ss = input.selectionStart;
                pp = 6;
                //cp = r.length + 4;
                pss = input.selectionStart;
            }
    
        }
    
        if(r.length === 7){
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,7)}___`;
            input.setSelectionRange(r.length + 4,r.length + 4);
            ss = input.selectionStart;
            pp = 7;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
        if(r.length === 8){
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,8)}__`;
            input.setSelectionRange(r.length + 4,r.length + 4);
            ss = input.selectionStart;
            pp = 8;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
    
        if(r.length === 9){
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,9)}_`;
            input.setSelectionRange(r.length + 4,r.length + 4);
            ss = input.selectionStart;
            pp = 9;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
        if(r.length >= 10){
            e.target.value = `(${r.slice(0,3)}) ${r.slice(3,6)}-${r.slice(6,10)}`;
            input.setSelectionRange(r.length + 4,r.length + 4);
            ss = input.selectionStart;
            pp = 10;
            //cp = r.length + 4;
            pss = input.selectionStart;
        }
    }
    
    //prl = r.length;
    prl = getStringLength2(r);
    console.log('pss:', pss);
}
*/


/*
function formatString(str){
    console.log('str:', str.length);
    switch(str.length){
        case 1:
            return `(${str.slice(0,1)}__) ___-____`;
        case 2:
            return `(${str.slice(0,2)}_) ___-____`;
        case 3:
            return `(${str.slice(0,3)}) ___-____`;
        case 4:
            return `(${str.slice(0,3)}) ${str.slice(3,4)}__-____`;
        case 5:
            return `(${str.slice(0,3)}) ${str.slice(3,5)}_-____`;
        case 6:
            return `(${str.slice(0,3)}) ${str.slice(3,6)}-____`;
        case 7:
            return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,7)}___`;
        case 8:
            return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,8)}__`;
        case 9:
            return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,9)}_`;
        default:
            return `(${str.slice(0,3)}) ${str.slice(3,6)}-${str.slice(6,10)}`;
    }
}
*/

/*
function getStringLength(r){
    switch(r.length){
        case 0:
            return 0;
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 7;
        case 5:
            return 8;
        case 6:
            return 9;
        case 7:
            return 11;
        case 8:
            return 12;
        case 9:
            return 13;
        case 10:
            return 14;
        default:
            throw new Error('no position found.');
    }
}
*/

/*
function getStringLength2(r){
    switch(r.length){
        case 0:
            return 0;
        case 1:
            return 2;
        case 2:
            return 3;
        case 3:
            return 4;
        case 4:
            return 7;
        case 5:
            return 8;
        case 6:
            return 9;
        case 7:
            return 11;
        case 8:
            return 12;
        case 9:
            return 13;
        case 10:
            return 14;
        default:
            throw new Error('no position found.');
    }
}
*/



    return <div className="phone-number-input-container">
                <input ref={pniRef} type="text" 
                    id={phoneNumberId}
                    className="phone-number-input"
                    value={phoneNumber} 
                    onClick={clickHandler} 
                    onSelect={selectHandler} 
                    onKeyDown={keydownHandler} 
                    onChange={changeHandler} />
            </div>;
});

export default PhoneNumber;