import React, {useState, useEffect, useRef} from 'react';
import StoreLocation from './storeLocation';
import {Link} from '@reach/router';
import StoreSearchParams from './storeSearchParams';
//import {addStoreSearchParams, AddStoreSearchParams} from './redux/actions';
import {connect} from 'react-redux';
import { selectStoreSearchParams } from './redux/features/storeSearchParams/storeSearchParamsSlice';
import { selectStoreLocation } from './redux/features/storeLocation/storeLocationSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import {navigate} from '@reach/router';
import handleGetCustomerLocation from './helper-get-customer-location';
import formatLinkString from './helper-format-link-string';
import './MapDisplay.css';

interface Marker {
    marker:google.maps.Marker;
    locationId:number;
}

interface Props {
    apiKey?:string;
    storeLocation:StoreLocation;
    otherStoreLocations:StoreLocation[];
}


const MapDisplay3:React.FunctionComponent<Props> = (props:Props)=>{
    const dispatch = useAppDispatch();
    const storeSearchParams = useAppSelector(selectStoreSearchParams);
    //console.log('ssps7:', storeSearchParams);
    const {apiKey} = props;
    const mapContainer:React.RefObject<HTMLDivElement> = useRef(null);
    const [mapExist, setMapExist] = useState(false);
    //const [mapRef, setMapRef]:[any, any] = useState(null);
    const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
    const [markerRef, setMarkerRef] = useState(new google.maps.Marker({position:{lat:0, lng:0},
        icon: {url:'https://server.kando-proto-3.com/store-location-marker.png', scaledSize:new google.maps.Size(25, 42.5),
                    labelOrigin:new google.maps.Point(12.5, 14)}
    }));

    const [infoWindowRef, setInfoWindowRef] = useState(new google.maps.InfoWindow());
    const mapLocationControlContainer = useRef<HTMLDivElement>(null);
    const mapDisplayStickyContainerRef = useRef<HTMLDivElement>(null);

    const [hasNewLocations, setHasNewLocations] = useState(false);
    const [storeLocation, setStoreLocation] = useState<StoreLocation>(props.storeLocation);
    const [otherStoreLocations, setOtherStoreLocations] = useState<StoreLocation[]>(props.otherStoreLocations);
    const [markers, setMarkers] = useState<Marker[]>(setupMarkers(props.storeLocation, props.otherStoreLocations));

    
    useEffect(()=>{

        //console.log('e7:');

        const r = props.otherStoreLocations.filter((osl)=>{
                                            //console.log('id7o', osl.storeLocationId);
                                        return osl.storeLocationFound === true;
                                    });

        if(r.length === 0){
            //console.log('e7:');
            //setMapRef(null);
            setHasNewLocations(true);
            setOtherStoreLocations(props.otherStoreLocations);

        } else {
            setHasNewLocations(false);
        }

    },[props.otherStoreLocations]);


    useEffect(()=>{
        //console.log('e7:');
        setStoreLocation(props.storeLocation);
        
    },[props.storeLocation]);

    useEffect(()=>{
        //console.log('e7:', mapRef);
        if(mapExist){
            //infoWindowRef.close();
            //markerRef.setMap(null);
            initMap(storeLocation, otherStoreLocations, mapRef);
        }
          
    },[storeLocation]);
    
    
    useEffect(()=>{
        //console.log('e7:');

        /*
        const loader = new Loader({
            apiKey: apiKey!,
            version: "weekly"
          });

        loader
        .load()
        .then((google)=>{
            //console.log('mapContainer:', mapContainer);
            mapDisplayStickyContainerRef.current!.style.height = `${window.innerHeight}px`;

            initMap(storeLocation, otherStoreLocations, mapRef);
        })
        .catch((err)=>console.error('google map error:', err.message));
        */

        mapDisplayStickyContainerRef.current!.style.height = `${window.innerHeight}px`;

        initMap(storeLocation, otherStoreLocations, mapRef);
        
    },[]);

    function setupMarkers(sls:StoreLocation, osls:StoreLocation[]):Marker[] {
        let ms:Marker[] = [];
        //set store location selected marker
        const m = new google.maps.Marker({position:{lat:0, lng:0},
                        icon: {url:'https://server.kando-proto-3.com/store-location-marker.png', scaledSize:new google.maps.Size(25, 42.5),
                                    labelOrigin:new google.maps.Point(12.5, 14)}
                    });

        m.setPosition({lat: sls.location.lat, lng: sls.location.lng});
        m.setLabel({text:`${sls.order}`, color:'white', fontWeight:'600', fontSize:'12px', fontFamily:'Open Sans'});
        ms.push({marker:m, locationId:sls.storeLocationId});

        //set other store location markers
        const oslsms = osls.map((osl)=>{
                            const m = new google.maps.Marker({position:{lat:0, lng:0},
                                icon: {url:'https://server.kando-proto-3.com/store-location-marker.png', scaledSize:new google.maps.Size(25, 42.5),
                                            labelOrigin:new google.maps.Point(12.5, 14)}
                            });

                            m.setPosition({lat: osl.location.lat, lng: osl.location.lng});
                            m.setLabel({text:`${osl.order}`, color:'white', fontWeight:'600', fontSize:'12px', fontFamily:'Open Sans'});
                            return ({marker:m, locationId:osl.storeLocationId});
                        });

        //console.log('markers:', [...ms, ...oslsms]);
        return [...ms, ...oslsms];
    }

    
    function initMap(storeLocation:StoreLocation, otherStoreLocations:StoreLocation[], mapRef:google.maps.Map | null):void {
        
        let center = {lat: storeLocation.location.lat, lng: storeLocation.location.lng};
        
        if(mapRef === null){
            //console.log('il7:');

            let map = new google.maps.Map(
                mapContainer.current as HTMLDivElement, {zoom: 14, center: center, mapTypeControl:false, streetViewControl:false});
                //console.log('map:', map);
                setMapRef(map);
                setMapExist(true);

                if(storeLocation.storeLocationFound){
                    //attach the select location to the map
                    const m = markers.filter((m)=>{
                                    return m.locationId === storeLocation.storeLocationId;
                                })[0];

                    m.marker.setMap(map);
            
                
                    let infoWindoContentString:string = `<div class="info-window-container">
                                                            <a href="/store-details/${encodeURIComponent(formatLinkString(storeLocation.storeName))}/${storeLocation.storeLocationId}" class="info-window-store-name">${storeLocation.storeName}</a>
                                                            <div class="info-window-details-container">
                                                                <div class="info-window-details-address">${storeLocation.streetAddress}</div>
                                                                <div class="info-window-details-address">${storeLocation.city}, ${storeLocation.state} ${storeLocation.zipCode}</div>
                                                                <a href="/store-details/${encodeURIComponent(formatLinkString(storeLocation.storeName))}/${storeLocation.storeLocationId}" class="info-window-details-btn">Store Details</a>
                                                            </div>
                                                        </div>`;
    
                    infoWindowRef.setContent(infoWindoContentString);
                    infoWindowRef.open(map, m.marker);

                }

                //attach other locations to the map
                const ms = markers.filter((m)=>{
                                        return m.locationId !== storeLocation.storeLocationId;
                                    });

                ms.forEach((m)=>{
                    m.marker.setMap(map);
                });
    


                //console.log('test item:', mapLocationControlContainer);
                //set location gps control
                map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapLocationControlContainer.current as HTMLDivElement);

                mapLocationControlContainer.current!.style.display = 'block';
                

        } else if(!storeLocation.storeLocationFound && mapRef){
            //console.log('center6:');
            //infoWindowRef.close();
            //markerRef.setMap(null);

        } else {
            
            //console.log('il7:');

            infoWindowRef.close();

            const m = markers.filter((m)=>{
                                        return m.locationId === storeLocation.storeLocationId;
                                    })[0];

            //markerRef.setPosition(center);
            const pos = m.marker.getPosition();
            //const markerPos = {lat: osl.location.lat, lng: osl.location.lng};
            //console.log('r7:', hasNewLocations);

           

            let infoWindoContentString:string = `<div class="info-window-container">
                                                    <a href="/store-details/${encodeURIComponent(formatLinkString(storeLocation.storeName))}/${storeLocation.storeLocationId}" class="info-window-store-name">${storeLocation.storeName}</a>
                                                    <div class="info-window-details-container">
                                                        <div class="info-window-details-address">${storeLocation.streetAddress}</div>
                                                        <div class="info-window-details-address">${storeLocation.city}, ${storeLocation.state} ${storeLocation.zipCode}</div>
                                                        <a href="/store-details/${encodeURIComponent(formatLinkString(storeLocation.storeName))}/${storeLocation.storeLocationId}" class="info-window-details-btn">Store Details</a>
                                                    </div>
                                                </div>`;

            infoWindowRef.setContent(infoWindoContentString);
            //(mapRef as google.maps.Map).panTo(center);
            (mapRef as google.maps.Map).panTo(pos!);

            infoWindowRef.open(mapRef, m.marker)
            
        }

        
    }
 


    return (
        <div className="map-display-wrapper">
            <div ref={mapDisplayStickyContainerRef} className="map-display-sticky-container">
                <div className="map-display-container" ref={mapContainer}>
                    <div ref={mapLocationControlContainer} title="Locate me" onClick={(e:React.MouseEvent)=>
                                                                                                handleGetCustomerLocation(e, storeSearchParams as StoreSearchParams, dispatch)
                                                                                    } className="map-location-control-container"></div>
                </div>
            </div>
        </div>
    );
    



}

export default MapDisplay3;