export default class CustomerRelation {
    responseId:number;
    reviewId:number;
    response:string;
    responseDate:number;

    constructor(options:{
        responseId?:number,
        reviewId?:number,
        response?:string,
        responseDate?:number
    }={}){
        this.responseId = options.responseId || 0;
        this.reviewId = options.reviewId || 0;
        this.response = options.response || '';
        this.responseDate = options.responseDate || Date.now();
    }
}