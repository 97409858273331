import React, { useState, useEffect, useRef } from 'react';
import {Link, navigate} from '@gatsbyjs/reach-router';
import Card from './card';
import HomePageLayoutItem from './homePageLayoutItem';
import MarketingPicture from './marketingPicture';

interface Props {
    card:Card;
    layoutAction:string;
    updateExistingHomePageLayoutItems?:(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string)=>void;
}

const CardDisplay:React.FunctionComponent<Props> = (props: Props) => {
    const {updateExistingHomePageLayoutItems} = props;
    const [card, setCard] = useState(props.card);
    const [layoutAction, setLayoutAction] = useState(props.layoutAction);
    const [cardChecked, setCardChecked] = useState(setupCardCheckbox(props.card));

    //console.log('card:', card, 'props.card:', props.card === card);
    //console.log('card:');

    useEffect(()=>{
        if(props.card !== card){
            //console.log('card:');
            setCard(props.card);
        }
        
    },[props.card]);



    function setupCardCheckbox(card:Card):boolean {
        //console.log('cardId:', card.cardId);
        return card.homePageLayoutId > 0 ? true : false;
    }

    function cardCheckboxHandler(e:React.ChangeEvent):void {
        //const checkboxChecked = (e.target as HTMLInputElement).checked;
        //setCardChecked(checkboxChecked);
        const mp = new MarketingPicture({...card,
                                        marketingPictureId:card.cardId})
        updateExistingHomePageLayoutItems!(mp, 'card-box');
    }

    if(layoutAction === 'edit'){
        return <div className="card-box-item-link">
                    {
                        (card.homePageLayoutId > 0 && !card.selectedForRemoval)
                        ?
                        <form className="hplid-checkbox-form">
                            <input className="hplid-checkbox-control" type="checkbox" id={`${card.cardId}`} value={card.cardId} 
                                checked={cardChecked} onChange={cardCheckboxHandler}/>
                        </form>
                        : null
                    }
                    {
                        (card.homePageLayoutId === 0 || card.selectedForRemoval)
                        ?
                        <div className="ahpl-new-item-label">New</div>
                        : null
                    }
                    <div className="card-box-item-image-container">
                        <img src={`https://server.kando-proto-3.com/${card.desktopSource}`}
                            alt={card.heading} className="card-box-item-image"/>
                    </div>
                    <div className="card-box-item-caption-container">
                        {
                            card.heading !== 'none'
                            ?
                            <h2 className="card-box-item-caption-heading">{card.heading}</h2>
                            : null
                        }
                        {
                            card.caption !== 'none'
                            ?
                            <p className="card-box-item-caption">{card.caption}</p>
                            : null
                        }
                    </div>
                </div>

    } else if(layoutAction === 'add'){
        return  <div className="card-box-item-link">
                    <div className="card-box-item-image-container">
                        <img src={`https://server.kando-proto-3.com/${card.desktopSource}`} alt={card.heading} className="card-box-item-image"/>
                    </div>
                    <div className="card-box-item-caption-container">
                        {
                            card.heading !== 'none'
                            ?
                            <h2 className="card-box-item-caption-heading">{card.heading}</h2>
                            : null
                        }
                        {
                            card.caption !== 'none'
                            ?
                            <p className="card-box-item-caption">{card.caption}</p>
                            : null
                        }
                    </div>
                </div>

    } else {
        //display
        return <Link to="/" className="card-box-item-link">
                    <div className="card-box-item-image-container">
                        <img src={`https://server.kando-proto-3.com/${card.desktopSource}`} alt={card.heading} className="card-box-item-image"/>
                    </div>
                    <div className="card-box-item-caption-container">
                        {
                            card.heading !== 'none'
                            ?
                            <h2 className="card-box-item-caption-heading">{card.heading}</h2>
                            : null
                        }
                        {
                            card.caption !== 'none'
                            ?
                            <p className="card-box-item-caption">{card.caption}</p>
                            : null
                        }
                        
                    </div>
                </Link>
    }
}

export default CardDisplay;