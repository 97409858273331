import React from 'react';
import SearchFilter, {SearchFilterGroup} from './searchFilter';
import ProductVerification from './productVerification';
import ProductPromotion from './productPromotion';
import formatSearchFilterName from './helper-format-search-filter-name';
import './CheckboxBuilder.css';


interface Props {
    options:SearchFilterGroup[];
    title:string;
    handleOptionNameSelection:(e:React.ChangeEvent)=>void;
    getFormControlMeasurement?:(e:HTMLDivElement | null)=>void;
}

//original version
const CheckboxBuilder: React.FunctionComponent<Props> = (props:Props) => {
//const CheckboxBuilder = forwardRef<Ref, Props>((props, ref)=>{
    const {options, title, getFormControlMeasurement, handleOptionNameSelection} = props;
    //console.log('options:', options);

    //orginal version for line #52
    //<div key={k} className={`search-filter-group-search-terms stg ${sf.filterName}`}></div>


    function setupSGCheckboxControls(options:SearchFilterGroup[]):JSX.Element[] {
        return options.map((sg, i)=>{
                    return  <div key={i} ref={getFormControlMeasurement} className="add-product-checkbox-form-group sfat">
                                <div className="search-filter-group">
                                    <input type="checkbox" className="admin-checkbox-control" id={`${sg.groupCategoryName}`}
                                        data-search-group-category-name={`${sg.groupCategoryName}`} 
                                        data-search-group-filter-name={`${sg.groupFilterName}`}
                                        data-search-type="Group Filter Name"
                                        name="checkbox-search-filter-group-name" value={`${sg.groupFilterName}`} onChange={handleOptionNameSelection}/>
                                    <label className="search-filter-group-label" htmlFor={`${sg.groupCategoryName}`}>{sg.groupFilterName}</label>
                                    <span className="search-filter-group-category-name">({sg.groupCategoryName})</span>
                                </div>
                                {
                                    sg.searchFilters.map((sf, j)=>{
                                        return  <div key={j} ref={getFormControlMeasurement} 
                                                    className={`search-filter-group-search-filters sfg ${sg.groupFilterName}`}>
                                                    <div className="search-filter">
                                                        <input type="checkbox" className="admin-checkbox-control" id={`${sf.searchFilterId} ${sf.filterName}`}
                                                            data-search-group-category-name={`${sg.groupCategoryName}`} 
                                                            data-search-group-filter-name={`${sg.groupFilterName}`}
                                                            data-search-filter-id={`${sf.searchFilterId}`}
                                                            data-search-type="Search Filter"
                                                        name={`${sg.groupCategoryName} Search Filter`} value={`${sf.filterName}`} onChange={handleOptionNameSelection}/>
                                                        <label className="search-filter-group-label" htmlFor={`${sf.searchFilterId} ${sf.filterName}`}>{sf.filterName}</label>
                                                    </div>
                                                    {
                                                        sf.searchTerms.map((st, k)=>{
                                                            return  <div key={k} className={`search-filter-group-search-terms stg ${formatSearchFilterName(sf.filterName, sg.groupFilterName)}`}>
                                                                        <div className="search-term">
                                                                            <input type="checkbox" className="admin-checkbox-control" id={`${st.searchTermId} ${st.searchTermName}`}
                                                                                data-search-group-category-name={`${sg.groupCategoryName}`}
                                                                                data-search-group-filter-name={`${sg.groupFilterName}`}
                                                                                data-search-filter-name={`${sf.filterName}`}
                                                                                data-search-term-id={`${st.searchTermId}`}
                                                                                data-search-type="Search Term"
                                                                             name={`${sg.groupCategoryName} Search Term`} value={`${st.searchTermName}`} onChange={handleOptionNameSelection}/>
                                                                            <label className="search-filter-group-label" htmlFor={`${st.searchTermId} ${st.searchTermName}`}>{st.searchTermName}</label> 
                                                                        </div>
                                                                    </div>
                                                        })
                                                    }
                                                </div>
                                    })
                                }
                            </div>
                });
    }


    return (
        <fieldset>
            <legend>{title}</legend>
            {setupSGCheckboxControls(options)}
        </fieldset>
    );


    
}

export default CheckboxBuilder;