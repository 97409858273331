import React, {useState, useEffect} from 'react';
import Product from './product';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppSelector} from './redux/hooks';
import './ProductQtyLimitErrorMessage.css';

interface Props{
    product:Product;
    shopByAreaType:boolean;
}

const ProductQtyLimitErrorMessage: React.FunctionComponent<Props> = (props:Props) => {
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const [product, setProduct] = useState(props.product);
    const [showProductQtyLimitErrorMsg, setShowProductQtyLimitErrorMsg] = useState(false);
    const [productQtyLimit, setProductQtyLimit] = useState(99);
    const [shopByAreaType, setShopByAreaType] = useState(props.shopByAreaType);

    useEffect(()=>{

        if(shoppingCartRedux.shoppingCartCategories.length > 0){
            const scc = shoppingCartRedux.shoppingCartCategories.filter((scc)=>{
                            return scc.storeDepartmentName === product.storeDepartmentName;
                        })[0];

            //console.log('scc:', scc);
            if(typeof scc !== 'undefined'){

                const scp = scc.shoppingCartProducts.filter((scp)=>{
                    //console.log('scp:', scp.productId, scp.productQtyLimit, scp.productQtyLimitExceeded);
                    return scp.productId === product.productId;
                })[0];


                if(typeof scp !== 'undefined'){
                    const pql = scp.productQtyLimit;

                    if(scp.productQtyLimitExceeded){
                        setProductQtyLimit(pql);
                        setShowProductQtyLimitErrorMsg(true);

                    } else {
                        setShowProductQtyLimitErrorMsg(false);
                    }
                }

            }



        }

    },[shoppingCartRedux])

    function setupProductQtyLimitCSSClass(sbat:boolean):string{
        if(sbat){
            return 'shop-product-detail-qty-limit-msg';
        } else {
            return 'shop-product-detail-qty-limit-individual-msg';
        }
    }

    function setupProductQtyLimitMsg(sbat:boolean):string|JSX.Element{
        if(sbat){
            return `Oops this product is limited to only ${productQtyLimit} units per order`;
        } else {
            return <div className="product-qty-limit-msg-container">
                        <img src={'https://server.kando-proto-3.com/oops.png'} className="product-qty-limit-msg-img"/>
                        <span className="product-qty-limit-msg">This product has reached its maximum order quantity</span>
                    </div>;
        }
    }

    if(showProductQtyLimitErrorMsg){          
        return <div className={`${setupProductQtyLimitCSSClass(shopByAreaType)}`}>{setupProductQtyLimitMsg(shopByAreaType)}</div>;

    } else {
        return null;
    }

}

export default ProductQtyLimitErrorMessage;