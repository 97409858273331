import Review from './review';
import CustomerRelation from './customerRelation';

export function addReviews(reviews:Review[]):Review[] {
    return reviews.map((review)=>{
                return new Review({
                            reviewId:review.reviewId,
                            userName:review.userName,
                            rating:review.rating,
                            title:review.title,
                            comment:review.comment,
                            approved:review.approved,
                            customerRelation:addCustomerRelation(review.customerRelation as CustomerRelation),
                            submitted:review.submitted,
                            productName:review.productName,
                            productCategoryName:review.productCategoryName
                        });
            });
}

export function addCustomerRelation(customerRelation:CustomerRelation):CustomerRelation | null {
        //console.log('cr:', customerRelation);
    if(customerRelation === null){
        return null;
    } else {
        return new CustomerRelation({
            response:customerRelation.response,
            responseDate:customerRelation.responseDate
        });
    }

}