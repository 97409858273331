import React, {useState, useEffect, useRef} from 'react';
import Product from './product';
import AddProductStoreStructure from './AddProductStoreStructure';

interface Props {
    product:Product;
    handleCloseForm:(formType:string)=>void;
}

const EditProductStoreStructure: React.FunctionComponent<Props> = (props:Props) => {
    const {product, handleCloseForm} = props;
    //console.log('product3-9:', product);

    return <AddProductStoreStructure showEditProduct={true} productToEdit={product} handleCloseForm={handleCloseForm}/>
}

export default EditProductStoreStructure;