import HomePageLayoutItem from "./homePageLayoutItem";

class MarketingPicture extends HomePageLayoutItem {
    public marketingPictureId:number;
    public homePageLayoutId:number;
    public heading:string;
    public caption:string;
    public mobileSource:string;
    public desktopSource:string;

    constructor(options:{
        marketingPictureId?:number,
        homePageLayoutId?:number,
        categoryName?:string,
        categoryId?:number,
        heading?:string,
        caption?:string,
        mobileSource?:string,
        desktopSource?:string

    }={}){
        super(options);
        this.marketingPictureId = options.marketingPictureId || 0;
        this.homePageLayoutId = options.homePageLayoutId || 0;
        this.heading = options.heading || 'none';
        this.caption = options.caption || 'none';
        this.mobileSource = options.mobileSource || '';
        this.desktopSource = options.desktopSource || '';
    }
}  


export default MarketingPicture;