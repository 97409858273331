import React, {useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLoaderData, useParams } from "react-router-dom";
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import { Buffer } from 'buffer';
import Login from './adminLogin';
import Footer from './Footer';
import './AdminLoginForm.css';

interface Props {}


const AdminLoginForm:React.FunctionComponent<Props> = (props: Props) => {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const {destination} = useParams();

    const emailAddressLabelRef = useRef<HTMLSpanElement>(null);
    const passwordLabelRef = useRef<HTMLSpanElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const passwordContainerRef = useRef<HTMLLabelElement>(null);
    const eyeOpenBtnContainerRef = useRef<HTMLSpanElement>(null);
    const eyeClosedBtnContainerRef = useRef<HTMLSpanElement>(null);
    const [password, setPasword] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('We are unable to match the details you entered with our records.');
    const emailAddressInputRef = useRef<HTMLInputElement>(null);
    const emailAddressContainerRef = useRef<HTMLLabelElement>(null);
    const [showEmailAddressEmptyError, setShowEmailAddressEmptyError] = useState(false);
    const [showEmailAddressNotValidError, setShowEmailAddressNotValidError] = useState(false);
    const [showPasswordEmptyError, setShowPasswordEmptyError] = useState(false);
    const [showPasswordLessThan6CharsError, setShowPasswordLessThan6CharsError] = useState(false);
    const [showLoginErrorMsg, setShowLoginErrorMsg] = useState(false);

    


    function focusHandler(e:React.FocusEvent):void {
        const target = (e.target as HTMLInputElement).id;
        
        if(target === 'emailAddress'){
            emailAddressLabelRef.current!.classList.remove('admin-email-address-label-reg');
            emailAddressLabelRef.current!.classList.add('admin-email-address-label-sm');
        }

        if(target === 'password'){
            passwordLabelRef.current!.classList.remove('admin-password-label-reg');
            passwordLabelRef.current!.classList.add('admin-password-label-sm');
        }


    }

    function blurHandler(e:React.FocusEvent):void {
        const target = (e.target as HTMLInputElement).id;
        const inputValueLength = (e.target as HTMLInputElement).value.length;

        if(target === 'emailAddress' && inputValueLength === 0){
            emailAddressLabelRef.current!.classList.remove('admin-email-address-label-sm');
            emailAddressLabelRef.current!.classList.add('admin-email-address-label-reg');
            
        } 
        
        if(target === 'emailAddress' && inputValueLength > 0){
            emailAddressLabelRef.current!.classList.remove('admin-email-address-label-reg');
            emailAddressLabelRef.current!.classList.add('admin-email-address-label-sm');
        }

        if(target === 'password' && inputValueLength === 0){
            passwordLabelRef.current!.classList.remove('admin-password-label-sm');
            passwordLabelRef.current!.classList.add('admin-password-label-reg');
            
        } 
        
        if(target === 'password' && inputValueLength > 0){
            passwordLabelRef.current!.classList.remove('admin-password-label-reg');
            passwordLabelRef.current!.classList.add('admin-password-label-sm');
        }

    }

    function passwordChangeHandler(e:React.ChangeEvent):void {
        const value = (e.target as HTMLInputElement).value;
        setPasword(value);
    }

    function emailAddressChangeHandler(e:React.ChangeEvent):void {
        const value = (e.target as HTMLInputElement).value;
        setEmailAddress(value);
    }

    function eyeClosedImgHandler(e:React.MouseEvent):void {
        const buttonId = e.currentTarget.id;

        if(passwordInputRef.current!.type === 'password'){
            passwordInputRef.current!.type = 'text';

        } else {
            passwordInputRef.current!.type = 'password';
        }

        if(buttonId === 'eyeClosed'){
            eyeClosedBtnContainerRef.current!.style.display = 'none';
            eyeOpenBtnContainerRef.current!.style.display = 'flex';

        } else {
            eyeOpenBtnContainerRef.current!.style.display = 'none';
            eyeClosedBtnContainerRef.current!.style.display = 'flex';
            
        }
    }

    function adminLoginSubmitHandler(e:React.FormEvent):void {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);
        //console.log('formData:', formData.get('emailAddress'), formData.get('password'));

        const emailAddress = formData.get('emailAddress') as string;
        const password = formData.get('password') as string;

        //reset all form element ref style property
        emailAddressContainerRef.current!.style.border = '';
        emailAddressInputRef.current!.style.color = '';
        emailAddressLabelRef.current!.style.color = '';
        passwordContainerRef.current!.style.border = '';
        passwordInputRef.current!.style.color = '';
        passwordLabelRef.current!.style.color = '';

        let emailAddressEmptyError:boolean = false;
        let emailAddressNotValidError:boolean = false;
        if(emailAddress.length === 0){
            //show errors
            emailAddressEmptyError = true;

        } else {
            const isValid = /^\s*([a-zA-Z0-9_\-\.\+\'\%\$]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,25}|[0-9]{1,3})(\]?)\s*$/.test(emailAddress);

            if(!isValid){
                emailAddressNotValidError = true;
            }
            //console.log('eanve:', isValid);

        }

        let passwordEmptyError:boolean = false;
        let passwordLessThan6CharsError:boolean = false;
        if(password.length === 0){
            //show errors
            passwordEmptyError = true;

        } else {
            if(password.length <= 5){
                passwordLessThan6CharsError = true;
            }
        }



        if(emailAddressEmptyError || emailAddressNotValidError || passwordEmptyError || passwordLessThan6CharsError){
            setShowLoginErrorMsg(false);

            if(emailAddressEmptyError){
                setShowEmailAddressEmptyError(true);
                emailAddressContainerRef.current!.style.border = '1px solid #DC291E';
                emailAddressLabelRef.current!.style.color = '#DC291E';

            } else {
                setShowEmailAddressEmptyError(false);
            }

            if(emailAddressNotValidError){
                setShowEmailAddressNotValidError(true);
                emailAddressContainerRef.current!.style.border = '1px solid #DC291E';
                emailAddressLabelRef.current!.style.color = '#DC291E';

            } else {
                setShowEmailAddressNotValidError(false);
            }

            if(passwordEmptyError){
                setShowPasswordEmptyError(true);
                passwordContainerRef.current!.style.border = '1px solid #DC291E';
                passwordLabelRef.current!.style.color = '#DC291E';

            } else {
                setShowPasswordEmptyError(false);
            }

            if(passwordLessThan6CharsError){
                setShowPasswordLessThan6CharsError(true);
                passwordContainerRef.current!.style.border = '1px solid #DC291E';
                passwordLabelRef.current!.style.color = '#DC291E';

            } else {
                setShowPasswordLessThan6CharsError(false);
            }

            return;

        } else {

            const login = {emailAddress, password};

            const c = 'include';
            const m = 'cors';

            //check if credentials match a record in the database
            fetch(`https://server.kando-proto-3.com/admin-login`, {
                method:'POST',
                mode:m,
                credentials:c,
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify(login)

            })
            .then((res)=>res.json())
            .then(({record})=>{
                //console.log('record:', record);
                if(record === null){
                    setShowEmailAddressEmptyError(false);
                    setShowEmailAddressNotValidError(false);
                    setShowPasswordEmptyError(false);
                    setShowPasswordLessThan6CharsError(false);

                    setShowLoginErrorMsg(true);
                } else {
                    //set session storage with login credentials
                    //window.sessionStorage.setItem('signIn', 'true');
                    //previous version
                    //navigate('/admin');
                    navigate(`/${destination}`);
                }

            })
            .catch((err)=>{
                console.log('admin login error:', err.message);
            });
        }

    }


    return <div className="login-container">
                <ProductSearch/>
                <MainNavbar2 hideAdminToolsLink={true}/>
                <div className="login-display-container">
                    <h1 className="login-heading">Sign In</h1>
                    {
                        showLoginErrorMsg
                        ?
                        <div className="login-error-msg-container">
                            <p className="login-error-msg">{errorMessage}</p>
                        </div>
                        :
                        null
                    }
                    <form className="admin-login-form-container" onSubmit={adminLoginSubmitHandler}>
                        <div className="admin-email-address-wrapper">
                            <label ref={emailAddressContainerRef} className="email-address-container" htmlFor="emailAddress">
                                <input ref={emailAddressInputRef} type="text" id="emailAddress" name="emailAddress"
                                    autoComplete="off"
                                    value={emailAddress}
                                    onFocus={focusHandler}
                                    onBlur={blurHandler}
                                    onChange={emailAddressChangeHandler}
                                    className="admin-email-address-input"/>
                                <span ref={emailAddressLabelRef} className="admin-email-address-label-reg">Email Address</span>
                            </label>
                            { showEmailAddressEmptyError ? <p className="admin-login-error-msg">Please enter your email address.</p> : null }
                            { showEmailAddressNotValidError ? <p className="admin-login-error-msg">Please enter a valid email address.</p> : null }
                        </div>
                        <div className="admin-login-password-wrapper">
                            <label ref={passwordContainerRef} className="password-container" htmlFor="password">
                                <input ref={passwordInputRef} type="password" id="password" name="password"
                                    autoComplete="off"
                                    value={password}
                                    onFocus={focusHandler}
                                    onBlur={blurHandler}
                                    onChange={passwordChangeHandler}
                                    className="admin-email-address-input"/>
                                <span ref={passwordLabelRef} className="admin-email-address-label-reg">Password</span>
                                <span ref={eyeClosedBtnContainerRef} className="eye-closed-image-btn-container">
                                    <button type="button" onClick={eyeClosedImgHandler} id="eyeClosed" className="eye-close-image-btn">
                                        <img src="https://server.kando-proto-3.com/eye-close.svg" alt="closed eye"
                                            />
                                    </button>
                                </span>
                                <span ref={eyeOpenBtnContainerRef} className="eye-open-image-btn-container">
                                    <button type="button" onClick={eyeClosedImgHandler} id="eyeOpen" className="eye-open-image-btn">
                                        <img src="https://server.kando-proto-3.com/eye-open.svg" alt="open eye"
                                            />
                                    </button>
                                </span>
                            </label>
                            { showPasswordEmptyError ? <p className="admin-login-error-msg">Please enter your password.</p> : null}
                            { showPasswordLessThan6CharsError ? <p className="admin-login-error-msg">Password length must be greater than 5 characters.</p> : null}
                        </div>
                        <div className="admin-login-btn-wrapper">
                            <button className="admin-login-btn" type="submit">Sign In</button>
                        </div>
                    </form>
                </div>
                <Footer/>
            </div>
};


export default AdminLoginForm;