import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
//import {Link, Router} from '@gatsbyjs/reach-router';
import { Link, useNavigate, useParams, useLocation, useLoaderData } from "react-router-dom";
//import './RelatedProducts.css';
import Product from './product';
import Review from './review';
import ShopProductReviews from './ShopProductReviews';
import getApprovedProductReviews from './helper-get-approved-product-reviews';
import {addReviews} from './helper-add-reviews';
import ShopProductGroupRating from './ShopProductGroupRating';
import ProductDescription from './productDescription';
import NutritionFacts, {NutritionServing, VitaminServing} from './nutritionFacts';
import ProductZoomImage from './productZoomImage';
import ShopProductDesktopLayout from './ShopProductDesktopLayout';
import ShopProductMobileLayout from './ShopProductMobileLayout';
import ShopProductMiniCart from './ShopProductMiniCart';
import LoaderAnimation from './LoaderAnimation';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import { addSearchFilterParams } from './redux/features/searchFilterParams/searchFilterParamsSlice';
import {addProductKeywordSearch, selectProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import loadNavLink from './loadNavLinkHelper';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import GenericErrorMessage from './GenericErrorMessage';
import './ShopProduct.css';
import ShopProductParams from  './shopProductParams';
import ImageZoomMulti from './ImageZoomMulti';
import ImageZoom from './ImageZoom';
import PageNotFound from './PageNotFound';
import formatLinkString from './helper-format-link-string';


interface Props {
    //path:string;
    storeDepartmentName?:string;
    departmentCategoryName?:string;
    productCategoryName?:string;
    //original version
    productName?:string;
    productId?:string;
}


const ShopProduct2: React.FunctionComponent<Props> = (props:Props) => {
    //console.log('pi:', props.productId);
    const ld = useLoaderData() as ShopProductParams;
    const location = useLocation();
    const [showPageNotFound, setShowPageNotFound] = useState(false);
    const isPageNotFound = useRef<boolean>(false);
    //console.log('result:', getParameters(location.pathname));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const shoppingCartRedux = useAppSelector(selectShoppingCart);

    isPageNotFound.current = (ld.type === 'pageNotFound') ? true : false;

    //previous version
    //const [shopProductParams, setShopProductParams] = useState<ShopProductParams>(getParameters(location.pathname));
    const [shopProductParams, setShopProductParams] = useState<ShopProductParams>(ld);

    //const [productCategories, setProductCategories] = useState<ProductCategory[] | []>([]);
    //const [storeDepartmentName, setStoreDepartmentName] = useState(useParams().storeDepartmentName!.replace(/(^<)|(>$)/g, '')/*props.storeDepartmentName*/);
    const [storeDepartmentName, setStoreDepartmentName] = useState(shopProductParams.storeDepartmentName);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(shopProductParams.departmentCategoryName);
    //const [departmentCategoryName, setDepartmentCategoryName] = useState(useParams().departmentCategoryName!.replace(/(^<)|(>$)/g, '')/*props.departmentCategoryName*/);
    const [productCategoryName, setProductCategoryName] = useState(shopProductParams.productCategoryName);
    //const [productCategoryName, setProductCategoryName] = useState(useParams().productCategoryName!.replace(/(^<)|(>$)/g, '')/*props.productCategoryName*/);
    //original version
    //const [productName, setProductName] = useState(useParams().productName!.replace(/(^<)|(>$)/g, '')/*props.productName*/);
    const [productName, setProductName] = useState(shopProductParams.productName);
    //const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState(shopProductParams.productId);
    //const [productId, setProductId] = useState(useParams().productId!!.replace(/(^<)|(>$)/g, '')/*props.productId!*/);
    //const [productCategoryProducts, setProductCategoryProducts] = useState<Product[] | []>([]);
    const [productSelected, setProductSelected] = useState<Product | null>(null);
    const [productReviews, setProductReviews] = useState<Review[] | []>([]);
    const [controller, setController] = useState<AbortController>(new AbortController());
    const [showNutritionListContent, setShowNutritionListContent] = useState(true);
    const closeNutritionListIcon = useRef<HTMLElement>(null);
    const openNutritionListIcon = useRef<HTMLElement>(null);
    const [storeDepartmentId, setStoreDepartmentId] = useState(0);
    const [departmentCategoryId, setDepartmentCategoryId] = useState(0);
    const [productCategoryId, setProductCategoryId] = useState(0);
    const [showProductProducts, setShowProductProducts] = useState(false);

    const [showDesktopLayout, setShowDesktopLayout] = useState(true);
    const showDesktopLayoutRef = useRef<boolean>(false);
    const productBreadcrumContainerRef = useRef<HTMLUListElement>(null);

    const [showMiniCart, setShowMiniCart] = useState(false);
    const miniCartHasProductsRef = useRef<boolean>(false);

    const productLayoutWrapperRefs = useRef<HTMLDivElement[]>([]);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const portrait = useRef<MediaQueryList[]>([]);


    //console.log('pid:', productId);

    useEffect(()=>{
        //console.log('scr4:');
        if(window.innerWidth >= 768 && shoppingCartRedux.shoppingCartCategories.length > 0){
            miniCartHasProductsRef.current = true;
            setShowMiniCart(true);

        } else if(shoppingCartRedux.shoppingCartCategories.length > 0){
            miniCartHasProductsRef.current = true;

        } else {
            miniCartHasProductsRef.current = false;
            setShowMiniCart(false);

            if(productLayoutWrapperRefs.current.length > 0){
                //console.log('ran10');
                productLayoutWrapperRefs.current[0].style.width = '';
                productLayoutWrapperRefs.current[0].style.margin = '';
            }
        }


    },[shoppingCartRedux]);

    
    function getProductLayoutWrapperRef(el:HTMLDivElement|null):void {

        if(el !== null && productLayoutWrapperRefs.current.length === 0){
            productLayoutWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = productLayoutWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                productLayoutWrapperRefs.current.push(el);
            }

        }

        //console.log('plwrs:', productLayoutWrapperRefs.current);


        if(showMiniCart && window.innerWidth <= 1175){
            productLayoutWrapperRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            productLayoutWrapperRefs.current[0].style.margin = '0 auto 0 0';

        } else {
            productLayoutWrapperRefs.current[0].style.width = '100%';
            productLayoutWrapperRefs.current[0].style.margin = '0 auto';
        }

        if(showMiniCart && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }

    }

    useEffect(()=>{

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        /*
        if(!(parseInt(productId) > 0)){
            setIsLoading(false);
            setShowPageNotFound(true);
            isPageNotFound.current = true;
            return;
        }
        */

        if(shopProductParams.type === 'pageNotFound'){
            setIsLoading(false);
            setShowPageNotFound(true);
            isPageNotFound.current = true;
            return;
        }
        
        //console.log('loc:', location.pathname);
        validateUrlParameters(shopProductParams.storeDepartmentName, shopProductParams.departmentCategoryName, 
                            shopProductParams.productCategoryName, shopProductParams.productName, 
                            shopProductParams.productId)
        .then((validate)=>{
            //console.log('called now', validate);
            if(validate){
                setupShopProductResources(productId);
                setShowPageNotFound(false);
                isPageNotFound.current = false;
                
            } else {
                setIsLoading(false);
                setShowPageNotFound(true);
                isPageNotFound.current = true;
            }
        })
        .catch((err)=>{
            //console.log('validate url parameters error:', err.message)
        });
 


       if(window.innerWidth >= 1024){
            setShowDesktopLayout(true);
       } else {
            setShowDesktopLayout(false);
       }   

       window.addEventListener('resize', shopProductResizeHandler);

       //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);
       portrait.current[0] = window.matchMedia("(orientation: portrait)");
       portrait.current[0].addEventListener("change", shopProductResizeHandler);


       dispatch(addProductKeywordSearch!({
        keywordSearch:'',
        priceRangeSearch:'relatedProducts',
        storeDepartmentSearch:'none',
        departmentCategorySearch:'none',
        sentFromKeywordList:false,
        clearKeywordSearch:true
            }));


       return cleanupShopProductResizeHandler;

    },[]);

    function cleanupShopProductResizeHandler():void {
        window.removeEventListener('resize', shopProductResizeHandler);

        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
        portrait.current[0].removeEventListener("change", shopProductResizeHandler);

    }

    function screenOrientationChangeHandler(e:Event):void {
        shopProductResizeHandler(new Event('test'));
    }

    function shopProductResizeHandler(e:Event):void {
        //console.log('scr4:', miniCartHasProductsRef.current);

        if(!isPageNotFound.current){

            if(window.innerWidth >= 1024){
                showDesktopLayoutRef.current = true;
                setShowDesktopLayout(true);

            } else {
                showDesktopLayoutRef.current = false;
                setShowDesktopLayout(false);
            }

            if(window.innerWidth >= 768 && window.innerWidth <= 1175 && miniCartHasProductsRef.current){
                productLayoutWrapperRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
                productLayoutWrapperRefs.current[0].style.margin = '0 auto 0 0';
                setShowMiniCart(true);

            } 
            
            if(window.innerWidth > 1175 && miniCartHasProductsRef.current){
                productLayoutWrapperRefs.current[0].style.width = '100%';
                productLayoutWrapperRefs.current[0].style.margin = '0 auto';
                //setShowMiniCart(true);

            }

            if(window.innerWidth >= 768 && !miniCartHasProductsRef.current){
                productLayoutWrapperRefs.current[0].style.width = '100%';
                productLayoutWrapperRefs.current[0].style.margin = '0 auto';
            }

            if(window.innerWidth < 768){
                productLayoutWrapperRefs.current[0].style.width = '100%';
                productLayoutWrapperRefs.current[0].style.margin = '0 auto';
                setShowMiniCart(false);
            }

            if(miniCartHasProductsRef.current && window.innerWidth >= 768 && window.innerWidth <= 795){
                productBreadcrumContainerRef.current!.style.fontSize = '15px';

            } else {
                productBreadcrumContainerRef.current!.style.fontSize = '';
            }
        
        }

    }


    function getParameters(path:string):ShopProductParams {
        const r = path.slice(5);
        //console.log('str:', decodeURIComponent(r));
        const ur = decodeURIComponent(r);
        //console.log('ur:', ur);
        const matches = ur.matchAll(/(\/<)/g);
        let ips:number[] = [];
        for(const match of matches){
            //console.log('m31:', match);
            if(typeof match.index !== 'undefined'){
                ips.push(match.index);
            }
            
        }
        
        //console.log('ips:', ips);


        /*previous version
        const match = r.match(/\//g) as RegExpMatchArray;
        console.log('match:', match);
        const myRe = /\//g;
        let counter = 0;
        let ips = [];

        while(counter < match.length){
            ips.push(myRe.exec(ur)!.index);
            counter++;
            
        }
        */

        interface PT {
            [index:string]:string;
        }

        let params:string[] = [];

        switch(ips.length){
            case 6:
                //console.log('ips:', ips);
                for(let i=0; i < ips.length; i++){
                    switch(i){
                        case 0:
                            const param1 = ur.slice(ips[i] + 1, ips[1]);
                            params.push(param1);
                            break;
                        case 1:
                            const param2 = ur.slice(ips[1] + 1, ips[2]);
                            params.push(param2);
                            break;
                        case 2:
                            const param3 = ur.slice(ips[2] + 1, ips[3]);
                            params.push(param3);
                            break;
                        case 3:
                            const param4 = ur.slice(ips[3] + 1, ips[4]);
                            params.push(param4);
                            break;
                        case 4:
                            const param5 = ur.slice(ips[4] + 1, ips[5]);
                            params.push(param5);
                            break;
                        case 5:
                            const param6 = ur.slice(ips[5] + 1);
                            params.push(param6);
                            break;
                        default:
                            //throw new Error('no index position found.');
                            isPageNotFound.current = true;
                            return new ShopProductParams({type:'pageNotFound'});
                    }
                    
                }

                break;                

            default:
                //throw new Error('no index position found.');
                isPageNotFound.current = true;
                return new ShopProductParams({type:'pageNotFound'});
        }

        //console.log('params:', params);
        //remove extra forward slashes, "<" and ">"
        params = params.map((param)=>{
                    const fp = param.replace(/(^<)|(>$)/g, '');
                    return fp.replace(/\/\//g, '/');
                });

       //console.log('params:', params);
       //console.log('params:', params.join('/'));
       switch(params.length){
            case 6:
                const rps6 = {
                    type:'product',
                    storeDepartmentName:params[0],
                    departmentCategoryName:params[1],
                    productCategoryName:params[2],
                    productName:params[3],
                    filterName:params[4],
                    productId:params[5]
                    
                };

                /*
                const sb3 = new Sbat({
                    type:'productCategory',
                    storeDepartmentName:params[0],
                    departmentCategoryName:params[1],
                    productCategoryName:params[2],
                    storeDepartmentId:params[3],
                    productCategoryId:params[4]
                });
                */

                if(!(parseInt(rps6.productId) > 0)
                    || rps6.filterName !== 'did'){
                    isPageNotFound.current = true;
                    return new ShopProductParams({type:'pageNotFound'});
                }

                return new ShopProductParams({...rps6});

            default:
                //throw new Error('no params found.');
                isPageNotFound.current = true;
                return new ShopProductParams({type:'pageNotFound'});
       }


    }

    function validateUrlParameters(sdn:string, dcn:string, pcn:string, pn:string, productId:string):Promise<boolean> {
        //console.log('sdn:', sdn);
        
        const params = {sdn, dcn, pcn, pn, productId};

        return fetch('https://server.kando-proto-3.com/shop-product-validate-url-params', {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(params)
                })
                .then(res=>{
                    return res.json();
                })
                .then(({validate})=>{
                    //console.log('validate:', validate);
                    return validate;
                })
                .catch((err)=>{
                    console.error('validate url params error:', err.message);
                    return false;
                });


    }

    function setupShopProductResources(productId:string):void {
        const pId = Number.parseInt(productId, 10);
        Promise.all([getProductStoreAreaIdsPromise(pId),
                    getShopProductPromise(pId),
                    getApprovedProductReviews(productId, controller.signal)
                    ])
        .then(([storeAreaIds, product, productReviews])=>{

            if(storeAreaIds === null || product === null || productReviews === null){
                setShowErrorMessage(true);
                setIsLoading(false);
                return;
            }


            //console.log('product20:', product);
            //console.log('saIds:', storeAreaIds);
            //console.log('prs:', productReviews);
            setProductSelected(addProduct(product!));
            const reviews = addReviews(productReviews);
            setProductReviews(reviews);
        
            const [storeDepartmentId, departmentCategoryId, productCategoryId] = storeAreaIds;
            setStoreDepartmentId(storeDepartmentId);
            setDepartmentCategoryId(departmentCategoryId);
            setProductCategoryId(productCategoryId);
            setShowProductProducts(true);
            setIsLoading(false);
        
            
        })
        .catch(err=>{
            //console.log('setup shop resources error:', err);
            setShowErrorMessage(true);
            setIsLoading(false);
        });
    }

    function handleClickProductBreadCrumLink(e:React.MouseEvent):void {
        setShowProductProducts(false);
        setupShopProductResources(productId);
    }

    async function getProductStoreAreaIdsPromise(productId:number):Promise<number[]|null>{
        try{
            const resp = await fetch(`https://server.kando-proto-3.com/get-product-store-area-ids-mariadb/${productId}`, {
                                    method:'GET',
                                    headers:{
                                        'Accept':'application/json'
                                    }
                                });

            const psaIds = await resp.json();
            return psaIds;
        
        } catch(err){
            //console.log('product store area ids fetch errror:', err);
            return null;
        }
    }

    async function getShopProductPromise(productId:number):Promise<Product|null>{
        try{
            const resp = await fetch(`https://server.kando-proto-3.com/shop-product-mariadb/${productId}`, {
                                        method:'GET',
                                        headers:{
                                            'Accept':'application/json'
                                        }
                                    });

            const product = await resp.json();
            return product;
        
        } catch(err){
            //console.log('get shop product fetch errror:', err);     
            return null;
        } 
    }

    function addProduct(product:Product):Product{
        let p = new Product({...product});
        const pd = addProductDescription(p.productDescription!);
        p.productDescription = pd;
        const nf = addNutritionFacts(p.nutritionFacts!);
        p.nutritionFacts = nf;
        const pzis = addProductZoomImages(p.productZoomImages);
        p.productZoomImages = pzis;
        p.productCategoryName = productCategoryName!;
        p.departmentCategoryName = departmentCategoryName!;
        p.storeDepartmentName = storeDepartmentName!;
        return p;
    }

    function addProductDescription(productDescription:ProductDescription):ProductDescription {
        return new ProductDescription({...productDescription});
    }

    function addNutritionFacts(nutritionFacts:NutritionFacts):NutritionFacts {
        return new NutritionFacts({...nutritionFacts});
    }

    function addProductZoomImages(productZoomImages:ProductZoomImage[]):ProductZoomImage[]{
        return productZoomImages.map((pzi)=>{
                    return new ProductZoomImage({...pzi});
                });
    }


    function resetSearchFilterParams():void {
        dispatch(addSearchFilterParams({keywordSearchType:'',
                                        otherStoreAreaId:'0',
                                        products:[],
                                        showProductSubCategoryFilter:true,
                                        noSearchFilterAttached:false,
                                        searchFilterSelected:'',
                                        searchFilters:[],
                                        searchTermIsSelected:false,
                                        storeAreaName:'',
                                        storeAreaType:'',
                                        storeDepartmentId:'0',
                                        searchTermSelected:''
                                        }));
    }


    //if(productSelected !== null && showProductProducts){
        //console.log('test passed');
        return (
            <div className="shop-product-wrapper">
                <ProductSearch/>
                <MainNavbar2/>
                <MenuListMobileNav/>
                
                {
                    (productSelected !== null && showProductProducts && !isLoading && !showPageNotFound)
                    ?    
                    <div ref={getProductLayoutWrapperRef} className="shop-product-layout-wrapper">

                        <ul ref={productBreadcrumContainerRef} className="shop-product-breadcrum-container">
                            <li className="spbci">
                                <Link to="/" className="shop-store-department-home-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/', navigate)}>Home</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spbci">
                                <Link to="/shop" className="shop-store-department-overview-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/shop/overview', navigate)}>Shop</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spbci">
                                <Link to={`/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${storeDepartmentId}`} className="shop-store-department-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${storeDepartmentId}`, navigate)}>{storeDepartmentName}</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spbci">
                                <Link to={`/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${encodeURIComponent(formatLinkString(departmentCategoryName))}/${storeDepartmentId}/${departmentCategoryId}`} className="shop-product-category-department-name-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${encodeURIComponent(formatLinkString(departmentCategoryName))}/${storeDepartmentId}/${departmentCategoryId}`, navigate)}>{departmentCategoryName}</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spbci">
                                <Link to={`/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${encodeURIComponent(formatLinkString(departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategoryName))}/${storeDepartmentId}/${productCategoryId}`} className="shop-product-categoryname-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>{resetSearchFilterParams(); loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${encodeURIComponent(formatLinkString(departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategoryName))}/${storeDepartmentId}/${productCategoryId}`, navigate)}}>{productCategoryName}</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spbci">{productSelected.productName}</li>
                        </ul>


                        {
                            showDesktopLayout
                            ?
                            <ShopProductDesktopLayout productSelected={productSelected} productReviews={productReviews} />
                            :
                            <ShopProductMobileLayout productSelected={productSelected} productReviews={productReviews} />
                        }

                        <ShopProductReviews 
                                productSelected={productSelected}/>


                    </div>
                    :
                    null
                }

                {       
                    showPageNotFound
                    ?
                    <PageNotFound/>
                    :
                    null
                }

                {
                    isLoading
                    ?
                    <div className="sp-loader-animation-container">
                        {<LoaderAnimation/>}
                    </div>
                    :
                    null
                }

                {
                    showErrorMessage
                    ?
                    <div className="hp-generic-error-msg-container">
                        <GenericErrorMessage/>
                    </div>
                    :
                    null
                }

                {
                    showMiniCart
                    ?
                    <ShopProductMiniCart/>
                    : null
                }       
            </div>
            
        );

    /*} else {
        return <div className="ssdcs-loader-animation-container">
                    <LoaderAnimation/>
                </div>;
    }*/


}

export default ShopProduct2;
