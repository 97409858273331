import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HighlightBackground from '../../highlightBackground';


const initialState: HighlightBackground = {
    highlightBackground:false
};

export const highlightBackgroundSlice = createSlice({
  name: 'highlightBackground',
  initialState,
  reducers: {
    addHighlightBackground: (state, action:PayloadAction<HighlightBackground>) => {
        //console.log('state:', state, 'action:', action);
      state.highlightBackground = action.payload.highlightBackground;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addHighlightBackground } = highlightBackgroundSlice.actions;

interface RootState {
  highlightBackground:HighlightBackground;
}

export const selectHighlightBackground = (state:RootState) =>  {
  return  {
    highlightBackground:state.highlightBackground
  };
}

export default highlightBackgroundSlice.reducer;