import CustomerRelation from './customerRelation';

export default class Review {
    reviewId:number;
    productId:number;
    userName:string;
    title:string;
    rating:number;
    comment:string;
    approved:boolean;
    customerRelation:CustomerRelation | null;
    productName:string;
    productCategoryName:string;
    submitted:number;

    constructor(options:{
        reviewId?:number,
        productId?:number,
        userName?:string,
        title?:string,
        rating?:number,
        comment?:string,
        approved?:boolean,
        customerRelation?:CustomerRelation | null,
        submitted?:number,
        productName?:string,
        productCategoryName?:string

    }={}){
        this.reviewId = options.reviewId || 0;
        this.productId = options.productId || 0;
        this.userName = options.userName || '';
        this.title = options.title || '';
        this.rating = options.rating || 0;
        this.comment = options.comment || '';
        this.approved = options.approved || false;
        this.customerRelation = options.customerRelation || null;
        this.submitted = options.submitted || Date.now();
        this.productName = options.productName || '';
        this.productCategoryName = options.productCategoryName || '';
    }
}