export default class StoreSearchParams {
    lat:number;
    lng:number;
    currentLocationRequest:boolean;
    defaultLocation:string;
    storeSearch:string;
    showStoreLocator:boolean;
    listChangerBtnPosition:number;

    constructor(options:{
        lat?:number,
        lng?:number,
        currentLocationRequest?:boolean,
        defaultLocation?:string,
        storeSearch?:string,
        showStoreLocator?:boolean,
        listChangerBtnPosition?:number

    } = {}){
        this.lat = options.lat || 0;
        this.lng = options.lng || 0;
        this.currentLocationRequest = options.currentLocationRequest || false;
        this.defaultLocation = '78633';
        this.storeSearch = options.storeSearch || '';
        this.showStoreLocator = options.showStoreLocator || false;
        this.listChangerBtnPosition = options.listChangerBtnPosition || 0;
    }
}