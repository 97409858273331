import React, {useState, useEffect} from 'react';
import './HomePageLayoutSelector.css';

export interface ItemLayoutOption {
    value:string;
    order:number;
    id:number;
}

interface Props {
    label:string;
    options:ItemLayoutOption[];
    optionSelected:number;
    optionId:string;
    cssStyles?:{minWidth:number, backgroundPosition:number};
    callback:(e:React.ChangeEvent)=>void;
}

const HomePageLayoutSelector:React.FunctionComponent<Props> = (props:Props)=>{
    const {optionId, cssStyles, callback} = props;
    const [itemOptionSelected, setItemOptionSelected] = useState<number>(props.optionSelected);
    const [itemOptions, setItemOptions] = useState<ItemLayoutOption[]>(props.options);
    const [itemOptionSelectorLabel, setItemOptionSelectorLabel] = useState(props.label);

    useEffect(()=>{
        setItemOptions(props.options);
    },[props.options]);

    useEffect(()=>{
        setItemOptionSelected(props.optionSelected);
    },[props.optionSelected]);

    function setupItemSelecterItemOptionList(options:ItemLayoutOption[]):JSX.Element[] {
        const totalItems = itemOptions.length;
        const itemsOrderFromTop = itemOptionSelected - 1;

        return options.map((o, i)=>{
            //console.log('isoft:', itemsOrderFromTop, 'io:', o.order, 'ios:', itemOptionSelected);
                    if(itemsOrderFromTop === 0 && o.order === itemOptionSelected){
                        return <option key={o.order} value={o.order}>{o.value}</option>

                    } else if(itemsOrderFromTop === 0 && o.order > itemOptionSelected){
                        return <option className="hpls-option" key={o.order} value={o.order}>(move after) {o.value}</option>

                    } else if(o.order === itemOptionSelected && itemsOrderFromTop !== 0){
                        return <option key={o.order} value={o.order}>{o.value}</option>;

                    } else if(o.order > itemOptionSelected && itemsOrderFromTop !== 0){
                        return <option className="hpls-option" key={o.order} value={o.order}>(move after) {o.value}</option>

                    } else {
                        return <option className="hpls-option" key={o.order} value={o.order}>(move before) {o.value}</option>
                    }

                });

    }

    /*
    function itemSelectorChangeHandler(e:React.ChangeEvent):void {
        const optionSelected = (e.target as HTMLSelectElement).value;
        setItemOptionSelected(optionSelected);
    }
    */

    return (<div className="hplb-item-selector-wrapper">
                <label className="hplb-item-selector-label" htmlFor={`hpls${optionId}`}>{itemOptionSelectorLabel}</label>
                <select 
                    style={{minWidth:`${(typeof cssStyles !== 'undefined' ? cssStyles.minWidth : 120 )}px`, backgroundPosition:`calc(100% - ${(typeof cssStyles !== 'undefined' ? cssStyles.backgroundPosition : 14 )}px`}}
                    className="hplb-item-selector" id={`hpls${optionId}`} value={itemOptionSelected} onChange={callback}>
                    {setupItemSelecterItemOptionList(itemOptions)}
                </select>
            </div>);
}

export default HomePageLayoutSelector;