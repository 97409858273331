import React, {useImperativeHandle, useRef, forwardRef, useEffect} from 'react';
import './DBErrorMessage.css';

export interface DBErrorType {
    area:string;
    hasError:boolean;
    fileName?:string;
}

export interface DBError {
    productNameError?:DBErrorType;
    storeLocationNameError?:DBErrorType;
    departmentNameError?:DBErrorType;
    departmentCategoryNameError?:DBErrorType;
    productCategoryNameError?:DBErrorType;
    productSubCategoryNameError?:DBErrorType;
    storeLocationNumberError?:DBErrorType;
    latitudeCoordinateError?:DBErrorType;
    longitudeCoordinateError?:DBErrorType;
    imageErrors:DBErrorType[];
}


interface Props {
    dbError:DBError;
}

type Ref = {
    dbemcRef:React.RefObject<HTMLDivElement>
};

const DBErrorMessage = forwardRef<Ref, Props>((props, ref)=>{
    const {productNameError:pne, productSubCategoryNameError:pscne, productCategoryNameError:pcne, departmentCategoryNameError:dcne, 
        storeLocationNameError:slne, storeLocationNumberError:sne, departmentNameError:dne,
        latitudeCoordinateError:latce, longitudeCoordinateError:lngce, imageErrors:ies} = props.dbError;
    //console.log('dberrorMessage');
    //console.log('pne:', pne)
    const dbemcRef = useRef<HTMLDivElement>(null);

    const closeBtnContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, ()=>({
        
        get dbemcRef(){
            return dbemcRef;
        }
        
        })
    );

    useEffect(()=>{
        //console.log('wh:', window.innerHeight);
        
        if(window.innerHeight < 500){
            closeBtnContainerRef.current!.classList.remove('db-error-msg-close-btn-container-reg');
            closeBtnContainerRef.current!.classList.add('db-error-msg-close-btn-container-sm');
        } 
        

        window.addEventListener('resize', dbErrorMessageResizeHandler);

        window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupDBErrorMessageResizeHandler;
    },[]);

    function dbErrorMessageResizeHandler(e:Event):void {

        if(dbemcRef.current!.getBoundingClientRect().height < window.innerHeight){
            dbemcRef.current!.style.height = '';
            dbemcRef.current!.style.overflowY = '';
            dbemcRef.current!.style.overscrollBehaviorY = '';
        }

        if(dbemcRef.current!.getBoundingClientRect().height > window.innerHeight){
            dbemcRef.current!.style.height = `${window.innerHeight + 1}px`;
            dbemcRef.current!.style.overflowY = 'scroll';
            dbemcRef.current!.style.overscrollBehaviorY = 'contain';
        }

        
        if(window.innerHeight < 500){
            closeBtnContainerRef.current!.classList.remove('db-error-msg-close-btn-container-reg');
            closeBtnContainerRef.current!.classList.add('db-error-msg-close-btn-container-sm');

        } else {
            closeBtnContainerRef.current!.classList.remove('db-error-msg-close-btn-container-sm');
            closeBtnContainerRef.current!.classList.add('db-error-msg-close-btn-container-reg');
        }
        
    }

    function screenOrientationChangeHandler(e:Event):void {
        dbErrorMessageResizeHandler(new Event('test'));
    }

    function cleanupDBErrorMessageResizeHandler():void {
        window.removeEventListener('resize', dbErrorMessageResizeHandler);
        window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function closeDBErrorMessageHandler(e:React.MouseEvent):void {
        //console.log('eh:', dbemcRef.current!.offsetHeight);
        const eh = dbemcRef.current!.offsetHeight;
        dbemcRef.current!.style.top = `-${eh + 60}px`;
    }

    function addProductNameError(pne:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Product Name</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{pne.area}</li>
                    </ul>
                </div>
                );

    }

    function addProductCategoryNameError(pcne:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Product Category Name</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{pcne.area}</li>
                    </ul>
                </div>
                );

    }

    function addStoreLocationNameError(dcne:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Store Location Name</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{slne!.area}</li>
                    </ul>
                </div>
                );

    }

    function addStoreLocationNumberError(sne:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Store Location Number</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{sne!.area}</li>
                    </ul>
                </div>
                );

    }

    function addLatutudeCoordinateError(latce:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Latitude Coordinate</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{latce!.area}</li>
                    </ul>
                </div>
                );

    }

    function addLongitudeCoordinateError(lgnce:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Longitude Coordinate</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{lgnce!.area}</li>
                    </ul>
                </div>
                );

    }


    function addDepartmentNameError(dcne:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Department Name</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{dne!.area}</li>
                    </ul>
                </div>
                );

    }

    function addDepartmentCategoryNameError(dcne:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Department Category Name</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{dcne.area}</li>
                    </ul>
                </div>
                );

    }


    function addProductSubCategoryNameError(pscne:DBErrorType):JSX.Element {

        return (
                <div className="db-error-message-list-container">
                    <h3 className="db-error-message-list-title">Product SubCategory Name</h3>
                    <ul className="db-error-message-list">
                        <li className="db-error-message-list-item pie">{pscne.area}</li>
                    </ul>
                </div>
                );

    }

    function addImageErrors(ies:DBErrorType[]):JSX.Element[] {
        return  ies.map((ie, k)=>{
                    return <div key={k} className="db-error-message-list-container">
                                <h3 className="db-error-message-list-title">{ie.area}</h3>
                                    <ul className="db-error-message-list">
                                        <li className="db-error-message-list-item ie">{ie.fileName}</li>
                                    </ul>
                            </div>
                });

    }

        
    return (
        <div ref={dbemcRef} className="db-error-message-container">
            <h1 className="db-error-message-title">Items Already Exist</h1>
            <div className="db-error-message-list-container-wrapper">
                {(pne !== undefined && pne.hasError) ? addProductNameError(pne) : null}
                {(pscne !== undefined && pscne.hasError) ? addProductSubCategoryNameError(pscne) : null}
                {(pcne !== undefined && pcne.hasError) ? addProductCategoryNameError(pcne) : null}
                {(dcne !== undefined && dcne.hasError) ? addDepartmentCategoryNameError(dcne) : null}
                {(dne !== undefined && dne.hasError) ? addDepartmentNameError(dne) : null}
                {(slne !== undefined && slne.hasError) ? addStoreLocationNameError(slne) : null}
                {(sne !== undefined && sne.hasError) ? addStoreLocationNumberError(sne) : null}
                {(latce !== undefined && latce.hasError) ? addLatutudeCoordinateError(latce) : null}
                {(lngce !== undefined && lngce.hasError) ? addLongitudeCoordinateError(lngce) : null}
                {(ies.length > 0) ? addImageErrors(ies) : null}
            </div>
            <div ref={closeBtnContainerRef} className="db-error-msg-close-btn-container-reg">
                <button type="button" className="db-error-msg-close-btn"
                    onClick={closeDBErrorMessageHandler}>Close</button>
            </div>
        </div>
    );





});

export default DBErrorMessage;