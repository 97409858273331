import React, {useEffect, useState} from 'react';
import { Outlet, useParams, useLocation } from "react-router-dom";
import PageNotFound from './PageNotFound';
//import './Products.css';

interface Props {
    //path?:string;
    //children:React.ReactElement;
}

//{children}

const Products: React.FunctionComponent<Props> = (props:Props) => {
    //const { children } = props;

    return (
        <div className="products-shape-container-wrapper">
            <Outlet/>            
        </div>
        
    );
}

export default Products;