import React, {useState, useRef, useEffect} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import {throttle} from 'lodash';
import {addProductKeywordSearch, selectProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppDispatch} from './redux/hooks';
import loadNavLink from './loadNavLinkHelper';
import './CategoryShifter2.css';

interface Props {}

interface ScrollBreakPoint {
    categoryId:number;
    positionTop:number;
    positionHeight:number;
    positionElement:HTMLDivElement;
}

interface Shifter {
    categoryId:number;
    shifterId:number;
    heading:string;
    msg:string;
    shifterImgPath:string;
    width:number;
    height:number;
}

interface Category {
    categoryId:number;
    categoryImgPath:string;
    width:number;
    height:number;
    shifters:Shifter[];
}

const categories = [
    {
        categoryId:1,
        categoryImgPath:'https://server.kando-proto-3.com/Feb-LP-Taco-Tuesday.webp',
        width:304,
        height:547,
        shifters:[
            {
                categoryId:1,
                shifterId:1,
                heading:'siete grain-free tortilla shells',
                //shifterImgPath:'images/whats-new/tacos/Feb-LP-Siete-Taco-Shells-lg.webp',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-Siete-Taco-Shells-lg.webp',
                msg:`Bring on the salsa! These one of a kind grain free, gluten free, vegan tortilla shells
                    will have you wanting taco Tuesday every night of the week.`,
                width:608,
                height:547
            },
            {
                categoryId:1,
                shifterId:2,
                heading:'diablo verde salsas (mild, medium, and hot)',
                //shifterImgPath:'images/whats-new/tacos/Feb-LP-Diablo-Verde.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-Diablo-Verde.jpg',
                msg:`These creamy cilantro salsas took home the grand prize in the 2019 Quest for Texas Best and are noe at home on H-E-B shelves.`,
                width:304,
                height:547
            }
        ]
    },
    {
        categoryId:2,
        //categoryImgPath:'images/whats-new/brews/Feb-LP-New-Brews.webp',
        categoryImgPath:'https://server.kando-proto-3.com/Feb-LP-New-Brews.webp',
        width:304,
        height:547,
        shifters:[
            {
                categoryId:2,
                shifterId:3,
                heading:'cafe ole by H-E-B cold brew pouch',
                //shifterImgPath:'images/whats-new/brews/Feb-LP-Cafe-Ole.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-Cafe-Ole.jpg',
                msg:`There's a brand new way to enjoy your Cafe Ole by H-E-B. Enjoy sipping on your favorite Texas coffee flavors over ice with
                    cold brew poucyes.`,
                width:304,
                height:547
            },
            {
                categoryId:2,
                shifterId:4,
                heading:'pbr hard coffee',
                //shifterImgPath:'images/whats-new/brews/Feb-LP-PBR.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-PBR.jpg',
                msg:`Enjoy your coffee with a kick! This boozy caffeinated beverage from Pabst Blue Ribbon is a unique twist on two Texas favorites.`,
                width:304,
                height:547
            },
            {
                categoryId:2,
                shifterId:5,
                heading:'ranch water',
                //shifterImgPath:'images/whats-new/brews/Feb-LP-Ranch-Water.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-Ranch-Water.jpg',
                msg:`Shotgun Ranch Water is the first seltzer Texas born and brewed. The flavor is a modern twist on a Texas classic with hints of lime,
                    agave with refreshing mineral water.`,
                width:304,
                height:547
            }
        ]
    },
    {
        categoryId:3,
        //categoryImgPath:'images/whats-new/mixers/Feb-LP-New-Mixers.webp',
        categoryImgPath:'https://server.kando-proto-3.com/Feb-LP-New-Mixers.webp',
        width:304,
        height:547,
        shifters:[
            {
                categoryId:3,
                shifterId:6,
                heading:'central market organic premium seltzers',
                //shifterImgPath:'images/whats-new/mixers/Feb-LP-Central-Market-Soda.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-Central-Market-Soda.jpg',
                msg:`Class up your cocktail with new Central Market Organic Premium Seltzers in brand new flavors like Lemon 
                    Blackberry and Clementine Ginger.`,
                width:608,
                height:547
            },
            {
                categoryId:3,
                shifterId:7,
                heading:'H-E-B soda ginger beer club soda lime and club soda watermelon',
                //shifterImgPath:'images/whats-new/mixers/Feb-LP-HEB-Soda.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-HEB-Soda.jpg',
                msg:`Perfect as a single serve cocktail mixer, grab these new H-E-B Mixers in the newest flavor, Club Soda Watermelon.`,
                width:304,
                height:547
            }
        ]
    },
    {
        categoryId:4,
        //categoryImgPath:'images/whats-new/soaps/Feb-LP-Soaps.webp',
        categoryImgPath:'https://server.kando-proto-3.com/Feb-LP-Soaps.webp',
        width:304,
        height:547,
        shifters:[
            {
                categoryId:4,
                shifterId:8,
                heading:'H-E-B seasonal dish soap bluebonnet',
                //shifterImgPath:'images/whats-new/soaps/Feb-LP-BlueBonnet.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-BlueBonnet.jpg',
                msg:`Bring the scent of the Texas state flower into your home with Limited Edition Ultra Concetrated Bluebonnet Dish Soap.`,
                width:304,
                height:547
            },
            {
                categoryId:4,
                shifterId:9,
                heading:'H-E-B seasonal dish soap yellow rose',
                //shifterImgPath:'images/whats-new/soaps/Feb-LP-YellowRose.webp',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-YellowRose.webp',
                msg:`Texas pride comes to your kitchen with our newest Limited Edition Yellow Rose Dish Soap.`,
                width:304,
                height:547
            },
            {
                categoryId:4,
                shifterId:10,
                heading:'H-E-B seasonal dish soap indian paintbrush',
                //shifterImgPath:'images/whats-new/soaps/Feb-LP-IndianPaintbrush.webp',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-IndianPaintbrush.webp',
                msg:`Bring a little bit of the Texas hill country into your kitchen with H-E-B Limited Edition Ultra Concetrated Indian Paintbrush Dish Soap.`,
                width:304,
                height:547
            }
        ]
    },
    {
        categoryId:5,
        //categoryImgPath:'images/whats-new/sweets/Feb-LP-Sweet-Tooth.webp',
        categoryImgPath:'https://server.kando-proto-3.com/Feb-LP-Sweet-Tooth.webp',
        width:304,
        height:547,
        shifters:[
            {
                categoryId:5,
                shifterId:11,
                heading:'H-E-B creamy creations® cherry & chocolate',
                //shifterImgPath:'images/whats-new/sweets/Feb-LP-CC-Cherry.jpg',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-CC-Cherry.jpg',
                msg:`H-E-B creamy creations® Cherry & Chocolate Limited Edition Ice Cream will have your toes tapping. Enjoy
                    this mashup of two favorites, cherry flavored ice cream and chocolate chips.`,
                width:304,
                height:547
            },
            {
                categoryId:5,
                shifterId:12,
                heading:'H-E-B creamy creations® puppy love',
                //shifterImgPath:'images/whats-new/sweets/Feb-LP-CC-Puppy.webp',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-CC-Puppy.webp',
                msg:`Fall in love with H-E-B Creamy Creations® Limited Edition Puppy Love Ice Cream! Swoon
                    with every bite of vanilla ice cream and chocolate chips swirled with peanut butter & graham cracker.`,
                width:304,
                height:547
            },
            {
                categoryId:5,
                shifterId:13,
                heading:'H-E-B creamy creations® lone star rodeo',
                //shifterImgPath:'images/whats-new/sweets/Feb-LP-CC-LoneStar.webp',
                shifterImgPath:'https://server.kando-proto-3.com/Feb-LP-CC-LoneStar.webp',
                msg:`It's rodeo season, Texas! Kick off your boots and enjoy H-E-B Creamy Creations® Limited Edition Lone Star Rodeo - 1905 chocolate
                    ice cream with roased pecans and chocolate cookie swirls.`,
                width:304,
                height:547
            }
        ]
    }
];

const CategoryShifter2:React.FunctionComponent<Props> = (props:Props)=>{
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [cs, setCs] = useState<Category[]>(categories);
    const categoryShifterContainerRefs = useRef<HTMLDivElement[]>([]);
    const categoryShifterItemRefs = useRef<HTMLDivElement[]>([]);
    const itemImageContainerRefs = useRef<HTMLDivElement[]>([]);
    const categoryShifterOverlayParagraphRefs = useRef<HTMLParagraphElement[]>([]);
    const cscBreakPoints = useRef<ScrollBreakPoint[]>([]);
    const csiBreakPoints = useRef<ScrollBreakPoint[]>([]);
    const [firstCategoryShifterItems, setFirstCategoryShifterItems] = useState<HTMLDivElement[]>([]);

    const pp = useRef<number>(0);
    const cp = useRef<number>(0);
    const ww = useRef<number>(0);
    const raf = useRef<number>(0);
    const isDone = useRef<boolean>(true);
    const inTransition = useRef<boolean>(false);

    const throttleRef = useRef(throttle(scrollHandler,100));

    const portrait = useRef<MediaQueryList[]>([]);

    function handleShifterMouseEnter(e:React.MouseEvent):void {
        const ele = (e.target as HTMLElement).closest('.hover-effect') as HTMLDivElement;
        ele.style.backgroundColor = 'black';
        const imgElement = ele.firstElementChild as HTMLImageElement;
        imgElement.style.transform = 'scale(1.1)';
        imgElement.style.opacity = '.5';
        const categoryShifterOverlayElement = ele!.lastElementChild as HTMLDivElement;
        const overayHeadingElement = categoryShifterOverlayElement.firstElementChild as HTMLHeadingElement;
        overayHeadingElement.style.transform = 'translateY(0)';
        const overlayParagraphElement = categoryShifterOverlayElement.lastElementChild as HTMLParagraphElement;
        overlayParagraphElement.style.transform = 'translateX(0)';
        overlayParagraphElement.style.transition = 'transform .35s linear';
    }

    function handleShifterMouseLeave(e:React.MouseEvent):void {
        const ele = (e.target as HTMLElement).closest('.hover-effect') as HTMLDivElement;
        ele.style.backgroundColor = '';
        const imgElement = ele.firstElementChild as HTMLImageElement;
        imgElement.style.transform = 'scale(1.0)';
        imgElement.style.opacity = '1';
        const categoryShifterOverlayElement = ele.lastElementChild as HTMLDivElement;
        const overayHeadingElement = categoryShifterOverlayElement.firstElementChild as HTMLHeadingElement;
        overayHeadingElement.style.transform = 'translateY(50px)';
        const overlayParagraphElement = categoryShifterOverlayElement.lastElementChild as HTMLParagraphElement;
        overlayParagraphElement.style.transform = 'translateX(100%)';
        overlayParagraphElement.style.transition = 'transform .1s linear';
    }

    useEffect(()=>{
        let counter = 0;
        let pCounter = 0;
        categories.forEach((c, i)=>{
            counter = counter > 0 ? counter + 1 : counter;
            setupCategoryShifterContainerClass(c, counter);
            setupCategoryShifterItemClass(c, c.shifters, counter, true);
            setupItemImageContainerClass(c, counter);
        
            c.shifters.forEach((s, j)=>{
                ++counter;
                setupCategoryShifterItemClass(s, c.shifters, counter, false);
                setupItemImageContainerClass(s, counter);
                setupCategoryShifterOverlayParagraphClass(s, pCounter);
                ++pCounter;
            });
        });

        const cscs = categoryShifterContainerRefs.current.map((cscr, pos)=>{
                        return ({
                            categoryId:pos + 1,
                            positionTop:cscr.offsetTop,
                            positionHeight:cscr.offsetTop + cscr.offsetHeight,
                            positionElement:cscr
                        });
                    });

        //console.log('cscs:', cscs);

        cscBreakPoints.current = cscs;

        const csis = categoryShifterItemRefs.current.map((csi, pos)=>{
                        return ({
                            categoryId:pos + 1,
                            positionTop:csi.offsetTop,
                            positionHeight:csi.offsetTop + csi.offsetHeight,
                            positionElement:csi
                        });
                    });
        
        //console.log('csis:', csis);

        csiBreakPoints.current = csis;


        dispatch(addProductKeywordSearch!({
            keywordSearch:'',
            priceRangeSearch:'relatedProducts',
            storeDepartmentSearch:'none',
            departmentCategorySearch:'none',
            sentFromKeywordList:false,
            clearKeywordSearch:true
                }));


    },[]);

    useEffect(()=>{
        //setup scroll event functionality
        //window.addEventListener('scroll', throttle(scrollHandler,100));
        window.addEventListener('scroll', throttleRef.current);

        return cleanupScrollHandler;

    },[]);

    function cleanupScrollHandler():void {
        //window.removeEventListener('scroll', throttle(scrollHandler,100));
        window.removeEventListener('scroll', throttleRef.current);
    }

    function scrollHandler(e:Event):void {
        //console.log('pos:', window.scrollY);

        cp.current = window.scrollY;
        //setup dynamically the scrolling breaking points based on the number
        //of containers for desktop and the number of items for mobile
        //make sure not to trigger the first item in each list.

        ww.current = document.documentElement.clientWidth;

        if(isDone.current){
            isDone.current = false;
            raf.current = window.requestAnimationFrame(rafHandler);
        }
        

    }

    function rafHandler():void {
        if(ww.current >= 768){
            //desktop settings
            getDesktopBreakPoints(cscBreakPoints.current);
        } else {
            //mobile settings
            getMobileBreakPoints(csiBreakPoints.current, cscBreakPoints.current);
        }

        pp.current = cp.current;
        isDone.current = true;
    }

    function getMobileBreakPoints(csis:ScrollBreakPoint[], cscs:ScrollBreakPoint[]):void {
        //console.log('pos:', csis.length);
        for(let i=0; i < csis.length; i++){
            if((window.scrollY > csis[i].positionTop) && (window.scrollY < csis[i].positionHeight) && !inTransition.current){
                //console.log('tran');
                //add css styles to all child elements
                //excluding the first child element in each list
                const result = firstCategoryShifterItems.filter((fcsi)=>{
                                    return fcsi === csis[i].positionElement;
                                });
                
                //console.log('result:', result[0]);

                if(result.length === 0){
                    //console.log('ele:', csis[i].positionElement);
                    const ele = csis[i].positionElement.firstElementChild as HTMLDivElement;
                    ele.style.backgroundColor = 'black';
                    const imgElement = ele.firstElementChild as HTMLImageElement;
                    imgElement.style.transform = 'scale(1.1)';
                    imgElement.style.opacity = '.5';
                    const categoryShifterOverlayElement = ele!.lastElementChild as HTMLDivElement;
                    const overayHeadingElement = categoryShifterOverlayElement.firstElementChild as HTMLHeadingElement;
                    overayHeadingElement.style.transform = 'translateY(0)';
                    const overlayParagraphElement = categoryShifterOverlayElement.lastElementChild as HTMLParagraphElement;
                    overlayParagraphElement.style.transform = 'translateX(0)';
                    overlayParagraphElement.style.transition = 'transform .35s linear';
                }

                inTransition.current = true;

            }

            
            if((window.scrollY < csis[i].positionTop) || (window.scrollY > csis[i].positionHeight) && inTransition.current){
                const result = firstCategoryShifterItems.filter((fcsi)=>{
                                    return fcsi === csis[i].positionElement;
                                });

                //console.log('result:', result[0]);

                if(result.length === 0){
                    //console.log('ele:', csis[i].positionElement);
                    const ele = csis[i].positionElement.firstElementChild as HTMLDivElement;
                    ele.style.backgroundColor = '';
                    const imgElement = ele.firstElementChild as HTMLImageElement;
                    imgElement.style.transform = 'scale(1.0)';
                    imgElement.style.opacity = '1';
                    const categoryShifterOverlayElement = ele.lastElementChild as HTMLDivElement;
                    const overayHeadingElement = categoryShifterOverlayElement.firstElementChild as HTMLHeadingElement;
                    overayHeadingElement.style.transform = 'translateY(50px)';
                    const overlayParagraphElement = categoryShifterOverlayElement.lastElementChild as HTMLParagraphElement;
                    overlayParagraphElement.style.transform = 'translateX(100%)';
                    overlayParagraphElement.style.transition = 'transform .1s linear'; 
                }

                inTransition.current = false;

            }
            


        }
    }


    function getDesktopBreakPoints(cscs:ScrollBreakPoint[]):void {
        //console.log('pos:', cscs.length);
        for(let i=0; i < cscs.length; i++){
            if((window.scrollY > cscs[i].positionTop) && (window.scrollY < cscs[i].positionHeight) && !inTransition.current){
                //add css styles to all child elements
                //excluding the first child element in each list
                const childElements = Array.from(cscs[i].positionElement.children) as HTMLDivElement[];
                //remove the first element
                childElements.shift();
                for(let j=0; j < childElements.length; j++){
                    //console.log('tran');
                    const ie = childElements[j];
                    const ele = ie.firstElementChild as HTMLDivElement;
                    ele.style.backgroundColor = 'black';
                    const imgElement = ele.firstElementChild as HTMLImageElement;
                    imgElement.style.transform = 'scale(1.1)';
                    imgElement.style.opacity = '.5';
                    const categoryShifterOverlayElement = ele!.lastElementChild as HTMLDivElement;
                    const overayHeadingElement = categoryShifterOverlayElement.firstElementChild as HTMLHeadingElement;
                    overayHeadingElement.style.transform = 'translateY(0)';
                    const overlayParagraphElement = categoryShifterOverlayElement.lastElementChild as HTMLParagraphElement;
                    overlayParagraphElement.style.transform = 'translateX(0)';
                    overlayParagraphElement.style.transition = 'transform .35s linear';
                }

                inTransition.current = true;

            }

            
            if((window.scrollY < cscs[i].positionTop) || (window.scrollY > cscs[i].positionHeight) && inTransition.current){
                const childElements = Array.from(cscs[i].positionElement.children) as HTMLDivElement[];
                //remove the first element
                childElements.shift();
                for(let j=0; j < childElements.length; j++){
                    //console.log('tran');
                    const ie = childElements[j];
                    const ele = ie.firstElementChild as HTMLDivElement;
                    ele.style.backgroundColor = '';
                    const imgElement = ele.firstElementChild as HTMLImageElement;
                    imgElement.style.transform = 'scale(1.0)';
                    imgElement.style.opacity = '1';
                    const categoryShifterOverlayElement = ele.lastElementChild as HTMLDivElement;
                    const overayHeadingElement = categoryShifterOverlayElement.firstElementChild as HTMLHeadingElement;
                    overayHeadingElement.style.transform = 'translateY(50px)';
                    const overlayParagraphElement = categoryShifterOverlayElement.lastElementChild as HTMLParagraphElement;
                    overlayParagraphElement.style.transform = 'translateX(100%)';
                    overlayParagraphElement.style.transition = 'transform .1s linear'; 
                }

                inTransition.current = false;

            }
            


        }
    }


    useEffect(()=>{
        window.addEventListener('resize', categoryShifterResizeHandler);
        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);
        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", categoryShifterResizeHandler);

        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', categoryShifterResizeHandler);
        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
        portrait.current[0].removeEventListener("change", categoryShifterResizeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        categoryShifterResizeHandler(new Event('test'));
    }

    function categoryShifterResizeHandler(e:Event):void {
        //console.log('resize:', window.screen.orientation.type, window.innerWidth);
        let counter = 0;
        let pCounter = 0;
        categories.forEach((c, i)=>{
            counter = counter > 0 ? counter + 1 : counter;
            setupCategoryShifterContainerClass(c, counter);
            setupCategoryShifterItemClass(c, c.shifters, counter, true);
            setupItemImageContainerClass(c, counter);
        
            c.shifters.forEach((s, j)=>{
                ++counter;
                setupCategoryShifterItemClass(s, c.shifters, counter, false);
                setupItemImageContainerClass(s, counter);
                setupCategoryShifterOverlayParagraphClass(s, pCounter);
                ++pCounter;
            });
        });

        const cscs = categoryShifterContainerRefs.current.map((cscr, pos)=>{
                        return ({
                            categoryId:pos + 1,
                            positionTop:cscr.offsetTop,
                            positionHeight:cscr.offsetTop + cscr.offsetHeight,
                            positionElement:cscr
                        });
                    });

        //console.log('cscs:', cscs);

        cscBreakPoints.current = cscs;


        //console.log('cscref:', categoryShifterContainerRefs.current.length, categoryShifterContainerRefs.current[0].offsetHeight,);
        const csis = categoryShifterItemRefs.current.map((csi, pos)=>{
                        return ({
                            categoryId:pos + 1,
                            positionTop:csi.offsetTop,
                            positionHeight:csi.offsetTop + csi.offsetHeight,
                            positionElement:csi
                        });
                    });

        //console.log('csis:', csis);
        csiBreakPoints.current = csis;
    }

    function setupCategoryShifterContainerClass(item:Category, itemPos:number):void {
        //console.log('itemPos:', itemPos);
        //console.log('itemPos:', itemPos, categoryShifterContainerRefs.current[itemPos]);

        const shifterContainerRef = categoryShifterContainerRefs.current[item.categoryId - 1];

        const ww = document.documentElement.clientWidth;

        if(ww >= 768){
            //shifterContainerRef.style.marginBottom = '79px';
            shifterContainerRef.style.marginTop = '';
            shifterContainerRef.style.paddingLeft = '';
            shifterContainerRef.style.paddingRight = '';

        } else {
            shifterContainerRef.style.marginTop = '0px';
            //shifterContainerRef.style.marginBottom = '0px';
            shifterContainerRef.style.paddingLeft = '15px';
            shifterContainerRef.style.paddingRight = '15px';
        }
        
    }

    function isItemLast(item:Shifter, itemList:Shifter[]):boolean {
        //add left border if item is last in list
        const lastItem = itemList[itemList.length - 1];

        if((item as Shifter).shifterId === lastItem.shifterId){
            //itemContainerRef.style.borderRight = '2px solid #888';
            return true;

        } else {
            return false;
        }
    }

    function setupCategoryShifterItemClass(item:Category|Shifter, itemList:Shifter[], itemPos:number, isFirstItem:boolean):void {
        //console.log('itemPos:', itemPos, categoryShifterItemRefs.current[itemPos]);
        //get the first item in each list
        if(isFirstItem){
            firstCategoryShifterItems.push(categoryShifterItemRefs.current[itemPos]);
            setFirstCategoryShifterItems([...firstCategoryShifterItems]);
        }
       
        const shifterContainerRef = categoryShifterContainerRefs.current[item.categoryId - 1];
        const itemContainerRef = categoryShifterItemRefs.current[itemPos];

        const icwp = (item.width / shifterContainerRef.offsetWidth) * 100;
        //console.log('pcalc:', shifterContainerRef.offsetWidth, item.width, icwp, itemContainerRef);

        const ww = document.documentElement.clientWidth;

        itemContainerRef.style.width = `${icwp}%`;

        if(ww >= 768){
            itemContainerRef.style.width = `${icwp}%`;

        }

        if(ww >= 768 && !isItemLast(item as Shifter, itemList)){
            itemContainerRef.style.borderRight = '2px solid #888';
        }

        if(ww >= 768 && isItemLast(item as Shifter, itemList)){
            itemContainerRef.style.borderRight = '2px solid #888';
        }
        

        if(ww < 768 && !isFirstItem){
                itemContainerRef.style.width = '100%';
                itemContainerRef.style.borderRight = '';
        } 
        
        if(ww < 768 && isFirstItem) {
            itemContainerRef.style.width = `${38.143}%`;
            itemContainerRef.style.borderRight = '';
        } 
        
        
    }

    function setupItemImageContainerClass(item:Category|Shifter, itemPos:number):void {
        //console.log('itemPos:', itemPos , itemImageContainerRefs.current[itemPos]);
        const itemContainerRef = categoryShifterItemRefs.current[itemPos];
        const itemImageContainerRef = itemImageContainerRefs.current[itemPos];

        
        const paddingBottom = (item.height / item.width) * 100;
        //console.log('calc:', item.height / item.width, 'iw:', item.width, itemImageContainerRef);
        itemImageContainerRef.style.height = '0px';
        itemImageContainerRef.style.paddingBottom = `${paddingBottom}%`;

        //console.log('itemPos:', itemPos);
    }

    function setupCategoryShifterOverlayParagraphClass(item:Shifter, itemPos:number):void {
        //console.log('itemPos:', itemPos , categoryShifterOverlayParagraphRefs.current[itemPos]);
        const itemContainerRef = categoryShifterItemRefs.current[itemPos];
        const itemImageContainerRef = itemImageContainerRefs.current[itemPos];
        const categoryShifterOverlayParagraphRef = categoryShifterOverlayParagraphRefs.current[itemPos];

        //console.log('csopRef:', categoryShifterOverlayParagraphRef, item.width);
        //change at width 290px from 2rem to 1rem on margin-top;
        const ww = document.documentElement.clientWidth;

        /*
        if(ww > 290 && item.width > 600){
            //categoryShifterOverlayParagraphRef.style.marginTop = '2rem';
        }
        
        if(ww <= 290 && item.width > 600){
            categoryShifterOverlayParagraphRef.style.marginTop = '0';
        }

        if(ww <= 290 && item.width < 600){
            categoryShifterOverlayParagraphRef.style.marginTop = '6rem';
        }

        if(ww < 768 && ww > 290 && item.width < 600){
            categoryShifterOverlayParagraphRef.style.marginTop = '20rem';
        }
        */
        
    }


    function getCategoryShifterContainerRef(el:HTMLDivElement|null):void {
        //console.log('el:');
        if(el !== null && categoryShifterContainerRefs.current.length === 0){
            categoryShifterContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = categoryShifterContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                categoryShifterContainerRefs.current.push(el);
            }

        }

        //console.log('cscref:', categoryShifterContainerRefs.current);      
        
    }

    function getCategoryShifterItemRef(el:HTMLDivElement|null):void {
        //console.log('el:');
        if(el !== null && categoryShifterItemRefs.current.length === 0){
            categoryShifterItemRefs.current.push(el);

        } else if(el !== null) {
            const found = categoryShifterItemRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                categoryShifterItemRefs.current.push(el);
            }

        }

        //console.log('csiref:', categoryShifterItemRefs.current);
       
        
    }

    function getItemImageContainerRef(el:HTMLDivElement|null):void {
        //console.log('el:');
        if(el !== null && itemImageContainerRefs.current.length === 0){
            itemImageContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = itemImageContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                itemImageContainerRefs.current.push(el);
            }

        }

        //console.log('iicref:', itemImageContainerRefs.current);
       
        
    }

    function getCategoryShifterOverlayParagraphRefs(el:HTMLDivElement|null):void {
        //console.log('el:');
        if(el !== null && categoryShifterOverlayParagraphRefs.current.length === 0){
            categoryShifterOverlayParagraphRefs.current.push(el);

        } else if(el !== null) {
            const found = categoryShifterOverlayParagraphRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                categoryShifterOverlayParagraphRefs.current.push(el);
            }

        }

        //console.log('csopref:', categoryShifterOverlayParagraphRefs.current);
       
        
    }


    function setCategoryShifterOverlayParagraphClass(item:Shifter):string {
        if(item.width === 304){
            return 'cs-304';
        } else {
            return 'cs-608';
        }
    }

    
    function setupCategoryShifters(categories:Category[]):JSX.Element[]{
        return categories.map((c, i)=>{
            //console.log('car:', c.height/c.width);
                    return (<div ref={getCategoryShifterContainerRef} key={i} className="category-shifter-container">
                                <div ref={getCategoryShifterItemRef} className="category-shifter-list-item">
                                    <div ref={getItemImageContainerRef} className="cs-list-item-image-container">
                                        <img src={`${c.categoryImgPath}`} alt={`category${i+1}`} className="hover-effect-image"/>
                                    </div>
                                </div>
                                {c.shifters.map((s, j)=>{
                                    //console.log('sar:', (s.width*.80) * s.height/s.width);
                                    return (<div key={j+1} ref={getCategoryShifterItemRef} className="category-shifter-list-item">
                                                <div ref={getItemImageContainerRef} className="hover-effect"
                                                        onMouseEnter={handleShifterMouseEnter} onMouseLeave={handleShifterMouseLeave} >
                                                    <img src={`${s.shifterImgPath}`} alt={s.heading} className="hover-effect-image"/>
                                                    <div className="category-shifter-overlay">
                                                        <h2 className="category-shifter-overlay-heading">{s.heading.toUpperCase()}</h2>
                                                        <p ref={getCategoryShifterOverlayParagraphRefs} className={`category-shifter-overlay-paragraph ${setCategoryShifterOverlayParagraphClass(s)}`}>
                                                            {s.msg}&reg;
                                                            <button type="button" className="category-shifter-overlay-btn">
                                                                <Link to={'/shop'} className="category-shifter-overlay-btn-link"
                                                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/shop/overview', navigate)}>Shop Now</Link>
                                                            </button>
                                                            
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>);

                                })}
                            </div>);
                });
    }



    return (
        <div className="cs-container-wrapper">
            {setupCategoryShifters(cs)}
        </div>
    );

}

export default CategoryShifter2;