import React, { useState, useEffect, useRef } from 'react';
import HomePageLayout from './homePageLayout';
import HomePageLayoutDisplay from './HomePageLayoutDisplay';
//import { Link, navigate } from "@gatsbyjs/reach-router";
import { Link, useNavigate } from 'react-router-dom';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import {addHomePageLayoutItems} from './helper-home-page-layout';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import LoaderAnimation from './LoaderAnimation';
import ShopProductMiniCart from './ShopProductMiniCart';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import GenericErrorMessage from './GenericErrorMessage';
import loadNavLinkHelper from './loadNavLinkHelper';
import './HomePage.css';


interface Props {
    //path?:string;
    /*children:React.ReactElement;*/
    /*'*'?:string;*/
}

const HomePage:React.FunctionComponent<Props> = (props: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shoppingCartRedux = useAppSelector(selectShoppingCart);

    const [homePageLayouts, setHomePageLayouts] = useState<HomePageLayout[]>([]);
    //search properties
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No home page layouts have been added.');

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(50);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<HomePageLayout[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const homePageWrapperRefs = useRef<HTMLDivElement[]>([]);
    const [showMiniCart, setShowMiniCart] = useState(false);
    const miniCartHasProductsRef = useRef<boolean>(false);



    useEffect(()=>{
        //console.log('scr4:');
        if(window.innerWidth >= 768 && shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;
            setShowMiniCart(true);

        } else if(shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;

        } else {
            //console.log('scr4:');
            miniCartHasProductsRef.current = false;
            setShowMiniCart(false);

            if(homePageWrapperRefs.current.length > 0){
                //console.log('ran10');
                homePageWrapperRefs.current[0].style.width = '';
                homePageWrapperRefs.current[0].style.margin = '';
            }

        }

    },[shoppingCartRedux]);





    function getHomePageWrapperRef(el:HTMLDivElement|null):void {
        if(el !== null){
            homePageWrapperRefs.current.length = 0;
        }
    
        if(el !== null && homePageWrapperRefs.current.length === 0){
            homePageWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = homePageWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                homePageWrapperRefs.current.push(el);
            }

        }

        //console.log('plwrs:', homePageWrapperRefs.current);


        if(showMiniCart && window.innerWidth <= 1575){
            //console.log('smc:');
            homePageWrapperRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            homePageWrapperRefs.current[0].style.margin = '0 auto 0 0';

        } else {
            homePageWrapperRefs.current[0].style.width = '100%';
            homePageWrapperRefs.current[0].style.margin = '0 auto';
        }

        /*css styles for bread crumbs
        if(showMiniCart && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

    }

    function getHomePageErrorMsgWrapperRef(el:HTMLDivElement|null):void {        
        if(el !== null){
            homePageWrapperRefs.current.length = 0;
        }
    
        if(el !== null && homePageWrapperRefs.current.length === 0){
            homePageWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = homePageWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                homePageWrapperRefs.current.push(el);
            }

        }

        //console.log('plwrs:', homePageWrapperRefs.current);


        if(showMiniCart && window.innerWidth <= 1575){
            //console.log('smc:');
            homePageWrapperRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            homePageWrapperRefs.current[0].style.margin = '0 auto 0 0';

        } else {
            homePageWrapperRefs.current[0].style.width = '100%';
            homePageWrapperRefs.current[0].style.margin = '0 auto';
        }

        /*css styles for bread crumbs
        if(showMiniCart && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

    }

    useEffect(()=>{
        window.addEventListener('resize', shopProductResizeHandler);

        window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);


        return cleanupShopProductResizeHandler;
    },[]);

    function cleanupShopProductResizeHandler():void {
        window.removeEventListener('resize', shopProductResizeHandler);

        window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        shopProductResizeHandler(new Event('test'));
    }

    function shopProductResizeHandler(e:Event):void {
        //console.log('scr4:', miniCartHasProductsRef.current);
        if(window.innerWidth >= 768 && window.innerWidth <= 1600 && miniCartHasProductsRef.current){
            homePageWrapperRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            const ma = 0;//window.innerWidth > 1535 ? window.innerWidth - 1535 : 0;
            //console.log('ma:', ma);
            homePageWrapperRefs.current[0].style.margin = `0 auto 0 ${ma}px`;
            setShowMiniCart(true);

        } 
        
        if(window.innerWidth > 1600 && miniCartHasProductsRef.current){
            homePageWrapperRefs.current[0].style.width = '100%';
            homePageWrapperRefs.current[0].style.margin = '0 auto';
            //setShowMiniCart(true);

        }

        if(window.innerWidth >= 768 && !miniCartHasProductsRef.current){
            homePageWrapperRefs.current[0].style.width = '100%';
            homePageWrapperRefs.current[0].style.margin = '0 auto';
        }

        if(window.innerWidth < 768){
            homePageWrapperRefs.current[0].style.width = '100%';
            homePageWrapperRefs.current[0].style.margin = '0 auto';
            setShowMiniCart(false);
        }

        /*css styles for breadcrumbs
        if(miniCartHasProductsRef.current && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

    }



    useEffect(()=>{

        setIsLoading(true);
        //get home page layouts from database home_page_layouts
        //need to create the route and table for home_page_layouts
        //window.scrollTo(0,0);
        getAllHomePageLayouts();

        dispatch(addProductKeywordSearch!({
            keywordSearch:'',
            priceRangeSearch:'relatedProducts',
            storeDepartmentSearch:'none',
            departmentCategorySearch:'none',
            sentFromKeywordList:false,
            clearKeywordSearch:true
                }));

    },[]);


    function getAllHomePageLayouts(){
        fetch('https://server.kando-proto-3.com/get-all-home-page-layouts/notReloaded', {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then(res=>res.json())
        .then(({hpls}:{hpls:HomePageLayout[]})=>{
            //console.log('res:', hpls);
            if(hpls.length > 0){
                const layouts = hpls.map((hpl)=>{
                                const r = new HomePageLayout({
                                                                ...hpl,
                                                                items:addHomePageLayoutItems(hpl)
                                                            });
                                return r;
                            });

                            //hpWrapperRef.current!.style.minHeight = `${layouts.length * 450}px`;
                //console.log('layouts:', layouts.length);
                setShowNoSearchResultsFound(false);
                setHomePageLayouts(layouts);
                getAnyItemList(listChangerBtnPosition, layouts, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, layouts);
                setIsLoading(false);

            } else {
                //console.log('test');
                //need to show message that no home page layouts have been added.
                setNoSearchResultsMessage(`No home page layouts have been added`);
                setShowNoSearchResultsFound(true);
                setHomePageLayouts([]);
                setIsLoading(false);

            }

        })
        .catch(err=>{
            //console.log('fetch home page layouts error:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);
        });

    }

    function setupHomePageLayoutTypeClasses(hpl:HomePageLayout):string {
        switch(hpl.homePageLayoutType){
            case 'card-box':
            case 'slider3':
            case 'slider4':
            case 'carousel':
                return 'hp-no-mb';

            case 'banner-box':
                if(hpl.homePageLayoutOrder === 1 ){
                    return 'hp-mb-first';

                } else {
                    return 'hp-mb';
                }

            default:
                throw new Error('no home page layout type found.');
        }
    }


    function hideHomePage():void {
        setIsLoading(true);
    }


    function setupHomePageLayoutContainers(itemsList:object[]):JSX.Element{
        //console.log('itemsList:', itemsList);
        return <div className="hp-container">
                    {(itemsList as HomePageLayout[]).map((pl)=>{
                        //console.log('pl:', pl);
                    return (<div key={pl.homePageLayoutId} className={`hp-item-container ${setupHomePageLayoutTypeClasses(pl)}`}>
                                <HomePageLayoutDisplay homePageLayout={pl} showEditHomePageLayout={false}
                                    productionMode={true} hpCallback={hideHomePage} />
                            </div>);
                    })}
                </div>
    }

    function showItemsPerList(itemsList:object[]):void {
        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as HomePageLayout[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);       

    }

    function displayListSorter(itemsPerList:number, hpls:HomePageLayout[]):void {
        if(hpls.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function addLayoutHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        loadNavLinkHelper(e, '/admin/home-page-layouts', navigate);
    }

    /*previous version for no results found msg
    /*<GenericInfoMessage infoMessage={noSearchResultsMessage}
    customStyle={'hplb-info-message'} showCloseBtn={false}/>*/

    /*
    if(homePageLayouts.length === 0 && !isLoading){
        console.log('test');
        return <div className="hp-layout-wrapper">
                    <div ref={getHomePageErrorMsgWrapperRef} className="hp-wrapper">
                        {
                            showNoSearchResultsFound
                            ?
                            <GenericInfoMessage infoMessage={noSearchResultsMessage}
                            customStyle={'hplb-info-message'} showCloseBtn={false}/>
                            : null
                        }
                    </div>
                    {
                        showMiniCart
                        ?
                        <ShopProductMiniCart/>
                        : null
                    }
                </div>

    } else if(homePageLayouts.length > 0 && !isLoading) {
        console.log('test');*/
        return <div className="hp-layout-wrapper">
                    <ProductSearch/>
                    <MainNavbar2/>
                    <MenuListMobileNav/>
                    
                    <div ref={getHomePageWrapperRef} className="hp-wrapper">
                        {/*<h3 className="home-page-layout-builder-heading">Home page layouts</h3>*/}
                        {
                            (showNoSearchResultsFound && !isLoading && (homePageLayouts.length > 0 || homePageLayouts.length === 0))
                            ? 
                            <div className="hp-no-results-msg-wrapper">
                                <h1 className="hp-heading">Home Page</h1>
                                <div className="ahpl-no-img-msg-wrapper">
                                    <div className="hplib-empty-item-slider-msg-container">
                                        <h2 className="hplib-empty-item-slider-msg-heading">No layouts have been added.</h2>
                                        <p className="hplib-empty-item-slider-msg">Add a layout to get started.</p>
                                    </div>
                                    <div className="hplib-empty-item-slider-img-container">
                                        <img className="hplib-empty-item-slider-img" src="https://server.kando-proto-3.com/poster-icon.svg"/>
                                    </div>
                                    <div className="ahpl-add-image-btn-container">
                                        <button type="button" className="ahpl-add-image-btn"
                                            onClick={addLayoutHandler}>Add Layout</button>
                                    </div>
                                </div>
                            </div>
                            
                            :
                            null
                        }

                        {
                            (!isLoading && !showNoSearchResultsFound && homePageLayouts.length > 0)
                            ?
                            <ListViewer itemsList={itemsList} items={homePageLayouts} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupHomePageLayoutContainers} />
                            :
                            null
                        }

                        {
                            showErrorMessage
                            ?
                            <div className="hp-generic-error-msg-container">
                                <GenericErrorMessage/>
                            </div>
                            :
                            null
                        }

                        {
                            isLoading
                            ?
                            <div className="hp-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null
                        }

                    </div>
                    {
                        showMiniCart
                        ?
                        <ShopProductMiniCart/>
                        : null
                    }
                </div>
} /*else {
        return <div className="hp-loader-animation-container">
                    <LoaderAnimation/>
                </div>
    }
    
}*/

export default HomePage;