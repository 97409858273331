import DepartmentCategory from './departmentCategory';
import SearchFilter from './searchFilter';

export default class StoreDepartment  {
    departmentId:number;
    departmentName:string;
    departmentLinkName:string;
    departmentImage:string;
    departmentCategories:DepartmentCategory[];
    showDepartmentCategoryShortcut:boolean;
    isSpecialDepartment:boolean;
    highlightText:boolean;
    searchFilters:SearchFilter[];
    departmentDateAdded:Date;

    constructor(options:{
        departmentId?:number,
        departmentName?:string,
        departmentLinkName?:string,
        departmentImage?:string,
        departmentCategories?:DepartmentCategory[],
        showDepartmentCategoryShortcut?:boolean,
        isSpecialDepartment?:boolean,
        highlightText?:boolean,
        searchFilters?:SearchFilter[],
        departmentDateAdded?:Date;
    }={}){
        this.departmentId = options.departmentId || 0;
        this.departmentName = options.departmentName || '';
        this.departmentLinkName = options.departmentLinkName || '';
        this.departmentImage = options.departmentImage || '';
        this.departmentCategories = options.departmentCategories || [];
        this.showDepartmentCategoryShortcut = options.showDepartmentCategoryShortcut || false;
        this.isSpecialDepartment = options.isSpecialDepartment || false;
        this.highlightText = options.highlightText || false;
        this.searchFilters = options.searchFilters || [];
        this.departmentDateAdded = options.departmentDateAdded || new Date();
    }
}