import React, {useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProductCategory from './productCategory';

interface Props {
    productCategory:ProductCategory;
}

const HighlightProductCategoryText:React.FunctionComponent<Props> = (props:Props)=>{
    const {productCategory} = props;
    //console.log('productCategory highlighted:', productCategory.productCategoryName);
    //console.log('highlightText:', productCategory.highlightText);

    if(productCategory.highlightText){
        return <div className="highlight-pc-text">{productCategory.productCategoryName}</div>;

    } else {
        return <div>{productCategory.productCategoryName}</div>;

    }

}

export default HighlightProductCategoryText;