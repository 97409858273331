import React from 'react';
import './LoaderAnimation.css';

interface Props {}


const LoaderAnimation:React.FunctionComponent<Props> = (props:Props)=>{

    //console.log('t3:');

    return <div className="loader-animation-wrapper">
                <div className="loader-animation-container">
                    <div className="loader-animation-spin-text">S
                    <div className="loader-animation-spin-right">
                        <div className="loader-animation-spin-left"></div>
                    </div>
                    </div>   
                </div>
            </div>
}

export default LoaderAnimation;