import React, { useState, useEffect, useRef } from 'react';
import Product from './product';
import ShopProductDetail from './ShopProductDetail';
import {Link, navigate} from '@gatsbyjs/reach-router';
import Card from './card';
import CardDisplay from './CardDisplay';
import HomePageLayoutItem from './homePageLayoutItem';
import './CardBox.css';

interface Props {
    //path?:string;
    heading:string;
    caption:string;
    cards:Card[];
    layoutAction:string;
    updateExistingHomePageLayoutItems?:(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string)=>void;
    //setupItemsCallback:(items:Card[])=>JSX.Element[];
}

const CardBox:React.FunctionComponent<Props> = (props: Props) => {
    const {updateExistingHomePageLayoutItems} = props;
    const [cards, setCards] = useState<Card[]>(props.cards);
    const [heading, setHeading] = useState(props.heading);
    const [caption, setCaption] = useState(props.caption);
    const [layoutAction, setLayoutAction] = useState(props.layoutAction);
    const [cardChecked, setCardChecked] = useState(false);

    //console.log('cards:', cards, 'props.cards:', props.cards === cards);

    useEffect(()=>{
        if(props.cards !== cards){
            //console.log('card:');
            setCards(props.cards);
        }
    },[props.cards]);
    

    function setupCardbox(layoutAction:string, card:Card):JSX.Element {
        return <CardDisplay layoutAction={layoutAction} card={card} updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}/>
    }


    function setupItemsCallback(cards:Card[]):JSX.Element[] {
        return cards.map((card, i)=>{
                            return <div className="card-box-item" key={i}>
                                        {setupCardbox(layoutAction, card)}
                                    </div>
                        });
    }
    

    return <div className="card-box-wrapper">
                <div className="card-box-heading-container">
                    {heading !== 'none' ? <h1 className="card-box-heading">{heading}</h1> : null}
                    {caption !== 'none' ? <div className="card-box-caption">{caption}</div> : null}
                </div>
                <div className="card-box-container">
                    {setupItemsCallback(cards)}
                </div>
            </div>;

}

export default CardBox;