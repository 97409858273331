import React from 'react';

function helperHighlightDepartment(
                                e:React.MouseEvent,
                                departmentName:string
                            ):Promise<Response>{
                            
            //console.log('test worked');
            //e.preventDefault();
            e.stopPropagation();
            //console.log('made it');

            const params = JSON.stringify({departmentName});

            //previous version
            //return fetch(`http://localhost:9500/highlight-department-name-text/${departmentName}`,{
            return fetch(`https://server.kando-proto-3.com/highlight-department-name-text-mariadb`,{
                        method:'PUT',
                        headers:{
                            'Content-Type':'application/json',
                            'Accept':'application/json'
                        },
                        body:params
                    });
                               


}

export default helperHighlightDepartment;