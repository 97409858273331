import React from 'react';
import {SearchFilterArea} from './relatedProduct';

export  function resetSearchFilterAreaHighlight():Promise<Response>{
                return fetch(`https://server.kando-proto-3.com/reset-all-search-filter-area-highlight`,{
                        method:'PUT',
                        headers:{
                        'Accept':'application/json'
                        }
                });
}

function helperHighlightSearchFilterArea(
                e:React.MouseEvent,
                searchFilterAreaSelected:SearchFilterArea,
                prevSearchFilterAreaSelected:SearchFilterArea
            ):Promise<Response>{
    
        //console.log('sfas:', searchFilterAreaSelected.searchFilterAreaName, 'psfas:', prevSearchFilterAreaSelected.searchFilterAreaName, 'equals:', searchFilterAreaSelected.searchFilterAreaName === prevSearchFilterAreaSelected.searchFilterAreaName);
        //if(searchFilterAreaSelected.searchFilterAreaName !== prevSearchFilterAreaSelected.searchFilterAreaName){
                const sfaId:number = searchFilterAreaSelected.searchFilterAreaId;
                const sfaType:string = searchFilterAreaSelected.searchFilterAreaType;
                const sfaName:string = searchFilterAreaSelected.searchFilterAreaName;
        
        
        
                        //console.log('test worked');
                        //e.preventDefault();
                        e.stopPropagation();
                        //console.log('made it');
                        //original version
                        //return fetch(`http://localhost:9500/highlight-search-filter-area-text/${sfaId}/${sfaType}/${sfaName}`,{
                        return fetch(`https://server.kando-proto-3.com/highlight-search-filter-area-text/${JSON.stringify(prevSearchFilterAreaSelected)}/${JSON.stringify(searchFilterAreaSelected)}`,{
                                method:'PUT',
                                headers:{
                                'Accept':'application/json'
                                }
                        });
        //}

   
}

export default helperHighlightSearchFilterArea;