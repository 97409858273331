import React, {useState, useEffect, useRef} from 'react';
import HomePageLayoutSelector, {ItemLayoutOption} from './HomePageLayoutSelector';
import HomePageLayoutTypeSelector, {ItemTypeOption} from './HomePageLayoutTypeSelector';
import HomePageLayout from './homePageLayout';
import HomePageLayoutItem from './homePageLayoutItem';
import {Link, navigate} from '@gatsbyjs/reach-router';
import ShopProductDetail from './ShopProductDetail';
import Product from './product';
import CardBox from './CardBox';
import Card from './card';
import ImageSlider3 from './ImageSlider3';
import SliderType from './sliderType';
import BannerBox from './BannerBox';
import BannerBox2 from './BannerBox2';
import Banner from './banner';
import MarketingBanner from './marketingBanner';
import MarketingPicture from './marketingPicture';
import HomePageLayoutItemBuilder from './HomePageLayoutItemBuilder';
import AddHomePageLayout from './AddHomePageLayout';
import {getHeading, convertHeadingType} from './helper-home-page-layout';
import {getNumberOfItemsAvailable, getNumberOfItemsRequired, 
    setupAddBtnDisableAttribute, setupAddBtnDisableClass, filterNonExistingHomePageLayoutItems} from './helper-home-page-layout-item';
import GenericInfoMessage from './GenericInfoMessage';
import SliderDisplay from './SliderDisplay';
import ProductDisplay from './ProductDisplay';
import LoaderAnimation from './LoaderAnimation';
import './HomePageLayoutDisplay.css';


interface Props{
    homePageLayout:HomePageLayout|null;
    showEditHomePageLayout:boolean;
    handleCloseHomePageLayout?:(homePageLayout:HomePageLayout|null)=>void;
    handleSaveHomePageLayout?:(hpl:HomePageLayout)=>void;
    handleUpdateHomePageLayout?:(hpl:HomePageLayout)=>void;
    handleDeleteHomePageLayout?:(hpl:HomePageLayout)=>void;
    handleUpdateHomePageLayoutOrder?:(hpl:HomePageLayout)=>void;
    //need to attach this to selector to get order to update in home page layout builder
    //need to finish attaching this function
    homePageLayoutOrderOptions?:ItemLayoutOption[];
    nextHomePageLayoutId?:number;
    reorderPageLayoutsCallback?:()=>void;
    handleEditHomePageLayout?:(hpl:HomePageLayout)=>void;
    productionMode?:boolean;
    hpCallback?:()=>void;
}

//const oos:ItemOption[] = [{value:1},{value:2}, {value:3}, {value:4}, {value:5}];
const tos:ItemTypeOption[] = [{value:'carousel'},{value:'card-box'}, {value:'slider3'}, {value:'slider4'}, {value:'banner-box'}];

const HomePageLayoutDisplay:React.FunctionComponent<Props> = (props:Props)=>{
    const {reorderPageLayoutsCallback, handleCloseHomePageLayout, handleSaveHomePageLayout, 
            handleUpdateHomePageLayout, handleDeleteHomePageLayout, handleEditHomePageLayout,
            handleUpdateHomePageLayoutOrder, hpCallback} = props;
    const [orderOptions, setOrderOptions] = useState<ItemLayoutOption[]>(setupHomePageLayoutOrderOptions(props.homePageLayout!, props.homePageLayoutOrderOptions));
    const [typeOptions, setTypeOptions] = useState<ItemTypeOption[]>(tos);
    const [homePageLayout, setHomePageLayout] = useState<HomePageLayout|null>(props.homePageLayout!);
    const [showEditHomePageLayout, setShowEditHomePageLayout] = useState(props.showEditHomePageLayout);
    const [homePageLayoutTypeSelected, setHomePageLayoutTypeSelected] = useState(setupHomePageLayoutType(homePageLayout));
    const [homePageLayoutOrderSelected, setHomePageLayoutOrderSelected] = useState<number>(setupHomePageLayoutOrderSelected(homePageLayout!, props.homePageLayoutOrderOptions));
    const [showHomePageLayoutItemList, setShowHomePageLayoutItemList] = useState(false);
    const [selectedHomePageLayoutItems, setSelectedHomePageLayoutItems] = useState<HomePageLayoutItem[]>([]);
    const [selectedHeading, setSelectedHeading] = useState(setupHomePageLayoutHeading(homePageLayout));
    const [selectedCaption, setSelectedCaption] = useState(setupHomePageLayoutCaption(homePageLayout));
    const [selectedHeadingPositionDesktop, setSelectedHeadingPositionDesktop] = useState(setupHeadingPositionDesktop(homePageLayout));
    const [selectedHeadingPositionMobile, setSelectedHeadingPositionMobile] = useState(setupHeadingPositionMobile(homePageLayout));
    const [selectedHeadingSizeDesktop, setSelectedHeadingSizeDesktop] = useState(setupHeadingSizeDesktop(homePageLayout));
    const [selectedHeadingSizeMobile, setSelectedHeadingSizeMobile] = useState(setupHeadingSizeMobile(homePageLayout));
    const [selectedHeadingTypeDesktop, setSelectedHeadingTypeDesktop] = useState(setupHeadingTypeDesktop(homePageLayout));
    const [selectedHeadingTypeMobile, setSelectedHeadingTypeMobile] = useState(setupHeadingTypeMobile(homePageLayout));
    const [fullLengthBannerCheckboxChecked, setFullLengthBannerCheckboxChecked] = useState(setupFullLength(homePageLayout));
    const [selectedCaptionColor, setSelectedCaptionColor] = useState(setupCaptionColor(homePageLayout));
    const [selectedHeadingColor, setSelectedHeadingColor] = useState(setupHeadingColor(homePageLayout));
    const [selectedButtonBackgroundColor, setSelectedButtonBackgroundColor] = useState(setupButtonBackgroundColor(homePageLayout));
    const [selectedButtonColor, setSelectedButtonColor] = useState(setupButtonColor(homePageLayout));

    
    const [itemsBtnDisabled, setItemsBtnDisabled] = useState(false);
    const [nextHomePageLayoutId, setNextHomePageLayoutId] = useState(props.nextHomePageLayoutId);
    //const [previousHomePageLayout, setPreviousHomePageLayout] = useState<HomePageLayout|null>(props.homePageLayout);
    const [existingHomePageLayoutItems, setExistingHomePageLayoutItems] = useState<HomePageLayoutItem[]>(setupExistingHomePageLayoutItems(homePageLayout))
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [itemQtyOverLimitMsg, setItemQtyOverLimitMsg] = useState('Exceeed the number of items allowed.');
    const [showItemQtyOverLimitMsg, setShowItemQtyOverLimitMsg] = useState(false);
    const [homePageLayoutTypeMsg, setHomePageLayoutTypeMsg] = useState('You are unable to switch to this layout type because it requiress a different image setup.');
    const [showHomePageLayoutTypeMsg, setShowHomePageLayoutTypeMsg] = useState(false);

    const [productionMode, setProductionMode] = useState(props.productionMode);
    const [isLoading, setIsLoading] = useState(false);

    const editHomePageLayoutContainerRef = useRef<HTMLDivElement>(null);
        
    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);


    useEffect(()=>{
        if(props.nextHomePageLayoutId !== nextHomePageLayoutId){
            setNextHomePageLayoutId(props.nextHomePageLayoutId);
        }
    },[props.nextHomePageLayoutId]);


    function setupSaveBtnDisabled(showEditHomePageLayout:boolean):boolean{
        if(showEditHomePageLayout){
            return false;

        } else {
            return true;
        }

    }

    function setupHomePageLayoutOrderOptions(hpl:HomePageLayout, hplos?:ItemLayoutOption[]):ItemLayoutOption[] {
        if(typeof hplos === 'undefined'){
            return [];
        } else {
            return hplos;
        }
    }

    function setupHomePageLayoutOrderSelected(hpl:HomePageLayout, hplos?:ItemLayoutOption[]):number {
        if(typeof hplos === 'undefined'){
            return 0;
        } else {
            return hplos.filter((hplo)=>{
                        return (hplo.id === hpl.homePageLayoutId);
                    })[0].order!;
        }
    }

    function setupHomePageLayoutType(hpl:HomePageLayout|null):string {
        if(hpl === null){
            return 'banner-box';

        } else {
            return hpl.homePageLayoutType;
        }
    }

    function setupExistingHomePageLayoutItems(hpl:HomePageLayout|null):HomePageLayoutItem[] {
        //console.log('hpl:', hpl);
        if(hpl === null){
            return [];

        } else {
            return [...hpl.items];
        }
    }

    function setupHomePageLayoutHeading(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.heading === 'none'){
            return '';

        } else {
            return hpl.heading;
        }
    }

    function setupHomePageLayoutCaption(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.caption === 'none'){
            return '';

        } else {
            return hpl.caption;
        }
    }

    function setupHeadingPositionDesktop(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return 'top-center';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            //console.log('mb:');
            return mb.headingPositionDesktop;
        }
    }

    function setupHeadingPositionMobile(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return 'top-center';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.headingPositionMobile;
        }
    }

    function setupHeadingSizeDesktop(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return 'regular';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.headingSizeDesktop;
        }
    }

    function setupHeadingSizeMobile(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return 'regular';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.headingSizeMobile;
        }
    }
    
    function setupHeadingTypeDesktop(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return 'banner-box';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.headingTypeDesktop;
        }
    }

    function setupHeadingTypeMobile(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return 'banner-box';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.headingTypeMobile;
        }
    }

    function setupFullLength(hpl:HomePageLayout|null):boolean {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return false;

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.fullLength;
        }
    }

    function setupCaptionColor(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return '#414142';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.captionColor;
        }
    }

    function setupHeadingColor(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return '#414142';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.headingColor;
        }
    }

    function setupButtonBackgroundColor(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return '#007DB3';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.buttonBackgroundColor;
        }
    }
    
    function setupButtonColor(hpl:HomePageLayout|null):string {
        if(hpl === null || hpl.homePageLayoutType === 'card-box' || hpl.homePageLayoutType === 'carousel'
            || hpl.homePageLayoutType === 'slider3' || hpl.homePageLayoutType === 'slider4'){
            return '#FFFFFF';

        } else {
            const mb = hpl.items[0] as MarketingBanner;
            return mb.buttonColor;
        }
    }



    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }

    /*
    function setupCardsCallback(items:object[]):JSX.Element[] {
        return (items as Card[]).map((item, i)=>{
                        return <div key={i} className="slider-image">
                                    //<Link to="/">
                                    <div className="hpld-slider-image-container">
                                        <img className="hpld-slider-image" src={`http://localhost:9500/${item.desktopSource}`}/>
                                        {item.heading !== 'none' ? <h3>{item.heading}</h3> : null}
                                        {item.caption ! == 'none' ? <div>{item.caption}</div> : null}
                                    </div>
                                    //</Link>
                                </div>
                    });
    }

    function setupProductsCallback(dps:object[]):JSX.Element[] {
        console.log('dpscallback');
        return (dps as Product[]).map((dp, i)=>{
                    return <ShopProductDetail key={i} product={dp} getImageRef={getImageRef}/>
                });
    }
    */

    function setupSlider3Callback(items:object[]):JSX.Element[] {
        return (items as MarketingPicture[]).map((item, i)=>{
                        return <SliderDisplay key={i} item={item} type={'slider3'} layoutAction={'display'}
                                    updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}/>
                    });
    }

    function setupSlider4Callback(items:object[]):JSX.Element[] {
        return (items as MarketingPicture[]).map((item, i)=>{
                        return <SliderDisplay key={i} item={item} type={'slider4'} layoutAction={'display'}
                                    updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}/>
                    });
    }

    function setupProductsCallback(dps:object[]):JSX.Element[] {
        //console.log('dpscallback');
        return (dps as Product[]).map((dp, i)=>{
            //console.log('dp:', dp);
            return <ProductDisplay key={i} product={dp} layoutAction={'display'} getImageRef={getImageRef}
                        updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems} hpCallback={hpCallback}/>
        });

    }



    function setupDisplay(hpl:HomePageLayout,
                            homePageLayoutTypeSelected:string,
                            layoutAction:string,
                            addCarouselOverlay:boolean
                            ):JSX.Element {
                //console.log('result:', homePageLayoutTypeSelected, hpl, addCarouselOverlay);
        switch(homePageLayoutTypeSelected){
            case 'slider3':
                //finish mapping hptypes to slider types
                const st3 = new SliderType({...hpl, type:'slider'});
                return <ImageSlider3 sliderType={st3} items={hpl.items} setupItemsCallback={setupSlider3Callback} optionalHeader={true}/>
            case 'slider4':
                const st4 = new SliderType({...hpl, type:'slider'});
                return <ImageSlider3 sliderType={st4} items={hpl.items} setupItemsCallback={setupSlider4Callback} optionalHeader={true}/>
            case 'carousel':
                const stc = new SliderType({...hpl, type:'carousel'});
                if(addCarouselOverlay){
                    return <div className="hpld-carousel-wrapper">
                                <ImageSlider3 sliderType={stc} items={hpl.items} setupItemsCallback={setupProductsCallback} optionalHeader={true}/>
                                <div className="hpld-carousel-btn-overlay"></div>
                            </div>

                } else {
                    return <ImageSlider3 sliderType={stc} items={hpl.items} setupItemsCallback={setupProductsCallback} optionalHeader={true}/>;
                }

            case 'card-box':
                const cbh = hpl.heading;
                const cbc = hpl.caption;
                const cards = (hpl.items as MarketingPicture[]).map((item)=>{
                                    return new Card({...item});
                                });
                return <CardBox heading={cbh} caption={cbc} layoutAction={layoutAction} cards={cards}/>
            case 'banner-box':
                const mb = hpl.items[0] as MarketingBanner;
                //console.log('mb:', mb);
                //console.log('heading:', hpl.heading, 'caption:', hpl.caption);
                const bbh = hpl.heading;
                const bbc = hpl.caption;
                const bbhpd = mb.headingPositionDesktop;
                const bbhpm = mb.headingPositionMobile;
                const bbhsd = mb.headingSizeDesktop;
                const bbhsm = mb.headingSizeMobile;
                const bbhtd = mb.headingTypeDesktop;
                const bbhtm = mb.headingTypeMobile;
                const bbfl = mb.fullLength;
                const bbhc = mb.headingColor;
                const bbcc = mb.captionColor;
                const bbbbc = mb.buttonBackgroundColor;
                const bbbc = mb.buttonColor;
                const bbpbd = mb.paddingBottomDesktop;
                const bbpbm = mb.paddingBottomMobile;
                //let b = (hpl.items as MarketingBanner[])[0];
                //console.log('bv:', bannerView);
                //previous version used productionMode ? 'production' : 'desktop';
                const bannerView = 'production';//productionMode ? 'production' : 'desktop';
                //previous version
                //const {heading1, heading2} = getHeading(mb, bbhtd!, bbhtm!, bannerView)
                const {heading1, heading2} = (window.innerWidth >= 768) ? getHeading(mb, bbhtd!, bbhtm!, 'desktop') : getHeading(mb, bbhtd!, bbhtm!, 'mobile');
                //console.log('h1:', heading1, 'h2:', heading2);
                const banner = new Banner({
                                            heading1:heading1,
                                            heading2:heading2,
                                            caption:mb.caption,
                                            desktopSource:mb.desktopSource,
                                            mobileSource:mb.mobileSource,
                                            headingPositionDesktop:bbhpd,
                                            headingPositionMobile:bbhpm,
                                            headingSizeDesktop:bbhsd,
                                            headingSizeMobile:bbhsm,
                                            headingTypeDesktop:convertHeadingType(bbhtd!),
                                            headingTypeMobile:convertHeadingType(bbhtm!),
                                            fullLength:bbfl,
                                            headingColor:bbhc,
                                            captionColor:bbcc,
                                            buttonBackgroundColor:bbbbc,
                                            buttonColor:bbbc,
                                            paddingBottomDesktop:bbpbd,
                                            paddingBottomMobile:bbpbm
                                            });
                
                if(productionMode){
                    return <BannerBox2 banner={banner} layoutAction={layoutAction} editBanner={true} bannerView={bannerView}/>

                } else {
                    return <div className="hpld-item-no-production-banner-options">
                                <BannerBox2 banner={banner} layoutAction={layoutAction} editBanner={true} bannerView={bannerView}/>
                            </div>
                }
                

                

            default:
                throw new Error('no page layout type found.');
        }
    }

    function setupEditDisplay(hpl:HomePageLayout,
                            homePageLayoutTypeSelected:string,
                            layoutAction:string
                            ):JSX.Element {
                                //console.log('test:', existingHomePageLayoutItems, selectedHomePageLayoutItems);


        switch(homePageLayoutTypeSelected){
            case 'slider3':
            case 'slider4':
            case 'carousel':
            case 'card-box':
                return <AddHomePageLayout heading={selectedHeading} caption={selectedCaption} 
                            showBannerBoxFields={false} homePageLayoutType={homePageLayoutTypeSelected}
                            homePageLayoutItems={selectedHomePageLayoutItems}
                            existingHomePageLayoutItems={existingHomePageLayoutItems}
                            layoutAction={layoutAction}
                            updateSelectedHeading={updateSelectedHeading}
                            updateSelectedCaption={updateSelectedCaption}
                            updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}
                            setShowHomePageLayoutItemList={setShowHomePageLayoutItemList} />

            case 'banner-box':
                return <AddHomePageLayout headingPositionDesktop={selectedHeadingPositionDesktop} heading={selectedHeading} 
                            caption={selectedCaption} headingPositionMobile={selectedHeadingPositionMobile}
                            showBannerBoxFields={true} homePageLayoutType={homePageLayoutTypeSelected}
                            homePageLayoutItems={selectedHomePageLayoutItems}
                            existingHomePageLayoutItems={existingHomePageLayoutItems}
                            layoutAction={layoutAction}
                            headingSizeDesktop={selectedHeadingSizeDesktop}
                            headingSizeMobile={selectedHeadingSizeMobile}
                            headingTypeDesktop={selectedHeadingTypeDesktop}
                            headingTypeMobile={selectedHeadingTypeMobile}
                            fullLengthBannerCheckboxChecked={fullLengthBannerCheckboxChecked}
                            headingColor={selectedHeadingColor}
                            captionColor={selectedCaptionColor}
                            buttonBackgroundColor={selectedButtonBackgroundColor}
                            buttonColor={selectedButtonColor}
                            updateSelectedHeading={updateSelectedHeading}
                            updateSelectedCaption={updateSelectedCaption}
                            updateSelectedHeadingPositionDesktop={updateSelectedHeadingPositionDesktop}
                            updateSelectedHeadingPositionMobile={updateSelectedHeadingPositionMobile}
                            updateSelectedHeadingSizeDesktop={updateSelectedHeadingSizeDesktop}
                            updateSelectedHeadingSizeMobile={updateSelectedHeadingSizeMobile}
                            updateSelectedHeadingTypeDesktop={updateSelectedHeadingTypeDesktop}
                            updateSelectedHeadingTypeMobile={updateSelectedHeadingTypeMobile}
                            updateFullLengthBannerCheckboxChecked={updateFullLengthBannerCheckboxChecked}
                            updateSelectedCaptionColor={updateSelectedCaptionColor}
                            updateSelectedHeadingColor={updateSelectedHeadingColor}
                            updateSelectedBackgroundColor={updateSelectedBackgroundColor}
                            updateSelectedButtonColor={updateSelectedButtonColor}
                            updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}
                            setShowHomePageLayoutItemList={setShowHomePageLayoutItemList}
                                />

            default:
                throw new Error('no page layout type found.');
        }
    }

    function updateSelectedHeading(heading:string):void {
        setSelectedHeading(heading);
    }

    function updateSelectedCaption(caption:string):void {
        setSelectedCaption(caption);
    }

    function updateSelectedHeadingPositionDesktop(hbPosition:string):void {
        setSelectedHeadingPositionDesktop(hbPosition);
    }

    function updateSelectedHeadingPositionMobile(hbPosition:string):void {
        setSelectedHeadingPositionMobile(hbPosition);
    }

    function updateSelectedHeadingSizeDesktop(headingSize:string):void {
        setSelectedHeadingSizeDesktop(headingSize);
    }

    function updateSelectedHeadingSizeMobile(headingSize:string):void {
        setSelectedHeadingSizeMobile(headingSize);
    }

    function updateSelectedHeadingTypeDesktop(headingType:string):void {
        setSelectedHeadingTypeDesktop(headingType);
    }

    function updateSelectedHeadingTypeMobile(headingType:string):void {
        setSelectedHeadingTypeMobile(headingType);
    }

    function updateFullLengthBannerCheckboxChecked(fullLengthBannerCheckboxChecked:boolean):void {
        setFullLengthBannerCheckboxChecked(fullLengthBannerCheckboxChecked);
    }

    function updateSelectedCaptionColor(captionColor:string):void {
        setSelectedCaptionColor(captionColor);
    }

    function updateSelectedHeadingColor(headingColor:string):void {
        setSelectedHeadingColor(headingColor);
    }

    function updateSelectedBackgroundColor(buttonBackgroundColor:string):void {
        setSelectedButtonBackgroundColor(buttonBackgroundColor);
    }

    function updateSelectedButtonColor(buttonColor:string):void {
        setSelectedButtonColor(buttonColor);
    }

    function removeExistingHomePageLayoutItem(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string):Promise<Response> {
        //create an object that stores the item and item type
        //write a fetch statement that sends the object via post method
        //to the server route remove-existing-home-page-layout-item
        //finish this route on server.js line #780
        const hpliInfo = {
            item:existingHomePageLayoutItem,
            type:homePageLayoutType
        };
        
        return fetch(`https://server.kando-proto-3.com/remove-existing-home-page-layout-item`, {
                method:'POST',
                headers:{
                    'content-type':'application/json',
                    'accept':'application/json'
                },
                body:JSON.stringify(hpliInfo)
                });

    }

    function updateExistingHomePageLayoutItems(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string):void {
        
        setShowItemQtyOverLimitMsg(false);
        setShowHomePageLayoutTypeMsg(false);
        

        if(homePageLayoutType === 'banner-box'){
            //console.log('ehli:', editHomePageLayoutContainerRef.current!.querySelector('.add-home-page-layout-image-container'));
            editHomePageLayoutContainerRef.current!.style.minHeight = `${editHomePageLayoutContainerRef.current!.getBoundingClientRect().height}px`;

        } else {
            editHomePageLayoutContainerRef.current!.style.minHeight = `${editHomePageLayoutContainerRef.current!.getBoundingClientRect().height}px`;
        }

        if(existingHomePageLayoutItems.length === 1){
            const element = editHomePageLayoutContainerRef.current!.querySelector('.add-home-page-layout-image-container') as HTMLDivElement;
            element.scrollIntoView();

            const bannerMobileViewWrapperElement = element.querySelector('.banner-mobile-view-wrapper') as HTMLDivElement;

            if(bannerMobileViewWrapperElement){
                //console.log('h7:', bannerMobileViewWrapperElement.getBoundingClientRect().height, bannerMobileViewWrapperElement.getBoundingClientRect().height / 2, (bannerMobileViewWrapperElement.getBoundingClientRect().height / 2) - 100);
                editHomePageLayoutContainerRef.current!.style.minHeight = `${(editHomePageLayoutContainerRef.current!.getBoundingClientRect().height - (bannerMobileViewWrapperElement.getBoundingClientRect().height / 2)) - 300}px`;

            } else {
                editHomePageLayoutContainerRef.current!.style.minHeight = `${editHomePageLayoutContainerRef.current!.getBoundingClientRect().height}px`;
            }

                
        }
        
        

        setIsLoading(true);
        let totalHomePageLayoutItems = [];
        let disableSaveBtn = false;
        switch(homePageLayoutType){
            case 'card-box':
            case 'slider3':
            case 'slider4':
                const emps = (existingHomePageLayoutItems as MarketingPicture[]).filter((ehpli)=>{
                                    return ehpli.marketingPictureId !== (existingHomePageLayoutItem as MarketingPicture).marketingPictureId;
                                });
                //console.log('emps:', emps, 'mp:', existingHomePageLayoutItem);
                totalHomePageLayoutItems = (emps as HomePageLayoutItem[]).concat(selectedHomePageLayoutItems);
                //console.log('total:', totalHomePageLayoutItems);

                switch(homePageLayoutType){
                    case 'card-box':
                        disableSaveBtn = totalHomePageLayoutItems.length === 2 ? false : true;
                        break;
                    case 'slider3':
                        disableSaveBtn = totalHomePageLayoutItems.length === 3 ? false : true;
                        break;
                    case 'slider4':
                        disableSaveBtn = totalHomePageLayoutItems.length === 4 ? false : true;
                        break;
                    default:
                        throw new Error('no home page layout type found.');
                }

                removeExistingHomePageLayoutItem(existingHomePageLayoutItem, homePageLayoutType)
                .then((res)=>res.json())
                .then(({status})=>{
                    //console.log('status:', status);

                    //setSaveBtnDisabled(disableSaveBtn);
                    //setItemsBtnDisabled(!disableSaveBtn);
                    setIsLoading(false);
                    setExistingHomePageLayoutItems(emps);

                })
                .catch((err)=>console.error('error removing existing home page layout item:', err.message));
                
                break;

            case 'banner-box':
                const embs = (existingHomePageLayoutItems as MarketingBanner[]).filter((ehpli)=>{
                                    return ehpli.marketingBannerId !== (existingHomePageLayoutItem as MarketingBanner).marketingBannerId;
                                });

                totalHomePageLayoutItems = (embs as HomePageLayoutItem[]).concat(selectedHomePageLayoutItems);
                disableSaveBtn = totalHomePageLayoutItems.length === 1 ? false : true;

                removeExistingHomePageLayoutItem(existingHomePageLayoutItem, homePageLayoutType)
                .then((res)=>res.json())
                .then(({status})=>{
                    //console.log('status:', status);

                    //setSaveBtnDisabled(disableSaveBtn);
                    //setItemsBtnDisabled(!disableSaveBtn);
                    setIsLoading(false);
                    setExistingHomePageLayoutItems(embs);
                })
                .catch((err)=>console.error('error removing existing home page layout item:', err.message));

                break;

            case 'carousel':
                const eps = (existingHomePageLayoutItems as Product[]).filter((ehpli)=>{
                                return ehpli.productId !== (existingHomePageLayoutItem as Product).productId;
                            });

                //console.log('eps:', eps);

                disableSaveBtn = (totalHomePageLayoutItems.length >= 7
                                          && totalHomePageLayoutItems.length <= 25) ? false : true;

                removeExistingHomePageLayoutItem(existingHomePageLayoutItem, homePageLayoutType)
                .then((res)=>res.json())
                .then(({status})=>{
                    //console.log('status:', status);

                    //setSaveBtnDisabled(disableSaveBtn);
                    //setItemsBtnDisabled(!disableSaveBtn);
                    setIsLoading(false);
                    setExistingHomePageLayoutItems(eps);
                })
                .catch((err)=>console.error('error removing existing home page layout item:', err.message));

                break;

            default:
                throw new Error('no home page layout type found.');
        }

    }


    function setupAddDisplay(homePageLayoutTypeSelected:string, layoutAction:string):JSX.Element {
        switch(homePageLayoutTypeSelected){
            case 'slider3':
            case 'slider4':
            case 'carousel':
            case 'card-box':
                return <AddHomePageLayout heading={selectedHeading} caption={selectedCaption} 
                            showBannerBoxFields={false} homePageLayoutType={homePageLayoutTypeSelected}
                            homePageLayoutItems={selectedHomePageLayoutItems}
                            existingHomePageLayoutItems={existingHomePageLayoutItems}
                            layoutAction={layoutAction}
                            updateSelectedHeading={updateSelectedHeading}
                            updateSelectedCaption={updateSelectedCaption}
                            setShowHomePageLayoutItemList={setShowHomePageLayoutItemList} />

            case 'banner-box':
                return <AddHomePageLayout headingPositionDesktop={selectedHeadingPositionDesktop} heading={selectedHeading} 
                            caption={selectedCaption} headingPositionMobile={selectedHeadingPositionMobile}
                            showBannerBoxFields={true} homePageLayoutType={homePageLayoutTypeSelected}
                            homePageLayoutItems={selectedHomePageLayoutItems}
                            existingHomePageLayoutItems={existingHomePageLayoutItems}
                            layoutAction={layoutAction}
                            headingSizeDesktop={selectedHeadingSizeDesktop}
                            headingSizeMobile={selectedHeadingSizeMobile}
                            headingTypeDesktop={selectedHeadingTypeDesktop}
                            headingTypeMobile={selectedHeadingTypeMobile}
                            fullLengthBannerCheckboxChecked={fullLengthBannerCheckboxChecked}
                            headingColor={selectedHeadingColor}
                            captionColor={selectedCaptionColor}
                            buttonBackgroundColor={selectedButtonBackgroundColor}
                            buttonColor={selectedButtonColor}
                            updateSelectedHeading={updateSelectedHeading}
                            updateSelectedCaption={updateSelectedCaption}
                            updateSelectedHeadingPositionDesktop={updateSelectedHeadingPositionDesktop}
                            updateSelectedHeadingPositionMobile={updateSelectedHeadingPositionMobile}
                            updateSelectedHeadingSizeDesktop={updateSelectedHeadingSizeDesktop}
                            updateSelectedHeadingSizeMobile={updateSelectedHeadingSizeMobile}
                            updateSelectedHeadingTypeDesktop={updateSelectedHeadingTypeDesktop}
                            updateSelectedHeadingTypeMobile={updateSelectedHeadingTypeMobile}
                            updateFullLengthBannerCheckboxChecked={updateFullLengthBannerCheckboxChecked}
                            updateSelectedCaptionColor={updateSelectedCaptionColor}
                            updateSelectedHeadingColor={updateSelectedHeadingColor}
                            updateSelectedBackgroundColor={updateSelectedBackgroundColor}
                            updateSelectedButtonColor={updateSelectedButtonColor}
                            setShowHomePageLayoutItemList={setShowHomePageLayoutItemList}
                             />

            default:
                throw new Error('no page layout type found.');
        }

    }


    function getLayoutTypeSelected(e:React.ChangeEvent, layoutAction:string):void {
        //console.log('glts:', showEditHomePageLayout);
        const optionSelected = (e.target as HTMLSelectElement).value;
        //setHomePageLayoutTypeSelected(optionSelected);
        if(homePageLayout !== null){
            const hpl = new HomePageLayout({
                                        ...homePageLayout!,
                                        homePageLayoutType:optionSelected
                                        });
            setHomePageLayout(hpl);
        }
        
        /*
        if(!showEditHomePageLayout){
            const {result} = checkItemQty(optionSelected, homePageLayoutTypeSelected);
            if(result === 'failed'){
                setHomePageLayoutTypeSelected(optionSelected);
                setShowHomePageLayoutItemList(true);   

            } else {
                //setShowItemQtyOverLimitMsg(false);
                setShowHomePageLayoutItemList(false);
                resetSelectedHomePageLayoutItems(optionSelected, homePageLayoutTypeSelected);            
                setHomePageLayoutTypeSelected(optionSelected);
            }

        }
        */

        if(layoutAction === 'add-layout'){
            setSelectedHomePageLayoutItems([]);
            setExistingHomePageLayoutItems([]);
            setShowHomePageLayoutTypeMsg(false);
            setShowItemQtyOverLimitMsg(false);
            setHomePageLayoutTypeSelected(optionSelected);

        } else {
            //if(showEditHomePageLayout){
            const {result, msg} = checkExistingItemQty(existingHomePageLayoutItems,selectedHomePageLayoutItems, optionSelected, homePageLayoutTypeSelected);
            //console.log('result:', result, 'msg:', msg);
            if(result === 'failed' && msg === 'none'){
                setHomePageLayoutTypeSelected(optionSelected);
                //setShowHomePageLayoutItemList(true); 
                setShowItemQtyOverLimitMsg(false);

            } else if(result === 'failed' && msg !== 'none'){
                //setHomePageLayoutTypeSelected(optionSelected);
                setItemQtyOverLimitMsg(msg);
                setShowHomePageLayoutTypeMsg(false);
                setShowItemQtyOverLimitMsg(true);
                //setShowHomePageLayoutItemList(true);

            }else {
                //setShowItemQtyOverLimitMsg(false);
                setShowItemQtyOverLimitMsg(false);
                setShowHomePageLayoutItemList(false);
                resetSelectedHomePageLayoutItems(optionSelected, homePageLayoutTypeSelected);            
                //setHomePageLayoutTypeSelected(optionSelected);
            }

            //}
        }    
          


        



        

    }

    function resetSelectedHomePageLayoutItems(optionLayoutTypeSelected:string, homePageLayoutType:string):void {
        //console.log('hplys:', optionLayoutTypeSelected, 'hplt:', homePageLayoutType);
        if(optionLayoutTypeSelected !== homePageLayoutType){
            
            //use this option if switching from slider3|slider4|cardbox to bannerbox 
            //use this option if switching from slider3|slider4|cardbox to carousel
            //use this option if switching from carousel to slider3|slider4|cardbox 
            //use this option if switching from bannerbox to slider3|slider4|cardbox 
            //use this option if switching from carousel to bannerbox
            //use this option if switching from bannerbox to carousel
            if(homePageLayoutType === 'banner-box' && (optionLayoutTypeSelected === 'card-box' 
                || optionLayoutTypeSelected === 'slider3' || optionLayoutTypeSelected === 'slider4'
                || optionLayoutTypeSelected === 'carousel')){
                    //console.log('hplys:', 'passed');
                if(existingHomePageLayoutItems.length > 0 || selectedHomePageLayoutItems.length > 0){
                    //console.log('hplys:', 'passed');
                    //set a new message
                    //set a new message type with a container to hold 2 buttons
                    //one button allows the user to move forward
                    //one button allows the user to cancel moving forward
                    //change the css style
                    //add a css style to the message container that makes it absolute
                    //center the container horizontally and in the same place as the other message from the top position
                    //start here and finish
                    const msg = `You are unable to switch to the ${optionLayoutTypeSelected} layout because it requires a different image setup than the ${homePageLayoutType} layout.`;
                    setHomePageLayoutTypeMsg(msg);
                    setShowHomePageLayoutTypeMsg(true);
                    
                } else {
                    setSelectedHomePageLayoutItems([]);
                    setExistingHomePageLayoutItems([]);
                    setHomePageLayoutTypeSelected(optionLayoutTypeSelected);
                }
            }

            if(homePageLayoutType === 'carousel' && (optionLayoutTypeSelected === 'card-box' 
                || optionLayoutTypeSelected === 'slider3' || optionLayoutTypeSelected === 'slider4'
                || optionLayoutTypeSelected === 'banner-box')){
                    //console.log('hplys:', 'passed');
                if(existingHomePageLayoutItems.length > 0 || selectedHomePageLayoutItems.length > 0){
                    //set a new message
                    //set a new message type with a container to hold 2 buttons
                    //one button allows the user to move forward
                    //one button allows the user to cancel moving forward
                    //change the css style
                    //add a css style to the message container that makes it absolute
                    //center the container horizontally and in the same place as the other message from the top position
                    //start here and finish
                    const msg = `You are unable to switch to the ${optionLayoutTypeSelected} layout because it requires a different image setup than the ${homePageLayoutType} layout.`;
                    setHomePageLayoutTypeMsg(msg);
                    setShowHomePageLayoutTypeMsg(true);
                    
                } else {
                    setSelectedHomePageLayoutItems([]);
                    setExistingHomePageLayoutItems([]);
                    setHomePageLayoutTypeSelected(optionLayoutTypeSelected);
                }

            }

            if((homePageLayoutType === 'card-box' || homePageLayoutType === 'slider3' || homePageLayoutType === 'slider4')
               && (optionLayoutTypeSelected === 'banner-box' || optionLayoutTypeSelected === 'carousel')){
                //console.log('hplys:', 'passed');
                if(existingHomePageLayoutItems.length > 0 || selectedHomePageLayoutItems.length > 0){
                    //set a new message
                    //set a new message type with a container to hold 2 buttons
                    //one button allows the user to move forward
                    //one button allows the user to cancel moving forward
                    //change the css style
                    //add a css style to the message container that makes it absolute
                    //center the container horizontally and in the same place as the other message from the top position
                    //start here and finish
                    const msg = `You are unable to switch to the ${optionLayoutTypeSelected} layout because it requires a different image setup than the ${homePageLayoutType} layout.`;
                    setHomePageLayoutTypeMsg(msg);
                    setShowHomePageLayoutTypeMsg(true);
                    
                } else {
                    setSelectedHomePageLayoutItems([]);
                    setExistingHomePageLayoutItems([]);
                    setHomePageLayoutTypeSelected(optionLayoutTypeSelected);
                }
                

            }

            if((homePageLayoutType === 'card-box' || homePageLayoutType === 'slider3')
                && optionLayoutTypeSelected === 'slider4'){
                //console.log('hplys:', 'passed');

                setShowHomePageLayoutTypeMsg(false);
                setHomePageLayoutTypeSelected(optionLayoutTypeSelected);
            }

            if((homePageLayoutType === 'card-box' || homePageLayoutType === 'slider4')
                && optionLayoutTypeSelected === 'slider3'){
                //console.log('hplys:', 'passed');

                setShowHomePageLayoutTypeMsg(false);
                setHomePageLayoutTypeSelected(optionLayoutTypeSelected);
            }

            if((homePageLayoutType === 'slider3' || homePageLayoutType === 'slider4')
                && optionLayoutTypeSelected === 'card-box'){
                //console.log('hplys:', 'passed');

                setShowHomePageLayoutTypeMsg(false);
                setHomePageLayoutTypeSelected(optionLayoutTypeSelected);
            }
             

        }
    }

    function checkItemQty(optionLayoutTypeSelected:string, homePageLayoutType:string):{result:string} {
        if(homePageLayoutType === 'card-box' && optionLayoutTypeSelected === 'slider3'){
            const qtyDiff = Math.abs(3 - selectedHomePageLayoutItems.length);
            //console.log('hplys:', qtyDiff, selectedHomePageLayoutItems.length);
            if(qtyDiff > 0){
                return ({result:'failed'});
            }

        }

        if(homePageLayoutType === 'card-box' && optionLayoutTypeSelected === 'slider4'){
            const qtyDiff = Math.abs(4 - selectedHomePageLayoutItems.length);
            //console.log('hplys:', qtyDiff, selectedHomePageLayoutItems.length);
            if(qtyDiff > 0){
                return ({result:'failed'});
            }

        }


        if(homePageLayoutType === 'slider3' && optionLayoutTypeSelected === 'card-box'){
            const qtyDiff = Math.abs(2 - selectedHomePageLayoutItems.length);
            //console.log('hplys:', qtyDiff, selectedHomePageLayoutItems.length);
            if(qtyDiff > 0){
                return ({result:'failed'});
            }

        }

        if(homePageLayoutType === 'slider4' && optionLayoutTypeSelected === 'card-box'){
            const qtyDiff = Math.abs(2 - selectedHomePageLayoutItems.length);
            //console.log('hplys:', qtyDiff, selectedHomePageLayoutItems.length);
            if(qtyDiff > 0){
                return ({result:'failed'});
            }
        }

        if(homePageLayoutType === 'slider4' && optionLayoutTypeSelected === 'slider3'){
            const qtyDiff = Math.abs(3 - selectedHomePageLayoutItems.length);
            //console.log('hplys:', qtyDiff, selectedHomePageLayoutItems.length);
            if(qtyDiff > 0){
                return ({result:'failed'});
            }
        }

        if(homePageLayoutType === 'slider3' && optionLayoutTypeSelected === 'slider4'){
            const qtyDiff = Math.abs(4 - selectedHomePageLayoutItems.length);
            //console.log('hplys:', qtyDiff, selectedHomePageLayoutItems.length);
            if(qtyDiff > 0){
                return ({result:'failed'});
            }
        }

        return ({result:'passed'});
    }

    function getLayoutOrderSelected(e:React.ChangeEvent):void {
        const optionSelected = parseInt((e.target as HTMLSelectElement).value,10);
        //console.log('os:', optionSelected);
        setHomePageLayoutOrderSelected(optionSelected);
        const hpl = new HomePageLayout({
                                    ...homePageLayout!,
                                    homePageLayoutOrder:optionSelected
                                    });
        //setHomePageLayout(hpl);

        handleUpdateHomePageLayoutOrder!(hpl);

    }

    function getHomePageLayoutItemList(e:React.MouseEvent):void {

        const {msg, showMsg} = checkLayoutTypeExistingItemLimit(existingHomePageLayoutItems, selectedHomePageLayoutItems);
        //console.log('msg:', msg, 'showMsg:', showMsg, 'sehpl:', showEditHomePageLayout);
        if(showMsg && showEditHomePageLayout){
            setItemQtyOverLimitMsg(msg);
            setShowHomePageLayoutTypeMsg(false);
            setShowItemQtyOverLimitMsg(true);

        } else {
            setShowHomePageLayoutTypeMsg(false);
            setShowItemQtyOverLimitMsg(false);
            setShowHomePageLayoutItemList(true);
        }


        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function checkExistingItemQty(ehplis:HomePageLayoutItem[], hplis:HomePageLayoutItem[],
                                optionLayoutTypeSelected:string, homePageLayoutType:string):{result:string, msg:string}{
        const totalItems = ehplis.concat(hplis);
        const existingItems = ehplis.length;
        const selectedItems = hplis.length;
        if(homePageLayoutType === 'card-box' && optionLayoutTypeSelected === 'slider3'){
            if(existingItems > 3 && selectedItems > 3){
                const selectedItemsDiff = (existingItems + selectedItems) - 3;
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems > 3 && selectedItems === 0){
                const existingItemsDiff = existingItems - 3;
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove ${existingItemsDiff} ${existingItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 0 && selectedItems > 3){
                const selectedItemsDiff = selectedItems - 3;
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 3 && selectedItems === 1){
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove 1 item and try again.`;  
                return ({result:'failed', msg});
                
            }

            if(existingItems === 2 && selectedItems === 2){
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

            if(existingItems === 1 && selectedItems === 3){
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }
      
        }

        if(homePageLayoutType === 'card-box' && optionLayoutTypeSelected === 'slider4'){
             if(existingItems > 4 && selectedItems > 4){
                const selectedItemsDiff = (existingItems + selectedItems) - 4;
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems > 4 && selectedItems === 0){
                const existingItemsDiff = existingItems - 4;
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove ${existingItemsDiff} ${existingItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 0 && selectedItems > 4){
                const selectedItemsDiff = selectedItems - 4;
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 4 && selectedItems === 1){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;  
                return ({result:'failed', msg});
                
            }

            if(existingItems === 2 && selectedItems === 3){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

            if(existingItems === 3 && selectedItems === 2){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

            if(existingItems === 1 && selectedItems === 4){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

        }


        if(homePageLayoutType === 'slider3' && optionLayoutTypeSelected === 'card-box'){
            if(existingItems > 2 && selectedItems > 2){
                const selectedItemsDiff = (existingItems + selectedItems) - 2;
                const msg = `You have more than the required amount of items for the card-box layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems > 2 && selectedItems === 0){
                const existingItemsDiff = existingItems - 2;
                const msg = `You have more than the required amount of items for the card-box layout. Please remove ${existingItemsDiff} ${existingItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 0 && selectedItems > 2){
                const selectedItemsDiff = selectedItems - 2;
                const msg = `You have more than the required amount of items for the card-box layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 2 && selectedItems === 1){
                const msg = `You have more than the required amount of items for the card-box layout. Please remove 1 item and try again.`;  
                return ({result:'failed', msg});
                
            }

            if(existingItems === 1 && selectedItems === 2){
                const msg = `You have more than the required amount of items for the card-box layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

        }

        if(homePageLayoutType === 'slider4' && optionLayoutTypeSelected === 'card-box'){
            if(existingItems > 2 && selectedItems > 2){
                const selectedItemsDiff = (existingItems + selectedItems) - 2;
                const msg = `You have more than the required amount of items for the card-box layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems > 2 && selectedItems === 0){
                const existingItemsDiff = existingItems - 2;
                const msg = `You have more than the required amount of items for the card-box layout. Please remove ${existingItemsDiff} ${existingItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 0 && selectedItems > 2){
                const selectedItemsDiff = selectedItems - 2;
                const msg = `You have more than the required amount of items for the card-box layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 2 && selectedItems === 1){
                const msg = `You have more than the required amount of items for the card-box layout. Please remove 1 item and try again.`;  
                return ({result:'failed', msg});
                
            }

            if(existingItems === 1 && selectedItems === 2){
                const msg = `You have more than the required amount of items for the card-box layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }
        }

        if(homePageLayoutType === 'slider4' && optionLayoutTypeSelected === 'slider3'){
            if(existingItems > 3 && selectedItems > 3){
                const selectedItemsDiff = (existingItems + selectedItems) - 3;
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems > 3 && selectedItems === 0){
                const existingItemsDiff = existingItems - 3;
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove ${existingItemsDiff} ${existingItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 0 && selectedItems > 3){
                const selectedItemsDiff = selectedItems - 3;
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 3 && selectedItems === 1){
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove 1 item and try again.`;  
                return ({result:'failed', msg});
                
            }

            if(existingItems === 2 && selectedItems === 2){
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

            if(existingItems === 1 && selectedItems === 3){
                const msg = `You have more than the required amount of items for the slider3 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }
        }

        if(homePageLayoutType === 'slider3' && optionLayoutTypeSelected === 'slider4'){
            if(existingItems > 4 && selectedItems > 4){
                const selectedItemsDiff = (existingItems + selectedItems) - 4;
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems > 4 && selectedItems === 0){
                const existingItemsDiff = existingItems - 4;
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove ${existingItemsDiff} ${existingItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 0 && selectedItems > 4){
                const selectedItemsDiff = selectedItems - 4;
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove ${selectedItemsDiff} ${selectedItemsDiff === 1 ? 'item' : 'items'} and try again.`;    
                return ({result:'failed', msg});
                
            }

            if(existingItems === 4 && selectedItems === 1){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;  
                return ({result:'failed', msg});
                
            }

            if(existingItems === 2 && selectedItems === 3){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

            if(existingItems === 3 && selectedItems === 2){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }

            if(existingItems === 1 && selectedItems === 4){
                const msg = `You have more than the required amount of items for the slider4 layout. Please remove 1 item and try again.`;
                return ({result:'failed', msg});
                
            }
        }

        return ({result:'passed', msg:'none'});

    }

    function checkLayoutTypeExistingItemLimit(ehplis:HomePageLayoutItem[], hplis:HomePageLayoutItem[]):{msg:string, showMsg:boolean} {
        const totalItems = ehplis.concat(hplis);

        if(homePageLayoutTypeSelected === 'card-box' && totalItems.length >= 2 && ehplis.length >= 2){
            const qtyDiff = 2 - totalItems.length;
            const msg = `You have ${qtyDiff < 0 ? 'more than ' : ''}the required amount of items for this layout. Please remove ${Math.abs(qtyDiff) + 1} ${Math.abs(qtyDiff) === 0 ? 'item' : 'items'} and try again.`;
            //setItemQtyOverLimitMsg(msg);
            //setShowItemQtyOverLimitMsg(true);
            return ({msg, showMsg:true});

        }

        if(homePageLayoutTypeSelected === 'slider3' && totalItems.length >= 3 && ehplis.length >= 3){
            const qtyDiff = 3 - totalItems.length;
            const msg = `You have ${qtyDiff < 0 ? 'more than ' : ''}the required amount of items for this layout. Please remove ${Math.abs(qtyDiff) + 1} ${Math.abs(qtyDiff) === 0 ? 'item' : 'items'} and try again.`;
            //setItemQtyOverLimitMsg(msg);
            //setShowItemQtyOverLimitMsg(true);
            return ({msg, showMsg:true});

        }

        if(homePageLayoutTypeSelected === 'slider4' && totalItems.length >= 4 && ehplis.length >= 4){
            const qtyDiff = 4 - totalItems.length;
            const msg = `You have ${qtyDiff < 0 ? 'more than ' : ''}the required amount of items for this layout. Please remove ${Math.abs(qtyDiff) + 1} ${Math.abs(qtyDiff) === 0 ? 'item' : 'items'} and try again.`;
            //setItemQtyOverLimitMsg(msg);
            //setShowItemQtyOverLimitMsg(true);
            return ({msg, showMsg:true});
        }

        if(homePageLayoutTypeSelected === 'carousel' && totalItems.length >= 25 && ehplis.length >= 25){
            const qtyDiff = 25 - totalItems.length;
            const msg = `You have ${qtyDiff < 0 ? 'more than ' : ''}the required amount of items for this layout. Please remove ${Math.abs(qtyDiff) + 1} ${Math.abs(qtyDiff) === 0 ? 'item' : 'items'} and try again.`;
            //setItemQtyOverLimitMsg(msg);
            //setShowItemQtyOverLimitMsg(true);
            return ({msg, showMsg:true});
        }

        if(homePageLayoutTypeSelected === 'banner-box' && totalItems.length >= 1 && ehplis.length >= 1){
            const qtyDiff = 1 - totalItems.length;
            const msg = `You have ${qtyDiff < 0 ? 'more than ' : ''}the required amount of items for this layout. Please remove ${Math.abs(qtyDiff) + 1} ${Math.abs(qtyDiff) === 0 ? 'item' : 'items'} and try again.`;
            //setItemQtyOverLimitMsg(msg);
            //setShowItemQtyOverLimitMsg(true);
            return ({msg, showMsg:true});
        }

        return ({msg:'You have the required amount of items for this layout', showMsg:false});

    }

    function cancelHomePageLayoutItemList(e:React.MouseEvent):void {
        setSelectedHomePageLayoutItems([]);
        setShowHomePageLayoutItemList(false);
        //setSaveBtnDisabled(true);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function addHomePageLayoutItemList(shplis:HomePageLayoutItem[]):void {
        if(shplis[0] instanceof MarketingBanner){
            const mb = shplis[0];
            setSelectedHeadingPositionDesktop(mb.headingPositionDesktop);
            setSelectedHeadingPositionMobile(mb.headingPositionMobile);
            setSelectedHeadingSizeDesktop(mb.headingSizeDesktop);
            setSelectedHeadingSizeMobile(mb.headingSizeMobile);
            setSelectedHeadingTypeDesktop(mb.headingTypeDesktop);
            setSelectedHeadingTypeMobile(mb.headingTypeMobile);
        }

        setSelectedHomePageLayoutItems(shplis);
        setShowHomePageLayoutItemList(false);

        const totalHomePageLayoutItems = (existingHomePageLayoutItems).concat(shplis);
        let disableSaveBtn = false;
        //console.log('total:', existingHomePageLayoutItems, shplis, homePageLayoutTypeSelected);
        switch(homePageLayoutTypeSelected){
            case 'card-box':
                disableSaveBtn = (totalHomePageLayoutItems.length === 2) ? false : true;
                break;
            case 'slider3':
                disableSaveBtn = (totalHomePageLayoutItems.length === 3) ? false : true;
                break;
            case 'slider4':
                disableSaveBtn = (totalHomePageLayoutItems.length === 4) ? false : true;
                break;
            case 'banner-box':
                disableSaveBtn = (totalHomePageLayoutItems.length === 1) ? false : true;
                break;
            case 'carousel':
                disableSaveBtn = ((totalHomePageLayoutItems.length >= 7) && (totalHomePageLayoutItems.length <= 25)) ? false : true;
                break;
            default:
                throw new Error('no home page layout found.');

        }

        //setSaveBtnDisabled(disableSaveBtn);
        if(existingHomePageLayoutItems.length === 0){
            //setItemsBtnDisabled(false);
        } else {
            //setItemsBtnDisabled(!disableSaveBtn);
        }


        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);
        

    }

    function cancelAddHomePageLayoutHandler(e:React.MouseEvent):void {
        handleCloseHomePageLayout!(homePageLayout);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            //setIsLoading(false);
        },100);

    }

    function getSliderDistance(homePageLayoutType:string):number {
        if(homePageLayoutType === 'slider3'){
            return 3;

        } else if(homePageLayoutType === 'slider4'){
            return 4;

        } else {
            return 0;
        }
    }

    function closeItemQtyOverLimitMsgHandler(e:React.MouseEvent):void {
        setShowItemQtyOverLimitMsg(false);
    }

    function closeHomePageLayoutTypeMsgHandler(e:React.MouseEvent):void {
        setShowHomePageLayoutTypeMsg(false);
    }

    function continueHomePageLayoutTypeMsgHandler(e:React.MouseEvent):void {
        const optionSelected = homePageLayoutTypeMsg.split(' ')[1];
        setExistingHomePageLayoutItems([]);
        setSelectedHomePageLayoutItems([]);
        setHomePageLayoutTypeSelected(optionSelected);
        setShowHomePageLayoutTypeMsg(false);
    }

    function checkNumberOfLayoutItems(ehplis:HomePageLayoutItem[], hplis:HomePageLayoutItem[], hplt:string):{result:string, msg:string} {
        //check if correct amount of items for the layout type selected
        if(homePageLayoutTypeSelected === 'banner-box'){
            const layoutItems = existingHomePageLayoutItems.concat(selectedHomePageLayoutItems);
            const totalLayoutItems = layoutItems.length;

            if(totalLayoutItems < 1 || totalLayoutItems > 1){
                //const numberOfLayoutItems = 1 - totalLayoutItems;
                const nolis = 1 - totalLayoutItems;
                //show a message that says you need to add number of layout items;
                const msg = `You have ${nolis < 0 ? 'more than' : 'less than'} the required amount of items for this layout. Please ${nolis < 0 ? 'remove' : 'add'} ${Math.abs(nolis)} ${Math.abs(nolis) === 1 ? 'item' : 'items'} and try again.`;
                return ({result:'failed', msg});
            }
            
        }

        if(homePageLayoutTypeSelected === 'carousel'){
            const layoutItems = existingHomePageLayoutItems.concat(selectedHomePageLayoutItems);
            const totalLayoutItems = layoutItems.length;

            /*
            if(totalLayoutItems < 25){
                //const numberOfLayoutItems = 1 - totalLayoutItems;
                const nolis = 1 - totalLayoutItems;
                //show a message that says you need to add number of layout items;
                const msg = `You have ${nolis < 0 ? 'more than' : 'less than'} the required amount of items for this layout. Please ${nolis < 0 ? 'remove' : 'add'} ${Math.abs(nolis)} ${Math.abs(nolis) === 1 ? 'item' : 'items'} and try again.`;
                return ({result:'failed', msg});
            }
            */

            if(totalLayoutItems < 25 || totalLayoutItems > 25){
                //const numberOfLayoutItems = 1 - totalLayoutItems;
                const nolis = 25 - totalLayoutItems;
                //show a message that says you need to add number of layout items;
                const msg = `You have ${nolis < 0 ? 'more than' : 'less than'} the required amount of items for this layout. Please ${nolis < 0 ? 'remove' : 'add'} ${Math.abs(nolis)} ${Math.abs(nolis) === 1 ? 'item' : 'items'} and try again.`;
                return ({result:'failed', msg});
            }
            
        }

        if(homePageLayoutTypeSelected === 'card-box'){
            const layoutItems = existingHomePageLayoutItems.concat(selectedHomePageLayoutItems);
            const totalLayoutItems = layoutItems.length;

            if(totalLayoutItems < 2 || totalLayoutItems > 2){
                //const numberOfLayoutItems = 1 - totalLayoutItems;
                const nolis = 2 - totalLayoutItems;
                //show a message that says you need to add number of layout items;
                const msg = `You have ${nolis < 0 ? 'more than' : 'less than'} the required amount of items for this layout. Please ${nolis < 0 ? 'remove' : 'add'} ${Math.abs(nolis)} ${Math.abs(nolis) === 1 ? 'item' : 'items'} and try again.`;
                return ({result:'failed', msg});
            }
           
        }

        if(homePageLayoutTypeSelected === 'slider3'){
            const layoutItems = existingHomePageLayoutItems.concat(selectedHomePageLayoutItems);
            const totalLayoutItems = layoutItems.length;

            if(totalLayoutItems < 3 || totalLayoutItems > 3){
                //const numberOfLayoutItems = 1 - totalLayoutItems;
                const nolis = 3 - totalLayoutItems;
                //show a message that says you need to add number of layout items;
                const msg = `You have ${nolis < 0 ? 'more than' : 'less than'} the required amount of items for this layout. Please ${nolis < 0 ? 'remove' : 'add'} ${Math.abs(nolis)} ${Math.abs(nolis) === 1 ? 'item' : 'items'} and try again.`;
                return ({result:'failed', msg});
            }
           
        }

        if(homePageLayoutTypeSelected === 'slider4'){
            const layoutItems = existingHomePageLayoutItems.concat(selectedHomePageLayoutItems);
            const totalLayoutItems = layoutItems.length;

            if(totalLayoutItems < 4 || totalLayoutItems > 4){
                //const numberOfLayoutItems = 1 - totalLayoutItems;
                const nolis = 4 - totalLayoutItems;
                //show a message that says you need to add number of layout items;
                const msg = `You have ${nolis < 0 ? 'more than' : 'less than'} the required amount of items for this layout. Please ${nolis < 0 ? 'remove' : 'add'} ${Math.abs(nolis)} ${Math.abs(nolis) === 1 ? 'item' : 'items'} and try again.`;
                return ({result:'failed', msg});

            }
           
        }

        return ({result:'passed', msg:'none'});

    }

    function saveHomePageLayoutHandler(layoutAction:string):void {
        //handleSaveHomePageLayout
        //console.log('save:', homePageLayout, selectedHomePageLayoutItems, homePageLayoutTypeSelected);

        const {result, msg} = checkNumberOfLayoutItems(existingHomePageLayoutItems, selectedHomePageLayoutItems, homePageLayoutTypeSelected);
        if(result === 'failed'){
            setItemQtyOverLimitMsg(msg);
            setShowHomePageLayoutTypeMsg(false);
            setShowItemQtyOverLimitMsg(true);

        } else {
        
            //if home page layout is null then adding a new home page layout
            //if(homePageLayout === null){
                const allHomePageLayoutItems = existingHomePageLayoutItems.concat(selectedHomePageLayoutItems);

                //console.log('ahplis:', allHomePageLayoutItems);

                //const hplItem = selectedHomePageLayoutItems[0];
                const hplItem = allHomePageLayoutItems[0];

                if(hplItem instanceof MarketingBanner){

                    const mb = hplItem as MarketingBanner;
                    //if home page layout item is an instance of a Marketing Banner
                    //then save headingSize, headingType, headingPosition, headingColor
                    //buttonColor buttonBackgroundColor, captionColor, fullLength, homePageLayoutId
                    //for each item
                    //console.log('mbId:', (allHomePageLayoutItems[0] as MarketingBanner).marketingBannerId);
                    //console.log('hplId:', (allHomePageLayoutItems[0] as MarketingBanner).homePageLayoutId);
                    //console.log('shpd:', selectedHeadingPositionDesktop);
                    //console.log('shpm:', selectedHeadingPositionMobile);
                    //console.log('shsd:', selectedHeadingSizeDesktop);
                    //console.log('shsm:', selectedHeadingSizeMobile);
                    //console.log('shtd:', selectedHeadingTypeDesktop);
                    //console.log('shtm:', selectedHeadingTypeMobile);
                    //console.log('flbcc:', fullLengthBannerCheckboxChecked);
                    //console.log('scc:', selectedCaptionColor);
                    //console.log('shc:', selectedHeadingColor);
                    //console.log('sbbc:', selectedButtonBackgroundColor);
                    //console.log('sbc:', selectedButtonColor);

                    //get the next homePageLayoutId from the database before you add a new Home page layout
                    //set this up tomorrow in HomePageLayoutDisplay
                    mb.homePageLayoutId = (layoutAction === 'add') ? nextHomePageLayoutId! : homePageLayout!.homePageLayoutId;
                    mb.selectedForApproval = true;
                    mb.headingPositionDesktop = selectedHeadingPositionDesktop;
                    mb.headingPositionMobile = selectedHeadingPositionMobile;
                    mb.headingSizeDesktop = selectedHeadingSizeDesktop;
                    mb.headingSizeMobile = selectedHeadingSizeMobile;
                    mb.headingTypeDesktop = selectedHeadingTypeDesktop;
                    mb.headingTypeMobile = selectedHeadingTypeMobile;
                    mb.fullLength = fullLengthBannerCheckboxChecked;
                    mb.captionColor = selectedCaptionColor;
                    mb.headingColor = selectedHeadingColor;
                    mb.buttonBackgroundColor = selectedButtonBackgroundColor;
                    mb.buttonColor = selectedButtonColor;
                    

                    //console.log('mb selected:', mb, nextHomePageLayoutId);
                    const hpl = new HomePageLayout({
                                                    homePageLayoutId:(layoutAction === 'add') ? nextHomePageLayoutId! : homePageLayout!.homePageLayoutId,
                                                    homePageLayoutOrder:(layoutAction === 'add') ? 1 : homePageLayout!.homePageLayoutOrder,
                                                    homePageLayoutType:homePageLayoutTypeSelected,
                                                    items:[mb]
                                                });
                    //nedd to send the marketing banner and save to the database with the homepagelayoutId attached
                    //add home page layout with new id to the database
                    //do the same for marketing pictures and products below

                    //this is the save function from home page layout builder
                    //add the mb as a argument to the function and call it.
                    //before you send the information need to get the next home page layout id from the database
                    //this should already be added as a parameter to the home page layout display when it is created
                    //need to add another paramter (the layout) to the handle save home page layout function.
                    //start here and finish
                    if(layoutAction === 'add'){
                        handleSaveHomePageLayout!(hpl);

                    } else {
                        handleUpdateHomePageLayout!(hpl);
                    }
                    
                    
                    //home page layout will not have a heading or caption when the item is a marketingBanner
                    //because the heading an caption are part of the banner

                } else if(hplItem instanceof MarketingPicture) {
                    //if home page layout item is an instance of a marking picture or product
                    //then only save the homePageLayoutId for each item
                    //for the home page layout save the heading and caption if they have been added.
                   //console.log('heading:', selectedHeading);
                    //console.log('caption:', selectedCaption);
                    //console.log('mbId:', (allHomePageLayoutItems[0] as MarketingPicture).marketingPictureId);
                    //console.log('hplId:', (allHomePageLayoutItems[0] as MarketingPicture).homePageLayoutId);

                    //add homepagelayoutid to each marketing picture
                    const hplis = allHomePageLayoutItems.map((shpli)=>{
                                        shpli.homePageLayoutId = (layoutAction === 'add') ? nextHomePageLayoutId! : homePageLayout!.homePageLayoutId;
                                        shpli.selectedForApproval = true;
                                        return shpli;
                                    });


                            //console.log('hplis:', hplis);

                    //add heading and caption and homepagelayoutid to homepagelayout if they exist 
                    const hpl = new HomePageLayout({
                        homePageLayoutId:(layoutAction === 'add') ? nextHomePageLayoutId! : homePageLayout!.homePageLayoutId,
                        homePageLayoutOrder:(layoutAction === 'add') ? 1 : homePageLayout!.homePageLayoutOrder,
                        homePageLayoutType:homePageLayoutTypeSelected,
                        heading:selectedHeading.length === 0 ? 'none' : selectedHeading,
                        caption:selectedCaption.length === 0 ? 'none' : selectedCaption,
                        distance:getSliderDistance(homePageLayoutTypeSelected),
                        items:[...hplis]
                    });

                    if(layoutAction === 'add'){
                        handleSaveHomePageLayout!(hpl);

                    } else {
                        handleUpdateHomePageLayout!(hpl);
                    }             
                

                } else {
                    //this area is for products
                    //console.log('heading:', selectedHeading);
                    //console.log('caption:', selectedCaption);
                    //console.log('mbId:', (allHomePageLayoutItems[0] as Product).productId);
                    //console.log('hplId:', (allHomePageLayoutItems[0] as Product).homePageLayoutId);

                    const hplis = allHomePageLayoutItems.map((shpli)=>{
                                    shpli.homePageLayoutId = (layoutAction === 'add') ? nextHomePageLayoutId! : homePageLayout!.homePageLayoutId;
                                    shpli.selectedForApproval = true;
                                    return shpli;
                                    });


                    //console.log('hplis:', hplis);

                    //add heading and caption and homepagelayoutid to homepagelayout if they exist 
                    const hpl = new HomePageLayout({
                        homePageLayoutId:(layoutAction === 'add') ? nextHomePageLayoutId! : homePageLayout!.homePageLayoutId,
                        homePageLayoutOrder:(layoutAction === 'add') ? 1 : homePageLayout!.homePageLayoutOrder,
                        homePageLayoutType:homePageLayoutTypeSelected,
                        heading:selectedHeading.length === 0 ? 'none' : selectedHeading,
                        caption:selectedCaption.length === 0 ? 'none' : selectedCaption,
                        items:[...hplis]
                    });

                    if(layoutAction === 'add'){
                        handleSaveHomePageLayout!(hpl);

                    } else {
                        handleUpdateHomePageLayout!(hpl);
                    } 

                }



            //} else {
                //if home page layout is not null then editing an existing home page layout
                //finish this after getting save new home page layout to work
                //console.log('hpl:', homePageLayout, selectedHomePageLayoutItems, existingHomePageLayoutItems);
           // }


    }

        
    }

    function editHomePageLayoutHandler(e:React.MouseEvent):void {
        //window.scrollTo(0,0);
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);
        
        handleEditHomePageLayout!(homePageLayout!);
    }

    function deleteHomePageLayoutHandler(e:React.MouseEvent):void {
        handleDeleteHomePageLayout!(homePageLayout!);
    }

    function setupHomePageLayoutContainerClass(hpl:HomePageLayout):string {
        switch(hpl.homePageLayoutType){
            case 'card-box':
            case 'slider3':
            case 'slider4':
            case 'carousel':
                return 'hpld-no-mb';
            case 'banner-box':
                if(hpl.homePageLayoutOrder === 1){
                    return 'hpld-mb-first';
                } else {
                    return 'hpld-mb';
                }
                
            default:
                throw new Error('no home page layout type found.');
        }
    }

    function setupHomePageLayoutItemClass(hpl:HomePageLayout):string {
        switch(hpl.homePageLayoutType){
            case 'card-box':
            case 'slider3':
            case 'slider4':
            case 'carousel':
                return 'hpld-item-no-mb';
            case 'banner-box':
                if(hpl.homePageLayoutOrder === 1){
                    return 'hpld-item-mb-first';
                } else {
                    return 'hpld-item-mb';
                }
                
            default:
                throw new Error('no home page layout type found.');
        }
    }


    if(isLoading){
        return <div className="hplb-wrapper">
                    <div className="hpld-loaeder-animation-container">
                        {/*<LoaderAnimation/>*/}
                    </div>
            </div>
    } else

    if(showHomePageLayoutItemList){
        return <HomePageLayoutItemBuilder homePageLayout={homePageLayout!}
                    homePageLayoutType={homePageLayoutTypeSelected}
                    addHomePageLayoutItemList={addHomePageLayoutItemList}
                    cancelHomePageLayoutItemList={cancelHomePageLayoutItemList}
                    selectedHomePageLayoutItems={selectedHomePageLayoutItems}
                    existingHomePageLayoutItems={existingHomePageLayoutItems} />;

    } else if(homePageLayout === null){
        //console.log(null);
        return (<div className="hpld-item-container">
                    <h1 className="home-page-layout-builder-heading">Add Layout</h1>
                    <ul className="hpld-item-options-list">
                        <li className="hpld-item-option-list-item">
                            <HomePageLayoutTypeSelector label={"Type"} optionSelected={homePageLayoutTypeSelected}
                                cssStyles={{minWidth:140, backgroundPosition:14}}
                                 options={typeOptions} optionId={"add-layout"} callback={getLayoutTypeSelected}/>
                        </li>
                        <li className="hpld-item-option-list-item">
                            <button type="button" className="hpld-item-option-list-items-btn" onClick={getHomePageLayoutItemList}>Images</button>
                        </li>
                        <li className="hpld-item-option-list-item">
                            <button type="button" className="hpld-item-option-list-save-btn" onClick={(e:React.MouseEvent)=>saveHomePageLayoutHandler('add')} disabled={saveBtnDisabled}>Save</button>
                        </li>
                        <li className="hpld-item-option-list-item">
                            <button type="button" className="hpld-item-option-list-cancel-btn" onClick={cancelAddHomePageLayoutHandler}>Cancel</button>
                        </li>
                    </ul>
                    <div className="hpld-item">
                        {
                            setupAddDisplay(homePageLayoutTypeSelected, 'add')
                        }
                        {
                            showItemQtyOverLimitMsg
                            ?
                            <div className="hpl-generic-info-msg-container">
                                <GenericInfoMessage infoMessage={itemQtyOverLimitMsg} showExtraMsgContainer={true}
                                showCloseBtn={true} closeInfoMsgHandler={closeItemQtyOverLimitMsgHandler}/>
                            </div>
                            : null
                        }
                        {
                            showHomePageLayoutTypeMsg
                            ?
                            <div className="hpl-generic-info-msg-container">
                                <GenericInfoMessage infoMessage={homePageLayoutTypeMsg} showCloseBtn={true} 
                                showExtraMsgContainer={true} closeInfoMsgHandler={closeHomePageLayoutTypeMsgHandler}
                                continueInfoMsgHandler={continueHomePageLayoutTypeMsgHandler}/>
                            </div>
                            : null
                        }
                    </div>
                </div>); 

    } else if(showEditHomePageLayout) {
        return (<div id={homePageLayout.homePageLayoutId.toString()} ref={editHomePageLayoutContainerRef} className="hpld-item-container">
                    <h1 className="home-page-layout-builder-heading">Edit Layout</h1>
                    <ul className="hpld-item-options-list">
                        <li className="hpld-item-option-list-item">
                            <HomePageLayoutTypeSelector label={"Type"} optionSelected={homePageLayoutTypeSelected}
                                cssStyles={{minWidth:140, backgroundPosition:14}}
                               options={typeOptions} optionId={"edit-layout"} callback={getLayoutTypeSelected}/>
                        </li>
                        <li className="hpld-item-option-list-item">
                            <button type="button" className="hpld-item-option-list-save-btn" onClick={(e:React.MouseEvent)=>saveHomePageLayoutHandler('edit')} disabled={saveBtnDisabled}>Save</button>
                        </li>
                        <li className="hpld-item-option-list-item">
                            <button type="button" className="hpld-item-option-list-cancel-btn" onClick={cancelAddHomePageLayoutHandler}>Cancel</button>
                        </li>
                        <li className="hpld-item-option-list-item">
                            <button type="button" className="hpld-item-option-list-items-btn" onClick={getHomePageLayoutItemList} disabled={itemsBtnDisabled}>Images</button>
                        </li>
                    </ul>
                    <div className="hpld-item">
                        {
                            setupEditDisplay(homePageLayout, homePageLayoutTypeSelected, 'edit')
                        }
                        {
                            showItemQtyOverLimitMsg
                            ?
                            <div className="hpl-generic-info-msg-container">
                                <GenericInfoMessage infoMessage={itemQtyOverLimitMsg} showExtraMsgContainer={true}
                                showCloseBtn={true} closeInfoMsgHandler={closeItemQtyOverLimitMsgHandler}/>
                            </div>
                            : null
                        }
                        {
                            showHomePageLayoutTypeMsg
                            ?
                            <div className="hpl-generic-info-msg-container">
                                <GenericInfoMessage infoMessage={homePageLayoutTypeMsg} showCloseBtn={true} 
                                showExtraMsgContainer={true} closeInfoMsgHandler={closeHomePageLayoutTypeMsgHandler}
                                continueInfoMsgHandler={continueHomePageLayoutTypeMsgHandler}/>
                            </div>
                            : null
                        }
                    </div>
                </div>);

    } else {

        return (<div id={homePageLayout.homePageLayoutId.toString()} className={`hpld-item-container ${productionMode ? setupHomePageLayoutContainerClass(homePageLayout) : ''}`}>
                    {
                        !productionMode
                        ?
                        <ul className="hpld-item-options-list">
                            <li className="hpld-item-option-list-item">
                            <HomePageLayoutSelector label={"Order"} optionSelected={homePageLayoutOrderSelected} 
                                options={orderOptions} optionId={homePageLayout.homePageLayoutId.toString()} callback={getLayoutOrderSelected!}/>
                            </li>
                            <li className="hpld-item-option-list-item">
                                <button type="button" className="hpld-item-option-list-edit-btn" onClick={editHomePageLayoutHandler}>Edit</button>
                            </li>
                            <li className="hpld-item-option-list-item">
                                <button type="button" className="hpld-item-option-list-delete-btn" onClick={deleteHomePageLayoutHandler}>Delete</button>
                            </li>
                        </ul>
                        : null
                    }

                    
                    <div className={`hpld-item ${productionMode ? setupHomePageLayoutItemClass(homePageLayout) : 'hpld-item-no-production-options'}`}>
                        {
                            setupDisplay(homePageLayout, homePageLayoutTypeSelected, 'display', productionMode ? false : true)
                        }
                    </div>
                </div>);
    }


}

export default HomePageLayoutDisplay;