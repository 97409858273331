import React, {useState, useEffect} from 'react';
import {selectShoppingCart, addProductsToMiniCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import './FullCartErrorMsg.css';

interface Props {}

const FullCartErrorMsg:React.FunctionComponent<Props> = (props:Props)=>{
    //need to add background overlay
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const dispatch = useAppDispatch();
    const [fullCartErrorMsgStyleClass, setFullCartErrorMsgStyleClass] = useState('full-cart-error-msg-background-shade-container-hide');

    useEffect(()=>{
        if(shoppingCartRedux.itemLimitExceeded && shoppingCartRedux.showItemLimtExceededErrorMsg){
            setFullCartErrorMsgStyleClass('full-cart-error-msg-background-shade-container');

        } else {
            setFullCartErrorMsgStyleClass('full-cart-error-msg-background-shade-container-hide');
        }

    },[shoppingCartRedux]);


    function closeFullCartErrorMsg(e:React.MouseEvent):void {
        e.stopPropagation();
        setFullCartErrorMsgStyleClass('full-cart-error-msg-background-shade-container-hide');
        shoppingCartRedux.itemLimitExceeded = false;
        shoppingCartRedux.showItemLimtExceededErrorMsg = false;
        const sc = Object.assign({}, shoppingCartRedux);
        dispatch(addProductsToMiniCart(sc));

    }


    return  <div className={`${fullCartErrorMsgStyleClass}`}>
                <div className="full-cart-error-msg-container">
                    <h2 className="full-cart-error-msg-heading">Your cart is full</h2>
                    <p className="full-cart-error-msg">To serve all our customers better, we limit order size.</p>
                    <div className="full-cart-error-msg-close-btn-container">
                        <button type="button" className="full-cart-error-msg-close-btn"
                            onClick={closeFullCartErrorMsg}>Close</button>
                    </div>
                </div>
            </div>

}

export default FullCartErrorMsg;