import React, {useState, useEffect, useRef } from 'react';
import {connect} from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {fromEvent} from 'rxjs';
import ShopCategories from './shopCategories';
import ShopCategory from './shopCategory';
import DropdownMenuCategories from './DropdownMenuCatetories';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import SubShopCategory from './subShopCategory';
//import ShopLoadDetails from './redux/shopLoadDetails';
//import {AddShopLoadDetails, addShopLoadDetails} from './redux/actions';
//import HighlightBackground from './redux/highlightBackground';
//import {AddHighlightBackground, addHighlightBackground} from './redux/actions';
import handleGetCustomerLocation from './helper-get-customer-location';
//import {addStoreSearchParams, AddStoreSearchParams} from './redux/actions';
import StoreSearchParams from './storeSearchParams';
import {addShopLoadDetails} from './redux/features/shopLoadDetails/shopLoadDetailsSlice';
import {selectProductShopCategories, addProductShopCategories} from './redux/features/productShopCategories/productShopCategoriesSlice';
import {selectHighlightBackground, addHighlightBackground} from './redux/features/highlightBackground/highlightBackgroundSlice';
import {selectMobileNav, showMobileNav} from './redux/features/mobileNav/mobileNavSlice';
import {selectAdminLoginDropdownMobile, showAdminLoginDropdownMobile} from './redux/features/adminLoginDropdownMobile/adminLoginDropdownMobileSlice';
import {selectAdminLogin, showAdminLogin} from './redux/features/adminLogin/adminLoginSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import adcs from './admin-dropdown-categories';
import AdminLoginDropdown from './AdminLoginDropdown';
import loadNavLinkHelper from './loadNavLinkHelper';
import './MainNavbar.css';


/*
interface State {
    productShopCategories:ShopCategory[];
    storeSearchParams:StoreSearchParams;
    highlightBackground:HighlightBackground;
}
*/

/*
function mapStateToProps(state:State) {
    const { productShopCategories, storeSearchParams, highlightBackground } = state;
    console.log('productShopCategories test 7-11:', productShopCategories);

    return {
        productShopCategories,
        storeSearchParams,
        highlightBackground
    };

}
*/


interface Props {
    //addShopLoadDetails:(shopLoadDetails:ShopLoadDetails)=>AddShopLoadDetails;
    //storeSearchParams?:StoreSearchParams;
    //addStoreSearchParams?:(storeSearchParams:StoreSearchParams)=>AddStoreSearchParams;
    //productShopCategories:ShopCategory[];
    //highlightBackground:HighlightBackground;
    loadNavLink?:(e:React.MouseEvent, pathname:string)=>void ;
    hideAdminToolsLink?:boolean;
}

/*
const mapDispatchToProps = {
    addShopLoadDetails,
    addStoreSearchParams
};
*/


const MainNavbar2:React.FunctionComponent<Props> = (props: Props) => {
    //console.log('main navbar');
    //const {addShopLoadDetails, storeSearchParams, addStoreSearchParams} = props;
    const {loadNavLink, hideAdminToolsLink} = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const productShopCategoriesRedux = useAppSelector(selectProductShopCategories);
    const selectAdminLoginRedux = useAppSelector(selectAdminLogin);
    //const highlightBackgroundRedux = useAppSelector(selectHighlightBackground);
    //const showMobileNavRedux = useAppSelector(selectMobileNav);
    //console.log('smnr:', showMobileNavRedux);

    const [curbsideDeliveryShape, setCurbsideDeliveryShape] = useState('link-item-not-selected');
    const [myStoreShape, setMyStoreShape] = useState('link-item-selected');
    const [dropdownShape, setDropdownShape] = useState('dropdown-list-hide');
    const [dropdownShadeContainer, setDropdownShadeContainer] = useState('background-shade-container-hide');
    const [keywordDropdownShadeContainer, setKeywordDropdownShadeContainer] = useState('keyword-background-shade-container-hide');
    const [backgroundShadeContainerMobile, setBackgroundShadeContainerMobile] = useState('background-shade-container-mobile-hide');
    //const curbsideDeliveryLink:any = React.createRef<HTMLDivElement>();
    const curbsideDeliveryLink:any = useRef(null);
    //const myStoreLink:any = React.createRef<HTMLDivElement>();
    const myStoreLink:any = useRef(null);
    //const dropdownBtn:any = React.createRef<HTMLButtonElement>();
    const dropdownBtn:any = useRef(null);
    //const shopDropdownMenuListItem:any = React.createRef<HTMLLIElement>();
    const shopDropdownMenuListItem:React.RefObject<HTMLLIElement> = useRef(null);
    //const shopDropdownMenuList:any = React.createRef<HTMLUListElement>();
    //const shopDropdownMenuList = useRef<HTMLDivElement>(null);
    const shopDropdownMenuListContainer = useRef<HTMLDivElement>(null);
    const adminDropdownMenuListContainer = useRef<HTMLDivElement>(null);
    const shopListCaretUp:React.RefObject<HTMLDivElement> = useRef(null);
    const [shopDropdownMenuListShape, setShopDropdownMenuListShape] = useState({height:'0px', paddingTop:'0px', paddingBottom:'0px'});
    const [subShopCategoryListItemHeight, setSubShopCategoryListItemHeight] = useState(0);
    //const pharmacyDropdownMenuList = useRef<HTMLDivElement>(null);
    const pharmacyDropdownMenuListContainer = useRef<HTMLDivElement>(null);
    const pharmacyListCaretUp:React.RefObject<HTMLDivElement> = useRef(null);
    const pharmacyDropdownMenuListItem:React.RefObject<HTMLLIElement> = useRef(null);
    const [subPharmacyCategoryListItemHeight, setSubPharmacyCategoryListItemHeight] = useState(0);
    const [pharmacyDropdownMenuListShape, setPharmacyDropdownMenuListShape] = useState({height:'0px', paddingTop:'0px', paddingBottom:'0px'});
    const [shopDropdownCategories, setShopDropdownCategories] = useState<ShopCategory[]>([]);
    const [pharmacyDropdownCategories, setPharmacyDropdownCategories] = useState<ShopCategory[]>([]);
    const [adminDropdownCategories, setAdminDropdownCategories] = useState<ShopCategory[]>(adcs);

    //const [highlightBackground, setHighlightBackground] = useState<boolean>(props.highlightBackground.highlightBackground);
    //const [highlightBackground, setHighlightBackground] = useState<boolean>(highlightBackgroundRedux.highlightBackground.highlightBackground);
    
    const mobileNavOpen = useRef<boolean>(false);
    const isMobile = useRef<boolean>(false);
    const menuListMobileNavRef = useRef<HTMLElement>(null);
    const menuListNavRef = useRef<HTMLUListElement>(null);

    const isScrolling = useRef<boolean>(false);
    const dropdownShadeContainerRef = useRef<HTMLDivElement>(null);

    const adminLoginDropdownRef = useRef<HTMLDivElement>(null);
    const [showAdminToolsDropdown, setShowAdminToolsDropdown] = useState(false);
    const [showStoreLocatorDropdown, setShowStoreLocatorDropdown] = useState(false);

    const [adminLoggedIn, setAdminLoggedIn] = useState(selectAdminLoginRedux.showAdminLogin);

    //console.log('sdcs:', shopDropdownCategories);

    useEffect(()=>{
        checkLoginStatus()
        .then((loggedIn)=>{
            if(loggedIn){
                dispatch(showAdminLogin({showAdminLogin:true}));
            }
        })
        .catch((err)=>console.log('check login error:', err.message));


        //get shop categories and sub shop categories
        Promise.all([getProductShopCategoriesPromise(),getPharmacyShopCategoriesPromise()])
        .then(([productShopCategories, pharmacyShopCategories])=>{
            //console.log('productShopCategories:', productShopCategories);
            //console.log('pharmacyShopCategories:', pharmacyShopCategories);
            setShopDropdownCategories([...productShopCategories]);
            dispatch(addProductShopCategories([...productShopCategories]));
            setPharmacyDropdownCategories([...pharmacyShopCategories]);
        })
        .catch((err)=>console.error('Promise.all() fetch shop categories error:', err));



    },[]);

    function checkLoginStatus():Promise<boolean> {
        const c = 'include';
        const m = 'cors';
        return fetch('https://server.kando-proto-3.com/check-admin-login-status', {
                    method:'GET',
                    mode:m,
                    credentials:c,
                    headers:{
                        'Content-Type':'application/json',
                    }
                })
                .then((res)=>res.json())
                .then(({loginStatus})=>{
                    //console.log('loginStatus:', loginStatus);
                    if(loginStatus === 'loggedIn'){
                        return true;

                    } else {
                        return false;
                    }
                })
                .catch((err)=>{
                    console.log('check login status error:', err.message);
                    return false
                });
    }

    useEffect(()=>{
        window.addEventListener('scroll', mainNavbarScrollHandler);
        return cleanupMainNavbarScrollHandler;
    },[]);

    function cleanupMainNavbarScrollHandler():void {
        window.removeEventListener('scroll', mainNavbarScrollHandler);
    }

    function mainNavbarScrollHandler(e:Event):void {
        if(!isScrolling.current){
            isScrolling.current = true;
            window.requestAnimationFrame(rafHandler);
        }
    }

    function rafHandler():void {
        const scrollPos = window.scrollY;
        const ww = window.innerWidth;

        if(ww >= 1024 && scrollPos > 112){
            isScrolling.current = false;
            return;
        }
        
        if(ww >= 1024 && scrollPos <= 112){
            if(dropdownShadeContainerRef.current !== null){
                dropdownShadeContainerRef.current!.style.top = `${112 - scrollPos}px`;
            }
            
        }

        isScrolling.current = false;
    }


    /*
    useEffect(()=>{
        
        console.log('showMobile:', showMobileNavRedux);
        mobileNavOpen.current = showMobileNavRedux.showMobileNav;

        if(showMobileNavRedux.showMobileNav && !showMobileNavRedux.linkSelected){
            //mobile nav opened but no link was selected
            setBackgroundShadeContainerMobile('background-shade-container-mobile');
            console.log('mlmnr:', menuListMobileNavRef.current);
            menuListMobileNavRef.current!.style.transition = 'transform .5s ease';
            menuListMobileNavRef.current!.style.transform = 'translateX(100%)';

        } else if(!showMobileNavRedux.showMobileNav && !showMobileNavRedux.linkSelected) {
            //mobile nav closed but no link was selected
            setBackgroundShadeContainerMobile('background-shade-container-mobile-hide');
            console.log('mlmnr:', menuListMobileNavRef.current);
            menuListMobileNavRef.current!.style.transition = 'transform .5s ease';
            menuListMobileNavRef.current!.style.transform = 'translateX(0px)';

        } else {
            //mobile nav closed and link was selected
            setBackgroundShadeContainerMobile('background-shade-container-mobile-hide');
            console.log('mlmnr:', menuListMobileNavRef.current);
            menuListMobileNavRef.current!.style.transition = 'transform 0s ease';
            menuListMobileNavRef.current!.style.transform = 'translateX(0px)';

        }
        

    },[showMobileNavRedux.showMobileNav]);
    */


    useEffect(()=>{
        //setShopDropdownCategories([...props.productShopCategories]);
        setShopDropdownCategories([...productShopCategoriesRedux.productShopCategories]);
    },[productShopCategoriesRedux.productShopCategories/*props.productShopCategories*/]);


    useEffect(()=>{
        setAdminLoggedIn(selectAdminLoginRedux.showAdminLogin);        
    },[selectAdminLoginRedux.showAdminLogin]);


    /*
    useEffect(()=>{
        if(highlightBackgroundRedux.highlightBackground.highlightBackground){
            setKeywordDropdownShadeContainer('keyword-background-shade-container');

        } else {
            setKeywordDropdownShadeContainer('keyword-background-shade-container-hide');
        }
        //setHighlightBackground(props.highlightBackground.highlightBackground);
        setHighlightBackground(highlightBackgroundRedux.highlightBackground.highlightBackground);

    },[highlightBackgroundRedux.highlightBackground.highlightBackground]);
    */

    async function getPharmacyShopCategoriesPromise():Promise<ShopCategory[]>{
        let pscs:ShopCategory[] = [];
        try {
            const resp = await fetch(`https://server.kando-proto-3.com/get-pharmacy-shop-categories-mariadb`, {
                                    method:'GET',
                                    headers:{
                                        'Accept':'application/json'
                                    }
                                });

            pscs = await resp.json();            

        } catch(error){
            console.error('pharmacy shop categories fetch error:', error);
        } finally {
            return addShopCategories(pscs);
        }
    }

    async function getProductShopCategoriesPromise():Promise<ShopCategory[]>{
        let pscs:ShopCategory[] = [];
        try {
            const resp = await fetch(`https://server.kando-proto-3.com/get-product-shop-categories-mariadb`, {
                                    method:'GET',
                                    headers:{
                                        'Accept':'application/json'
                                    }
                                });

            pscs = await resp.json();


        } catch(error){
            console.error('product shop categories fetch error:', error);
        } finally {
            return addShopCategories(pscs);
        }
    }

    /*
    useEffect(()=>{

        window.addEventListener('resize', resizeHandler);

        return cleanupResizeHandler;
    },[]);


    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        console.log('mn2 resize:');
        const showMobile = (window.innerWidth >= 1024) ? false : true;

        console.log('showMobile:', showMobile, mobileNavOpen);

        if(showMobile && mobileNavOpen.current){
            console.log('showMobile:', showMobile);
            setBackgroundShadeContainerMobile('background-shade-container-mobile');
            menuListMobileNavRef.current!.style.transform = 'translateX(100%)';

        } 
        
        if(!showMobile && mobileNavOpen.current) {
            console.log('showMobile:', showMobile);
            setBackgroundShadeContainerMobile('background-shade-container-mobile-hide');
            menuListMobileNavRef.current!.style.transform = 'translateX(0px)';
        }

        
    }
    */


    function addShopCategories(shopCategories:ShopCategory[]):ShopCategory[]{
        return shopCategories.map((sc)=>{
                    return new ShopCategory({
                                shopCategoryId:sc.shopCategoryId,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryLink:sc.shopCategoryLink,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                            });
                });
    }

    function addSubShopCategories(subShopCategories:SubShopCategory[]):SubShopCategory[]{
        return subShopCategories.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });
    }

    function handleShopCategoryOnMouseEnter(e:React.MouseEvent):void {
        //console.log('elem:', shopDropdownMenuListContainer.current);
        shopDropdownMenuListContainer.current!.style.display = 'block';
    
        setDropdownShadeContainer('background-shade-container');

    }

    function handlePharmacyCategoryOnMouseEnter(e:React.MouseEvent):void {
        pharmacyDropdownMenuListContainer.current!.style.display = 'block';
        setDropdownShadeContainer('background-shade-container');

    }

    function handleCategoryOnMouseLeave(e:React.MouseEvent):void {
        //(shopListCaretUp.current as HTMLDivElement).style.display = 'none';
        shopDropdownMenuListContainer.current!.style.display = 'none';
        const sdml = shopDropdownMenuListContainer.current!.querySelector('.shop-dropdown-menu-list') as HTMLUListElement;
        sdml.style.borderRadius = '24px';
        //(pharmacyListCaretUp.current as HTMLDivElement).style.display = 'none';
        pharmacyDropdownMenuListContainer.current!.style.display = 'none';
        const pdml = pharmacyDropdownMenuListContainer.current!.querySelector('.shop-dropdown-menu-list') as HTMLUListElement;
        pdml.style.borderRadius = '24px';
        
                
        setDropdownShadeContainer('background-shade-container-hide');

        
    }

    function removeShopOverviewDropdownShadow():void {
        //console.log('what:');
        //shopListCaretUp.current!.style.display = 'none';
        shopDropdownMenuListContainer.current!.style.display = 'none';

        setDropdownShadeContainer('background-shade-container-hide');

    }

    function navbarLinkHandler(e:React.MouseEvent, linkType:string):void {
        e.preventDefault();
        e.stopPropagation();

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        
        switch(linkType){
            case 'whats-new':
                navigate('/whats-new');
                dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));
                break;
            case 'storeLocator':
                navigate('/store-locator');
                dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));
                break;
            case 'admin':
                navigate('/admin');
                dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));
                break;
            default:
                throw new Error('no link type found');

        }
        
    }

    function adminToolsMouseEnterHandler(e:React.MouseEvent):void {
        //console.log('enter:');
        if(window.innerWidth >= 1024){
            //adminLoginDropdownRef.current!.style.display = 'block';
            setShowAdminToolsDropdown(true);
            setDropdownShadeContainer('background-shade-container');
        }

    }

    function adminToolsMouseLeaveHandler(e:React.MouseEvent):void {
        //console.log('enter:');
        if(window.innerWidth >= 1024){
            //adminLoginDropdownRef.current!.style.display = 'none';
            setShowAdminToolsDropdown(false);
            setDropdownShadeContainer('background-shade-container-hide');
        }

    }

    function adminToolsLoginHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        if(window.innerWidth < 1024){
            //dispatch(showMobileNav({showMobileNav:true, linkSelected:false}));
            dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:true, linkSelected:false}));
        }

    }

    function adminLogoutHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        const c = 'include';
        const m = 'cors';

        if(adminLoggedIn){
            fetch('https://server.kando-proto-3.com/logout-admin', {
                method:'GET',
                mode:m,
                credentials:c,
                headers:{
                    'Content-Type':'application/json'
                }
            })
            .then((res)=>res.json())
            .then(({loginStatus})=>{
                if(loginStatus === 'notLoggedIn'){
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    setAdminLoggedIn(false);
                    navigate('/');
                    dispatch(showAdminLogin({showAdminLogin:false}));
                }
            })
            .catch((err)=>console.log('logout admin error:', err.message));
            
        }
    }

    function storeLocatorMouseEnterHandler(e:React.MouseEvent):void {
        //console.log('enter:');
        if(window.innerWidth >= 1024){
            //adminLoginDropdownRef.current!.style.display = 'block';
            setShowStoreLocatorDropdown(true);
            setDropdownShadeContainer('background-shade-container');
        }

    }

    function storeLocatorMouseLeaveHandler(e:React.MouseEvent):void {
        //console.log('enter:');
        if(window.innerWidth >= 1024){
            //adminLoginDropdownRef.current!.style.display = 'none';
            setShowStoreLocatorDropdown(false);
            setDropdownShadeContainer('background-shade-container-hide');
        }

    }

    function hideMobileDropdownMenu():void {
        //console.log('helper:');
        dispatch(showMobileNav({showMobileNav:false, linkSelected:false}));
        dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:false, linkSelected:false}));
        dispatch(addHighlightBackground({highlightBackground:false}));
    }


    return (
        <div className="menu-list-wrapper">
            <div className="menu-list-wrapper-background">
                <div className="menu-list-container">
                         {/*<nav ref={menuListMobileNavRef} className="menu-list-mobile-nav">
                                <ul className="menu-list-options-mobile">
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/"} className="menu-list-options-item-link-mobile">
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M384 1184v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm1152 512v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm128 704v-832q0-13-9.5-22.5t-22.5-9.5h-1472q-13 0-22.5 9.5t-9.5 22.5v832q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm128-1088v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"></path></svg>
                                            Recipes
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/"} className="menu-list-options-item-link-mobile">
                                            <svg className="menu-list-options-item-link-svg" width="2048" height="1792" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M1024 512h-384v384h384v-384zm128 640v128h-640v-128h640zm0-768v640h-640v-640h640zm640 768v128h-512v-128h512zm0-256v128h-512v-128h512zm0-256v128h-512v-128h512zm0-256v128h-512v-128h512zm-1536 960v-960h-128v960q0 26 19 45t45 19 45-19 19-45zm1664 0v-1088h-1536v1088q0 33-11 64h1483q26 0 45-19t19-45zm128-1216v1216q0 80-56 136t-136 56h-1664q-80 0-136-56t-56-136v-1088h256v-128h1792z"></path></svg>
                                            Weekly Ad
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/"} className="menu-list-options-item-link-mobile">
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" ><path fill="currentColor" d="M1024 452l316 316-572 572-316-316zm-211 979l618-618q19-19 19-45t-19-45l-362-362q-18-18-45-18t-45 18l-618 618q-19 19-19 45t19 45l362 362q18 18 45 18t45-18zm889-637l-907 908q-37 37-90.5 37t-90.5-37l-126-126q56-56 56-136t-56-136-136-56-136 56l-125-126q-37-37-37-90.5t37-90.5l907-906q37-37 90.5-37t90.5 37l125 125q-56 56-56 136t56 136 136 56 136-56l126 125q37 37 37 90.5t-37 90.5z"></path></svg>
                                            Coupons
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/admin"} onClick={(e:React.MouseEvent)=>navbarLinkHandler(e, 'admin')} className="menu-list-options-item-link-mobile">
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M384 1184v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm1152 512v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm128 704v-832q0-13-9.5-22.5t-22.5-9.5h-1472q-13 0-22.5 9.5t-9.5 22.5v832q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm128-1088v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"></path></svg>
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M576 1344q0 26-19 45t-45 19-45-19-19-45 19-45 45-19 45 19 19 45zm1024 61q0 121-73 190t-194 69h-874q-121 0-194-69t-73-190q0-68 5.5-131t24-138 47.5-132.5 81-103 120-60.5q-22 52-22 120v203q-58 20-93 70t-35 111q0 80 56 136t136 56 136-56 56-136q0-61-35.5-111t-92.5-70v-203q0-62 25-93 132 104 295 104t295-104q25 31 25 93v64q-106 0-181 75t-75 181v89q-32 29-32 71 0 40 28 68t68 28 68-28 28-68q0-42-32-71v-89q0-52 38-90t90-38 90 38 38 90v89q-32 29-32 71 0 40 28 68t68 28 68-28 28-68q0-42-32-71v-89q0-68-34.5-127.5t-93.5-93.5q0-10 .5-42.5t0-48-2.5-41.5-7-47-13-40q68 15 120 60.5t81 103 47.5 132.5 24 138 5.5 131zm-320-893q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z"></path></svg>
                                            Admin
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/store-locator"} onClick={(e:React.MouseEvent)=>navbarLinkHandler(e, 'storeLocator')} className="menu-list-options-item-link-mobile">
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M384 1184v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm1152 512v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm128 704v-832q0-13-9.5-22.5t-22.5-9.5h-1472q-13 0-22.5 9.5t-9.5 22.5v832q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm128-1088v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"></path></svg>
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1024 1376v-192q0-14-9-23t-23-9H800q-14 0-23 9t-9 23v192q0 14 9 23t23 9h192q14 0 23-9t9-23zm256-672q0-88-55.5-163T1086 425t-170-41q-243 0-371 213-15 24 8 42l132 100q7 6 19 6 16 0 25-12 53-68 86-92 34-24 86-24 48 0 85.5 26t37.5 59q0 38-20 61t-68 45q-63 28-115.5 86.5T768 1020v36q0 14 9 23t23 9h192q14 0 23-9t9-23q0-19 21.5-49.5T1100 957q32-18 49-28.5t46-35 44.5-48 28-60.5 12.5-81zm384 192q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="currentColor"></path></svg>
                                            Find a Store
                                        </Link>
                                    </li>
                                </ul>
                                <ul className="menu-list-mobile">
                                    <li className="shop-mobile-menu-list-item">
                                        <Link to="/shop" className="shop-mobile-menu-list-item-heading-link">
                                            Shop
                                            <i className="fa fa-angle-down menu-list-angle-down" aria-hidden="true"></i>
                                        </Link>
                                        <DropdownMenuCategories ref={shopDropdownMenuListContainer} dropdownMenuListHeading={'shop-mobile'} 
                                            dropdownCategories={shopDropdownCategories}
                                            />
                                    </li>
                                    <li className="shop-mobile-menu-list-item">
                                        <Link to={"/"} className="shop-mobile-menu-list-item-heading-link">
                                            Pharmacy
                                            <i className="fa fa-angle-down menu-list-angle-down" aria-hidden="true"></i>
                                        </Link>
                                        <DropdownMenuCategories ref={pharmacyDropdownMenuListContainer} dropdownMenuListHeading={'shop-mobile'}
                                            dropdownCategories={pharmacyDropdownCategories}
                                            />
                                    </li>
                                </ul>
                            </nav>
                            */}
                        
                            <ul ref={menuListNavRef} className="menu-list">
                                <li className="menu-list-item shop-menu-list-item" onMouseEnter={handleShopCategoryOnMouseEnter} onMouseLeave={handleCategoryOnMouseLeave}
                                    onClick={removeShopOverviewDropdownShadow}>
                                    <Link to="/shop/overview" className="menu-list-item-heading"
                                        onClick={(e:React.MouseEvent)=>{ 
                                            //hideMobileDropdownMenu(); 
                                            (typeof loadNavLink !== 'undefined') ? loadNavLink!(e, '/shop/overview') : loadNavLinkHelper(e, '/shop/overview', navigate)}}>
                                        Shop
                                        {/*<i className="fa fa-angle-down menu-list-angle-down" aria-hidden="true"></i>*/}
                                        <svg className="menu-list-item-heading-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" ><path fill="#fff" fillRule="evenodd" d="M1.65 4.24C1.232 3.882.6 3.93.24 4.35c-.359.419-.31 1.05.11 1.41l7 6c.374.32.926.32 1.3 0l7-6c.42-.36.469-.991.11-1.41-.36-.42-.992-.469-1.41-.11L8 9.684 1.65 4.24z" clipRule="evenodd"></path></svg>
                                    </Link>
                                    <DropdownMenuCategories ref={shopDropdownMenuListContainer} dropdownMenuListHeading={'shop'} 
                                        dropdownCategories={shopDropdownCategories}
                                        loadNavLink={loadNavLink}
                                        />
                                </li>
                                <li className="menu-list-item"><Link to="/" className="menu-list-item-heading"
                                    onClick={(e:React.MouseEvent)=>{
                                        //hideMobileDropdownMenu();
                                        (typeof loadNavLink !== 'undefined') ? loadNavLink!(e, '/') : loadNavLinkHelper(e, '/', navigate)}}>Recipes</Link></li>
                                <li className="menu-list-item"><Link to="/" className="menu-list-item-heading"
                                    onClick={(e:React.MouseEvent)=>{
                                        //hideMobileDropdownMenu();
                                        (typeof loadNavLink !== 'undefined') ? loadNavLink!(e, '/') : loadNavLinkHelper(e, '/', navigate)}}>Weekly Ad</Link></li>
                                <li className="menu-list-item"><Link to="/" className="menu-list-item-heading"
                                    onClick={(e:React.MouseEvent)=>{
                                        //hideMobileDropdownMenu();
                                        (typeof loadNavLink !== 'undefined') ? loadNavLink!(e, '/') : loadNavLinkHelper(e, '/', navigate)}}>Coupons</Link></li>
                                <li className="menu-list-item pharmacy-menu-list-item" onMouseEnter={handlePharmacyCategoryOnMouseEnter} onMouseLeave={handleCategoryOnMouseLeave}
                                    onClick={removeShopOverviewDropdownShadow}>
                                    <Link to={"/"} className="menu-list-item-heading" 
                                        onClick={(e:React.MouseEvent)=>{
                                            //hideMobileDropdownMenu();
                                            (typeof loadNavLink !== 'undefined') ? loadNavLink!(e, '/') : loadNavLinkHelper(e, '/', navigate)}}>
                                        Pharmacy
                                        {/*<i className="fa fa-angle-down menu-list-angle-down" aria-hidden="true"></i>*/}
                                        <svg className="menu-list-item-heading-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" ><path fill="#fff" fillRule="evenodd" d="M1.65 4.24C1.232 3.882.6 3.93.24 4.35c-.359.419-.31 1.05.11 1.41l7 6c.374.32.926.32 1.3 0l7-6c.42-.36.469-.991.11-1.41-.36-.42-.992-.469-1.41-.11L8 9.684 1.65 4.24z" clipRule="evenodd"></path></svg>
                                    </Link>
                                    <DropdownMenuCategories ref={pharmacyDropdownMenuListContainer} dropdownMenuListHeading={'shop'} 
                                        dropdownCategories={pharmacyDropdownCategories}
                                        loadNavLink={loadNavLink}
                                        />
                                </li>
                            </ul>
                
                    <div className={`${backgroundShadeContainerMobile}`}></div>
                    <ul className="menu-options-list">
                        <li className="menu-options-list-item">
                            <Link to={"/whats-new"} className="mn2-menu-option-link" onClick={(e:React.MouseEvent)=>{
                                //hideMobileDropdownMenu();
                                navbarLinkHandler(e, 'whats-new')}}>What's New</Link>
                        </li>
 
                        <li className="menu-options-list-item">
                            {/*previous link to admin before adding login */}
                            <Link to={"/admin"} className="mn2-menu-option-link" onClick={(e:React.MouseEvent)=>navbarLinkHandler(e, 'admin')}>Admin Tools</Link>
                            {/*<button type="button" className="mn2-menu-option-btn" onClick={adminToolsLoginHandler}>Admin Tools</button>*/}
                            <button className="lock-container" onClick={adminLogoutHandler}>
                            {
                                adminLoggedIn
                                ?
                                <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                                :
                                <i className="fa fa-lock" aria-hidden="true"></i>
                            }
                            </button>
                            {/*<svg className="menu-list-item-heading-svg admin-login-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path fill="#fff" fillRule="evenodd" d="M1.65 4.24C1.232 3.882.6 3.93.24 4.35c-.359.419-.31 1.05.11 1.41l7 6c.374.32.926.32 1.3 0l7-6c.42-.36.469-.991.11-1.41-.36-.42-.992-.469-1.41-.11L8 9.684 1.65 4.24z" clipRule="evenodd"></path></svg>*/}
                            {/* showAdminToolsDropdown ? <AdminLoginDropdown contentType={"adminTools"} ref={adminLoginDropdownRef} showMobile={false}/> : null */}
                        </li>

                        <li className="menu-options-list-item">
                            {/*<Link to="/store-locator" className="" onClick={(e:React.MouseEvent)=>
                                                                                                    //handleGetCustomerLocation(e, storeSearchParams as StoreSearchParams, addStoreSearchParams as (storeSearchParams: StoreSearchParams) => AddStoreSearchParams)
                                                                                                    handleGetCustomerLocation(e, storeSearchParams as StoreSearchParams, addStoreSearchParams!)
                                                                                                }>Find a Store</Link>*/}
                            <Link to={"/store-locator"} className="mn2-menu-option-link" onClick={(e:React.MouseEvent)=>{
                                navbarLinkHandler(e, 'storeLocator')}}>Find a Store</Link>
                                {/*<button type="button" className="mn2-menu-option-btn" onClick={adminToolsLoginHandler}>Find a Store</button>*/}
                                <button className="lock-container" onClick={adminLogoutHandler}>
                                {
                                    adminLoggedIn
                                    ?
                                    <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                                    :
                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                }
                                </button>
                                
                                {/*<svg className="menu-list-item-heading-svg admin-login-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path fill="#fff" fillRule="evenodd" d="M1.65 4.24C1.232 3.882.6 3.93.24 4.35c-.359.419-.31 1.05.11 1.41l7 6c.374.32.926.32 1.3 0l7-6c.42-.36.469-.991.11-1.41-.36-.42-.992-.469-1.41-.11L8 9.684 1.65 4.24z" clipRule="evenodd"></path></svg>*/}
                                {/* showStoreLocatorDropdown ? <AdminLoginDropdown ref={adminLoginDropdownRef} contentType={"storeLocator"} showMobile={false}/> : null */}
                        </li>
                        {/*<li>
                            <Link to="/login" className="">Log in</Link>
                        </li>
                        <li className="register-or-list-item">
                            <Link to="/register" className="register-list-item-link">Register</Link>
                            <span className="or-list-item">or </span>
                            <Link to="/lists" className="">Lists</Link>
                        </li>
                        <li>
                            <Link to="/help" className="">Help</Link>
                        </li>*/}            
                    </ul>
                    {/*<div className={dropdownShadeContainer}></div>*/}
                    {/*<div className={keywordDropdownShadeContainer}></div>*/}
                </div>
                <div ref={dropdownShadeContainerRef} className={dropdownShadeContainer}></div>
            </div>
        </div>          
    );
}

export default MainNavbar2;
/*previous version with connect()
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainNavbar2);
*/
