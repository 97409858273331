    import StoreSearchParams from './storeSearchParams';
    import {navigate} from '@gatsbyjs/reach-router';
    import {AddStoreSearchParams} from './redux/actions';
    import { addStoreSearchParams } from './redux/features/storeSearchParams/storeSearchParamsSlice';
    import {useAppDispatch} from './redux/hooks';
    import { PayloadAction } from '@reduxjs/toolkit';
    //import {window} from './window';
    
    //finding customer location
    export default function handleGetCustomerLocation(e:React.MouseEvent, ssp:StoreSearchParams,
        dispatch:(action:PayloadAction<StoreSearchParams>)=>StoreSearchParams/*addStoreSearchParams:(storeSearchParams:StoreSearchParams)=>AddStoreSearchParams*/):void {
        e.preventDefault();
        //console.log('doc:', document);
        //console.log('nav.geo:', window.navigator.geolocation);
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(setUpShowCustomerPosition(ssp, dispatch/*addStoreSearchParams*/), setUpShowCustomerPositionError(ssp/*, addStoreSearchParams*/));
          } else {
            //console.log("Geolocation is not supported by this browser.");
          }
    }

    function setUpShowCustomerPosition(storeSearchParams:StoreSearchParams,
        dispatch:(action:PayloadAction<StoreSearchParams>)=>StoreSearchParams/*addStoreSearchParams:(storeSearchParams:StoreSearchParams)=>AddStoreSearchParams*/):(position:GeolocationPosition)=>void {

        return function showCustomerPosition(position:GeolocationPosition):void {
            //console.log('customer position:', position.coords);
            const {latitude, longitude} = position.coords;
            //set customer search parameters
            (storeSearchParams as StoreSearchParams).lat = latitude;
            (storeSearchParams as StoreSearchParams).lng = longitude;
            (storeSearchParams as StoreSearchParams).currentLocationRequest = true;
            (storeSearchParams as StoreSearchParams).storeSearch = 'current location';
            (storeSearchParams as StoreSearchParams).showStoreLocator = false;
            dispatch(addStoreSearchParams(storeSearchParams));
            //previous version
            //(addStoreSearchParams as (storeSearchParams:StoreSearchParams)=>AddStoreSearchParams)(storeSearchParams as StoreSearchParams);
            //original version
            //navigate(`/store-locator/${customerLocation.latitude},${customerLocation.longitude}`);
            navigate('/store-locator');
    
        }
    }

    function setUpShowCustomerPositionError(storeSearchParams:StoreSearchParams/*,
                            addStoreSearchParams:(storeSearchParams:StoreSearchParams)=>AddStoreSearchParams*/):(positionError:GeolocationPositionError)=>void {

        return function showCustomerPositionError(positionError:GeolocationPositionError):void {
            //assign default location
            switch(positionError.code) {
                case 1:
                    //console.log("User denied the request for Geolocation.");
                    navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
                    break;
                case 2:
                    //console.log("Location information is unavailable.");
                    navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
                    break;
                case 3:
                    //console.log("The request to get user location timed out.");
                    navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
                  break;
                default:
                    //console.log("An unknown error occurred.");
                    navigate(`/store-locator/${(storeSearchParams as StoreSearchParams).defaultLocation}`);
                    break;
              }
            
            //log positionError message
            //console.log('positionError message:', positionError.message);
        }
    
    }

