import React, {useState, useEffect} from 'react';
import SearchFilter, {SearchFilterGroup} from './searchFilter';
import formatSearchFilterName from './helper-format-search-filter-name';
import './ExistingSGCheckboxControl.css';


interface Props {
    options:SearchFilterGroup[];
    title:string;
    handleOptionNameSelection:(e:React.ChangeEvent)=>void;
    getFormControlMeasurement?:(e:HTMLDivElement | null)=>void;
}

const ExistingSGCheckboxControl: React.FunctionComponent<Props> = (props:Props) => {
    const {options, title, getFormControlMeasurement, handleOptionNameSelection} = props;
    //console.log('options:', options);


    function setupESGCheckboxControls(options:SearchFilterGroup[]):JSX.Element[] {
        return options.map((sg, i)=>{
                    return  <div key={i} ref={getFormControlMeasurement} className="add-product-checkbox-form-group e-sfat">
                                <div className="search-filter-group existing-sfg">
                                    <input type="checkbox" className="admin-checkbox-control" id={`existing-${sg.groupCategoryName}`}
                                        data-search-group-category-name={`${sg.groupCategoryName}`} 
                                        data-search-group-filter-name={`${sg.groupFilterName}`}
                                        data-search-type="Group Filter Name"
                                        name="checkbox-search-filter-group-name" value={`${sg.groupFilterName}`} onChange={handleOptionNameSelection}/>
                                    <label className="search-filter-group-label" htmlFor={`existing-${sg.groupCategoryName}`}>{sg.groupFilterName}</label>
                                    <span className="search-filter-group-category-name">({sg.groupCategoryName})</span>
                                </div>
                                {
                                    sg.searchFilters.map((sf, j)=>{
                                        return  <div key={j} ref={getFormControlMeasurement} className={`search-filter-group-search-filters e-sfg existing-${sg.groupFilterName}`}>
                                                    <div className="search-filter existing-sf">
                                                        <input type="checkbox" className="admin-checkbox-control" id={`existing-${sf.searchFilterId} ${sf.filterName}`}
                                                            data-search-group-category-name={`${sg.groupCategoryName}`} 
                                                            data-search-group-filter-name={`${sg.groupFilterName}`}
                                                            data-search-filter-id={`${sf.searchFilterId}`}
                                                            data-search-type="Search Filter"
                                                        name={`existing-${sg.groupCategoryName} Search Filter`} value={`${sf.filterName}`} onChange={handleOptionNameSelection}/>
                                                        <label className="search-filter-group-label" htmlFor={`existing-${sf.searchFilterId} ${sf.filterName}`}>{sf.filterName}</label>
                                                    </div>
                                                    {
                                                        sf.searchTerms.map((st, k)=>{
                                                            return  <div key={k} className={`search-filter-group-search-terms stg existing-${formatSearchFilterName(sf.filterName, sg.groupFilterName)}`}>
                                                                        <div className="search-term existing-st">
                                                                            <input type="checkbox" className="admin-checkbox-control" id={`existing-${st.searchTermId} ${st.searchTermName}`}
                                                                                data-search-group-category-name={`${sg.groupCategoryName}`}
                                                                                data-search-group-filter-name={`${sg.groupFilterName}`}
                                                                                data-search-filter-name={`${sf.filterName}`}
                                                                                data-search-term-id={`${st.searchTermId}`}
                                                                                data-search-type="Search Term"
                                                                             name={`existing-${sg.groupCategoryName} Search Term`} value={`${st.searchTermName}`} onChange={handleOptionNameSelection}/>
                                                                            <label className="search-filter-group-label" htmlFor={`existing-${st.searchTermId} ${st.searchTermName}`}>{st.searchTermName}</label> 
                                                                        </div>
                                                                    </div>
                                                        })
                                                    }
                                                </div>
                                    })
                                }
                            </div>
                });
    }

    if(options.length > 0){
        return (
            <fieldset>
                <legend>{title}</legend>
                {setupESGCheckboxControls(options as SearchFilterGroup[])}
            </fieldset>
        );

    } else {
        return null;
    }

}


export default ExistingSGCheckboxControl;