import React, {useEffect, useState, useRef} from 'react';
import './GenericErrorMessage.css';

interface Props {
    msg?:string;
}

const GenericErrorMessage:React.FunctionComponent<Props> = (props:Props)=>{
    const {msg} = props;

    return (<div className="generic-error-msg-container">
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" 
                    aria-hidden="true" className="generic-error-msg">
                    <path fill="currentColor" d="M1024 1375v-190q0-14-9.5-23.5T992 1152H800q-13 0-22.5 9.5T768 1185v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11H786q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17H128q-34 0-63.5-17T18 1601q-37-63-2-126L784 67q17-31 47-49t65-18 65 18 47 49z"></path>
                </svg>
                {
                    (typeof msg !== 'undefined')
                    ?
                    <p className="generic-error-msg-text">{msg}</p>
                    :
                    <p className="generic-error-msg-text">An unexpected error has occurred. Please retry your previous action, and if the error persists try again later or contact customer service.</p>
                }
                
            </div>);
}


export default GenericErrorMessage;