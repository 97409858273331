import React, {useImperativeHandle, useRef, forwardRef, useEffect} from 'react';
import SearchFilter, {SearchFilterGroup} from './searchFilter';
import ProductVerification from './productVerification';
import ProductPromotion from './productPromotion';
import './FormErrorMessage.css';

export interface FormErrorType {
            area:string;
            hasError:boolean;
        }

export interface FormError {
            productInfoErrors:FormErrorType[];
            pricingErrors:FormErrorType[];
            imageErrors:FormErrorType[];
            searchFilterGroupError:FormErrorType;
            existingSearchFilterGroupError:FormErrorType;
        }


interface Props {
    formError:FormError;
}

type Ref = {
    sfgemcRef:React.RefObject<HTMLDivElement>
};

const FormErrorMessage = forwardRef<Ref, Props>((props, ref)=>{
    //console.log('formErrorMesage');
    const {productInfoErrors:pies, pricingErrors:pes, imageErrors:ies, searchFilterGroupError:sfge, existingSearchFilterGroupError:esfge} = props.formError;
    //console.log('sfge errors:', sfge);
    //console.log('esfge errors:', esfge);
    const sfgemcRef = useRef<HTMLDivElement>(null);

    const closeBtnContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, ()=>({
        
        get sfgemcRef(){
            return sfgemcRef;
        }
        
        })
    );

    useEffect(()=>{
        //console.log('wh:', window.innerHeight);
        if(window.innerHeight < 500){
            closeBtnContainerRef.current!.classList.remove('form-error-msg-close-btn-container-reg');
            closeBtnContainerRef.current!.classList.add('form-error-msg-close-btn-container-sm');
        } 

        window.addEventListener('resize', formErrorMessageResizeHandler);
        window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);
        return cleanupFormErrorMessageResizeHandler;
    },[]);

    function formErrorMessageResizeHandler(e:Event):void {
        //console.log('eh:', sfgemcRef.current!.offsetHeight, window.innerHeight);

        if(sfgemcRef.current!.getBoundingClientRect().height < window.innerHeight){
            sfgemcRef.current!.style.height = '';
            sfgemcRef.current!.style.overflowY = '';
            sfgemcRef.current!.style.overscrollBehaviorY = '';
        }

        if(sfgemcRef.current!.getBoundingClientRect().height > window.innerHeight){
            sfgemcRef.current!.style.height = `${window.innerHeight + 1}px`;
            sfgemcRef.current!.style.overflowY = 'scroll';
            sfgemcRef.current!.style.overscrollBehaviorY = 'contain';
        }

        if(window.innerHeight < 500){
            closeBtnContainerRef.current!.classList.remove('form-error-msg-close-btn-container-reg');
            closeBtnContainerRef.current!.classList.add('form-error-msg-close-btn-container-sm');

        } else {
            closeBtnContainerRef.current!.classList.remove('form-error-msg-close-btn-container-sm');
            closeBtnContainerRef.current!.classList.add('form-error-msg-close-btn-container-reg');
        }
    }

    function screenOrientationChangeHandler(e:Event):void {
        formErrorMessageResizeHandler(new Event('test'));
    }


    function cleanupFormErrorMessageResizeHandler():void {
        window.removeEventListener('resize', formErrorMessageResizeHandler);
        window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function closeFormErrorMessageHandler(e:React.MouseEvent):void {
        //console.log('eh:', sfgemcRef.current!.offsetHeight);
        const eh = sfgemcRef.current!.offsetHeight;
        sfgemcRef.current!.style.top = `-${eh + 60}px`;
    }

    function addProductInfoErrors(pies:FormErrorType[]):JSX.Element {

        return (
                <div className="form-error-message-list-container info">
                    <h2 className="form-error-message-list-title">Product Info</h2>
                    <ul className="form-error-message-list">
                        {pies.map((pie, k)=>{
                            return <li key={k} className="form-error-message-list-item pie">{pie.area}</li>
                        })}
                    </ul>
                </div>

                );

    }

    function addPricingErrors(pes:FormErrorType[]):JSX.Element {

        return (
                <div className="form-error-message-list-container">
                    <h2 className="form-error-message-list-title">Pricing</h2>
                    <ul className="form-error-message-list">
                            {pes.map((pe, k)=>{
                                return <li key={k} className="form-error-message-list-item pe">{pe.area}</li>
                            })}
                        </ul>
                </div>
                );

    }

    function addImageErrors(ies:FormErrorType[]):JSX.Element {

        return (
                <div className="form-error-message-list-container">
                    <h2 className="form-error-message-list-title">Images</h2>
                    <ul className="form-error-message-list">
                        {ies.map((ie, k)=>{
                            return <li key={k} className="form-error-message-list-item ie">{ie.area}</li>
                        })}
                    </ul>
                </div>
                );

    }

    function addSearchGroupHeading(type:string):string {
        return (type === 'existing')
                ? 'Existing Search Filter Groups'
                : 'Search Filter Groups';
    }

    function addSearchFilterGroupError(area:string, type:string):JSX.Element {

        switch(area){
            case 'searchFilter':
                return  <div className="form-error-message-list-container">
                            <h2 className="form-error-message-list-title">{addSearchGroupHeading(type)}</h2>
                            <ul className="form-error-message-list">
                                <li className="form-error-message-list-item sf">search filter</li>
                            </ul>
                        </div>

            case 'searchTerm':
                return  <div className="form-error-message-list-container">
                            <h2 className="form-error-message-list-title">{addSearchGroupHeading(type)}</h2>
                            <ul className="form-error-message-list">
                                <li className="form-error-message-list-item st">search term</li>
                            </ul>
                        </div>
            case 'both':
                return  <div className="form-error-message-list-container">
                            <h2 className="form-error-message-list-title">{addSearchGroupHeading(type)}</h2>
                            <ul className="form-error-message-list">
                                <li className="form-error-message-list-item sf">search filter</li>
                                <li className="form-error-message-list-item st">search term</li>
                            </ul>
                        </div>
            default:
                return  <div>No Items</div>
        }

    }

    return (
        <div ref={sfgemcRef} className="form-error-message-container">
            <h1 className="form-error-message-title">Missed Items</h1>
            <div className="form-error-message-list-container-wrapper">
                {(pies.length > 0) ? addProductInfoErrors(pies) : null}
                {(pes.length > 0) ? addPricingErrors(pes) : null}
                {(ies.length > 0) ? addImageErrors(ies) : null}
                {sfge.hasError ? addSearchFilterGroupError(sfge.area, 'available') : null}
                {esfge.hasError ? addSearchFilterGroupError(esfge.area, 'existing') : null}
            </div>
            <div ref={closeBtnContainerRef} className="form-error-msg-close-btn-container-reg">
                <button type="button" className="form-error-msg-close-btn"
                    onClick={closeFormErrorMessageHandler}>Close</button>
            </div>
        </div>
    );



});

export default FormErrorMessage;