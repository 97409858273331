import React, {useState, useEffect, useRef} from 'react';
import AddShopProductReview from './AddShopProductReview';
import ShopProductReviewsDetails from './ShopProductReviewsDetails';
import Review from './review';
import CustomerRelation from './customerRelation';
import {addReviews} from './helper-add-reviews';
import Product from './product';
import getApprovedProductReviews from './helper-get-approved-product-reviews';
import GenericErrorMessage from './GenericErrorMessage';
import './RelatedProducts.css';
import './ShopProductReviews.css';

interface Props {
    //original version
    /*productName:string;
    productCategoryName:string;*/
    productSelected:Product;
}

const ShopProductReviews:React.FunctionComponent<Props> = (props:Props) => {
    const [userName, setUserName] = useState('tim');
    //original version
    //const [productName, setProductName] = useState(props.productName);
    //const [productCategoryName, setProductCategoryName] = useState(props.productCategoryName);
    const [productSelected, setProductSelected] = useState<Product>(props.productSelected);
    const [productReviews, setProductReviews] = useState<Review[] | []>([]);
    const [controller, setController] = useState<AbortController>(new AbortController());

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(()=>{
        /*
        fetch(`http://localhost:9500/product-reviews/${productCategoryName}/${productName}`, {
            headers:{
                'Accept':'application/json'
            }
        })
        */
       Promise.all([getApprovedProductReviews(productSelected.productId.toString(), controller.signal)])
        .then(([reviews])=>{
            if(reviews === null){
                throw new Error('get approved product reviews error');
            }
            //console.log('reviews test:', reviews);
            const productReviews = addReviews(reviews);
            ///console.log('productReviews:', productReviews);
            setProductReviews(productReviews);
        })
        .catch(err=>{
            //console.log('reviews error:', err);
            setShowErrorMessage(true);
        });

    },[]);

    useEffect(()=>{
        return function cleanUp(){
            controller.abort();
        }
    },[]);

    /*

    function addReviews(reviews:Review[]):Review[] {
        return reviews.map((review)=>{
                    return new Review({
                                userName:review.userName,
                                rating:review.rating,
                                title:review.title,
                                comment:review.comment,
                                approved:review.approved,
                                customerRelation:addCustomerRelation(review.customerRelation as CustomerRelation),
                                submitted:review.submitted,
                                productName:review.productName,
                                productCategoryName:review.productCategoryName
                            });
                });
    }

    function addCustomerRelation(customerRelation:CustomerRelation):CustomerRelation | null {

        if(customerRelation === null){
            return null;
        } else {
            return new CustomerRelation({
                response:customerRelation.response,
                responseDate:customerRelation.responseDate
            });
        }

    }
    */

    return <div id="reviews-container" className="shop-product-reviews-container">
                <div className="shop-product-reviews-border-container">
                    <div className="shop-product-reviews-heading-container">
                        <h2 className="shop-product-reviews-heading">Reviews</h2> 
                    </div>
                    {
                        showErrorMessage
                        ?
                        <div className="hp-generic-error-msg-container">
                            <GenericErrorMessage/>
                        </div>
                        :
                        <div className="shop-product-reviews-details-container">
                            {/*<AddShopProductReview userName={userName} 
                                productCategoryName={productCategoryName} productName={productName}/>*/}
                            <AddShopProductReview userName={userName} productSelected={productSelected}/>
                            <ShopProductReviewsDetails productReviews={productReviews} />
                        </div>
                    }
                </div>
           </div>
}

export default ShopProductReviews;