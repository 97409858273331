import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import ShopCategory from './shopCategory';
import DropdownMenuCategories from './DropdownMenuCatetories';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import SubShopCategory from './subShopCategory';
import {selectProductShopCategories, addProductShopCategories} from './redux/features/productShopCategories/productShopCategoriesSlice';
import {selectHighlightBackground} from './redux/features/highlightBackground/highlightBackgroundSlice';
import {selectMobileNav, showMobileNav} from './redux/features/mobileNav/mobileNavSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import loadNavLinkHelper from './loadNavLinkHelper';
import './MainNavbar.css';


interface Props {
    loadNavLink?:(e:React.MouseEvent, pathname:string)=>void ;
}

type Ref = {menuListMobileNavRef:HTMLElement, menuListNavWrapperRef:HTMLDivElement, backgroundShadeContainerRef:HTMLDivElement};


//const MenuListMobileNav:React.FunctionComponent<Props> = (props: Props) => {
const MenuListMobileNav = forwardRef<Ref, Props>((props, ref)=> {
    //console.log('main navbar');
    //const {addShopLoadDetails, storeSearchParams, addStoreSearchParams} = props;
    const {loadNavLink} = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const productShopCategoriesRedux = useAppSelector(selectProductShopCategories);
    //const highlightBackgroundRedux = useAppSelector(selectHighlightBackground);
    const showMobileNavRedux = useAppSelector(selectMobileNav);
    //console.log('smnr:', showMobileNavRedux);
    const [dropdownShadeContainer, setDropdownShadeContainer] = useState('background-shade-container-hide');
    const [keywordDropdownShadeContainer, setKeywordDropdownShadeContainer] = useState('keyword-background-shade-container-hide');
    const [backgroundShadeContainerMobile, setBackgroundShadeContainerMobile] = useState('background-shade-container-mobile-hide');
    const shopDropdownMenuListContainer = useRef<HTMLDivElement>(null);
    const pharmacyDropdownMenuListContainer = useRef<HTMLDivElement>(null);
    const [shopDropdownCategories, setShopDropdownCategories] = useState<ShopCategory[]>([]);
    const [pharmacyDropdownCategories, setPharmacyDropdownCategories] = useState<ShopCategory[]>([]);
    
    const mobileNavOpen = useRef<boolean>(false);
    const isMobile = useRef<boolean>(false);
    const menuListMobileNavRef = useRef<HTMLElement>(null);
    const menuListNavRef = useRef<HTMLUListElement>(null);
    const menuListNavWrapperRef = useRef<HTMLDivElement>(null);
    const backgroundShadeContainerRef = useRef<HTMLDivElement>(null);

    const portrait = useRef<MediaQueryList[]>([]);


    useImperativeHandle(ref, ()=>({menuListMobileNavRef:menuListMobileNavRef.current!, 
        menuListNavWrapperRef:menuListNavWrapperRef.current!,
        backgroundShadeContainerRef:backgroundShadeContainerRef.current!}));
                   

    //console.log('sdcs:', shopDropdownCategories);

    useEffect(()=>{
        //get shop categories and sub shop categories
        //console.log('tests:');
        Promise.all([getProductShopCategoriesPromise(),getPharmacyShopCategoriesPromise()])
        .then(([productShopCategories, pharmacyShopCategories])=>{
            //console.log('productShopCategories:', productShopCategories);
            //console.log('pharmacyShopCategories:', pharmacyShopCategories);
            setShopDropdownCategories([...productShopCategories]);
            dispatch(addProductShopCategories([...productShopCategories]));
            setPharmacyDropdownCategories([...pharmacyShopCategories]);
        })
        .catch((err)=>console.error('Promise.all() fetch shop categories error:', err));


    },[]);


    useEffect(()=>{
        //console.log('r28:');
        //console.log('showMobile:', showMobileNavRedux);
        mobileNavOpen.current = showMobileNavRedux.showMobileNav;

        if(showMobileNavRedux.closeShopByTypeNav){
            return;
        }


        if(showMobileNavRedux.closeMobileNavImmediately){
            backgroundShadeContainerRef.current!.classList.remove('background-shade-container-mobile');
            setBackgroundShadeContainerMobile('background-shade-container-mobile-hide');
            //console.log('mlmnr:', menuListMobileNavRef.current);
            menuListMobileNavRef.current!.style.transition = 'transform 0s ease';
            menuListMobileNavRef.current!.style.transform = 'translateX(0px)';

            menuListNavWrapperRef.current!.style.width = '0%';
            
        } else if(showMobileNavRedux.showMobileNav && !showMobileNavRedux.linkSelected){
            //mobile nav opened but no link was selected
            backgroundShadeContainerRef.current!.classList.remove('background-shade-container-mobile-hide');
            setBackgroundShadeContainerMobile('background-shade-container-mobile');
            //console.log('mlmnr:', menuListMobileNavRef.current);
            menuListMobileNavRef.current!.style.transition = 'transform .5s ease';
            menuListMobileNavRef.current!.style.transform = 'translateX(100%)';

            menuListNavWrapperRef.current!.style.width = '100%';

        } else if(!showMobileNavRedux.showMobileNav && !showMobileNavRedux.linkSelected) {
            //mobile nav closed but no link was selected
            backgroundShadeContainerRef.current!.classList.remove('background-shade-container-mobile');
            setBackgroundShadeContainerMobile('background-shade-container-mobile-hide');
            //console.log('mlmnr:', menuListMobileNavRef.current);
            menuListMobileNavRef.current!.style.transition = 'transform .5s ease';
            menuListMobileNavRef.current!.style.transform = 'translateX(0px)';

            menuListNavWrapperRef.current!.style.width = '0%';

        } else {
            //mobile nav closed and link was selected
            backgroundShadeContainerRef.current!.classList.remove('background-shade-container-mobile');
            setBackgroundShadeContainerMobile('background-shade-container-mobile-hide');
            //console.log('mlmnr:', menuListMobileNavRef.current);
            menuListMobileNavRef.current!.style.transition = 'transform 0s ease';
            menuListMobileNavRef.current!.style.transform = 'translateX(0px)';

            menuListNavWrapperRef.current!.style.width = '0%';

        }
        

    },[showMobileNavRedux.showMobileNav]);


    useEffect(()=>{
        //setShopDropdownCategories([...props.productShopCategories]);
        setShopDropdownCategories([...productShopCategoriesRedux.productShopCategories]);
    },[productShopCategoriesRedux.productShopCategories/*props.productShopCategories*/]);


    /*
    useEffect(()=>{
        if(highlightBackgroundRedux.highlightBackground.highlightBackground){
            setKeywordDropdownShadeContainer('keyword-background-shade-container');

        } else {
            setKeywordDropdownShadeContainer('keyword-background-shade-container-hide');
        }
        //setHighlightBackground(props.highlightBackground.highlightBackground);
        setHighlightBackground(highlightBackgroundRedux.highlightBackground.highlightBackground);

    },[highlightBackgroundRedux.highlightBackground.highlightBackground]);
    */

    async function getPharmacyShopCategoriesPromise():Promise<ShopCategory[]>{
        let pscs:ShopCategory[] = [];
        try {
            const resp = await fetch(`https://server.kando-proto-3.com/get-pharmacy-shop-categories-mariadb`, {
                                    method:'GET',
                                    headers:{
                                        'Accept':'application/json'
                                    }
                                });

            pscs = await resp.json();            

        } catch(error){
            console.error('pharmacy shop categories fetch error:', error);
        } finally {
            return addShopCategories(pscs);
        }
    }

    async function getProductShopCategoriesPromise():Promise<ShopCategory[]>{
        let pscs:ShopCategory[] = [];
        try {
            const resp = await fetch(`https://server.kando-proto-3.com/get-product-shop-categories-mariadb`, {
                                    method:'GET',
                                    headers:{
                                        'Accept':'application/json'
                                    }
                                });

            pscs = await resp.json();


        } catch(error){
            console.error('product shop categories fetch error:', error);
        } finally {
            return addShopCategories(pscs);
        }
    }

    useEffect(()=>{

        window.addEventListener('resize', resizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", resizeHandler);

        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupResizeHandler;
    },[]);


    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);

        portrait.current[0].removeEventListener("change", resizeHandler);

        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        resizeHandler(new Event('test'));
    }


    function resizeHandler(e:Event):void {
        //console.log('mn2 resize:');
        const showMobile = (window.innerWidth >= 1024) ? false : true;

        //console.log('showMobile:', showMobile, mobileNavOpen);

        if(showMobile && mobileNavOpen.current){
            //console.log('showMobile:', showMobile);
            setBackgroundShadeContainerMobile('background-shade-container-mobile');
            menuListMobileNavRef.current!.style.transform = 'translateX(100%)';
            menuListNavWrapperRef.current!.style.width = '100%';

        } 
        
        if(!showMobile && mobileNavOpen.current) {
            //console.log('showMobile:', showMobile);
            setBackgroundShadeContainerMobile('background-shade-container-mobile-hide');
            menuListMobileNavRef.current!.style.transform = 'translateX(0px)';
            menuListNavWrapperRef.current!.style.width = '';
        }


        
    }


    function addShopCategories(shopCategories:ShopCategory[]):ShopCategory[]{
        return shopCategories.map((sc)=>{
                    return new ShopCategory({
                                shopCategoryId:sc.shopCategoryId,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryLink:sc.shopCategoryLink,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                            });
                });
    }

    function addSubShopCategories(subShopCategories:SubShopCategory[]):SubShopCategory[]{
        return subShopCategories.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });
    }

    function closeNavMenu(e:React.MouseEvent, pathname:string):void {
        //console.log('e.target:', e.target);
        e.stopPropagation();
        
        if(typeof loadNavLink !== 'undefined'){
            loadNavLink(e, pathname);

        } else {
            loadNavLinkHelper(e, pathname, navigate);
        }
        
        dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));
    }

    function closeAllExistingLists(ele:HTMLElement):void {
        const t = (ele as HTMLElement).closest('.menu-list-mobile-nav') as HTMLUListElement;
        //console.log('test7:', t, t.getElementsByClassName('show-smdsmlc'));
        const tels = Array.from(t.getElementsByClassName('show-smdsmlc'));
        tels.forEach((tel)=>{
            tel.classList.remove('show-smdsmlc');
            const ae = tel.previousElementSibling as HTMLAnchorElement;
            const aeChildSVG = Array.from(ae.getElementsByClassName('rotate-svg-dropdown-arrow'))[0] as SVGElement;
            aeChildSVG.classList.remove('rotate-svg-dropdown-arrow');
        });
    }


    return (
        <div ref={menuListNavWrapperRef} className="menu-list-mobile-nav-wrapper">
            <div className="menu-list-mobile-nav-wrapper-background">
                <div className="menu-list-mobile-nav-container">
                         <nav ref={menuListMobileNavRef} className="menu-list-mobile-nav">
                                <ul className="menu-list-options-mobile">
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/"} className="menu-list-options-item-link-mobile"
                                            onClick={(e:React.MouseEvent)=>{
                                                closeAllExistingLists(e.target as HTMLElement);
                                                closeNavMenu(e, '/whats-new')}}>
                                            {/*<svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" ><path fill="currentColor" d="M1024 452l316 316-572 572-316-316zm-211 979l618-618q19-19 19-45t-19-45l-362-362q-18-18-45-18t-45 18l-618 618q-19 19-19 45t19 45l362 362q18 18 45 18t45-18zm889-637l-907 908q-37 37-90.5 37t-90.5-37l-126-126q56-56 56-136t-56-136-136-56-136 56l-125-126q-37-37-37-90.5t37-90.5l907-906q37-37 90.5-37t90.5 37l125 125q-56 56-56 136t56 136 136 56 136-56l126 125q37 37 37 90.5t-37 90.5z"></path></svg>*/}
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1024 1376v-192q0-14-9-23t-23-9H800q-14 0-23 9t-9 23v192q0 14 9 23t23 9h192q14 0 23-9t9-23zm256-672q0-88-55.5-163T1086 425t-170-41q-243 0-371 213-15 24 8 42l132 100q7 6 19 6 16 0 25-12 53-68 86-92 34-24 86-24 48 0 85.5 26t37.5 59q0 38-20 61t-68 45q-63 28-115.5 86.5T768 1020v36q0 14 9 23t23 9h192q14 0 23-9t9-23q0-19 21.5-49.5T1100 957q32-18 49-28.5t46-35 44.5-48 28-60.5 12.5-81zm384 192q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="currentColor"></path></svg>
                                            What's new
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/"} className="menu-list-options-item-link-mobile"
                                            onClick={(e:React.MouseEvent)=>{
                                                closeAllExistingLists(e.target as HTMLElement);
                                                closeNavMenu(e, '/')}}>
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M384 1184v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm1152 512v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm128 704v-832q0-13-9.5-22.5t-22.5-9.5h-1472q-13 0-22.5 9.5t-9.5 22.5v832q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm128-1088v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"></path></svg>
                                            Recipes
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/"} className="menu-list-options-item-link-mobile"
                                            onClick={(e:React.MouseEvent)=>{
                                                closeAllExistingLists(e.target as HTMLElement);
                                                closeNavMenu(e, '/')}}>
                                            <svg className="menu-list-options-item-link-svg" width="2048" height="1792" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M1024 512h-384v384h384v-384zm128 640v128h-640v-128h640zm0-768v640h-640v-640h640zm640 768v128h-512v-128h512zm0-256v128h-512v-128h512zm0-256v128h-512v-128h512zm0-256v128h-512v-128h512zm-1536 960v-960h-128v960q0 26 19 45t45 19 45-19 19-45zm1664 0v-1088h-1536v1088q0 33-11 64h1483q26 0 45-19t19-45zm128-1216v1216q0 80-56 136t-136 56h-1664q-80 0-136-56t-56-136v-1088h256v-128h1792z"></path></svg>
                                            Weekly Ad
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/"} className="menu-list-options-item-link-mobile"
                                            onClick={(e:React.MouseEvent)=>{
                                                closeAllExistingLists(e.target as HTMLElement);
                                                closeNavMenu(e, '/')}}>
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" ><path fill="currentColor" d="M1024 452l316 316-572 572-316-316zm-211 979l618-618q19-19 19-45t-19-45l-362-362q-18-18-45-18t-45 18l-618 618q-19 19-19 45t19 45l362 362q18 18 45 18t45-18zm889-637l-907 908q-37 37-90.5 37t-90.5-37l-126-126q56-56 56-136t-56-136-136-56-136 56l-125-126q-37-37-37-90.5t37-90.5l907-906q37-37 90.5-37t90.5 37l125 125q-56 56-56 136t56 136 136 56 136-56l126 125q37 37 37 90.5t-37 90.5z"></path></svg>
                                            Coupons
                                        </Link>
                                    </li>
                                    {/*<li className="menu-list-options-item-mobile">
                                        <Link to={"/admin"} onClick={(e:React.MouseEvent)=>navbarLinkHandler(e, 'admin')} className="menu-list-options-item-link-mobile">
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M384 1184v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm1152 512v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm128 704v-832q0-13-9.5-22.5t-22.5-9.5h-1472q-13 0-22.5 9.5t-9.5 22.5v832q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm128-1088v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"></path></svg>
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M576 1344q0 26-19 45t-45 19-45-19-19-45 19-45 45-19 45 19 19 45zm1024 61q0 121-73 190t-194 69h-874q-121 0-194-69t-73-190q0-68 5.5-131t24-138 47.5-132.5 81-103 120-60.5q-22 52-22 120v203q-58 20-93 70t-35 111q0 80 56 136t136 56 136-56 56-136q0-61-35.5-111t-92.5-70v-203q0-62 25-93 132 104 295 104t295-104q25 31 25 93v64q-106 0-181 75t-75 181v89q-32 29-32 71 0 40 28 68t68 28 68-28 28-68q0-42-32-71v-89q0-52 38-90t90-38 90 38 38 90v89q-32 29-32 71 0 40 28 68t68 28 68-28 28-68q0-42-32-71v-89q0-68-34.5-127.5t-93.5-93.5q0-10 .5-42.5t0-48-2.5-41.5-7-47-13-40q68 15 120 60.5t81 103 47.5 132.5 24 138 5.5 131zm-320-893q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z"></path></svg>
                                            Admin
                                        </Link>
                                    </li>
                                    <li className="menu-list-options-item-mobile">
                                        <Link to={"/store-locator"} onClick={(e:React.MouseEvent)=>navbarLinkHandler(e, 'storeLocator')} className="menu-list-options-item-link-mobile">
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M384 1184v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-64q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm1152 512v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5t-22.5 9.5h-960q-13 0-22.5-9.5t-9.5-22.5v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm128 704v-832q0-13-9.5-22.5t-22.5-9.5h-1472q-13 0-22.5 9.5t-9.5 22.5v832q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm128-1088v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"></path></svg>
                                            <svg className="menu-list-options-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1024 1376v-192q0-14-9-23t-23-9H800q-14 0-23 9t-9 23v192q0 14 9 23t23 9h192q14 0 23-9t9-23zm256-672q0-88-55.5-163T1086 425t-170-41q-243 0-371 213-15 24 8 42l132 100q7 6 19 6 16 0 25-12 53-68 86-92 34-24 86-24 48 0 85.5 26t37.5 59q0 38-20 61t-68 45q-63 28-115.5 86.5T768 1020v36q0 14 9 23t23 9h192q14 0 23-9t9-23q0-19 21.5-49.5T1100 957q32-18 49-28.5t46-35 44.5-48 28-60.5 12.5-81zm384 192q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="currentColor"></path></svg>
                                            Find a Store
                                        </Link>
                                    </li>*/}
                                </ul>
                                <ul className="menu-list-mobile">
                                    <li className="shop-mobile-menu-list-item">
                                        <Link to="/shop" className="shop-mobile-menu-list-item-heading-link"
                                            onClick={(e:React.MouseEvent)=>{
                                                closeAllExistingLists(e.target as HTMLElement);
                                                closeNavMenu(e, '/shop/overview')}}>
                                            Shop
                                            {/*<i className="fa fa-angle-down menu-list-angle-down" aria-hidden="true"></i>*/}
                                        </Link>
                                        <DropdownMenuCategories ref={shopDropdownMenuListContainer} dropdownMenuListHeading={'shop-mobile'} 
                                            dropdownCategories={shopDropdownCategories}
                                            loadNavLink={loadNavLink}
                                            />
                                    </li>
                                    <li className="shop-mobile-menu-list-item">
                                        <Link to={"/"} className="shop-mobile-menu-list-item-heading-link"
                                            onClick={(e:React.MouseEvent)=>{
                                                closeAllExistingLists(e.target as HTMLElement);
                                                closeNavMenu(e, '/')}}>
                                            Pharmacy
                                            {/*<i className="fa fa-angle-down menu-list-angle-down" aria-hidden="true"></i>*/}
                                        </Link>
                                        <DropdownMenuCategories ref={pharmacyDropdownMenuListContainer} dropdownMenuListHeading={'shop-mobile'}
                                            dropdownCategories={pharmacyDropdownCategories}
                                            loadNavLink={loadNavLink}
                                            />
                                    </li>
                                </ul>
                            </nav>
                    <div ref={backgroundShadeContainerRef} className={`${backgroundShadeContainerMobile}`}></div>
                    {/*<div className={dropdownShadeContainer}></div>*/}
                </div>
            </div>
        </div>          
    );

});

export default MenuListMobileNav;
