import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {Link, Router} from '@gatsbyjs/reach-router';
import './RelatedProducts.css';
import Product from './product';
import Review from './review';
import ShopProductReviews from './ShopProductReviews';
import getApprovedProductReviews from './helper-get-approved-product-reviews';
import {addReviews} from './helper-add-reviews';
import ShopProductGroupRating from './ShopProductGroupRating';
import ProductDescription from './productDescription';
import NutritionFacts, {NutritionServing, VitaminServing} from './nutritionFacts';
import ProductZoomImage from './productZoomImage';
import ShopProductDetailCheckoutBtn from './ShopProductDetailCheckoutBtn';
import ProductQtyLimitErrorMessage from './ProductQtyLimitErrorMessage';
import ShoppingCartItemLimitErrorMessage from './ShoppingCartItemLimitErrorMessage';
import './ShopProductDesktopLayout.css';

import ImageZoomMulti from './ImageZoomMulti';
import ImageZoomMulti2 from './ImageZoomMulti2';
import ImageZoom from './ImageZoom';

interface Props {
    productSelected:Product;
    productReviews:Review[];
}

const ShopProductDekstopLayout: React.FunctionComponent<Props> = (props:Props) => {
    const [productSelected, setProductSelected] = useState<Product>(props.productSelected);
    const [productReviews, setProductReviews] = useState<Review[]>(props.productReviews);
    const [showNutritionListContent, setShowNutritionListContent] = useState(true);
    const closeNutritionListIcon = useRef<HTMLElement>(null);
    const openNutritionListIcon = useRef<HTMLElement>(null);
    const [showProductProducts, setShowProductProducts] = useState(false);

    //console.log('ps226:', productSelected);


    function addProductSalePriceLabel(product:Product):JSX.Element | null {
        if(product.weightBy === 'each' && product.showSalePrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)}/lb`;
            return <div className="shop-product-overview-sale-price">
                        ${product.eachPrice.toFixed(2)} each
                    </div>;

        } else if(product.weightBy === 'pound' && product.showSalePrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)}/lb`;
            return <div className="shop-product-overview-sale-price">
                        ${product.salePrice.toFixed(2)} lb
                    </div>;

        } else {
            return null;
        }
    }

    function addProductListPriceLabel(product:Product):JSX.Element | null{
        //console.log('p:', product);
        if(product.weightBy === 'each' && product.showSalePrice && product.showListPrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)} each`;
            return <div className="shop-product-overview-list-price-slashed">
                        ${product.eachPrice.toFixed(2)} each
                    </div>;

        } else if(product.weightBy === 'pound' && product.showSalePrice && product.showListPrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)}/lb`;
            return <div className="shop-product-overview-list-price-slashed">
                        ${product.eachPrice.toFixed(2)} lb
                    </div>;
        } else if(product.weightBy === 'each' && !product.showSalePrice && !product.showListPrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)} each`;
            return <div className="shop-product-overview-list-price">
                        ${product.eachPrice.toFixed(2)}
                    </div>;

        } else if(product.weightBy === 'pound' && !product.showSalePrice && !product.showListPrice){
            //console.log('passed');
            //original version
            //return `$${product.eachPrice.toFixed(2)}/lb`;
            return <div className="shop-product-overview-list-price">
                        ${product.eachPrice.toFixed(2)} lb
                    </div>;
        } else {
            return null;
        }
    }

    function addProductUnitOfMeasureSalePriceLabel(product:Product):JSX.Element | null {
        if(product.weightBy === 'each' && product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-overview-unit-of-measure-sale-price">
                        (${product.unitOfMeasureSalePrice.toFixed(2)}/oz)
                    </div>;

        } else if(product.weightBy === 'each' && product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'lb'){
            return <div className="shop-product-overview-unit-of-measure-sale-price">
                        (${product.unitOfMeasureSalePrice.toFixed(2)}/lb)
                    </div>;

        } else if(product.weightBy === 'pound' && product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-overview-unit-of-measure-sale-price">
                        (${product.unitOfMeasureSalePrice.toFixed(2)}/oz)
                    </div>;
        }  else if(product.weightBy === 'each' && product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'ct'){
            return <div className="shop-product-overview-unit-of-measure-sale-price">
                        (${product.unitOfMeasureSalePrice.toFixed(2)}/ct)
                    </div>;
        } else {
            return null;

        } 
    }

    function addProductUnitOfMeasureListPriceLabel(product:Product):JSX.Element | null {
        if(product.weightBy === 'each' && product.showSalePrice && product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-overview-unit-of-measure-list-price-slashed">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / oz)
                    </div>;

        } else if(product.weightBy === 'each' && product.showSalePrice && product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'lb'){
            return <div className="shop-product-overview-unit-of-measure-list-price-slashed">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / lb)
                    </div>;

        } else if(product.weightBy === 'pound' && product.showSalePrice && product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-overview-unit-of-measure-list-price-slashed">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / oz)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showSalePrice && !product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-overview-unit-of-measure-list-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / oz)
                    </div>;

        } else if(product.weightBy === 'each' && !product.showSalePrice && !product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'lb'){
            return <div className="shop-product-overview-unit-of-measure-list-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / lb)
                    </div>;

        } else if(product.weightBy === 'pound' && !product.showSalePrice && !product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-overview-unit-of-measure-list-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / oz)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showSalePrice && !product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'ct'){
            return <div className="shop-product-overview-unit-of-measure-list-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / ct)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showSalePrice && !product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'sheet'){
            return <div className="shop-product-overview-unit-of-measure-list-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / sheet)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showSalePrice && !product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'pack'){
            return <div className="shop-product-overview-unit-of-measure-list-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / pack)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showSalePrice && !product.showUnitOfMeasureListPrice && product.unitOfMeasure === 'load'){
            return <div className="shop-product-overview-unit-of-measure-list-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)} / load)
                    </div>;
        } else {
            return null;

        } 
    }

    function setupWriteReviewLink(productReviews:Review[]):JSX.Element | null {
        if(productReviews.length === 0){
            return <div className="shop-product-overview-write-review-link-container">
                        <a href="#reviews-container" title="Be the first to write a review"
                            className="shop-product-overview-write-review-link">
                            Be the first to write a review
                        </a>
                    </div>
        } else {
            return null;
        }

    }

    function setupProductRating(productReviews:Review[]):JSX.Element | null {
        if(productReviews.length > 0){
            return <ShopProductGroupRating 
                        productReviews={productReviews}
                        showTotalReviewsMsg={false}
                        showTotalReviews={false}
                        starSizeCssStyle={'product-overview-rating-star-shape'}
                        starRatingListGroupContainerCssStyle={'star-rating-list-group-container-product-options'}
                        starRatingListCssStyle={'star-rating-list-product-options'}
                    />
        } else {
            return null;
        }

    }

    function setupRateReadReviewQuantityLink(productReviews:Review[]):JSX.Element | null {
        if(productReviews.length > 0){
            return <div className="shop-product-overview-rate-read-review-quantity-link-container">
                        <a href="#reviews-container" title="Rate and Review"
                            className="shop-product-overview-rate-review-link">
                            Rate and Review
                        </a>
                        {' | '}
                        <a href="#reviews-container" title="Read Reviews" 
                            className="shop-product-overview-read-reviews-link">
                            Read Reviews ({productReviews.length})
                        </a>
                    </div>

        } else {
            return null;
        }

    }

    function setupAddToProductPrice(productSelected:Product):string {
        //console.log('ps:', productSelected);
        if(productSelected.showSalePrice && productSelected.showWeightBy && productSelected.weightBy === 'each'){
            return `$${productSelected.salePrice.toFixed(2)} each`;

        } else if(productSelected.showSalePrice && productSelected.showWeightBy && productSelected.weightBy === 'pound'){
            return `$${productSelected.salePrice.toFixed(2)} lb`;

        } else if(!productSelected.showSalePrice && productSelected.showWeightBy && productSelected.weightBy === 'each'){
            return `$${productSelected.eachPrice.toFixed(2)} each`;
        } else if(!productSelected.showSalePrice && productSelected.showWeightBy && productSelected.weightBy === 'pound'){
            return `$${productSelected.eachPrice.toFixed(2)} lb`;
        } else if(productSelected.showSalePrice && !productSelected.showWeightBy){
            return `$${productSelected.salePrice.toFixed(2)}`;
        } else {
            return `$${productSelected.eachPrice.toFixed(2)}`;
        }
    }

    function setupEstimatedPackageWeight(productSelected:Product):string | null {
        if(productSelected.showPackingOption){
            const packageWeight = productSelected.packingOption.match(/\d\.\d+/i)![0];
            //console.log('pw:', packageWeight);
            return `Estimated package weight: ${packageWeight} lbs each`;
        } else {
            return null;
        }
    }

    function setupProductDescription(productSelected:Product):JSX.Element|null {
        if(productSelected.productDescription!.details.length > 0){
            return <p>
                        {productSelected.productDescription!.description}
                        {productSelected.productDescription!.details.map((detail, index)=>{
                            return <span key={index}><br/>- {detail}</span>
                        })}
                    </p>
        } else {
            


            return formatProductDescription(productSelected.productDescription!.description);
        }

    }

    function formatProductDescription(description:string):JSX.Element|null {
        //const matches = description.matchAll(/\u2022[ \u00E9\u2011\u2012\u2013?!"-\.,a-zA-Z0-9]*/gi);
        const matches = description.matchAll(/\u2022.*/gi);
        //console.log('match:', matches);
        let ips:number[][] = [];
        if(matches !== null){
            for(const match of matches){
                //console.log('match:', match);
                ips.push([match.index!, match[0].length]);
            }

            //console.log('match:', ips);

            if(ips.length === 0 && description.length === 0){
                return null;
            }

            if(ips.length === 0 && description.length > 0){
                return <div className="shop-product-description-list-wrapper">{description}</div>
            }
    
            const list:JSX.Element =  <ul className="shop-product-description-list">
                                        {ips.map((ip, index)=>{
                                            return <li className="shop-product-description-list-item" key={index}>
                                                        {description.slice(ip[0],  ip[0] + ip[1])}
                                                    </li>
                                        })}
                                    </ul>
    
    
            return <div className="shop-product-description-list-wrapper">
                        {
                            (description.slice(0, ips[0][0]).length > 0)
                            ?
                            <div>{description.slice(0, ips[0][0])}</div>
                            :
                            null
                        }

                        {list}

                        {
                            (description.slice(ips[ips.length - 1][0] + ips[ips.length - 1][1]).length > 0)
                            ?
                            <div>{description.slice(ips[ips.length - 1][0] + ips[ips.length - 1][1])}</div>
                            :
                            null
                        }
                    </div>;

        } else {
            return <p>{description}</p>;
        }

    }


    function toggleDropdownIcon(e:React.MouseEvent):void {
        const closeIcon = closeNutritionListIcon.current as HTMLElement;
        const openIcon = openNutritionListIcon.current as HTMLElement;
        //console.log('e:', e.target === closeIcon);

        if(openIcon.classList.contains('show-nutrition-list-icon')){
            openIcon.classList.remove('show-nutrition-list-icon');
            openIcon.classList.add('hide-nutrition-list-icon');
            closeIcon.classList.remove('hide-nutrition-list-icon');
            closeIcon.classList.add('show-nutrition-list-icon');
            setShowNutritionListContent(false);

        } else {
            openIcon.classList.remove('hide-nutrition-list-icon');
            openIcon.classList.add('show-nutrition-list-icon');
            closeIcon.classList.remove('show-nutrition-list-icon');
            closeIcon.classList.add('hide-nutrition-list-icon');
            setShowNutritionListContent(true);
        }
    }

    function setupNutritionFactsVitamins(productSelected:Product):JSX.Element[] | null {
        if(productSelected.nutritionFacts!.vitamins!.length > 0){
            let trs:JSX.Element[] = [];
            const vitamins = productSelected.nutritionFacts!.vitamins!;
            //console.log('total vitamins:', vitamins.length);
            let vitaminRows:number = Math.ceil(productSelected.nutritionFacts!.vitamins!.length / 2);
            let ctr:number = 0;
            for(let x=0; x < vitaminRows; x++){
                //console.log('ctr:', ctr);
                if(typeof vitamins[ctr] !== 'undefined' && typeof vitamins[ctr + 1] !== 'undefined'){
                    trs.push(<tr key={x}><td>{`${vitamins[ctr].sn} ${vitamins[ctr].dv}`}</td><td className="nutrition-table-cell-shape">{`${vitamins[ctr + 1].sn} ${vitamins[ctr + 1].dv}`}</td></tr>);
                } else if(typeof vitamins[ctr] !== 'undefined' && typeof vitamins[ctr + 1] === 'undefined'){
                    //console.log('test passed2');
                    trs.push(<tr key={x}><td>{`${vitamins[ctr].sn} ${vitamins[ctr].dv}`}</td><td>{''}</td></tr>);                   
                }
                ctr += 2;
            }

            //console.log('trs:', trs);
            
            return trs;
            

        } else {
            return null;
        }
        
    }

    //previous version
    //<ImageZoomMulti productSelected={productSelected}/>

    if(productSelected /*&& showProductProducts*/){
        //console.log('ps226:');
        return (
            <div className="shop-product-desktop-layout-wrapper">
                <div className="shop-product-overview-wrapper">
                    <div className="shop-product-overview-container">
                        <div className="shop-product-overview-details-container">
                            <div className="shop-product-overview-details-wrapper">
                                {
                                    productSelected.showProductZoomImages 
                                    ? <ImageZoomMulti2 productSelected={productSelected}/>
                                    : <ImageZoom productSelected={productSelected} />
                                }
                                <div className="shop-product-overview-details">
                                    <h3 className="shop-product-heading">{productSelected.productName}</h3>
                                    <div className="shop-product-packing-option-desktop">{productSelected.weightBy}</div>
                                    <div className="shop-product-overview-price-container">
                                        <div className="shop-product-overview-sale-price-container">
                                            {addProductListPriceLabel(productSelected)}
                                            {addProductUnitOfMeasureListPriceLabel(productSelected)}
                                        </div>
                                        <div className="shop-product-overview-list-price-container">
                                            {/*${product.eachPrice.toFixed(2)} each*/}
                                            {addProductSalePriceLabel(productSelected)}
                                            {addProductUnitOfMeasureSalePriceLabel(productSelected)}
                                        </div>
                                    </div>
                                    <div className="shop-product-overview-rating-container">
                                        {setupWriteReviewLink(productReviews)}
                                        {setupProductRating(productReviews)}
                                        {setupRateReadReviewQuantityLink(productReviews)}
                                    </div>
                                </div>
                            </div>
                            <div className="shop-product-overview-description-section">
                                <div className="shop-product-overview-description-container">
                                    <div className="shop-product-overview-description-detail-container">
                                        <span className="shop-product-overview-description-detail-heading">Product Description</span>
                                        {setupProductDescription(productSelected)}
                                    </div>
                                    {
                                        (productSelected.productDescription!.ingredients.length > 0)
                                        ?
                                        <div className="shop-product-overview-description-detail-container">
                                            <span className="shop-product-overview-description-detail-heading">Ingredients</span>
                                            <p className="shop-product-ingredients-container">
                                                {productSelected.productDescription!.ingredients}
                                            </p>
                                        </div>
                                        :
                                        null
                                    }

                                    {
                                        (productSelected.productDescription!.safetyWarning.length > 0)
                                        ?
                                        <div className="shop-product-overview-description-detail-container">
                                            <span className="shop-product-overview-description-detail-heading">Safety Warning</span>
                                            <p className="shop-product-safety-warning-container">
                                                {productSelected.productDescription!.safetyWarning}
                                            </p>
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="shop-product-overview-description-detail-disclaimer">
                                        Actual product packaging and materials may contain more or different information than that shown on our website. You should not rely solely on the information presented here. Always read labels, warnings and directions before using or consuming a product. View full Product Information Disclaimer
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shop-product-sidebar-container">
                            <div className="shop-product-sidebar-add-to-cart-price-section">
                                <div className="shop-product-sidebar-add-to-cart-price-container">
                                    <span className="shop-product-sidebar-add-to-cart-price">
                                        {setupAddToProductPrice(productSelected)}
                                    </span>
                                </div>
                                <div className="shop-product-sidebar-estimated-package-weight-container">
                                    {setupEstimatedPackageWeight(productSelected)}
                                </div>
                                {/*<button type="button" className="shop-product-sidebar-btn shop-product-cart-btn">Add to Cart</button>*/}
                                <div className="shop-product-sidebar-checkout-btn-container">
                                    <ProductQtyLimitErrorMessage product={productSelected} shopByAreaType={false}/>
                                    <ShoppingCartItemLimitErrorMessage product={productSelected}  shopByAreaType={false}/>
                                    <ShopProductDetailCheckoutBtn product={productSelected} showShopProductDetailCheckoutBtn={true} />
                                </div>
                                <button type="button" className="shop-product-sidebar-btn shop-product-list-btn">Add to List</button>
                            </div>

                            {
                                productSelected.showNutritionFacts ? <div className="shop-product-overview-nutrition-facts-container">
                                    <div className="shop-product-overview-nutrition-facts-title">
                                        Nutrition Facts
                                    </div>
                                    <ul className="shop-product-overview-nutrition-facts-list">
                                        <li>
                                            <div className="shop-product-overview-nutrition-dropdown-container"
                                                onClick={toggleDropdownIcon}>
                                                <span className="shop-product-overview-nutrition-dropdown-icon-container">
                                                    <i ref={closeNutritionListIcon} 
                                                        className="fas fa-caret-right hide-nutrition-list-icon nutrition-list-icon-shape"></i>
                                                    <i ref={openNutritionListIcon} 
                                                        className="fas fa-caret-down show-nutrition-list-icon nutrition-list-icon-shape"></i>
                                                </span>
                                                <span className="shop-product-overview-nutrition-dropdown-name">
                                                    {productSelected.nutritionFacts!.productName}
                                                </span>
                                            </div>
                                            {
                                                showNutritionListContent ? <div className="shop-product-overview-nutrition-dropdown-content">
                                                    <p className="nutrition-table-serving-stats">
                                                        Serving Size {productSelected.nutritionFacts!.servingSize}
                                                        <br/>
                                                        Serving Per Container {productSelected.nutritionFacts!.servingsPerContainer}
                                                    </p>
                                                    <dl>
                                                        <dd className="nutrition-table-aps-data">
                                                            <div className="shop-product-overview-nutrition-table-title">Amount Per Serving</div>
                                                            <table className="shop-product-overview-nutrition-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>CALORIES</td>
                                                                        <td colSpan={2} className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.calories}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>CALORIES FROM FAT</td>
                                                                        <td colSpan={2} className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.caloriesFromFat}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </dd>
                                                        <dd className="nutrition-table-dv-data">
                                                            <div className="shop-product-overview-nutrition-table-title">%DV</div>
                                                            <table className="shop-product-overview-nutrition-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>TOTAL FAT</td>
                                                                        <td>{productSelected.nutritionFacts!.totalFat!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.totalFat!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>SATURATED FAT</td>
                                                                        <td>{productSelected.nutritionFacts!.saturatedFat!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.saturatedFat!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>TRANS FAT</td>
                                                                        <td>{productSelected.nutritionFacts!.transFat!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.transFat!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>CHOLESTEROL</td>
                                                                        <td>{productSelected.nutritionFacts!.cholesterol!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.cholesterol!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>SODIUM</td>
                                                                        <td>{productSelected.nutritionFacts!.sodium!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.sodium!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>POTASSIUM</td>
                                                                        <td>{productSelected.nutritionFacts!.potassium!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.potassium!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>TOTAL CARBOHYDRATE</td>
                                                                        <td>{productSelected.nutritionFacts!.totalCarbohydrate!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.totalCarbohydrate!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>DIETARY FIBER</td>
                                                                        <td>{productSelected.nutritionFacts!.dietaryFiber!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.dietaryFiber!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>SUGARS</td>
                                                                        <td>{productSelected.nutritionFacts!.sugars!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.sugars!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>SUGAR ALCOHOL</td>
                                                                        <td>{productSelected.nutritionFacts!.sugarAlcohol!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.sugarAlcohol!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>PROTEIN</td>
                                                                        <td>{productSelected.nutritionFacts!.protein!.aps}</td>
                                                                        <td className="nutrition-table-cell-shape">{productSelected.nutritionFacts!.protein!.dv}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table className="shop-product-overview-nutrition-table">
                                                                <tbody>
                                                                    {setupNutritionFactsVitamins(productSelected)}
                                                                </tbody>
                                                            </table>
                                                            <table className="shop-product-overview-nutrition-table">
                                                                <tbody>
                                                                    <tr className="footer">
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="footer-disclaimer">*Percentage Daily values are based on a 2,000 calorie diet.Your Daily values may be higher or lower depending on your calorie needs</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                : null
                                            }
                                        </li>
                                    </ul>

                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        );

    } else {
        return null;
    }


}

export default ShopProductDekstopLayout;
