import React, {useState, useEffect, useRef} from 'react';
import {Link, Router} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product from './product';
import './ImageZoomMulti.css';
import { couldStartTrivia } from 'typescript';


interface Props {
    productSelected:Product
}

const ImageZoomMulti2: React.FunctionComponent<Props> = (props:Props) => {
    const [productSelected, setProductSelected] = useState(props.productSelected);
    const imageZoomRef = useRef<HTMLDivElement>(null);
    const productImageRef = useRef<HTMLImageElement>(null);
    const imageZoomLensRef = useRef<HTMLDivElement>(null);
    //const [cx, setCx] = useState(0);
    //const [cy, setCy] = useState(0);
    const cX = useRef<number>(0);
    const cY = useRef<number>(0);
    const [zoomImages, setZoomImages] = useState<string[]>(getZoomImages(props.productSelected));
    const [zoomImageSelected, setZoomImageSelected] = useState(props.productSelected.productMobileImage);
    const zoomImageRefs = useRef<HTMLImageElement[]>([]);

    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(()=>{

        console.log('useeffect');
        imageZoom(
            productImageRef.current!,
            imageZoomRef.current!,
            imageZoomLensRef.current!
        );

    },[zoomImageSelected]);

    
    function getZoomImages(productSelected:Product):string[] {
        return productSelected.productZoomImages.map((pzi)=>{
                        return pzi.productZoomImage;
                    });
    }


    function imageZoom(
                img:HTMLImageElement, 
                result:HTMLDivElement,
                lens:HTMLDivElement
            ) {
        //var img, lens, result, cx, cy;
        let cx:number = 0;
        let cy:number = 0;
        //img = document.getElementById(imgID);
        //result = document.getElementById(resultID);
        /* Create lens: */
        //lens = document.createElement("DIV");
        //lens.setAttribute("class", "img-zoom-lens");
        /* Insert lens: */
        //img.parentElement.insertBefore(lens, img);
        /* Calculate the ratio between result DIV and lens: */
        cx = result.offsetWidth / lens.offsetWidth;
        cy = result.offsetHeight / lens.offsetHeight;
        /* Set background properties for the result DIV */
        //original version
        //result.style.backgroundImage = "url('" + img.src + "')";
        result.style.backgroundImage = `url("http://server.test_app3${img.src}")`;
        //result.style.backgroundImage = "url('" + '/images/shop/meat-seafood/seafood/fish/previously-frozen-sockeye-salmon-center-cut-wild-caught-002193768-180.jpg' + "')";
        result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";

        //previous version
        //setCx(cx);
        //setCy(cy);
        cX.current = cx;
        cY.current = cy;

        /* Execute a function when someone moves the cursor over the image, or the lens: */
        //lens.addEventListener("mousemove", moveLens);
        //img.addEventListener("mousemove", moveLens);
        /* And also for touch screens: */
        //lens.addEventListener("touchmove", moveLens);
        //img.addEventListener("touchmove", moveLens);
        
    }

    function moveLens(
            e:React.MouseEvent,
            img:HTMLImageElement, 
            result:HTMLDivElement,
            lens:HTMLDivElement
            ) {
                console.log('cx:', cX.current);
                console.log('cy:', cY.current);
        let pos, x, y;
        /* Prevent any other actions that may occur when moving over the image */
        e.preventDefault();
        e.stopPropagation();
        /* Get the cursor's x and y positions: */
        pos = getCursorPos(e, img);
        /* Calculate the position of the lens: */
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);
        /* Prevent the lens from being positioned outside the image: */
        if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
        if (x < 0) {x = 0;}
        if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
        if (y < 0) {y = 0;}
        /* Set the position of the lens: */
        //lens.style.left = x + "px";
        lens.style.left = '50%';
        lens.style.transform = 'translateX(-50%)';
        lens.style.top = y + "px";
        /* Display what the lens "sees": */
        result.style.backgroundPosition = "-" + (x * cX.current) + "px -" + (y * cY.current) + "px";
    }

    function getCursorPos(e:React.MouseEvent, img:HTMLImageElement) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /* Get the x and y positions of the image: */
        a = img.getBoundingClientRect();
        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x : x, y : y};
    }

    function hideMoveLens(
            e:React.MouseEvent,
            result:HTMLDivElement,
            lens:HTMLDivElement
            ):void {

        e.stopPropagation();
        
        lens.classList.remove('img-zoom-lens-multi');
        lens.classList.add('hide-img-zoom-lens-multi');
        result.classList.remove('img-zoom-result');
        result.classList.add('hide-img-zoom-result');


    }

    function showMoveLens(
                e:React.MouseEvent,
                result:HTMLDivElement,
                lens:HTMLDivElement
                ):void {

        e.stopPropagation();

                    console.log('showlens');
        result.classList.remove('hide-img-zoom-result');
        result.classList.add('img-zoom-result');                    
        lens.classList.remove('hide-img-zoom-lens-multi');
        lens.classList.add('img-zoom-lens-multi');

    }

    /*previous version
    function addZoomImage(el:HTMLImageElement):void {
        console.log('el added:', el);
        zoomImages.forEach(zoomImage =>{
            console.log('zoomImage:', zoomImage);
        });

        console.log('zoomImages.length:', zoomImages.length);
        if((zoomImages as HTMLImageElement[]).length === 0 && el !== null){
            console.log('test passed1');
            //(quantityInputsRef.current as HTMLInputElement[])[elIndex] = el;
            (zoomImages as HTMLImageElement[]).push(el);
            setZoomImages([...zoomImages]);
            
        } else if((zoomImages as HTMLImageElement[]).length > 0 && el !== null) {
            console.log('test passed2');
            const zoomImageFound = (zoomImages as HTMLImageElement[]).find((zoomImage)=>{
                return zoomImage === el;
            });
            console.log('found el:', zoomImageFound);
            if(typeof zoomImageFound === 'undefined'){
                (zoomImages as HTMLImageElement[])[(zoomImages as HTMLImageElement[]).length] = el;
                setZoomImages([...zoomImages]);
            }
            
        }  

    }
    */

    function getZoomImageRef(el:HTMLImageElement|null):void {
        //miniCartTestContainerRefs.current.length = 0;
        //console.log('el:');
        if(el !== null && zoomImageRefs.current.length === 0){
            console.log('last:');
            zoomImageRefs.current.push(el);

        } else if(el !== null) {
            const found = zoomImageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                console.log('last:');
                zoomImageRefs.current.push(el);
            }

        }

        console.log('zirs:', zoomImageRefs.current, isLoaded);
                                  

    }


    function getImageFile(imageUrl:string):string {
        const ip = imageUrl.lastIndexOf('/');
        const imgFile = imageUrl.slice(ip + 1);
        console.log('imgFile:', imgFile);
        return imgFile;
    }

    //zoomImages

    /*
    function setupImageZoomList(productSelected:Product):JSX.Element {
        const imgFile = getImageFile(productSelected.productMobileImage);
        console.log('zif:', imgFile);
        return <ul className="shop-product-image-zoom-list">
                    {productSelected.productZoomImages.map((productZoomImage, index)=>{
                        const zoomImgFile = getImageFile(productZoomImage.productZoomImage);
                        console.log('zif:', zoomImgFile);
                        if(imgFile === zoomImgFile){
                            console.log('pzi first:', productZoomImage);
                            return <li key={index}>
                                        <img ref={getZoomImageRef}
                                            onClick={outlineZoomImageSelected} 
                                            className="selected-outline-zoom-image"
                                            src={`${process.env.PUBLIC_URL}${productZoomImage.productZoomImage}`} 
                                            width="50" height="50" />
                                    </li>
                        } else {
                            console.log('pzi second:', productZoomImage);
                            return <li key={index}>
                                        <img ref={getZoomImageRef}
                                            onClick={outlineZoomImageSelected} 
                                            className="reg-outline-zoom-image"
                                            src={`${process.env.PUBLIC_URL}${productZoomImage.productZoomImage}`} width="50" height="50" />
                                    </li>
                        }

                    })}
                </ul>
    }
    */

    function setupImageZoomList(zoomImages:string[], productSelected:Product):JSX.Element {
        const imgFile = getImageFile(productSelected.productMobileImage);
        console.log('zif:', imgFile);
        return <ul className="shop-product-image-zoom-list">
                    {zoomImages.map((zi, index)=>{
                        const zoomImgFile = getImageFile(zi);
                        console.log('zif:', zoomImgFile === imgFile);
                        console.log('isLoaded:', isLoaded);
                        if(imgFile === zoomImgFile){
                            console.log('pzi first:', zoomImgFile);
                            return <li key={index}>
                                        <img ref={getZoomImageRef}
                                            onClick={outlineZoomImageSelected} 
                                            
                                            className="selected-outline-zoom-image"
                                            src={`http://server.test_app3${zi}`} 
                                            width="50" height="50" />
                                    </li>
                        } else {
                            console.log('pzi second:', zoomImgFile);
                            return <li key={index}>
                                        <img ref={getZoomImageRef}
                                            onClick={outlineZoomImageSelected} 
                                            className="reg-outline-zoom-image"
                                            src={`http://server.test_app3${zi}`} width="50" height="50" />
                                    </li>
                        }


                    })}
                </ul>
    }

    function outlineZoomImageSelected(e:React.MouseEvent):void {
        const zoomImageSelected = e.target as HTMLImageElement;
        console.log('zis:', zoomImageSelected);
        
        const imgFile = getImageFile(zoomImageSelected.src);
        //console.log('zis:', zoomImageSelected.src);
        for(let i=0; i < zoomImageRefs.current.length; i++){
            zoomImageRefs.current[i].classList.remove('selected-outline-zoom-image');
            zoomImageRefs.current[i].classList.remove('reg-outline-zoom-image');
        }

        for(let i=0; i < zoomImageRefs.current.length; i++){
            if(zoomImageSelected === zoomImageRefs.current[i]){
                zoomImageRefs.current[i].classList.add('selected-outline-zoom-image');

            } else {
                zoomImageRefs.current[i].classList.add('reg-outline-zoom-image');
            }
            
            
        }
        

        //console.log('zis:', `${process.env.PUBLIC_URL}${zoomImageSelected.src}`);
        
        setZoomImageSelected(`http://server.test_app3${zoomImageSelected.src}`);
        //zoomImageSelected.classList.add('selected-outline-zoom-image');
        //addZoomImage(zoomImageSelected);
    }

    console.log('render');
    return <div className="shop-product-image-zoom-multi-container">
                <div id="myImageLens" ref={imageZoomLensRef} 
                    className="hide-img-zoom-lens-multi"
                    onMouseLeave={(e:React.MouseEvent)=>hideMoveLens(
                                                                e,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}
                    onMouseEnter={(e:React.MouseEvent)=>showMoveLens(
                                                                e,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}  
                    onMouseMove={(e:React.MouseEvent)=>moveLens(
                                                                e,
                                                                productImageRef.current!,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}>                                
                </div>
                <div id="myresult" ref={imageZoomRef} className="hide-img-zoom-result"></div>
                <img id="myimage" ref={productImageRef} 
                    className="shop-product-image-multi" src={zoomImageSelected}
                    onMouseLeave={(e:React.MouseEvent)=>hideMoveLens(
                                                                e,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}
                    onMouseEnter={(e:React.MouseEvent)=>showMoveLens(
                                                                e,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )} 
                    onMouseMove={(e:React.MouseEvent)=>moveLens(
                                                                e,
                                                                productImageRef.current!,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}/>
                <div className="shop-product-image-zooms-container">
                    <div className="shop-product-image-zooms">
                        {setupImageZoomList(zoomImages, productSelected)}
                    </div>
                </div>
                <div className="shop-product-image-directions">Roll over image to zoom in</div>
            </div>;

}


export default ImageZoomMulti2;