import React, {useState, useEffect} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './RelatedProducts.css';
import './AddShopProductReview.css';
import AddShopProductRating from './AddShopProductRating';
import EditShopProductReviewUserName from './EditShopProductReviewUserName';
import Product from './product';

interface Props {
    userName:string;
    /*original version
    productCategoryName:string;
    productName:string;
    */
    productSelected:Product;
}

const AddShopProductReview:React.FunctionComponent<Props> = (props:Props)=>{
    const [userName, setUserName] = useState(props.userName);
    //original version
    //const [productCategoryName, setProductCategoryName] = useState(props.productCategoryName);
    //const [productName, setProductName] = useState(props.productName);
    const [productSelected, setProductSelected] = useState<Product>(props.productSelected);

    function updateUserName(userName:string):void {
        setUserName(userName);
    }

    //original layout
    //return (<Col xl={6} lg={6} className="add-shop-product-review-btn-container">
    if(userName.length > 0){
        return (<div className="add-shop-product-review-btn-container">
                    <div>
                        <h3 className="add-shop-product-review-heading">Rate This Product</h3>
                    </div>
                    <EditShopProductReviewUserName userName={userName} updateUserName={updateUserName}/>
                    {/*<AddShopProductRating userName={userName} productCategoryName={productCategoryName}
                                            productName={productName}/>*/}
                    <AddShopProductRating userName={userName} productSelected={productSelected}/>
                </div>);
    
    } else {
        return  <div className="add-shop-product-review-btn-container">
                    <div className="add-shop-product-review-sub-heading">Tell us what you think!</div>
                    <button type="button" className="col-xl-6 col-lg-6 add-shop-product-review-btn">Write a Review</button>
                </div>
    }

}

export default AddShopProductReview;