import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {Link} from '@reach/router';
import Product from './product';
import ShoppingCart from './shoppingCart';
import ShoppingCartCategory from './shoppingCartCategory';
import './ShopProductDetailCheckoutBtn.css';
import ShopProductGroupRating from './ShopProductGroupRating';
import getApprovedProductReviews from './helper-get-approved-product-reviews';
import {addReviews} from './helper-add-reviews';
//import {addProductsToMiniCart, AddProductsToMiniCart} from './redux/actions';
import {addProductsToMiniCart, selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import cloneDeep from 'lodash/cloneDeep';


interface Props {
    /*
    storeDepartmentName:string;
    departmentCategoryName:string;
    productCategoryName:string;
    */
    product:Product;
    showShopProductDetailCheckoutBtn:boolean;
    //productAddedToMiniCart:boolean;
    //shoppingCart?:ShoppingCart;
    //addProductsToMiniCart?:(shoppingCart:ShoppingCart)=>AddProductsToMiniCart;
}

/*
interface State {
    shoppingCart:ShoppingCart;
}

function mapStateToProps(state:State) {
    const { shoppingCart } = state;
    console.log('shoppingCart 11-7:', shoppingCart);
    return {
        shoppingCart: cloneDeep(shoppingCart)
    };

}

const mapDispatchToProps = {
    addProductsToMiniCart
};
*/

const ShopProductDetailCheckoutBtn: React.FunctionComponent<Props> = (props:Props) => {
    /*
    const [storeDepartmentName, setStoreDepartmentName] = useState(props.storeDepartmentName);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(props.departmentCategoryName);
    const [productCategoryName, setProductCategoryName] = useState(props.productCategoryName);
    */
    //const { addProductsToMiniCart } = props;

    const dispatch = useAppDispatch();

    //console.log('spdcb start:', props.product);
    //previous version
    //const shoppingCart = props.shoppingCart as ShoppingCart;
    const shoppingCart = useAppSelector(selectShoppingCart);

    //const [shoppingCart, setShoppingCart] = useState<ShoppingCart | null>(props.shoppingCart as ShoppingCart);
    const [product, setProduct] = useState<Product | null>(props.product);
    const [showShopProductDetailCheckoutBtn, setShowShopProductDetailCheckoutBtn] = useState(props.showShopProductDetailCheckoutBtn);
    //const [productAddedToMiniCart, setProductAddedToMiniCart] = useState(false);
    //const [productRemovedFromMiniCart, setProductRemovedFromMiniCart] = useState(false);
    const [miniCartLoadingProduct, setMiniCartLoadingProduct] = useState(false);
    const [showCheckoutBtnDropdownList, setShowCheckoutBtnDropdownList] = useState(false);
    const [weightSelected, setWeightSelected] = useState(0);
    const checkoutBtn = useRef<HTMLButtonElement>(null);
    const [checkoutBtnDropdownListStyle, setCheckoutBtnDropdownListStyle] = useState('shop-product-detail-checkout-btn-dropdown-list-down');
    //const [shoppingCartQuantity, setShoppingCartQuantity] = useState(0);

    const [addScProduct, setAddScProduct] = useState(false);

    /*
    original format
    useEffect(()=>{
        console.log('sc effect outside:', (shoppingCart as ShoppingCart).shoppingCartProduct as Product);
        if(((shoppingCart as ShoppingCart).shoppingCartProduct as Product) !== null 
            && ((shoppingCart as ShoppingCart).shoppingCartProduct as Product).productName === (product as Product).productName){
                console.log('sc effect inside:', (shoppingCart as ShoppingCart).shoppingCartProduct);
            setShoppingCart(shoppingCart as ShoppingCart);
            setProduct((shoppingCart as ShoppingCart).shoppingCartProduct as Product);
            if(miniCartLoadingProduct){
                //setTimeout(()=>{
                    setMiniCartLoadingProduct(false);
                //},1000);
                
            }
            //setProductAddedToMiniCart(true);
        }
    },[checkShoppingCartProduct(shoppingCart as ShoppingCart, product as Product)]);
    */

    /*
    //most recent version of useEffect()
    useEffect(()=>{
        console.log('shopproductDetailCheckoutBtn useEffect()');
        if((shoppingCart.shoppingCartProduct as Product) !== null 
            && (shoppingCart.shoppingCartProduct as Product).productName === (product as Product).productName){
                console.log('sc effect inside:', shoppingCart.shoppingCartProduct as Product);
            setProduct(shoppingCart.shoppingCartProduct as Product);
            if(miniCartLoadingProduct){
                //setTimeout(()=>{
                    setMiniCartLoadingProduct(false);
                //},1000);
                
            }
            //setProductAddedToMiniCart(true);
        }        
    },[shoppingCart]);
    */
    //console.log('p10:', props.product, 'sc:', shoppingCart);
   
   useEffect(()=>{
        //console.log('product test first:', props.product.productName);
        //console.log('showShopProductDetailCheckoutBtn:', showShopProductDetailCheckoutBtn);
        //console.log('product useEffect()1');
        //console.log('p5:');

        setProduct(new Product({...props.product}));       
   },[props.product]);

   useEffect(()=>{
       //console.log('product useEffect()2');
       //console.log('p:', product);
       //console.log('p5:');

       if(product!.shoppingCartQuantity as number > 0){
            checkShoppingCartForProduct(shoppingCart, product as Product, miniCartLoadingProduct); 
       }
   },[product]);
   

  //console.log('product start:', product);

   useEffect(()=>{
    //console.log('shoppingCart useEffect()');
    //console.log('p5:', typeof product);
    //console.log('p10:', props.product, 'sc:', shoppingCart);
        //console.log('shopproductDetailCheckoutBtn useEffect()');
        //console.info('product test second:', product);
        checkShoppingCartForProduct(shoppingCart, product as Product, miniCartLoadingProduct);
        //console.log('test failed')
    },[shoppingCart]);


    function checkShoppingCartForProduct(shoppingCart:ShoppingCart, product:Product, miniCartLoadingProduct:boolean):void {
        //console.log('scp test5:', product.shoppingCartQuantity = 0);
        const shoppingCartCategory = shoppingCart.shoppingCartCategories.find((shoppingCartCategory)=>{
                                    return shoppingCartCategory.storeDepartmentName === product.storeDepartmentName;
                                });
        
        //console.log('p10:', shoppingCartCategory);
        
        if(typeof shoppingCartCategory === 'undefined'){
            product.shoppingCartQuantity = 0;
            setProduct(new Product({...product}));
            return;
        }


        if(typeof shoppingCartCategory !== 'undefined'){
            const shoppingCartProduct = shoppingCartCategory.shoppingCartProducts.find((shoppingCartProduct)=>{
                                            return shoppingCartProduct.productName === product.productName;
                                        });
                        //console.log('scp test:', shoppingCartProduct);
                        //console.log('scp test original:', product.shoppingCartQuantity);
            
            if(typeof shoppingCartProduct !== 'undefined'){
                //console.log('scp:', product); 
                setProduct(shoppingCartProduct as Product);
                if(miniCartLoadingProduct){
                    //setTimeout(()=>{
                        setMiniCartLoadingProduct(false);
                    //},1000);
                    
                }
            //change btn value if product is deleted from mini cart
            } else if(typeof shoppingCartProduct === 'undefined' && product.shoppingCartQuantity as number > 0){
                //console.log('test passed 4-14');
                //console.log('scp:', product);
                product.shoppingCartQuantity = 0;
                setProduct(new Product({...product}));

            }

        } 

        //console.log('scp:', product);
    }


    function getTotalProductsAdded(shoppingCart:ShoppingCart):number {
        let totalProductsAdded:number = 0;
        shoppingCart.shoppingCartCategories.forEach((shoppingCartCategory)=>{
            shoppingCartCategory.shoppingCartProducts.forEach((shoppingCartProduct)=>{
                if(shoppingCartProduct.weightBy === 'pound' && shoppingCartProduct.shoppingCartQuantity !== 0){
                    totalProductsAdded += 1;
                } else {
                    totalProductsAdded += shoppingCartProduct.shoppingCartQuantity as number;
                }
                
            });
        });

        return totalProductsAdded;
    }

    function handleRemoveProductMiniCart(
                            e:React.MouseEvent,
                            shoppingCart:ShoppingCart,
                            shoppingCartProduct:Product
                            ):void {
        //console.log('hrpmc');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        //setMiniCartLoadingProduct(true);

        //clear all full cart selections from the shopping cart
        shoppingCart.fullCartSelections.length = 0;


        if(shoppingCartProduct.weightBy === 'each'){
            shoppingCartProduct.shoppingCartQuantity = Number(shoppingCartProduct.shoppingCartQuantity);
            shoppingCartProduct.shoppingCartQuantity -= 1;
    
            if(shoppingCartProduct.shoppingCartQuantity > 1){
                shoppingCartProduct.showEachPrice = true;
            } else if(shoppingCartProduct.shoppingCartQuantity < 2){
                shoppingCartProduct.showEachPrice = false;
            }            

        } else {
            shoppingCartProduct.shoppingCartQuantity = 0;
        }

        shoppingCartProduct.productQtyLimitExceeded = false;

        shoppingCartProduct.selectedToFullCart = false;


        shoppingCartProduct.totalPrice = (shoppingCartProduct.shoppingCartQuantity * shoppingCartProduct.eachPrice);


        setProduct({...shoppingCartProduct});
        //setShoppingCartQuantity(shoppingCartProduct.shoppingCartQuantity);
        //shoppingCart.shoppingCartProduct = shoppingCartProduct;
        //shoppingCart.shoppingCartProducts = updateShoppingCartProducts(shoppingCart, product as Product);
        shoppingCart.shoppingCartCategories = updatedShoppingCartCategories(shoppingCart, product as Product);
        //console.log('shoppingCartCategorie:', shoppingCart.shoppingCartCategories);
        shoppingCart.shoppingCartProductAdded = false;
        shoppingCart.shoppingCartProductRemoved = true;

        shoppingCart.itemLimitExceeded = false;
        shoppingCart.showItemLimtExceededErrorMsg = false;

        //previous version
        //shoppingCart.showShoppingCart = false;
        shoppingCart.showShoppingCart = shoppingCart.shoppingCartCategories.length === 0 ? false : true;

        //const sc:ShoppingCart = Object.assign({}, shoppingCart);
        let clone:ShoppingCart = shoppingCart;// cloneDeep(shoppingCart);
        //console.log('scclone:', clone);
        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(clone);
        dispatch(addProductsToMiniCart(clone));
    }

    function handleAddProductMiniCart(e:React.MouseEvent, shoppingCart:ShoppingCart, shoppingCartProduct:Product):void {
        //let shoppingCartProduct:Product = product as Product;
        //console.log('scp:', shoppingCartProduct);
        //console.log('sc:', shoppingCart);

        //console.log('e.target:', e.target);
        //console.log('e.nativeEvent:', e.nativeEvent);
        e.stopPropagation();
        //e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        //show dropdown if product is weighed by the pound
        /*
        original version
        if(shoppingCartProduct.weightBy === 'pound'){
            addProductDetailBtnDropdownDetails!({
                closeDropdown:true,
                checkoutBtn:checkoutBtn.current!
            });
            setShowCheckoutBtnDropdownList(!showCheckoutBtnDropdownList);
            return;
        }
        */

        //don't update the btn quantity if product quantity is reached
        if(shoppingCartProduct.shoppingCartQuantity === shoppingCartProduct.productQtyLimit){
            shoppingCartProduct.productQtyLimitExceeded = true;
            setProduct({...shoppingCartProduct});
            shoppingCart.shoppingCartCategories = updatedShoppingCartCategories(shoppingCart, product as Product);
            shoppingCart.shoppingCartProductAdded = true;
            shoppingCart.shoppingCartProductRemoved = false;
    
            //previous version
            //shoppingCart.showShoppingCart = false;
            shoppingCart.showShoppingCart = shoppingCart.shoppingCartCategories.length === 0 ? false : true;

            let clone:ShoppingCart = shoppingCart;//cloneDeep(shoppingCart);
            //console.log('clone:', clone);
            dispatch(addProductsToMiniCart(clone));

            return;

        } else {
            shoppingCartProduct.productQtyLimitExceeded = false;
        }

        
        //need to get the total item count and put it as the first part of the condiition
        const tpa = getTotalProductsAdded(shoppingCart);
        //console.log('tpa:', tpa);
        //don't update the btn quantity if item limit of order is reached
        if(tpa === shoppingCart.itemLimit || shoppingCart.itemLimitExceeded){
            //check if product is waiting to be added to full cart
            const fcs = shoppingCart.fullCartSelections.filter((fcs)=>{
                                                        return fcs.productId === shoppingCartProduct.productId;
                                                    });

            if(fcs.length === 0){
                shoppingCartProduct.selectedToFullCart = true;
                if(shoppingCartProduct.productZoomImages.length > 0){
                    shoppingCartProduct.productZoomImages = shoppingCartProduct.productZoomImages.map((pzi)=>{
                                                    return Object.assign({}, pzi);
                                                });
                }
                shoppingCartProduct.nutritionFacts = Object.assign({}, shoppingCartProduct.nutritionFacts);
                shoppingCartProduct.productDescription = Object.assign({}, shoppingCartProduct.productDescription);
                const scpClone = Object.assign({}, shoppingCartProduct);
                shoppingCart.fullCartSelections.push(scpClone);
            }

            //setProduct({...shoppingCartProduct});
            //shoppingCart.shoppingCartCategories = updatedShoppingCartCategories(shoppingCart, product as Product);
            shoppingCart.shoppingCartProductAdded = true;
            shoppingCart.shoppingCartProductRemoved = false;
    
            shoppingCart.itemLimitExceeded = true;
            shoppingCart.showItemLimtExceededErrorMsg = false;
            //previous version
            //shoppingCart.showShoppingCart = false;
            shoppingCart.showShoppingCart = shoppingCart.shoppingCartCategories.length === 0 ? false : true;

            let clone:ShoppingCart = shoppingCart;//cloneDeep(shoppingCart);
            //console.log('clone:', clone);
            dispatch(addProductsToMiniCart(clone));

            return;

        }




        if(shoppingCartProduct.shoppingCartQuantity === 0){
            //console.log('test result');
            setMiniCartLoadingProduct(true);
        }

        shoppingCartProduct.shoppingCartQuantity = Number(shoppingCartProduct.shoppingCartQuantity);
        shoppingCartProduct.shoppingCartQuantity += 1;

        if(shoppingCartProduct.shoppingCartQuantity > 1){
            shoppingCartProduct.showEachPrice = true;
        } else if(shoppingCartProduct.shoppingCartQuantity < 2){
            shoppingCartProduct.showEachPrice = false;
        }

        if(shoppingCartProduct.showSalePrice){
            shoppingCartProduct.totalPrice = (shoppingCartProduct.shoppingCartQuantity * shoppingCartProduct.salePrice);    
        } else {
            shoppingCartProduct.totalPrice = (shoppingCartProduct.shoppingCartQuantity * shoppingCartProduct.eachPrice);
        }

        //original version
        //shoppingCartProduct.totalPrice = (shoppingCartProduct.shoppingCartQuantity * shoppingCartProduct.eachPrice);
        
        //console.log('result:', shoppingCartProduct.shoppingCartQuantity);
        setProduct({...shoppingCartProduct});
        //setShoppingCartQuantity(shoppingCartProduct.shoppingCartQuantity);
        //shoppingCart.shoppingCartProduct = shoppingCartProduct;
        //shoppingCart.shoppingCartCategories.shoppingCartProducts = updateShoppingCartProducts(shoppingCart, product as Product);
        shoppingCart.shoppingCartCategories = updatedShoppingCartCategories(shoppingCart, product as Product);
        //shoppingCart.shoppingCartStoreDepartmentNames = addShoppingCartStoreDepartmentNames(shoppingCart, product as Product);
        //console.log('shoppingCartStoreDepartmentNames:', shoppingCart.shoppingCartStoreDepartmentNames);
        //console.log('shoppingCartCategories:', shoppingCart.shoppingCartCategories);
        shoppingCart.shoppingCartProductAdded = true;
        shoppingCart.shoppingCartProductRemoved = false;

        //previous version
        //shoppingCart.showShoppingCart = false;
        shoppingCart.showShoppingCart = shoppingCart.shoppingCartCategories.length === 0 ? false : true;
        //original version for deep clone object
        //const sc:ShoppingCart = Object.assign({}, shoppingCart);
        let clone:ShoppingCart = shoppingCart;//cloneDeep(shoppingCart);
        //console.log('clone21:', clone);
        //if(!addScProduct){
        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(clone);
        dispatch(addProductsToMiniCart(clone));
            //setAddScProduct(true);
        //}
        
        //original version for clone
        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
        
    }

    function shopProductDetailCheckoutBtnDropdownListSelector(e:React.ChangeEvent,shoppingCart:ShoppingCart, shoppingCartProduct:Product):void {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        //console.log('weightSelected:', (e.target as HTMLSelectElement).value);
        const weightSelected:string = (e.target as HTMLSelectElement).value;
        /*
        let spaceFoundIndex:number = weightSelected.search(/ /i);
        console.log('sfi:', spaceFoundIndex);
        console.log('number:', parseFloat(weightSelected.slice(0, spaceFoundIndex + 1)));
        */

       
        //need to get the total item count and put it as the first part of the condiition
        const tpa = getTotalProductsAdded(shoppingCart);
        //console.log('tpa:', tpa);
        //don't update the btn quantity if item limit of order is reached
        if(tpa === shoppingCart.itemLimit || shoppingCart.itemLimitExceeded){
            //check if product is waiting to be added to full cart
            const fcs = shoppingCart.fullCartSelections.filter((fcs)=>{
                                                        return fcs.productId === shoppingCartProduct.productId;
                                                    });

            if(fcs.length === 0){
                shoppingCartProduct.selectedToFullCart = true;
                if(shoppingCartProduct.productZoomImages.length > 0){
                    shoppingCartProduct.productZoomImages = shoppingCartProduct.productZoomImages.map((pzi)=>{
                                                    return Object.assign({}, pzi);
                                                });
                }
                shoppingCartProduct.nutritionFacts = Object.assign({}, shoppingCartProduct.nutritionFacts);
                shoppingCartProduct.productDescription = Object.assign({}, shoppingCartProduct.productDescription);
                const scpClone = Object.assign({}, shoppingCartProduct);
                shoppingCart.fullCartSelections.push(scpClone);
            }

            //setProduct({...shoppingCartProduct});
            //shoppingCart.shoppingCartCategories = updatedShoppingCartCategories(shoppingCart, product as Product);
            shoppingCart.shoppingCartProductAdded = true;
            shoppingCart.shoppingCartProductRemoved = false;

            shoppingCart.itemLimitExceeded = true;
            shoppingCart.showItemLimtExceededErrorMsg = false;
            //previous version
            //shoppingCart.showShoppingCart = false;
            shoppingCart.showShoppingCart = shoppingCart.shoppingCartCategories.length === 0 ? false : true;

            let clone:ShoppingCart = shoppingCart;//cloneDeep(shoppingCart);
            //console.log('clone2:', clone.showShoppingCart);
            dispatch(addProductsToMiniCart(clone));

            return;

        }



        if(shoppingCartProduct.shoppingCartQuantity === 0){
            //console.log('test result');
            setMiniCartLoadingProduct(true);
        }
        //original version
        //shoppingCartProduct.shoppingCartQuantity = Number(shoppingCartProduct.shoppingCartQuantity);
        //shoppingCartProduct.shoppingCartQuantity = parseFloat(weightSelected.slice(0, spaceFoundIndex + 1));
        shoppingCartProduct.shoppingCartQuantity = parseFloat(weightSelected);
    
        //original version
        //shoppingCartProduct.shoppingCartQuantity += 1;

        shoppingCartProduct.showEachPrice = true;
        /*
        original version
        if(shoppingCartProduct.shoppingCartQuantity > 1){
            shoppingCartProduct.showEachPrice = true;
        } else if(shoppingCartProduct.shoppingCartQuantity < 2){
            shoppingCartProduct.showEachPrice = false;
        }
        */
        if(shoppingCartProduct.showSalePrice){
            shoppingCartProduct.totalPrice = (shoppingCartProduct.shoppingCartQuantity * shoppingCartProduct.salePrice);    
        } else {
            shoppingCartProduct.totalPrice = (shoppingCartProduct.shoppingCartQuantity * shoppingCartProduct.eachPrice);
        }
        
        //console.log('result:', shoppingCartProduct.shoppingCartQuantity);
        setProduct({...shoppingCartProduct});
        //setShoppingCartQuantity(shoppingCartProduct.shoppingCartQuantity);
        //shoppingCart.shoppingCartProduct = shoppingCartProduct;
        //shoppingCart.shoppingCartCategories.shoppingCartProducts = updateShoppingCartProducts(shoppingCart, product as Product);
        
        //original version
        //shoppingCart.shoppingCartCategories = updatedShoppingCartCategories(shoppingCart, product as Product);
        shoppingCart.shoppingCartCategories = updatedShoppingCartCategories(shoppingCart, shoppingCartProduct /*product as Product*/);

        //shoppingCart.shoppingCartStoreDepartmentNames = addShoppingCartStoreDepartmentNames(shoppingCart, product as Product);
        //console.log('shoppingCartStoreDepartmentNames:', shoppingCart.shoppingCartStoreDepartmentNames);
        //console.log('shoppingCartCategories:', shoppingCart.shoppingCartCategories);
        shoppingCart.shoppingCartProductAdded = true;
        shoppingCart.shoppingCartProductRemoved = false;

        shoppingCart.showShoppingCart = shoppingCart.shoppingCartCategories.length === 0 ? false : true;

        //previous version
        //shoppingCart.showShoppingCart = false;
        //const sc:ShoppingCart = Object.assign({}, shoppingCart);
        let clone:ShoppingCart = shoppingCart;// cloneDeep(shoppingCart);
        //console.log('sc:', sc.shoppingCartCategories[0].shoppingCartProducts[0]);
        
        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(clone);
        dispatch(addProductsToMiniCart(clone));
        
    }

    function updatedShoppingCartCategories(shoppingCart:ShoppingCart, product:Product):ShoppingCartCategory[]{
        //console.log('product:', product.shoppingCartQuantity);
        if(shoppingCart.shoppingCartCategories.length > 0){
            const shoppingCartCategory = shoppingCart.shoppingCartCategories.find((shoppingCartCategory)=>{
                return shoppingCartCategory.storeDepartmentName === product.storeDepartmentName;
            });
            const shoppingCartCategoryIndex = shoppingCart.shoppingCartCategories.findIndex((shoppingCartCategory)=>{
                                                    return shoppingCartCategory.storeDepartmentName === product.storeDepartmentName;
                                                });
            if(shoppingCartCategoryIndex !== -1){
                //console.log('passed:', product.showEachPrice);
                (shoppingCartCategory as ShoppingCartCategory).shoppingCartProducts = updateShoppingCartProducts(shoppingCartCategory as ShoppingCartCategory, product);
                const updatedShoppingCartCategory = shoppingCartCategory;
                
                if(updatedShoppingCartCategory!.shoppingCartProducts.length > 0){
                    shoppingCart.shoppingCartCategories.splice(shoppingCartCategoryIndex, 1, updatedShoppingCartCategory as ShoppingCartCategory);
                    //console.log('uscc:', shoppingCart.shoppingCartCategories);
                    return shoppingCart.shoppingCartCategories;

                } else {
                    shoppingCart.shoppingCartCategories.splice(shoppingCartCategoryIndex, 1);
                    //console.log('uscc:', shoppingCart.shoppingCartCategories);
                    return shoppingCart.shoppingCartCategories;
                }


            } else {
                //console.log('passed:', product);
                const storeDepartmentName = product.storeDepartmentName;
                if(product.productZoomImages.length > 0){
                    product.productZoomImages = product.productZoomImages.map((pzi)=>{
                                                    return Object.assign({}, pzi);
                                                });
                }
                product.nutritionFacts = Object.assign({}, product.nutritionFacts);
                product.productDescription = Object.assign({}, product.productDescription);
                const shoppingCartProducts:Product[] = [Object.assign({}, product)];
                const updatedShoppingCartCategory = Object.assign({}, new ShoppingCartCategory({
                                                        storeDepartmentName,
                                                        shoppingCartProducts
                                                    }));
                //previous version
                //shoppingCart.shoppingCartCategories.push(updatedShoppingCartCategory);
                shoppingCart.shoppingCartCategories.unshift(updatedShoppingCartCategory);
                return shoppingCart.shoppingCartCategories;
            }

        } else {
            const storeDepartmentName = product.storeDepartmentName;
            if(product.productZoomImages.length > 0){
                product.productZoomImages = product.productZoomImages.map((pzi)=>{
                                                return Object.assign({}, pzi);
                                            });
            }
            product.nutritionFacts = Object.assign({}, product.nutritionFacts);
            product.productDescription = Object.assign({}, product.productDescription);
            const shoppingCartProducts:Product[] = [Object.assign({}, product)];
            const updatedShoppingCartCategory = Object.assign({}, new ShoppingCartCategory({
                                                    storeDepartmentName,
                                                    shoppingCartProducts
                                                }));
            

            shoppingCart.shoppingCartCategories.push(updatedShoppingCartCategory);
            //console.log('passed:', product);
            return shoppingCart.shoppingCartCategories; 
        }
        
    }

    function updateShoppingCartProducts(shoppingCartCategory:ShoppingCartCategory, product:Product):Product[] {
        //console.log('pfound:', product);
        //console.log('total shoppingCart.shoppingCartProducts:', shoppingCartCategory.shoppingCartProducts);
        /*
        const scp = shoppingCart.shoppingCartProducts.find((shoppingCartProduct)=>{
            return shoppingCartProduct.productName === product.productName;
        });
        */
        const scpIndex = shoppingCartCategory.shoppingCartProducts.findIndex((shoppingCartProduct)=>{
            return shoppingCartProduct.productName === product.productName;
        });

        if(scpIndex !== -1 && product.shoppingCartQuantity as number > 0){
            //console.log('p10:');
            //console.log('products passed if');
            if(product.productZoomImages.length > 0){
                product.productZoomImages = product.productZoomImages.map((pzi)=>{
                                                return Object.assign({}, pzi);
                                            });
            }
            product.nutritionFacts = Object.assign({}, product.nutritionFacts);
            product.productDescription = Object.assign({}, product.productDescription);
            const updatedShoppingCartProduct = Object.assign({}, new Product({...product}));
            shoppingCartCategory.shoppingCartProducts.splice(scpIndex, 1, updatedShoppingCartProduct);
            return shoppingCartCategory.shoppingCartProducts;

        } else if(scpIndex !== -1 && product.shoppingCartQuantity === 0){
            //console.log('p10:');
            //console.log('products passed if');
            //const updatedShoppingCartProduct = Object.assign({}, new Product({...product}));
            shoppingCartCategory.shoppingCartProducts.splice(scpIndex, 1);
            return shoppingCartCategory.shoppingCartProducts;

        } else {
            //console.log('p10:');
            //console.log('products passed else');
            if(product.productZoomImages.length > 0){
                product.productZoomImages = product.productZoomImages.map((pzi)=>{
                                                return Object.assign({}, pzi);
                                            });
            }
            product.nutritionFacts = Object.assign({}, product.nutritionFacts);
            product.productDescription = Object.assign({}, product.productDescription);
            const updatedShoppingCartProduct = Object.assign({}, new Product({...product}));
            (shoppingCartCategory.shoppingCartProducts).unshift(updatedShoppingCartProduct);
            return shoppingCartCategory.shoppingCartProducts;
        }
    }

    /*
    most recent versions for adding a product to the mini cart
    function checkShoppingCartForProduct(shoppingCart:ShoppingCart, product:Product, miniCartLoadingProduct:boolean):void {
        console.log('sc test:', shoppingCart, 'product:', product);
        const scp = shoppingCart.shoppingCartProducts.find((shoppingCartProduct)=>{
                                                return shoppingCartProduct.productName === product.productName;
                                            });
        if(typeof scp !== 'undefined'){
            setProduct(scp as Product);
            if(miniCartLoadingProduct){
                //setTimeout(()=>{
                    setMiniCartLoadingProduct(false);
                //},1000);
                
            }            
        }
    }
    
    function handleRemoveProductMiniCart(shoppingCartProduct:Product, shoppingCart:ShoppingCart):void {
        //setMiniCartLoadingProduct(true);
        shoppingCartProduct.shoppingCartQuantity -= 1;
        setProduct({...shoppingCartProduct});
        //setShoppingCartQuantity(shoppingCartProduct.shoppingCartQuantity);
        //shoppingCart.shoppingCartProduct = shoppingCartProduct;
        shoppingCart.shoppingCartProducts = updateShoppingCartProducts(shoppingCart, product as Product);
        console.log('shoppingCartProducts:', shoppingCart.shoppingCartProducts);
        shoppingCart.shoppingCartProductAdded = false;
        shoppingCart.shoppingCartProductRemoved = true;
        const sc:ShoppingCart = Object.assign({}, shoppingCart);
        (addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
    }

    function handleAddProductMiniCart(shoppingCartProduct:Product):void {
        if(shoppingCartProduct.shoppingCartQuantity === 0){
            console.log('test result');
            setMiniCartLoadingProduct(true);
        }
        shoppingCartProduct.shoppingCartQuantity += 1;
        console.log('result:', shoppingCartProduct.shoppingCartQuantity);
        setProduct({...shoppingCartProduct});
        //setShoppingCartQuantity(shoppingCartProduct.shoppingCartQuantity);
        //shoppingCart.shoppingCartProduct = shoppingCartProduct;
        shoppingCart.shoppingCartProducts = updateShoppingCartProducts(shoppingCart, product as Product);
        shoppingCart.shoppingCartStoreDepartmentNames = addShoppingCartStoreDepartmentNames(shoppingCart, product as Product);
        console.log('shoppingCartStoreDepartmentNames:', shoppingCart.shoppingCartStoreDepartmentNames);
        console.log('shoppingCartProducts:', shoppingCart.shoppingCartProducts);
        shoppingCart.shoppingCartProductAdded = true;
        shoppingCart.shoppingCartProductRemoved = false;
        const sc:ShoppingCart = Object.assign({}, shoppingCart);
        (addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
        
    }

    function updateShoppingCartProducts(shoppingCart:ShoppingCart, product:Product):Product[] {
        console.log('total shoppingCart.shoppingCartProducts:', shoppingCart.shoppingCartProducts);
        const scp = shoppingCart.shoppingCartProducts.find((shoppingCartProduct)=>{
            return shoppingCartProduct.productName === product.productName;
        });
        const scpIndex = shoppingCart.shoppingCartProducts.findIndex((shoppingCartProduct)=>{
            return shoppingCartProduct.productName === product.productName;
        });

        if(typeof scp !== 'undefined' && scpIndex !== -1){
            console.log('products passed if');
            const updatedShoppingCartProduct = new Product({...product});
            shoppingCart.shoppingCartProducts.splice(scpIndex, 1, updatedShoppingCartProduct);
            return shoppingCart.shoppingCartProducts;

        } else {
            console.log('products passed else');
            const updatedShoppingCartProduct = new Product({...product});
            (shoppingCart.shoppingCartProducts as Product[]).unshift(updatedShoppingCartProduct);
            return shoppingCart.shoppingCartProducts;
        }
    }

    function addShoppingCartStoreDepartmentNames(shoppingCart:ShoppingCart, product:Product):string[] {
        const scsdn = shoppingCart.shoppingCartStoreDepartmentNames.find((shoppingCartStoreDepartmentName)=>{
            return shoppingCartStoreDepartmentName === product.storeDepartmentName;
        });

        if(typeof scsdn !== 'undefined' || shoppingCart.shoppingCartStoreDepartmentNames.length === 0){
            console.log('yes test passed');
            (shoppingCart.shoppingCartStoreDepartmentNames as string[]).push(product.storeDepartmentName);
            return shoppingCart.shoppingCartStoreDepartmentNames;
        } else {
            return shoppingCart.shoppingCartStoreDepartmentNames as string[];
        }
        
    }

    function removeShoppingCartStoreDepartmentNames(shoppingCart:ShoppingCart, product:Product):string[] {
        const scsdn = shoppingCart.shoppingCartStoreDepartmentNames.find((shoppingCartStoreDepartmentName)=>{
            return shoppingCartStoreDepartmentName === product.storeDepartmentName;
        });

        const scsdnIndex = shoppingCart.shoppingCartStoreDepartmentNames.findIndex((shoppingCartStoreDepartmentName)=>{
            return shoppingCartStoreDepartmentName === product.storeDepartmentName;
        });

        if(typeof scsdn !== 'undefined' && scsdnIndex !== -1){
            console.log('shoppingCartStoreDepartmentNames passed if');
            shoppingCart.shoppingCartStoreDepartmentNames.splice(scsdnIndex, 1);
            return shoppingCart.shoppingCartStoreDepartmentNames;

        } else {
            console.log('shoppingCartStoreDepartmentNames passed else');
            return shoppingCart.shoppingCartStoreDepartmentNames;
        }
        
    }
    */

    /*
    console.log('product:', product);
    console.log('shoppingQuantity:', (product && (product as Product).shoppingCartQuantity));
    console.log('showShopProductDetailCheckoutBtn:', showShopProductDetailCheckoutBtn);
    console.log('miniCartLoadingProduct:', miniCartLoadingProduct);
    */

    function addProductQuantity(product:Product):string {
        const isWholeNumberQuantity:boolean = (product.shoppingCartQuantity.toString().search(/\./) !== -1) ? true : false;
        if(product.weightBy === 'each'){
            return `${product.shoppingCartQuantity} Added`;
        } else if(isWholeNumberQuantity && product.weightBy === 'pound'){
            //previous version
            //return `${formatProductWeight(parseFloat(product.shoppingCartQuantity as string))} Added`;
            return `${formatProductWeight(parseFloat(product.shoppingCartQuantity as string))}`;
        } else {
           return `${formatProductWeight(parseInt(product.shoppingCartQuantity as string, 10))}` ;
        }
    }

    function formatProductWeight(weight:number):string {
        //console.log('weight:', weight);
        if(weight > 1 || weight < 1){
            return `${weight} lbs`;

        } else {
            return `${weight} lb`;
        }
    }


    function cancelSelectClickEvent(e:React.MouseEvent):void {
        //console.log('csce');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        /*
        shoppingCart.showShoppingCart = false;
        //const sc:ShoppingCart = Object.assign({}, shoppingCart);
        let clone:ShoppingCart = shoppingCart; //cloneDeep(shoppingCart);
        
        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(clone);
        dispatch(addProductsToMiniCart(clone));
        */
    }

   

    //button for products whose weight is by the pound
    if(showShopProductDetailCheckoutBtn && product && product.weightBy === 'pound' && (product.shoppingCartQuantity === 0)){
        //console.log('p14:', product);
        //const weightList:number[] = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5];
        return <div className="shop-product-detail-checkout-btn-container">
                    <div className="shop-product-detail-checkout-btn">
                        {/*<div className="test-detail-container">*/}
                            <span className="shop-product-detail-checkout-btn-text-test">Add to Cart</span>
                            <span><i className="fas fa-chevron-down checkout-btn-plus-icon-shape"></i></span>
                        {/*</div>*/}
                        
                        <select
                            defaultValue={"Select a Weight"}
                            id={`shop-product-detail-checkout-btn-select-btn-${product.productId}`}
                            className="shop-product-detail-checkout-btn-select-btn"
                                onClick={cancelSelectClickEvent}
                                onChange={(e:React.ChangeEvent)=>shopProductDetailCheckoutBtnDropdownListSelector(e, shoppingCart, product)}>
                            {/*<span><i className="fas fa-shopping-cart checkout-btn-shopping-cart-shape"></i></span>*/}
                            {/*<span className="shop-product-detail-checkout-btn-text">Add to Cart</span>*/}
                            {/*<span className="shop-product-detail-checkout-btn-text-test">Add to Cart</span>*/}
                            {/*<span><i className="fas fa-chevron-down checkout-btn-plus-icon-shape"></i></span>*/}
                            <option key={0} value="Select a Weight" disabled={true}>Select a Weight</option>
                            {product.weightList.map((weight, index)=>{
                                return <option key={index + 1} value={weight}>{formatProductWeight(weight)}</option>
                            })}
                        </select>
                        
                    </div>
                </div>

    } else
    //button for products whose weight is by the each
    if(showShopProductDetailCheckoutBtn && product && (product.shoppingCartQuantity === 0) /*!productAddedToMiniCart*/){
        //console.log('p:', product);
        //console.log('p10:', product);
        return <div className="shop-product-detail-checkout-btn-container">
                    <button type="button" className="shop-product-detail-checkout-btn"
                            onClick={(e:React.MouseEvent)=>handleAddProductMiniCart(e, shoppingCart, product)}>
                        {/*<span><i className="fas fa-shopping-cart checkout-btn-shopping-cart-shape"></i></span>*/}
                        <span className="shop-product-detail-checkout-btn-text-test">Add to cart</span>
                        {/*<span><i className="fas fa-plus checkout-btn-plus-icon-shape"></i></span>*/}
                    </button>
                </div>

    //button for products whose weight is by the pound

    /*
    original version
                    <button type="button" style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}} 
                        className="shop-product-detail-checkout-btn"
                        onClick={(e:React.MouseEvent)=>handleAddProductMiniCart(e, shoppingCart, product)}>
                    //{<span><i className="fas fa-shopping-cart checkout-btn-shopping-cart-shape"></i></span>}
                    //{<span className="shop-product-detail-checkout-btn-text">{shoppingCartQuantity} Added</span>}
                    <span className="shop-product-detail-checkout-btn-text-test">
                        //{{(product as Product).shoppingCartQuantity} Added}
                        {addProductQuantity(product)}
                    </span>
                    <span><i className="fas fa-plus checkout-btn-plus-icon-shape"></i></span>
                </button>
                */
    } else if(showShopProductDetailCheckoutBtn && product && product.weightBy === 'pound' && !miniCartLoadingProduct
        && (product.shoppingCartQuantity as number > 0)){
            //console.log('test passed');
            //console.log('shopping car quantity:', product.shoppingCartQuantity);
        //const weightList:number[] = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5];
    return <div className="shop-product-detail-checkout-btn-container">
                <button type="button" className="shop-product-detail-subtract-btn-container"
                        onClick={(e:React.MouseEvent)=>handleRemoveProductMiniCart(e, shoppingCart, product)}>
                    <span><i className="fas fa-minus"></i></span>
                </button>
                <div className="shop-product-detail-checkout-btn-select-btn-container" /*onClick={(e:React.MouseEvent)=>console.log('test worked')}*/>
                    <div className="shop-product-detail-checkout-btn" style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}}>
                        <span className="shop-product-detail-checkout-btn-text-test">
                            {/*{(product as Product).shoppingCartQuantity} Added*/}
                            {addProductQuantity(product)}
                        </span>
                        <span><i className="fas fa-plus checkout-btn-plus-icon-shape"></i></span>                
                    </div>
                    <select value={`${product.shoppingCartQuantity}`}
                        id={`shop-product-detail-checkout-btn-select-btn-${product.productId}`}
                        className="shop-product-detail-checkout-btn-select-btn"
                            onClick={cancelSelectClickEvent}
                            onChange={(e:React.ChangeEvent)=>shopProductDetailCheckoutBtnDropdownListSelector(e, shoppingCart, product)}>
                        {/*<span><i className="fas fa-shopping-cart checkout-btn-shopping-cart-shape"></i></span>*/}
                        {/*<span className="shop-product-detail-checkout-btn-text">Add to Cart</span>*/}
                        {/*<span className="shop-product-detail-checkout-btn-text-test">Add to Cart</span>*/}
                        {/*<span><i className="fas fa-chevron-down checkout-btn-plus-icon-shape"></i></span>*/}
                        <option key={0} value="Select a Weight" disabled={true}>Select a Weight</option>
                        {product.weightList.map((weight, index)=>{
                            return <option key={index + 1} value={weight}>{formatProductWeight(weight)}</option>
                        })}
                    </select>
                    
                </div>

                {/*<button type="button" style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}} 
                        className="shop-product-detail-checkout-btn"
                        onClick={(e:React.MouseEvent)=>handleAddProductMiniCart(e, shoppingCart, product)}>
                    {<span><i className="fas fa-shopping-cart checkout-btn-shopping-cart-shape"></i></span>}
                    {<span className="shop-product-detail-checkout-btn-text">{shoppingCartQuantity} Added</span>}
                    <span className="shop-product-detail-checkout-btn-text-test">
                        {{(product as Product).shoppingCartQuantity} Added}
                        {addProductQuantity(product)}
                    </span>
                    <span><i className="fas fa-plus checkout-btn-plus-icon-shape"></i></span>
                </button>*/}
            </div>
        
    }
    //button for products whose weight is by the each
    else if(showShopProductDetailCheckoutBtn && product && !miniCartLoadingProduct
                && (product.shoppingCartQuantity as number > 0)){
                    //console.log('p10:', product);
                    //console.log('test passed');
                    //console.log('shopping car quantity:', product.shoppingCartQuantity);
        return <div className="shop-product-detail-checkout-btn-container">
                    <button type="button" className="shop-product-detail-subtract-btn-container"
                            onClick={(e:React.MouseEvent)=>handleRemoveProductMiniCart(e, shoppingCart, product)}>
                        <span><i className="fas fa-minus"></i></span>
                    </button>
                    <button type="button" style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}} 
                            className="shop-product-detail-checkout-btn"
                            onClick={(e:React.MouseEvent)=>handleAddProductMiniCart(e, shoppingCart, product)}>
                        {/*<span><i className="fas fa-shopping-cart checkout-btn-shopping-cart-shape"></i></span>*/}
                        {/*<span className="shop-product-detail-checkout-btn-text">{shoppingCartQuantity} Added</span>*/}
                        <span className="shop-product-detail-checkout-btn-text-test">
                            {addProductQuantity(product)}
                            {/*{(product as Product).shoppingCartQuantity} Added*/}
                        </span>
                        <span><i className="fas fa-plus checkout-btn-plus-icon-shape"></i></span>
                    </button>
                </div>
                
    //button for products whose weight is by the each
    //previous version was setting product.shoppingCartQuantity === 1
    } else if(showShopProductDetailCheckoutBtn && miniCartLoadingProduct /*&& !productAddedToMiniCart*/ 
                && /*(product as Product).shoppingCartQuantity === 1*/ (product as Product).shoppingCartQuantity as number > 0){
                    //console.log('test14');
        return <div className="shop-product-detail-checkout-btn-container">
                    <button type="button" className="shop-product-detail-check-mark-btn">
                        <span><i className="fas fa-check checkout-btn-shopping-cart-shape"></i></span>
                    </button>
                </div>

    } else {
        return null;
    }
    

    
}

export default ShopProductDetailCheckoutBtn;
/*previous version using connect()
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopProductDetailCheckoutBtn);
*/