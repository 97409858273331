import React, { useState, useEffect, useRef, useMemo, useCallback, ReactHTML } from 'react';
import {fromEvent} from 'rxjs';
import {connect} from 'react-redux';
//import {navigate} from '@gatsbyjs/reach-router';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import './KeywordSearch.css';
import KeywordList from './KeywordList';
//import {addProductKeywordSearch, AddProductKeywordSearch, AddHighlightBackground, addHighlightBackground} from './redux/actions';
import {addProductKeywordSearch, selectProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {addHighlightBackground, selectHighlightBackground} from './redux/features/highlightBackground/highlightBackgroundSlice';
import {selectMobileNav, showMobileNav} from './redux/features/mobileNav/mobileNavSlice';
import {selectAdminLoginDropdownMobile, showAdminLoginDropdownMobile} from './redux/features/adminLoginDropdownMobile/adminLoginDropdownMobileSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import ProductKeywordSearch from './redux/productKeywordSearch';
import HighlightBackground from './redux/highlightBackground';
//import { useLocation } from "@gatsbyjs/reach-router";
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

interface Props {
    showKeywordSearch:boolean;
    showKeywordSearchModal?:boolean;
    //productKeywordSearch?:ProductKeywordSearch;
    //addProductKeywordSearch?:(productKeywordSearch:ProductKeywordSearch)=>AddProductKeywordSearch;
    //addHighlightBackground?:(highlightBackground:HighlightBackground)=>AddHighlightBackground;
}


/*
interface State {
    productKeywordSearch:ProductKeywordSearch;
}

function mapStateToProps(state:State) {
    const { productKeywordSearch } = state;
    console.log('keywordSearch test 4-26:', productKeywordSearch);
    return {
        productKeywordSearch
    };

}



const mapDispatchToProps = {
    addProductKeywordSearch,
    addHighlightBackground
};
*/


const KeywordSearch:React.FunctionComponent<Props> = (props:Props) => {
    //console.log('kms:', props.showKeywordSearchModal);
    const {showKeywordSearchModal} = props;
    //const { addProductKeywordSearch, addHighlightBackground } = props;
    const productKeywordSearchRedux = useAppSelector(selectProductKeywordSearch);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const selectHighlightBackgroundRedux = useAppSelector(selectHighlightBackground);
    //const {highlightBackground} = useAppSelector(selectHighlightBackground);
    //previous version
    //const keywordPath = decodeURIComponent(useLocation().pathname).slice(8);
    const keywordPath = decodeURIComponent(useLocation().pathname).slice(10);
    //previous version
    //const pathname = decodeURIComponent(useLocation().pathname).slice(1,7);
    const pathname = decodeURIComponent(useLocation().pathname).slice(1,10);
    //const pathname = getQueryParams(useLocation().pathname);

    const [showKeywordSearch, setShowKeywordSearch] = useState(props.showKeywordSearch);
    //previous version
    //const keywordSearchContainer:any = React.createRef<HTMLDivElement>();
    const keywordSearchContainer = useRef<HTMLDivElement>(null);
    //previous version
    //const textInput:React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    const textInput = useRef<HTMLInputElement>(null);
    const [searchContainerShape, setSearchContainerShape] = useState('search-container-shape');
    const [btnCloseShape, setBtnCloseShape] = useState('btn-close-shape-remove');
    const [btnSearchShape, setBtnSearchShape] = useState('btn-search-shape');
    const [btnSearchShapeHighlight, setBtnSearchShapeHighlight] = useState('');
    const [keywords, setKeywords] = useState<string[]>([]);
    const [keyword, setKeyword] = useState((pathname === 'search/q=' && keywordPath.length > 0) ? keywordPath : '');
    const [keywordSearchTerm, setKeywordSearchTerm] = useState('');
    const [showKeywords, setShowKeywords] = useState(false);
    const [keywordSearchContainerHeight, setKeywordSearchContainerHeight] = useState(0);
    const [keywordSearchContainerWidth, setKeywordSearchContainerWidth] = useState(0);
    const keywordSearchErrorMessageContainer:React.RefObject<HTMLDivElement> = useRef(null);
    const keywordSearchErrorMessage:React.RefObject<HTMLDivElement> = useRef(null);
    const keywordSearchErrorMessageCaretUp:React.RefObject<HTMLDivElement> = useRef(null);

    const [firstSetTimeout, setFirstSetTimeout] = useState<NodeJS.Timeout | null>(null);
    const [secondSetTimeout, setSecondSetTimeout] = useState<NodeJS.Timeout | null>(null);
    const [lastSetTimeout, setLastSetTimeout] = useState<NodeJS.Timeout | null>(null);

    const keywordsRef = useRef<string[]>([]);
    const showKeywordsRef = useRef<boolean>(false);

    const showKeywordSearchModalRef = useRef<boolean>(false);

    const portrait = useRef<MediaQueryList[]>([]);

    const isEnterKeyPressedRef = useRef<boolean>(false);

    //previous version
    //const bfBtnKeyword = checkForwardBackBtnPressed(useLocation().pathname);
    const bfBtnKeyword = checkForwardBackBtnPressed2(useLocation().pathname);

    const closeBtnRef = useRef<HTMLButtonElement>(null);

    //remove comments after testing
    //const [productKeywordSearch, setProductKeywordSearch] = useState<ProductKeywordSearch>(props.productKeywordSearch!);

    //console.log('test redux passed:', productKeywordSearchRedux.keywordSearch, 'kw:', keyword, 'kst:', keywordSearchTerm);
    
    useEffect(()=>{
        //console.log('kp14:', bfBtnKeyword);
        if(bfBtnKeyword === 'none'){
            setKeyword('');

        } else {
            setKeyword(bfBtnKeyword);
            setBtnCloseShape('btn-close-shape');
        }

        if(keyword.length > 0 ){
            setBtnSearchShapeHighlight('btn-search-shape-highlight');
        }

        if(keyword.length === 0){
            setBtnSearchShapeHighlight('');
        }
        
    },[bfBtnKeyword]);


    useEffect(()=>{
        /*
        const kw = window.sessionStorage.getItem('keyword');
        if(kw !== null){
            setKeyword(kw!);
        }
        */

        document.addEventListener('click', closeShowKeywords);
        return cleanupCloseShowKeywords;
    },[]);

    useEffect(()=>{

        //console.log('kp14:', keywordPath, 'pn:', pathname, 'ks:', productKeywordSearchRedux.keywordSearch, 'bk:', bfBtnKeyword);

        if(productKeywordSearchRedux.keywordSearch.length === 0
            && productKeywordSearchRedux.clearKeywordSearch){
                //previous version
                //setKeyword('');

                if(bfBtnKeyword !== 'none' && bfBtnKeyword.length > 0){
                    setKeyword(bfBtnKeyword);

                } else {
                    setKeyword('');
                }
                //console.log('kp14:', keywordPath, 'pn:', pathname, 'ks:', productKeywordSearchRedux.keywordSearch, 'bk:', bfBtnKeyword);
        }

        if(productKeywordSearchRedux.keywordSearch !== keyword
            && productKeywordSearchRedux.keywordSearch !== 'none'
            && productKeywordSearchRedux.sentFromKeywordList){
            //window.sessionStorage.setItem('keyword', productKeywordSearchRedux.keywordSearch);
            //previous version
            //setKeyword(productKeywordSearchRedux.keywordSearch);

            if(bfBtnKeyword !== 'none' && bfBtnKeyword.length > 0){
                setKeyword(bfBtnKeyword);

            } else {
                setKeyword(productKeywordSearchRedux.keywordSearch);
            }

            //console.log('kp14:', keywordPath, 'pn:', pathname, 'ks:', productKeywordSearchRedux.keywordSearch, 'bk:', bfBtnKeyword);
            
        }

        if(productKeywordSearchRedux.keywordSearch !== keyword
            && productKeywordSearchRedux.keywordSearch === 'none'
            && pathname === 'search/q=' && keywordPath.length === 0){
            //window.sessionStorage.setItem('keyword', '');
            setKeyword('');

            //console.log('kp14:', keywordPath, 'pn:', pathname, 'ks:', productKeywordSearchRedux.keywordSearch, 'bk:', bfBtnKeyword);
        }

        if(productKeywordSearchRedux.keywordSearch !== keyword
            && productKeywordSearchRedux.keywordSearch === 'none'
            && pathname === 'search/q=' && keywordPath.length > 0){
            //window.sessionStorage.setItem('keyword', pathname);
            setKeyword(keywordPath);

            //console.log('kp14:', keywordPath, 'pn:', pathname, 'ks:', productKeywordSearchRedux.keywordSearch, 'bk:', bfBtnKeyword);
        }

        //close keword list, reset css styles and remove background overlay
        setKeywords([]);
        setShowKeywords(false);

        if(productKeywordSearchRedux.clearKeywordSearch && closeBtnRef.current!.classList.contains('btn-close-shape')){
            closeBtnRef.current!.click();
            textInput.current!.blur();
        }

        //console.log('sksm:');
        dispatch(addHighlightBackground!({highlightBackground:false}));

        const kscw = keywordSearchContainer.current!.getBoundingClientRect().width;
        if(pathname === 'search/q=' && keywordPath.length > 0 /*&& kscw <= 306*/ && window.innerWidth <= 530 && window.innerWidth >= 480){
            keywordSearchContainer.current!.style.position = 'absolute';
            keywordSearchContainer.current!.style.width = '100%';
            (keywordSearchContainer.current!.closest('.keyword-search-position-container') as HTMLDivElement).style.marginRight = '16px';

        } else {
            keywordSearchContainer.current!.style.position = '';
            keywordSearchContainer.current!.style.width = '';
            (keywordSearchContainer.current!.closest('.keyword-search-position-container') as HTMLDivElement).style.marginRight = '';
        }

        setSearchContainerShape('search-container-shape');

    },[productKeywordSearchRedux.keywordSearch]);


    useEffect(()=>{
        //console.log('kw25:', showKeywordSearchModal);
        if(showKeywordSearchModal){
            showKeywordSearchModalRef.current = showKeywordSearchModal;
            setShowKeywords(false);
            showKeywordsRef.current = false;

        } else {
            showKeywordSearchModalRef.current = false;
        }

    },[showKeywordSearchModal]);

    function checkForwardBackBtnPressed(pathname:string):string {
        //if keyword !== pathname then back/forward btn pressed and need them to match
        //as long as the word "search" is in the pathname
        const pn = decodeURIComponent(pathname);
        const pathCategoryName = pn.slice(0,8);
        //console.log('r22:', pathCategoryName);

        if(pathCategoryName === '/search/'){
            //console.log('r22:', true);
            //console.log('r22:', pn.slice(8));
            const kw = pn.slice(8);
            return kw;
        } else {
            return 'none';
        }
        
    }

    function checkForwardBackBtnPressed2(pathname:string):string {
        //if keyword !== pathname then back/forward btn pressed and need them to match
        //as long as the word "search" is in the pathname
        const pn = decodeURIComponent(pathname);
        const pathCategoryName = pn.slice(0,10);
        //console.log('r22:', pathCategoryName);

        if(pathCategoryName === '/search/q='){
            //console.log('r22:', true);
            //console.log('r22:', pn.slice(10));
            const kw = pn.slice(10);
            return kw;
        } else {
            return 'none';
        }
        
    }

    function getQueryParams(path:string):string {
        //console.log('tp14:', /^\/search\/q=.+/i.test(path));
        if(/^\/search\/q=.+/i.test(path)){
            const ip = path.indexOf('search/q=');
            const qp = path.slice(ip + 9);
            //console.log('qp:', qp);
            return decodeURIComponent(qp);

        } else {
            return '';
        }
        
    }


    function closeShowKeywords(e:MouseEvent):void{
        //e.stopPropagation();
        //console.log('document click:', e.target, keyword.length);
        setKeywords([]);
        setShowKeywords(false);
        showKeywordsRef.current = false;
        dispatch(addHighlightBackground!({highlightBackground:false}));

        setSearchContainerShape('search-container-shape');

    }

    function cleanupCloseShowKeywords():void {
        document.removeEventListener('click', closeShowKeywords);
    }


    useEffect(()=>{
        window.addEventListener('resize', keywordSearchResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", keywordSearchResizeHandler);

        return cleanupResizeEvent;
    },[]);

    function cleanupResizeEvent():void {
        window.removeEventListener('resize', keywordSearchResizeHandler);

        portrait.current[0].removeEventListener("change", keywordSearchResizeHandler);
    }

    function keywordSearchResizeHandler(e:Event):void {
        //console.log('resize test:', keywordSearchContainer.current!.getBoundingClientRect().width, textInput, window.innerWidth);
        const kscw = keywordSearchContainer.current!.getBoundingClientRect().width;
        if(textInput.current!.value.length > 0 /*&& kscw <= 306*/ && window.innerWidth <= 530 && window.innerWidth >= 480){
            keywordSearchContainer.current!.style.position = 'absolute';
            keywordSearchContainer.current!.style.width = '100%';
            (keywordSearchContainer.current!.closest('.keyword-search-position-container') as HTMLDivElement).style.marginRight = '16px';

        } else {
            keywordSearchContainer.current!.style.position = '';
            keywordSearchContainer.current!.style.width = '';
            (keywordSearchContainer.current!.closest('.keyword-search-position-container') as HTMLDivElement).style.marginRight = '';
        }

        //console.log('kw25:', keywordsRef.current, showKeywordsRef.current, showKeywordSearchModalRef.current);
        if(keywordsRef.current.length > 0 && window.innerWidth < 480 && showKeywordsRef.current){
            dispatch(addHighlightBackground!({highlightBackground:false}));
        } 

        if(keywordsRef.current.length > 0 && window.innerWidth >= 480 && showKeywordsRef.current && !showKeywordSearchModalRef.current){
            dispatch(addHighlightBackground!({highlightBackground:true}));
        }

        if(keywordsRef.current.length > 0 && window.innerWidth >= 480 && !showKeywordsRef.current && showKeywordSearchModalRef.current){
            dispatch(addHighlightBackground!({highlightBackground:false}));
        }

    }

    /*original version
    function keywordSearchHandler(e:React.ChangeEvent<HTMLInputElement>):void {
        e.persist();
        console.log('input value:', e.target.value);
        setKeyword(e.target.value);
        //window.clearTimeout(intervalId.current);

        //intervalId.current = window.setTimeout(()=>{
                                console.log('called server');
                                closeKeywordErrorMsg();
                                //add close button
                                setBtnCloseShape('btn-close-shape');
                                setKeyword(e.target.value);
                                if(/(!|@|%|\^|\$|#|\?|_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&)/.test(e.target.value)){
                                    setShowKeywords(false);
                                } else if(e.target.value.length > 0){
                                    //get matching keywords
                                    getMatchingKeywords(e.target.value);
                                        
                                } else if(e.target.value === ''){
                                    setKeywords([]);
                                    setShowKeywords(false);
                                    setBtnCloseShape('btn-close-shape-remove');
                                    addHighlightBackground!({highlightBackground:false});
                                }
          //                  },200);
    }
    */

    function ksh(kws:string):void {
        //console.log('called server');
        //closeKeywordErrorMsg();
        //add close button
        //setBtnCloseShape('btn-close-shape');
        
        //setKeyword(kws);
        //previous version
        //if(/(!|@|%|\^|\$|#|\?|_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&)/.test(kws)){
        if(/(!|@|%|\^|\$|#|\?| |_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&)/.test(kws)){
            //console.log('tkws:', kws);
            setBtnCloseShape('btn-close-shape');
            setShowKeywords(false);
            dispatch(addHighlightBackground!({highlightBackground:false}));
        } else {// if(kws.length > 0){
            let kwSearch = kws;
            const r = kwSearch.split(' ');
            //console.log('r12:', r.length);
            let trimedWords = [];
    
            for(let i=0; i < r.length; i++){
                //console.log(`chars at ${i}:`, r[i].length);
                if(r[i].length > 0){
                    trimedWords.push(r[i]);
                }
            }
        
            //const kws = trimedWords.join(' ');
            //console.log('kws:', kws);
            const tkws = trimedWords.join(' ');
            
            //console.log('tkws:', kws, kws.length, tkws.length);

            //console.log('k20:', keyword, tkws.length, isEnterKeyPressedRef.current);
            //console.log('tkws:', tkws.length);
            //get matching keywords
            //getMatchingKeywords(kws);
            if(tkws.length > 0 && isEnterKeyPressedRef.current){
                //console.log('tkws:');
                setBtnCloseShape('btn-close-shape');
                isEnterKeyPressedRef.current = false;
                return;
            }

            if(tkws.length > 0 && !isEnterKeyPressedRef.current){
                getMatchingKeywords(tkws);

                setBtnCloseShape('btn-close-shape');
                //console.log('tkws:');
            } else {
                //console.log('tkws:');
                setKeywords([]);
                setShowKeywords(false);
                //setBtnSearchShapeHighlight('');
                //setBtnCloseShape('btn-close-shape-remove');
                dispatch(addHighlightBackground!({highlightBackground:false}));

                setBtnSearchShapeHighlight('');
                setBtnCloseShape('btn-close-shape-remove');
            }

            
            //reset enter key press ref
            isEnterKeyPressedRef.current = false;
                
        }/* else if(kws === ''){
            setKeywords([]);
            setShowKeywords(false);
            setBtnSearchShapeHighlight('');
            setBtnCloseShape('btn-close-shape-remove');
            dispatch(addHighlightBackground!({highlightBackground:false}));
        }*/
    }

    const debounceksh = useMemo(()=>debounce(ksh,200),[]);

    function keywordSearchHandler(e:React.ChangeEvent<HTMLInputElement>):void{
        //console.log('kscw:', keywordSearchContainer.current!.getBoundingClientRect().width, e.target.value.length);
        const kscw = keywordSearchContainer.current!.getBoundingClientRect().width;
        if(e.target.value.length > 0 /*&& kscw <= 306*/ && window.innerWidth <= 530 && window.innerWidth >= 480){
            keywordSearchContainer.current!.style.position = 'absolute';
            keywordSearchContainer.current!.style.width = '100%';
            (keywordSearchContainer.current!.closest('.keyword-search-position-container') as HTMLDivElement).style.marginRight = '16px';

        } else {
            keywordSearchContainer.current!.style.position = '';
            keywordSearchContainer.current!.style.width = '';
            (keywordSearchContainer.current!.closest('.keyword-search-position-container') as HTMLDivElement).style.marginRight = '';
        }


        setBtnSearchShapeHighlight('btn-search-shape-highlight');
        setKeyword(e.target.value);
        debounceksh(e.target.value);


        //console.log('tkws:', e.target.value.length);

        /*previous version
        if(e.target.value.length > 0){
            setBtnCloseShape('btn-close-shape');

        } else {
            setKeywords([]);
            setShowKeywords(false);
            setBtnSearchShapeHighlight('');
            setBtnCloseShape('btn-close-shape-remove');
            dispatch(addHighlightBackground!({highlightBackground:false}));
        }
        */


    }

    
    //need to add parameter debounceksh to array so if useMemo is updated so the most up to date version is canceled.
    useEffect(()=>{
        return debounceksh.cancel();
    },[]);
    

    function handleEnterKeyPress(e:React.KeyboardEvent):void{
        //console.log('input value:', e.key);
        //console.log('key code:', e.key);
        if(e.key === 'Enter'){
            //console.log('tkws:');
            //console.log('passed');
            submitFormHandler(e);
            textInput.current!.blur();
        } 
    }

    function encodeKeyword(kw:string){
        let ekw = '';
        kw.split('').forEach((w)=>{
            if(w !== '/'){
                ekw += encodeURIComponent(w);
            } else {
                ekw += w;
            }
        });
        
        return ekw;
    }


    //function submitFormHandler(e:React.MouseEvent | React.KeyboardEvent):void {
    function submitFormHandler(e:React.FormEvent | React.KeyboardEvent):void {
        //console.log('test7:', keyword.length);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        e.preventDefault();

        document.body.classList.remove('adj-mobile-search');

        isEnterKeyPressedRef.current = (e.type === 'submit') ? false : true;

        //console.log('k20:', keyword, isEnterKeyPressedRef.current);

        //cancel form submition if keyword is an empty string;
        if(keyword.length === 0 || /^ +$/i.test(keyword)){
            //create function to load error message
            //showKeywordErrorMsg();
            return;
        }
        //console.log('form submit');
        //show search button clicked
        setBtnSearchShape('btn-search-shape-clicked');
        let productName = keyword;
        //previous version
        //let restrictedChars = productName.match(/(!|@|%|\^|\$|#|\?|_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&|<|>|\"|\')/gi);
        let restrictedChars = productName.match(/(\.|\+|\*|\?|\^|\$|\(|\)|\[|\]|\{|\}|\||\\)/gi);
        //console.log('restrictedChars:', restrictedChars);
        if(restrictedChars !== null){
            let productNameChars = productName.split('');
            productName = '';
            for(let i=0; i < productNameChars.length; i++){
                for(let h=0; h < restrictedChars.length; h++){
                    if(hasRestrictedChars(productNameChars, i, restrictedChars, h, 0)){
                        break;
                    } else {
                        productName += productNameChars[i];
                        break;
                    }
                }
            } 
            //console.log('newProductName:', newWord);
            //console.log('newProductName adjusted:', productName, 'kwl:', productName.length, 'rcs:', restrictedChars, 'rcsj:', restrictedChars.join(''), 'fj:', keyword);
            //window.sessionStorage.setItem('keyword', productName);
            productName = productName.length === 0 ? keyword : productName;

            //console.log('tkws:', keyword);
            setKeyword(keyword.trim());
    
            if(productKeywordSearchRedux.keywordSearch === productName.trim()){
                dispatch(addProductKeywordSearch!({
                                        keywordSearch:productName.trim(),
                                        priceRangeSearch:'relatedProducts',
                                        storeDepartmentSearch:'none',
                                        departmentCategorySearch:'none',
                                        sentFromKeywordList:false,
                                        clearKeywordSearch:false,
                                        sameKeywordEntered:true
                                            }));

            } else {
                dispatch(addProductKeywordSearch!({
                                        keywordSearch:productName.trim(),
                                        priceRangeSearch:'relatedProducts',
                                        storeDepartmentSearch:'none',
                                        departmentCategorySearch:'none',
                                        sentFromKeywordList:false,
                                        clearKeywordSearch:false,
                                        sameKeywordEntered:false
                                            }));
            }
                                    

            showKeywordsRef.current = false;
            keywordsRef.current.length = 0;
            setKeywords([]);
                                    
            
            setShowKeywords(false);
            dispatch(addHighlightBackground!({highlightBackground:false}));
            //previous version        
            //navigate(`/search/${encodeURIComponent(keyword)}`);
            navigate(`/search/q=${encodeKeyword(keyword.trim())}`);
            //get products from database and post results

        } else {
            //get products from database and post results
            //console.log('newProductName:', productName);
            //window.sessionStorage.setItem('keyword', productName);
            setKeyword(productName.trim());
            
            if(productKeywordSearchRedux.keywordSearch === productName.trim()){
                dispatch(addProductKeywordSearch!({
                                        keywordSearch:productName.trim(),
                                        priceRangeSearch:'relatedProducts',
                                        storeDepartmentSearch:'none',
                                        departmentCategorySearch:'none',
                                        sentFromKeywordList:false,
                                        clearKeywordSearch:false,
                                        sameKeywordEntered:true
                                            }));

            } else {
                dispatch(addProductKeywordSearch!({
                                        keywordSearch:productName.trim(),
                                        priceRangeSearch:'relatedProducts',
                                        storeDepartmentSearch:'none',
                                        departmentCategorySearch:'none',
                                        sentFromKeywordList:false,
                                        clearKeywordSearch:false,
                                        sameKeywordEntered:false
                                            }));
            }


            showKeywordsRef.current = false;
            keywordsRef.current.length = 0;
            setKeywords([]);
                                    

            setShowKeywords(false);
            dispatch(addHighlightBackground!({highlightBackground:false}));
            //previous version
            //navigate(`/search/${productName}`);
            navigate(`/search/q=${encodeKeyword(productName.trim())}`);
        }

        
    }

    function hasRestrictedChars(productNameChars:string[], productNameCharsIndex:number, restrictedChars:string[], restrictedCharsIndex:number, position:number):boolean {
        if(position === restrictedChars.length){
            //console.log('done');
            return false;
        }
        if(productNameChars[productNameCharsIndex] === restrictedChars[restrictedCharsIndex]){
            //console.log('item match');
            return true; 
        } else {
            //console.log('no match');
            return hasRestrictedChars(productNameChars, productNameCharsIndex, restrictedChars, ++restrictedCharsIndex, ++position);
        }
    
    }

    function getMatchingKeywords(keyword:string):void {
        setKeywordSearchTerm(keyword);
        //get matching keywords
        fetch(`https://server.kando-proto-3.com/keyword-search-mariadb/${keyword}`, {
            method:'get',
            headers:{
                'accept':'application/json'
            }
        })
        .then(r => r.json())
        .then(keywords => {
            //console.log('response:', keywords);


            if(!isEnterKeyPressedRef.current){
                //remove previous keywords
                //orignal version
                //setKeywords([]);
                if(!showKeywords){
                    //console.log('showKeywords:', showKeywords);
                    setShowKeywords(true);
                } 

                //console.log('k20:', keyword, isEnterKeyPressedRef.current);

                //console.log('keyword test:', keywords);
                if(keywords.length > 0){
                    dispatch(addHighlightBackground!({highlightBackground:true}));
                    showKeywordsRef.current = true;
                } else {
                    dispatch(addHighlightBackground!({highlightBackground:false}));
                    showKeywordsRef.current = false;
                }

                //add new keywords to list
                setKeywords([...keywords]);

                keywordsRef.current = [...keywords];

            }

            isEnterKeyPressedRef.current = false;


          

            
    
        })
        .catch(e => console.log('error:', e));    
    }

    function handleCloseBtn(e:React.MouseEvent):void {
        e.stopPropagation();
        setBtnCloseShape('btn-close-shape-remove');
        setBtnSearchShapeHighlight('');
        setBtnSearchShape('btn-search-shape');
        setKeyword('');
        setShowKeywords(false);
        keywordSearchContainer.current!.style.position = '';
        keywordSearchContainer.current!.style.width = '';
        (keywordSearchContainer.current!.closest('.keyword-search-position-container') as HTMLDivElement).style.marginRight = '';
        (textInput.current as HTMLInputElement).focus();
        dispatch(addHighlightBackground!({highlightBackground:false}));
    }

    function handleInputFocus(e:React.FocusEvent):void {
        //console.log('focus:', btnCloseShape, keywordSearchTerm, searchContainerShape);
        setSearchContainerShape('search-container-shape-focus');        
        hideMobileDropdownMenu();
        isEnterKeyPressedRef.current = false;
    }

    function hideMobileDropdownMenu():void {
        dispatch(showMobileNav({showMobileNav:false, linkSelected:false}));
        dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:false, linkSelected:false}));
        dispatch(addHighlightBackground({highlightBackground:false}));
    }

    function preventHighlightBackgroundStateChangeHandler(e:React.MouseEvent):void {
        e.stopPropagation();
    }

    function handleInputBlur(e:React.FocusEvent):void {
        //console.log('blur:');
        if(keyword.length === 0){
            setBtnSearchShapeHighlight('');
        }

        setSearchContainerShape('search-container-shape');
    }

    function closeKeywordErrorMsg():void {
        keywordSearchErrorMessageContainer.current!.classList.remove('keyword-search-error-message-container-adjustment');
        keywordSearchErrorMessage.current!.classList.remove('keyword-search-error-message-adjustment');
    }

    function showKeywordErrorMsg():void {
        const textInputWidth:number = textInput.current!.getBoundingClientRect().width;
        const distanceToShiftMsgLeft:number = textInputWidth * .25;

        keywordSearchErrorMessageContainer.current!.style.left = `${distanceToShiftMsgLeft}px`;
        if(!keywordSearchErrorMessageContainer.current!.classList.contains('keyword-search-error-message-container-adjustment')){
            //console.log('st:', secondSetTimeout);
            if(secondSetTimeout !== null){
                clearTimeout(secondSetTimeout);
            }

            keywordSearchErrorMessageContainer.current!.classList.add('keyword-search-error-message-container-adjustment');
            keywordSearchErrorMessage.current!.classList.add('keyword-search-error-message-adjustment');

            const firstTimeout = setTimeout(()=>{
                keywordSearchErrorMessageContainer.current!.classList.remove('keyword-search-error-message-container-adjustment');
                keywordSearchErrorMessage.current!.classList.remove('keyword-search-error-message-adjustment');
                
            },5000);

            if(firstTimeout !== null && (firstTimeout !== firstSetTimeout)){
                setFirstSetTimeout(firstTimeout);
            }
            
        
        } else if(keywordSearchErrorMessageContainer.current!.classList.contains('keyword-search-error-message-container-adjustment')){
            //console.log('ft:', firstSetTimeout);
            if(firstSetTimeout !== null){
                clearTimeout(firstSetTimeout);
            } 
            
            if(secondSetTimeout !== null){
                clearTimeout(secondSetTimeout);
            } 
            
            if(lastSetTimeout !== null){
                clearTimeout(lastSetTimeout);
            }
            keywordSearchErrorMessageContainer.current!.classList.remove('keyword-search-error-message-container-adjustment');
            keywordSearchErrorMessage.current!.classList.remove('keyword-search-error-message-adjustment');

            const secondTimeout = setTimeout(()=>{
                    keywordSearchErrorMessageContainer.current!.classList.add('keyword-search-error-message-container-adjustment');
                    keywordSearchErrorMessage.current!.classList.add('keyword-search-error-message-adjustment');

                    const lastTimeout = setTimeout(()=>{
                        keywordSearchErrorMessageContainer.current!.classList.remove('keyword-search-error-message-container-adjustment');
                        keywordSearchErrorMessage.current!.classList.remove('keyword-search-error-message-adjustment'); 
                    },5000);

                    if(lastTimeout !== null && (lastTimeout !== lastSetTimeout)){
                        setLastSetTimeout(lastTimeout);
                    }  

                },250);

            if(secondTimeout !== null && (secondTimeout !== secondSetTimeout)){
                setSecondSetTimeout(secondTimeout);
            }                
        }
        
    }


    if(showKeywordSearch){
        return <div ref={keywordSearchContainer} className={`${searchContainerShape} keyword-search-position`}>
                    {/*<div ref={keywordSearchErrorMessageContainer} className="keyword-search-error-message-container">
                        <div ref={keywordSearchErrorMessageCaretUp} className="keyword-search-error-message-caret-up"></div>
                        <div ref={keywordSearchErrorMessage} className="keyword-search-error-message">
                            <div className="exclamation-shape-container"><i className="fas fa-exclamation exclamation-shape"></i></div>
                            <div className="exclamation-message-shape">Please fill out this field.</div>
                        </div>
                    </div>*/}
                    <form className="product-search-input-form" onSubmit={submitFormHandler}>
                        <fieldset className="product-search-input-container">
                            <input type="text" ref={textInput} id="keyword-search"  value={keyword} onFocus={handleInputFocus} onBlur={handleInputBlur} 
                                onClick={preventHighlightBackgroundStateChangeHandler}
                                onChange={keywordSearchHandler} onKeyDown={(e:React.KeyboardEvent)=>handleEnterKeyPress(e)} className="product-search-input-shape"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            autoComplete="off" autoCorrect="off"
                            required={true}
                            />
                            <button type="button" ref={closeBtnRef} onClick={handleCloseBtn} className={`${btnCloseShape}`}>
                                {/*<i className="fa fa-times" aria-hidden="true"></i>*/}
                                <span className="btn-close-svg-container"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 7.15147L12.9757 2.17574C13.2101 1.94142 13.59 1.94142 13.8243 2.17574C14.0586 2.41005 14.0586 2.78995 13.8243 3.02426L8.84853 8L13.8243 12.9757C14.0586 13.2101 14.0586 13.59 13.8243 13.8243C13.59 14.0586 13.2101 14.0586 12.9757 13.8243L8 8.84853L3.02426 13.8243C2.78995 14.0586 2.41005 14.0586 2.17574 13.8243C1.94142 13.59 1.94142 13.2101 2.17574 12.9757L7.15147 8L2.17574 3.02426C1.94142 2.78995 1.94142 2.41005 2.17574 2.17574C2.41005 1.94142 2.78995 1.94142 3.02426 2.17574L8 7.15147Z" fill="currentColor"></path></svg></span>
                            </button>
                            <button type="submit" aria-label="keyword search"/*onClick={(e:React.MouseEvent)=>submitFormHandler(e)}*/ className={`${btnSearchShape} ${btnSearchShapeHighlight}`}>
                                {/*<i className="fa fa-search" aria-hidden="true"></i>*/}
                                <span className="btn-search-svg-container"><svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.937 9.88a5.333 5.333 0 11.943-.943l3.591 3.592a.667.667 0 11-.942.942L8.937 9.88zm-3.27-.213a4 4 0 100-8 4 4 0 000 8z" fill="currentColor"></path></svg></span>
                            </button>
                        </fieldset>
                    </form>

                    {/*<div ref={shopListCaretUp} className="shop-list-caret-up"></div>*/}
                    
                    <KeywordList keywordSearchContainerWidth={keywordSearchContainerWidth} showKeywords={showKeywords} keywordSearchTerm={keywordSearchTerm} keywords={keywords}/>
                </div>
    } else {
        return null;
    }

}

export default KeywordSearch;
/*previous connect() version
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KeywordSearch);
*/