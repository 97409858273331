import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Product from '../../../product';
import cloneDeep from 'lodash/cloneDeep';

interface GeneralProductsFilterNav {
    storeDepartmentName:string;
    departmentCategoryName:string;
    productCategoryName:string;
    storeDepartmentId:string;
    productCategoryId:string;
    showGeneralProductsFilterNav:boolean;
    generalProducts:Product[];
}

const initialState:GeneralProductsFilterNav = {
    storeDepartmentName:'',
    departmentCategoryName:'',
    productCategoryName:'',
    storeDepartmentId:'',
    productCategoryId:'',
    showGeneralProductsFilterNav:false,
    generalProducts:[]
};

export const generalProductsFilterNavSlice = createSlice({
  name: 'generalProductsFilterNav',
  initialState,
  reducers: {
    showGeneralProductsFilterNav: (state, action:PayloadAction<GeneralProductsFilterNav>) => {
      state.storeDepartmentName = action.payload.storeDepartmentName;
      state.departmentCategoryName = action.payload.departmentCategoryName;
      state.productCategoryName = action.payload.productCategoryName;
      state.storeDepartmentId = action.payload.storeDepartmentId;
      state.productCategoryId = action.payload.productCategoryId;
      state.showGeneralProductsFilterNav = action.payload.showGeneralProductsFilterNav;
      state.generalProducts = action.payload.generalProducts;
    }
  }
})

// Action creators are generated for each case reducer function
export const { showGeneralProductsFilterNav } = generalProductsFilterNavSlice.actions;

interface RootState {
  generalProductsFilterNav: GeneralProductsFilterNav;
}

export const selectGeneralProductsFilterNav = (state:RootState) =>  {
  //const clone = cloneDeep(state.productKeywordSearch);
  return state.generalProductsFilterNav;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default generalProductsFilterNavSlice.reducer;