import React from 'react';

function helperHighlightCategory(
                                e:React.MouseEvent,
                                categoryName:string,
                                categoryType:string
                            ):Promise<Response>{
                            
            //console.log('test worked');
            e.preventDefault();
            //console.log('made it');

            const params = JSON.stringify({categoryName});

            //previous version
            //return fetch(`http://localhost:9500/hightlight-${categoryType}-category-name-text/${categoryName}`,{
            return fetch(`https://server.kando-proto-3.com/hightlight-${categoryType}-category-name-text-mariadb`,{
                        method:'PUT',
                        headers:{
                            'Content-Type':'application/json',
                            'Accept':'application/json'
                        },
                        body:params
                    });
                               


}

export default helperHighlightCategory;