import React, {useState, useEffect, useRef} from 'react';
import HomePageLayout from './homePageLayout';
import MarketingBanner from './marketingBanner';
import MarketingPicture from './marketingPicture';
import HomePageLayoutItem from './homePageLayoutItem';
import Product from './product';
import HomePageLayoutDisplay from './HomePageLayoutDisplay';
import HomePageLayoutItemDisplay from './HomePageLayoutItemDisplay';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import ImageSlider3 from './ImageSlider3';
import SliderType from './sliderType';
import {filterNonExistingHomePageLayoutItems} from './helper-home-page-layout-item';
import Card from './card';
import {Link, navigate} from '@gatsbyjs/reach-router';
import LoaderAnimation from './LoaderAnimation';
import './HomePageLayoutItemBuilder.css';
import GenericErrorMessage from './GenericErrorMessage';


interface Props {
    homePageLayout:HomePageLayout;
    homePageLayoutType:string;
    addHomePageLayoutItemList:(shplis:HomePageLayoutItem[])=>void;
    cancelHomePageLayoutItemList:(e:React.MouseEvent)=>void;
    selectedHomePageLayoutItems:HomePageLayoutItem[];
    existingHomePageLayoutItems:HomePageLayoutItem[];
}

const HomePageLayoutItemBuilder:React.FunctionComponent<Props> = (props:Props)=>{
    const {cancelHomePageLayoutItemList, addHomePageLayoutItemList} = props;
    const [homePageLayout, setHomePageLayout] = useState<HomePageLayout>(props.homePageLayout);
    const [homePageLayoutType, setHomePageLayoutType] = useState(props.homePageLayoutType);
    const [homePageLayoutItems, setHomePageLayoutItems] = useState<HomePageLayoutItem[]>([]);
    const [selectedHomePageLayoutItems, setSelectedHomePageLayoutItems] = useState<HomePageLayoutItem[]>(props.selectedHomePageLayoutItems);
    const [existingHomePageLayoutItems, setExistingHomePageLayoutItems] = useState<HomePageLayoutItem[]>(props.existingHomePageLayoutItems);


    //search properties
    const [departmentNameSearchText, setDepartmentNameSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No layout items have been added.');

    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(60);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<HomePageLayout[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    const checkBoxControlsRef = useRef<HTMLInputElement[]>([]);
    
    const [reloadImageSlider, setReloadImageSlider] = useState(false);

    const itemSliderPickerWrapperRefs = useRef<HTMLDivElement[]>([]);
    const imagePickerCloseBtnRefs = useRef<HTMLButtonElement[]>([]);
    const imagePickerShowBtnContainerRefs = useRef<HTMLDivElement[]>([]);
    const imgSliderPickerOverlayRef = useRef<HTMLDivElement>(null);
    const imgSliderPickerInnerWrapperRefs = useRef<HTMLDivElement[]>([]);
    const imageSliderPickerWrapperDoubleRefs = useRef<HTMLDivElement[]>([]);
    const hplibWrapperRefs = useRef<HTMLDivElement[]>([]);

    const isScrolling = useRef<boolean>(false);
    const cp = useRef<number>(0);

    const scrollStopPosition = useRef<number>(0);

    const [sliderType, setSliderType] = useState<SliderType>(new SliderType({type:'carousel', heading:'Selected Items', scrollEventPadding:32}))
    const isMobile = useRef<boolean>(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const portrait = useRef<MediaQueryList[]>([]);


    useEffect(()=>{ 
        
        if(window.innerWidth >= 768){
            setSliderType(new SliderType({type:'carousel', scrollEventPadding:32}));
            isMobile.current = false;

        } else {
            setSliderType(new SliderType({type:'carousel', scrollEventPadding:0}));
            isMobile.current = true;
        }

        const homePageLayoutTypeChanged = validateHomePageLayoutTypeChanged(homePageLayoutType, selectedHomePageLayoutItems);
        if(homePageLayoutTypeChanged && selectedHomePageLayoutItems.length > 0){
            setSelectedHomePageLayoutItems([]);
        }

        const hplt = homePageLayoutType;
        const hplId = homePageLayout ? homePageLayout.homePageLayoutId : 0;
        fetch(`https://server.kando-proto-3.com/get-all-home-page-layout-items/${hplt}/${hplId.toString()}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then(res=>res.json())
        .then(({hplis}:{hplis:HomePageLayoutItem[]})=>{
            //console.log('res:', hplis);

            if(hplis.length > 0){
                //validate if items have been previously selected and are the same type
                //before removing matching items from home page layout item list
                let filteredItems:HomePageLayoutItem[] = [];
                let layoutItems:HomePageLayoutItem[] = [];
                if(!homePageLayoutTypeChanged && selectedHomePageLayoutItems.length > 0){
                    //console.log('passed:', 'shplis:', selectedHomePageLayoutItems, 'hplis:', hplis);
                   filteredItems = resetSelectedHomePageLayoutItems(
                                                            homePageLayoutType, 
                                                            selectedHomePageLayoutItems, 
                                                            filterNonExistingHomePageLayoutItems(hplis)
                                                            );
                   layoutItems = addHomePageLayoutItems(homePageLayoutType, filteredItems);

                } else {
                    layoutItems = addHomePageLayoutItems(
                                                homePageLayoutType, 
                                                filterNonExistingHomePageLayoutItems(hplis)
                                                );
                }

            
                //console.log('layout items:', layoutItems);
                setHomePageLayoutItems(layoutItems);
                getAnyItemList(listChangerBtnPosition, layoutItems, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, layoutItems);
                setIsLoading(false);

            } else {
                //need to show message that no home page layouts have been added.
                //previous message
                //setNoSearchResultsMessage(`No home page layout items have been added`);
                //setNoSearchResultsMessage(`All ${getHPLIBType(hplt)} have been selected.`);
                setNoSearchResultsMessage(`All ${getHPLIBType(hplt)} have been selected. If additional ${getHPLIBType(hplt)} need to be added, add more ${getHPLIBType(hplt)} to the inventory list or remove existing layouts.`)
                setShowNoSearchResultsFound(true);
                setHomePageLayoutItems([]);
                setIsLoading(false);

            }

        })
        .catch(err=>{
            //console.log('fetch home page layout items error:', err.message);
            setIsLoading(false); 
            setShowErrorMessage(true);       
        });


    },[]);


    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }

    function getHPLIBType(type:string):string {
        //console.log('ehplis:', existingHomePageLayoutItems, selectedHomePageLayoutItems);
        switch(type){
            case 'banner-box':
                return 'banners';
            case 'carousel':
                return 'products'
            case 'card-box':
            case 'slider-3':
            case 'slider-4':
                return 'marketing pictures'
            default:
                throw new Error('No home page layout types found.');
        }
    }
    
    function getHPLIBwrapperRef(el:HTMLDivElement|null):void {
        if(el !== null && hplibWrapperRefs.current.length > 0){
            hplibWrapperRefs.current.length = 0;
        }

        if(el !== null && hplibWrapperRefs.current.length === 0){
            hplibWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = hplibWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                hplibWrapperRefs.current.push(el);
            }

        }

        //console.log('wrapper7:', 168, hplibWrapperRefs.current[0].offsetHeight);

        const imgPickerHeight = (window.innerHeight >= 600) ? 538 : 365;

        scrollStopPosition.current = (hplibWrapperRefs.current[0].offsetHeight + ((window.innerWidth >= 480) ? 168 : 152)) - imgPickerHeight;

        //console.log('aprcrs:', hplibWrapperRefs.current);

        //console.log('hplib:', hplibWrapperRefs.current[0].closest('.hplb-layout-container'));

        const hplbLayoutContainer = hplibWrapperRefs.current[0].closest('.hplb-layout-container') as HTMLDivElement;
        hplbLayoutContainer.style.minHeight = '';

    }

    function getImgSliderPickerInnerWrapperRef(el:HTMLDivElement|null):void {
        if(el !== null && imgSliderPickerInnerWrapperRefs.current.length > 0){
            imgSliderPickerInnerWrapperRefs.current.length = 0;
        }

        if(el !== null && imgSliderPickerInnerWrapperRefs.current.length === 0){
            imgSliderPickerInnerWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = imgSliderPickerInnerWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imgSliderPickerInnerWrapperRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', imgSliderPickerInnerWrapperRefs.current);

    }

    function getImageSliderPickerWrapperDoubleRef(el:HTMLDivElement|null):void {
        if(el !== null && imageSliderPickerWrapperDoubleRefs.current.length > 0){
            imageSliderPickerWrapperDoubleRefs.current.length = 0;
        }

        if(el !== null && imageSliderPickerWrapperDoubleRefs.current.length === 0){
            imageSliderPickerWrapperDoubleRefs.current.push(el);

        } else if(el !== null) {
            const found = imageSliderPickerWrapperDoubleRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageSliderPickerWrapperDoubleRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', imageSliderPickerWrapperDoubleRefs.current);

        const imgPickerHeight = (window.innerHeight >= 600) ? 538 : 365;
        imageSliderPickerWrapperDoubleRefs.current[0]!.style.height = `${imgPickerHeight}px`;


    }

    function getImagePickerShowBtnContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && imagePickerShowBtnContainerRefs.current.length > 0){
            imagePickerShowBtnContainerRefs.current.length = 0;
        }

        if(el !== null && imagePickerShowBtnContainerRefs.current.length === 0){
            imagePickerShowBtnContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = imagePickerShowBtnContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imagePickerShowBtnContainerRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', imagePickerShowBtnContainerRefs.current);

    }

    function getImagePickerCloseBtnRef(el:HTMLButtonElement|null):void {
        if(el !== null && imagePickerCloseBtnRefs.current.length > 0){
            imagePickerCloseBtnRefs.current.length = 0;
        }

        if(el !== null && imagePickerCloseBtnRefs.current.length === 0){
            imagePickerCloseBtnRefs.current.push(el);

        } else if(el !== null) {
            const found = imagePickerCloseBtnRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imagePickerCloseBtnRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', imagePickerCloseBtnRefs.current);

    }


    function getItemSliderPickerWrapperRef(el:HTMLDivElement|null):void {
        if(el !== null && itemSliderPickerWrapperRefs.current.length > 0){
            itemSliderPickerWrapperRefs.current.length = 0;
        }

        if(el !== null && itemSliderPickerWrapperRefs.current.length === 0){
            itemSliderPickerWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = itemSliderPickerWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                itemSliderPickerWrapperRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', itemSliderPickerWrapperRefs.current);

        const imgPickerHeight = (window.innerHeight >= 600) ? 538 : 365;
        
        itemSliderPickerWrapperRefs.current[0]!.style.height = `${imgPickerHeight}px`;


    }

    function updateImagePicker(filteredSelectedItems:HomePageLayoutItem[]):void {
        if(cp.current >= 111 + ((window.innerWidth >= 480) ? 168 : 152) && filteredSelectedItems.length > 0){
            itemSliderPickerWrapperRefs.current[0].style.transition = 'transform .3s ease-in-out';
            itemSliderPickerWrapperRefs.current[0].style.transform = 'translateX(0)';
        }

        setReloadImageSlider(true);
        setTimeout(()=>{
            setReloadImageSlider(false);
        },250);


        if(filteredSelectedItems.length === 0){
            //console.log('fsis7:', filteredSelectedItems.length);    
            setReloadImageSlider(true);

            imgSliderPickerOverlayRef.current!.classList.remove('hplib-item-slider-picker-overlay-show');
            imgSliderPickerOverlayRef.current!.classList.add('hplib-item-slider-picker-overlay-hide');

            setTimeout(()=>{
                setReloadImageSlider(false);
            },250);
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', hplibScrollHandler);
        return cleanupHPLIBscrollHandler;
    },[]);

    function cleanupHPLIBscrollHandler():void {
        window.removeEventListener('scroll', hplibScrollHandler)
    }

    function hplibScrollHandler(e:Event):void {
        if(!isScrolling.current){
            isScrolling.current = true;
            window.requestAnimationFrame(hplibRAF);
        }
    }

    function hplibRAF():void {
        cp.current = window.scrollY;

        if(typeof itemSliderPickerWrapperRefs.current[0]! !== 'undefined'){

            //console.log('w7:', cp.current, scrollStopPosition.current);
            if(cp.current >= (scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32))){
                itemSliderPickerWrapperRefs.current[0].style.top = `${scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32) - ((window.innerWidth >= 480) ? 168 : 152)}px`;
                itemSliderPickerWrapperRefs.current[0].style.position = 'absolute';
    
                isScrolling.current = false;
                return;
            }


            //console.log('cp6:', cp.current, 279);
            if(cp.current >= 111 + ((window.innerWidth >= 480) ? 168 : 152) + 100){
                //scrolling down page
                imgSliderPickerInnerWrapperRefs.current[0]!.style.opacity = '0';
                imgSliderPickerInnerWrapperRefs.current[0]!.style.transition = 'opacity 0s linear';

                itemSliderPickerWrapperRefs.current[0].style.position = 'fixed';
                itemSliderPickerWrapperRefs.current[0].style.top = '0';// `${(cp.current - (ipwtp.current + 168)) + ipwtp.current}px`;
                itemSliderPickerWrapperRefs.current[0].style.transition = 'transform 0s linear';
                itemSliderPickerWrapperRefs.current[0].style.transform = 'translateX(100%)';
                itemSliderPickerWrapperRefs.current[0].style.boxShadow = 'none';

                imagePickerCloseBtnRefs.current[0]!.style.display = 'flex';
                imagePickerShowBtnContainerRefs.current[0]!.style.display = 'flex';

                imgSliderPickerOverlayRef.current!.classList.remove('hplib-item-slider-picker-overlay-show');
                imgSliderPickerOverlayRef.current!.classList.add('hplib-item-slider-picker-overlay-hide');
                
            
            } else {
                //scrolling up page
                if(cp.current >= 279 && cp.current < 379 && window.innerHeight <= 600){
                    itemSliderPickerWrapperRefs.current[0].style.position = 'fixed';
                    itemSliderPickerWrapperRefs.current[0].style.top = '0';

                } else {
                    itemSliderPickerWrapperRefs.current[0].style.position = 'absolute';
                    itemSliderPickerWrapperRefs.current[0].style.top = '';// `${ipwtp.current}px`;
                }


                itemSliderPickerWrapperRefs.current[0].style.transition = 'transform .3s ease-in-out';
                itemSliderPickerWrapperRefs.current[0].style.transform = 'translateX(0)';
                itemSliderPickerWrapperRefs.current[0].style.boxShadow = '0px 0px 8px -2px #414142';
                itemSliderPickerWrapperRefs.current[0].style.borderRadius = '0';
                
                imagePickerCloseBtnRefs.current[0]!.style.display = 'none';
                imagePickerShowBtnContainerRefs.current[0]!.style.display = 'none';

                imgSliderPickerInnerWrapperRefs.current[0]!.style.opacity = '1';
                imgSliderPickerInnerWrapperRefs.current[0]!.style.transition = 'opacity .3s ease-in-out';
            }
        }

        isScrolling.current = false;
    }

    useEffect(()=>{
        window.addEventListener('resize', hplibResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", hplibResizeHandler);
        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupHPLIBresizeHandler;
    },[]);

    function cleanupHPLIBresizeHandler():void {
        window.removeEventListener('resize', hplibResizeHandler);

        portrait.current[0].removeEventListener("change", hplibResizeHandler);

        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        hplibResizeHandler(new Event('test'));
    }

    function hplibResizeHandler(e:Event):void {
        const imgPickerHeight = (window.innerHeight >= 600) ? 538 : 365;

        if(typeof imageSliderPickerWrapperDoubleRefs.current[0] !== 'undefined'){
            imageSliderPickerWrapperDoubleRefs.current[0]!.style.height = `${imgPickerHeight}px`;
        }

        if(typeof itemSliderPickerWrapperRefs.current[0] !== 'undefined'){
            itemSliderPickerWrapperRefs.current[0]!.style.height = `${imgPickerHeight}px`;
        }

        if(typeof hplibWrapperRefs.current[0] !== 'undefined'){

            scrollStopPosition.current = (hplibWrapperRefs.current[0].offsetHeight + ((window.innerWidth >= 480) ? 168 : 152)) - imgPickerHeight;
            //console.log('w7:', scrollStopPosition.current);
            hplibRAF();

            if(window.innerWidth >= 768 && isMobile.current){
                setSliderType(new SliderType({type:'carousel', heading:'Selected Items', scrollEventPadding:32}));
                isMobile.current = false;

            } 
            
            if(window.innerWidth < 768 && !isMobile.current){
                setSliderType(new SliderType({type:'carousel', heading:'Selected Items', scrollEventPadding:0}));
                isMobile.current = true;
            }

        }
    }



    function validateHomePageLayoutTypeChanged(hplt:string, shplis:HomePageLayoutItem[]):boolean {
        //console.log('hplt:', hplt, 'shplis:', shplis);
        if((shplis.length > 0 && hplt === 'card-box' || hplt === 'slider3' || hplt === 'slider4')
            && shplis[0] instanceof MarketingPicture){
                return false;

        } else if(shplis.length > 0 && hplt === 'banner-box' && shplis[0] instanceof MarketingBanner){
                return false;
                
        } else if(shplis.length > 0 && hplt === 'carousel' && shplis[0] instanceof Product){
                return false;
            
        } else {
            return true;
        }
        
    }

    
    function resetSelectedHomePageLayoutItems(hplit:string, shplis:HomePageLayoutItem[], hplil:HomePageLayoutItem[]):HomePageLayoutItem[] {
        switch(hplit){
            case 'slider3':
            case 'slider4':
            case 'card-box':
                //all three above get marketing pictures
                const filteredMPList = (hplil as MarketingPicture[]).filter((hpli)=>{
                                            let foundItem = false;
                                            (shplis as MarketingPicture[]).forEach((shpli)=>{
                                                if(shpli.marketingPictureId === hpli.marketingPictureId){
                                                    foundItem = true;
                                                }
                                            });

                                            return foundItem ? false : true;
                                        });

                return filteredMPList;

            case 'banner-box':
                //unique get marketing banners
                const filteredMBList = (hplil as MarketingBanner[]).filter((hpli)=>{
                                            let foundItem = false;
                                            (shplis as MarketingBanner[]).forEach((shpli)=>{
                                                if(shpli.marketingBannerId === hpli.marketingBannerId){
                                                    foundItem = true;
                                                }
                                            });

                                            return foundItem ? false : true;
                                        });

                return filteredMBList;

            case 'carousel':
                //unique get products
                const filteredProductList = (hplil as Product[]).filter((hpli)=>{
                                            let foundItem = false;
                                            (shplis as Product[]).forEach((shpli)=>{
                                                if(shpli.productId === hpli.productId){
                                                    foundItem = true;
                                                }
                                            });

                                            return foundItem ? false : true;
                                        });

                return filteredProductList;
            default:
                throw new Error('No home page layout type found.');
        }
    }

   
    function addHomePageLayoutItems(homePageLayoutType:string, items:HomePageLayoutItem[]):HomePageLayoutItem[] {
        switch(homePageLayoutType){
            case 'card-box':
                if(items.length > 0){
                    const cbItems = items.map((item)=>{
                                    return new MarketingPicture({...item});
                                });
                    return cbItems;

                } else {
                    return [];
                }

            case 'banner-box':
                if(items.length > 0){
                    const bbItems = items.map((item)=>{
                                    return new MarketingBanner({...item});
                                });
                    return bbItems;

                } else {
                    return [];
                }

            case 'slider3':
                if(items.length > 0){
                    const s3Items = items.map((item)=>{
                                    return new MarketingPicture({...item});
                                });
                    return s3Items;

                } else {
                    return [];
                }

            case 'slider4':
                if(items.length > 0){
                    const s4Items = items.map((item)=>{
                                    return new MarketingPicture({...item});
                                });
                    return s4Items;

                } else {
                    return [];
                }

            case 'carousel':
                if(items.length > 0){
                    const cItems = items.map((item)=>{
                                    return new Product({...item});
                                });
                    return cItems;

                } else {
                    return [];
                }

            default:
                throw new Error('no home page layout type found.');
        }
    }

    function getHomePageLayoutItemKey(homePageLayoutType:string, hpli:HomePageLayoutItem):number {
        switch(homePageLayoutType){
            case'slider3':
            case'slider4':
            case'card-box':
                return (hpli as MarketingPicture).marketingPictureId;
            case'banner-box':
                return (hpli as MarketingBanner).marketingBannerId;
            case'carousel':
                return (hpli as Product).productId;
            default:
                throw new Error('no page layout item key found.');
        }
    }

    function getCheckboxControlRef(el:HTMLInputElement|null):void {
        if(el !== null && checkBoxControlsRef.current.length === 0){
            checkBoxControlsRef.current.push(el);

        } else if(el !== null) {
            const found = checkBoxControlsRef.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                checkBoxControlsRef.current.push(el);
            }

        }
        
        
    }

    function checkboxHandler(e:React.ChangeEvent, hpli:HomePageLayoutItem):void {
        const target = e.target as HTMLInputElement;
        const checkboxChecked = (e.target as HTMLInputElement).checked;
        const checkboxValue = (e.target as HTMLInputElement).value;
        //console.log('checked:', checkboxChecked, 'value:', checkboxValue, 'item:', hpli);
        //loop thru a checkbox controls to find matching element
        const filterResult = checkBoxControlsRef.current.filter((cbcRef)=>{
                                    return cbcRef === target;
                                });

        filterResult[0].checked = !checkboxChecked;

        filterHomePageLayoutItemLists(hpli, !checkboxChecked);

        //close exceeded limit message if an item has been removed from the list
        if(showNoSearchResultsFound){
            setShowNoSearchResultsFound(false);
        }

    }

    function compareHomePageLayoutItems(a:HomePageLayoutItem, b:HomePageLayoutItem):number {
        if(a instanceof MarketingBanner && b instanceof MarketingBanner){
            if(a.marketingBannerId < b.marketingBannerId){
                return -1;
            } else if(a.marketingBannerId > b.marketingBannerId){
                return 1;
            } else {
                return 0;
            }

        } else if(a instanceof MarketingPicture && b instanceof MarketingPicture){
            if(a.marketingPictureId < b.marketingPictureId){
                return -1;
            } else if(a.marketingPictureId > b.marketingPictureId){
                return 1;
            } else {
                return 0;
            }

        } else {
            const a1 = a as Product;
            const b1 = b as Product;
            if(a1.productId < b1.productId){
                return -1;
            } else if(a1.productId > b1.productId){
                return 1;
            } else {
                return 0;
            }
        }
    }

    function filterHomePageLayoutItemLists(hpli:HomePageLayoutItem, hpliChecked:boolean):void {
        if(hpliChecked && hpli instanceof MarketingBanner){
            const filteredSelectedItems = (selectedHomePageLayoutItems as MarketingBanner[]).filter((item)=>{
                                                return item.marketingBannerId !== hpli.marketingBannerId
                                            });

            updateImagePicker(filteredSelectedItems);

            setSelectedHomePageLayoutItems(filteredSelectedItems);

            homePageLayoutItems.push(hpli);

            //sort by id
            homePageLayoutItems.sort(compareHomePageLayoutItems);

            setHomePageLayoutItems([...homePageLayoutItems]);

            getAnyItemList(listChangerBtnPosition, homePageLayoutItems, itemsPerList, showItemsPerList);
            displayListSorter(itemsPerList, homePageLayoutItems);

        } else if(hpliChecked && hpli instanceof MarketingPicture){
            const filteredSelectedItems = (selectedHomePageLayoutItems as MarketingPicture[]).filter((item)=>{
                                                return item.marketingPictureId !== hpli.marketingPictureId
                                            });

            updateImagePicker(filteredSelectedItems);

            setSelectedHomePageLayoutItems(filteredSelectedItems);

            homePageLayoutItems.push(hpli);

            //sort by id
            homePageLayoutItems.sort(compareHomePageLayoutItems);

            setHomePageLayoutItems([...homePageLayoutItems]);

            getAnyItemList(listChangerBtnPosition, homePageLayoutItems, itemsPerList, showItemsPerList);
            displayListSorter(itemsPerList, homePageLayoutItems);

        } else {
            const filteredSelectedItems = (selectedHomePageLayoutItems as Product[]).filter((item)=>{
                                                return item.productId !== (hpli as Product).productId
                                            });

            updateImagePicker(filteredSelectedItems);

            setSelectedHomePageLayoutItems(filteredSelectedItems);

            homePageLayoutItems.push(hpli);

            //sort by id
            homePageLayoutItems.sort(compareHomePageLayoutItems);

            setHomePageLayoutItems([...homePageLayoutItems]);

            getAnyItemList(listChangerBtnPosition, homePageLayoutItems, itemsPerList, showItemsPerList);
            displayListSorter(itemsPerList, homePageLayoutItems);

        }
    }

    function updateHomePageLayoutItemLists(hpli:HomePageLayoutItem, hpliChecked:boolean):void {
        setReloadImageSlider(true);

        setTimeout(()=>{

            if(cp.current >= 111 + ((window.innerWidth >= 480) ? 168 : 152) + 100){
                //scrolling down page
                imgSliderPickerInnerWrapperRefs.current[0]!.style.opacity = '0';
                imgSliderPickerInnerWrapperRefs.current[0]!.style.transition = 'opacity 0s linear';

                itemSliderPickerWrapperRefs.current[0].style.position = 'fixed';
                itemSliderPickerWrapperRefs.current[0].style.top = '0';// `${(cp.current - (ipwtp.current + 168)) + ipwtp.current}px`;
                itemSliderPickerWrapperRefs.current[0].style.transition = 'transform 0s linear';
                itemSliderPickerWrapperRefs.current[0].style.transform = 'translateX(100%)';
                itemSliderPickerWrapperRefs.current[0].style.boxShadow = 'none';

                imagePickerCloseBtnRefs.current[0]!.style.display = 'flex';
                imagePickerShowBtnContainerRefs.current[0]!.style.display = 'flex';

                imgSliderPickerOverlayRef.current!.classList.remove('hplib-item-slider-picker-overlay-show');
                imgSliderPickerOverlayRef.current!.classList.add('hplib-item-slider-picker-overlay-hide');
                
            }

            if(cp.current >= (scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32))){
                itemSliderPickerWrapperRefs.current[0].style.top = `${scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32) - ((window.innerWidth >= 480) ? 168 : 152)}px`;
                itemSliderPickerWrapperRefs.current[0].style.position = 'absolute';

            }

            setReloadImageSlider(false);
        },250);


        if(hpliChecked && hpli instanceof MarketingBanner){
            const filteredItems = (homePageLayoutItems as MarketingBanner[]).filter((item)=>{
                                                return item.marketingBannerId !== hpli.marketingBannerId
                                            });

            setHomePageLayoutItems(filteredItems);

            selectedHomePageLayoutItems.push(hpli);

            //console.log('fis:', filteredItems, 'sis:', selectedHomePageLayoutItems);

            setSelectedHomePageLayoutItems([...selectedHomePageLayoutItems]);


            if(filteredItems.length === 0){
                setNoSearchResultsMessage(`All banners have been selected. If additional banners need to be added, add more banners to the inventory list or remove existing layouts.`)
                setShowNoSearchResultsFound(true);
                const root = document.documentElement;
                root.scrollTo(0,0);

                return;
            }


            if((filteredItems.length / itemsPerList) === listChangerBtnPosition){
                const lcbp = listChangerBtnPosition - 1;
                //console.log('lcbp:', lcbp);
                setListChangerBtnPosition(lcbp);
                getAnyItemList(lcbp, filteredItems, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, filteredItems);

            } else {
                getAnyItemList(listChangerBtnPosition, filteredItems, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, filteredItems);
            }

            //getAnyItemList(listChangerBtnPosition, filteredItems, itemsPerList, showItemsPerList);
            //displayListSorter(itemsPerList, filteredItems);

        } else if(hpliChecked && hpli instanceof MarketingPicture){
            const filteredItems = (homePageLayoutItems as MarketingPicture[]).filter((item)=>{
                                                return item.marketingPictureId !== hpli.marketingPictureId
                                            });

                                            

            setHomePageLayoutItems(filteredItems);

            selectedHomePageLayoutItems.push(hpli);

            setSelectedHomePageLayoutItems([...selectedHomePageLayoutItems]);


            if(filteredItems.length === 0){
                setNoSearchResultsMessage(`All marketing pictures have been selected. If additional marketing pictures need to be added, add more marketing pictures to the inventory list or remove existing layouts.`)
                setShowNoSearchResultsFound(true);
                const root = document.documentElement;
                root.scrollTo(0,0);

                return;
            }


            if((filteredItems.length / itemsPerList) === listChangerBtnPosition){
                const lcbp = listChangerBtnPosition - 1;
                //console.log('lcbp:', lcbp);
                setListChangerBtnPosition(lcbp);
                getAnyItemList(lcbp, filteredItems, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, filteredItems);

            } else {
                getAnyItemList(listChangerBtnPosition, filteredItems, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, filteredItems);
            }

            //getAnyItemList(listChangerBtnPosition, filteredItems, itemsPerList, showItemsPerList);
            //displayListSorter(itemsPerList, filteredItems);

        } else {
            const filteredItems = (homePageLayoutItems as Product[]).filter((item)=>{
                                                return item.productId !== (hpli as Product).productId
                                            });

            setHomePageLayoutItems(filteredItems);

            selectedHomePageLayoutItems.push(hpli);

            setSelectedHomePageLayoutItems([...selectedHomePageLayoutItems]);

            //console.log('fis:', filteredItems.length);

            if(filteredItems.length === 0){
                setNoSearchResultsMessage(`All products have been selected. If additional products need to be added, add more products to the inventory list or remove existing layouts.`)
                setShowNoSearchResultsFound(true);
                const root = document.documentElement;
                root.scrollTo(0,0);

                return;
            }

            
            if((filteredItems.length / itemsPerList) === listChangerBtnPosition){
                const lcbp = listChangerBtnPosition - 1;
                setListChangerBtnPosition(lcbp);
                getAnyItemList(lcbp, filteredItems, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, filteredItems);

            } else {
                getAnyItemList(listChangerBtnPosition, filteredItems, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, filteredItems);
            }

            //getAnyItemList(listChangerBtnPosition, filteredItems, itemsPerList, showItemsPerList);
            //displayListSorter(itemsPerList, filteredItems);

        }

    }

    function setupItemsCallback(items:object[]):JSX.Element[] {
        if(items[0] instanceof MarketingBanner || items[0] instanceof MarketingPicture){
            return (items as HomePageLayoutItem[]).map((item, i)=>{
                    if(item instanceof MarketingPicture){
                        const mp = item as MarketingPicture;
                        return <div key={mp.marketingPictureId} className="hplid-img-wrapper">
                                    <div className="hplid-checkbox-form">
                                        <input className="hplid-checkbox-control" type="checkbox" checked={true}
                                            id={mp.marketingPictureId.toString()}
                                            ref={getCheckboxControlRef} value={mp.marketingPictureId} 
                                             onChange={(e:React.ChangeEvent)=>checkboxHandler(e, item)}/>
                                    </div>
                                    <div className="hplid-img-container hplid-img-adj">
                                        <img className="hplid-img" src={`https://server.kando-proto-3.com/${mp.desktopSource}`}/>
                                    </div>
                                    <div className="hplib-heading-caption-wrapper">
                                        {mp.heading !== 'none' ? <div className="hblib-heading-container">
                                                                    <div className="hblib-heading-label" role="label">Heading</div>
                                                                    <div className="hblib-heading">{mp.heading}</div>
                                                                </div> : <div className="hblib-heading-container">
                                                                            <div className="hblib-heading-label" role="label">Heading</div>
                                                                            <div className="hblib-heading">No heading has been added.</div>
                                                                        </div> }
                                        {mp.caption !== 'none' ? <div className="hblib-caption-container">
                                                                    <div className="hblib-caption-label" role="label">Caption</div>
                                                                    <div className="hblib-caption">{mp.caption}</div>
                                                                </div> : <div className="hblib-caption-container">
                                                                            <div className="hblib-caption-label" role="label">Caption</div>
                                                                            <div className="hblib-caption">No caption has been added.</div>
                                                                        </div>}
                                    </div>
                                </div>;
                    } else {
                        const mb = item as MarketingBanner;
                        return <div key={mb.marketingBannerId} className="hplid-img-wrapper">
                                    <form className="hplid-checkbox-form">
                                        <input className="hplid-checkbox-control" type="checkbox" checked={true}
                                            id={mb.marketingBannerId.toString()}
                                            ref={getCheckboxControlRef} value={mb.marketingBannerId} 
                                            onChange={(e:React.ChangeEvent)=>checkboxHandler(e, item)}/>
                                    </form>
                                    <div className="hplid-img-container-wrapper">
                                        <div style={{paddingBottom:`${mb.paddingBottomMobile}%`}} className="hplid-img-container">
                                            <img className="hplid-img" src={`https://server.kando-proto-3.com/${mb.mobileSource}`}/>
                                        </div>
                                    </div>
                                    <div className="hplib-heading-caption-wrapper">
                                        {mb.heading !== 'none' ? <div className="hplid-heading-container">
                                                                    <div className="hplid-heading-label" role="label">Heading</div>
                                                                    <div className="hplid-heading">{mb.heading}</div>
                                                                </div> : <div className="hplid-heading-container">
                                                                            <div role="label">Heading</div>
                                                                            <div className="hplid-heading">No heading has been added.</div>
                                                                        </div> }
                                        {mb.caption !== 'none' ? <div className="hplid-caption-container">
                                                                    <div role="label">Caption</div>
                                                                    <div className="hplid-caption">{mb.caption}</div>
                                                                </div> : <div className="hplid-caption-container">
                                                                            <div role="label">Caption</div>
                                                                            <div className="hplid-caption">No caption has been added.</div>
                                                                        </div>}
                                    </div>
                                </div>;
                    }

            });

        } else {
            return (items as Product[]).map((item, i)=>{
                //return <ShopProductDetail product={product} getImageRef={getImageRef}/>
                return <div key={item.productId} className="hplid-img-wrapper">
                            <form className="hplid-checkbox-form">
                                <input className="hplid-checkbox-control" type="checkbox"
                                    id={item.productId.toString()}
                                    ref={getCheckboxControlRef} value={item.productId} checked={true}
                                    onChange={(e:React.ChangeEvent)=>checkboxHandler(e, item)}/>
                            </form>
                            <div className="hplid-img-container hplid-img-adj">
                                <img className="hplid-img" src={`https://server.kando-proto-3.com/${item.productMobileImage}`}/>
                            </div>
                            <div className="hplib-heading-caption-wrapper">
                                <div className="hplid-heading">{item.productName}</div>
                            </div>
                        </div>;
            });
        }

    }


    function filterNonExistingHomePageLayoutItems(shplis:HomePageLayoutItem[]):HomePageLayoutItem[]{
        const nehplis = shplis.filter((shpli)=>{
            //console.log('result:', shpli.homePageLayoutId === 0);
                            return shpli.homePageLayoutId === 0 || (!shpli.reloaded && shpli.selectedForRemoval);
                        });

        //console.log('nehplis:', nehplis);

        return nehplis;
    }
    
    
    function setupAddBtnDisableClass(shplis:HomePageLayoutItem[], ehplis:HomePageLayoutItem[], hplt:string):boolean {
        //original version
        //const itemsAvailable = getNumberOfItemsAvailable(hplt) - shplis.length;
        //const itemsRequired = getNumberOfItemsRequired(hplt)
        //const itemsSelected = shplis.length;
        
        const itemsAvailable = getNumberOfItemsAvailable(hplt) - shplis.concat(ehplis).length;
        const itemsRequired = getNumberOfItemsRequired(hplt)
        const itemsSelected = shplis.concat(ehplis).length;
        
        if(itemsAvailable < 0 /*|| itemsSelected < itemsRequired*/){
            
            //return 'hblib-list-add-btn-disabled';
            return false;
            //addBtnRef.current!.setAttribute('disabled', 'true');
        } else {
            //addBtnRef.current!.classList.remove('hblib-list-add-btn-disabled');
            //addBtnRef.current!.removeAttribute('disabled');
            //return '';
            return true;
        }


    }

    function setupAddBtnDisableAttribute(shplis:HomePageLayoutItem[], ehplis:HomePageLayoutItem[], hplt:string):boolean {
        //original version
        //const itemsAvailable = getNumberOfItemsAvailable(hplt) - shplis.length;
        //const itemsRequired = getNumberOfItemsRequired(hplt)
        //const itemsSelected = shplis.length;
        
        const itemsAvailable = getNumberOfItemsAvailable(hplt) - shplis.concat(ehplis).length;
        const itemsRequired = getNumberOfItemsRequired(hplt)
        const itemsSelected = shplis.concat(ehplis).length;
        
        if(itemsAvailable < 0 /*|| itemsSelected < itemsRequired*/){
            
            //addBtnRef.current!.classList.add('hblib-list-add-btn-disabled');
            //addBtnRef.current!.setAttribute('disabled', 'true');
            return true;
        } else {
            //addBtnRef.current!.classList.remove('hblib-list-add-btn-disabled');
            //addBtnRef.current!.removeAttribute('disabled');
            return false;
        }


    }
    

    function getNumberOfItemsAvailable(hplt:string){
        switch(hplt){
            case 'slider3':
                return 3;
            case 'slider4':
                return 4;
            case 'card-box':
                return 2;
            case 'banner-box':
                return 1;
            case 'carousel':
                return 25;
            default:
                throw new Error('No home page layout type found.');
        }
    }

    function getNumberOfItemsRequired(hplt:string){
        switch(hplt){
            case 'slider3':
                return 3;
            case 'slider4':
                return 4;
            case 'card-box':
                return 2;
            case 'banner-box':
                return 1;
            case 'carousel':
                return 25;
            default:
                throw new Error('No home page layout type found.');
        }
    }
    

    function setupSelectedHomePageItemCounter(shplis:HomePageLayoutItem[], ehplis:HomePageLayoutItem[], hplt:string):JSX.Element {
        //original version
        //const itemsAvailable = getNumberOfItemsAvailable(hplt) - shplis.length;
        const itemsAvailable = getNumberOfItemsAvailable(hplt) - shplis.concat(ehplis).length;
        const itemsRequired = getNumberOfItemsRequired(hplt)
        //origianal version
        //const itemsSelected = shplis.length;
        const itemsSelected = shplis.concat(ehplis).length;

        return <div className="hplib-item-counter-container">
                    {/*<div className="hplib-item-counter-msg">
                        Available: {itemsAvailable} 
                    </div>*/}
                    <div className="hplib-item-counter-msg">
                        Required: {itemsRequired} 
                    </div>
                    <div className="hplib-item-counter-msg">
                        Selected: {itemsSelected}
                    </div>
                    {
                        itemsAvailable < 0
                        ? <div className="hplib-item-counter-limit-msg">
                            Over limit: {Math.abs(itemsAvailable)}
                        </div>
                        : null
                    }
                    {
                        itemsAvailable > 0
                        ? <div className="hplib-item-counter-limit-msg">
                            Under limit: {Math.abs(itemsAvailable)}
                        </div>
                        : null
                    }
                </div>


    }

    
    function checkHomePageLayoutType(hplis:HomePageLayoutItem[], ehplis:HomePageLayoutItem[], hplt:string):boolean {
        const totalItems = ehplis.concat(hplis).length;
        const numberOfExistingItems = ehplis.length;
        //console.log('hplt:', hplt);
        //return (hplt === 'banner-box' || hplt === 'carousel') ? true : false;
        return true;
    }

//checkHomePageLayoutType(selectedHomePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType)
/*
<button 
    className={`hblib-list-add-btn ${setupAddBtnDisableClass(selectedHomePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType)}`} 
    disabled={setupAddBtnDisableAttribute(selectedHomePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType)} type="button"
    onClick={(e:React.MouseEvent)=>addHomePageLayoutItemList(selectedHomePageLayoutItems)}>Add</button>
    */
   
    function setupOptionalHeader(selectedHomePageLayoutItems:HomePageLayoutItem[], 
                                existingHomePageLayoutItems:HomePageLayoutItem[], homePageLayoutType:string):JSX.Element {
        //console.log('shplis:', selectedHomePageLayoutItems);
        return <div className="hblib-list-btn-counter-wrapper">
                    {/*<div className="hblib-image-slider-heading-container">
                        <h3 className="hblib-image-slider-heading">Selected Items</h3>
                    </div>*/}
                    {setupSelectedHomePageItemCounter(selectedHomePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType)}
                    <div className="hblib-list-btn-container">          
                        <button ref={getImagePickerCloseBtnRef} type="button" className="img-slider-picker-btn-hide"
                            onClick={hideImageSliderPicker}>Close
                        </button>
                        
                        {
                            
                            setupAddBtnDisableClass(selectedHomePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType)
                            ?
                            <button 
                                className="hblib-list-add-btn" type="button" 
                                onClick={(e:React.MouseEvent)=>addHomePageLayoutItemList(selectedHomePageLayoutItems)}>Add</button>
                            :
                            null

                        }
                        
                        <button className="hblib-list-close-btn" type="button" onClick={cancelHomePageLayoutItemList}>Cancel</button>

                    </div>
                </div>
    }

    function setupHomePageLayoutItems(itemsList:object[]):JSX.Element{
        //console.log('itemsList24:', itemsList, 'sis:', selectedHomePageLayoutItems);
        //const st = new SliderType({type:'carousel', heading:'Selected Items'});
        return <div className="hplib-list-wrapper">
            {/*}
                    <div className="hblib-list-btn-container">
                        <button className="hblib-list-close-btn" type="button" onClick={closeHomePageLayoutItemList}>Close</button>
                        <button className="hblib-list-add-btn hblib-list-add-btn-disable" 
                            ref={addBtnRef} type="button"onClick={closeHomePageLayoutItemList}>Add</button>
                    </div>
                    {setupSelectedHomePageItemCounter(selectedHomePageLayoutItems, homePageLayoutType)}

                    */}

                    {/*
                        (selectedHomePageLayoutItems.length > 0)
                        ? 
                        <ImageSlider3 sliderType={st} items={selectedHomePageLayoutItems} 
                            setupItemsCallback={setupItemsCallback} 
                            optionalHeader={setupOptionalHeader(selectedHomePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType)}/>

                        : null
                    */}

                    <div className="hplib-container">
                                {(itemsList as HomePageLayoutItem[]).map((hpli)=>{
                                return (<div key={getHomePageLayoutItemKey(homePageLayoutType, hpli)} className="hplib-item-container">
                                            <HomePageLayoutItemDisplay type={homePageLayoutType} item={hpli}
                                                selectedHomePageLayoutItems={selectedHomePageLayoutItems}
                                                existingHomePageLayoutItems={existingHomePageLayoutItems}
                                                displayExceededSliderQtyMessage={displayExceededSliderQtyMessage}
                                              updateHomePageLayoutItemLists={updateHomePageLayoutItemLists} getImageRef={getImageRef}/>
                                        </div>);
                                })}
                    </div>
                </div>
    }


    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as HomePageLayout[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);

            //show list of images selected
            if(selectedHomePageLayoutItems.length > 0){
                window.requestAnimationFrame(hplibRAF);
            }

        },100);     

    }

    function displayListSorter(itemsPerList:number, hplis:HomePageLayoutItem[]):void {
        if(hplis.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function hideImageSliderPicker(e:React.MouseEvent):void {
        itemSliderPickerWrapperRefs.current[0]!.style.position = 'absolute';

        if(cp.current >= (scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32))){
            itemSliderPickerWrapperRefs.current[0].style.top = `${scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32) - ((window.innerWidth >= 480) ? 168 : 152)}px`;

        } else {
            itemSliderPickerWrapperRefs.current[0]!.style.top = `${cp.current - ((window.innerWidth >= 480) ? 168 : 152)}px`;
        }

        itemSliderPickerWrapperRefs.current[0]!.style.transform = 'translateX(100%)';
        itemSliderPickerWrapperRefs.current[0]!.style.transition = 'transform .3s ease-in-out';
        itemSliderPickerWrapperRefs.current[0].style.boxShadow = 'none';

        imagePickerShowBtnContainerRefs.current[0]!.style.display = 'flex';

        imgSliderPickerInnerWrapperRefs.current[0]!.style.opacity = '0';
        imgSliderPickerInnerWrapperRefs.current[0]!.style.transition = 'opacity .3s ease-in-out';

        imgSliderPickerOverlayRef.current!.classList.remove('hplib-item-slider-picker-overlay-show');
        imgSliderPickerOverlayRef.current!.classList.add('hplib-item-slider-picker-overlay-hide');
    }

    function showImageSliderPicker(e:React.MouseEvent):void {
        
        itemSliderPickerWrapperRefs.current[0]!.style.position = 'absolute';

        if(cp.current >= (scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32))){
            itemSliderPickerWrapperRefs.current[0].style.top = `${scrollStopPosition.current - ((window.innerWidth >= 480) ? 16 : 32) - ((window.innerWidth >= 480) ? 168 : 152)}px`;

        } else {
            itemSliderPickerWrapperRefs.current[0]!.style.top = `${cp.current - ((window.innerWidth >= 480) ? 168 : 152)}px`;
        }
        
        itemSliderPickerWrapperRefs.current[0]!.style.transform = 'translateX(0%)';
        itemSliderPickerWrapperRefs.current[0]!.style.transition = 'transform .3s ease-in-out';
        itemSliderPickerWrapperRefs.current[0].style.boxShadow = 'none';// '0px 0px 8px -2px #414142';

        if(cp.current >= 111 + ((window.innerWidth >= 480) ? 168 : 152)){
            itemSliderPickerWrapperRefs.current[0].style.borderBottomLeftRadius = '8px';
            itemSliderPickerWrapperRefs.current[0].style.borderBottomRightRadius = '8px';

        } else {
            itemSliderPickerWrapperRefs.current[0].style.borderRadius = '0';
        }
        

        imagePickerShowBtnContainerRefs.current[0]!.style.display = 'none';

        imgSliderPickerInnerWrapperRefs.current[0]!.style.opacity = '1';
        imgSliderPickerInnerWrapperRefs.current[0]!.style.transition = 'opacity .3 ease-in-out';

        imgSliderPickerOverlayRef.current!.classList.remove('hplib-item-slider-picker-overlay-hide');
        imgSliderPickerOverlayRef.current!.classList.add('hplib-item-slider-picker-overlay-show');
    }


    function displayExceededSliderQtyMessage(qty:number):void {
        setNoSearchResultsMessage(`You have exceeded the image container limit of ${qty} ${(qty === 1) ? 'item' : 'items'}.  Remove at least one item to continue adding images.`);
        setShowNoSearchResultsFound(true);

        const root = document.documentElement;
        root.scrollIntoView({behavior:'smooth'});
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setNoSearchResultsMessage('');
        setShowNoSearchResultsFound(false);
    }


    if(homePageLayoutItems.length === 0 && selectedHomePageLayoutItems.length === 0 && !isLoading){
        return <div ref={getHPLIBwrapperRef} className="hplib-wrapper">
                    <h1 className="home-page-layout-builder-heading">Layout Items</h1>
                    <div className="hblib-list-close-btn-container">
                        <button className="hblib-list-close-btn" type="button" 
                                onClick={cancelHomePageLayoutItemList}>Cancel
                        </button>
                    </div>
                    
                    {
                        showErrorMessage
                        ?
                        <div className="">
                            <GenericErrorMessage/>
                        </div>
                        :
                        <div className="hplib-info-msg-wrapper">
                            {/*<GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>*/}
                            {noSearchResultsMessage}
                        </div>
                    }

                </div>
    } else if((homePageLayoutItems.length > 0 || selectedHomePageLayoutItems.length > 0) && !isLoading) {
        return <div ref={getHPLIBwrapperRef} className="hplib-wrapper">
                    <h1 className="home-page-layout-builder-heading">Layout Items</h1>
                    {
                        (selectedHomePageLayoutItems.length > 0)
                        ?
                        <div ref={getItemSliderPickerWrapperRef} className="hplib-item-slider-picker-wrapper">
    
                            <div ref={getImagePickerShowBtnContainerRef} className="img-slider-picker-btn-show-container">
                                <button type="button" className="img-slider-picker-btn-show"
                                    onClick={showImageSliderPicker}>Cart
                                </button>
                            </div>
                            
                            
                            <div ref={getImgSliderPickerInnerWrapperRef} className="hplib-img-slider-picker-inner-wrapper">

                                {setupOptionalHeader(selectedHomePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType)}

                                {
                                    (!reloadImageSlider)
                                    ?
                                    <ImageSlider3 sliderType={sliderType} items={selectedHomePageLayoutItems} 
                                    setupItemsCallback={setupItemsCallback} 
                                    optionalHeader={false}/>
                                    :
                                    null
                                }
                                

                                {
                                    (reloadImageSlider)
                                    ?
                                    <div className="hplib-loader-animation-container">
                                        <LoaderAnimation/>
                                    </div>
                                    :
                                    null
                                }

                            </div>

                        </div>
                        :
                        null
                    }

                    
                    <div ref={getImageSliderPickerWrapperDoubleRef} className="hplib-item-slider-picker-wrapper-double">
                    {
                        selectedHomePageLayoutItems.length === 0
                        ? 
                        <div className="hblib-list-close-btn-container">
                            <button className="hblib-list-close-btn" type="button" 
                                    onClick={cancelHomePageLayoutItemList}>Cancel
                            </button>
                        </div>
                        : 
                        null
                    }
                    
                    {
                        (selectedHomePageLayoutItems.length == 0)
                        ?
                        <div className="hplib-empty-item-slider-picker-container">
                            <div className="hplib-empty-item-slider-msg-container">
                                <h2 className="hplib-empty-item-slider-msg-heading">No images have been selected.</h2>  
                                <p className="hplib-empty-item-slider-msg">Select an image below to get started.</p>
                            </div>
                            <div className="hplib-empty-item-slider-img-container">
                                <img className="hplib-empty-item-slider-img" src="https://server.kando-proto-3.com/image-outline-icon.svg"/>
                            </div>
                        </div>
                        :
                        null

                    }
                    </div>

                    {
                        showNoSearchResultsFound
                        ? <div className="hblib-generic-info-msg-wrapper">
                            <GenericInfoMessage infoMessage={noSearchResultsMessage} 
                                showExtraMsgContainer={true} closeInfoMsgHandler={closeInfoMsgHandler}
                                showCloseBtn={true}/>
                          </div>
                        :
                        null
                    }

                    {
                        homePageLayoutItems.length > 0
                        ? 
                        <ListViewer itemsList={itemsList} items={homePageLayoutItems} showListSorter={showListSorter}
                            listChangerBtnPosition={listChangerBtnPosition}
                            itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                            getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupHomePageLayoutItems}
                            adjContainerStyle={{width:'calc(100% - 32px)', margin:'16px auto 0'}} />
                        :
                        null
                    }

                    <div ref={imgSliderPickerOverlayRef} className="hplib-item-slider-picker-overlay"></div>
                </div>
    } else {
        return <div className="hplib-wrapper">
                    <h1 className="home-page-layout-builder-heading">Layout Items</h1>
                    <div className="aprs-loaeder-animation-container">
                        <LoaderAnimation/>
                    </div>
                </div>
    }


}

export default HomePageLayoutItemBuilder;