//Recaptcha.tsx
import React, {useState, useEffect, useRef, forwardRef} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import './Recaptcha.css';

interface Props {
    dataCallback:(responseToken:string)=>void;
    expiredCallback:()=>void;
    errorCallback:()=>void;
}

type Ref = HTMLDivElement;

//const Recaptcha: React.FunctionComponent<Props> = (props:Props) => {
const Recaptcha = forwardRef<Ref, Props>((props, ref)=>{
    const {dataCallback, expiredCallback, errorCallback} = props;
    const loaded = useRef<boolean>(false);

    const recaptchaElementRef = useRef<HTMLDivElement>(null);
    const portrait = useRef<MediaQueryList[]>([]);

    function verifyCallback(tr:string):void {
        dataCallback(tr);
        //grecaptcha.reset();
    }


    useEffect(()=>{
        if(!loaded.current){        
            grecaptcha.render('recaptcha_element', {
            'sitekey' : '6LdDQhIpAAAAAOCcNfTBwqx7F8BTehP7WkPoILz6',
            'callback' : verifyCallback,
            'expired-callback':expiredCallback,
            'error-callback':errorCallback
            });

            loaded.current = true;            
            
        }

    },[]);

    useEffect(()=>{

        if(window.innerWidth >= 364){
            recaptchaElementRef.current!.style.transform = 'none';
        } 
        
        if(window.innerWidth < 364){
            const wp = window.innerWidth / 364;
            recaptchaElementRef.current!.style.transform = `scale(${wp})`;
        }

        window.addEventListener('resize', recaptchaResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", recaptchaResizeHandler);

        return cleanupResizeHandler;
    },[]);

    function recaptchaResizeHandler(e:Event):void {
        //console.log('resize');
        if(window.innerWidth >= 364){
            recaptchaElementRef.current!.style.transform = 'none';


        } 
        
        if(window.innerWidth < 364){
            const wp = window.innerWidth / 364;
            recaptchaElementRef.current!.style.transform = `scale(${wp})`;
        }
    }

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', recaptchaResizeHandler);
        portrait.current[0].removeEventListener("change", recaptchaResizeHandler);
    }

    return <div ref={ref} className="recaptcha-wrapper">
                <div className="recaptcha-caption-logo-wrapper">
                    <div className="recaptcha-caption-container">
                        <p className="recatpcha-text">www.kando-proto-3.com</p>
                        <p className="recatpcha-text">Additional security check is required</p>
                    </div>
                    <div className="recaptcha-logo-container-wrapper">
                        <div className="recaptcha-logo-container">
                            <div className="product-search-logo recaptcha-logo">SHOP</div>
                        </div>
                    </div>
                </div>
                <div className="recaptcha-element-wrapper">
                    <div ref={recaptchaElementRef} id="recaptcha_element"></div>
                </div>        
            </div>

});

export default Recaptcha;