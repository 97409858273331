import React, {useState, useEffect} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import StoreStructureOptionSelect from './StoreStructureOptionSelect';
import SearchFilter, {SearchTerm} from './searchFilter';
import StoreStructureLoadingMsg from './StoreStructureLoadingMsg';
import StoreStructureAlert from './StoreStructureAlert';
import showSearchFilterFormErrorMsgs from './helper-show-search-filter-form-error-msgs';
import isSearchFilterAreaNameSelected from './helper-is-search-filter-area-name-selected';
import {getDepartmentNamesPromise, 
        getDepartmentCategoryNamesPromise, 
        getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import DbResponse from './db-response';
import LoaderAnimation from './LoaderAnimation';
import './EditSearchFilter.css';

interface Props {
    showEditSearchFilter:boolean;
    showDepartment:boolean;
    showDepartmentCategory:boolean;
    showProductCategory:boolean;
    deptNameSelected:string;
    deptCategoryNameSelected:string;
    productCategoryNameSelected:string;
    searchFilterNameSelected:string;
    searchFilterSelected:boolean;
    showSearchFilter:boolean;
    searchFilterAreaNameSelected:string;
    searchFilterAreaTypeSelected:string;
    searchFilterIdSelected:number;
    handleCancelSearchFilter:()=>void;
    handleUpdateSearchFilter:(
        /*deptNameSelected:string,
        deptCategoryNameSelected:string,
        productCategoryNameSelected:string,*/
        /*searchFilterIdSelected:number,
        searchFilterAreaTypeSelected:string,
        searchFilterAreaNameSelected:string,
        searchFilterNameSelected:string,
        searchFilterSelected:boolean,
        showSearchFilter:boolean*/
        searchFilters:SearchFilter[]
                    )=>void;
}

const EditSearchFilter:React.FunctionComponent<Props> = (props:Props)=>{
    const {showEditSearchFilter, handleCancelSearchFilter, handleUpdateSearchFilter} = props;
    const [deptNames, setDeptNames] = useState<string[]>([]);
    const [deptNameSelected, setDeptNameSelected] = useState(props.deptNameSelected);
    const [deptCategoryNames, setDeptCategoryNames] = useState<string[]>([]);
    const [deptCategoryNameSelected, setDeptCategoryNameSelected] = useState(props.deptCategoryNameSelected);
    const [productCategoryNames, setProductCategoryNames] = useState<string[]>([]);
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState(props.productCategoryNameSelected);
    const [searchFilterNameSelected, setSearchFilterNameSelected] = useState(props.searchFilterNameSelected);
    const [searchFilterIdSelected, setSearchFilterIdSelected] = useState(props.searchFilterIdSelected);
    const [searchFilterSelected, setSearchFilterSelected] = useState(props.searchFilterSelected);
    const [showSearchFilter, setShowSearchFilter] = useState(props.showSearchFilter);
    const [showDepartment, setShowDepartment] = useState(props.showDepartment);
    const [showDepartmentCategory, setShowDepartmentCategory] = useState(props.showDepartmentCategory);
    const [showProductCategory, setShowProductCategory] = useState(props.showProductCategory);
    const [searchFilterAreaNameSelected, setSearchFilterAreaNameSelected] = useState(props.searchFilterAreaNameSelected);
    const [searchFilterAreaTypeSelected, setSearchFilterAreaTypeSelected] = useState(props.searchFilterAreaTypeSelected);
    const [searchFilterNameErrorMsg, setSearchFilterNameErrorMsg] = useState('Please add a search filter name');
    const [showSearchFilterNameErrorMsg, setShowSearchFilterNameErrorMsg] = useState(false);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('no msg');
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [storeStructureLoadingMsg, setStoreStructureLoadingMsg] = useState('loading');
    //const [showStoreStructureLoadingMsg, setShowStoreStructureLoadingMsg] = useState(false);
    const [showSearchFilterAreaNameErrorMsg, setShowSearchFilterAreaNameErrorMsg] = useState(false);
    const [searchFilterDeptNameErrorMsg, setSearchFilterDeptNameErrorMsg] = useState('Please add a department name');
    const [searchFilterDeptCategoryNameErrorMsg, setSearchFilterDeptCategoryNameErrorMsg] = useState('Please add a department category name');
    const [searchFilterProductCategoryNameErrorMsg, setSearchFilterProductCategoryNameErrorMsg] = useState('Please add a product category name');

    const [isLoading, setIsLoading] = useState(true);
    const [showSearchFilterNameLimitError, setShowSearchFilterNameLimitError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    //console.log('dns:', deptNameSelected);
    //console.log('sfs:', searchFilterSelected);
    //console.log('ssf:', showSearchFilter);
    useEffect(()=>{
        Promise.all([getDepartmentNamesPromise(), getDepartmentCategoryNamesPromise(), getProductCategoryNamesPromise()])
        .then(([departments, departmentCategories, productCategories])=>{
            //console.log('dns:', deptNames);
            //console.log('dcns:', deptCategoryNames);
            //console.log('pcns:', productCategoryNames);
            const dns = getDepartmentNames(departments);
            setDeptNames([...dns]);
            const dcns = getDepartmentCategorNames(departmentCategories);
            setDeptCategoryNames([...dcns]);
            const pcns = getProductCategorNames(productCategories);
            setProductCategoryNames([...pcns]);

            setIsLoading(false);
        })
        .catch((err)=>console.error('promise all for names error:', err));

    },[]);

    function getDepartmentNames(ds:StoreDepartment[]):string[] {
        return ds.map((d)=>{
            return d.departmentName;
        })
    }

    function getDepartmentCategorNames(dcs:DepartmentCategory[]):string[] {
        return dcs.map((dc)=>{
            return dc.departmentCategoryName;
        })
    }

    function getProductCategorNames(pcs:ProductCategory[]):string[] {
        return pcs.map((pc)=>{
            return pc.productCategoryName;
        })
    }

    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        setShowStoreStructureAlert(false);
    }

    function handleDeptNameSelection(e:React.ChangeEvent):void {
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setShowDepartmentCategory(false);
            setShowProductCategory(false);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('Department');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(false);
        } else {
            setShowDepartmentCategory(true);
            setShowProductCategory(true);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(true);
        }
    }

    function handleDeptCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('dcns:', (e.target as HTMLSelectElement).value);
        const dcns = (e.target as HTMLSelectElement).value;
        if(dcns !== 'none'){
            setShowDepartment(false);
            setShowProductCategory(false);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('Department Category');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(false);        
        } else {
            setShowDepartment(true);
            setShowProductCategory(true);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(true);
        }
    }

    function handleProductCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('pcns:', (e.target as HTMLSelectElement).value);
        const pcns = (e.target as HTMLSelectElement).value;
        if(pcns !== 'none'){
            setShowDepartment(false);
            setShowDepartmentCategory(false);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('Product Category');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(false);        
        } else {
            setShowDepartment(true);
            setShowDepartmentCategory(true);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(true);
        }
    }

    function handleAddSearchFilterName(e:React.ChangeEvent):void {
        const searchFilterName:string = (e.target as HTMLInputElement).value;
        /*previous version
        if(searchFilterName === ''){
            setShowSearchFilterNameErrorMsg(true);
            setSearchFilterNameErrorMsg('Please add a search filter name');
        } else {
            setShowSearchFilterNameErrorMsg(false);
        }
        setSearchFilterNameSelected(searchFilterName);
        */

        //console.log('value.length:', searchFilterName.length);

        if(searchFilterName.length === 0){
            setShowSearchFilterNameLimitError(false);
            setShowSearchFilterNameErrorMsg(true);
            setSearchFilterNameErrorMsg('Please add a search filter name');
            setSearchFilterNameSelected(searchFilterName);

        } else if(searchFilterName.length > 0 && searchFilterName.length <= 25){
            setShowSearchFilterNameErrorMsg(false);
            setShowSearchFilterNameLimitError(false);
            setSearchFilterNameSelected(searchFilterName);

        } else {
            setShowSearchFilterNameErrorMsg(false);
            setShowSearchFilterNameLimitError(true);
        }


    }

    function handleSearchFilterSelected(e:React.ChangeEvent):void {
        //console.log('sfsc:', (e.target as HTMLInputElement).checked);
        setSearchFilterSelected((e.target as HTMLInputElement).checked);
    }
    

    function handleShowSearchFilter(e:React.ChangeEvent):void {
        //console.log('ssfc:', (e.target as HTMLInputElement).checked);
        setShowSearchFilter((e.target as HTMLInputElement).checked);
    }

    function handleSubmitSearchFilterForm(e:React.FormEvent):void {
        e.preventDefault();
        const showErrorMsgs:boolean = showSearchFilterFormErrorMsgs({
                                                        searchFilterNameSelected,
                                                        deptNameSelected,
                                                        deptCategoryNameSelected,
                                                        productCategoryNameSelected,
                                                        isSearchFilterAreaNameSelected,
                                                        setShowSearchFilterNameErrorMsg,
                                                        setSearchFilterNameErrorMsg,
                                                        setShowSearchFilterAreaNameErrorMsg,
                                                        setShowSearchFilterNameLimitError
                                                                    });

        if(showErrorMsgs){
            return;
            
        } else {

            setShowProcessingRequestMsg(true);

            //finish saving search filter to database
            const sfToAdd = new SearchFilter({
                searchFilterId:searchFilterIdSelected,
                searchFilterArea:searchFilterAreaNameSelected,
                searchFilterAreaType:searchFilterAreaTypeSelected,
                filterName:searchFilterNameSelected,
                showFilter:showSearchFilter,
                searchFilterSelected:searchFilterSelected
            });

            //console.log('sfta:', sfToAdd);
            const searchFilter = JSON.stringify(sfToAdd);
            
            //setIsLoading(true);

            //previous version
            //fetch(`http://localhost:9500/update-search-filter-only/${searchFilter}`,{
            fetch(`https://server.kando-proto-3.com/update-search-filter-only`,{
                method:'PUT',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:searchFilter
            })
            .then(resp=>resp.json())
            .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                //console.log('dbrm:', dbResponseMsg);
                //console.log('dbrs:', dbResponseRecords);

                setShowProcessingRequestMsg(false);
                
                if(dbResponseMsg === 'record updated'){
                    handleUpdateSearchFilter(
                                /*searchFilterIdSelected,
                                searchFilterAreaTypeSelected,
                                searchFilterAreaNameSelected,
                                searchFilterNameSelected,
                                searchFilterSelected,
                                showSearchFilter,*/
                                dbResponseRecords as SearchFilter[]
                                    );

                    //setIsLoading(false);
                
                } else if(dbResponseMsg === 'record matched another search filter'){
                    setShowSearchFilterNameErrorMsg(true);
                    setSearchFilterNameErrorMsg('Search filter name already exists');

                    //setIsLoading(false);

                } else {
                    throw new Error('search filter updated error.');
                }

                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
            })
            .catch(err=>console.error('add search filter fetch err:', err));
        }

    }

    function handleCancelEditSearchFilter(e:React.MouseEvent):void {
        handleCancelSearchFilter();

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    }

    if(showEditSearchFilter){
        return <div>
                    {/*<StoreStructureLoadingMsg
                        showStoreStructureLoadingMsg={showStoreStructureLoadingMsg}
                        storeStructureLoadingMsg={storeStructureLoadingMsg}
                    />*/}
                    <StoreStructureAlert 
                        showStoreStructureAlert={showStoreStructureAlert}
                        storeStructureAlertMsg={storeStructureAlertMsg}
                        handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />
                    <h1 className="edit-search-filter-heading">Edit Search Filter</h1>

                    {
                        !isLoading
                        ?
                    <form className="add-dpt-category-form" onSubmit={handleSubmitSearchFilterForm}>
                        {showDepartment ? 
                        <div className="edit-search-filter-input-form-group">
                            <label htmlFor="dpt-name">Department Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={deptNameSelected}
                                    optionNames={deptNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterDeptNameErrorMsg}
                                    selectId={"dpt-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showDepartmentCategory ?
                        <div className="edit-search-filter-input-form-group">
                            <label htmlFor="dpt-category-name">Department Category Name</label>
                            <StoreStructureOptionSelect 
                                    defaultValue='none'
                                    optionNameSelected={deptCategoryNameSelected}
                                    optionNames={deptCategoryNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterDeptCategoryNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptCategoryNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showProductCategory ?
                        <div className="edit-search-filter-input-form-group">
                            <label htmlFor="product-category-name">Product Category Name</label>
                            <StoreStructureOptionSelect 
                                    defaultValue='none'
                                    optionNameSelected={productCategoryNameSelected}
                                    optionNames={productCategoryNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterProductCategoryNameErrorMsg}
                                    selectId={"product-category-name"}
                                    handleOptionNameSelection={handleProductCategoryNameSelection}
                            />
                        </div>
                        : null
                        }
                        <div className="edit-search-filter-input-form-group">
                            <label htmlFor="search-filter-name">Search Filter Name</label>
                            <input type="text" value={searchFilterNameSelected} id="search-filter-name"
                             className={showSearchFilterNameErrorMsg ? 'form-input-control show-edit-search-filter-name-input-error-border' : 'form-input-control hide-edit-search-filter-name-input-error-border'}
                             onChange={handleAddSearchFilterName}/>
                            {
                                showSearchFilterNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (25)</p>
                                :
                                null
                            }

                            {
                                showSearchFilterNameErrorMsg 
                                ? 
                                <div className="show-edit-search-filter-name-error-msg">
                                    {searchFilterNameErrorMsg}
                                </div>
                                : 
                                null
                            }
                        </div>
                        {/*<div className="edit-search-filter-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="search-filter-selected" id="search-filter-selected"
                             checked={searchFilterSelected} onChange={handleSearchFilterSelected}/>
                            <label htmlFor="search-filter-selected">Search Filter Selected</label>
                        </div>*/}
                        <div className="edit-search-filter-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="show-search-filter" id="show-search-filter"
                             checked={showSearchFilter} onChange={handleShowSearchFilter}/>
                            <label htmlFor="show-search-filter">Show Search Filter</label>
                        </div>
                        <div className="product-form-submit-btn-container">
                            <button type="submit" className="add-search-filter-save-btn">Save</button>
                            <button type="button" className="add-search-filter-cancel-btn"
                                onClick={handleCancelEditSearchFilter}>
                                    Cancel
                            </button>
                            {
                                (showProcessingRequestMsg)
                                ?
                                <div className="process-request-msg">
                                    Processing...
                                </div>
                                :
                                null
                            }
                        </div>
                    </form>
                    :
                    <div className="ssdcs-loader-animation-container">
                        <LoaderAnimation/>
                    </div>
                }
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>;
    }

}

export default EditSearchFilter;