import React, {useState, useEffect} from 'react';
import {connect, MapDispatchToProps} from 'react-redux';
import './ButtonCheckSelector.css';
import StoreLocation from './storeLocation';
//import {addStoreLocation, AddStoreLocation} from './redux/actions';
import {addStoreLocation} from './redux/features/storeLocation/storeLocationSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';

interface Props {
    storeLocation:StoreLocation;
    //original version
    //storeName:string;
    storeNameChecked:string;
    setMyCheckedStoreName:(storeNameChecked:string)=>void;
    //addStoreLocation?:(storeLocation:StoreLocation)=>AddStoreLocation;
    
}

/*
const mapDispatchToProps = {
    addStoreLocation
};
*/

const ButtonCheckSelector:React.FunctionComponent<Props> = (props:Props) => {
    const {storeLocation, storeNameChecked, setMyCheckedStoreName/*, addStoreLocation*/} = props;
    const [btnChecked, setBtnChecked] = useState(false);
    const dispatch = useAppDispatch();
    //const [storeName, setStoreName] = useState(existingStoreName);
    //console.log('btnChecked:', btnChecked);
    //console.log('storeName:', storeLocation.storeName);
    function handleButtonChecked(e:React.MouseEvent, storeLocation:StoreLocation){
        e.stopPropagation();
        
        btnChecked ? setBtnChecked(false) : setBtnChecked(true);
        setMyCheckedStoreName(storeLocation.storeName);
        //update my store name
        //(addStoreLocation as (storeLocation:StoreLocation)=>AddStoreLocation)(storeLocation);
        dispatch(addStoreLocation(storeLocation));
        //console.log('store name:', storeName);
    }

    /*
    useEffect(()=>{
        console.log('buttonCheckSelect useEffect()');
    },[storeNameChecked]);
    */

    if(storeNameChecked === storeLocation.storeName){
        return <button type="button" className="store-details-check-btn-checked"><svg className="store-details-check-btn-checked-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M4.11112 11.6423L14.3236 2.26348C14.7304 1.88991 15.363 1.91683 15.7365 2.3236C16.1101 2.73037 16.0832 3.36297 15.6764 3.73654L4.78752 13.7365C4.405 14.0878 3.81723 14.0878 3.43471 13.7365L0.323601 10.8794C-0.0831736 10.5058 -0.110092 9.87323 0.263476 9.46646C0.637044 9.05968 1.26964 9.03276 1.67641 9.40633L4.11112 11.6423Z" fill="currentColor"></path></svg><span>My Store</span></button>
    } else {
        return <button type="button" className="store-details-check-btn" onClick={(e:React.MouseEvent)=>handleButtonChecked(e, storeLocation)}>Make My Store</button>
    }
    
}

export default ButtonCheckSelector;
/*previous version with connect()
export default connect(
    null,
    mapDispatchToProps
)(ButtonCheckSelector);
*/