import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StoreServiceArea from '../../../storeServiceArea';
import cloneDeep from 'lodash/cloneDeep';

interface Availability {
  id:number;
  order:number;
  day:string;
  hours:string;
}

interface StoreLocation {
  storeName:string;
  location:{lat:number, lng:number};
  streetAddress:string;
  city:string;
  state:string;
  zipCode:string;
  storePhone:string;
  storeHours:Availability[];
  pharmacyPhone:string;
  pharmacyHours:Availability[];
  pharmacyServices:string[];
  curbsidePickup:boolean;
  distance:number;
  order:number;
  storeServiceAreas:StoreServiceArea[];
  //storeServices:string[];
  storeNumber:number;
  storeLocationFound:boolean;
}

const initialState: StoreLocation = {
  storeName:'',
  location:{lat:0, lng:0},
  streetAddress:'',
  city:'',
  state:'',
  zipCode:'',
  storePhone:'',
  storeHours:[{id:0, order:0, day:'Mon-Sun', hours:'8:00 AM - 8:00 PM'}],
  pharmacyPhone:'',
  pharmacyHours:[],
  pharmacyServices:[],
  curbsidePickup:false,
  distance:0,
  order:0,
  storeServiceAreas:[],
  //storeServices:[],
  storeNumber:0,
  storeLocationFound:false
};

export const storeLocationSlice = createSlice({
  name: 'storeLocation',
  initialState,
  reducers: {
    addStoreLocation: (state, action:PayloadAction<StoreLocation>) => {
      //console.log('action:', action);
      state.storeName = action.payload.storeName;
      state.location = action.payload.location;
      state.streetAddress = action.payload.storeName;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.zipCode = action.payload.zipCode;
      state.storePhone = action.payload.storePhone;
      state.storeHours = action.payload.storeHours;
      state.pharmacyPhone = action.payload.pharmacyPhone;
      state.pharmacyHours = action.payload.pharmacyHours;
      state.pharmacyServices = action.payload.pharmacyServices;
      state.curbsidePickup = action.payload.curbsidePickup;
      state.distance = action.payload.distance;
      state.order = action.payload.order;
      state.storeServiceAreas = action.payload.storeServiceAreas;
      //state.storeServices = action.payload.storeServices;
      state.storeNumber = action.payload.storeNumber;
      state.storeLocationFound = action.payload.storeLocationFound;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addStoreLocation } = storeLocationSlice.actions;

interface RootState {
  storeLocation: StoreLocation;
}

export const selectStoreLocation = (state:RootState) =>  {

  const clone = cloneDeep(state.storeLocation);
  return clone;

  /*
  return  {
    storeName:state.storeLocation.storeName,
    location:state.storeLocation.location,
    streetAddress:state.storeLocation.streetAddress,
    addressLocality:state.storeLocation.addressLocality,
    storePhone:state.storeLocation.storePhone,
    storeHours:state.storeLocation.storeHours,
    pharmacyPhone:state.storeLocation.pharmacyPhone,
    pharmacyHours:state.storeLocation.pharmacyHours,
    curbsidePickup:state.storeLocation.curbsidePickup,
    distance:state.storeLocation.distance,
    order:state.storeLocation.order,
    storeDepartments:state.storeLocation.storeDepartments,
    storeServices:state.storeLocation.storeServices,
    storeNumber:state.storeLocation.storeNumber,
    storeLocationFound:state.storeLocation.storeLocationFound
  };
  */
}

export default storeLocationSlice.reducer;