import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import ProductKeywordSearch from '../../productKeywordSearch';
import SearchFilter from '../../../searchFilter';
import Product from '../../../product';
import cloneDeep from 'lodash/cloneDeep';

export interface SearchFilterParams {
    searchFilterSelected:string;
    searchTermSelected:string;
    searchTermIsSelected:boolean;
    storeDepartmentId:string;
    otherStoreAreaId:string;
    storeAreaName:string;
    storeAreaType:string;
    keywordSearchType:string;
    showProductSubCategoryFilter:boolean;
    noSearchFilterAttached:boolean;
    searchFilters:SearchFilter[];
    products:Product[];
}

const initialState:SearchFilterParams = {
    searchFilterSelected:'',
    searchTermSelected:'',
    searchTermIsSelected:false,
    storeDepartmentId:'0',
    otherStoreAreaId:'0',
    storeAreaName:'',
    storeAreaType:'',
    keywordSearchType:'',
    showProductSubCategoryFilter:true,
    noSearchFilterAttached:false,
    searchFilters:[],
    products:[]
};

export const searchFilterParamsSlice = createSlice({
  name: 'searchFilterParams',
  initialState,
  reducers: {
    addSearchFilterParams: (state, action:PayloadAction<SearchFilterParams>) => {
      state.searchFilterSelected = action.payload.searchFilterSelected;
      state.searchTermSelected = action.payload.searchTermSelected;
      state.searchTermIsSelected = action.payload.searchTermIsSelected;
      state.storeDepartmentId = action.payload.storeDepartmentId;
      state.otherStoreAreaId = action.payload.otherStoreAreaId;
      state.storeAreaName = action.payload.storeAreaName;
      state.storeAreaType = action.payload.storeAreaType;
      state.keywordSearchType = action.payload.keywordSearchType;
      state.showProductSubCategoryFilter = action.payload.showProductSubCategoryFilter;
      state.noSearchFilterAttached = action.payload.noSearchFilterAttached;
      state.searchFilters = action.payload.searchFilters;
      state.products = action.payload.products;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addSearchFilterParams } = searchFilterParamsSlice.actions;

interface RootState {
  searchFilterParams: SearchFilterParams
}

export const selectSearchFilterParams = (state:RootState) =>  {
  const clone = cloneDeep(state.searchFilterParams);
  //return state.searchFilterParams;
  return clone;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default searchFilterParamsSlice.reducer;