import React from 'react';
//import {navigate} from '@gatsbyjs/reach-router';
import {NavigateFunction } from "react-router-dom";

function loadNavLink(e:React.MouseEvent, pathname:string, navigate:NavigateFunction):void {
    e.preventDefault();
    //console.log('what:');
    //console.log('posy14:', window.scrollY);

    //console.log('link:', encodeURIComponent(pathname));

    //console.log('t5:');

    //window.sessionStorage.setItem('admin-scroll-position', `${window.scrollY}`);

    let root = document.documentElement;
    root!.setAttribute('style', 'scroll-behavior: auto');
    root.scrollTo(0,0);
    navigate(pathname);
    window.setTimeout(()=>{
        root!.removeAttribute('style');
    },100);

    
}

export default loadNavLink;