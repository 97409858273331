import React, {useState, useEffect} from 'react';
import {Link} from '@reach/router';
import StoreStructureOptionSelect from './StoreStructureOptionSelect';
import SearchFilter, {SearchTerm} from './searchFilter';
import StoreStructureLoadingMsg from './StoreStructureLoadingMsg';
import StoreStructureAlert from './StoreStructureAlert';
import showSearchTermFormErrorMsgs from './helper-show-search-term-form-error-msgs';
import isSearchFilterAreaNameSelected from './helper-is-search-filter-area-name-selected';
import {getDepartmentNamesPromise, 
        getDepartmentCategoryNamesPromise, 
        getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import DbResponse from './db-response';
import LoaderAnimation from './LoaderAnimation';
import './EditSearchTerm.css';

interface Props {
    showEditSearchTerm:boolean;
    showDepartment:boolean;
    showDepartmentCategory:boolean;
    showProductCategory:boolean;
    deptNameSelected:string;
    deptCategoryNameSelected:string;
    productCategoryNameSelected:string;
    searchFilterNameSelected:string;
    searchTermNameSelected:string;
    searchTermIdSelected:number;
    searchTermImgSelected:string;
    showSearchTermImg:boolean;
    showSearchTerm:boolean;
    searchTermSelected:boolean;
    searchFilterAreaNameSelected:string;
    searchFilterAreaTypeSelected:string;
    handleCancelSearchTerm:()=>void;
    handleUpdateSearchTerm:(
        /*deptNameSelected:string,
        deptCategoryNameSelected:string,
        productCategoryNameSelected:string,*/
        /*searchFilterIdSelected:number,
        searchFilterAreaTypeSelected:string,
        searchFilterAreaNameSelected:string,
        searchFilterNameSelected:string,
        searchFilterSelected:boolean,
        showSearchFilter:boolean*/
        searchTerms:SearchTerm[]
                    )=>void;
}

const EditSearchTerm:React.FunctionComponent<Props> = (props:Props)=>{
    const {showEditSearchTerm, handleCancelSearchTerm, handleUpdateSearchTerm} = props;
    const [deptNames, setDeptNames] = useState<string[]>([]);
    const [deptNameSelected, setDeptNameSelected] = useState(props.deptNameSelected);
    const [deptCategoryNames, setDeptCategoryNames] = useState<string[]>([]);
    const [deptCategoryNameSelected, setDeptCategoryNameSelected] = useState(props.deptCategoryNameSelected);
    const [productCategoryNames, setProductCategoryNames] = useState<string[]>([]);
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState(props.productCategoryNameSelected);
    const [searchFilterNameSelected, setSearchFilterNameSelected] = useState(props.searchFilterNameSelected);
    const [searchFilterNames, setSearchFilterNames] = useState<string[]>([]);
    const [searchTermNameSelected, setSearchTermNameSelected] = useState(props.searchTermNameSelected);
    const [searchTermIdSelected, setSearchTermIdSelected] = useState(props.searchTermIdSelected);
    const [newSearchTermImgSelected, setNewSearchTermImgSelected] = useState('');
    const [searchTermImgSelected, setSearchTermImgSelected] = useState(props.searchTermImgSelected);
    const [showSearchTermImg, setShowSearchTermImg] = useState(props.showSearchTermImg);
    const [showSearchTerm, setShowSearchTerm] = useState(props.showSearchTerm);
    const [searchTermSelected, setSearchTermSelected] = useState(props.searchTermSelected);
    const [showDepartment, setShowDepartment] = useState(props.showDepartment);
    const [showDepartmentCategory, setShowDepartmentCategory] = useState(props.showDepartmentCategory);
    const [showProductCategory, setShowProductCategory] = useState(props.showProductCategory);
    const [searchFilterAreaNameSelected, setSearchFilterAreaNameSelected] = useState(props.searchFilterAreaNameSelected);
    const [searchFilterAreaTypeSelected, setSearchFilterAreaTypeSelected] = useState(props.searchFilterAreaTypeSelected);
    const [searchTermNameErrorMsg, setSearchTermNameErrorMsg] = useState('Please add a search filter name');
    const [showSearchTermNameErrorMsg, setShowSearchTermNameErrorMsg] = useState(false);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('no msg');
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [storeStructureLoadingMsg, setStoreStructureLoadingMsg] = useState('loading');
    //const [showStoreStructureLoadingMsg, setShowStoreStructureLoadingMsg] = useState(false);
    const [showSearchFilterAreaNameErrorMsg, setShowSearchFilterAreaNameErrorMsg] = useState(false);
    const [searchFilterNameErrorMsg, setSearchFilterNameErrorMsg] = useState('Please add a search filter name');
    const [showSearchFilterNameErrorMsg, setShowSearchFilterNameErrorMsg] = useState(false);
    const [deptNameErrorMsg, setSearchFilterDeptNameErrorMsg] = useState('Please add a department name');
    const [deptCategoryNameErrorMsg, setSearchFilterDeptCategoryNameErrorMsg] = useState('Please add a department category name');
    const [productCategoryNameErrorMsg, setSearchFilterProductCategoryNameErrorMsg] = useState('Please add a product category name');

    const [isLoading, setIsLoading] = useState(true);
    const [showSearchTermNameLimitError, setShowSearchTermNameLimitError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    //console.log('dns:', deptNameSelected);
    //console.log('sftns:', searchFilterNameSelected);
    //console.log('stns:', searchTermNameSelected);
    //console.log('sst:', showSearchTerm);
    //console.log('sd:', showDepartment);
    //console.log('sdc:', showDepartmentCategory);
    //console.log('spc:', showProductCategory);
    //console.log('stId:', searchTermIdSelected);

    useEffect(()=>{
        if(showDepartment && !showDepartmentCategory && !showProductCategory){
            Promise.all([getDepartmentNamesPromise(), getRelatedSearchFilterNamesPromise(deptNameSelected)])
            .then(([departments, relatedSearchFilterNames])=>{
                //console.log('rsfns:', relatedSearchFilterNames);
                const dns = getDepartmentNames(departments);
                setDeptNames([...dns]);
                setSearchFilterNames([...relatedSearchFilterNames]);

                setIsLoading(false);
            })
            .catch((err)=>console.error('dept name error:', err));
        } else if(showDepartmentCategory && !showDepartment && !showProductCategory){
            Promise.all([getDepartmentCategoryNamesPromise(), getRelatedSearchFilterNamesPromise(deptCategoryNameSelected)])
            .then(([departmentCategories, relatedSearchFilterNames])=>{
                const dcns = getDepartmentCategorNames(departmentCategories);
                setDeptCategoryNames([...dcns]);
                setSearchFilterNames([...relatedSearchFilterNames]);

                setIsLoading(false);
            })
            .catch((err)=>console.error('dept category name error:', err));
        } else if(showProductCategory && !showDepartment && !showDepartmentCategory){
            Promise.all([getProductCategoryNamesPromise(), getRelatedSearchFilterNamesPromise(productCategoryNameSelected)])
            .then(([productCategories, relatedSearchFilterNames])=>{
                const pcns = getProductCategorNames(productCategories);
                setProductCategoryNames([...pcns]);
                setSearchFilterNames([...relatedSearchFilterNames]);   

                setIsLoading(false);
            })
            .catch((err)=>console.error('product category name error:', err));
        } else {
            throw new Error('no search filter area selected');
        }

    },[]);

    function getDepartmentNames(ds:StoreDepartment[]):string[] {
        return ds.map((d)=>{
            return d.departmentName;
        })
    }

    function getDepartmentCategorNames(dcs:DepartmentCategory[]):string[] {
        return dcs.map((dc)=>{
            return dc.departmentCategoryName;
        })
    }

    function getProductCategorNames(pcs:ProductCategory[]):string[] {
        return pcs.map((pc)=>{
            return pc.productCategoryName;
        })
    }

    function getRelatedSearchFilterNamesPromise(searchFilterArea:string):Promise<string[]> {
        const params = JSON.stringify({searchFilterAreaSelected:searchFilterArea});

        return new Promise((resolve, reject)=>{
                    //previous version
                    //return fetch(`http://localhost:9500/get-search-filters-by-search-filter-area/${searchFilterArea}`, {
                    return fetch(`https://server.kando-proto-3.com/get-search-filters-by-search-filter-area`, {
                                method:'POST',
                                headers:{
                                    'content-type':'application/json',
                                    'accept':'application/json'
                                },
                                body:params
                            })
                            .then(resp=>resp.json())
                            .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                                if(dbResponseMsg === 'record found'){
                                    const searchFilterNames = (dbResponseRecords as SearchFilter[]).map((sf:SearchFilter)=>{
                                                                    return sf.filterName
                                                                });
                                    resolve(searchFilterNames);
                                } else {
                                    resolve([]);
                                }
                                
                            })
                            .catch((err)=>{
                                console.error('get search filters by search filter area error:', err);
                                reject(err);
                            });
                });

    }

    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        setShowStoreStructureAlert(false);
    }

    function handleDeptNameSelection(e:React.ChangeEvent):void {
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setShowDepartmentCategory(false);
            setShowProductCategory(false);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('Department');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(false);

            getRelatedSearchFilterNamesPromise(dns)
            .then((sfns)=>{
                setSearchFilterNames(sfns);
            })
            .catch((err)=>console.error('search filter names error:', err));
            setSearchFilterNameSelected('none');

        } else {
            setShowDepartmentCategory(true);
            setShowProductCategory(true);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(true);

            Promise.all([getDepartmentNamesPromise(),
                        getDepartmentCategoryNamesPromise(),
                        getProductCategoryNamesPromise()])
            .then(([departments, departmentCategories, productCategories])=>{
                const dns = getDepartmentNames(departments);
                setDeptNames([...dns]);
                const dcns = getDepartmentCategorNames(departmentCategories);
                setDeptCategoryNames([...dcns]);
                const pcns = getProductCategorNames(productCategories);
                setProductCategoryNames([...pcns]);
            })
            .catch((err)=>console.error('Promise.all() search filter area error:', err));
            setSearchFilterNames([]);
            setSearchFilterNameSelected('none');
            setShowSearchFilterNameErrorMsg(true);
        }
    }

    function handleDeptCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('dcns:', (e.target as HTMLSelectElement).value);
        const dcns = (e.target as HTMLSelectElement).value;
        if(dcns !== 'none'){
            setShowDepartment(false);
            setShowProductCategory(false);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('Department Category');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(false);

            getRelatedSearchFilterNamesPromise(dcns)
            .then((sfns)=>{
                setSearchFilterNames(sfns);
            })
            .catch((err)=>console.error('search filter names error:', err));
            setSearchFilterNameSelected('none');
                  
        } else {
            setShowDepartment(true);
            setShowProductCategory(true);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(true);

            Promise.all([getDepartmentNamesPromise(),
                getDepartmentCategoryNamesPromise(),
                getProductCategoryNamesPromise()])
            .then(([departments, departmentCategories, productCategories])=>{
                const dns = getDepartmentNames(departments);
                setDeptNames([...dns]);
                const dcns = getDepartmentCategorNames(departmentCategories);
                setDeptCategoryNames([...dcns]);
                const pcns = getProductCategorNames(productCategories);
                setProductCategoryNames([...pcns]);
            })
            .catch((err)=>console.error('Promise.all() search filter area error:', err));
            setSearchFilterNames([]);
            setSearchFilterNameSelected('none');
            setShowSearchFilterNameErrorMsg(true);

        }
    }

    function handleProductCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('pcns:', (e.target as HTMLSelectElement).value);
        const pcns = (e.target as HTMLSelectElement).value;
        if(pcns !== 'none'){
            setShowDepartment(false);
            setShowDepartmentCategory(false);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('Product Category');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(false);

            getRelatedSearchFilterNamesPromise(pcns)
            .then((sfns)=>{
                setSearchFilterNames(sfns);
            })
            .catch((err)=>console.error('search filter names error:', err));
            setSearchFilterNameSelected('none');

        } else {
            setShowDepartment(true);
            setShowDepartmentCategory(true);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(true);

            Promise.all([getDepartmentNamesPromise(),
                getDepartmentCategoryNamesPromise(),
                getProductCategoryNamesPromise()])
            .then(([departments, departmentCategories, productCategories])=>{
                const dns = getDepartmentNames(departments);
                setDeptNames([...dns]);
                const dcns = getDepartmentCategorNames(departmentCategories);
                setDeptCategoryNames([...dcns]);
                const pcns = getProductCategorNames(productCategories);
                setProductCategoryNames([...pcns]);
            })
            .catch((err)=>console.error('Promise.all() search filter area error:', err));
            setSearchFilterNames([]);
            setSearchFilterNameSelected('none');
            setShowSearchFilterNameErrorMsg(true);
        }
    }

    function handleAddSearchFilterName(e:React.ChangeEvent):void {
        const searchFilterName:string = (e.target as HTMLInputElement).value;
        if(searchFilterName === 'none'){
            setShowSearchFilterNameErrorMsg(true);
            setSearchFilterNameErrorMsg('Please add a search filter name');
        } else {
            setShowSearchFilterNameErrorMsg(false);
        }
        setSearchFilterNameSelected(searchFilterName);
    }

    function handleSearchTermSelected(e:React.ChangeEvent):void {
        //console.log('sfsc:', (e.target as HTMLInputElement).checked);
        setSearchTermSelected((e.target as HTMLInputElement).checked);
    }
    

    function handleShowSearchTerm(e:React.ChangeEvent):void {
        //console.log('ssfc:', (e.target as HTMLInputElement).checked);
        setShowSearchTerm((e.target as HTMLInputElement).checked);
    }

    function updateSearchTermImg():string {
        if(searchTermImgSelected.length > 0 && newSearchTermImgSelected.length > 0){
            return newSearchTermImgSelected;
        } else if(searchTermImgSelected.length > 0 && newSearchTermImgSelected.length === 0){
            return searchTermImgSelected
        } else if(searchTermImgSelected.length === 0 && newSearchTermImgSelected.length > 0){
            return newSearchTermImgSelected;
        } else {
            return 'none';
        }
    }

    function handleSubmitSearchTermForm(e:React.FormEvent):void {
        e.preventDefault();

        //console.log('sfns:', searchFilterNameSelected);
        //console.log('dns:', deptNameSelected);
        //console.log('dcns:', deptCategoryNameSelected);
        //console.log('pcns:', productCategoryNameSelected);
        //console.log('stns:', searchTermNameSelected);

        
        const showErrorMsgs:boolean = showSearchTermFormErrorMsgs(
                                                                    {searchFilterNameSelected,
                                                                    deptNameSelected,
                                                                    deptCategoryNameSelected,
                                                                    productCategoryNameSelected,
                                                                    isSearchFilterAreaNameSelected,
                                                                    setShowSearchFilterAreaNameErrorMsg,
                                                                    setSearchFilterNameErrorMsg,
                                                                    setShowSearchFilterNameErrorMsg,
                                                                    setSearchTermNameErrorMsg,
                                                                    setShowSearchTermNameErrorMsg,
                                                                    setShowSearchTermNameLimitError,
                                                                    searchTermNameSelected});
                                    
        if(showErrorMsgs){
            //console.log('error test passed');
            //console.log('sfns:', searchFilterNameSelected);
            return;

        }//add else sate to the rest of function if top condition works
        //need to add error filtering for search term name selected

        setShowProcessingRequestMsg(true);

        const stToAdd = new SearchTerm({
                    searchFilterArea:searchFilterAreaNameSelected,
                    searchFilterAreaType:searchFilterAreaTypeSelected,
                    searchFilterName:searchFilterNameSelected,
                    searchTermName:searchTermNameSelected,
                    searchTermId:searchTermIdSelected,
                    searchTermSelected:searchTermSelected,
                    showSearchTerm:showSearchTerm,    
                    showSearchTermImg:showSearchTermImg,
                    searchTermImg:updateSearchTermImg()
                });


        //console.log('stta:', stToAdd);
        const searchTerm = JSON.stringify(stToAdd);

        //setShowStoreStructureLoadingMsg(true);
        //setIsLoading(true);
        //previous version
        //fetch(`http://localhost:9500/update-search-term-only/${searchTerm}`,{
        fetch(`https://server.kando-proto-3.com/update-search-term-only`,{
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:searchTerm
        })
        .then(resp=>resp.json())
        .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
            //console.log('dbrm:', dbResponseMsg);
            //console.log('dbrs:', dbResponseRecords);

            setShowProcessingRequestMsg(false);

            //setShowStoreStructureLoadingMsg(false);
            if(dbResponseMsg === 'record updated'){
                handleUpdateSearchTerm(
                            /*searchFilterIdSelected,
                            searchFilterAreaTypeSelected,
                            searchFilterAreaNameSelected,
                            searchFilterNameSelected,
                            searchFilterSelected,
                            showSearchFilter,*/
                            dbResponseRecords as SearchTerm[]
                                );

                //setIsLoading(false);
            
            } else if(dbResponseMsg === 'record matched another search term'){
                setShowSearchFilterNameErrorMsg(true);
                setSearchFilterNameErrorMsg('Search filter name already exists');
                setShowSearchTermNameErrorMsg(true);
                setSearchTermNameErrorMsg('Search term name already exists');

                //setIsLoading(false);

            } else {
                throw new Error('search term updated error.');
            }

            /*
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
            */
        })
        .catch(err=>console.error('edit search term fetch err:', err));

    }

    function handleCancelAddSearchTerm(e:React.MouseEvent):void {
        handleCancelSearchTerm();

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    }

    function handleEditSearchTermName(e:React.ChangeEvent):void {
        const searchTermName:string = (e.target as HTMLInputElement).value;
        /*previous version
        if(searchTermName === ''){
            setShowSearchTermNameErrorMsg(true);
            setSearchTermNameErrorMsg('Please add a search term name');
        } else {
            setShowSearchTermNameErrorMsg(false);
        }
        
        setSearchTermNameSelected(searchTermName);
        */

        //console.log('value.length:', searchTermName.length);
            
        if(searchTermName.length === 0){
            setShowSearchTermNameLimitError(false);
            setShowSearchTermNameErrorMsg(true);
            setSearchTermNameErrorMsg('Please add a search term name');
            setSearchTermNameSelected(searchTermName);

        } else if(searchTermName.length > 0 && searchTermName.length <= 20){
            setShowSearchTermNameErrorMsg(false);
            setShowSearchTermNameLimitError(false);
            setSearchTermNameSelected(searchTermName);

        } else {
            setShowSearchTermNameErrorMsg(false);
            setShowSearchTermNameLimitError(true);
        }

    }

    function handleShowSearchTermImg(e:React.ChangeEvent):void {
        setShowSearchTermImg((e.target as HTMLInputElement).checked);
    }

    function handleSearchTermImg(e:React.ChangeEvent):void {
        const stImg = (e.target as HTMLInputElement).value;
        //console.log('stImg:', stImg);
        setSearchTermImgSelected(stImg);
    

    }

    function handleNewSearchTermImg(e:React.ChangeEvent):void {
        const stImg = (e.target as HTMLInputElement).value;
        const stFileName = stImg.slice(stImg.lastIndexOf('\\') + 1);
        //console.log('stfn:', stFileName);
        //console.log('search term img:', (e.target as HTMLInputElement).value);
        setNewSearchTermImgSelected(stFileName);
    }
    

    if(showEditSearchTerm){
        return <div>
                    {/*<StoreStructureLoadingMsg
                        showStoreStructureLoadingMsg={showStoreStructureLoadingMsg}
                        storeStructureLoadingMsg={storeStructureLoadingMsg}
                    />*/}
                    {/*<StoreStructureAlert 
                        showStoreStructureAlert={showStoreStructureAlert}
                        storeStructureAlertMsg={storeStructureAlertMsg}
                        handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />*/}
                    
                    <h1 className="edit-search-term-stand-alone-heading">Edit Search Term</h1>

                    {
                        !isLoading
                        ?
                    <form className="add-dpt-category-form" onSubmit={handleSubmitSearchTermForm}>
                        {showDepartment ? 
                        <div className="edit-search-term-stand-alone-input-form-group">
                            <label htmlFor="dpt-name">Department Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={deptNameSelected}
                                    optionNames={deptNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={deptNameErrorMsg}
                                    selectId={"dpt-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showDepartmentCategory ?
                        <div className="edit-search-term-stand-alone-input-form-group">
                            <label htmlFor="dpt-category-name">Department Category Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={deptCategoryNameSelected}
                                    optionNames={deptCategoryNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={deptCategoryNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptCategoryNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showProductCategory ?
                        <div className="edit-search-term-stand-alone-input-form-group">
                            <label htmlFor="product-category-name">Product Category Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={productCategoryNameSelected}
                                    optionNames={productCategoryNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={productCategoryNameErrorMsg}
                                    selectId={"product-category-name"}
                                    handleOptionNameSelection={handleProductCategoryNameSelection}
                            />
                        </div>
                        : null
                        }
                        <div className="edit-search-term-stand-alone-input-form-group">
                            <label htmlFor="search-filter-name">Search Filter Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={searchFilterNameSelected}
                                    optionNames={searchFilterNames}
                                    showErrorMsg={showSearchFilterNameErrorMsg}
                                    errorMsg={searchFilterNameErrorMsg}
                                    selectId={"search-filter-name"}
                                    handleOptionNameSelection={handleAddSearchFilterName}
                            />
                        </div>
                        <div className="edit-search-term-stand-alone-input-form-group">
                            <label htmlFor="search-term-name">Search Term Name</label>
                            <input type="text" name="search-term-name" id="search-term-name"
                             className={showSearchTermNameErrorMsg ? 'form-input-control show-search-term-name-input-error-border' : 'form-input-control hide-search-term-name-input-error-border'}
                             value={searchTermNameSelected} onChange={handleEditSearchTermName}/>

                            {
                                showSearchTermNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (20)</p>
                                :
                                null
                            }

                             
                             {
                                showSearchTermNameErrorMsg 
                                ? 
                                <div className="show-search-term-name-error-msg">
                                    {searchTermNameErrorMsg}
                                </div>
                                : 
                                null
                              }

                        </div>
                        {/*<div className="edit-search-term-stand-alone-file-form-group">
                            <label htmlFor="search-term-img">Current Search Term Image</label>
                            <input type="text" className="search-term-img-name" name="search-term-img-name"
                              value={searchTermImgSelected} onChange={handleSearchTermImg}
                              readOnly={true}/>
                        </div>*/}
                        {/*<div className="edit-search-term-stand-alone-file-form-group">
                            <label htmlFor="search-term-img">New Search Term Image</label>
                            <input type="file" name="search-term-img-file-name" onChange={handleNewSearchTermImg}/>
                        </div>*/}
                        {/*<div className="edit-search-term-stand-alone-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="search-term-selected" id="search-term-selected"
                              checked={searchTermSelected} onChange={handleSearchTermSelected}/>
                            <label htmlFor="search-term-selected">Search Term Selected</label>
                        </div>*/}
                        <div className="edit-search-term-stand-alone-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="show-search-term" id="show-search-term"
                              checked={showSearchTerm} onChange={handleShowSearchTerm}/>
                            <label htmlFor="show-search-term">Show Search Term</label>
                        </div>
                        {/*<div className="edit-search-term-stand-alone-checkbox-form-group">
                            <input type="checkbox" name="show-search-term-img" id="show-search-term-img"
                              checked={showSearchTermImg} onChange={handleShowSearchTermImg}/>
                            <label htmlFor="show-search-term-img">Show Search Term Image</label>
                        </div>*/}
                        <div className="product-form-submit-btn-container">
                            <button type="submit" className="edit-search-term-stand-alone-save-btn">Save</button>
                            <button type="button" className="edit-search-term-stand-alone-cancel-btn"
                                onClick={handleCancelAddSearchTerm}>
                                    Cancel
                            </button>
                            {
                                (showProcessingRequestMsg)
                                ?
                                <div className="process-request-msg">
                                    Processing...
                                </div>
                                :
                                null
                            }
                        </div>
                    </form>
                    :
                    <div className="ssdcs-loader-animation-container">
                        <LoaderAnimation/>
                    </div>
                }
                </div>
    
    } else {
        return <div className="ssdcs-loader-animation-container">
                {/*<LoaderAnimation/>*/}
            </div>;
    }

}

export default EditSearchTerm;