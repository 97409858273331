import React, { useState, useEffect, useRef } from 'react';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import SliderType from './sliderType';
import './ImageSlider3.css';

interface Props {
    //path?:string;
    sliderType:SliderType;
    items:object[];
    setupItemsCallback:(items:object[])=>JSX.Element[];
    optionalHeader?:boolean;
}

const ImageSlider3:React.FunctionComponent<Props> = (props: Props) => {
    const {setupItemsCallback, optionalHeader} = props;

    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);

    const imageContainerRef = useRef<HTMLDivElement>(null);
    const sliderImagesRef = useRef<HTMLDivElement[]>([]);
    const imgSliderScrollbarWrapperRef = useRef<HTMLDivElement>(null);
    const imgSliderScrollbarContainerRef = useRef<HTMLDivElement>(null);
    const imgSliderScrollbarRef = useRef<HTMLDivElement>(null);
    const imgSliderContainerRef = useRef<HTMLDivElement>(null);
    const imgSliderLeftBtnRef = useRef<HTMLButtonElement>(null);
    const imgSliderRightBtnRef = useRef<HTMLButtonElement>(null);

    const sliderImageWidth = useRef(0); //, setSliderImageWidth] = useState(0);
    const imageSliderDistance = useRef<number>(0);
    const slideDistance = useRef(4); //, setSlideDistance] = useState(4);
    const numberOfSliderImagesVisible = useRef(4); //, setNumberOfSliderImagesVisible] = useState(4);
    const numberOfSliderImages = useRef(0); //, setNumberOfSliderImages] = useState(4);
    const [scrollbarWidth, setScrollbarWidth] = useState(0);
    const scrollbarContainerWidth = useRef(0); //, setScrollbarContainerWidth] = useState(0);
    const [scrollbarContainerLeftPos, setScrollbarContainerLeftPos] = useState(0);
    const [scrollbarLeftPos, setScrollbarLeftPos] = useState(0);
    const [gridColumnPadding, setGridColumnPadding] = useState(16);
    //const scrollbarCurrentPosition = useRef(0);
    const screenWidth = useRef(0);
    const [items, setItems] = useState(props.items);
    const [sliderType, setSliderType] = useState<SliderType>(props.sliderType);
    const inTransition = useRef<boolean>(false);

    const portrait = useRef<MediaQueryList[]>([]);


    useEffect(()=>{
        if(shoppingCartRedux.shoppingCartCategories.length > 0){
            setIsShoppingCartOpen(true);
        } else {
            setIsShoppingCartOpen(false);
        }
    },[shoppingCartRedux]);



    
    useEffect(()=>{
        if(props.sliderType !== sliderType){
            setSliderType(props.sliderType);
        }
    },[props.sliderType]);


    useEffect(()=>{

        getChildImages();

        //console.log('ww:', window.innerWidth);

        

        //screenWidth.current = window.innerWidth;

        resizeEventHandler(new Event('test'));


        /*
        //call all logic from line #54-106 when click to add/remove items from carousel is
        //not needed


        //setupSliderImageVariables();

        if(screenWidth.current < 768){
            imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
            imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
            
            if(sliderType.type === 'slider' && sliderType.distance === 3 
                && document.documentElement.clientWidth < 678){
                    imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper'); 

            } else if(sliderType.type === 'slider' && sliderType.distance === 4 
                        && document.documentElement.clientWidth < 904) {
                imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');

            } else if(sliderType.type === 'slider' && sliderType.distance === 3 
                    && document.documentElement.clientWidth >= 678) {
                imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

            } else if(sliderType.type === 'slider' && sliderType.distance === 4 
                    && document.documentElement.clientWidth >= 904) {
                imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

            } else {
                if(numberOfSliderImagesVisible.current > numberOfSliderImages.current
                    || numberOfSliderImages.current === numberOfSliderImagesVisible.current){
                        imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');
                
                } else {
                    imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');
                }

            }

        } else if(numberOfSliderImages.current === numberOfSliderImagesVisible.current){
            imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
            imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
            imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

        } else {
            if(numberOfSliderImagesVisible.current > numberOfSliderImages.current){
                imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
                imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
                imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

            } else {
                imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
                imgSliderRightBtnRef.current!.classList.remove('hide-image-slider-btn');
                imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');
            }

            
        }
        */
        
        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        window.addEventListener('resize', resizeEventHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", resizeEventHandler);



        return cleanupResizeEventHandler;

    },[props.items]);


    useEffect(()=>{
        //console.log('ww:', window.innerWidth);
        resizeEventHandler(new Event('test'));
    },[isShoppingCartOpen]);


    function cleanupResizeEventHandler():void {
        window.removeEventListener('resize', resizeEventHandler);
        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);

        portrait.current[0].removeEventListener("change", resizeEventHandler);

    }

    function getChildImages():void {
        const childElements = Array.from(imageContainerRef.current!.children).map((child)=>{
                                return child as HTMLDivElement;
                            });

        sliderImagesRef.current! = [...childElements];

        //console.log('childElements:', childElements);
        
    }

    function screenOrientationChangeHandler(e:Event):void {
        resizeEventHandler(new Event('test'));
    }

    function resizeEventHandler(e:Event):void {
        //console.log('resize');
        setupSliderImageVariables();

        //console.log('isbc:', scrollbarContainerWidth.current);

        const endDistance = 0;
        const startDistance = (numberOfSliderImages.current * sliderImageWidth.current) - (numberOfSliderImagesVisible.current * sliderImageWidth.current);

        const targetWidth = imgSliderScrollbarRef.current!.getBoundingClientRect().width;
        const sbcWidth = imgSliderScrollbarContainerRef.current!.getBoundingClientRect().width;
        const endPosition = sbcWidth - targetWidth;        

        let cp = scrollbarContainerWidth.current * (startDistance === 0 ? 0 : (Math.abs(imageSliderDistance.current) / startDistance));

        //console.log('sd9:', startDistance, 'isd:', imageSliderDistance.current, 'cp:', cp, 'ep:', sbcWidth - targetWidth);
        //console.log('sbw:', targetWidth, 'sbcw:', sbcWidth, scrollbarContainerWidth);

        if(cp <= 0) {
            cp = 0;

            //set current distance for image slider container
            const currentDistance =  startDistance * (endPosition === 0 ? 0 :(cp/endPosition));
            imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
            imageContainerRef.current!.style.transform = `translateX(-${currentDistance}px)`;
            //console.log('calc:', currentDistance, cp, endPosition);
        }

        if(cp >= endPosition){
            cp = endPosition;

            //set current distance for image slider container
            const currentDistance = startDistance * (endPosition === 0 ? 0 :(cp/endPosition));
            imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
            //previous version was `translateX(-${currentDistance}px)`;
            imageContainerRef.current!.style.transform =  `translateX(${0}px)`;//`translateX(-${currentDistance}px)`;
            //console.log('calc:', currentDistance, cp, endPosition);
        
        }

        if(screenWidth.current >= 768){
            imageContainerRef.current!.style.transform = `translateX(${imageSliderDistance.current}px)`;
            imgSliderContainerRef.current!.scrollLeft = 0;
        }
        


        if(screenWidth.current < 768){
            //console.log('hide');
            //console.log('condition');
            imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
            imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');

            if(sliderType.type === 'slider' && sliderType.distance === 3 
                && document.documentElement.clientWidth < 678){
                    //console.log('condition');
                imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');

            } else if(sliderType.type === 'slider' && sliderType.distance === 4 
                        && document.documentElement.clientWidth < 904) {
                            //console.log('condition');
                imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');

            } else if(sliderType.type === 'slider' && sliderType.distance === 3 
                    && document.documentElement.clientWidth >= 678) {
                        //console.log('condition');
                imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

            } else if(sliderType.type === 'slider' && sliderType.distance === 4 
                    && document.documentElement.clientWidth >= 904) {
                        //console.log('condition');
                imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

            } else {
                if(numberOfSliderImagesVisible.current > numberOfSliderImages.current
                    || numberOfSliderImages.current === numberOfSliderImagesVisible.current){
                        imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');
                
                } else {
                    imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');
                }

            }
            

        } else if(numberOfSliderImages.current === numberOfSliderImagesVisible.current){
            imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
            imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
            imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

        }  else if(screenWidth.current >= 768 && cp === 0){
            //console.log('condition');
            if(numberOfSliderImagesVisible.current > numberOfSliderImages.current){
                //console.log('condition');
                imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
                imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
                imgSliderScrollbarWrapperRef.current!.classList.add('hide-image-slider-scrollbar-wrapper');

            } else {
                //console.log('condition');
                imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
                imgSliderRightBtnRef.current!.classList.remove('hide-image-slider-btn');
                imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');
            }


        } else if(screenWidth.current >= 768 && cp === endPosition){
            //console.log('condition1');
            imgSliderLeftBtnRef.current!.classList.remove('hide-image-slider-btn');
            imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
            imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');
            
        } else {
            //console.log('condition2', cp, endPosition);;
            if(imgSliderLeftBtnRef.current!.classList.contains('hide-image-slider-btn')){
                imgSliderLeftBtnRef.current!.classList.remove('hide-image-slider-btn');
                imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');
            }

            if(imgSliderRightBtnRef.current!.classList.contains('hide-image-slider-btn')){
                imgSliderRightBtnRef.current!.classList.remove('hide-image-slider-btn');
                imgSliderScrollbarWrapperRef.current!.classList.remove('hide-image-slider-scrollbar-wrapper');
            }

        }


        imgSliderScrollbarRef.current!.style.transform = `translateX(${cp}px)`;
        
        //console.log('sd:', imageSliderDistance.current);


    }

    function setupSliderImageVariables():void {
        screenWidth.current = window.innerWidth;
        //console.log('hide:', screenWidth.current);


        if(screenWidth.current >= 1200){
            numberOfSliderImagesVisible.current = sliderType.type === 'slider' ? sliderType.distance : 6;
            slideDistance.current = sliderType.type === 'slider' ? sliderType.distance : 6;

            sliderImageWidth.current = sliderImagesRef.current[0].getBoundingClientRect().width + gridColumnPadding;

            numberOfSliderImages.current = sliderImagesRef.current.length;

            setupSliderScrollbarVariables(numberOfSliderImagesVisible.current);

        } else if(screenWidth.current >= 1024 && screenWidth.current < 1200){
            numberOfSliderImagesVisible.current = sliderType.type === 'slider' ? sliderType.distance : 5;
            slideDistance.current = sliderType.type === 'slider' ? sliderType.distance : 5;

            //imageSliderDistance.current += 813;//162.65;

            sliderImageWidth.current = sliderImagesRef.current[0].getBoundingClientRect().width + gridColumnPadding;
            numberOfSliderImages.current = sliderImagesRef.current.length;

            setupSliderScrollbarVariables(numberOfSliderImagesVisible.current);

        } else if(screenWidth.current >= 768 && screenWidth.current < 1024) {
            
            numberOfSliderImagesVisible.current = sliderType.type === 'slider' ? sliderType.distance : 4;
            slideDistance.current = sliderType.type === 'slider' ? sliderType.distance : 4;

            sliderImageWidth.current = sliderImagesRef.current[0].getBoundingClientRect().width + gridColumnPadding;
            //setSliderImageWidth(sliderImgWidth);
            numberOfSliderImages.current = sliderImagesRef.current.length;


            setupSliderScrollbarVariables(numberOfSliderImagesVisible.current);


        } else {

            sliderImageWidth.current = sliderImagesRef.current[0].getBoundingClientRect().width + gridColumnPadding;
            //console.log('<768:', imgSliderContainerRef.current!.getBoundingClientRect().width, screenWidth.current / sliderImageWidth.current);
            const icw = imgSliderContainerRef.current!.getBoundingClientRect().width;

            numberOfSliderImagesVisible.current = icw / sliderImageWidth.current;
            slideDistance.current = numberOfSliderImagesVisible.current;

            sliderImageWidth.current = sliderImagesRef.current[0].getBoundingClientRect().width + gridColumnPadding;
            //setSliderImageWidth(sliderImgWidth);
            numberOfSliderImages.current = sliderImagesRef.current.length;


            setupSliderScrollbarVariables(numberOfSliderImagesVisible.current);

            imageContainerRef.current!.style.transform = 'translateX(0px)';
    
            imgSliderContainerRef.current!.scrollLeft = Math.abs(imageSliderDistance.current);

        }
    }


    function setupSliderScrollbarVariables(nosiv:number):void {
        //set image slider scrollbar container width
        const isscw = imgSliderScrollbarContainerRef.current!.getBoundingClientRect().width;
        imgSliderScrollbarRef.current!.style.width =  (nosiv > sliderImagesRef.current.length) 
                                                        ?  `${((sliderImagesRef.current.length/25) * 100).toFixed(4)}%`
                                                        :`${((nosiv/sliderImagesRef.current.length) * 100).toFixed(4)}%`;
        const issw = imgSliderScrollbarRef.current!.getBoundingClientRect().width;
        //setScrollbarContainerWidth(isscw - issw);
        //console.log('isscw:', isscw, 'issw:', issw, nosiv, sliderImagesRef.current.length);
        //scroll bar was not previously visible
        if(isscw === 0 && issw === 0 && window.innerWidth >= 768){
            //console.log('passed');
            const adjIsscw = document.documentElement.clientWidth - 32;//30;
            const adjIssw = (nosiv/sliderImagesRef.current.length) * adjIsscw;
            //console.log('adjIsscw:', adjIsscw, 'adjIssw:', adjIssw, (adjIssw / 100) * adjIsscw);
            scrollbarContainerWidth.current = adjIsscw - adjIssw;

        } else if(isscw === 0 && issw === 0 && window.innerWidth < 768){
            //console.log('passed');
            const adjIsscw = document.documentElement.clientWidth - 16;//15;
            const adjIssw = (nosiv/sliderImagesRef.current.length) * adjIsscw;
            //console.log('adjIsscw:', adjIsscw, 'adjIssw:', adjIssw, (adjIssw / 100) * adjIsscw);
            scrollbarContainerWidth.current = adjIsscw - adjIssw;

        }else {
            scrollbarContainerWidth.current = (isscw - issw);
            //console.log('r:', scrollbarContainerWidth.current);
        }
        
    }

    function imageSlideClickHandler(e:React.MouseEvent):void {
        const target = (e.currentTarget as HTMLButtonElement);
        const targetName = target.value;

        if(inTransition.current){
            return;

        } else {
            //set transition event
            imageContainerRef.current!.style.transition = 'transform .6s ease';
            imgSliderScrollbarRef.current!.style.transition = 'transform .6s ease';
            inTransition.current = true;
            
            //const gridColumnPadding = 16;
            //const sliderImageWidth = sliderImagesRef.current[0].getBoundingClientRect().width + gridColumnPadding;
            //console.log('siw:', sliderImageWidth, targetName);
            //const numberOfSliderImages = sliderImagesRef.current.length;
            //const numberOfSliderImagesVisible = 5;
            //const slideDistance = 1;
            const currentPosition = 0;
            const startDistance = (numberOfSliderImages.current * sliderImageWidth.current) - (numberOfSliderImagesVisible.current * sliderImageWidth.current);
            //console.log('sd:', startDistance, numberOfSliderImages.current, numberOfSliderImagesVisible.current);
            const endDistance = 0;//sliderImageWidth * numberOfSliderImages;
            //console.log('ed:', endDistance);
            const currentDistance = imageSliderDistance.current;
            //console.log('cd:', currentDistance);

            if(targetName === 'left'){
                //console.log('left:', 'cd:', currentDistance, 'ed:', endDistance);

                if(currentDistance === endDistance){
                    return;

                } else if((currentDistance < endDistance)
                        && currentDistance + (sliderImageWidth.current * slideDistance.current) > Math.abs(endDistance)){
                        //console.log('amt:', currentDistance);
                        imageSliderDistance.current += Math.abs(currentDistance) - Math.abs(endDistance);
                        imageContainerRef.current!.style.transform = `translateX(${imageSliderDistance.current}px)`;

                }  else {
                    //console.log('amt:', currentDistance);
                    imageSliderDistance.current += sliderImageWidth.current * slideDistance.current;
                    imageContainerRef.current!.style.transform = `translateX(${imageSliderDistance.current}px)`;
                }

                if(imageSliderDistance.current === endDistance){
                    imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
                    imgSliderRightBtnRef.current!.classList.remove('hide-image-slider-btn');
                    
                } else {
                    imgSliderRightBtnRef.current!.classList.remove('hide-image-slider-btn');
                }

                

            } else {
                //console.log('cd:', currentDistance, startDistance);
                if(currentDistance === -startDistance){
                    return;

                } else if((Math.abs(currentDistance) < startDistance)
                        && ((Math.abs(currentDistance) + (sliderImageWidth.current * slideDistance.current)) > startDistance)){
                    //console.log('pos:', 1, 'amt:', Math.abs(startDistance), Math.abs(currentDistance));
                        imageSliderDistance.current -= Math.abs(startDistance) - Math.abs(currentDistance);
                        imageContainerRef.current!.style.transform = `translateX(${imageSliderDistance.current}px)`;

                } else {
                    //console.log('pos:', 2, currentDistance);
                    imageSliderDistance.current -= sliderImageWidth.current * slideDistance.current;
                    imageContainerRef.current!.style.transform = `translateX(${imageSliderDistance.current}px)`;
                }

                if(imageSliderDistance.current === -startDistance){
                    //console.log('passed')
                    imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
                    imgSliderLeftBtnRef.current!.classList.remove('hide-image-slider-btn');
                    

                } else {
                    //console.log('passed');
                    imgSliderLeftBtnRef.current!.classList.remove('hide-image-slider-btn');
                }
                
            }

            //console.log('moved:', startDistance, Math.abs(imageSliderDistance.current) / startDistance, imageSliderDistance.current);
            //set current position fro scroll bar
            const cp = scrollbarContainerWidth.current * (startDistance === 0 ? 0 : (Math.abs(imageSliderDistance.current) / startDistance));
            //console.log('cp:', cp, scrollbarContainerWidth.current);
            imgSliderScrollbarRef.current!.style.transform = `translateX(${cp}px)`;

        }
        
    }

    function scrollEventHandler(e:React.UIEvent):void {
        const cp = imageContainerRef.current!.getBoundingClientRect().left - sliderType.scrollEventPadding;//sliderImagesRef.current![0].getBoundingClientRect().left;
        const imgs = sliderImagesRef.current!.length;
        const sliderImageContainerWidth = (numberOfSliderImages.current * sliderImageWidth.current) - (numberOfSliderImagesVisible.current * sliderImageWidth.current);
        const imageSliderContainerPadding = 0;
        const endPosition = sliderImageContainerWidth - imageSliderContainerPadding;
        //console.log('scroll:', cp, (e.target as HTMLDivElement).scrollLeft, imageSliderDistance.current, sliderImageContainerWidth, endPosition);

        const csp = scrollbarContainerWidth.current * (Math.abs(cp) / endPosition);
        //console.log('csp:', csp, scrollbarContainerWidth.current, cp, endPosition);
        imgSliderScrollbarRef.current!.style.transform = `translateX(${csp}px)`;

        //current version
        //imageSliderDistance.current = cp + 16;
        imageSliderDistance.current = cp;


    }

    
    function mousedownImageSliderHandler(e:React.TouchEvent):void {
        e.stopPropagation();
        const parentTarget = e.target as HTMLDivElement;
        //console.log('e.type:', e.type, window.innerWidth, document.documentElement.clientWidth, imageContainerRef.current!.getBoundingClientRect().width);
        //console.log('scrollbar mouse down:', e.pageX, e.pageY);
        //console.log('scrollbar mouse:', e.type);
        //console.log('scrollbar mouse down mobile:', e.touches[0].pageX, e.touches[0].pageY);

                   
        const target = imgSliderScrollbarRef.current!;// (e.target as HTMLDivElement);
        const targetWidth = target.getBoundingClientRect().width;
        const sbcWidth = imgSliderScrollbarContainerRef.current!.getBoundingClientRect().width;
        const sbcLeftPos = imgSliderScrollbarContainerRef.current!.getBoundingClientRect().left;
        const sbLeftPos = target.getBoundingClientRect().left;
        const startPosition = 0;
        //const endPosition = sbcWidth - targetWidth;

        //width for showing at least 4 slider images
        const sliderImageContainerWidth = (numberOfSliderImages.current * sliderImageWidth.current) - (numberOfSliderImagesVisible.current * sliderImageWidth.current);
        const endPosition = sliderImageContainerWidth;
        //console.log('sicw:',sliderImageContainerWidth);
        //console.log('ep:', endPosition);
        //console.log('left:', imageContainerRef.current!.getBoundingClientRect().left);
        //console.log('left:', imgSliderContainerRef.current!.getBoundingClientRect().left);
        const iscLeftPos = imgSliderContainerRef.current!.getBoundingClientRect().left;
        const icLeftPos = imageContainerRef.current!.getBoundingClientRect().left;
    

        let cp = 0;
        let pp = 0;
        let ip = 0;
        
        //ip = e.touches[0].pageX + (sbcLeftPos - sbLeftPos);
        ip = e.touches[0].pageX - icLeftPos;
        //scrollbarCurrentPosition.current = e.pageX + (sbcLeftPos - sbLeftPos);
        //console.log('ip:', ip);


        function documentMousemoveHandler(e:TouchEvent):void {
            //console.log('mouse:', e instanceof MouseEvent, 'touch:', e instanceof TouchEvent);

            //adjust for mouse pointer position on scroll bar
            cp = e.touches[0].pageX - ip;
            //cp = e.pageX - scrollbarCurrentPosition.current;
            
            //console.log('scrolled before:', `${((cp/endPosition) * 100).toFixed(2)}%`, cp);

            //console.log('cp:', cp, 'ip:', ip, 'ep:', endPosition);

            //desktop version    
            //if(cp <= 0) {
              //  cp = 0;
            if(cp <= -endPosition){
               // console.log('test:', cp);
                cp = -endPosition;


                //hide left button
                if(screenWidth.current >= 768){
                    imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
                }
                
                //set current distance for image slider container
                //const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                //imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;

                imageContainerRef.current!.style.transform = `translateX(${cp}px)`;
                //target.style.transform = `translateX(${cp}px)`;
                
                return;
            }

            //desktop version
            //if(cp >= endPosition){
                //cp = endPosition;
            if(cp >= 0){
                //console.log('test:', cp);
                cp = 0;


                //hide right button
                if(screenWidth.current >= 768){
                    imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
                }
                
                //set current distance for image slider container
                //const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                //imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
                imageContainerRef.current!.style.transform = `translateX(${cp}px)`;
                //target.style.transform = `translateX(${cp}px)`;
                
                return;
            }


            
            if(cp < 0 && cp < pp){
                //console.log('test:', cp);

                //show right button
                if(screenWidth.current >= 768){
                    imgSliderRightBtnRef.current!.classList.remove('hide-image-slider-btn');
                }
                

                //console.log('first');
                //go left
                //scroll right
                //set current distance for image slider container
                const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                //imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
                imageContainerRef.current!.style.transform = `translateX(${currentDistance}px)`;
                //target.style.transform = `translateX(${cp}px)`;

                //console.log('cd:', cp, endPosition - cp);;
            
            } 
            
            //if(cp < sbcWidth && cp > pp){
            if(cp > -endPosition && cp > pp){
                //console.log('test:', cp);

                //show left button
                if(screenWidth.current >= 768){
                    imgSliderLeftBtnRef.current!.classList.remove('hide-image-slider-btn');
                }
                

                //console.log('second');
                //go right
                //scroll left
                //set current distance for image slider container
                const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                //imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
                imageContainerRef.current!.style.transform = `translateX(${currentDistance}px)`;
                //target.style.transform = `translateX(${cp}px)`;

                //console.log('cd:', cp, endPosition - cp);
            }

            pp = cp;
            //ip = cp;


            const csp = scrollbarContainerWidth.current * (Math.abs(cp) / endPosition);
            //console.log('csp:', csp, scrollbarContainerWidth.current);
            imgSliderScrollbarRef.current!.style.transform = `translateX(${csp}px)`;

    
        }


        function mousemoveScrollbarHandler(e:MouseEvent):void {
            //console.log('scroll bar mouse move');
        }

        function mouseupScrollbarHandler(e:MouseEvent|TouchEvent):void {
            //e.stopPropagation();
            //console.log('scrollbar mouse up');
            //target.removeEventListener('mousemove', mousemoveScrollbarHandler);
            //parentTarget.removeEventListener('mouseup', mouseupScrollbarHandler);
            //document.removeEventListener('mousemove', documentMousemoveHandler);
            //document.removeEventListener('mouseup', documentMouseupHandler);

            parentTarget.removeEventListener('touchend', mouseupScrollbarHandler);
            document.removeEventListener('touchmove', documentMousemoveHandler);
            document.removeEventListener('touchend', documentMouseupHandler);

            imgSliderScrollbarContainerRef.current!.classList.remove('isb-expand-full');
            imgSliderScrollbarContainerRef.current!.classList.add('isb-expand-medium');

        }


        function documentMouseupHandler(e:TouchEvent):void {
            //console.log('document mouseup');
            //document.removeEventListener('mousemove', documentMousemoveHandler);
            //document.removeEventListener('mouseup', documentMouseupHandler);
            document.ondragstart = null;
            //target.removeEventListener('mousemove', mousemoveScrollbarHandler);
            //parentTarget.removeEventListener('mouseup', mouseupScrollbarHandler);

            document.removeEventListener('touchmove', documentMousemoveHandler);
            document.removeEventListener('touchend', documentMouseupHandler);
            parentTarget.removeEventListener('touchend', mouseupScrollbarHandler);          

            imgSliderScrollbarContainerRef.current!.classList.remove('isb-expand-full');

        }

        
        document.ondragstart = function() {
            return false;
          };
          

        //target.addEventListener('mousemove', mousemoveScrollbarHandler);
        //parentTarget.addEventListener('mouseup', mouseupScrollbarHandler);
        //document.addEventListener('mousemove', documentMousemoveHandler);
        //document.addEventListener('mouseup', documentMouseupHandler);

        parentTarget.addEventListener('touchend', mouseupScrollbarHandler);
        document.addEventListener('touchmove', documentMousemoveHandler);
        document.addEventListener('touchend', documentMouseupHandler);

    }

    function getSliderImages(el:HTMLDivElement):void {
        if(el !== null){
            if(sliderImagesRef.current.length === 0){
                sliderImagesRef.current.push(el);

            } else {
                const found = sliderImagesRef.current.find((si)=>{
                                    return si === el;
                                });

                if(typeof found === 'undefined'){
                    sliderImagesRef.current.push(el);
                }
            }
            
        }
    }

    
    /*previous version that is hard coded with values in original format
    function setupImages():JSX.Element{
        return <div ref={imageContainerRef} className="slider-image-list">
                {        
                    [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25].map((el, pos)=>{
                                        return <div key={pos} ref={getSliderImages} className="slider-image">{el}</div>
                                    })
                }
                </div>

    }
    */
    

    //new version with callback to import any data type you want to show
    /*
    function setupImages(items:number[]):JSX.Element[]{
        return items.map((el, pos)=>{
                        return <div key={pos} ref={getSliderImages} className="slider-image"></div>
                    })
    }
    */
    

    function mousedownScrollbarHandler(e:React.PointerEvent/*React.MouseEvent|React.TouchEvent*/):void {
        
        if(screenWidth.current < 768 && e.pointerType === 'touch'){
            //console.log('e.type:', e.type);
            return;
        }

        e.stopPropagation();
        const parentTarget = e.target as HTMLDivElement;
        //console.log('e.type:', e.type, window.innerWidth, document.documentElement.clientWidth);
        //console.log('scrollbar mouse down:', e.pageX, e.pageY);
        if(e.type === 'mousedown'){
            //console.log('scrollbar mouse down desktop:', (e as React.MouseEvent).pageX, (e as React.MouseEvent).pageY);
        } else {
            //console.log('scrollbar mouse:', e.type);
            //console.log('scrollbar mouse down mobile:', (e as React.TouchEvent).touches[0].pageX, (e as React.TouchEvent).touches[0].pageY);
            /*imageContainerRef.current!.style.transition = 'transform .3s linear';*/
            
        }
        const target = imgSliderScrollbarRef.current!;// (e.target as HTMLDivElement);
        const targetWidth = target.getBoundingClientRect().width;
        const sbcWidth = imgSliderScrollbarContainerRef.current!.getBoundingClientRect().width;
        const sbcLeftPos = imgSliderScrollbarContainerRef.current!.getBoundingClientRect().left;
        const sbLeftPos = target.getBoundingClientRect().left;
        const startPosition = 0;
        const endPosition = sbcWidth - targetWidth;

        imgSliderScrollbarContainerRef.current!.classList.remove('isb-expand-medium');
        imgSliderScrollbarContainerRef.current!.classList.add('isb-expand-full');
        //const gridColumnPadding = 16;
        //const sliderImageWidth = sliderImagesRef.current[0].getBoundingClientRect().width + gridColumnPadding;
        //const numberOfSliderImages = sliderImagesRef.current.length;

        //width for showing at least 4 slider images
        const sliderImageContainerWidth = (numberOfSliderImages.current * sliderImageWidth.current) - (numberOfSliderImagesVisible.current * sliderImageWidth.current);        
        //console.log('sicw:',sliderImageContainerWidth);
        //console.log('ep:', endPosition);
        //console.log('left:', imageContainerRef.current!.getBoundingClientRect().left);

        let cp = 0;
        let pp = 0;
        let ip = 0;
        
        //ip = (e.type === 'mousedown') ? (e as React.MouseEvent).pageX + (sbcLeftPos - sbLeftPos) : (e as React.TouchEvent).touches[0].pageX + (sbcLeftPos - sbLeftPos);
        ip = e.pageX + (sbcLeftPos - sbLeftPos);

        //scrollbarCurrentPosition.current = e.pageX + (sbcLeftPos - sbLeftPos);


        function documentMousemoveHandler(e:/*MouseEvent|TouchEvent*/PointerEvent):void {
            //console.log('mouse:', e instanceof MouseEvent, 'touch:', e instanceof TouchEvent);
            
            //console.log('document mousemove:', 'e.pageX:', e.pageX, 'leftPos:', sbcLeftPos, 'sbWidth:', targetWidth);
            //original number for scroll distance before test
            //cp = e.pageX - sbcLeftPos;

            //adjust for mouse pointer position on scroll bar
            //cp = (e.type === 'mousemove') ? (e as MouseEvent).pageX - ip : (e as TouchEvent).touches[0].pageX - ip;
            cp = e.pageX - ip;

            //cp = e.pageX - scrollbarCurrentPosition.current;
            
            //console.log('scrolled before:', `${((cp/endPosition) * 100).toFixed(2)}%`, cp);

            //console.log('cp:', cp, 'ep:', endPosition, 'sbcWidth:', sbcWidth, 'ep - cp:', endPosition - cp);;

            //desktop version    
            if(cp <= 0) {
                cp = 0;


                //hide left button
                if(screenWidth.current >= 768){
                    imgSliderLeftBtnRef.current!.classList.add('hide-image-slider-btn');
                }
                
                //set current distance for image slider container
                const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
                imageContainerRef.current!.style.transform = `translateX(-${currentDistance}px)`;
                target.style.transform = `translateX(${cp}px)`;
                //console.log('test:');
                return;
            }

            //desktop version
            if(cp >= endPosition){
                cp = endPosition;


                //hide right button
                if(screenWidth.current >= 768){
                    imgSliderRightBtnRef.current!.classList.add('hide-image-slider-btn');
                }
                
                //set current distance for image slider container
                const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
                imageContainerRef.current!.style.transform = `translateX(-${currentDistance}px)`;
                target.style.transform = `translateX(${cp}px)`;
                //console.log('test:');
                return;
            }


            
            if(cp > 0 && cp < pp){
                //console.log('test:');

                //show right button
                if(screenWidth.current >= 768){
                    imgSliderRightBtnRef.current!.classList.remove('hide-image-slider-btn');
                }
                

                //console.log('first');
                //go left
                //scroll right
                //set current distance for image slider container
                const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
                imageContainerRef.current!.style.transform = `translateX(-${currentDistance}px)`;
                target.style.transform = `translateX(${cp}px)`;

                //console.log('cd:', currentDistance, endPosition - cp);;
            
            } 
            
            //if(cp < sbcWidth && cp > pp){
            if(cp < endPosition && cp > pp){
                //console.log('test:');

                //show left button
                if(screenWidth.current >= 768){
                    imgSliderLeftBtnRef.current!.classList.remove('hide-image-slider-btn');
                }
                

                //console.log('second');
                //go right
                //scroll left
                //set current distance for image slider container
                const currentDistance = sliderImageContainerWidth * (endPosition === 0 ? 0 :(cp/endPosition));
                imageSliderDistance.current = currentDistance === 0 ? 0 : -currentDistance;
                imageContainerRef.current!.style.transform = `translateX(-${currentDistance}px)`;
                target.style.transform = `translateX(${cp}px)`;

                //console.log('cd:', currentDistance, endPosition - cp);
            }

            pp = cp;
            //ip = cp;

    
        }


        function mousemoveScrollbarHandler(e:MouseEvent):void {
            //console.log('scroll bar mouse move');
        }

        function mouseupScrollbarHandler(e:/*MouseEvent|TouchEvent*/PointerEvent):void {
            //e.stopPropagation();
            //console.log('scrollbar mouse up');
            //target.removeEventListener('mousemove', mousemoveScrollbarHandler);
            parentTarget.removeEventListener('pointerup', mouseupScrollbarHandler);
            document.removeEventListener('pointermove', documentMousemoveHandler);
            document.removeEventListener('pointerup', documentMouseupHandler);

            //parentTarget.removeEventListener('touchend', mouseupScrollbarHandler);
            //document.removeEventListener('touchmove', documentMousemoveHandler);
            //document.removeEventListener('touchend', documentMouseupHandler);

            imgSliderScrollbarContainerRef.current!.classList.remove('isb-expand-full');
            imgSliderScrollbarContainerRef.current!.classList.add('isb-expand-medium');

        }


        function documentMouseupHandler(e:/*MouseEvent|TouchEvent*/PointerEvent):void {
            //console.log('document mouseup');
            document.removeEventListener('pointermove', documentMousemoveHandler);
            document.removeEventListener('pointerup', documentMouseupHandler);
            document.ondragstart = null;
            //target.removeEventListener('mousemove', mousemoveScrollbarHandler);
            parentTarget.removeEventListener('pointerup', mouseupScrollbarHandler);

            //document.removeEventListener('touchmove', documentMousemoveHandler);
            //document.removeEventListener('touchend', documentMouseupHandler);
            //parentTarget.removeEventListener('touchend', mouseupScrollbarHandler);          

            imgSliderScrollbarContainerRef.current!.classList.remove('isb-expand-full');

        }

        
        document.ondragstart = function() {
            return false;
          };
          

        //target.addEventListener('mousemove', mousemoveScrollbarHandler);
        parentTarget.addEventListener('pointerup', mouseupScrollbarHandler);
        document.addEventListener('pointermove', documentMousemoveHandler);
        document.addEventListener('pointerup', documentMouseupHandler);

        //parentTarget.addEventListener('touchend', mouseupScrollbarHandler);
        //document.addEventListener('touchmove', documentMousemoveHandler);
        //document.addEventListener('touchend', documentMouseupHandler);

    }

    function mouseenterScrollbarHandler(e:React.MouseEvent):void {
        //console.log('mouseenter');
        if(imgSliderScrollbarContainerRef.current!.classList.contains('isb-expand-full')){
            return;
        } else {
            imgSliderScrollbarContainerRef.current!.classList.add('isb-expand-medium');
        }
    }

    function mouseleaveScrollbarHandler(e:React.MouseEvent):void {
        if(imgSliderScrollbarContainerRef.current!.classList.contains('isb-expand-full')){
            return;
        } else {
            imgSliderScrollbarContainerRef.current!.classList.remove('isb-expand-medium');
        }
    }


    function setupSliderImageListClass(st:SliderType):string {
        if(st.type === 'slider' && st.distance === 3){
            return 'slider-image-list-3';

        } else if(st.type === 'slider' && st.distance === 4) {
            return 'slider-image-list-4';

        } else {
            return 'slider-image-list';
        }
    }

    /*
    function setupItems(items:object[]):JSX.Element[] {
        return items.map((item, i)=>{
                        return <div key={i} className="slider-image">
                                    <Link to="/">

                                    </Link>
                                </div>
                    });
    }
    */

    function transitionEndHandler(e:React.TransitionEvent):void {
        //console.log('transitionend:', e.type);
        imageContainerRef.current!.style.transition = '';
        imgSliderScrollbarRef.current!.style.transition = '';
        inTransition.current = false;
    }


    return <div className="image-slider-wrapper">
                {
                    optionalHeader
                    ?
                    <div className="image-slider-heading-container">
                        {sliderType.heading !== 'none' ? <h3 className="image-slider-heading">{sliderType.heading}</h3> : null}
                        {sliderType.caption !== 'none' ? <div className="image-slider-caption">{sliderType.caption}</div> : null}
                    </div>
                    :
                    null
                }
                <div ref={imgSliderContainerRef} onScroll={scrollEventHandler} className="image-slider-container">
                    <button ref={imgSliderLeftBtnRef} aria-label="slide images left" type="button" className="image-slider-btn hide-image-slider-btn" value="left" onClick={imageSlideClickHandler}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                    </button>
                    <button ref={imgSliderRightBtnRef} aria-label="slide images right" type="button" className="image-slider-btn hide-image-slider-btn" value="right" onClick={imageSlideClickHandler}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                    </button>
                    {<div ref={imageContainerRef} id="slider" /*onTouchStart={mousedownImageSliderHandler}*/ onTransitionEnd={transitionEndHandler} className={setupSliderImageListClass(sliderType)}>
                        {setupItemsCallback(props.items)}
                    </div>}
                </div>
                <div ref={imgSliderScrollbarWrapperRef} className="image-slider-scrollbar-wrapper hide-image-slider-scrollbar-wrapper" onPointerDown={mousedownScrollbarHandler} /*onTouchStart={mousedownScrollbarHandler} onMouseDown={mousedownScrollbarHandler}*/
                    onMouseEnter={mouseenterScrollbarHandler} onMouseLeave={mouseleaveScrollbarHandler}>
                    <div ref={imgSliderScrollbarContainerRef} className="image-slider-scrollbar-container">
                        <div ref={imgSliderScrollbarRef} className="image-slider-scrollbar"
                        /*onMouseDown={mousedownScrollbarHandler}*/></div>
                    </div>
                </div>
            </div>
}

export default ImageSlider3;