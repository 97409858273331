import React, {useRef, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MapDisplay from './MapDisplay';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate, useRouteLoaderData } from 'react-router-dom';
import StoreLocation from './storeLocation';
import ListChanger from './ListChanger';
import ButtonCheckSelector from './ButtonCheckSelector';
import StoreSearchParams from './storeSearchParams';
//import {addStoreSearchParams, AddStoreSearchParams} from './redux/actions';
import {selectStoreLocation} from './redux/features/storeLocation/storeLocationSlice';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {selectAdminLogin, showAdminLogin} from './redux/features/adminLogin/adminLoginSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import addMyStoreName from './helper-add-my-store-name';
import StoreLocationDisplay from './StoreLocationDisplay';
import getItemList from './helper-get-item-list';
import { Loader } from '@googlemaps/js-api-loader';
import Footer from './Footer';
import ShopProductMiniCart from './ShopProductMiniCart';
import LoaderAnimation from './LoaderAnimation';
import loadNavLink from './loadNavLinkHelper';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import './StoreLocator.css';


interface Props {
    //path:string;
    //storeSearchParams?:StoreSearchParams;
    //storeLocation?:StoreLocation | null;
    //addStoreSearchParams?:(storeSearchParams:StoreSearchParams)=>AddStoreSearchParams;
}

/*
interface State {
    //storeSearchParams:StoreSearchParams;
    storeLocation:StoreLocation | null;
}
*/

/*
function mapStateToProps(state:State) {
    console.log('state:', state);
    const { storeSearchParams, storeLocation } = state;
    console.log('storeLocation test 2-26:', storeLocation);
    //const myStoreLocation = storeLocation;
    return {
        //storeSearchParams,
        storeLocation
    };

}
*/

/*
const mapDispatchToProps = {
    addStoreSearchParams
};
*/

const StoreLocator:React.FunctionComponent<Props> = (props:Props)=>{
    const dispatch = useAppDispatch();
    const storeLocationRedux = useAppSelector(selectStoreLocation);
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const navigate = useNavigate();

    let storeLocations: StoreLocation[] = [];
    let store:StoreLocation = new StoreLocation();
    //let list:StoreLocation[] = [];
    let storeSearchParams = new StoreSearchParams({
                                                    lat:29.40758, lng:-98.50176,
                                                    storeSearch:'current location',
                                                    currentLocationRequest:true
                                                    });
    const [ssps, setSsps] = useState<StoreSearchParams>(storeSearchParams);
    //total locations from search results
    const [storeLocationList, setStoreLocationList] = useState(storeLocations);
    const [storeLocation, setStoreLocation] = useState(store);
    /*console.log('storeSearch:', storeSearch);
    console.log('storeLocation:', storeLocation);*/
    const storeInputControl:React.RefObject<HTMLInputElement> = useRef(null);
    //filtered list of search results by page
    const [itemsList, setItemsList] = useState<StoreLocation[] | null>(null);
    const [storeLocationSearch, setStoreLocationSearch] = useState(ssps.storeSearch);
    const [storeLocationSearchPhrase, setStoreLocationSearchPhrase] = useState(ssps.storeSearch);
    //const [storeLocationBtnChecked, setStoreLocationSearchPhrase] = useState('');
    //original format
    //const [myStoreName, setMyStoreName] = useState('');
    //const [myStoreName, setMyStoreName] = useState(addMyStoreName(props.storeLocation as StoreLocation));
    const [myStoreName, setMyStoreName] = useState(addMyStoreName(new StoreLocation({...storeLocationRedux})));
    
    //const [myStoreLocation, setMyStoreLocation] = useState<StoreLocation | null>(props.myStoreLocation as StoreLocation);
    const [showStoreLocator, setShowStoreLocator] = useState(false);

    const [showNoLocationFoundMessage, setShowNoLocationFoundMessage] = useState<boolean>(false);
    const [storeLocationSearchErrorMessage, setStoreLocationSearchErrorMessage] = useState<string>('no error message');
    const [storeLocationSearchMessage, setStoreLocationSearchMessage] = useState<string>('searching');

    const [listChangerBtnPosition, setListChangerBtnPosition] = useState<number>(0);
    const [itemsPerList, setItemsPerList] = useState<number>(4);

    const [showStoreLocatorErrorMsg, setShowStoreLocatorErrorMsg] = useState(false);

    const [showLocationMap, setShowLocationMap] = useState(true);

    const storeLocatorContainerRefs = useRef<HTMLDivElement[]>([]);
    const [showMiniCart, setShowMiniCart] = useState(false);
    const miniCartHasProductsRef = useRef<boolean>(false);

	const portrait = useRef<MediaQueryList[]>([]);

    const isLoggedIn = useRouteLoaderData('exploreStore');
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(()=>{
        //checkLoginStatus()
        //.then((loggedIn)=>{
            //if(!loggedIn){
            //remove comments below after testing
            if(!isLoggedIn){
            //if(false){
                navigate('/admin-login/store-locator');

            } else {
                setLoggedIn(true);
                dispatch(showAdminLogin({showAdminLogin:true}));
            }
        //})
        //.catch((err)=>console.log('check login error:', err.message));
    },[]);

    function checkLoginStatus():Promise<boolean> {
        return fetch('https://server.kando-proto-3.com/check-admin-login-status', {
                    method:'GET',
                    mode:'cors',
                    credentials:'include',
                    headers:{
                        'Content-Type':'application/json',
                    }
                })
                .then((res)=>res.json())
                .then(({loginStatus})=>{
                    //console.log('loginStatus:', loginStatus);
                    if(loginStatus === 'loggedIn'){
                        return true;

                    } else {
                        return false;
                    }
                })
                .catch((err)=>{
                    //console.log('check login status error:', err.message);
                    return false
                });
    }



    useEffect(()=>{
        //window.scrollTo(0,0);
        //console.log('scr4:');
        if(window.innerWidth >= 768 && shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;
            setShowMiniCart(true);

        } else if(shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;

        } else {
            //console.log('scr4:');
            miniCartHasProductsRef.current = false;
            setShowMiniCart(false);

            if(storeLocatorContainerRefs.current.length > 0){
                //console.log('ran10');
                storeLocatorContainerRefs.current[0].style.width = '';
                storeLocatorContainerRefs.current[0].style.margin = '';
            }

        }

    },[shoppingCartRedux]);


    function getStoreLocatorContainerRef(el:HTMLDivElement|null):void {
    
        if(el !== null && storeLocatorContainerRefs.current.length === 0){
            storeLocatorContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = storeLocatorContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                storeLocatorContainerRefs.current.push(el);
            }

        }

        //console.log('plwrs:', storeLocatorContainerRefs.current);


        if(showMiniCart && window.innerWidth <= 1575){
            //console.log('smc:');
            storeLocatorContainerRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            storeLocatorContainerRefs.current[0].style.margin = '0 auto 0 0';

        } else {
            storeLocatorContainerRefs.current[0].style.width = '100%';
            storeLocatorContainerRefs.current[0].style.margin = '0 auto';
        }

        /*css styles for bread crumbs
        if(showMiniCart && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

    }

    useEffect(()=>{
        window.addEventListener('resize', shopProductResizeHandler);

        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);
        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", shopProductResizeHandler);

        return cleanupShopProductResizeHandler;
    },[]);

    function cleanupShopProductResizeHandler():void {
        window.removeEventListener('resize', shopProductResizeHandler);

        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
        portrait.current[0].removeEventListener("change", shopProductResizeHandler);

    }

    function screenOrientationChangeHandler(e:Event):void {
        shopProductResizeHandler(new Event('test'));
    }

    function shopProductResizeHandler(e:Event):void {
        //console.log('scr4:', miniCartHasProductsRef.current);
        if(window.innerWidth >= 768 && window.innerWidth <= 1575 && miniCartHasProductsRef.current){
            storeLocatorContainerRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            const ma = window.innerWidth > 1535 ? window.innerWidth - 1535 : 0;
            //console.log('ma:', ma);
            storeLocatorContainerRefs.current[0].style.margin = `0 auto 0 ${ma}px`;
            setShowMiniCart(true);

        } 
        
        if(window.innerWidth > 1575 && miniCartHasProductsRef.current){
            storeLocatorContainerRefs.current[0].style.width = '100%';
            storeLocatorContainerRefs.current[0].style.margin = '0 auto';
            //setShowMiniCart(true);

        }

        if(window.innerWidth >= 768 && !miniCartHasProductsRef.current){
            storeLocatorContainerRefs.current[0].style.width = '100%';
            storeLocatorContainerRefs.current[0].style.margin = '0 auto';
        }

        if(window.innerWidth < 768){
            storeLocatorContainerRefs.current[0].style.width = '100%';
            storeLocatorContainerRefs.current[0].style.margin = '0 auto';
            setShowMiniCart(false);
        }

        /*css styles for breadcrumbs
        if(miniCartHasProductsRef.current && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

    }



    //get new store location when user mouseover list item
    useEffect(()=>{
        let ssp:StoreSearchParams;
        const ss = window.sessionStorage.getItem('storeSearchParams');
        //console.log('center:', ss, 'sl:', storeLocation);

        if(ss !== null){
            ssp = new StoreSearchParams();
            const ussp = JSON.parse(ss);
            ssp.storeSearch = ussp.storeSearch;
            ssp.currentLocationRequest = ussp.currentLocationRequest;
            ssp.lat = ussp.lat;
            ssp.lng = ussp.lng;
            ssp.showStoreLocator = ussp.showStoreLocator;
            ssp.listChangerBtnPosition = ussp.listChangerBtnPosition;
    
            setListChangerBtnPosition(ussp.listChangerBtnPosition);
            
        } else {
            ssp = new StoreSearchParams({...ssps});
        }

        //console.log('store location useEffect():', ssp);
        //console.log('store search:', ssp.storeSearch);
        //console.log('storeLocationSearch:', storeLocationSearch);
        //console.log('clr:', ssp.currentLocationRequest);

        //console.log('center:', ss, 'ssp:', ssp);
        
        /*
        if(ssp.storeSearch === '' && ss !== null){
            const ussp = JSON.parse(ss);
            ssp.storeSearch = ussp.storeSearch;
            ssp.currentLocationRequest = ussp.currentLocationRequest;
            ssp.lat = ussp.lat;
            ssp.lng = ussp.lng;
            ssp.showStoreLocator = ussp.showStoreLocator;
            
        }
        */

        //console.log('ssp7:', ssp);

        setStoreLocationSearchMessage('searching');

        /*previous version
        if(!ssp.currentLocationRequest){
            console.log('passed');
            setStoreLocationSearch(ssp.storeSearch);
            setStoreLocationSearchPhrase(ssp.storeSearch);
            //setShowStoreSearchMessage(true);
            //clear any previous locations from the list
            if(itemsList !== null){
                setItemsList(null);
            }
        

            getStoreLocation(ssp);


        } else if(ssp.currentLocationRequest){
            console.log('passed');
            //clear any previous locations from the list
            if(itemsList !== null){
                setItemsList(null);
            }
            
            //original version
            //ssp.currentLocationRequest = false;
            getCustomerLocation(ssp); 
          
        }
        */

        if(itemsList !== null){
            setItemsList(null);
        }

        setStoreLocationSearch(ssp.storeSearch);
        setStoreLocationSearchPhrase(ssp.storeSearch);

        getCustomerLocation(ssp);
        

        dispatch(addProductKeywordSearch!({
            keywordSearch:'',
            priceRangeSearch:'relatedProducts',
            storeDepartmentSearch:'none',
            departmentCategorySearch:'none',
            sentFromKeywordList:false,
            clearKeywordSearch:true
                }));

    },[]);
    


    function getStoreLocation(ssp:StoreSearchParams):void {
        //console.log('get search:', ssp.storeSearch);
       // window.setTimeout(()=>{
            setShowStoreLocator(true);
            ssp.showStoreLocator = true;
            //(addStoreSearchParams as (storeSearchParams:StoreSearchParams)=>AddStoreSearchParams)(ssp);
            setStoreLocationSearchMessage('searching');

            const params = {storeSearch:ssp.storeSearch};

        //},1000);
        //get store locations
        //previous version
        //fetch(`https://server.kando-proto-3.com/store-location-list.json/${ssp.storeSearch}`,{
        fetch(`https://server.kando-proto-3.com/store-location-list.json`,{
            method:'POST',
            mode:'cors',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(params)
        })
        .then(res => res.json())
        .then(({storeLocationList, coords, distanceExceeded, candidatesFound}) => {
            //console.log('test:', storeLocationList, 'coords:', coords, 'de:', distanceExceeded, 'cf:', candidatesFound);

            if(distanceExceeded || !candidatesFound){
                //show no location msg if a location was not found.
                setStoreLocationSearchErrorMessage(ssp.storeSearch);
                let sl = new StoreLocation({...storeLocation});
                sl.location.lat = sl.location.lat === 0 ? ssps.lat : sl.location.lat;
                sl.location.lng = sl.location.lng === 0 ? ssps.lng : sl.location.lng;
                sl.storeLocationFound = false;
                setStoreLocation(sl);
                setStoreLocationList([]);

                //console.log('center:',ssp);

                ssp.lat = ssps.lat;
                ssp.lng = ssps.lng;

                

            } else {
                ssp.lat = coords.lat;
                ssp.lng = coords.lng;

                setStoreLocationList(storeLocationList);
                //storeLocationList[0].storeLocationFound = true;
                //previous version
                //setStoreLocation(storeLocationList[0]);
                getStoreLocationItem(ssp.listChangerBtnPosition, itemsPerList, storeLocationList);
                //previous version
                //setItemsList(storeLocationList.slice(0,3));
                getItemList(ssp.listChangerBtnPosition, storeLocationList, itemsPerList, showItemsPerList);

            }

            //console.log('ssp test:', ssp, ssps);

            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
            setStoreLocationSearchMessage('completed');
            

        })
        .catch(error =>{
            //console.log('store locator get store error:', error.message);
            //console.log('sl:', storeLocation);
            //close map location market info window if open
            storeLocation.storeLocationFound = false;
            setStoreLocation(new StoreLocation({...storeLocation}));
            setShowStoreLocator(true);
            setShowStoreLocatorErrorMsg(true);
        });
        
    }

    function getCustomerLocation(ssp:StoreSearchParams) {

        const loader = new Loader({
            apiKey: "AIzaSyD02knEm3qPtvmQW4aJhp7H4V9VGOu4KDQ",
            version: "weekly"
          });

        loader
        .load()
        .then((google)=>{
            //console.log('google:', google);
            //get customer zip code
            const lat:number = Number(ssp.lat);
            const lng:number = Number(ssp.lng);
            let latLng = new google.maps.LatLng(lat, lng);
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({'location': latLng}, function(results:google.maps.GeocoderResult[]|null, status:google.maps.GeocoderStatus) {
                //console.log('geocode results:', results);
                //console.log('geocode status:', status, results);
            if (status === 'OK' && results !== null) {            
                //console.log('address:', results[1].formatted_address.split(','));
                /*let userFormatedAddress:string[] = results[1].formatted_address.split(',');
                let userStreetAddress:string = results[1].formatted_address.split(',')[0];
                let userCityAddress:string = results[1].formatted_address.split(',')[1];*/
                let userStateZipAddress:string = results[1].formatted_address.split(',')[2];
                //console.log('zip code:', userStateZipAddress.slice(-5));
                window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));

                setStoreLocationSearch(userStateZipAddress.slice(-5));
                setStoreLocationSearchPhrase(userStateZipAddress.slice(-5));
                ssp.storeSearch = userStateZipAddress.slice(-5);

                //original position
                //setShowStoreLocator(true);
                    
                ssp.showStoreLocator = true;

                //get store locations
                fetch(`https://server.kando-proto-3.com/customer-store-location-list.json/${lat},${lng}`,{
                    method:'GET',
                    mode:'cors',
                    headers:{
                        //'Accept-Control-Request-Method':'GET',
                        //'origin':'http://localhost:3000',
                        'Accept':'application/json'
                    }
                })
                .then(res => res.json())
                .then(({storeLocationList}) => {
                    //console.log('sll:', storeLocationList.slice(0,3));
                    //console.log('browser store-location-list:', storeLocationList);
                    //console.log('browser store-location:', storeLocationList[0]);
                    setStoreLocationList(storeLocationList);
                    //previous version
                    //setStoreLocation(storeLocationList[0]);
                    getStoreLocationItem(ssp.listChangerBtnPosition, itemsPerList, storeLocationList);
                    //previous version
                    //setItemsList(storeLocationList.slice(0,3));
                    getItemList(ssp.listChangerBtnPosition, storeLocationList, itemsPerList, showItemsPerList);

                    setStoreLocationSearchMessage('completed');
                    setShowStoreLocator(true);
                })
                .catch(error =>{
                    //console.log('store locator get customer error:', error.message);
                    setShowStoreLocator(true);
                    setShowStoreLocatorErrorMsg(true);
                }); 
                        
            } else {
                //console.log('Geocode was not successful for the following reason: ' + status);
                //setStoreLocationSearch('no zip code found');
                //setStoreLocationSearchPhrase('no zip code found');

                setShowStoreLocator(true);
                setShowStoreLocatorErrorMsg(true);

                /*
                //get store locations
                fetch(`http://localhost:9500/customer-store-location-list.json/${lat},${lng}`,{
                    headers:{
                        'Accept-Control-Request-Method':'GET',
                        'origin':'http://localhost:3000',
                        'Accept':'application/json'
                    }
                })
                .then(res => res.json())
                .then(({storeLocationList}) => {
                    console.log('browser store-location-list:', storeLocationList);
                    console.log('browser store-location:', storeLocationList[0]);
                    setStoreLocationList(storeLocationList);
                    setStoreLocation(storeLocationList[0]);
                    setItemsList(storeLocationList.slice(0,3));

                    setStoreLocationSearchMessage('completed');
                })
                .catch(err => console.log('error:', err));
                */

            }
            });

        })
        .catch((err)=>{
            //console.log('google maps error:', err.message);
            setShowStoreLocator(true);
            setShowStoreLocatorErrorMsg(true);
        });

    }


    function handleSubmitStoreSearch(e:React.FormEvent):void {        
        e.preventDefault();
        setStoreLocationSearch(storeLocationSearchPhrase);

        if(itemsList !== null){
            setItemsList(null);
        }
        
        setStoreLocationSearchErrorMessage('no error message');

        const ssp = new StoreSearchParams({
                                storeSearch:storeLocationSearchPhrase,
                                currentLocationRequest:false
                                        });

        ssp.listChangerBtnPosition = 0;

        setListChangerBtnPosition(0);

        getStoreLocation(ssp);
        
    }  

    function handleStoreSearch(e:React.ChangeEvent<HTMLInputElement>):void {
        //console.log('input value:', e.target.value);
        setStoreLocationSearchPhrase(e.target.value);  
        //console.log('store address encoded:', e.target.value.replace(/ /gi, '+'));
    }

    function centerMap(storeLocation:StoreLocation):void {
        //console.log('center map');
        storeLocation.storeLocationFound = true;
        setStoreLocation(storeLocation);
    }

    function getStoreLocationItem(listChangerBtnPosition:number, itemsPerList:number, storeLocationList:StoreLocation[]):void {
        //console.log('lcbp:', listChangerBtnPosition, 'ipl:', itemsPerList);
        const bp = listChangerBtnPosition + 1;
        const lastItem = bp * itemsPerList;
        const firstItem = lastItem - itemsPerList;
        //console.log('fi:', storeLocationList[firstItem]);
        storeLocationList[firstItem].storeLocationFound = true;
        setStoreLocation(storeLocationList[firstItem]);
    }

    function showItemsPerList(itemsList:StoreLocation[]):void {
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        //console.log('il7:');
        if(window.innerWidth >= 1024){
            setShowLocationMap(false);
            setTimeout(()=>{
                setShowLocationMap(true);
            },600);
        }

        setListChangerBtnPosition(listChangerBtnPosition);

        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }

        //window.scrollTo(0,0);     
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setShowStoreLocator(false);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setShowStoreLocator(true);
        },100); 

    }

    function setMyCheckedStoreName(storeNameChecked:string):void {
        //console.log('setMyStoreName')
        setMyStoreName(storeNameChecked);
    }

    function storeLocatorCallback(e:React.MouseEvent, pathname:string):void {
        e.preventDefault();

        setShowStoreLocator(false);
    
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        navigate(pathname);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    
        
    }

    //if(showStoreLocator){

        return (
            <div className="store-locator-wrapper">
                <ProductSearch/>
                <MainNavbar2/>
                <MenuListMobileNav/>


                {
                    showStoreLocator
                    ?
                    <div ref={getStoreLocatorContainerRef} className="store-locator-container">
                        <ul className="store-details-breadcrum-container">
                            <li className="slbi">
                                <Link to="/" className="store-details-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/', navigate)}>Home</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="slbi store-details-breadcrum-link-store-name">Find a store</li>
                        </ul>
                        <h1 className="store-locator-heading">Find a Store</h1>

                        <StoreLocationDisplay 
                            itemsList={itemsList} storeLocationList={storeLocationList} storeLocation={storeLocation} 
                            storeLocationSearch={storeLocationSearch} storeLocationSearchPhrase={storeLocationSearchPhrase}
                            myStoreName={myStoreName} storeLocationSearchErrorMessage={storeLocationSearchErrorMessage}
                            listChangerBtnPosition={listChangerBtnPosition} itemsPerList={itemsPerList}
                            showStoreLocatorErrorMsg={showStoreLocatorErrorMsg}
                            getListChangerBtnPosition={getListChangerBtnPosition}
                            storeLocationSearchMessage={storeLocationSearchMessage} handleStoreSearch={handleStoreSearch} 
                            handleSubmitStoreSearch={handleSubmitStoreSearch} centerMap={centerMap} 
                            setMyCheckedStoreName={setMyCheckedStoreName} showItemsPerList={showItemsPerList}
                            showLocationMap={showLocationMap} stCallback={storeLocatorCallback}/>

                    </div>
                    :
                    <div className="sbat-loader-animation-container">
                        {
                            loggedIn
                            ?
                            <LoaderAnimation/>
                            :
                            null
                        }
                    </div>
                    
                }


                {
                    showMiniCart
                    ?
                    <ShopProductMiniCart/>
                    : null
                }

                {/*<Footer/>*/}
            </div>
        )        

/*
    } else {
        return  <div className="store-locator-loader-animation-container">
                    <LoaderAnimation/>
                </div>
            

    }
    */


}

export default StoreLocator;
/*
export default connect(
    mapStateToProps,
    null//mapDispatchToProps
)(StoreLocator);
*/
