import SearchFilter from './searchFilter';
import Product from './product';


export class SearchFilterArea {
    public departmentId:number;
    public highlightText:boolean;
    public searchFilterAreaType:string;
    public searchFilterAreaName:string;
    public searchFilterAreaId:number;
    public searchFilterAreaChildren:SearchFilterArea[];

    constructor(options:{
        departmentId?:number,
        highlightText?:boolean,
        searchFilterAreaType?:string,
        searchFilterAreaName?:string,
        searchFilterAreaId?:number,
        searchFilterAreaChildren?:SearchFilterArea[]

    }={}){
        this.departmentId = options.departmentId || 0;
        this.highlightText = options.highlightText || false;
        this.searchFilterAreaType = options.searchFilterAreaType || 'Department';
        this.searchFilterAreaName = options.searchFilterAreaName || 'none';
        this.searchFilterAreaId = options.searchFilterAreaId || 0;
        this.searchFilterAreaChildren = options.searchFilterAreaChildren || [];
    }
}

export default class RelatedProduct {
    public keywordSearchType:string;
    public searchFilterAreas:SearchFilterArea[];
    public searchFilters:SearchFilter[];
    public products:Product[];
    public isProductCategory:boolean;

    constructor(options:{
        keywordSearchType?:string,
        searchFilterAreas?:SearchFilterArea[],
        searchFilters?:SearchFilter[],
        products?:Product[],
        isProductCategory?:boolean;
    }={}){
        this.keywordSearchType = options.keywordSearchType || '';
        this.searchFilterAreas = options.searchFilterAreas || [];
        this.searchFilters = options.searchFilters || [];
        this.products = options.products || [];
        this.isProductCategory = options.isProductCategory || false;
    }
}
