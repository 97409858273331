import React, {useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import StoreDepartment from './storeDepartment';

interface Props {
    storeDepartment:StoreDepartment;
}

const HighlightDepartmentText:React.FunctionComponent<Props> = (props:Props)=>{
    const {storeDepartment} = props;
    //console.log('storeDepartment highlighted:', storeDepartment.departmentName);
    //console.log('highlightText:', storeDepartment.highlightText === true);

    if(storeDepartment.highlightText){
        return <div className="highlight-department-text">{storeDepartment.departmentName}</div>;

    } else {
        return <div>{storeDepartment.departmentName}</div>;

    }

}

export default HighlightDepartmentText;