import React, {useState, useEffect, useRef} from 'react';
import MarketingBanner from './marketingBanner';
import MarketingPicture from './marketingPicture';
import HomePageLayoutItem from './homePageLayoutItem';
import ShopProductDetail from './ShopProductDetail';
import Product from './product';
import './HomePageLayoutItemDisplay.css';

interface Props {
    type:string;
    item:HomePageLayoutItem;
    selectedHomePageLayoutItems:HomePageLayoutItem[];
    existingHomePageLayoutItems:HomePageLayoutItem[];
    displayExceededSliderQtyMessage:(qyt:number)=>void;
    updateHomePageLayoutItemLists:(hpli:HomePageLayoutItem, hpliChecked:boolean)=>void;
    getImageRef:(el:HTMLImageElement|null)=>void;
}

const HomePageLayoutItemDisplay:React.FunctionComponent<Props> = (props:Props)=>{
    const {getImageRef, selectedHomePageLayoutItems, existingHomePageLayoutItems, displayExceededSliderQtyMessage, updateHomePageLayoutItemLists} = props;
    const [homePageLayoutItem, setHomePageLayoutItem] = useState(props.item);
    const [homePageLayoutType, setHomePageLayoutType] = useState(props.type);
    const [homePageLayoutItemChecked, setHomePageLayoutItemChecked] = useState(false);

    //console.log('shplis:', selectedHomePageLayoutItems.length);

    function checkboxHandler(e:React.ChangeEvent, selectedHomePageLayoutItems:number, existingHomePageLayoutItems:number, type:string):void {
        const checkboxChecked = (e.target as HTMLInputElement).checked;
        const checkboxValue = (e.target as HTMLInputElement).value;
        //console.log('checked:', checkboxChecked, 'value:', checkboxValue, 'hplis:', selectedHomePageLayoutItems, existingHomePageLayoutItems, getLayoutTypeQty(type), type);
        if(existingHomePageLayoutItems + selectedHomePageLayoutItems > getLayoutTypeQty(type)){
            displayExceededSliderQtyMessage(getLayoutTypeQty(type));
        } else {
            setHomePageLayoutItemChecked(checkboxChecked);

            updateHomePageLayoutItemLists(homePageLayoutItem, checkboxChecked);
        }

    }


    function getLayoutTypeQty(type:string):number {
        switch(type){
            case 'banner-box':
                return 1;
            case 'card-box':
                return 2;
            case 'slider3':
                return 3;
            case 'slider4':
                return 4;
            case 'carousel':
                return 25;
            default:
                throw new Error('No layout type qty found.');
        }
    }


    function setupHomePageLayoutItem(hpli:HomePageLayoutItem, type:string):JSX.Element {
        switch(type){
            case 'slider3':
            case 'slider4':
            case 'card-box':
                const mp = new MarketingPicture({...hpli});
                return <div className="hplid-img-wrapper">
                            <form className="hplid-checkbox-form">
                                <input className="hplid-checkbox-control" id={mp.marketingPictureId.toString()} type="checkbox" value={mp.marketingPictureId} 
                                    checked={homePageLayoutItemChecked} onChange={(e:React.ChangeEvent)=>checkboxHandler(
                                                                                                                e, 
                                                                                                                selectedHomePageLayoutItems.length + 1,
                                                                                                                existingHomePageLayoutItems.length,
                                                                                                                type)}/>
                            </form>
                            <div className="hplid-img-container hplid-img-adj">
                                <img ref={getImageRef} className="hplid-img" data-alt={mp.heading} alt={""} data-src={`${mp.desktopSource}`}/>
                            </div>
                            <div className="hplid-heading-caption-wrapper">
                                {
                                    mp.heading !== 'none' ? 
                                                        <div className="hplid-heading-container">
                                                            <div className="hplid-heading-label" role="label">Heading</div>
                                                            <div className="hplid-heading">{mp.heading}</div>
                                                        </div> 
                                                        :
                                                        <div className="hplid-heading-container">
                                                            <div className="hplid-heading-label" role="label">Heading</div>
                                                            <div className="hplid-heading">No heading has been added.</div>
                                                        </div>
                                }
                                {
                                    mp.caption !== 'none' ? 
                                                        <div className="hplid-caption-container">
                                                            <div className="hplid-caption-label" role="label">Caption</div>
                                                            <div className="hplid-caption">{mp.caption}</div>
                                                        </div> 
                                                        :
                                                        <div className="hplid-caption-container">
                                                            <div className="hplid-caption-label" role="label">Caption</div>
                                                            <div className="hplid-caption">No caption has been added.</div>
                                                        </div>
                                                        
                                }
                            </div>
                        </div>;

            case 'banner-box':
                const mb = new MarketingBanner({...hpli});
                return <div className="hplid-img-wrapper">
                            <form className="hplid-checkbox-form">
                                <input className="hplid-checkbox-control" id={mb.marketingBannerId.toString()} type="checkbox" value={mb.marketingBannerId} 
                                    checked={homePageLayoutItemChecked} onChange={(e:React.ChangeEvent)=>checkboxHandler(
                                                                                                                e, 
                                                                                                                selectedHomePageLayoutItems.length + 1,
                                                                                                                existingHomePageLayoutItems.length,
                                                                                                                type)}/>
                            </form>
                            <div className="hplid-img-container-wrapper">
                                <div style={{paddingBottom:`${mb.paddingBottomMobile}%`}} className="hplid-img-container">
                                    <img ref={getImageRef} className="hplid-img" data-alt={mb.heading} alt={""} data-src={`${mb.mobileSource}`}/>
                                </div>
                            </div>
                            <div className="hplid-heading-caption-wrapper">
                                {
                                    mb.heading !== 'none' ? 
                                                        <div className="hplid-heading-container">
                                                            <div className="hplid-heading-label">Heading</div>
                                                            <div className="hplid-heading">{mb.heading}</div>
                                                        </div> 
                                                        : 
                                                        <div className="hplid-heading-container">
                                                            <div className="hplid-heading-label">Heading</div>
                                                            <div className="hplid-heading">No heading has been added.</div>
                                                        </div> 
                                }
                                {
                                    mb.caption !== 'none' ? 
                                                        <div className="hplid-caption-container">
                                                            <div className="hplid-caption-label">Caption</div>
                                                            <div className="hplid-caption">{mb.caption}</div>
                                                        </div> 
                                                        : 
                                                        <div className="hplid-caption-container">
                                                            <div className="hplid-caption-label">Caption</div>
                                                            <div className="hplid-caption">No caption has been added.</div>
                                                        </div>
                                }
                            </div>
                        </div>;

            case 'carousel':
                const product = new Product({...hpli});
                //return <ShopProductDetail product={product} getImageRef={getImageRef}/>
                return <div className="hplid-img-wrapper">
                            <form className="hplid-checkbox-form">
                                <input className="hplid-checkbox-control" id={product.productId.toString()} type="checkbox" value={product.productId} 
                                    checked={homePageLayoutItemChecked} onChange={(e:React.ChangeEvent)=>checkboxHandler(
                                                                                                                    e, 
                                                                                                                    selectedHomePageLayoutItems.length + 1,
                                                                                                                    existingHomePageLayoutItems.length,
                                                                                                                    type)}/>
                            </form>
                            <div className="hplid-img-container hplid-img-adj">
                                {/*<img className="hplid-img" src={`${process.env.PUBLIC_URL}${product.productImage}`}/>*/}
                                <img ref={getImageRef} className="hplid-img" data-alt={product.productName} alt={""} data-src={`${product.productMobileImage}`}/>
                            </div>
                            <div className="hplid-heading-container">
                                <div className="hplid-heading">{product.productName}</div>
                            </div>
                        </div>;

            default:
                throw new Error('no home page layout type found.');

        }
    }

    return <div className="hplid-wrapper">
                {setupHomePageLayoutItem(homePageLayoutItem, homePageLayoutType)}
            </div>;
}

export default HomePageLayoutItemDisplay;