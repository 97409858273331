import ShopCategory from './shopCategory';
import SubShopCategory from './subShopCategory';

const adcs = [
    new ShopCategory({
        shopCategoryId:1,
        shopCategoryName:'Product Reviews',
        shopCategoryLink:'/admin/product-reviews'
    }),
    new ShopCategory({
        shopCategoryId:2,
        shopCategoryName:'Home Page Layouts',
        shopCategoryLink:'/admin/home-page-layouts'
    }),
    new ShopCategory({
        shopCategoryId:3,
        shopCategoryName:'Store Locations',
        shopCategoryLink:'/admin/store-locations'
    }),
    new ShopCategory({
        shopCategoryId:4,
        shopCategoryName:'Store Structure',
        shopCategoryLink:'/admin/store-structure',
        subShopCategories:[
            new SubShopCategory({
                subShopCategoryId:1,
                shopCategoryId:4,
                subShopCategoryName:'Departments',
                subShopCategoryLink:'/admin/store-structure/departments'
            }),
            new SubShopCategory({
                subShopCategoryId:2,
                shopCategoryId:4,
                subShopCategoryName:'Department Categories',
                subShopCategoryLink:'/admin/store-structure/department-categories'
            }),
            new SubShopCategory({
                subShopCategoryId:3,
                shopCategoryId:4,
                subShopCategoryName:'Product Categories',
                subShopCategoryLink:'/admin/store-structure/product-categories'
            }),
            new SubShopCategory({
                subShopCategoryId:4,
                shopCategoryId:4,
                subShopCategoryName:'Product Subcategories',
                subShopCategoryLink:'/admin/store-structure/product-sub-categories'
            }),
            new SubShopCategory({
                subShopCategoryId:5,
                shopCategoryId:4,
                subShopCategoryName:'Products',
                subShopCategoryLink:'/admin/store-structure/products'
            }),
            new SubShopCategory({
                subShopCategoryId:6,
                shopCategoryId:4,
                subShopCategoryName:'Search Filters',
                subShopCategoryLink:'/admin/store-structure/search-filters'
            }),
            new SubShopCategory({
                subShopCategoryId:7,
                shopCategoryId:4,
                subShopCategoryName:'Search Terms',
                subShopCategoryLink:'/admin/store-structure/search-terms'
            })
        ]
    })
];

export default adcs;