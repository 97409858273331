import React, {useEffect, useState, useRef} from 'react';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import ProductSubCategory from './productSubCategory';
import StoreDepartmentCategorySidebarSelector2 from './StoreDepartmentCategorySidebarSelector2';
import Product from './product';
import SearchFilter from './searchFilter';
//import {Link, navigate, useMatch} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import ShopProductDetail from './ShopProductDetail';
import {getAnyItemList} from './helper-get-item-list';
import addDepartmentCategories, {addSearchFilters} from './helper-add-department-categories';
import cloneDeep from 'lodash/cloneDeep';
import PriceRange from './priceRange';
import helperHighlightCategory from './helper-highlight-category';
import helperSetOptionValue from './helper-set-option-value';
import HighlightDepartmentText from './HighlightDepartmentText';
import SearchFilterSidebar from './SearchFilterSidebar';
import PriceSlider3 from './PriceSlider3';
import {sortByAtoZ, sortByZtoA, sortByNewest, sortByRatings, 
    sortByPriceHightoLow, sortByPriceLowtoHigh} from './helper-sort-by-option';
import HighlightDepartmentCategoryText from './HighlightDepartmentCategoryText';
import helperHighlightDepartment from './helper-highlight-department';
import checkFilterSearchTerm2, {checkSearchFiltersSelected, updateSearchFilters2} from './helper-search-filter-setup';
import {addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {selectSearchFilterParams, addSearchFilterParams, SearchFilterParams} from './redux/features/searchFilterParams/searchFilterParamsSlice';
import { selectSearchFilterProductsVisibility } from './redux/features/searchFilterProductVisibility/searchFilterVisibilitySlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import Sbat from './shopByAreaType';
import formatLinkString from './helper-format-link-string';
import checkSearchFilterValidity, {SearchFilterValidity} from './helper-check-search-filter-validity';
import './ShopByAreaTypeFilter.css';
//import { useSelector } from 'react-redux';

interface Props {
    //storeDepartment:StoreDepartment;
    //storeDepartments:StoreDepartment[];
    storeDepartmentName:string;
    storeDepartmentId:string;
    departmentCategoryName:string;
    departmentCategoryId:string;
    productCategoryName:string;
    productCategoryId:string;
    shopByAreaType:Sbat;
    /*searchFilters:SearchFilter[];
    initialSearchFilters:SearchFilter[];
    startSliderAmount:number;
    endSliderAmount:number;
    previousSlider1PositionLeft:string;
    previousSlider2PositionLeft:string;
    previousSlider1PostionLeftAmount:number;
    previousSlider2PostionLeftAmount:number;
    showPriceSlider:boolean;*/
    getShopByAreaTypeProducts:(products:Product[], kst:string)=>void;
    getStoreDepartment:(sd: StoreDepartment|null)=>void;
    getStoreDepartmentCategory:(dc:DepartmentCategory|null)=>void;
    getProductCategoryForSBAT:(pc:ProductCategory|null)=>void;
    getProductCategorySearchFiltersForSBAT:(searchFilters:SearchFilter[])=>void;
    getKeywordSearchType:(kst: string)=>void;
    sortByOptionValue:string;
    loadNavLink:(e:React.MouseEvent, pathname:string)=>void;
    sssbatps: React.Dispatch<React.SetStateAction<boolean>>;
    getSearchFilterValidity:(sfv:SearchFilterValidity)=>void;
}

const ShopByAreaTypeFilter:React.FunctionComponent<Props> = (props:Props)=>{
    //console.log('sdnf2:', props.storeDepartmentName, 'sdid:',props.storeDepartmentId, 'dcn:',props.departmentCategoryName, 'dcid:', props.departmentCategoryId, 'pcn:', props.productCategoryName, 'pcid:', props.productCategoryId, 'sbat:', props.shopByAreaType);
    const {getShopByAreaTypeProducts, getStoreDepartment, getKeywordSearchType, 
            getStoreDepartmentCategory, getProductCategoryForSBAT, getProductCategorySearchFiltersForSBAT, loadNavLink, sssbatps, getSearchFilterValidity} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const searchFilterParamsRedux = useAppSelector(selectSearchFilterParams);
    //console.log('isLoaded:', isLoaded, ssbatps);

    /***department properties***/
    const [storeDepartments, setStoreDepartments] = useState<StoreDepartment[] | []>([]);
    const [storeDepartment, setStoreDepartment] = useState<StoreDepartment | null>(null);
    const [storeDepartmentId, setStoreDepartmentId] = useState(props.storeDepartmentId);
    const [storeDepartmentName, setStoreDepartmentName] = useState(props.storeDepartmentName);
    const [showShopStoreDepartment, setShowShopStoreDepartment] = useState(false);
    const [controller, setController] = useState<AbortController>(new AbortController());
    //const [showDepartmentProducts, setShowDepartmentProducts] = useState(false);
    //const [departmentProducts, setDepartmentProducts] = useState<Product[] | []>([]);
    const [sortByOptionValue, setSortByOptionValue] = useState(props.sortByOptionValue);
    //const [departmentProductTotal, setDepartmentProductTotal] = useState(0);
    const [keywordSearchType, setKeywordSearchType] = useState('results');
    const [startSliderAmount, setStartSliderAmount] = useState(0);
    const [endSliderAmount, setEndSliderAmount] = useState(0);
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState('0%');
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState('100%');
    const [previousSlider1PostionLeftAmount, setPreviousSlider1PostionLeftAmount] = useState(0);
    const [previousSlider2PostionLeftAmount, setPreviousSlider2PostionLeftAmount] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(0);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(100);
    const [slider1ZIndex, setSlider1ZIndex] = useState(0);
    const [slider2ZIndex, setSlider2ZIndex] = useState(0);
    const [keywordSearch, setKeywordSearch] = useState(props.storeDepartmentName);
    const [showPriceSlider, setShowPriceSlider] = useState(false);
    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [initialSearchFilters, setInitialSearchFilters] = useState<SearchFilter[]>([]);
    const [searchTermIsSelected, setSearchTermIsSelected] = useState(false);
    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);
    const [hasDepartmentCategories, setHasDepartmentCategories] = useState<boolean>(false);
    const [itemsPerList, setItemsPerList] = useState(40);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Product[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No store locations have been added.');


    /***department category properties***/
    //const [departmentCategoryProductTotal, setDepartmentCategoryProductTotal] = useState(0);
    //const [departmentCategoryProducts, setDepartmentCategoryProducts] = useState<Product[] | []>([]);
    //const [showDepartmentCategoryProducts, setShowDepartmentCategoryProducts] = useState(false);
    const [showSpecialProductCategoryShortcut, setShowSpecialProductCategoryShortcut] = useState(true);
    const [departmentCategoryId, setDepartmentCategoryId] = useState(props.departmentCategoryId);
    const [hasProductCategories, setHasProductCategories] = useState<boolean>(false);


    /***product category properties***/
    const [productCategories, setProductCategories] = useState<ProductCategory[] | []>([]);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(props.departmentCategoryName);
    const [productCategoryName, setProductCategoryName] = useState(props.productCategoryName);
    //const [productCategoryProducts, setProductCategoryProducts] = useState<Product[] | []>([]);
    const [productCategory, setProductCategory] = useState<ProductCategory | null>(null);
    const [storeDepartmentCategory, setStoreDepartmentCategory] = useState<DepartmentCategory | null>(null);
    const [isSpecialDepartmentCategory, setIsSpecialDepartmentCategory] = useState(false);
    //const [productCategoryProductTotal, setProductCategoryProductTotal] = useState(0);
    //const [showProductCategoryProducts, setShowProductCategoryProducts] = useState(false);
    const [productCategorySearch, setProductCategorySearch] = useState('product');
    const [productSubCategorySelected, setProductSubCategorySelected] = useState<ProductSubCategory | null>(null);
    const [productCategoryId, setProductCategoryId] = useState(props.productCategoryId);

    const [shopByAreaTypeProducts, setShopByAreaTypeProducts] = useState<Product[]>([]);
    const [showShopByAreaTypeProducts, setShowShopByAreaTypeProducts] = useState<boolean>(/*ssbatps*/false);
    const [shopByAreaTypeProductTotal, setShopByAreaTypeProductTotal] = useState(0);

    const shopProductsMobileNavRef = useRef<HTMLDivElement>(null);

    const [shopByAreaType, setShopByAreaType] = useState(props.shopByAreaType);

    const showPriceSliderRef = useRef<boolean>(false);

    const highestPriceProductAmount = useRef<number>(0);
    const lowestPriceProductAmount = useRef<number>(0);

    const portrait = useRef<MediaQueryList[]>([]);

    const [showSearchFilterSidebar, setShowSearchFilterSidebar] = useState(true);

    const [showMobileFilter, setShowMobileFilter] = useState(true);

    const searchFilterErrorMsgRef = useRef<boolean>(false);

    //console.log('null5:');
    //console.log('sfstis:', searchFilters, searchTermIsSelected);

    //console.log('r21:', 'dId:', props.storeDepartmentId, 'dcId:', props.departmentCategoryId, 'pcId;', props.productCategoryId, props.shopByAreaType);


    useEffect(()=>{
        //console.log('r21:');

        searchFilterErrorMsgRef.current = false;

        /*previous version
        setSortByOptionValue(props.sortByOptionValue);
        setStoreDepartmentName(props.storeDepartmentName);
        setStoreDepartmentId(props.storeDepartmentId);
        setDepartmentCategoryName(props.departmentCategoryName);
        setDepartmentCategoryId(props.departmentCategoryId);
        setProductCategoryName(props.productCategoryName);
        setProductCategoryId(props.productCategoryId);
        setShopByAreaType(props.shopByAreaType);
        */

        if(props.shopByAreaType.type === 'department'){        
            /*
            dispatch(addProductKeywordSearch({
                keywordSearch:'',
                priceRangeSearch:'none',
                storeDepartmentSearch:'none',
                departmentCategorySearch:'none',
                sentFromKeywordList:false
                    }));
                    */
            //setShowShopStoreDepartment(false);
            //console.log('sdn1:', storeDepartmentName, storeDepartmentId, props.storeDepartmentName, props.storeDepartmentId);
            //console.log('sd19:', storeDepartment);
            //console.log('r21:');

            //setShowShopStoreDepartment(false);

            //setShowShopByAreaTypeProducts(false);

            //setSortByOptionValue(props.sortByOptionValue);

            //setDepartmentProducts([]);

            //imageRefs.current.length = 0;
            //setImageRefsLoaded(false);

            setShowPriceSlider(false);


            getStoreDepartments(props.storeDepartmentId!);
        }

        if(props.shopByAreaType.type === 'departmentCategory'){
            //console.log('useEffect() called:', departmentCategoryId, props.departmentCategoryId);
            //console.log('d4:', departmentCategoryId);

            //console.log('r21:');

            //setShowDepartmentCategoryProducts(false);

            //setShowShopByAreaTypeProducts(false);
            
            //console.log('sdid1:', storeDepartmentId);
            //console.log('dcid:', departmentCategoryId);
            //setSortByOptionValue(props.sortByOptionValue);

            //imageRefs.current.length = 0;
            //setImageRefsLoaded(false);

            setShowPriceSlider(false);

            getDepartmentWithDepartmentCategories(
                                                props.storeDepartmentId!, 
                                                props.departmentCategoryId!, 
                                                props.departmentCategoryName!
                                                    );

        }

        if(props.shopByAreaType.type === 'productCategory'){
            //console.log('p4:', storeDepartmentId, productCategoryId);
            //console.log('r21:');
            //setStoreDepartmentName(props.storeDepartmentName);
            //setDepartmentCategoryName(props.departmentCategoryName);
            //setProductCategoryName(props.productCategoryName);
            //setStoreDepartmentId(props.storeDepartmentId);
            //setProductCategoryId(props.productCategoryId);
            //setKeywordSearch(props.productCategoryName);
            //setSortByOptionValue(props.sortByOptionValue);
            
            //setShowShopByAreaTypeProducts(false);

            //console.log('useEffect:', props.productCategoryName);
            //imageRefs.current.length = 0;
            //setImageRefsLoaded(false);

            if(storeDepartmentCategory !== null){

                const upcs = storeDepartmentCategory!.productCategories.map((pc)=>{
                    if(pc.productCategoryId! === parseInt(props.productCategoryId)) {
                        pc.highlightText = true;
                        return pc;

                    } else {
                        pc.highlightText = false;
                        return pc;
                    }
                });

                const udc = new DepartmentCategory({...storeDepartmentCategory,
                                                productCategories:upcs});

                setStoreDepartmentCategory(udc);

            }

            setShowPriceSlider(false);

            //test version
            getDepartmentWithProductCategories(props.storeDepartmentId!, props.productCategoryId!);  

        }


    },[props.shopByAreaType]);


    /***department methods***/
    /*
    useEffect(()=>{

        if(shopByAreaType.type === 'department'){        

            //setShowShopStoreDepartment(false);
            console.log('sdn1:', storeDepartmentName, storeDepartmentId, props.storeDepartmentName, props.storeDepartmentId);
            console.log('sd19:', storeDepartment);
            console.log('r21:');

            setShowShopStoreDepartment(false);

            //setShowShopByAreaTypeProducts(false);

            setSortByOptionValue(props.sortByOptionValue);

            //setDepartmentProducts([]);
            imageRefs.current.length = 0;
            setImageRefsLoaded(false);


            getStoreDepartments(props.storeDepartmentId!);
        }


    },[storeDepartmentId]);
    */

    /*
    useEffect(()=>{
        if(ssbatps !== showShopByAreaTypeProducts){
            setShowShopByAreaTypeProducts(ssbatps);
            document.documentElement.scrollIntoView();
        }
    },[ssbatps]);
    */
    

    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", resizeHandler);

        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);

        portrait.current[0].removeEventListener("change", resizeHandler);
        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        resizeHandler(new Event('test'));
    }

    function resizeHandler(e:Event):void {
        //console.log('rp resize:', showPriceSlider);
        if(window.innerWidth >= 1024 && !showPriceSliderRef.current){
            //setShowPriceSlider(true);
            setupPriceSliderVisibility();
            showPriceSliderRef.current = true;
        }

        if(window.innerWidth < 1024 && showPriceSliderRef.current){
            setShowPriceSlider(false);
            showPriceSliderRef.current = false;
        }

        if(searchFilterErrorMsgRef.current){
            setShowPriceSlider(false);
        }
        
    }
    /*previous version
    useEffect(()=>{
        if(shopByAreaType.type === 'department'){
            console.log('dps:', searchFilters);
            setStoreDepartment(props.storeDepartment);
            setStoreDepartments(props.storeDepartments);
            setSearchFilters(props.searchFilters);
            setInitialSearchFilters(props.initialSearchFilters);
            setStartSliderAmount(props.startSliderAmount);
            setEndSliderAmount(props.endSliderAmount);
            setPreviousSlider1PositionLeft(props.previousSlider1PositionLeft);
            setPreviousSlider2PositionLeft(props.previousSlider2PositionLeft)
            setPreviousSlider1PostionLeftAmount(props.previousSlider1PostionLeftAmount);
            setPreviousSlider2PostionLeftAmount(props.previousSlider2PostionLeftAmount);
            setShowPriceSlider(props.showPriceSlider);
        }
    }, [props.searchFilters]);
    */


    function getStoreDepartments(storeDepartmentId:string):void {
        //console.log('did:', storeDepartmentId);
        fetch(`https://server.kando-proto-3.com/shop-store-department-only-mariadb/${storeDepartmentId}`,{
            method:'GET',
            headers:{
                'Accept':'application/json'
            },
            signal:controller.signal
        })
        .then((res)=>{

            setSortByOptionValue(props.sortByOptionValue);
            setStoreDepartmentName(props.storeDepartmentName);
            setStoreDepartmentId(props.storeDepartmentId);
            setDepartmentCategoryName(props.departmentCategoryName);
            setDepartmentCategoryId(props.departmentCategoryId);
            setProductCategoryName(props.productCategoryName);
            setProductCategoryId(props.productCategoryId);
            setShopByAreaType(props.shopByAreaType);

            imageRefs.current.length = 0;
            setImageRefsLoaded(false);

            //if(window.innerWidth >= 1024){
                //setShowShopByAreaTypeProducts(false);
            //} else {
                setShowMobileFilter(false);
            //}

            return res.json();
        })
        .then((dept:StoreDepartment|null)=>{
            //console.log('dcs 4-23:', dept.departmentCategories);
            //console.log('dept5:', dept);

            if(dept === null){
                getStoreDepartment(null);
                return;

            } else {

                let sd:StoreDepartment = new StoreDepartment({
                    departmentId:dept.departmentId,
                    departmentName:dept.departmentName,
                    departmentImage:dept.departmentImage,
                    departmentCategories: addDepartmentCategories(dept.departmentCategories),
                    isSpecialDepartment:dept.isSpecialDepartment,
                    highlightText:dept.highlightText,
                    showDepartmentCategoryShortcut:dept.showDepartmentCategoryShortcut,
                    searchFilters:addSearchFilters(dept.searchFilters)
                });

                //console.log('sd5:', sd.departmentName, props.storeDepartmentName);
                if(sd.departmentName !== props.storeDepartmentName){
                    getStoreDepartment(null);
                    return;
                } 


                //if(setShopDepartmentState){
                setStoreDepartment(sd);
                getStoreDepartment(sd);
                
                setStoreDepartmentName(sd.departmentName);
                setStoreDepartmentId(sd.departmentId.toString());
                setStoreDepartments([sd]);
                //setDepartmentProductTotal(getDepartmentProductTotal(sd));
                setShopByAreaTypeProductTotal(getDepartmentProductTotal(sd));
                //setKeywordSearchType('results');
                
                //test for reloading products (lines #175-185)
                let departmentProducts:Product[] = getDepartmentProducts(sd);

                if(sd.departmentCategories.length === 0 && departmentProducts.length === 0){
                    //console.log('test 12:');
                    setKeywordSearchType('noProductsAdded');
                    getKeywordSearchType('noProductsAdded');
                    setHasDepartmentCategories(false);
                    setSearchFilters([]);
                    //setDepartmentProducts([]);
                    setShopByAreaTypeProducts([]);
                    getShopByAreaTypeProducts([], 'noProductsAdded');
                    setShowPriceSlider(false);

                } else if(sd.departmentCategories.length > 0 && departmentProducts.length === 0){
                    //console.log('test 12:');
                    setKeywordSearchType('noProductsAdded');
                    getKeywordSearchType('noProductsAdded');
                    setHasDepartmentCategories(true);
                    //setDepartmentProducts([]);
                    setShopByAreaTypeProducts([]);
                    getShopByAreaTypeProducts([], 'noProductsAdded');
                    setSearchFilters([]);
                    setShowPriceSlider(false);

                } else {
                    setKeywordSearchType('results');
                    getKeywordSearchType('results');
                    //console.log('dp:', departmentProducts);
                    //console.log('ds1 third render', departmentProducts);;
                    //setDepartmentProducts([...departmentProducts]);
                    /*previous version
                    setShopByAreaTypeProducts([...departmentProducts]);
                    getShopByAreaTypeProducts([...departmentProducts], 'results');

                    //getAnyItemList(listChangerBtnPosition, departmentProducts, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, departmentProducts);
        
        
                    //get start and end price amounts from each product
                    getSliderAmount([...departmentProducts]);
                    setSliderPosition(0);
                    setPreviousSlider1TotalDistance(0);
                    setPreviousSlider2TotalDistance(100);
        
        
                    getStoreDepartmentSearchFilters(sd);
                    */



                    const {valid, sfat, reason, products} = checkSearchFilterValidity(sd.searchFilters, departmentProducts, 'Department');
                    //console.log('valid8:', valid, reason, products, sd);

                    if(valid && reason === 'pass') {
                        //console.log('r8:');

                        getAnyItemList(listChangerBtnPosition, departmentProducts, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, departmentProducts);
                        
                        setShopByAreaTypeProducts([...departmentProducts]);
                        getShopByAreaTypeProducts([...departmentProducts], 'results');

                        //get start and end price amounts from each product
                        getSliderAmount([...departmentProducts]);
                        setSliderPosition(0);
                        setPreviousSlider1TotalDistance(0);
                        setPreviousSlider2TotalDistance(100);

                        getStoreDepartmentSearchFilters(sd);

                        setShowSearchFilterSidebar(true);

                    } else {
                        //console.log('r8:');
                        setSearchFilters([]);
                        setShowSearchFilterSidebar(false);
                        setShowPriceSlider(false);

                        getAnyItemList(listChangerBtnPosition, departmentProducts, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, departmentProducts);

                        setShopByAreaTypeProducts([...departmentProducts]);
                        getShopByAreaTypeProducts([...departmentProducts], 'results');

                        getSearchFilterValidity({valid, sfat, reason, products});

                        searchFilterErrorMsgRef.current = true;

                    }

                }
                



                //window.scrollTo(0,0);
                //setShowShopStoreDepartment(true);
                //setShowDepartmentProducts(true);


                //if(window.innerWidth >= 1024){
                  //  setShowShopByAreaTypeProducts(true);
                //} else {
                    setShowMobileFilter(true);
                //}

                //console.log('r21:');

            }
            
            

        })
        .catch(err=>console.error('error shopDepartment:', err));
    }


    /*
    useEffect(()=>{
        console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                console.log('src:', src);
                target.src = `${process.env.PUBLIC_URL}${src}`;
                observer.unobserve(target);
            }
        });
    }
    

    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
    }
    */

    function clearAllExistingImages():void {
        //console.log('irs:', imageRefs.current);
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
    }
    
    
    function getDepartmentProductTotal(storeDepartment:StoreDepartment):number{
        let departmentProducts:Product[] = [];
        storeDepartment.departmentCategories.forEach((departmentCategory)=>{
            departmentCategory.productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    departmentProducts.push(product);
                });
            });

        });
        return departmentProducts.length;

    }

    function getDepartmentProducts(storeDepartment:StoreDepartment):Product[]{
        let departmentProducts:Product[] = [];
        storeDepartment.departmentCategories.forEach((departmentCategory)=>{
            departmentCategory.productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    departmentProducts.push(product);
                });
            });

        });
        return departmentProducts;

    }

    function getStoreDepartmentSearchFilters(storeDepartment:StoreDepartment):void {
        //console.log('sd:', storeDepartment);
        let searchFilters:SearchFilter[] = [];
        storeDepartment.searchFilters.forEach((searchFilter)=>{
            //console.log('sfn:', searchFilter.filterName, searchFilter);
            if(searchFilter.showFilter){
                searchFilters.push(searchFilter);
            }
        });
        //console.log('sfs:', searchFilters);
        const searchFiltersBeforeUpdate = cloneDeep(searchFilters);

        let departmentProducts:Product[] = [];
        storeDepartment.departmentCategories.forEach((departmentCategory)=>{
            departmentCategory.productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    departmentProducts.push(product);
                    product.searchFilterTerms.forEach((searchFilterTerm)=>{
                        //console.log('sft:', searchFilterTerm);
                        searchFilters.forEach((searchFilter, index)=>{
                            //console.log('fn:', searchFilter.filterName);
                            //console.log('sftn:', searchFilterTerm.searchFilterTermName);
                            if(searchFilter.filterName === searchFilterTerm.searchFilterTermName){
                                
                                //console.log('pass:', searchFilter.filterName);
                                searchFilter.searchTerms.forEach((searchTerm, index)=>{
                                    //console.log('fn:', searchFilter.filterName);
                                    if(searchTerm.searchTermName === searchFilterTerm.searchFilterTermValue){
                                        searchTerm.searchTermQty += 1;
                                    }
                                    
                                });
                                
                            }

                        });
                        //console.log('sfs8:', searchFilters);
                        
                    })
                    
                });
            });
        });

                
        if(checkSearchFiltersSelected(searchFiltersBeforeUpdate)){
            //console.log('result2:', true);
            setInitialSearchFilters([...searchFilters]);
            const [updatedSearchFilters, productsSelected] = updateSearchFilters2(departmentProducts, searchFiltersBeforeUpdate, searchFilters);
            //console.log('usfs:', updatedSearchFilters);
    
            if(productsSelected.length === 0){
                //console.log('test');
                setKeywordSearchType('noResults');
                setSearchTermIsSelected(true);
            } else {
                setKeywordSearchType('results');
            }
            
            setSearchFilters([...updatedSearchFilters]);
            //setDepartmentProducts(productsSelected);
            setShopByAreaTypeProducts(productsSelected);
            //console.log('p.length:', productsSelected.length);
            //setDepartmentProductTotal(productsSelected.length);
            setShopByAreaTypeProductTotal(productsSelected.length);
            setShowSpecialProductCategoryShortcut(false);

            //return true;
            
        } else {
            
            const fsfs = removeZeroQtySearchTerms(searchFilters);
            const ufsfs = removeZeroQtySearchFilters(fsfs);

            //console.log('sfs:', ufsfs);
            setInitialSearchFilters([...ufsfs]);
            setSearchFilters([...ufsfs]);
            setShowSpecialProductCategoryShortcut(true);

            //return false;
        }
        
        
    }


    function addDepartmentProducts(departmentProducts:Product[]):Product[] {
        //setDepartmentProductTotal(departmentProducts.length);
        setShopByAreaTypeProductTotal(departmentProducts.length);
        return addProducts(departmentProducts);
    }

    
    function sortByOptionSelected(
                    e:React.ChangeEvent, 
                    shopByAreaTypeProducts:Product[],
                    selectedStoreDepartmentName:string,
                    selectedDepartmentCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedDepartmentCategoryId:number
                                ):void {
        //console.log('sortByOptionSelection:', (e.target as HTMLSelectElement).value);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        //setShowDepartmentProducts(false);
        setShowShopByAreaTypeProducts(false);
        setSortByOptionValue((e.target as HTMLSelectElement).value);
        const selectedOption:string =  (e.target as HTMLSelectElement).value;
        helperSetOptionValue(
                selectedOption, 
                shopByAreaTypeProducts, 
                true,
                //setDepartmentProducts, 
                setShopByAreaTypeProducts,
                //setShowDepartmentProducts
                setShowShopByAreaTypeProducts
                );

        getAnyItemList(listChangerBtnPosition, shopByAreaTypeProducts, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, shopByAreaTypeProducts);


    }

    function loadStoreDepartmentCategory(e:React.MouseEvent, storeDepartmentName:string, departmentCategory:DepartmentCategory):void {
        e.stopPropagation();
    }

    /*
    function loadNavLink(e:React.MouseEvent, pathname:string):void {
        e.preventDefault();
        console.log('posy14:', window.scrollY);

        //window.sessionStorage.setItem('admin-scroll-position', `${window.scrollY}`);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        navigate(pathname);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        
    }
    */


    //store department sidebar 1ine#593-689
    /***test area for special department */
    function setupSpecialStoreDepartmentCategories(storeDepartmentName:string, departmentCategories:DepartmentCategory[]):JSX.Element | null{
        if(departmentCategories.length > 0){
            return  <ul className="shop-special-department-category-sidebar-list">
                        {departmentCategories.map((departmentCategory, index)=>{
                            //console.log('dcn 4-23:', departmentCategory);
                            return <li key={index} className="shop-special-department-category-sidebar-list-item"
                                        /*onClick={(e:React.MouseEvent)=>loadStoreDepartmentCategory(e, storeDepartmentName, departmentCategory)}*/>
                                        <Link to={`/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${encodeURIComponent(formatLinkString(departmentCategory.departmentCategoryName))}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`}
                                            className="shop-special-department-category-sidebar-list-item-link"
                                            onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartmentName))}/${encodeURIComponent(formatLinkString(departmentCategory.departmentCategoryName))}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`)}>
                                            {departmentCategory.departmentCategoryName}
                                        </Link>
                                    </li>
                        })}                            
                    </ul>

        } else {
            return null;
        }


    }


    //for department
    function setupSpecialStoreDepartmentCategorySidebar2(storeDepartment:StoreDepartment, selectedStoreDepartmentName:string):JSX.Element | null{
        //console.log('isTrue:', selectedStoreDepartmentName === storeDepartment.departmentName);
        return (selectedStoreDepartmentName === storeDepartment.departmentName) 
                ? setupSpecialStoreDepartmentCategories(storeDepartment.departmentName, storeDepartment.departmentCategories) : null;
    }

    function setupSpecialStoreDepartmentSidebar(selectedStoreDepartmentName:string, storeDepartments:StoreDepartment[]):JSX.Element{
        //console.log('ssdn:', selectedStoreDepartmentName, storeDepartments, searchTermIsSelected);
        return <div className="shop-store-department-sidebar-container">
                    <div className="shop-store-department-sidebar-link-container">
                        <Link to="/shop" className="shop-store-department-overview-breadcrum-link shop-link-shape"
                            onClick={(e:React.MouseEvent)=>loadNavLink(e, '/shop/overview')}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-chevron-shape">
                                <path fillRule={"evenodd"} clipRule={"evenodd"} d={"M4.24076 14.3492C3.88134 14.7685 3.9299 15.3998 4.34923 15.7592C4.76855 16.1187 5.39985 16.0701 5.75927 15.6508L11.7593 8.65077C12.0803 8.27628 12.0803 7.72368 11.7593 7.34919L5.75927 0.349191C5.39985 -0.0701351 4.76855 -0.118696 4.34923 0.240726C3.9299 0.600147 3.88134 1.23145 4.24076 1.65077L9.68294 7.99998L4.24076 14.3492Z"}
                                fill={'currentColor'}></path>
                            </svg>
                            Shop
                        </Link>
                    </div>
                    <ul className="shop-store-department-sidebar-list-container">
                        <li className="shop-store-department-sidebar-list-refinement">
                            <ul className="shop-special-department-sidebar-list">
                                {storeDepartments.map((storeDepartment, index)=>{
                                    return storeDepartment.departmentName === selectedStoreDepartmentName ?
                                                        <li key={index} className="shop-special-department-sidebar-list-item">  
                                                            <HighlightDepartmentText storeDepartment={storeDepartment}/>
                                                            <ul className="shop-special-department-category-sidebar-list-container">
                                                            {setupSpecialStoreDepartmentCategorySidebar2(storeDepartment, selectedStoreDepartmentName)}
                                                            </ul>
                                                        </li>
                                                        : null
                                    

                                })}
                            </ul>
                        </li>
                        {
                            showSearchFilterSidebar
                            ?
                            <SearchFilterSidebar 
                                searchFilters={searchFilters} initialSearchFilters={initialSearchFilters} 
                                searchTermIsSelected={searchTermIsSelected} storeDepartmentId={storeDepartmentId!}
                                otherStoreAreaId={storeDepartmentId!} storeAreaName={storeDepartmentName!}
                                storeAreaType={'Department'}
                                setShowDepartmentProducts={setShowShopByAreaTypeProducts/*setShowDepartmentProducts*/}
                                clearAllExistingImages={clearAllExistingImages}
                                setSearchFilters={setSearchFilters} 
                                /*setDepartmentCategoryProducts={setDepartmentProducts}*/
                                setDepartmentCategoryProducts={updateDepartmentProducts}
                                setDepartmentCategoryProductTotal={setShopByAreaTypeProductTotal /*setDepartmentProductTotal*/} 
                                setShowSpecialProductCategoryShortcut={setShowSpecialProductCategoryShortcut}
                                setSearchTermIsSelected={setSearchTermIsSelected} setKeywordSearchType={setKeywordSearchType} 
                                addSearchFilters={addSearchFilters}
                                keywordSearch={undefined}
                                startAmount={previousSlider1PostionLeftAmount}
                                endAmount={previousSlider2PostionLeftAmount}
                                sssbatps={sssbatps}
                            />
                            :
                            null
                        }
                        {
                            showPriceSlider 
                            ? 
                            <li className="shop-store-department-sidebar-list-refinement">
                                <PriceSlider3
                                        keywordSearch={keywordSearch!}
                                        startSliderAmount={startSliderAmount} 
                                        endSliderAmount={endSliderAmount}
                                        previousSlider1PositionLeft={previousSlider1PositionLeft}
                                        previousSlider2PositionLeft={previousSlider2PositionLeft}
                                        previousSlider1PostionLeftAmount={previousSlider1PostionLeftAmount}
                                        previousSlider2PostionLeftAmount={previousSlider2PostionLeftAmount}
                                        priceSliderSearchResults={priceSliderSearchResults}
                                        sliderPosition={sliderPosition}
                                        previousSlider1TotalDistance={previousSlider1TotalDistance}
                                        previousSlider2TotalDistance={previousSlider2TotalDistance}
                                        slider1ZIndex={slider1ZIndex}
                                        slider2ZIndex={slider2ZIndex}
                                        sortByOptionValue={sortByOptionValue}
                                                /> 
                            </li> 
                            : 
                            null
                        }
                    </ul>
                </div>
    }

    /*
    function setupSpecialDepartmentCategorySearchShortcut(storeDepartment:StoreDepartment):JSX.Element | null {
        if(storeDepartment.showDepartmentCategoryShortcut){
            return <ul className="shop-special-store-department-product-category-search-shortcut">
                        {
                            storeDepartment.departmentCategories.map((departmentCategory, index)=>{
                                return <li key={index}>
                                            <Link to={`/shop/${departmentCategory.departmentName}/${departmentCategory.departmentCategoryName}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`}
                                                className="shop-special-store-department-product-category-search-shortcut-item-link"
                                                onClick={(e:React.MouseEvent)=>loadStoreDepartmentCategory(e, storeDepartment.departmentName, departmentCategory)}>
                                                <div className="shop-special-store-department-product-category-search-shortcut-image-container">
                                                    <img src={departmentCategory.departmentCategoryImage} height="85" width="85"/>
                                                </div>
                                                <div className="shop-special-store-department-product-category-search-shortcut-title">
                                                    {departmentCategory.departmentCategoryName}
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>

        } else {
            return null;
        }

    }
    */

    /*
    function setupDepartmentProducts(itemsList:object[], optionalCategory?:object):JSX.Element{
            console.log('dp1:', itemsList);
            
        if(((optionalCategory as StoreDepartment) !== null && itemsList.length === 0)){
            let itemsList:Product[] = getDepartmentProducts((optionalCategory as StoreDepartment));
            
            console.log('dp2:', itemsList);
            //setDepartmentProducts([...itemsList]);
            setShopByAreaTypeProducts([...itemsList]);


            //get start and end price amounts from each product
            getSliderAmount([...itemsList]);
            setSliderPosition(0);
            setPreviousSlider1TotalDistance(0);
            setPreviousSlider2TotalDistance(100);

            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper">
                                                <ShopProductDetail product={product} getImageRef={getImageRef}/>
                                            </div> 
                        })}
                    </div>

        } else {
            console.log('dp3:', itemsList);

            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper">
                                                <ShopProductDetail product={product} getImageRef={getImageRef}/>
                                            </div> 
                        })}
                    </div>
        }

    }
    */


    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as Product[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);

        //window.scrollTo(0,0);      

    }

    function displayListSorter(itemsPerList:number, ps:Product[]):void {
        if(ps.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }


    function updateDepartmentProducts(dps:Product[], kst?:string):void {
        //console.log('dps:', dps, window.scrollY);
        //setDepartmentProducts([...dps]);
        
        //setShopByAreaTypeProducts([...dps]);

        getShopByAreaTypeProducts([...dps], kst!);

        //getAnyItemList(listChangerBtnPosition, dps, itemsPerList, showItemsPerList);
        //displayListSorter(itemsPerList, dps);
    }



    /***department category methods***/
    /*
    useEffect(()=>{
        
        if(shopByAreaType.type === 'departmentCategory'){
            console.log('useEffect() called:', departmentCategoryId, props.departmentCategoryId);
            console.log('d4:', departmentCategoryId);

            console.log('r21:');

            //setShowDepartmentCategoryProducts(false);

            //setShowShopByAreaTypeProducts(false);
            
            console.log('sdid1:', storeDepartmentId);
            console.log('dcid:', departmentCategoryId);
            setSortByOptionValue(props.sortByOptionValue);

            imageRefs.current.length = 0;
            setImageRefsLoaded(false);

            getDepartmentWithDepartmentCategories(
                                                props.storeDepartmentId!, 
                                                props.departmentCategoryId!, 
                                                props.departmentCategoryName!
                                                    );

        }
        
    },[departmentCategoryId]);
    */
    
    
    
    /*
    useEffect(()=>{
        console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();

        }


    },[imageRefsLoaded]);
    */


    function getDepartmentWithDepartmentCategories(storeDepartmentId:string, departmentCategoryId:string, departmentCategoryName:string):void {
        //console.log('dn7:', storeDepartmentId, 'dcid:', departmentCategoryId, 'dcn:', departmentCategoryName);

        fetch(`https://server.kando-proto-3.com/shop-store-department-with-department-category-mariadb/${storeDepartmentId}/${departmentCategoryId}`, {
            //original version for getting store dept from db
            //fetch(`http://localhost:9500/shop/${props.shopLoadDetails!.storeDepartmentName === 'none'? storeDepartmentName : props.shopLoadDetails!.storeDepartmentName}`,{
                method:'GET',
                headers:{
                    'Accept':'application/json'
                },
                signal:controller.signal
            })
            .then((res)=>{
                //console.log('test res:', res);
                //setShowDepartmentCategoryProducts(false);
                //setDepartmentCategoryProducts([]);

                setSortByOptionValue(props.sortByOptionValue);
                setStoreDepartmentName(props.storeDepartmentName);
                setStoreDepartmentId(props.storeDepartmentId);
                setDepartmentCategoryName(props.departmentCategoryName);
                setDepartmentCategoryId(props.departmentCategoryId);
                setProductCategoryName(props.productCategoryName);
                setProductCategoryId(props.productCategoryId);
                setShopByAreaType(props.shopByAreaType);

                imageRefs.current.length = 0;
                setImageRefsLoaded(false);


                //if(window.innerWidth >= 1024){
                    //setShowShopByAreaTypeProducts(false);
                //} else {
                    setShowMobileFilter(false);
                //}

                return res.json();
            })
            .then((dept:StoreDepartment|null)=>{
                //console.log('sd5:', dept);

                if(dept === null){
                    getStoreDepartment(null);
                    return;

                } else {

                    let sd:StoreDepartment = new StoreDepartment({
                                                departmentId:dept.departmentId,
                                                departmentName:dept.departmentName,
                                                departmentImage:dept.departmentImage,
                                                departmentCategories: addDepartmentCategories(dept.departmentCategories),
                                                isSpecialDepartment:dept.isSpecialDepartment,
                                                showDepartmentCategoryShortcut:dept.showDepartmentCategoryShortcut,
                                                searchFilters:addSearchFilters(dept.searchFilters)
                                            });

                    if(sd.departmentName !== props.storeDepartmentName){
                        getStoreDepartment(null);
                        return;
                    } 

                    //if(setDepartmentCategoryState){
                    setStoreDepartment(sd);
                    getStoreDepartment(sd);
                    setStoreDepartmentName(sd.departmentName);
                    setDepartmentCategoryName(departmentCategoryName);
                    //console.log('sd:', sd.departmentCategories[1].productCategories.length);
                    //console.log('sd1:', sd);

                        const dc = getStoreDepartmentCategoryForDC(departmentCategoryName!, sd.departmentCategories);

                        if(typeof dc === 'undefined'){
                            //console.log('dc5:', dc);
                            getStoreDepartmentCategory(null);
                            return;    
                        }

                        getStoreDepartmentCategory(dc);
                        //test code for setting products (lines#195-207)
                        let departmentCategoryProducts:Product[] = [];
                        dc.productCategories.forEach((productCategory)=>{
                            productCategory.products.forEach((product)=>{
                                departmentCategoryProducts.push(product);
                            });
                        });
                        //console.log('setupDepartmentCategoryProducts:', departmentCategoryProducts);
                        if(dc.productCategories.length === 0 && departmentCategoryProducts.length === 0){
                            setKeywordSearchType('noProductsAdded');
                            getKeywordSearchType('noProductsAdded');
                            setHasProductCategories(false);
                            //setDepartmentCategoryProducts([]);
                            setShopByAreaTypeProducts([]);
                            getShopByAreaTypeProducts([], 'noProductsAdded');
                            setSearchFilters([]);
                            setShowPriceSlider(false);

                        } else if(dc.productCategories.length > 0 && departmentCategoryProducts.length === 0){
                            setKeywordSearchType('noProductsAdded');
                            getKeywordSearchType('noProductsAdded');
                            setHasProductCategories(true);
                            //setDepartmentCategoryProducts([]);
                            setShopByAreaTypeProducts([]);
                            getShopByAreaTypeProducts([], 'noProductsAdded');
                            setSearchFilters([]);
                            setShowPriceSlider(false);

                        } else {

                            //setDepartmentCategoryProducts([...departmentCategoryProducts]);
                            /*previous version
                            setShopByAreaTypeProducts([...departmentCategoryProducts]);
                            getShopByAreaTypeProducts([...departmentCategoryProducts], 'results');
                            */

                            setKeywordSearchType('results');
                            getKeywordSearchType('results');

                            /*previous version
                            getAnyItemList(listChangerBtnPosition, departmentCategoryProducts, itemsPerList, showItemsPerList);
                            displayListSorter(itemsPerList, departmentCategoryProducts);

                            //get start and end price amounts from each product
                            getSliderAmount([...departmentCategoryProducts]);
                            setSliderPosition(0);
                            setPreviousSlider1TotalDistance(0);
                            setPreviousSlider2TotalDistance(100);
                            
                            //setDepartmentCategoryProductTotal(getDepartmentCategoryProductTotal(sd, departmentCategoryName!));
                            setShopByAreaTypeProductTotal(getDepartmentCategoryProductTotal(sd, departmentCategoryName!));
                            */

                            const {valid, sfat, reason, products} = checkSearchFilterValidity(dc.searchFilters, departmentCategoryProducts, 'Department Category');
                            //console.log('valid8:', valid, products);

                            if(valid && reason === 'pass') {
                                
                                setShopByAreaTypeProducts([...departmentCategoryProducts]);
                                getShopByAreaTypeProducts([...departmentCategoryProducts], 'results');

                                getAnyItemList(listChangerBtnPosition, departmentCategoryProducts, itemsPerList, showItemsPerList);
                                displayListSorter(itemsPerList, departmentCategoryProducts);
        
                                //get start and end price amounts from each product
                                getSliderAmount([...departmentCategoryProducts]);
                                setSliderPosition(0);
                                setPreviousSlider1TotalDistance(0);
                                setPreviousSlider2TotalDistance(100);
        
                                //getStoreDepartmentSearchFilters(sd);
                                setShopByAreaTypeProductTotal(getDepartmentCategoryProductTotal(sd, departmentCategoryName!));

                                setShowSearchFilterSidebar(true);
        
                            } else {
                                setSearchFilters([]);
                                setShowSearchFilterSidebar(false);
                                setShowPriceSlider(false);

                                getAnyItemList(listChangerBtnPosition, departmentCategoryProducts, itemsPerList, showItemsPerList);
                                displayListSorter(itemsPerList, departmentCategoryProducts);
        
                                setShopByAreaTypeProducts([...departmentCategoryProducts]);
                                getShopByAreaTypeProducts([...departmentCategoryProducts], 'results');
        
                                getSearchFilterValidity({valid, sfat, reason, products});

                                searchFilterErrorMsgRef.current = true;
        
                            }

                            
                        }

                    //setShowDepartmentCategoryProducts(true);

                    //if(window.innerWidth >= 1024){
                      //  setShowShopByAreaTypeProducts(true);
                    //} else {
                        setShowMobileFilter(true);
                    //}
                }
        
            })
            .catch(err=>console.error('error shopDepartmentCategory:', err));


    }



    //change these two function to fit for department products and install above and below.
    function addDepartmentCategoryProducts(departmentCategoryProducts:Product[]):Product[] {
        //setDepartmentCategoryProductTotal(departmentCategoryProducts.length);
        setShopByAreaTypeProductTotal(departmentCategoryProducts.length);
        return addProducts(departmentCategoryProducts);
    }

    function addProducts(products:Product[]):Product[]{
        //setDepartmentCategoryProductTotal(products.length);
        return products.map((product)=>{
            return new Product({...product});
        });
    }

    function getStoreDepartmentCategoryForDC(departmentCategoryName:string, departmentCategories:DepartmentCategory[]):/*void*/ DepartmentCategory {
        //console.log('dcn:', departmentCategoryName);
        const foundDepartmentCategory = departmentCategories.find((departmentCategory)=>{
            return departmentCategory.departmentCategoryName === departmentCategoryName;
        }) as DepartmentCategory;

        if(typeof foundDepartmentCategory === 'undefined'){
            return foundDepartmentCategory;
        }

        //console.log('dc:', foundDepartmentCategory);
        //console.log('dc:', foundDepartmentCategory.productCategories.length);

        setStoreDepartmentCategory(foundDepartmentCategory);

        //load initial unsaved search filters with this function
        getDepartmentCategorySearchFilters(foundDepartmentCategory);

        return foundDepartmentCategory;
    
    }

    function getDepartmentCategorySearchFilters(departmentCategory:DepartmentCategory):void {
        //console.log('dc:', departmentCategory);
        let searchFilters:SearchFilter[] = [];
        departmentCategory.searchFilters.forEach((searchFilter)=>{
            //console.log('sfn:', searchFilter.filterName);
            if(searchFilter.showFilter){
                searchFilters.push(searchFilter);
            }
        });
        //console.log('sfs:', searchFilters);
        const searchFiltersBeforeUpdate = cloneDeep(searchFilters);

        let departmentCategoryProducts:Product[] = [];
        departmentCategory.productCategories.forEach((productCategory)=>{
            productCategory.products.forEach((product)=>{
                departmentCategoryProducts.push(product);
                //console.log('product:', product);
                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                    //console.log('sft:', searchFilterTerm);
                    searchFilters.forEach((searchFilter, index)=>{
                        //console.log('fn:', searchFilter.filterName);
                        //console.log('sftn:', searchFilterTerm.searchFilterTermName);
                        if(searchFilter.filterName === searchFilterTerm.searchFilterTermName){
                            //console.log('pass:', searchFilter.filterName);
                            searchFilter.searchTerms.forEach((searchTerm, index)=>{
                                //console.log('fn:', searchFilter.filterName);
                                if(searchTerm.searchTermName === searchFilterTerm.searchFilterTermValue){
                                    searchTerm.searchTermQty += 1;
                                }
                                
                            });
                            
                        }

                    });
                    //console.log('sfs:', searchFilters);
                    
                })
                
            });
        });

        //console.log('sfbu:', searchFiltersBeforeUpdate);

        
        if(checkSearchFiltersSelected(searchFiltersBeforeUpdate)){
            setInitialSearchFilters([...searchFilters]);
            const [updatedSearchFilters, productsSelected] = updateSearchFilters2(departmentCategoryProducts, searchFiltersBeforeUpdate, searchFilters);
            //console.log('usfs:', updatedSearchFilters);
    
            if(productsSelected.length === 0){
                //console.log('test');
                setKeywordSearchType('noResults');
                setSearchTermIsSelected(true);
            } else {
                setKeywordSearchType('results');
            }
            
            setSearchFilters([...updatedSearchFilters]);
            //setDepartmentCategoryProducts(productsSelected);
            setShopByAreaTypeProducts(productsSelected);
            //console.log('p.length:', productsSelected.length);
            //setDepartmentCategoryProductTotal(productsSelected.length);
            setShopByAreaTypeProductTotal(productsSelected.length);
            setShowSpecialProductCategoryShortcut(false);

            //return true;
            
        } else {

            const fsfs = removeZeroQtySearchTerms(searchFilters);

            const ufsfs = removeZeroQtySearchFilters(fsfs);

            //console.log('sfs:', ufsfs);
            setInitialSearchFilters([...ufsfs]);
            setSearchFilters([...ufsfs]);
            setShowSpecialProductCategoryShortcut(true);
    
        }
        
        
    }

    function removeZeroQtySearchTerms(sfs:SearchFilter[]):SearchFilter[] {
        sfs.forEach((sf)=>{
            const sts = sf.searchTerms.filter((st)=>{
                            if(st.searchTermQty === 0){
                                //console.log('stn:', st.searchTermName, 'stQty:', st.searchTermQty);
                            }
                            return st.searchTermQty !== 0;
                        });

                sf.searchTerms = sts;
            });

        //console.log('usfs 5-3:', sfs);
        return sfs;
    }

    function removeZeroQtySearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        const fsfs = sfs.filter((sf)=>{
                        return sf.searchTerms.length > 0;
                    });
        //console.log('fsfs:', fsfs);
        return fsfs;
    }


   function hightlightProductCategoryTextForDC(
                    e:React.MouseEvent, 
                    productCategoryName:string, 
                    categoryType:string,
                    storeDepartmentName:string,
                    departmentCategoryName:string,
                    storeDepartmentId:number,
                    productCategoryId:number
                    ):void {
        e.preventDefault();
        e.stopPropagation();
        //console.log('productCategoryName:', productCategoryName);
        helperHighlightCategory(e, productCategoryName, categoryType)
        .then((response)=>{
            //setShowDepartmentCategoryProducts(false);
            setShowShopByAreaTypeProducts(false);
            return response.json();
        })
        .then(({action})=>{
            if(action === 'added'){
                //console.log('added');
                navigate(`/shop/${storeDepartmentName}/${departmentCategoryName}/${productCategoryName}/${storeDepartmentId}/${productCategoryId}`);

            }
        })
        .catch(err=>console.log('error:', err));
    }


    //for department category
    /****test for frozen food layout***/
    function setupSpecialStoreDepartmentCategorySidebar(storeDepartment:StoreDepartment, departmentCategoryName:string):JSX.Element{
        //console.log('sdcn:', departmentCategoryName);
        //console.log('sd:', storeDepartment);
        return <div className="shop-store-department-sidebar-container">
                    <div className="shop-store-department-sidebar-link-container">
                        <Link to={`/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${storeDepartment.departmentId}`} className="shop-link-shape"
                            onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${storeDepartment.departmentId}`)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-chevron-shape">
                                <path fillRule={"evenodd"} clipRule={"evenodd"} d={"M4.24076 14.3492C3.88134 14.7685 3.9299 15.3998 4.34923 15.7592C4.76855 16.1187 5.39985 16.0701 5.75927 15.6508L11.7593 8.65077C12.0803 8.27628 12.0803 7.72368 11.7593 7.34919L5.75927 0.349191C5.39985 -0.0701351 4.76855 -0.118696 4.34923 0.240726C3.9299 0.600147 3.88134 1.23145 4.24076 1.65077L9.68294 7.99998L4.24076 14.3492Z"}
                                fill={'currentColor'}></path>
                            </svg>
                            {storeDepartment.departmentName}
                        </Link>
                    </div>
                    <ul className="shop-store-department-sidebar-list-container">
                        <li className="shop-store-department-sidebar-list-refinement">
                            <ul className="shop-special-department-category-sidebar-list ssdcsl-adjust">
                                {storeDepartment.departmentCategories.map((departmentCategory, index)=>{
                                    return departmentCategory.departmentCategoryName === departmentCategoryName ?                                  
                                            <li key={index} className="shop-store-department-sidebar-list-item">
                                                <HighlightDepartmentCategoryText departmentCategory={departmentCategory}/>
                                                    {
                                                        setupSpecialProductCategorySidebar(departmentCategory, departmentCategoryName)
                                                    }
                                            </li>
                                            : null
                                })}                            
                            </ul>
                        </li>
                        {/*setupSearchFilters(searchFilters)*/}
                        {
                            showSearchFilterSidebar
                            ?
                            <li>
                                <SearchFilterSidebar 
                                        searchFilters={searchFilters} initialSearchFilters={initialSearchFilters} 
                                        searchTermIsSelected={searchTermIsSelected} storeDepartmentId={storeDepartmentId!}
                                        otherStoreAreaId={departmentCategoryId!} storeAreaName={departmentCategoryName}
                                        storeAreaType={'Department Category'}
                                        setShowDepartmentProducts={setShowShopByAreaTypeProducts /*setShowDepartmentCategoryProducts*/}
                                        clearAllExistingImages={clearAllExistingImages}
                                        setSearchFilters={setSearchFilters} 
                                        /*setDepartmentCategoryProducts={setDepartmentCategoryProducts}*/
                                        setDepartmentCategoryProducts={updateDepartmentCategoryProducts}
                                        setDepartmentCategoryProductTotal={setShopByAreaTypeProductTotal /*setDepartmentCategoryProductTotal*/} 
                                        setShowSpecialProductCategoryShortcut={setShowSpecialProductCategoryShortcut}
                                        setSearchTermIsSelected={setSearchTermIsSelected} setKeywordSearchType={setKeywordSearchType} 
                                        addSearchFilters={addSearchFilters}
                                        keywordSearch={undefined}
                                        startAmount={previousSlider1PostionLeftAmount}
                                        endAmount={previousSlider2PostionLeftAmount}
                                        sssbatps={sssbatps}
                                />
                            </li>
                            :
                            null
                        }
                        {
                            showPriceSlider 
                            ? 
                            <li className="shop-store-department-sidebar-list-refinement">
                                <PriceSlider3
                                        keywordSearch={keywordSearch!}
                                        startSliderAmount={startSliderAmount} 
                                        endSliderAmount={endSliderAmount}
                                        previousSlider1PositionLeft={previousSlider1PositionLeft}
                                        previousSlider2PositionLeft={previousSlider2PositionLeft}
                                        previousSlider1PostionLeftAmount={previousSlider1PostionLeftAmount}
                                        previousSlider2PostionLeftAmount={previousSlider2PostionLeftAmount}
                                        priceSliderSearchResults={priceSliderSearchResults}
                                        sliderPosition={sliderPosition}
                                        previousSlider1TotalDistance={previousSlider1TotalDistance}
                                        previousSlider2TotalDistance={previousSlider2TotalDistance}
                                        slider1ZIndex={slider1ZIndex}
                                        slider2ZIndex={slider2ZIndex}
                                        sortByOptionValue={sortByOptionValue}
                                                /> 
                            </li> 
                            :
                            null
                        }
                    </ul>
                </div>
    }

    function setupSpecialProductCategorySidebar(departmentCategory:DepartmentCategory, selectedDepartmentCategoryName:string):JSX.Element | null {
        //console.log('sdcsb:', departmentCategory.showProductCategorySidebar, 'dc:', departmentCategory.departmentCategoryName);
        if(departmentCategory.departmentCategoryName === selectedDepartmentCategoryName && departmentCategory.showProductCategorySidebar){
            return <ul className="shop-special-store-department-category-product-category-sidebar-list sssdcpcsl-adjust">
                        {
                            departmentCategory.productCategories.map((productCategory, index)=>{
                                //console.log('test result:', productCategory.departmentName);
                                return <li key={index} className="shop-special-store-department-category-product-category-sidebar-list-item dc-highlight-adj">
                                            <Link to={`/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${departmentCategory.departmentId}/${productCategory.productCategoryId}`}
                                                className="shop-special-store-department-category-product-category-sidebar-list-item-link"
                                                onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${departmentCategory.departmentId}/${productCategory.productCategoryId}`)}>{productCategory.productCategoryName}</Link>
                                        </li>
                            })
                        }
                    </ul>
        } else {
            return null;
        }
    }

    function setupSpecialProductCategorySearchShortcut(storeDepartmentCategory:DepartmentCategory):JSX.Element | null {
        //console.log('sdc:', storeDepartmentCategory.productCategories.length);
        if(storeDepartmentCategory.showProductCategoryShortcut){
            return <ul className="shop-special-store-department-product-category-search-shortcut">
                        {
                            storeDepartmentCategory.productCategories.map((productCategory, index)=>{
                                return <li key={index}>
                                            <Link to={`/shop/${productCategory.departmentName}/${productCategory.departmentCategoryName}/${productCategory.productCategoryName}/${storeDepartmentCategory.departmentId}/${productCategory.productCategoryId}`}
                                                className="shop-special-store-department-product-category-search-shortcut-item-link"
                                                onClick={(e:React.MouseEvent)=>handleProductCategorySearchForDC(e, productCategory)}>
                                                <div className="shop-special-store-department-product-category-search-shortcut-image-container">
                                                    <img src={`https://server.kando-proto-3.com/${productCategory.productCategoryImage}`} height="85" width="85"/>
                                                </div>
                                                <div className="shop-special-store-department-product-category-search-shortcut-title">
                                                    {productCategory.productCategoryName}
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>

        } else {
            return null;
        }

    }

    function handleProductCategorySearchForDC(e:React.MouseEvent, productCategory:ProductCategory /*keywordSearch:string, productCategoryName:string*/):void {
        //console.log('productCategory result:', productCategory);

        setKeywordSearchType('results');
        setProductCategoryName(productCategory.productCategoryName);
        setSortByOptionValue(props.sortByOptionValue);
        //setShowDepartmentCategoryProducts(false);
        setShowShopByAreaTypeProducts(false);
        //window.scrollTo(0,0);
            
        if(productCategory.showProductCategoryNameSearch){
            //console.log('spcns');
            e.preventDefault();
            
            //finish this area customizing search filters that are selected    
            Promise.all([getShopDepartmentCategorySearchFiltersSelectedPromise(departmentCategoryName!, productCategory.productCategoryName),
                        getShopProductCategoryNameSearchPromise(productCategory.productCategoryName)
            ])
            .then(([departmentCategory, departmentCategoryProducts])=>{
                //console.log('isfs:', initialSearchFilters);
                //console.log('departmentCategory:', departmentCategory);
                getDepartmentCategorySearchFilters(departmentCategory);

                //console.log('dcp:', departmentCategoryProducts);
                //setDepartmentCategoryProductTotal(departmentCategoryProducts.length);
                setShopByAreaTypeProductTotal(departmentCategoryProducts.length);
                //setDepartmentCategoryProducts(departmentCategoryProducts);
                setShopByAreaTypeProducts(departmentCategoryProducts);

                //get start and end price amounts from each product
                getSliderAmount(departmentCategoryProducts);
                setSliderPosition(0);
                setPreviousSlider1TotalDistance(0);
                setPreviousSlider2TotalDistance(100);

                //setShowDepartmentCategoryProducts(true);
                setShowShopByAreaTypeProducts(true);
            })
            .catch((err)=>console.error('Promise.all() error:', err));
            

        } else if(productCategory.showProductCategoryKeywordSearch) {
            //console.log('spcks');
            e.preventDefault();
            fetch(`https://server.kando-proto-3.com/shop-product-category-keyword-search/${productCategory.productCategoryName}`, {
                headers:{
                    'Accept':'application/json'
                }
            })
            .then((response)=>response.json())
            .then((departmentCategoryProducts)=>{
                //console.log('dcp:', departmentCategoryProducts);
                //setDepartmentCategoryProductTotal(departmentCategoryProducts.length);
                setShopByAreaTypeProductTotal(departmentCategoryProducts.length);
                //setDepartmentCategoryProducts(departmentCategoryProducts);
                setShopByAreaTypeProducts(departmentCategoryProducts);

            })
            .catch(err=>console.log('error:', err));
        
        } else {
            //console.log('final else');
            //console.log('test passed');
            return;
        }

    }

    function getShopDepartmentCategorySearchFiltersSelectedPromise(
                                                selectedDepartmentCategoryName:string,
                                                selectedProductCategoryName:string
                                                ):Promise<DepartmentCategory>{
        return new Promise((resolve, reject)=>{
                    fetch(`https://server.kando-proto-3.com/shop-department-category-search-filters-selected/${selectedDepartmentCategoryName}/${selectedProductCategoryName}`, {
                        method:'GET',
                        headers:{
                            'Accept':'application/json'
                        }
                    })
                    .then((resp)=>resp.json())
                    .then((departmentCategory)=>{
                        //console.log('dc:', departmentCategory);
                        resolve(departmentCategory);
                    })
                    .catch(err=>{
                        console.error('getShopDepartmentCategorySearchFiltersSelectedPromise error:', err);
                        reject(err);
                    });
                });
    }

    function getShopProductCategoryNameSearchPromise(selectedProductCategoryName:string):Promise<Product[]>{
        return new Promise((resolve, reject)=>{
                    fetch(`https://server.kando-proto-3.com/shop-product-category-name-search/${selectedProductCategoryName}`, {
                        method:'GET',
                        headers:{
                            'Accept':'application/json'
                        }
                    })
                    .then((resp)=>resp.json())
                    .then((departmentCategoryProducts)=>resolve(departmentCategoryProducts))
                    .catch((err)=>{
                        console.error('getShopProductCategoryNameSearchPromise error:', err);
                        reject(err);
                    });
                });
    }

    function getDepartmentCategoryProductTotal(storeDepartment:StoreDepartment, selectedDepartmentCategoryName:string):number{
        const foundDepartmentCategory = storeDepartment.departmentCategories.find((departmentCategory)=>{
            return departmentCategory.departmentCategoryName === selectedDepartmentCategoryName;
        });

        if(typeof foundDepartmentCategory !== 'undefined'){
            //console.log('r:', true);
            let departmentCategoryProducts:Product[] = [];
            foundDepartmentCategory.productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    departmentCategoryProducts.push(product);
                });
            });
            return Number(departmentCategoryProducts.length);
        } else {
            return 0;
        }

    }

    /*
    function setupDepartmentCategoryProducts(itemsList:object[], optionalCategory?:object):JSX.Element{
            console.log('dcp:', itemsList);
            console.log('dcpt:', shopByAreaTypeProductTotal);
            console.log('kst:', keywordSearchType);
            console.log('stis:', searchTermIsSelected);
            console.log('storeDepartmentCategory.departmentCategoryName:', (optionalCategory as DepartmentCategory).departmentCategoryName);

            
        if(((optionalCategory as DepartmentCategory) !== null && itemsList.length === 0)){
            console.log('sdc:', optionalCategory!);
            
            let itemsList:Product[] = [];
            (optionalCategory as DepartmentCategory).productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    itemsList.push(product);
                });
            });
            console.log('setupDepartmentCategoryProducts:', itemsList);
            //setDepartmentCategoryProducts([...itemsList]);
            setShopByAreaTypeProducts([...itemsList]);
            //get start and end price amounts from each product
            getSliderAmount([...itemsList]);
            setSliderPosition(0);
            setPreviousSlider1TotalDistance(0);
            setPreviousSlider2TotalDistance(100);
            //setShowDepartmentCategoryProducts(true);
            //setProductResultCount(departmentCategoryProducts.length);
            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper">
                                                <ShopProductDetail product={product} getImageRef={getImageRef}/>
                                            </div> 
                        })}
                    </div>

        } else {
           
            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper">
                                                <ShopProductDetail product={product} getImageRef={getImageRef}/>
                                            </div> 
                        })}
                    </div>
        }

    }
    */


    function loadStoreDepartment(e:React.MouseEvent, storeDepartment:StoreDepartment):void {
        e.preventDefault();
        
        helperHighlightDepartment(e, storeDepartment.departmentName)
        .then((response)=>{
            //setShowDepartmentCategoryProducts(false);
            setShowShopByAreaTypeProducts(false);
            return response.json();
        })
        .then(({action})=>{
            if(action === 'added'){
                //console.log('added');
                navigate(`/shop/${storeDepartment.departmentName}/${storeDepartment.departmentId}`);
            
        }})
        .catch(err=>console.log('error:', err));
    }
    

    function updateDepartmentCategoryProducts(dcps:Product[], kst?:string):void {
        //console.log('dcps:', dcps);
        //setDepartmentCategoryProducts([...dcps]);
        
        //setShopByAreaTypeProducts([...dcps]);
        getShopByAreaTypeProducts([...dcps], kst!);

        //getAnyItemList(listChangerBtnPosition, dcps, itemsPerList, showItemsPerList);
        //displayListSorter(itemsPerList, dcps);
    }



    /***product category methods***/
      
    /*
    useEffect(()=>{
        if(shopByAreaType.type === 'productCategory'){
            console.log('p4:', storeDepartmentId, productCategoryId);
            console.log('r21:');
            //setStoreDepartmentName(props.storeDepartmentName);
            //setDepartmentCategoryName(props.departmentCategoryName);
            //setProductCategoryName(props.productCategoryName);
            //setStoreDepartmentId(props.storeDepartmentId);
            //setProductCategoryId(props.productCategoryId);
            setKeywordSearch(props.productCategoryName);
            //setSortByOptionValue(props.sortByOptionValue);
            
            //setShowShopByAreaTypeProducts(false);

            console.log('useEffect:', props.productCategoryName);

            imageRefs.current.length = 0;
            setImageRefsLoaded(false);


            //test version
            getDepartmentWithProductCategories(storeDepartmentId!, productCategoryId!);  

        }
        
        //setShowProductCategoryProducts(false);
    },[productCategoryName]);
    */
    
    

    /*

    useEffect(()=>{

        if(shopByAreaType.type === 'productCategory'){
            console.log('loaded again');
            //window.scrollTo(0,0);
            console.log('p4:', storeDepartmentId, productCategoryId);

            //setShowProductCategoryProducts(false);
            //setShowShopByAreaTypeProducts(false);

            imageRefs.current.length = 0;
            setImageRefsLoaded(false);

            //test version
            getDepartmentWithProductCategories(storeDepartmentId!, productCategoryId!);        
        }

    },[productCategoryName]);
    */


    useEffect(()=>{
        //console.log('sbatpc5:', searchFilterParamsRedux);
        if(!searchFilterParamsRedux.showProductSubCategoryFilter){
            //console.log('null5:', searchFilterParamsRedux);
                setSearchFilters(searchFilterParamsRedux.searchFilters);
                setKeywordSearchType(searchFilterParamsRedux.keywordSearchType);
                setSearchTermIsSelected(searchFilterParamsRedux.searchTermIsSelected);
                updateProductCategoryProducts(searchFilterParamsRedux.products, searchFilterParamsRedux.keywordSearchType); 
        }   

    },[searchFilterParamsRedux.showProductSubCategoryFilter]);


    /*
    useEffect(()=>{
        console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);
    */
    

    function getDepartmentWithProductCategories(storeDepartmentId:string, productCategoryId:string):void {
        fetch(`https://server.kando-proto-3.com/shop-store-department-with-product-category-mariadb/${storeDepartmentId}/${productCategoryId}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>{

            setSortByOptionValue(props.sortByOptionValue);
            setStoreDepartmentName(props.storeDepartmentName);
            setStoreDepartmentId(props.storeDepartmentId);
            setDepartmentCategoryName(props.departmentCategoryName);
            setDepartmentCategoryId(props.departmentCategoryId);
            setProductCategoryName(props.productCategoryName);
            setProductCategoryId(props.productCategoryId);
            setShopByAreaType(props.shopByAreaType);

            setKeywordSearch(props.productCategoryName);

            imageRefs.current.length = 0;
            setImageRefsLoaded(false);

            //if(window.innerWidth >= 1024){
                //setShowShopByAreaTypeProducts(false);
            //} else {

                //setShowMobileFilter(false);

                setShowSearchFilterSidebar(false);

            //}
            
            return res.json();
        })
        .then((dept:StoreDepartment|null)=>{
            //console.log('dept:', dept);
            if(dept === null){
                getProductCategoryForSBAT(null);
                return;
            }

            let sd:StoreDepartment = new StoreDepartment({
                                        departmentId:dept.departmentId,
                                        departmentName:dept.departmentName,
                                        departmentImage:dept.departmentImage,
                                        departmentCategories: addDepartmentCategories(dept.departmentCategories)
                                    });

            if(sd.departmentName !== props.storeDepartmentName){
                getStoreDepartment(null);
                return;
            } 
            
            setStoreDepartment(sd);
            getStoreDepartment(sd);
            //console.log('sd:', sd, 'dcn:', departmentCategoryName, 'sdId:', storeDepartmentId, 'pcId:', productCategoryId, 'pcs:', productCategories);
            const dc = getStoreDepartmentCategoryForPC(departmentCategoryName as string, sd.departmentCategories);

            if(typeof dc === 'undefined'){
                //console.log('dc5:', dc);
                getStoreDepartmentCategory(null);
                return;    
            }

            setStoreDepartmentCategory(dc);
            getStoreDepartmentCategory(dc);

            const pcId = window.parseInt(productCategoryId, 10);
            const pcs = getProductCategories(dc, pcId);
            
            const pc = getProductCategory(pcId, pcs as ProductCategory[]);
            //console.log('pc7:', pc);

            if(pc === null || pc.productCategoryName !== props.productCategoryName){
                getProductCategoryForSBAT(null);
                return;
            }

            if(pc !== null){
                pc.showProductSubCategoryShortcut = !!pc.showProductSubCategoryShortcut;
                setProductCategory(pc);
                getProductCategoryForSBAT(pc);
                getProductCategorySearchFilters(pc as ProductCategory);
            }

            const ps = getPCProducts(pcs, pcId);
            //console.log('p 10-16:', addProducts(products));
            setProductCategories(pcs);
            //setProductCategoryProducts([...addProducts(products)]);
            if(ps.length === 0){
                setKeywordSearchType('noProductsAdded');
                getKeywordSearchType('noProductsAdded');
                //setProductCategoryProducts([]);
                setShopByAreaTypeProducts([]);
                getShopByAreaTypeProducts([], 'noProductsAdded');
                setSearchFilters([]);
                setShowPriceSlider(false);

            } else {
                //setProductCategoryProducts([...ps]);
                /*previous version
                setShopByAreaTypeProducts([...ps]);
                getShopByAreaTypeProducts([...ps], 'results');

                getAnyItemList(listChangerBtnPosition, ps, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, ps);

                //getSliderAmount(addProducts(products));
                getSliderAmount([...ps]);
                setSliderPosition(0);
                setPreviousSlider1TotalDistance(0);
                setPreviousSlider2TotalDistance(100);

                */

                const {valid, sfat, reason, products} = checkSearchFilterValidity(pc.searchFilters, ps, 'Product Category');
                //console.log('valid8:', valid, products);

                if(valid && reason === 'pass') {
                    
                    setShopByAreaTypeProducts([...ps]);
                    getShopByAreaTypeProducts([...ps], 'results');

                    getAnyItemList(listChangerBtnPosition, ps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, ps);

                    //get start and end price amounts from each product
                    getSliderAmount([...ps]);
                    setSliderPosition(0);
                    setPreviousSlider1TotalDistance(0);
                    setPreviousSlider2TotalDistance(100);

                    //getStoreDepartmentSearchFilters(sd);
                    setShopByAreaTypeProductTotal(getDepartmentCategoryProductTotal(sd, departmentCategoryName!));

                    setShowSearchFilterSidebar(true);

                } else {
                    setSearchFilters([]);
                    setShowSearchFilterSidebar(false);
                    setShowPriceSlider(false);

                    getAnyItemList(listChangerBtnPosition, ps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, ps);

                    setShopByAreaTypeProducts([...ps]);
                    getShopByAreaTypeProducts([...ps], 'results');

                    getSearchFilterValidity({valid, sfat, reason, products});

                    searchFilterErrorMsgRef.current = true;

                }

                
            }


            //setShowProductCategoryProducts(true);


            //if(window.innerWidth >= 1024){
              //  setShowShopByAreaTypeProducts(true);
            //} else {
                //setShowMobileFilter(true);
                setShowSearchFilterSidebar(true);
            //}
    
        })
        .catch(err=>console.log('error:', err));
    }


    function addProductCategoryProducts(productCategories:ProductCategory[], productCategoryName:string):Product[] {
        //console.log('productCategoryName:', productCategoryName);
        let productCategory = productCategories.find((productCategory)=>{
                                                    return productCategory.productCategoryName === productCategoryName;
                                                });
                //console.log('products:', (productCategory as ProductCategory).products);
                //setProductCategoryProductTotal((productCategory as ProductCategory).products.length);
                setShopByAreaTypeProductTotal((productCategory as ProductCategory).products.length);
            return addProducts((productCategory as ProductCategory).products);
    }

    function getStoreDepartmentCategoryForPC(departmentCategoryName:string, departmentCategories:DepartmentCategory[]):DepartmentCategory {
        const foundDepartmentCategory = departmentCategories.find((departmentCategory)=>{
            return departmentCategory.departmentCategoryName === departmentCategoryName;
        }) as DepartmentCategory;

        if(typeof foundDepartmentCategory === 'undefined'){
            return foundDepartmentCategory;
        }
        
        if(foundDepartmentCategory.isSpecialDepartmentCategory){
            setIsSpecialDepartmentCategory(true);
            return foundDepartmentCategory;
        } else {
            setIsSpecialDepartmentCategory(false);
            return foundDepartmentCategory;
        }
    
    }

    function getProductCategories(dc:DepartmentCategory, pcId:number):ProductCategory[] {
        const pcs = dc.productCategories.map((pc)=>{
                        return new ProductCategory({
                                                    ...pc,
                                                    productSubCategories:getProductSubCategories(pc.productSubCategories)
                                                });
                    });

        return pcs;
    }

    function getProductCategory(productCategoryId:number, productCategories:ProductCategory[]):ProductCategory | null {
        //console.log('test passed againg 4-9');
        const foundProductCategory = productCategories.find((productCategory)=>{
            return productCategory.productCategoryId === productCategoryId;
        }) as ProductCategory;
        
        //console.log('fpc:', foundProductCategory);
        if(typeof foundProductCategory !== 'undefined'){           
            return foundProductCategory;
        } else {
            return null;
        }
    
    }

    function getProductSubCategories(pscs:ProductSubCategory[]):ProductSubCategory[]{
        //console.log('pscs:', pscs);
        return pscs.map((psc)=>{
                    return new ProductSubCategory({...psc});
                });
    }

    function getProductCategorySearchFilters(productCategory:ProductCategory):void {
        //console.log('dc:', productCategory);
        let searchFilters:SearchFilter[] = [];
        productCategory.searchFilters.forEach((searchFilter)=>{
            //console.log('sfn:', searchFilter.filterName);
            if(searchFilter.showFilter){
                searchFilters.push(searchFilter);
            }
        });
        //console.log('sfs4:', searchFilters);
        const searchFiltersBeforeUpdate = cloneDeep(searchFilters);

        let productCategoryProducts:Product[] = [];
        //departmentCategory.productCategories.forEach((productCategory)=>{
            productCategory.products.forEach((product)=>{
                productCategoryProducts.push(product);
                
                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                    //console.log('sft:', searchFilterTerm);
                    searchFilters.forEach((searchFilter, index)=>{
                        //console.log('fn:', searchFilter.filterName);
                        //console.log('sftn:', searchFilterTerm.searchFilterTermName);
                        if(searchFilter.filterName === searchFilterTerm.searchFilterTermName){
                            //console.log('pass:', searchFilter.filterName);
                            searchFilter.searchTerms.forEach((searchTerm, index)=>{
                                //console.log('fn:', searchFilter.filterName);
                                if(searchTerm.searchTermName === searchFilterTerm.searchFilterTermValue){
                                    searchTerm.searchTermQty += 1;
                                }
                                
                            });
                            
                        }

                    });
                    //console.log('sfs8:', searchFilters);
                    
                })
                
            });
        //});

        //console.log('csfs:', searchFiltersBeforeUpdate);

        if(checkSearchFiltersSelected(searchFiltersBeforeUpdate)){
            setInitialSearchFilters([...searchFilters]);
            getProductCategorySearchFiltersForSBAT([...searchFilters]);
            const [updatedSearchFilters, productsSelected] = updateSearchFilters2(productCategoryProducts, searchFiltersBeforeUpdate, searchFilters);
            //console.log('usfs:', updatedSearchFilters);
    
            if(productsSelected.length === 0){
                //console.log('test');
                setKeywordSearchType('noResults');
                setSearchTermIsSelected(true);
            } else {
                setKeywordSearchType('results');
            }
            
            setSearchFilters([...updatedSearchFilters]);
            //setProductCategoryProducts(productsSelected);
            setShopByAreaTypeProducts(productsSelected);
            //console.log('p.length:', productsSelected.length);
            //setProductCategoryProductTotal(productsSelected.length);
            setShopByAreaTypeProductTotal(productsSelected.length);
            setShowSpecialProductCategoryShortcut(false);

            //return true;
            
        } else {

            const fsfs = removeZeroQtySearchTerms(searchFilters);
            const ufsfs = removeZeroQtySearchFilters(fsfs);

            //console.log('sfs:', ufsfs);
            setInitialSearchFilters([...ufsfs]);
            getProductCategorySearchFiltersForSBAT([...ufsfs]);
            setSearchFilters([...ufsfs]);
            setShowSpecialProductCategoryShortcut(true);

        }
        
        
    }


    function getSliderAmount(products:Product[]):void {
        const sortedProducts = products.sort(sortByPriceHightoLow);
        const highestPricedProduct:Product = sortedProducts[0] as Product;
        highestPriceProductAmount.current = highestPricedProduct.eachPrice;
        const lowestPricedProduct:Product = sortedProducts[sortedProducts.length - 1] as Product;
        lowestPriceProductAmount.current = lowestPricedProduct.eachPrice;
        //console.log('high price:', highestPricedProduct.eachPrice);
        //console.log('low price:', lowestPricedProduct.eachPrice);
        if(((highestPricedProduct.eachPrice - lowestPricedProduct.eachPrice) > 1) && window.innerWidth >= 1024){
            setShowPriceSlider(true);
            //console.log('yes');
        } else {
            setShowPriceSlider(false);
            //console.log('no');
        }
        setStartSliderAmount(lowestPricedProduct.eachPrice);
        setEndSliderAmount(highestPricedProduct.eachPrice);
        setPreviousSlider1PostionLeftAmount(lowestPricedProduct.eachPrice);
        setPreviousSlider2PostionLeftAmount(highestPricedProduct.eachPrice);
        if(lowestPricedProduct.eachPrice === highestPricedProduct.eachPrice){
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('0%');
        } else {
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('100%');
        }

    }

    function setupPriceSliderVisibility():void {
        if((highestPriceProductAmount.current - lowestPriceProductAmount.current) > 1){
            //console.log('sps:');
            setShowPriceSlider(true);
            
        } else {
            setShowPriceSlider(false);
        }
    }

    //finish this function 5-5-20
    function priceSliderSearchResults(
        keywordSearch:string,
        startSliderAmount:number,
        endSliderAmount:number,
        previousSlider1PositionLeft:string,
        previousSlider2PositionLeft:string,
        previousSlider1PostionLeftAmount:number,
        previousSlider2PostionLeftAmount:number,
        sliderPosition:number,
        previousSlider1TotalDistance:number,
        previousSlider2TotalDistance:number,
        selectedSlider1ZIndex:number,
        selectedSlider2ZIndex:number,
        sortByOptionValue:string
        ):void {
        //console.log('keywordSearch:', keywordSearch);
        //console.log('ssa:', startSliderAmount);
        //console.log('esa:', endSliderAmount);
        //console.log('previousSlider1PostionLeftAmount:', previousSlider1PostionLeftAmount);
        //console.log('previousSlider2PostionLeftAmount:', previousSlider2PostionLeftAmount);

        //window.scrollTo(0,0);

        //setShowProductCategoryProducts(false);
        sssbatps(false);
        setShowShopByAreaTypeProducts(false);

        dispatch(addSearchFilterParams({keywordSearchType:'',
                                        otherStoreAreaId:'0',
                                        products:[],
                                        showProductSubCategoryFilter:true,
                                        noSearchFilterAttached:false,
                                        searchFilterSelected:'',
                                        searchFilters:[],
                                        searchTermIsSelected:false,
                                        storeAreaName:'',
                                        storeAreaType:'',
                                        storeDepartmentId:'0',
                                        searchTermSelected:''
                                        }));

        //window.scrollTo(0,0);
        setStartSliderAmount(startSliderAmount);
        setEndSliderAmount(endSliderAmount);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
                    
        //console.log('ssa === esa:', previousSlider1PostionLeftAmount === previousSlider2PostionLeftAmount);

        
        if(previousSlider1PostionLeftAmount === previousSlider2PostionLeftAmount){
            setSlider1ZIndex(selectedSlider1ZIndex);
            setSlider2ZIndex(selectedSlider2ZIndex);
        }


        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);  
        

        if(shopByAreaType.type === 'productCategory'){

            if(productCategorySearch === 'product') {
                setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
                setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
                setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
                setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
                setSliderPosition(sliderPosition);
                setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
                setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

                const pr = {    
                    status:'filterProducts',
                    startAmount:previousSlider1PostionLeftAmount,
                    endAmount:previousSlider2PostionLeftAmount
                };

                //console.log('pr2:', pr);

                getProductCategoriesPromise(productCategoryId!, pr)
                .then((storeDepartmentProductCategories)=>{
                    //console.log('price range products:', keywordSearchResult.relatedProducts);
                    //console.log('test:', storeDepartmentProductCategories);
                    //setProducts([...keywordSearchResult.relatedProducts]);
                    const pcId = parseInt(productCategoryId!, 10);
                    const pc = getProductCategory(pcId, storeDepartmentProductCategories);
                    
                    const products:Product[] = addProductCategoryProducts(storeDepartmentProductCategories, productCategoryName as string);
                    //console.log('ps2:', products);

                    if(products.length === 0){
                        setKeywordSearchType('noResults');
                        getKeywordSearchType('noResults');
                        //setProductCategoryProducts([]);
                        setShopByAreaTypeProducts([]);
                        getShopByAreaTypeProducts([], 'noResults');

                        setShowSearchFilterSidebar(false);

                        //setShowProductCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                        //window.scrollTo(0,0);
                    } else {
                        setKeywordSearchType('productCategory');
                        getKeywordSearchType('productCategory');

                        setShowSearchFilterSidebar(true);

                        //original version
                        //setProductCategoryProducts(products);

                        helperSetOptionValue(
                                sortByOptionValue, 
                                products, 
                                false,
                                //storeDepartmentName!,
                                //departmentCategoryName!,
                                //setProductCategoryProducts,
                                setShopByAreaTypeProducts,
                                //setShowProductCategoryProducts
                                setShowShopByAreaTypeProducts
                                );

                        getShopByAreaTypeProducts(products, 'productCategory');

                        getAnyItemList(listChangerBtnPosition, products, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, products);

                        getProductCategorySearchFilters(pc!);

                        //setShowProductCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                        //window.scrollTo(0,0);
                    }
                    

                })
                .catch(err=>console.log('price-range-search error:', err));

            } else if(productCategorySearch === 'subProductKeyword'){

                setKeywordSearchType('productCategory');
                getKeywordSearchType('productCategory');
                setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
                setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
                setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
                setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
                setSliderPosition(sliderPosition);
                setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
                setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

                fetch(`https://server.kando-proto-3.com/shop-product-sub-category-keyword-search-price-range/${productSubCategorySelected!.productSubCategoryName}/${productSubCategorySelected!.productCategoryName}/${previousSlider1PostionLeftAmount.toString()}/${previousSlider2PostionLeftAmount.toString()}`, {
                    
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then(res=>{
                    //setProducts([]);
                    //setProductCategoryProducts([]);
                    setShopByAreaTypeProducts([]);
                    
                    return res.json()
                })
                .then((subCategoryProducts)=>{
                    //console.log('price range products:', keywordSearchResult.relatedProducts);
                    //console.log('test:', subCategoryProducts);
                    //setProductCategoryProductTotal(subCategoryProducts.length);
                    setShopByAreaTypeProductTotal(subCategoryProducts.length);
                    if(subCategoryProducts.length === 0){
                        setKeywordSearchType('noResults');
                        getKeywordSearchType('noResults');
                        //setProductCategoryProducts([]);
                        setShopByAreaTypeProducts([]);
                        getShopByAreaTypeProducts([], 'noResults');
                        //setShowProductCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                        //window.scrollTo(0,0);
                    } else {
                        const scps = addProducts(subCategoryProducts);
                        setKeywordSearchType('productCategory');
                        getKeywordSearchType('productCategory');
                        //setProductCategoryProducts(scps);
                        setShopByAreaTypeProducts(scps);
                        getShopByAreaTypeProducts(scps, 'productCategory');
                        //setShowProductCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                        //setProductCategoryProductTotal(scps.length);
                        setShopByAreaTypeProductTotal(scps.length);
                        //window.scrollTo(0,0);
                        getAnyItemList(listChangerBtnPosition, scps, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, scps);
                    }

                })
                .catch(err=>console.log('price-range-search error:', err));


            } else if(productCategorySearch === 'subProductName'){

                setKeywordSearchType('productCategory');
                getKeywordSearchType('productCategory');
                setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
                setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
                setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
                setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
                setSliderPosition(sliderPosition);
                setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
                setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

                fetch(`https://server.kando-proto-3.com/shop-product-sub-category-name-search-price-range/${productSubCategorySelected!.productSubCategoryName}/${productSubCategorySelected!.productCategoryName}/${previousSlider1PostionLeftAmount.toString()}/${previousSlider2PostionLeftAmount.toString()}`, {
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then(res=>{
                    //setProducts([]);
                    //setProductCategoryProducts([]);
                    setShopByAreaTypeProducts([]);
                    
                    return res.json()
                })
                .then((subCategoryProducts)=>{
                    //console.log('price range products:', keywordSearchResult.relatedProducts);
                    //console.log('test:', subCategoryProducts);
                    //setProductCategoryProductTotal(subCategoryProducts.length);
                    setShopByAreaTypeProductTotal(subCategoryProducts.length);
                    if(subCategoryProducts.length === 0){
                        setKeywordSearchType('noResults');
                        getKeywordSearchType('noResults');
                        //setProductCategoryProducts([]);
                        setShopByAreaTypeProducts([]);
                        getShopByAreaTypeProducts([], 'noResults');
                        //setShowProductCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                        //window.scrollTo(0,0);
                    } else {
                        const scps = addProducts(subCategoryProducts);
                        setKeywordSearchType('productCategory');
                        getKeywordSearchType('productCategory');
                        //setProductCategoryProducts(scps);
                        setShopByAreaTypeProducts(scps);
                        getShopByAreaTypeProducts(scps, 'productCategory');
                        //setShowProductCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                        //setProductCategoryProductTotal(scps.length);
                        setShopByAreaTypeProductTotal(scps.length);
                        //window.scrollTo(0,0);
                        getAnyItemList(listChangerBtnPosition, scps, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, scps);
                    }

                })
                .catch(err=>console.log('price-range-search error:', err));


            }

        }


        if(shopByAreaType.type === 'departmentCategory'){
            
            if(productCategoryName === 'none'){
                //console.log('passed test');
                setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
                setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
                setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
                setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
                setSliderPosition(sliderPosition);
                setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
                setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

                const pr = {
                    priceRange:{
                        startAmount:previousSlider1PostionLeftAmount,
                        endAmount:previousSlider2PostionLeftAmount
                    }
                };
                
                fetch(`https://server.kando-proto-3.com/shop-department-category-product-price-range-mariadb/${storeDepartmentId}/${departmentCategoryId}`, {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:JSON.stringify(pr)
                })
                .then(res=>{
                    //setDepartmentCategoryProducts([]);
                    setShopByAreaTypeProducts([]);
                    return res.json()
                })
                .then((dept)=>{
                    //console.log('result 5-3:', dept);
                    let sd:StoreDepartment = new StoreDepartment({
                                                departmentId:dept.departmentId,
                                                departmentName:dept.departmentName,
                                                departmentImage:dept.departmentImage,
                                                departmentCategories: addDepartmentCategories(dept.departmentCategories),
                                                isSpecialDepartment:dept.isSpecialDepartment,
                                                showDepartmentCategoryShortcut:dept.showDepartmentCategoryShortcut,
                                                searchFilters:addSearchFilters(dept.searchFilters)
                                            });

                    let departmentCategoryProducts:Product[] = [];
                    const dc = getStoreDepartmentCategoryForDC(departmentCategoryName!, sd.departmentCategories);
                    dc.productCategories.forEach((productCategory)=>{
                        productCategory.products.forEach((product)=>{
                            departmentCategoryProducts.push(product);
                        });
                    });

                    const products:Product[] = addDepartmentCategoryProducts(departmentCategoryProducts);
                    //console.log('p 2-14:', products);

                    if(products.length === 0){
                        setKeywordSearchType('noResults');
                        getKeywordSearchType('noResults');
                        //setDepartmentCategoryProducts([]);
                        setShopByAreaTypeProducts([]);
                        getShopByAreaTypeProducts([], 'noResults');

                        setShowSearchFilterSidebar(false);

                        //setShowDepartmentCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                    } else {
                        setKeywordSearchType('results');
                        getKeywordSearchType('results');

                        setShowSearchFilterSidebar(true);

                        helperSetOptionValue(
                                    sortByOptionValue, 
                                    products, 
                                    false,
                                    //storeDepartmentName!,
                                    //departmentCategoryName!, 
                                    //setDepartmentCategoryProducts,
                                    setShopByAreaTypeProducts,
                                    //setShowDepartmentCategoryProducts,
                                    setShowShopByAreaTypeProducts
                                    //addShopLoadDetails!
                                    );
                        
                        getShopByAreaTypeProducts(products, 'results');

                        //console.log('products:', products);
                        getAnyItemList(listChangerBtnPosition, products, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, products);

                        //setShowDepartmentCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                    }

                })
                .catch(err=>console.log('price-range-search error:', err));
                
                
            } else {
                //console.log('test passed');
                setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
                setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
                setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
                setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
                setSliderPosition(sliderPosition);
                setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
                setPreviousSlider2TotalDistance(previousSlider2TotalDistance);


                const pr = {
                    priceRange:{
                        startAmount:previousSlider1PostionLeftAmount,
                        endAmount:previousSlider2PostionLeftAmount
                    }
                };
                
                fetch(`https://server.kando-proto-3.com/shop-department-category-product-price-range-mariadb/${storeDepartmentId}/${departmentCategoryId}`, {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:JSON.stringify(pr)
                })
                .then(res=>{
                    //setDepartmentCategoryProducts([]);
                    setShopByAreaTypeProducts([]);
                    return res.json()
                })
                .then((dept)=>{
                    let sd:StoreDepartment = new StoreDepartment({
                                                departmentId:dept.departmentId,
                                                departmentName:dept.departmentName,
                                                departmentImage:dept.departmentImage,
                                                departmentCategories: addDepartmentCategories(dept.departmentCategories),
                                                isSpecialDepartment:dept.isSpecialDepartment,
                                                showDepartmentCategoryShortcut:dept.showDepartmentCategoryShortcut,
                                                searchFilters:addSearchFilters(dept.searchFilters)
                                            });

                    let departmentCategoryProducts:Product[] = [];
                    const dc = getStoreDepartmentCategoryForDC(departmentCategoryName!, sd.departmentCategories);

                    dc.productCategories.forEach((productCategory)=>{
                        productCategory.products.forEach((product)=>{
                            departmentCategoryProducts.push(product);
                        });
                    });                                        
                    
                    //console.log('result:', dept);
                    const products:Product[] = addDepartmentCategoryProducts(departmentCategoryProducts);
                    if(products.length === 0){
                        setKeywordSearchType('noResults');
                        getKeywordSearchType('noResults');
                        //setDepartmentCategoryProducts([]);
                        setShopByAreaTypeProducts([]);
                        getShopByAreaTypeProducts([], 'noResults');

                        setShowSearchFilterSidebar(false);

                        //setShowDepartmentCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);
                    } else {
                        setKeywordSearchType('results');
                        getKeywordSearchType('results');

                        setShowSearchFilterSidebar(true);

                        //setDepartmentCategoryProducts(products);
                        setShopByAreaTypeProducts(products);
                        getShopByAreaTypeProducts(products, 'results');
                        //setShowDepartmentCategoryProducts(true);
                        setShowShopByAreaTypeProducts(true);

                        //console.log('products:', products);
                        getAnyItemList(listChangerBtnPosition, products, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, products);

                    }

                })
                .catch(err=>console.log('price-range-search error:', err));
            }


        }


        if(shopByAreaType.type === 'department'){
            //console.log('pst:');

            setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
            setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
            setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
            setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
            setSliderPosition(sliderPosition);
            setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
            setPreviousSlider2TotalDistance(previousSlider2TotalDistance);
    
            //setShowDepartmentCategoryProducts(false);
            
            const ps = {
                    priceRange:{
                        startAmount:previousSlider1PostionLeftAmount,
                        endAmount:previousSlider2PostionLeftAmount
                    }
                };
    
            fetch(`https://server.kando-proto-3.com/shop-store-department-product-price-range-mariadb/${storeDepartmentId}`, {
                method:'POST',
                headers:{
                    'content-type':'application/json',
                    'Accept':'application/json'
                },
                body:JSON.stringify(ps)
            })
            .then(res=>{
                return res.json();
            })
            .then((dept)=>{
                
                let sd:StoreDepartment = new StoreDepartment({
                    departmentId:dept.departmentId,
                    departmentName:dept.departmentName,
                    departmentImage:dept.departmentImage,
                    departmentCategories: addDepartmentCategories(dept.departmentCategories),
                    isSpecialDepartment:dept.isSpecialDepartment,
                    showDepartmentCategoryShortcut:dept.showDepartmentCategoryShortcut,
                    searchFilters:addSearchFilters(dept.searchFilters)
                });
                
                //console.log('result2:', sd);
                const sdps = getDepartmentProducts(sd);
                const products:Product[] = addDepartmentProducts(sdps);
                //console.log('products:', products);
                if(products.length === 0){
                    //console.log('first');
                    setKeywordSearchType('noResults');
                    //setDepartmentProducts([]);
                    setShopByAreaTypeProducts([]);
                    getShopByAreaTypeProducts([], 'noResults');
                    getKeywordSearchType('noResults');

                    setShowSearchFilterSidebar(false);

                    //setShowDepartmentProducts(true);
                    setShowShopByAreaTypeProducts(true);
                } else {
                    //console.log('last');
                    setKeywordSearchType('results');
                    getKeywordSearchType('results');

                    setShowSearchFilterSidebar(true);
                    
                    helperSetOptionValue(
                                sortByOptionValue, 
                                products, 
                                false,
                                //storeDepartmentName!,
                                //departmentCategoryName!, 
                                //setDepartmentProducts,
                                setShopByAreaTypeProducts,
                                //setShowDepartmentProducts,
                                setShowShopByAreaTypeProducts
                                //addShopLoadDetails!
                                );
    
                    //getAnyItemList(listChangerBtnPosition, products, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, products);
    
                    getStoreDepartmentSearchFilters(sd);

                    //console.log('pssrsp:', products);
                    getShopByAreaTypeProducts(products, 'results');
                                
                    //setShowDepartmentProducts(true);
                    setShowShopByAreaTypeProducts(true);
                }


            })
            .catch(err=>console.log('price-range-search error:', err));
               

        }


    }


    /*
    function setupProductCategoryProducts(listItems:object[]):JSX.Element{
            console.log('setupProductCategoryProducts:', listItems);
            console.log('test passed products:', listItems);
        return  <div className="shop-product-category-products-wrapper">
                    {(listItems as Product[]).map((product, index)=>{
                                                        return <div key={index} className="shop-product-detail-wrapper">
                                                                    <ShopProductDetail product={product} getImageRef={getImageRef}/>
                                                                </div> 
                                                    })}
                </div>
    }
    */


    //test if a function with a closure still updated variable inside when the function itself doesn't change
    function updateProductCategoryShortcutSearchFilter(showShortcut:boolean, sfps:SearchFilterParams|null):void {
        if(showShortcut){
            //productCategory!.showProductSubCategoryShortcut = true;
            const pc = new ProductCategory({...productCategory});
            setProductCategory(pc);
            //setShowProductCategoryProducts(true);
            setShowShopByAreaTypeProducts(true);

            
            if(sfps === null){
                dispatch(addSearchFilterParams({keywordSearchType:'',
                                                otherStoreAreaId:'0',
                                                products:[],
                                                showProductSubCategoryFilter:true,
                                                noSearchFilterAttached:false,
                                                searchFilterSelected:'',
                                                searchFilters:[],
                                                searchTermIsSelected:false,
                                                storeAreaName:'',
                                                storeAreaType:'',
                                                storeDepartmentId:'0',
                                                searchTermSelected:''
                                                }));
            }
            

        } else {
            //productCategory!.showProductSubCategoryShortcut = false;
            const pc = new ProductCategory({...productCategory});
            setProductCategory(pc);
            //setShowProductCategoryProducts(true);
            setShowShopByAreaTypeProducts(true);

            if(sfps !== null){
                if(sfps !== null){
                    //console.log('sfps:', sfps);
                    if(sfps.searchFilters.length > 0){
                        const usfs = sfps.searchFilters.map((sf)=>{
                                            const sfda = Object.assign({}, new Date(sf.searchFilterDateAdded));
                                            sf.searchFilterDateAdded = sfda;
    
                                            if(sf.searchTerms.length > 0){
                                                const usts = sf.searchTerms.map((st)=>{
                                                                return Object.assign({}, st);
                                                            });
    
                                                sf.searchTerms = usts;
                                                return Object.assign({}, sf);
    
                                            } else {
                                                return Object.assign({}, sf);
                                            }
                                        });
    
                        sfps.searchFilters = usfs
                    }
    
                    if(sfps.products.length > 0){
                        const ups = sfps.products.map((p)=>{
                                        return Object.assign({}, p);
                                    });
    
                        sfps.products = ups;
                    }
    
    
                    dispatch(addSearchFilterParams({...sfps, showProductSubCategoryFilter:false}));
                }

            }
        }

        //console.log('sfps5:', sfps);
    }

    function handleProductSubCategorySearch(e:React.MouseEvent, productSubCategory:ProductSubCategory):void {
        //console.log('productSubCategorySearch:', productSubCategory, 'deptId:', storeDepartment!.departmentId, 'sfis:', searchTermIsSelected);
        e.preventDefault();
        e.stopPropagation();
        //window.scrollTo(0,0);
        
        setSortByOptionValue(props.sortByOptionValue);
        setProductSubCategorySelected(productSubCategory);
        //setShowProductCategoryProducts(false);
        setShowShopByAreaTypeProducts(false);


        checkFilterSearchTerm2(
                                e,
                                productSubCategory.productCategorySearchFilterName,//'type',
                                productSubCategory.productCategorySearchTermName,//'pork',
                                initialSearchFilters,
                                searchTermIsSelected,
                                storeDepartment!.departmentId.toString(),
                                productSubCategory.productCategoryId.toString(),
                                productSubCategory.productCategoryName,
                                'Product Category',
                                //setShowProductCategoryProducts,
                                setShowShopByAreaTypeProducts,
                                clearAllExistingImages,
                                setSearchFilters,
                                //setProductCategoryProducts,
                                setShopByAreaTypeProducts,
                                //setProductCategoryProductTotal,
                                setShopByAreaTypeProductTotal,
                                setShowSpecialProductCategoryShortcut,
                                setSearchTermIsSelected,
                                setKeywordSearchType,
                                addSearchFilters,
                                previousSlider1PostionLeftAmount,
                                previousSlider2PostionLeftAmount,
                                updateProductCategoryShortcutSearchFilter
                            );


    }


    function handleProductCategorySearchForPC(e:React.MouseEvent, selectedProductCategory:ProductCategory /*keywordSearch:string, productCategoryName:string*/):void {
        //console.log('productCategory result:', selectedProductCategory);
        //console.log('spcns:', selectedProductCategory.showProductCategoryNameSearch);
        //console.log('spcks:', selectedProductCategory.showProductCategoryKeywordSearch);
        //console.log('spcn:', selectedProductCategory.productCategoryName);
        //console.log('pcn:', productCategoryName);
        
        if(selectedProductCategory.productCategoryName === productCategoryName){
            //console.log('true');
            //setShowProductCategoryProducts(false);
            setShowShopByAreaTypeProducts(false);
            getProductCategoryProducts();
            //window.scrollTo(0,0);
        } else if(selectedProductCategory.productCategoryName !== productCategoryName){
            //console.log('true');
            //setShowProductCategoryProducts(false);
            setShowShopByAreaTypeProducts(false)
            //window.scrollTo(0,0);
        } 
        

    }    


    function getProductCategoriesPromise(productCategoryId:string, priceRange:PriceRange):Promise<ProductCategory[]> {
        //console.log('pcId:', productCategoryId);
        return fetch(`https://server.kando-proto-3.com/shop-get-product-categories-mariadb/${storeDepartmentId}/${productCategoryId}`, {
                                        //method:'GET',
                                        method:'POST',
                                        headers:{
                                            'Content-Type':'application/json',
                                            'Accept':'application/json'
                                        },
                                        body:JSON.stringify(priceRange)
                                    })
                .then((res)=>{
                    //setProductCategoryProducts([]);
                    setShopByAreaTypeProducts([]);
                    return res.json();
                })
                .then((productCategories)=>{
                    //console.log('pcs:', productCategories);
                    return productCategories;
                })
                .catch(err=>console.error('get product categories fetch error:', err));
    }


    function getProductCategoriesAndRelatedProducts(productCategoryId:string, departmentCategoryName:string, storeDepartmentId:string):void {
        //console.log('pcid:', productCategoryId, 'dcId:', departmentCategoryName, 'sdId:', storeDepartmentId);
        const pr = {    
            status:'noFilterProducts',
            startAmount:previousSlider1PostionLeftAmount,
            endAmount:previousSlider2PostionLeftAmount
        };

        Promise.all([getProductCategoriesPromise(productCategoryId!, pr),
                    /*getProductCategoryProductsPromise(storeDepartmentId!, productCategoryId!)*/
                    ])
        .then(([productCategories/*, products*/])=>{
            //console.log('p 10-16:', products);
            const pcs = productCategories.map((pc)=>{
                            return new ProductCategory({...pc});
                        });
            //console.log('pcs 7-15:', pcs);
            const pcId = window.parseInt(productCategoryId!, 10);
            const ps = getPCProducts(pcs, pcId);
            //setProductCategoryProductTotal(ps.length);
            setShopByAreaTypeProductTotal(ps.length);
            //console.log('p 10-16:', addProducts(products));
            setProductCategories(pcs);
            //setProductCategoryProducts([...addProducts(products)]);
            //setProductCategoryProducts([...ps]);
            setShopByAreaTypeProducts([...ps]);

            //getSliderAmount(addProducts(products));
            getSliderAmount([...ps]);
            setSliderPosition(0);
            setPreviousSlider1TotalDistance(0);
            setPreviousSlider2TotalDistance(100);
            //setShowProductCategoryProducts(true);
            setShowShopByAreaTypeProducts(true);
        })
        .catch((err)=>console.error('Promise.all() product catategories and products error:', err));
        
    }


    function getPCProducts(productCategories:ProductCategory[], pcId:number):Product[] {
        const pc = productCategories.find((pc)=>{
                        return pc.productCategoryId === pcId;
                    });

        if(typeof pc === 'undefined'){
            throw new Error('could not find product category');
        }

        return addProducts(pc.products);

    }

    //helper to get selected product category
    //original version to get products categories and products
    //refer to getProductCategoriesAndRelatedProducts()
    function getProductCategoryProducts():void{
        //window.scrollTo(0,0);
        //original version
        fetch(`https://server.kando-proto-3.com/shop/${departmentCategoryName}/${productCategoryName}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
            })
            .then((resp)=> {
                //console.log('resp:', resp);
                //setShowProductCategoryProducts(false);
                return resp.json();
            })
            .then((storeDepartmentProductCategories)=>{
                //console.log('productCategoryProducts:', storeDepartmentProductCategories);
                //console.log('productCategoryName:', productCategoryName);
                //original version
                setProductCategories(storeDepartmentProductCategories);
                //original version
                //setProductCategoryProducts(addProductCategoryProducts(storeDepartmentProductCategories, productCategoryName as string));
                setShopByAreaTypeProducts(addProductCategoryProducts(storeDepartmentProductCategories, productCategoryName as string));

                //get start and end price amounts from each product
                //original version
                getSliderAmount(addProductCategoryProducts(storeDepartmentProductCategories, productCategoryName as string));
                setSliderPosition(0);
                setPreviousSlider1TotalDistance(0);
                setPreviousSlider2TotalDistance(100);
                //setShowProductCategoryProducts(true);
                setShowShopByAreaTypeProducts(true);
                

            })
            .catch(err=>console.log('error 10-16:', err));
    }

    function hightlightProductCategoryText(
                e:React.MouseEvent, 
                selectedProductCategoryName:string, 
                categoryType:string,
                storeDepartmentName:string,
                departmentCategoryName:string,
                selectedStoreDepartmentId:number,
                selectedProductCategoryId:number
                ):void {
        e.preventDefault();
        //console.log('spcn:', selectedProductCategoryName);
        //console.log('pcn:', productCategoryName);

        setProductCategorySearch('product');

        //setShowProductCategoryProducts(false);
        setShowShopByAreaTypeProducts(false);

        if(selectedProductCategoryName === productCategoryName){
            //console.log('test passed');
            helperHighlightCategory(e, selectedProductCategoryName, categoryType)
            .then((response)=>{
                //setShowProductCategoryProducts(false);
                return response.json()
            })
            .then(({action})=>{
                if(action === 'added') /*&& (selectedProductCategoryName === productCategoryName)*/{
                    //console.log('added');

                setSliderPosition(0);
                setPreviousSlider1TotalDistance(0);
                setPreviousSlider2TotalDistance(100);
                getProductCategoriesAndRelatedProducts(selectedProductCategoryId.toString(), departmentCategoryName, selectedStoreDepartmentId.toString());

                } 
            })
            .catch(err=>console.log('error:', err));

        } else {

            helperHighlightCategory(e, selectedProductCategoryName, categoryType)
            .then((response)=>response.json())
            .then(({action})=>{
                if(action === 'added'){
                    setSliderPosition(0);
                    setPreviousSlider1TotalDistance(0);
                    setPreviousSlider2TotalDistance(100);
                    //console.log('not added');
                    
                    navigate(`/shop/${formatLinkString(storeDepartmentName)}/${formatLinkString(departmentCategoryName)}/${formatLinkString(selectedProductCategoryName)}/${selectedStoreDepartmentId}/${selectedProductCategoryId}`);

                }
            })
            .catch(err=>console.log('error:', err));
        }



    }


    function setupProductCategorySidebar(storeDepartment:StoreDepartment, storeDepartmentCategory:DepartmentCategory,
                                        showSearchFilterSidebar:boolean, showPriceSlider:boolean):JSX.Element {
        //console.log('sa2:', previousSlider1PostionLeftAmount, 'ea2:', previousSlider2PostionLeftAmount, 'ssa:', startSliderAmount, 'esa:', endSliderAmount);
        return  <div ref={shopProductsMobileNavRef} className="shop-products-mobile-nav-container">

                    {/*<div className="shop-products-mobile-search-filter-container">*/}
                        <StoreDepartmentCategorySidebarSelector2 
                            productCategories={productCategories} 
                            isSpecialDepartmentCategory={storeDepartmentCategory.isSpecialDepartmentCategory}
                            storeDepartment={storeDepartment as StoreDepartment}
                            storeDepartmentName={storeDepartmentName}
                            departmentCategoryName={departmentCategoryName}
                            storeDepartmentCategory={storeDepartmentCategory!}
                            setShowProductCategoryProducts={setShowShopByAreaTypeProducts /*setShowProductCategoryProducts*/}
                            clearAllExistingImages={clearAllExistingImages}
                            hightlightProductCategoryText={hightlightProductCategoryText}
                            reloadProductCategory={reloadProductCategory}
                            reloadDepartmentCategory={reloadDepartmentCategory}
                            showPriceSlider={showPriceSlider}
                            showSearchFilterSidebar={showSearchFilterSidebar}
                            
                            keywordSearch={keywordSearch!}
                            startSliderAmount={startSliderAmount}
                            endSliderAmount={endSliderAmount}
                            previousSlider1PositionLeft={previousSlider1PositionLeft}
                            previousSlider2PositionLeft={previousSlider2PositionLeft}
                            previousSlider1PostionLeftAmount={previousSlider1PostionLeftAmount}
                            previousSlider2PostionLeftAmount={previousSlider2PostionLeftAmount}
                            priceSliderSearchResults={priceSliderSearchResults}
                            sliderPosition={sliderPosition}
                            previousSlider1TotalDistance={previousSlider1TotalDistance}
                            previousSlider2TotalDistance={previousSlider2TotalDistance}
                            slider1ZIndex={slider1ZIndex}
                            slider2ZIndex={slider2ZIndex}
                            sortByOptionValue={sortByOptionValue}

                            searchFilters={searchFilters}
                            initialSearchFilters={initialSearchFilters}
                            searchTermIsSelected={searchTermIsSelected}
                            storeDepartmentId={storeDepartmentId!}
                            otherStoreAreaId={productCategoryId!}
                            storeAreaName={productCategoryName!}
                            storeAreaType={'Product Category'}
                            setSearchFilters={setSearchFilters}
                            
                            setDepartmentCategoryProducts={updateProductCategoryProducts}
                            setDepartmentCategoryProductTotal={setShopByAreaTypeProductTotal /*setProductCategoryProductTotal*/}
                            setShowSpecialProductCategoryShortcut={setShowSpecialProductCategoryShortcut}
                            setSearchTermIsSelected={setSearchTermIsSelected}
                            setKeywordSearchType={setKeywordSearchType}
                            addSearchFilters={addSearchFilters}
                            updateProductCategoryShortcutSearchFilter={updateProductCategoryShortcutSearchFilter}
                            loadNavLink={loadNavLink}
                            sssbatps={sssbatps}
                        />
                    {/*</div>

                    <div className="shop-products-mobile-item-count-container">
                        <button type="button" className="shop-products-mobile-view-items-btn">{`Show ${shopByAreaTypeProducts.length} ${(shopByAreaTypeProducts.length === 1 ? 'result' : 'results')}`}</button>
                    </div>*/}
                </div>

    }


    function reloadProductCategory(
                    e:React.MouseEvent, 
                    storeDepartmentName:string,
                    departmentCategoryName:string,
                    selectedProductCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedProductCategoryId:number
                        ):void {

        //console.log('spcn:', selectedProductCategoryName, productCategoryName);
        //console.log('pcn:', productCategoryName);


        if(selectedProductCategoryName === productCategoryName){
            //console.log('true:');

        } else {

        }
        

    }

    function reloadDepartmentCategory(
                    e:React.MouseEvent, 
                    storeDepartmentName:string,
                    selectedDepartmentCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedDepartmentCategoryId:number
                                ):void{

        e.preventDefault();
        e.stopPropagation();
        //console.log('sdn:', storeDepartmentName);
        //console.log('sdcn:', selectedDepartmentCategoryName);

        helperHighlightCategory(e, selectedDepartmentCategoryName, 'department')
        .then((response)=>response.json())
        .then(({action})=>{
            if(action === 'added'){
                //console.log('added');

                navigate(`/shop/${storeDepartmentName}/${selectedDepartmentCategoryName}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`);

            }
        })
        .catch(err=>console.log('error:', err));


        
    }

    //duplicate
    function hightlightProductCategoryTextForPC(
                    e:React.MouseEvent, 
                    selectedProductCategoryName:string, 
                    categoryType:string,
                    storeDepartmentName:string,
                    departmentCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedProductCategoryId:number
                    ):void {
        e.preventDefault();
        //console.log('spcn:', selectedProductCategoryName);
        //console.log('pcn:', productCategoryName);

        setProductCategorySearch('product');

       //setShowProductCategoryProducts(false);
       setShowShopByAreaTypeProducts(false);

        if(selectedProductCategoryName === productCategoryName){
            //console.log('test passed');
            helperHighlightCategory(e, selectedProductCategoryName, categoryType)
            .then((response)=>{
                //setShowProductCategoryProducts(false);
                return response.json()
            })
            .then(({action})=>{
                if(action === 'added') /*&& (selectedProductCategoryName === productCategoryName)*/{
                    //console.log('added');

                   setSliderPosition(0);
                   setPreviousSlider1TotalDistance(0);
                   setPreviousSlider2TotalDistance(100);
                   getProductCategoriesAndRelatedProducts(selectedProductCategoryId.toString(), departmentCategoryName, selectedStoreDepartmentId.toString());
    
                } 
            })
            .catch(err=>console.log('error:', err));

        } else {

            helperHighlightCategory(e, selectedProductCategoryName, categoryType)
            .then((response)=>response.json())
            .then(({action})=>{
                if(action === 'added'){
                    setSliderPosition(0);
                    setPreviousSlider1TotalDistance(0);
                    setPreviousSlider2TotalDistance(100);
                    //console.log('not added');
                    
                    navigate(`/shop/${storeDepartmentName}/${departmentCategoryName}/${selectedProductCategoryName}/${selectedStoreDepartmentId}/${selectedProductCategoryId}`);
    
                }
            })
            .catch(err=>console.log('error:', err));
        }



    }



    function sortByOptionClicked(
                        e:React.MouseEvent,
                        optionsClicked:string, 
                        productCategoryProducts:Product[],
                        selectedStoreDepartmentId:number,
                        selectedDepartmentCategoryId:number
                        ):void {
        //console.log('sortByOptionClicked:', optionsClicked);

        e.stopPropagation();
        //console.log('show me:');

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        //setShowProductCategoryProducts(false);
        setShowShopByAreaTypeProducts(false);
        //window.scrollTo(0,0);
        setSortByOptionValue(optionsClicked);
        const selectedOption:string = optionsClicked;
        helperSetOptionValue(
        selectedOption, 
        productCategoryProducts, 
        true, 
        //storeDepartmentName!,
        //departmentCategoryName!,
        //setProductCategoryProducts,
        setShopByAreaTypeProducts,
        //setShowProductCategoryProducts
        setShowShopByAreaTypeProducts
        );

        getAnyItemList(listChangerBtnPosition, productCategoryProducts, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, productCategoryProducts);

    }

    function productMobileNavHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        shopProductsMobileNavRef.current!.style.visibility = 'visible';
        shopProductsMobileNavRef.current!.style.transform = 'translateY(0%)';  
    }

    function shopProductsMobileNavCloseBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        shopProductsMobileNavRef.current!.style.visibility = '';
        shopProductsMobileNavRef.current!.style.transform = '';
    }


    function updateProductCategoryProducts(pcps:Product[], kst?:string):void {
        //console.log('ps2:', pcps);
        //setProductCategoryProducts([...pcps]);
        //console.log('kws7:', keywordSearchType, kst);
        //setShopByAreaTypeProducts([...pcps]);
        let kwst = '';
        if(typeof kst !== 'undefined'){
            kwst = kst;

        } else {
            kwst = keywordSearchType;
        }

        getShopByAreaTypeProducts([...pcps], kwst);

        //getAnyItemList(listChangerBtnPosition, pcps, itemsPerList, showItemsPerList);
        //displayListSorter(itemsPerList, pcps);
    }

    //console.log('sf23:', shopByAreaType.type, storeDepartment !== null, storeDepartment, storeDepartments.length > 0, showShopByAreaTypeProducts);

    //create function to add/remove conditions based on screen width
    //if mobile make results appear as they are
    //if desktop make results look like they rebooted
    function setupShowProductsCondition(showProducts:boolean):boolean {
        
        /*
        if(window.innerWidth < 1024){
            return true;

        } else {
            if(showProducts){
                return true;

            } else {
                return false;
            }
        }
        */

        return true;

    }



    if(shopByAreaType.type === 'department' && storeDepartment !== null 
        && storeDepartment.isSpecialDepartment && storeDepartments.length > 0  
        && setupShowProductsCondition(showShopByAreaTypeProducts) && showMobileFilter
        /*&& showShopByAreaTypeProducts*/){
            //console.log('sf23:');
        return <div className="sbat-side-bar-container">
                    {setupSpecialStoreDepartmentSidebar(storeDepartmentName!, storeDepartments!)}
                </div>

    } else if(shopByAreaType.type === 'departmentCategory' && storeDepartment !== null 
                && setupShowProductsCondition(showShopByAreaTypeProducts) && showMobileFilter
                /*&& showShopByAreaTypeProducts*/){
        return  <div className="sbat-side-bar-container">
                    {setupSpecialStoreDepartmentCategorySidebar(storeDepartment as StoreDepartment, departmentCategoryName as string)}
                </div>

    } else if(shopByAreaType.type === 'productCategory' && storeDepartment !== null 
                && storeDepartmentCategory !== null
                && setupShowProductsCondition(showShopByAreaTypeProducts) && showMobileFilter){
                    //console.log('p4:', storeDepartmentId, productCategoryId);
        return <div className="sbat-side-bar-container">
                    {setupProductCategorySidebar(storeDepartment, storeDepartmentCategory, showSearchFilterSidebar, showPriceSlider)}
                </div>

    } else {
        //console.log('sf23:');
        return null;
    }
}

export default ShopByAreaTypeFilter;