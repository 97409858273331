import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StoreSearchParams from '../../../storeSearchParams';
import cloneDeep from 'lodash/cloneDeep';

const initialState: StoreSearchParams = {
    lat:0,
    lng:0,
    currentLocationRequest:false,
    defaultLocation:'78633',
    storeSearch:'',
    showStoreLocator:false,
    listChangerBtnPosition:0
};

export const storeSearchParamsSlice = createSlice({
  name: 'storeSearchParams',
  initialState,
  reducers: {
    addStoreSearchParams: (state, action:PayloadAction<StoreSearchParams>) => {
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
      state.currentLocationRequest = action.payload.currentLocationRequest;
      state.defaultLocation = action.payload.defaultLocation;
      state.listChangerBtnPosition = action.payload.listChangerBtnPosition;
      state.showStoreLocator = action.payload.showStoreLocator;
      state.storeSearch = action.payload.storeSearch;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addStoreSearchParams } = storeSearchParamsSlice.actions;

interface RootState {
  storeSearchParams: StoreSearchParams
}

export const selectStoreSearchParams = (state:RootState) =>  {
  const clone = cloneDeep(state.storeSearchParams);
  return clone;
  /*
  return  {
    lat:state.storeSearchParams.lat,
    lng:state.storeSearchParams.lng,
    currentLocationRequest:state.storeSearchParams.currentLocationRequest,
    defaultLocation:state.storeSearchParams.defaultLocation,
    storeSearch:state.storeSearchParams.storeSearch,
    showStoreLocator:state.storeSearchParams.showStoreLocator,
    listChangerBtnPosition:state.storeSearchParams.listChangerBtnPosition
  };
  */
}

export default storeSearchParamsSlice.reducer;