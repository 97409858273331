import React, {useEffect, useState, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useRouteError, isRouteErrorResponse } from 'react-router-dom';
import './GenericErrorMessage2.css';

interface Props {}

const GenericErrorMessage2:React.FunctionComponent<Props> = (props:Props)=>{
    //const error = useRouteError();    

    return (<div className="generic-error-msg-2-container">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
        className="generic-error-msg-2-svg">
        <path fillRule="evenodd" clipRule="evenodd" 
        d="M11.106 3.498c.368-.664 1.42-.664 1.788 0l9 16.2c.332.598-.152 1.302-.895 1.302H3c-.743 0-1.227-.704-.894-1.302l8.999-16.2zM11 9.858c0-.474.448-.858 1-.858s1 .384 1 .857v4.286c0 .473-.448.857-1 .857s-1-.384-1-.857V9.857zM13 17a1 1 0 11-2 0 1 1 0 012 0z" 
        fill="currentColor"></path>
    </svg>
    <h2 className="generic-error-msg-2-heading">An unexpected error has occurred</h2>
    <p className="generic-error-msg-2-text"> Please retry your last action.</p>
    <p className="generic-error-msg-2-text"> If the error persists try again later or contact tech support.</p>
    </div>);
}


export default GenericErrorMessage2;