import React, {useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLoaderData } from "react-router-dom";
import MainNavbar2 from './MainNavbar2';
import { Buffer } from 'buffer';
import AdminLoginForm from './AdminLognForm';
//import './AdminLogin.css';

interface Props {}


const AdminLogin:React.FunctionComponent<Props> = (props: Props) => {
    
    return <AdminLoginForm/>

};


export default AdminLogin;