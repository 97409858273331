import React, {useState, useEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Review from './review';
import {addReviews} from './helper-add-reviews';
import AdminProductReview from './AdminProductReview';
import transformDate from './helper-transform-date';
import ApproveRejectProductReview from './ApproveRejectProductReview';
import getNotApprovedProductReviews from './helper-get-not-approved-product-reviews';
import AdminProductReviewsLoadingMsg from './AdminProductReviewsLoadingMsg';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import DbResponse from './db-response';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import LoaderAnimation from './LoaderAnimation';
import Footer from './Footer';
import FormErrorMessage, {FormError} from './FormErrorMessage';
import loadNavLink from './loadNavLinkHelper';
import './AdminProductReviews.css';
import GenericErrorMessage from './GenericErrorMessage';

interface Props {
    //path:string;
}

const productReviewRatings = ['all', '1', '2', '3', '4', '5'];
const approvedOptions = ['yes', 'no'];
const resultsPerPage = ['5', '10', '20', '30'];

const AdminProductReviews2:React.FunctionComponent<Props> = (props:Props) => {
    //console.log('adminProductReviews test');
    const navigate = useNavigate();
    const [productReviews, setProductReviews] = useState<Review[] | []>([]);
    const [productReviewSelected, setProductReviewSelected] = useState<Review | null>(null);
    const [showApproveRejectProductReview, setShowApproveRejectProductReview] = useState(false);
    const [showProductReviewLoadingMsg, setShowProductReviewLoadingMsg] = useState(true);

    const [productReviewSearchText, setProductReviewSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No product reviews have been added.');
    //const [showLoadingSearchResults, setShowLoadingSearchResults] = useState(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(10);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Review[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);
    //const [listViewerHeading, setListViewerHeading] = useState('Reviews awaiting approval');
    const [listViewerHeading, setListViewerHeading] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [elementName, setElementName] = useState('product-review');
    const [productReviewRatingNameSelected, setProductReviewRatingNameSelected] = useState('all');
    const [productReviewRatingNames, setProductReviewRatingNames] = useState<string[]>(productReviewRatings);

    const [elementName2, setElementName2] = useState('approved-reviews');
    const [approvedProductReviewOptionSelected, setApprovedProductReviewOptionSelected] = useState('no');
    const [approvedProductReviewOptions, setApprovedProductReviewOptions] = useState<string[]>(approvedOptions);

    const [elementName3, setElementName3] = useState('results-per-page');
    const [resultsPerPageOptionSelected, setResultsPerPageOptionSelected] = useState('10');
    const [resultsPerPageOptions, setResultsPerPageOptions] = useState<string[]>(resultsPerPage);

    const adminProductReviewsContainerRefs = useRef<HTMLDivElement[]>([]);
    const containerTopRef = useRef<number>(0);

    const prKeywordRef = useRef<HTMLInputElement>(null);
    const prKeywordRefs = useRef<HTMLInputElement[]>([]);

    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });

    //const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);

    const sfgErrorMsgRefs = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}[]>([]);


    const existingSearchResultsHeight = useRef<number>(0);

    const [showKeywordSearchLimitError, setShowKeywordSearchLimitError] = useState(false);

    const portrait = useRef<MediaQueryList[]>([]);

    const [temporaryClearSearchResults, setTemporaryClearSearchResults] = useState(false);

    //const formRef = useRef<HTMLFormElement>(null);

    const formRefs = useRef<HTMLFormElement[]>([]);

    const [showFooter, setShowFooter] = useState(false);

    const loaderRefs = useRef<HTMLDivElement[]>([]);

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);
    const isLoadingTimeoutRef = useRef<number>(0);


    //console.log('load9:');


    useEffect(()=>{
        //console.log('load9:');
        //window.scrollTo(0,0);
        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);

        getNotApprovedProductReviewsList();
    },[]);

    function getNotApprovedProductReviewsList(lcbPosition?:number):void {
        getNotApprovedProductReviews()
        .then(res => res.json())
        .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{            
            if(dbResponseMsg === 'record found'){
                const prs = addReviews(dbResponseRecords as Review[]);
                //setShowProductReviewLoadingMsg(false);
                //console.log('lcbp:', listChangerBtnPosition);
                setProductReviews(addReviews(prs));
                //previous version
                //getAnyItemList(0, prs, itemsPerList, showItemsPerList);
                getAnyItemList(typeof lcbPosition !== 'undefined' ? lcbPosition : listChangerBtnPosition, prs, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, prs);
                //setListViewerHeading('Reviews awaiting approval');
                setListViewerHeading('');
                setShowApproveRejectProductReview(false);
                setIsLoading(false);

                //adjust item count based on items per list setting
                //
                let nrh = 0;
                if(itemsPerList < prs.length){
                    nrh = (itemsPerList * 94) + 736 + 16 + 57;

                } else {
                    nrh = (prs.length) * 94 + 736 + 16 + 57;
                }

                if(nrh > 950){
                    adminProductReviewsContainerRefs.current[0].style.minHeight = `${nrh}px`;
                }
                
                

            } else {
                //setShowProductReviewLoadingMsg(false);
                setNoSearchResultsMessage('No product reviews to review.');
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setProductReviews([]);
                //setListViewerHeading('Reviews awaiting approval');
                setListViewerHeading('');
                setShowApproveRejectProductReview(false);
                setIsLoading(false);

                setShowFooter(true);
            }

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
            
        })
        .catch(err=>{
            //console.log('error:', err);
            setShowErrorMessage(true);
            setIsLoading(false);

            setShowFooter(true);
        });
    }

    function getPRKeywordRef(el:HTMLInputElement|null):void {
        if(el !== null){
            prKeywordRefs.current.length = 0;
        }

        if(el !== null && prKeywordRefs.current.length === 0){
            prKeywordRefs.current.push(el);

        } else if(el !== null) {
            const found = prKeywordRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                prKeywordRefs.current.push(el);
            }

        }

        //console.log('prkrs:', prKeywordRefs.current);

        if(window.innerWidth >= 575){
            prKeywordRefs.current[0]!.placeholder = 'Enter product, product category, department category or department';

        } else {
            prKeywordRefs.current[0]!.placeholder = '';
        }

    }

    useEffect(()=>{
        window.addEventListener('resize', adminProductReviewsResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", adminProductReviewsResizeHandler);

        return cleanupAdminProductReviewsResizeHandler;
    },[]);

    function adminProductReviewsResizeHandler(e:Event):void {

        if(typeof prKeywordRefs.current[0] !== 'undefined'){
            if(window.innerWidth >= 575){
                prKeywordRefs.current[0]!.placeholder = 'Enter product, product category, department category or department';

            } else {
                prKeywordRefs.current[0]!.placeholder = '';
            }
        }

        if(typeof sfgErrorMsgRefs.current![0] !== 'undefined'){
            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
        }

    }

    function cleanupAdminProductReviewsResizeHandler():void {
        window.removeEventListener('resize', adminProductReviewsResizeHandler);

        portrait.current[0].removeEventListener("change", adminProductReviewsResizeHandler);
    }


    function getFormRef(el:HTMLFormElement|null):void {
        if(formRefs.current.length > 0){
            formRefs.current.length = 0;
        }

        if(el !== null && formRefs.current.length === 0){
            formRefs.current.push(el);

        } else if(el !== null) {
            const found = formRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                formRefs.current.push(el);
            }

        }
    }

    type Ref = {
        sfgemcRef:React.RefObject<HTMLDivElement>
    };

    function getSfgErrorMsgRef(el: Ref|null):void {
        if(sfgErrorMsgRefs.current.length > 0){
            sfgErrorMsgRefs.current.length = 0;
        }

        if(el !== null && sfgErrorMsgRefs.current.length === 0){
            sfgErrorMsgRefs.current.push(el);

        } else if(el !== null) {
            const found = sfgErrorMsgRefs.current.find((ir)=>{
                                return ir.sfgemcRef === el.sfgemcRef;
                            });

            if(typeof found === 'undefined'){
                sfgErrorMsgRefs.current.push(el);
            }

        }

        //console.log('sfgErrorMsgRefs:', sfgErrorMsgRefs.current);
    }

    function getLoaderRef(el: HTMLDivElement|null):void {
        if(loaderRefs.current.length > 0){
            loaderRefs.current.length = 0;
        }

        if(el !== null && loaderRefs.current.length === 0){
            loaderRefs.current.push(el);

        } else if(el !== null) {
            const found = loaderRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                loaderRefs.current.push(el);
            }

        }

        //console.log('loaderRefs:', loaderRefs);

        if(typeof loaderRefs.current[0] !== 'undefined'){
            const yValue = formRefs.current[0].getBoundingClientRect().bottom + 66;
            loaderRefs.current[0].style.transform = `translate(-50%, ${yValue}px)`;
        }


    }

    
    function getAdminProductReviewsContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && adminProductReviewsContainerRefs.current.length === 0){
            adminProductReviewsContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = adminProductReviewsContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                adminProductReviewsContainerRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', adminProductReviewsContainerRefs.current);
    }

    function sortItemsByDateSubmitted(sortDirection:string):void {
        if(sortDirection === 'asc'){
            //console.log('sort29');
            const prs = productReviews.sort(compareAscendingDates);
            setProductReviews(prs);
            getAnyItemList(listChangerBtnPosition, prs, itemsPerList, showItemsPerList);

        } else {
            //console.log('sort29');
            const prs = productReviews.sort(compareDescendingDates);
            setProductReviews(prs);
            getAnyItemList(listChangerBtnPosition, prs, itemsPerList, showItemsPerList);
        }

    }

    function compareDescendingDates(a:Review, b:Review):number {
        const aDate = new Date(a.submitted).getTime();
        const bDate = new Date(b.submitted).getTime();

        return bDate - aDate;
    }

    function compareAscendingDates(a:Review, b:Review):number {
        const aDate = new Date(a.submitted).getTime();
        const bDate = new Date(b.submitted).getTime();

        return aDate - bDate;
    }


  
    function setupProductReviewsTable(itemsList:object[]):JSX.Element {
        return   <div className="aprs-table-container">
                    <table className="aprs-table">
                        <thead>
                            <tr>
                                {setupProductReviewsTableHeadings()}
                            </tr>
                        </thead>
                        <tbody>
                            {setupProductReviewsTableRows(itemsList as Review[])}
                        </tbody>
                    </table>
                </div>
    }


    function setupProductReviewsTableHeadings():JSX.Element[]{
        const tableHeadings:string[] = ['Username', 'Title','Rating','Approved', 'Product', 'Submitted', 'Action'];
        return tableHeadings.map((tableHeading, index)=>{
                if(tableHeading === 'Submitted'){
                    return <th key={index}>
                                <div className="admin-date-added-header-container">
                                    <span>{tableHeading}</span><span className="admin-date-added-svg-btn-container">
                                        <button type="button" aria-label="ascending date order" className="admin-date-added-svg-btn"
                                            onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('asc')}>
                                            <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                <path className="sort-up" d="M200 100 L100 0 L0 100 Z" fill={"#707070"}></path>
                                            </svg>
                                        </button>
                                        <button type="button" aria-label="descending date order" className="admin-date-added-svg-btn"
                                            onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('desc')}>
                                            <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                <path className="sort-down" d="M200 0 L100 100 L0 0 Z" fill={"#707070"}></path>
                                            </svg>
                                        </button>
                                    </span>
                                 </div>
                            </th>;
                } else {
                    return <th key={index}>{tableHeading}</th>;
                }
            
        });
    }

    function setupProductReviewsTableData(productReview:Review):JSX.Element[]{
        //console.log('productReview test:', productReview);
        return Object.entries(productReview)
                    .filter(([key, value])=>{
                        switch(key){
                            case 'productName':
                            case 'rating':
                            case 'title':
                            case 'userName':
                            case 'approved':
                            case 'submitted':
                                return true;
                            default:
                                return false;
                        }
                    })
                    .map(([key, value], index)=>{
                        //console.log('index:', index, value);
                        if(key === 'submitted'){
                            return <td key={index}><span className="">{transformDate(value)}</span></td>
                        } else if(key === 'approved'){
                            return <td key={index}><span className="aprs-td">{value ? 'Yes' : 'No'}</span></td>
                        }else {
                            return <td key={index}><span className="aprs-td">{value}</span></td>
                        }
                        
                    })
                    .concat(setupActionBtns(productReview));
    }


    function setupActionBtns(productReview:Review):JSX.Element {
        if(productReview.approved){
            return <td key={6} className="td-action">
                        <div className="table-item-btn-container">
                            <button type="button" className="edit-table-item-btn" onClick={(e:React.MouseEvent)=>{
                                handleApproveProductReview(productReview)
                            }}>Edit
                            </button>
                            <button type="button" className="remove-table-item-btn" onClick={(e:React.MouseEvent)=>{
                                removeDatebaseProductReview(productReview.reviewId)
                            }}>Delete
                            </button>
                        </div>
                    </td>

        } else {
            return <td key={6} className="td-action">
                        <div className="table-item-btn-container">
                            <button type="button" className="select-table-item-btn" onClick={(e:React.MouseEvent)=>{
                                handleApproveProductReview(productReview)
                            }}>select
                            </button>
                        </div>
                    </td>
        }
    }


    function removeDatebaseProductReview(productReviewId:number):void {
        //reset form errors
        prKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        setShowKeywordSearchLimitError(false);


        //original version with .json file
        //fetch(`http://localhost:9500/delete-product-review/${JSON.stringify(productReview)}`,{
        //test version with mariadb
        fetch(`https://server.kando-proto-3.com/delete-product-review-mariadb/${productReviewId.toString()}`,{
            method:'DELETE',
            headers:{
                'Accept':'application/json'
            }
        })
        .then(res=>{

            setIsLoadingDelete(true);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            return res.json();
        })
        .then(({dbResponseMsg}:DbResponse)=>{
            if(dbResponseMsg === 'record deleted'){
                /*
                getNotApprovedProductReviews()
                .then(res => res.json())
                .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{            
                    if(dbResponseMsg === 'record found'){
                        const prs = addReviews(dbResponseRecords as Review[]);
                        //setShowProductReviewLoadingMsg(false);
                        console.log('lcbp:', listChangerBtnPosition);
                        setProductReviews(addReviews(prs));
                        getAnyItemList(listChangerBtnPosition, prs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, prs);
                        //setListViewerHeading('Reviews awaiting approval');
                        setListViewerHeading('');
                        setShowApproveRejectProductReview(false);
                        setProductReviewSearchText('');
                        setIsLoading(false);
        
                        //adjust item count based on items per list setting
                        //previous version
                        let nrh = 0;
                        if(itemsPerList < prs.length){
                            nrh = (itemsPerList * 94) + 736 + 16 + 57;
        
                        } else {
                            nrh = (prs.length) * 94 + 736 + 16 + 57;
                        }
        
                        if(nrh > 900){
                            adminProductReviewsContainerRefs.current[0].style.minHeight = `${nrh}px`;
                        }
                        
                        
        
                    } else {
                        //setShowProductReviewLoadingMsg(false);
                        setNoSearchResultsMessage('No product reviews to review.');
                        setShowNoSearchResultsFound(true);
                        setShowInfoMessage(true);
                        setProductReviews([]);
                        //setListViewerHeading('Reviews awaiting approval');
                        setListViewerHeading('');
                        setShowApproveRejectProductReview(false);
                        setProductReviewSearchText('');
                        setIsLoading(false);
                    }
        
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);
                    
                })
                .catch(err=>{
                    console.log('error:', err);
                    setShowErrorMessage(true);
                    setProductReviewSearchText('');
                    setIsLoading(false);
                });
                */

                const uprs = productReviews.filter((pr)=>{
                                                return pr.reviewId !== productReviewId;
                                            });

                //console.log('uprs:', uprs);

                if(uprs.length === 0){
                    setNoSearchResultsMessage(`All approved product reviews have been deleted.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setProductReviews([]);
                    //setListViewerHeading('Reviews awaiting approval');
                    setListViewerHeading('');
                    setShowApproveRejectProductReview(false);
                    setProductReviewSearchText('');
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);


                } else {
                    //setShowProductReviewLoadingMsg(false);
                    setProductReviews(addReviews(uprs));

                    //console.log('r15:', (uprs.length / itemsPerList), listChangerBtnPosition);

                    if((uprs.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, uprs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, uprs);

                    } else {
                        getAnyItemList(listChangerBtnPosition, uprs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, uprs);
                    }


                    //setListViewerHeading('Reviews awaiting approval');
                    setListViewerHeading('');
                    setShowApproveRejectProductReview(false);
                    setProductReviewSearchText('');
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                }

            }   

            
        })
        .catch(err=>console.log('error:', err));
    }

    function setupProductReviewsTableRows(productReviews:Review[]):JSX.Element[]{
        //console.log('prs:', productReviews);
        return productReviews.map((productReview, index)=>{
            return <tr key={index}>
                        {setupProductReviewsTableData(productReview)}
                    </tr>;
        });
        
    }

    function handleApproveProductReview(productReview:Review):void {
        setShowKeywordSearchLimitError(false);

        setProductReviewSelected(productReview);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setProductReviewSearchText('');
        setShowApproveRejectProductReview(true);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        adminProductReviewsContainerRefs.current[0].style.minHeight = `950px`;

    }

    function handleHideApproveRejectProductReview():void {
        //console.log('handleHideApproveRejectProductReview');
        setShowApproveRejectProductReview(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    }

    function handleApproveRejectProductReviewSubmit(updatedProductReviews:Review[], action:string):void {
        //console.log('handleApproveRejectProductReviewSubmit:', updatedProductReviews);
        //setShowApproveRejectProductReview(false);
        const upr = updatedProductReviews[0];

        if(action === 'updated'){
            //product review was updated
            const prs = productReviews.map((pr)=>{
                    if(pr.reviewId === upr.reviewId){
                        return upr;

                    } else {
                        return pr;
                    }
                });


            getAnyItemList(listChangerBtnPosition, prs, itemsPerList, showItemsPerList);
            displayListSorter(itemsPerList, prs);
            //setListViewerHeading('Reviews awaiting approval');
            setListViewerHeading('');
            setProductReviews(prs);
            setShowApproveRejectProductReview(false);
            setIsLoading(false);

        } else {
            //product review was approved or rejected
            //use filter to get update review list
            const prs = productReviews.filter((pr)=>{
                                            return pr.reviewId !== upr.reviewId;
                                        });

            //console.log('r15:', (prs.length / itemsPerList), listChangerBtnPosition);
            if(prs.length === 0){
                setNoSearchResultsMessage(`No product reviews to review.`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setProductReviews([]);

                setListViewerHeading('');
                setProductReviews(prs);
                setShowApproveRejectProductReview(false);
                setIsLoading(false);

            } else {

                if(listChangerBtnPosition > 0 && (prs.length / itemsPerList) === listChangerBtnPosition){
                    const lcbp = listChangerBtnPosition - 1;
                    //console.log('lcbp:', lcbp);
                    setListChangerBtnPosition(lcbp);
                    getAnyItemList(lcbp, prs, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, prs);

                } else {
                    getAnyItemList(listChangerBtnPosition, prs, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, prs);
                }  


                //setListViewerHeading('Reviews awaiting approval');
                setListViewerHeading('');
                setProductReviews(prs);
                setShowApproveRejectProductReview(false);
                setIsLoading(false);

            }
        }


        /*
        setProductReviews(updatedProductReviews);
        setShowApproveRejectProductReview(false);
        getNotApprovedProductReviewsList();
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    }

    function handleCloseFormSubmit(e:React.MouseEvent):void {
        //getNotApprovedProductReviewsList();
        setShowApproveRejectProductReview(false);
    }

    function handleOptionNameSelection(e:React.ChangeEvent):void {
        const prrnSelected = (e.target as HTMLSelectElement).value;
        setProductReviewRatingNameSelected(prrnSelected);
    }

    function handleApprovedProductReviewOptionSelected(e:React.ChangeEvent):void {
        const proSelected = (e.target as HTMLSelectElement).value;
        setApprovedProductReviewOptionSelected(proSelected);
    }

    function handleResultsPerPageOptionSelected(e:React.ChangeEvent):void {
        const rppoSelected = (e.target as HTMLSelectElement).value;
        setResultsPerPageOptionSelected(rppoSelected);
        const ipp = parseInt(rppoSelected, 10);

        const previpp = itemsPerList;
        const previousPositionInList = (previpp * (listChangerBtnPosition + 1)) - previpp + 1;
        const startPointInList = Math.floor(previousPositionInList / ipp);
        //console.log('cbp:', startPointInList, previousPositionInList / ipp, listChangerBtnPosition);

        if(ipp > productReviews.length){
            setItemsPerList(ipp);
            setListChangerBtnPosition(0);
            getAnyItemList(0, productReviews, ipp, showItemsPerList);

        } else {

            if(listChangerBtnPosition === 0){
                setItemsPerList(ipp);
                setListChangerBtnPosition(0);
                getAnyItemList(0, productReviews, ipp, showItemsPerList);

            } else {
                setItemsPerList(ipp);
                setListChangerBtnPosition(startPointInList - 1);
                getAnyItemList(startPointInList - 1, productReviews, ipp, showItemsPerList);
            }

        }

        displayListSorter(ipp, productReviews);

        //adjust item count based on items per list setting
        let nrh = 0;
        if(ipp < productReviews.length){
            nrh = (ipp * 94) + 736 + 16 + 57;

        } else {
            nrh = (productReviews.length) * 94 + 736 + 16 + 57;
        }

        if(nrh > 950){
            adminProductReviewsContainerRefs.current[0].style.minHeight = `${nrh}px`;
        }


    }


    function showItemsPerList(itemsList:object[]):void {
        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as Review[]);


        //adjust item count based on items per list setting
        let nrh = 0;
        if(itemsPerList < itemsList.length){
            nrh = (itemsPerList * 94) + 736 + 16 + 57;

        } else {
            nrh = (itemsList.length) * 94 + 736 + 16 + 57;
        }

        const mh = 950;
        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
            adminProductReviewsContainerRefs.current[0].style.minHeight = `${mh}px`;
        }

        if(existingSearchResultsHeight.current === 0 && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh < mh){
            existingSearchResultsHeight.current = mh;

            adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh > existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }
        //console.log('esrs:', existingSearchResultsHeight.current);

    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100); 

    }

    function displayListSorter(itemsPerList:number, rs:Review[]):void {
        if(rs.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleProductReviewSearchText(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        //console.log('value.length:', target.value.length);

        if(target.value.length <= 50){
            setProductReviewSearchText(target.value);
            setShowKeywordSearchLimitError(false);

        } else {
            setShowKeywordSearchLimitError(true);
        }
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowInfoMessage(false);
    }

    function resetProductReviews():void {
        //reset form errors
        prKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        setShowKeywordSearchLimitError(false);

        setProductReviews([]);
        setProductReviewSearchText('');
        setProductReviewRatingNameSelected('all');
        setApprovedProductReviewOptionSelected('no');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);

        setIsLoading(true);

        setItemsList(null);
        setListChangerBtnPosition(0);

        getNotApprovedProductReviewsList(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        adminProductReviewsContainerRefs.current[0].style.minHeight = `950px`;

    }


    function handleSubmitProductReviewSearch(e:React.FormEvent):void {
        e.preventDefault();

        const form = e.target as HTMLFormElement;

        //reset form errors
        prKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //product info
        //product name selected
        const prst = productReviewSearchText;
        if(prst.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Product Review Keyword'});

        } else {
            setShowKeywordSearchLimitError(false);
        }

        //pricing info
        //department category image
        /*
        const pp = productPrice;
        if(pp.length === 0 || parseInt(pp, 10) === 0){
            formError.pricingErrors.push({hasError:true, area:'Price'});
        }
        */

        const prsts = formError.productInfoErrors.length > 0 ? true : false;
        //const pps = formError.pricingErrors.length > 0 ? true : false;

        if(prsts /*|| pps*/){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }
      
        
        //setShowLoadingSearchResults(true);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setItemsList(null);
       
        

        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);

        const searchText = productReviewSearchText;
        
        if(searchText.length > 0){
            let searchString = '';

            for(let i=0; i < searchText.length; i++){
                if(/[\(\)\[\]\*\$\^\{\}\?\_\+\.\"\'\\|]/.test(searchText[i])){
                    searchString += `\\${searchText[i]}`;

                } else {
                    searchString += `${searchText[i]}`;
                }
            }

            //console.log('str26:', searchString);
            //searchString = '\\(-\\_\'\"\"\\]\\\\//\\?what\\^\\$@1';  

            //const st = {searchText:searchString};
            
            //const params = JSON.stringify(st);

            const approvedOption = approvedProductReviewOptionSelected;
            const productReviewRating = productReviewRatingNameSelected;
            const productReviewSearch = {searchString, productReviewRating, approvedOption};

            setTemporaryClearSearchResults(true);

            fetch(`https://server.kando-proto-3.com/product-review-search`, {
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:JSON.stringify(productReviewSearch)
            })
            .then((res)=>{

                setIsLoadingDelete(true);

                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);

                return res.json();
            })
            .then((result)=>{
                //console.log('aprcrs:', result);

                if(result.length === 1 && result[0].source === null && result[0].productReviews === null){
                    //setShowLoadingSearchResults(false);
                    setNoSearchResultsMessage('No product reviews matched those search parameters.');
                    setShowNoSearchResultsFound(true);
                    setProductReviews([]);
                    setShowInfoMessage(true);

                    setTemporaryClearSearchResults(false);

                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);

                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    adminProductReviewsContainerRefs.current[0].style.minHeight = `950px`;

                } else {
                    
                    //console.log('res 11-27:', result);            
                    const prs = addReviews(result);
                    //console.log('prs:', prs);
                    setProductReviews(prs);
                    //setListViewerHeading('Approved product reviews');
                    setListViewerHeading('');
                    setListChangerBtnPosition(0);
                    getAnyItemList(0, prs, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, prs);

                    setTemporaryClearSearchResults(false);
                    //setShowLoadingSearchResults(false);
                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);

                    //adjust item count based on items per list setting
                    let nrh = 0;
                    if(itemsPerList < prs.length){
                        nrh = (itemsPerList * 94) + 736 + 16 + 57;

                    } else {
                        nrh = (prs.length) * 94 + 736 + 16 + 57;
                    }

                    const mh = 950;
                    if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                        adminProductReviewsContainerRefs.current[0].style.minHeight = `${mh}px`;
                    }

                    if(existingSearchResultsHeight.current === 0 && nrh > mh){
                        existingSearchResultsHeight.current = nrh;

                        adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }

                    if(nrh < existingSearchResultsHeight.current && nrh > mh){
                        existingSearchResultsHeight.current = nrh;

                        adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }

                    if(nrh < existingSearchResultsHeight.current && nrh < mh){
                        existingSearchResultsHeight.current = mh;

                        adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }

                    if(nrh > existingSearchResultsHeight.current && nrh > mh){
                        existingSearchResultsHeight.current = nrh;

                        adminProductReviewsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }
                    //console.log('esrs:', existingSearchResultsHeight.current);

                    
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);
                    
                }
                
            })
            .catch((err)=>{
                console.error('product review search error:', err.message);
                setShowErrorMessage(true);
                window.clearTimeout(isLoadingTimeoutRef.current);
                setIsLoading(false);
                setIsLoadingDelete(false);
    
            });

        } else {
            //console.log('no results found.');
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
        }
        


    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Product Review Keyword':
                                prKeywordRefs.current[0]!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no product review keyword info errors found!');
                        }
                    }
                });


        //set image errors
        /*
        formError.pricingErrors.forEach((pe)=>{
            console.log('pe:', pe);
            if(pe.hasError && pe.area === 'Price'){
                console.log('pe.area:', pe.area);
                productPriceRef.current!.classList.add('pricing-error');
            } else {
                throw new Error('no price errors found!');
            }
        });
        */
    }


    {/*
    if(!isLoading)*/}
    
    return <div className="admin-product-reviews-wrapper">             
                    <div ref={getAdminProductReviewsContainerRef} className="admin-product-reviews-container">
                        <ul className="store-details-breadcrum-container">
                            <li className="aprbi">
                                <Link to="/admin" className="store-details-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="aprbi store-details-breadcrum-link-store-name">Product Reviews</li>
                        </ul>


                        {
                            !showApproveRejectProductReview
                            ?
                            <h1 className="admin-product-reviews-heading">Product Reviews</h1>    
                            :
                            null
                        }
                        

                        {
                            !showApproveRejectProductReview
                            ?
                            <div className="">
                                <div className="admin-pr-reset-prs-btn-container">
                                    <button type="button" className="admin-pr-reset-items-btn" onClick={resetProductReviews}>Reset Reviews</button>
                                </div>
                                <form ref={getFormRef} className="admin-search-form" onSubmit={handleSubmitProductReviewSearch}>
                                    <fieldset className="admin-search-box-container">
                                        <label className="admin-search-box-label" htmlFor="search-box">Keywords</label>
                                        <span className="admin-search-secondary-label">(Enter product, product category, department category or department)</span>
                                        <div className="table-keyword-search-layout-wrapper">
                                            <input id="search-box" className="admin-search-box" type="text"
                                                ref={getPRKeywordRef}
                                                placeholder={"Enter product, product category, department category or department"}
                                                value={productReviewSearchText} onChange={handleProductReviewSearchText}/>
                                            {
                                                showKeywordSearchLimitError
                                                ?
                                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                                :
                                                null
                                            }
                                        </div>
                                    </fieldset>
                                    <fieldset className="admin-rating-container">
                                        <label className="admin-rating-selector-label" htmlFor="rating">Rating</label>
                                        <div className="admin-rating-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName} optionNameSelected={productReviewRatingNameSelected}
                                                optionNames={productReviewRatingNames} showErrorMsg={false} errorMsg={'none'} selectControlId={"rating"}
                                                handleOptionNameSelection={handleOptionNameSelection}/>
                                        </div>
                                    </fieldset>
                                    <fieldset className="admin-rating-container">
                                        <label className="admin-rating-selector-label" htmlFor="approved">Approved</label>
                                        <div className="admin-rating-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName2} optionNameSelected={approvedProductReviewOptionSelected}
                                                optionNames={approvedOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"approved"}
                                                handleOptionNameSelection={handleApprovedProductReviewOptionSelected}/>
                                        </div>
                                    </fieldset>
                                    <fieldset className="admin-search-form-btn-container">
                                        <button type="submit" className="admin-search-form-btn">Search</button>
                                    </fieldset>
                                    <FormErrorMessage ref={getSfgErrorMsgRef} formError={formError} />
                                </form>
                            </div>
                            :
                            null
                        }

                        {
                            !isLoading && showApproveRejectProductReview
                            ? 
                            <ApproveRejectProductReview productReview={productReviewSelected as Review} 
                                showProductReview={showApproveRejectProductReview}
                                handleHideProductReview={handleHideApproveRejectProductReview} 
                                handleProductReviewSubmit={handleApproveRejectProductReviewSubmit}
                                handleCloseFormSubmit={handleCloseFormSubmit}/>
                            :
                            null
                        }
                        {
                            !isLoading && showNoSearchResultsFound && showInfoMessage && !isLoadingDelete
                            ? 
                            <div className="admin-list-wrapper">
                                <div className="admin-list-total-continer">
                                    <span className="admin-list-total">{productReviews.length} results</span>
                                    <div className="admin-results-per-page-container">
                                        <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                        <div className="admin-results-per-page-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                                optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                                cssStyles={{minWidth:50, backgroundPosition:5}}
                                                handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="pr-generic-info-message-container">
                                    {noSearchResultsMessage}
                                    {/*<GenericInfoMessage infoMessage={noSearchResultsMessage}/>*/}
                                </div>
                            </div>
                            :
                            null
                        }

                        {
                            !isLoading && productReviews.length > 0 && !showApproveRejectProductReview && !showNoSearchResultsFound && !isLoadingDelete
                            ?
                            <div className="admin-list-wrapper">
                                <div className="admin-list-total-continer">
                                    {
                                        temporaryClearSearchResults
                                        ?
                                        <span className="admin-list-total"></span>
                                        :
                                        <span className="admin-list-total">{(productReviews.length === 1) ? `${productReviews.length} result` : `${productReviews.length} results`}</span>
                                    }
                                    
                                    <div className="admin-results-per-page-container">
                                        <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                        <div className="admin-results-per-page-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                                optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                                cssStyles={{minWidth:50, backgroundPosition:5}}
                                                handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                        </div>
                                    </div>
                                </div>

                                {
                                    !showErrorMessage
                                    ?
                                    <ListViewer itemsList={itemsList} items={productReviews} showListSorter={showListSorter}
                                        listChangerBtnPosition={listChangerBtnPosition}
                                        listViewerHeading={listViewerHeading}
                                        itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                        getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupProductReviewsTable} 
                                    />
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }


                        {
                            showErrorMessage
                            ?
                            <div className="aprs-generic-error-msg-wrapper">
                                <GenericErrorMessage/>
                            </div>
                            :
                            null
                        }

                        {
                            isLoading && !showErrorMessage && !isLoadingDelete
                            ?
                            <div ref={getLoaderRef} className="aprs-loader2-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null                      
                        }

                        {
                            isLoadingDelete && !showErrorMessage
                            ?
                            <div className="aprs-loader2-animation-container">
                                {/*<LoaderAnimation/>*/}
                                {
                                    isLoading
                                    ?
                                    <LoaderAnimation/>
                                    :
                                    null
                                }
                            </div>
                            :
                            null                      
                        }

                        {
                            showProcessingRequestMsg
                            ?
                            <div className="process-request-msg-fixed">
                                Processing...
                            </div>
                            :
                            null
                        }

                    </div>

                    {
                        showFooter
                        ?
                        <Footer/>
                        :
                        null
                    }
                    

            </div>
    {/*}
    } else {
        return <div className="aprs-loaeder-animation-container">
                    <LoaderAnimation/>
                </div>
    }
    */}




}

export default AdminProductReviews2;