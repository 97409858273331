import DbResponse from './db-response';
import {SearchTerm} from './searchFilter';

function isSearchTermUnique(searchTerm:SearchTerm):Promise<DbResponse> {        
    const st = JSON.stringify(searchTerm);
    //console.log('st19:', st);
    //original version
    //return fetch(`http://localhost:9500/add-search-term-only/${st}`, {
    return fetch(`https://server.kando-proto-3.com/add-search-term-only`, {
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:st
            })
            .then((resp)=>resp.json())
            .then((dbResponse)=>{
                //console.log('dbr:', dbResponse);
                return dbResponse;
            })
            .catch((err)=>console.error('is search term unique fetch error:', err));
}

export default isSearchTermUnique;