import HomePageLayoutItem from './homePageLayoutItem';
import HomePageLayoutType from './homePageLayoutType';

class HomePageLayout {
    public homePageLayoutId:number;
    public homePageLayoutOrder:number;
    public homePageLayoutType:string;
    public heading:string;
    public caption:string;
    public distance:number;
    //public headingPositionDesktop:string;
    //public headingPositionMobile:string;
    public items:HomePageLayoutItem[];
    public homePageLayoutAdded:Date;

    constructor(options:{
        homePageLayoutId?:number,
        homePageLayoutOrder?:number,
        homePageLayoutType?:string,
        heading?:string,
        caption?:string,
        distance?:number,
        //headingPositionDesktop?:string,
        //headingPositionMobile?:string,
        items?:HomePageLayoutItem[],
        homePageLayoutAdded?:Date

    }={}){
        this.homePageLayoutId = options.homePageLayoutId || 0;
        this.homePageLayoutOrder = options.homePageLayoutOrder || 0;
        this.homePageLayoutType = options.homePageLayoutType || 'slider3';
        this.heading = options.heading || 'none';
        this.caption = options.caption || 'none';
        this.distance = options.distance || 0;
        //this.headingPositionDesktop = options.headingPositionDesktop = 'top-center';
        //this.headingPositionMobile = options.headingPositionMobile = 'top-center';
        this.items = options.items || [];
        this.homePageLayoutAdded = options.homePageLayoutAdded || new Date();
    }
}

export default HomePageLayout;