import ProductCategory from './productCategory';
import SearchFilter from './searchFilter';

export default class DepartmentCategory {
    departmentCategoryId:number;
    departmentId:number;
    departmentCategoryName:string;
    departmentCategoryLinkName:string;
    departmentName:string;
    departmentCategoryImage:string;
    productCategories:ProductCategory[];
    isSpecialDepartmentCategory:boolean;
    showProductCategoryShortcut:boolean;
    showProductCategorySidebar:boolean;
    showProductCategories:boolean;
    highlightText:boolean;
    searchFilters:SearchFilter[];
    departmentCategoryDateAdded:Date;

    constructor(options:{
        departmentCategoryId?:number,
        departmentId?:number;
        departmentCategoryName?:string,
        departmentCategoryLinkName?:string,
        departmentName?:string,
        departmentCategoryImage?:string,
        productCategories?:ProductCategory[],
        isSpecialDepartmentCategory?:boolean;
        showProductCategoryShortcut?:boolean,
        showProductCategorySidebar?:boolean,
        showProductCategories?:boolean,
        highlightText?:boolean,
        searchFilters?:SearchFilter[],
        departmentCategoryDateAdded?:Date;
    }={}){
        this.departmentCategoryId = options.departmentCategoryId || 0;
        this.departmentId = options.departmentId || 0;
        this.departmentCategoryName = options.departmentCategoryName || '';
        this.departmentCategoryLinkName = options.departmentCategoryLinkName || '';
        this.departmentName = options.departmentName || '';
        this.departmentCategoryImage = options.departmentCategoryImage || '';
        this.productCategories = options.productCategories || [];
        this.isSpecialDepartmentCategory = options.isSpecialDepartmentCategory || false;
        this.showProductCategoryShortcut = options.showProductCategoryShortcut || false;
        this.showProductCategorySidebar = options.showProductCategorySidebar || false;
        this.showProductCategories = options.showProductCategories || false;
        this.highlightText = options.highlightText || false;
        this.searchFilters = options.searchFilters || [];
        this.departmentCategoryDateAdded = options.departmentCategoryDateAdded || new Date();
    }
}