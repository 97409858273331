import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import Footer from './Footer';
import './PageNotFound.css';


interface Props {
    showNavSearch?:boolean;
    showFooter?:boolean;
}

const PageNotFound: React.FunctionComponent<Props> = (props:Props) => {
    const {showNavSearch, showFooter} = props;
    
    return <div className="page-not-found-wrapper">
                {
                    showNavSearch
                    ?
                    <div>
                        <ProductSearch/>
                        <MainNavbar2/>
                    </div>
                    :
                    null
                }


                <div className="page-not-found-display-wrapper">
                    <ul className="store-details-breadcrum-container">
                        <li className="abi">
                            <Link to="/" className="store-details-breadcrum-link">Home</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="abi store-details-breadcrum-link-store-name">Page Not Found</li>
                    </ul>
                    <div className="page-not-found-heading-container">
                        <h1 className="page-not-found-heading">Page Not Found</h1>
                    </div>
                    <div className="page-not-found-text-container">
                        <p className="page-not-found-text">The page you are requesting cannot be found.</p>
                    </div>
                </div>

                {
                    showFooter
                    ?
                    <Footer/>
                    :
                    null
                }
            </div>
}

export default PageNotFound;