import React, {useState, useLayoutEffect, useEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import StoreDepartment from './storeDepartment';
import AddStoreDepartment from './AddStoreDepartment';
import EditStoreDepartment from './EditStoreDepartment';
import ShopCategory from './shopCategory';
import SubShopCategory from './subShopCategory';
import {connect} from 'react-redux';
//import {AddProductShopCategories, addProductShopCategories} from './redux/actions';
import {selectProductShopCategories, addProductShopCategories} from './redux/features/productShopCategories/productShopCategoriesSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import FormErrorMessage, {FormError} from './FormErrorMessage';
import LoaderAnimation from './LoaderAnimation';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import transformDate from './helper-transform-date';
import loadNavLink from './loadNavLinkHelper';
import Footer from './Footer';
import './StoreStructureDepartments.css';
import GenericErrorMessage from './GenericErrorMessage';


interface DepartmentSource {
    source:string;
    department:StoreDepartment;
}


interface Props {
    //path:string;
}


const resultsPerPage = ['5', '10', '20', '30'];

const StoreStructureDepartments2: React.FunctionComponent<Props> = (props:Props) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [showAddDepartment, setShowAddDepartment] = useState(false);
    const [showEditDepartment, setShowEditDepartment] = useState(false);
    const [departments, setDepartments] = useState<StoreDepartment[]>([]);
    const [departmentToEdit, setDepartmentToEdit] = useState<StoreDepartment|null>(null);
    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    //search properties
    const [departmentNameSearchText, setDepartmentNameSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No departments have been added.');

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(10);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<StoreDepartment[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [elementName3, setElementName3] = useState('results-per-page');
    const [resultsPerPageOptionSelected, setResultsPerPageOptionSelected] = useState('10');
    const [resultsPerPageOptions, setResultsPerPageOptions] = useState<string[]>(resultsPerPage);

    const [isLoading, setIsLoading] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const storeStructureDeptsContainerRefs = useRef<HTMLDivElement[]>([]);

    const departmentKeywordRef = useRef<HTMLInputElement>(null);

    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });

    //const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);

    const sfgErrorMsgRefs = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}[]>([]);

    const existingSearchResultsHeight = useRef<number>(0);

    const [pagePosition, setPagePosition] = useState<number>(0);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [showKeywordSearchLimitError, setShowKeywordSearchLimitError] = useState(false);

    const [temporaryClearSearchResults, setTemporaryClearSearchResults] = useState(false);

    const formRefs = useRef<HTMLFormElement[]>([]);

    const [showFooter, setShowFooter] = useState(false);

    const loaderRefs = useRef<HTMLDivElement[]>([]);

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const isLoadingTimeoutRef = useRef<number>(0);


    function getStoreStructureDeptsContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && storeStructureDeptsContainerRefs.current.length === 0){
            storeStructureDeptsContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = storeStructureDeptsContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                storeStructureDeptsContainerRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', storeStructureDeptsContainerRefs.current);
    }


    useEffect(()=>{

        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);

        //window.scrollTo(0,0);
        getAllDepartments();
        
    },[]);

    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();

        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                //target.src = `http://server.test_app3/${src}`;
                target.src = `${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        //console.log('fr7:', formRefs.current[0]);
        if(typeof sfgErrorMsgRefs.current![0] !== 'undefined'){
            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
        }

    }

    function getFormRef(el:HTMLFormElement|null):void {
        if(formRefs.current.length > 0){
            formRefs.current.length = 0;
        }

        if(el !== null && formRefs.current.length === 0){
            formRefs.current.push(el);

        } else if(el !== null) {
            const found = formRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                formRefs.current.push(el);
            }

        }
    }

    type Ref = {
        sfgemcRef:React.RefObject<HTMLDivElement>
    };

    function getSfgErrorMsgRef(el: Ref|null):void {
        if(sfgErrorMsgRefs.current.length > 0){
            sfgErrorMsgRefs.current.length = 0;
        }

        if(el !== null && sfgErrorMsgRefs.current.length === 0){
            sfgErrorMsgRefs.current.push(el);

        } else if(el !== null) {
            const found = sfgErrorMsgRefs.current.find((ir)=>{
                                return ir.sfgemcRef === el.sfgemcRef;
                            });

            if(typeof found === 'undefined'){
                sfgErrorMsgRefs.current.push(el);
            }

        }

        //console.log('sfgErrorMsgRefs:', sfgErrorMsgRefs.current);
    }

    function getLoaderRef(el: HTMLDivElement|null):void {
        if(loaderRefs.current.length > 0){
            loaderRefs.current.length = 0;
        }

        if(el !== null && loaderRefs.current.length === 0){
            loaderRefs.current.push(el);

        } else if(el !== null) {
            const found = loaderRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                loaderRefs.current.push(el);
            }

        }

        //console.log('loaderRefs:', loaderRefs);

        if(typeof loaderRefs.current[0] !== 'undefined'){
            const yValue = formRefs.current[0].getBoundingClientRect().bottom + 66;
            loaderRefs.current[0].style.transform = `translate(-50%, ${yValue}px)`;
        }


    }

    function getAllDepartments(lcbPosition?:number):void {
        fetch(`https://server.kando-proto-3.com/get-all-departments-mariadb`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then((departments)=>{
            //console.log('departments 5-8:', departments);
            if(departments.length === 0){
                //show generic info message if no department categories are found
                setNoSearchResultsMessage(`No departments have been added`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoading(false);

                setShowFooter(true);

            } else {
                //use this promise when using base64 img
                Promise.all(addDepartmentImages(departments))
                .then((res)=>{
                    //console.log('res 6-3:', res);
                    const result = res.map((r)=>{
                                    return r.department
                                    });
                    //console.log('result:', result);

                    /*original version
                    const pscs = addProductSubCategories(productSubCategories);
                    setProductSubCategories(pscs);
                    */

                    const ds = addDepartments(res);
                    setDepartments(ds);
                    getAnyItemList(typeof lcbPosition !== 'undefined' ? lcbPosition : listChangerBtnPosition, ds, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, ds);
                    setIsLoading(false);

                    setShowFooter(true);

                    //adjust item count based on items per list setting
                    //previous version
                    let nrh = 0;
                    if(itemsPerList < ds.length){
                        nrh = (itemsPerList * 94) + 588 + 16 + 57;

                    } else {
                        nrh = (ds.length) * 94 + 588 + 16 + 57;
                    }
                   

                    if(nrh > 950){
                        storeStructureDeptsContainerRefs.current[0].style.minHeight = `${nrh}px`;
                    }
                    
                    
            
                })
                .catch((err)=>{
                    //console.log('add department images error:', err.message);
                    setIsLoading(false);
                    setShowErrorMessage(true);

                    setShowFooter(true);
                });
            }


        })
        .catch((err)=>{
            //console.log('no department found fetch error:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);

            setShowFooter(true);
        });
    }

    function addDepartmentImages(ds:DepartmentSource[]):Promise<DepartmentSource>[] {
        return ds.map((d)=>{
            //console.log('d:', d);
            return new Promise((resolve, reject)=>{
                d.department.departmentImage.length === 0 
                ? resolve(d)
                : addDepartmentImage(d, resolve);

            });
        });

    }

    function addDepartments(ds:DepartmentSource[]):StoreDepartment[] {
        return ds.map((d)=>{
                    return new StoreDepartment({...d.department});
                });
    }

    function resetDepartments():void {
        //reset form errors
        departmentKeywordRef.current!.classList.remove('product-info-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        setShowKeywordSearchLimitError(false);

        setDepartments([]);
        setDepartmentNameSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(true);

        setItemsList(null);
        setListChangerBtnPosition(0);

        getAllDepartments(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        storeStructureDeptsContainerRefs.current[0].style.minHeight = '950px';
        
    }


    function handleAddDepartment(e:React.MouseEvent):void {
        setShowKeywordSearchLimitError(false);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setDepartmentNameSearchText('');
        setShowAddDepartment(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        storeStructureDeptsContainerRefs.current[0].style.minHeight = '950px';

    }

    function handleEditDepartment(d:StoreDepartment):void {
        setShowKeywordSearchLimitError(false);
        setDepartmentToEdit(d);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setDepartmentNameSearchText('');
        setShowEditDepartment(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        storeStructureDeptsContainerRefs.current[0].style.minHeight = '950px';

    }

    function addDepartmentImage(ds:DepartmentSource, resolve:(value: DepartmentSource | PromiseLike<DepartmentSource>) => void):void {
        //add image file if it exists
        //console.log('source:', ds.source);
        //console.log('lastindexof /:', ds.department.departmentImage.lastIndexOf('/'));
        //console.log('lastindexof .:', ds.department.departmentImage.lastIndexOf('.'));
        const forwardSlashPos = ds.department.departmentImage.lastIndexOf('/');
        const periodPosition =  ds.department.departmentImage.lastIndexOf('.');
        //console.log('file path:', ds.department.departmentImage);
        //console.log('filename:', ds.department.departmentImage.substring(forwardSlashPos + 1, periodPosition));
        const filename = ds.department.departmentImage.substring(forwardSlashPos + 1, periodPosition);
        //console.log('mimeType:', ds.department.departmentImage.substring(periodPosition + 1));
        const mime = ds.department.departmentImage.substring(forwardSlashPos + 1, periodPosition);
        const binary = window.atob(ds.source);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        for(let i=0; i < binary.length; i++){
            view[i] = binary.charCodeAt(i);
        }

        const file = new File([view], filename, {type:mime});

        const reader = new FileReader();

        function loadHandler(){
            ds.department.departmentImage = reader.result as string;
            resolve(ds);
            reader.removeEventListener('load', loadHandler);
        }

        reader.addEventListener('load', loadHandler);

        if(file){
            reader.readAsDataURL(file);
        }
    }

    function handleRemoveDepartment(storeDepartment:StoreDepartment):void {
        //reset form errors
        departmentKeywordRef.current!.classList.remove('product-info-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        setShowKeywordSearchLimitError(false);

        
        fetch(`https://server.kando-proto-3.com/delete-individual-department`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(storeDepartment)
        })
        .then((res)=>{

            setIsLoadingDelete(true);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            return res.json();
        })
        .then(({departmentDeleted, pscs})=>{
            //console.log('department deleted:', departmentDeleted);
            if(departmentDeleted){
                const uds = departments.filter((d)=>{
                                return d.departmentId !== storeDepartment.departmentId;
                            });

                if(uds.length === 0){
                    setDepartmentNameSearchText('');
                    setNoSearchResultsMessage(`All departments have been deleted from this search results page.  
                                                Try another search results page, keyword search, reset the department list or add more departments.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setDepartments(uds);
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                    
                } else {

                    //console.log('r15:', (uds.length / itemsPerList), listChangerBtnPosition);

                    if((uds.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, uds, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, uds);

                    } else {
                        getAnyItemList(listChangerBtnPosition, uds, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, uds);
                    }

                    setDepartmentNameSearchText('');
                    setDepartments(uds);
                    //getAnyItemList(listChangerBtnPosition, uds, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, uds);
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                }
                
        
                
            }


            const productShopCategories = pscs.map((psc:ShopCategory)=>{
                return addShopCategory(psc);
            });
    
            //console.log('pscs 7-11:', productShopCategories);
            dispatch(addProductShopCategories([...productShopCategories]));





        })
        .catch((err)=>{
            console.error('delete department from db fetch err:', err.message);
            setShowErrorMessage(true);
        });
    }

    function addShopCategory(sc:ShopCategory):ShopCategory {
        return new ShopCategory({shopCategoryId:sc.shopCategoryId,
                                shopCategoryLink:sc.shopCategoryLink,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                                });
    }

    function addSubShopCategories(sscs:SubShopCategory[]):SubShopCategory[] {
        return sscs.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });

    }

    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }

    function getUpdatedDepartment(department:StoreDepartment):void {
        fetch(`https://server.kando-proto-3.com/get-updated-department/${department.departmentId}`, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then(({departmentSource})=>{
            //console.log('psc:', departmentSource, departments);
            if(departmentSource === null){
                setShowErrorMessage(true);
                setIsLoading(false);

            } else {
                Promise.resolve(                
                                new Promise((resolve, reject)=>{
                                    //console.log('test15:');
                                    addDepartmentImage(departmentSource, resolve);
                }))
                .then((dSource)=>{

                    //console.log('pcs15:', dSource);

                    const ds = departments.map((d)=>{
                                    if(d.departmentId === (dSource as DepartmentSource).department.departmentId){
                                        return (dSource as DepartmentSource).department;

                                    } else {
                                        return d;
                                    }
                                });

                    

                    setDepartments(ds);
                    getAnyItemList(listChangerBtnPosition, ds, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, ds);
                    setIsLoading(false);

                })
                .catch((err)=>console.log('error get updated department image:', err.message));


            }
        })
        .catch((err)=>console.log('get updated department error:', err.message));
    }


    function sortItemsByDateSubmitted(sortDirection:string):void {
        if(sortDirection === 'asc'){
            //console.log('sort29');
            const ds = departments.sort(compareAscendingDates);
            setDepartments(ds);
            getAnyItemList(listChangerBtnPosition, ds, itemsPerList, showItemsPerList);

        } else {
            //console.log('sort29');
            const ds = departments.sort(compareDescendingDates);
            setDepartments(ds);
            getAnyItemList(listChangerBtnPosition, ds, itemsPerList, showItemsPerList);
        }

    }

    function compareDescendingDates(a:StoreDepartment, b:StoreDepartment):number {
        const aDate = new Date(a.departmentDateAdded).getTime();
        const bDate = new Date(b.departmentDateAdded).getTime();

        return bDate - aDate;
    }

    function compareAscendingDates(a:StoreDepartment, b:StoreDepartment):number {
        const aDate = new Date(a.departmentDateAdded).getTime();
        const bDate = new Date(b.departmentDateAdded).getTime();

        return aDate - bDate;
    }



    function setupDepartmentsTable(itemsList:object[]/*d:StoreDepartment[]*/):JSX.Element {
        //console.log('sort29:', itemsList);
        return <div className="ssds-table-container">
                    <table className="ssds-table">
                        <thead>
                            <tr>
                                <th className="th-dept">Department</th><th className="th-img">Department Image</th><th className="th-date"><div className="admin-date-added-header-container"><span>Date Added</span><span className="admin-date-added-svg-btn-container">
                                                                                                                                                                                                                            <button type="button" aria-label="ascending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('asc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-up" d="M200 100 L100 0 L0 100 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                            <button type="button" aria-label="descending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('desc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-down" d="M200 0 L100 100 L0 0 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                        </span></div></th><th className="th-action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(itemsList as StoreDepartment[]).map((d)=>{
                        //console.log(`pn:${p.productName}, dn:${p.storeDepartmentName}, dcn:${p.departmentCategoryName}, pcn:${p.productCategoryName}, psc:${p.productSubCategoryName}, pda:${p.productDateAdded}`)
                                                return <tr key={d.departmentId}>
                                                            <td>
                                                                <div className="ssds-td">{d.departmentName}</div>
                                                            </td>
                                                            <td className="ssds-img"><img ref={getImageRef} data-alt={d.departmentName} alt={""} data-src={d.departmentImage} className="ssds-table-image" /></td>
                                                            <td><div className="ssds-td">{transformDate(new Date(d.departmentDateAdded).getTime())}</div></td>
                                                            <td className="td-action">
                                                                <div className="table-item-btn-container">
                                                                    <button className="edit-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleEditDepartment(d)
                                                                        }}>
                                                                        Edit
                                                                    </button>
                                                                    <button className="remove-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleRemoveDepartment(d);
                                                                        }}>
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                            })
                            }
                        </tbody>
                    </table>
                </div>
    }

    function handleCloseForm(formType:string, cancel?:boolean):void {
        
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        if(formType === 'editDepartment'){
            setDepartments([]);
            setIsLoading(true);
            setShowEditDepartment(false);
            //getAllDepartments();
            getUpdatedDepartment(departmentToEdit!);
        } else if(formType === 'addDepartment') {
            setDepartments([]);
            setIsLoading(true);
            setShowAddDepartment(false);
            setShowEditDepartment(false);
            if(cancel){
                getAllDepartments();
            } else {
                setListChangerBtnPosition(0);
                getAllDepartments(0);
            }

        } 


    }

    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as StoreDepartment[]);

        //adjust item count based on items per list setting
        let nrh = 0;
        if(itemsPerList < itemsList.length){
            nrh = (itemsPerList * 94) + 588 + 16 + 57;

        } else {
            nrh = (itemsList.length) * 94 + 588 + 16 + 57;
        }

        const mh = 950;
        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
            storeStructureDeptsContainerRefs.current[0].style.minHeight = `${mh}px`;
        }

        if(existingSearchResultsHeight.current === 0 && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh < mh){
            existingSearchResultsHeight.current = mh;

            storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh > existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }
        //console.log('esrs:', existingSearchResultsHeight.current);


    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);    

    }

    function displayListSorter(itemsPerList:number, ds:StoreDepartment[]):void {
        if(ds.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleDepartmentSearchText(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        
        //console.log('value.length:', target.value.length);

        if(target.value.length <= 50){
            setDepartmentNameSearchText(target.value);
            setShowKeywordSearchLimitError(false);

        } else {
            setShowKeywordSearchLimitError(true);
        }
    }

    function handleResultsPerPageOptionSelected(e:React.ChangeEvent):void {
        const rppoSelected = (e.target as HTMLSelectElement).value;
        setResultsPerPageOptionSelected(rppoSelected);
        const ipp = parseInt(rppoSelected, 10);

        const previpp = itemsPerList;
        const previousPositionInList = (previpp * (listChangerBtnPosition + 1)) - previpp + 1;
        const startPointInList = Math.floor(previousPositionInList / ipp);
        //console.log('cbp:', startPointInList, previousPositionInList / ipp, listChangerBtnPosition);

        if(ipp > departments.length){
            setItemsPerList(ipp);
            setListChangerBtnPosition(0);
            getAnyItemList(0, departments, ipp, showItemsPerList);

        } else {

            if(listChangerBtnPosition === 0){
                setItemsPerList(ipp);
                setListChangerBtnPosition(0);
                getAnyItemList(0, departments, ipp, showItemsPerList);

            } else {
                setItemsPerList(ipp);
                setListChangerBtnPosition(startPointInList - 1);
                getAnyItemList(startPointInList - 1, departments, ipp, showItemsPerList);
            }

        }
        
        displayListSorter(ipp, departments);

        //adjust item count based on items per list setting
        let nrh = 0;
        if(ipp < departments.length){
            nrh = (ipp * 94) + 588 + 16 + 57;

        } else {
            nrh = (departments.length) * 94 + 588 + 16 + 57;
        }
        

        if(nrh > 950){
            storeStructureDeptsContainerRefs.current[0].style.minHeight = `${nrh}px`;
        }
    }


    function handleSubmitDepartmentSearch(e:React.FormEvent):void {
        e.preventDefault();
        //setShowLoadingSearchResults(true);
        const form = e.target as HTMLFormElement;

        //reset form errors
        departmentKeywordRef.current!.classList.remove('product-info-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //product info
        //product name selected
        const dnst = departmentNameSearchText;
        if(dnst.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Department Keyword'});

        } else {
            setShowKeywordSearchLimitError(false);
        }

        //pricing info
        /*
        const pp = productPrice;
        if(pp.length === 0 || parseInt(pp, 10) === 0){
            formError.pricingErrors.push({hasError:true, area:'Price'});
        }
        */

        const dnsts = formError.productInfoErrors.length > 0 ? true : false;
        //const pps = formError.pricingErrors.length > 0 ? true : false;

        if(dnsts/* || pps*/){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
            
        }

        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);

        //setItemsList(null);

        setShowProcessingRequestMsg(true);


        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        const searchText = departmentNameSearchText;

        /*
        const zipCodePattern = /\d+/g
        const zcr = searchText.match(zipCodePattern);

        if(zcr !== null){
            //found some numbers
            let numberString = '';
            zcr.forEach((num)=>{
                numberString += num;		
                });
                        
            console.log('zcr:', zcr);
            console.log('search number:', parseInt(numberString,10));
    
            const searchText = numberString;
            //check store numbers
            fetch(`http://localhost:9500/store-location-search-number/${searchText}`, {
                method:'GET',
                headers:{
                    'Content-Type':'text/javascript',
                    'Accept':'text/javascript'
                }
            })
            .then((res)=>res.json())
            .then((result)=>{
                console.log('result:', result);
                if(result.length === 1 && result[0].source === null && result[0].department === null){
                    //setShowLoadingSearchResults(false);
                    setShowNoSearchResultsFound(true);
                } else {
                    
                    Promise.all(addDepartmentImages(result))
                    .then((res)=>{
                        console.log('res 11-27:', res);            
                        const ds = addDepartments(res);
                        setDepartments(ds);
                        getAnyItemList(listChangerBtnPosition, ds, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, ds);
                        //setShowLoadingSearchResults(false);
                    })
                    .catch((err)=>console.error('add department images error:', err.message));
                    
                }
            })
            .catch(err=>console.error('department number search error:', err.message));
            
        } else {
            */
            //check department name
            if(searchText.length > 0){
                let searchString = '';
    
                for(let i=0; i < searchText.length; i++){
                    if(/[\(\)\[\]\*\$\^\{\}\?\_\+\.\"\'\\|]/.test(searchText[i])){
                        searchString += `\\${searchText[i]}`;
    
                    } else {
                        searchString += `${searchText[i]}`;
                    }
                }
    
                //console.log('str26:', searchString);
                //searchString = '\\(-\\_\'\"\"\\]\\\\//\\?what\\^\\$@1';  

                const st = {searchText:searchString};
                
                const params = JSON.stringify(st);

                //set search results to empty string until a response from the db is received
                setTemporaryClearSearchResults(true);

                //previous version
                //fetch(`http://localhost:9500/department-search-name/${searchText}`, {
                fetch(`https://server.kando-proto-3.com/department-search-name`, {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:params
                })
                .then((res)=>{

                    setShowProcessingRequestMsg(false);
                    setItemsList(null);
                    isLoadingTimeoutRef.current = window.setTimeout(()=>{
                                                            setIsLoading(true);
                                                        },1000);
                    setIsLoadingDelete(true);

                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    return res.json();
                })
                .then((result)=>{
                    //console.log('result:', result);
                    if(result.length === 1 && result[0].source === null && result[0].department === null){
                        //setShowLoadingSearchResults(false);
                        setNoSearchResultsMessage('No departments matched those search parameters.');
                        setDepartments([]);
                        setShowNoSearchResultsFound(true);
                        setShowInfoMessage(true);

                        setTemporaryClearSearchResults(false);

                        window.clearTimeout(isLoadingTimeoutRef.current);
                        setIsLoading(false);
                        setIsLoadingDelete(false);


                        storeStructureDeptsContainerRefs.current[0].style.minHeight = `950px`;


                    } else {
                        Promise.all(addDepartmentImages(result))
                        .then((res)=>{
                            //console.log('res 11-27:', res);            
                            const ds = addDepartments(res);
                            setListChangerBtnPosition(0);
                            getAnyItemList(0, ds, itemsPerList, showItemsPerList);
                            displayListSorter(itemsPerList, ds);
                            setDepartments(ds);

                            setTemporaryClearSearchResults(false);

                            window.clearTimeout(isLoadingTimeoutRef.current);
                            setIsLoading(false);
                            setIsLoadingDelete(false);

                            

                            //setShowLoadingSearchResults(false);

                            //adjust item count based on items per list setting
                            let nrh = 0;
                            if(itemsPerList < ds.length){
                                nrh = (itemsPerList * 94) + 588 + 16 + 57;

                            } else {
                                nrh = (ds.length) * 94 + 588 + 16 + 57;
                            }

                            const mh = 950;
                            if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                                storeStructureDeptsContainerRefs.current[0].style.minHeight = `${mh}px`;
                            }
        
                            if(existingSearchResultsHeight.current === 0 && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh < existingSearchResultsHeight.current && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh < existingSearchResultsHeight.current && nrh < mh){
                                existingSearchResultsHeight.current = mh;
        
                                storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh > existingSearchResultsHeight.current && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                storeStructureDeptsContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
                            //console.log('esrs:', existingSearchResultsHeight.current);

                            let root = document.documentElement;
                            root!.setAttribute('style', 'scroll-behavior: auto');
                            root.scrollTo(0,0);
                            window.setTimeout(()=>{
                                root!.removeAttribute('style');
                            },100);
                        })
                        .catch((err)=>{
                            //console.log('add department images error:', err.message);
                            setShowErrorMessage(true);

                            window.clearTimeout(isLoadingTimeoutRef.current);
                            setIsLoading(false);
                            setIsLoadingDelete(false);
                            
                        });
                        
                    }

                    
                })
                .catch(err=>{
                    //console.log('department name search error:', err.message);
                    setShowErrorMessage(true);

                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);
                    
                });

            } else {
                //console.log('no results found.');
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);

                setIsLoadingDelete(false);
                

                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
            }
            
            
        //}


    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Department Keyword':
                                departmentKeywordRef.current!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no department keyword info errors found!');
                        }
                    }
                });


        //set image errors
        /*
        formError.pricingErrors.forEach((pe)=>{
            console.log('pe:', pe);
            if(pe.hasError && pe.area === 'Price'){
                console.log('pe.area:', pe.area);
                productPriceRef.current!.classList.add('pricing-error');
            } else {
                throw new Error('no price errors found!');
            }
        });
        */
    }
    
    /*
    if(!showAddDepartment && !showEditDepartment && departments.length === 0 && !isLoading){
        return <div className="store-structure-depts-wrapper">
                    <div ref={getStoreStructureDeptsContainerRef} className="store-structure-depts-container">
                        <h3 className="store-structure-dpts-categories-heading">Departments</h3>
                        <button type="button" onClick={handleAddDepartment}>Add Department</button>
                        <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                    </div>
                    <Footer/>
                </div>
    } else if(!showAddDepartment && !showEditDepartment && departments.length > 0) {*/
        return <div className="store-structure-depts-wrapper">
                    <div ref={getStoreStructureDeptsContainerRef} className="store-structure-depts-container">
                        <ul className="store-details-breadcrum-container">
                            <li className="ssdbi">
                                <Link to="/admin" className="store-details-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="ssdbi">
                                <Link to="/admin/store-structure" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin/store-structure', navigate)}>Store Structure</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="ssdbi store-details-breadcrum-link-store-name">Departments</li>
                        </ul>
                        {
                            !showAddDepartment && !showEditDepartment
                            ?
                            <h1 className="store-structure-dpts-categories-heading">Departments</h1>
                            :
                            null
                        }
                        
                        {
                            !showAddDepartment && !showEditDepartment
                            ?
                            <div>
                                <div className="admin-add-item-btn-container">
                                    <button type="button" className="admin-add-item-btn" onClick={handleAddDepartment}>Add Department</button>
                                    <button type="button" className="admin-reset-items-btn" onClick={resetDepartments}>Reset Departments</button>
                                </div>
                                <form ref={getFormRef} className="admin-search-form" onSubmit={handleSubmitDepartmentSearch}>
                                    <fieldset className="admin-search-box-container">
                                        <label className="admin-search-box-label" htmlFor="search-box">Keywords:</label>
                                        <div className="table-keyword-search-layout-wrapper">
                                            <input id="search-box" className="admin-search-box" type="text"
                                                ref={departmentKeywordRef}
                                                placeholder={"Enter department name"}
                                                value={departmentNameSearchText} onChange={handleDepartmentSearchText}/>
                                            {
                                                showKeywordSearchLimitError
                                                ?
                                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                                :
                                                null
                                            }
                                        </div>
                                    </fieldset>
                                    <fieldset className="admin-search-form-btn-container">
                                        <button type="submit" className="admin-search-form-btn">Search</button>
                                    </fieldset>
                                    <FormErrorMessage ref={getSfgErrorMsgRef} formError={formError} />
                                </form>
                            </div>
                            :
                            null
                        }

                        {
                            !showAddDepartment && !showEditDepartment && !isLoading 
                            && showNoSearchResultsFound && showInfoMessage && !isLoadingDelete
                            ? 
                            <div className="admin-list-wrapper">
                                <div className="admin-list-total-continer">
                                    <span className="admin-list-total">{departments.length} results</span>
                                    <div className="admin-results-per-page-container">
                                        <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                        <div className="admin-results-per-page-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                                optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                                cssStyles={{minWidth:50, backgroundPosition:5}}
                                                handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="pr-generic-info-message-container">
                                    {noSearchResultsMessage}
                                    {/*<GenericInfoMessage infoMessage={noSearchResultsMessage}/>*/}
                                </div>
                            </div>
                            :
                            null
                        }

                        {
                            !showAddDepartment && !showEditDepartment && !isLoading && departments.length > 0
                            && !showNoSearchResultsFound && !showErrorMessage && !isLoadingDelete
                            ?
                            <div className="admin-list-wrapper">
                                <div className="admin-list-total-continer">
                                    {
                                        temporaryClearSearchResults
                                        ?
                                        <span className="admin-list-total"></span>
                                        :
                                        <span className="admin-list-total">{(departments.length === 1) ? `${departments.length} result` : `${departments.length} results`}</span>    
                                    }
                                    <div className="admin-results-per-page-container">
                                        <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                        <div className="admin-results-per-page-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                                optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                                cssStyles={{minWidth:50, backgroundPosition:5}}
                                                handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                        </div>
                                    </div>
                                </div>
                                <ListViewer itemsList={itemsList} items={departments} showListSorter={showListSorter}
                                    listChangerBtnPosition={listChangerBtnPosition}
                                    itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                    getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupDepartmentsTable} />
                            </div>
                            :
                            null
                        }

                        {
                            showAddDepartment && !showEditDepartment && !isLoading
                            ?
                            <AddStoreDepartment showEditDepartment={false} handleCloseForm={handleCloseForm}/>
                            :
                            null
                        }

                        {
                            !showAddDepartment && showEditDepartment && !isLoading
                            ?
                            <EditStoreDepartment department={departmentToEdit!} handleCloseForm={handleCloseForm}/>
                            :
                            null
                        }

                        {
                            !showAddDepartment && !showEditDepartment && isLoading && !showNoSearchResultsFound && !isLoadingDelete
                            ?
                            <div ref={getLoaderRef} className="ssds-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null
                        }

                        {   
                            !showAddDepartment && !showEditDepartment && isLoadingDelete && !showNoSearchResultsFound
                            ?
                            <div className="ssds-loader2-animation-container">
                                {/*<LoaderAnimation/>*/}
                                {
                                    isLoading
                                    ?
                                    <LoaderAnimation/>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }



                        {
                            showErrorMessage
                            ?
                            <div className="ssd-generic-error-msg-wrapper">
                                <GenericErrorMessage/>
                            </div>
                            :
                            null
                        }

                        {
                            showProcessingRequestMsg
                            ?
                            <div className="process-request-msg-fixed">
                                Processing...
                            </div>
                            :
                            null
                        }

                        
                    </div>
                    {
                        showFooter
                        ?
                        <Footer/>
                        :
                        null
                    }
                </div>
                /*
    } else if(showAddDepartment && !showEditDepartment) {
        return <div className="store-structure-depts-wrapper">
                    <div ref={getStoreStructureDeptsContainerRef} className="store-structure-depts-container">
                                <AddStoreDepartment showEditDepartment={false} handleCloseForm={handleCloseForm}/>
                    </div>
                    <Footer/>
                </div>
    } else if(!showAddDepartment && showEditDepartment){
        return  <div className="store-structure-depts-wrapper">
                    <div ref={getStoreStructureDeptsContainerRef} className="store-structure-depts-container">
                        <EditStoreDepartment department={departmentToEdit!} handleCloseForm={handleCloseForm}/>
                    </div>
                    <Footer/>
                </div>
    } else {
        return <div className="ssds-loader-animation-container">
                    <LoaderAnimation/>
                </div>
    }
    */
}


export default StoreStructureDepartments2;
