import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
//import {Link} from '@reach/router';
import { Link, useNavigate } from 'react-router-dom';
import Product from './product';
import ShoppingCart from './shoppingCart';
import ShoppingCartCategory from './shoppingCartCategory';

import ShopProductGroupRating from './ShopProductGroupRating';
import getApprovedProductReviews from './helper-get-approved-product-reviews';
import {addReviews} from './helper-add-reviews';
//import {addProductsToMiniCart, AddProductsToMiniCart} from './redux/actions';
import {addProductsToMiniCart, selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import DeleteMiniCartProduct from './DeleteMiniCartProduct';
import helperUpdateShoppingCartProductQuantity from './helper-update-shopping-cart-product-quantity';
import {throttle} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import loadNavLink from './loadNavLinkHelper';
import './ShopProductMiniCart.css';

interface Props {
    /*
    storeDepartmentName:string;
    departmentCategoryName:string;
    productCategoryName:string;
    product:Product;
    showShopProductDetailCheckoutBtn:boolean;
    */

   //shoppingCart?:ShoppingCart;
   //addProductsToMiniCart?:(shoppingCart:ShoppingCart)=>AddProductsToMiniCart;
}

/*
interface State {
    shoppingCart:ShoppingCart;
}

function mapStateToProps(state:State) {
    const { shoppingCart } = state;
    //console.log('shoppingCart test 3-19:', shoppingCart);
    return {
        shoppingCart: cloneDeep(shoppingCart)
    };

}

const mapDispatchToProps = {
    addProductsToMiniCart
};
*/

const ShopProductMiniCart: React.FunctionComponent<Props> = (props:Props) => {
    let isQuantityItemBtnContainerOpen:boolean = false;
    //let currentShoppingCartProductQuantitySelected:string = 'n/a';
    //let shoppingCartProductSelected:Product | null = null;
    //let shoppingCartCategorySelected:ShoppingCartCategory | null = null;
    const navigate = useNavigate();
    //const { addProductsToMiniCart } = props;
    const dispatch = useAppDispatch();
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    //console.log('scr:', shoppingCartRedux);

    //const shoppingCart = props.shoppingCart as ShoppingCart;

    const [shoppingCart, setShoppingCart] = useState<ShoppingCart | null>(shoppingCartRedux as ShoppingCart);

    //const [shoppingCartProducts, setShoppingCartProducts] = useState<Product[] | []>([]);
    const [shoppingCartCategories, setShoppingCartCategories] = useState<ShoppingCartCategory[] | []>([]);
    //const [shoppingCartProduct, setShoppingCartProduct] = useState<Product | null>(props.shoppingCartProduct as Product);
    const [updateMiniCart, setUpdateMiniCart] = useState(false);
    const [miniCartHeight, setMiniCartHeight] = useState(0);
    const miniCartContainer:React.RefObject<HTMLDivElement> = useRef(null);
    const miniCartListContainer:React.RefObject<HTMLDivElement> = useRef(null);
    const [initialMiniCartHeight, setInitialMiniCartHeight] = useState(window.innerHeight);
    const [initialMiniCartWidth, setInitialMiniCartWidth] = useState(window.innerWidth);
    const [miniCartClosed, setMiniCartClosed] = useState({closed:false});
    const [elementVisibilityClass, setElementVisibilityClass] = useState('hide-element');
    const [deleteMiniCartProductVisibilityClass, setDeleteMiniCartProductVisibilityClass] = useState('hide-element');
    const [showRightArrow, setShowRightArrow] = useState('hide-element');
    const [showLeftArrow, setShowLeftArrow] = useState('show-element');
    const [miniCartVisibility, setMiniCartVisibility] = useState('shop-product-mini-cart-list-wrapper-closed');
    const [showDeleteMiniCartProduct, setShowDeleteMiniCartProduct] = useState(false);
    const [showProductListItemDeleteBtn, setShowProductListItemDeleteBtn] = useState('');
    //const [moveMiniCartListItem, setMoveMiniCartListItem]= useState('');
    const [miniCartProductListItem, setMiniCartProductListItem] = useState<HTMLDivElement | null>(null);
    const [miniCartProductListItems, setMiniCartProductListItems] = useState<HTMLDivElement[] | []>([]);
    const [miniCartProductListItemDeleteBtns, setMiniCartProductListItemDeleteBtns] = useState<HTMLElement[] | []>([]);
    const [deleteProductElements, setDeleteProductElements] = useState<HTMLDivElement[] | []>([]);
    const [clickedShoppingCartProduct, setClickedShoppingCartProduct] = useState('');
    const [deletedShoppingCartProduct, setDeleteShoppingCartProduct] = useState(false);
    const [shoppingCartProductQuantity, setShoppingCartProductQuantity] = useState(0);
    const [updatedShoppingCartProductQuantity, setUpdatedShoppingCartProductQuantity] = useState(false);
    const [quantityDecreaseBtns, setQuantityDecreaseBtns] = useState<HTMLButtonElement[] | []>([]);
    const [quantityIncreaseBtns, setQuantityIncreaseBtns] = useState<HTMLButtonElement[] | []>([]);
    const [quantityItemBtnContainers, setQuantityItemBtnContainers] = useState<HTMLDivElement[] | []>([]);
    const quantityInputsRef:React.RefObject<HTMLInputElement[] | []> = useRef<HTMLInputElement[] | []>([]);
    const [previousShoppingCartProductQuantitySelected, setPreviousShoppingCartProductQuantitySelected] = useState(0);
    const [currentShoppingCartProductQuantitySelected, setCurrentShoppingCartProductQuantitySelected] = useState('n/a');
    const [shoppingCartProductSelected, setShoppingCartProductSelected] = useState<Product | null>(null);
    const [shoppingCartCategorySelected, setShoppingCartCategorySelected] = useState<ShoppingCartCategory | null>(null);
    const [previousShoppingCartProductSelected, setPreviousShoppingCartProductSelected] = useState<Product | null>(null);
    const [previousShoppingCartCategorySelected, setPreviousShoppingCartCategorySelected] = useState<ShoppingCartCategory | null>(null);
    const miniCartProductsOverlayRef:React.RefObject<HTMLDivElement[]> = useRef<HTMLDivElement[] | []>([]);
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [quantityInputs, setQuantityInputs] = useState<HTMLInputElement[] | []>([]);
    const [quantitySelectors, setQuantitySelectors] = useState<HTMLSelectElement[] | []>([]);
    
    const [showProductQuantityDropdownList, setShowProductQuantityDropdownList] = useState(false);
    const inputQuantityElement = useRef<HTMLInputElement | null>(null);
    const inputQuantityElementContainer = useRef<HTMLDivElement | null>(null);
    const [showShoppingCart, setShowShoppingCart] = useState(false);

    //start here add quantity selector button
    const productQuantitySelectorBtn = useRef<HTMLDivElement>(null);

    const cp = useRef<number>(0);
    const pp = useRef<number>(0);
    const scrolling = useRef<boolean>(false);
    const miniCartContainerRefs = useRef<HTMLDivElement[]>([]);
    const miniCartTestContainerRefs = useRef<HTMLLIElement[]>([]);
    const miniCartCategoryListContainerRefs = useRef<HTMLDivElement[]>([]);
    const miniCartFooterContainerRefs = useRef<HTMLDivElement[]>([]);
    const miniCartCategoryListItemRefs = useRef<HTMLLIElement[]>([]);
    const miniCartProductListRefs = useRef<HTMLUListElement[]>([]);
    const miniCartProductListItemRefs = useRef<HTMLDivElement[]>([]);

    const [shoppingCartProductAdded, setShoppingCartProductAdded] = useState(false);
    const [shoppingCartProductRemoved, setShoppingCartProductRemoved] = useState(false);

    /*
    console.log('spmc test sc:', shoppingCart && shoppingCart.shoppingCartCategories[0]
    && shoppingCart.shoppingCartCategories[0].shoppingCartProducts[0].shoppingCartQuantity);
    console.log('spmc test props.sc:', props.shoppingCart && props.shoppingCart.shoppingCartCategories[0]
    && props.shoppingCart.shoppingCartCategories[0].shoppingCartProducts[0].shoppingCartQuantity);
    */

    function getMiniCartFooterContainerRef(el:HTMLDivElement|null):void {
        //miniCartTestContainerRefs.current.length = 0;
        //console.log('el:');
        if(el !== null && miniCartFooterContainerRefs.current.length === 0){
            miniCartFooterContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = miniCartFooterContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                miniCartFooterContainerRefs.current.push(el);
            }

        }

        //console.log('mcclirs:', miniCartFooterContainerRefs.current);
                                  

    }

    function getMiniCartCategoryListContainerRef(el:HTMLDivElement|null):void {
        //miniCartTestContainerRefs.current.length = 0;
        //console.log('el:');
        if(el !== null && miniCartCategoryListContainerRefs.current.length === 0){
            miniCartCategoryListContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = miniCartCategoryListContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                miniCartCategoryListContainerRefs.current.push(el);
            }

        }

        //console.log('mcclirs:', miniCartCategoryListContainerRefs.current);
                                  

    }

    function getMiniCartCategoryListItemRef(el:HTMLLIElement|null):void {
        //miniCartTestContainerRefs.current.length = 0;
        //console.log('el:');
        if(el !== null && miniCartCategoryListItemRefs.current.length === 0){
            miniCartCategoryListItemRefs.current.push(el);

        } else if(el !== null) {
            const found = miniCartCategoryListItemRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                miniCartCategoryListItemRefs.current.push(el);
            }

        }

        //console.log('mcclirs:', miniCartCategoryListItemRefs.current);
                                  

    }

    function getMiniCartProductListRef(el:HTMLUListElement|null):void {
        if(el !== null && miniCartProductListRefs.current.length === 0){
            miniCartProductListRefs.current.push(el);

        } else if(el !== null) {
            const found = miniCartProductListRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                miniCartProductListRefs.current.push(el);
            }

        }

        //console.log('mcplrs:', miniCartProductListRefs.current);
    }

    function getMiniCartProductListItemRef(el:HTMLDivElement|null):void {
        if(el !== null && miniCartProductListItemRefs.current.length === 0){
            miniCartProductListItemRefs.current.push(el);

        } else if(el !== null) {
            const found = miniCartProductListItemRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                miniCartProductListItemRefs.current.push(el);
            }

        }

        //console.log('mcplirs:', miniCartProductListItemRefs.current);
    }


    function getMiniCartTestContainerRef(el:HTMLLIElement|null):void {
        //console.log('mctcrs:', miniCartTestContainerRefs.current.length);
        //miniCartTestContainerRefs.current.length = 0;
        //console.log('el:');
        if(el !== null && miniCartTestContainerRefs.current.length === 0){
            miniCartTestContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = miniCartTestContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                miniCartTestContainerRefs.current.push(el);
            }

        }

        //console.log('mctcrs:', miniCartTestContainerRefs.current);


    }


    function getMiniCartContainerRef(el:HTMLDivElement|null):void {
        //miniCartContainerRefs.current.length = 0;
        //console.log('el:');
        if(el !== null && miniCartContainerRefs.current.length === 0){
            miniCartContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = miniCartContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                miniCartContainerRefs.current.push(el);
            }

        }

        //console.log('mccrs:', miniCartContainerRefs.current);



        setupMiniCartSizing();

        
    }

    useEffect(()=>{
        window.addEventListener('scroll', throttle(miniCartScrollHandler, 10));
        return cleanupMiniCartScrollHandler;
    },[]);

    function miniCartScrollHandler(e:Event):void {
        cp.current = window.scrollY;

        if(!scrolling.current){
            scrolling.current = true;
            window.requestAnimationFrame(rafHandler);          
        }

        pp.current = cp.current;

        

    }

    function rafHandler():void {
        const tp = window.innerWidth >= 480 ? 112 : 96;
        //console.log('cp:', cp.current, (window.innerHeight - tp) + cp.current);
        const mh = (window.innerHeight - tp) + cp.current;

        if(miniCartContainerRefs.current.length === 1){
            //miniCartContainerRefs.current[0].style.position = 'absolute';
        }

        if(miniCartContainerRefs.current.length === 1 && cp.current >= tp){
            miniCartContainerRefs.current[0].style.top = '0';
            miniCartContainerRefs.current[0].style.height = `${window.innerHeight}px`;
            miniCartContainerRefs.current[0].style.position = 'fixed';
        }

        if(miniCartContainerRefs.current.length === 1 && (cp.current < tp)){
            miniCartContainerRefs.current[0].style.height = `${mh}px`;
            miniCartContainerRefs.current[0].style.top = '';
            miniCartContainerRefs.current[0].style.position = '';
        }

        if(miniCartCategoryListContainerRefs.current.length === 1){
            const mcclcw = miniCartCategoryListContainerRefs.current![0].getBoundingClientRect().width;
            //console.log('r1:', mcclcw);
            let mcclch = 0;

            if(mcclcw === 78){
                miniCartProductListRefs.current.forEach((mcplr)=>{
                    mcplr.style.margin = '0';
                });
            }


            //const mcclcw = miniCartCategoryListContainerRef.current!.getBoundingClientRect().width;
            //console.log('r1:', mcclcw, miniCartCategoryListContainerRefs.current![0].offsetWidth , miniCartCategoryListContainerRefs.current![0].clientWidth);
            //let mcclch = 0;
            if(mcclcw <= 380 && mcclcw >= 280){
                
                miniCartCategoryListContainerRefs.current![0].style.maxHeight = `${miniCartContainerRefs.current[0].getBoundingClientRect().height - 110 - 41.72}px`;
                mcclch = miniCartCategoryListContainerRefs.current![0].getBoundingClientRect().height;

            } else {
                miniCartCategoryListContainerRefs.current![0].style.maxHeight = '';
                mcclch = miniCartCategoryListContainerRefs.current![0].getBoundingClientRect().height;
            }

            
            //when mini cart is partially open with scroll bar
            //32 is the padding for list container
            if(miniCartCategoryListContainerRefs.current![0].clientWidth >= 77){
                //console.log('r1:', miniCartCategoryListContainerRefs.current![0].clientWidth);
                miniCartCategoryListContainerRefs.current![0].style.padding = '16px';

                miniCartTestContainerRefs.current.forEach((mctc)=>{
                                                    mctc.style.padding = '0';
                                                    mctc.style.marginBottom = '16px';
                                                    mctc.style.border = 'none';
                                                });
                                                
                miniCartProductListItemRefs.current.forEach((mcpli)=>{
                                                mcpli.style.columnGap = '0px';
                                            });

            } 


            if(miniCartCategoryListContainerRefs.current![0].clientWidth < 77){
                //console.log('r1:');
                miniCartCategoryListContainerRefs.current![0].style.padding = '16px 8px 8px';

                miniCartTestContainerRefs.current.forEach((mctc)=>{
                                                    mctc.style.padding = '0';
                                                    mctc.style.marginBottom = '16px';
                                                    mctc.style.border = 'none';
                                                });  
                                                
                miniCartProductListItemRefs.current.forEach((mcpli)=>{
                    mcpli.style.columnGap = '0px';
                });

            } 


            //when mini cart is fully open
            if(mcclcw === 380){
                miniCartCategoryListContainerRefs.current![0].style.padding = '';

                miniCartTestContainerRefs.current.forEach((mctc)=>{
                                                    mctc.style.padding = '';
                                                    mctc.style.marginBottom = '';
                                                    mctc.style.border = '';
                                                });
                                                
                miniCartProductListItemRefs.current.forEach((mcpli)=>{
                    mcpli.style.columnGap = '8px';
                });

            }

            if(mcclcw === 78){
                miniCartCategoryListItemRefs.current.forEach((mccli)=>{
                                                    mccli.style.margin = '0';
                                                });
            }

            if(mcclcw === 380){
                miniCartCategoryListItemRefs.current.forEach((mccli)=>{
                                                    mccli.style.margin = '';
                                                });
            }
        }


        scrolling.current = false;
    }


    function cleanupMiniCartScrollHandler():void {
        window.removeEventListener('scroll', throttle(miniCartScrollHandler,10));
    }


    useEffect(()=>{
        window.addEventListener('resize', miniCartResizeHandler);

        window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupMiniCartResizeHandler;
    },[]);

    function cleanupMiniCartResizeHandler():void {
        window.removeEventListener('resize', miniCartResizeHandler);

        window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        miniCartResizeHandler(new Event('test'));
    }

    function miniCartResizeHandler(e:Event):void {
        if(typeof miniCartContainerRefs.current[0] !== 'undefined'){
            if(window.innerWidth <= 380 && miniCartContainerRefs.current[0].classList.contains('shop-product-mini-cart-list-wrapper-opened')){
                miniCartContainerRefs.current[0].style.width = '100%';
                //miniCartFooterContainerRef.current!.style.width = '100%';
                
            } else {
                miniCartContainerRefs.current[0].style.width = '';
                //miniCartFooterContainerRef.current!.style.width = '100%';
            }
        }

        setupMiniCartSizing();


    }

    function setupMiniCartSizing():void {
        if(miniCartContainerRefs.current.length === 1){
            const tp = window.innerWidth >= 480 ? 112 : 96;
            //console.log('tp:', tp, 'cp:', cp.current, 'mt:', miniCartContainerRefs.current[0].getBoundingClientRect().top, 'wh:', window.innerHeight);
            const mh = (window.innerHeight - tp) + cp.current;
            const mt = miniCartContainerRefs.current[0].getBoundingClientRect().top;

            //console.log('cp:', cp.current, 'mh:', mh, 'mt:', mt);
           
            //get height when page is reloaded and previously scrolled
            //but less than 112px
            if(cp.current === 0 && mt === tp){
                miniCartContainerRefs.current[0].style.height = `${window.innerHeight - tp}px`;               
            }

            if(cp.current === 0 && mt <= 0){
                //console.log('cp:', mh + mt);
                miniCartContainerRefs.current[0].style.height = `${window.innerHeight}px`;
                miniCartContainerRefs.current[0].style.top = '0';
                miniCartContainerRefs.current[0].style.position = 'fixed';
                
            }
    
            if(cp.current === 0 && mt > 0 && mt < tp){
                //console.log('cp22:', mh + mt);
                miniCartContainerRefs.current[0].style.height = `${mh + (tp - mt)}px`;
                miniCartContainerRefs.current[0].style.top = '';
                miniCartContainerRefs.current[0].style.position = '';
                
            }

            if(cp.current > 0 && cp.current >= tp){
                miniCartContainerRefs.current[0].style.top = '0';
                miniCartContainerRefs.current[0].style.height = `${window.innerHeight}px`;
                miniCartContainerRefs.current[0].style.position = 'fixed';
            }
    
            if(cp.current > 0 && cp.current < tp){
                //console.log('cp22:');
                miniCartContainerRefs.current[0].style.height = `${mh}px`;
                miniCartContainerRefs.current[0].style.top = '';
                miniCartContainerRefs.current[0].style.position = '';
            }


            const mcclcw = miniCartCategoryListContainerRefs.current![0].getBoundingClientRect().width;

            if(mcclcw === 78){
                miniCartProductListRefs.current.forEach((mcplr)=>{
                    mcplr.style.margin = '0';
                });
            }


            //console.log('r1:', mcclcw, miniCartCategoryListContainerRefs.current![0].offsetWidth, miniCartCategoryListContainerRefs.current![0].clientWidth);
            let mcclch = 0;
            if(mcclcw <= 380 && mcclcw >= 280){
                
                miniCartCategoryListContainerRefs.current![0].style.maxHeight = `${miniCartContainerRefs.current[0].getBoundingClientRect().height - 110 - 41.72}px`;
                mcclch = miniCartCategoryListContainerRefs.current![0].getBoundingClientRect().height;

            } else {
                miniCartCategoryListContainerRefs.current![0].style.maxHeight = '';
                mcclch = miniCartCategoryListContainerRefs.current![0].getBoundingClientRect().height;
            }

            //when mini cart is partially open with scroll bar
            //32 is the padding for list container
            if(miniCartCategoryListContainerRefs.current![0].clientWidth < 77){
                miniCartCategoryListContainerRefs.current![0].style.padding = '16px 8px 8px';

                miniCartTestContainerRefs.current.forEach((mctc)=>{
                                                    mctc.style.padding = '0';
                                                    mctc.style.marginBottom = '16px';
                                                    mctc.style.border = 'none';
                                                });
                                                
                miniCartProductListItemRefs.current.forEach((mcpli)=>{
                    mcpli.style.columnGap = '0px';
                });

            } 


            //when mini cart is partially open with scroll bar
            //32 is the padding for list container
            if(miniCartCategoryListContainerRefs.current![0].clientWidth >= 77){
                miniCartCategoryListContainerRefs.current![0].style.padding = '16px';

                miniCartTestContainerRefs.current.forEach((mctc)=>{
                                                    mctc.style.padding = '0';
                                                    mctc.style.marginBottom = '16px';
                                                    mctc.style.border = 'none';
                                                });   

                miniCartProductListItemRefs.current.forEach((mcpli)=>{
                    mcpli.style.columnGap = '0px';
                });

            } 

           

            //when mini cart is fully open
            if(mcclcw === 380){
                miniCartCategoryListContainerRefs.current![0].style.padding = '';

                miniCartTestContainerRefs.current.forEach((mctc)=>{
                                                    mctc.style.padding = '';
                                                    mctc.style.marginBottom = '';
                                                    mctc.style.border = '';
                                                }); 
                                                
                miniCartProductListItemRefs.current.forEach((mcpli)=>{
                    mcpli.style.columnGap = '8px';
                });
                
            }

            if(mcclcw === 78){
                miniCartCategoryListItemRefs.current.forEach((mccli)=>{
                                                    mccli.style.margin = '0';
                                                });
            }

            if(mcclcw === 380){
                miniCartCategoryListItemRefs.current.forEach((mccli)=>{
                                                    mccli.style.margin = '';
                                                });
            }
            

        }
    }

    
    useEffect(()=>{
        //previous version
        //document.addEventListener('click', (e)=>closeMiniShoppingCart(e, miniCartListContainer));
        document.addEventListener('click', closeMiniShoppingCart);

        return function cleanupCloseMiniCart(){
            //previous version
            //document.removeEventListener('click', (e)=>closeMiniShoppingCart(e, miniCartListContainer));
            document.removeEventListener('click', closeMiniShoppingCart);
        }
    },[]);
    
    

    /*previous version copy
    useEffect(()=>{
        console.log('sc:', props.shoppingCart && props.shoppingCart.shoppingCartCategories[0]
                && props.shoppingCart.shoppingCartCategories[0].shoppingCartProducts[0]);
        setShoppingCart(props.shoppingCart as ShoppingCart);
    },[props.shoppingCart])
    */

    useEffect(()=>{
        //console.log('scr21:', shoppingCartRedux && shoppingCartRedux.shoppingCartCategories[0]
                //&& shoppingCartRedux.shoppingCartCategories[0].shoppingCartProducts[0]);
        //clear all references when minicart is empty
        if(shoppingCartRedux.shoppingCartCategories.length === 0){
            miniCartContainerRefs.current.length = 0;
            miniCartTestContainerRefs.current.length = 0;
            miniCartCategoryListContainerRefs.current.length = 0;
            miniCartFooterContainerRefs.current.length = 0;
            miniCartCategoryListItemRefs.current.length = 0;
            miniCartProductListRefs.current.length = 0;
            miniCartProductListItemRefs.current.length = 0;
        }

        setShoppingCartProductAdded(shoppingCartRedux.shoppingCartProductAdded);
        setShoppingCartProductRemoved(shoppingCartRedux.shoppingCartProductRemoved);
        const clone = cloneDeep(shoppingCartRedux);
        setShoppingCart(clone);

        //setShowShoppingCart(shoppingCartRedux.showShoppingCart);
    },[shoppingCartRedux])
    
    //console.log('scr2:');

    useEffect(()=>{
        //console.log('scpae:', shoppingCartProductAdded, 'scpar:', shoppingCartRedux.shoppingCartProductAdded, 'scpre:', shoppingCartProductRemoved, 'scprr:', shoppingCartRedux.shoppingCartProductRemoved);

        /*previous version
        if(!deletedShoppingCartProduct && !updatedShoppingCartProductQuantity){
            console.log('scr2:');
            closeMiniCart();
        }
        */

        if(shoppingCartProductAdded || shoppingCartProductRemoved){
            //console.log('scr4:');
            closeMiniCart();
        }

        updateMiniCartList(shoppingCart as ShoppingCart);
        setTotalOrderAmount(getTotalOrderAmount(shoppingCart as ShoppingCart));
    
        
    },[shoppingCart]);

    
    function closeMiniShoppingCart(
                        e:MouseEvent/*, 
                        miniCartListContainerRef:React.RefObject<HTMLDivElement>*/):void {
        //console.log('cmsc:', e.target);
        e.stopPropagation();
        e.stopImmediatePropagation();

        //console.log('mclcr:', miniCartListContainer.current);
       
       if(typeof miniCartContainerRefs.current[0] !== 'undefined' 
            && miniCartContainerRefs.current[0].classList.contains('shop-product-mini-cart-list-wrapper-opened')
            && miniCartListContainer.current !== null){
           //console.log('mclcr:', miniCartListContainer.current, miniCartListContainer.current.firstElementChild);
           //shoppingCart!.showShoppingCart = false;
           miniCartListContainer.current.click();
           (miniCartListContainer.current.firstElementChild as HTMLDivElement).click();

       }

       
       //setDeleteMiniCartProductVisibilityClass('hide-element');
       /*
       setMiniCartVisibility('shop-product-mini-cart-list-wrapper-closed');
       setElementVisibilityClass('hide-element');
       setDeleteMiniCartProductVisibilityClass('hide-element');
       */

        //shoppingCart!.showShoppingCart = false;
        //addProductsToMiniCart!(shoppingCart!);
        //setShoppingCart(shoppingCart!);

    }
    

    function getTotalOrderAmount(shoppingCart:ShoppingCart):number {
        let totalOrderAmount:number = 0;
        shoppingCart.shoppingCartCategories.forEach((shoppingCartCategory)=>{
            shoppingCartCategory.shoppingCartProducts.forEach((shoppingCartProduct)=>{
                totalOrderAmount += shoppingCartProduct.totalPrice;
                //console.log('scq:', typeof shoppingCartProduct.shoppingCartQuantity);
                if(shoppingCartProduct.shoppingCartQuantity as number > 1 
                    || shoppingCartProduct.weightBy === 'pound'){
                    shoppingCartProduct.showEachPrice = true;
                } else {
                    shoppingCartProduct.showEachPrice = false;
                }
            });
        });

        return totalOrderAmount;
    }

    /*previous version
    function getMiniCartHeight(miniCartContainer:React.RefObject<HTMLDivElement>):void {
        //console.log('ran test:', miniCartContainer);
        if(miniCartContainer.current !== null){
            const windowHeight:number = window.innerHeight;
            const topMiniCartContainerWindowHeight:number = miniCartContainer.current.getBoundingClientRect().top;
            const adjustedTopMiniCartContainerWindowHeight:number = windowHeight - topMiniCartContainerWindowHeight;
            //console.log('minicartheight:', adjustedTopMiniCartContainerWindowHeight);
        }
    }
    */

    function getMiniCartHeight(miniCartContainer:HTMLDivElement):void {
        //console.log('ran test:', miniCartContainer);
        if(typeof miniCartContainer !== 'undefined'){
            const windowHeight:number = window.innerHeight;
            const topMiniCartContainerWindowHeight:number = miniCartContainer.getBoundingClientRect().top;
            const adjustedTopMiniCartContainerWindowHeight:number = windowHeight - topMiniCartContainerWindowHeight;
            //console.log('minicartheight:', adjustedTopMiniCartContainerWindowHeight);
        }
    }

    function handleOpenCloseMiniCart(e:React.MouseEvent):void {
        //console.log('hocmc:', e);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        miniCartContainerRefs.current[0].style.width = '';

        if(miniCartVisibility === 'shop-product-mini-cart-list-wrapper-closed'){
            
            miniCartProductListItemRefs.current.forEach((mcpli)=>{
                                                mcpli.style.columnGap = '8px';
                                                mcpli.style.transition = 'all 0s ease-in-out';
                                            });

            miniCartTestContainerRefs.current.forEach((mctc)=>{
                                        mctc.style.padding = '';
                                        mctc.style.marginBottom = '0';
                                        mctc.style.border = '';
                                    });

            shoppingCart!.showShoppingCart = true;
            //addProductsToMiniCart!(shoppingCart!);
            //setShoppingCart({...shoppingCart!});
            setShowLeftArrow('hide-element');
            setShowRightArrow('show-element');
            setElementVisibilityClass('');
            setMiniCartVisibility('shop-product-mini-cart-list-wrapper-opened');
            

        } else {
            //console.log('test passed');
            shoppingCart!.showShoppingCart = true;

            miniCartProductListItemRefs.current.forEach((mcpli)=>{
                                        mcpli.style.columnGap = '0px';
                                        mcpli.style.transition = 'all 0s ease-in-out';
                                    });

            miniCartTestContainerRefs.current.forEach((mctc)=>{
                mctc.style.padding = '0';
                mctc.style.marginBottom = '16px';
                mctc.style.border = 'none';
            }); 

            //addProductsToMiniCart!(shoppingCart!);
            //setShoppingCart({...shoppingCart!});
            removeMinicartListItemOverlay();
            setShowLeftArrow('show-element');
            setShowRightArrow('hide-element');
            setElementVisibilityClass('hide-element');
            setMiniCartVisibility('shop-product-mini-cart-list-wrapper-closed');
            setMiniCartProductListItems(resetMiniCartProductListItems(miniCartProductListItems));
            setMiniCartProductListItemDeleteBtns(resetMiniCartProductListItemDeleteBtns(miniCartProductListItemDeleteBtns));
            setDeleteProductElements(resetDeleteProductElements(deleteProductElements));





        }

  
    }

    function removeMinicartListItemOverlay():void {
        if(miniCartListContainer.current !== null){
            miniCartListContainer.current.click();
        }
    }

    function closeMiniCart():void {
        removeMinicartListItemOverlay();
        //console.log('cmc');
        setMiniCartVisibility('shop-product-mini-cart-list-wrapper-closed');
        setShowLeftArrow('show-element');
        setShowRightArrow('hide-element');
        setElementVisibilityClass('hide-element');
        setMiniCartProductListItems(resetMiniCartProductListItems(miniCartProductListItems));
        setMiniCartProductListItemDeleteBtns(resetMiniCartProductListItemDeleteBtns(miniCartProductListItemDeleteBtns));
        setDeleteProductElements(resetDeleteProductElements(deleteProductElements));

       
    }

    function resetMiniCartProductListItems(miniCartProductListItems:HTMLDivElement[]):HTMLDivElement[] {
        const updatedMiniCartProductListItems:HTMLDivElement[] = miniCartProductListItems.map((miniCartProductListItem)=>{
                                        miniCartProductListItem.classList.remove('move-mini-cart-list-item');
                                        return miniCartProductListItem;
                                    });

            updatedMiniCartProductListItems.length = 0;
            return updatedMiniCartProductListItems;
    }

    function resetMiniCartProductListItemDeleteBtns(miniCartProductListItemDeleteBtns:HTMLElement[]):HTMLElement[] {
        const updatedMiniCartProductListItemDeleteBtns:HTMLElement[] = miniCartProductListItemDeleteBtns.map((miniCartProductListItemDeleteBtn)=>{
                                            miniCartProductListItemDeleteBtn.classList.remove('hide-element');
                                        return miniCartProductListItemDeleteBtn;
                                    });

            updatedMiniCartProductListItemDeleteBtns.length = 0;
            return updatedMiniCartProductListItemDeleteBtns;
    }

    function resetDeleteProductElements(deleteProductElements:HTMLDivElement[]):HTMLDivElement[]{
        const updatedDeleteProductElements:HTMLDivElement[] = deleteProductElements.map((deleteProductElement)=>{
                                            deleteProductElement.classList.remove('show-element');
                                        return deleteProductElement;
                                    });

        updatedDeleteProductElements.length = 0;
        return updatedDeleteProductElements;    
    }

    /*
    original format for loading minicart with individual products
    function updateMiniCartList(shoppingCart:ShoppingCart, shoppingCartProducts:Product[]):void {
        console.log('shoppingCart minicart:', shoppingCart);
        const shoppingCartProduct = shoppingCart.shoppingCartProduct as Product;
        if(shoppingCartProduct !== null && shoppingCartProducts.length > 0 && shoppingCart.shoppingCartProductAdded){
            const shoppingCartProductFound = shoppingCartProducts.find((scp)=>{
                                                            return scp.productName === shoppingCartProduct.productName;
                                                        });
            if(typeof shoppingCartProductFound === 'undefined'){
                console.log('scp undefined added > to 0');
                setShoppingCartProducts([...shoppingCartProducts, shoppingCartProduct]);    
            } else {
                console.log('scp added > to 0');
                const shoppingCartProductFoundIndex = shoppingCartProducts.findIndex((scp)=>{
                                                            return scp.productName === shoppingCartProduct.productName;
                                                        });
                const result = shoppingCartProducts.splice(shoppingCartProductFoundIndex, 1, shoppingCartProduct);
                console.log('scps result:', result);
                console.log('shoppingCartProductFoundIndex:', shoppingCartProductFoundIndex);
                console.log('scps:', shoppingCartProducts);
                setShoppingCartProducts([...shoppingCartProducts]);
            }
            //setShoppingCartProducts([...shoppingCartProducts, shoppingCartProduct]);
            //(addProductToMiniCart as (shoppingCartProduct:Product)=>AddProductToMiniCart)(shoppingCartProduct);
        } else if(shoppingCartProduct !== null && shoppingCartProducts.length > 0 && shoppingCart.shoppingCartProductRemoved){
            const shoppingCartProductFound = shoppingCartProducts.find((scp)=>{
                return scp.productName === shoppingCartProduct.productName;
            });
            const shoppingCartProductFoundIndex = shoppingCartProducts.findIndex((scp)=>{
                return scp.productName === shoppingCartProduct.productName;
            });
            if(shoppingCartProductFound && shoppingCartProductFound.shoppingCartQuantity === 0){
                console.log('scp quantity:', shoppingCartProduct.shoppingCartQuantity);
                console.log('scpf quantity:', (shoppingCartProductFound as Product).shoppingCartQuantity);
                shoppingCartProducts.splice(shoppingCartProductFoundIndex, 1);
                console.log('test passed removed product');
                setShoppingCartProducts([...shoppingCartProducts]);
            } else if(shoppingCartProductFound) {
                console.log('test failed');
                shoppingCartProducts.splice(shoppingCartProductFoundIndex, 1, shoppingCartProduct);
                setShoppingCartProducts([...shoppingCartProducts]);
            } else {
                console.log('test else failed');
                return;
            }

            //console.log('scps result:', result);
            console.log('shoppingCartProductFoundIndex:', shoppingCartProductFoundIndex);
            console.log('scps:', shoppingCartProducts);
            

        } else if(shoppingCartProduct !== null && shoppingCartProducts.length === 0 && shoppingCart.shoppingCartProductAdded){
            console.log('scp added equal to 0');
            setShoppingCartProducts([shoppingCartProduct]);
            //(addProductToMiniCart as (shoppingCart:ShoppingCart)=>AddProductToMiniCart)(shoppingCartProduct);
        }
    }
    */

   function updateMiniCartList(shoppingCart:ShoppingCart):void {
               //console.log('sc:', shoppingCart && shoppingCart.shoppingCartCategories[0]
                //&& shoppingCart.shoppingCartCategories[0].shoppingCartProducts[0]);
       const shoppingCartCategories = shoppingCart.shoppingCartCategories;
        if(shoppingCartCategories.length > 0){
            let updatedShoppingCartCategories = shoppingCartCategories.map((updatedShoppingCartCategory)=>{
                                const result = updatedShoppingCartCategory.shoppingCartProducts.filter((shoppingCartProduct)=>{
                                    return shoppingCartProduct.shoppingCartQuantity !== 0;
                                });
                                if(result.length === 0){
                                    updatedShoppingCartCategory.shoppingCartProducts = [];
                                    return updatedShoppingCartCategory;

                                } else {
                                    updatedShoppingCartCategory.shoppingCartProducts = result;
                                    return updatedShoppingCartCategory;
                                }
                                                            
                            });

                            //console.log('updatedShoppingCartCategories:', updatedShoppingCartCategories);

            updatedShoppingCartCategories = (updatedShoppingCartCategories as ShoppingCartCategory[]).filter((updatedShoppingCartCategory)=>{
                                    //console.log('updatedShoppingCartCategory:', updatedShoppingCartCategory);
                                    return updatedShoppingCartCategory.shoppingCartProducts.length !== 0;
                                });

            //console.log('product:', updatedShoppingCartCategories[0].shoppingCartProducts[0]);
           
            //previous version
            //getMiniCartHeight(miniCartContainer);
            getMiniCartHeight(miniCartContainerRefs.current[0]);

            //console.log('updatedShoppingCartCategories:', updatedShoppingCartCategories);
            //setShoppingCartProducts([...updatedShoppingCartProducts]);
            setShoppingCartCategories([...updatedShoppingCartCategories as ShoppingCartCategory[]]);
            //setShoppingCartCategories([]);
        } else {
            //setShoppingCartProducts([]);
            setShoppingCartCategories([]);
        }
    }

    /*
    most recent version of adding a product to mini cart
    function updateMiniCartList(shoppingCart:ShoppingCart):void {
        if(shoppingCart.shoppingCartProducts.length > 0){
            const updatedShoppingCartProducts:Product[] = shoppingCart.shoppingCartProducts.filter((shoppingCartProduct)=>{
                                return shoppingCartProduct.shoppingCartQuantity !== 0;
                            });
            setShoppingCartProducts([...updatedShoppingCartProducts]);
        } else {
            setShoppingCartProducts([]);
        }
    }
    */

    function showDeleteMiniCartProductMsg(e:React.MouseEvent, shoppingCartName:string):void {
        //console.log('parent:', (e.target as HTMLElement));
        const btnElement = (e.target as HTMLElement).closest('.shop-product-mini-cart-product-list-item-delete-btn') as HTMLButtonElement;
        const liElement:HTMLDivElement = (((btnElement.parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement);
        //previous version
        //const liElement:HTMLDivElement = ((((e.target as HTMLElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement);
        const deleteProductElement:HTMLDivElement = liElement.firstElementChild as HTMLDivElement;
        const deleteBtn:HTMLElement = btnElement;
        //previous version
        //const deleteBtn:HTMLElement = (e.target as HTMLElement);
        const productListItem:HTMLDivElement = ((btnElement.parentElement as HTMLDivElement).parentElement as HTMLDivElement);
        productListItem.style.transition = 'all .125s ease-in-out';
        //previous version
        //const productListItem:HTMLDivElement = (((e.target as HTMLElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement);

        //previous version
        //const liElement:HTMLDivElement = ((((e.target as HTMLElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement);
        //previous version
        //const deleteProductElement:HTMLDivElement = liElement.firstElementChild as HTMLDivElement;
        //previous version
        //const deleteBtn:HTMLElement = (e.target as HTMLElement);
        //previous version
        //const productListItem:HTMLDivElement = (((e.target as HTMLElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement);
        const productListItemFound = miniCartProductListItems.find((miniCartProductListItem)=>{
                                    return miniCartProductListItem === productListItem;
                                });
                                
        if(typeof productListItemFound === 'undefined'){
            productListItem.classList.add('move-mini-cart-list-item');
            setMiniCartProductListItems([...miniCartProductListItems, productListItem]);
        } else {
            productListItem.classList.add('move-mini-cart-list-item');
            const productListItemFoundIndex:number = miniCartProductListItems.findIndex((miniCartProductListItem)=>{
                return miniCartProductListItem === productListItemFound;
            });
            miniCartProductListItems.splice(productListItemFoundIndex, 1, productListItemFound);
            setMiniCartProductListItems([...miniCartProductListItems]);            
        }

        const deleteBtnFound = miniCartProductListItemDeleteBtns.find((miniCartProductListItemDeleteBtn)=>{
            return miniCartProductListItemDeleteBtn === deleteBtn;
        });
        if(typeof deleteBtnFound === 'undefined'){
            deleteBtn.classList.add('hide-element');
            setMiniCartProductListItemDeleteBtns([...miniCartProductListItemDeleteBtns, deleteBtn]);
        } else {
            deleteBtn.classList.add('hide-element');
            const deleteBtnFoundIndex:number = miniCartProductListItemDeleteBtns.findIndex((miniCartProductListItemDeleteBtn)=>{
                return miniCartProductListItemDeleteBtn === deleteBtnFound;
            });
            miniCartProductListItemDeleteBtns.splice(deleteBtnFoundIndex, 1, deleteBtnFound);
            setMiniCartProductListItemDeleteBtns([...miniCartProductListItemDeleteBtns]);            
        }

        const deleteProductElementFound = deleteProductElements.find((dpe)=>{
            return dpe === deleteProductElement;
        });
        if(typeof deleteProductElementFound === 'undefined'){
            deleteProductElement.classList.add('show-element');
            setDeleteProductElements([...deleteProductElements, deleteProductElement]);
        } else {
            deleteProductElement.classList.add('show-element');
            const deleteProductElementFoundIndex:number = deleteProductElements.findIndex((dpe)=>{
                return dpe === deleteProductElementFound;
            });
            deleteProductElements.splice(deleteProductElementFoundIndex, 1, deleteProductElementFound);
            setDeleteProductElements([...deleteProductElements]);            
        }
        
        
        //console.log('shoppingCartProduct:', shoppingCartName);
        //setMiniCartProductListItem(listItem);
        setClickedShoppingCartProduct(shoppingCartName);
        //setShowProductListItemDeleteBtn('hide-element');
        //listItem.classList.add('move-mini-cart-list-item');
        //setShowDeleteMiniCartProduct(true);
    }

    function showCancelDeleteMiniCartProductMsg(e:React.MouseEvent):void {
        //console.log('cancel delete element:', e.target);
        const deleteProductElement:HTMLDivElement = (((e.target as HTMLButtonElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement);
        const productListItem:HTMLDivElement = deleteProductElement.nextElementSibling as HTMLDivElement;
        const deleteBtn:HTMLElement = ((productListItem as HTMLDivElement).firstElementChild as HTMLDivElement).firstElementChild as HTMLElement;
        //console.log('next element sibling:', deleteProductElement.nextElementSibling);

        const deleteProductElementFound = deleteProductElements.find((dpe)=>{
            return dpe === deleteProductElement;
        });
        if(typeof deleteProductElementFound !== 'undefined'){
            deleteProductElement.classList.remove('show-element');
            const deleteProductElementFoundIndex:number = deleteProductElements.findIndex((dpe)=>{
                return dpe === deleteProductElementFound;
            });
            deleteProductElements.splice(deleteProductElementFoundIndex, 1, deleteProductElementFound);
            setDeleteProductElements([...deleteProductElements]);
        }

        const productListItemFound = miniCartProductListItems.find((miniCartProductListItem)=>{
            return miniCartProductListItem === productListItem;
        });
        if(typeof productListItemFound !== 'undefined'){
            productListItem.classList.remove('move-mini-cart-list-item');
            const productListItemFoundIndex:number = miniCartProductListItems.findIndex((miniCartProductListItem)=>{
                return miniCartProductListItem === productListItemFound;
            });
            miniCartProductListItems.splice(productListItemFoundIndex, 1, productListItemFound);
            setMiniCartProductListItems([...miniCartProductListItems]);
        }

        const deleteBtnFound = miniCartProductListItemDeleteBtns.find((miniCartProductListItemDeleteBtn)=>{
            return miniCartProductListItemDeleteBtn === deleteBtn;
        });
        if(typeof deleteBtnFound !== 'undefined'){
            deleteBtn.classList.remove('hide-element');
            const deleteBtnFoundIndex:number = miniCartProductListItemDeleteBtns.findIndex((miniCartProductListItemDeleteBtn)=>{
                return miniCartProductListItemDeleteBtn === deleteBtnFound;
            });
            miniCartProductListItemDeleteBtns.splice(deleteBtnFoundIndex, 1, deleteBtnFound);
            setMiniCartProductListItemDeleteBtns([...miniCartProductListItemDeleteBtns]);
        }

        //console.log('cancel test');
        //console.log('miniCartProductListItems:', miniCartProductListItems);
        //console.log('miniCartProductListItemDeleteBtns:', miniCartProductListItemDeleteBtns);
        //deleteProductElements[0].classList.remove('show-element');
        //setShowProductListItemDeleteBtn('');
        //(miniCartProductListItem as HTMLDivElement).classList.remove('move-mini-cart-list-item');
        //setShowDeleteMiniCartProduct(false); 
    }

    function deleteMiniCartProduct(
        e:React.MouseEvent, 
        selectedShoppingCartProduct:Product, 
        shoppingCartCategories:ShoppingCartCategory[]
        ):void {

            
        const deleteShoppingCartMsgContainer:HTMLDivElement = ((e.target as HTMLButtonElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement;
        const miniCartProductListItem:HTMLDivElement = deleteShoppingCartMsgContainer.nextElementSibling as HTMLDivElement;
        const deleteBtn:HTMLElement = (miniCartProductListItem.firstElementChild as HTMLDivElement).firstElementChild as HTMLElement;


        const updatedDeleteProductElements = deleteProductElements.filter((deleteProductElement)=>{
            return deleteProductElement !== deleteShoppingCartMsgContainer;
        });

        const updatedMiniCartProductListItems = miniCartProductListItems.filter((miniCartProduct)=>{
            return miniCartProduct !== miniCartProductListItem;
        });

        const updatedMiniCartProductListItemDeleteBtns = miniCartProductListItemDeleteBtns.filter((miniCartProductListItemDeleteBtn)=>{
            return miniCartProductListItemDeleteBtn !== deleteBtn;
        });


        
        (deleteShoppingCartMsgContainer as HTMLDivElement).classList.remove('show-element');
        miniCartProductListItem.classList.remove('move-mini-cart-list-item');
        deleteBtn.classList.remove('hide-element');
        setDeleteProductElements(updatedDeleteProductElements);
        setMiniCartProductListItems(updatedMiniCartProductListItems);
        setMiniCartProductListItemDeleteBtns(updatedMiniCartProductListItemDeleteBtns);
        
        
        //console.log('scp:', selectedShoppingCartProduct);
        //console.log('scc:', shoppingCartCategories);
        const sccFound = shoppingCartCategories.find((shoppingCartCategory)=>{
                            return shoppingCartCategory.storeDepartmentName === selectedShoppingCartProduct.storeDepartmentName;
                        });
        const scps = (sccFound as ShoppingCartCategory).shoppingCartProducts.filter((shoppingCartProduct)=>{
                        return shoppingCartProduct.productName !== selectedShoppingCartProduct.productName;
                    });

        //console.log('sccFound before:', sccFound);
        //console.log('scps:', scps);
        (sccFound as ShoppingCartCategory).shoppingCartProducts = scps;
        //console.log('sccFound after:', sccFound);

        const sccFoundIndex = shoppingCartCategories.findIndex((shoppingCartCategory)=>{
                            return shoppingCartCategory.storeDepartmentName === (sccFound as ShoppingCartCategory).storeDepartmentName;
                        });

        //check if there are any products left in the shopping cart category
        if(scps.length === 0){
            shoppingCartCategories.splice(sccFoundIndex, 1);
            //console.log('mc10:', shoppingCartCategories);

        } else {
            shoppingCartCategories.splice(sccFoundIndex, 1, (sccFound as ShoppingCartCategory));
        }

       
        (shoppingCart as ShoppingCart).shoppingCartProductAdded = false;
        (shoppingCart as ShoppingCart).shoppingCartProductRemoved = false;

        if(shoppingCartCategories.length === 0){
            (shoppingCart as ShoppingCart).shoppingCartCategories = [];

        } else {
            (shoppingCart as ShoppingCart).shoppingCartCategories = shoppingCartCategories;
        }
        

        //console.log('mc10:', shoppingCart);

        setShoppingCartProductAdded(false);
        setShoppingCartProductRemoved(false);
        shoppingCart!.shoppingCartProductAdded = false;
        shoppingCart!.shoppingCartProductRemoved =false;


        setDeleteShoppingCartProduct(true);
        setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

        
        //setMiniCartVisibility('shop-product-mini-cart-list-wrapper-opened');
        const sc:ShoppingCart = Object.assign({}, shoppingCart);

        //console.log('mc10:', sc);

        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
        dispatch(addProductsToMiniCart(sc));



            


    }

    function updateShoppingCartProductQuantityPound(
            e:React.ChangeEvent,
            shoppingCartCategories:ShoppingCartCategory[],
            shoppingCartProductSelected:Product, 
            shoppingCartCategorySelected:ShoppingCartCategory
                                            ):void{

            //e.stopPropagation();
            //console.log('e.target.value:', e.target);
            //console.log('scps:', shoppingCartProductSelected);
            let previousShoppingCartProductTotalPrice:number = 0;
            const updatedShoppingCartProductQuantity = (e.target as HTMLSelectElement).value;
            setCurrentShoppingCartProductQuantitySelected(updatedShoppingCartProductQuantity.toString());
            setPreviousShoppingCartProductQuantitySelected(parseFloat(updatedShoppingCartProductQuantity));
            previousShoppingCartProductTotalPrice = shoppingCartProductSelected.totalPrice;
            if(shoppingCartProductSelected.showSalePrice){
                shoppingCartProductSelected.totalPrice = (parseFloat(updatedShoppingCartProductQuantity) * shoppingCartProductSelected.salePrice);
            } else {
                shoppingCartProductSelected.totalPrice = (parseFloat(updatedShoppingCartProductQuantity) * shoppingCartProductSelected.eachPrice);
            }
            

            /*
            if(parseInt(updatedShoppingCartProductQuantity, 10) > 1){
                shoppingCartProductSelected.showEachPrice = true;
            } else if(parseInt(updatedShoppingCartProductQuantity, 10) < 2){
                shoppingCartProductSelected.showEachPrice = false;
            }
            */

           shoppingCartProductSelected.showEachPrice = true;
           shoppingCartProductSelected.shoppingCartQuantity = parseFloat((e.target as HTMLSelectElement).value);

            const updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                updatedShoppingCartProductQuantity, 
                shoppingCartCategories,
                shoppingCartCategorySelected, 
                shoppingCartProductSelected
                );
    
            setPreviousShoppingCartProductSelected(shoppingCartProductSelected);
            setPreviousShoppingCartCategorySelected(shoppingCartCategorySelected);
            setShoppingCartCategories([...updatedShoppingCartCategories]);

            setShoppingCartProductAdded(false);
            setShoppingCartProductRemoved(false);
            shoppingCart!.shoppingCartProductAdded = false;
            shoppingCart!.shoppingCartProductRemoved =false;

            setUpdatedShoppingCartProductQuantity(true);

            setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));


            const sc:ShoppingCart = Object.assign({}, shoppingCart);
    
            //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
            dispatch(addProductsToMiniCart(sc));
    }

    function updateShoppingCartProductQuantity(
            e:React.ChangeEvent<HTMLInputElement>,
            shoppingCartCategories:ShoppingCartCategory[],
            shoppingCartProductSelected:Product, 
            shoppingCartCategorySelected:ShoppingCartCategory
            ):void {
        e.nativeEvent.stopImmediatePropagation();
        let previousShoppingCartProductTotalPrice:number = 0;
        const updatedShoppingCartProductQuantity = e.target.value;

        //cancel a requests if amount is greater than product qty limit
        if(parseInt(updatedShoppingCartProductQuantity,10) > shoppingCartProductSelected.productQtyLimit){
            const scpq = getShoppingCartProductQty(shoppingCartRedux, shoppingCartProductSelected);
            //console.log('overLimit:', scpq);
            shoppingCartRedux.itemLimitExceeded = false;
            shoppingCartRedux.showItemLimtExceededErrorMsg = false;
            shoppingCartRedux.shoppingCartProductAdded = false;
            shoppingCartRedux.shoppingCartProductRemoved = false;
            const clone = cloneDeep(shoppingCartRedux);
            const sc:ShoppingCart = Object.assign({}, clone);
            dispatch(addProductsToMiniCart(sc));
            return;
        }


        const sqq = getTotalProductsAddedMinusShoppingCartProductSelected(shoppingCart!, shoppingCartProductSelected);
        const scpq = getShoppingCartProductQty(shoppingCartRedux, shoppingCartProductSelected);

        //console.log('e.press:', updatedShoppingCartProductQuantity, 'sqq:', sqq, 'il:', shoppingCart!.itemLimit);

        if(updatedShoppingCartProductQuantity.length > 0 && ((sqq + parseInt(updatedShoppingCartProductQuantity,10)) > shoppingCart!.itemLimit)){
            //console.log('overLimit:', parseInt(updatedShoppingCartProductQuantity,10), 'scpq:', scpq);
            shoppingCartRedux.itemLimitExceeded = true;
            shoppingCartRedux.showItemLimtExceededErrorMsg = true;
            shoppingCartRedux.shoppingCartProductAdded = false;
            shoppingCartRedux.shoppingCartProductRemoved = false;
            const clone = cloneDeep(shoppingCartRedux);
            const sc:ShoppingCart = Object.assign({}, clone);
            dispatch(addProductsToMiniCart(sc));

            return;
        }        


        if(updatedShoppingCartProductQuantity === ''){
            //console.log('true');
            shoppingCartProductSelected.showEachPrice = false;
            setCurrentShoppingCartProductQuantitySelected(updatedShoppingCartProductQuantity);
            setPreviousShoppingCartProductQuantitySelected(shoppingCartProductSelected.shoppingCartQuantity as number);

            const updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                updatedShoppingCartProductQuantity, 
                shoppingCartCategories,
                shoppingCartCategorySelected, 
                shoppingCartProductSelected
                );
    
            setPreviousShoppingCartProductSelected(shoppingCartProductSelected);
            setPreviousShoppingCartCategorySelected(shoppingCartCategorySelected);
            setShoppingCartCategories([...updatedShoppingCartCategories]);

            setShoppingCartProductAdded(false);
            setShoppingCartProductRemoved(false);
            shoppingCart!.shoppingCartProductAdded = false;
            shoppingCart!.shoppingCartProductRemoved =false;

            setUpdatedShoppingCartProductQuantity(true);

            setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

        } else if(updatedShoppingCartProductQuantity !== '' && updatedShoppingCartProductQuantity !== '0'){
            setCurrentShoppingCartProductQuantitySelected(updatedShoppingCartProductQuantity);
            setPreviousShoppingCartProductQuantitySelected(parseInt(updatedShoppingCartProductQuantity, 10));
            previousShoppingCartProductTotalPrice = shoppingCartProductSelected.totalPrice;

            if(shoppingCartProductSelected.showSalePrice){
                shoppingCartProductSelected.totalPrice = (parseInt(updatedShoppingCartProductQuantity, 10) * shoppingCartProductSelected.salePrice);    
            } else {
                shoppingCartProductSelected.totalPrice = (parseInt(updatedShoppingCartProductQuantity, 10) * shoppingCartProductSelected.eachPrice);
            }

            //original version
            //shoppingCartProductSelected.totalPrice = (parseInt(updatedShoppingCartProductQuantity, 10) * shoppingCartProductSelected.eachPrice);

            if(parseInt(updatedShoppingCartProductQuantity, 10) > 1){
                shoppingCartProductSelected.showEachPrice = true;
            } else if(parseInt(updatedShoppingCartProductQuantity, 10) < 2){
                shoppingCartProductSelected.showEachPrice = false;
            }

            const updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                updatedShoppingCartProductQuantity, 
                shoppingCartCategories,
                shoppingCartCategorySelected, 
                shoppingCartProductSelected
                );
    
            setPreviousShoppingCartProductSelected(shoppingCartProductSelected);
            setPreviousShoppingCartCategorySelected(shoppingCartCategorySelected);
            setShoppingCartCategories([...updatedShoppingCartCategories]);

            setShoppingCartProductAdded(false);
            setShoppingCartProductRemoved(false);
            shoppingCart!.shoppingCartProductAdded = false;
            shoppingCart!.shoppingCartProductRemoved =false;

            setUpdatedShoppingCartProductQuantity(true);

            setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

            const clone = cloneDeep(shoppingCart);
    
            const sc:ShoppingCart = Object.assign({}, clone);
    
            //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
            dispatch(addProductsToMiniCart(sc));

        } else if(updatedShoppingCartProductQuantity !== '' && updatedShoppingCartProductQuantity === '0'){
            shoppingCartProductSelected.showEachPrice = false;
            return;
        }



    }

    function increaseShoppingCartProductQuantity(
                        e:React.MouseEvent<HTMLButtonElement>,
                        currentShoppingCartProductQuantitySelected:string,
                        previousShoppingCartProductQuantitySelected:number,
                        shoppingCartCategories:ShoppingCartCategory[],
                        shoppingCartCategorySelected:ShoppingCartCategory,
                        shoppingCartProductSelected:Product
                        ):void {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let updatedShoppingCartProductQuantity:number = 0;
        let updatedShoppingCartCategories:ShoppingCartCategory[] = [];

        const btnValueClicked:string = (((e.target as SVGElement).closest('.shop-product-mini-cart-product-list-item-quantity-increment-btn') as HTMLButtonElement).previousElementSibling as HTMLInputElement).value;
        //previous version
        //const btnValueClicked:string = ((e.target as HTMLButtonElement).previousElementSibling as HTMLInputElement).value;

        //cancel request if amount is greater than product qty limit
        //console.log('bvc:', btnValueClicked);
        if(parseInt(btnValueClicked,10) === shoppingCartProductSelected.productQtyLimit){
            //console.log('too high');
            return;
        }

        const tpa = getTotalProductsAdded(shoppingCart!);
        //console.log('tpa:', tpa);
        if(tpa === shoppingCart!.itemLimit){
            const scpq = getShoppingCartProductQty(shoppingCartRedux, shoppingCartProductSelected);
            //console.log('overLimit:', scpq);
            shoppingCartRedux.itemLimitExceeded = true;
            shoppingCartRedux.showItemLimtExceededErrorMsg = true;
            shoppingCartRedux.shoppingCartProductAdded = false;
            shoppingCartRedux.shoppingCartProductRemoved = false;
            const clone = cloneDeep(shoppingCartRedux);
            const sc:ShoppingCart = Object.assign({}, clone);
            dispatch(addProductsToMiniCart(sc));
            return;
        }

        

        if(currentShoppingCartProductQuantitySelected === '' && (currentShoppingCartProductQuantitySelected !== btnValueClicked)){
            updatedShoppingCartProductQuantity = ((previousShoppingCartProductSelected as Product).shoppingCartQuantity = previousShoppingCartProductQuantitySelected);
            if(updatedShoppingCartProductQuantity > 1){
                (previousShoppingCartProductSelected as Product).showEachPrice = true;
            } else if(updatedShoppingCartProductQuantity < 2){
                (previousShoppingCartProductSelected as Product).showEachPrice = false;
            }

            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                    updatedShoppingCartProductQuantity, 
                                                    shoppingCartCategories,
                                                    previousShoppingCartCategorySelected as ShoppingCartCategory, 
                                                    previousShoppingCartProductSelected as Product
                                                    ); 
                
            updatedShoppingCartProductQuantity = parseInt(shoppingCartProductSelected.shoppingCartQuantity.toString(),10);
            updatedShoppingCartProductQuantity += 1;

            if(shoppingCartProductSelected.showSalePrice){
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.salePrice);    
            } else {
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);
            }

            //shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);

            if(updatedShoppingCartProductQuantity > 1){
                shoppingCartProductSelected.showEachPrice = true;
            }
            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                    updatedShoppingCartProductQuantity, 
                                                    shoppingCartCategories,
                                                    shoppingCartCategorySelected as ShoppingCartCategory, 
                                                    shoppingCartProductSelected as Product
                                                    );
        
        } else if(currentShoppingCartProductQuantitySelected === '' && currentShoppingCartProductQuantitySelected === btnValueClicked){
            updatedShoppingCartProductQuantity = ((previousShoppingCartProductSelected as Product).shoppingCartQuantity = previousShoppingCartProductQuantitySelected);
            updatedShoppingCartProductQuantity += 1;

            if(shoppingCartProductSelected.showSalePrice){
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.salePrice);    
            } else {
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);
            }
            
            //shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);

            if(updatedShoppingCartProductQuantity > 1){
                shoppingCartProductSelected.showEachPrice = true;
            }
            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                updatedShoppingCartProductQuantity, 
                shoppingCartCategories,
                previousShoppingCartCategorySelected as ShoppingCartCategory, 
                previousShoppingCartProductSelected as Product
                ); 

        } else { 
            updatedShoppingCartProductQuantity = parseInt(shoppingCartProductSelected.shoppingCartQuantity.toString(), 10);
            updatedShoppingCartProductQuantity += 1;

            if(shoppingCartProductSelected.showSalePrice){
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.salePrice);    
            } else {
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);
            }
            
            //shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);

            if(updatedShoppingCartProductQuantity > 1){
                shoppingCartProductSelected.showEachPrice = true;
            }
            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                    updatedShoppingCartProductQuantity, 
                                                    shoppingCartCategories,
                                                    shoppingCartCategorySelected as ShoppingCartCategory, 
                                                    shoppingCartProductSelected as Product
                                                    );                                                    
        }         

        setCurrentShoppingCartProductQuantitySelected(updatedShoppingCartProductQuantity.toString());
        setPreviousShoppingCartProductQuantitySelected(updatedShoppingCartProductQuantity);
        setShoppingCartCategories([...updatedShoppingCartCategories]);

        setShoppingCartProductAdded(false);
        setShoppingCartProductRemoved(false);
        shoppingCart!.shoppingCartProductAdded = false;
        shoppingCart!.shoppingCartProductRemoved =false;


        setUpdatedShoppingCartProductQuantity(true);

        setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

        const sc:ShoppingCart = Object.assign({}, shoppingCart);

        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
        dispatch(addProductsToMiniCart(sc));         
    }

    function decreaseShoppingCartProductQuantity(
        e:React.MouseEvent<HTMLButtonElement>, 
        currentShoppingCartProductQuantitySelected:string,
        previousShoppingCartProductQuantitySelected:number,
        shoppingCartCategories:ShoppingCartCategory[],
        shoppingCartCategorySelected:ShoppingCartCategory,
        shoppingCartProductSelected:Product
        ):void {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let updatedShoppingCartProductQuantity:number = 0;
        let updatedShoppingCartCategories:ShoppingCartCategory[] = [];
        const btnValueClicked:string = (((e.target as SVGElement).closest('.shop-product-mini-cart-product-list-item-quantity-decrement-btn') as HTMLButtonElement).nextElementSibling as HTMLInputElement).value;

        const shoppingCartProductDeleteBtn = (((e.target as SVGElement).closest('.shop-product-mini-cart-product-list-item') as HTMLDivElement).firstElementChild as HTMLDivElement). firstElementChild as HTMLButtonElement;
        //previous version
        //const btnValueClicked:string = ((e.target as HTMLButtonElement).nextElementSibling as HTMLInputElement).value;
        //previous version
        //const shoppingCartProductDeleteBtn:HTMLElement = ((((((e.target as HTMLButtonElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).firstElementChild as HTMLDivElement).firstElementChild as HTMLElement);
        //console.log('test passed');
        if(currentShoppingCartProductQuantitySelected === '' && (currentShoppingCartProductQuantitySelected !== btnValueClicked)){
            //console.log('mc10:');
            updatedShoppingCartProductQuantity = ((previousShoppingCartProductSelected as Product).shoppingCartQuantity = previousShoppingCartProductQuantitySelected);

            if(updatedShoppingCartProductQuantity > 1){
                (previousShoppingCartProductSelected as Product).showEachPrice = true;
            } else if(updatedShoppingCartProductQuantity < 2){
                (previousShoppingCartProductSelected as Product).showEachPrice = false;
            }
            
            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                    updatedShoppingCartProductQuantity, 
                                                    shoppingCartCategories,
                                                    previousShoppingCartCategorySelected as ShoppingCartCategory, 
                                                    previousShoppingCartProductSelected as Product
                                                    ); 
                
            updatedShoppingCartProductQuantity = parseInt(shoppingCartProductSelected.shoppingCartQuantity.toString(),10);
            updatedShoppingCartProductQuantity -= 1;

            if(updatedShoppingCartProductQuantity === 0){
                shoppingCartProductDeleteBtn.click();           
                return;
            }

            if(shoppingCartProductSelected.showSalePrice){
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.salePrice);    
            } else {
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);
            }

            //shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);

            if(updatedShoppingCartProductQuantity > 1){
                shoppingCartProductSelected.showEachPrice = true;
            } else if(updatedShoppingCartProductQuantity < 2){
                shoppingCartProductSelected.showEachPrice = false;
            }

            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                    updatedShoppingCartProductQuantity, 
                                                    shoppingCartCategories,
                                                    shoppingCartCategorySelected as ShoppingCartCategory, 
                                                    shoppingCartProductSelected as Product
                                                    );
        
        } else if(currentShoppingCartProductQuantitySelected === '' && currentShoppingCartProductQuantitySelected === btnValueClicked){
            //console.log('mc10:');
            updatedShoppingCartProductQuantity = ((previousShoppingCartProductSelected as Product).shoppingCartQuantity = previousShoppingCartProductQuantitySelected);
            updatedShoppingCartProductQuantity -= 1;

            if(updatedShoppingCartProductQuantity === 0){
                shoppingCartProductDeleteBtn.click();           
                return;
            }

            (previousShoppingCartProductSelected as Product).totalPrice = (updatedShoppingCartProductQuantity * (previousShoppingCartProductSelected as Product).eachPrice);
            if(updatedShoppingCartProductQuantity > 1){
                (previousShoppingCartProductSelected as Product).showEachPrice = true;
            } else if(updatedShoppingCartProductQuantity < 2){
                (previousShoppingCartProductSelected as Product).showEachPrice = false;
            }

            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                updatedShoppingCartProductQuantity, 
                shoppingCartCategories,
                previousShoppingCartCategorySelected as ShoppingCartCategory, 
                previousShoppingCartProductSelected as Product
                ); 

        } else { 
            //console.log('mc10:');
            updatedShoppingCartProductQuantity = parseInt(shoppingCartProductSelected.shoppingCartQuantity.toString(), 10);
            updatedShoppingCartProductQuantity -= 1;
            if(updatedShoppingCartProductQuantity === 0){
                shoppingCartProductDeleteBtn.click();           
                return;
            }

            if(shoppingCartProductSelected.showSalePrice){
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.salePrice);    
            } else {
                shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);
            }

            //shoppingCartProductSelected.totalPrice = (updatedShoppingCartProductQuantity * shoppingCartProductSelected.eachPrice);

            if(updatedShoppingCartProductQuantity < 1){
                shoppingCartProductSelected.showEachPrice = true;
            } else if(updatedShoppingCartProductQuantity < 2){
                shoppingCartProductSelected.showEachPrice = false;
            }

            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                    updatedShoppingCartProductQuantity, 
                                                    shoppingCartCategories,
                                                    shoppingCartCategorySelected as ShoppingCartCategory, 
                                                    shoppingCartProductSelected as Product
                                                    );                                                    
        }         


        setCurrentShoppingCartProductQuantitySelected(updatedShoppingCartProductQuantity.toString());
        setPreviousShoppingCartProductQuantitySelected(updatedShoppingCartProductQuantity);
        setShoppingCartCategories([...updatedShoppingCartCategories]);

        setShoppingCartProductAdded(false);
        setShoppingCartProductRemoved(false);
        shoppingCart!.shoppingCartProductAdded = false;
        shoppingCart!.shoppingCartProductRemoved =false;
        shoppingCart!.itemLimitExceeded = false;
        shoppingCart!.showItemLimtExceededErrorMsg = false;

        setUpdatedShoppingCartProductQuantity(true);

        setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));
        
        const sc:ShoppingCart = Object.assign({}, shoppingCart);

        //(addProductsToMiniCart as (shoppingCart:ShoppingCart)=>AddProductsToMiniCart)(sc);
        dispatch(addProductsToMiniCart(sc));      
        
    }

    function getTotalProductsAdded(shoppingCart:ShoppingCart):number {
        let totalProductsAdded:number = 0;
        shoppingCart.shoppingCartCategories.forEach((shoppingCartCategory)=>{
            shoppingCartCategory.shoppingCartProducts.forEach((shoppingCartProduct)=>{
                if(shoppingCartProduct.weightBy === 'pound' && shoppingCartProduct.shoppingCartQuantity !== 0){
                    totalProductsAdded += 1;
                } else {
                    totalProductsAdded += shoppingCartProduct.shoppingCartQuantity as number;
                }
                
            });
        });

        return totalProductsAdded;
    }

    function getTotalProductsAddedMinusShoppingCartProductSelected(shoppingCart:ShoppingCart, scps:Product):number {
        let totalProductsAdded:number = 0;
        shoppingCart.shoppingCartCategories.forEach((shoppingCartCategory)=>{
            shoppingCartCategory.shoppingCartProducts.forEach((shoppingCartProduct)=>{
                if(shoppingCartProduct.weightBy === 'pound' 
                    && shoppingCartProduct.shoppingCartQuantity !== 0
                    && shoppingCartProduct.productId !== scps.productId){
                    totalProductsAdded += 1;

                } else {
                    if(shoppingCartProduct.productId !== scps.productId){
                        totalProductsAdded += shoppingCartProduct.shoppingCartQuantity as number;
                    }
                    
                }
                
            });
        });

        return totalProductsAdded;
    }

    function getShoppingCartProductQty(shoppingCart:ShoppingCart, scps:Product):number {
        const scc = shoppingCart.shoppingCartCategories.filter((shoppingCartCategory)=>{
                        return shoppingCartCategory.storeDepartmentName === scps.storeDepartmentName;
                    })[0];

        const p = scc.shoppingCartProducts.filter((p)=>{
                        return p.productId === scps.productId;
                    })[0];

        //console.log('overLimit:', p.shoppingCartQuantity, scps.shoppingCartQuantity);

        return p.shoppingCartQuantity as number;
    }

    function handleMiniCartProductListItemQuantityPoundClickEvent(
        e:React.MouseEvent,
        currentShoppingCartProductQuantitySelected:string, 
        previousShoppingCartProductQuantitySelected:number, 
        shoppingCartCategories:ShoppingCartCategory[],
        shoppingCartCategorySelected:ShoppingCartCategory, 
        shoppingCartProductSelected:Product
            ):void {
        e.stopPropagation();

        addQuantityInputRef(e.target as HTMLInputElement);

        let updatedShoppingCartCategories:ShoppingCartCategory[] = [];
        const inputElementSelected:HTMLInputElement = e.target as HTMLInputElement;
        const overlayElementSelected:HTMLDivElement = (((e.target as HTMLInputElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).nextElementSibling as HTMLDivElement;

        //add overlay
        (miniCartProductsOverlayRef.current as HTMLDivElement[]).forEach((miniCartProductOverlayRef)=>{
            if(overlayElementSelected === miniCartProductOverlayRef){
            (miniCartProductOverlayRef as HTMLDivElement).classList.remove('hide-element');
            (miniCartProductOverlayRef as HTMLDivElement).classList.add('show-element');
            }

            });

        ((e.target as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.remove('hide-element');
        ((e.target as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.add('show-element');
        ((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.remove('hide-element');
        ((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.add('show-element');
        ((e.target as HTMLInputElement).parentElement as HTMLDivElement).classList.add('expand-item-quantity-button-container');

        if(currentShoppingCartProductQuantitySelected === 'n/a'){
            return;

        } else if(currentShoppingCartProductQuantitySelected === '' && (shoppingCartProductSelected !== previousShoppingCartProductSelected)){
            if(previousShoppingCartProductQuantitySelected > 1){
            (previousShoppingCartProductSelected as Product).showEachPrice = true;
            } else if(previousShoppingCartProductQuantitySelected < 2){
            (previousShoppingCartProductSelected as Product).showEachPrice = false;
            }

        updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                previousShoppingCartProductQuantitySelected, 
                                shoppingCartCategories, 
                                previousShoppingCartCategorySelected as ShoppingCartCategory, 
                                previousShoppingCartProductSelected as Product
                            ); 
                            
        setShoppingCartCategories([...updatedShoppingCartCategories]);

        setShoppingCartProductAdded(false);
        setShoppingCartProductRemoved(false);
        shoppingCart!.shoppingCartProductAdded = false;
        shoppingCart!.shoppingCartProductRemoved =false;

        setUpdatedShoppingCartProductQuantity(true);

        setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

        } else if(currentShoppingCartProductQuantitySelected === '' && (shoppingCartProductSelected === previousShoppingCartProductSelected)){
            return;
        }

    }

    function handleMiniCartProductListItemQuantityClickEvent(
                                        e:React.MouseEvent,
                                        currentShoppingCartProductQuantitySelected:string, 
                                        previousShoppingCartProductQuantitySelected:number, 
                                        shoppingCartCategories:ShoppingCartCategory[],
                                        shoppingCartCategorySelected:ShoppingCartCategory, 
                                        shoppingCartProductSelected:Product
                                    ):void {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        
            //console.log('e.target:', e.target);
        addQuantityInputRef(e.target as HTMLInputElement);
   

        let updatedShoppingCartCategories:ShoppingCartCategory[] = [];
        //const inputElementSelected:HTMLInputElement = e.target as HTMLInputElement;
        //const overlayElementSelected:HTMLDivElement = (((e.target as HTMLInputElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).nextElementSibling as HTMLDivElement;

        /*
        //add overlay
        (miniCartProductsOverlayRef.current as HTMLDivElement[]).forEach((miniCartProductOverlayRef)=>{
            if(overlayElementSelected === miniCartProductOverlayRef){
                (miniCartProductOverlayRef as HTMLDivElement).classList.remove('hide-element');
                (miniCartProductOverlayRef as HTMLDivElement).classList.add('show-element');
            }

        });
        */

        /*
        if(shoppingCartProductSelected.weightBy === 'pound'){

            (e.target as HTMLInputElement).classList.remove('show-element');
            (e.target as HTMLInputElement).classList.add('hide-element');
            ((e.target as HTMLInputElement).nextElementSibling as HTMLSelectElement).classList.remove('hide-element');
            ((e.target as HTMLInputElement).nextElementSibling as HTMLSelectElement).classList.add('show-element-pound');
            ((e.target as HTMLInputElement).parentElement as HTMLDivElement).classList.add('expand-item-quantity-button-container-pound');
            
        } else {
        */
       /*
        ((e.target as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.remove('hide-element');
        ((e.target as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.add('show-element');
        ((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.remove('hide-element');
        ((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.add('show-element');
        ((e.target as HTMLInputElement).parentElement as HTMLDivElement).classList.add('expand-item-quantity-button-container-each');
        //}
        */

       
        if(currentShoppingCartProductQuantitySelected === 'n/a'){
                return;

        } else if(currentShoppingCartProductQuantitySelected === '' && (shoppingCartProductSelected !== previousShoppingCartProductSelected)){
            if(previousShoppingCartProductQuantitySelected > 1){
                (previousShoppingCartProductSelected as Product).showEachPrice = true;
            } else if(previousShoppingCartProductQuantitySelected < 2){
                (previousShoppingCartProductSelected as Product).showEachPrice = false;
            }

            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                        previousShoppingCartProductQuantitySelected, 
                                                        shoppingCartCategories, 
                                                        previousShoppingCartCategorySelected as ShoppingCartCategory, 
                                                        previousShoppingCartProductSelected as Product
                                                    ); 
                                                    
            setShoppingCartCategories([...updatedShoppingCartCategories]);

            setShoppingCartProductAdded(false);
            setShoppingCartProductRemoved(false);
            shoppingCart!.shoppingCartProductAdded = false;
            shoppingCart!.shoppingCartProductRemoved =false;

            setUpdatedShoppingCartProductQuantity(true);

            setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

        } else if(currentShoppingCartProductQuantitySelected === '' && (shoppingCartProductSelected === previousShoppingCartProductSelected)){
            return;
        }
 
    }

    function handleMiniCartProductListItemQuantityClickEventPound(
                    e:React.MouseEvent//,
                    /*currentShoppingCartProductQuantitySelected:string, 
                    previousShoppingCartProductQuantitySelected:number, 
                    shoppingCartCategories:ShoppingCartCategory[],
                    shoppingCartCategorySelected:ShoppingCartCategory, 
                    shoppingCartProductSelected:Product*/
                        ):void {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();


        //console.log('e.target:', e.target);
        //addQuantityInputRef(e.target as HTMLInputElement);
        addQuantitySelectorRef((e.target as HTMLInputElement).nextElementSibling as HTMLSelectElement);


        /*
        let updatedShoppingCartCategories:ShoppingCartCategory[] = [];
        const inputElementSelected:HTMLInputElement = e.target as HTMLInputElement;
        const overlayElementSelected:HTMLDivElement = (((e.target as HTMLInputElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).nextElementSibling as HTMLDivElement;

        //add overlay
        (miniCartProductsOverlayRef.current as HTMLDivElement[]).forEach((miniCartProductOverlayRef)=>{
            if(overlayElementSelected === miniCartProductOverlayRef){
                (miniCartProductOverlayRef as HTMLDivElement).classList.remove('hide-element');
                (miniCartProductOverlayRef as HTMLDivElement).classList.add('show-element');
            }

        });

        //if(shoppingCartProductSelected.weightBy === 'pound'){

        (e.target as HTMLInputElement).classList.remove('show-element');
        (e.target as HTMLInputElement).classList.add('hide-element');
        ((e.target as HTMLInputElement).nextElementSibling as HTMLSelectElement).classList.remove('hide-element');
        ((e.target as HTMLInputElement).nextElementSibling as HTMLSelectElement).classList.add('show-element-pound');
        ((e.target as HTMLInputElement).parentElement as HTMLDivElement).classList.add('expand-item-quantity-button-container-pound');
        (((e.target as HTMLInputElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).classList.add('expand-item-quantity-button-container-pound');

        ((((e.target as HTMLInputElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).previousElementSibling as HTMLDivElement).classList.add('expand-item-price-container-pound');
        */

        /*    
        } else {

            ((e.target as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.remove('hide-element');
            ((e.target as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.add('show-element');
            ((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.remove('hide-element');
            ((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.add('show-element');
            ((e.target as HTMLInputElement).parentElement as HTMLDivElement).classList.add('expand-item-quantity-button-container-each');
        }
        */

        /*
        if(currentShoppingCartProductQuantitySelected === 'n/a'){
            console.log('passed');
            return;

        } else if(currentShoppingCartProductQuantitySelected === '' && (shoppingCartProductSelected !== previousShoppingCartProductSelected)){
            if(previousShoppingCartProductQuantitySelected > 1){
                (previousShoppingCartProductSelected as Product).showEachPrice = true;
            } else if(previousShoppingCartProductQuantitySelected < 2){
                (previousShoppingCartProductSelected as Product).showEachPrice = false;
            }

            updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                    previousShoppingCartProductQuantitySelected, 
                                    shoppingCartCategories, 
                                    previousShoppingCartCategorySelected as ShoppingCartCategory, 
                                    previousShoppingCartProductSelected as Product
                                ); 
                                
            console.log('passed');
            setShoppingCartCategories([...updatedShoppingCartCategories]);
            setUpdatedShoppingCartProductQuantity(true);
            setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

        } else if(currentShoppingCartProductQuantitySelected === '' && (shoppingCartProductSelected === previousShoppingCartProductSelected)){
            console.log('passed');
            return;
        }
        */

    }

    function handleCloseProductQuantityBtnContainer(
        e:React.MouseEvent,
        currentShoppingCartProductQuantitySelected:string,
        previousShoppingCartProductQuantitySelected:number, 
        shoppingCartCategories:ShoppingCartCategory[], 
        previousShoppingCartCategorySelected:ShoppingCartCategory,
        previousShoppingCartProductSelected:Product
        ):void {

        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        //console.log('quantityInputs.length:', quantityInputs.length);
        //console.log('pscps:', previousShoppingCartProductSelected);
        //console.log('pscpqs:', previousShoppingCartProductQuantitySelected);
        //console.log('cscpqs:', currentShoppingCartProductQuantitySelected);
        
        /*
        //remove overlay
        (miniCartProductsOverlayRef.current as HTMLDivElement[]).forEach((miniCartProductOverlayRef)=>{
            (miniCartProductOverlayRef as HTMLDivElement).classList.remove('show-element');
            (miniCartProductOverlayRef as HTMLDivElement).classList.add('hide-element');
        });
    

        (quantityInputs as HTMLInputElement[]).forEach((quantityInputRef)=>{
            if(((quantityInputRef as HTMLInputElement).previousElementSibling as HTMLButtonElement)){
                console.log('passed');
                ((quantityInputRef as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.remove('show-element');
                ((quantityInputRef as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.add('hide-element');
                ((quantityInputRef as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.remove('show-element');
                ((quantityInputRef as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.add('hide-element');
                ((quantityInputRef as HTMLInputElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-each');
            }
        });

        (quantitySelectors as HTMLSelectElement[]).forEach((quantitySelectorRef)=>{
            console.log('passed');
            if((quantitySelectorRef as HTMLSelectElement).previousElementSibling as HTMLInputElement){
                console.log('passed');
                ((quantitySelectorRef as HTMLSelectElement).previousElementSibling as HTMLInputElement).classList.remove('hide-element');
                ((quantitySelectorRef as HTMLSelectElement).previousElementSibling as HTMLInputElement).classList.add('show-element');
                (quantitySelectorRef as HTMLSelectElement).classList.remove('show-element-pound');
                (quantitySelectorRef as HTMLSelectElement).classList.add('hide-element');
                ((quantitySelectorRef as HTMLSelectElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-pound');
                (((quantitySelectorRef as HTMLSelectElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-pound');

                ((((quantitySelectorRef as HTMLSelectElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).previousElementSibling as HTMLDivElement).classList.remove('expand-item-price-container-pound');
            }
        });
        */
        
        if(currentShoppingCartProductQuantitySelected === ''){
            if(previousShoppingCartProductQuantitySelected > 1){
                (previousShoppingCartProductSelected as Product).showEachPrice = true;
            } else if(previousShoppingCartProductQuantitySelected < 2){
                (previousShoppingCartProductSelected as Product).showEachPrice = false;
            }

            const updatedShoppingCartCategories = helperUpdateShoppingCartProductQuantity(
                                                        previousShoppingCartProductQuantitySelected, 
                                                        shoppingCartCategories,
                                                        previousShoppingCartCategorySelected, 
                                                        previousShoppingCartProductSelected
                                                    );

            setShoppingCartCategories([...updatedShoppingCartCategories]);

            setShoppingCartProductAdded(false);
            setShoppingCartProductRemoved(false);
            shoppingCart!.shoppingCartProductAdded = false;
            shoppingCart!.shoppingCartProductRemoved =false;

            setUpdatedShoppingCartProductQuantity(true);

            setShoppingCart(new ShoppingCart({...shoppingCart as ShoppingCart}));

        }

    }



   function setupMiniCartList(shoppingCartCategories:ShoppingCartCategory[]):JSX.Element[]{
        //console.log('sccs:', shoppingCartCategories);
        miniCartCategoryListItemRefs.current.length = 0;
        miniCartProductListRefs.current.length = 0;
        //console.log('shoppingCartCategories test:', 
                    //shoppingCartCategories[0].shoppingCartProducts[0] === shoppingCartRedux!.shoppingCartCategories[0].shoppingCartProducts[0]);
        return shoppingCartCategories.map((shoppingCartCategory, index)=>{
                            return <li ref={getMiniCartCategoryListItemRef} key={index} className="shop-product-mini-cart-category-list-item">
                                        <h3 className={`shop-product-mini-cart-category-heading ${!shoppingCart!.showShoppingCart ? 'hide-element' : elementVisibilityClass}`}>{shoppingCartCategory.storeDepartmentName}</h3>
                                        <ul ref={getMiniCartProductListRef} className="shop-product-mini-cart-product-list">
                                            {setupShoppingCartProductsList(shoppingCartCategory.shoppingCartProducts, 
                                                shoppingCartCategories, shoppingCartCategory)}
                                        </ul>
                                    </li>
                        });
    }

    function addMiniCartProductOverlayRef(el:HTMLDivElement | null):void {
        
        if((miniCartProductsOverlayRef.current as HTMLDivElement[]).length === 0 && el !== null){
            (miniCartProductsOverlayRef.current as HTMLDivElement[]).push(el);
        } else if((miniCartProductsOverlayRef.current as HTMLDivElement[]).length > 0 && el !== null) {
            const mcporFound = (miniCartProductsOverlayRef.current as HTMLDivElement[]).find((mcpor)=>{
                return mcpor === el;
            });

            if(typeof mcporFound === 'undefined'){
                (miniCartProductsOverlayRef.current as HTMLDivElement[]).push(el);
            }
        }
        
    }

    //test format
    function updateQuantityInputRef(shoppingCart:ShoppingCart):void {
        //console.log('quantityInputs:', quantityInputs);
        //console.log('quantityInputs.length:', quantityInputs.length);
        let quantityInputsMatches:HTMLInputElement[] = [];
        if(quantityInputs.length > 0){
            quantityInputs.forEach((quantityInput)=>{
                shoppingCart.shoppingCartCategories.forEach((shoppingCartCategory)=>{
                    shoppingCartCategory.shoppingCartProducts.forEach((shoppingCartProduct)=>{
                        if(shoppingCartProduct.productName === quantityInput.dataset.productname){
                            quantityInputsMatches.push(quantityInput);
                        }
                    });
                });
            });

        }

        //console.log('matches:', quantityInputsMatches);
        quantityInputsMatches.forEach((quantityInput)=>{
            //console.log('quantityInput:', quantityInput);
        });
        setQuantityInputs([...quantityInputsMatches]);
    }

    function addQuantityInputRef(el:HTMLInputElement):void {
        ///console.log('el added:', el);
        quantityInputs.forEach(quantityInput =>{
            //console.log('quantitInput:', quantityInput);
        });

        //console.log('quantityInputs.length:', quantityInputs.length);
        if((quantityInputs as HTMLInputElement[]).length === 0 && el !== null){
            //console.log('test passed1');
            //(quantityInputsRef.current as HTMLInputElement[])[elIndex] = el;
            (quantityInputs as HTMLInputElement[]).push(el);
            setQuantityInputs([...quantityInputs]);
            
        } else if((quantityInputs as HTMLInputElement[]).length > 0 && el !== null) {
            //console.log('test passed2');
            const quantityInputRefFound = (quantityInputs as HTMLInputElement[]).find((quantityInputRef)=>{
                return quantityInputRef === el;
            });
            //console.log('found el:', quantityInputRefFound);
            if(typeof quantityInputRefFound === 'undefined'){
                (quantityInputs as HTMLInputElement[])[(quantityInputs as HTMLInputElement[]).length] = el;
                setQuantityInputs([...quantityInputs]);
            }
            
        }  

    }

    function addQuantitySelectorRef(el:HTMLSelectElement):void {
        //console.log('el added:', el);
        quantitySelectors.forEach(quantitySelector =>{
            //console.log('quantitySelector:', quantitySelector);
        });

        //console.log('quantitySelectors.length:', quantitySelectors.length);
        if((quantitySelectors as HTMLSelectElement[]).length === 0 && el !== null){
            //console.log('test passed1');
            //(quantityInputsRef.current as HTMLInputElement[])[elIndex] = el;
            (quantitySelectors as HTMLSelectElement[]).push(el);
            setQuantitySelectors([...quantitySelectors]);
            
        } else if((quantitySelectors as HTMLSelectElement[]).length > 0 && el !== null) {
            //console.log('test passed2');
            const quantitySelectorRefFound = (quantitySelectors as HTMLSelectElement[]).find((quantitySelectorRef)=>{
                return quantitySelectorRef === el;
            });
            //console.log('found el:', quantitySelectorRefFound);
            if(typeof quantitySelectorRefFound === 'undefined'){
                (quantitySelectors as HTMLSelectElement[])[(quantitySelectors as HTMLSelectElement[]).length] = el;
                setQuantitySelectors([...quantitySelectors]);
            }
            
        }  

    }


    function setupShoppingCartProductQuantityLabel(shoppingCartProduct:Product):JSX.Element | null {
        //console.log('weightBy:', shoppingCartProduct.weightBy);
        //console.log('showEachPrice:', shoppingCartProduct.showEachPrice);
        if(shoppingCartProduct.showEachPrice && shoppingCartProduct.weightBy === 'each'
            && !shoppingCartProduct.showSalePrice){
                return <div className="shop-product-mini-cart-product-list-item-each-price">
                            ${shoppingCartProduct.eachPrice.toFixed(2)}
                            <span className="each-forward-slash-shape">/</span>
                            each
                       </div>
        } else if(shoppingCartProduct.showEachPrice && shoppingCartProduct.weightBy === 'each'
                    && shoppingCartProduct.showSalePrice){
            return <div className="shop-product-mini-cart-product-list-item-each-price">
                        ${shoppingCartProduct.salePrice.toFixed(2)}
                        <span className="each-forward-slash-shape">/</span>
                        each
                   </div>
        } else if(shoppingCartProduct.showEachPrice 
                    && shoppingCartProduct.weightBy === 'pound'
                    && !shoppingCartProduct.showSalePrice) {
            return <div className="shop-product-mini-cart-product-list-item-each-price">
                        ${shoppingCartProduct.eachPrice.toFixed(2)}
                        <span className="each-forward-slash-shape">/</span>
                        lb
                    </div>    
        } else if(shoppingCartProduct.showEachPrice 
                    && shoppingCartProduct.weightBy === 'pound'
                    && shoppingCartProduct.showSalePrice) {
            return <div className="shop-product-mini-cart-product-list-item-each-price">
                        ${shoppingCartProduct.salePrice.toFixed(2)}
                        <span className="each-forward-slash-shape">/</span>
                        lb
                    </div>    
        } else {
            return null;
        }
    }

    function formatTotalPrice(toa:number):string {

        if(toa >= 1000 && toa < 10000){
            let amt = toa.toFixed(2).split('');
            amt.splice(1,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 10000 && toa < 100000){
            let amt = toa.toFixed(2).split('');
            amt.splice(2,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 100000 && toa < 1000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(3,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 1000000 && toa < 10000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(4,0, ',');
            amt.splice(1,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 10000000 && toa < 100000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(5,0, ',');
            amt.splice(2,0, ',');
            return `$${amt.join('')}`;

        } else if(toa >= 100000000 && toa < 1000000000) {
            let amt = toa.toFixed(2).split('');
            amt.splice(6,0, ',');
            amt.splice(3,0, ',');
            return `$${amt.join('')}`;

        } else {
            return `$${toa.toFixed(2)}`;
        } 
    }

    
    function setupShoppingCartProductSalePriceLabel(shoppingCartProduct:Product):JSX.Element {
        //console.log('scscq === ""', shoppingCartProduct.shoppingCartQuantity === '');
        //console.log('scscq !== ""', shoppingCartProduct.shoppingCartQuantity !== '');
        if(shoppingCartProduct.showSalePrice && shoppingCartProduct.shoppingCartQuantity !== ''){
            return <div className="shop-product-mini-cart-product-list-item-total-price-sale-container">
                        <div className="shop-product-mini-cart-product-list-item-total-price-sale">
                            ${shoppingCartProduct.totalPrice.toFixed(2)}
                        </div>
                        <div className="shop-product-mini-cart-product-list-item-total-price price-slash">
                            ${(shoppingCartProduct.eachPrice * (shoppingCartProduct.shoppingCartQuantity as number)).toFixed(2)}
                        </div>
                    </div>
        } else if(shoppingCartProduct.showSalePrice && shoppingCartProduct.shoppingCartQuantity === ''){
            return <div className="shop-product-mini-cart-product-list-item-total-price-sale-container">
                        <div className="shop-product-mini-cart-product-list-item-total-price-sale">
                            ${shoppingCartProduct.totalPrice.toFixed(2)}
                        </div>
                        <div className="shop-product-mini-cart-product-list-item-total-price price-slash">
                            ${(shoppingCartProduct.eachPrice).toFixed(2)}
                        </div>
                    </div>
        } else {
            return <div className="shop-product-mini-cart-product-list-item-total-price">
                        {/*${shoppingCartProduct.totalPrice.toFixed(2)}*/}
                        {formatTotalPrice(shoppingCartProduct.totalPrice)}
                    </div>
        }
        
    }

    function setupProductQuantityDropdownList(shoppingCart:ShoppingCart, shoppingCartProduct:Product):JSX.Element {
        const weightList:number[] = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5];
        //console.log('window.innerHight:',  window.innerHeight / 2);
        const windowMiddlePosition:number = window.innerHeight / 2;
        const checkoutBtnTopPosition:number = productQuantitySelectorBtn.current!.getBoundingClientRect().top;
        const checkoutBtnHeight:number = productQuantitySelectorBtn.current!.getBoundingClientRect().height;
        //console.log('cbh:', checkoutBtnHeight);
        //console.log('cbt:', productQuantitySelectorBtn.current!.getBoundingClientRect().top);

        if(checkoutBtnTopPosition < windowMiddlePosition){
            //console.log('less');
            return (<ul className="shop-product-detail-checkout-btn-dropdown-list-down">
                        <li key={0} className="shop-product-detail-checkout-btn-dropdown-list-item-first">Select A Weight</li>
                        {weightList.map((weight, index)=>{
                            return <li key={index + 1} className="shop-product-detail-checkout-btn-dropdown-list-item"
                                    onClick={(e:React.MouseEvent)=> console.log('test')
                                        /* 
                                            shopProductDetailCheckoutBtnDropdownListSelector(
                                                                                                `${weight} lb`,
                                                                                                shoppingCart,
                                                                                                shoppingCartProduct
                                                                                                )
                                            */}>
                                       {`${weight} lb`}
                                    </li>
                        })}
                </ul>);            

        } else {
            //console.log('more');
            return (<ul className="shop-product-detail-checkout-btn-dropdown-list-up">
                {weightList.map((weight, index)=>{
                    if(index === 0){
                        return <li key={index} className="shop-product-detail-checkout-btn-dropdown-list-item-first">Select A Weight</li>
                    } else {
                        return <li key={index} className="shop-product-detail-checkout-btn-dropdown-list-item"
                                 onClick={(e:React.MouseEvent)=> console.log('test')
                                    /*shopProductDetailCheckoutBtnDropdownListSelector(
                                                                                                `${weight} lb`,
                                                                                                shoppingCart,
                                                                                                shoppingCartProduct
                                    )*/}>
                                    {`${weight} lb`}
                                </li>
                    }
                    
                })}
                </ul>);
        }

        


    }

    function testClickFunction(e:React.MouseEvent):void {
        //console.log('testclickFunction:', e.target);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    function setupShoppingCartProductQuantitySelector(
                                shoppingCartProduct:Product, 
                                shoppingCartCategories:ShoppingCartCategory[],
                                shoppingCartCategory:ShoppingCartCategory
                                ):JSX.Element {

                                    //<i className="fas fa-chevron-down"></i>
        const weightList:number[] = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5];
          //<option key={0} disabled={true} value="">Select a Weight</option>
            return  <select value={`${shoppingCartProduct.shoppingCartQuantity}`}
                        id={`shopping-cart-product-quantity-selector-btn-${shoppingCartProduct.productId}`}
                        className="shopping-cart-product-quantity-selector-btn"
                        onClick={testClickFunction}                   
                        onChange={(e:React.ChangeEvent)=>{
                            miniCartProductListItemQuantityPoundClickEvent(
                                                                e,
                                                                shoppingCartCategories,     
                                                                shoppingCartProduct as Product, 
                                                                shoppingCartCategory as ShoppingCartCategory
                                                                        )
                        }}>
                        {shoppingCartProduct.weightList.map((weight, index)=>{
                            //previous version
                            //return <option key={index} value={`${weight}`}>{weight} lb</option>
                            return <option key={index} value={`${weight}`}>{(weight === 1) ? `${weight} lb` : `${weight} lbs`}</option>
                        })}
                    </select>
                


    }

    function miniCartProductListItemQuantityPoundClickEvent(
                            e:React.ChangeEvent,
                            shoppingCartCategories:ShoppingCartCategory[],     
                            shoppingCartProduct:Product, 
                            shoppingCartCategory:ShoppingCartCategory
                            ):void {
        e.stopPropagation();
        //console.log('e.target:', e.target);
        //console.log('qs:', quantitySelectors);

        //remove overlay
        (miniCartProductsOverlayRef.current as HTMLDivElement[]).forEach((miniCartProductOverlayRef)=>{
            (miniCartProductOverlayRef as HTMLDivElement).classList.remove('show-element');
            (miniCartProductOverlayRef as HTMLDivElement).classList.add('hide-element');
        });

        (quantityInputs as HTMLInputElement[]).forEach((quantityInputRef)=>{
            if(((quantityInputRef as HTMLInputElement).previousElementSibling as HTMLButtonElement)){
                //console.log('passed');
                ((quantityInputRef as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.remove('show-element');
                ((quantityInputRef as HTMLInputElement).previousElementSibling as HTMLButtonElement).classList.add('hide-element');
                ((quantityInputRef as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.remove('show-element');
                ((quantityInputRef as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.add('hide-element');
                ((quantityInputRef as HTMLInputElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-each');
            }
        });

        (quantitySelectors as HTMLSelectElement[]).forEach((quantitySelectorRef)=>{
            //console.log('passed');
            if((quantitySelectorRef as HTMLSelectElement).previousElementSibling as HTMLInputElement){
                //console.log('passed');
                ((quantitySelectorRef as HTMLSelectElement).previousElementSibling as HTMLInputElement).classList.remove('hide-element');
                ((quantitySelectorRef as HTMLSelectElement).previousElementSibling as HTMLInputElement).classList.add('show-element');
                (quantitySelectorRef as HTMLSelectElement).classList.remove('show-element-pound');
                (quantitySelectorRef as HTMLSelectElement).classList.add('hide-element');
                ((quantitySelectorRef as HTMLSelectElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-pound');
                (((quantitySelectorRef as HTMLSelectElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-pound');

                ((((quantitySelectorRef as HTMLSelectElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).previousElementSibling as HTMLDivElement).classList.remove('expand-item-price-container-pound');
            }
        });

        /*
        ((e.target as HTMLSelectElement).previousElementSibling as HTMLInputElement).classList.remove('hide-element');
        ((e.target as HTMLSelectElement).previousElementSibling as HTMLInputElement).classList.add('show-element');
        (e.target as HTMLSelectElement).classList.remove('show-element-pound');
        (e.target as HTMLSelectElement).classList.add('hide-element');
        ((e.target as HTMLSelectElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-pound');
        (((e.target as HTMLSelectElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).classList.remove('expand-item-quantity-button-container-pound');
        ((((e.target as HTMLInputElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).previousElementSibling as HTMLDivElement).classList.remove('expand-item-price-container-pound');
        */

        //setShowShoppingCartProductQuantitySelector(true);
        //setShowProductQuantityDropdownList(!showProductQuantityDropdownList);

        //inputQuantityElement.current!.classList.remove('show-element');
        //inputQuantityElement.current!.classList.add('hide-element');
        //original version
        //(e.target as HTMLInputElement).classList.remove('show-element');
        //(e.target as HTMLInputElement).classList.add('hide-element');
        //((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.remove('hide-element');
        //((e.target as HTMLInputElement).nextElementSibling as HTMLButtonElement).classList.add('show-element');
        //((e.target as HTMLInputElement).parentElement as HTMLDivElement).classList.add('expand-item-quantity-button-container');
        //inputQuantityElementContainer.current!.classList.add('expand-item-quantity-button-container');
        
        
        updateShoppingCartProductQuantityPound(
                            e,
                            shoppingCartCategories,     
                            shoppingCartProduct as Product, 
                            shoppingCartCategory as ShoppingCartCategory
                                                );
        

    }

    function shoppingCartKeydownHandler(e:React.KeyboardEvent):void{
        //console.log('e.press:', e.code);
        if(e.code === 'ArrowUp' || e.code === 'ArrowDown' || e.code === 'KeyE' || e.code === 'Period'){
            e.stopPropagation();
            e.preventDefault();
            return;
        }
    }

    function setupShoppingCartProductsList(shoppingCartProducts:Product[], shoppingCartCategories:ShoppingCartCategory[],
                                             shoppingCartCategory:ShoppingCartCategory):JSX.Element[]{
        miniCartTestContainerRefs.current.length = 0;
        miniCartProductListItemRefs.current.length = 0;
        //console.log('shoppingCartProducts test:', shoppingCartProducts);
        return shoppingCartProducts.map((shoppingCartProduct, index)=>{
                            return <li key={index} ref={getMiniCartTestContainerRef} className="shop-product-mini-cart-test-container">
                                        <div className="delete-mini-cart-product-container">
                                            <div className="delete-mini-cart-product-msg">
                                                Are you sure you want to remove?
                                            </div>
                                            <div className="delete-mini-cart-product-btn-container">
                                                <button type="button" className="delete-mini-cart-product-cancel-btn"
                                                    onClick={showCancelDeleteMiniCartProductMsg}>
                                                        Cancel
                                                </button>
                                                <button type="button" className="delete-mini-cart-product-remove-btn"
                                                    onClick={(e:React.MouseEvent)=>{
                                                        deleteMiniCartProduct(e, shoppingCartProduct, shoppingCartCategories);
                                                        }}>
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                        {/*<DeleteMiniCartProduct showDeleteMiniCartProduct={showDeleteMiniCartProduct}
                                            showCancelDeleteMiniCartProductMsg={showCancelDeleteMiniCartProductMsg} 
                                            shoppingCartProductName={shoppingCartProduct.productName} 
                                            clickedShoppingCartProduct={clickedShoppingCartProduct}/>*/}
                                        
                                        <div ref={getMiniCartProductListItemRef} className="shop-product-mini-cart-product-list-item">
                                            <div className={`shop-product-mini-cart-product-list-item-delete-btn-container ${!shoppingCart!.showShoppingCart ? 'hide-element' : elementVisibilityClass}`}
                                               >
                                                <button type="button" className={`shop-product-mini-cart-product-list-item-delete-btn ${showProductListItemDeleteBtn}`}
                                                    onClick={(e:React.MouseEvent)=>showDeleteMiniCartProductMsg(e, shoppingCartProduct.productName)}>
                                                    <svg width="16" height="16" className="mini-cart-list-delete-btn-svg" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M16 8A8 8 0 100 8a8 8 0 0016 0zM1 8a7 7 0 1114 0A7 7 0 011 8zm7 1.414l2.293 2.293a1 1 0 001.414-1.414L9.414 8l2.293-2.293a1 1 0 00-1.414-1.414L8 6.586 5.707 4.293a1 1 0 00-1.414 1.414L6.586 8l-2.293 2.293a1 1 0 101.414 1.414L8 9.414z" fill="currentColor"></path></svg>
                                                </button>
                                                {/*<i className={`fas fa-times-circle shop-product-mini-cart-product-list-item-delete-btn ${showProductListItemDeleteBtn}`}
                                                    onClick={(e:React.MouseEvent)=>showDeleteMiniCartProductMsg(e, shoppingCartProduct.productName)}></i>*/}
                                            </div>
                                            <div className="shop-product-mini-cart-product-list-item-image-container">
                                                <img src={`https://server.kando-proto-3.com/${shoppingCartProduct.productMiniCartImage}`} className="shop-product-mini-cart-product-list-item-image"/>
                                            </div>
                                            <div className={`shop-product-mini-cart-product-list-item-name-container ${!shoppingCart!.showShoppingCart ? 'hide-element' : elementVisibilityClass}`}>
                                                <div className="shop-product-mini-cart-product-list-item-name">{shoppingCartProduct.productName}</div>
                                            </div>
                                            <div className={`shop-product-mini-cart-product-list-item-price-container ${!shoppingCart!.showShoppingCart ? 'hide-element' : elementVisibilityClass}`}>
                                                {/*original version*/}
                                                {/*<div className="shop-product-mini-cart-product-list-item-total-price">${shoppingCartProduct.totalPrice.toFixed(2)}</div>*/}
                                                {/*shoppingCartProduct.showEachPrice ? <div className="shop-product-mini-cart-product-list-item-each-price">
                                                                                    ${shoppingCartProduct.eachPrice.toFixed(2)}/each
                                                                                </div>
                                                :null*/}
                                                {setupShoppingCartProductSalePriceLabel(shoppingCartProduct)}
                                                {setupShoppingCartProductQuantityLabel(shoppingCartProduct)}
                                            </div>
                                            <div className={`shop-product-mini-cart-product-list-item-quantity-container ${elementVisibilityClass}`}>
                                                {shoppingCartProduct.weightBy === 'pound' 
                                                ? 
                                                    <div className="shop-product-mini-cart-product-list-item-quantity-button-container-pound" ref={inputQuantityElementContainer}>
                                                        {/*<input ref={inputQuantityElement}
                                                            className="shop-product-mini-cart-product-list-item-quantity-pound show-element"
                                                            type="text" data-productname={shoppingCartProduct.productName} readOnly={true} value={`${shoppingCartProduct.shoppingCartQuantity} lb`}
                                                            onClick={(e:React.MouseEvent)=>{
                                                                handleMiniCartProductListItemQuantityClickEventPound(e);
                                                                }}/>*/}
                                                        {/*showShoppingCartProductQuantitySelector 
                                                        ?*/ setupShoppingCartProductQuantitySelector(
                                                                                        shoppingCartProduct,
                                                                                        shoppingCartCategories,
                                                                                        shoppingCartCategory
                                                                                        ) 
                                                        /*: null*/}
                                                    </div>
                                                
                                                :
                                                
                                                    <div className="shop-product-mini-cart-product-list-item-quantity-button-container">
                                                        <button type="button" 
                                                            className="shop-product-mini-cart-product-list-item-quantity-decrement-btn"
                                                            onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                                                                decreaseShoppingCartProductQuantity(e, 
                                                                    currentShoppingCartProductQuantitySelected,
                                                                    previousShoppingCartProductQuantitySelected,
                                                                    shoppingCartCategories,
                                                                    shoppingCartCategory as ShoppingCartCategory,
                                                                    shoppingCartProduct as Product
                                                                    );
                                                                }}>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 2.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V2a1 1 0 00-1-1h-4a1 1 0 00-1-1H7a1 1 0 00-1 1H2a1 1 0 00-1 1v.5zM2.59 4a.5.5 0 00-.493.582l1.764 10.582a1 1 0 00.986.836h6.306a1 1 0 00.986-.836l1.764-10.582A.5.5 0 0013.41 4H2.59zM6 6a1 1 0 00-1 1v6a1 1 0 102 0V7a1 1 0 00-1-1zm3 1a1 1 0 112 0v6a1 1 0 11-2 0V7z" fill="currentColor"></path></svg>
                                                        </button>
                                                        <input /*ref={((el)=>addQuantityInputRef(el, index))}*/
                                                            id={`shop-product-mini-cart-product-list-item-quantity-each-${shoppingCartProduct.productId}`}
                                                            className="shop-product-mini-cart-product-list-item-quantity-each"
                                                            type="number" data-productname={shoppingCartProduct.productName} value={shoppingCartProduct.shoppingCartQuantity}
                                                            onClick={(e:React.MouseEvent)=>{
                                                                handleMiniCartProductListItemQuantityClickEvent(e,
                                                                    currentShoppingCartProductQuantitySelected, 
                                                                    previousShoppingCartProductQuantitySelected,
                                                                    shoppingCartCategories, 
                                                                    shoppingCartCategory as ShoppingCartCategory,
                                                                    shoppingCartProduct as Product
                                                                    );
                                                                }} 
                                                            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
                                                                updateShoppingCartProductQuantity(e,
                                                                        shoppingCartCategories,     
                                                                        shoppingCartProduct as Product, 
                                                                        shoppingCartCategory as ShoppingCartCategory
                                                                        );
                                                                }}
                                                            onKeyDown={shoppingCartKeydownHandler}/>
                                                        <button type="button" 
                                                            className="shop-product-mini-cart-product-list-item-quantity-increment-btn"
                                                            onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                                                                increaseShoppingCartProductQuantity(e,
                                                                    currentShoppingCartProductQuantitySelected,
                                                                    previousShoppingCartProductQuantitySelected,
                                                                    shoppingCartCategories,
                                                                    shoppingCartCategory as ShoppingCartCategory,
                                                                    shoppingCartProduct as Product
                                                                    )
                                                                }}>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M8 0a1 1 0 00-1 1v6H1a1 1 0 000 2h6v6a1 1 0 102 0V9h6a1 1 0 100-2H9V1a1 1 0 00-1-1z" fill="currentColor"></path></svg>
                                                        </button>
                                                    </div>}
                                            </div>
                                            <div ref={addMiniCartProductOverlayRef} 
                                                className="shop-product-mini-cart-product-list-item-overlay">
                                            </div>
                                        </div>
                                    </li>
                        });
    }




    /*
    most recent version of setting up the mini cart
    function setupMiniCartList(shoppingCartProducts:Product[]):JSX.Element[]{
        console.log('shoppingCartProducts test:', shoppingCartProducts);
        return shoppingCartProducts.map((shoppingCartProduct, index)=>{
                            return <li key={index} className="shop-product-mini-cart-list-item">
                                        <span>{shoppingCartProduct.storeDepartmentName}</span>
                                        <i className="fas fa-times-circle"></i>
                                        <img src={shoppingCartProduct.productMiniCartImage}/>
                                        <span>{shoppingCartProduct.productName}</span>
                                        <span className="shop-product-mini-cart-list-item-quantity">{shoppingCartProduct.shoppingCartQuantity}</span>
                                    </li>
                        });
    }
    */

    //console.log('scr21:', shoppingCart!.showShoppingCart, shoppingCartRedux.showShoppingCart, miniCartVisibility);
    if(shoppingCartCategories.length > 0 && shoppingCartRedux.showShoppingCart){
    //previous version
    //if(shoppingCartCategories.length > 0){
    //if(shoppingCartProducts.length > 0){
        return <div ref={getMiniCartContainerRef} /*ref={miniCartContainer}*/ className={`${!shoppingCart!.showShoppingCart ? 'shop-product-mini-cart-list-wrapper-closed' : miniCartVisibility}`}>
                    <div ref={miniCartListContainer} className="shop-product-mini-cart-list-container"
                        onClick={(e:React.MouseEvent)=>{handleCloseProductQuantityBtnContainer(
                            e,
                            currentShoppingCartProductQuantitySelected,
                            previousShoppingCartProductQuantitySelected, 
                            shoppingCartCategories, 
                            previousShoppingCartCategorySelected as ShoppingCartCategory,
                            previousShoppingCartProductSelected as Product   
                        )}}>
                        <button type="button" className="shop-product-mini-cart-heading-container" onClick={handleOpenCloseMiniCart}>
                            <span id="direction-arrow-left" className={`shop-product-mini-cart-heading-angle-left-icon-container ${showLeftArrow}`}>
                                {/*<i className="fas fa-angle-left"></i>*/}
                                <svg className="mini-cart-left-arrrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.24076 14.3492C3.88134 14.7685 3.9299 15.3998 4.34923 15.7592C4.76855 16.1187 5.39985 16.0701 5.75927 15.6508L11.7593 8.65077C12.0803 8.27628 12.0803 7.72368 11.7593 7.34919L5.75927 0.349191C5.39985 -0.0701351 4.76855 -0.118696 4.34923 0.240726C3.9299 0.600147 3.88134 1.23145 4.24076 1.65077L9.68294 7.99998L4.24076 14.3492Z" fill="currentColor"></path></svg>
                            </span>
                            <span id="direction-arrow-right" className={`shop-product-mini-cart-heading-angle-right-icon-container ${showRightArrow}`}>
                                {/*<i className="fas fa-angle-right"></i>*/}
                                <svg className="mini-cart-right-arrrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.24076 14.3492C3.88134 14.7685 3.9299 15.3998 4.34923 15.7592C4.76855 16.1187 5.39985 16.0701 5.75927 15.6508L11.7593 8.65077C12.0803 8.27628 12.0803 7.72368 11.7593 7.34919L5.75927 0.349191C5.39985 -0.0701351 4.76855 -0.118696 4.34923 0.240726C3.9299 0.600147 3.88134 1.23145 4.24076 1.65077L9.68294 7.99998L4.24076 14.3492Z" fill="currentColor"></path></svg>
                            </span>
                            <span className="shop-product-mini-cart-heading">Cart</span>
                        </button>
                        <div ref={getMiniCartCategoryListContainerRef} className="shop-product-mini-cart-category-list-container">
                            <ul className="shop-product-mini-cart-category-list">
                                {setupMiniCartList(shoppingCartCategories)}
                            </ul>
                        </div>
                        <div ref={getMiniCartFooterContainerRef} className={`shop-product-mini-cart-view-cart-footer ${!shoppingCart!.showShoppingCart ? 'hide-element' : elementVisibilityClass}`}>
                            <div className="mini-cart-item-total-container">
                                <div className="mini-cart-item-total-heading">Estimated total</div>
                                {/*<div className="mini-cart-item-total-amount">${totalOrderAmount.toFixed(2)}</div>*/}
                                <div className="mini-cart-item-total-amount">{formatTotalPrice(totalOrderAmount)}</div>
                            </div>
                            <div className="shop-product-mini-cart-view-cart-btn-container">
                                <button type="button" className="shop-product-mini-cart-view-cart-btn">
                                    <Link to="/cart" className="shop-product-mini-cart-view-cart-btn-link"
                                        onClick={(e:React.MouseEvent)=>loadNavLink(e, '/cart', navigate)}>Review cart</Link></button>
                            </div>
                        </div>
                    </div>
                </div>

    } else {
        return null;
    }

}

export default ShopProductMiniCart;
/*previous version using connect()
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopProductMiniCart);
*/