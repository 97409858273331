import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate, NavigateFunction } from "react-router-dom";
import ShopLoadDetails from './redux/shopLoadDetails';
import {AddShopLoadDetails, addShopLoadDetails} from './redux/actions';
import {addProductKeywordSearch, selectProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppDispatch} from './redux/hooks';
import LoaderAnimation from './LoaderAnimation';
import loadNavLinkHelper from './loadNavLinkHelper';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import GenericErrorMessage from './GenericErrorMessage';
import formatLinkString from './helper-format-link-string';
import './ShopOverview.css';


interface Props {
    //path:string;
    //shopLoadDetails?:ShopLoadDetails;
    //addShopLoadDetails?:(ShopLoadDetails:ShopLoadDetails)=>AddShopLoadDetails;
}

/*
interface State {
    shopLoadDetails:ShopLoadDetails;
}

function mapStateToProps(state:State) {
    const { shopLoadDetails } = state;
    console.log('shopLoadDetails test 6-7:', shopLoadDetails);
    return {
        shopLoadDetails
    };

}

const mapDispatchToProps = {
    addShopLoadDetails
};
*/


const ShopOverview:React.FunctionComponent<Props> =(props:Props)=>{
    //const {addShopLoadDetails} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const [shopLoadDetails, setShopLoadDetails] = useState<ShopLoadDetails>(props.shopLoadDetails!);
    const [storeDepartments, setStoreDepartments] = useState<StoreDepartment[] | []>([]);
    const [showShopOverview, setShowShopOverview] = useState(false);
    //const [shopLoadDetails, setShopLoadDetails] = useState<ShopLoadDetails>(props.shopLoadDetails!);
    const [controller, setController] = useState<AbortController>(new AbortController());
    //console.log('sld:', props.shopLoadDetails!);
    const [isLoading, setIsLoading] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [linkClicked, setLinkClicked] = useState(false);

/*
    useEffect(()=>{
        setShopLoadDetails(props.shopLoadDetails!);
    },[props.shopLoadDetails]);
    */
    

    useEffect(()=>{
        //console.log('data:');
        //window.scrollTo(0,0);
        //setShowShopOverview(false);
        //let setShopOverviewState:boolean = true;
        //current version
        //fetch("http://localhost:9500/shop",{
        fetch(`https://server.kando-proto-3.com/shop-overview-mariadb`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }//,
            //signal:controller.signal
        })
        .then((res)=>res.json())
        .then((data:StoreDepartment[])=>{
            //console.log('data:', data);
            if(data.length > 0){
                let sds:StoreDepartment[] = data.map((storeDepartment)=>{
                    return new StoreDepartment({
                                        departmentId:storeDepartment.departmentId,
                                        departmentName:storeDepartment.departmentName,
                                        departmentImage:storeDepartment.departmentImage,
                                        departmentCategories: addDepartmentCategories(storeDepartment.departmentCategories)
                    });
                });

                //console.info('yes');
                setStoreDepartments(sds);
                setShowShopOverview(true);
                setIsLoading(false);

                //console.log('sds:', sds);

            } else {
                setStoreDepartments([])
                setShowShopOverview(false);
                setIsLoading(false);
            }
        })
        .catch(err=>{
            //console.log('error shopOverview:', err);
            setShowErrorMessage(true);
            setIsLoading(false);
        });

        dispatch(addProductKeywordSearch!({
            keywordSearch:'',
            priceRangeSearch:'relatedProducts',
            storeDepartmentSearch:'none',
            departmentCategorySearch:'none',
            sentFromKeywordList:false,
            clearKeywordSearch:true
                }));
                

    },[/*props.shopLoadDetails!*/]);

    /*
    useEffect(()=>{
        return function cleanUp(){
            //setShopDepartmentState = false;
            controller.abort();
        }
    },[]);
    */

    function addDepartmentCategories(departmentCategories:DepartmentCategory[]):DepartmentCategory[]{
        return departmentCategories.map((departmentCategory)=>{
            return new DepartmentCategory({
                departmentId:departmentCategory.departmentId,
                departmentCategoryId:departmentCategory.departmentCategoryId,
                departmentCategoryName: departmentCategory.departmentCategoryName,
                departmentName: departmentCategory.departmentName
            })
        });
    }

    function setupStoreDepartments(storeDepartments:StoreDepartment[]):JSX.Element[]{
        return storeDepartments.map((storeDepartment, index)=>{
            return <li key={index} className="shop-overview-store-department-list-item">
                        <div className="shop-overview-store-department-image-link-container">
                            <Link to={`${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${storeDepartment.departmentId}`} className="shop-overview-store-department-image-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${storeDepartment.departmentId}`)}>
                                <img className="shop-overview-store-department-image" alt={storeDepartment.departmentName} height="152" width="152" src={`https://server.kando-proto-3.com/${storeDepartment.departmentImage}`}/>
                            </Link>
                        </div>
                        <div className="shop-overview-store-department-name-link-container">
                            <Link to={`${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${storeDepartment.departmentId}`} className="shop-overview-store-department-name-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${storeDepartment.departmentId}`)}>{storeDepartment.departmentName}</Link>
                        </div>
                        {setupStoreDepartmentCategories(storeDepartment)}
                    </li>
        })
    }

    function setupStoreDepartmentCategories(storeDepartment:StoreDepartment):JSX.Element[]{
        let departmentCategories:DepartmentCategory[] = storeDepartment.departmentCategories;
        return departmentCategories.map((departmentCategory, index)=>{
            return <div className="shop-overview-store-department-category-name-link-container" key={index}>
                        <Link to={`${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${encodeURIComponent(formatLinkString(departmentCategory.departmentCategoryName))}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`} className="shop-overview-store-department-category-name-link"
                            onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${encodeURIComponent(formatLinkString(departmentCategory.departmentCategoryName))}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`)}>{departmentCategory.departmentCategoryName}</Link>
                    </div>
        });
    }

    function reloadShopOverview(e:React.MouseEvent):void {
        addShopLoadDetails!({reload:true,
                            storeDepartmentName:'none',
                            departmentCategoryName:'none',
                            storeDepartmentId:0,
                            departmentCategoryId:0});
    }

    function loadNavLink(e:React.MouseEvent, pathname:string):void {
        e.preventDefault();
        //console.log('posy14:', window.scrollY);
        setLinkClicked(true);
        //window.sessionStorage.setItem('admin-scroll-position', `${window.scrollY}`);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        navigate(pathname);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        
    }

    function addDepartmentHandler(e:React.MouseEvent, pathname:string, navigate:NavigateFunction):void {
        e.preventDefault();
        loadNavLinkHelper(e, pathname, navigate);

    }

    if(linkClicked){
        return <div>
                    <ProductSearch/>
                    <MainNavbar2/>
                    <div className="lc-loader-animation-container">
                        <LoaderAnimation/>
                    </div>;
                </div>

    } else {
        //if(storeDepartments.length > 0 && showShopOverview){
        return  <div className="shop-overview-department-container-wrapper">
                    <ProductSearch/>
                    <MainNavbar2/>
                    <MenuListMobileNav/>
                    <div className="shop-overview-department-container">
                        <ul className="shop-overview-breadcrum-container">
                            <li className="sobli">
                                <Link to="/" className="shop-overview-home-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/')}>Home</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="sobli">Shop</li>
                        </ul>
                        <div className="shop-overview-store-department-heading-container">
                            <h1 className="shop-overview-store-department-heading">Shop by Department</h1>
                        </div>
                        {
                            storeDepartments.length > 0 && showShopOverview && !isLoading && !showErrorMessage
                            ?
                            <div className="shop-overview-store-department-list-container">
                                <ul className="shop-overview-store-department-list">
                                    {setupStoreDepartments(storeDepartments)}
                                </ul>
                                {/*<div className="shop-overview-promotion-content-container">
                                    <div className="sopc-item-container">
                                        <div className="sopc-img-container">
                                            <img className="sopc-img" alt="KODI items" height="235" width="235" src={"https://server.kando-proto-3.com/kodi-bundle-235x235.webp"} />
                                        </div>
                                        <div className="sopc-img-content-info-container">
                                            <p className="sopc-promotion-discount">KODI by H‑E‑B</p>
                                            <div className="sopc-promotion-description">Raise your expeditions!</div>
                                            <p className="sopc-promotion-shop-now-container">
                                                <Link to="https://kando-proto-3.com/shop/overview" data-gtm-vis-recent-on-screen-8857370_80="696" data-gtm-vis-first-on-screen-8857370_80="696" data-gtm-vis-total-visible-time-8857370_80="100" data-gtm-vis-has-fired-8857370_80="1">
                                                    Shop Now
                                                    <span></span>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sopc-item-container">
                                        <div className="sopc-img-container">
                                            <img className="sopc-img" alt="pet food" src={"https://server.kando-proto-3.com/heritage-ranch-square-with-dog-and-cat.webp"} />
                                        </div>
                                        <div className="sopc-img-content-info-container">
                                            <p className="sopc-promotion-discount">Heritage Ranch Pet Food</p>
                                            <div className="sopc-promotion-description">Available for both cats and dogs!</div>
                                            <p className="sopc-promotion-shop-now-container">
                                                <Link to="https://kando-proto-3.com/shop/overview" data-gtm-vis-recent-on-screen-8857370_80="711" data-gtm-vis-first-on-screen-8857370_80="711" data-gtm-vis-total-visible-time-8857370_80="100" data-gtm-vis-has-fired-8857370_80="1">
                                                    Shop Now
                                                    <span></span>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                */}
                            </div>
                            :
                            null
                        }

                        {
                            (!showShopOverview && !isLoading && !showErrorMessage)
                            ?
                            <div className="hp-no-results-msg-wrapper">
                                <div className="ahpl-no-img-msg-wrapper">
                                    <div className="hplib-empty-item-slider-msg-container">
                                        <h2 className="hplib-empty-item-slider-msg-heading">No departments have been added.</h2>
                                        <p className="hplib-empty-item-slider-msg">Add a department to get started.</p>
                                    </div>
                                    <div className="so-img-container">
                                        <img className="hplib-empty-item-slider-img" src="https://server.kando-proto-3.com/folder-tree-icon.svg"/>
                                    </div>
                                    <div className="ahpl-add-image-btn-container">
                                        <button type="button" className="so-add-department-btn">
                                            <Link to="/admin/store-structure"
                                            className="so-add-department-btn-link" onClick={(e:React.MouseEvent)=>addDepartmentHandler(e, '/admin/store-structure', navigate)}>Add Department</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }

                        {
                            isLoading
                            ?
                            <div className="so-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null
                        }

                        {
                            showErrorMessage
                            ?
                            <div className="so-generic-error-msg-wrapper">
                                <GenericErrorMessage/>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>

        /*} else {
            return <div className="so-loader-animation-container">
                        <LoaderAnimation/>
                    </div>;
        }*/
    }
}

export default ShopOverview;
/*
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopOverview);
*/
