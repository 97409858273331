import React, {useState, useEffect, useRef} from 'react';
import AddProduct from './AddProduct';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import EditProductStoreStructure from './EditProductStoreStructure';
import AddProductStoreStructure from './AddProductStoreStructure';
import Product from './product';
import SearchFilterTerm from './searchFilterTerm';
import SearchFilter, {SearchTerm} from './searchFilter';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import PriceRangeSelectControl from './PriceRangeSelectControl';
import transformDate from './helper-transform-date';
import LoaderAnimation from './LoaderAnimation';
import FormErrorMessage, {FormError} from './FormErrorMessage';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import Footer from './Footer';
import loadNavLink from './loadNavLinkHelper';
import GenericErrorMessage from './GenericErrorMessage';
import './StoreStructureProducts.css';

interface Props {
    //path:string;
}

const resultsPerPage = ['5', '10', '20', '30'];

const StoreStructureProducts2: React.FunctionComponent<Props> = (props:Props) => {
    const navigate = useNavigate();
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [showEditProduct, setShowEditProduct] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [productToEdit, setProductToEdit] = useState<Product|null>(null);
    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    //search properties
    const [productNameSearchText, setProductNameSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No products have been added.');

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(10);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Product[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [elementName3, setElementName3] = useState('results-per-page');
    const [resultsPerPageOptionSelected, setResultsPerPageOptionSelected] = useState('10');
    const [resultsPerPageOptions, setResultsPerPageOptions] = useState<string[]>(resultsPerPage);

    const [isLoading, setIsLoading] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [productPrice, setProductPrice] = useState('5.00');
    const [minimumPrice, setMinimumPrice] = useState('0');
    const [stepIncrementAmt, setStepIncrementAmt] = useState('0.01');

    const [priceRangeSelectControlName, setPriceRangeSelectControlName] = useState('price-search-operator');
    const [priceRangeOperatorNameSelected, setPriceRangeOperatorNameSelected] = useState('less than');
    const [priceRangeOperatorNames, setPriceRangeOperatorNames] = useState<string[]>(['equal to', 'greater than', 'less than']);

    const productKeywordRef = useRef<HTMLInputElement>(null);
    const productPriceRef = useRef<HTMLDivElement>(null);
    const pKeywordRefs = useRef<HTMLInputElement[]>([]);

    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });

    //const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);

    const sfgErrorMsgRefs = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}[]>([]);

    const productContainerRefs = useRef<HTMLDivElement[]>([]);
    const existingSearchResultsHeight = useRef<number>(0);

    const [showKeywordSearchLimitError, setShowKeywordSearchLimitError] = useState(false);
    const [showPriceSearchLimitError, setShowPriceSearchLimitError] = useState(false);

    const [temporaryClearSearchResults, setTemporaryClearSearchResults] = useState(false);

    //const formRef = useRef<HTMLFormElement>(null);

    const formRefs = useRef<HTMLFormElement[]>([]);

    const [showFooter, setShowFooter] = useState(false);

    const loaderRefs = useRef<HTMLDivElement[]>([]);

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);


    const isLoadingTimeoutRef = useRef<number>(0);


    useEffect(()=>{

        /*
        fetch(`http://localhost:9500/get-all-products-mariadb`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then(({products, searchFilters})=>{
            //console.log('products:', products);
            //console.log('sfs:', searchFilters);
            const ps = addProducts(products);
            setProducts(ps);
            const sfs = addSearchFilters(searchFilters);
            //console.log('sfs:', sfs);
            setSearchFilters(sfs);

        })
        .catch((err)=>console.error('no products found fetch error:', err.message));
        */

        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);

        
        getAllProducts();
        
    },[]);


    useEffect(()=>{

        if(imageRefsLoaded){

            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();

        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previousv version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    function getProductContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && productContainerRefs.current.length === 0){
            productContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = productContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                productContainerRefs.current.push(el);
            }

        }

        //console.log('prkrs:', productContainerRefs.current);
    }

    function getPKeywordRef(el:HTMLInputElement|null):void {
        if(el !== null){
            pKeywordRefs.current.length = 0;
        }

        if(el !== null && pKeywordRefs.current.length === 0){
            pKeywordRefs.current.push(el);

        } else if(el !== null) {
            const found = pKeywordRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                pKeywordRefs.current.push(el);
            }

        }

        //console.log('prkrs:', pKeywordRefs.current);

        if(window.innerWidth >= 670){
            pKeywordRefs.current[0]!.placeholder = 'Enter product name, product category, department category or department name';

        } else {
            pKeywordRefs.current[0]!.placeholder = '';
        }

    }

    useEffect(()=>{
        window.addEventListener('resize', sspcResizeHandler);
        return cleanupSSPCResizeHandler;
    },[]);

    function sspcResizeHandler(e:Event):void {
        if(window.innerWidth >= 670){
            pKeywordRefs.current[0]!.placeholder = 'Enter product name, product category, department category or department name';

        } else {
            pKeywordRefs.current[0]!.placeholder = '';
        }

        if(typeof sfgErrorMsgRefs.current![0] !== 'undefined'){
            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
        }
    }

    function cleanupSSPCResizeHandler():void {
        window.removeEventListener('resize', sspcResizeHandler);
    }

    function getFormRef(el:HTMLFormElement|null):void {
        if(formRefs.current.length > 0){
            formRefs.current.length = 0;
        }

        if(el !== null && formRefs.current.length === 0){
            formRefs.current.push(el);

        } else if(el !== null) {
            const found = formRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                formRefs.current.push(el);
            }

        }
    }

    type Ref = {
        sfgemcRef:React.RefObject<HTMLDivElement>
    };

    function getSfgErrorMsgRef(el: Ref|null):void {
        if(sfgErrorMsgRefs.current.length > 0){
            sfgErrorMsgRefs.current.length = 0;
        }

        if(el !== null && sfgErrorMsgRefs.current.length === 0){
            sfgErrorMsgRefs.current.push(el);

        } else if(el !== null) {
            const found = sfgErrorMsgRefs.current.find((ir)=>{
                                return ir.sfgemcRef === el.sfgemcRef;
                            });

            if(typeof found === 'undefined'){
                sfgErrorMsgRefs.current.push(el);
            }

        }

        //console.log('sfgErrorMsgRefs:', sfgErrorMsgRefs.current);
    }

    function getLoaderRef(el: HTMLDivElement|null):void {
        if(loaderRefs.current.length > 0){
            loaderRefs.current.length = 0;
        }

        if(el !== null && loaderRefs.current.length === 0){
            loaderRefs.current.push(el);

        } else if(el !== null) {
            const found = loaderRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                loaderRefs.current.push(el);
            }

        }

        //console.log('loaderRefs:', loaderRefs);

        if(typeof loaderRefs.current[0] !== 'undefined'){
            const yValue = formRefs.current[0].getBoundingClientRect().bottom + 66;
            loaderRefs.current[0].style.transform = `translate(-50%, ${yValue}px)`;
        }


    }

    function getAllProducts(lcbPosition?:number):void {
        fetch(`https://server.kando-proto-3.com/get-all-products-mariadb`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then(({products, searchFilters})=>{
            //console.log('products:', products);
            //console.log('sfs:', searchFilters);
            if(products.length === 0){
                //show generic info message if no products are found
                setNoSearchResultsMessage(`No products have been added`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoading(false);

                setShowFooter(true);

            } else {
                const ps = addProducts(products);
                setProducts(ps);
                const sfs = addSearchFilters(searchFilters);
                //console.log('sfs:', sfs);
                setSearchFilters(sfs);
                getAnyItemList(typeof lcbPosition !== 'undefined' ? lcbPosition : listChangerBtnPosition, ps, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, ps);
                setIsLoading(false);

                setShowFooter(true);

                //possible scroll bar is additional 16px
                //adjust item count based on items per list setting
                //previous version
                let nrh = 0;
                if(itemsPerList < ps.length){
                    nrh = (itemsPerList * 94) + 766 + 16 + 57;

                } else {
                    nrh = (ps.length) * 94 + 766 + 16 + 57;
                }

                
                if(nrh > 950){
                    productContainerRefs.current[0].style.minHeight = `${nrh}px`;
                }
                

            }



        })
        .catch((err)=>{
            console.error('no products found fetch error:', err.message);
            setShowErrorMessage(true);
            setIsLoading(false);

            setShowFooter(true);
        });
    }


    function resetProducts():void {
        //reset form errors
        //productKeywordRef.current!.classList.remove('product-info-error');
        pKeywordRefs.current[0]!.classList.remove('product-info-error');
        productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        setShowKeywordSearchLimitError(false);
        setShowPriceSearchLimitError(false);

        setProducts([]);
        setProductNameSearchText('');
        setProductPrice('5.00');
        setPriceRangeOperatorNameSelected('less than');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(true);

        setItemsList(null);
        setListChangerBtnPosition(0);

        getAllProducts(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        productContainerRefs.current[0].style.minHeight = `950px`;
    }


    function handleAddProduct(e:React.MouseEvent):void {
        e.stopPropagation();

        setShowKeywordSearchLimitError(false);
        setShowPriceSearchLimitError(false);

        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setProductNameSearchText('');
        setShowAddProduct(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        productContainerRefs.current[0].style.minHeight = `950px`;

    }

    function addProducts(products:Product[]):Product[] {
        //console.log('pt:', products);
        const ps = products.map((p)=>{
                        return new Product({...p});
                    });

                    //console.log('ps:', ps);

        return ps.map((p)=>{
                    p.searchFilterTerms = addSearchFilterTerms(p.searchFilterTerms);
                    return p;
                });     
    }

    function addSearchFilterTerms(sfts:SearchFilterTerm[]):SearchFilterTerm[]{
        return sfts.map((sft)=>{
                    return new SearchFilterTerm(sft);
                });
    }

    function addSearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        const usfs =  sfs.map((sf)=>{
                            return new SearchFilter({...sf});
                        });

        return usfs.map((sf)=>{
                    sf.searchTerms = addSearchTerms(sf.searchTerms);
                    return sf;
                })
    }

    function addSearchTerms(sts:SearchTerm[]):SearchTerm[]{
        return sts.map((st)=>{
                    return new SearchTerm({...st});
                });
    }

    function handleEditProduct(product:Product):void {
        //console.log('pte:', product);
        setShowKeywordSearchLimitError(false);
        setShowPriceSearchLimitError(false);
        setProductToEdit(product);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setProductNameSearchText('');
        setShowEditProduct(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        productContainerRefs.current[0].style.minHeight = `950px`;
    }

    function handleRemoveProduct(product:Product):void {
        //reset form errors
        //productKeywordRef.current!.classList.remove('product-info-error');
        pKeywordRefs.current[0]!.classList.remove('product-info-error');
        productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        setShowPriceSearchLimitError(false);
        setShowKeywordSearchLimitError(false);


        fetch(`https://server.kando-proto-3.com/delete-individual-product`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(product)
        })
        .then((res)=>{

            setIsLoadingDelete(true);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            return res.json()})
        .then(({productDeleted})=>{
            //console.log('product deleted16:', productDeleted);

            if(productDeleted){
                const ups = products.filter((p)=>{
                                return p.productId !== product.productId;
                            });

                //let user know there are no products left in search list
                if(ups.length === 0){
                    setNoSearchResultsMessage(`All products have been deleted from this search results page.  
                                                Try another search results page, keyword search, reset the product list or add more products.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setProductNameSearchText('');
                    setProducts(ups);
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                    
                } else {

                    //console.log('r15:', (ups.length / itemsPerList), listChangerBtnPosition);

                    if((ups.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, ups, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, ups);

                    } else {
                        getAnyItemList(listChangerBtnPosition, ups, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, ups);
                    }

                    setProductNameSearchText('');
                    setProducts(ups);
                    //getAnyItemList(listChangerBtnPosition, ups, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, ups);
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                }
                
               
                

            } else {
                throw new Error('a problem occurred while deleting the product');
            }


            
        })
        .catch((err)=>{
            //console.log('delete product from db fetch err:', err.message);
            setShowErrorMessage(true);
        });
    }

    function getExistingSearchFiltersCount(sfts:SearchFilterTerm[], sfat:string, sfa:string):number {
        //console.log('sfts:', sfts);
        let found:string[] = [];


        if(found.length === 0 && sfts.length > 0){
            sfts.forEach((sft)=>{
                if(sft.searchFilterAreaType === sfat && sft.searchFilterArea === sfa && found.length === 0){
                    //console.log('found3-8 1:');
                    found.push(sft.searchFilterTermName);
                }
            });
        }

        if(found.length > 0 && sfts.length > 0){
            sfts.forEach((sft)=>{
                let sfnFound = false;
                //console.log('found:', found);
                found.forEach((f)=>{
                    //console.log('f:', f, 'sftn:', sft.searchFilterTermName, f === sft.searchFilterTermName);
                    if(sft.searchFilterAreaType === sfat && sft.searchFilterArea === sfa && sft.searchFilterTermName === f){
                        sfnFound = true;
                    }   
                });
                
                if(sft.searchFilterAreaType === sfat && sft.searchFilterArea === sfa && !sfnFound){
                    //console.log('sfnFound:', sfnFound, 'sft:', sft.searchFilterTermName);
                    found.push(sft.searchFilterTermName);
                    //console.log('found:', found);
                }
            });
        }

        //console.log('existing unique search filters found:', found);
        return found.length;  
    }

    function getAvailableSearchFiltersCount(sfs:SearchFilter[], sfat:string, sfa:string):number {
        let found:string[] = [];


        if(found.length === 0 && sfs.length > 0){
            sfs.forEach((sf)=>{
                if(sf.searchFilterAreaType === sfat && sf.searchFilterArea === sfa && found.length === 0){
                    found.push(sf.filterName);
                }
            });
        }

        if(found.length > 0 && sfs.length > 0){
            sfs.forEach((sf)=>{
                let sfnFound = false;
                found.forEach((f)=>{
                    if(sf.searchFilterAreaType === sfat && sf.searchFilterArea === sfa && sf.filterName === f){
                        sfnFound = true;
                    }   
                });

                if(sf.searchFilterAreaType === sfat && sf.searchFilterArea === sfa && !sfnFound){
                    found.push(sf.filterName);
                }
            });
        }

        //console.log('available unique search filters found:', found.length);
        return found.length;        
    }

    function getSearchFilterSetupPercentage(esfc:number, asfc:number):JSX.Element | null {
        const amount = esfc / asfc;
        //console.log('amount:', amount);
        if(amount === 1){
            return null;
        } else if(amount > 0 && amount < 1){
            return <td style={{backgroundColor:'rgb(255, 255, 204)'}} colSpan={2}>{`${(amount * 100).toFixed(2)}% complete`}</td>;
        } else {
            return <td style={{backgroundColor:'rgb(255, 255, 204)', zIndex:3}} colSpan={2}>{`${0}% complete`}</td>;
        }
    
    }

    function getDefaultSetupStyles(esfc:number, asfc:number):{display:string, backgroundColor:string} {
        const amount = esfc / asfc;
        return amount !== 1 
                        ? {display:'none', backgroundColor:'#fff'} 
                        : {display:'', backgroundColor:'#fff'};
    }

    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }



    function getUpdatedProduct(product:Product):void {
        fetch(`https://server.kando-proto-3.com/get-updated-product/${product.productId}`, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then(({updatedProducts, searchFilters})=>{
            //console.log('psfs:', updatedProducts, searchFilters);
            if(updatedProducts.length === 0 && searchFilters.length === 0){
                setShowErrorMessage(true);
                setIsLoading(false);

            } else {
                const updatedProduct = updatedProducts[0];

                //console.log('p15:', updatedProduct);

                const productList = products.map((p)=>{
                    if(p.productId === updatedProduct.productId){
                        return updatedProduct;

                    } else {
                        return p;
                    }
                });

                const ps = addProducts(productList);
                setProducts(ps);
                const sfs = addSearchFilters(searchFilters);
                //console.log('sfs:', sfs);
                setSearchFilters(sfs);
                getAnyItemList(listChangerBtnPosition, ps, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, ps);
                setIsLoading(false);




            }
        })
        .catch((err)=>console.log('get updated product subcategory error:', err.message));
    }


    function sortItemsByDateSubmitted(sortDirection:string):void {
        if(sortDirection === 'asc'){
            //console.log('sort29');
            const ps = products.sort(compareAscendingDates);
            setProducts(ps);
            getAnyItemList(listChangerBtnPosition, ps, itemsPerList, showItemsPerList);

        } else {
            //console.log('sort29');
            const ps = products.sort(compareDescendingDates);
            setProducts(ps);
            getAnyItemList(listChangerBtnPosition, ps, itemsPerList, showItemsPerList);
        }

    }

    function compareDescendingDates(a:Product, b:Product):number {
        const aDate = new Date(a.productDateAdded).getTime();
        const bDate = new Date(b.productDateAdded).getTime();

        return bDate - aDate;
    }

    function compareAscendingDates(a:Product, b:Product):number {
        const aDate = new Date(a.productDateAdded).getTime();
        const bDate = new Date(b.productDateAdded).getTime();

        return aDate - bDate;
    }



    function setupProductsTable(itemsList:object[]):JSX.Element {
        return <div className="ssps-table-container">
                    <table className="ssps-table">
                        <thead>
                            <tr>
                                <th className="th-ps-p">Product</th><th className="th-ps-dept">Department</th><th className="th-ps-d-esf"><span>Exist</span> <span>SFS</span></th><th className="th-ps-d-asf"><span>Avail</span> <span>SFS</span></th><th className="th-ps-dc"><span>Department</span> <span>Category</span></th><th className="th-ps-dc-esf"><span>Exist</span> <span>SFS</span></th><th className="th-ps-dc-asf"><span>Avail</span> <span>SFS</span></th><th className="th-ps-pc"><span>Product</span> <span>Category</span></th><th className="th-ps-pc-esf"><span>Exist</span> <span>SFS</span></th><th className="th-ps-pc-asf"><span>Avail</span> <span>SFS</span></th><th className="th-ps-ql"><span>Qty</span> <span>Limit</span></th><th className="th-ps-price">Price</th><th className="th-ps-img"><span>Product</span> <span>Image</span></th><th className="th-ps-date"><div className="admin-date-added-header-container"><span>Date Added</span><span className="admin-date-added-svg-btn-container">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <button type="button" aria-label="ascending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('asc')}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <path className="sort-up" d="M200 100 L100 0 L0 100 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </svg>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <button type="button" aria-label="descending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('desc')}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <path className="sort-down" d="M200 0 L100 100 L0 0 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </svg>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </span></div></th><th className="th-ps-action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(itemsList as Product[]).map((p, i)=>{
                        //console.log(`pn:${p.productName}, dn:${p.storeDepartmentName}, dcn:${p.departmentCategoryName}, pcn:${p.productCategoryName}, psc:${p.productSubCategoryName}, pda:${p.productDateAdded}`)
                                                return <tr key={i}>
                                                            <td><div className="ssps-td">{p.productName}</div></td>
                                                            <td><div className="ssps-td">{p.storeDepartmentName}</div></td>
                                                            {/*getSearchFilterSetupPercentage(getExistingSearchFiltersCount(p.searchFilterTerms, 'Department', p.storeDepartmentName), getAvailableSearchFiltersCount(searchFilters, 'Department', p.storeDepartmentName))}
                                                            {<td style={getDefaultSetupStyles(getExistingSearchFiltersCount(p.searchFilterTerms, 'Department', p.storeDepartmentName), getAvailableSearchFiltersCount(searchFilters, 'Department', p.storeDepartmentName))}>{getExistingSearchFiltersCount(p.searchFilterTerms, 'Department', p.storeDepartmentName)}</td>}
                                                            {<td style={getDefaultSetupStyles(getExistingSearchFiltersCount(p.searchFilterTerms, 'Department', p.storeDepartmentName), getAvailableSearchFiltersCount(searchFilters, 'Department', p.storeDepartmentName))}>{getAvailableSearchFiltersCount(searchFilters, 'Department', p.storeDepartmentName)}</td>*/}
                                                            {<td>{getExistingSearchFiltersCount(p.searchFilterTerms, 'Department', p.storeDepartmentName)}</td>}
                                                            {<td>{getAvailableSearchFiltersCount(searchFilters, 'Department', p.storeDepartmentName)}</td>}
                                                            <td><div className="ssps-td">{p.departmentCategoryName}</div></td>
                                                            {<td>{getExistingSearchFiltersCount(p.searchFilterTerms, 'Department Category', p.departmentCategoryName)}</td>}
                                                            {<td>{getAvailableSearchFiltersCount(searchFilters, 'Department Category', p.departmentCategoryName)}</td>}
                                                            <td><div className="ssps-td">{p.productCategoryName}</div></td>
                                                            {<td>{getExistingSearchFiltersCount(p.searchFilterTerms, 'Product Category', p.productCategoryName)}</td>}
                                                            {<td>{getAvailableSearchFiltersCount(searchFilters, 'Product Category', p.productCategoryName)}</td>}
                                                            <td>{p.productQtyLimit}</td>
                                                            <td>${p.totalPrice.toFixed(2)}</td>
                                                            <td className="sspscs-img"><img ref={getImageRef} data-alt={p.productName} alt={""} data-src={p.productImage} className="ssps-table-image"/></td>
                                                            <td><div className="ssps-td">{transformDate(new Date(p.productDateAdded).getTime())}</div></td>
                                                            <td className="td-action">
                                                                <div className="table-item-btn-container">
                                                                    <button className="edit-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleEditProduct(p)
                                                                        }}>
                                                                        Edit
                                                                    </button>
                                                                    <button className="remove-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleRemoveProduct(p);
                                                                        }}>
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                            })
                            }
                        </tbody>
                    </table>
                </div>
    }


    function handleCloseForm(formType:string, cancel?:boolean):void {

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        
        if(formType === 'editProduct'){
            setProducts([]);
            setIsLoading(true);
            setShowEditProduct(false);
            //getAllProducts();
            getUpdatedProduct(productToEdit!);
            
        } else if(formType === 'addProduct') {
            setProducts([]);
            setIsLoading(true);
            setShowAddProduct(false);
            if(cancel){
                getAllProducts();

            } else {
                setListChangerBtnPosition(0);
                getAllProducts(0);
            }

            
        } 


    }


    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as Product[]);

        //adjust item count based on items per list setting
        let nrh = 0;
        if(itemsPerList < itemsList.length){
            nrh = (itemsPerList * 94) + 766 + 16 + 57;

        } else {
            nrh = (itemsList.length) * 94 + 766 + 16 + 57;
        }

        //console.log('nrh:', nrh);

        const mh = 950;
        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
            productContainerRefs.current[0].style.minHeight = `${mh}px`;
        }

        if(existingSearchResultsHeight.current === 0 && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh < mh){
            existingSearchResultsHeight.current = mh;

            productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh > existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }
        //console.log('esrs:', existingSearchResultsHeight.current);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function displayListSorter(itemsPerList:number, ps:Product[]):void {
        if(ps.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleProductSearchText(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        //console.log('value.length:', target.value.length);
        if(target.value.length <= 150){
            setProductNameSearchText(target.value);
            setShowKeywordSearchLimitError(false);

        } else {
            setShowKeywordSearchLimitError(true);
        }

        
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowInfoMessage(false);
    }

    function handleResultsPerPageOptionSelected(e:React.ChangeEvent):void {
        const rppoSelected = (e.target as HTMLSelectElement).value;
        setResultsPerPageOptionSelected(rppoSelected);
        const ipp = parseInt(rppoSelected, 10);
        
        const previpp = itemsPerList;
        const previousPositionInList = (previpp * (listChangerBtnPosition + 1)) - previpp + 1;
        const startPointInList = Math.floor(previousPositionInList / ipp);
        //console.log('cbp:', startPointInList, previousPositionInList / ipp, listChangerBtnPosition);

        if(ipp > products.length){
            setItemsPerList(ipp);
            setListChangerBtnPosition(0);
            getAnyItemList(0, products, ipp, showItemsPerList);

        } else {

            if(listChangerBtnPosition === 0){
                setItemsPerList(ipp);
                setListChangerBtnPosition(0);
                getAnyItemList(0, products, ipp, showItemsPerList);

            } else {
                setItemsPerList(ipp);
                setListChangerBtnPosition(startPointInList - 1);
                getAnyItemList(startPointInList - 1, products, ipp, showItemsPerList);
            }

        }
        
        displayListSorter(ipp, products);

        //possible scroll bar is additional 16px
        //adjust item count based on items per list setting
        let nrh = 0;
        if(ipp < products.length){
            nrh = (ipp * 94) + 766 + 16 + 57;

        } else {
            nrh = (products.length) * 94 + 766 + 16 + 57;
        }

        
        if(nrh > 950){
            productContainerRefs.current[0].style.minHeight = `${nrh}px`;
        }


    }


    function handleSubmitProductSearch(e:React.FormEvent):void {
        e.preventDefault();
        //setShowLoadingSearchResults(true);
        const form = e.target as HTMLFormElement;

        //reset form errors
        //productKeywordRef.current!.classList.remove('product-info-error');
        pKeywordRefs.current[0]!.classList.remove('product-info-error');
        productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;


        //product info
        //product name selected
        const pnst = productNameSearchText;
        if(pnst.length === 0){
            setShowKeywordSearchLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Product Keyword'});

        } else {
            setShowKeywordSearchLimitError(false);
        }

        //pricing info
        //department category image
        const pp = productPrice;
        if(pp.length === 0 || parseInt(pp, 10) === 0){
            setShowPriceSearchLimitError(false);
            formError.pricingErrors.push({hasError:true, area:'Price'});

        } else {
            setShowPriceSearchLimitError(false);
        }

        const pnsts = formError.productInfoErrors.length > 0 ? true : false;
        const pps = formError.pricingErrors.length > 0 ? true : false;

        if(pnsts || pps){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }


        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        //setItemsList(null);

        setShowProcessingRequestMsg(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        const searchText = productNameSearchText;

        //check department name       
        if(searchText.length > 0){
            let searchString = '';
    
            for(let i=0; i < searchText.length; i++){
                if(/[\(\)\[\]\*\$\^\{\}\?\_\+\.\"\'\\|]/.test(searchText[i])){
                    searchString += `\\${searchText[i]}`;

                } else {
                    searchString += `${searchText[i]}`;
                }
            }

            //console.log('str26:', searchString);
            //searchString = '\\(-\\_\'\"\"\\]\\\\//\\?what\\^\\$@1';  

            const productOperator = priceRangeOperatorNameSelected;
            const productAmount = Number(productPrice).toFixed(2);
            const productSearch = {searchText:searchString, 
                                    productOperator, 
                                    productAmount };

            //console.log('productPrice:', productSearch);

            setTemporaryClearSearchResults(true);

            fetch(`https://server.kando-proto-3.com/product-search-name`, {
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:JSON.stringify(productSearch)
            })
            .then((res)=>{

                setShowProcessingRequestMsg(false);
                setItemsList(null);
                isLoadingTimeoutRef.current = window.setTimeout(()=>{
                                                        setIsLoading(true);
                                                    },1000);
                setIsLoadingDelete(true);

                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);

                return res.json();
            })
            .then((result)=>{
                //console.log('result:', result);
                if(result.length === 1 && result[0].products === null && result[0].searchFilters === null){
                    //setShowLoadingSearchResults(false);
                    setNoSearchResultsMessage('No products matched those search parameters.');
                    setShowNoSearchResultsFound(true);
                    setProducts([]);
                    setShowInfoMessage(true);

                    setTemporaryClearSearchResults(false);

                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);

                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);
            
                    productContainerRefs.current[0].style.minHeight = `950px`;

                } else {
                    //console.log('res 11-29:', result);
                    const ps = addProducts(result.products);
                    setProducts(ps);
                    const sfs = addSearchFilters(result.searchFilters);
                    //console.log('sfs:', sfs);
                    setSearchFilters(sfs);
                    setListChangerBtnPosition(0);
                    getAnyItemList(0, ps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, ps);

                    setTemporaryClearSearchResults(false);
                    //setShowLoadingSearchResults(false);

                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);


                    //adjust item count based on items per list setting
                    let nrh = 0;
                    if(itemsPerList < ps.length){
                        nrh = (itemsPerList * 94) + 766 + 16 + 57;

                    } else {
                        nrh = (ps.length) * 94 + 766 + 16 + 57;
                    }

                    //console.log('nrh:', nrh);

                    const mh = 950;
                    if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                        productContainerRefs.current[0].style.minHeight = `${mh}px`;
                    }

                    if(existingSearchResultsHeight.current === 0 && nrh > mh){
                        existingSearchResultsHeight.current = nrh;

                        productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }

                    if(nrh < existingSearchResultsHeight.current && nrh > mh){
                        existingSearchResultsHeight.current = nrh;

                        productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }

                    if(nrh < existingSearchResultsHeight.current && nrh < mh){
                        existingSearchResultsHeight.current = mh;

                        productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }

                    if(nrh > existingSearchResultsHeight.current && nrh > mh){
                        existingSearchResultsHeight.current = nrh;

                        productContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                    }
                    //console.log('esrs:', existingSearchResultsHeight.current);

                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);
                    
                }
                
            })
            .catch(err=>{
                //console.log('product name search error:', err.message);
                setShowErrorMessage(true);
                window.clearTimeout(isLoadingTimeoutRef.current);
                setIsLoading(false);
                setIsLoadingDelete(false);
            });

        } else {
            //console.log('no results found.');
            setShowNoSearchResultsFound(true);
            setShowInfoMessage(true);
            window.clearTimeout(isLoadingTimeoutRef.current);
            setIsLoading(false);
            setIsLoadingDelete(false);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
        }


    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Product Keyword':
                                //productKeywordRef.current!.classList.add('product-info-error');
                                pKeywordRefs.current[0].classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no product keyword info errors found!');
                        }
                    }
                });


        //set image errors
        formError.pricingErrors.forEach((pe)=>{
            //console.log('pe:', pe);
            if(pe.hasError && pe.area === 'Price'){
                //console.log('pe.area:', pe.area);
                productPriceRef.current!.classList.add('pricing-error');
            } else {
                throw new Error('no price errors found!');
            }
        });
    }

    function productPriceHandler(e:React.ChangeEvent):void {
        const price = (e.target as HTMLInputElement).value;
        const m = price.match(/\./i);
        if(m !== null && price.slice(m.index! + 1).length > 2){
            return;
        }
        //save this number to the database
        //use this for add product form
        const p = Number(price).toFixed(2);

        if(Number(p) <= 9999.99){
            setProductPrice(price);    
            setShowPriceSearchLimitError(false);

        } else {
            setShowPriceSearchLimitError(true);
        }

    }

    function priceKeydownHandler(e:React.KeyboardEvent):void {
        //console.log('key:', e.key, );
        if(e.key === 'e' || e.key === 'E'){
            e.preventDefault();
        }
    }

    function handlePriceRangeOperatorSelector(e:React.ChangeEvent):void {
        const prons = (e.target as HTMLSelectElement).value;
        //console.log('prons:', prons);
        setPriceRangeOperatorNameSelected(prons);
    }
    
    {/*
    if(!showAddProduct && !showEditProduct && products.length === 0 && !isLoading){
        return <div>
                    <h3 className="store-structure-dpts-categories-heading">Products</h3>
                    {<p>No products have been added.</p>}
                    <button type="button" onClick={handleAddProduct}>Add Product</button>
                    <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                </div>
    } else if(!showAddProduct && !showEditProduct && products.length > 0 && !isLoading) */}
    return <div className="ssps-wrapper">
                <div ref={getProductContainerRef} className="ssps-container">
                    <ul className="store-details-breadcrum-container">
                        <li className="sdbi">
                            <Link to="/admin" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi">
                            <Link to="/admin/store-structure" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin/store-structure', navigate)}>Store Structure</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi store-details-breadcrum-link-store-name">Products</li>
                    </ul>

                    {
                        !showAddProduct && !showEditProduct
                        ?
                        <h1 className="store-structure-dpts-categories-heading">Products</h1>
                        :
                        null
                    }
                    
                    {
                        !showAddProduct && !showEditProduct
                        ?
                        <div>
                            <div className="admin-add-item-btn-container">
                                <button type="button" className="admin-add-item-btn" onClick={handleAddProduct}>Add Product</button>
                                <button type="button" className="admin-reset-items-btn" onClick={resetProducts}>Reset Products</button>
                            </div>
                            <form ref={getFormRef} className="admin-search-form" onSubmit={handleSubmitProductSearch}>
                                <fieldset className="admin-search-box-container">
                                    <label className="admin-search-box-label" htmlFor="search-box">Keywords:</label>
                                    <span className="product-search-secondary-label">(Enter product name, product category, department category or department)</span>
                                    <div className="table-keyword-search-layout-wrapper">
                                        <input ref={getPKeywordRef} id="search-box" className="admin-search-box" type="text"
                                            placeholder={"Enter product name, product category, department category or department"}
                                            value={productNameSearchText} onChange={handleProductSearchText}/>
                                        
                                        {
                                            showKeywordSearchLimitError
                                            ?
                                            <p className="exceed-character-limit-error">The maximum characters allowed (150)</p>
                                            :
                                            null
                                        }
                                    </div>
                                </fieldset>
                                <fieldset className="product-price-search-container">
                                    <label className="product-price-search-operator-label" htmlFor={"search-box-operator"}>Operator:</label>
                                    <PriceRangeSelectControl elementName={priceRangeSelectControlName} showErrorMsg={false}
                                        errorMsg={"none"} optionNameSelected={priceRangeOperatorNameSelected} 
                                        optionNames={priceRangeOperatorNames} elementId={"search-box-operator"}
                                        cssStyles={{minWidth:140, backgroundPosition:14}}
                                        handleOptionNameSelection={handlePriceRangeOperatorSelector}/>
                                </fieldset>
                                <fieldset className="product-price-search-amt-container">
                                    <label htmlFor={"search-box-price"} className="product-price-search-amt-label">Price:</label>
                                    <div className="product-price-search-layout-wrapper">
                                        <div ref={productPriceRef} className="product-price-search-dollar-amount-wrapper">
                                            <span className="product-price-search-dollar-symbol">$</span>
                                            <input type="number" name="price-search-amount" id="search-box-price" className="product-price-search-amt" value={productPrice} 
                                                    min={minimumPrice} step={stepIncrementAmt}
                                                    onChange={productPriceHandler} onKeyDown={priceKeydownHandler}/>
                                        </div>
                                        {
                                            showPriceSearchLimitError
                                            ?
                                            <p className="exceed-character-limit-error">The maximum amount allowed (9999.99)</p>
                                            :
                                            null
                                        }
                                    </div>
                                </fieldset>
                                <fieldset className="admin-search-form-btn-container">
                                    <button type="submit" className="admin-search-form-btn">Search</button>
                                </fieldset>
                                <FormErrorMessage ref={getSfgErrorMsgRef} formError={formError} />
                            </form>
                        </div>
                        :
                        null
                    }

                    {
                        !showAddProduct && !showEditProduct && !isLoading 
                        && showNoSearchResultsFound && showInfoMessage && !isLoadingDelete
                        ? 
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                <span className="admin-list-total">{products.length} results</span>
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-generic-info-message-container">
                                {noSearchResultsMessage}
                                {/*<GenericInfoMessage infoMessage={noSearchResultsMessage}/>*/}
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        !showAddProduct && !showEditProduct && !isLoading && products.length > 0 
                        && !showNoSearchResultsFound && !showErrorMessage && !isLoadingDelete
                        ?
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                {
                                    temporaryClearSearchResults
                                    ?
                                    <span className="admin-list-total"></span>
                                    :
                                    <span className="admin-list-total">{(products.length === 1) ? `${products.length} result` : `${products.length} results`}</span>
                                }
                                
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <ListViewer itemsList={itemsList} items={products} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupProductsTable} />
                        </div>
                        :
                        null
                    }

                    {
                        showAddProduct && !showEditProduct && !isLoading
                        ?
                        <AddProductStoreStructure showEditProduct={false} handleCloseForm={handleCloseForm}/>
                        :
                        null
                    }

                    {
                        !showAddProduct && showEditProduct && !isLoading
                        ?
                        <EditProductStoreStructure product={productToEdit!} handleCloseForm={handleCloseForm}/>   
                        :
                        null
                    }

                    {
                        !showAddProduct && !showEditProduct && isLoading && !isLoadingDelete
                        ?
                        <div ref={getLoaderRef} className="ssds-loader-animation-container">
                            <LoaderAnimation/>
                        </div>
                        :
                        null
                    }

{
                        !showAddProduct && !showEditProduct && isLoadingDelete
                        ?
                        <div className="ssds-loader2-animation-container">
                            {/*<LoaderAnimation/>*/}
                            {
                                isLoading
                                ?
                                <LoaderAnimation/>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }

                    {
                        showProcessingRequestMsg
                        ?
                        <div className="process-request-msg-fixed">
                            Processing...
                        </div>
                        :
                        null
                    }

                    {
                        showErrorMessage
                        ?
                        <div className="ssd-generic-error-msg-wrapper">
                            <GenericErrorMessage/>
                        </div>
                        :
                        null
                    }

                </div>
                {
                    showFooter
                    ?
                    <Footer/>
                    :
                    null
                }
            </div>
                /*
    } else if(showAddProduct && !showEditProduct) {
        return <div>
                <AddProductStoreStructure showEditProduct={false} handleCloseForm={handleCloseForm}/>
                </div>
    } else if(!showAddProduct && showEditProduct){
        return <EditProductStoreStructure product={productToEdit!} handleCloseForm={handleCloseForm}/>
    } else {
        return <div>
                    <h3 className="store-structure-dpts-categories-heading">Products</h3>
                    <div>Loading...</div>
                    <div data-testid="spinner">
                            <i className="fa fa-spinner fa-spin" style={{fontSize:'48px'}}></i>
                    </div>
                </div>
    }
    */
}


export default StoreStructureProducts2;