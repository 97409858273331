import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import 'rxjs/_esm2015/index.js';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Router, LocationProvider } from '@gatsbyjs/reach-router';
import { BrowserRouter, Routes, Route, createBrowserRouter, 
        createRoutesFromElements, RouterProvider, LoaderFunctionArgs, Params } from "react-router-dom";
import ExploreStore from './ExploreStore';
import HomePage from './HomePage';
import Products from './Products';
import WhatsNew from './WhatsNew';
import StoreLocator from './StoreLocator';
import HighlightedProducts from './HighlightedProducts';
import RelatedProducts from './RelatedProducts';
//import RelatedProducts2 from './RelatedProducts2';
import RelatedProduct from './RelatedProduct';
import ListChanger from './ListChanger';
import StoreDetails from './StoreDetails';
import Shop from './Shop';
import ShopDepartment from './ShopDepartment';
import ShopDepartment2 from './ShopDepartment2';
//original version
import ShopDepartmentCategory from './ShopDepartmentCategory';
//test version with mariadb and session ids
import ShopDepartmentCategory3 from './ShopDepartmentCategory3';
import ShopProductCategory2 from './ShopProductCategory2';
import RelatedProducts2 from './RelatedProducts2';
import ShopOverview from './ShopOverview';
import ShopProduct from './ShopProduct';
import ShopProduct2 from './ShopProduct2';
import Admin from './Admin';
import AdminTools from './AdminTools';
import AdminProductReviews from './AdminProductReviews';
import AdminProductReviews2 from './AdminProductReviews2';
import CurbsideDelivery from './CurbsideDelivery';
import StoreStructure from './StoreStructure';
import StoreStructureDepartments from './StoreStructureDepartments';
import StoreStructureDepartments2 from './StoreStructureDepartments2';
import StoreStructureDepartmentCategories from './StoreStructureDepartmentCategories';
import StoreStructureDepartmentCategories2 from './StoreStructureDepartmentCategories2';
import StoreStructureProductCategories from './StoreStructureProductCategories';
import StoreStructureProductCategories2 from './StoreStructureProductCategories2';
import StoreStructureProductSubCategories from './StoreStructureProductSubCategories';
import StoreStructureProductSubCategories2 from './StoreStructureProductSubCategories2';
import StoreStructureProducts from './StoreStructureProducts';
import StoreStructureProducts2 from './StoreStructureProducts2';
import StoreStructureSearchFilters from './StoreStructureSearchFilters';
import StoreStructureSearchFilters2 from './StoreStructureSearchFilters2';
import StoreStructureSearchTerms from './StoreStructureSearchTerms';
import StoreStructureSearchTerms2 from './StoreStructureSearchTerms2';
import AdminStoreLocations from './AdminStoreLocations';
import AdminStoreLocations2 from './AdminStoreLocations2';
import HomePageLayoutBuilder from './HomePageLayoutBuilder';
import HomePageLayoutBuilder2 from './HomePageLayoutBuilder2';
import ShopByAreaType from './ShopByAreaType';
import OnRouteChange from './OnRouteChange';
import ScrollToTop from './ScrollToTop';
import ShopProductCart from './ShopProductCart';
import PageNotFound from './PageNotFound';
import GenericErrorMessage2 from './GenericErrorMessage2';
import {createRoot} from 'react-dom/client';
import AdminLogin from './AdminLogin';
import AdminLoginForm from './AdminLognForm';
import shopLoader, {productLoader} from './helper-shop-loader';
import AppErrorMessage from './AppErrorMessage';
//const container = document.getElementById('app');
//const root = createRoot(container!);


//original version
//<StoreLocator path="store-locator/:storeSearch"/>
//<RelatedProduct path="product/:productId"/>
//<HighlightedProducts path="/"/>
//<RelatedProducts path=":productCategoryOrProductName"/>
//<ShopDepartment path="/:storeDepartmentName"/>

/*previous routing from admin component
<Route path="admin/*" element={<Admin/>}/>
<Route path="/store-structure/*" element={<StoreStructure/>}/>
*/
//<AdminTools path="admin-tools/*"/>
//ReactDOM.render(
//<ScrollToTop/>
{/*
root.render(
    <Provider store={store}>
        <BrowserRouter>
            
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index={true} element={<HomePage/>}/>
                    <Route path="/*" element={<ExploreStore/>}/>
                    <Route path="admin" element={<Admin/>}>
                        <Route path="product-reviews" element={<AdminProductReviews2/>}/>
                        <Route path="home-page-layouts" element={<HomePageLayoutBuilder2/>}/>
                        <Route path="store-locations" element={<AdminStoreLocations2/>}/>
                        <Route path="store-structure" element={<StoreStructure/>}>
                            <Route path="departments" element={<StoreStructureDepartments2/>}/>
                            <Route path="department-categories" element={<StoreStructureDepartmentCategories2/>}/>
                            <Route path="product-categories" element={<StoreStructureProductCategories2/>}/>
                            <Route path="product-sub-categories" element={<StoreStructureProductSubCategories2/>}/>
                            <Route path="products" element={<StoreStructureProducts2/>}/>
                            <Route path="search-filters" element={<StoreStructureSearchFilters2/>}/>
                            <Route path="search-terms" element={<StoreStructureSearchTerms2/>}/>
                            <Route path="*" element={<PageNotFound showNavbar={false}/>}/>
                        </Route>
                        <Route path="*" element={<PageNotFound showNavbar={false}/>}/>
                    </Route>
                    <Route path="whats-new" element={<WhatsNew/>}/>
                    <Route path="shop" element={<Shop/>}>
                        <Route path="overview" element={<ShopOverview/>}/>
                        <Route path=":storeDepartmentName/:storeDepartmentId/*" element={<ShopByAreaType/>}/>
                        <Route path=":storeDepartmentName/:departmentCategoryName/:productCategoryName/:productName/:storeDepartmentId/:productId"  element={<ShopProduct2/>}/>
                        <Route path="*" element={<PageNotFound showNavbar={true}/>}/>
                    </Route>
                    <Route path="search" element={<Products/>}>
                        <Route path="*" element={<RelatedProducts2/>}/>
                    </Route>
                    <Route path="cart" element={<ShopProductCart/>}/>
                    <Route path="page-not-found" element={<PageNotFound showNavbar={false}/>}/>
                    <Route path="*" element={<PageNotFound showNavbar={false}/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </Provider>
    

, document.getElementById('root'));
*/}

function checkLoginStatus():Promise<boolean> {
    //console.log('load9:');
    //console.log('build_mode:', process.env.NODE_ENV);

    const c = 'include';
    const m = 'cors';
    return fetch('https://server.kando-proto-3.com/check-admin-login-status', {
                method:'GET',
                mode:m,
                credentials:c,
                headers:{
                    'Content-Type':'application/json',
                }
            })
            .then((res)=>res.json())
            .then(({loginStatus})=>{
                //console.log('load9:');
                //console.log('loginStatus:', loginStatus);
                if(loginStatus === 'loggedIn'){
                    return true;

                } else {
                    return false;
                }
            })
            .catch((err)=>{
                //console.log('check login status error:', err.message);
                return false
            });
}



const router = createBrowserRouter(
                    createRoutesFromElements(
                        <Route path="/" element={<App/>} errorElement={<AppErrorMessage/>}>
                            <Route index={true} element={<HomePage/>}/>
                            <Route path="/*" loader={checkLoginStatus} id="exploreStore" element={<ExploreStore/>}/>
                            <Route path="admin-login" element={<AdminLogin/>}>
                                <Route path=":destination" element={<AdminLoginForm/>}/>
                            </Route>
                            <Route path="admin" loader={checkLoginStatus} element={<Admin/>}>
                                <Route path="product-reviews" element={<AdminProductReviews2/>}/>
                                <Route path="home-page-layouts" element={<HomePageLayoutBuilder2/>}/>
                                <Route path="store-locations" element={<AdminStoreLocations2/>}/>
                                <Route path="store-structure" element={<StoreStructure/>}>
                                    <Route path="departments" element={<StoreStructureDepartments2/>}/>
                                    <Route path="department-categories" element={<StoreStructureDepartmentCategories2/>}/>
                                    <Route path="product-categories" element={<StoreStructureProductCategories2/>}/>
                                    <Route path="product-sub-categories" element={<StoreStructureProductSubCategories2/>}/>
                                    <Route path="products" element={<StoreStructureProducts2/>}/>
                                    <Route path="search-filters" element={<StoreStructureSearchFilters2/>}/>
                                    <Route path="search-terms" element={<StoreStructureSearchTerms2/>}/>
                                    <Route path="*" element={<PageNotFound/>}/>
                                </Route>
                                <Route path="*" element={<PageNotFound showFooter={true}/>}/>
                            </Route>
                            <Route path="whats-new" element={<WhatsNew/>}/>
                            <Route path="shop" element={<Shop/>}>
                                <Route path="overview" element={<ShopOverview/>}/>
                                <Route path=":storeDepartmentName/:storeDepartmentId/*" loader={shopLoader} element={<ShopByAreaType/>}/>
                                <Route path=":storeDepartmentName/:departmentCategoryName/:productCategoryName/:productName/:storeDepartmentId/:productId" loader={productLoader} element={<ShopProduct2/>}/>
                                <Route path="*" element={<PageNotFound/>}/>
                            </Route>
                            <Route path="search" element={<Products/>}>
                                <Route path="*" element={<RelatedProducts2/>}/>
                            </Route>
                            <Route path="cart" element={<ShopProductCart/>}/>
                            <Route path="page-not-found" element={<PageNotFound />}/>
                            <Route path="*" element={<PageNotFound />}/>
                        </Route>
                    )
                );


createRoot(document.getElementById("root") as HTMLDivElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
    );


//<OnRouteChange action={()=>window.scrollTo(0,0)}/>
//<RelatedProducts2 path=":keywordSearch"/>

/*for curbside deliver when I have time to implement
<Route path="curbside-delivery" element={<CurbsideDelivery/>}>
<Route path="home" element={<HomePage/>}/>
</Route>
*/

/*previous version of routing for admin component
                <Admin path="admin">
                    <AdminProductReviews2 path="product-reviews" />
                    <HomePageLayoutBuilder2 path="home-page-layouts"/>
                    <AdminStoreLocations2 path="store-locations"/>
                    <StoreStructure path="store-structure">
                        <StoreStructureDepartments2 path="departments"/>
                        <StoreStructureDepartmentCategories2 path="department-categories"/>
                        <StoreStructureProductCategories2 path="product-categories"/>
                        <StoreStructureProductSubCategories2 path="product-sub-categories"/>
                        <StoreStructureProducts2 path="products"/>
                        <StoreStructureSearchFilters2 path="search-filters"/>
                        <StoreStructureSearchTerms2 path="search-terms"/>
                    </StoreStructure>
                </Admin>
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
<ShopByAreaType path="/:storeDepartmentName/:storeDepartmentId"/>
<ShopByAreaType path="/:storeDepartmentName/:departmentCategoryName/:storeDepartmentId/:departmentCategoryId"/>
<ShopByAreaType path="/:storeDepartmentName/:departmentCategoryName/:productCategoryName/:storeDepartmentId/:productCategoryId"/>
*/

/*This was the original layout for shop component routing
<Shop path="shop">
<ShopOverview path="/"/>
    <ShopDepartment path="/:storeDepartmentName/:storeDepartmentId"/>
    <ShopDepartmentCategory2 path="/:storeDepartmentName/:departmentCategoryName/:storeDepartmentId/:departmentCategoryId"/>
    <ShopProductCategory path="/:storeDepartmentName/:departmentCategoryName/:productCategoryName/:storeDepartmentId/:productCategoryId"/>
    <ShopProduct path="/:storeDepartmentName/:departmentCategoryName/:productCategoryName/:productName/:storeDepartmentId/:productId" />
</Shop>

<Products path="search">
    <RelatedProducts path=":keywordSearch"/>
</Products>
*/