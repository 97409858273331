function checkWordMatch(word:string, matchingWord:string):{condition:string, matches:string[]}{
    if(matchingWord === word){
        return {condition:'exactMatch', matches:[word + '1']};
    }

    const mws = matchingWord.split(' ');
    const ws = word.split(' ');
    
    let matches:string[] = [];
    mws.forEach((mw)=>{
        ws.forEach((w)=>{
            if(w === mw){
                matches.push(w);
            }
        });
    });
    
    if(matches.length > 0){
        return ({condition:'partialMatch', matches});
        
    } else {
        return ({condition:'noMatch', matches});
    }
    
    
}

function addHyphen(filterName:string):string {
    let words = filterName.split(' ');
    if(words.length > 1){
      let hw = '';
      let last = words.length - 1;
      words.forEach((word, i)=>{
        if(i < last){
            hw += word + '-';
            
        } else {
            hw += word;
        }
      });
      
      return hw;
      
    } else {
      return filterName;
    }
}

function formatSearchFilterName(searchFilterName:string, searchGroupFilterName:string){
    const r = checkWordMatch(searchFilterName, searchGroupFilterName);
   
   switch(r.condition){
       case 'exactMatch':
           //console.log('r:', addHyphen(r.matches[0]));
           return addHyphen(r.matches[0]);

       case 'partialMatch':
           //console.log('r:', addHyphen(text));
           return addHyphen(searchFilterName);

       case 'noMatch':
           //console.log('r:', searchFilterName);
           return searchFilterName;

       default:
           throw new Error('no conditions found.');
   }

}

export default formatSearchFilterName;