import StoreServiceArea from './storeServiceArea';

interface StoreLocationGeometry {
    lat:number;
    lng:number;
}

export class Availability {
    id:number;
    order:number;
    day:string;
    hours:string;

    constructor(options:{
        id?:number,
        order?:number,
        day?:string,
        hours?:string
    }={}){
        this.id = options.id || 0;
        this.order = options.order || 0;
        this.day = options.day || '';
        this.hours = options.hours || '';
    }
}



export default class StoreLocation {
    storeLocationId:number;
    storeName:string;
    storeLocationImage:string;
    location:StoreLocationGeometry;
    streetAddress:string;
    city:string;
    state:string;
    zipCode:string;
    storePhone:string;
    storeHours:Availability[];
    pharmacyPhone:string;
    pharmacyHours:Availability[];
    pharmacyServices:string[];
    curbsidePickup:boolean;
    distance:number;
    order:number;
    //storeDepartments:StoreDepartment[];
    storeServiceAreas:StoreServiceArea[];
    storeNumber:number;
    storeLocationFound:boolean;
    storeLocationDateAdded:Date;

    constructor(options:{
        storeLocationId?:number,
        storeName?:string,
        storeLocationImage?:string,
        location?:StoreLocationGeometry,
        streetAddress?:string,
        city?:string,
        state?:string,
        zipCode?:string,
        storePhone?:string,
        storeHours?:Availability[],
        pharmacyPhone?:string,
        pharmacyHours?:Availability[],
        pharmacyServices?:string[],
        curbsidePickup?:boolean,
        distance?:number,
        order?:number,
        //storeDepartments?:StoreDepartment[],
        storeServiceAreas?:StoreServiceArea[],
        storeNumber?:number,
        storeLocationFound?:boolean,
        storeLocationDateAdded?:Date

    } = {}){
        this.storeLocationId = options.storeLocationId || 0;
        this.storeName = options.storeName || '';
        this.storeLocationImage = options.storeLocationImage || '';
        this.location = options.location || {lat:0, lng:0};
        this.streetAddress = options.streetAddress || '';
        this.city = options.city || '';
        this.state = options.state || '';
        this.zipCode = options.zipCode || '';
        this.storePhone = options.storePhone || '';
        this.storeHours = options.storeHours || [];
        this.pharmacyPhone = options.pharmacyPhone || '';
        this.pharmacyHours = options.pharmacyHours || [];
        this.pharmacyServices = options.pharmacyServices || [];
        this.curbsidePickup = options.curbsidePickup || false;
        this.distance = options.distance || 0;
        this.order = options.order || 0;
        //this.storeDepartments = options.storeDepartments || [];
        this.storeServiceAreas = options.storeServiceAreas || [];
        this.storeNumber = options.storeNumber || 0;
        this.storeLocationFound = options.storeLocationFound || false;
        this.storeLocationDateAdded = options.storeLocationDateAdded || new Date();
    }

    getStoreName():string {
        return this.storeName;
    }

    getLocation():StoreLocationGeometry {
        return this.location;
    }

    getStoreAddress():string {
        return this.streetAddress;
    }

}
