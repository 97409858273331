/*
import { createStore } from 'redux';
import rootReducer from './reducers';
import { devToolsEnhancer } from 'redux-devtools-extension';



//export default createStore(rootReducer, initialState, devToolsEnhancer({}));
export default createStore(rootReducer, devToolsEnhancer({}));
*/



import { configureStore } from '@reduxjs/toolkit'

import storeLocationReducer, {addStoreLocation} from './features/storeLocation/storeLocationSlice';
import shoppingCartReducer, {addProductsToMiniCart} from './features/shoppingCart/shoppingCartSlice';
import storeSearchParamsReducer, {addStoreSearchParams} from './features/storeSearchParams/storeSearchParamsSlice';
import productKeywordSearchReducer, {addProductKeywordSearch} from './features/productKeywordSearch/productKeywordSearchSlice';
import shopLoadDetailsReducer, {addShopLoadDetails} from './features/shopLoadDetails/shopLoadDetailsSlice';
import productShopCategoriesReducer, {addProductShopCategories} from './features/productShopCategories/productShopCategoriesSlice';
import highlightBackgroundReducer, {addHighlightBackground} from './features/highlightBackground/highlightBackgroundSlice';
import mobileNavReducer, {showMobileNav} from './features/mobileNav/mobileNavSlice';
import adminLoginDropdownMobileReducer, {showAdminLoginDropdownMobile} from './features/adminLoginDropdownMobile/adminLoginDropdownMobileSlice';
import generalProductsFilterNavReducer, {showGeneralProductsFilterNav} from './features/generalProductsFilterNav/generalProductsFilterNavSlice';
import relatedProductsFilterNavReducer, {showRelatedProductsFilterNav} from './features/relatedProductsFilterNav/relatedProductsFilterNavSlice';
import adminLoginReducer, {showAdminLogin} from './features/adminLogin/adminLoginSlice';
import shopByAreaTypeReducer from './features/shopByAreaType/shopByAreaTypeSlice';
import searchFilterParamsReducer from './features/searchFilterParams/searchFilterParamsSlice';
import SearchFilterProductVisibilityReducer from './features/searchFilterProductVisibility/searchFilterVisibilitySlice';
import homePageLayoutReducer, {selectHomePageLayoutParams, addHomePageLayout} from './features/homePageLayout/homePageLayoutSlice';
import StoreLocation from '../storeLocation';
import ShoppingCart from '../shoppingCart';




/*
import rootReducer from './reducers'
const store = configureStore({ reducer: rootReducer });
*/


const store = configureStore({
    reducer:{
        storeLocation:storeLocationReducer,
        shoppingCart:shoppingCartReducer,
        storeSearchParams:storeSearchParamsReducer,
        productKeywordSearch:productKeywordSearchReducer,
        shopLoadDetails:shopLoadDetailsReducer,
        productShopCategories:productShopCategoriesReducer,
        highlightBackground:highlightBackgroundReducer,
        mobileNav:mobileNavReducer,
        adminLoginDropdownMobile:adminLoginDropdownMobileReducer,
        generalProductsFilterNav:generalProductsFilterNavReducer,
        relatedProductsFilterNavR:relatedProductsFilterNavReducer,
        shopByAreaType:shopByAreaTypeReducer,
        searchFilterParams:searchFilterParamsReducer,
        searchFilterProductVisibility:SearchFilterProductVisibilityReducer,
        adminLogin:adminLoginReducer,
        homePageLayoutParams:homePageLayoutReducer
    }
});


export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;


/*
store.dispatch(addHighlightBackground({highlightBackground:false}));


store.dispatch(addStoreSearchParams(
    {
    lat:20,
    lng:40,
    currentLocationRequest:true,
    defaultLocation:'78633',
    storeSearch:'georgetown2',
    showStoreLocator:true,
    listChangerBtnPosition:2
    }
));

store.dispatch(addProductShopCategories([{
    shopCategoryId:12,
    shopCategoryNameId:4,
    subShopCategories:[],
    shopCategoryName:'windows',
    shopCategoryLink:'http://localhost:6000/windows'
}]));

store.dispatch(addShopLoadDetails({
    reload:true,
    storeDepartmentName:'Fruit & Vegetables',
    departmentCategoryName:'Fruit',
    storeDepartmentId:3,
    departmentCategoryId:11
}));

store.dispatch(addProductKeywordSearch({
    keywordSearch:'apples',
    priceRangeSearch:'relatedProducts',
    storeDepartmentSearch:'Fruit',
    departmentCategorySearch:'Fruit & Vegetables'
}));

store.dispatch(addStoreLocation(
    Object.assign({}, new StoreLocation({storeName:'georgetown2', storeLocationFound:true}))
));

store.dispatch(addProductsToMiniCart(
    Object.assign({}, new ShoppingCart({showShoppingCart:true}))
));
*/

//console.log('store:', store.getState());




export default store;
// The store now has redux-thunk added and the Redux DevTools Extension is turned on