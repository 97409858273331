import React, {useState, useRef, useEffect} from 'react';
import KeywordSearch from './KeywordSearch';
import {addHighlightBackground, selectHighlightBackground} from './redux/features/highlightBackground/highlightBackgroundSlice';
import {selectProductKeywordSearch, addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import './KeywordSearchModal.css';

interface Props {
    setShowKeywordSearchModal:(value: React.SetStateAction<boolean>) => void
}

const KeywordSearchModal:React.FunctionComponent<Props> = (props:Props) => {
    const {setShowKeywordSearchModal} = props;
    const dispatch = useAppDispatch();
    const selectProductKeywordSearchRedux = useAppSelector(selectProductKeywordSearch);
    const selectHighlightBackgroundRedux = useAppSelector(selectHighlightBackground).highlightBackground.highlightBackground;
    const [productKeywordSearch, setProductKeywordSearch] = useState(selectProductKeywordSearchRedux.keywordSearch);
    //console.log('spksr:', selectProductKeywordSearchRedux, productKeywordSearch, selectHighlightBackgroundRedux);
    const ksmWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        ksmWrapperRef.current!.style.transform = 'translateY(0)';
       //console.log('sksm:');
        //dispatch(addHighlightBackground({highlightBackground:true}));
    },[]);

    
    useEffect(()=>{
        //console.log('sksm:');
        if(selectProductKeywordSearchRedux.keywordSearch !== productKeywordSearch && !selectHighlightBackgroundRedux){
            setProductKeywordSearch(selectProductKeywordSearchRedux.keywordSearch);

            //window.setTimeout(()=>{
                setShowKeywordSearchModal(false);
            //},50);
            
        }

        if(selectProductKeywordSearchRedux.keywordSearch === productKeywordSearch 
            && selectProductKeywordSearchRedux.sameKeywordEntered){

            //window.setTimeout(()=>{
                setShowKeywordSearchModal(false);
            //},50);

            dispatch(addProductKeywordSearch!({
                                    keywordSearch:productKeywordSearch,
                                    priceRangeSearch:'relatedProducts',
                                    storeDepartmentSearch:'none',
                                    departmentCategorySearch:'none',
                                    sentFromKeywordList:false,
                                    clearKeywordSearch:false,
                                    sameKeywordEntered:false
                                        }));

            dispatch(addHighlightBackground({highlightBackground:false}));
        } 


        
    },[selectProductKeywordSearchRedux]);
    

    function closeKeywordSearchModal(/*e:React.MouseEvent*/):void {
        //console.log('cksm14:');
        //dispatch(addHighlightBackground({highlightBackground:false}));

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100); 

        setShowKeywordSearchModal(false);

        document.body.classList.remove('adj-mobile-search');
    }

    return <div ref={ksmWrapperRef} className="ksm-wrapper">
                <div className="ksm-container">
                    <div className="keyword-search-position-container">
                        <KeywordSearch showKeywordSearch={true}/>
                        <button type="button" className="ksm-cancel-btn" onClick={closeKeywordSearchModal}>Cancel</button>
                    </div>
                </div>
            </div>
}

export default KeywordSearchModal;
