import React, {useState, useEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AddSearchFilter from './AddSearchFilter';
import SearchFilter, {SearchTerm} from './searchFilter';
import StoreStructureAlert from './StoreStructureAlert';
import StoreStructureLoadingMsg from './StoreStructureLoadingMsg';
import AddSearchTermStandAlone from './AddSearchTermStandAlone';
import EditSearchTerm from './EditSearchTerm';
import DbResponse from './db-response';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import transformDate from './helper-transform-date';
import LoaderAnimation from './LoaderAnimation';
import FormErrorMessage, {FormError} from './FormErrorMessage';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import loadNavLink from './loadNavLinkHelper';
import GenericErrorMessage from './GenericErrorMessage';
import Footer from './Footer';
import './StoreStructureSearchTerms.css';

interface SearchTermError {
    searchTerms:SearchTerm[];
    error:string|null;
}

interface Props {
    //path:string;
}

const resultsPerPage = ['5', '10', '20', '30'];

const StoreStructureSearchTerms2: React.FunctionComponent<Props> = (props:Props) => {
    const navigate = useNavigate();
    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [searchTerms, setSearchTerms] = useState<SearchTerm[]>([]);
    const [showAddSearchTerm, setShowAddSearchTerm] = useState(false);
    const [showEditSearchTerm, setShowEditSearchTerm] = useState(false);
    const [deptNameSelected, setDeptNameSelected] = useState('none');
    const [deptCategoryNameSelected, setDeptCategoryNameSelected] = useState('none');
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState('none');
    const [searchFilterNameSelected, setSearchFilterNameSelected] = useState('');
    const [searchTermNameSelected, setSearchTermNameSelected] = useState('');
    const [searchTermIdSelected, setSearchTermIdSelected] = useState(0);
    const [searchTermImgSelected, setSearchTermImgSelected] = useState('');
    const [showSearchTermImg, setShowSearchTermImg] = useState(false);
    const [showSearchTerm, setShowSearchTerm] = useState(false);
    const [searchTermSelected, setSearchTermSelected] = useState(false);
    const [searchFilterAreaNameSelected, setSearchFilterAreaNameSelected] = useState('none');
    const [searchFilterAreaTypeSelected, setSearchFilterAreaTypeSelected] = useState('none');
    const [showDepartment, setShowDepartment] = useState(true);
    const [showDepartmentCategory, setShowDepartmentCategory] = useState(true);
    const [showProductCategory, setShowProductCategory] = useState(true);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('no msg');
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [storeStructureLoadingMsg, setStoreStructureLoadingMsg] = useState('saving search filter');
    const [showStoreStructureLoadingMsg, setShowStoreStructureLoadingMsg] = useState(false);

    //search properties
    const [searchTermNameSearchText, setSearchTermNameSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No search terms have been added.');

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(10);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<SearchFilter[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [elementName3, setElementName3] = useState('results-per-page');
    const [resultsPerPageOptionSelected, setResultsPerPageOptionSelected] = useState('10');
    const [resultsPerPageOptions, setResultsPerPageOptions] = useState<string[]>(resultsPerPage);

    const [isLoading, setIsLoading] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    
    const stKeywordRef = useRef<HTMLInputElement>(null);
    const stKeywordRefs = useRef<HTMLInputElement[]>([]);

    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });

    //const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);

    const sfgErrorMsgRefs = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}[]>([]);

    const stContainerRefs = useRef<HTMLDivElement[]>([]);
    const existingSearchResultsHeight = useRef<number>(0);

    const [showKeywordSearchLimitError, setShowKeywordSearchLimitError] = useState(false);

    const [temporaryClearSearchResults, setTemporaryClearSearchResults] = useState(false);

    //const formRef = useRef<HTMLFormElement>(null);

    const formRefs = useRef<HTMLFormElement[]>([]);

    const [showFooter, setShowFooter] = useState(false);

    const loaderRefs = useRef<HTMLDivElement[]>([]);

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const isLoadingTimeoutRef = useRef<number>(0);

    const [isLoadingEditComponent, setIsLoadingEditComponent] = useState(false);


    //console.log('sfans:', searchFilterAreaNameSelected);
    //console.log('sfns:', searchFilterNameSelected);
    //console.log('stns:', searchTermNameSelected);
    
    useEffect(()=>{
        //window.scrollTo(0,0);
        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);

        getAllSearchTermsPromise();
    },[]);


    function getAllSearchTermsPromise(lcbPosition?:number):void {
        getAllSearchTerms()
        .then((res:SearchTermError)=>{
            //console.log('res:', res);
            if(res.error === null){

                if(res.searchTerms.length === 0){
                    //show generic info message if no search filters are found
                    setNoSearchResultsMessage(`No search terms have been added.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setSearchTerms([]);
                    setIsLoading(false);

                    setShowFooter(true);
            
                } else {
                    const msts = modifySearchTermDateAdded(res.searchTerms);
                    const sts = addSearchTerms(msts);
                    //console.log('sts:', sts);
                    setSearchTerms(sts);
                    getAnyItemList(typeof lcbPosition !== 'undefined' ? lcbPosition : listChangerBtnPosition, sts, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, sts);
                    setIsLoading(false);

                    setShowFooter(true);

                    //adjust item count based on items per list setting
                    //previous version
                    let nrh = 0;
                    if(itemsPerList < sts.length){
                        nrh = (itemsPerList * 91) + 588 + 16 + 57;

                    } else {
                        nrh = (sts.length) * 91 + 588 + 16 + 57;
                    }

                                        
                    if(nrh > 950){
                        stContainerRefs.current[0].style.minHeight = `${nrh}px`;
                    }
                    
            
                }

            } else {
                setSearchTerms([]);
                //setNoSearchResultsMessage(res.error);
                //setIsLoading(false);
                
            }
        })
        .catch(err=>{
            //console.log('get all search terms from db error:', err.message);
            setSearchTerms([]);
            //setNoSearchResultsMessage(`Initially loading search terms component fetch error: ${err.message}.`);
            setShowErrorMessage(true);
            setIsLoading(false);

            setShowFooter(true);

        });
    }


    function getSTContainerRef(el:HTMLDivElement|null):void {
        if(el !== null){
            stContainerRefs.current.length = 0;
        }

        if(el !== null && stContainerRefs.current.length === 0){
            stContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = stContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                stContainerRefs.current.push(el);
            }

        }

        //console.log('prkrs:', stContainerRefs.current);
    }


    function getSTKeywordRef(el:HTMLInputElement|null):void {
        if(el !== null){
            stKeywordRefs.current.length = 0;
        }

        if(el !== null && stKeywordRefs.current.length === 0){
            stKeywordRefs.current.push(el);

        } else if(el !== null) {
            const found = stKeywordRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                stKeywordRefs.current.push(el);
            }

        }

        //console.log('prkrs:', stKeywordRefs.current);

        if(window.innerWidth >= 460){
            stKeywordRefs.current[0]!.placeholder = 'Enter search term, filter name, filter area or filter type';

        } else {
            stKeywordRefs.current[0]!.placeholder = '';
        }

    }

    useEffect(()=>{
        window.addEventListener('resize', ssstResizeHandler);
        return cleanupSSSTResizeHandler;
    },[]);

    function ssstResizeHandler(e:Event):void {
        if(window.innerWidth >= 460){
            stKeywordRefs.current[0]!.placeholder = 'Enter search term, filter name, filter area or filter type';

        } else {
            stKeywordRefs.current[0]!.placeholder = '';
        }

        if(typeof sfgErrorMsgRefs.current![0] !== 'undefined'){
            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
        }
    }

    function cleanupSSSTResizeHandler():void {
        window.removeEventListener('resize', ssstResizeHandler);
    }

    function getFormRef(el:HTMLFormElement|null):void {
        if(formRefs.current.length > 0){
            formRefs.current.length = 0;
        }

        if(el !== null && formRefs.current.length === 0){
            formRefs.current.push(el);

        } else if(el !== null) {
            const found = formRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                formRefs.current.push(el);
            }

        }
    }

    type Ref = {
        sfgemcRef:React.RefObject<HTMLDivElement>
    };

    function getSfgErrorMsgRef(el: Ref|null):void {
        if(sfgErrorMsgRefs.current.length > 0){
            sfgErrorMsgRefs.current.length = 0;
        }

        if(el !== null && sfgErrorMsgRefs.current.length === 0){
            sfgErrorMsgRefs.current.push(el);

        } else if(el !== null) {
            const found = sfgErrorMsgRefs.current.find((ir)=>{
                                return ir.sfgemcRef === el.sfgemcRef;
                            });

            if(typeof found === 'undefined'){
                sfgErrorMsgRefs.current.push(el);
            }

        }

        //console.log('sfgErrorMsgRefs:', sfgErrorMsgRefs.current);
    }

    function getLoaderRef(el: HTMLDivElement|null):void {
        if(loaderRefs.current.length > 0){
            loaderRefs.current.length = 0;
        }

        if(el !== null && loaderRefs.current.length === 0){
            loaderRefs.current.push(el);

        } else if(el !== null) {
            const found = loaderRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                loaderRefs.current.push(el);
            }

        }

        //console.log('loaderRefs:', loaderRefs);

        if(typeof loaderRefs.current[0] !== 'undefined'){
            const yValue = formRefs.current[0].getBoundingClientRect().bottom + 66;
            loaderRefs.current[0].style.transform = `translate(-50%, ${yValue}px)`;
        }


    }


    //add this function just like search filters.
    function getAllSearchTerms():Promise<SearchTermError> {
        return fetch(`https://server.kando-proto-3.com/get-all-search-terms`,{
                    method:'GET',
                    headers:{
                        'Accept':'application/javascript'
                    }
                })
                .then((res)=>res.json())
                .then((res:SearchTermError)=>{
                    //console.log('sfs:', searchFilters);
                    //const sfs = addSearchFilters(searchFilters);

                    if(res.error === null){
                        const msts = modifySearchTermDateAdded(res.searchTerms);
                        const sts = addSearchTerms(msts);
                        //console.log('sts:', sts);
                        return {searchTerms:sts, error:null};

                    } else {
                        return {searchTerms:[], error:res.error};
                    }


                })
                .catch(err=>{
                    //console.log('search terms fetch error:', err.message);
                    setShowErrorMessage(true);
                    setIsLoading(false);
                    return {searchTerms:[], error:`Initially loading search terms component fetch error: ${err.message}.`};

                });
    }

    function resetSearchTerms():void {
        //reset form errors
        stKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        setShowKeywordSearchLimitError(false);

        setSearchTerms([]);
        setSearchTermNameSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(true);

        setItemsList(null);
        setListChangerBtnPosition(0);

        getAllSearchTermsPromise(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);


        stContainerRefs.current[0].style.minHeight = `950px`;
        
    }

    function handleAddSearchTerm(e:React.MouseEvent):void {
        setShowKeywordSearchLimitError(false);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setSearchTermNameSearchText('');
        setShowAddSearchTerm(true);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);


        stContainerRefs.current[0].style.minHeight = `950px`;


    }

    function sortByNewest(a:SearchTerm, b:SearchTerm){
        const dateA = a.searchTermDateAdded//.toString();
        const dateB = b.searchTermDateAdded//.toString();
        if(dateB < dateA){
            return -1;
        } else if(dateB > dateA){
            return 1;
        } else {
            return 0;
        }
    }


    function sortItemsByDateSubmitted(sortDirection:string):void {
        if(sortDirection === 'asc'){
            //console.log('sort29');
            const sts = searchTerms.sort(compareAscendingDates);
            setSearchTerms(sts);
            getAnyItemList(listChangerBtnPosition, sts, itemsPerList, showItemsPerList);

        } else {
            //console.log('sort29');
            const sts = searchTerms.sort(compareDescendingDates);
            setSearchTerms(sts);
            getAnyItemList(listChangerBtnPosition, sts, itemsPerList, showItemsPerList);
        }

    }

    function compareDescendingDates(a:SearchTerm, b:SearchTerm):number {
        const aDate = new Date(a.searchTermDateAdded).getTime();
        const bDate = new Date(b.searchTermDateAdded).getTime();

        return bDate - aDate;
    }

    function compareAscendingDates(a:SearchTerm, b:SearchTerm):number {
        const aDate = new Date(a.searchTermDateAdded).getTime();
        const bDate = new Date(b.searchTermDateAdded).getTime();

        return aDate - bDate;
    }


    function setupSearchTermTable(itemsList:object[]):JSX.Element {
        return <div className="sssts-table-container">
                    <table className="sssts-table">
                        <thead>
                            <tr>
                                <th className="th-sf-ft">Filter Type</th><th className="th-sf-fa">Filter Area</th><th className="th-sf-fn">Filter Name</th><th className="th-sf-st">Search Term</th><th className="th-sf-date"><div className="admin-date-added-header-container"><span>Date Added</span><span className="admin-date-added-svg-btn-container">
                                                                                                                                                                                                                            <button type="button" aria-label="ascending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('asc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-up" d="M200 100 L100 0 L0 100 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                            <button type="button" aria-label="descending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('desc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-down" d="M200 0 L100 100 L0 0 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                        </span></div></th><th className="th-sf-action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(itemsList as SearchTerm[]).map((st, i)=>{
                        //console.log(`fa:${st.searchFilterArea}, fn:${st.searchFilterName}, qty:${st.searchTermName}, date:${st.searchTermDateAdded}`)
                                                return <tr key={i}>
                                                            <td><div className="sssts-td">{st.searchFilterAreaType}</div></td>
                                                            <td><div className="sssts-td">{st.searchFilterArea}</div></td>
                                                            <td><div className="sssts-td">{st.searchFilterName}</div></td>
                                                            <td><div className="sssts-td">{st.searchTermName}</div></td>
                                                            <td><div className="sssts-td">{transformDate(new Date(st.searchTermDateAdded).getTime())}</div></td>
                                                            <td className="td-action">
                                                                <div className="table-item-btn-container">
                                                                    <button type="button"
                                                                        className="edit-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            editSearchTerm(st.searchTermId);
                                                                        }}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button"
                                                                        className="remove-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            removeSearchTerm(st);
                                                                        }}>
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                            })
                            }
                        </tbody>
                    </table>
                </div>
    }

    function findSearchTerm(searchTermToAdd:SearchTerm, searchTerms:SearchTerm[]):SearchTerm | undefined {
        const stFound = searchTerms.find((st)=>{
        /*console.log('sf.searchFilterAreaType === searchFilterCreated.searchFilterAreaType', sf.searchFilterAreaType === searchFilterCreated.searchFilterAreaType)
        console.log('sf.searchFilterArea === searchFilterCreated.searchFilterArea', sf.searchFilterArea === searchFilterCreated.searchFilterArea)
        console.log('sf.filterName === searchFilterCreated.filterName', sf.filterName === searchFilterCreated.filterName)*/
                            return ((st.searchFilterAreaType === searchTermToAdd.searchFilterAreaType)
                                    && (st.searchFilterArea === searchTermToAdd.searchFilterArea)
                                    && (st.searchFilterName.toLowerCase() === searchTermToAdd.searchFilterName.toLowerCase())
                                    && (st.searchTermName.toLowerCase() === searchTermToAdd.searchTermName.toLowerCase()));

                        });

        return stFound;
    }

    function handleCancelSearchTerm():void{
        setIsLoading(true);
        setShowEditSearchTerm(false);
        setShowAddSearchTerm(false);

        getAnyItemList(listChangerBtnPosition, searchTerms, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, searchTerms);
        setSearchTermNameSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(false);

        /*
        getAllSearchTerms()
        .then((res:SearchTermError)=>{
        if(res.error === null){

            if(res.searchTerms.length === 0){
                //show generic info message if no search filters are found
                setNoSearchResultsMessage(`No search terms have been added.`);
                setSearchTermNameSearchText('');
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setSearchTerms([]);
                setIsLoading(false);
        
            } else {
                const msts = modifySearchTermDateAdded(res.searchTerms);
                const sts = addSearchTerms(msts);
                console.log('sts:', sts);
                setSearchTerms(sts);
                getAnyItemList(listChangerBtnPosition, sts, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, sts);
                setSearchTermNameSearchText('');
                setShowNoSearchResultsFound(false);
                setShowInfoMessage(false);
                setIsLoading(false);
        
            }

        } else {
            setSearchTerms([]);
            setNoSearchResultsMessage(res.error);
            setShowNoSearchResultsFound(true);
            setShowInfoMessage(true);
            setIsLoading(false);
            
        }
    })
    .catch(err=>{
        console.log('get all search terms from db error:', err.message);
        setSearchTerms([]);
        //setNoSearchResultsMessage(`Cancel search term fetch error: ${err.message}.`);
        setShowErrorMessage(true);
        setIsLoading(false);

    });
    */

        /*original settings
        setShowDepartment(true);
        setShowProductCategory(true);
        setShowDepartmentCategory(true);
        setDeptNameSelected('none');
        setDeptCategoryNameSelected('none');
        setProductCategoryNameSelected('none');
        setSearchFilterNameSelected('');
        setSearchTermNameSelected('');
        setSearchTermSelected(false);
        setSearchFilterAreaNameSelected('none');
        setSearchFilterAreaTypeSelected('none');
        setShowAddSearchTerm(false);
        */


    }

    function addSearchTermId(searchTerms:SearchTerm[]):number {
        let searchTermIds:number[] = [];
        searchTerms.forEach((st)=>{
            searchTermIds.push(st.searchTermId);
        });
        return searchTermIds.sort(sortByHighestId)[0] + 1;
    }

    function sortByHighestId(aId:number, bId:number):number{
        if(bId < aId){
            return -1;
        } else if(bId > aId){
            return 1;
        } else {
            return 0;
        }
    }

    function loadSearchFilterAreaType(st:SearchTerm):void {
        switch(st.searchFilterAreaType){
            case 'Department':
                setDeptNameSelected(st.searchFilterArea);
                setDeptCategoryNameSelected('none');
                setProductCategoryNameSelected('none');
                setShowDepartment(true);
                setShowDepartmentCategory(false);
                setShowProductCategory(false);
                break;
            case 'Department Category':
                setDeptCategoryNameSelected(st.searchFilterArea);
                setDeptNameSelected('none');
                setProductCategoryNameSelected('none');
                setShowDepartment(false);
                setShowDepartmentCategory(true);
                setShowProductCategory(false);
                break;
            case 'Product Category':
                setProductCategoryNameSelected(st.searchFilterArea);
                setDeptNameSelected('none');
                setDeptCategoryNameSelected('none');
                setShowDepartment(false);
                setShowDepartmentCategory(false);
                setShowProductCategory(true);
                break;
            default:
                throw new Error('no search term area type found.');
        }
    }

    function editSearchTerm(searchTermId:number):void {
        setShowKeywordSearchLimitError(false);

        setIsLoadingEditComponent(true);
        setShowFooter(false);

        fetch(`https://server.kando-proto-3.com/get-search-term-only/${searchTermId.toString()}`,{
            method:'GET',
            headers:{
                'Accept':'application/javascript'
            }
            })
            .then(resp=>{
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);

                stContainerRefs.current[0].style.minHeight = `950px`;

                return resp.json();
            })
            .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                //console.log('dbrm:', dbResponseMsg);
                //console.log('dbrr:', dbResponseRecords);
                if(dbResponseMsg === 'record found'){
                    const st = addSearchTerm({...dbResponseRecords[0] as SearchTerm});
                    //console.log('st:', st);
                    loadSearchFilterAreaType(st);
                    setSearchTermIdSelected(st.searchTermId);
                    setSearchFilterAreaTypeSelected(st.searchFilterAreaType);
                    setSearchFilterAreaNameSelected(st.searchFilterArea);
                    setSearchFilterNameSelected(st.searchFilterName);
                    setSearchTermImgSelected(st.searchTermImg);
                    setShowSearchTermImg(st.showSearchTermImg);
                    setShowSearchTerm(st.showSearchTerm);
                    setSearchTermSelected(st.searchTermSelected);
                    setSearchTermNameSelected(st.searchTermName);
                    setShowNoSearchResultsFound(false);
                    setShowInfoMessage(false);
                    setSearchTermNameSearchText('');
                    setShowEditSearchTerm(true);

                    setIsLoadingEditComponent(false);
                    setShowFooter(true);

                } else {
                    throw new Error('no search filter record found');
                }


            })
            .catch(err=>{
                //console.log('edit search filter fetch error:', err);
                setShowErrorMessage(true);
                setIsLoadingEditComponent(false);
                setShowFooter(true);
            });        
    }

    function removeSearchTerm(st:SearchTerm):void {
        //reset form errors
        stKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        setShowKeywordSearchLimitError(false);

        const searchTerm = JSON.stringify(st);

        //previous version
        //fetch(`http://localhost:9500/remove-search-term/${searchTerm}`,{
        fetch(`https://server.kando-proto-3.com/remove-search-term`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:searchTerm
        })
        .then(resp=>{

            setIsLoadingDelete(true);
            setShowProcessingRequestMsg(false);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            return resp.json();
        })
        .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
            //console.log('records:', dbResponseRecords);
            if(dbResponseMsg === 'record removed'){
                //const msts = modifySearchTermDateAdded(dbResponseRecords as SearchTerm[]);
                //const sts = addSearchTerms(msts);
                
                const usts = searchTerms.filter((t)=>{
                                return t.searchTermId !== st.searchTermId;
                            });

                if(usts.length === 0){
                    setSearchTerms([...usts]);
                    //getAnyItemList(listChangerBtnPosition, usts, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, usts);
                    setNoSearchResultsMessage(`All search terms have been deleted from this search results page.  
                                                Try another search results page, keyword search, reset the search term list or add more search terms.`);
                    setSearchTermNameSearchText('');
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    //setIsLoading(false);
                    setIsLoadingDelete(false);


                } else {

                    //console.log('r15:', (usts.length / itemsPerList), listChangerBtnPosition);

                    if((usts.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, usts, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, usts);

                    } else {
                        getAnyItemList(listChangerBtnPosition, usts, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, usts);
                    }

                    setSearchTerms([...usts]);
                    //getAnyItemList(listChangerBtnPosition, usts, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, usts);
                    setSearchTermNameSearchText('');
                    //setNoSearchResultsMessage(`All search terms have been deleted.  Add more search terms to continue.`);
                    setShowNoSearchResultsFound(false);
                    setShowInfoMessage(false);
                    //setIsLoading(false);
                    setIsLoadingDelete(false);

                }
                


            } else {
                setSearchTerms([]);
                setSearchTermNameSearchText('');
                setNoSearchResultsMessage(dbResponseMsg);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                //setIsLoading(false);
                setIsLoadingDelete(false);

            }


        })
        .catch((err)=>{
            //console.error('remove search term error:', err);
            setSearchTerms([]);
            setSearchTermNameSearchText('');
            //setNoSearchResultsMessage(`remove search term error: ${err.message}.`);;
            //setShowNoSearchResultsFound(true);
            //setShowInfoMessage(true);
            setShowErrorMessage(true);
            //setIsLoading(false);
            setIsLoadingDelete(false);
            setShowProcessingRequestMsg(false);
        });
    }

    function addSearchTerm(st:SearchTerm):SearchTerm {
        return new SearchTerm({...st});
    }

    function addSearchTerms(sts:SearchTerm[]):SearchTerm[] {
        return sts.map((st)=>{
                    return new SearchTerm({...st});
                });
    }

    function modifySearchTermDateAdded(sts:SearchTerm[]):SearchTerm[] {
        //modify date string
        return sts.map((st)=>{
                    st.searchTermDateAdded = new Date(st.searchTermDateAdded);
                    return st;
                });
        
    }

    function addSearchFilters(sfs:SearchFilter[]):SearchFilter[] {
        return sfs.map((sf)=>{
                    sf = new SearchFilter({...sf});
                    sf.searchTerms = sf.searchTerms.map((st)=>{
                                                return new SearchTerm({...st});
                                            });
                    return sf;
                });
    }

    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        setShowStoreStructureAlert(false);
    }

    function handleSaveSearchTerm(
                        searchFilterAreaTypeSelected:string,
                        searchFilterAreaNameSelected:string,
                        searchFilterNameSelected:string,
                        searchTermNameSelected:string,
                        searchTermSelected:boolean,
                        showSearchTerm:boolean
                                ):void {
        //console.log('st saved');
        setShowAddSearchTerm(false);
        setIsLoading(true);

        setListChangerBtnPosition(0);

        getAllSearchTerms()
        .then((res:SearchTermError)=>{
            //console.log('res:', res);
            if(res.error === null){
                if(res.searchTerms.length === 0){
                    //show generic info message if no search filters are found
                    setNoSearchResultsMessage(`No search terms have been added.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setSearchTerms([]);
                    setSearchTermNameSearchText('');
                    setIsLoading(false);
            
                } else {
                    const msts = modifySearchTermDateAdded(res.searchTerms);
                    const sts = addSearchTerms(msts);
                    //console.log('sts:', sts);
                    setShowDepartment(true);
                    setShowProductCategory(true);
                    setShowDepartmentCategory(true);
                    setDeptNameSelected('none');
                    setDeptCategoryNameSelected('none');
                    setProductCategoryNameSelected('none');
                    setSearchFilterNameSelected('');
                    setSearchTermNameSelected('');
                    setSearchTermSelected(false);
                    setSearchFilterAreaNameSelected('none');
                    setSearchFilterAreaTypeSelected('none');
                    setShowAddSearchTerm(false);
                    //setShowStoreStructureLoadingMsg(false);
                    //setSearchTerms([...sts]);
                    setSearchTerms(sts);
                    getAnyItemList(0, sts, itemsPerList, showItemsPerList);
                    //getAnyItemList(listChangerBtnPosition, sts, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, sts);
                    setSearchTermNameSearchText('');
                    setIsLoading(false);

                    /*
                    console.log('st added:', addSearchTerm(searchTermAdded));
                    const searchTermSaved = addSearchTerm(searchTermAdded);
                    searchTerms.push(searchTermSaved);
                    const usts = searchTerms.sort(sortByNewest);
                    setSearchTerms([...usts]);
                    */
            
                }

            } else {
                setSearchTerms([]);
                setSearchTermNameSearchText('');
                //setNoSearchResultsMessage(res.error);
                setIsLoading(false);
                
            }
        })
        .catch(err=>{
            //console.log('get all search terms from db error:', err.message);
            setSearchTerms([]);
            //setNoSearchResultsMessage(`save search term fetch error: ${err.message}.`);
            setShowErrorMessage(true);
            setSearchTermNameSearchText('');
            setIsLoading(false);

        });

    }

    //previous verion sent all search terms back
    //function handleUpdateSearchTerm(searchTerms:SearchTerm[]):void {
    function handleUpdateSearchTerm(sts:SearchTerm[]):void {
        //console.log('st updated:', searchTerms);
        //const usts = addSearchTerms(searchTerms);
        //console.log('st updated:', sts[0]);
        const ust = sts[0];

        const usts = searchTerms.map((st)=>{
                        if(st.searchTermId === ust.searchTermId){
                            return ust;

                        } else {
                            return st;
                        }
                    });

        getAnyItemList(listChangerBtnPosition, usts, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, usts);

        setShowDepartment(true);
        setShowProductCategory(true);
        setShowDepartmentCategory(true);
        setDeptNameSelected('none');
        setDeptCategoryNameSelected('none');
        setProductCategoryNameSelected('none');
        setSearchFilterNameSelected('');
        setSearchFilterAreaNameSelected('none');
        setSearchFilterAreaTypeSelected('none')
        setSearchTerms(usts);
        
        setSearchTermNameSelected('');
        setSearchTermSelected(false);
        setShowSearchTerm(false);
        setShowSearchTermImg(false);
        setSearchTermImgSelected('');
        setShowEditSearchTerm(false);

        setIsLoading(true);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);
    }


    function showItemsPerList(itemsList:object[]):void {
        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as SearchFilter[]);

        //adjust item count based on items per list setting
        let nrh = 0;
        if(itemsPerList < itemsList.length){
            nrh = (itemsPerList * 91) + 588 + 16 + 57;

        } else {
            nrh = (itemsList.length) * 91 + 588 + 16 + 57;
        }

        const mh = 950;
        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
            stContainerRefs.current[0].style.minHeight = `${mh}px`;
        }

        if(existingSearchResultsHeight.current === 0 && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh < mh){
            existingSearchResultsHeight.current = mh;

            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh > existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }
        //console.log('esrs:', existingSearchResultsHeight.current);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function displayListSorter(itemsPerList:number, sts:SearchTerm[]):void {
        //console.log('ipl test:', itemsPerList, 'sts:', sts.length);
        if(sts.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleSearchTermSearchText(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        //console.log('value.length:', target.value.length);

        if(target.value.length <= 20){
            setSearchTermNameSearchText(target.value);
            setShowKeywordSearchLimitError(false);

        } else {
            setShowKeywordSearchLimitError(true);
        }
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowInfoMessage(false);
    }

    function handleResultsPerPageOptionSelected(e:React.ChangeEvent):void {
        const rppoSelected = (e.target as HTMLSelectElement).value;
        setResultsPerPageOptionSelected(rppoSelected);
        const ipp = parseInt(rppoSelected, 10);
        
        const previpp = itemsPerList;
        const previousPositionInList = (previpp * (listChangerBtnPosition + 1)) - previpp + 1;
        const startPointInList = Math.floor(previousPositionInList / ipp);
        //console.log('cbp:', startPointInList, previousPositionInList / ipp, listChangerBtnPosition);

        if(ipp > searchTerms.length){
            setItemsPerList(ipp);
            setListChangerBtnPosition(0);
            getAnyItemList(0, searchTerms, ipp, showItemsPerList);

        } else {

            if(listChangerBtnPosition === 0){
                setItemsPerList(ipp);
                setListChangerBtnPosition(0);
                getAnyItemList(0, searchTerms, ipp, showItemsPerList);

            } else {
                setItemsPerList(ipp);
                setListChangerBtnPosition(startPointInList - 1);
                getAnyItemList(startPointInList - 1, searchTerms, ipp, showItemsPerList);
            }

        }
        
        displayListSorter(ipp, searchTerms);


        //adjust item count based on items per list setting
        let nrh = 0;
        if(ipp < searchTerms.length){
            nrh = (ipp * 91) + 588 + 16 + 57;

        } else {
            nrh = (searchTerms.length) * 91 + 588 + 16 + 57;
        }

                            
        if(nrh > 950){
            stContainerRefs.current[0].style.minHeight = `${nrh}px`;
        }

    }


    function handleSubmitSearchTermSearch(e:React.FormEvent):void {
        e.preventDefault();

        const form = e.target as HTMLFormElement;

        //reset form errors
        stKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //product info
        //product name selected
        const stnst = searchTermNameSearchText;
        if(stnst.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Search Term Keyword'});
            
        } else {
            setShowKeywordSearchLimitError(false);
        }

        //pricing info
        //department category image
        /*
        const pp = productPrice;
        if(pp.length === 0 || parseInt(pp, 10) === 0){
            formError.pricingErrors.push({hasError:true, area:'Price'});
        }
        */

        const stnsts = formError.productInfoErrors.length > 0 ? true : false;
        //const pps = formError.pricingErrors.length > 0 ? true : false;

        if(stnsts /*|| pps*/){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }
        //setShowLoadingSearchResults(true);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        //setItemsList(null);
        
        setShowProcessingRequestMsg(true);
        //setIsLoading(true);

        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);

        const searchText = searchTermNameSearchText;

        /*
        const zipCodePattern = /\d+/g
        const zcr = searchText.match(zipCodePattern);

        if(zcr !== null){
            //found some numbers
            let numberString = '';
            zcr.forEach((num)=>{
                numberString += num;		
                });
                        
            console.log('zcr:', zcr);
            console.log('search number:', parseInt(numberString,10));
    
            const searchText = numberString;
            //check store numbers
            fetch(`http://localhost:9500/store-location-search-number/${searchText}`, {
                method:'GET',
                headers:{
                    'Content-Type':'text/javascript',
                    'Accept':'text/javascript'
                }
            })
            .then((res)=>res.json())
            .then((result)=>{
                console.log('result:', result);
                if(result.length === 1 && result[0].source === null && result[0].department === null){
                    //setShowLoadingSearchResults(false);
                    setShowNoSearchResultsFound(true);
                } else {
                    
                    Promise.all(addDepartmentImages(result))
                    .then((res)=>{
                        console.log('res 11-27:', res);            
                        const ds = addDepartments(res);
                        setDepartments(ds);
                        getAnyItemList(listChangerBtnPosition, ds, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, ds);
                        //setShowLoadingSearchResults(false);
                    })
                    .catch((err)=>console.error('add department images error:', err.message));
                    
                }
            })
            .catch(err=>console.error('department number search error:', err.message));
            
        } else {
            */
            //check department name            
            if(searchText.length > 0){
                let searchString = '';
    
                for(let i=0; i < searchText.length; i++){
                    if(/[\(\)\[\]\*\$\^\{\}\?\_\+\.\"\'\\|]/.test(searchText[i])){
                        searchString += `\\${searchText[i]}`;
    
                    } else {
                        searchString += `${searchText[i]}`;
                    }
                }
    
                //console.log('str26:', searchString);
                //searchString = '\\(-\\_\'\"\"\\]\\\\//\\?what\\^\\$@1';  

                const st = {searchText:searchString};
                
                const params = JSON.stringify(st);

                setTemporaryClearSearchResults(true);

                //previous version
                //fetch(`http://localhost:9500/search-term-search-name/${searchText}`, {
                fetch(`https://server.kando-proto-3.com/search-term-search-name`, {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:params
                })
                .then((res)=>{

                    setShowProcessingRequestMsg(false);
                    setItemsList(null);
                    isLoadingTimeoutRef.current = window.setTimeout(()=>{
                                                            setIsLoading(true);
                                                        },1000);
                    setIsLoadingDelete(true);
    
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    return res.json();
                })
                .then((result)=>{
                    //console.log('result:', result);
                    if(result.length === 1 && result[0].searchTerms === null){
                        //setShowLoadingSearchResults(false);
                        setNoSearchResultsMessage('No search terms matched those search parameters.');
                        setShowNoSearchResultsFound(true);
                        setSearchTerms([]);
                        setShowInfoMessage(true);

                        setTemporaryClearSearchResults(false);

                        window.clearTimeout(isLoadingTimeoutRef.current);
                        setIsLoading(false);
                        setIsLoadingDelete(false);

                        let root = document.documentElement;
                        root!.setAttribute('style', 'scroll-behavior: auto');
                        root.scrollTo(0,0);
                        window.setTimeout(()=>{
                            root!.removeAttribute('style');
                        },100);
        
                        stContainerRefs.current[0].style.minHeight = `950px`;

                    } else {
                        //console.log('res 11-29:', result);
                        const msts = modifySearchTermDateAdded(result);
                        const sts = addSearchTerms(msts);
                        //console.log('sts:', sts);
                        setSearchTerms(sts);
                        setListChangerBtnPosition(0);
                        getAnyItemList(0, sts, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, sts);

                        setTemporaryClearSearchResults(false);
                        //setShowLoadingSearchResults(false);

                        window.clearTimeout(isLoadingTimeoutRef.current);
                        setIsLoading(false);
                        setIsLoadingDelete(false);

                        //adjust item count based on items per list setting
                        let nrh = 0;
                        if(itemsPerList < sts.length){
                            nrh = (itemsPerList * 91) + 588 + 16 + 57;

                        } else {
                            nrh = (sts.length) * 91 + 588 + 16 + 57;
                        }

                        const mh = 950;
                        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                            stContainerRefs.current[0].style.minHeight = `${mh}px`;
                        }
    
                        if(existingSearchResultsHeight.current === 0 && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh < existingSearchResultsHeight.current && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh < existingSearchResultsHeight.current && nrh < mh){
                            existingSearchResultsHeight.current = mh;
    
                            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh > existingSearchResultsHeight.current && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            stContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
                        //console.log('esrs:', existingSearchResultsHeight.current);

                        let root = document.documentElement;
                        root!.setAttribute('style', 'scroll-behavior: auto');
                        root.scrollTo(0,0);
                        window.setTimeout(()=>{
                            root!.removeAttribute('style');
                        },100);
                        
                    }
                    
                })
                .catch(err=>{
                    console.error('search term name search error:', err.message);
                    setShowErrorMessage(true);
                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);
                });

            } else {
                //console.log('no results found.');
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                window.clearTimeout(isLoadingTimeoutRef.current);
                setIsLoading(false);
                setIsLoadingDelete(false);

                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
            }
            
            
        //}


    }


    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Search Term Keyword':
                                stKeywordRefs.current[0]!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no search term keyword info errors found!');
                        }
                    }
                });


        //set image errors
        /*
        formError.pricingErrors.forEach((pe)=>{
            console.log('pe:', pe);
            if(pe.hasError && pe.area === 'Price'){
                console.log('pe.area:', pe.area);
                productPriceRef.current!.classList.add('pricing-error');
            } else {
                throw new Error('no price errors found!');
            }
        });
        */
    }


    {/*}
    if(isLoading){
        return <div>
                    <h3 className="store-structure-dpts-categories-heading">Search Terms</h3>
                    <div>Loading...</div>
                    <div data-testid="spinner">
                            <i className="fa fa-spinner fa-spin" style={{fontSize:'48px'}}></i>
                    </div>
                </div>

    } else if(!showAddSearchTerm && !showEditSearchTerm && searchTerms.length === 0){
        return <Col>
                    <h3 className="store-structure-dpts-categories-heading">Search Terms</h3>
                    {<p>No search terms have been added.</p>}
                    <button type="button" onClick={handleAddSearchTerm}>Add Search Term</button>
                    <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                </Col>
    } else if(!showAddSearchTerm && !showEditSearchTerm && searchTerms.length > 0) {*/}
    return <div className="sssts-wrapper">
                <div ref={getSTContainerRef} className="sssts-container">
                    <ul className="store-details-breadcrum-container">
                        <li className="sdbi">
                            <Link to="/admin" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi">
                            <Link to="/admin/store-structure" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin/store-structure', navigate)}>Store Structure</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi store-details-breadcrum-link-store-name">Search Terms</li>
                    </ul>

                    {
                        !showAddSearchTerm && !showEditSearchTerm && !isLoadingEditComponent
                        ?
                        <h1 className="store-structure-dpts-categories-heading">Search Terms</h1>
                        :
                        null
                    }
                    
                    {
                        !showAddSearchTerm && !showEditSearchTerm && !isLoadingEditComponent
                        ?
                        <div>
                            <div className="admin-add-item-btn-container">
                                <button type="button" className="admin-add-item-btn" onClick={handleAddSearchTerm}>Add Term</button>
                                <button type="button" className="admin-reset-items-btn" onClick={resetSearchTerms}>Reset Terms</button>
                            </div>
                            <form ref={getFormRef} className="admin-search-form" onSubmit={handleSubmitSearchTermSearch}>
                                <fieldset className="admin-search-box-container">
                                    <label className="admin-search-box-label" htmlFor="search-box">Keywords:</label>
                                    <span className="st-search-secondary-label">(Enter search term, filter name, filter area or filter type)</span>
                                    <div className="table-keyword-search-layout-wrapper">
                                        <input id="search-box" className="admin-search-box" type="text"
                                            ref={getSTKeywordRef}
                                            placeholder={"Enter search term, filter name, filter area or filter type"}
                                            value={searchTermNameSearchText} onChange={handleSearchTermSearchText}/>
                                        {
                                            showKeywordSearchLimitError
                                            ?
                                            <p className="exceed-character-limit-error">The maximum characters allowed (20)</p>
                                            :
                                            null
                                        }
                                    </div>
                                </fieldset>
                                <fieldset className="admin-search-form-btn-container">
                                    <button type="submit" className="admin-search-form-btn">Search</button>
                                </fieldset>
                            </form>
                            <FormErrorMessage ref={getSfgErrorMsgRef} formError={formError} />
                        </div>
                        :
                        null
                    }


                    {
                        !showAddSearchTerm && !showEditSearchTerm && !isLoading
                        && showNoSearchResultsFound && showInfoMessage && !isLoadingDelete
                        ? 
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                <span className="admin-list-total">{searchTerms.length} results</span>
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-generic-info-message-container">
                                {noSearchResultsMessage}
                                {/*<GenericInfoMessage infoMessage={noSearchResultsMessage}/>*/}
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        !showAddSearchTerm && !showEditSearchTerm && !isLoading
                        && searchTerms.length >0 && !showNoSearchResultsFound && !showErrorMessage && !isLoadingDelete && !isLoadingEditComponent
                        ?
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                {
                                    temporaryClearSearchResults
                                    ?
                                    <span className="admin-list-total"></span>
                                    :
                                    <span className="admin-list-total">{(searchTerms.length === 1) ? `${searchTerms.length} result` : `${searchTerms.length} results`}</span>
                                }
                                
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <ListViewer itemsList={itemsList} items={searchTerms} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupSearchTermTable} />
                        </div>
                        :
                        null
                    }

                    {
                        showAddSearchTerm && !showEditSearchTerm && !isLoading
                        ?
                        <AddSearchTermStandAlone 
                            showAddSearchTerm={showAddSearchTerm}
                            handleSaveSearchTerm={handleSaveSearchTerm}
                            handleCancelSearchTerm={handleCancelSearchTerm}
                        />
                        :
                        null
                    }

                    {
                        !showAddSearchTerm && showEditSearchTerm && !isLoading
                        ?
                        <EditSearchTerm
                            showEditSearchTerm={showEditSearchTerm}
                            showDepartment={showDepartment}
                            showDepartmentCategory={showDepartmentCategory}
                            showProductCategory={showProductCategory}
                            deptNameSelected={deptNameSelected}
                            deptCategoryNameSelected={deptCategoryNameSelected}
                            productCategoryNameSelected={productCategoryNameSelected}
                            searchFilterNameSelected={searchFilterNameSelected}
                            searchTermNameSelected={searchTermNameSelected}
                            searchTermIdSelected={searchTermIdSelected}
                            searchTermImgSelected={searchTermImgSelected}
                            showSearchTermImg={showSearchTermImg}
                            showSearchTerm={showSearchTerm}
                            searchTermSelected={searchTermSelected}
                            searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                            searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                            handleUpdateSearchTerm={handleUpdateSearchTerm}
                            handleCancelSearchTerm={handleCancelSearchTerm}
                        />
                        :
                        null
                    }

                    {
                        !showAddSearchTerm && !showEditSearchTerm && isLoading
                        ?
                        <div ref={getLoaderRef} className="ssds-loader-animation-container">
                            <LoaderAnimation/>
                        </div>
                        :
                        null
                    }

                    {
                        !showAddSearchTerm && !showEditSearchTerm && isLoadingDelete
                        ?
                        <div className="sssfs-loader-animation-container">
                            {/*<LoaderAnimation/>*/}
                            {
                                isLoading
                                ?
                                <LoaderAnimation/>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }

                    {
                        showProcessingRequestMsg
                        ?
                        <div className="process-request-msg-fixed">
                            Processing...
                        </div>
                        :
                        null
                    }

                    {
                        showErrorMessage
                        ?
                        <div className="ssd-generic-error-msg-wrapper">
                            <GenericErrorMessage/>
                        </div>
                        :
                        null
                    }


                </div>
                {
                    showFooter
                    ?
                    <Footer/>
                    :
                    null
                }
            </div>
                /*
    } else if(showAddSearchTerm && !showEditSearchTerm) {
        return <Col>
                    <h3 className="store-structure-dpts-categories-heading">Search Terms</h3>
                    <StoreStructureLoadingMsg
                        showStoreStructureLoadingMsg={showStoreStructureLoadingMsg}
                        storeStructureLoadingMsg={storeStructureLoadingMsg}
                    />
                    <StoreStructureAlert 
                            showStoreStructureAlert={showStoreStructureAlert}
                            storeStructureAlertMsg={storeStructureAlertMsg}
                            handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />
                    <AddSearchTermStandAlone 
                            showAddSearchTerm={showAddSearchTerm}
                            handleSaveSearchTerm={handleSaveSearchTerm}
                            handleCancelSearchTerm={handleCancelSearchTerm}
                    />
                </Col>

    } else {
        return <Col>
                    <h3 className="store-structure-dpts-categories-heading">Search Terms</h3>
                    <EditSearchTerm
                            showEditSearchTerm={showEditSearchTerm}
                            showDepartment={showDepartment}
                            showDepartmentCategory={showDepartmentCategory}
                            showProductCategory={showProductCategory}
                            deptNameSelected={deptNameSelected}
                            deptCategoryNameSelected={deptCategoryNameSelected}
                            productCategoryNameSelected={productCategoryNameSelected}
                            searchFilterNameSelected={searchFilterNameSelected}
                            searchTermNameSelected={searchTermNameSelected}
                            searchTermIdSelected={searchTermIdSelected}
                            searchTermImgSelected={searchTermImgSelected}
                            showSearchTermImg={showSearchTermImg}
                            showSearchTerm={showSearchTerm}
                            searchTermSelected={searchTermSelected}
                            searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                            searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                            handleUpdateSearchTerm={handleUpdateSearchTerm}
                            handleCancelSearchTerm={handleCancelSearchTerm}
                    />
                </Col>
    }
    */
    

}


export default StoreStructureSearchTerms2;