import React, {useState, useEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from 'react-router-dom';
import HomePageLayout from './homePageLayout';
import MarketingBanner from './marketingBanner';
import MarketingPicture from './marketingPicture';
import HomePageLayoutItem from './homePageLayoutItem';
import Product from './product';
import HomePageLayoutDisplay from './HomePageLayoutDisplay';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import { ItemLayoutOption } from './HomePageLayoutSelector';
import {addHomePageLayoutItems} from './helper-home-page-layout';
import {useAppSelector} from './redux/hooks';
import LoaderAnimation from './LoaderAnimation';
import loadNavLink from './loadNavLinkHelper';
import GenericErrorMessage from './GenericErrorMessage';
import Footer from './Footer';
import './HomePageLayoutBuilder.css';

interface HplDimension {
    hplId:number;
    hplHeight:number;
}


interface Props {
    //path:string;
}

const HomePageLayoutBuilder2:React.FunctionComponent<Props> = (props:Props)=>{
    const navigate = useNavigate();

    const [homePageLayouts, setHomePageLayouts] = useState<HomePageLayout[]>([]);
    const [homePageLayoutToEdit, setHomePageLayoutToEdit] = useState<HomePageLayout|null>(null);
    const [showAddHomePageLayout, setShowAddHomePageLayout] = useState(false);
    const [showEditHomePageLayout, setShowEditHomePageLayout] = useState(false);

    //search properties
    const [departmentNameSearchText, setDepartmentNameSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No layouts have been added.');

    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(40);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<HomePageLayout[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [nextHomePageLayoutId, setNextHomePageLayoutId] = useState(0);
    const [homePageLayoutOrderOptions, setHomePageLayoutOrderOptions] = useState<ItemLayoutOption[]>([]);

    const [infoMessage, setInfoMessage] = useState('An unexpected error has occurred. Please retry your previous action, and if the error persists try again later or contact customer service.');
    const hplbLayoutContainerRef = useRef<HTMLDivElement>(null);
    const hplDimensions = useRef<HplDimension[]>([]);
    const hplbContainerRef = useRef<HTMLDivElement[]>([]);

    const portrait = useRef<MediaQueryList[]>([]);

    const [showFooter, setShowFooter] = useState(false);


    useEffect(()=>{
        //get home page layouts from database home_page_layouts
        //need to create the route and table for home_page_layouts
        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);

        //window.scrollTo(0,0);
        getAllHomePageLayouts('none', 'reloaded');

    },[]);


    function getAllHomePageLayouts(action?:string, status?:string){
        const s = (typeof status !== 'undefined') ? status : 'notReloaded';
        fetch(`https://server.kando-proto-3.com/get-all-home-page-layouts/${s}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then(res=>res.json())
        .then(({hpls}:{hpls:HomePageLayout[]})=>{
            //console.log('res:', hpls);
            if(hpls.length > 0){
                const layouts = hpls.map((hpl)=>{
                                const r = new HomePageLayout({
                                                                ...hpl,
                                                                items:addHomePageLayoutItems(hpl, status)
                                                            });
                                return r;
                            });

                //console.log('layouts:', layouts);
                setHomePageLayoutOrderOptions(getHomePageLayoutOrderOptions(layouts));
                setShowNoSearchResultsFound(false);
                setHomePageLayouts(layouts);
                if(action === 'add'){
                    setListChangerBtnPosition(0);
                    getAnyItemList(0, layouts, itemsPerList, showItemsPerList);

                } else {
                    getAnyItemList(listChangerBtnPosition, layouts, itemsPerList, showItemsPerList);
                }
                
                displayListSorter(itemsPerList, layouts);
                setIsLoading(false);

                setShowFooter(true);

            } else {
                //console.log('test');
                //need to show message that no home page layouts have been added.
                setNoSearchResultsMessage(`No layouts have been added`);
                setShowNoSearchResultsFound(true);
                setHomePageLayouts([]);
                setIsLoading(false);

                setShowFooter(true);

            }

        })
        .catch(err=>{
            //console.log('fetch home page layouts error:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);

            setShowFooter(true);
        });

    }


    function getHomePageLayoutOrderOptions(hpls:HomePageLayout[]):ItemLayoutOption[] {
        return hpls.map((hpl, i)=>{
                    switch(hpl.homePageLayoutType){
                        case 'card-box':
                        case 'slider3':
                        case 'slider4':
                            if(hpl.heading === 'none'){
                                return ({
                                    value:`${(hpl.items[0] as MarketingPicture).heading}`,
                                    order:hpl.homePageLayoutOrder,
                                    id:hpl.homePageLayoutId
                                });

                            } else {
                                return ({
                                    value:hpl.heading,
                                    order:hpl.homePageLayoutOrder,
                                    id:hpl.homePageLayoutId
                                });
                            }

                        case 'carousel':
                            if(hpl.heading === 'none'){
                                return ({
                                    value:'product list',
                                    order:hpl.homePageLayoutOrder,
                                    id:hpl.homePageLayoutId
                                });

                            } else {
                                return ({
                                    value:hpl.heading,
                                    order:hpl.homePageLayoutOrder,
                                    id:hpl.homePageLayoutId
                                });
                            }

                        case 'banner-box':
                            return ({
                                value:(hpl.items[0] as MarketingBanner).heading,
                                order:hpl.homePageLayoutOrder,
                                id:hpl.homePageLayoutId
                            });
                        default:
                            throw new Error('no home page layout type found.');
                    }
                });
    }

    
    function addHomePageLayoutItems(hpl:HomePageLayout, status?:string):HomePageLayoutItem[] {
        //console.log('hpl:', hpl);
        switch(hpl.homePageLayoutType){
            case 'card-box':
                if(hpl.items.length > 0){
                    const cbItems = hpl.items.map((item)=>{
                                    const mp = new MarketingPicture({...item});
                                    if(typeof status === 'undefined'){
                                        mp.selectedForRemoval = !!mp.selectedForRemoval;

                                    } else {
                                        mp.selectedForRemoval = false;
                                    }
                                    
                                    return mp;
                                });
                        //console.log('cbItems:', cbItems);
                    return cbItems;

                } else {
                    return [];
                }

            case 'banner-box':
                if(hpl.items.length > 0){
                    const bbItems = hpl.items.map((item)=>{
                                    const mb = new MarketingBanner({...item});
                                    if(typeof status === 'undefined'){
                                        mb.selectedForRemoval = !!mb.selectedForRemoval;

                                    } else {
                                        mb.selectedForRemoval = false;
                                    }

                                    return mb;
                                });
                    return bbItems;

                } else {
                    return [];
                }

            case 'slider3':
                if(hpl.items.length > 0){
                    const s3Items = hpl.items.map((item)=>{
                                    const mp = new MarketingPicture({...item});
                                    if(typeof status === 'undefined'){
                                        mp.selectedForRemoval = !!mp.selectedForRemoval;

                                    } else {
                                        mp.selectedForRemoval = false;
                                    }

                                    return mp;
                                });
                    return s3Items;

                } else {
                    return [];
                }

            case 'slider4':
                if(hpl.items.length > 0){
                    const s4Items = hpl.items.map((item)=>{
                                    const mp = new MarketingPicture({...item});
                                    if(typeof status === 'undefined'){
                                        mp.selectedForRemoval = !!mp.selectedForRemoval;

                                    } else {
                                        mp.selectedForRemoval = false;
                                    }

                                    return mp;
                                });
                    return s4Items;

                } else {
                    return [];
                }

            case 'carousel':
                if(hpl.items.length > 0){
                    const cItems = hpl.items.map((item)=>{
                                    const p = new Product({...item});
                                    if(typeof status === 'undefined'){
                                        p.selectedForRemoval = !!p.selectedForRemoval;

                                    } else {
                                        p.selectedForRemoval = false;
                                    }
                                    
                                    return p;

                                });
                    return cItems;

                } else {
                    return [];
                }

            default:
                throw new Error('no home page layout type found.');
        }
    }

    function reorderHomePageLayouts(hplSelected:HomePageLayout, hpls:HomePageLayout[]):HomePageLayout[]{
        //remove the previous home page layout from the list
        const phpl = hpls.filter((hpl)=>{
                        return hpl.homePageLayoutId === hplSelected.homePageLayoutId;
                    });
        
        //get previous order number
        const po = phpl[0].homePageLayoutOrder;
        //get current order number
        const co = hplSelected.homePageLayoutOrder;


        if(co > po){
            //then all ids that are less than or equal to co need to be decreased by 1;
            //update home page layout in list with current order
            
            //decrease all home page layouts order by 1
            const dhpls = hpls.map((hpl)=>{
                                if(hpl.homePageLayoutId === hplSelected.homePageLayoutId){
                                    hpl.homePageLayoutOrder = co;
                                }

                                if((hpl.homePageLayoutId !== hplSelected.homePageLayoutId) 
                                    && hpl.homePageLayoutOrder <= co && hpl.homePageLayoutOrder >= po){
                                    //console.log('passed');
                                    hpl.homePageLayoutOrder -= 1;
                                }

                                return hpl;
                                
                            });

            //console.log('uhpls:', dhpls, hplSelected);
            return dhpls;


        } else {
            //co < po
            //then all ids that are more than or equal to co need to be increased by 1;

            //increase all home page layouts order by 1
            const ihpls = hpls.map((hpl)=>{
                                if(hpl.homePageLayoutId === hplSelected.homePageLayoutId){
                                    hpl.homePageLayoutOrder = co;
                                }

                                if((hpl.homePageLayoutId !== hplSelected.homePageLayoutId)
                                 && hpl.homePageLayoutOrder >= co && hpl.homePageLayoutOrder <= po){
                                    //console.log('hpl:', hpl);
                                    //console.log('passed');
                                    hpl.homePageLayoutOrder += 1;
                                }

                                return hpl;
                                
                            });

             //console.log('uhpls:', ihpls, hplSelected);
            return ihpls;
        }
    }
    

    function handleUpdateHomePageLayoutOrder(hpl:HomePageLayout):void {
        setIsLoading(true);
        //console.log('hpl:', hpl, 'hpls:', homePageLayouts);
        const rhpls = reorderHomePageLayouts(hpl, homePageLayouts);

        //save home page layout and home page layout items in db
        fetch('https://server.kando-proto-3.com/update-home-page-layout-order',{
            method:'POST',
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(rhpls)
        })
        .then((res)=>res.json())
        .then(({status})=>{
            if(status === 'updated'){
                getAllHomePageLayouts('none');
                //need to put getAllHomePageLayouts on line #45 in a function and call it here to get the updated
                //list of home page layouts
                //need to add the reordering home page layout capability 
                //need to setup error message below if nothing updated
                //console.log('el.height:');

            } else {
                //need to set Generic Info Message to show error message
                setIsLoading(false);
                setShowErrorMessage(true);

            }
            

        })
        .catch((err)=>{
            //console.log('error updating home page layout order in db:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);
        });

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    }

    function setupGetHPLRef(hpl:HomePageLayout, numOfhpls:number):(el:HTMLDivElement|null)=>void{
        return function getHPLRef(el:HTMLDivElement|null):void {
                    if(el !== null && hplDimensions.current.length === 0){
                        hplDimensions.current.push({hplHeight:el.getBoundingClientRect().height, hplId:hpl.homePageLayoutId});
            
                    } else if(el !== null) {
                        const found = hplDimensions.current.find((ir)=>{
                                            return ir.hplId === hpl.homePageLayoutId;
                                        });

                        if(typeof found !== 'undefined' && (found.hplHeight !== el.getBoundingClientRect().height)){
                            found.hplHeight = el.getBoundingClientRect().height;
                        }
            
                        if(typeof found === 'undefined'){
                            hplDimensions.current.push({hplHeight:el.getBoundingClientRect().height, hplId:hpl.homePageLayoutId});
                        }
            
                    }


                    
                    if(hplDimensions.current.length > numOfhpls){
                        hplDimensions.current = hplDimensions.current.filter((hpld)=>{
                                                                        let found = false;
                                                                        homePageLayouts.forEach((hpl)=>{
                                                                            if(hpl.homePageLayoutId === hpld.hplId){
                                                                                found = true;
                                                                            }
                                                                        });
                                                                        
                                                                        return found;
                                                                    });
                    }


                    
                    //console.log('hplDimensions:', hplDimensions.current, numOfhpls, homePageLayouts);

                    if(hplDimensions.current.length === 1 && numOfhpls === 1){
                        hplDimensions.current = [];
                        hplbLayoutContainerRef.current!.style.minHeight = `950px`;
                        return;
                    }


                    if(hplDimensions.current.length === numOfhpls){
                        let total = 0;
                        hplDimensions.current.forEach((hpld)=>{
                                                total += hpld.hplHeight;
                                            });

                        //console.log('hplDimensions:', total);
                        total += 64 + 64 + 189 + (numOfhpls * 64);
                        //console.log('hplDimensions:', total);

                        //console.log('ref:', hplbLayoutContainerRef.current!);

                        if(hplbLayoutContainerRef.current! !== null){
                            hplbLayoutContainerRef.current!.style.minHeight = `${total}px`;
                        }
                        
                    }
                }
    }
    

    useEffect(()=>{
        window.addEventListener('resize', resizeEventHandler);

        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);
        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", resizeEventHandler);

        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeEventHandler);
        portrait.current[0].removeEventListener("change", resizeEventHandler);
        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        resizeEventHandler(new Event('test'));
    }

    function resizeEventHandler(e:Event):void {
        //console.log('hplbc:', hplbContainerRef.current[0].getBoundingClientRect().height);

        //previous version that works
        //const hplbLayoutContainer = hplbContainerRef.current[0].closest('.hplb-layout-container') as HTMLDivElement;
        const hplbLayoutContainer = hplbContainerRef.current[0] ? hplbContainerRef.current[0].closest('.hplb-layout-container') as HTMLDivElement : null;

        if(hplbLayoutContainer !== null){
            const adjHeight = 189 + 64 + hplbContainerRef.current[0].getBoundingClientRect().height + 64 + 57;
            hplbLayoutContainer.style.minHeight = `${adjHeight}px`;
        }

    }


    function getHPLBContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && hplbContainerRef.current.length > 0){
            hplbContainerRef.current.length = 0;
        }

        if(el !== null && hplbContainerRef.current.length === 0){
            hplbContainerRef.current.push(el);

        } else if(el !== null) {
            const found = hplbContainerRef.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                hplbContainerRef.current.push(el);
            }

        }

        //console.log('hplbc:', hplbContainerRef.current[0].getBoundingClientRect().height);

        const hplbLayoutContainer = hplbContainerRef.current[0].closest('.hplb-layout-container') as HTMLDivElement;
        if(hplbLayoutContainer !== null){
            const adjHeight = 189 + 64 + hplbContainerRef.current[0].getBoundingClientRect().height + 64 + 57;
            hplbLayoutContainer.style.minHeight = `${adjHeight}px`;
        }
    }


    function setupHomePageLayoutContainers(itemsList:object[]):JSX.Element{
        //console.log('itemsList:', itemsList);
        return <div ref={getHPLBContainerRef} className="hplb-container">
                    {(itemsList as HomePageLayout[]).map((pl)=>{
                    return (<div key={pl.homePageLayoutId} ref={setupGetHPLRef(pl, itemsList.length)} className="hplb-item-container">
                                <HomePageLayoutDisplay homePageLayout={pl} showEditHomePageLayout={false}
                                    homePageLayoutOrderOptions={homePageLayoutOrderOptions}
                                    handleCloseHomePageLayout={handleCloseHomePageLayout}
                                    handleSaveHomePageLayout={handleSaveHomePageLayout}
                                    handleUpdateHomePageLayout={handleUpdateHomePageLayout}
                                    handleDeleteHomePageLayout={handleDeleteHomePageLayout}
                                    handleEditHomePageLayout={handleEditHomePageLayout}
                                    handleUpdateHomePageLayoutOrder={handleUpdateHomePageLayoutOrder}
                                    productionMode={false} />
                            </div>);
                    })}
                </div>
    }

    function handleAddPageLayout(e:React.MouseEvent):void {
        //get next home page layout id from database
        fetch('https://server.kando-proto-3.com/get-next-home-page-layout-id-order', {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then(({nextHomePageLayoutId})=>{
            //console.log('hplId:', nextHomePageLayoutId);
            setNextHomePageLayoutId(nextHomePageLayoutId);
        })
        .catch((err)=>{
            //console.log('error get next home page layout id:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);
        });

        setShowAddHomePageLayout(true);
        setShowEditHomePageLayout(false);


        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);


    }

    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as HomePageLayout[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);    

    }

    function displayListSorter(itemsPerList:number, hpls:HomePageLayout[]):void {
        if(hpls.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleCloseHomePageLayout(homePageLayout:HomePageLayout|null):void {
        //console.log('hpl:', homePageLayout);
        if(homePageLayout !== null){
            setIsLoading(true);
            const hpliInfo = {
                items:homePageLayout.items,
                type:homePageLayout.homePageLayoutType
            }

            fetch('https://server.kando-proto-3.com/save-home-page-layout-items', {
                method:'POST',
                headers:{
                    'content-type':'application/json',
                    'accept':'application/json'
                },
                body:JSON.stringify(hpliInfo)
            })
            .then((res)=>res.json())
            .then(({status})=>{
                //console.log('status:', status);
                setIsLoading(false);
                setShowAddHomePageLayout(false);
                setShowEditHomePageLayout(false);
            })
            .catch((err)=>{
                console.error('save home page layout items to db error:', err.message);
                setIsLoading(false);
                setShowErrorMessage(true);
            });
            
        } else {
            setShowAddHomePageLayout(false);
            setShowEditHomePageLayout(false);
        }
        

    }

    function handleUpdateHomePageLayout(hpl:HomePageLayout):void {
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        //need to add home page layout to home page layout list
        setShowAddHomePageLayout(false);
        setShowEditHomePageLayout(false);
        setIsLoading(true);

        //console.log('hpl20:', hpl);
        //save home page layout and home page layout items in db
        fetch('https://server.kando-proto-3.com/update-home-page-layout',{
            method:'POST',
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(hpl)
        })
        .then((res)=>res.json())
        .then(({status})=>{
            if(status === 'updated'){
                getAllHomePageLayouts('none');
                //need to put getAllHomePageLayouts on line #45 in a function and call it here to get the updated
                //list of home page layouts
                //need to add the reordering home page layout capability 
                //need to setup error message below if nothing updated

            } else {
                //need to set Generic Info Message to show error message
                setIsLoading(false);
                setShowErrorMessage(true);
            }

            /*previous version
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
            */



        })
        .catch((err)=>{
            console.error('error updating home page layout in db:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);
        });


    }

    function handleSaveHomePageLayout(hpl:HomePageLayout):void {
        //need to add home page layout to home page layout list
        setShowAddHomePageLayout(false);
        setShowEditHomePageLayout(false);
        setIsLoading(true);

        //console.log('hpl:', hpl, 'hpls:', homePageLayouts);

        //save home page layout and home page layout items in db
        fetch('https://server.kando-proto-3.com/save-home-page-layout',{
            method:'POST',
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify({hpl, homePageLayouts})
        })
        .then((res)=>res.json())
        .then(({status})=>{
            if(status === 'updated'){
                getAllHomePageLayouts('add');
                //need to put getAllHomePageLayouts on line #45 in a function and call it here to get the updated
                //list of home page layouts
                //need to add the reordering home page layout capability 
                //need to setup error message below if nothing updated

            } else {
                //need to set Generic Info Message to show error message
                setIsLoading(false);
                setShowErrorMessage(true);
            }            

        })
        .catch((err)=>{
            console.error('error saving home page layout in db:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);
        });

        
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        //setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            //setIsLoading(false);
        },100);


    }

    function handleDeleteHomePageLayout(hpl:HomePageLayout):void {
        //need to add home page layout to home page layout list
        setShowAddHomePageLayout(false);
        setShowEditHomePageLayout(false);
        setIsLoading(true);

        //console.log('hpl:', hpl);
        //save home page layout and home page layout items in db
        fetch('https://server.kando-proto-3.com/delete-home-page-layout',{
            method:'DELETE',
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(hpl)
        })
        .then((res)=>res.json())
        .then(({status})=>{
            if(status === 'deleted'){
                //previous version
                //getAllHomePageLayouts();
                //console.log('dhpls:', homePageLayouts);

                const uhpls = homePageLayouts.filter((h)=>{
                    return h.homePageLayoutId !== hpl.homePageLayoutId;
                });
                //console.log('usls:', usls);
                if(uhpls.length === 0){
                    setNoSearchResultsMessage(`No home page layouts have been added.`);
                    setShowNoSearchResultsFound(true);
                    setHomePageLayouts(uhpls);
                    
                } else {

                    //console.log('r15:', (uhpls.length / itemsPerList), listChangerBtnPosition);

                    if((uhpls.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, uhpls, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, uhpls);

                    } else {
                        getAnyItemList(listChangerBtnPosition, uhpls, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, uhpls);
                    }

                    setHomePageLayouts(uhpls);
                    //getAnyItemList(listChangerBtnPosition, usls, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, usls);
                }

                setIsLoading(false);

                //need to put getAllHomePageLayouts on line #45 in a function and call it here to get the updated
                //list of home page layouts
                //need to add the reordering home page layout capability 
                //need to setup error message below if nothing updated

            } else {
                //need to set Generic Info Message to show error message
                setIsLoading(false);
                setShowErrorMessage(true);
            } 
            

        })
        .catch((err)=>{
            console.error('error saving home page layout in db:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);
        });


        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);


    }

    function handleEditHomePageLayout(hpl:HomePageLayout):void {
        //console.log('edit:', hpl);
        setHomePageLayoutToEdit(hpl);
        setShowAddHomePageLayout(false);
        setShowEditHomePageLayout(true);
    }

    /*previous version
    for no results message
    <GenericInfoMessage infoMessage={noSearchResultsMessage}
    customStyle={'hplb-info-message'} showCloseBtn={false}/>
    */

    function closeInfoMessageHandler():void {
        setShowErrorMessage(false);
    }

    

    /*
    if(!showAddHomePageLayout && !showEditHomePageLayout && homePageLayouts.length === 0 && !isLoading){
        console.log('test');*/
        return <div className="hplb-wrapper">
                    <div ref={hplbLayoutContainerRef} className="hplb-layout-container">
                        <ul className="hplb-breadcrum-container">
                            <li className="aprbi">
                                <Link to="/admin" className="store-details-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="aprbi store-details-breadcrum-link-store-name">Home Page Layouts</li>
                        </ul>

                        {
                            !showAddHomePageLayout && !showEditHomePageLayout
                            ?
                            <h1 className="home-page-layout-builder-heading">Home Page Layouts</h1>
                            :
                            null
                        }

                        {/*
                            showAddHomePageLayout && !showEditHomePageLayout
                            ?
                            <h1 className="home-page-layout-builder-heading">Add Layout</h1>
                            :
                            null
                        */}

                        {/*
                            !showAddHomePageLayout && showEditHomePageLayout
                            ?
                            <h1 className="home-page-layout-builder-heading">Edit Layout</h1>
                            :
                            null
                        */}

                        {
                            !showAddHomePageLayout && !showEditHomePageLayout
                            ?
                            <div className="hplb-add-layout-btn-container">
                                <button type="button" className="admin-add-item-btn" onClick={handleAddPageLayout}>Add Layout</button>
                            </div>
                            :
                            null
                        }


                        {
                            !showAddHomePageLayout && !showEditHomePageLayout && 
                            (homePageLayouts.length === 0 || homePageLayouts.length >= 0)
                            && !isLoading && showNoSearchResultsFound
                            ?
                            <div className="hplb-no-results-msg-container">
                                {noSearchResultsMessage}
                            </div>
                            : 
                            null
                        }

                        {
                            !showAddHomePageLayout && !showEditHomePageLayout && homePageLayouts.length > 0 && !isLoading          
                            ?
                            <ListViewer itemsList={itemsList} items={homePageLayouts} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupHomePageLayoutContainers}
                                adjContainerStyle={{width:'calc(100% - 64px)', margin:'0 auto'}} />
                            :
                            null
                        }


                        {
                            showAddHomePageLayout && !showEditHomePageLayout
                            ?
                            <HomePageLayoutDisplay homePageLayout={null} showEditHomePageLayout={false}
                                nextHomePageLayoutId={nextHomePageLayoutId}
                                handleCloseHomePageLayout={handleCloseHomePageLayout}
                                handleSaveHomePageLayout={handleSaveHomePageLayout}
                                handleUpdateHomePageLayout={handleUpdateHomePageLayout}
                                handleDeleteHomePageLayout={handleDeleteHomePageLayout}
                                handleUpdateHomePageLayoutOrder={handleUpdateHomePageLayoutOrder}
                            />
                            :
                            null
                        }

                        {
                            !showAddHomePageLayout && showEditHomePageLayout
                            ?
                            <HomePageLayoutDisplay showEditHomePageLayout={true} 
                                homePageLayout={homePageLayoutToEdit} 
                                handleCloseHomePageLayout={handleCloseHomePageLayout}
                                handleSaveHomePageLayout={handleSaveHomePageLayout}
                                handleUpdateHomePageLayout={handleUpdateHomePageLayout}
                                handleDeleteHomePageLayout={handleDeleteHomePageLayout}
                                handleUpdateHomePageLayoutOrder={handleUpdateHomePageLayoutOrder}
                            />
                            :
                            null
                        }

                        {
                            showErrorMessage
                            ?
                            <div className="hplb-generic-error-msg-wrapper">
                                <GenericInfoMessage infoMessage={infoMessage} showExtraMsgContainer={true}
                                showCloseBtn={true} closeInfoMsgHandler={closeInfoMessageHandler}/>
                            </div>
                            :
                            null
                        }

                        {
                            isLoading
                            ?
                            <div className="hplb-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null
                        }
                    </div>
                    
                    {
                        showFooter
                        ?
                        <div style={{marginTop:'32px'}}>
                            <Footer/>
                        </div>
                        :
                        null
                    }
                </div>
    /*} else if(!showAddHomePageLayout && !showEditHomePageLayout && homePageLayouts.length > 0 && !isLoading) {
        console.log('test');
        return <div className="hplb-wrapper">
                    <h1 className="home-page-layout-builder-heading">Home page layouts</h1>
                    <button type="button" onClick={handleAddPageLayout}>Add layout</button>
                    {
                        showNoSearchResultsFound
                        ? 
                        <GenericInfoMessage infoMessage={noSearchResultsMessage}
                            customStyle={'hplb-info-message'} showCloseBtn={false}/>
                        :
                        <ListViewer itemsList={itemsList} items={homePageLayouts} showListSorter={showListSorter}
                            listChangerBtnPosition={listChangerBtnPosition}
                            itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                            getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupHomePageLayoutContainers} />
                    }
                </div>
    } else if(showAddHomePageLayout && !showEditHomePageLayout) {
        //return <div>
          //          <AddStoreDepartment showEditDepartment={false} handleCloseForm={handleCloseForm}/>
        //        </div>
        return <div className="hplb-wrapper">
                    <h3>Add home page layout</h3>
                    <HomePageLayoutDisplay homePageLayout={null} showEditHomePageLayout={false}
                        nextHomePageLayoutId={nextHomePageLayoutId}
                        handleCloseHomePageLayout={handleCloseHomePageLayout}
                        handleSaveHomePageLayout={handleSaveHomePageLayout}
                        handleUpdateHomePageLayout={handleUpdateHomePageLayout}
                        handleDeleteHomePageLayout={handleDeleteHomePageLayout}
                        handleUpdateHomePageLayoutOrder={handleUpdateHomePageLayoutOrder}/>
                </div>
    } else if(!showAddHomePageLayout && showEditHomePageLayout){
        //return  <EditStoreDepartment department={departmentToEdit!} handleCloseForm={handleCloseForm}/>
        return  <HomePageLayoutDisplay showEditHomePageLayout={true} 
                    homePageLayout={homePageLayoutToEdit} 
                    handleCloseHomePageLayout={handleCloseHomePageLayout}
                    handleSaveHomePageLayout={handleSaveHomePageLayout}
                    handleUpdateHomePageLayout={handleUpdateHomePageLayout}
                    handleDeleteHomePageLayout={handleDeleteHomePageLayout}
                    handleUpdateHomePageLayoutOrder={handleUpdateHomePageLayoutOrder}
                    />
    } else {
        return <div className="hplb-wrapper">
                    <h3 className="home-page-layout-builder-heading">Home page layouts</h3>               
                    <div>Loading...</div>
                    <div data-testid="spinner">
                            <i className="fa fa-spinner fa-spin" style={{fontSize:'48px'}}></i>
                    </div>
                </div>
    }
    */


}

export default HomePageLayoutBuilder2;