import MarketingBanner from "./marketingBanner";
import MarketingPicture from "./marketingPicture";
import Product from "./product";
import HomePageLayout from "./homePageLayout";
import HomePageLayoutItem from "./homePageLayoutItem";

export function convertHeadingType(type:string):string{
    switch(type){
        case 'no-split':
            return 'noSplit';
        case 'split-2':
        case 'split-3':
            return 'split';
        default:
            throw new Error('no heading type found.');

    }
}

export function getHeading(mb:MarketingBanner, headingTypeDesktopOptionSelected:string, 
                    headingTypeMobileOptionSelected:string, bannerView:string):{heading1:string, heading2:string} {
                        //console.log('htdos:', headingTypeDesktopOptionSelected);
                        
    switch(bannerView){
        case 'desktop':
        case 'production':
            switch(headingTypeDesktopOptionSelected){
                case 'no-split':
                    return headingSplit(mb, 1);
                case 'split-2':
                    return headingSplit(mb, 2);
                case 'split-3':
                    return headingSplit(mb, 3);
                default:
                    throw new Error('no heading type desktop option selected found.');
            }
        case 'mobile':
        case 'production':
            switch(headingTypeMobileOptionSelected){
                case 'no-split':
                    return headingSplit(mb, 1);
                case 'split-2':
                    return headingSplit(mb, 2);
                case 'split-3':
                    return headingSplit(mb, 3);
                default:
                    throw new Error('no heading type mobile option selected found.');
            }
        default:
            throw new Error('no banner view found.');
    }

}

export function headingSplit(mb:MarketingBanner, numberOfHeadings:number):{heading1:string, heading2:string} {
    if(numberOfHeadings === 1){
        return {heading1:mb.heading, heading2:'none'};

    } else {
        const heading = mb.heading;
        const headingWords = heading.split(' ');
        let h1:string[] = [];
        let h2:string[] = [];

        for(let i=0; i < headingWords.length; i++){
            if(i < numberOfHeadings){
                h1.push(headingWords[i]);

            } else {
                h2.push(headingWords[i]);
            }
        }
        
        const heading1 = h1.join(' ');
        const heading2 = h2.join(' ');

        return {heading1, heading2};
    }

}

export function addHomePageLayoutItems(hpl:HomePageLayout):HomePageLayoutItem[] {
    //console.log('hpl:', hpl);
    switch(hpl.homePageLayoutType){
        case 'card-box':
            if(hpl.items.length > 0){
                const cbItems = hpl.items.map((item)=>{
                                return new MarketingPicture({...item});
                            });
                    //console.log('cbItems:', cbItems);
                return cbItems;

            } else {
                return [];
            }

        case 'banner-box':
            if(hpl.items.length > 0){
                const bbItems = hpl.items.map((item)=>{
                                return new MarketingBanner({...item});
                            });
                return bbItems;

            } else {
                return [];
            }

        case 'slider3':
            if(hpl.items.length > 0){
                const s3Items = hpl.items.map((item)=>{
                                return new MarketingPicture({...item});
                            });
                return s3Items;

            } else {
                return [];
            }

        case 'slider4':
            if(hpl.items.length > 0){
                const s4Items = hpl.items.map((item)=>{
                                return new MarketingPicture({...item});
                            });
                return s4Items;

            } else {
                return [];
            }

        case 'carousel':
            if(hpl.items.length > 0){
                const cItems = hpl.items.map((item)=>{
                                return new Product({...item});
                            });
                return cItems;

            } else {
                return [];
            }

        default:
            throw new Error('no home page layout type found.');
    }
}