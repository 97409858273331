import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';

export function getDepartmentNamesPromise():Promise<StoreDepartment[]> {

    function addDepartments(ds:StoreDepartment[]):StoreDepartment[]{
        return ds.map((d)=>{
            return new StoreDepartment({...d});
        });
    }

    return new Promise((resolve, reject)=>{
        fetch(`https://server.kando-proto-3.com/get-departments`,{
            method:'GET',
            headers:{
                'Accept':'application/javascript'
            }
        })
        .then((res)=>res.json())
        .then((ds:StoreDepartment[])=>{
            const uds = addDepartments(ds);
            resolve(uds);            
        })
        .catch((err)=>{
            console.error('dept names fetch error:', err);
            reject(err);
        });
    });
}

export function getDepartmentCategoryNamesPromise():Promise<DepartmentCategory[]> {
    function addDepartmentCategories(dcs:DepartmentCategory[]):DepartmentCategory[]{
        return dcs.map((dc)=>{
            return new DepartmentCategory({...dc});
        });
    }

    return new Promise((resolve, reject)=>{
        fetch(`https://server.kando-proto-3.com/get-department-categories`,{
            method:'GET',
            headers:{
                'Accept':'application/javascript'
            }
        })
        .then((res)=>res.json())
        .then((dcs:DepartmentCategory[])=>{
            const udcs = addDepartmentCategories(dcs);
            resolve(udcs);            
        })
        .catch((err)=>{
            console.error('dept category names fetch error:', err);
            reject(err);
        });
    });
}

export function getProductCategoryNamesPromise():Promise<ProductCategory[]> {
    function addProductCategories(pcs:ProductCategory[]):ProductCategory[]{
        return pcs.map((pc)=>{
            return new ProductCategory({...pc});
        });
    }

    return new Promise((resolve, reject)=>{
        fetch(`https://server.kando-proto-3.com/get-product-categories`,{
            method:'GET',
            headers:{
                'Accept':'application/javascript'
            }
        })
        .then((res)=>res.json())
        .then((pcs:ProductCategory[])=>{
            const upcs = addProductCategories(pcs);
            resolve(upcs);            
        })
        .catch((err)=>{
            console.error('product category names fetch error:', err);
            reject(err);
        });
    });
}