import Product from './product';

export default class ShoppingCartCategory {
    storeDepartmentName:string;
    shoppingCartProducts:Product[];
    constructor(options:{
        storeDepartmentName?:string,
        shoppingCartProducts?:Product[]
    }={}){
        this.storeDepartmentName = options.storeDepartmentName || '';
        this.shoppingCartProducts = options.shoppingCartProducts || [];
    }
}