import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import StoreStructureOptionSelect from './StoreStructureOptionSelect';
import {getDepartmentNamesPromise, 
    getDepartmentCategoryNamesPromise, 
    getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import StoreDepartmentSelectControl from './StoreDepartmentSelectControl';
import DepartmentCategorySelectControl from './DepartmentCategorySelectControl';
import ProductCategorySelectControl from './ProductCategorySelectControl';
import StoreDepartment from './storeDepartment';
import FormErrorMessage, {FormError, FormErrorType} from './FormErrorMessage';
import DBErrorMessage, {DBError} from './DBErrorMessage';
import GenericInfoMessage from './GenericInfoMessage';
import LoaderAnimation from './LoaderAnimation';
import formatLinkString from './helper-format-link-string';
import './AddProductCategory.css';

interface Props {
    //showAddProductCategory:boolean;
    showEditProductCategory:boolean;
    productCategoryToEdit?:ProductCategory;
    handleCloseForm:(formType:string, cancel?:boolean)=>void;
}

interface ProductCategoryImage {
    source:string;
    path:string;
    type:string;
}

interface ThumbnailImage {
    img:HTMLImageElement;
    name:string;
}

interface EditProductCategoryInfo {
    productCategoryToEdit?:ProductCategory;
    productCategoryImages?:ProductCategoryImage[];
    relatedDepartments?:StoreDepartment[];
    relatedDepartmentCategories?:DepartmentCategory[];
    productCategoryNameSelected?:string;
    productCategoryToEditError?:Error;
}

interface DeleteImageResponse {
    msg:string;
}

const AddProductCategory: React.FunctionComponent<Props> = (props:Props) => {

    const {showEditProductCategory, productCategoryToEdit,  handleCloseForm} = props;
    const [departments, setDepartments] = useState<StoreDepartment[]>([]);
    const [departmentNames, setDepartmentNames] = useState<string[]>([]);
    const [departmentNameSelected, setDepartmentNameSelected] = useState('');
    const [showDeptNameErrorMsg, setShowDeptNameErrorMsg] = useState(false);
    const [deptNameErrorMsg, setDeptNameErrorMsg] = useState('Please add a department name.');
    const [departmentCategories, setDepartmentCategories] = useState<DepartmentCategory[]>([]);
    const [departmentCategoryNames, setDepartmentCategoryNames] = useState<string[]>([]);
    const [departmentCategoryNameSelected, setDepartmentCategoryNameSelected] = useState('');
    const [showDeptCategoryNameErrorMsg, setShowDeptCategoryNameErrorMsg] = useState(false);
    const [deptCategoryNameErrorMsg, setDeptCategoryNameErrorMsg] = useState('Please add a department category name.');
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [productCategoryNames, setProductCategoryNames] = useState<string[]>([]);
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState('');
    const [showProductCategoryNameErrorMsg, setShowProductCategoryNameErrorMsg] = useState(false);
    const [productCategoryNameErrorMsg, setProductCategoryNameErrorMsg] = useState('Please add a product category name.');
    const [productCategoryToEditId, setProductCategoryToEditId] = useState(0);
    const [showProductCategoryNameSearch, setShowProductCategoryNameSearch] = useState(false);
    const [showProductCategoryKeywordSearch, setShowProductCategoryKeywordSearch] = useState(false);
    const [showProductSubCategoryShortcut, setShowProductSubCategoryShortcut] = useState(false);
    const [showRelatedProductCategoryShortcut, setShowRelatedProductCategoryShortcut] = useState(false);
    const [productCategoryThumbnailImages, setProductCategoryThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [productCategoryImages, setProductCategoryImages] = useState<File[]>([]);
    const [editProductCategoryLoaded, setEditProductCategoryLoaded] = useState(false);

    //department select element reference
    const dSelectElementRef = useRef<{dSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //department category select element reference
    const dcSelectElementRef = useRef<{dcSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //product category select element reference
    const pcSelectElementRef = useRef<{pcSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);

    const [siteIsLoaded, setSiteIsLoaded] = useState(false);
    const dnLabelRef = useRef<HTMLLabelElement>(null);
    const dcnLabelRef = useRef<HTMLLabelElement>(null);
    const pcnLabelRef = useRef<HTMLInputElement>(null);
    const nameSearchRef = useRef<HTMLInputElement>(null);
    const keywordSearchRef = useRef<HTMLInputElement>(null);
    const productSubCategoryShortcutRef = useRef<HTMLInputElement>(null);
    const relatedProductCategoryShortcutRef = useRef<HTMLInputElement>(null);
    const pciLabelRef = useRef<HTMLLabelElement>(null);
    const imageProductCategoryRef = useRef<HTMLInputElement>(null);
    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });
    const [dbError, setDBError] = useState<DBError>({
                                                    productCategoryNameError:{hasError:false, area:'none'},
                                                    imageErrors:[]
                                                        });

    const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);
    const dbErrorMsgRef = useRef<{dbemcRef:React.RefObject<HTMLDivElement>}>(null);
    
    const [showGenericInfoMessage, setShowGenericInfoMessage] = useState(false);
    const [genericInfoMessage, setGenericInfoMessage] = useState('No items found.');

    const [showProductCategoryNameLimitError, setShowProductCategoryNameLimitError] = useState(false);
    const [showProductCategoryFileLimitError, setShowProductCategoryFileLimitError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if(showEditProductCategory){

            Promise.all([/*getDepartmentNamesPromise(),*/ getProductCategoryToEditPromise(productCategoryToEdit!)])
            .then(([/*departments,*/ editProductCategoryInfo])=>{
                //console.log('ds:', departments);
                //setupDepartmentInfo([...departments]);
                
                //console.log('editProductCategoryInfo:', editProductCategoryInfo);
                setupDepartmentInfo(editProductCategoryInfo.relatedDepartments!);
                setupDepartmentCategoryInfo(editProductCategoryInfo.relatedDepartmentCategories!);
                setProductCategoryNameSelected(editProductCategoryInfo.productCategoryNameSelected!);

                //original version
                //setupProductSubCategoryEditInfo(editProductSubCategoryInfo.productSubCategoryToEdit!);
                setupProductCategoryEditInfo(editProductCategoryInfo.productCategoryToEdit!);

                setupProductCategoryImages(editProductCategoryInfo.productCategoryImages!);

            })
            .catch((err)=>console.error('promise all for names error:', err));

        } else {

            Promise.all([getDepartmentNamesPromise() /*getDepartmentCategoryNamesPromise(), getProductCategoryNamesPromise(),*/])
            .then(([departments, /*departmentCategories, productCategories,*/])=>{
                //console.log('ds:', departments);
                //console.log('dcs:', departmentCategories);
                //console.log('pcs:', productCategories);
                //console.log('sfgs:', searchFilterGroups);
                setupDepartmentInfo([...departments]);
                //setupDepartmentCategoryInfo([...departmentCategories]);
                //setupProductCategoryInfo([...productCategories]);

            })
            .catch((err)=>console.error('promise all for names error:', err));
        }
    },[]);


    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
        const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
        dbemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
        sfgemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
    }


    function getProductCategoryToEditPromise(pc:ProductCategory):Promise<EditProductCategoryInfo>{
        return fetch(`https://server.kando-proto-3.com/get-product-category-to-edit-mariadb/${pc.productCategoryId}`, {
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({productCategoryToEdit, productCategoryImages, relatedDepartments, 
                        relatedDepartmentCategories, productCategoryNameSelected }:EditProductCategoryInfo)=>{
                            
                    const pcte = new ProductCategory({...productCategoryToEdit});
                    //console.log('pcte:', pcte);
                    
                    //console.log('pcis:', productCategoryImages);

                    //console.log('rdns:', relatedDepartments);
                    //console.log('rdcns:', relatedDepartmentCategories);
                    //console.log('pcns:', productCategoryNameSelected);

                    return ({
                        productCategoryToEdit:pcte,
                        productCategoryImages:productCategoryImages,
                        relatedDepartments,
                        relatedDepartmentCategories,
                        productCategoryNameSelected
                    });
                })
                .catch((err)=>{
                    console.error('fetch product category to edit error:', err.message);
                    return ({productCategoryToEditError:new Error(err.message)})
                });

    }

    function setupDepartmentInfo(ds:StoreDepartment[]):void {
        function addDepartments(ds:StoreDepartment[]):StoreDepartment[]{
            return ds.map((d)=>{
                return new StoreDepartment({...d});
            })
        }

        function addDepartmentNames(ds:StoreDepartment[]):string[]{
            return ds.map((d)=>{
                return d.departmentName;
            })
        }

        const uds = addDepartments(ds);
        setDepartments(uds);
        const dns = addDepartmentNames(uds);
        setDepartmentNames(dns);
    }

    function setupProductCategoryEditInfo(pc:ProductCategory):void {
        //console.log('test 6-7');
        setDepartmentNameSelected(pc.departmentName);
        setDepartmentCategoryNameSelected(pc.departmentCategoryName);
        setProductCategoryNameSelected(pc.productCategoryName);
        setShowProductCategoryKeywordSearch(pc.showProductCategoryNameSearch);
        setShowProductCategoryNameSearch(pc.showProductCategoryKeywordSearch);
        setShowProductSubCategoryShortcut(pc.showProductSubCategoryShortcut);
        setShowRelatedProductCategoryShortcut(pc.showRelatedProductCategoryShortcut);
        setProductCategoryToEditId(pc.productCategoryId!);
        setEditProductCategoryLoaded(true);
        //need to set form a file image
        //setProductSearchImages(product.productImage);

        //psc.showProductSubCategoryKeywordSearch ? keywordSearchRef.current!.checked = true : keywordSearchRef.current!.checked = false;
        //psc.showProductSubCategoryNameSearch ? nameSearchRef.current!.checked = true : nameSearchRef.current!.checked = false;
        


        //original version
        //window.setTimeout(()=>triggerStoreDepartmentChangeEvent(psc.departmentName), 100);
    
    }

    function handleDeptNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setDepartmentNameSelected(dns);
            setShowDeptNameErrorMsg(false);
            const found = departments.filter((d)=>{
                                        return d.departmentName === dns;
                                    });

            if(found.length === 0){
            throw new Error('no department found.');
            }

            const dId = found[0].departmentId;

            getRelatedCategories('Department', dns, dId);
            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');
            
        } else {
            setDepartmentNameSelected(dns);
            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');
        }
        
        
    }

    function handleDeptCategoryNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dcns:', (e.target as HTMLSelectElement).value);
        const dcns = (e.target as HTMLSelectElement).value;
        if(dcns !== 'none'){
            setDepartmentCategoryNameSelected(dcns);
            setShowDeptCategoryNameErrorMsg(false);
            const found = departmentCategories.filter((dc)=>{
                                    return dc.departmentCategoryName === dcns;
                                });

            if(found.length === 0){
                throw new Error('no department category found.');
            }

            const dcId = found[0].departmentCategoryId;

            //getRelatedCategories('Department Category', dcns, dcId);
        } else {
            setDepartmentCategoryNameSelected(dcns);
        }


    }

    function getRelatedCategories(sfat:string, sfa:string, sfaId?:number):void {

        if(sfat === 'Department'){
            //fetch related department categories
            relatedDepartmentCategories(sfa, sfaId as number);

        }

        /*
        if(sfat === 'Department Category'){
            //fetch related product categories
            relatedProductCategories(sfaId as number);
        }
        */

        /*
        if(sfat === 'Product Category'){
            //fetch related search filters
            getRelatedProductCategorySearchFilters(sfat, sfa);
        }
        */

    }

    function setupDepartmentCategoryInfo(dcs:DepartmentCategory[]):void {
        function addDepartmentCategories(dcs:DepartmentCategory[]):DepartmentCategory[]{
            return dcs.map((dc)=>{
                return new DepartmentCategory({...dc});
            })
        }

        function addDepartmentCategoryNames(dcs:DepartmentCategory[]):string[]{
            return dcs.map((dc)=>{
                return dc.departmentCategoryName;
            })
        }

        const udcs = addDepartmentCategories(dcs);
        setDepartmentCategories(udcs);
        const dcns = addDepartmentCategoryNames(udcs);
        setDepartmentCategoryNames(dcns);

    }
   

    function relatedDepartmentCategories(
                                departmentName:string,
                                departmentId:number
                                ):Promise<DepartmentCategory[]> {

        return fetch(`https://server.kando-proto-3.com/get-related-department-categories/${departmentId.toString()}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((dcs:DepartmentCategory[])=>{
                //console.log('dcs 3-28:', dcs);
                if(dcs.length === 0){
                    //show generic info message if no product categories are found
                    setGenericInfoMessage(`No department categories have been added to the "${departmentName}" department.
                    Please add some if you want to use the "${departmentName}" department.`);
                    setShowGenericInfoMessage(true);
                } else {
                    setupDepartmentCategoryInfo(dcs);
                }
                
                return;
            })
            .catch((err)=>{
                console.error('err:', err.message)
                return err.message;
            });


    }

    function setupProductCategoryImages(productCategoryImages:ProductCategoryImage[]):void {
        productCategoryImages.forEach((pci)=>{
            //console.log('pci:', pci);
            //add images by category
            const si = new Image();
            //previous version
            //si.src = `${process.env.PUBLIC_URL}${pci.path}`;
            si.src = `https://server.kando-proto-3.com/${pci.path}`;
            //original version
            //setProductSubCategoryThumbnailImages([{img:si, name:psci.path}]);
            setProductCategoryThumbnailImages([{img:si, name:getFileName(pci.path)}]);
            //console.log('psci.source s:', pci.source);
            const sbyteCharacters = window.atob(pci.source);
            const sbyteNumbers = new Array(sbyteCharacters.length);
            for (let i = 0; i < sbyteCharacters.length; i++) {
                sbyteNumbers[i] = sbyteCharacters.charCodeAt(i);
            }
            const sbyteArray = new Uint8Array(sbyteNumbers);
            const sBlob = new Blob([sbyteArray], {type:pci.type});
            const sFile = new File([sBlob], pci.path, {type:pci.type});
            //console.log('sFile:', sFile)
            //const sBlob = new Blob([pi.source], {type:pi.type});
            //const sFile = new File([sBlob], pi.path, {type:pi.type});
            setProductCategoryImages([sFile]);
        });


    }

    function getFileName(filePath:string):string {
        const lastForwardSlash = filePath.lastIndexOf('/');
        const fileName = filePath.slice(lastForwardSlash + 1);
        return fileName;
    }

    function handleProductCategoryNameSearch(e:React.ChangeEvent):void {
        e.stopPropagation();
        const nameSearchChecked = (e.target as HTMLInputElement).checked;
        //console.log('checked:', nameSearchChecked);
        nameSearchChecked ? setShowProductCategoryNameSearch(true) : setShowProductCategoryNameSearch(false);
    }

    function handleProductSubCategoryKeywordSearch(e:React.ChangeEvent):void {
        e.stopPropagation();
        const keywordSearchChecked = (e.target as HTMLInputElement).checked;
        //console.log('checked:', keywordSearchChecked);
        keywordSearchChecked ? setShowProductCategoryKeywordSearch(true) : setShowProductCategoryKeywordSearch(false);

    }

    function handleProductSubCategoryShortcut(e:React.ChangeEvent):void {
        e.stopPropagation();
        const productSubCategoryShortcutChecked = (e.target as HTMLInputElement).checked;

        if(!showProductSubCategoryShortcut && showRelatedProductCategoryShortcut){
            setGenericInfoMessage(`Only one shortcut can be selected.`);
            setShowGenericInfoMessage(true);
            return;
        }

        //console.log('checked:', productSubCategoryShortcutChecked);
        productSubCategoryShortcutChecked ? setShowProductSubCategoryShortcut(true) : setShowProductSubCategoryShortcut(false);

    }

    function handleRelatedProductCategoryShortcut(e:React.ChangeEvent):void {
        e.stopPropagation();
        const relatedProductCategoryShortcutChecked = (e.target as HTMLInputElement).checked;

        if(!showRelatedProductCategoryShortcut && showProductSubCategoryShortcut){
            setGenericInfoMessage(`Only one shortcut can be selected.`);
            setShowGenericInfoMessage(true);
            return;
        }

        //console.log('checked:', relatedProductCategoryShortcutChecked);
        relatedProductCategoryShortcutChecked ? setShowRelatedProductCategoryShortcut(true) : setShowRelatedProductCategoryShortcut(false);

    }

    function imageClickHandler(e:React.MouseEvent):void {
        //console.log('e.target:', e.target);
        e.preventDefault();
        //e.stopPropagation();
        imageProductCategoryRef.current!.value = '';
        imageProductCategoryRef.current!.click();
    }

    function handleFileUpload(e:React.ChangeEvent):void {
        //console.log('file upload:', e.target, (e.target as HTMLInputElement).files);
        const target = e.target as HTMLInputElement;
        const fileList = (e.target as HTMLInputElement).files;
        const files = Array.from(fileList!);

        if(fileList![0].size / (1024 ** 2) <= 1.5){
            addThumbNailImage(files);
            setShowProductCategoryFileLimitError(false);           

        } else {
            setShowProductCategoryFileLimitError(true);
        }

    }

    function addThumbNailImage(files:File[]):void {
        let fileImgs:ThumbnailImage[] = [];

        files.forEach((f)=>{
            const img = new Image();
            img.src = URL.createObjectURL(f);
            //img.height = 40;
            //img.width = 40;
            //not using this function because I need a local copy so
            //the image is found when deleting from list
            //would revoke them in general if I did need to reference a local copy
            //before saving the image to the database
            /*
            img.onload = function(){
                URL.revokeObjectURL(img.src);
            }*/
            
            fileImgs.push({name:f.name, img:img})
        });

        setProductCategoryThumbnailImages([...fileImgs]);
        setProductCategoryImages([...files]);



                
    }



    function setupProductCategoryThumbnailImages(tis:ThumbnailImage[]):JSX.Element {
        //console.log('tis:', tis);
        if(tis.length === 0){
            return  <div className="product-image-search-list-container">No images selected</div>
        } else {
            return  <div className="product-image-search-list-container">
                        <ul className="product-image-search-list">
                            {tis.map((ti, k)=>{
                                return <li key={k} className="image-list-item">
                                            <img src={ti.img.src} className="add-product-category-thumbnail-image"/>
                                            <span className="image-list-item-url-container">
                                                <span>{ti.name}</span>
                                                <span className="image-list-item-close-btn" onClick={(e:React.MouseEvent)=>removeImageFromList2(/*ti.img, ti.name*/)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </span>
                                            </span>
                                        </li>
                            })}
                        </ul>
                    </div>
        }

    }

    function removeImageFromList2():void {
        //remove all thumbnails
        setProductCategoryThumbnailImages([]);

        //remove all images
        setProductCategoryImages([]);
    }

    function removeImageFromList(img:HTMLImageElement, fileNameSelected:string):void {
        //console.log('img:', img, `/images/product-category/${fileNameSelected}`);
        
        const imageToDelete = {filePath:`/images/product-category/${fileNameSelected}`, directory:'product-category'};

        fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({msg})=>{

                    const stis = productCategoryThumbnailImages.filter((i)=>{
                        return i.img !== img;
                    });

                    //original version
                    //setProductSubCategoryThumbnailImages(stis);
                    //remove all thumbnails
                    setProductCategoryThumbnailImages([]);

                    const sis = productCategoryImages.filter((f)=>{
                                    return f.name !== fileNameSelected;
                                });
                                

                    //original version
                    //setProductSubCategoryImages(sis);
                    //remove all images
                    setProductCategoryImages([]);


                    //console.log('product category image removed from form:', msg);
                })
                .catch((err)=>console.error('remove product category image from public folder error:', err.message));

    }

    function removeImagefromDatabase(searchFilterAreaId:number, filePathDirectorySelected:string):Promise<Response> {
        //original version
        //const imageToDelete = {filePath:`${filePathSelected}`, directory:`${filePathSelectedDirectory}`};

        const imageToDelete = {searchFilterAreaId, filePathDirectorySelected};


        return fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    //body:JSON.stringify(imageToDelete),
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })

    }

    function getNextNumberId(oid:string):number {
        let id = window.parseInt(oid, 10);
        return ++id;
    }


    function handleFormSubmit(e:React.FormEvent, formAction:string):void {
        e.preventDefault();
        const form = e.target as HTMLFormElement;

        dbErrorMsgRef.current!.dbemcRef.current!.style.top = '-2000px';
        sfgErrorMsgRef.current!.sfgemcRef.current!.style.top = '-2000px';

        //rest form errors
        //dnLabelRef.current!.classList.remove('product-info-error');
        dSelectElementRef.current?.dSelectElementRef.current!.classList.remove('product-info-error');

        //dcnLabelRef.current!.classList.remove('product-info-error');
        dcSelectElementRef.current!.dcSelectElementRef.current!.classList.remove('product-info-error');

        pcnLabelRef.current!.classList.remove('product-info-error');

        pciLabelRef.current!.classList.remove('image-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.imageErrors.length = 0;

        //product info
        //department name selected
        const dns = departmentNameSelected;
        if(dns.length === 0 || dns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Department Name'});
        }

        //department category name selected
        const dcns = departmentCategoryNameSelected;
        if(dcns.length === 0 || dcns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Department Category Name'});
        }

        //product category name selected
        const pcns = productCategoryNameSelected;
        const pclns = formatLinkString(pcns);

        if(pcns.length === 0 || pcns === 'none'){
            setShowProductCategoryNameLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Product Category Name'});

        } else {
            setShowProductCategoryNameLimitError(false);
        }

        //images-groups
        //product category image
        let pscImages = productCategoryImages;
        if(pscImages.length === 0 || pscImages.length > 1){
            setShowProductCategoryFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Product Category Image'});

        } else {

            setShowProductCategoryFileLimitError(false);

            const fn = pscImages[0].name.toLowerCase();
            //const ufn = fn.replace(/[=?#+%^{}\[\]\\\/,<>"\*$@!|\(\)&~`]/gi, '');
            const ufn = fn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nfn = ufn.replace(/ /ig, '-');
    
            let blob = pscImages[0].slice(0, pscImages[0].size, pscImages[0].type);
            const newFile = new File([blob], nfn, {type: blob.type});
            //remove original unfiltered file
            pscImages.length = 0;
            pscImages.push(newFile);
        }

        //console.log('pscImages:', pscImages);
        //return;

        const pies = formError.productInfoErrors.length > 0 ? true : false;
        const ies = formError.imageErrors.length > 0 ? true : false;

        if(pies || ies){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }

        setShowProcessingRequestMsg(true);


        if(formAction === 'addProductCategory'){

            let productCategoryImageName = productCategoryImages.length > 0 ? productCategoryImages[0].name : 'none';
                //productCategoryImageName = formatFilePathName(productCategoryImageName).toLowerCase();
                productCategoryImageName = productCategoryImageName.toLowerCase();

            const pcin = {
                //original version
                //productCategoryImageName:productCategoryImageName
                productCategoryImageName:productCategoryImageName
            };

            //console.log('pcin:', pcin);

            const urlParams = {productCategoryNameSelected,
                                productCategoryImageName
                            };

            const params = JSON.stringify(urlParams);

            //previous version
            //fetch(`http://localhost:9500/get-product-category-info/${productCategoryNameSelected}`, {
            fetch(`https://server.kando-proto-3.com/get-product-category-info`, {
                method:'POST',
                //body:JSON.stringify(pcin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({productCategoryNameExists, productCategoryImageExists, lastProductCategoryId, productCategoryId})=>{
                    //console.log('pscne:', productCategoryNameExists);
                    //console.log('pscie:', productCategoryImageExists);
                    //console.log('lpId:', lastProductCategoryId);
                    //console.log('pcId:', productCategoryId, 'productCategoryNameSelected:', productCategoryNameSelected);
                    

                    if(productCategoryNameExists || productCategoryImageExists){

                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.productCategoryNameError =  {hasError:false, area:'none'};


                        if(productCategoryImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Product Category Image', fileName:productCategoryImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(productCategoryNameExists){
                            dbError.productCategoryNameError =  {hasError:true, area:productCategoryNameSelected};
                        }

                        dbError.productCategoryNameError = (dbError.productCategoryNameError.hasError) 
                                                    ? dbError.productCategoryNameError 
                                                    : {hasError:false, area:'none'};

                        const de = {...dbError};
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
            
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;

                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }
            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        //const pcId = window.parseInt(productCategoryId, 10);
                        const nextProductCategoryId = getNextNumberId(lastProductCategoryId);
                        
                        //create new product category filled with form data fields
                        const productCategory = new ProductCategory({
                                                productCategoryId:nextProductCategoryId,
                                                productCategoryImage:pcin.productCategoryImageName,
                                                departmentName:dns,
                                                departmentCategoryName:dcns,
                                                productCategoryName:pcns,
                                                productCategoryLinkName:pclns,
                                                showProductCategoryKeywordSearch:showProductCategoryKeywordSearch,
                                                showProductCategoryNameSearch:showProductCategoryNameSearch,
                                                showProductSubCategoryShortcut:showProductSubCategoryShortcut,
                                                showRelatedProductCategoryShortcut:showRelatedProductCategoryShortcut
                                            }); 

                        const fd = new FormData();
                        //add product info
                        fd.append('product-info', JSON.stringify(productCategory));

                        //add product subCategory image
                        fd.append('product-category-image', productCategoryImages[0]);

                        //console.log('psc:', productCategory, productCategoryImages[0]);
                        
                        setIsLoading(true);

                        fetch(`https://server.kando-proto-3.com/add-individual-product-category`, {
                            method:'POST',
                            body:fd
                        })
                        .then((res)=>{
                            return res.json();
                        })
                        .then((res)=>{
                            //console.log('add individual product category response:', res);

                            //close form update product list
                            handleCloseForm('addProductCategory');
                        })
                        .catch((err)=>console.error('add individual product category fetch error:', err.message));

                    }

                })
                .catch((err)=>{
                    console.error('product search err:', err)
                });
                
                
        } else {

            let productCategoryImageName = productCategoryImages.length > 0 ? productCategoryImages[0].name : 'none';
                //productCategoryImageName = formatFilePathName(productCategoryImageName).toLowerCase();
                productCategoryImageName = productCategoryImageName.toLowerCase();

            //console.log('result 6-7', productCategoryImageName);

            const pscin = {
                //original version
                //productCategoryImageName:productCategoryImageName
                productCategoryImageName:productCategoryImageName
            };

            const urlParams = {productCategoryNameSelected,
                                productCategoryToEditId,
                                productCategoryImageName};

            const params = JSON.stringify(urlParams);

            //previous version
            //fetch(`http://localhost:9500/check-product-category-exists/${productCategoryNameSelected}/${productCategoryToEditId}`, {
            fetch(`https://server.kando-proto-3.com/check-product-category-exists`, {
                method:'POST',
                //body:JSON.stringify(pscin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({productCategoryNameExists, productCategoryImageExists, productCategoryId})=>{
                    //console.log('productCategoryNameExists:', productCategoryNameExists);
                    //console.log('productCategoryImageExists:', productCategoryImageExists);
                    //console.log('productCategoryId:', productCategoryId);
                    if(productCategoryNameExists || productCategoryImageExists){
                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.productCategoryNameError =  {hasError:false, area:'none'};

                        if(productCategoryImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Product Category Image', fileName:productCategoryImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(productCategoryNameExists){
                            dbError.productCategoryNameError =  {hasError:true, area:productCategoryNameSelected};
                        }

                        dbError.productCategoryNameError = (dbError.productCategoryNameError!.hasError) 
                                                    ? dbError.productCategoryNameError 
                                                    : {hasError:false, area:'none'};


                        const de = {...dbError};
                        
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
        
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;

                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }

            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        removeImagefromDatabase(productCategoryId, 'product-category')
                        .then((res)=>res.json())
                        .then(({msg})=>{
                            if(msg === 'product-category image removed'){
                                //console.log('product category image removed from form:', msg);

                                //create new product category filled with form data fields
                                const productCategory = new ProductCategory({
                                    productCategoryId:productCategoryId,
                                    productCategoryImage:pscin.productCategoryImageName,
                                    departmentName:dns,
                                    departmentCategoryName:dcns,
                                    productCategoryName:pcns,
                                    productCategoryLinkName:pclns,
                                    showProductCategoryKeywordSearch:showProductCategoryKeywordSearch,
                                    showProductCategoryNameSearch:showProductCategoryNameSearch,
                                    showProductSubCategoryShortcut:showProductSubCategoryShortcut,
                                    showRelatedProductCategoryShortcut:showRelatedProductCategoryShortcut
                                });               


                                const fd = new FormData();
                                //add product info
                                fd.append('product-info', JSON.stringify(productCategory));

                                //add product search image
                                fd.append('product-category-image', productCategoryImages[0]);

                                //console.log('product 6-7:', productCategory);
                                //console.log('product-category-image 6-7:', productCategoryImages[0]);

                                setIsLoading(true);
                                    
                                //update product in database
                                fetch(`https://server.kando-proto-3.com/update-individual-product-category`, {
                                    method:'PUT',
                                    body:fd
                                    })
                                    .then((res)=>{
                                        return res.json();
                                    })
                                    .then((res)=>{
                                        handleCloseForm('editProductCategory');
                                    })
                                    .catch((err)=>console.error('update individual product category fetch error:', err.message));

                            } else {
                                throw new Error('file was not removed.');
                            }
                            
                        })
                        .catch((err)=>console.error('remove product category image from public folder error:', err.message));




                    }
                    




                })
                .catch((err)=>console.error('check product category exists fetch error:', err.message));



        }

    }

    function formatFilePathName(filePathName:string):string {
        return filePathName.search(/\//i) === -1 ? `/images/product-category/${filePathName}` : filePathName;
    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Department Name':
                                //dnLabelRef.current!.classList.add('product-info-error');
                                dSelectElementRef.current?.dSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Department Category Name':
                                //dcnLabelRef.current!.classList.add('product-info-error');
                                dcSelectElementRef.current!.dcSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Category Name':
                                pcnLabelRef.current!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no product category info errors found!');
                        }
                    }
                });


        //set image errors
        formError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Product Category Image'){
                //console.log('ie.area:', ie.area);
                pciLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });
    }

    function addDBFormLabelErrors(dbError:DBError): void {
        //set product info errors
        if(dbError.productCategoryNameError!.hasError){
            pcnLabelRef.current!.classList.add('product-info-error');
        }
        
        //set image errors
        dbError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Product Category Image'){
                //console.log('ie.area:', ie.area);
                pciLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });

    }

    function handleProductCategoryName(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const value = target.value;
    
        //console.log('value.length:', value.length);

        if(value.length <= 50){
            setProductCategoryNameSelected(value);
            setShowProductCategoryNameLimitError(false);

        } else {
            setShowProductCategoryNameLimitError(true);
        }

    }

    function setupFormAction(showEditProductSubCategory:boolean):string {
        return (showEditProductSubCategory) 
                ? 'updateProductCategory'
                : 'addProductCategory'
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowGenericInfoMessage(false);
    }


    if(isLoading){
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>
    } else

    if(!showEditProductCategory || (showEditProductCategory && editProductCategoryLoaded)){
        return <div>
                    {
                        (showEditProductCategory)
                        ? <h1 className="add-product-category-heading">Edit Product Category</h1>
                        : <h1 className="add-product-category-heading">Add Product Category</h1>
                    }
                    <form ref={formRef} className="add-dpt-category-form" onSubmit={(e:React.FormEvent)=>handleFormSubmit(e, setupFormAction(showEditProductCategory))}>
                    <div className="add-product-input-form-group">
                            <label ref={dnLabelRef} htmlFor="dpt-name">Department Name</label>
                            <StoreDepartmentSelectControl ref={dSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={departmentNameSelected}
                                    optionNames={departmentNames}
                                    showErrorMsg={showDeptNameErrorMsg}
                                    errorMsg={deptNameErrorMsg}
                                    selectId={"dpt-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={dcnLabelRef} htmlFor="dpt-category-name">Department Category Name</label>
                            <DepartmentCategorySelectControl ref={dcSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={departmentCategoryNameSelected}
                                    optionNames={departmentCategoryNames}
                                    showErrorMsg={showDeptCategoryNameErrorMsg}
                                    errorMsg={deptCategoryNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptCategoryNameSelection}
                            />
                        </div>
                        {/*<div className="add-product-input-form-group">
                            <label ref={pcnLabelRef} htmlFor="product-category-name">Product Category Name</label>
                            <ProductCategorySelectControl ref={pcSelectElementRef}
                                    defaultValue={'none'}
                                    optionNameSelected={productCategoryNameSelected}
                                    optionNames={productCategoryNames}
                                    showErrorMsg={showProductCategoryNameErrorMsg}
                                    errorMsg={productCategoryNameErrorMsg}
                                    handleOptionNameSelection={handleProductCategoryNameSelection}
                            />
                        </div>*/}
                        <div className="add-product-sub-category-input-form-group">
                            <label htmlFor="product-sub-category-name">Product Category Name</label>
                            <input ref={pcnLabelRef} name="product-sub-category-name" type="text" 
                                id="product-sub-category-name"
                                className="form-input-control" value={productCategoryNameSelected} onChange={handleProductCategoryName}/>
                            {
                                showProductCategoryNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                :
                                null
                            }

                        </div>
                        <div className="add-product-sub-category-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={pciLabelRef} htmlFor="product-image-search" onClick={imageClickHandler}><span className="product-image-search-label-link">Select Images <span className="no-color-label-link">(product category)</span></span></label>
                                </div>
                                {
                                    showProductCategoryFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageProductCategoryRef} id="product-image-search" className="product-image-search" name="product-image-search" type="file" multiple onChange={handleFileUpload}/>
                                {setupProductCategoryThumbnailImages(productCategoryThumbnailImages)}

                            </div>
                        {/*<div className="add-product-sub-category-checkbox-form-group">
                            <input ref={nameSearchRef} type="checkbox" checked={showProductCategoryNameSearch} name="show-product-sub-category-name-search" 
                                id="show-product-sub-category-name-search" onChange={handleProductCategoryNameSearch}/>
                            <label htmlFor="show-product-sub-category-name-search">Show Name Search</label>
                        </div>
                        <div className="add-product-sub-category-checkbox-form-group">
                            <input ref={keywordSearchRef} type="checkbox" checked={showProductCategoryKeywordSearch} name="show-product-sub-category-keyword-search" 
                                id="show-product-sub-category-keyword-search" onChange={handleProductSubCategoryKeywordSearch}/>
                            <label htmlFor="show-product-sub-category-keyword-search">Show Keyword Search</label>
                        </div>*/}
                        <div className="add-product-sub-category-checkbox-form-group">
                            <input className="admin-checkbox-control" ref={productSubCategoryShortcutRef} type="checkbox" checked={showProductSubCategoryShortcut} name="show-product-sub-category-name-search" 
                                id="show-product-sub-category-name-search" onChange={handleProductSubCategoryShortcut}/>
                            <label htmlFor="show-product-sub-category-name-search">Show product subcategory shortcut</label>
                        </div>
                        <div className="add-product-sub-category-checkbox-form-group">
                            <input className="admin-checkbox-control" ref={relatedProductCategoryShortcutRef} type="checkbox" checked={showRelatedProductCategoryShortcut} name="show-product-sub-category-keyword-search" 
                                id="show-product-sub-category-keyword-search" onChange={handleRelatedProductCategoryShortcut}/>
                            <label htmlFor="show-product-sub-category-keyword-search">Show related product category shortcut</label>
                        </div>

                        {
                            (showEditProductCategory)
                            ?   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-save-btn" type="submit" value="Save"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('editProductCategory')} value="Cancel"/>

                                    {
                                        (showProcessingRequestMsg && !showGenericInfoMessage)
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            :   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-add-btn" type="submit" value="Add"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('addProductCategory', true)} value="Cancel"/>

                                    {
                                        (showProcessingRequestMsg && !showGenericInfoMessage)
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                   
                        }
                        
                        {
                            showGenericInfoMessage 
                            ? (<div className="add-product-sub-category-form-info-message-container">
                                <GenericInfoMessage infoMessage={genericInfoMessage} showExtraMsgContainer={true}
                                    showCloseBtn={true} closeInfoMsgHandler={closeInfoMsgHandler}/>
                                </div>)
                            : null
                        }
                        <DBErrorMessage ref={dbErrorMsgRef} dbError={dbError}/>
                        <FormErrorMessage ref={sfgErrorMsgRef} formError={formError} />

                    </form>
                </div>
       
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    {<LoaderAnimation/>}
                </div>
    }
}


export default AddProductCategory;