import React, {useState, useEffect, forwardRef} from 'react';
import './StoreStructureOptionSelect.css';

interface Props {
    defaultValue:string;
    optionNameSelected:string;
    optionNames:string[];
    showErrorMsg:boolean;
    errorMsg:string;
    selectId:string;
    cssStyles?:{minWidth:number, backgroundPosition:number};
    handleOptionNameSelection:(e:React.ChangeEvent)=>void;
}

type Ref = HTMLSelectElement;

//const StoreStructureOptionSelect: React.FunctionComponent<Props> = (props:Props) => {
const StoreStructureOptionSelect = forwardRef<Ref, Props>((props, ref)=>{
    const {optionNameSelected, optionNames, defaultValue, showErrorMsg, errorMsg, selectId, cssStyles, handleOptionNameSelection} = props;
    //console.log('ons:', optionNameSelected);

    function setupOptions(optionNames:string[]):JSX.Element[]{
        return optionNames.map((optionNames, i)=>{
                            return <option key={i + 1} value={optionNames}>{optionNames}</option>;                    
                        });
    }

    if(optionNames.length >= 0){
        return  <div className="store-structure-option-select-container">
                    <select ref={ref} id={selectId} 
                    style={{minWidth:`${(typeof cssStyles !== 'undefined' ? cssStyles.minWidth : 120 )}px`, backgroundPosition:`calc(100% - ${(typeof cssStyles !== 'undefined' ? cssStyles.backgroundPosition : 14 )}px`}}
                    className={showErrorMsg ? 'show-store-structure-option-select-error-border': 'hide-store-structure-option-select-no-error-border'}
                     value={optionNameSelected} onChange={(e:React.ChangeEvent)=>handleOptionNameSelection(e)}>
                        <option key={0} value={defaultValue}>{defaultValue}</option>
                        {setupOptions(optionNames)}
                    </select>
                    <div className={showErrorMsg ? 'show-store-structure-option-select-error-msg': 'hide-store-structure-option-select-error-msg' }>
                        {errorMsg}
                    </div>
                </div>
    } else {
        return null;
    }

});

export default StoreStructureOptionSelect;