class SliderType {
    public type:string;
    public distance:number;
    public heading:string;
    public caption:string;
    public scrollEventPadding:number;

    constructor(options:{
        type?:string,
        distance?:number,
        heading?:string,
        caption?:string,
        scrollEventPadding?:number

    }={}){
        this.type = options.type || 'slider3';
        this.distance = options.distance || 0;
        this.heading = options.heading || 'none';
        this.caption = options.caption || 'none';
        this.scrollEventPadding = options.scrollEventPadding || 0;
    }
}  


export default SliderType;