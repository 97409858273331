import React, {useState, useEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AddSearchFilter from './AddSearchFilter';
import SearchFilter, {SearchTerm} from './searchFilter';
import AddSearchTerm from './AddSearchTerm';
import StoreStructureAlert from './StoreStructureAlert';
import StoreStructureLoadingMsg from './StoreStructureLoadingMsg';
import getDateConversion from './helper-date-conversion';
import DbResponse from './db-response';
import isSearchTermUnique from './helper-add-search-term-db';
import EditSearchFilter from './EditSearchFilter';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import LoaderAnimation from './LoaderAnimation';
import transformDate from './helper-transform-date';
import FormErrorMessage, {FormError} from './FormErrorMessage';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import loadNavLink from './loadNavLinkHelper';
import GenericErrorMessage from './GenericErrorMessage';
import Footer from './Footer';
import './StoreStructureSearchFilters.css';

interface Props {
    //path:string;
}

const resultsPerPage = ['5', '10', '20', '30'];

const StoreStructureSearchFilters2: React.FunctionComponent<Props> = (props:Props) => {
    const navigate = useNavigate();
    const [showAddSearchFilter, setShowAddSearchFilter] = useState(false);
    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [showAddSearchTerm, setShowAddSearchTerm] = useState(false);
    const [deptNameSelected, setDeptNameSelected] = useState('none');
    const [deptCategoryNameSelected, setDeptCategoryNameSelected] = useState('none');
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState('none');
    const [searchFilterNameSelected, setSearchFilterNameSelected] = useState('');
    const [searchTermNameSelected, setSearchTermNameSelected] = useState('');
    const [searchFilterSelected, setSearchFilterSelected] = useState(false);
    const [searchFilterIdSelected, setSearchFilterIdSelected] = useState(0);
    const [showSearchFilter, setShowSearchFilter] = useState(false);
    const [searchTermSelected, setSearchTermSelected] = useState(false);
    const [showSearchTerm, setShowSearchTerm] = useState(false);
    const [searchTermImgSelected, setSearchTermImgSelected] = useState('');
    const [showSearchTermImg, setShowSearchTermImg] = useState(false);
    const [searchFilterAreaNameSelected, setSearchFilterAreaNameSelected] = useState('none');
    const [searchFilterAreaTypeSelected, setSearchFilterAreaTypeSelected] = useState('none');
    const [showDepartment, setShowDepartment] = useState(true);
    const [showDepartmentCategory, setShowDepartmentCategory] = useState(true);
    const [showProductCategory, setShowProductCategory] = useState(true);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('no msg');
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [storeStructureLoadingMsg, setStoreStructureLoadingMsg] = useState('saving search filter');
    const [showStoreStructureLoadingMsg, setShowStoreStructureLoadingMsg] = useState(false);
    const [showEditSearchFilter, setShowEditSearchFilter] = useState(false);

    //search properties
    const [searchFilterNameSearchText, setSearchFilterNameSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No search filters have been added.');

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(10);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<SearchFilter[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [elementName3, setElementName3] = useState('results-per-page');
    const [resultsPerPageOptionSelected, setResultsPerPageOptionSelected] = useState('10');
    const [resultsPerPageOptions, setResultsPerPageOptions] = useState<string[]>(resultsPerPage);

    const [isLoading, setIsLoading] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const sfKeywordRef = useRef<HTMLInputElement>(null);
    const sfKeywordRefs = useRef<HTMLInputElement[]>([]);

    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });

    //const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);

    const sfgErrorMsgRefs = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}[]>([]);


    const sfContainerRefs = useRef<HTMLDivElement[]>([]);
    const existingSearchResultsHeight = useRef<number>(0);

    const [showKeywordSearchLimitError, setShowKeywordSearchLimitError] = useState(false);

    const [temporaryClearSearchResults, setTemporaryClearSearchResults] = useState(false);

    //const formRef = useRef<HTMLFormElement>(null);

    const formRefs = useRef<HTMLFormElement[]>([]);

    const [showFooter, setShowFooter] = useState(false);

    //console.log('sfans:', searchFilterAreaNameSelected);
    //console.log('sfns:', searchFilterNameSelected);
    //console.log('stns:', searchTermNameSelected);

    //console.log('ss:', window.sessionStorage.getItem('showAddSearchFilter'));
    /*
    
    sf.searchFilterDateAdded = `${d.getFullYear()}-${getCurrentMonth(d)}-${getCurrentDay(d)} ${getCurrentHours(d)}:${getCurrentMinutes(d)}:${getCurrentSeconds(d)}`;
    */

    const loaderRefs = useRef<HTMLDivElement[]>([]);

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const isLoadingTimeoutRef = useRef<number>(0);

    const [isLoadingEditComponent, setIsLoadingEditComponent] = useState(false);

   useEffect(()=>{

    /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);
        
        getAllSearchFilters();
    },[]);

    function getAllSearchFilters(lcbPosition?:number):void {
        getAllSearchFiltersPromise()
        .then((sfs:SearchFilter[]|null)=>{
            //console.log('sfs:', sfs);
            if(sfs === null){
                return;

            } else if(sfs.length === 0){
                //show generic info message if no search filters are found
                setNoSearchResultsMessage(`No search filters have been added.`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoading(false);

                setShowFooter(true);

            } else {
                setSearchFilters([...sfs]);
                getAnyItemList(typeof lcbPosition !== 'undefined' ? lcbPosition : listChangerBtnPosition, sfs, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, sfs);
                setIsLoading(false);

                setShowFooter(true);

                //adjust item count based on items per list setting
                //previous version
                let nrh = 0;
                if(itemsPerList < sfs.length){
                    nrh = (itemsPerList * 91) + 588 + 16 + 57;

                } else {
                    nrh = (sfs.length) * 91 + 588 + 16 + 57;
                }

                                    
                if(nrh > 950){
                    sfContainerRefs.current[0].style.minHeight = `${nrh}px`;
                }
                

            }

            
        })
        .catch((err)=>{
            //console.log('getAllSearchFiltersPromise error:',err);
            setShowErrorMessage(true);
            setIsLoading(false);

            setShowFooter(true);
        });
    
    }


    function getSFContainerRef(el:HTMLInputElement|null):void {
        if(el !== null && sfContainerRefs.current.length === 0){
            sfContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = sfContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                sfContainerRefs.current.push(el);
            }

        }

        //console.log('prkrs:', sfContainerRefs.current);
    }


    
    function getSFKeywordRef(el:HTMLInputElement|null):void {
        if(el !== null){
            sfKeywordRefs.current.length = 0;
        }

        if(el !== null && sfKeywordRefs.current.length === 0){
            sfKeywordRefs.current.push(el);

        } else if(el !== null) {
            const found = sfKeywordRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                sfKeywordRefs.current.push(el);
            }

        }

        //console.log('prkrs:', sfKeywordRefs.current);

        if(window.innerWidth >= 360){
            sfKeywordRefs.current[0]!.placeholder = 'Enter filter name, filter area or filter type';

        } else {
            sfKeywordRefs.current[0]!.placeholder = '';
        }

    }

    useEffect(()=>{
        window.addEventListener('resize', sssfResizeHandler);
        return cleanupSSSFResizeHandler;
    },[]);

    function sssfResizeHandler(e:Event):void {
        if(window.innerWidth >= 360){
            sfKeywordRefs.current[0]!.placeholder = 'Enter filter name, filter area or filter type';

        } else {
            sfKeywordRefs.current[0]!.placeholder = '';
        }

        if(typeof sfgErrorMsgRefs.current![0] !== 'undefined'){
            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
        }
    }

    function cleanupSSSFResizeHandler():void {
        window.removeEventListener('resize', sssfResizeHandler);
    }

    function getFormRef(el:HTMLFormElement|null):void {
        if(formRefs.current.length > 0){
            formRefs.current.length = 0;
        }

        if(el !== null && formRefs.current.length === 0){
            formRefs.current.push(el);

        } else if(el !== null) {
            const found = formRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                formRefs.current.push(el);
            }

        }
    }

    type Ref = {
        sfgemcRef:React.RefObject<HTMLDivElement>
    };

    function getSfgErrorMsgRef(el: Ref|null):void {
        if(sfgErrorMsgRefs.current.length > 0){
            sfgErrorMsgRefs.current.length = 0;
        }

        if(el !== null && sfgErrorMsgRefs.current.length === 0){
            sfgErrorMsgRefs.current.push(el);

        } else if(el !== null) {
            const found = sfgErrorMsgRefs.current.find((ir)=>{
                                return ir.sfgemcRef === el.sfgemcRef;
                            });

            if(typeof found === 'undefined'){
                sfgErrorMsgRefs.current.push(el);
            }

        }

        //console.log('sfgErrorMsgRefs:', sfgErrorMsgRefs.current);
    }

    function getLoaderRef(el: HTMLDivElement|null):void {
        if(loaderRefs.current.length > 0){
            loaderRefs.current.length = 0;
        }

        if(el !== null && loaderRefs.current.length === 0){
            loaderRefs.current.push(el);

        } else if(el !== null) {
            const found = loaderRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                loaderRefs.current.push(el);
            }

        }

        //console.log('loaderRefs:', loaderRefs);

        if(typeof loaderRefs.current[0] !== 'undefined'){
            const yValue = formRefs.current[0].getBoundingClientRect().bottom + 66;
            loaderRefs.current[0].style.transform = `translate(-50%, ${yValue}px)`;
        }


    }
    
    
    function getAllSearchFiltersPromise():Promise<SearchFilter[]|null> {
        return fetch(`https://server.kando-proto-3.com/get-all-search-filters`,{
                    method:'GET',
                    headers:{
                        'Accept':'application/javascript'
                    }
                })
                .then((res)=>res.json())
                .then((searchFilters:SearchFilter[])=>{
                    if(searchFilters.length > 0){                    
                        //console.log('sfs:', searchFilters);
                        const usfs = modifySearchFilterDateAdded(searchFilters);
                        const csfs = addSearchFilters(usfs);
                        //console.log('csfs:', csfs);
                        //original version
                        //setSearchFilters(csfs);
                        return csfs;

                    } else {
                        return [];
                    }   
                })
                .catch(err=>{
                    //console.log('search filters fetch error:', err);
                    setShowErrorMessage(true);
                    setIsLoading(false);
                    return null;
                });
    }

    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        setShowStoreStructureAlert(false);
    }


    function modifySearchFilterDateAdded(searchFilters:SearchFilter[]):SearchFilter[] {
        //modify date string
        return searchFilters.map((sf)=>{
                    sf.searchFilterDateAdded = new Date(sf.searchFilterDateAdded);
                    return sf;
                });
        
    }

    function resetSearchFilters():void {
        //reset form errors
        sfKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;
        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;
        
        setShowKeywordSearchLimitError(false);

        setSearchFilters([]);
        setSearchFilterNameSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(true);

        setItemsList(null);
        setListChangerBtnPosition(0);

        getAllSearchFilters(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        sfContainerRefs.current[0].style.minHeight = `950px`;
    }


    function handleAddSearchFilter(e:React.MouseEvent):void {
        //window.sessionStorage.setItem('showAddSearchFilter', 'true');
        setShowKeywordSearchLimitError(false);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setSearchFilterNameSearchText('');
        setShowAddSearchFilter(true);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        sfContainerRefs.current[0].style.minHeight = `950px`;

    }

    function sortByNewest(a:SearchFilter, b:SearchFilter){
        const dateA = a.searchFilterDateAdded//.toString();
        const dateB = b.searchFilterDateAdded//.toString();
        if(dateB < dateA){
            return -1;
        } else if(dateB > dateA){
            return 1;
        } else {
            return 0;
        }
    }


    function sortItemsByDateSubmitted(sortDirection:string):void {
        if(sortDirection === 'asc'){
            //console.log('sort29');
            const sfs = searchFilters.sort(compareAscendingDates);
            setSearchFilters(sfs);
            getAnyItemList(listChangerBtnPosition, sfs, itemsPerList, showItemsPerList);

        } else {
            //console.log('sort29');
            const sfs = searchFilters.sort(compareDescendingDates);
            setSearchFilters(sfs);
            getAnyItemList(listChangerBtnPosition, sfs, itemsPerList, showItemsPerList);
        }

    }

    function compareDescendingDates(a:SearchFilter, b:SearchFilter):number {
        const aDate = new Date(a.searchFilterDateAdded).getTime();
        const bDate = new Date(b.searchFilterDateAdded).getTime();

        return bDate - aDate;
    }

    function compareAscendingDates(a:SearchFilter, b:SearchFilter):number {
        const aDate = new Date(a.searchFilterDateAdded).getTime();
        const bDate = new Date(b.searchFilterDateAdded).getTime();

        return aDate - bDate;
    }


    function setupSearchFilterTable(itemsList:object[]):JSX.Element {
        return <div className="sssfs-table-container">
                    <table className="sssfs-table">
                        <thead>
                            <tr>
                                <th className="th-sf-ft">Filter Type</th><th className="th-sf-fa">Filter Area</th><th className="th-sf-fn">Filter Name</th><th className="th-sf-st">Search Terms</th><th className="th-sf-date"><div className="admin-date-added-header-container"><span>Date Added</span><span className="admin-date-added-svg-btn-container">
                                                                                                                                                                                                                            <button type="button" aria-label="ascending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('asc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-up" d="M200 100 L100 0 L0 100 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                            <button type="button" aria-label="descending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('desc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-down" d="M200 0 L100 100 L0 0 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                        </span></div></th><th className="th-sf-action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(itemsList as SearchFilter[]).map((sf, i)=>{
                        //console.log(`fa:${sf.searchFilterArea}, fn:${sf.filterName}, qty:${sf.searchTermQty}, date:${sf.searchFilterDateAdded}`)
                                                return <tr key={i}>
                                                            <td><div className="sssfs-td">{sf.searchFilterAreaType}</div></td>
                                                            <td><div className="sssfs-td">{sf.searchFilterArea}</div></td>
                                                            <td><div className="sssfs-td">{sf.filterName}</div></td>
                                                            <td className="store-structure-search-filters-search-term-qty">{sf.searchTermQty}</td>
                                                            <td><div className="sssfs-td">{transformDate(new Date(sf.searchFilterDateAdded).getTime())}</div></td>
                                                            <td className="td-action">
                                                                <div className="table-item-btn-container">
                                                                    <button type="button"
                                                                        className="edit-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            editSearchFilter(sf)
                                                                        }}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button"
                                                                        className="remove-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            removeSearchFilter(sf);
                                                                        }}>
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                            })
                            }
                        </tbody>
                    </table>
                </div>
    }

    function handleBackToSearchFilter(
                        searchTermNameSelected:string,
                        searchTermSelected:boolean,
                        showSearchTerm:boolean,
                        showSearchTermImg:boolean,
                        searchTermImgSelected:string
                                    ):void {
        setSearchTermNameSelected(searchTermNameSelected);
        setSearchTermSelected(searchTermSelected);
        setShowSearchTerm(showSearchTerm);
        setShowSearchTermImg(showSearchTermImg);
        setSearchTermImgSelected(searchTermImgSelected);
        setShowAddSearchTerm(false);
        setShowAddSearchFilter(true);
        //console.log('sfans:', searchFilterAreaNameSelected);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

    }

    function findSearchFilter(searchFilterToBeAdded:SearchFilter, searchFilters:SearchFilter[]):SearchFilter | undefined {
        const sfFound = searchFilters.find((sf)=>{
        /*console.log('sf.searchFilterAreaType === searchFilterCreated.searchFilterAreaType', sf.searchFilterAreaType === searchFilterCreated.searchFilterAreaType)
        console.log('sf.searchFilterArea === searchFilterCreated.searchFilterArea', sf.searchFilterArea === searchFilterCreated.searchFilterArea)
        console.log('sf.filterName === searchFilterCreated.filterName', sf.filterName === searchFilterCreated.filterName)*/
                            return ((sf.searchFilterAreaType === searchFilterToBeAdded.searchFilterAreaType)
                                    && (sf.searchFilterArea === searchFilterToBeAdded.searchFilterArea)
                                    && (sf.filterName.toLowerCase() === searchFilterToBeAdded.filterName.toLowerCase()));

                        });

        return sfFound;
    }

    function handleCancelSearchFilter():void{
        //window.sessionStorage.setItem('showAddSearchFilter', 'false');
        setIsLoading(true);

        setShowDepartment(true);
        setShowProductCategory(true);
        setShowDepartmentCategory(true);
        setDeptNameSelected('none');
        setDeptCategoryNameSelected('none');
        setProductCategoryNameSelected('none');
        setSearchFilterNameSelected('');
        setSearchFilterSelected(false);
        setShowSearchFilter(false);
        setSearchFilterAreaNameSelected('none');
        setSearchFilterAreaTypeSelected('none')
        setShowAddSearchFilter(false);
        //setSearchFilters(asfs);
        
        setSearchTermNameSelected('');
        setSearchTermSelected(false);
        setShowSearchTerm(false);
        setShowSearchTermImg(false);
        setSearchTermImgSelected('');
        //keep search term form open to add another search term
        setShowAddSearchTerm(false);
        setShowEditSearchFilter(false);

        getAnyItemList(listChangerBtnPosition, searchFilters, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, searchFilters);
        setSearchFilterNameSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(false);

        /*
        getAllSearchFiltersPromise()
        .then((sfs:SearchFilter[]|null)=>{
            if(sfs === null){
                return;

            } else if(sfs.length === 0){
                //show generic info message if no search filters are found
                setNoSearchResultsMessage(`No search filters have been added.`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoading(false);

            } else {

                setSearchFilters([...sfs]);
                setShowDepartment(true);
                setShowProductCategory(true);
                setShowDepartmentCategory(true);
                setDeptNameSelected('none');
                setDeptCategoryNameSelected('none');
                setProductCategoryNameSelected('none');
                setSearchFilterNameSelected('');
                setSearchFilterSelected(false);
                setShowSearchFilter(false);
                setSearchFilterAreaNameSelected('none');
                setSearchFilterAreaTypeSelected('none')
                setShowAddSearchFilter(false);
                //setSearchFilters(asfs);
                
                setSearchTermNameSelected('');
                setSearchTermSelected(false);
                setShowSearchTerm(false);
                setShowSearchTermImg(false);
                setSearchTermImgSelected('');
                //keep search term form open to add another search term
                setShowAddSearchTerm(false);
                setShowEditSearchFilter(false);


                getAnyItemList(listChangerBtnPosition, sfs, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, sfs);
                setSearchFilterNameSearchText('');
                setShowNoSearchResultsFound(false);
                setShowInfoMessage(false);
                setIsLoading(false);

            }


            
        })
        .catch((err)=>{
            console.log('cancel search filter error:',err.message);
            setShowErrorMessage(true);
            setIsLoading(false);
        });
        */

        /*original version
        setShowDepartment(true);
        setShowProductCategory(true);
        setShowDepartmentCategory(true);
        setDeptNameSelected('none');
        setDeptCategoryNameSelected('none');
        setProductCategoryNameSelected('none');
        setSearchFilterNameSelected('');
        setSearchFilterSelected(false);
        setShowSearchFilter(false);
        setSearchFilterAreaNameSelected('none');
        setSearchFilterAreaTypeSelected('none')
        setShowAddSearchFilter(false);
        */
    }

    function handleSaveSearchFilter(
                        /*deptNameSelected:string,
                        deptCategoryNameSelected:string,
                        productCategoryNameSelected:string,*/
                        /*searchFilterAreaTypeSelected:string,
                        searchFilterAreaNameSelected:string,
                        searchFilterNameSelected:string,
                        searchFilterSelected:boolean,
                        showSearchFilter:boolean,*/
                        searchFilters:SearchFilter[]
                                    ):void {
        
            //console.log('sf saved');


        const asfs = addSearchFilters(searchFilters);

        setShowDepartment(true);
        setShowProductCategory(true);
        setShowDepartmentCategory(true);
        setDeptNameSelected('none');
        setDeptCategoryNameSelected('none');
        setProductCategoryNameSelected('none');
        setSearchFilterNameSelected('');
        setSearchFilterSelected(false);
        setShowSearchFilter(false);
        setSearchFilterAreaNameSelected('none');
        setSearchFilterAreaTypeSelected('none')
        setShowAddSearchFilter(false);
        setSearchFilters(asfs);
        
        setSearchTermNameSelected('');
        setSearchTermSelected(false);
        setShowSearchTerm(false);
        setShowSearchTermImg(false);
        setSearchTermImgSelected('');
        //keep search term form open to add another search term
        setShowAddSearchTerm(false);

        /* original version
        setShowDepartment(true);
        setShowProductCategory(true);
        setShowDepartmentCategory(true);
        setDeptNameSelected('none');
        setDeptCategoryNameSelected('none');
        setProductCategoryNameSelected('none');
        setSearchFilterNameSelected('');
        setSearchFilterSelected(false);
        setShowSearchFilter(false);
        setSearchFilterAreaNameSelected('none');
        setSearchFilterAreaTypeSelected('none')
        setShowAddSearchFilter(false);
        setSearchFilters(asfs);
        */

        setListChangerBtnPosition(0);

        //previous version
        //getAnyItemList(listChangerBtnPosition, asfs, itemsPerList, showItemsPerList);
        getAnyItemList(0, asfs, itemsPerList, showItemsPerList);
        setIsLoading(true);
        displayListSorter(itemsPerList, asfs);
        setSearchFilterNameSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);
 
    }

    function handleUpdateSearchFilter(
                /*searchFilterIdSelected:number,
                searchFilterAreaTypeSelected:string,
                searchFilterAreaNameSelected:string,
                searchFilterNameSelected:string,
                searchFilterSelected:boolean,
                showSearchFilter:boolean*/
                sfs:SearchFilter[]
                        ):void {
        //console.log('sf updated:', sfs[0]);
        //const usfs = addSearchFilters(searchFilters);
        let usf = sfs[0];

        const usfs = searchFilters.map((sf)=>{
                        if(sf.searchFilterId === usf.searchFilterId){
                            return usf;

                        } else {
                            return sf;
                        }
                    });


        getAnyItemList(listChangerBtnPosition, usfs, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, usfs);

        setShowDepartment(true);
        setShowProductCategory(true);
        setShowDepartmentCategory(true);
        setDeptNameSelected('none');
        setDeptCategoryNameSelected('none');
        setProductCategoryNameSelected('none');
        setSearchFilterNameSelected('');
        setSearchFilterSelected(false);
        setShowSearchFilter(false);
        setSearchFilterAreaNameSelected('none');
        setSearchFilterAreaTypeSelected('none')
        setShowAddSearchFilter(false);
        setSearchFilters(usfs);
        
        setSearchTermNameSelected('');
        setSearchTermSelected(false);
        setShowSearchTerm(false);
        setShowSearchTermImg(false);
        setSearchTermImgSelected('');
        //keep search term form open to add another search term
        setShowAddSearchTerm(false);

        setShowEditSearchFilter(false);

        setIsLoading(true);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);     

    }

    function addSearchFilterId(searchFilters:SearchFilter[]):number {
        let searchFilterIds:number[] = [];
        searchFilters.forEach((sf)=>{
            searchFilterIds.push(sf.searchFilterId);
        });
        return searchFilterIds.sort(sortByHighestId)[0] + 1;
    }

    function sortByHighestId(aId:number, bId:number):number{
        if(bId < aId){
            return -1;
        } else if(bId > aId){
            return 1;
        } else {
            return 0;
        }
    }

    function editSearchFilter(searchFilter:SearchFilter):void {
        const usf = JSON.stringify(searchFilter);

        setIsLoadingEditComponent(true);
        setShowFooter(false);
        //setShowProcessingRequestMsg(true);
        setShowKeywordSearchLimitError(false);

       
        //previous version
        //fetch(`http://localhost:9500/get-search-filter-only/${searchFilterAreaTypeSelected}/${searchFilterAreaNameSelected}/${usf}`,{
        fetch(`https://server.kando-proto-3.com/get-search-filter-only`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:usf
            })
            .then(resp=>{
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
        
                sfContainerRefs.current[0].style.minHeight = `950px`;

                return resp.json();
            })
            .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                //console.log('dbrm:', dbResponseMsg);
                //console.log('dbrr:', dbResponseRecords);
                if(dbResponseMsg === 'record found'){
                    const sf = addSearchFilter({...dbResponseRecords[0] as SearchFilter});
                    //console.log('sf:', sf);
                    loadSearchFilterAreaType(sf);
                    setSearchFilterIdSelected(sf.searchFilterId);
                    setSearchFilterAreaTypeSelected(sf.searchFilterAreaType);
                    setSearchFilterAreaNameSelected(sf.searchFilterArea);
                    setSearchFilterNameSelected(sf.filterName);
                    setShowSearchFilter(sf.showFilter);
                    setSearchFilterSelected(sf.searchFilterSelected);
                    //setShowAddSearchFilter(true);
                    setShowNoSearchResultsFound(false);
                    setShowInfoMessage(false);
                    setSearchFilterNameSearchText('');
                    setShowEditSearchFilter(true);
                    setIsLoadingEditComponent(false);
                    //setShowProcessingRequestMsg(false);
                    setShowFooter(true);

                } else {
                    throw new Error('no search filter record found');
                }



            })
            .catch(err=>{
                //console.log('edit search filter fetch error:', err);
                setShowErrorMessage(true);
                setIsLoadingEditComponent(false);
                setShowFooter(true);
            });        
    }

    function removeSearchFilter(sf:SearchFilter, lcbPosition?:number):void {
        const sfId:number = sf.searchFilterId;
        //console.log('sfId:', sfId);
        const ssf:string = JSON.stringify(sf);

        //reset form errors
        sfKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;
        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;


        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        setShowKeywordSearchLimitError(false);

        //setItemsList(null);

        //previous version
        //fetch(`http://localhost:9500/remove-search-filter/${ssf}`,{
        fetch(`https://server.kando-proto-3.com/remove-search-filter`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:ssf
        })
        .then(resp=>{

            setIsLoadingDelete(true);
            setItemsList(null);
            setShowProcessingRequestMsg(false);
            
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
            

            return resp.json();
        })
        .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
            
            if(dbResponseMsg === 'record found'){
                //const sfs = addSearchFilters(dbResponseRecords as SearchFilter[]);
                const usfs = searchFilters.filter((sf)=>{
                                return sf.searchFilterId !== sfId;
                            });

                    //console.log('usfs:', usfs);

                if(usfs.length === 0){
                    setNoSearchResultsMessage(`All search filters have been deleted from the search results page.  
                                                Try another search results page, keyword search, reset the search filter list or add more search filters.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setSearchFilterNameSearchText('');
                    setSearchFilters([...usfs]);
                    //setIsLoading(false);
                    setIsLoadingDelete(false);
                    
                } else {

                    //console.log('r15:', (usfs.length / itemsPerList), listChangerBtnPosition);

                    if((usfs.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, usfs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, usfs);

                    } else {
                        getAnyItemList(listChangerBtnPosition, usfs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, usfs);
                    }

                    setSearchFilterNameSearchText('');
                    setSearchFilters([...usfs]);
                    //getAnyItemList(listChangerBtnPosition, usfs, itemsPerList, showItemsPerList);
                    //setIsLoading(false);
                    //displayListSorter(itemsPerList, usfs);
                    //setIsLoading(false);
                    setIsLoadingDelete(false);
                }
                

            

            } else {
                setSearchFilters([]);
                //setIsLoading(false);
                setIsLoadingDelete(false);
            }



        })
        .catch((err)=>{
            //console.log('remove search filter error:', err);
            setShowProcessingRequestMsg(false);
            setShowErrorMessage(true);
        });        

    }

    function loadSearchFilterAreaType(sf:SearchFilter):void {
        switch(sf.searchFilterAreaType){
            case 'Department':
                setDeptNameSelected(sf.searchFilterArea);
                setDeptCategoryNameSelected('none');
                setProductCategoryNameSelected('none');
                setShowDepartment(true);
                setShowDepartmentCategory(false);
                setShowProductCategory(false);
                break;
            case 'Department Category':
                setDeptCategoryNameSelected(sf.searchFilterArea);
                setDeptNameSelected('none');
                setProductCategoryNameSelected('none');
                setShowDepartment(false);
                setShowDepartmentCategory(true);
                setShowProductCategory(false);
                break;
            case 'Product Category':
                setProductCategoryNameSelected(sf.searchFilterArea);
                setDeptNameSelected('none');
                setDeptCategoryNameSelected('none');
                setShowDepartment(false);
                setShowDepartmentCategory(false);
                setShowProductCategory(true);
                break;
            default:
                throw new Error('no search filter area type found.');
        }
    }

    function addSearchFilter(sf:SearchFilter):SearchFilter {
        sf = new SearchFilter({...sf});
        sf.searchFilterDateAdded = new Date(sf.searchFilterDateAdded);
        sf.searchTerms = sf.searchTerms.map((st)=>{
                                    return new SearchTerm({...st});
                                });
        return sf;      
    }

    function addSearchFilters(sfs:SearchFilter[]):SearchFilter[] {
        return sfs.map((sf)=>{
                    sf = new SearchFilter({...sf});
                    sf.searchFilterDateAdded = new Date(sf.searchFilterDateAdded);
                    //console.log('d:', sf.searchFilterDateAdded.toLocaleDateString());
                    sf.searchTerms = sf.searchTerms.map((st)=>{
                                                return new SearchTerm({...st});
                                            });
                    return sf;
                });
    }

    /* this was turned into helper-add-search-term-db.ts
    function isSearchTermUnique(searchTerm:SearchTerm):Promise<DbResponse> {        
        const st = JSON.stringify(searchTerm);
        return fetch(`http://localhost:9500/add-search-term-only/${st}`, {
                    method:'POST',
                    headers:{
                        'Accept':'application/javascript'
                    }
                })
                .then((resp)=>resp.json())
                .then((dbResponse)=>{
                    console.log('dbr:', dbResponse);
                    return dbResponse;
                })
                .catch((err)=>console.error('is search term unique fetch error:', err));
    }
    */

    //remove this after testing it in addSearchTerm.tsx
    function handleSaveSearchTerm(
                        /*deptNameSelected:string,
                        deptCategoryNameSelected:string,
                        productCategoryNameSelected:string,
                        searchFilterNameSelected:string,*/
                        searchTermNameSelected:string,
                        searchTermSelected:boolean,
                        showSearchTerm:boolean,
                        showSearchTermImg:boolean,
                        searchTermImgSelected:string
                                ):void {
                                    /*searchFilterAreaTypeSelected:string,
                                    searchFilterAreaNameSelected:string,
                                    searchFilterNameSelected:string,
                                    searchFilterSelectedChecked:boolean,
                                    showSearchFilterChecked:boolean*/
        //console.log('st saved');
        //console.log('sfats:', searchFilterAreaTypeSelected);
        //console.log('sfans:', searchFilterAreaNameSelected);
        //console.log('sfns:', searchFilterNameSelected);
        //console.log('sfs:', searchFilterSelected);
        //console.log('ssf', showSearchFilter);
        const sfToBeAdded = new SearchFilter({
                                searchFilterArea:searchFilterAreaNameSelected,
                                searchFilterAreaType:searchFilterAreaTypeSelected,
                                filterName:searchFilterNameSelected,
                                showFilter:showSearchFilter,
                                searchFilterSelected:searchFilterSelected
                            });     

        const stToAdd = new SearchTerm({
                            searchFilterArea:searchFilterAreaNameSelected,
                            searchFilterAreaType:searchFilterAreaTypeSelected,
                            searchFilterName:searchFilterNameSelected,
                            searchTermName:searchTermNameSelected,
                            searchTermSelected:searchTermSelected,
                            showSearchTerm:showSearchTerm,    
                            showSearchTermImg:showSearchTermImg,
                            searchTermImg:searchTermImgSelected
                        });

        
        //console.log('sfToBeAdded:', sfToBeAdded);
        //console.log('stToAdd:', stToAdd);
        isSearchTermUnique(stToAdd)
        .then(({dbResponseMsg}:DbResponse)=>{
            setShowStoreStructureLoadingMsg(false);
            //console.log('dbrm:', dbResponseMsg);
            if(dbResponseMsg === 'record found'){
                //show alert that search term already exits
                setStoreStructureAlertMsg('search term already exists');
                setShowStoreStructureAlert(true);
    
                setTimeout(()=>{
                    setStoreStructureAlertMsg('no msg');
                    setShowStoreStructureAlert(false);
                },2000);
            
            } else {
                const searchFilter = JSON.stringify(sfToBeAdded);
                setShowStoreStructureLoadingMsg(true);
                
                fetch(`https://server.kando-proto-3.com/add-search-filter-only/${searchFilter}`,{
                    method:'POST',
                    headers:{
                        'Accept':'application/javascript'
                    }
                })
                .then(resp=>resp.json())
                .then(({dbResponseRecords}:DbResponse)=>{
                    //console.log('dbrr:', dbResponseRecords);
                    setShowStoreStructureLoadingMsg(false);
                    //run these after db is updated
                    //setDeptNameSelected('none');
                    //setDeptCategoryNameSelected('none');
                    //setProductCategoryNameSelected('none');
                    //setSearchFilterNameSelected('none');
                    //setSearchTermNameSelected('');
                    //setSearchFilterSelectedChecked(false);
                    //setShowSearchFilterChecked(false);
                    //setSearchTermSelected(false);
                    //setShowAddSearchTerm(false);
                    //setShowAddSearchFilter(true);
                    //finish saving search term to database
                    //make sure to save the current values of
                    //searchFilterSelectedChecked() and showSearchFilterChecked() to the database
                    //fetch()

                    const asfs = addSearchFilters(dbResponseRecords as SearchFilter[]);
                    
                    //add these to the close add search term function
                    /*
                    setShowDepartment(true);
                    setShowProductCategory(true);
                    setShowDepartmentCategory(true);
                    setDeptNameSelected('none');
                    setDeptCategoryNameSelected('none');
                    setProductCategoryNameSelected('none');
                    setSearchFilterNameSelected('');
                    setSearchFilterSelectedChecked(false);
                    setShowSearchFilterChecked(false);
                    setSearchFilterAreaNameSelected('none');
                    setSearchFilterAreaTypeSelected('none')
                    setShowAddSearchFilter(false);
                    setSearchFilters(asfs);
                    
                    setSearchTermNameSelected('');
                    setSearchTermSelected(false);
                    setShowSearchTerm(false);
                    setShowSearchTermImg(false);
                    setSearchTermImgSelected('');
                    setShowAddSearchTerm(false);
                    */
                    //keep search term form open to add another search term
                    //setShowAddSearchTerm(true);
                    
        
                })
                .catch((err)=>console.error('add search term fetch error:', err));
                
            }
        })
        .catch((err)=>console.error('is search term unique fetch error:', err));

    }

    function handleCloseAddSearchTerm(e:React.MouseEvent):void {

        setListChangerBtnPosition(0);

        getAllSearchFiltersPromise()
        .then((sfs:SearchFilter[]|null)=>{

            if(sfs === null){
                return;

            } else if(sfs.length === 0){
                //show generic info message if no search filters are found
                setNoSearchResultsMessage(`No search filters have been added.`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoading(false);

            } else {

                setSearchFilters([...sfs]);
                setShowDepartment(true);
                setShowProductCategory(true);
                setShowDepartmentCategory(true);
                setDeptNameSelected('none');
                setDeptCategoryNameSelected('none');
                setProductCategoryNameSelected('none');
                setSearchFilterNameSelected('');
                setSearchFilterSelected(false);
                setShowSearchFilter(false);
                setSearchFilterAreaNameSelected('none');
                setSearchFilterAreaTypeSelected('none')
                setShowAddSearchFilter(false);
                //setSearchFilters(asfs);
                
                setSearchTermNameSelected('');
                setSearchTermSelected(false);
                setShowSearchTerm(false);
                setShowSearchTermImg(false);
                setSearchTermImgSelected('');
                //keep search term form open to add another search term
                setShowAddSearchTerm(false);

                //previous version
                //getAnyItemList(listChangerBtnPosition, sfs, itemsPerList, showItemsPerList);
                getAnyItemList(0, sfs, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, sfs);
                setIsLoading(false);
            }

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            
        })
        .catch((err)=>{
            //console.log('close add search term error:',err.message);
            setShowErrorMessage(true);
            setIsLoading(false);
        });
    }

    function handleAddSearchTerm(
                    showDepartment:boolean,
                    showDepartmentCategory:boolean,
                    showProductCategory:boolean,
                    deptNameSelected:string,
                    deptCategoryNameSelected:string,
                    productCategoryNameSelected:string,
                    searchFilterNameSelected:string,
                    searchTermNameSelected:string,
                    searchFilterSelected:boolean,
                    showSearchFilter:boolean,
                    searchFilterAreaNameSelected:string,
                    searchFilterAreaTypeSelected:string
                                ):void {
        
        //console.log('sfats:', searchFilterAreaTypeSelected);
        //console.log('sfans:', searchFilterAreaNameSelected);
        //console.log('sfns:', searchFilterNameSelected);

        //current settings
        setShowDepartment(showDepartment);
        setShowDepartmentCategory(showDepartmentCategory);
        setShowProductCategory(showProductCategory);
        setDeptNameSelected(deptNameSelected);
        setDeptCategoryNameSelected(deptCategoryNameSelected);
        setProductCategoryNameSelected(productCategoryNameSelected);
        setSearchFilterNameSelected(searchFilterNameSelected);
        setSearchTermNameSelected(searchTermNameSelected);
        setSearchFilterSelected(searchFilterSelected);
        setShowSearchFilter(showSearchFilter);
        setSearchFilterAreaNameSelected(searchFilterAreaNameSelected);
        setSearchFilterAreaTypeSelected(searchFilterAreaTypeSelected);
        setShowAddSearchFilter(false);
        setShowAddSearchTerm(true);
           
        
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        
    }


    function showItemsPerList(itemsList:object[]):void {
        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as SearchFilter[]);

        //adjust item count based on items per list setting
        let nrh = 0;
        if(itemsPerList < itemsList.length){
            nrh = (itemsPerList * 91) + 588 + 16 + 57;

        } else {
            nrh = (itemsList.length) * 91 + 588 + 16 + 57;
        }

        const mh = 950;
        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
            sfContainerRefs.current[0].style.minHeight = `${mh}px`;
        }

        if(existingSearchResultsHeight.current === 0 && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh < mh){
            existingSearchResultsHeight.current = mh;

            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh > existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }
        //console.log('esrs:', existingSearchResultsHeight.current);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function displayListSorter(itemsPerList:number, sfs:SearchFilter[]):void {
        if(sfs.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleSearchFilterSearchText(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        //console.log('value.length:', target.value.length);

        if(target.value.length <= 25){
            setSearchFilterNameSearchText(target.value);
            setShowKeywordSearchLimitError(false);

        } else {
            setShowKeywordSearchLimitError(true);
        }

    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowInfoMessage(false);
    }

    function handleResultsPerPageOptionSelected(e:React.ChangeEvent):void {
        const rppoSelected = (e.target as HTMLSelectElement).value;
        setResultsPerPageOptionSelected(rppoSelected);
        const ipp = parseInt(rppoSelected, 10);
        
        const previpp = itemsPerList;
        const previousPositionInList = (previpp * (listChangerBtnPosition + 1)) - previpp + 1;
        const startPointInList = Math.floor(previousPositionInList / ipp);
        //console.log('cbp:', startPointInList, previousPositionInList / ipp, listChangerBtnPosition);

        if(ipp > searchFilters.length){
            setItemsPerList(ipp);
            setListChangerBtnPosition(0);
            getAnyItemList(0, searchFilters, ipp, showItemsPerList);

        } else {

            if(listChangerBtnPosition === 0){
                setItemsPerList(ipp);
                setListChangerBtnPosition(0);
                getAnyItemList(0, searchFilters, ipp, showItemsPerList);

            } else {
                setItemsPerList(ipp);
                setListChangerBtnPosition(startPointInList - 1);
                getAnyItemList(startPointInList - 1, searchFilters, ipp, showItemsPerList);
            }

        }
        
        displayListSorter(ipp, searchFilters);


        //adjust item count based on items per list setting
        let nrh = 0;
        if(ipp < searchFilters.length){
            nrh = (ipp * 91) + 588 + 16 + 57;

        } else {
            nrh = (searchFilters.length) * 91 + 588 + 16 + 57;
        }

                            
        if(nrh > 950){
            sfContainerRefs.current[0].style.minHeight = `${nrh}px`;
        }

    }


    function handleSubmitSearchFilterSearch(e:React.FormEvent):void {
        e.preventDefault();

        const form = e.target as HTMLFormElement;

        //reset form errors
        sfKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;
        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //product info
        //product name selected
        const sfnst = searchFilterNameSearchText;
        if(sfnst.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Search Filter Keyword'});
            
        } else {
            setShowKeywordSearchLimitError(false);
        }

        //pricing info
        //department category image
        /*
        const pp = productPrice;
        if(pp.length === 0 || parseInt(pp, 10) === 0){
            formError.pricingErrors.push({hasError:true, area:'Price'});
        }
        */

        const sfnsts = formError.productInfoErrors.length > 0 ? true : false;
        //const pps = formError.pricingErrors.length > 0 ? true : false;

        if(sfnsts /*|| pps*/){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }
        //setShowLoadingSearchResults(true);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        //setItemsList(null);

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);

        
        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);

        const searchText = searchFilterNameSearchText;

        /*
        const zipCodePattern = /\d+/g
        const zcr = searchText.match(zipCodePattern);

        if(zcr !== null){
            //found some numbers
            let numberString = '';
            zcr.forEach((num)=>{
                numberString += num;		
                });
                        
            console.log('zcr:', zcr);
            console.log('search number:', parseInt(numberString,10));
    
            const searchText = numberString;
            //check store numbers
            fetch(`http://localhost:9500/store-location-search-number/${searchText}`, {
                method:'GET',
                headers:{
                    'Content-Type':'text/javascript',
                    'Accept':'text/javascript'
                }
            })
            .then((res)=>res.json())
            .then((result)=>{
                console.log('result:', result);
                if(result.length === 1 && result[0].source === null && result[0].department === null){
                    //setShowLoadingSearchResults(false);
                    setShowNoSearchResultsFound(true);
                } else {
                    
                    Promise.all(addDepartmentImages(result))
                    .then((res)=>{
                        console.log('res 11-27:', res);            
                        const ds = addDepartments(res);
                        setDepartments(ds);
                        getAnyItemList(listChangerBtnPosition, ds, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, ds);
                        //setShowLoadingSearchResults(false);
                    })
                    .catch((err)=>console.error('add department images error:', err.message));
                    
                }
            })
            .catch(err=>console.error('department number search error:', err.message));
            
        } else {
            */
            //check department name            
            if(searchText.length > 0){
                let searchString = '';
    
                for(let i=0; i < searchText.length; i++){
                    if(/[\(\)\[\]\*\$\^\{\}\?\_\+\.\"\'\\|]/.test(searchText[i])){
                        searchString += `\\${searchText[i]}`;
    
                    } else {
                        searchString += `${searchText[i]}`;
                    }
                }
    
                //console.log('str26:', searchString);
                //searchString = '\\(-\\_\'\"\"\\]\\\\//\\?what\\^\\$@1';  

                const st = {searchText:searchString};
                
                const params = JSON.stringify(st);

                setTemporaryClearSearchResults(true);
                //previous version
                //fetch(`http://localhost:9500/search-filter-search-name/${searchText}`, {
                fetch(`https://server.kando-proto-3.com/search-filter-search-name`, {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:params
                })
                .then((res)=>{

                    setShowProcessingRequestMsg(false);
                    setItemsList(null);
                    isLoadingTimeoutRef.current = window.setTimeout(()=>{
                                                            setIsLoading(true);
                                                        },1000);
                    setIsLoadingDelete(true);
    
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    return res.json();
                })
                .then((result)=>{
                    //console.log('result:', result);
                    if(result.length === 1 && result[0].searchFilters === null){
                        //setShowLoadingSearchResults(false);
                        setNoSearchResultsMessage('No search filters matched those search parameters.');
                        setShowNoSearchResultsFound(true);
                        setSearchFilters([]);
                        setShowInfoMessage(true);

                        setTemporaryClearSearchResults(false);
                        window.clearTimeout(isLoadingTimeoutRef.current);
                        setIsLoading(false);
                        setIsLoadingDelete(false);

                        sfContainerRefs.current[0].style.minHeight = `950px`;

                    } else {
                        //console.log('res29:', result);
                        const usfs = modifySearchFilterDateAdded(result);
                        const csfs = addSearchFilters(usfs);
                        setSearchFilters(csfs);
                        setListChangerBtnPosition(0);
                        getAnyItemList(0, csfs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, csfs);

                        setTemporaryClearSearchResults(false);

                        window.clearTimeout(isLoadingTimeoutRef.current);
                        setIsLoading(false);
                        setIsLoadingDelete(false);
                        //setShowLoadingSearchResults(false);

                        //adjust item count based on items per list setting
                        let nrh = 0;
                        if(itemsPerList < csfs.length){
                            nrh = (itemsPerList * 91) + 588 + 16 + 57;

                        } else {
                            nrh = (csfs.length) * 91 + 588 + 16 + 57;
                        }

                        const mh = 950;
                        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                            sfContainerRefs.current[0].style.minHeight = `${mh}px`;
                        }
    
                        if(existingSearchResultsHeight.current === 0 && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh < existingSearchResultsHeight.current && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh < existingSearchResultsHeight.current && nrh < mh){
                            existingSearchResultsHeight.current = mh;
    
                            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh > existingSearchResultsHeight.current && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            sfContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
                        //console.log('esrs:', existingSearchResultsHeight.current);                        
                        
                    }
                    
                })
                .catch(err=>{
                    //console.log('search filter name search error:', err.message);
                    setShowErrorMessage(true);
                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);
                });

            } else {
                //console.log('no results found.');
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoadingDelete(false);


                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
            }
            
            
        //}


    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Search Filter Keyword':
                                sfKeywordRefs.current[0]!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no search filter keyword info errors found!');
                        }
                    }
                });


        //set image errors
        /*
        formError.pricingErrors.forEach((pe)=>{
            console.log('pe:', pe);
            if(pe.hasError && pe.area === 'Price'){
                console.log('pe.area:', pe.area);
                productPriceRef.current!.classList.add('pricing-error');
            } else {
                throw new Error('no price errors found!');
            }
        });
        */
    }


    {/*}
    if(isLoading){
        return <div>
                    <h3 className="store-structure-dpts-categories-heading">Search Filters</h3>
                    <div>Loading...</div>
                    <div data-testid="spinner">
                            <i className="fa fa-spinner fa-spin" style={{fontSize:'48px'}}></i>
                    </div>
                </div>

    } else if(!showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && searchFilters.length === 0){
        return <Col>
                    <h3 className="store-structure-dpts-categories-heading">Search Filters</h3>
                    {<p>No search filters have been added.</p>}
                    <button type="button" onClick={handleAddSearchFilter}>Add Search Filter</button>
                    <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                </Col>
    } else if(!showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && searchFilters.length > 0) {*/}
    return <div className="sssfs-wrapper">
                <div ref={getSFContainerRef} className="sssfs-container">
                    <ul className="store-details-breadcrum-container">
                        <li className="sdbi">
                            <Link to="/admin" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi">
                            <Link to="/admin/store-structure" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin/store-structure', navigate)}>Store Structure</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi store-details-breadcrum-link-store-name">Search Filters</li>
                    </ul>

                    {
                        !showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && !isLoadingEditComponent
                        ?
                        <h1 className="store-structure-dpts-categories-heading">Search Filters</h1>
                        :
                        null
                    }
                    

                    {
                        !showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && !isLoadingEditComponent
                        ?
                        <div>
                            <div className="admin-add-item-btn-container">
                                <button type="button" className="admin-add-item-btn" onClick={handleAddSearchFilter}>Add Filter</button>
                                <button type="button" className="admin-reset-items-btn" onClick={resetSearchFilters}>Reset Filters</button>
                            </div>
                            <form ref={getFormRef} className="admin-search-form" onSubmit={handleSubmitSearchFilterSearch}>
                                <fieldset className="admin-search-box-container">
                                    <label className="admin-search-box-label" htmlFor="search-box">Keywords:</label>
                                    <span className="sf-search-secondary-label">(Enter filter name, filter area or filter type)</span>
                                    <div className="table-keyword-search-layout-wrapper">
                                        <input id="search-box" className="admin-search-box" type="text"
                                            ref={getSFKeywordRef}
                                            placeholder={"Enter filter name, filter area or filter type"}
                                            value={searchFilterNameSearchText} onChange={handleSearchFilterSearchText}/>
                                        {
                                            showKeywordSearchLimitError
                                            ?
                                            <p className="exceed-character-limit-error">The maximum characters allowed (25)</p>
                                            :
                                            null
                                        }
                                    </div>
                                </fieldset>
                                <fieldset className="admin-search-form-btn-container">
                                    <button type="submit" className="admin-search-form-btn">Search</button>
                                </fieldset>
                                <FormErrorMessage ref={getSfgErrorMsgRef} formError={formError} />
                            </form>
                        </div>
                        :
                        null
                    
                    }


                    {
                        !showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && !isLoading
                        && showNoSearchResultsFound && showInfoMessage && !isLoadingDelete
                        ?
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                <span className="admin-list-total">{searchFilters.length} results</span>
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-generic-info-message-container">
                                {noSearchResultsMessage}
                                {/*<GenericInfoMessage infoMessage={noSearchResultsMessage}/>*/}
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        !showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && !isLoading
                        && searchFilters.length > 0 && !showNoSearchResultsFound && !showErrorMessage && !isLoadingDelete && !isLoadingEditComponent
                        ?
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                {
                                    temporaryClearSearchResults
                                    ?
                                    <span className="admin-list-total"></span>
                                    :
                                    <span className="admin-list-total">{(searchFilters.length === 1) ? `${searchFilters.length} result` : `${searchFilters.length} results`}</span>
                                }
                                
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <ListViewer itemsList={itemsList} items={searchFilters} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupSearchFilterTable} />
                        </div>
                        :
                        null
                    }

                    {
                        showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && !isLoading
                        ?
                        <AddSearchFilter 
                        setIsLoading={setIsLoading}
                        showAddSearchFilter={showAddSearchFilter}
                        showDepartment={showDepartment}
                        showDepartmentCategory={showDepartmentCategory}
                        showProductCategory={showProductCategory}
                        deptNameSelected={deptNameSelected}
                        deptCategoryNameSelected={deptCategoryNameSelected}
                        productCategoryNameSelected={productCategoryNameSelected}
                        searchFilterNameSelected={searchFilterNameSelected}
                        searchTermNameSelected={searchTermNameSelected}
                        searchFilterSelected={searchFilterSelected}
                        showSearchFilter={showSearchFilter}
                        searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                        searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                        handleSaveSearchFilter={handleSaveSearchFilter}
                        handleAddSearchTerm={handleAddSearchTerm}
                        handleCancelSearchFilter={handleCancelSearchFilter}
                        />
                        :
                        null
                    }

                    {
                        !showAddSearchFilter && showAddSearchTerm && !showEditSearchFilter && !isLoading
                        ?
                        <AddSearchTerm 
                        showAddSearchTerm={showAddSearchTerm}
                        /*showDepartment={showDepartment}
                        showDepartmentCategory={showDepartmentCategory}
                        showProductCategory={showProductCategory}*/
                        /*deptNameSelected={deptNameSelected}
                        deptCategoryNameSelected={deptCategoryNameSelected}
                        productCategoryNameSelected={productCategoryNameSelected}*/
                        searchFilterNameSelected={searchFilterNameSelected}
                        searchTermNameSelected={searchTermNameSelected}
                        searchTermSelected={searchTermSelected}
                        showSearchTerm={showSearchTerm}
                        showSearchTermImg={showSearchTermImg}
                        searchTermImgSelected={searchTermImgSelected}
                        searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                        searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                        searchFilterSelected={searchFilterSelected}
                        showSearchFilter={showSearchFilter}
                        handleBackToSearchFilter={handleBackToSearchFilter}
                        handleCloseAddSearchTerm={handleCloseAddSearchTerm}
                        />
                        :
                        null
                    }

                    {
                        !showAddSearchFilter && !showAddSearchTerm && showEditSearchFilter && !isLoading
                        ?
                        <EditSearchFilter
                        showEditSearchFilter={showEditSearchFilter}
                        showDepartment={showDepartment}
                        showDepartmentCategory={showDepartmentCategory}
                        showProductCategory={showProductCategory}
                        deptNameSelected={deptNameSelected}
                        deptCategoryNameSelected={deptCategoryNameSelected}
                        productCategoryNameSelected={productCategoryNameSelected}
                        searchFilterNameSelected={searchFilterNameSelected}
                        searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                        searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                        searchFilterSelected={searchFilterSelected}
                        showSearchFilter={showSearchFilter}
                        searchFilterIdSelected={searchFilterIdSelected}
                        handleUpdateSearchFilter={handleUpdateSearchFilter}
                        handleCancelSearchFilter={handleCancelSearchFilter}
                            />
                        :
                        null
                    }

                    {
                        !showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && isLoading
                        ?
                        <div ref={getLoaderRef} className="ssds-loader-animation-container">
                            <LoaderAnimation/>
                        </div>
                        :
                        null
                    }

                    {
                        !showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter && isLoadingDelete
                        ?
                        <div className="sssfs-loader-animation-container">
                            {/*<LoaderAnimation/>*/}
                            {
                                isLoading
                                ?
                                <LoaderAnimation/>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }

                    {
                        showProcessingRequestMsg
                        ?
                        <div className="process-request-msg-fixed">
                            Processing...
                        </div>
                        :
                        null
                    }

                    {
                        showErrorMessage
                        ?
                        <div className="ssd-generic-error-msg-wrapper">
                            <GenericErrorMessage/>
                        </div>
                        :
                        null
                    }

                </div>
                {
                    showFooter
                    ?
                    <Footer/>
                    :
                    null
                }
            </div>
    {/*}
    } else if(showAddSearchFilter && !showAddSearchTerm && !showEditSearchFilter) {
        return <Col>
                    <h3 className="store-structure-dpts-categories-heading">Search Filters</h3>
                    <StoreStructureLoadingMsg
                        showStoreStructureLoadingMsg={showStoreStructureLoadingMsg}
                        storeStructureLoadingMsg={storeStructureLoadingMsg}
                    />
                    <StoreStructureAlert 
                            showStoreStructureAlert={showStoreStructureAlert}
                            storeStructureAlertMsg={storeStructureAlertMsg}
                            handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />
                    <AddSearchFilter 
                            setIsLoading={setIsLoading}
                            showAddSearchFilter={showAddSearchFilter}
                            showDepartment={showDepartment}
                            showDepartmentCategory={showDepartmentCategory}
                            showProductCategory={showProductCategory}
                            deptNameSelected={deptNameSelected}
                            deptCategoryNameSelected={deptCategoryNameSelected}
                            productCategoryNameSelected={productCategoryNameSelected}
                            searchFilterNameSelected={searchFilterNameSelected}
                            searchTermNameSelected={searchTermNameSelected}
                            searchFilterSelected={searchFilterSelected}
                            showSearchFilter={showSearchFilter}
                            searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                            searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                            handleSaveSearchFilter={handleSaveSearchFilter}
                            handleAddSearchTerm={handleAddSearchTerm}
                            handleCancelSearchFilter={handleCancelSearchFilter}
                    />
                </Col>
    } else if(!showAddSearchFilter && showAddSearchTerm && !showEditSearchFilter){
        return <Col>
                    <h3 className="store-structure-dpts-categories-heading">Search Filters</h3>
                    <AddSearchTerm 
                            showAddSearchTerm={showAddSearchTerm}
                            searchFilterNameSelected={searchFilterNameSelected}
                            searchTermNameSelected={searchTermNameSelected}
                            searchTermSelected={searchTermSelected}
                            showSearchTerm={showSearchTerm}
                            showSearchTermImg={showSearchTermImg}
                            searchTermImgSelected={searchTermImgSelected}
                            searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                            searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                            searchFilterSelected={searchFilterSelected}
                            showSearchFilter={showSearchFilter}
                            handleBackToSearchFilter={handleBackToSearchFilter}
                            handleCloseAddSearchTerm={handleCloseAddSearchTerm}
                    />
                </Col>
    
    } else {
        return <Col>
                    <h3 className="store-structure-dpts-categories-heading">Search Filters</h3>
                    <EditSearchFilter
                            showEditSearchFilter={showEditSearchFilter}
                            showDepartment={showDepartment}
                            showDepartmentCategory={showDepartmentCategory}
                            showProductCategory={showProductCategory}
                            deptNameSelected={deptNameSelected}
                            deptCategoryNameSelected={deptCategoryNameSelected}
                            productCategoryNameSelected={productCategoryNameSelected}
                            searchFilterNameSelected={searchFilterNameSelected}
                            searchFilterAreaNameSelected={searchFilterAreaNameSelected}
                            searchFilterAreaTypeSelected={searchFilterAreaTypeSelected}
                            searchFilterSelected={searchFilterSelected}
                            showSearchFilter={showSearchFilter}
                            searchFilterIdSelected={searchFilterIdSelected}
                            handleUpdateSearchFilter={handleUpdateSearchFilter}
                            handleCancelSearchFilter={handleCancelSearchFilter}
                    />
                </Col>
    }
    */}
    

}


export default StoreStructureSearchFilters2;