function getAreaTypeSearchFilterNamesPromise(searchAreaTypeSelected:string, searchAreaSelected:string):Promise<string[]> {
    return fetch(`https://server.kando-proto-3.com/get-area-type-search-filters/${searchAreaTypeSelected}/${searchAreaSelected}`,{
                method:'GET',
                headers:{
                    'Accept':'application/javascript'
                }
            })
            .then((res)=>res.json())
            .then((searchFilterNames:string[])=>{
                //console.log('sfns:', searchFilterNames);
                return searchFilterNames;           
            })
            .catch(err=>{
                //console.error('get search filters names fetch error:', err)
                return [];
            });
}

export default getAreaTypeSearchFilterNamesPromise;