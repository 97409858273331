class ProductWeight {
    public weightId:number;
    public productId:number;
    public startWeight:number;
    public endWeight:number;
    public weightIncrement:number;
    public weightList:number[];
    constructor(options:{    
        weightId?:number,
        productId?:number,
        startWeight?:number,
        endWeight?:number,
        weightIncrement?:number,
        weightList?:number[]
    }={}){
        this.weightId = options.weightId || 0;
        this.productId = options.productId || 0;
        this.startWeight = options.startWeight || 0;
        this.endWeight = options.endWeight || 0;
        this.weightIncrement = options.weightIncrement || 0;
        this.weightList = options.weightList || [];
    }
}

export default ProductWeight;