import React, {useState, useEffect} from 'react';
import MapDisplay from './MapDisplay';
import MapDisplay2 from './MapDisplay2';
import MapDisplay3 from './MapDisplay3';
import StoreLocation from './storeLocation';

interface Props {
    storeLocation:StoreLocation;
    storeLocationList:StoreLocation[];
}

const StoreLocationMap:React.FunctionComponent<Props> = (props:Props)=>{

    const [storeLocation, setStoreLocation] = useState<StoreLocation>(props.storeLocation);
    const [otherStoreLocations, setOtherStoreLocations] = useState<StoreLocation[]|null>(props.storeLocationList);

    useEffect(()=>{
        setStoreLocation(props.storeLocation);
    },[props.storeLocation]);

    useEffect(()=>{
        const osls = getOtherStoreLocations(props.storeLocation, props.storeLocationList);
        setOtherStoreLocations(osls);
    },[props.storeLocationList]);

    function getOtherStoreLocations(storeLocationSelected:StoreLocation, storeLocationList:StoreLocation[]):StoreLocation[] {
        if(storeLocationList !== null){
            const osls = storeLocationList.filter((slli)=>{
                return slli.storeLocationId !== storeLocationSelected.storeLocationId;
            });
            //console.log('osls:', osls);
            return osls;

        } else {
            return [];
        }

    }

    if(storeLocation.location.lat !== 0 && storeLocation.location.lng !== 0 && otherStoreLocations !== null && otherStoreLocations.length > 0){
        //console.log('sl8:', storeLocation, 'osls:', otherStoreLocations);
        //return <MapDisplay apiKey="AIzaSyD02knEm3qPtvmQW4aJhp7H4V9VGOu4KDQ" storeLocation={storeLocation} />
        //return <MapDisplay2 apiKey="AIzaSyD02knEm3qPtvmQW4aJhp7H4V9VGOu4KDQ" storeLocation={storeLocation} otherStoreLocations={otherStoreLocations}/>
        return <MapDisplay3 apiKey="AIzaSyD02knEm3qPtvmQW4aJhp7H4V9VGOu4KDQ" storeLocation={storeLocation} otherStoreLocations={otherStoreLocations}/>
    } else {
        return null;
    }
}

export default StoreLocationMap;