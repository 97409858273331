import React, {useState, useEffect, useRef} from 'react';
import StoreDepartment from './storeDepartment';
import AddStoreDepartment from './AddStoreDepartment';

interface Props {
    department:StoreDepartment;
    handleCloseForm:(formType:string)=>void;
}

const EditStoreDepartment: React.FunctionComponent<Props> = (props:Props) => {
    const {department, handleCloseForm} = props;

    return <AddStoreDepartment showEditDepartment={true} departmentToEdit={department} handleCloseForm={handleCloseForm}/>
}

export default EditStoreDepartment;