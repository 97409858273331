import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import ProductKeywordSearch from '../../productKeywordSearch';
import cloneDeep from 'lodash/cloneDeep';

interface AdminLoginDropdownMobile {
    showAdminLoginDropdownMobile:boolean;
    //showMobileNav:boolean;
    linkSelected:boolean;
}

const initialState:AdminLoginDropdownMobile = {
    showAdminLoginDropdownMobile:false,
    linkSelected:false
};

export const adminLoginDropdownMobileSlice = createSlice({
  name: 'adminLoginDropdownMobile',
  initialState,
  reducers: {
    showAdminLoginDropdownMobile: (state, action:PayloadAction<AdminLoginDropdownMobile>) => {
      state.showAdminLoginDropdownMobile = action.payload.showAdminLoginDropdownMobile;
      state.linkSelected = action.payload.linkSelected;
    }
  }
})

// Action creators are generated for each case reducer function
export const { showAdminLoginDropdownMobile } = adminLoginDropdownMobileSlice.actions;

interface RootState {
  adminLoginDropdownMobile: AdminLoginDropdownMobile
}

export const selectAdminLoginDropdownMobile = (state:RootState) =>  {
  //const clone = cloneDeep(state.productKeywordSearch);
  return state.adminLoginDropdownMobile;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default adminLoginDropdownMobileSlice.reducer;