import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import {connect} from 'react-redux';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate, useLocation } from "react-router-dom";
import ShopCategories from './shopCategories';
import ShopCategory from './shopCategory';
import helperHighlightCategory from './helper-highlight-category';
import ShopLoadDetails from './redux/shopLoadDetails';
//import {AddShopLoadDetails, addShopLoadDetails} from './redux/actions';
import {addShopLoadDetails} from './redux/features/shopLoadDetails/shopLoadDetailsSlice';
import {selectMobileNav, showMobileNav} from './redux/features/mobileNav/mobileNavSlice';
import {selectAdminLoginDropdownMobile, showAdminLoginDropdownMobile} from './redux/features/adminLoginDropdownMobile/adminLoginDropdownMobileSlice';
import {selectHighlightBackground, addHighlightBackground} from './redux/features/highlightBackground/highlightBackgroundSlice';
//import { addDropdownNavParams, selectDropdownNavParams } from './redux/features/dropdownNavParams/dropdownNavParamsSlice';
//import {resetSBAT} from './redux/features/shopByAreaType/shopByAreaTypeSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import loadNavLinkHelper from './loadNavLinkHelper';
import formatLinkString from './helper-format-link-string';


interface Props {
    //dropdownMenuList:React.RefObject<HTMLUListElement>;
    dropdownCategories:ShopCategory[];
    //original version
    //dropdownCategories:ShopCategories;
    dropdownMenuListHeading:string;
    loadNavLink?:(e:React.MouseEvent, pathname:string)=>void;
}

/*
const mapDispatchToProps = {
    addShopLoadDetails
};
*/

type Ref = HTMLDivElement;
//type Ref = {menuListContainer:React.RefObject<HTMLDivElement>, menuListMobileNav:React.RefObject<HTMLElement>};

const DropdownMenuCategories = forwardRef<Ref, Props>((props, ref)=>{
//const DropdownMenuCategories:React.FunctionComponent<Props> = (props:Props)=>{
    //const dropdownNavParamsRedux = useAppSelector(selectDropdownNavParams);
    //console.log('redux28:', dropdownNavParamsRedux);
    const location = useLocation();
    const navigate = useNavigate();
    const {dropdownCategories, dropdownMenuListHeading, loadNavLink} = props;

    const dropdownMenuListContainer = useRef<HTMLDivElement>(null);
    const shopMobileDropdownSubMenuListContainerRefs = useRef<HTMLDivElement[]>([]);

    //console.log('ddcs:', dropdownCategories);
  
    /*
    useImperativeHandle(ref, ()=>{
       console.log('ref test2', ref);

       return ({
            get menuListContainer(){
            return dropdownMenuListContainer;
            },
            get menuListMobileNav(){
                return menuListMobileNav
            }      
        });
    });
    */    

    useImperativeHandle(ref, ()=>dropdownMenuListContainer.current!);

    /*
    useEffect(()=>{
        console.log('redux28:', dropdownMenuListContainer.current!);

        if(window.innerWidth < 1024 && typeof loadNavLink === 'undefined' && dropdownNavParamsRedux.closeDropdownNav 
            && dropdownMenuListContainer.current! !== null){
            dropdownMenuListContainer.current!.style.display = 'none';
            const parent = dropdownMenuListContainer.current!.querySelector('.shop-dropdown-menu-list') as HTMLUListElement;
            //const parent = (e.target as HTMLElement).closest('.shop-dropdown-menu-list') as HTMLUListElement;
            if(parent !== null){
                parent.style.borderRadius = '24px';
            }
            
        }

        dispatch(addDropdownNavParams({closeDropdownNav:false}));

    },[dropdownNavParamsRedux]);
    */
    
   
    const dispatch = useAppDispatch();

    function highlightCategoryText(
                                    e:React.MouseEvent,
                                    selectedStoreDepartmentName:string,
                                    selectedDepartmentCategoryName:string,
                                    selectedDepartmentCategoryId:number,
                                    selectedStoreDepartmentId:number
                                ):void {
        e.stopPropagation();


        if(e.cancelable){
            e.preventDefault();
        }
        
        if(window.innerWidth >= 1024){
            const parent = (e.target as HTMLElement).closest('.shop-dropdown-menu-list') as HTMLUListElement;
            const elem = (e.target as HTMLElement).closest('.pharmacy-menu-list-item');

            if(elem !== null){
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                navigate(`/`);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
                
                
                dropdownMenuListContainer.current!.style.display = 'none';
                parent.style.borderRadius = '24px';


            } else {
                helperHighlightCategory(e, selectedDepartmentCategoryName, 'department')
                .then((response)=>response.json())
                .then(({action})=>{
                    if(action === 'added'){
                        //console.log('added');
                        //original version
                        //navigate(`/shop/${selectedStoreDepartmentName}/${selectedDepartmentCategoryName}`);

                        /*
                        let root = document.documentElement;
                        root!.setAttribute('style', 'scroll-behavior: auto');
                        navigate(`/shop/${selectedStoreDepartmentName}/${selectedDepartmentCategoryName}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`);
                        window.setTimeout(()=>{
                            root!.removeAttribute('style');
                        },100);
                        */
                        if(typeof loadNavLink !== 'undefined'){
                            //console.log('loc3:', decodeURIComponent(location.pathname) === `/shop/${formatLinkString(selectedStoreDepartmentName)}/${formatLinkString(selectedDepartmentCategoryName)}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`);

                            if(decodeURIComponent(location.pathname) !== `/shop/${formatLinkString(selectedStoreDepartmentName)}/${formatLinkString(selectedDepartmentCategoryName)}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`){
                                //previous version
                                //loadNavLink!(e, `/shop/<${encodeURIComponent(selectedStoreDepartmentLinkName.replace(/\//g, '//'))}>/<${encodeURIComponent(selectedDepartmentCategoryName.replace(/\//g, '//'))}>/<${selectedStoreDepartmentId}>/<${selectedDepartmentCategoryId}>`);
                                loadNavLink!(e, `/shop/${encodeURIComponent(formatLinkString(selectedStoreDepartmentName))}/${encodeURIComponent(formatLinkString(selectedDepartmentCategoryName))}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`);
                            }

                            handleDropdownSubMenuListItemClick(
                                e, 
                                selectedStoreDepartmentName, 
                                selectedDepartmentCategoryName,
                                selectedStoreDepartmentId,
                                selectedDepartmentCategoryId
                                );


                        } else {
                            //previous version
                            //loadNavLinkHelper(e, `/shop/<${encodeURIComponent(selectedStoreDepartmentLinkName.replace(/\//g, '//'))}>/<${encodeURIComponent(selectedDepartmentCategoryLinkName.replace(/\//g, '//'))}>/<${selectedStoreDepartmentId}>/<${selectedDepartmentCategoryId}>`, navigate);
                            loadNavLinkHelper(e, `/shop/${encodeURIComponent(formatLinkString(selectedStoreDepartmentName))}/${encodeURIComponent(formatLinkString(selectedDepartmentCategoryName))}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`, navigate);
                        }



    
                    }
                })
                .catch(err=>console.log('error:', err)); 
            }

            
            //hide all existing mobile dropdowns
            /*
            window.setTimeout(()=>{
                dispatch(showMobileNav({showMobileNav:false, linkSelected:false}));
                dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:false, linkSelected:false}));
                dispatch(addHighlightBackground({highlightBackground:false}));
            },0);
            */


        
        } else {
            //check if the link heading text is "Pharmacy"
            const linkContainerElement = (e.target as HTMLElement).closest('.shop-mobile-dropdown-menu-list-container') as HTMLDivElement;
            //console.log('lce:', linkContainerElement);
            const headingLinkElementText = linkContainerElement.previousElementSibling!.textContent;

            if(headingLinkElementText === 'Pharmacy'){
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                navigate(`/`);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
                
                dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));

                //console.log('test7:');
                closeAllExistingLists(e.target as HTMLElement);

            } else {
                

                helperHighlightCategory(e, selectedDepartmentCategoryName, 'department')
                .then((response)=>response.json())
                .then(({action})=>{
                    if(action === 'added'){
                        //console.log('added');
                        //original version
                        //navigate(`/shop/${selectedStoreDepartmentName}/${selectedDepartmentCategoryName}`);
                        /*
                        let root = document.documentElement;
                        root!.setAttribute('style', 'scroll-behavior: auto');
                        navigate(`/shop/${selectedStoreDepartmentName}/${selectedDepartmentCategoryName}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`);
                        window.setTimeout(()=>{
                            root!.removeAttribute('style');
                        },100);
                        */

                        if(typeof loadNavLink !== 'undefined'){

                            //console.log('loc28:');

                            if(decodeURIComponent(location.pathname) !== `/shop/${formatLinkString(selectedStoreDepartmentName)}/${formatLinkString(selectedDepartmentCategoryName)}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`){
                                loadNavLink!(e, `/shop/${encodeURIComponent(formatLinkString(selectedStoreDepartmentName))}/${encodeURIComponent(formatLinkString(selectedDepartmentCategoryName))}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`);
                            }

                    
                            //closeAllExistingLists(e.target as HTMLElement);
                            dispatch(showMobileNav({showMobileNav:false, linkSelected:true, closeShopByTypeNav:true}));

                        } else {

                            //console.log('loc28:');

                            loadNavLinkHelper(e, `/shop/${encodeURIComponent(formatLinkString(selectedStoreDepartmentName))}/${encodeURIComponent(formatLinkString(selectedDepartmentCategoryName))}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`, navigate);

                            //closeAllExistingLists(e.target as HTMLElement);
                            /*
                            window.setTimeout(()=>{
                                dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));                           
                            },200);
                            */
                            
                        }

                       


                
                       
                       /*
                       handleDropdownSubMenuListItemClick(
                        e, 
                        selectedStoreDepartmentName, 
                        selectedDepartmentCategoryName,
                        selectedStoreDepartmentId,
                        selectedDepartmentCategoryId
                        );
                        */
                        


                    }
                })
                .catch(err=>console.log('error:', err)); 

                //console.log('test7:');


            }

        }


    }

    function handleDropdownMenuListItemLinkEnter(e:React.MouseEvent):void {
        //console.log('e.target:', e.currentTarget);

        if(window.innerWidth >= 1024){
            const parent = ((e.currentTarget as HTMLAnchorElement).parentElement as HTMLLIElement);//.parentElement as HTMLUListElement;
            const lastChild = ((e.currentTarget as HTMLLIElement).firstElementChild as HTMLAnchorElement).lastElementChild as HTMLElement;
            if(lastChild.classList.contains('shop-dropdown-menu-list-item-link-svg')){
                //console.log('has sub list:', true);
                parent.style.borderTopRightRadius = '0';
                parent.style.borderBottomRightRadius = '0';
            } else {
                //console.log('has sub list:', false);
                parent.style.borderRadius = '24px';
            }
        }


    }

    function handleDropdownMenuListItemLinkLeave(e:React.MouseEvent):void {
        const parent = ((e.target as HTMLAnchorElement).parentElement as HTMLLIElement).parentElement as HTMLUListElement;
        //console.log('e.target:', parent);
        parent.style.borderRadius = '24px';
    }


    function handleDropdownMenuListItemClick(
                            e:React.MouseEvent, 
                            selectedStoreDepartmentName:string,
                            selectedStoreDepartmentId:number
                            ):void {

        e.stopPropagation();

        if (e.cancelable){
            e.preventDefault();
        }



        //console.log('pointer:', e.cancelable, 'e.target:', e.target);


        //console.log('ssdn:', selectedStoreDepartmentName); 

        if(window.innerWidth >= 1024){

            const elem = (e.target as HTMLElement).closest('.pharmacy-menu-list-item');

            if(elem !== null){
                
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                navigate(`/`);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);

                //dropdownMenuListContainer.current!.style.display = 'none';


            } else {
                /*
                dispatch(addShopLoadDetails({reload:true,
                                    storeDepartmentName:selectedStoreDepartmentName,
                                    departmentCategoryName:'none',
                                    storeDepartmentId:selectedStoreDepartmentId,
                                    departmentCategoryId:0}));
                                    */

                                    
                //original version
                //navigate(`/shop/${selectedStoreDepartmentName}`);
                //console.log('test24:');
                /*
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                navigate(`/shop/${selectedStoreDepartmentName}/${selectedStoreDepartmentId}`);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
                */

                if(typeof loadNavLink !== 'undefined'){
                    //console.log('loc3:', location.pathname, encodeURIComponent(selectedStoreDepartmentName));

                    if(decodeURIComponent(location.pathname) !== `/shop/${formatLinkString(selectedStoreDepartmentName)}/${selectedStoreDepartmentId}`){
                        //previous version
                        //loadNavLink!(e, `/shop/<${encodeURIComponent(selectedStoreDepartmentName.replace(/\//g, '//'))}>/<${selectedStoreDepartmentId}>`);
                        loadNavLink!(e, `/shop/${encodeURIComponent(formatLinkString(selectedStoreDepartmentName))}/${selectedStoreDepartmentId}`);
                    }

                    //window.setTimeout(()=>{
                        dropdownMenuListContainer.current!.style.display = 'none';
                        const parent = (e.target as HTMLElement).closest('.shop-dropdown-menu-list') as HTMLUListElement;
                        parent.style.borderRadius = '24px';
                    //},0);

                    

                } else {
                    //console.log('loc3:', location.pathname, selectedStoreDepartmentName, encodeURIComponent(selectedStoreDepartmentName.replace(/\//g, '//')));
                    //previous version
                    //loadNavLinkHelper(e, `/shop/<${encodeURIComponent(selectedStoreDepartmentName.replace(/\//g, '//'))}>/<${selectedStoreDepartmentId}>`, navigate);
                    loadNavLinkHelper(e, `/shop/${encodeURIComponent(formatLinkString(selectedStoreDepartmentName))}/${selectedStoreDepartmentId}`, navigate);
                }
               
                

                
                //dropdownMenuListContainer.current!.style.display = 'none';
                


            }

         
            


            
            //hide all existing mobile dropdowns
            /*
            window.setTimeout(()=>{
                dispatch(showMobileNav({showMobileNav:false, linkSelected:false}));
                dispatch(showAdminLoginDropdownMobile({showAdminLoginDropdownMobile:false, linkSelected:false}));
                dispatch(addHighlightBackground({highlightBackground:false}));
            },0);
            */

            

        } else {

            const ele = (e.target as HTMLElement).closest('.shop-mobile-dropdown-menu-list-item-link');

            if(ele !== null && ele.lastElementChild instanceof SVGElement){
                //console.log('has svg:', shopMobileDropdownSubMenuListContainerRefs);
    
                const svg = ele.lastElementChild as SVGElement;
                svg.classList.toggle('rotate-svg-dropdown-arrow');
    
                const menuContainer = ele.nextElementSibling as HTMLDivElement;
    
                menuContainer.classList.toggle('show-smdsmlc');

                //console.log('test7:');
                
            } else {

                //check if the link heading text is "Pharmacy"
                const linkContainerElement = (e.target as HTMLElement).closest('.shop-mobile-dropdown-menu-list-container') as HTMLDivElement;
                const headingLinkElementText = linkContainerElement.previousElementSibling!.textContent;

                if(headingLinkElementText === 'Pharmacy'){
                    
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    navigate(`/`);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));


                    closeAllExistingLists(e.target as HTMLElement);
                    //console.log('test7:');


                } else {
                    
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    navigate(`/shop/${selectedStoreDepartmentName}/${selectedStoreDepartmentId}`);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);
                    

                    dispatch(showMobileNav({showMobileNav:false, linkSelected:true}));

                    //console.log('test7:');
                }


            }
            
            
        }


        //console.log('ssdn:', selectedStoreDepartmentName); 

     
    
    }

    
    function closeAllExistingLists(ele:HTMLElement):void {
        //previous version
        //const t = (ele as HTMLElement).closest('.menu-list-mobile') as HTMLUListElement;
        const t = (ele as HTMLElement).closest('.menu-list-mobile-nav') as HTMLUListElement;
        //console.log('test7:', t, t.getElementsByClassName('show-smdsmlc'));
        const tels = Array.from(t.getElementsByClassName('show-smdsmlc'));
        tels.forEach((tel)=>{
            tel.classList.remove('show-smdsmlc');
            const ae = tel.previousElementSibling as HTMLAnchorElement;
            const aeChildSVG = Array.from(ae.getElementsByClassName('rotate-svg-dropdown-arrow'))[0] as SVGElement;
            aeChildSVG.classList.remove('rotate-svg-dropdown-arrow');
        });
    }

   

    function handleDropdownSubMenuListItemClick(
                                    e:React.MouseEvent, 
                                    selectedStoreDepartmentName:string, 
                                    selectedDepartmentCategoryName:string,
                                    selectedStoreDepartmentId:number,
                                    selectedDepartmentCategoryId:number
                                    ):void {
        //e.stopPropagation();
        
        //console.log('shop dropdown menu list item clicked');
        if(window.innerWidth >= 1024){
            //window.setTimeout(()=>{
                dropdownMenuListContainer.current!.style.display = 'none';
                const parent = (e.target as HTMLElement).closest('.shop-dropdown-menu-list') as HTMLUListElement;
                parent.style.borderRadius = '24px';

            //},0);

        }
        

                            
    }


    function getShopMobileDropdownSubMenuListContainerRef(el:HTMLDivElement|null):void {
        //console.log('el:');
        if(el !== null && shopMobileDropdownSubMenuListContainerRefs.current.length === 0){
            shopMobileDropdownSubMenuListContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = shopMobileDropdownSubMenuListContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                shopMobileDropdownSubMenuListContainerRefs.current.push(el);
            }

        }

        //console.log('cscref:', categoryShifterContainerRefs.current);      
        
    }
    

    function loadShopCategories(shopCategories:ShopCategory[]):JSX.Element[] {
        //console.log('scs:', shopCategories);
        return shopCategories.map((shopCategory, index)=>{
                return <li key={index} 
                            className={`${dropdownMenuListHeading}-dropdown-menu-list-item`}
                            onMouseEnter={handleDropdownMenuListItemLinkEnter}
                            onMouseLeave={handleDropdownMenuListItemLinkLeave}>
                            <Link className={`${dropdownMenuListHeading}-dropdown-menu-list-item-link`} to={`${shopCategory.shopCategoryLink}/${shopCategory.shopCategoryNameId}`}
                                onClick={(e:React.MouseEvent)=>handleDropdownMenuListItemClick(
                                                                                                            e, 
                                                                                                            shopCategory.shopCategoryName,
                                                                                                            shopCategory.shopCategoryNameId
                                                                                                            )}>
                                <span className={`${dropdownMenuListHeading}-dropdown-menu-list-item-name`}>
                                    {shopCategory.shopCategoryName}
                                </span>
                                {
                                    shopCategory.subShopCategories.length > 0 
                                    ?
                                    <svg className="shop-mobile-dropdown-menu-list-item-link-svg shop-dropdown-menu-list-item-link-svg" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"></path></svg>
                                    
                                    : null
                                }
                            {/*<i className="ml-2 fa fa-angle-right" aria-hidden="true"></i> */}
                            {/*<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"></path></svg>*/}
                            </Link>
                            {shopCategory.subShopCategories.length > 0 ?
                                <div ref={getShopMobileDropdownSubMenuListContainerRef} className={`${dropdownMenuListHeading}-dropdown-sub-menu-list-container`}>
                                    {(dropdownMenuListHeading === 'shop') ? 
                                        <h5 className={`${dropdownMenuListHeading}-dropdown-sub-menu-list-heading`}>{shopCategory.shopCategoryName}</h5>
                                        : null
                                    }
                                    <ul className={`${dropdownMenuListHeading}-dropdown-sub-menu-list`}>
                                        {shopCategory.subShopCategories.map((subShopCategory, index)=>{
                                            return <li className={`${dropdownMenuListHeading}-dropdown-sub-menu-list-item`} key={index}>
                                                        <Link className={`${dropdownMenuListHeading}-dropdown-sub-menu-list-item-link`} 
                                                            to={`${subShopCategory.subShopCategoryLink}/${subShopCategory.shopCategoryNameId}/${subShopCategory.subShopCategoryNameId}`}
                                                            onClick={(e:React.MouseEvent)=>
                                                                highlightCategoryText(
                                                                    e,
                                                                    shopCategory.shopCategoryName,
                                                                    subShopCategory.subShopCategoryName,
                                                                    subShopCategory.subShopCategoryNameId,
                                                                    subShopCategory.shopCategoryNameId
                                                                )}>
                                                            {subShopCategory.subShopCategoryName}
                                                        </Link>
                                                    </li>
                                        })}
                                    </ul>
                                </div> : null
                            }
                        </li>
            });
    }
    

    return (
            <div ref={dropdownMenuListContainer} className={`${dropdownMenuListHeading}-dropdown-menu-list-container`}>
                <ul className={`${dropdownMenuListHeading}-dropdown-menu-list`}>
                    {loadShopCategories(dropdownCategories)}
                </ul>
            </div>
            );
});

export default DropdownMenuCategories;

/*previous connect() version
export default connect(
    null,
    mapDispatchToProps
)(DropdownMenuCategories);
*/