import Product from './product';
import ProductSubCategory from './productSubCategory';
import SearchFilter from './searchFilter';

export default class ProductCategory {
    productCategoryId?:number;
    productCategoryName:string;
    productCategoryLinkName:string;
    productCategoryImage:string;
    productSubCategories:ProductSubCategory[];
    departmentCategoryName:string;
    departmentName:string;
    products:Product[];
    /*isSpecialProductCategory:boolean;
    showProductCategorySidebar:boolean;
    showProductCategoryShortcut:boolean;*/
    showProductSubCategoryShortcut:boolean;
    showRelatedProductCategoryShortcut:boolean;
    showProductCategoryNameSearch:boolean;
    showProductCategoryKeywordSearch:boolean;
    highlightText:boolean;
    searchFilters:SearchFilter[];
    productCategoryDateAdded:Date;

    constructor(options:{
        productCategoryId?:number,
        productCategoryName?:string,
        productCategoryLinkName?:string,
        productCategoryImage?:string,
        productSubCategories?:ProductSubCategory[],
        departmentCategoryName?:string,
        departmentName?:string,
        products?:Product[],
        /*isSpecialProductCategory?:boolean,
        showProductCategorySidebar?:boolean,
        showProductCategoryShortcut?:boolean,*/
        showProductSubCategoryShortcut?:boolean,
        showRelatedProductCategoryShortcut?:boolean,
        showProductCategoryNameSearch?:boolean,
        showProductCategoryKeywordSearch?:boolean,
        highlightText?:boolean,
        searchFilters?:SearchFilter[],
        productCategoryDateAdded?:Date
    }={}){
        this.productCategoryId = options.productCategoryId || 0;
        this.productCategoryName = options.productCategoryName || '';
        this.productCategoryLinkName = options.productCategoryLinkName || '';
        this.productCategoryImage = options.productCategoryImage || '';
        this.productSubCategories = options.productSubCategories || [];
        this.departmentCategoryName = options.departmentCategoryName || '';
        this.departmentName = options.departmentName || '';
        this.products = options.products || [];
        /*this.isSpecialProductCategory = options.isSpecialProductCategory || false;
        this.showProductCategorySidebar = options.showProductCategorySidebar || false;
        this.showProductCategoryShortcut = options.showProductCategoryShortcut || false;*/
        this.showProductSubCategoryShortcut = options.showProductSubCategoryShortcut || false;
        this.showRelatedProductCategoryShortcut = options.showRelatedProductCategoryShortcut || false;
        this.showProductCategoryNameSearch = options.showProductCategoryNameSearch || false;
        this.showProductCategoryKeywordSearch = options.showProductCategoryKeywordSearch || false;
        this.highlightText = options.highlightText || false;
        this.searchFilters = options.searchFilters || [];
        this.productCategoryDateAdded = options.productCategoryDateAdded || new Date();
    }
}