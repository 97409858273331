import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './AddShopProductRating.css';
import Review from './review';
import {addReviews} from './helper-add-reviews';
import addProductReview from './helper-add-product-review';
import DbResponse from './db-response';
import Product from './product';
import StoreStructureAlert from './StoreStructureAlert';


interface Props {
    userName:string;
    /* original version
    productCategoryName:string;
    productName:string;
    */
   productSelected:Product;
}

const AddShopProductRating:React.FunctionComponent<Props> = (props:Props)=>{
    const [userName, setUserName] = useState(props.userName);
    const [starItemSelected, setStarItemSelected] = useState(0);
    const starItemList = useRef<HTMLUListElement>(null);
    const [starItemMouseEnter, setStarItemMouseEnter] = useState(false);
    const [starItemMouseLeave, setStarItemMouseLeave] = useState(false);
    const [starItemHover, setStarItemHover] = useState(0);
    const [starItemClicked, setStarItemClicked] = useState(false);
    const [productRatingTitle, setProductRatingTitle] = useState('Example: Best Product Ever');
    const [defaultProductRatingTitle, setDefaultProductRatingTitle] = useState('Example: Best Product Ever');
    const [productRatingComment, setProductRatingComment] = useState('Enter your review here.');
    const [defaultProductRatingComment, setDefaultProductRatingComment] = useState('Enter your review here.');
    const [productRating, setProductRating] = useState(0);
    //original version
    //const [productCategoryName, setProductCategoryName] = useState(props.productCategoryName);
    //const [productName, setProductName] = useState(props.productName);
    const [productSelected, setProductSelected] = useState<Product>(props.productSelected);
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('');

    const [showProductRatingCommentErrorMsg, setShowProductRatingCommentErrorMsg] = useState(false);
    const [showProductRatingTitleErrorMsg, setShowProductRatingTitleErrorMsg] = useState(false);
    const [showProductRatingErrorMsg, setShowProductRatingErrorMsg] = useState(false);
    const [showProductRatingCommentErrorText, setShowProductRatingCommentErrorText] = useState(false);
    const [productRatingCommentErrorMsg, setProductRatingCommentErrorMsg] = useState('');
    const [productRatingCommentErrorText, setProductRatingCommentErrorText] = useState('');
    const [productRatingTitleErrorMsg, setProductRatingTitleErrorMsg] = useState('');
    const [productRatingErrorMsg, setProductRatingErrorMsg] = useState('');

    const questionRef = useRef<HTMLInputElement | null>(null);
    const commentRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(()=>{
        //console.log('username changed:', props.userName);
        setUserName(props.userName);
    },[props.userName]);

    function handleStarItemSelected(e:React.MouseEvent):void {
        //console.log('handleStarItemSelected:', Number((e.target as HTMLLIElement).title));
        const productRatingSelected:number = Number((e.target as HTMLLIElement).title);
        setStarItemSelected(productRatingSelected);
        setStarItemClicked(true);
        setProductRating(productRatingSelected);
    }

    function handleStarItemMouseEnter(e:React.MouseEvent):void {
        //console.log('element:', (e.target as HTMLElement).title);
        const itemNumber:number = Number((e.target as HTMLElement).title);
        setStarItemHover(itemNumber);
        setStarItemMouseLeave(false);
        setStarItemMouseEnter(true);        
    }

    function handleStarItemMouseLeave(e:React.MouseEvent):void {
        setStarItemMouseEnter(false);
        setStarItemMouseLeave(true)
        setStarItemHover(0);

    }

    function setupShopProductRating(starItemSelected:number, starItemHover:number, starItemClicked:boolean, starItemMouseEnter:boolean, starItemMouseLeave:boolean):JSX.Element[] {
        
        let starItems:JSX.Element[] = [];
        let fullStarColor:JSX.Element = <i className="fas fa-star add-shop-product-rating-star-color change-product-rating-color"></i>;
        let fullStarNoColor:JSX.Element = <i className="fas fa-star add-shop-product-rating-star-no-color change-product-rating-color"></i>;

        if(starItemSelected > 0 && starItemSelected < 5 && starItemClicked && starItemMouseEnter && !starItemMouseLeave){
            for(let i=1; i < 6; i++){
                if(i <= starItemSelected){
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                    onMouseLeave={handleStarItemMouseLeave} 
                    className="fas fa-star add-shop-product-rating-star-color change-product-rating-color"></i>);
                
                } else if(i === starItemHover){
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                    onMouseLeave={handleStarItemMouseLeave} 
                    className="fas fa-star add-shop-product-rating-star-color change-product-rating-color"></i>);
                } else {
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                    onMouseLeave={handleStarItemMouseLeave} 
                    className="fas fa-star add-shop-product-rating-star-no-color change-product-rating-color"></i>);
                }

            }
        
        //if all stars all clicked
        } else if(starItemSelected > 0 && starItemSelected < 5 && starItemClicked && !starItemMouseEnter && starItemMouseLeave){
            for(let i=1; i < 6; i++){
                if(i <= starItemSelected){
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                    onMouseLeave={handleStarItemMouseLeave} 
                    className="fas fa-star add-shop-product-rating-star-color change-product-rating-color"></i>);
                
                } else if(i === starItemHover){
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                    onMouseLeave={handleStarItemMouseLeave} 
                    className="fas fa-star add-shop-product-rating-star-color change-product-rating-color"></i>);
                } else {
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                    onMouseLeave={handleStarItemMouseLeave} 
                    className="fas fa-star add-shop-product-rating-star-no-color change-product-rating-color"></i>);
                }

            }
        
        //if all stars all clicked
        } else if(starItemSelected === 5 && starItemClicked/* && starItemMouseEnter && !starItemMouseLeave*/){
            for(let i=0; i < 5; i++){
                starItems.push(<i title={(i + 1).toString()} onMouseEnter={handleStarItemMouseEnter}
                                onMouseLeave={handleStarItemMouseLeave} 
                                className="fas fa-star add-shop-product-rating-star-color change-product-rating-color"></i>);
            }

        } else if(starItemSelected === 0 && starItemHover === 0 && !starItemClicked && !starItemMouseEnter && !starItemMouseLeave){
            
            for(let i=0; i < 5; i++){
                starItems.push(<i title={(i + 1).toString()} onMouseEnter={handleStarItemMouseEnter}
                                onMouseLeave={handleStarItemMouseLeave} 
                                className="fas fa-star add-shop-product-rating-star-no-color change-product-rating-color"></i>);
            }
        
        } else if(starItemSelected === 0 && starItemHover === 0 && !starItemClicked && !starItemMouseEnter && starItemMouseLeave){
            for(let i=1; i < 6; i++){
                starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                                onMouseLeave={handleStarItemMouseLeave} 
                                className="fas fa-star add-shop-product-rating-star-no-color change-product-rating-color"></i>);
            }   

        } else if(starItemSelected === 0 && starItemHover > 0 && !starItemClicked && starItemMouseEnter && !starItemMouseLeave){
            for(let i=1; i < 6; i++){
                if(i === starItemHover){
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                                        onMouseLeave={handleStarItemMouseLeave} 
                                        className="fas fa-star add-shop-product-rating-star-color change-product-rating-color"></i>);
                } else {
                    starItems.push(<i title={(i).toString()} onMouseEnter={handleStarItemMouseEnter}
                                    onMouseLeave={handleStarItemMouseLeave} 
                                    className="fas fa-star add-shop-product-rating-star-no-color change-product-rating-color"></i>)
                }
                
            }            
        }

        return starItems.map((starItem, index)=>{

            return <li key={index} title={(index + 1).toString()} 
                    className="add-shop-product-rating-list-item" 
                    onClick={handleStarItemSelected}>{starItem}</li>
        });

    }

    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        setShowStoreStructureAlert(false);
    }

    function checkProductRatingTitleErrors(productRatingTitle:string):boolean {
        if(productRatingTitle === defaultProductRatingTitle
            || productRatingTitle === ''){
            setShowProductRatingTitleErrorMsg(true);
            setProductRatingTitleErrorMsg('product-rating-title-error-msg');
            return true;

        } else {
            setShowProductRatingTitleErrorMsg(false);
            setProductRatingTitleErrorMsg('');
            return false;
        }
    }


    function checkProductRatingCommentErrors(productRatingComment:string):boolean {
        if(productRatingComment === defaultProductRatingComment
            || productRatingComment === ''){
            setShowProductRatingCommentErrorText(false);
            setProductRatingCommentErrorText('');
            setShowProductRatingCommentErrorMsg(true);
            setProductRatingCommentErrorMsg('product-rating-comment-error-msg');
            return true;         
        } else if(productRatingComment.length < 20){
            setShowProductRatingCommentErrorMsg(false);
            setShowProductRatingCommentErrorText(true);
            setProductRatingCommentErrorText('product-rating-comment-error-text');
            return true;
        } else {
            setShowProductRatingCommentErrorMsg(false);
            setProductRatingCommentErrorMsg('');
            setShowProductRatingCommentErrorText(false);
            setProductRatingCommentErrorText('');
            return false;
        }
    }

    function checkProductRatingErrors(productRating:number):boolean {
        if(productRating == 0){
            setShowProductRatingErrorMsg(true);
            setProductRatingErrorMsg('product-rating-error-msg');
            return true;
        
        } else {
            setShowProductRatingErrorMsg(false);
            setProductRatingErrorMsg('');
            return false;
        }
    }

    function handleProductRatingSubmit(e:React.FormEvent):void {
        e.preventDefault();
        //console.log('title:', productRatingTitle);
        //console.log('comment:', productRatingComment);
        //console.log('rating:', productRating);
        //check if form inputs completed
        const prtHasErrors = checkProductRatingTitleErrors(productRatingTitle)
        const prcHasErrors = checkProductRatingCommentErrors(productRatingComment);
        const prHasErrors = checkProductRatingErrors(productRating);

        if(prtHasErrors || prcHasErrors || prHasErrors){
            return;
        }

        const productReview = new Review({
            productId:productSelected.productId,
            userName:userName,
            title:productRatingTitle,
            comment:productRatingComment,
            rating:productRating,
            productName:productSelected.productName,
            productCategoryName:productSelected.productCategoryName
        });

        addDatabaseProductReview(productReview, productSelected.productName);
    }

    function addDatabaseProductReview(productReview:Review, productName:string):void {
        //original version
        /*fetch(`http://localhost:9500/all-product-reviews/${productCategoryName}/${productName}`, {
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=> res.json())
        .then((reviews:Review[])=>{
            console.log('reviews test:', reviews);
            const productReviews = addReviews(reviews);
            console.log('productReviews:', productReviews);
            productReview.reviewId = getProductReviewId(productReviews);*/
            //test version
            addProductReview(productReview)
            .then(res=>res.json())
            .then(({dbResponseMsg}:DbResponse)=>{
                if(dbResponseMsg === 'record added'){
                    //console.log('product review addded');
                    setStoreStructureAlertMsg(`
                    Thank you for reviewing ${productName}.
                    We post all reviews that meet are company guidelines within 48 hours.`);
                    setShowStoreStructureAlert(true);
                    //clear star rating parameters
                    setStarItemSelected(0);
                    setStarItemHover(0);
                    setStarItemClicked(false);
                    setStarItemMouseEnter(false);
                    setStarItemMouseLeave(false)
                    //clear form inputs
                    setProductRatingTitle('');
                    setProductRatingComment('');
                    setProductRating(0);

                    //close alert message
                    /*
                    window.setTimeout(()=>{
                        setShowStoreStructureAlert(false);
                    },5000);
                    */
                    
                }
            })
            .catch(err=>console.log('error:', err));
        /*})
        .catch(err=>console.log('reviews error:', err));*/

    }

    function getProductReviewId(productReviews:Review[]):number {
        function compareProductReviewIds(a:number, b:number) {
            return a - b;
        }

        if(productReviews.length === 0){
            return 1;
        } else {
            let productReviewIds:number[] = [];
            let lastProductReviewId:number;
            for(let i=0; i < productReviews.length; i++){
                productReviewIds.push(productReviews[i].reviewId)
            }
            //console.log('productReviewIds before:', productReviewIds);
            productReviewIds.sort(compareProductReviewIds);
            //console.log('productReviewIds after:', productReviewIds);
            lastProductReviewId = (productReviewIds.pop() as number);
            return ++lastProductReviewId;
        }

    }

    function handleProductRatingTitle(e:React.ChangeEvent):void {
        const title:string = (e.target as HTMLInputElement).value;
        setProductRatingTitle(title);
    }

    function handleRatingQuestionFocus(e:React.FocusEvent):void {
        //console.log('value:', (e.target as HTMLInputElement).value);
        //console.log('prt:', productRatingTitle);
        if((e.target as HTMLInputElement).value === defaultProductRatingTitle){
            (questionRef.current as HTMLInputElement).classList.add('darkText');
            setProductRatingTitle('');
        }
    }

    function handleRatingQuestionBlur(e:React.FocusEvent):void {
        //console.log('value:', (e.target as HTMLInputElement).value);
        if((e.target as HTMLInputElement).value === ''){
            (questionRef.current as HTMLInputElement).classList.remove('darkText');
            setProductRatingTitle(defaultProductRatingTitle);
        }
    }

    function handleProductRatingComment(e:React.ChangeEvent):void {
        const comment:string = (e.target as HTMLTextAreaElement).value;
        setProductRatingComment(comment);
    }

    function handleRatingCommentFocus(e:React.FocusEvent):void {
        //console.log('value:', (e.target as HTMLInputElement).value);
        //console.log('prt:', productRatingComment);
        if((e.target as HTMLInputElement).value === defaultProductRatingComment){
            (commentRef.current as HTMLTextAreaElement).classList.add('darkText');
            setProductRatingComment('');
        }
    }

    function handleRatingCommentBlur(e:React.FocusEvent):void {
        //console.log('value:', (e.target as HTMLInputElement).value);
        if((e.target as HTMLInputElement).value === ''){
            (commentRef.current as HTMLTextAreaElement).classList.remove('darkText');
            setProductRatingComment(defaultProductRatingComment);
        }
    }

    //showStoreStructureAlert={showStoreStructureAlert}
    //storeStructureAlertMsg={storeStructureAlertMsg}

    if(userName.length > 0){
        return <div className="add-shop-product-rating-container">

                    <StoreStructureAlert 
                        showStoreStructureAlert={showStoreStructureAlert}
                        storeStructureAlertMsg={storeStructureAlertMsg}
                        handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />

                    <form onSubmit={handleProductRatingSubmit}>
                        <div className="add-shop-product-rating-question">
                            How would you rate this?
                        </div>
                        <ul ref={starItemList} className={`add-shop-product-rating-list ${productRatingErrorMsg}`}>
                            {setupShopProductRating(starItemSelected, starItemHover, starItemClicked, starItemMouseEnter, starItemMouseLeave)}
                        </ul>
                        {showProductRatingErrorMsg 
                         ? <div className="product-rating-error-msg-dropdown">
                                Please rate the product
                           </div>
                         : null
                        }
                        <label className="add-shop-product-rating-question" htmlFor="add-shop-product-rating-form-control">Enter Review Title</label>
                        <input ref={questionRef} type="text" id="add-shop-product-rating-form-control" className={`add-shop-product-rating-form-control ${productRatingTitleErrorMsg}`}
                                onFocus={handleRatingQuestionFocus}
                                onBlur={handleRatingQuestionBlur}
                                onChange={handleProductRatingTitle} 
                                value={productRatingTitle}/>
                        {showProductRatingTitleErrorMsg 
                         ? <div className="product-rating-title-error-msg-dropdown">
                                Please enter a review title
                           </div>
                         : null
                        }
                        <label className="add-shop-product-rating-question" htmlFor="add-shop-product-rating-text">What do you think?</label>
                        <textarea ref={commentRef} style={{resize:'none', paddingTop:'15px'}} rows={2} id="add-shop-product-rating-text"
                            className={`add-shop-product-rating-form-control add-shop-product-rating-text ${productRatingCommentErrorMsg}`} 
                            onFocus={handleRatingCommentFocus}
                            onBlur={handleRatingCommentBlur}
                            onChange={handleProductRatingComment} 
                            value={productRatingComment}/>
                        {(showProductRatingCommentErrorMsg && !showProductRatingCommentErrorText)
                         ? <div className="product-rating-comment-error-msg-dropdown">
                                Please enter a review
                           </div>
                         : null
                        }
                        <div className={`add-shop-product-rating-help-text ${productRatingCommentErrorText}`}>Review text must be at least 20 characters.</div>
                        <button type="submit" className="col-xl-5 col-lg-5 add-shop-product-rating-submit-btn">Submit</button>
                    </form>
                </div>

    } else {
        return null;
    }
    
}

export default AddShopProductRating;