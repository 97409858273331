import React, {useEffect, useRef, useState} from 'react';
import './PriceSlider.css';
import RangeParams from './rangeParams';

declare let document: Document;
declare let window: Window;

let rp:RangeParams = {};

interface Props{
    keywordSearch:string;
    startSliderAmount:number;
    endSliderAmount:number;
    previousSlider1PositionLeft:string;
    previousSlider2PositionLeft:string;
    previousSlider1PostionLeftAmount:number;
    previousSlider2PostionLeftAmount:number;
    sliderPosition:number;
    previousSlider1TotalDistance:number;
    previousSlider2TotalDistance:number;
    slider1ZIndex:number;
    slider2ZIndex:number;
    sortByOptionValue:string;
    priceSliderSearchResults:(
                            keywordSearch:string,
                            startSliderAmount:number,
                            endSliderAmount:number,
                            previousSlider1PositionLeft:string,
                            previousSlider2PositionLeft:string,
                            previousSlider1PostionLeftAmount:number,
                            previousSlider2PostionLeftAmount:number,
                            sliderPosition:number,
                            previousSlider1TotalDistance:number,
                            previousSlider2TotalDistance:number,
                            slider1ZIndex:number,
                            slider2ZIndex:number,
                            sortByOptionValue:string
                            )=>void;
}

const PriceSlider3:React.FunctionComponent<Props> = (props:Props)=>{
    const {priceSliderSearchResults} = props;

    const slider1 = useRef<HTMLButtonElement | null>(null);
    const slider2 = useRef<HTMLButtonElement | null>(null);
    const sliderBtnContainer = useRef<HTMLDivElement | null>(null);
    const sliderContainer = useRef<HTMLDivElement | null>(null);
    const sliderBtnAmountContainer = useRef<HTMLDivElement | null>(null);
    const slider1Amount = useRef<HTMLInputElement | null>(null);
    const slider2Amount = useRef<HTMLInputElement | null>(null);
    const [sliderBtnContainerWidth, setSliderBtnContainerWidth] = useState(0);
    const [sliderBtnContainerLeft, setSliderBtnContainerLeft] = useState(0);
    const [sliderWidth, setSliderWidth] = useState(0);
    const [adjustedEnd, setAdjustedEnd] = useState(0);
    const [start, setStart] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(props.sliderPosition);//useState(0);
    const [currentPointerPosition, setCurrentPointerPosition] = useState(0);
    const [endpointReached, setEndpointReached] = useState(false);
    const [startpointReached, setStartpointReached] = useState(false);
    const [totalDistance, setTotalDistance] = useState(0);
    const [slidersMatch, setSlidersMatch] = useState(false);
    const [startSliderAmount, setStartSliderAmount] = useState(props.startSliderAmount);
    const [endSliderAmount, setEndSliderAmount] = useState(props.endSliderAmount);
    const [sliderDistancePerIncrease, setSliderDistancePerIncrease] = useState(0);
    const [increaseAmountBy, setIncreaseAmountBy] = useState(props.startSliderAmount);
    const [percentIncreaseBy, setPercentIncreaseBy] = useState(0);
    const [slider1AmountInitialValue, setSlider1AmountInitialValue] = useState(props.previousSlider1PostionLeftAmount.toFixed(2)/* === 0 ? props.startSliderAmount.toFixed(2) : props.previousSlider1PostionLeftAmount.toFixed(2)*/);
    const [slider2AmountInitialValue, setSlider2AmountInitialValue] = useState(props.previousSlider2PostionLeftAmount.toFixed(2)/* === 0 ? props.endSliderAmount.toFixed(2) : props.previousSlider2PostionLeftAmount.toFixed(2)*/);
    //original version
    //const [previousSlider1Position, setPreviousSlider1Position] = useState(props.startSliderAmount);
    const [previousSlider1Position, setPreviousSlider1Position] = useState(0);
    const [previousSlider2Position, setPreviousSlider2Position] = useState(0);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(props.previousSlider1TotalDistance);//useState(0);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(props.previousSlider2TotalDistance);//useState(props.endSliderAmount);
    const [shiftX, setShiftX] = useState(0);
    const [eventType, setEventType] = useState('none');
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState(props.previousSlider1PositionLeft);
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState(props.previousSlider2PositionLeft);

    const [keywordSearch, setKeywordSearch] = useState(props.keywordSearch); 
    
    const [slider1ZIndex, setSlider1ZIndex] = useState(props.slider1ZIndex);
    const [slider2ZIndex, setSlider2ZIndex] = useState(props.slider2ZIndex);

    const sbov = useRef<string>(props.sortByOptionValue);

    
    useEffect(()=>{
        sbov.current = props.sortByOptionValue;
    },[props.sortByOptionValue]);
    


    //console.log('props.ssa:', props.startSliderAmount);
    //console.log('props.esa:', props.endSliderAmount);

    /*
    useEffect(()=>{
        setPreviousSlider1PositionLeft(props.previousSlider1PositionLeft)
    },[props.previousSlider1PositionLeft]);

    useEffect(()=>{
        setPreviousSlider2PositionLeft(props.previousSlider2PositionLeft)
    },[props.previousSlider2PositionLeft]);

    useEffect(()=>{
        setSlider1AmountInitialValue(props.previousSlider1PostionLeftAmount.toFixed(2));
        console.log('slider1AmountInitialValue:', slider1AmountInitialValue);
        console.log('previousSlider1PostionLeftAmount:', props.previousSlider1PostionLeftAmount);
        console.log('values equal:', parseFloat(slider1AmountInitialValue) === props.previousSlider1PostionLeftAmount);
    },[props.previousSlider1PostionLeftAmount]);

    useEffect(()=>{
        setSlider2AmountInitialValue(props.previousSlider2PostionLeftAmount.toFixed(2));
    },[props.previousSlider2PostionLeftAmount]);

    
    useEffect(()=>{
        //console.log('start:', props.startSliderAmount);
        setStartSliderAmount(props.startSliderAmount);
    },[props.startSliderAmount]);

    useEffect(()=>{
        //console.log('end:', props.endSliderAmount);
        setEndSliderAmount(props.endSliderAmount);
    },[props.endSliderAmount]);
    */ 
   
    /*
    useEffect(()=>{
        setSlider1ZIndex(props.slider1ZIndex);
    },[props.slider1ZIndex]);

    useEffect(()=>{
        setSlider2ZIndex(props.slider2ZIndex);
    },[props.slider2ZIndex]);
    */

    useEffect(()=>{
        //console.log('PriceSlider3 setup');
        //console.log('start:', props.startSliderAmount);
        //console.log('end:', props.endSliderAmount);
        //console.log('sbc:', sliderBtnContainer.current!.offsetWidth);

        rp.slider1 = (slider1 as React.RefObject<HTMLButtonElement>).current as HTMLButtonElement;
        rp.slider2 = (slider2 as React.RefObject<HTMLButtonElement>).current as HTMLButtonElement;
        rp.sliderBtnContainer = (sliderBtnContainer as React.RefObject<HTMLDivElement>).current as HTMLDivElement;
        rp.sliderContainer = (sliderContainer as React.RefObject<HTMLDivElement>).current as HTMLDivElement;
        rp.sliderBtnAmountContainer = (sliderBtnAmountContainer as React.RefObject<HTMLDivElement>).current as HTMLDivElement;
        rp.slider1Amount = (slider1Amount as React.RefObject<HTMLInputElement>).current as HTMLInputElement;
        rp.slider2Amount = (slider2Amount as React.RefObject<HTMLInputElement>).current as HTMLInputElement;
        rp.sliderBtnContainerLeft = rp.sliderBtnContainer.getBoundingClientRect().left;
        rp.sliderBtnContainerWidth = 204;//rp.sliderBtnContainer.getBoundingClientRect().width;
        rp.sliderWidth = rp.slider1.getBoundingClientRect().width;
        rp.adjustedEnd = rp.sliderBtnContainerWidth;
        rp.endSliderAmount = calcSliderAmount(endSliderAmount)//parseInt(endSliderAmount.toString().slice(0,-2), 10);
        rp.startSliderAmount = calcSliderAmount(startSliderAmount)//parseInt(startSliderAmount.toString().slice(0,-2), 10);
        //console.log('test end:', calcSliderAmount(endSliderAmount));
        //console.log('test start:', calcSliderAmount(startSliderAmount));
        //console.log('test sdpi:', (rp.adjustedEnd as number / 100) * (100 / (rp.endSliderAmount! - (rp.startSliderAmount as number))));
        //console.log('test pib:', parseFloat((((rp.adjustedEnd / (rp.endSliderAmount! - rp.startSliderAmount!)) / rp.adjustedEnd) * 100).toFixed(3)));
        rp.sliderDistancePerIncrease = (rp.adjustedEnd as number / 100) * (100 / (rp.endSliderAmount! - (rp.startSliderAmount as number)));
        rp.percentIncreaseBy = parseFloat((((rp.adjustedEnd / (rp.endSliderAmount! - rp.startSliderAmount!)) / rp.adjustedEnd) * 100).toFixed(3));
        //rp.percentIncreaseBy = percentIncreaseBy;
        rp.sliderAmountCents = getSliderAmountCents(startSliderAmount);
        //console.log('sacs:', rp.sliderAmountCents);
        rp.increaseAmountBy = rp.startSliderAmount;
        rp.start = start;
        rp.sliderPosition = sliderPosition;
        rp.currentPointerPosition = currentPointerPosition;
        rp.endpointReached = endpointReached;
        rp.startpointReached = startpointReached;
        rp.totalDistance = totalDistance;
        rp.slidersMatch = slidersMatch;
        rp.slider1AmountInitialValue = slider1AmountInitialValue;
        rp.slider2AmountInitialValue = slider2AmountInitialValue;
        rp.slider1Amount.value = `$${rp.slider1AmountInitialValue}`;
        rp.slider2Amount.value = `$${rp.slider2AmountInitialValue}`;
        //original version
        //rp.previousSlider1Position = previousSlider1Position;
        rp.previousSlider1Position = parseInt((parseFloat(previousSlider1PositionLeft)/100 * rp.adjustedEnd).toString());
        //original version
        //rp.previousSlider2Position = rp.adjustedEnd;
        rp.previousSlider2Position = parseInt((parseFloat(previousSlider2PositionLeft)/100 * rp.adjustedEnd).toString());

        rp.previousSlider1TotalDistance = previousSlider1TotalDistance;
        rp.previousSlider2TotalDistance = (previousSlider2TotalDistance === 100) ? (rp.adjustedEnd / rp.sliderDistancePerIncrease) : previousSlider2TotalDistance;
        /*rp.previousSlider1PositionLeft = previousSlider1PositionLeft;
        rp.previousSlider2PositionLeft = previousSlider2PositionLeft;*/
        rp.slider1.style.left = previousSlider1PositionLeft;
        rp.slider2.style.left = previousSlider2PositionLeft;
        rp.shiftX = shiftX;
        rp.eventType = eventType;

        rp.slider1ZIndex = slider1ZIndex;
        rp.slider2ZIndex = slider2ZIndex;

        //console.log('dpi:', rp.sliderDistancePerIncrease);
        //console.log('sbcl:', rp.sliderBtnContainerLeft);
        //console.log('sbcw:', rp.sliderBtnContainerWidth);
        //console.log('adjustedend:', rp.adjustedEnd);
        //console.log('sliderWidth:', rp.sliderWidth);
        //console.log('startSliderAmount:', rp.startSliderAmount);
        //console.log('endSliderAmount:', rp.endSliderAmount);
        //console.log('keywordSearch:', keywordSearch);
        //console.log('endSliderAmount:', endSliderAmount);
        //console.log('startSliderAmount:', startSliderAmount);
        //console.log('percentIncreaseBy:', rp.percentIncreaseBy);
        //console.log('slider1PositionLeft:', rp.slider1.style.left!);
        //console.log('slider2PositionLeft:', rp.slider2.style.left!);
        //console.log('rp.previousSlider1TotalDistance:', rp.previousSlider1TotalDistance);
        //console.log('rp.previousSlider2TotalDistance:', rp.previousSlider2TotalDistance);

        //console.log('previousSlider1PositionLeft:', parseFloat(previousSlider1PositionLeft)/100 * rp.adjustedEnd);
        //console.log('previousSlider2PositionLeft:', parseInt((parseFloat(previousSlider2PositionLeft)/100 * rp.adjustedEnd).toString()));
        //console.log('previousSlider2PositionLeft:', previousSlider2PositionLeft);
        //console.log('rp.slider1ZIndex:', rp.slider1ZIndex);
        //console.log('rp.slider2ZIndex:', rp.slider2ZIndex);
        //console.log('td23:', rp.totalDistance, rp.previousSlider2TotalDistance, rp.sliderPosition, rp.previousSlider2Position, rp.adjustedEnd);

        rp.sliderBtnContainer.style.backgroundColor = '#009FCE';
        rp.slider1.style.backgroundColor = '#009FCE';
        rp.slider2.style.backgroundColor = '#009FCE';

        rp.slider1!.style.zIndex! = `${rp.slider1ZIndex}`;
        rp.slider2!.style.zIndex! = `${rp.slider2ZIndex}`;

        /*
        if(rp.percentIncreaseBy! === Infinity){
            return;
        }
        */

        //don't load slider if start and end amounts are the same
        /*
        if(rp.startSliderAmount === rp.endSliderAmount){
            return;
        }
        */

        //rp.slider1Amount.value = rp.startSliderAmount.toString();
        //rp.slider2Amount.value = rp.endSliderAmount.toString();
            setupSlider(
                rp.slider1,
                rp.slider2//,
                //rp.sliderBtnContainerLeft
            );
            
    },[]);

    useEffect(()=>{
        return function removePriceSliderEventListeners(){
            //console.log('priceSlider3 removePriceSliderEventListeners()');
            //console.log('slider1PositionLeft:', rp.slider1!.style.left!);
            //console.log('slider2PositionLeft:', rp.slider2!.style.left!);
            //console.log('startSliderAmount:', rp.startSliderAmount);
            //console.log('endSliderAmount:', rp.endSliderAmount);
            //console.log('slider1AmountInitialValue:', slider1AmountInitialValue);
            //console.log('slider2AmountInitialValue:', slider2AmountInitialValue);

            rp.slider1!.onmousedown = null;
            rp.slider1!.onmousemove = null;
            rp.slider1!.onmouseup = null;
            rp.slider2!.onmousedown = null;
            rp.slider2!.onmousemove = null;
            rp.slider2!.onmouseup = null;
            document.onmousemove = null;
            document.onmouseup = null;
        }
    },[]);


    //useEffect(()=>{
        //console.log('useEffect startSliderAmount');
        //(rp.slider1Amount as HTMLInputElement).value = `$${props.startSliderAmount}`;
        //rp.sliderAmountCents = getSliderAmountCents(props.startSliderAmount);
        /*
        rp.startSliderAmount = getStartSliderAmount(props.startSliderAmount);
        rp.sliderAmountCents = getSliderAmountCents(props.startSliderAmount);
        console.log('ssa:', rp.startSliderAmount)
        console.log('sas:', rp.sliderAmountCents);
        rp.increaseAmountBy = rp.startSliderAmount;
        */
        //rp.slider1AmountInitialValue = props.startSliderAmount.toFixed(2);
        //setSlider1AmountInitialValue(props.startSliderAmount.toFixed(2));
    //},[props.startSliderAmount]);

    //useEffect(()=>{
        //console.log('useEffect endSliderAmount');
        //(rp.slider2Amount as HTMLInputElement).value = `$${props.endSliderAmount}`;
        //(rp.slider1Amount as HTMLInputElement).value = `$${props.startSliderAmount}`;
        //console.log('r1:', props.startSliderAmount);
        //console.log('r2:', props.endSliderAmount);
        //rp.startSliderAmount = getStartSliderAmount(props.startSliderAmount);
        //rp.sliderAmountCents = getSliderAmountCents(props.startSliderAmount);
        /*
        if(props.endSliderAmount > 0){        
            rp.endSliderAmount = getEndSliderAmount(props.endSliderAmount);
            rp.sliderDistancePerIncrease = (rp.adjustedEnd as number / 100) * (100 / (rp.endSliderAmount - (rp.startSliderAmount as number)));
            rp.percentIncreaseBy = parseFloat((((rp.adjustedEnd as number / (rp.endSliderAmount - (rp.startSliderAmount as number))) / (rp.adjustedEnd as number)) * 100).toFixed(3));
            console.log('dpi:', rp.sliderDistancePerIncrease);
            console.log('esa:', rp.endSliderAmount);
            console.log('ssa:', rp.startSliderAmount);
            console.log('ae:', rp.adjustedEnd);
            rp.previousSlider2TotalDistance = rp.endSliderAmount;
            //rp.slider2AmountInitialValue = props.endSliderAmount.toFixed(2);
            //setSlider2AmountInitialValue(props.endSliderAmount.toFixed(2));
            rp.slider1Amount!.value = `$${props.startSliderAmount.toFixed(2)}`;
            rp.slider2Amount!.value = `$${props.endSliderAmount.toFixed(2)}`;
            console.log('slider1:', slider1.current);
        }
        */
       
    //},[props.endSliderAmount]);

    function calcSliderAmount(sliderAmount:number):number {
        if(sliderAmount.toString().indexOf('.') !== -1){
            let index = sliderAmount.toString().indexOf('.');
            let amountAfterDecimal = sliderAmount.toString().slice(index);
            if(amountAfterDecimal.length === 2){
                return parseInt(sliderAmount.toString().slice(0,-2),10);
            } else {
                return parseInt(sliderAmount.toString().slice(0,-1),10);
            }
            
        } else{
            return sliderAmount;

        }
    }

    function getStartSliderAmount(startSliderAmount:number):number {
        //console.log('ssa:', startSliderAmount);
        if(startSliderAmount === 0){
            return 0;
        } else {
            let ssa:number = parseInt(startSliderAmount.toString().slice(0, -2), 10);
            return ssa;
        }

    }

    function getEndSliderAmount(endSliderAmount:number):number {
        //console.log('esa:', endSliderAmount);
        if(endSliderAmount === 0){
            return 0;
        } else {
            let esa:number = parseInt(endSliderAmount.toString().slice(0, -2), 10);
            return esa;
        }

    }

    function getSliderAmountCents(sliderAmount:number):string {
        //console.log('sacs:', sliderAmount);
        //let sliderAmountCents:string = startSliderAmount.toString().slice(-2);
        //return sliderAmountCents;
        if(sliderAmount.toString().indexOf('.') !== -1){
            /*previous version
            let index = sliderAmount.toString().indexOf('.');
            console.log('sacs:', 'index:', index);
            let amountAfterDecimal = sliderAmount.toString().slice(index);
            console.log('sacs:', 'amountAfterDecimal:', amountAfterDecimal);
            if(amountAfterDecimal.length === 2){
                return sliderAmount.toString().slice(index);
            } else {
                return sliderAmount.toString().slice(index + 1);
            }
            */

            //new version
            let index = sliderAmount.toString().indexOf('.');
            //console.log('sacs:', 'index:', index);
            let amountAfterDecimal = sliderAmount.toString().slice(index + 1);
            //console.log('sacs:', 'amountAfterDecimal:', amountAfterDecimal);
            if(amountAfterDecimal.length === 2){
                return amountAfterDecimal;
            } else {
                return amountAfterDecimal + '0';
            }
            
            
        } else{
            return '00';

        }
    }

    function setupSlider(
        slider1:HTMLButtonElement,
        slider2:HTMLButtonElement//,
        //sliderBtnContainerLeft:number
        ):void {
        //console.log('setupSlider called');

        /*
        let leftSlider:number = 0;
        let rightSlider:number = 0;
        let leftSliderPercentage:number = 0;
        let rightSliderPercentage:number = 0;
        */

        const sliders:HTMLButtonElement[] = [slider1, slider2];
        sliders.forEach((slider)=>{
            slider.onmousedown = function(event:MouseEvent) {
                rp.eventType = 'mousedown';
                rp.shiftX = event.clientX - (slider.getBoundingClientRect().left - rp.sliderBtnContainerLeft!);      

                //slider.style.zIndex = '1';
                
                function onMouseMove(event:MouseEvent){
                    //console.log('rp:', rp);
                    moveSliderAt(event.pageX);
                }
              
                // moves the slider at (pageX) coordinates
                // taking initial shifts into account
                function moveSliderAt(pageX:number):void {
                    //rp.slider1 = rp.slider1 as HTMLButtonElement;
                    //rp.slider2 = rp.slider2 as HTMLButtonElement;
                    //rp.shiftX = rp.shiftX as number;
                    //rp.slider1Amount = rp.slider1Amount as HTMLInputElement;
                    //rp.slider2Amount = rp.slider2Amount as HTMLInputElement;
                    //rp.sliderDistancePerIncrease = rp.sliderDistancePerIncrease as number;
                    //rp.totalDistance = rp.totalDistance as number;
                    //rp.startSliderAmount = rp.startSliderAmount as number;
                    //rp.endSliderAmount = rp.endSliderAmount as number;
                    //rp.sliderPosition = rp.sliderPosition as number;
                    //rp.currentPointerPosition = rp.currentPointerPosition as number;
                    //rp.startpointReached = rp.startpointReached as boolean;
                    //rp.endpointReached = rp.endpointReached as boolean;
                    //rp.slidersMatch = rp.slidersMatch as boolean;
                    //rp.previousSlider1Position = rp.previousSlider1Position as number;
                    //rp.previousSlider2Position = rp.previousSlider2Position as number;
                    //rp.previousSlider1TotalDistance = rp.previousSlider1TotalDistance as number;
                    //rp.previousSlider2TotalDistance = rp.previousSlider2TotalDistance as number;
                    //rp.sliderBtnContainerLeft = rp.sliderBtnContainerLeft as number;
                    //rp.sliderBtnContainerWidth = rp.sliderBtnContainerWidth as number;
                    //rp.start = rp.start as number;
                    //rp.adjustedEnd = rp.adjustedEnd as number;
                    //rp.sliderAmountCents = rp.sliderAmountCents as string;
                    //rp.percentIncreaseBy = rp.percentIncreaseBy as number;
                    //rp.increaseAmountBy = rp.increaseAmountBy as number;
                    /*
                    leftSlider = parseInt(rp.slider1!.style.left!.slice(0,-1));
                    rightSlider = parseInt(rp.slider2!.style.left!.slice(0,-1));
                    leftSliderPercentage = parseFloat(rp.slider1!.style.left!.slice(0,-1));
                    rightSliderPercentage = parseFloat(rp.slider2!.style.left!.slice(0,-1));
                    */
                                        
                    if(rp.slider1 === event.target && Math.abs(rp.sliderPosition!) >= 0){
                        //console.log('first:', rp.sliderPosition!);
                        //console.log('second', rp.adjustedEnd!);
                        //console.log('slidersMatch:', rp.slidersMatch!);

                        rp.sliderPosition = pageX - rp.shiftX!;                                              
                        rp.totalDistance = Math.round(rp.sliderPosition! / rp.sliderDistancePerIncrease!);
                        rp.totalDistance = Math.abs(rp.totalDistance);
                        //console.log('td:', rp.totalDistance);
                        //console.log('sliderPosition:', rp.sliderPosition!);
                        //console.log('previousSlider1Position:', rp.previousSlider1Position!);
                        //console.log('previousSlider1TotalDistance:', rp.previousSlider1TotalDistance!, rp.totalDistance);
                        //console.log('previousSlider2Position:', rp.previousSlider2Position!);
                        //console.log('previousSlider2TotalDistance:', rp.previousSlider2TotalDistance!, rp.totalDistance);
                        
                        //console.log('iab:', rp.increaseAmountBy);
                    }
                    
                    if(rp.slider2 === event.target && rp.sliderPosition! <= rp.adjustedEnd!){
                        rp.sliderPosition = pageX - rp.shiftX!;
                        rp.totalDistance = Math.round(rp.sliderPosition / rp.sliderDistancePerIncrease!);
                        //rp.totalDistance = Math.abs(rp.totalDistance);
                        //console.log('sliderPosition:', rp.sliderPosition!);
                        //console.log('previousSlider2Position:', rp.previousSlider2Position!);
                        //console.log('previousSlider2TotalDistance:', rp.previousSlider2TotalDistance!, rp.totalDistance);
    
                    }
    
                    rp.currentPointerPosition = pageX - rp.shiftX!;
                    
                    
                    if(rp.slidersMatch && (event.target === rp.slider1) && rp.sliderPosition! >= rp.previousSlider1Position!){
                        //console.log('sliderPosition:', rp.sliderPosition);
                        //console.log('previousSlider1Position:', rp.previousSlider1Position);
                        //console.log('cancel match', rp.sliderPosition, rp.previousSlider1Position);
                        rp.slidersMatch = false;
                        
    
                    } else if(rp.slidersMatch && (event.target === rp.slider2) && rp.sliderPosition! <= rp.previousSlider2Position!){
                        rp.slidersMatch = false;
                        
                    }
                    
                    //console.log('slidersMatch:', rp.slidersMatch);
                    //console.log('slider1:', slider1);
                    //console.log('rp.slider1:', rp.slider1);
                    //if(!rp.slidersMatch && parseInt(rp.slider1!.style.left!.slice(0,-1)) >= parseInt(rp.slider2!.style.left!.slice(0,-1))){
                    if(!rp.slidersMatch && (event.target === rp.slider1) && rp.totalDistance! >= rp.previousSlider2TotalDistance! /*parseInt(rp.slider1!.style.left!.slice(0,-1)) >= parseInt(rp.slider2!.style.left!.slice(0,-1))*/){
    
                        //if(event.target === rp.slider1){
                            rp.slider1.style.left = parseFloat(rp.slider2!.style.left!.slice(0,-1)) + '%';
                            rp.previousSlider1Position = rp.previousSlider2Position;
                            rp.previousSlider1TotalDistance = rp.previousSlider2TotalDistance;
                            //rp.sliderPosition = rp.previousSlider2Position;
                            //original version
                            //rp.slider1Amount!.value = rp.slider2Amount!.value + '.' + rp.sliderAmountCents;
                            rp.slider1Amount!.value = rp.slider2Amount!.value;
                            //console.log('value1 ==:', rp.slider1Amount!.value);

                            rp.slidersMatch = true;
                            //console.log('called match', rp.totalDistance, rp.previousSlider2Position, rp.previousSlider2TotalDistance, rp.slider1.style.left);
                            return;
    
                    } else if(!rp.slidersMatch && (event.target === rp.slider2) && rp.totalDistance! <= rp.previousSlider1TotalDistance! /*parseInt(rp.slider2!.style.left!.slice(0,-1)) <= parseInt(rp.slider1!.style.left!.slice(0,-1))*/){    
                            //} else if(event.target === rp.slider2){
    
                            rp.slider2.style.left = parseFloat(rp.slider1!.style.left!.slice(0,-1)) + '%';
                            rp.previousSlider2Position = rp.previousSlider1Position;
                            rp.previousSlider2TotalDistance = rp.previousSlider1TotalDistance;
                            //original version
                            //rp.slider2Amount!.value = rp.slider1Amount!.value + '.' + rp.sliderAmountCents;
                            rp.slider2Amount!.value = rp.slider1Amount!.value;
                            //console.log('value2 ==:', rp.slider2Amount!.value);
                        //}
                        
                        rp.slidersMatch = true;
                        //console.log('called match2:', rp.totalDistance, rp.previousSlider1Position, rp.previousSlider1TotalDistance, rp.slider2.style.left);
                        return;
    
                    } else if(rp.startpointReached && rp.currentPointerPosition > rp.start!){
                        rp.startpointReached = false;
                        rp.sliderPosition = 0;
        
                    } else if(rp.endpointReached && rp.currentPointerPosition <= rp.adjustedEnd!){
                        rp.endpointReached = false;
                        rp.sliderPosition = rp.adjustedEnd;
                        return;
        
                    } else if((rp.slider1 === event.target) && pageX <= (rp.sliderBtnContainerLeft!)){
        
                        if(!rp.startpointReached){
                            rp.previousSlider1Position = 0;
                            rp.previousSlider1TotalDistance = 0;
                            slider.style.left = 0 + '%';
                            rp.startpointReached = true;
                            rp.slider1Amount!.value = '$' + rp.startSliderAmount + '.' + rp.sliderAmountCents;
                            //console.log('value <=:', rp.slider1Amount!.value);
                        }
    
                        return;
        
                    } else if((rp.slider1 === event.target) && ((pageX - rp.shiftX!) >= (rp.adjustedEnd!))){
                        if(!rp.endpointReached){
                            rp.previousSlider1Position = rp.adjustedEnd;
                            //original version
                            //rp.previousSlider1TotalDistance = rp.endSliderAmount;
                            rp.previousSlider1TotalDistance = (rp.adjustedEnd! / rp.sliderDistancePerIncrease!);
                            slider.style.left = 100 + '%';
                            rp.endpointReached = true;
                            rp.slider1Amount!.value = '$' + rp.endSliderAmount + '.' + rp.sliderAmountCents;
                            //console.log('value >=:', rp.slider1Amount!.value);
                        }
                        return;
        
                    } else if((rp.slider2 === event.target) && ((pageX - rp.shiftX!) >= (rp.adjustedEnd!))){
                        
                        if(!rp.endpointReached){
                            rp.previousSlider2Position = rp.adjustedEnd;
                            //original version
                            //rp.previousSlider2TotalDistance = rp.endSliderAmount;
                            rp.previousSlider2TotalDistance = (rp.adjustedEnd! / rp.sliderDistancePerIncrease!);
                            rp.slider2.style.left = 100 + '%';
                            rp.endpointReached = true;
                            rp.slider2Amount!.value = '$' + rp.endSliderAmount + '.' + rp.sliderAmountCents;
                        } 
    
                        return;
        
                    } else if((rp.slider2 === event.target) && pageX <= (rp.sliderBtnContainerLeft!)){
                        
                        if(!rp.startpointReached){
                            rp.previousSlider2Position = 0;
                            rp.previousSlider2TotalDistance = 0;
                            slider.style.left = rp.start + '%';
                            rp.startpointReached = true;
                            rp.slider2Amount!.value = '$' + rp.startSliderAmount + '.' + rp.sliderAmountCents;
                        } 
    
                        return;
        
                    }              
    
                    if(rp.slider1 === event.target && rp.sliderPosition! > rp.previousSlider1Position!){
                        
                        if(rp.totalDistance! > 0 && rp.totalDistance! > rp.previousSlider1TotalDistance!){
                            //console.log('ran slider1 increase');
                            rp.slider1.style.left = (rp.totalDistance! * rp.percentIncreaseBy!) + '%';
                            rp.slider1Amount!.value = '$' + (rp.totalDistance! + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;
                            
                            //preset endSliderAmount
                            if(parseInt(rp.slider2Amount!.value.slice(1,-2),10) === rp.endSliderAmount){
                                rp.slider2Amount!.value = '$' + rp.endSliderAmount + '.' + rp.sliderAmountCents;
                            }
                            //console.log('s1av >:', rp.slider1Amount!.value!);
                            //console.log('slider2Amount:', rp.slider2Amount!.value);
                            //console.log('endSliderAmount:', rp.endSliderAmount);
                            //console.log('iab:', rp.increaseAmountBy!);
                            //console.log('td:', rp.totalDistance!);
                            //console.log('previousSlider1TotalDistance:', rp.previousSlider1TotalDistance!);
                            //console.log('sac:', rp.sliderAmountCents);
                            //console.log('s1aiv:', rp.slider1AmountInitialValue);
                            rp.previousSlider1TotalDistance = rp.totalDistance;
                        }
    
                        rp.previousSlider1Position = rp.sliderPosition;
    
                    } else if(rp.slider1 === event.target && rp.sliderPosition! < rp.previousSlider1Position!){
                        
                        if(rp.totalDistance! < /*rp.endSliderAmount!*/ rp.previousSlider2TotalDistance! && rp.totalDistance! < rp.previousSlider1TotalDistance!){
                            //console.log('ran slider1 decrease');
                            rp.slider1.style.left = (rp.totalDistance! * rp.percentIncreaseBy!) + '%';
                            rp.slider1Amount!.value = '$' + (rp.totalDistance! + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;
                            //console.log('s1av <:', rp.slider1Amount!.value!);
                            rp.previousSlider1TotalDistance = rp.totalDistance;
                            
                            //console.log('slider1Amount:', rp.slider1Amount!.value);
                        }
                        
                        rp.previousSlider1Position = rp.sliderPosition;
    
                    } else if(rp.slider2 === event.target && rp.sliderPosition! > rp.previousSlider2Position!) {
    
                        if(rp.totalDistance! > 0 && rp.totalDistance! > rp.previousSlider2TotalDistance!){
                            const lastAmount = rp.slider2.style.left!.slice(0,-1);
                            rp.slider2.style.left = ((rp.totalDistance! / (rp.endSliderAmount! - rp.startSliderAmount!)) * 100) + '%';
                            rp.slider2Amount!.value = '$' + (rp.totalDistance! + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;
                            rp.previousSlider2TotalDistance = rp.totalDistance;
                        }
                        
                        rp.previousSlider2Position = rp.sliderPosition;
    
                    } else if(rp.slider2 === event.target && rp.sliderPosition! < rp.previousSlider2Position!) {
    
                        if(rp.totalDistance! < rp.endSliderAmount! && rp.totalDistance! < rp.previousSlider2TotalDistance!){
                            rp.slider2.style.left = (rp.totalDistance! / (rp.endSliderAmount! - rp.startSliderAmount!)) * 100 + '%';
                            rp.slider2Amount!.value = '$' + (rp.totalDistance! + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;
                            //console.log('amount:', rp.slider2Amount)
                            rp.previousSlider2TotalDistance = rp.totalDistance;

                            //console.log('slider2Amount:', rp.slider2Amount!.value);
                        }
                        
                        rp.previousSlider2Position = rp.sliderPosition;
                    }
                
                
                
                }
              
                // move the ball on mousemove
                document.onmousemove = onMouseMove;//onMouseMove;
                document.onmouseup = function(event:MouseEvent){
                    //console.log('doc mouseup');
                    event.stopPropagation();
                    //console.log('document onmouseup');
                    document.onmousemove = null;
                    document.onmouseup = null;
                    slider.onmouseup = null;
                    //original version
                    //slider.style.zIndex = '1';
                    if(slider === rp.slider1!){
                        rp.slider1!.style.zIndex! = '1';
                        rp.slider2!.style.zIndex! = '0';
                    } else {
                        rp.slider2!.style.zIndex! = '1';
                        rp.slider1!.style.zIndex! = '0';
                    }
                    /*slider.style.position = 'relative';
                    slider.style.top = '-9px';*/
                    //rp.sliderPosition = 1;
                    //original version and position
                    //rp.sliderPosition = 0;
                    //console.log('sliders match:', rp.slidersMatch);
                    //console.log('startSliderAmount:', rp.startSliderAmount);
                    //console.log('endSliderAmount:', rp.endSliderAmount);
                    //console.log('endSliderAmount:', parseFloat(rp.slider2Amount!.value));
                    //console.log('keywordSearch:', keywordSearch);
                    //console.log('slider1.style.left:', slider1.style.left!.length);
                    //console.log('slider2.style.left:', slider2.style.left!.length);
                    //console.log('slider1AmountInitialValue:', slider1AmountInitialValue);
                    //console.log('rp.startSliderAmount:', rp.startSliderAmount);
                    //console.log('rp.sliderAmountCentts:', rp.sliderAmountCents);
                    //console.log('previousSlider1TotalDistance:', rp.previousSlider1TotalDistance);
                    //console.log('previousSlider2TotalDistance:', rp.previousSlider2TotalDistance);
                    //console.log('sliderPosition:', rp.sliderPosition);
                    //console.log('psov:', sbov.current);

                    //previous version was to only return the rp.sliderPosition with no adjustments

                    priceSliderSearchResults(
                                        //parseInt(rp.slider1Amount!.value.slice(1,-2),10),
                                        //parseInt(rp.slider2Amount!.value.slice(1,-2),10),
                                        keywordSearch,
                                        //parseFloat(rp.slider1AmountInitialValue!),
                                        //parseFloat(rp.slider2AmountInitialValue!),
                                        parseFloat(startSliderAmount.toFixed(2)),
                                        parseFloat(endSliderAmount.toFixed(2)),
                                        slider1.style.left!.length === 0 ? '0%' : slider1.style.left!,
                                        slider2.style.left!.length === 0 ? '100%' : slider2.style.left!,
                                        parseFloat(rp.slider1Amount!.value.slice(1)),
                                        parseFloat(rp.slider2Amount!.value.slice(1)),
                                        (rp.sliderPosition! > rp.adjustedEnd! ? rp.adjustedEnd! : rp.sliderPosition!),
                                        rp.previousSlider1TotalDistance!,
                                        rp.previousSlider2TotalDistance!,
                                        parseInt(rp.slider1!.style.zIndex!,10),
                                        parseInt(rp.slider2!.style.zIndex!,10),
                                        sbov.current
                                        );

                                        

                    rp.sliderPosition = 0;
                    
                };
              
                // drop the ball, remove unneeded handlers
                slider.onmouseup = function onMouseUp(event:MouseEvent) {
                    event.stopPropagation();
                    //console.log('slider mouseup');
                    document.onmousemove = null;
                    document.onmouseup = null;
                    slider.onmouseup = null;
                    //original version
                    //slider.style.zIndex = '1';
                    if(slider === rp.slider1!){
                        rp.slider1!.style.zIndex! = '1';
                        rp.slider2!.style.zIndex! = '0';
                    } else {
                        rp.slider2!.style.zIndex! = '1';
                        rp.slider1!.style.zIndex! = '0';
                    }
                    /*slider.style.position = 'relative';
                    slider.style.top = '-6px';*/
                    //rp.sliderPosition = 1;
                    //orginal version and position
                    //rp.sliderPosition = 0;
                    //console.log('sliders match:', rp.slidersMatch);
                    //console.log('startSliderAmount:', rp.startSliderAmount!);
                    //console.log('endSliderAmount:', parseFloat(rp.slider2Amount!.value));
                    ///console.log('slider1AmountInitialValue:', rp.slider1AmountInitialValue);
                    //console.log('slider2AmountInitialValue:', rp.slider2AmountInitialValue);
                    //console.log('keywordSearch:', keywordSearch);
                    //console.log('slider1.style.left:', slider1.style.left!.length);
                    //console.log('slider2.style.left:', slider2.style.left!.length);
                    //console.log('rp.sliderAmountCentts:', rp.sliderAmountCents);
                    //console.log('previousSlider1TotalDistance:', rp.previousSlider1TotalDistance);
                    //console.log('previousSlider2TotalDistance:', rp.previousSlider2TotalDistance);
                   //console.log('sliderPosition:', rp.sliderPosition);
                    //console.log('psov:', sbov.current);

                    //previous version was to only return the rp.sliderPosition with no adjustments

                    priceSliderSearchResults(
                                            //parseInt(rp.slider1Amount!.value.slice(1,-2),10),
                                            //parseInt(rp.slider2Amount!.value.slice(1,-2),10),
                                            keywordSearch,
                                            //parseFloat(rp.slider1AmountInitialValue!),
                                            //parseFloat(rp.slider2AmountInitialValue!),
                                            parseFloat(startSliderAmount.toFixed(2)),
                                            parseFloat(endSliderAmount.toFixed(2)),
                                            slider1.style.left!.length === 0 ? '0%' : slider1.style.left!,
                                            slider2.style.left!.length === 0 ? '100%' : slider2.style.left!,
                                            parseFloat(rp.slider1Amount!.value.slice(1)),
                                            parseFloat(rp.slider2Amount!.value.slice(1)),
                                            (rp.sliderPosition! > rp.adjustedEnd! ? rp.adjustedEnd! : rp.sliderPosition!),
                                            rp.previousSlider1TotalDistance!,
                                            rp.previousSlider2TotalDistance!,
                                            parseInt(rp.slider1!.style.zIndex!,10),
                                            parseInt(rp.slider2!.style.zIndex!,10),
                                            sbov.current
                                            );
                    
                    rp.sliderPosition = 0;
                                    
                };
              
              };
              
              slider.ondragstart = function onDragStart(event:DragEvent) {
                return false;
              };;
              
    
        });
    
    }

    //move slider to location clicked
    function moveSliderLocationClicked(e:React.MouseEvent){
        //console.log('clicked:', rp.eventType);
        let sp = e.pageX - rp.sliderBtnContainerLeft!;
        let sdpi = rp.sliderDistancePerIncrease;

        if(rp.eventType === 'mousedown'){
            rp.eventType = 'none';
            return;

        } else {
            //console.log('test sp:', sp);
            //console.log('test sdpi:', sdpi);
            let slider1Position = parseFloat(rp.slider1!.style.left!.slice(0,-1));
            let slider2Position = parseFloat(rp.slider2!.style.left!.slice(0,-1));
            //console.log('made it1:', slider1Position);
            //console.log('made it2:', slider2Position);
            if(rp.startSliderAmount === rp.endSliderAmount){
                return;
            }

            if(slider1Position !== 0) {
                slider1Position = ((slider1Position) / 100) * rp.sliderBtnContainerWidth!;
            }
            if(slider2Position !== 100){
                slider2Position = ((slider2Position) / 100) * rp.sliderBtnContainerWidth!;
            }
            if(slider1Position === 0){
                slider1Position = (0 * rp.sliderBtnContainerWidth!);
            } 
            if(slider2Position === 100){
                slider2Position = (1 * rp.sliderBtnContainerWidth!);
            } 


            if(sp < slider1Position && sp < slider2Position){
                let td = Math.round(sp/rp.sliderDistancePerIncrease!);

                rp.slider1!.style.left = (td * rp.percentIncreaseBy!) + '%';
                rp.previousSlider1TotalDistance = td;
                rp.slider1Amount!.value = '$' + (td + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;

            } else if(sp > slider1Position && sp > slider2Position){
                let td = Math.round(sp/rp.sliderDistancePerIncrease!);
                rp.slider2!.style.left = (td * rp.percentIncreaseBy!) + '%';
                rp.previousSlider2TotalDistance = td;
                rp.slider2Amount!.value = '$' + (td + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;

            } else if(sp > slider1Position && sp < slider2Position){
                const spToSlider1Position = (sp - slider1Position) - rp.sliderWidth!;
                const spToSlider2Position = slider2Position - sp;
                //console.log('passed');
                if(spToSlider1Position < spToSlider2Position){
                    let td = Math.round(sp/rp.sliderDistancePerIncrease!);
                    rp.slider1!.style.left = (td * rp.percentIncreaseBy!) + '%';
                    rp.previousSlider1TotalDistance = td;
                    rp.slider1Amount!.value = '$' + (td + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;
                    //preset endSliderAmount cents
                    if(parseInt(rp.slider2Amount!.value.slice(1,-2),10) === rp.endSliderAmount){
                        rp.slider2Amount!.value = '$' + rp.endSliderAmount + '.' + rp.sliderAmountCents;
                    }
                    //preset endSliderAmount cents
                    //rp.slider2Amount!.value = '$' + rp.endSliderAmount + '.' + rp.sliderAmountCents;

                } else if(spToSlider2Position < spToSlider1Position){
                    let td = Math.round(sp/rp.sliderDistancePerIncrease!);
                    rp.slider2!.style.left = (td * rp.percentIncreaseBy!) + '%';
                    rp.previousSlider2TotalDistance = td;
                    rp.slider2Amount!.value = '$' + (td + rp.increaseAmountBy!) + '.' + rp.sliderAmountCents;
                }
            }        
            
            //console.log('psov:', sbov.current);
            //setTimeout(()=>{
                priceSliderSearchResults(
                    //parseInt(rp.slider1Amount!.value.slice(1,-2),10),
                    //parseInt(rp.slider2Amount!.value.slice(1,-2),10),
                    keywordSearch,
                    //parseFloat(rp.slider1AmountInitialValue!),
                    //parseFloat(rp.slider2AmountInitialValue!),
                    parseFloat(startSliderAmount.toFixed(2)),
                    parseFloat(endSliderAmount.toFixed(2)),
                    rp.slider1!.style.left!.length === 0 ? '0%' : rp.slider1!.style.left!,
                    rp.slider2!.style.left!.length === 0 ? '100%' : rp.slider2!.style.left!,
                    parseFloat(rp.slider1Amount!.value.slice(1)),
                    parseFloat(rp.slider2Amount!.value.slice(1)),
                    rp.sliderPosition!,
                    rp.previousSlider1TotalDistance!,
                    rp.previousSlider2TotalDistance!,
                    parseInt(rp.slider1!.style.zIndex!,10),
                    parseInt(rp.slider2!.style.zIndex!,10),
                    sbov.current
                    );
            //},0);
            

        
        }
        
    };
//value={`$${slider1AmountInitialValue}`}
//value={`$${slider2AmountInitialValue}`}
    return (
        <div className="slider-container-wrapper">
            <div id="price" className="slider-heading">Price Range</div>
            <div ref={sliderBtnAmountContainer} className="slider-btn-amount-container">
                <input ref={slider1Amount} aria-labelledby="price" type="text" id="slider1Amount" className="slider1Amount"
                    readOnly={true}/>
                <input ref={slider2Amount} aria-labelledby="price" type="text" id="slider2Amount" className="slider2Amount"
                    readOnly={true}/>
            </div>
            <div ref={sliderContainer} className="slider-container">
                <div ref={sliderBtnContainer} className="slider-btn-container"
                    onClick={moveSliderLocationClicked}>
                    <button ref={slider1} aria-label="left slider" id="slider1" type="button"></button>
                    <button ref={slider2} aria-label="right slider" id="slider2" type="button"></button>
                </div>
            </div>
        </div>
        ); 

       

}

export default PriceSlider3;