import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './CustomerRelationResponse.css';
import CustomerRelation from './customerRelation';
import transformDate from './helper-transform-date';

interface Props {
    customerRelation:CustomerRelation;
}

const CustomerRelationResponse:React.FunctionComponent<Props> = (props:Props)=>{
    const [customerRelation, setCustomerRelation] = useState<CustomerRelation | null>(props.customerRelation);

    useEffect(()=>{
        setCustomerRelation(props.customerRelation);
    },[props.customerRelation]);

    if((customerRelation as CustomerRelation) !== null){
        return <div className="customer-relation-response-container">
                    <div className="customer-relation-response-heading">Customer Relations</div>
                    <div className="customer-relation-response-date">{transformDate((customerRelation as CustomerRelation).responseDate)}</div>
                    <div className="customer-relation-response">
                        {(customerRelation as CustomerRelation).response}
                    </div>
                </div>

    } else {
        return null;
    }

}

export default CustomerRelationResponse;