import StoreService from './storeService';

export default class StoreServiceArea {
    public storeLocationId:number;
    public storeServiceAreaId:number;
    public storeServiceAreaName:string;
    public storeServices:StoreService[];

    constructor(options: {
        storeLocationId?:number,
        storeServiceAreaId?:number,
        storeServiceAreaName?:string,
        storeServices?:StoreService[]
    }={}){
        this.storeLocationId = options.storeLocationId || 0;
        this.storeServiceAreaId = options.storeServiceAreaId || 0;
        this.storeServiceAreaName = options.storeServiceAreaName || '';
        this.storeServices = options.storeServices || [];
    }
}