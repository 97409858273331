class Banner {
    public bannerId:number;
    public homePageLayoutId:number;
    public selectedForRemoval:boolean;
    public heading1:string;
    public heading2:string;
    public headingSizeDesktop:string;
    public headingSizeMobile:string;
    public caption:string;
    public headingPositionDesktop:string;
    public headingPositionMobile:string;
    public headingTypeDesktop:string;
    public headingTypeMobile:string;
    public captionColor:string;
    public headingColor:string;
    public buttonBackgroundColor:string;
    public buttonColor:string;
    public paddingBottomDesktop:number;
    public paddingBottomMobile:number;
    public fullLength:boolean;
    public mobileSource:string;
    public desktopSource:string;

    constructor(options:{
        bannerId?:number,
        homePageLayoutId?:number,
        selectedForRemoval?:boolean,
        heading1?:string,
        heading2?:string,
        headingSizeDesktop?:string,
        headingSizeMobile?:string,
        caption?:string,
        headingPositionDesktop?:string,
        headingPositionMobile?:string,
        headingTypeDesktop?:string,
        headingTypeMobile?:string,
        captionColor?:string,
        headingColor?:string,
        buttonBackgroundColor?:string,
        buttonColor?:string,
        paddingBottomDesktop?:number,
        paddingBottomMobile?:number,
        fullLength?:boolean,
        mobileSource?:string,
        desktopSource?:string
    }={}){
        this.bannerId = options.bannerId || 0;
        this.homePageLayoutId = options.homePageLayoutId || 0;
        this.selectedForRemoval = options.selectedForRemoval || false;
        this.heading1 = options.heading1 || 'none';
        this.heading2 = options.heading2 || 'none';
        this.headingSizeDesktop = options.headingSizeDesktop || 'regular';
        this.headingSizeMobile = options.headingSizeMobile || 'regular';
        this.caption = options.caption || 'none';
        this.headingPositionDesktop = options.headingPositionDesktop || 'top-center';
        this.headingPositionMobile = options.headingPositionMobile || 'top-center';
        this.headingTypeDesktop = options.headingTypeDesktop || 'noSplit';
        this.headingTypeMobile = options.headingTypeMobile || 'noSplit';
        this.captionColor = options.captionColor || '#414142';
        this.headingColor = options.headingColor || '#414142';
        this.buttonBackgroundColor = options.buttonBackgroundColor || '#007DB3';
        this.buttonColor = options.buttonColor || '#FFFFFF';
        this.paddingBottomDesktop = options.paddingBottomDesktop || 100;
        this.paddingBottomMobile = options.paddingBottomMobile || 100;
        this.fullLength = options.fullLength || false;
        this.mobileSource = options.mobileSource || '';
        this.desktopSource = options.desktopSource || '';
    }
}

export default Banner;