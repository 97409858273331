import React, {useImperativeHandle, useRef, forwardRef} from 'react';
import {Link} from '@reach/router';
import './StoreStructureOptionSelect.css';

interface Props {
    //defaultValue:string;
    elementName:string;
    optionNameSelected:string;
    optionNames:string[];
    elementId:string;
    showErrorMsg:boolean;
    errorMsg:string;
    cssStyles?:{minWidth:number, backgroundPosition:number};
    handleOptionNameSelection:(e:React.ChangeEvent)=>void;
}

type Ref = {
    dSelectElementRef:React.RefObject<HTMLSelectElement>;
};

//const StoreDepartmentSelectControl: React.FunctionComponent<Props> = (props:Props) => {
const PriceRangeSelectControl = forwardRef<Ref, Props>((props, ref)=>{    
    const {optionNameSelected, elementName, optionNames, elementId, showErrorMsg, errorMsg, cssStyles, handleOptionNameSelection} = props;
        //console.log('ons:', optionNameSelected);

    const controlRef = useRef<HTMLSelectElement>(null);

    useImperativeHandle(ref, ()=>({
        
        get dSelectElementRef(){
            return controlRef;
        }
        
        })
    );


    function setupOptions(optionNames:string[]):JSX.Element[]{
        return optionNames.map((optionNames, i)=>{
                            return <option key={i + 1} value={optionNames}>{optionNames}</option>;                    
                        });
    }

    if(optionNames.length >= 0){
        return  <div className="store-structure-option-select-container">
                    <select ref={controlRef} name={elementName} id={elementId} 
                    style={{minWidth:`${(typeof cssStyles !== 'undefined' ? cssStyles.minWidth : 120 )}px`, backgroundPosition:`calc(100% - ${(typeof cssStyles !== 'undefined' ? cssStyles.backgroundPosition : 14 )}px`}}
                    className={showErrorMsg ? 'show-store-structure-option-select-error-border': 'hide-store-structure-option-select-no-error-border'}
                     value={optionNameSelected} onChange={(e:React.ChangeEvent)=>handleOptionNameSelection(e)}>
                        {/*<option key={0} value={defaultValue}>{defaultValue}</option>*/}
                        {setupOptions(optionNames)}
                    </select>
                    <div className={showErrorMsg ? 'show-store-structure-option-select-error-msg': 'hide-store-structure-option-select-error-msg' }>
                        {errorMsg}
                    </div>
                </div>
    } else {
        return null;
    }

});

export default PriceRangeSelectControl;