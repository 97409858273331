import React, {useState, useEffect} from 'react';
import {Link} from '@reach/router';
import StoreStructureOptionSelect from './StoreStructureOptionSelect';
import SearchFilter, {SearchTerm} from './searchFilter';
import {getDepartmentNamesPromise, 
    getDepartmentCategoryNamesPromise, 
    getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import getAreaTypeSearchFilterNamesPromise from './helper-get-search-filter-names-promise';
import DbResponse from './db-response';
import isSearchTermUnique from './helper-add-search-term-db';
import StoreStructureLoadingMsg from './StoreStructureLoadingMsg';
import StoreStructureAlert from './StoreStructureAlert';
import showSearchTermFormErrorMsgs from './helper-show-search-term-form-error-msgs';
import isSearchFilterAreaNameSelected from './helper-is-search-filter-area-name-selected';
import LoaderAnimation from './LoaderAnimation';
import './AddSearchTermStandAlone.css';

interface Props {
    showAddSearchTerm:boolean;
    handleSaveSearchTerm:(
                    searchFilterAreaTypeSelected:string,
                    searchFilterAreaNameSelected:string,
                    searchFilterNameSelected:string,
                    searchTermNameSelected:string,
                    searchTermSelected:boolean,
                    showSearchTerm:boolean
                        )=>void;
    handleCancelSearchTerm:()=>void;
}

const AddSearchTermStandAlone: React.FunctionComponent<Props> = (props:Props) => {
    const {showAddSearchTerm, handleCancelSearchTerm, handleSaveSearchTerm } = props;
    const [deptSearchFilterNames, setDeptSearchFilterNames] = useState<string[]>([]);
    const [deptCategorySearchFilterNames, setDeptCategorySearchFilterNames] = useState<string[]>([]);
    const [productCategorySearchFilterNames, setProductCategorySearchFilterNames] = useState<string[]>([]);
    const [deptNameSelected, setDeptNameSelected] = useState('none');
    const [deptCategoryNameSelected, setDeptCategoryNameSelected] = useState('none');
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState('none');
    const [showDepartment, setShowDepartment] = useState(true);
    const [showDepartmentCategory, setShowDepartmentCategory] = useState(true);
    const [showProductCategory, setShowProductCategory] = useState(true);
    const [searchFilterAreaNameSelected, setSearchFilterAreaNameSelected] = useState('none');
    const [searchFilterAreaTypeSelected, setSearchFilterAreaTypeSelected] = useState('none');
    const [searchFilterNameSelected, setSearchFilterNameSelected] = useState('none');
    const [searchTermNameSelected, setSearchTermNameSelected] = useState('');
    const [searchTermSelected, setSearchTermSelected] = useState(false);
    const [showSearchTerm, setShowSearchTerm] = useState(false);
    const [searchFilterNames, setSearchFilterNames] = useState<string[]>([]);
    const [searchTermImgSelected, setSearchTermImgSelected] = useState('');
    const [showSearchTermImg, setShowSearchTermImg] = useState(false);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('no msg');
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [storeStructureLoadingMsg, setStoreStructureLoadingMsg] = useState('saving search term');
    const [showStoreStructureLoadingMsg, setShowStoreStructureLoadingMsg] = useState(false);
    const [showSearchFilterAreaNameErrorMsg, setShowSearchFilterAreaNameErrorMsg] = useState(false);
    const [searchFilterDeptNameErrorMsg, setSearchFilterDeptNameErrorMsg] = useState('Please select a department name.');
    const [searchFilterDeptCategoryNameErrorMsg, setSearchFilterDeptCategoryNameErrorMsg] = useState('Please select a department category name.');
    const [searchFilterProductCategoryNameErrorMsg, setSearchFilterProductCategoryNameErrorMsg] = useState('Please select a product category name.');
    const [showSearchFilterNameErrorMsg, setShowSearchFilterNameErrorMsg] = useState(false);
    const [searchFilterNameErrorMsg, setSearchFilterNameErrorMsg] = useState('Please select a search filter name.');
    const [searchTermNameErrorMsg, setSearchTermNameErrorMsg] = useState('Please add a search term name.');
    const [showSearchTermNameErrorMsg, setShowSearchTermNameErrorMsg] = useState(false);

    const [showSearchTermNameLimitError, setShowSearchTermNameLimitError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    //console.log('sfats:', searchFilterAreaTypeSelected);
    /*console.log('d:', showDepartment);
    console.log('dc:', showDepartmentCategory);
    console.log('pc:', showProductCategory);*/

    useEffect(()=>{
        /*
        Promise.all([getDepartmentNamesPromise(), getDepartmentCategoryNamesPromise(), 
                     getProductCategoryNamesPromise(), getAllSearchFilterNamesPromise()])
        .then(([deptNames, deptCategoryNames, productCategoryNames, searchFilterNames])=>{
            console.log('dns:', deptNames);
            console.log('dcns:', deptCategoryNames);
            console.log('pcns:', productCategoryNames);
            setDeptNames([...deptNames]);
            setDeptCategoryNames([...deptCategoryNames]);
            setProductCategoryNames([...productCategoryNames]);
            setSearchFilterNames([...searchFilterNames]);
        })
        .catch((err)=>console.error('promise all for names error:', err));
        */
       Promise.all([getDepartmentSearchFilterNamesPromise(), getDepartmentCategorySearchFilterNamesPromise(),
                    getProductCategorySearchFilterNamesPromise()])
       .then(([deptSearchFilterNames, deptCategorySearchFilterNames, productCategorySearchFilterNames])=>{
           //console.log('dsfns:', deptSearchFilterNames);
           //console.log('dcsfns:', deptCategorySearchFilterNames);
           //console.log('pcsfns:', productCategorySearchFilterNames);
           setDeptSearchFilterNames([...deptSearchFilterNames]);
           setDeptCategorySearchFilterNames([...deptCategorySearchFilterNames]);
           setProductCategorySearchFilterNames([...productCategorySearchFilterNames]);
       })
       .catch((err)=>console.error('promise all for names error:', err));

    },[]);

    function getDepartmentSearchFilterNamesPromise():Promise<string[]> {
        return new Promise((resolve, reject)=>{
            fetch(`https://server.kando-proto-3.com/get-department-search-filter-names`,{
                method:'GET',
                headers:{
                    'Accept':'application/javascript'
                }
            })
            .then((res)=>res.json())
            .then((deptNames:string[])=>{
                //console.log('dns', deptNames);
                resolve(deptNames);            
            })
            .catch((err)=>{
                console.error('dept search filter names fetch error:', err);
                reject(err);
            });
        });
    }

    function getDepartmentCategorySearchFilterNamesPromise():Promise<string[]> {
        return new Promise((resolve, reject)=>{
            fetch(`https://server.kando-proto-3.com/get-department-category-search-filter-names`,{
                method:'GET',
                headers:{
                    'Accept':'application/javascript'
                }
            })
            .then((res)=>res.json())
            .then((deptNames:string[])=>{
                //console.log('dns', deptNames);
                resolve(deptNames);            
            })
            .catch((err)=>{
                console.error('dept category search filter names fetch error:', err);
                reject(err);
            });
        });
    }

    function getProductCategorySearchFilterNamesPromise():Promise<string[]> {
        return new Promise((resolve, reject)=>{
            fetch(`https://server.kando-proto-3.com/get-product-category-search-filter-names`,{
                method:'GET',
                headers:{
                    'Accept':'application/javascript'
                }
            })
            .then((res)=>res.json())
            .then((deptNames:string[])=>{
                //console.log('dns', deptNames);
                resolve(deptNames);            
            })
            .catch((err)=>{
                console.error('product category search filter names fetch error:', err);
                reject(err);
            });
        });
    }

    function handleDeptNameSelection(e:React.ChangeEvent):void {
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            //const sfns = getSearchFilterNames('Department', dns);
            //setSearchFilterNames(sfns);
            setShowDepartmentCategory(false);
            setShowProductCategory(false);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('Department');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(false);

            //get related search filters
            getAreaTypeSearchFilterNamesPromise('Department', dns)
            .then((deptSearchFilterNames)=>{
                setSearchFilterNames(deptSearchFilterNames);
            })
            .catch((err:React.ErrorInfo)=>{console.error('department search filters error:', err)});

        } else {
            //setSearchFilterNames([]);
            //setSearchFilterNameSelected('none');
            setShowDepartmentCategory(true);
            setShowProductCategory(true);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(true);

            //empty search term names
            setSearchFilterNames([]);
            
        }
    }

    function handleDeptCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('dcns:', (e.target as HTMLSelectElement).value);
        const dcns = (e.target as HTMLSelectElement).value;
        if(dcns !== 'none'){
            //const sfns = getSearchFilterNames('Department Category', dcns);
            //setSearchFilterNames(sfns);
            setShowDepartment(false);
            setShowProductCategory(false);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('Department Category');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(false);

            //get related search filters
            getAreaTypeSearchFilterNamesPromise('Department Category', dcns)
            .then((deptCategorySearchFilterNames)=>{
                setSearchFilterNames(deptCategorySearchFilterNames);
            })
            .catch((err:React.ErrorInfo)=>{console.error('department category search filters error:', err)});

        } else {
            //setSearchFilterNames([]);
            //setSearchFilterNameSelected('none');
            setShowDepartment(true);
            setShowProductCategory(true);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(true);

            //empty search term names
            setSearchFilterNames([]);
        }
    }

    function handleProductCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('pcns:', (e.target as HTMLSelectElement).value);
        const pcns = (e.target as HTMLSelectElement).value;
        if(pcns !== 'none'){
            //const sfns = getSearchFilterNames('Product Category', pcns);
            //setSearchFilterNames(sfns);
            setShowDepartment(false);
            setShowDepartmentCategory(false);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('Product Category');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(false);
            
            //get related search filters
            getAreaTypeSearchFilterNamesPromise('Product Category', pcns)
            .then((productCategorySearchFilterNames)=>{
                setSearchFilterNames(productCategorySearchFilterNames);
            })
            .catch((err:React.ErrorInfo)=>{console.error('product category search filters error:', err)});

        } else {
            //setSearchFilterNames([]);
            //setSearchFilterNameSelected('none');
            setShowDepartment(true);
            setShowDepartmentCategory(true);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(true);

            //empty search term names
            setSearchFilterNames([]);
        }
    }

    function isSearchTermNameSelected(searchTermNameSelected:string):boolean {
        if(searchTermNameSelected === ''){
            setStoreStructureAlertMsg('need to add a search term name');
            setShowStoreStructureAlert(true);
            setSearchTermNameErrorMsg('search-term-name-error-msg');

            setTimeout(()=>{
                setStoreStructureAlertMsg('no msg');
                setShowStoreStructureAlert(false);
            },2000);

            return true;

        } else {
            return false;
        }
    }

    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        setShowStoreStructureAlert(false);
    }

    function handleSubmitSearchTermForm(e:React.FormEvent):void {
        e.preventDefault();
        
        const showErrorMsgs:boolean = showSearchTermFormErrorMsgs(
                                                                    {searchFilterNameSelected,
                                                                    deptNameSelected,
                                                                    deptCategoryNameSelected,
                                                                    productCategoryNameSelected,
                                                                    isSearchFilterAreaNameSelected,
                                                                    setShowSearchFilterAreaNameErrorMsg,
                                                                    setSearchFilterNameErrorMsg,
                                                                    setShowSearchFilterNameErrorMsg,
                                                                    setSearchTermNameErrorMsg,
                                                                    setShowSearchTermNameErrorMsg,
                                                                    setShowSearchTermNameLimitError,
                                                                    searchTermNameSelected});
                                    
        if(showErrorMsgs){
            return;

        }//add else sate to the rest of function if top condition works
        //need to add error filtering for search term name selected

        setShowProcessingRequestMsg(true);

        const stToAdd = new SearchTerm({
                    searchFilterArea:searchFilterAreaNameSelected,
                    searchFilterAreaType:searchFilterAreaTypeSelected,
                    searchFilterName:searchFilterNameSelected,
                    searchTermName:searchTermNameSelected,
                    searchTermSelected:searchTermSelected,
                    showSearchTerm:showSearchTerm,    
                    showSearchTermImg:showSearchTermImg,
                    searchTermImg:(searchTermImgSelected.length === 0) ? 'none' : searchTermImgSelected
                });


        //console.log('stToAdd:', stToAdd);
        isSearchTermUnique(stToAdd)
        .then(({dbResponseMsg}:DbResponse)=>{
            setShowStoreStructureLoadingMsg(false);
            //console.log('dbrm:', dbResponseMsg);
            if(dbResponseMsg === 'record found'){
                //show alert that search term already exits
                /*
                setStoreStructureAlertMsg('search term already exists');
                setShowStoreStructureAlert(true);

                setTimeout(()=>{
                    setStoreStructureAlertMsg('no msg');
                    setShowStoreStructureAlert(false);
                },2000);
                */

                setShowProcessingRequestMsg(false);

               setShowSearchTermNameErrorMsg(true);
               setSearchTermNameErrorMsg('Search term already exists');

            } else {
                //const searchFilter = JSON.stringify(sfToBeAdded);
                setShowStoreStructureLoadingMsg(true);

                //console.log('sfatns:', searchFilterAreaTypeSelected);
                //console.log('sfans:', searchFilterAreaNameSelected);
                //console.log('sfn:', searchFilterNameSelected);

                const urlParams = {searchFilterAreaTypeSelected, 
                                    searchFilterAreaNameSelected, 
                                    searchFilterNameSelected};

                const params = JSON.stringify(urlParams);

                //get search filter name selected and update search_filters qty field
                //need to add this information
                //create a variable
                //add to request update-search-filter-qty
                //previous version
                //fetch(`http://localhost:9500/update-search-filter-search_term-qty-only/${searchFilterAreaTypeSelected}/${searchFilterAreaNameSelected}/${searchFilterNameSelected}`,{
                fetch(`https://server.kando-proto-3.com/update-search-filter-search_term-qty-only`,{
                    method:'PUT',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:params
                })
                .then(resp=>resp.json())
                .then(({dbResponseMsg}:DbResponse)=>{
                    //console.log('dbrm:', dbResponseMsg);
                    setShowProcessingRequestMsg(false);
                    setShowStoreStructureLoadingMsg(false);
                    if(dbResponseMsg === 'record updated'){
                        //call this function to close the component
                        handleSaveSearchTerm(
                            searchFilterAreaTypeSelected,
                            searchFilterAreaNameSelected,
                            searchFilterNameSelected,
                            searchTermNameSelected,
                            searchTermSelected,
                            showSearchTerm
                                );

                    } else {
                        throw new Error('search term recored was not updated.');
                    }   
                    
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                })
                .catch((err)=>{
                    console.error('add search term fetch error:', err);
                });

            }
        })
        .catch((err:React.ErrorInfo)=>{
            console.error('is search term unique fetch error:', err);

        });

    }

    /*
    function getSearchFilterNames(
                        searchFilterAreaTypeNameSelected:string,
                        searchFilterAreaNameSelected:string
                                ):string[] {
        let searchFilterNames:string[] = [];
        const sfs = searchFilters.filter((sf)=>{
                        return ((sf.searchFilterAreaType === searchFilterAreaTypeNameSelected)
                                && (sf.searchFilterArea === searchFilterAreaNameSelected))
                    });
        
        sfs.forEach((sf)=>{
            searchFilterNames.push(sf.filterName);
        });

        return searchFilterNames;
    }
    */

    function handleAddSearchFilterName(e:React.ChangeEvent):void {
        const searchFilterName:string = (e.target as HTMLInputElement).value;
        searchFilterName === 'none' ? setShowSearchFilterNameErrorMsg(true) : setShowSearchFilterNameErrorMsg(false);
        setSearchFilterNameSelected(searchFilterName);
    }

    function handleAddSearchTermName(e:React.ChangeEvent):void {
        const searchTermName:string = (e.target as HTMLInputElement).value;
        /*previous version
        if(searchTermName === ''){
            setShowSearchTermNameErrorMsg(true);
            setSearchTermNameErrorMsg('Please add a search term name');
        } else {
            setShowSearchTermNameErrorMsg(false);
        }
        
        setSearchTermNameSelected(searchTermName);
        */


        //console.log('value.length:', searchTermName.length);
            
        if(searchTermName.length === 0){
            setShowSearchTermNameLimitError(false);
            setShowSearchTermNameErrorMsg(true);
            setSearchTermNameErrorMsg('Please add a search term name');
            setSearchTermNameSelected(searchTermName);

        } else if(searchTermName.length > 0 && searchTermName.length <= 20){
            setShowSearchTermNameErrorMsg(false);
            setShowSearchTermNameLimitError(false);
            setSearchTermNameSelected(searchTermName);

        } else {
            setShowSearchTermNameErrorMsg(false);
            setShowSearchTermNameLimitError(true);
        }

    }

    function handleSearchTermSelected(e:React.ChangeEvent):void {
        setSearchTermSelected((e.target as HTMLInputElement).checked);
    }

    function handleShowSearchTerm(e:React.ChangeEvent):void {
        setShowSearchTerm((e.target as HTMLInputElement).checked);
    }

    function handleCancelAddSearchTerm(e:React.MouseEvent):void {
        handleCancelSearchTerm();

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    }

    function handleShowSearchTermImg(e:React.ChangeEvent):void {
        setShowSearchTermImg((e.target as HTMLInputElement).checked);
    }

    function handleAddSearchTermImg(e:React.ChangeEvent):void {
        const stImg = (e.target as HTMLInputElement).value;
        const stFileName = stImg.slice(stImg.lastIndexOf('\\') + 1);
        //console.log('stfn:', stFileName);
        //console.log('search term img:', (e.target as HTMLInputElement).value);
        setSearchTermImgSelected(stFileName);
    }

    if(showAddSearchTerm){
        return <div>
                    {/*<StoreStructureLoadingMsg
                        showStoreStructureLoadingMsg={showStoreStructureLoadingMsg}
                        storeStructureLoadingMsg={storeStructureLoadingMsg}
                    />*/}
                    {/*<StoreStructureAlert 
                        showStoreStructureAlert={showStoreStructureAlert}
                        storeStructureAlertMsg={storeStructureAlertMsg}
                        handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />*/}
                    <h1 className="add-search-term-stand-alone-heading">Add Search Term</h1>
                    <form className="add-dpt-category-form" onSubmit={handleSubmitSearchTermForm}>
                        {showDepartment ? 
                        <div className="add-search-term-stand-alone-input-form-group">
                            <label htmlFor="dpt-name">Department Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={deptNameSelected}
                                    optionNames={deptSearchFilterNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterDeptNameErrorMsg}
                                    selectId={"dpt-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showDepartmentCategory ?
                        <div className="add-search-term-stand-alone-input-form-group">
                            <label htmlFor="dpt-category-name">Department Category Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={deptCategoryNameSelected}
                                    optionNames={deptCategorySearchFilterNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterDeptCategoryNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptCategoryNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showProductCategory ?
                        <div className="add-search-term-stand-alone-input-form-group">
                            <label htmlFor="product-category-name">Product Category Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={productCategoryNameSelected}
                                    optionNames={productCategorySearchFilterNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterProductCategoryNameErrorMsg}
                                    selectId={"product-category-name"}
                                    handleOptionNameSelection={handleProductCategoryNameSelection}
                            />
                        </div>
                        : null
                        }
                        <div className="add-search-term-stand-alone-input-form-group">
                            <label htmlFor="search-filter-name">Search Filter Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={searchFilterNameSelected}
                                    optionNames={searchFilterNames}
                                    showErrorMsg={showSearchFilterNameErrorMsg}
                                    errorMsg={searchFilterNameErrorMsg}
                                    selectId={"search-filter-name"}
                                    handleOptionNameSelection={handleAddSearchFilterName}
                            />
                        </div>
                        <div className="add-search-term-stand-alone-input-form-group">
                            <label htmlFor="search-term-name">Search Term Name</label>
                            <input type="text" name="search-term-name" id="search-term-name"
                             className={showSearchTermNameErrorMsg ? 'form-input-control show-search-term-name-input-error-border' : 'form-input-control hide-search-term-name-input-error-border'}
                             value={searchTermNameSelected} onChange={handleAddSearchTermName}/>
                            {
                                showSearchTermNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (20)</p>
                                :
                                null
                            }

                             {
                                showSearchTermNameErrorMsg 
                                ? <div className="show-search-term-name-error-msg">
                                        {searchTermNameErrorMsg}
                                    </div>
                                : 
                                null
                              }

                        </div>
                        {/*<div className="add-search-term-stand-alone-file-form-group">
                            <label htmlFor="search-term-img">Search Term Image</label>
                            <input type="file" name="search-term-img-name" onChange={handleAddSearchTermImg}/>
                        </div>*/}
                        {/*<div className="add-search-term-stand-alone-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="search-term-selected" id="search-term-selected"
                              checked={searchTermSelected} onChange={handleSearchTermSelected}/>
                            <label htmlFor="search-term-selected">Search Term Selected</label>
                        </div>*/}
                        <div className="add-search-term-stand-alone-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="show-search-term" id="show-search-term"
                              checked={showSearchTerm} onChange={handleShowSearchTerm}/>
                            <label htmlFor="show-search-term">Show Search Term</label>
                        </div>
                        {/*<div className="add-search-term-stand-alone-checkbox-form-group">
                            <input type="checkbox" name="show-search-term-img" id="show-search-term-img"
                              checked={showSearchTermImg} onChange={handleShowSearchTermImg}/>
                            <label htmlFor="show-search-term-img">Show Search Term Image</label>
                        </div>*/}
                        <div className="product-form-submit-btn-container">
                            <button type="submit" className="add-search-term-stand-alone-save-btn">Save</button>
                            <button type="button" className="add-search-term-stand-alone-cancel-btn"
                                onClick={handleCancelAddSearchTerm}>
                                    Cancel
                            </button>

                            {
                                (showProcessingRequestMsg)
                                ?
                                <div className="process-request-msg">
                                    Processing...
                                </div>
                                :
                                null
                            }
                        </div>
                    </form>
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>;
    }
}


export default AddSearchTermStandAlone;