import React, {useState, useEffect} from 'react';
import {selectShoppingCart, addProductsToMiniCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import './EmptyCartAlertMsg.css';

interface Props {}

const EmptyCartAlertMsg:React.FunctionComponent<Props> = (props:Props)=>{
    //need to add background overlay
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const dispatch = useAppDispatch();
    const [emptyCartAlertMsgStyleClass, setEmptyCartAlertMsgStyleClass] = useState('empty-cart-alert-msg-background-shade-container-hide');

    //console.log('sceam:', shoppingCartRedux);
    useEffect(()=>{
        if(shoppingCartRedux.showEmptyCartAlertMsg){
            setEmptyCartAlertMsgStyleClass('empty-cart-alert-msg-background-shade-container');

        } else {
            setEmptyCartAlertMsgStyleClass('empty-cart-alert-msg-background-shade-container-hide');
        }

    },[shoppingCartRedux]);


    function closeEmptyCartAlertMsg(e:React.MouseEvent):void {
        e.stopPropagation();
        setEmptyCartAlertMsgStyleClass('empty-cart-alert-msg-background-shade-container-hide');
        shoppingCartRedux.itemLimitExceeded = false;
        shoppingCartRedux.showItemLimtExceededErrorMsg = false;
        shoppingCartRedux.showEmptyCartAlertMsg = false;
        const sc = Object.assign({}, shoppingCartRedux);
        dispatch(addProductsToMiniCart(sc));

    }

    function emptyShoppingCart(e:React.MouseEvent):void {
        e.stopPropagation();
        setEmptyCartAlertMsgStyleClass('empty-cart-alert-msg-background-shade-container-hide');
        shoppingCartRedux.shoppingCartCategories = [];
        shoppingCartRedux.shoppingCartProductAdded = false;
        shoppingCartRedux.shoppingCartProductRemoved = false;
        shoppingCartRedux.showShoppingCart = false;
        shoppingCartRedux.itemLimitExceeded = false;
        shoppingCartRedux.showItemLimtExceededErrorMsg = false;
        shoppingCartRedux.showEmptyCartAlertMsg = false;
        shoppingCartRedux.fullCartSelections = [];

        const sc = Object.assign({}, shoppingCartRedux);

        dispatch(addProductsToMiniCart(sc));
    }


    return  <div className={`${emptyCartAlertMsgStyleClass}`}>
                <div className="empty-cart-alert-msg-container">
                    <h2 className="empty-cart-alert-msg-heading">Empty your cart?</h2>
                    <p className="empty-cart-alert-msg">This will remove all items from your cart.</p>
                    <div className="empty-cart-alert-msg-close-btn-container">
                        <button type="button" className="empty-cart-alert-msg-cancel-btn"
                            onClick={closeEmptyCartAlertMsg}>Cancel</button>
                        <button type="button" className="empty-cart-alert-msg-empty-btn"
                            onClick={emptyShoppingCart}>Empty</button>
                    </div>
                </div>
            </div>

}

export default EmptyCartAlertMsg;