class Card {
    public cardId:number;
    public homePageLayoutId:number;
    public selectedForRemoval:boolean;
    public heading:string;
    public caption:string;
    public desktopSource:string;

    constructor(options:{
        cardId?:number,
        homePageLayoutId?:number,
        selectedForRemoval?:boolean,
        heading?:string,
        caption?:string,
        desktopSource?:string
    }={}){
        this.cardId = options.cardId || 0;
        this.homePageLayoutId = options.homePageLayoutId || 0;
        this.selectedForRemoval = options.selectedForRemoval || false;
        this.heading = options.heading || '';
        this.caption = options.caption || '';
        this.desktopSource = options.desktopSource || '';
    }
}

export default Card;