import React, {useEffect, useRef} from 'react';
import { useLocation } from "react-router-dom";
import CategoryShifter2 from './CategoryShifter2';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import './WhatsNew.css';

interface Props {
    //path?:string;
}

const WhatsNew:React.FunctionComponent<Props> = (props:Props)=>{
    //console.log('whats new');
    const location = useLocation();
    //console.log('loc:', location);

    return (
        <div className="whats-new-container">
            <ProductSearch/>
            <MainNavbar2/>
            <MenuListMobileNav/>
            <div className="whats-new-image-container">
                {/*<img src="images/whats-new/whatsNewHeroBanner-Feb-2020-1920x400.jpg" className="whats-new-image" />*/}
                <img src="https://server.kando-proto-3.com/whats-new/whatsNewHeroBanner-Feb-2020-1920x400.jpg" alt={"What's new banner"} className="whats-new-image" />
            </div>
            {/*<CategoryShifter />*/}
            <CategoryShifter2 />
        </div>
    )
}

export default WhatsNew;