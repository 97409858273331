import Review from './review';

export default function addProductReview(productReview:Review):Promise<Response> {
    const params = JSON.stringify(productReview);
    //previous version
    //return fetch(`http://localhost:9500/add-product-review-mariadb/${JSON.stringify(productReview)}`, {
    return fetch(`https://server.kando-proto-3.com/add-product-review-mariadb`, {
    //original version
    //return fetch(`http://localhost:9500/add-product-review/${JSON.stringify(productReview)}`, {
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:params
            });

}