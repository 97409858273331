import React, {useState, useEffect, useRef} from 'react';
import StoreLocation from './storeLocation';
import {Link} from '@reach/router';
import ButtonCheckSelector from './ButtonCheckSelector';
import ListChanger from './ListChanger';
import MapDisplay from './MapDisplay';
import StoreLocationList from './StoreLocationList';
import StoreLocationMap from './StoreLocationMap';
import StoreSearchMessage from './StoreSearchMessage';
import LoaderAnimation from './LoaderAnimation';
import './StoreLocationDisplay.css';

interface Props {
    itemsList:StoreLocation[] | null;
    storeLocationList:StoreLocation[] | [];
    storeLocation:StoreLocation;
    myStoreName:string;
    storeLocationSearch:string;
    storeLocationSearchPhrase:string;
    storeLocationSearchErrorMessage:string;
    storeLocationSearchMessage:string;
    listChangerBtnPosition:number;
    itemsPerList:number;
    showStoreLocatorErrorMsg:boolean;
    getListChangerBtnPosition:(listChangerBtnPosition:number)=>void;
    handleStoreSearch:(e:React.ChangeEvent<HTMLInputElement>)=>void;
    handleSubmitStoreSearch:(e:React.FormEvent)=>void ;
    centerMap:(storeLocation:StoreLocation)=>void;
    setMyCheckedStoreName:(storeNameChecked:string)=>void;
    showItemsPerList:(itemsList:StoreLocation[])=>void;
    showLocationMap:boolean;
    stCallback?:(e:React.MouseEvent, pathname:string)=>void;
}

const StoreLocationDisplay:React.FunctionComponent<Props> = (props:Props)=>{
    const {itemsList, myStoreName, storeLocationList, storeLocationSearch, 
        storeLocationSearchPhrase, storeLocationSearchErrorMessage, storeLocationSearchMessage,
        listChangerBtnPosition, itemsPerList, showStoreLocatorErrorMsg, getListChangerBtnPosition,
        handleSubmitStoreSearch, handleStoreSearch, centerMap, setMyCheckedStoreName, showItemsPerList, stCallback} = props;

        const [storeLocation, setStoreLocation] = useState(props.storeLocation);
        const [showLocationMap, setShowLocationMap] = useState(props.showLocationMap);

        //const showLocationMapRef = useRef<boolean>(false);

        //console.log('itemList:', itemsList);
        //console.log('result:', showStoreLocatorErrorMsg);

        useEffect(()=>{
            if(window.innerWidth >= 1024){
                setShowLocationMap(props.showLocationMap);
            }
            
        },[props.showLocationMap]);

        useEffect(()=>{
            //console.log('il7:');
            setStoreLocation(props.storeLocation);
                    
        },[props.storeLocation]);

        //console.log('il7:', storeLocation.storeLocationId, props.storeLocation.storeLocationId);

        useEffect(()=>{
            window.addEventListener('resize', storeLocationDisplayResizeHandler);
            return cleanupStoreLocationDisplayResizeHandler;
        },[]);

        function storeLocationDisplayResizeHandler(e:Event):void {
            if(window.innerWidth >= 1024){
                setShowLocationMap(true);

            } else {
                setShowLocationMap(false);
            }
        }

        function cleanupStoreLocationDisplayResizeHandler():void {
            window.removeEventListener('resize', storeLocationDisplayResizeHandler);
        }
    
    //if(itemsList !== null){
        return <div className="store-location-display-container">
                    {/*{(itemsList as StoreLocation[]).length > 0 ?*/}
                    <div className="store-locator-input-form-container">
                        
                        <form className="store-locator-input-form" onSubmit={handleSubmitStoreSearch}>
                            <div className="store-locator-input-control-container">
                                <input className="store-locator-input-control" type="text"
                                    id="store-locator-input-control"
                                    placeholder="Search by city or zip code"
                                    onChange={handleStoreSearch} value={storeLocationSearchPhrase} required={true}/>
                            </div>
                            <button type="submit" className="store-locator-input-form-btn">Search</button>
                        </form>

                        <StoreSearchMessage storeLocationList={storeLocationList}
                                storeLocationSearchMessage={storeLocationSearchMessage}
                                storeLocationSearch={storeLocationSearch} storeLocationSearchPhrase={storeLocationSearchPhrase}/>


                        {storeLocationSearchErrorMessage !== 'no error message'
                            ?
                            <div className="store-locatation-not-found-message-container">
                                {`No store found for address "${storeLocationSearch}" within 100 miles.`}
                            </div>
                            : null
                        }

                        <StoreLocationList itemsList={itemsList} myStoreName={myStoreName} showListChanger={true}
                            storeLocationList={storeLocationList} showItemsPerList={showItemsPerList}
                            showStoreLocatorErrorMsg={showStoreLocatorErrorMsg}
                            centerMap={centerMap} storeLocationSearchMessage={storeLocationSearchMessage} itemsPerList={itemsPerList}
                            listChangerBtnPosition={listChangerBtnPosition} getListChangerBtnPosition={getListChangerBtnPosition}
                            setMyCheckedStoreName={setMyCheckedStoreName} sdCallback={stCallback}/>

                    </div>
                    {
                        showLocationMap
                        ?
                        <div className="store-location-display-map-location-container">
                            <StoreLocationMap storeLocation={storeLocation} storeLocationList={itemsList!}/>
                        </div>
                        :
                        <div className="sld-loader-animation-container">
                            <LoaderAnimation/>
                        </div>
                    }

                    {/*<ListChanger items={storeLocationList} itemsPerList={4} showItemsPerList={showItemsPerList}/>*/}
                </div>

    //} else {
        
    //}
 

}


export default StoreLocationDisplay;