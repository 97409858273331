import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
//import {Link, navigate, useMatch} from '@gatsbyjs/reach-router';
import { Link, useMatch, useLocation } from 'react-router-dom';
import helperHighlightDepartment from './helper-highlight-department';
import ShopLoadDetails from './redux/shopLoadDetails';
//import {AddShopLoadDetails, addShopLoadDetails, AddProductKeywordSearch, addProductKeywordSearch} from './redux/actions';
import {addShopLoadDetails, selectShopLoadDetails} from './redux/features/shopLoadDetails/shopLoadDetailsSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import ShopProductMiniCart from './ShopProductMiniCart';
import HighlightDepartmentText from './HighlightDepartmentText';
import Product from './product';
import helperSetOptionValue, {helperSetOptionValueNow} from './helper-set-option-value';
import addDepartmentCategories, {addSearchFilters} from './helper-add-department-categories';
import ShopProductDetail from './ShopProductDetail';
import helperHighlightCategory from './helper-highlight-category';
import PriceSlider3 from './PriceSlider3';
import {sortByAtoZ, sortByZtoA, sortByNewest, sortByRatings, 
    sortByPriceHightoLow, sortByPriceLowtoHigh} from './helper-sort-by-option';
import SearchFilterSidebar from './SearchFilterSidebar';
import SearchFilter from './searchFilter';
import {checkSearchFiltersSelected, updateSearchFilters2} from './helper-search-filter-setup';
import cloneDeep from 'lodash/cloneDeep';
import ProductKeywordSearch from './redux/productKeywordSearch';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import LoaderAnimation from './LoaderAnimation';
import ScrollToTopButton from './ScrollToTopButton';
import formatLinkString from './helper-format-link-string';
import './ShopDepartment.css';

interface Props {
    //path:string;
    storeDepartment:StoreDepartment;
    storeDepartmentName:string;
    storeDepartmentId:string;
    //getSliderAmount:(products:Product[])=>void;
    departmentProducts:Product[];
    keywordSearchType:string;
    sortByOptionValue:string;
    sbatProductMobileNavFilterHandler:()=>void;
    sbatProductMobileNavSortByOptionHandler:(sbov:string)=>void;
    loadNavLink:(e:React.MouseEvent, pathname:string, showLoader?:boolean)=>void;
}

interface ProductSelection {
    id:number;
}


const ShopByAreaTypeStoreDepartment:React.FunctionComponent<Props> = (props:Props)=>{
    //console.log('match:', useMatch('/shop/:storeDepartmentName/:storeDepartmentId'));
    const location = useLocation();
    //const {getSliderAmount} = props;
    const {sbatProductMobileNavFilterHandler, sbatProductMobileNavSortByOptionHandler, loadNavLink} = props;
    //const shopLoadDetailsRedux = useAppSelector(selectShopLoadDetails);
    //console.log('sldredux:', shopLoadDetailsRedux, 'props:', props);
    //const {addShopLoadDetails, addProductKeywordSearch} = props;

    //console.log('store department props:', props);
    const [storeDepartments, setStoreDepartments] = useState<StoreDepartment[] | []>([]);
    const [storeDepartment, setStoreDepartment] = useState<StoreDepartment | null>(null);
    const [storeDepartmentId, setStoreDepartmentId] = useState(props.storeDepartmentId);
    //original version
    const [storeDepartmentName, setStoreDepartmentName] = useState(props.storeDepartmentName);
    //const [storeDepartmentName, setStoreDepartmentName] = useState('none');
    const [showShopStoreDepartment, setShowShopStoreDepartment] = useState(false);
    const [controller, setController] = useState<AbortController>(new AbortController());

    //const [shopLoadDetails, setShopLoadDetails] = useState(shopLoadDetailsRedux);

    const [showDepartmentProducts, setShowDepartmentProducts] = useState(false);
    const [departmentProducts, setDepartmentProducts] = useState<Product[] | []>([]);
    const [sortByOptionValue, setSortByOptionValue] = useState(props.sortByOptionValue);
    const [departmentProductTotal, setDepartmentProductTotal] = useState(0);
    const [keywordSearchType, setKeywordSearchType] = useState('results');

    const [startSliderAmount, setStartSliderAmount] = useState(0);
    const [endSliderAmount, setEndSliderAmount] = useState(0);
    const [showProductCategoryProducts, setShowProductCategoryProducts] = useState(false);
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState('0%');
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState('100%');
    const [previousSlider1PostionLeftAmount, setPreviousSlider1PostionLeftAmount] = useState(0);
    const [previousSlider2PostionLeftAmount, setPreviousSlider2PostionLeftAmount] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(0);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(100);
    const [slider1ZIndex, setSlider1ZIndex] = useState(0);
    const [slider2ZIndex, setSlider2ZIndex] = useState(0);
    const [keywordSearch, setKeywordSearch] = useState(props.storeDepartmentName);
    const [showPriceSlider, setShowPriceSlider] = useState(false);
    //const [productCategoryName, setProductCategoryName] = useState('none');

    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [initialSearchFilters, setInitialSearchFilters] = useState<SearchFilter[]>([]);
    //const [searchTermNamesSelected, setSearchTermNamesSelected] = useState<string[]>([]);
    //const [searchFilterNamesSelected, setSearchFilterNamesSelected] = useState<string[]>([]);
    const [searchTermIsSelected, setSearchTermIsSelected] = useState(false);
    const [showSpecialProductCategoryShortcut, setShowSpecialProductCategoryShortcut] = useState(true);

    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    const [hasDepartmentCategories, setHasDepartmentCategories] = useState<boolean>(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(60);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Product[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No store locations have been added.');

    const [showMiniCart, setShowMiniCart] = useState(false);
    const showMiniCartRef = useRef<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);

    //console.log('sortByOptionValue:', props.sortByOptionValue, sortByOptionValue);
    //console.log('sbatd:', 'sd:', props.storeDepartment, 'sdn:', props.storeDepartmentName, 'sdid:', props.storeDepartmentId, 'dps:', props.departmentProducts, 'kst:', props.keywordSearchType);

    //const [productSelection, setProductSelection] = useState<ProductSelection>(getPreviousProductSelection(sessionStorage.getItem('sbat-product-selection')));
    //const departmentProductContainerRefs = useRef<HTMLDivElement[]>([]);


    useEffect(()=>{                
        //setShowShopStoreDepartment(false);
        setShowShopStoreDepartment(true);
        setSortByOptionValue(props.sortByOptionValue);
        //setDepartmentProducts(props.departmentProducts);
        setDepartmentProductTotal(props.departmentProducts.length);
        setStoreDepartment(props.storeDepartment);
        setKeywordSearchType(props.keywordSearchType);
        /*previous version
        helperSetOptionValue(
            props.sortByOptionValue, 
            props.departmentProducts, 
            false,
            setDepartmentProducts, 
            setShowDepartmentProducts
            );
            */
        const hsovps = helperSetOptionValueNow(props.sortByOptionValue, props.departmentProducts);
        setDepartmentProducts(hsovps);
        setShowDepartmentProducts(true);
        getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, hsovps);

        //setDepartmentProducts([]);
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        if(window.innerWidth >= 768 && !showMiniCartRef.current){
            showMiniCartRef.current = true;
            setShowMiniCart(true);
        }

        if(window.innerWidth < 768 && showMiniCartRef.current){
            showMiniCartRef.current = false;
            setShowMiniCart(false);
        }

        
        
    },[props.departmentProducts]);


    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }
    

    function getImageRef(el:HTMLImageElement|null):void {       
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }


    /*
    function getDepartmentProductContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && departmentProductContainerRefs.current.length > 0){
            departmentProductContainerRefs.current.length = 0;
        }

        if(el !== null && departmentProductContainerRefs.current.length === 0){
            departmentProductContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = departmentProductContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                departmentProductContainerRefs.current.push(el);
            }

        }

        console.log('dpcrs:', departmentProductContainerRefs.current);
        
        const foundIdElement = departmentProductContainerRefs.current.filter((el)=>{
                                    return el.id === productSelection.id.toString();
                                });
        
        console.log('fie:', foundIdElement);
        if(foundIdElement.length === 1){
            foundIdElement[0].scrollIntoView({behavior:'smooth'});

        } 
        
    }

    function getPreviousProductSelection(previousProductSelection:string|null):ProductSelection {
        if(previousProductSelection === null){
            return {id:0};

        } else {
            const pps = JSON.parse(previousProductSelection);
            console.log('pps:', pps);
            return {id:pps};
        }
    }

    function setProductSelectionId(productSelectionId:number):void {
        sessionStorage.setItem('sbat-product-selection', `${productSelectionId}`);
    }
    */


    /*
    useEffect(()=>{
        window.addEventListener('resize', shopByAreaTypeResizeHandler)
        return cleanupShopByAreaTypeResizeHandler;
    },[])

    function cleanupShopByAreaTypeResizeHandler():void {
        window.removeEventListener('resize', shopByAreaTypeResizeHandler);
    }

    function shopByAreaTypeResizeHandler(e:Event):void {
        if(window.innerWidth >= 768 && !showMiniCartRef.current){
            showMiniCartRef.current = true;
            setShowMiniCart(true);
        }

        if(window.innerWidth < 768 && showMiniCartRef.current){
            showMiniCartRef.current = false;
            setShowMiniCart(false);
        }
    }
    */


    function getDepartmentProducts(storeDepartment:StoreDepartment):Product[]{
        let departmentProducts:Product[] = [];
        storeDepartment.departmentCategories.forEach((departmentCategory)=>{
            departmentCategory.productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    departmentProducts.push(product);
                });
            });

        });
        return departmentProducts;

    }


    function addDepartmentProducts(departmentProducts:Product[]):Product[] {
        return addProducts(departmentProducts);
    }

    function addProducts(products:Product[]):Product[]{
        //console.log('p 5-3:', products);
        setDepartmentProductTotal(products.length);
        return products.map((product)=>{
            return new Product({...product});
        });
    }

    
    function sortByOptionSelected(
                    e:React.ChangeEvent, 
                    departmentProducts:Product[],
                    selectedStoreDepartmentName:string,
                    selectedDepartmentCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedDepartmentCategoryId:number
                                ):void {
        //console.log('sortByOptionSelection:', (e.target as HTMLSelectElement).value);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        setShowDepartmentProducts(false);
        setSortByOptionValue((e.target as HTMLSelectElement).value);
        const selectedOption:string =  (e.target as HTMLSelectElement).value;
        helperSetOptionValue(
                selectedOption, 
                departmentProducts, 
                true,
                setDepartmentProducts, 
                setShowDepartmentProducts
                );

        sbatProductMobileNavSortByOptionHandler(selectedOption);

        getAnyItemList(listChangerBtnPosition, departmentProducts, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, departmentProducts);



    }

    function loadStoreDepartmentCategory(e:React.MouseEvent, storeDepartmentName:string, departmentCategory:DepartmentCategory, pathname:string):void {
        //e.preventDefault();
        e.stopPropagation();

        if(location.pathname === decodeURIComponent(pathname)){
            return;

        } else {
            loadNavLink(e, pathname, true);
        }
        
    }

    /*
    function scrollToTopCallback():void {
        const root = document.documentElement;
        root.scrollIntoView({behavior:'smooth'});
    }
    */


    /***test area for special department */
    function setupSpecialStoreDepartmentCategories(storeDepartmentName:string, departmentCategories:DepartmentCategory[]):JSX.Element | null{
        if(departmentCategories.length > 0){
            return  <ul className="shop-special-department-category-sidebar-list">
                        {departmentCategories.map((departmentCategory, index)=>{
                            //console.log('dcn 4-23:', departmentCategory);
                            return <li key={index} className="shop-special-department-category-sidebar-list-item"
                                        onClick={(e:React.MouseEvent)=>loadStoreDepartmentCategory(e, storeDepartmentName, departmentCategory, `/shop/${storeDepartmentName}/${departmentCategory.departmentCategoryName}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`)}>
                                        <Link to={`/shop/${storeDepartmentName}/${departmentCategory.departmentCategoryName}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`}
                                            className="shop-special-department-category-sidebar-list-item-link">
                                            {departmentCategory.departmentCategoryName}
                                        </Link>
                                    </li>
                        })}                            
                    </ul>

        } else {
            return null;
        }


    }


    function setupSpecialDepartmentCategorySearchShortcut(storeDepartment:StoreDepartment):JSX.Element | null {
        //console.log('sd8:', storeDepartment);
        if(storeDepartment.showDepartmentCategoryShortcut){
            return <ul className="shop-special-store-department-product-category-search-shortcut">
                        {
                            storeDepartment.departmentCategories.map((departmentCategory, index)=>{
                                return <li key={index}>
                                            <Link to={`/shop/${encodeURIComponent(formatLinkString(departmentCategory.departmentName))}/${encodeURIComponent(formatLinkString(departmentCategory.departmentCategoryName))}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`}
                                                className="shop-special-store-department-product-category-search-shortcut-item-link"
                                                onClick={(e:React.MouseEvent)=>loadStoreDepartmentCategory(e, storeDepartment.departmentName, departmentCategory, `/shop/${encodeURIComponent(formatLinkString(departmentCategory.departmentName))}/${encodeURIComponent(formatLinkString(departmentCategory.departmentCategoryName))}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`)}>
                                                <div className="shop-special-store-department-product-category-search-shortcut-image-container">
                                                    <img className="shop-special-store-department-product-category-search-shortcut-image"
                                                        alt={departmentCategory.departmentName}
                                                        src={`https://server.kando-proto-3.com/${departmentCategory.departmentCategoryImage}`}/>
                                                </div>
                                                <div className="shop-special-store-department-product-category-search-shortcut-title">
                                                    {departmentCategory.departmentCategoryName}
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>

        } else {
            return null;
        }

    }

    function setupDepartmentProducts(itemsList:object[], optionalCategory?:object):JSX.Element{
            //console.log('sbatd:', itemsList);
            
        if(((optionalCategory as StoreDepartment) !== null && itemsList.length === 0)
            /*|| (storeDepartmentCategory.departmentCategoryName !== departmentCategoryProducts[0].departmentCategoryName)*/){
            //console.log('sdc:', storeDepartmentCategory);
            
            let itemsList:Product[] = getDepartmentProducts((optionalCategory as StoreDepartment));
            
            //console.log('dp2:', itemsList);
            setDepartmentProducts([...itemsList]);


            //get start and end price amounts from each product
            //test code without having getSliderAmount
            //if needed after testing uncomment this code line#340-345 below
            /*
            getSliderAmount([...itemsList]);
            setSliderPosition(0);
            setPreviousSlider1TotalDistance(0);
            setPreviousSlider2TotalDistance(100);
            */

            //setShowDepartmentCategoryProducts(true);
            //setProductResultCount(departmentCategoryProducts.length);
            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper" /*id={product.productId.toString()}
                                                ref={getDepartmentProductContainerRef} onClick={()=>setProductSelectionId(product.productId)}*/>
                                                <ShopProductDetail product={product} getImageRef={getImageRef} sbtNavLink={loadNavLink}/>
                                            </div> 
                        })}
                    </div>

        } else {
            //console.log('dp3:', itemsList);

            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper" /*id={product.productId.toString()} 
                                                ref={getDepartmentProductContainerRef} onClick={()=>setProductSelectionId(product.productId)}*/>
                                                <ShopProductDetail product={product} getImageRef={getImageRef} sbtNavLink={loadNavLink}/>
                                            </div> 
                        })}

                        {/*<ScrollToTopButton scrollToTopCallback={scrollToTopCallback}/>*/}
                    </div>
        }

    }


    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as Product[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function displayListSorter(itemsPerList:number, ps:Product[]):void {
        if(ps.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function productMobileNavHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        //shopProductsMobileNavRef.current!.style.visibility = 'visible';
        //shopProductsMobileNavRef.current!.style.transform = 'translateY(0%)';  
        sbatProductMobileNavFilterHandler();
    }


    function updateDepartmentProducts(dps:Product[]):void {
        setDepartmentProducts([...dps]);

        getAnyItemList(listChangerBtnPosition, dps, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, dps);
    }


    if(storeDepartment !== null && keywordSearchType === 'noProductsAdded' && storeDepartment.isSpecialDepartment){            
        //console.log('test failed');
        return (
            <div className="shop-by-area-type-products-wrapper">
                <div className="shop-by-area-type-products-container">
                    <div>
                        {setupSpecialDepartmentCategorySearchShortcut(storeDepartment!)}
                    </div>
                    <div className="product-category-sort-by-heading">
                        <span className="sort-by-results-heading">{departmentProductTotal} {(departmentProductTotal === 1) ? 'result' : 'results'}</span>
                        <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                            <span className="sort-by-form-btn-text">
                                {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                Filter
                            </span>
                        </button>
                        <form className="sort-by-form">
                            <fieldset className="sort-by-form-group">
                                <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>{
                                                                        sortByOptionSelected(
                                                                                        e, 
                                                                                        departmentProducts,
                                                                                        storeDepartmentName!,
                                                                                        'none',
                                                                                        storeDepartment.departmentId,
                                                                                        0
                                                                                        )}}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price High to Low</option>
                                        <option value="Price Low to High">Price Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                </select>
                            </fieldset>
                        </form>
                    </div>
                    <div className="shop-product-category-products-error-message-container">
                        {/*hasDepartmentCategories
                            ?
                            <div className="shop-product-category-products-error-message">
                                <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                <div>{`There are no products setup. Please add some products to the ${storeDepartmentName} department.`}</div>
                            </div>
                            :
                            <div className="shop-product-category-products-error-message">
                                <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                <div>{`There are no department categories, product categories, product subcategories or products setup for this department.`}</div>
                            </div>
                        */}
                        <div className="shop-product-category-products-error-message">
                            <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                            <div>There are no products added to this department.</div>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else
 
    
    if(storeDepartment !== null && keywordSearchType === 'noResults' && storeDepartment.isSpecialDepartment){            
        //console.log('test failed');
        return (
            <div className="shop-by-area-type-products-wrapper">
                <div className="shop-by-area-type-products-container">
                    <div>
                        {setupSpecialDepartmentCategorySearchShortcut(storeDepartment!)}
                    </div>
                    <div className="product-category-sort-by-heading">
                        <span className="sort-by-results-heading">{departmentProductTotal} {(departmentProductTotal === 1) ? 'result' : 'results'}</span>
                        <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                            <span className="sort-by-form-btn-text">
                                {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                Filter
                            </span>
                        </button>
                        <form className="sort-by-form">
                            <fieldset className="sort-by-form-group">
                                <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>{
                                                                        sortByOptionSelected(
                                                                                        e, 
                                                                                        departmentProducts,
                                                                                        storeDepartmentName!,
                                                                                        'none',
                                                                                        storeDepartment.departmentId,
                                                                                        0
                                                                                        )}}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price High to Low</option>
                                        <option value="Price Low to High">Price Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                </select>
                            </fieldset>
                        </form>
                    </div>
                    <div className="shop-product-category-products-error-message-container">
                        <div className="shop-product-category-products-error-message">
                            <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                            <div>There are no products that match your search criteria. Please modify your criteria and try again.</div>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else if(storeDepartment !== null && storeDepartment.isSpecialDepartment && showShopStoreDepartment && showDepartmentProducts){
       //console.log('third render');
       //console.log('sbatd:', departmentProducts);
       return (<div className="shop-by-area-type-products-wrapper">
                    <div className="shop-by-area-type-products-container" style={{position:'relative'}}>
                        <div>
                            {setupSpecialDepartmentCategorySearchShortcut(storeDepartment!)}
                        </div>
                        <div className="product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{departmentProductTotal} {(departmentProductTotal === 1) ? 'result' : 'results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <div className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>{
                                                                            sortByOptionSelected(
                                                                                            e, 
                                                                                            departmentProducts,
                                                                                            storeDepartmentName!,
                                                                                            'none',
                                                                                            storeDepartment.departmentId,
                                                                                            0
                                                                                            )}}>
                                            <option value="Best Match">Best Match</option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                            <option value="Price High to Low">Price High to Low</option>
                                            <option value="Price Low to High">Price Low to High</option>
                                            <option value="Ratings">Ratings</option>
                                            <option value="Newest">Newest</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        {
                            (showNoSearchResultsFound && !isLoading)
                            ? 
                            <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                            :
                            null
                        }
                        {
                            (!showNoSearchResultsFound && !isLoading)
                            ?
                            <ListViewer itemsList={itemsList} items={departmentProducts} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition} optionalCategory={storeDepartment}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupDepartmentProducts} />
                            :
                            null
                        }
                        
                        {
                            (!showNoSearchResultsFound && isLoading)
                            ?
                            <div className="sbat-loader-animation-container">
                                {<LoaderAnimation/>}
                            </div>
                            : 
                            null
                            
                        }
                        
                    </div>
                </div>
       )
   }
    
    
    else {
        return  <div style={{width:'100%'}} >
                    <div className="sbat-loader-animation-container">
                        <LoaderAnimation/>
                    </div>
                </div>
    }
  
}

export default ShopByAreaTypeStoreDepartment;
