//import React from 'react';
import Product from './product'

/*
export default function sortByOption(
                        selectedOption:string,
                        productCategoryName:string
                        ):Promise<Response>{
                    switch(selectedOption){
                        case 'Best Match':
                            return getSortByResults(selectedOption, productCategoryName);
                        case 'A-Z':
                            return getSortByResults(selectedOption, productCategoryName);
                        case 'Z-A':
                            return getSortByResults(selectedOption, productCategoryName);
                        case 'Price:High to Low':
                            return getSortByResults(selectedOption, productCategoryName);
                        case 'Price:Low to High':
                            return getSortByResults(selectedOption, productCategoryName);
                        case 'Ratings':
                            return getSortByResults(selectedOption, productCategoryName);
                        case 'Newest':
                            return getSortByResults(selectedOption, productCategoryName);
                        default: throw new Error('no sort match found.');
                            
                    }
}

function getSortByResults(
            sortByOptionSelected:string,
            productCategoryName:string            
            ):Promise<Response> {
    return fetch(`http://localhost:9500/sort-by-option-selected/${productCategoryName}/${sortByOptionSelected}`,{
                method:'GET',
                headers:{
                    'Accept':'application/json'
                }
            });
}
*/

export function sortByAtoZ(a:Product, b:Product){
    //console.log('test passed again 4-25');
    const productNameA = a.productName.toUpperCase();
    const productNameB = b.productName.toUpperCase();
    if(productNameA < productNameB){
        return -1;
    } else if(productNameA > productNameB){
        return 1;
    } else {
        return 0;
    }
}

export function sortByZtoA(a:Product, b:Product){
    const productNameA = a.productName.toUpperCase();
    const productNameB = b.productName.toUpperCase();
    if(productNameB < productNameA){
        return -1;
    } else if(productNameB > productNameA){
        return 1;
    } else {
        return 0;
    }
}

export function sortByPriceHightoLow(a:Product, b:Product){
    return b.eachPrice - a.eachPrice;
}

export function sortByPriceLowtoHigh(a:Product, b:Product){
    return a.eachPrice - b.eachPrice;
}

export function sortByRatings(a:Product, b:Product){
    const ratingA = a.rating.toString();
    const ratingB = b.rating.toString();
    //console.log('ratingA:', ratingA, 'ratingB:', ratingB);
    if(ratingB < ratingA){
        return -1;
    } else if(ratingB > ratingA){
        return 1;
    } else {
        return 0;
    }
}

export function sortByNewest(a:Product, b:Product){
    const dateAddedA = a.productDateAdded.toString();
    const dateAddedB = b.productDateAdded.toString();
    if(dateAddedB < dateAddedA){
        return -1;
    } else if(dateAddedB > dateAddedA){
        return 1;
    } else {
        return 0;
    }
}