class ProductVerification {
            public productVerificationId:number;
            public productVerificationName:string;
            public productVerificationImage:string;
            constructor(options:{
                productVerificationId?:number,
                productVerificationName?:string,
                productVerificationImage?:string
            }={}){
                this.productVerificationId = options.productVerificationId || 0;
                this.productVerificationName = options.productVerificationName || '';
                this.productVerificationImage = options.productVerificationImage || '';
            }
        }

export class VerificationProduct {
        public verificationProductId:number;
        public productVerificationId:number;
        public productId:number;
        public productVerificationName:string;
        public productVerificationImage:string;
        constructor(options:{
            verificationProductId?:number,
            productVerificationId?:number;
            productId?:number,
            productVerificationName?:string,
            productVerificationImage?:string
        }={}){
            this.verificationProductId = options.verificationProductId || 0;
            this.productVerificationId = options.productVerificationId || 0;
            this.productId = options.productId || 0;
            this.productVerificationName = options.productVerificationName || '';
            this.productVerificationImage = options.productVerificationImage || '';
        }
    }

export default ProductVerification;