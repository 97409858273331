import React, {useState, useEffect, useRef} from 'react';
import ProductSubCategory from './productSubCategory';
import AddProductSubCategory from './AddProductSubCategory';

interface Props {
    productSubCategory:ProductSubCategory;
    handleCloseForm:(formType:string)=>void;
}

const EditProductSubCategory: React.FunctionComponent<Props> = (props:Props) => {
    const {productSubCategory, handleCloseForm} = props;

    return <AddProductSubCategory showEditProductSubCategory={true} productSubCategoryToEdit={productSubCategory} handleCloseForm={handleCloseForm}/>
}

export default EditProductSubCategory;