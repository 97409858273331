class Sbat {
    public type:string;
    public storeDepartmentName:string;
    public departmentCategoryName:string;
    public productCategoryName:string;
    public storeDepartmentId:string;
    public departmentCategoryId:string;
    public productCategoryId:string;

    constructor(options:{        
        type?:string,
        storeDepartmentName?:string,
        departmentCategoryName?:string,
        productCategoryName?:string,
        storeDepartmentId?:string,
        departmentCategoryId?:string,
        productCategoryId?:string
    }={}){
        this.type = options.type || '';
        this.storeDepartmentName = options.storeDepartmentName || '';
        this.departmentCategoryName = options.departmentCategoryName || '';
        this.productCategoryName = options.productCategoryName || '';
        this.storeDepartmentId = options.storeDepartmentId || '0';
        this.departmentCategoryId = options.departmentCategoryId || '0';
        this.productCategoryId = options.productCategoryId || '0';
    }
}

export default Sbat;