import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import ProductKeywordSearch from '../../productKeywordSearch';
import cloneDeep from 'lodash/cloneDeep';

interface MobileNav {
    showMobileNav:boolean;
    linkSelected:boolean;
    closeMobileNavImmediately?:boolean;
    closeShopByTypeNav?:boolean;
}

const initialState:MobileNav = {
    showMobileNav:false,
    linkSelected:false,
    closeMobileNavImmediately:false,
    closeShopByTypeNav:false
};

export const mobileNavSlice = createSlice({
  name: 'mobileNav',
  initialState,
  reducers: {
    showMobileNav: (state, action:PayloadAction<MobileNav>) => {
      state.showMobileNav = action.payload.showMobileNav;
      state.linkSelected = action.payload.linkSelected;
      state.closeMobileNavImmediately = action.payload.closeMobileNavImmediately;
      state.closeShopByTypeNav = action.payload.closeShopByTypeNav;
    }
  }
})

// Action creators are generated for each case reducer function
export const { showMobileNav } = mobileNavSlice.actions;

interface RootState {
  mobileNav: MobileNav
}

export const selectMobileNav = (state:RootState) =>  {
  //const clone = cloneDeep(state.productKeywordSearch);
  return state.mobileNav;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default mobileNavSlice.reducer;