import React, {useState, useEffect, useRef} from 'react';
import DepartmentCategory from './departmentCategory';
import AddDepartmentCategory from './AddDepartmentCategory';

interface Props {
    departmentCategory:DepartmentCategory;
    handleCloseForm:(formType:string)=>void;
}

const EditDepartmentCategory: React.FunctionComponent<Props> = (props:Props) => {
    const {departmentCategory, handleCloseForm} = props;

    return <AddDepartmentCategory showEditDepartmentCategory={true} departmentCategoryToEdit={departmentCategory} handleCloseForm={handleCloseForm}/>
}

export default EditDepartmentCategory;