import React, { useState, useEffect, useRef } from 'react';
import {Link, navigate} from '@gatsbyjs/reach-router';
import HomePageLayoutItem from './homePageLayoutItem';
import Product from './product';
import ShopProductDetail from './ShopProductDetail';
import './ProductDisplay.css';

interface Props {
    key?:string|number;
    product:Product;
    layoutAction:string;
    getImageRef:(el:HTMLImageElement|null)=>void;
    updateExistingHomePageLayoutItems?:(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string)=>void;
    hpCallback?:()=>void;
}

const ProductDisplay:React.FunctionComponent<Props> = (props: Props) => {
    const {getImageRef, updateExistingHomePageLayoutItems, hpCallback} = props;
    const [product, setProduct] = useState(props.product);
    const [layoutAction, setLayoutAction] = useState(props.layoutAction);
    const [productChecked, setProductChecked] = useState(setupProductCheckbox(props.product));

    //console.log('product:', product, 'props.product:', props.product, props.product === product);

    /*
    useEffect(()=>{
        if(props.product !== product){
            //console.log('card:');
            setProduct(props.product);
        }
    },[props.product]);
    */
    

    function setupProductCheckbox(product:Product):boolean {
        //console.log('cardId:', product.productId);
        return product.homePageLayoutId > 0 ? true : false;
    }

    function productCheckboxHandler(e:React.ChangeEvent):void {
        //const checkboxChecked = (e.target as HTMLInputElement).checked;
        //setCardChecked(checkboxChecked);
        const p = new Product({...props.product,
                            productId:props.product.productId})
        updateExistingHomePageLayoutItems!(p, 'carousel');
    }

    return <div style={{position:'relative'}} className="product-display-wrapper">
                {
                    (layoutAction === 'edit' && props.product.homePageLayoutId > 0 && !props.product.selectedForRemoval)
                    ?
                    <form className="hplid-checkbox-form">
                        <input className="hplid-checkbox-control" type="checkbox" id={`${props.product.productId}`} value={props.product.productId} 
                            checked={productChecked} onChange={productCheckboxHandler}/>
                    </form>
                    : null
                }
                {
                    (layoutAction === 'edit' && (props.product.homePageLayoutId === 0 || props.product.selectedForRemoval))
                    ?
                    <div className="ahpl-new-item-label">New</div>
                    : null
                }
                <ShopProductDetail product={props.product} getImageRef={getImageRef} hpCallback={hpCallback}/>
            </div>


}

export default ProductDisplay;