import React, {useEffect, useState} from 'react';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import Product from './product';
//import {Link, navigate} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from 'react-router-dom';
import addDepartmentCategories from './helper-add-department-categories';
import HighlightProductCategoryText from './HighlightProductCategoryText';
import PriceSlider3 from './PriceSlider3';
import PricePicker from './PricePicker';
import SearchFilterSidebar from './SearchFilterSidebar';
import SearchFilter from './searchFilter';
import {addSearchFilterParams, SearchFilterParams} from './redux/features/searchFilterParams/searchFilterParamsSlice';
import {useAppDispatch} from './redux/hooks';
import formatLinkString from './helper-format-link-string';

interface Props {
    productCategories:ProductCategory[];
    //storeDepartmentCategory:DepartmentCategory;
    isSpecialDepartmentCategory:boolean;
    storeDepartment:StoreDepartment;
    storeDepartmentName?:string;
    storeDepartmentCategory?:DepartmentCategory;
    departmentCategoryName?:string;
    setShowProductCategoryProducts?:(value: React.SetStateAction<boolean>) => void;
    clearAllExistingImages?:()=>void;
    hightlightProductCategoryText:(
        e:React.MouseEvent, 
        productCategoryName:string, 
        categoryType:string,
        storeDepartmentName:string,
        departmentCategoryName:string,
        selectedStoreDepartmentId:number,
        selectedProductCategoryId:number
    )=>void;
    reloadProductCategory:(
        e:React.MouseEvent, 
        storeDepartmentName:string,
        departmentCategoryName:string,
        productCategoryName:string,
        selectedStoreDepartmentId:number,
        selectedProductCategoryId:number
            )=>void;
    reloadDepartmentCategory:(
        e:React.MouseEvent, 
        storeDepartmentName:string,
        departmentCategoryName:string,
        selectedStoreDepartmentId:number,
        selectedDepartmentCategoryId:number
            )=>void;
    showPriceSlider:boolean;   
    //props for priceslider
    keywordSearch:string;
    startSliderAmount:number;
    endSliderAmount:number;
    previousSlider1PositionLeft:string;
    previousSlider2PositionLeft:string;
    previousSlider1PostionLeftAmount:number;
    previousSlider2PostionLeftAmount:number;
    sliderPosition:number;
    previousSlider1TotalDistance:number;
    previousSlider2TotalDistance:number;
    slider1ZIndex:number;
    slider2ZIndex:number;
    sortByOptionValue:string;
    priceSliderSearchResults:(
                            keywordSearch:string,
                            startSliderAmount:number,
                            endSliderAmount:number,
                            previousSlider1PositionLeft:string,
                            previousSlider2PositionLeft:string,
                            previousSlider1PostionLeftAmount:number,
                            previousSlider2PostionLeftAmount:number,
                            sliderPosition:number,
                            previousSlider1TotalDistance:number,
                            previousSlider2TotalDistance:number,
                            slider1ZIndex:number,
                            slider2ZIndex:number,
                            sortByOptionValue:string
                            )=>void;


    searchFilters:SearchFilter[];
    initialSearchFilters:SearchFilter[];
    searchTermIsSelected:boolean;
    storeDepartmentId:string;
    otherStoreAreaId:string;
    storeAreaName:string;
    storeAreaType:string;
    setSearchFilters:(value: React.SetStateAction<SearchFilter[]>) => void;
    //setDepartmentCategoryProducts:(value: React.SetStateAction<Product[]>) => void;
    setDepartmentCategoryProducts:(productCategoryProducts:Product[], kst?:string) => void;
    setDepartmentCategoryProductTotal:(value: React.SetStateAction<number>) => void;
    setShowSpecialProductCategoryShortcut:(value: React.SetStateAction<boolean>) => void;
    setSearchTermIsSelected:(value: React.SetStateAction<boolean>) => void;
    setKeywordSearchType:(value: React.SetStateAction<string>) => void;
    //addDepartmentCategoryProducts:(departmentCategoryProducts: Product[])=>Product[];
    addSearchFilters:(searchFilters: SearchFilter[])=>SearchFilter[];
    updateProductCategoryShortcutSearchFilter?:(showShortcut:boolean, sfps:SearchFilterParams|null)=>void;
    loadNavLink?:(e:React.MouseEvent, pathname:string)=>void;
    sssbatps?:React.Dispatch<React.SetStateAction<boolean>>;
    showSearchFilterSidebar?:boolean;
}

const StoreDepartmentCategorySidebarSelector2:React.FunctionComponent<Props> = (props:Props)=>{
    const {hightlightProductCategoryText, priceSliderSearchResults, reloadProductCategory, reloadDepartmentCategory, setShowProductCategoryProducts, clearAllExistingImages} = props;
        const {searchFilters, initialSearchFilters, searchTermIsSelected, storeDepartmentId, 
        otherStoreAreaId, storeAreaName, storeAreaType, setSearchFilters,
         setDepartmentCategoryProducts, setDepartmentCategoryProductTotal,
        setShowSpecialProductCategoryShortcut, setSearchTermIsSelected, setKeywordSearchType,
        /*addDepartmentCategoryProducts,*/ addSearchFilters, updateProductCategoryShortcutSearchFilter, loadNavLink, sssbatps} = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [productCategories, setProductCategories] = useState<ProductCategory[] | []>(props.productCategories);
    const [storeDepartment, setStoreDepartment] = useState<StoreDepartment | null>(props.storeDepartment);
    const [storeDepartmentName, setStoreDepartmentName] = useState(props.storeDepartmentName);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(props.departmentCategoryName);
    const [storeDepartmentCategory, setStoreDepartmentCategory] = useState(props.storeDepartmentCategory);
    const [isSpecialDepartmentCategory, setIsSpecialDepartmentCategory] = useState(props.isSpecialDepartmentCategory);
    //const [storeDepartmentCategory, setStoreDepartmentCategory] = useState<DepartmentCategory | null>(props.storeDepartmentCategory);
    const [startSliderAmount, setStartSliderAmount] = useState(props.startSliderAmount);
    const [endSliderAmount, setEndSliderAmount] = useState(props.endSliderAmount);
    //const [showProductCategoryProducts, setShowProductCategoryProducts] = useState(props.showProductCategoryProducts);
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState(props.previousSlider1PositionLeft);
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState(props.previousSlider2PositionLeft);
    const [previousSlider1PostionLeftAmount, setPreviousSlider1PostionLeftAmount] = useState(props.previousSlider1PostionLeftAmount);
    const [previousSlider2PostionLeftAmount, setPreviousSlider2PostionLeftAmount] = useState(props.previousSlider2PostionLeftAmount);
    const [keywordSearch, setKeywordSearch] = useState(props.keywordSearch);
    const [sliderPosition, setSliderPosition] = useState(props.sliderPosition);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(props.previousSlider1TotalDistance);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(props.previousSlider2TotalDistance);
    const [slider1ZIndex, setSlider1ZIndex] = useState(props.slider1ZIndex);
    const [slider2ZIndex, setSlider2ZIndex] = useState(props.slider2ZIndex);
    
    const [showPriceSlider, setShowPriceSlider] = useState(props.showPriceSlider);

    //console.log('sa2:', props.startSliderAmount, startSliderAmount, 'ea2:', props.endSliderAmount, endSliderAmount);
    //console.log('ea2:', props.endSliderAmount, endSliderAmount);
    //console.log('ps1pl:', props.previousSlider1PositionLeft, previousSlider1PositionLeft);
    //console.log('ps2pl:', props.previousSlider2PositionLeft, previousSlider2PositionLeft);
    //console.log('s1zi:', props.slider1ZIndex, slider1ZIndex);
    //console.log('s2zi:', props.slider2ZIndex, slider2ZIndex);
    //console.log('ps1td:', props.previousSlider1TotalDistance, previousSlider1TotalDistance);
    //console.log('ps2td:', props.previousSlider2TotalDistance, previousSlider2TotalDistance);
    //console.log('sp:', props.sliderPosition, sliderPosition);
    //console.log('ssa:', props.startSliderAmount, startSliderAmount);
    //console.log('keywordSearch:', props.keywordSearch, keywordSearch);
    //console.log('sfswsts:', searchTermIsSelected, 'pstis:', props.searchTermIsSelected);

    useEffect(()=>{
        setProductCategories(props.productCategories);
    },[props.productCategories]);
    
    useEffect(()=>{
        //console.log('test');
        setProductCategories(props.productCategories);
        setStoreDepartment(props.storeDepartment);
        setIsSpecialDepartmentCategory(props.isSpecialDepartmentCategory);
    },[props.isSpecialDepartmentCategory]);

    function setupSpecialProductCategorySidebar(departmentCategory:DepartmentCategory, selectedDepartmentCategoryName:string):JSX.Element | null {
        if(departmentCategory.departmentCategoryName === selectedDepartmentCategoryName && departmentCategory.showProductCategorySidebar){
            //console.log('test passed');
            return <ul className="shop-special-store-department-category-product-category-sidebar-list">
                        {
                            departmentCategory.productCategories.map((productCategory:ProductCategory, index)=>{
                                //console.log('test result:', productCategory.departmentName);
                                return <li key={index} className="shop-special-store-department-category-product-category-sidebar-list-item">
                                            <Link to={`/shop/${productCategory.departmentName}/${productCategory.departmentCategoryName}/${productCategory.productCategoryName}/${departmentCategory.departmentId}/${productCategory.productCategoryId}`}
                                                className="shop-special-store-department-category-product-category-sidebar-list-item-link"
                                                onClick={(e:React.MouseEvent)=>
                                                    reloadProductCategory(
                                                        e,
                                                        productCategory.departmentName,
                                                        productCategory.departmentCategoryName,
                                                        productCategory.productCategoryName,
                                                        departmentCategory.departmentId,
                                                        productCategory.productCategoryId!
                                                        )
                                                    }>
                                                {productCategory.productCategoryName}</Link>
                                        </li>
                            })
                        }
                    </ul>
        } else {
            return null;
        }
    }

    /*
    function loadNavLink(e:React.MouseEvent, pathname:string):void {
        e.preventDefault();
        console.log('posy14:', pathname);

        //window.sessionStorage.setItem('admin-scroll-position', `${window.scrollY}`);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        navigate(pathname);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        
    }
    */

    function setupLoadNavLink(e:React.MouseEvent, pathname:string):void {
        e.preventDefault();

        if(window.innerWidth >= 1024){
            const target = e.currentTarget as HTMLAnchorElement;
            (target.parentElement as HTMLLIElement).style.backgroundColor = '#fff';
        }


        dispatch(addSearchFilterParams({keywordSearchType:'',
        otherStoreAreaId:'0',
        products:[],
        showProductSubCategoryFilter:true,
        noSearchFilterAttached:false,
        searchFilterSelected:'',
        searchFilters:[],
        searchTermIsSelected:false,
        storeAreaName:'',
        storeAreaType:'',
        storeDepartmentId:'0',
        searchTermSelected:''
        }));
        
        loadNavLink!(e, pathname);
    }

    function mouseEnterHandler(e:React.MouseEvent):void {
        const target = e.currentTarget as HTMLLIElement;
        if(target.firstElementChild!.firstElementChild!.classList.contains('highlight-pc-text')){
            target.style.backgroundColor = '#fff';

        } else {
            target.style.backgroundColor = '';
        }
    }

    function touchStartHandler(e:React.TouchEvent):void {
        const target = e.currentTarget as HTMLAnchorElement;
        target.style.backgroundColor = '#F7F7F7';
    }

    function touchEndHandler(e:React.TouchEvent):void {
        const target = e.currentTarget as HTMLAnchorElement;
        target.style.backgroundColor = '#fff';
    }

    
    function setupSpecialProductCategorySidebar2(storeDepartment:StoreDepartment, selectedDepartmentCategoryName:string):JSX.Element {
        return <ul className="shop-special-store-department-category-product-category-sidebar-list">
                    {storeDepartment.departmentCategories.map((departmentCategory)=>{
                        if(departmentCategory.departmentCategoryName === selectedDepartmentCategoryName && departmentCategory.showProductCategorySidebar){
                            return departmentCategory.productCategories.map((productCategory, index)=>{
                                    //console.log('r25:', productCategory.productCategoryId, productCategory.departmentName, productCategory.productCategoryName, productCategory.highlightText);
                                    return <li key={index} onMouseEnter={mouseEnterHandler} onTouchStart={touchStartHandler} onTouchEnd={touchEndHandler} className="shop-special-store-department-category-product-category-sidebar-list-item">
                                                <Link to={`/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${departmentCategory.departmentId}/${productCategory.productCategoryId}`}
                                                    className="shop-special-store-department-category-product-category-sidebar-list-item-link"
                                                    onClick={(e:React.MouseEvent)=>
                                                        setupLoadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${departmentCategory.departmentId}/${productCategory.productCategoryId}`)
                                                        }>
                                                    {/*productCategory.productCategoryName*/}
                                                    <HighlightProductCategoryText productCategory={productCategory} /></Link>
                                            </li>
                                })
                        }
                    })}
                            
                </ul>

    }

    function setupSpecialStoreDepartmentCategorySidebar(storeDepartment:StoreDepartment, departmentCategoryName:string, storeDepartmentCategory:DepartmentCategory):JSX.Element{
        //console.log('sd7:', storeDepartment, storeDepartmentCategory);
        return <div className="shop-store-department-sidebar-container">
                    <div className="shop-link-shape-container">
                        <Link to={`/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${encodeURIComponent(formatLinkString(departmentCategoryName))}/${storeDepartment!.departmentId}/${storeDepartmentCategory!.departmentCategoryId}`} className="shop-link-shape"
                            onClick={(e:React.MouseEvent)=>setupLoadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${encodeURIComponent(formatLinkString(departmentCategoryName))}/${storeDepartment!.departmentId}/${storeDepartmentCategory!.departmentCategoryId}`)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-chevron-shape">
                                <path fillRule={"evenodd"} clipRule={"evenodd"} d={"M4.24076 14.3492C3.88134 14.7685 3.9299 15.3998 4.34923 15.7592C4.76855 16.1187 5.39985 16.0701 5.75927 15.6508L11.7593 8.65077C12.0803 8.27628 12.0803 7.72368 11.7593 7.34919L5.75927 0.349191C5.39985 -0.0701351 4.76855 -0.118696 4.34923 0.240726C3.9299 0.600147 3.88134 1.23145 4.24076 1.65077L9.68294 7.99998L4.24076 14.3492Z"}
                                fill={'currentColor'}></path>
                            </svg>
                            {departmentCategoryName}
                        </Link>
                    </div>
                    <ul className="shop-store-department-sidebar-list-container">
                        {/*<li className="shop-store-department-sidebar-list-refinement">
                            <ul className="shop-special-department-category-sidebar-list">
                                {storeDepartment.departmentCategories.map((departmentCategory, index)=>{
                                    return <li key={index} className="shop-store-department-sidebar-list-item">
                                                <Link to={`/shop/${departmentCategory.departmentName}/${departmentCategory.departmentCategoryName}/${departmentCategory.departmentId}/${departmentCategory.departmentCategoryId}`}
                                                    className="shop-store-department-sidebar-list-item-link"
                                                    onClick={(e:React.MouseEvent)=>
                                                        reloadDepartmentCategory(
                                                            e,
                                                            departmentCategory.departmentName,
                                                            departmentCategory.departmentCategoryName,
                                                            departmentCategory.departmentId,
                                                            departmentCategory.departmentCategoryId
                                                            )
                                                        }>
                                                        {departmentCategory.departmentCategoryName}</Link>
                                                    {
                                                        setupSpecialProductCategorySidebar(departmentCategory, departmentCategoryName)
                                                    }
                                            </li>
                                })}                            
                            </ul>
                        </li>*/}
                        <li className="shop-store-department-sidebar-list-refinement">
                            {setupSpecialProductCategorySidebar2(storeDepartment, departmentCategoryName)}
                        </li>
                        {
                            props.showSearchFilterSidebar!
                            ?
                            <li>
                                <SearchFilterSidebar 
                                    searchFilters={props.searchFilters} initialSearchFilters={props.initialSearchFilters} 
                                    searchTermIsSelected={props.searchTermIsSelected} storeDepartmentId={props.storeDepartmentId!}
                                    otherStoreAreaId={props.otherStoreAreaId} storeAreaName={props.storeAreaName}
                                    storeAreaType={props.storeAreaType}
                                    setShowDepartmentProducts={setShowProductCategoryProducts!}
                                    clearAllExistingImages={clearAllExistingImages!}
                                    setSearchFilters={setSearchFilters} setDepartmentCategoryProducts={setDepartmentCategoryProducts} 
                                    setDepartmentCategoryProductTotal={setDepartmentCategoryProductTotal} 
                                    setShowSpecialProductCategoryShortcut={setShowSpecialProductCategoryShortcut}
                                    setSearchTermIsSelected={setSearchTermIsSelected} setKeywordSearchType={setKeywordSearchType} 
                                    addSearchFilters={addSearchFilters}
                                    keywordSearch={undefined}
                                    startAmount={props.previousSlider1PostionLeftAmount}
                                    endAmount={props.previousSlider2PostionLeftAmount}
                                    updateProductCategoryShortcutSearchFilter={updateProductCategoryShortcutSearchFilter}
                                    sssbatps={sssbatps}
                                />
                            </li>
                            :
                            null
                        }
                        {   
                            props.showPriceSlider 
                            ? 
                            <li className="shop-store-department-sidebar-list-refinement">
                                <PriceSlider3
                                    keywordSearch={props.keywordSearch!}
                                    startSliderAmount={props.startSliderAmount} 
                                    endSliderAmount={props.endSliderAmount}
                                    previousSlider1PositionLeft={props.previousSlider1PositionLeft}
                                    previousSlider2PositionLeft={props.previousSlider2PositionLeft}
                                    previousSlider1PostionLeftAmount={props.previousSlider1PostionLeftAmount}
                                    previousSlider2PostionLeftAmount={props.previousSlider2PostionLeftAmount}
                                    priceSliderSearchResults={priceSliderSearchResults}
                                    sliderPosition={props.sliderPosition}
                                    previousSlider1TotalDistance={props.previousSlider1TotalDistance}
                                    previousSlider2TotalDistance={props.previousSlider2TotalDistance}
                                    slider1ZIndex={props.slider1ZIndex}
                                    slider2ZIndex={props.slider2ZIndex}
                                    sortByOptionValue={props.sortByOptionValue}
                                                /> 
                            </li> 
                            : null
                        }
                    </ul>
                </div>
    }

    function setupStoreDepartmentCategorySidebar(productCategories:ProductCategory[], storeDepartmentName:string, departmentCategoryName:string, storeDepartment:StoreDepartment):JSX.Element{
        return <div className="shop-store-department-sidebar-container">
                    <div>{departmentCategoryName}</div>
                    <ul className="shop-store-department-sidebar-list-container">
                        {/*<div className="shop-store-department-sidebar-list-refinement">*/}
                        {/*<div className="shop-store-department-sidebar-heading">{departmentCategoryName}</div>*/}
                        <li className="shop-store-department-sidebar-list-refinement">
                            <ul className="shop-store-department-sidebar-list">
                                {productCategories.map((productCategory, index)=>{
                                    return <li key={index} className="shop-store-department-sidebar-list-item">
                                                <Link to={`/shop/${formatLinkString(storeDepartmentName)}/${formatLinkString(departmentCategoryName)}/${formatLinkString(productCategory.productCategoryName)}/${storeDepartment.departmentId}/${productCategory.productCategoryId}`}
                                                    className="shop-store-department-sidebar-list-item-link"
                                                    onClick={(e:React.MouseEvent)=>
                                                        hightlightProductCategoryText(
                                                            e,
                                                            productCategory.productCategoryName,
                                                            'product',
                                                            storeDepartmentName,
                                                            departmentCategoryName,
                                                            storeDepartment.departmentId,
                                                            productCategory.productCategoryId!
                                                            )
                                                        }>
                                                        {/*productCategory.productCategoryName*/}
                                                        <HighlightProductCategoryText productCategory={productCategory}/>
                                                </Link>
                                            </li>
                                })}
                            </ul>
                        </li>
                        {
                            props.showSearchFilterSidebar!
                            ?
                            <li>
                                <SearchFilterSidebar 
                                    searchFilters={props.searchFilters} initialSearchFilters={props.initialSearchFilters} 
                                    searchTermIsSelected={props.searchTermIsSelected} storeDepartmentId={props.storeDepartmentId!}
                                    otherStoreAreaId={props.otherStoreAreaId} storeAreaName={props.storeAreaName}
                                    storeAreaType={props.storeAreaType}
                                    setShowDepartmentProducts={setShowProductCategoryProducts!}
                                    clearAllExistingImages={clearAllExistingImages!}
                                    setSearchFilters={setSearchFilters} setDepartmentCategoryProducts={setDepartmentCategoryProducts} 
                                    setDepartmentCategoryProductTotal={setDepartmentCategoryProductTotal} 
                                    setShowSpecialProductCategoryShortcut={setShowSpecialProductCategoryShortcut}
                                    setSearchTermIsSelected={setSearchTermIsSelected} setKeywordSearchType={setKeywordSearchType} 
                                    addSearchFilters={addSearchFilters}
                                    keywordSearch={undefined}
                                    startAmount={props.previousSlider1PostionLeftAmount}
                                    endAmount={props.previousSlider2PostionLeftAmount}
                                />
                            </li>
                            :
                            null
                        }
                        {
                            props.showPriceSlider 
                            ? 
                            <li className="shop-store-department-sidebar-list-refinement">
                                <PriceSlider3
                                    keywordSearch={props.keywordSearch!}
                                    startSliderAmount={props.startSliderAmount} 
                                    endSliderAmount={props.endSliderAmount}
                                    previousSlider1PositionLeft={props.previousSlider1PositionLeft}
                                    previousSlider2PositionLeft={props.previousSlider2PositionLeft}
                                    previousSlider1PostionLeftAmount={props.previousSlider1PostionLeftAmount}
                                    previousSlider2PostionLeftAmount={props.previousSlider2PostionLeftAmount}
                                    priceSliderSearchResults={priceSliderSearchResults}
                                    sliderPosition={props.sliderPosition}
                                    previousSlider1TotalDistance={props.previousSlider1TotalDistance}
                                    previousSlider2TotalDistance={props.previousSlider2TotalDistance}
                                    slider1ZIndex={props.slider1ZIndex}
                                    slider2ZIndex={props.slider2ZIndex}
                                    sortByOptionValue={props.sortByOptionValue}
                                    /> 
                            </li>
                            : 
                            null
                        }
                    </ul>

                </div>
    }

    if(isSpecialDepartmentCategory){
        //console.log('true:', storeDepartment, departmentCategoryName, storeDepartmentCategory);
        return setupSpecialStoreDepartmentCategorySidebar(props.storeDepartment as StoreDepartment, props.departmentCategoryName as string, props.storeDepartmentCategory as DepartmentCategory);
    } else {
        //console.log('false');
        return setupStoreDepartmentCategorySidebar(productCategories, storeDepartmentName as string, departmentCategoryName as string, storeDepartment!);
    }




}

export default StoreDepartmentCategorySidebarSelector2;