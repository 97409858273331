import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import StoreStructureOptionSelect from './StoreStructureOptionSelect';
import {getDepartmentNamesPromise, 
    getDepartmentCategoryNamesPromise, 
    getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import CheckboxBuilder from './CheckboxBuilder';    
import ProductVerification, {VerificationProduct} from './productVerification';
import ProductPromotion, {PromotionProduct} from './productPromotion';
import SearchFilter, {SearchTerm, SearchFilterGroup} from './searchFilter';
import SearchFilterTerm from './searchFilterTerm';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import ProductSubCategory from './productSubCategory';
import FormErrorMessage, {FormError, FormErrorType} from './FormErrorMessage';
import showSearchTermFormErrorMsgs from './helper-show-search-term-form-error-msgs';
import ExistingSGCheckboxControl from './ExistingSGCheckboxControl';
import ProductVerificationControl from './ProductVerificationControl';
import ProductVerificationControl2 from './ProductVerificationControl2';
import ProductPromotionControl from './ProductPromotionControl';
import ProductPromotionControl2 from './ProductPromotionControl2';
import StoreDepartmentSelectControl from './StoreDepartmentSelectControl';
import DepartmentCategorySelectControl from './DepartmentCategorySelectControl';
import ProductCategorySelectControl from './ProductCategorySelectControl';
import ProductSubCategorySelectControl from './ProductSubCategorySelectControl';
import Product from './product';
import ProductDescription from './productDescription';
import ProductWeight from './productWeight';
import DBErrorMessage, {DBError} from './DBErrorMessage';
import GenericInfoMessage from './GenericInfoMessage';
import LoaderAnimation from './LoaderAnimation';
import loadNavLink from './loadNavLinkHelper';
import formatSearchFilterName from './helper-format-search-filter-name';
import formatLinkString from './helper-format-link-string';
import './AddProductStoreStructure.css';


interface Props {
    /*showAddProduct:boolean;*/
    showEditProduct:boolean;
    productToEdit?:Product;
    handleCloseForm:(formType:string, cancel?:boolean)=>void;
}

interface ThumbnailImage {
    img:HTMLImageElement;
    name:string;
}

interface SFGErrorInfo {
    sfgErrors?:boolean;
    area?:string;
    sfgs?:SearchFilterGroup[];
    existingSfgErrors?:boolean;
    existingArea?:string;
    existingSfgs?:SearchFilterGroup[];
}

interface ProductImage {
    category:string;
    source:string;
    path:string;
    type:string;
}

interface EditProductInfo {
    productToEdit?:Product;
    relatedDepartments?:StoreDepartment[];
    relatedDepartmentCategories?:DepartmentCategory[];
    relatedProductCategories?:ProductCategory[];
    relatedProductSubCategories?:ProductSubCategory[];
    relatedProductPromotions?:ProductPromotion[];
    relatedProductVerifications?:ProductVerification[];
    existingSearchFilterGroups?:SearchFilterGroup[];
    availableSearchFilterGroups?:SearchFilterGroup[];
    productImages?:ProductImage[];
    productToEditError?:Error;
}


const AddProductStoreStructure: React.FunctionComponent<Props> = (props:Props) => {
    const {showEditProduct, productToEdit, handleCloseForm} = props;
    //console.log('pte:', productToEdit);
    const [departments, setDepartments] = useState<StoreDepartment[]>([]);
    const [departmentNames, setDepartmentNames] = useState<string[]>([]);
    const [departmentNameSelected, setDepartmentNameSelected] = useState('');
    const [showDeptNameErrorMsg, setShowDeptNameErrorMsg] = useState(false);
    const [deptNameErrorMsg, setDeptNameErrorMsg] = useState('Please add a department name.');
    const [departmentCategories, setDepartmentCategories] = useState<DepartmentCategory[]>([]);
    const [departmentCategoryNames, setDepartmentCategoryNames] = useState<string[]>([]);
    const [departmentCategoryNameSelected, setDepartmentCategoryNameSelected] = useState('');
    const [showDeptCategoryNameErrorMsg, setShowDeptCategoryNameErrorMsg] = useState(false);
    const [deptCategoryNameErrorMsg, setDeptCategoryNameErrorMsg] = useState('Please add a department category name.');
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [productCategoryNames, setProductCategoryNames] = useState<string[]>([]);
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState('');
    const [showProductCategoryNameErrorMsg, setShowProductCategoryNameErrorMsg] = useState(false);
    const [productCategoryNameErrorMsg, setProductCategoryNameErrorMsg] = useState('Please add a product category name.');
    const [productSubCategories, setProductSubCategories] = useState<ProductSubCategory[]>([]);
    const [productSubCategoryNames, setProductSubCategoryNames] = useState<string[]>([]);
    const [productSubCategoryNameSelected, setProductSubCategoryNameSelected] = useState('');
    const [showProductSubCategoryNameErrorMsg, setShowProductSubCategoryNameErrorMsg] = useState(false);
    const [productSubCategoryNameErrorMsg, setProductSubCategoryNameErrorMsg] = useState('Please add a product subcategory name.');
    const [dollarAmountSelected, setDollarAmountSelected] = useState('');
    const [centAmountSelected, setCentAmountSelected] = useState('');
    const [unitOfMeasureSelected, setUnitOfMeasureSelected] = useState('');
    const [showUnitOfMeasureErrorMsg, setShowUnitOfMeasureErrorMsg] = useState(false);
    const [unitOfMeasureErrorMsg, setUnitOfMeasureErrorMsg] = useState('Please add a unit of measure.');
    const [unitOfMeasureNames, setUnitOfMeasureNames] = useState(['lb', 'oz', 'ct', 'sheet', 'pack', 'individual', 'load']);
    const [weightBySelected, setWeightBySelected] = useState('');
    const [showWeightByErrorMsg, setShowWeightByErrorMsg] = useState(false);
    const [weightByErrorMsg, setWeightByErrorMsg] = useState('Please add a weight.');
    const [weightByNames, setWeightByNames] = useState(['each', 'pound']);
    const [weightRangeSelected, setWeightRangeSelected] = useState('');
    const [weightRangeNames, setWeightRangeNames] = useState(['0.25lb - 5lb', '0.25lb - 10lb', '1lb - 10lb']);
    const [showWeightRangeErrorMsg, setShowWeightRangeErrorMsg] = useState(false);
    const [weightRangeErrorMsg, setWeightRangeErrorMsg] = useState('Please add a weight range.');
    const [productPromotions, setProductPromotions] = useState<ProductPromotion[]>([]);
    const [productPromotionsTitle, setProductPromotionsTitle] = useState('Promotions');
    const [productPromotionsSelected, setProductPromotionsSelected] = useState<ProductPromotion[]>([]);
    const [productVerifications, setProductVerifications] = useState<ProductVerification[]>([]);
    const [productVerificationsTitle, setProductVerificationsTitle] = useState('Verfications');
    const [productVerificationsSelected, setProductVerificationsSelected] = useState<ProductVerification[]>([]);
    const [searchFilterGroups, setSearchFilterGroups] = useState<SearchFilterGroup[]>([]);
    const [searchFilterGroupsTitle, setSearchFilterGroupsTitle] = useState('Available Search Filter Groups');
    const [searchFilterGroupsSelected, setSearchFilterGroupsSelected] = useState<SearchFilterGroup[]>([]);
    const [existingSearchFilterGroups, setExistingSearchFilterGroups] = useState<SearchFilterGroup[]>([]);
    const [existingSearchFilterGroupsTitle, setExistingSearchFilterGroupsTitle] = useState('Existing Search Filter Groups');
    const [existingSearchFilterGroupsSelected, setExistingSearchFilterGroupsSelected] = useState<SearchFilterGroup[]>([]);
    const [groupCategoryNamesSelected, setgroupCategoryNamesSelected] = useState<string[]>([]);
    const [groupFilterNamesSelected, setgroupFilterNamesSelected] = useState<string[]>([]);
    const [sfgDepartmentSearchFilters, setSFGDepartmentSearchFilters] = useState<SearchFilter[]>([]);
    const [sfgdepartmentCategoryName, setSFGDepartmentCategoryName] = useState('');
    const [sfgProductCategoryNameSelected, setSFGproductCategoryNameSelected] = useState('');
    const [searchFilterGroupElementHeight, setSearchFilterGroupElementHeight] = useState(0);
    const [searchFilterElementHeight, setSearchFilterElementHeight] = useState(0);
    const [existingSearchFilterGroupElementHeight, setExistingSearchFilterGroupElementHeight] = useState(0);
    const [existingSearchFilterElementHeight, setExistingSearchFilterElementHeight] = useState(0);
    const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);
    const dbErrorMsgRef = useRef<{dbemcRef:React.RefObject<HTMLDivElement>}>(null);
    //const [showDBErrorMessage, setShowDBErrorMessage] = useState(true);
    const imageSearchRef = useRef<HTMLInputElement>(null);
    const [searchThumbnailImages, setSearchThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [productSearchImages, setProductSearchImages] = useState<File[]>([]);
    const imageSoloRef = useRef<HTMLInputElement>(null);
    const [soloThumbnailImages, setSoloThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [productSoloImages, setProductSoloImages] = useState<File[]>([]);
    const imageMobileRef = useRef<HTMLInputElement>(null);
    const [mobileThumbnailImages, setMobileThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [productMobileImages, setProductMobileImages] = useState<File[]>([]);
    const imageMiniCartRef = useRef<HTMLInputElement>(null);
    const [miniCartThumbnailImages, setMiniCartThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [productMiniCartImages, setProductMiniCartImages] = useState<File[]>([]);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [formError, setFormError] = useState<FormError>({
                                                        productInfoErrors:[],
                                                        pricingErrors:[],
                                                        imageErrors:[],
                                                        searchFilterGroupError:{hasError:false, area:'none'},
                                                        existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                    });
    const [dbError, setDBError] = useState<DBError>({
                                                productNameError:{hasError:false, area:'none'},
                                                imageErrors:[]
                                                    });

    const dnLabelRef = useRef<HTMLLabelElement>(null);
    const dcnLabelRef = useRef<HTMLLabelElement>(null);
    const pcnLabelRef = useRef<HTMLLabelElement>(null);
    const pscnLabelRef = useRef<HTMLLabelElement>(null);
    const pnLabelRef = useRef<HTMLInputElement>(null);
    const pdLabelRef = useRef<HTMLTextAreaElement>(null);
    const pqlLabelRef = useRef<HTMLInputElement>(null);
    const priceLabelRef = useRef<HTMLDivElement>(null);
    const uomPriceLabelRef = useRef<HTMLDivElement>(null);
    const uomLabelRef = useRef<HTMLLabelElement>(null);
    const uomLabelSelectRef = useRef<HTMLSelectElement>(null);

    const weightLabelRef = useRef<HTMLLabelElement>(null);
    const weightLabelSelectRef = useRef<HTMLSelectElement>(null);

    const wrLabelRef = useRef<HTMLLabelElement>(null);
    const wrLabelSelectRef = useRef<HTMLSelectElement>(null);

    const pisLabelRef = useRef<HTMLLabelElement>(null);
    const pisoloLabelRef = useRef<HTMLLabelElement>(null);
    const pimLabelRef = useRef<HTMLLabelElement>(null);
    const pimcLabelRef = useRef<HTMLLabelElement>(null);
    
    //promotion checkbox controls references
    //const ppCheckboxControlsRef = useRef<{ppCheckboxControlsRef:React.RefObject<HTMLInputElement[]>}>(null);
    //verification checkbox controls references
    //const pvCheckboxControlsRef = useRef<{pvCheckboxControlsRef:React.RefObject<HTMLInputElement[]>}>(null);
    //department select element reference
    const dSelectElementRef = useRef<{dSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //department category select element reference
    const dcSelectElementRef = useRef<{dcSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //product category select element reference
    const pcSelectElementRef = useRef<{pcSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    //product subcategory select element reference
    const pscSelectElementRef = useRef<{pscSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);

    const [siteIsLoaded, setSiteIsLoaded] = useState(false);
    const [productToEditId, setProductToEditId] = useState(0);

    const [searchFilterGroupsNames, setSearchFilterGroupsNames] = useState<string[]>([]);
    const [existingSearchFilterGroupsNames, setExistingSearchFilterGroupsNames] = useState<string[]>([]);

    const [editProductLoaded, setEditProductLoaded] = useState<boolean>(false);

    const inTransition = useRef(false);

    const [productPrice, setProductPrice] = useState('5.00');
    const [minimumPrice, setMinimumPrice] = useState('0');
    const [stepIncrementAmt, setStepIncrementAmt] = useState('0.01');

    const [uomProductPrice, setUOMProductPrice] = useState('0');
    const [minimumUOMPrice, setMinimumUOMPrice] = useState('0');
    const [stepIncrementUOMAmt, setStepIncrementUOMAmt] = useState('0.01');

    const [productQtyLimit, setProductQtyLimit] = useState('');
    const [minimumProductQty, setMinimumProductQty] = useState('0');
    const [stepIncrementProductQty, setStepIncrementProductQty] = useState('1');

    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [genericInfoMessage, setGenericInfoMessage] = useState('No items found.');

    const [mobileImageWidth, setMobileImageWidth] = useState(0);
    const [mobileImageHeight, setMobileImageHeight] = useState(0);

    //real time error message variables
    const [showProductNameLimitError, setShowProductNameLimitError] = useState(false);
    const [showProductDescriptionLimitError, setShowProductDescriptionLimitError] = useState(false);
    const [showProductQtyLimitError, setShowProductQtyLimitError] = useState(false);
    const [showPriceLimitError, setShowPriceLimitError] = useState(false);
    const [showUOMPriceLimitError, setShowUOMPriceLimitError] = useState(false);
    const [showMobileFileLimitError, setShowMobileFileLimitError] = useState(false);
    const [showSearchFileLimitError, setShowSearchFileLimitError] = useState(false);
    const [showMiniCartFileLimitError, setShowMiniCartFileLimitError] = useState(false);


    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{

        if(showEditProduct){

            Promise.all([getDepartmentNamesPromise(),/*, getProductPromotions(),
                        getProductVerifications(),*/ getProductToEditPromise(productToEdit!)])
            .then(([departments,/*, productPromotions , productVerifications,*/ editProductInfo])=>{
                //console.log('ds:', departments);
                //console.log('pps:', productPromotions);
                //console.log('pvs:', productVerifications);
                setupDepartmentInfo([...departments]);
                //setProductPromotions([...productPromotions]);
                //setProductVerifications([...productVerifications]);
                
                //console.log('editProductInfo:', editProductInfo);

                setupDepartmentInfo(editProductInfo.relatedDepartments!);
                setupDepartmentCategoryInfo(editProductInfo.relatedDepartmentCategories!);
                setupProductCategoryInfo(editProductInfo.relatedProductCategories!);
                setupProductSubCategoryInfo(editProductInfo.relatedProductSubCategories!);
                setProductPromotions(editProductInfo.relatedProductPromotions!);
                setProductVerifications(editProductInfo.relatedProductVerifications!);

                setupProductEditInfo(editProductInfo.productToEdit!);

                setupProductImages(editProductInfo.productImages!);
                
                setupExistingSearchFilterGroups(editProductInfo.existingSearchFilterGroups!);
                setupAvailableSearchFilterGroups(editProductInfo.availableSearchFilterGroups!);

            })
            .catch((err)=>console.error('promise all for names error:', err));

        } else {

            Promise.all([getDepartmentNamesPromise(), /*getDepartmentCategoryNamesPromise(), getProductCategoryNamesPromise(),*/ 
            getProductPromotions(), getProductVerifications()/*, getSearchFilterGroups()*/])
            .then(([departments, /*departmentCategories, productCategories,*/ productPromotions , productVerifications/*, searchFilterGroups*/])=>{
                //console.log('ds:', departments);
                //console.log('dcs:', departmentCategories);
                //console.log('pcs:', productCategories);
                //console.log('pps:', productPromotions);
                //console.log('pvs:', productVerifications);
                //console.log('sfgs:', searchFilterGroups);
                setupDepartmentInfo([...departments]);
                //setupDepartmentCategoryInfo([...departmentCategories]);
                //setupProductCategoryInfo([...productCategories]);
                setProductPromotions([...productPromotions]);
                setProductVerifications([...productVerifications]);
                //setSearchFilterGroups([...searchFilterGroups]);

            })
            .catch((err)=>console.error('promise all for names error:', err));
        }



    },[]);


    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
        const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
        dbemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
        sfgemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
    }


    function getProductToEditPromise(product:Product):Promise<EditProductInfo>{
        return fetch(`https://server.kando-proto-3.com/get-product-to-edit/${product.productId}/${product.productCategoryId}`, {
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({productToEdit, existingSearchFilterGroups, availableSearchFilterGroups, productImages,
                        relatedDepartments, relatedDepartmentCategories, relatedProductCategories,
                        relatedProductSubCategories, relatedProductPromotions, relatedProductVerifications}:EditProductInfo)=>{
                    //console.log('esfgs:', existingSearchFilterGroups, 'asfgs:', availableSearchFilterGroups);
                    const pte = new Product({...productToEdit});
                    //console.log('pte:', pte);
                    const esfgs = existingSearchFilterGroups!.length > 0 ? addSearchFilterGroups(existingSearchFilterGroups!) : [];
                    const asfgs = availableSearchFilterGroups!.length > 0 ? addSearchFilterGroups(availableSearchFilterGroups!) : [];
                    
                    //console.log('pi:', productImages);

                    return ({
                        productToEdit:pte,
                        existingSearchFilterGroups:esfgs,
                        availableSearchFilterGroups:asfgs,
                        productImages:productImages,
                        relatedDepartments,
                        relatedDepartmentCategories,
                        relatedProductCategories,
                        relatedProductSubCategories,
                        relatedProductPromotions,
                        relatedProductVerifications
                    });
                })
                .catch((err)=>{
                    console.error('fetch product to edit error:', err.message);
                    return ({productToEditError:new Error(err.message)})
                });

    }

    function triggerStoreDepartmentChangeEvent(dn:string):void {
        //console.log('dn:', dn);
        const change = new Event('change', {
            bubbles: true,
            cancelable: true
        });

        const selectRef = dSelectElementRef.current!.dSelectElementRef.current as HTMLSelectElement;
        selectRef.value = dn;
        selectRef.dispatchEvent(change);
    
    }

    function triggerDepartmentCategoryChangeEvent(dcn:string):void {
        //console.log('dcn:', dcn);
        const change = new Event('change', {
            bubbles: true,
            cancelable: true
        });

        const selectRef = dcSelectElementRef.current!.dcSelectElementRef.current as HTMLSelectElement;
        selectRef.value = dcn;
        selectRef.dispatchEvent(change);

    }

    function triggerProductCategoryChangeEvent(pcn:string):void {
        //console.log('pcn:', pcn);
        const change = new Event('change', {
            bubbles: true,
            cancelable: true
        });

        const selectRef = pcSelectElementRef.current!.pcSelectElementRef.current as HTMLSelectElement;
        selectRef.value = pcn;
        selectRef.dispatchEvent(change);
    }


    function setupProductEditInfo(product:Product):void {
        setDepartmentNameSelected(product.storeDepartmentName);
        setDepartmentCategoryNameSelected(product.departmentCategoryName);
        setProductCategoryNameSelected(product.productCategoryName);
        setProductSubCategoryNameSelected(product.productSubCategoryName);
        setUnitOfMeasureSelected(product.unitOfMeasure);
        setWeightBySelected(product.weightBy);
        setProductDescription(product.productDescription!.description);
        setProductName(product.productName);
        setProductToEditId(product.productId);
        setProductQtyLimit(product.productQtyLimit.toString());
        setEditProductLoaded(true);
        //need to set form a file image
        //setProductSearchImages(product.productImage);

        //console.log('pps:', product.productPromotions);

        if(product.productPromotions.length > 0){
            const pps = product.productPromotions.map((pp)=>{
                            return new ProductPromotion({...pp});
                        });

            /*
            console.log('ref:', ppCheckboxControlsRef.current!.ppCheckboxControlsRef.current!);

            const ppcbControls = ppCheckboxControlsRef.current!.ppCheckboxControlsRef.current as HTMLInputElement[];
            //check all controls that have matching ids
            pps.forEach((pp)=>{
                ppcbControls.forEach((control)=>{
                    console.log('control:', control);
                    const controlId = window.parseInt(control.id, 10);
                    if(pp.productPromotionId === controlId){
                        control.checked = true;
                    }
                });
            });
            */

            setProductPromotionsSelected([...pps]);
        }

        //console.log('pvs:', product.productVerifications);
        
        if(product.productVerifications.length > 0){
            const pvs = product.productVerifications.map((pv)=>{
                            return new ProductVerification({...pv});
                        });

                        /*
            const pvcbControls = pvCheckboxControlsRef.current!.pvCheckboxControlsRef.current as HTMLInputElement[];
            //check all controls that have matching ids
            pvs.forEach((pv)=>{
                pvcbControls.forEach((control)=>{
                    const controlId = window.parseInt(control.id, 10);
                    if(pv.productVerificationId === controlId){
                        control.checked = true;
                    }
                });
            });
            */

            setProductVerificationsSelected([...pvs]);
        }
        
        /*
        const decimal = product.eachPrice.toString().indexOf('.');
        const dollarAmount = product.eachPrice.toString().slice(0, decimal);
        const centsAmount = product.eachPrice.toString().slice(decimal + 1);
        console.log('price:', window.parseInt(dollarAmount,10));
        console.log('cents:', window.parseInt(centsAmount,10));
        setDollarAmountSelected(dollarAmount);
        setCentAmountSelected(centsAmount);
        */
        setProductPrice(product.eachPrice.toString());
        setUOMProductPrice(product.unitOfMeasureListPrice.toString());
        setupWeightRange(product.weightList);


        //window.setTimeout(()=>triggerStoreDepartmentChangeEvent(product.storeDepartmentName), 100);
    
    }

    function setupProductImages(productImages:ProductImage[]):void {
        productImages.forEach((pi)=>{
            //console.log('pi:', pi);
            //add images by category
            switch(pi.category){
                case 'search':
                    const si = new Image();
                    //previous version
                    //si.src = `${process.env.PUBLIC_URL}${pi.path}`;
                    si.src = `https://server.kando-proto-3.com/${pi.path}`;
                    setSearchThumbnailImages([{img:si, name:getFileName(pi.path)}]);
                    //console.log('pi.source s:', pi.source);
                    const sbyteCharacters = window.atob(pi.source);
                    const sbyteNumbers = new Array(sbyteCharacters.length);
                    for (let i = 0; i < sbyteCharacters.length; i++) {
                      sbyteNumbers[i] = sbyteCharacters.charCodeAt(i);
                    }
                    const sbyteArray = new Uint8Array(sbyteNumbers);
                    const sBlob = new Blob([sbyteArray], {type:pi.type});
                    const sFile = new File([sBlob], pi.path, {type:pi.type});
                    //console.log('sFile:', sFile)
                    //const sBlob = new Blob([pi.source], {type:pi.type});
                    //const sFile = new File([sBlob], pi.path, {type:pi.type});
                    setProductSearchImages([sFile]);
                    break;
                case 'mobile':
                    const mi = new Image();
                    //previous version
                    //mi.src = `${process.env.PUBLIC_URL}${pi.path}`;
                    mi.src = `https://server.kando-proto-3.com/${pi.path}`;
                    setMobileThumbnailImages([{img:mi, name:getFileName(pi.path)}]);
                    //console.log('pi.source m:', pi.source);
                    const mbyteCharacters = window.atob(pi.source);
                    const mbyteNumbers = new Array(mbyteCharacters.length);
                    for (let i = 0; i < mbyteCharacters.length; i++) {
                      mbyteNumbers[i] = mbyteCharacters.charCodeAt(i);
                    }
                    const mbyteArray = new Uint8Array(mbyteNumbers);
                    const mBlob = new Blob([mbyteArray], {type:pi.type});
                    const mFile = new File([mBlob], pi.path, {type:pi.type});
                    //console.log('mFile:', mFile)

                    //const mBlob = new Blob([pi.source], {type:pi.type});
                    //const mFile = new File([mBlob], pi.path, {type:pi.type});
                    setProductMobileImages([mFile]);
                    break;
                case 'miniCart':
                    const mci = new Image();
                    //previous version
                    //mci.src = `${process.env.PUBLIC_URL}${pi.path}`;
                    mci.src = `https://server.kando-proto-3.com/${pi.path}`;
                    setMiniCartThumbnailImages([{img:mci, name:getFileName(pi.path)}]);
                    const mcbyteCharacters = window.atob(pi.source);
                    const mcbyteNumbers = new Array(mcbyteCharacters.length);
                    for (let i = 0; i < mcbyteCharacters.length; i++) {
                      mcbyteNumbers[i] = mcbyteCharacters.charCodeAt(i);
                    }
                    const mcbyteArray = new Uint8Array(mcbyteNumbers);
                    const mcBlob = new Blob([mcbyteArray], {type:pi.type});
                    const mcFile = new File([mcBlob], pi.path, {type:pi.type});
                    //console.log('mcFile:', mcFile)

                    //const mcBlob = new Blob([pi.source], {type:pi.type});
                    //const mcFile = new File([mcBlob], pi.path, {type:pi.type});
                    setProductMiniCartImages([mcFile]);
                    break;
                default:
                    throw new Error('no image category found!');
            }


        });

    }

    function getFileName(filePath:string):string {
        const lastForwardSlash = filePath.lastIndexOf('/');
        const fileName = filePath.slice(lastForwardSlash + 1);
        return fileName;
    }

    function setupExistingSearchFilterGroups(esfgs:SearchFilterGroup[]):void {
        //console.log('esfgs:', esfgs);
        const uesfgs = addSearchFilterGroups(esfgs);
        const esfgns = uesfgs.map((esfg)=>{
                            return esfg.groupCategoryName;
                        });
        //console.log('esfgns:', esfgns);
        setExistingSearchFilterGroupsNames(esfgns);
        setExistingSearchFilterGroups(uesfgs);
    }

    function setupAvailableSearchFilterGroups(asfgs:SearchFilterGroup[]):void {
        //console.log('asfgs:', asfgs);
        const uasfgs = addSearchFilterGroups(asfgs);
        const asfgns = uasfgs.map((asfg)=>{
                            return asfg.groupCategoryName;
                        });
        //console.log('asfgns:', asfgns);
        setSearchFilterGroupsNames(asfgns);
        setSearchFilterGroups(uasfgs);
    }

    function setupWeightRange(weightList:number[]):void {
        if(weightList.length > 0){
            const sw = weightList[0];
            const ew = weightList[weightList.length - 1];
            const weightRange = `${sw}lb - ${ew}lb`;
            //console.log('wr:', weightRange);
            setWeightRangeSelected(weightRange);

        }
    }


    function setupDepartmentInfo(ds:StoreDepartment[]):void {
        function addDepartments(ds:StoreDepartment[]):StoreDepartment[]{
            return ds.map((d)=>{
                return new StoreDepartment({...d});
            })
        }

        function addDepartmentNames(ds:StoreDepartment[]):string[]{
            return ds.map((d)=>{
                return d.departmentName;
            })
        }

        const uds = addDepartments(ds);
        setDepartments(uds);
        const dns = addDepartmentNames(uds);
        setDepartmentNames(dns);
    }


    //add fetch to get live data
    function getProductPromotions():Promise<ProductPromotion[]> {

        function addProductPromotions(pps:ProductPromotion[]):ProductPromotion[]{
            return pps.map((pp)=>{
                        return new ProductPromotion({...pp});
                    });
        }

        return fetch(`https://server.kando-proto-3.com/get-product-promotions`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((pps:ProductPromotion[])=>{
                //console.log('pps 3-28:', pps);
                return addProductPromotions(pps);
            })
            .catch((err)=>{
                console.error('err:', err.message)
                return err.message;
            });
    }

    //add fetch to get live data
    function getProductVerifications():Promise<ProductVerification[]> {

        function addProductVerifications(pvs:ProductVerification[]):ProductVerification[]{
            return pvs.map((pv)=>{
                        return new ProductVerification({...pv});
                    });
        }
        
        return fetch(`https://server.kando-proto-3.com/get-product-verifications`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((pvs:ProductVerification[])=>{
                //console.log('pvs 3-28:', pvs);
                return addProductVerifications(pvs);
            })
            .catch((err)=>{
                console.error('err:', err.message)
                return err.message;
            });
    }

    //add fetch to get live data
    function getSearchFilterGroups(departmentNameSelected:string, departmentCategoryNameSelected:string, productCategoryNameSelected:string):Promise<SearchFilterGroup[]> {
        const dns = departmentNameSelected;
        const dcns = departmentCategoryNameSelected;
        const pcns = productCategoryNameSelected;
        let matchProductToEdit = false;


        if(productToEdit && dns === productToEdit!.storeDepartmentName && dcns === productToEdit!.departmentCategoryName && pcns === productToEdit!.productCategoryName){
            matchProductToEdit = true;
        }


        return fetch(`https://server.kando-proto-3.com/get-search-filters-with-search-terms`, {
                //original version
                //fetch(`http://localhost:9500/get-search-filters-with-search-terms/${dns}/${dcns}/${pcns}/${productToEdit!.productId}/${matchProductToEdit}`, {
                //method:'GET',
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                    },
                body:JSON.stringify({departmentNameSelected, departmentCategoryNameSelected, productCategoryNameSelected, productToEdit, matchProductToEdit})

                })
                .then((res)=>{
                    return res.json();
                })
                .then(([availableSearchFilterGroups, existingSearchFilterGroups]/*sfgs:SearchFilterGroup[]*/)=>{
                    const asfgs = availableSearchFilterGroups;
                    const esfgs = existingSearchFilterGroups;
                    //console.log('asfgs 5-11:', asfgs, esfgs, 'dns:', dns, 'dcns:', dcns, 'pcns:', pcns, 'pte:', productToEdit);

                    /*
                    if(!matchProductToEdit){
                        const usfgs = addSearchFilterGroups(asfgs);
                        const asfgns = usfgs.map((asfg)=>{
                            return asfg.groupCategoryName;
                        });
                        //console.log('asfgns:', asfgns);
                        setSearchFilterGroupsNames(asfgns);
                        //reset all search filter groups
                        setSearchFilterGroups(usfgs);   

                    } else {
                        */

                        const uasfgs = asfgs.length > 0 ? addSearchFilterGroups(asfgs) : [];
                        const asfgns = uasfgs.map((asfg)=>{
                            return asfg.groupCategoryName;
                        });
                        //console.log('asfgns:', asfgns);
                        setSearchFilterGroupsNames(asfgns);
                        //reset all search filter groups
                        setSearchFilterGroups(uasfgs); 

                        const uesfgs = esfgs.length > 0 ? addSearchFilterGroups(esfgs) : [];
                        const esfgns = uesfgs.map((esfg)=>{
                            return esfg.groupCategoryName;
                        });
                        //console.log('asfgns:', asfgns);
                        setExistingSearchFilterGroupsNames(esfgns);
                        //reset all search filter groups
                        setExistingSearchFilterGroups(uesfgs); 
                    //}
                

                    return;
                })
                .catch((err)=>{
                    console.error('err:', err.message)
                    return err.message;
                });


    }

    //add search filter helper and search term helper to load each
    //search filters will get sorted by the selection the user makes as far a dept, dc, pc
    function addSearchFilterGroups(sfgs:SearchFilterGroup[]):SearchFilterGroup[]{
        //console.log('sfgs3-11:', sfgs);
        return sfgs.map((sfg)=>{
            return new SearchFilterGroup({
                groupCategoryName:sfg.groupCategoryName,
                groupFilterName:sfg.groupFilterName,
                searchFilters:addSearchFilters(sfg.searchFilters),
            });
        });

    }

    function addSearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        return sfs.map((sf)=>{
                        return new SearchFilter({
                            searchFilterId:sf.searchFilterId,
                            searchFilterArea:sf.searchFilterArea,
                            searchFilterAreaType:sf.searchFilterAreaType,
                            filterName:sf.filterName,
                            searchTerms:addSearchTerms(sf.searchTerms)
                        });
                    });

    }

    function addSearchTerms(sts:SearchTerm[]):SearchTerm[] {
        return sts.map((st)=>{
                    return new SearchTerm({...st});
                });
    }

    
    function handleDeptNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setDepartmentNameSelected(dns);
            setShowDeptNameErrorMsg(false);
            const found = departments.filter((d)=>{
                                        return d.departmentName === dns;
                                    });

            if(found.length === 0){
                throw new Error('no department found.');
            }

            const dId = found[0].departmentId;

            getRelatedCategories('Department', dns, dId);

        } else {
            setDepartmentNameSelected(dns);
            //reset all search filter groups
            setSearchFilterGroups([]);
            //reset all existing search filter groups
            setExistingSearchFilterGroups([]);

            //reset product categories
            setProductCategoryNames([]);
            setProductCategoryNameSelected('none');

            //reset product subcategories
            setProductSubCategoryNames([]);
            setProductSubCategoryNameSelected('none');
        }



        //if(!siteIsLoaded && showEditProduct){
            //window.setTimeout(()=>triggerDepartmentCategoryChangeEvent(departmentCategoryNameSelected),100);
        //} else {

            //reset all search filter groups
            setSearchFilterGroups([]);
            //reset all existing search filter groups
            setExistingSearchFilterGroups([]);

            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');

            //reset product categories
            setProductCategoryNames([]);
            setProductCategoryNameSelected('none');

            //reset product subcategories
            setProductSubCategoryNames([]);
            setProductSubCategoryNameSelected('none');
        //}

        
        
    }

    function handleDeptCategoryNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('dcns:', (e.target as HTMLSelectElement).value);
        const dcns = (e.target as HTMLSelectElement).value;
        if(dcns !== 'none'){
            setDepartmentCategoryNameSelected(dcns);
            setShowDeptCategoryNameErrorMsg(false);
            const found = departmentCategories.filter((dc)=>{
                                    return dc.departmentCategoryName === dcns;
                                });

            if(found.length === 0){
                throw new Error('no department category found.');
            }

            const dcId = found[0].departmentCategoryId;

            getRelatedCategories('Department Category', dcns, dcId);
        } else {
            setDepartmentCategoryNameSelected(dcns);
        }

        if(!siteIsLoaded && showEditProduct){
            //console.log('test passed 5-12');
            //window.setTimeout(()=>triggerProductCategoryChangeEvent(productCategoryNameSelected),100);
        } 

        //if(siteIsLoaded){
            //reset all search filter groups
            setSearchFilterGroups([]);
            //reset all existing search filter groups
            setExistingSearchFilterGroups([]);

            setSearchFilterGroups([]);
            //reset all existing search filter groups
            setExistingSearchFilterGroups([]);

            //reset product categories
            setProductCategoryNames([]);
            setProductCategoryNameSelected('none');

            //reset product subcategories
            setProductSubCategoryNames([]);
            setProductSubCategoryNameSelected('none');
        //}

        //original version before adding productSubCategory selector
        //setSiteIsLoaded(true);

    }

    function handleProductCategoryNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('pcns:', (e.target as HTMLSelectElement).value);
        const pcns = (e.target as HTMLSelectElement).value;
        if(pcns !== 'none'){
            setProductCategoryNameSelected(pcns);
            setShowProductCategoryNameErrorMsg(false);
            const found = productCategories.filter((pc)=>{
                return pc.productCategoryName === pcns;
            });

            if(found.length === 0){
            throw new Error('no product category found.');
            }

            const pcId = found[0].productCategoryId;

            getRelatedCategories('Product Category', pcns, pcId);
        } else {
            setProductCategoryNameSelected(pcns);

        }

        //reset product subcategories
        setProductSubCategoryNames([]);
        setProductSubCategoryNameSelected('none');

        //reset all search filter groups
        setSearchFilterGroups([]);
        //reset all existing search filter groups
        setExistingSearchFilterGroups([]);
    }

    function handleProductSubCategoryNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('pcns:', (e.target as HTMLSelectElement).value);
        const pscns = (e.target as HTMLSelectElement).value;
        if(pscns !== 'none'){
            setProductSubCategoryNameSelected(pscns);
            setShowProductSubCategoryNameErrorMsg(false);
            //getRelatedCategories('Product Category', pscns);
        } else {
            setProductSubCategoryNameSelected(pscns);

        }

        /*
        //reset all search filter groups
        setSearchFilterGroups([]);
        //reset all existing search filter groups
        setExistingSearchFilterGroups([]);
        */
    }

    function getRelatedCategories(sfat:string, sfa:string, sfaId?:number):void {

        if(sfat === 'Department'){
            //fetch related department categories
            relatedDepartmentCategories(sfa, sfaId as number);

        }

        if(sfat === 'Department Category'){
            //fetch related product categories
            relatedProductCategories(sfa, sfaId as number);
        }

        if(sfat === 'Product Category'){
            //console.log('test 5-11:');
            //need to put both function below in a Promise.all()
            //and set product subcategories and product subcatgory names
            //and set searchfiltergroups and search filter group names
            //need put call getSearchFilterGroups inside relatedProductSubCategories so rpsc completes before
            //calling getSearchFilterGroups
            //fetch related product subcategories
            relatedProductSubCategories(sfa, sfaId as number);
            //fetch the related search filter groups for depts, deptCategories and product categories.

            getSearchFilterGroups(departmentNameSelected, departmentCategoryNameSelected, sfa);
        }

    }

    function setupDepartmentCategoryInfo(dcs:DepartmentCategory[]):void {
        function addDepartmentCategories(dcs:DepartmentCategory[]):DepartmentCategory[]{
            return dcs.map((dc)=>{
                return new DepartmentCategory({...dc});
            })
        }

        function addDepartmentCategoryNames(dcs:DepartmentCategory[]):string[]{
            return dcs.map((dc)=>{
                return dc.departmentCategoryName;
            })
        }

        const udcs = addDepartmentCategories(dcs);
        setDepartmentCategories(udcs);
        const dcns = addDepartmentCategoryNames(udcs);
        setDepartmentCategoryNames(dcns);

    }

    function relatedDepartmentCategories(
                                departmentName:string,
                                departmentId:number
                                ):Promise<DepartmentCategory[]> {

        return fetch(`https://server.kando-proto-3.com/get-related-department-categories/${departmentId.toString()}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((dcs:DepartmentCategory[])=>{
                //console.log('dcs 6-13:', dcs);
                if(dcs.length === 0){
                    //show generic info message if no product categories are found
                    setGenericInfoMessage(`No department categories have been added to the "${departmentName}" department.
                                            Please add some if you want to use the "${departmentName}" department.`);
                    
                    setupDepartmentCategoryInfo([]);
                    setShowNoSearchResultsFound(true);
                } else {
                    setupDepartmentCategoryInfo(dcs);
                }

                return;
            })
            .catch((err)=>{
                console.error('err:', err.message)
                return err.message;
            });


    }

    function setupProductCategoryInfo(pcs:ProductCategory[]):void {
        function addProductCategories(pcs:ProductCategory[]):ProductCategory[]{
            return pcs.map((pc)=>{
                return new ProductCategory({...pc});
            })
        }

        function addProductCategoryNames(pcs:ProductCategory[]):string[]{
            return pcs.map((pc)=>{
                return pc.productCategoryName;
            })
        }

        //console.log('test 5-12 sil:', siteIsLoaded);
        /*
        if(siteIsLoaded){
            console.log('test 5-12');
            setProductCategoryNameSelected('none');
        }
        */

        const upcs = addProductCategories(pcs);
        setProductCategories(upcs);
        const pcns = addProductCategoryNames(upcs);
        setProductCategoryNames(pcns);

    }

    function relatedProductCategories(
                            departmentCategoryName:string,
                            departmentCategoryId:number
                            ):Promise<ProductCategory[]> {
        //console.log('passed 5-12');

        return fetch(`https://server.kando-proto-3.com/get-related-product-categories/${departmentCategoryId.toString()}`, {
                method:'GET',
                headers:{
                    'Accept':'application/json'
                    }
                })
                .then((res)=>{
                    return res.json();
                })
                .then((pcs:ProductCategory[])=>{
                    //console.log('pcs 3-28:', pcs);
                    if(pcs.length === 0){
                        //show generic info message if no product categories are found
                        setGenericInfoMessage(`No product categories have been added to the "${departmentCategoryName}" department category.
                                                Please add some if you want to use the "${departmentCategoryName}" department category.`);

                        setupProductCategoryInfo([]);                        
                        setShowNoSearchResultsFound(true);

                    } else {
                        setupProductCategoryInfo(pcs);
                    }
                    return;
                })
                .catch((err)=>{
                    console.error('err:', err.message)
                    return err.message;
                });
        


    }

    function setupProductSubCategoryInfo(pscs:ProductSubCategory[]):void {
        function addProductSubCategories(pscs:ProductSubCategory[]):ProductSubCategory[]{
            return pscs.map((psc)=>{
                return new ProductSubCategory({...psc});
            })
        }

        function addProductSubCategoryNames(pscs:ProductSubCategory[]):string[]{
            return pscs.map((psc)=>{
                return psc.productSubCategoryName;
            })
        }

        const upscs = addProductSubCategories(pscs);
        setProductSubCategories(upscs);
        const pscns = addProductSubCategoryNames(upscs);
        setProductSubCategoryNames(pscns);
    }

    function relatedProductSubCategories(
                                productCategoryName:string,
                                productCategoryId:number
                                ):Promise<ProductSubCategory[]> {

        return fetch(`https://server.kando-proto-3.com/get-related-product-sub-categories/${productCategoryId.toString()}`, {
                method:'GET',
                headers:{
                    'Accept':'application/json'
                    }
                })
                .then((res)=>{
                    return res.json();
                })
                .then((pscs:ProductSubCategory[])=>{
                    //console.log('pscs 5-9:', pscs);
                    if(pscs.length === 0){
                        //show generic info message if no product categories are found
                        setGenericInfoMessage(`No product sub categories have been added to the "${productCategoryName}" product category.
                                                Please add some if you want to use the "${productCategoryName}" product sub category.`);

                        setupProductSubCategoryInfo([]);                        
                        setShowNoSearchResultsFound(true);

                    } else {
                        setupProductSubCategoryInfo(pscs);
                    }
                    return;
                })
                .catch((err)=>{
                    console.error('err:', err.message)
                    return err.message;
                });
        


    }

    function handleDollarAmount(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const amount = target.value;
        if(amount.length <= 4){
            setDollarAmountSelected(amount);
        } 
        
    }

    function handleCentAmount(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const amount = target.value;
        //check to see if there are two decimal places
        if(amount.length <= 2){
            setCentAmountSelected(amount);
        } 
       
    }

    function handleUnitOfMeasureSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        const unit = (e.target as HTMLSelectElement).value;
        setUnitOfMeasureSelected(unit);
    }

    function handleWeightBySelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        const weight = (e.target as HTMLSelectElement).value;
        setWeightBySelected(weight);
    }

    function handleWeightRangeSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        const weightRange = (e.target as HTMLSelectElement).value;
        setWeightRangeSelected(weightRange);
    }

    function handleProductPromotions(e:React.ChangeEvent):void {
        e.stopPropagation();
        const promoChecked = (e.target as HTMLInputElement).checked; 
        const promoName = (e.target as HTMLInputElement).value;
        const promoId = window.parseInt((e.target as HTMLInputElement).id, 10);
        //console.log('checked:', promoChecked, 'name:', promoName);
        //console.log('promoName:', promoName);
        //console.log('promoChecked:', promoChecked);
        if(productPromotionsSelected.length === 0 && promoChecked){
            const ppf = productPromotions.filter((pp)=>{
                            //return pp.productPromotionName === promoName;
                            return pp.productPromotionId === promoId;
                        });
            const pps = new ProductPromotion({
                                    productPromotionId:ppf[0].productPromotionId,
                                    productPromotionName:promoName,
                                    productPromotionImage:ppf[0].productPromotionImage
                                });
            setProductPromotionsSelected([pps]);
            //console.log('ppslist:', [promoName]);
        } else if(productPromotionsSelected.length > 0 && promoChecked){
            const found = productPromotionsSelected.filter((pp)=>{
                            //return pp.productPromotionName === promoName;
                            return pp.productPromotionId === promoId;
                        });
            
            if(found.length === 0){
                const ppf = productPromotions.filter((pp)=>{
                    //return pp.productPromotionName === promoName;
                    return pp.productPromotionId === promoId;
                });
                const pps = new ProductPromotion({
                                            productPromotionId:ppf[0].productPromotionId,
                                            productPromotionName:promoName,
                                            productPromotionImage:ppf[0].productPromotionImage
                                        });
                productPromotionsSelected.push(pps);
                //console.log('ppslist:', productPromotionsSelected);
                setProductPromotionsSelected(productPromotionsSelected);
            }

        } else {
            //remove matching item
            const upps = productPromotionsSelected.filter((pp)=>{
                                //return pp.productPromotionName !== promoName;
                                return pp.productPromotionId !== promoId;
                            });

            //console.log('ppslist:', upps);
            setProductPromotionsSelected(upps);
        }


    }

    function handleProductVerifications(e:React.ChangeEvent):void {
        e.stopPropagation();
        const pvChecked = (e.target as HTMLInputElement).checked; 
        const pvName = (e.target as HTMLInputElement).value;
        const pvId = window.parseInt((e.target as HTMLInputElement).id, 10);
        //console.log('pvChecked:', pvChecked);
        //console.log('pvName:', pvName);
        if(productVerificationsSelected.length === 0 && pvChecked){
            const pvf = productVerifications.filter((pv)=>{
                                    //return pv.productVerificationName === pvName;
                                    return pv.productVerificationId === pvId;
                                });
            const pvs = new ProductVerification({
                                            productVerificationId:pvf[0].productVerificationId,
                                            productVerificationName:pvName,
                                            productVerificationImage:pvf[0].productVerificationImage
                                        });
            setProductVerificationsSelected([pvs]);
            //console.log('pvslist:', [pvName]);
        } else if(productVerificationsSelected.length > 0 && pvChecked){
            const found = productVerificationsSelected.filter((pv)=>{
                            //return pv.productVerificationName === pvName;
                            return pv.productVerificationId === pvId;
                        });
            
            if(found.length === 0){
                const pvf = productVerifications.filter((pv)=>{
                    //return pv.productVerificationName === pvName;
                    return pv.productVerificationId === pvId;
                });
                const pvs = new ProductVerification({
                                            productVerificationId:pvf[0].productVerificationId,
                                            productVerificationName:pvName,
                                            productVerificationImage:pvf[0].productVerificationImage
                                        });
                productVerificationsSelected.push(pvs);
                //console.log('pvslist:', productVerificationsSelected);
                setProductVerificationsSelected(productVerificationsSelected);
            }

        } else {
            //remove matching item
            const upvs = productVerificationsSelected.filter((pv)=>{
                                //return pv.productVerificationName !== pvName;
                                return pv.productVerificationId !== pvId;
                            });

            //console.log('pvslist:', upvs);
            setProductVerificationsSelected(upvs);
        }
    }

    function getFormControlMeasurement(el:HTMLDivElement | null):void {
        if(el !== null){
            //const div = el as HTMLDivElement;
            if(el.classList.contains('sfat')){
                const h = el.getBoundingClientRect().height;
                setSearchFilterGroupElementHeight(h);

            } else if(el.classList.contains('sfg')){
                const h = el.getBoundingClientRect().height;
                setSearchFilterElementHeight(h);

            } else if(el.classList.contains('e-sfat')){
                const h = el.getBoundingClientRect().height;
                setExistingSearchFilterGroupElementHeight(h);

            } else if(el.classList.contains('e-sfg')){
                const h = el.getBoundingClientRect().height;
                setExistingSearchFilterElementHeight(h);

            }
        }
    }

    function handleSearchFilterGroups(e:React.ChangeEvent, sfgType:string):void {
        e.stopPropagation();
        
        const target = (e.target as HTMLInputElement);
        let elName = (e.target as HTMLInputElement).value;
        const elChecked = (e.target as HTMLInputElement).checked;
        //console.log('target:', target);
        ///console.log('dataset:', target.dataset);
        //console.log('elName:', elName);
        //console.log('elChecked:', elChecked);
        //console.log('sfgType:', sfgType);

        const sgcn = target.dataset['searchGroupCategoryName'];
        const sgfn = target.dataset['searchGroupFilterName'];
        const sgt = target.dataset['searchType'];

        if(sgt === 'Search Filter'){
            elName = formatSearchFilterName(elName, sgfn!);
        }


        function setupSFGClass(gpe:HTMLDivElement, sfgType:string):boolean{
            if(sfgType === 'existing'){
                return gpe.classList.contains('e-sfat') || gpe.classList.contains('e-sfg');
            } else {
                return gpe.classList.contains('sfat') || gpe.classList.contains('sfg');
            } 
        }

        function setupSFGElementName(sfgType:string, elName:string):string{
            if(sfgType === 'existing'){
                return `existing-${elName}`;
            } else {
                return elName;
            }
        }

        //prevent transition from being interrupted by a double click
        if(inTransition.current){
            //console.log('transition:', inTransition.current);
            //reset checkbox to previously checked position
            (e.target as HTMLInputElement).checked = (e.target as HTMLInputElement).checked ? false : true;
            return;
        }
   

        if(elChecked){
            //close/open child list
            const pe = target.parentElement as HTMLDivElement;
            const gpe = pe.parentElement as HTMLDivElement;
            //console.log('gpe:', gpe, gpe.classList.contains('search-filter-group-search-terms'));

            //remove error boundaries if they currently exist on related search filters
            if(gpe.classList.contains('search-filter-group-search-filters')
                && gpe.classList.contains('no-sfs-selected')){
                const foundElement = gpe.closest('.add-product-checkbox-form-group') as HTMLDivElement;
                Array.from(foundElement.getElementsByClassName('no-sfs-selected')).forEach((sfe)=>{
                    //console.log('sfe:', sfe);
                    //sfe.classList.contains('show-item') ? sfe.classList.remove('show-item') : sfe.classList.add('show-item');
                    sfe.classList.remove('no-sfs-selected');
                    //console.log('sfe1:', sfe);
                });
            }

            //remove error boundaries if they currently exist on related search terms
            if(gpe.classList.contains('search-filter-group-search-terms')
                && gpe.classList.contains('no-sts-selected')){
                const foundElement = gpe.closest('.search-filter-group-search-filters') as HTMLDivElement;
                Array.from(foundElement.getElementsByClassName('no-sts-selected')).forEach((sfe)=>{
                    //console.log('sfe:', sfe);
                    //sfe.classList.contains('show-item') ? sfe.classList.remove('show-item') : sfe.classList.add('show-item');
                    sfe.classList.remove('no-sts-selected');
                    //console.log('sfe1:', sfe);
                });
            }


            const ts = (e:TransitionEvent) => {
                //console.log('transitionstart open');
                inTransition.current = true;
                gpe.removeEventListener('transitionstart', ts);
            };

            const te = (e:TransitionEvent) => {
                //console.log('transitionend open');
                inTransition.current = false;
                gpe.removeEventListener('transitionend', te);
            };

            gpe.addEventListener('transitionstart', ts);

            gpe.addEventListener('transitionend', te);

            //original version
            //if(gpe.classList.contains('sfat') || gpe.classList.contains('sfg')){
            if(setupSFGClass(gpe, sfgType)){
                window.requestAnimationFrame(()=>{                
                    gpe.style.transition = 'height .4s linear';
                    //console.log('true:');
                    //get current number of search filters
                    //height of element being checked
                    const gpeHeight = gpe.getBoundingClientRect().height;
                    //console.log('transition height:', gpeHeight);
                    //number of search filters in list
                    //original version
                    //const sfCount = Array.from(gpe.getElementsByClassName(elName)).length;
                    const sfCount = (sfgType === 'existing') 
                                    ? Array.from(gpe.getElementsByClassName(`existing-${elName}`)).length
                                    : Array.from(gpe.getElementsByClassName(elName)).length;
                    //console.log('sfCount:', sfCount, gpe);
                    //height of search filter
                    //original version
                    //const sfHeight = Array.from(gpe.getElementsByClassName(elName))[0].getBoundingClientRect().height;
                    const sfHeight = (sfgType === 'existing') 
                                      ? Array.from(gpe.getElementsByClassName(`existing-${elName}`))[0].getBoundingClientRect().height
                                      : Array.from(gpe.getElementsByClassName(elName))[0].getBoundingClientRect().height;
                    //height of all search filters in list
                    const sflHeight = sfCount * sfHeight;
                    //total height of element checked and all search filters in list
                    const tHeight = gpeHeight + sflHeight;
                    //console.log('sflh:', sflHeight);
                    //origial version
                    //if(gpe.classList.contains('sfg')){
                    if(gpe.classList.contains('sfg') || gpe.classList.contains('e-sfg')){
                        (gpe.parentElement as HTMLDivElement).style.height = `${(gpe.parentElement as HTMLDivElement).getBoundingClientRect().height + sflHeight}px`;
                        (gpe.parentElement as HTMLDivElement).style.transition = 'height .4s linear';
                    }
                    gpe.style.height = `${tHeight}px`;
                })
            }
            //gpe.classList.add('open');
            //original version setupSFGElementName
            //Array.from(gpe.getElementsByClassName(elName)).forEach((sfe)=>{
            Array.from(gpe.getElementsByClassName(setupSFGElementName(sfgType, elName))).forEach((sfe)=>{
                //console.log('sfe:', sfe);
                sfe.classList.contains('show-item') ? sfe.classList.remove('show-item') : sfe.classList.add('show-item');

                //console.log('sfe:', sfe);
            });

        } else {
            //close all children
            const pe = target.parentElement as HTMLDivElement;
            const gpe = pe.parentElement as HTMLDivElement;

            const ts = (e:TransitionEvent) => {
                //console.log('transitionstart close');
                inTransition.current = true;
                gpe.removeEventListener('transitionstart', ts);
            };

            const te = (e:TransitionEvent) => {
                //console.log('transitionend close');
                inTransition.current = false;
                gpe.removeEventListener('transitionend', te);
            };

            gpe.addEventListener('transitionstart', ts);
            
            gpe.addEventListener('transitionend', te);

            //original version
            //if(gpe.classList.contains('sfat') || gpe.classList.contains('sfg')){
            if(setupSFGClass(gpe, sfgType)){
                window.requestAnimationFrame(()=>{
                    gpe.style.transition = 'height .2s ease';
                    //height of element being checked
                    const gpeHeight = gpe.getBoundingClientRect().height;
                    //number of search filters in list
                    //original version
                    //const sfCount = Array.from(gpe.getElementsByClassName(elName)).length;
                    const sfCount = (sfgType === 'existing')
                                    ? Array.from(gpe.getElementsByClassName(`existing-${elName}`)).length
                                    : Array.from(gpe.getElementsByClassName(elName)).length;
                    //console.log(':', sfCount);
                    //height of search filter
                    //original version
                    //const sfHeight = Array.from(gpe.getElementsByClassName(elName))[0].getBoundingClientRect().height;
                    const sfHeight = (sfgType === 'existing') 
                                      ? Array.from(gpe.getElementsByClassName(`existing-${elName}`))[0].getBoundingClientRect().height
                                      : Array.from(gpe.getElementsByClassName(elName))[0].getBoundingClientRect().height;
                    //height of all search filters in list
                    const sflHeight = sfCount * sfHeight;
                    //total height of element checked and all search filters in list
                    const tHeight = gpeHeight + sflHeight;
                    //original version
                    //if(gpe.classList.contains('sfg')){
                    if(gpe.classList.contains('sfg') || gpe.classList.contains('e-sfg')){
                        (gpe.parentElement as HTMLDivElement).style.height = `${(gpe.parentElement as HTMLDivElement).getBoundingClientRect().height - sflHeight}px`;
                        //(gpe.parentElement as HTMLDivElement).style.height = '32px';
                        (gpe.parentElement as HTMLDivElement).style.transition = 'height .2s ease';
                    }
                    //original version
                    //gpe.style.height = '32px';// `${Array.from(gpe.getElementsByClassName(elName))[0].getBoundingClientRect().height}px`;

                    gpe.classList.contains('search-filter-group') ? gpe.style.height = '50px' /*`${searchFilterGroupElementHeight}px`*/ : gpe.style.height = '50px';// `${searchFilterElementHeight}px`;

                    //gpe.classList.remove('open');
                    //original version
                    //Array.from(gpe.getElementsByClassName(elName)).forEach((sfe)=>{                       
                    Array.from(gpe.getElementsByClassName(setupSFGElementName(sfgType, elName))).forEach((sfe)=>{                       
                        
                        //original version
                        //if((sfe as HTMLDivElement).classList.contains('sfat')){
                        if((sfe as HTMLDivElement).classList.contains('sfat') || (sfe as HTMLDivElement).classList.contains('e-sfat')){
                            //console.log('passed1');
                            (sfe as HTMLDivElement).style.height = '50px';// `${searchFilterGroupElementHeight}px`;
                        }

                        //original version
                        //if((sfe as HTMLDivElement).classList.contains('sfg')){
                        if((sfe as HTMLDivElement).classList.contains('sfg') || (sfe as HTMLDivElement).classList.contains('e-sfg')){
                            //console.log('passed2');
                            (sfe as HTMLDivElement).style.height = '50px';// `${searchFilterElementHeight}px`;
                        }
                        
                       // window.requestAnimationFrame(()=>{
                            (((sfe as HTMLDivElement).firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement).checked = false;
                        //});
                        
                        sfe.classList.remove('show-item');
                        
                        if(sfe.classList.contains('no-sfs-selected')){
                            sfe.classList.remove('no-sfs-selected');
                        }

                        if(sfe.classList.contains('no-sts-selected')){
                            sfe.classList.remove('no-sts-selected');
                        }
                        
                        Array.from(sfe.getElementsByClassName('show-item')).forEach((child)=>{
                            //console.log('child:', child);
                            //window.requestAnimationFrame(()=>{
                                (((child as HTMLDivElement).firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement).checked = false;
                                child.classList.remove('show-item');
                            //});

                                if(child.classList.contains('no-sfs-selected')){
                                    child.classList.remove('no-sfs-selected');
                                }
        
                                if(child.classList.contains('no-sts-selected')){
                                    child.classList.remove('no-sts-selected');
                                }
                            
                            
                        })

                    });
                });
            }
            
        }


    }


    function getImageGroups():FormData {
        let fd = new FormData();

        if(productSearchImages.length > 0){
            productSearchImages.forEach((pmi)=>{
                fd.append('search', pmi);
            });
        }

        if(productSoloImages.length > 0){
            productSoloImages.forEach((pmi)=>{
                fd.append('solo', pmi);
            });
        }

        if(productMobileImages.length > 0){
            productMobileImages.forEach((pmi)=>{
                fd.append('mobile', pmi);
            });
        }

        if(productMiniCartImages.length > 0){
            productMiniCartImages.forEach((pmi)=>{
                fd.append('mini-cart', pmi);
            });
        }

        fd.append('message', JSON.stringify({msg:'hello brian'}));

        return fd;

    }


    function handleFormSubmit(e:React.FormEvent, formAction:string):void {
        e.preventDefault();
        e.stopPropagation();

        const form = e.target as HTMLFormElement;

        dbErrorMsgRef.current!.dbemcRef.current!.style.top = '-2000px';
        sfgErrorMsgRef.current!.sfgemcRef.current!.style.top = '-2000px';


        //file upload info
        //need to add additional fields as string values
        //like pricing, search filter terms, and product info
        //put these into an product object and other related objects and send to server
        //after you get back a productId confirming that the product is unique.
        //const fd = getImageGroups();
        //uploadImages(fd);

        //put all the info below in a function that returns a promise from the server with the productId if unique
        //could create an error message if the product is not unique
        //get this values

        //rest form errors
        //dnLabelRef.current!.classList.remove('product-info-error');
        dSelectElementRef.current!.dSelectElementRef.current!.classList.remove('product-info-error');

        //dcnLabelRef.current!.classList.remove('product-info-error');
        dcSelectElementRef.current!.dcSelectElementRef.current!.classList.remove('product-info-error');

        //pcnLabelRef.current!.classList.remove('product-info-error');
        pcSelectElementRef.current!.pcSelectElementRef.current!.classList.remove('product-info-error');

        pnLabelRef.current!.classList.remove('product-info-error');
        pdLabelRef.current!.classList.remove('product-info-error');
        pqlLabelRef.current!.classList.remove('product-info-error');
        priceLabelRef.current!.classList.remove('pricing-error');

        uomPriceLabelRef.current!.classList.remove('pricing-error');

        //uomLabelRef.current!.classList.remove('price-error');
        uomLabelSelectRef.current!.classList.remove('pricing-error');

        //weightLabelRef.current!.classList.remove('price-error');
        weightLabelSelectRef.current!.classList.remove('pricing-error');

        if(wrLabelRef.current instanceof HTMLLabelElement){
            //wrLabelRef.current!.classList.remove('price-error');
            wrLabelSelectRef.current!.classList.remove('pricing-error');
        }

        pisLabelRef.current!.classList.remove('image-error');
        //pisoloLabelRef.current!.classList.remove('image-error');
        pimLabelRef.current!.classList.remove('image-error');
        pimcLabelRef.current!.classList.remove('image-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.pricingErrors.length = 0;
        formError.imageErrors.length = 0;
        formError.searchFilterGroupError = {hasError:false, area:'none'};
        formError.existingSearchFilterGroupError = {hasError:false, area:'none'};

        //product info
        //department name selected
        const dns = departmentNameSelected;
        if(dns.length === 0 || dns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Department Name'});
        }

        //department category name selected
        const dcns = departmentCategoryNameSelected;
        if(dcns.length === 0 || dcns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Department Category Name'});
        }

        //product category name selected
        const pcns = productCategoryNameSelected;
        //console.log('pcns:', pcns);
        if(pcns.length === 0 || pcns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Product Category Name'});
        }

        //product subcategory name selected
        const pscns = productSubCategoryNameSelected.length === 0 ? 'none' : productSubCategoryNameSelected;

        //product name selected
        const pn = productName;
        const plns = formatLinkString(pn);

        if(pn.length === 0 || pn === 'none'){
            setShowProductNameLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Product Name'});

        } else {
            setShowProductNameLimitError(false);
        }

        //product description
        const pd = productDescription;
        if(pd.length === 0 || pd === 'none'){
            setShowProductDescriptionLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Product Description'});

        } else {
            setShowProductDescriptionLimitError(false);
        }

        const pql = productQtyLimit;
        if(pql.length === 0){
            setShowProductQtyLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Product Qty Limit'});

        } else {
            setShowProductQtyLimitError(false);
        }


        //price
        let price = Number(productPrice);
        //let das = dollarAmountSelected;
        //const cas = centAmountSelected;
        if(/*das.length < 2 ||*/ price === 0){
            setShowPriceLimitError(false);
            formError.pricingErrors.push({hasError:true, area:'Price'});

        } else {
            setShowPriceLimitError(false);
        }

        //das = (das.length === 0) ? '0' : das;
        const priceString = price.toFixed(2);

        //unit of measure price
        let uomPrice = Number(uomProductPrice);
        if(uomPrice === 0){
            setShowUOMPriceLimitError(false);
            formError.pricingErrors.push({hasError:true, area:'Unit Of Measure Price'});

        } else {
            setShowUOMPriceLimitError(false);
        }

        const uomPriceString = uomPrice.toFixed(2);

        //unit of measure
        const uoms = unitOfMeasureSelected;
        if(uoms.length === 0 || uoms === 'none'){
            formError.pricingErrors.push({hasError:true, area:'Unit Of Measure'});
        }

        //weight
        const wbs = weightBySelected;
        if(wbs.length === 0 || wbs === 'none'){
            formError.pricingErrors.push({hasError:true, area:'Weight'});
        }

        //weight range
        const wrs = weightRangeSelected;
        if(wbs === 'pound' && (wrs.length === 0 || wrs === 'none')){
            formError.pricingErrors.push({hasError:true, area:'Weight Range'});
        }

        //console.log('wbs:', wbs, 'wrs:', wrs);

        //images-groups
        //image-search
        let pSearchimages = productSearchImages;
        if(pSearchimages.length === 0 || pSearchimages.length > 1){
            setShowSearchFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Product Search Image'});

        } else {

            setShowSearchFileLimitError(false);

            const sfn = pSearchimages[0].name.toLowerCase();
            //const usfn = sfn.replace(/[\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=]/gi, '');
            const usfn = sfn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nsfn = usfn.replace(/ /ig, '-');
            //console.log('nsfn:', sfn, usfn, nsfn);
    
            let searchBlob = pSearchimages[0].slice(0, pSearchimages[0].size, pSearchimages[0].type);
            const newSearchFile = new File([searchBlob], nsfn, {type: searchBlob.type});
            //remove original unfiltered file
            pSearchimages.length = 0;
            pSearchimages.push(newSearchFile);
        }


        //add the rest of the images after getting everthing else working
        //image-solo
        const pSoloImages = productSoloImages;
        /*add condition when you have more images
        if(pSoloImages.length === 0 || pSoloImages.length > 1){
            formError.imageErrors.push({hasError:true, area:'Product Solo Image'});
        }
        */

        //image-mobile
        let pMobileImages = productMobileImages;
        //add condition when you have more images
        if(pMobileImages.length === 0 || pMobileImages.length > 1){
            setShowMobileFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Product Mobile Image'});

        } else {

            setShowMobileFileLimitError(false);

            const mfn = pMobileImages[0].name.toLowerCase();
            //const umfn = mfn.replace(/,/ig, '');
            const umfn = mfn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nmfn = umfn.replace(/ /ig, '-');
    
            let mobileBlob = pMobileImages[0].slice(0, pMobileImages[0].size, pMobileImages[0].type);
            const newMobileFile = new File([mobileBlob], nmfn, {type: mobileBlob.type});
            //remove original unfiltered file
            pMobileImages.length = 0;
            pMobileImages.push(newMobileFile);
        }
        

        //image-mini-cart
        let pMiniCartImages = productMiniCartImages;
        //add condition when you have more images
        if(pMiniCartImages.length === 0 || pMiniCartImages.length > 1){
            setShowMiniCartFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Product Mini Cart Image'});

        } else {
            
            setShowMiniCartFileLimitError(false);

            const mcfn = pMiniCartImages[0].name.toLowerCase();
            //const umcfn = mcfn.replace(/,/ig, '');
            const umcfn = mcfn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nmcfn = umcfn.replace(/ /ig, '-');
    
            let miniCartBlob = pMiniCartImages[0].slice(0, pMiniCartImages[0].size, pMiniCartImages[0].type);
            const newFile = new File([miniCartBlob], nmcfn, {type: miniCartBlob.type});
            //remove original unfiltered file
            pMiniCartImages.length = 0;
            pMiniCartImages.push(newFile);
        }



        //promotions
        const pps = productPromotionsSelected;
        //console.log('pps:', pps);
        //verifications
        const pvs = productVerificationsSelected;
        //console.log('pvs:', pvs);

        //console.log('sfgs:', searchFilterGroupsSelected);

        //original version
        //const {sfgErrors, area, sfgs} = (searchFilterGroups.length > 0) ? checkSearchFilterGroupErrors(form) : {sfgErrors:false, area:'none', sfgs:[]}
        //need to return two different sfg object depending on if "existing" or "available" then check the errors if any
        const {existingSfgErrors, existingArea, existingSfgs} = (existingSearchFilterGroups.length > 0) ? checkSearchFilterGroupErrors(form, 'existing') : {existingSfgErrors:false, existingArea:'none', existingSfgs:[]};
        const {sfgErrors, area, sfgs} = (searchFilterGroups.length > 0) ? checkSearchFilterGroupErrors(form, 'available') : {sfgErrors:false, area:'none', sfgs:[]}
            
        //console.log('sfgs:', existingSearchFilterGroups, 'avail:', searchFilterGroups, 'pte:', productToEdit, 'dns:', departmentNameSelected, 'dcns:', departmentCategoryNameSelected, 'pcns:', productCategoryNameSelected);
        //return;

        if(existingSfgErrors || sfgErrors){
            //console.log('ese:', existingSfgErrors, 'se:', sfgErrors);
            formError.searchFilterGroupError = (sfgErrors) ? {hasError:true, area:area!} : {hasError:false, area:'none'};
            formError.existingSearchFilterGroupError = (existingSfgErrors) ? {hasError:true, area:existingArea!} : {hasError:false, area:'none'};
            //console.log('fe:', formError);
            
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                addFormLabelErrors(formError);

                //show all form errors
                const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                const ew = sfgemc.offsetWidth;
                const eh = sfgemc.offsetHeight;
                //console.log('eh:', eh);
                //sfgemc.style.marginLeft = `-${ew / 2}px`;
                sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                sfgemc.style.top = `-${eh}px`;

                if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                    sfgemc.style.height = `${window.innerHeight + 1}px`;
                    sfgemc.style.overflowY = 'scroll';
                    sfgemc.style.overscrollBehaviorY = 'contain';
                }

                const raf2 = window.requestAnimationFrame(()=>{
                                const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                sfgemc.style.top = '0px';

                                //close error message container
                                /*
                                window.setTimeout(()=>{
                                    sfgemc.style.top = `-${eh}px`;
                                },3000);
                                */

                                window.cancelAnimationFrame(raf2);
                            });
                
                window.cancelAnimationFrame(raf1);

            });

            return;

        } else {

            const pies = formError.productInfoErrors.length > 0 ? true : false;
            const pes = formError.pricingErrors.length > 0 ? true : false;
            const ies = formError.imageErrors.length > 0 ? true : false;
            formError.searchFilterGroupError = {hasError:false, area:area!};
            formError.existingSearchFilterGroupError = {hasError:false, area:existingArea!};


            if(pies || pes || ies){
                const fe = {...formError};
                setFormError(fe);

                const raf1 = window.requestAnimationFrame(()=>{

                                addFormLabelErrors(formError);


                                //show all form errors
                                const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                const ew = sfgemc.offsetWidth;
                                const eh = sfgemc.offsetHeight;
                                //console.log('eh:', eh);
                                //sfgemc.style.marginLeft = `-${ew / 2}px`;
                                sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                                sfgemc.style.top = `-${eh}px`;

                                if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                    sfgemc.style.height = `${window.innerHeight + 1}px`;
                                    sfgemc.style.overflowY = 'scroll';
                                    sfgemc.style.overscrollBehaviorY = 'contain';
                                }
                                

                                const raf2 = window.requestAnimationFrame(()=>{
                                                const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                                sfgemc.style.top = '0px';

                                                //close error message container
                                                /*
                                                window.setTimeout(()=>{
                                                    sfgemc.style.top = `-${eh}px`;
                                                },3000);
                                                */

                                                window.cancelAnimationFrame(raf2);
                                            });
                                
                                window.cancelAnimationFrame(raf1);

                            });

                return;
            }

        }

        //use this return for testing
        //return;

        setShowProcessingRequestMsg(true);

        if(formAction === 'addProduct'){

            let searchImageName = productSearchImages.length > 0 ? productSearchImages[0].name : 'none';
                //searchImageName = formatFilePathName(productSearchImages[0].name, 'search').toLowerCase();
            let mobileImageName = productMobileImages.length > 0 ? productMobileImages[0].name : 'none';
                //mobileImageName = formatFilePathName(productMobileImages[0].name, 'mobile').toLowerCase();
            let miniCartImageName = productMiniCartImages.length > 0 ? productMiniCartImages[0].name : 'none';
                //miniCartImageName = formatFilePathName(productMiniCartImages[0].name, 'miniCart').toLowerCase();

            const productImages = {
                searchImageName:searchImageName,
                mobileImageName:mobileImageName,
                miniCartImageName:miniCartImageName
            };

            //console.log('pis 6-13:', productImages);

            const urlParams = {
                            productCategoryNameSelected,
                            productName,
                            searchImageName,
                            mobileImageName,
                            miniCartImageName
                                };

            const params = JSON.stringify(urlParams);

            //add data to database after testing that both types of search filter groups work
            //previous version
            //fetch(`http://localhost:9500/get-product-info/${productCategoryNameSelected}/${encodeURIComponent(productName)}`, {
            fetch(`https://server.kando-proto-3.com/get-product-info`, {
                method:'POST',
                //body:JSON.stringify(productImages),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({productNameExists, searchImageExists, mobileImageExists, miniCartImageExists, lastProductId, productCategoryId, 
                        lastSearchFilterTermId, lastProductDescriptionId, lastProductWeightId,
                        lastVerificationProductId, lastPromotionProductId})=>{
                    //console.log('pne:', productNameExists);
                    //console.log('sie:', searchImageExists);
                    //console.log('mie:', mobileImageExists);
                    //console.log('mcie:', miniCartImageExists);
                    //console.log('lpId:', lastProductId);
                    //console.log('pcId:', productCategoryId, 'productCategoryNameSelected:', productCategoryNameSelected, 'productName:', productName);
                    //console.log('lsftId:', lastSearchFilterTermId);
                    //console.log('lpwid:', lastProductWeightId);
                    //console.log('lvpid:', lastVerificationProductId);
                    //console.log('lppid:', lastPromotionProductId);
                    //console.log('sfgs:', searchFilterGroupsSelected);
                    //console.log('esfgs:', existingSearchFilterGroupsSelected);
                    

                    if(productNameExists || searchImageExists || mobileImageExists || miniCartImageExists){

                        //cancel process request msg
                        setShowProcessingRequestMsg(false);

                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.productNameError =  {hasError:false, area:'none'};


                        if(searchImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Product Search Image', fileName:productSearchImages[0].name})
                        }

                        if(mobileImageExists){
                            dbError.imageErrors.push({hasError:true, area:'Product Mobile Image', fileName:productMobileImages[0].name})
                        }

                        if(miniCartImageExists){
                            dbError.imageErrors.push({hasError:true, area:'Product Mini Cart Image', fileName:productMiniCartImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(productNameExists){
                            dbError.productNameError =  {hasError:true, area:productName};
                        }

                        dbError.productNameError = (dbError.productNameError.hasError) 
                                                    ? dbError.productNameError 
                                                    : {hasError:false, area:'none'};

                        const de = {...dbError};
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
            
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;


                            if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                dbemc.style.height = `${window.innerHeight + 1}px`;
                                dbemc.style.overflowY = 'scroll';
                                dbemc.style.overscrollBehaviorY = 'contain';
                            }
            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        //const pcId = window.parseInt(productCategoryId, 10);
                        const nextProductId = getNextNumberId(lastProductId);
                        const nextProductDescriptionId = getNextNumberId(lastProductDescriptionId);
                        const nextProductWeightId = getNextNumberId(lastProductWeightId);
                        //const lastVPId = window.parseInt(lastVerificationProductId, 10);
                        //const lastPPId = window.parseInt(lastPromotionProductId, 10);
                        const price = window.parseFloat(priceString);
                        const uomp = window.parseFloat(uomPriceString);
                        //create new product filled with form data fields
                        const product = new Product({
                                                productId:nextProductId,
                                                productName:pn,
                                                productLinkName:plns,
                                                productSubCategoryName:pscns,
                                                productCategoryId:productCategoryId,
                                                //productDescription:addProductDescription(nextProductDescriptionId, nextProductId, pd),
                                                totalPrice:price,
                                                eachPrice:price,
                                                unitOfMeasure:uoms,
                                                unitOfMeasureListPrice:uomp,//getUnitOfMeasureListPrice(uomp, uoms),
                                                weightBy:wbs,
                                                productQtyLimit:parseInt(productQtyLimit,10),
                                                mobileImageWidth:mobileImageWidth,
                                                mobileImageHeight:mobileImageHeight
                                            });

                        //console.log('product:', product);
    

                        //add product description
                        const productDescription = addProductDescription(nextProductDescriptionId, nextProductId, pd);

                        //add weight range if selected
                        const productWeight = (wrs.length > 0 && wbs === 'pound') ? getProductWeight(nextProductWeightId, wrs, nextProductId) : null;

                        //add promotion products
                        const spps = pps.length > 0 ? getPromitionProducts(pps, lastPromotionProductId, nextProductId, formAction) : [];

                        //add verification products
                        const svps = pvs.length > 0 ? getVerificationProducts(pvs, lastVerificationProductId, nextProductId, formAction) : [];

                        //all available search filter groups selected get added to the search filter term db table
                        //search filter groups selected (available)
                        const asfts = (sfgs !== undefined && sfgs.length > 0)
                                    ? getSearchFilterTerms(sfgs, nextProductId, lastSearchFilterTermId, formAction)
                                    : [];     

                            
                        //console.log('exiting sfgs:', existingSfgs);
                        //all existing search filter groups get removed from the search filter term db table (existing)
                        //need to create a new function that only takes existing productId and returns the complete existing search filter term
                        //so the searchfiltertermId can be located and removed from the db
                        const esfts = (existingSfgs !== undefined && existingSfgs.length > 0)
                                        ? getSearchFilterTerms(existingSfgs, nextProductId, lastSearchFilterTermId, formAction)
                                        : [];



                        const fd = new FormData();
                        //add product info
                        fd.append('product-info', JSON.stringify(product));
                        //add product description
                        fd.append('product-description', JSON.stringify(productDescription));
                        //add product weight range if it exists
                        if(productWeight !== null){
                            fd.append('product-weight', JSON.stringify(productWeight));
                        }
                        //add promotion products
                        if(spps.length > 0){
                            fd.append('promotion-products', JSON.stringify(spps));
                        }
                        //add verification products
                        if(svps.length > 0){
                            fd.append('verification-products', JSON.stringify(svps));
                        }
                        //add available search filter terms
                        if(asfts.length > 0){
                            fd.append('available-search-filter-terms', JSON.stringify(asfts));
                        }
                        //add existing search fitler terms
                        if(esfts.length > 0){
                            fd.append('existing-search-filter-terms', JSON.stringify(esfts));
                        }
                        //add product search image
                        fd.append('search-image', pSearchimages[0]);

                        //add product solo image
                        if(pSoloImages.length > 0){
                            fd.append('solo-image', pSoloImages[0]);
                        }
                        
                        //add product mobile image
                        if(pMobileImages.length > 0){
                            fd.append('mobile-image', pMobileImages[0]);
                        }
                        
                        //add product mini cart image
                        if(pMiniCartImages.length > 0){
                            fd.append('mini-cart-image', pMiniCartImages[0]);
                        }

                        setIsLoading(true);

                        fetch(`https://server.kando-proto-3.com/add-individual-product`, {
                            method:'POST',
                            body:fd
                        })
                        .then((res)=>{
                            return res.json();
                        })
                        .then((res)=>{
                            //console.log('add individual product response:', res);
                            //close form update product list

                            
                            handleCloseForm('addProduct');

                            let root = document.documentElement;
                            root!.setAttribute('style', 'scroll-behavior: auto');
                            root.scrollTo(0,0);
                            window.setTimeout(()=>{
                                root!.removeAttribute('style');
                            },100);
                            
                            

                        })
                        .catch((err)=>console.error('add individual product fetch error:', err.message));

                    }

                })
                .catch((err)=>{
                    console.error('product search err:', err)
                });
                
                
            } else {

                let searchImageName = productSearchImages.length > 0 ? productSearchImages[0].name : 'none';
                    //searchImageName = formatFilePathName(productSearchImages[0].name, 'search').toLowerCase();
                let mobileImageName = productMobileImages.length > 0 ? productMobileImages[0].name : 'none';
                    //mobileImageName = formatFilePathName(productMobileImages[0].name, 'mobile').toLowerCase();
                let miniCartImageName = productMiniCartImages.length > 0 ? productMiniCartImages[0].name : 'none';
                    //miniCartImageName = formatFilePathName(productMiniCartImages[0].name, 'miniCart').toLowerCase();

                const productImages = {
                    searchImageName:searchImageName,
                    mobileImageName:mobileImageName,
                    miniCartImageName:miniCartImageName
                };

                //console.log('pi 6-13:', productImages, productName);

                const urlParams = {productCategoryNameSelected, 
                                    productName, 
                                    productToEditId,
                                    searchImageName,
                                    mobileImageName,
                                    miniCartImageName
                                };

                const params = JSON.stringify(urlParams);

                //previous version
                //fetch(`http://localhost:9500/check-product-exists/${productCategoryNameSelected}/${encodeURIComponent(productName)}/${productToEditId}`, {
                fetch(`https://server.kando-proto-3.com/check-product-exists`, {
                    method:'POST',
                    //body:JSON.stringify(productImages),
                    headers:{
                        'Accept':'application/json',
                        'Content-Type':'application/json'
                    },
                    body:params
                    })
                    .then((res)=>{
                        return res.json();
                    })
                    .then(({productNameExists, searchImageExists, mobileImageExists, miniCartImageExists, productId, productCategoryId})=>{
                        //console.log('productNameExists:', productNameExists);
                        //console.log('searchImageExists:', searchImageExists);
                        //console.log('mobileImageExists:', mobileImageExists);
                        //console.log('miniCartImageExists:', miniCartImageExists);
                        //console.log('pcId:', productCategoryId);
                        //console.log('pId:', productId);
                        if(productNameExists || searchImageExists || mobileImageExists || miniCartImageExists){

                            //cancel process request msg
                            setShowProcessingRequestMsg(false);

                            //clear all error messages
                            dbError.imageErrors = [];
                            dbError.productNameError =  {hasError:false, area:'none'};

                            if(searchImageExists ){
                                dbError.imageErrors.push({hasError:true, area:'Product Search Image', fileName:productSearchImages[0].name})
                            }
    
                            if(mobileImageExists){
                                dbError.imageErrors.push({hasError:true, area:'Product Mobile Image', fileName:productMobileImages[0].name})
                            }
    
                            if(miniCartImageExists){
                                dbError.imageErrors.push({hasError:true, area:'Product Mini Cart Image', fileName:productMiniCartImages[0].name})
                            }
    
                            dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];
    
                            if(productNameExists){
                                dbError.productNameError =  {hasError:true, area:productName};
                            }
    
                            dbError.productNameError = (dbError.productNameError.hasError) 
                                                        ? dbError.productNameError 
                                                        : {hasError:false, area:'none'};
    

                            const de = {...dbError};
                            
                            setDBError(de);

                            const raf1 = window.requestAnimationFrame(()=>{
            
                                addDBFormLabelErrors(dbError);

                                //show all db errors
                                const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                const ew = dbemc.offsetWidth;
                                const eh = dbemc.offsetHeight;
                                //console.log('eh:', eh);
                                //dbemc.style.marginLeft = `-${ew / 2}px`;
                                dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                                dbemc.style.top = `-${eh}px`;


                                if(dbemc.getBoundingClientRect().height > window.innerHeight){
                                    dbemc.style.height = `${window.innerHeight + 1}px`;
                                    dbemc.style.overflowY = 'scroll';
                                    dbemc.style.overscrollBehaviorY = 'contain';
                                }

                
                                const raf2 = window.requestAnimationFrame(()=>{
                                                const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                                dbemc.style.top = '0px';
                
                                                //close error message container
                                                /*
                                                window.setTimeout(()=>{
                                                    dbemc.style.top = `-${eh}px`;
                                                },3000);
                                                */
                
                                                window.cancelAnimationFrame(raf2);
                                            });
                                
                                window.cancelAnimationFrame(raf1);
                
                            });

                        } else {

                            //finish add this promise
                            //put code in else statement
                            //inside the last then statement
                            removeImagefromDatabase(productId, 'product')
                            .then((res)=>res.json())
                            .then(({msg})=>{
                                //console.log('msg 6-26:', msg);
                                
                                if(msg === 'product image removed'){
                                    
                                    const price = window.parseFloat(priceString);
                                    const uomp = window.parseFloat(uomPriceString);
                                    //create new product filled with form data fields
                                    const product = new Product({
                                                            productId:productId,
                                                            productName:pn,
                                                            productLinkName:plns,
                                                            productSubCategoryName:pscns,
                                                            productCategoryId:productCategoryId,
                                                            //productDescription:addProductDescription(nextProductDescriptionId, nextProductId, pd),
                                                            totalPrice:price,
                                                            eachPrice:price,
                                                            unitOfMeasure:uoms,
                                                            unitOfMeasureListPrice:uomp,//getUnitOfMeasureListPrice(uomp, uoms),
                                                            weightBy:wbs,
                                                            productQtyLimit:parseInt(productQtyLimit),
                                                            mobileImageWidth:mobileImageWidth,
                                                            mobileImageHeight:mobileImageHeight
                                                        });
                        
                                    //add product description
                                    const productDescription = addProductDescription(0, productId, pd);
                        
                                    //add weight range if selected
                                    const productWeight = (wrs.length > 0 && wbs === 'pound') ? getProductWeight(0, wrs, productId) : null;
                                    //console.log('pw:', productWeight);
                        
                                    //add promotion products
                                    const spps = pps.length > 0 ? getPromitionProducts(pps, 0, productId, formAction) : [];
                        
                                    //add verification products
                                    const svps = pvs.length > 0 ? getVerificationProducts(pvs, 0, productId, formAction) : [];
                        
                                    //all available search filter groups selected get added to the search filter term db table
                                    //search filter groups selected (available)
                                    const asfts = (sfgs !== undefined && sfgs.length > 0)
                                                ? getSearchFilterTerms(sfgs, productId, 0, formAction)
                                                : [];     
                        
                                        
                                    //console.log('exiting sfgs:', existingSfgs, 'avail:', sfgs);
                                    
                                    //all existing search filter groups get removed from the search filter term db table (existing)
                                    //need to create a new function that only takes existing productId and returns the complete existing search filter term
                                    //so the searchfiltertermId can be located and removed from the db
                                    const esfts = (existingSfgs !== undefined && existingSfgs.length > 0)
                                                    ? getSearchFilterTerms(existingSfgs, productId, 0, formAction)
                                                    : [];
                        
                        
                                    const fd = new FormData();
                                    //add product info
                                    fd.append('product-info', JSON.stringify(product));
                                    //add product description
                                    fd.append('product-description', JSON.stringify(productDescription));
                                    //add product weight range if it exists
                                    if(productWeight !== null){
                                        fd.append('product-weight', JSON.stringify(productWeight));
                                    }
                                    //add promotion products
                                    if(spps.length > 0){
                                        fd.append('promotion-products', JSON.stringify(spps));
                                    }
                                    //add verification products
                                    if(svps.length > 0){
                                        fd.append('verification-products', JSON.stringify(svps));
                                    }
                                    //add available search filter terms
                                    if(asfts.length > 0){
                                        fd.append('available-search-filter-terms', JSON.stringify(asfts));
                                    }
                                    //add existing search fitler terms
                                    if(esfts.length > 0){
                                        //console.log('esfts:', esfts);
                                        fd.append('existing-search-filter-terms', JSON.stringify(esfts));
                                    }
                                    //add product search image
                                    fd.append('search-image', pSearchimages[0]);
                        
                                    //add product solo image
                                    if(pSoloImages.length > 0){
                                        fd.append('solo-image', pSoloImages[0]);
                                    }
                                    
                                    //add product mobile image
                                    if(pMobileImages.length > 0){
                                        fd.append('mobile-image', pMobileImages[0]);
                                    }
                                    
                                    //add product mini cart image
                                    if(pMiniCartImages.length > 0){
                                        fd.append('mini-cart-image', pMiniCartImages[0]);
                                    }
                        
        
                                    setIsLoading(true);
                        
                                    //update product in database
                                    fetch(`https://server.kando-proto-3.com/update-individual-product`, {
                                        method:'PUT',
                                        body:fd
                                        })
                                        .then((res)=>{
                                            return res.json();
                                        })
                                        .then((res)=>{
        
                                            handleCloseForm('editProduct');

                                            let root = document.documentElement;
                                            root!.setAttribute('style', 'scroll-behavior: auto');
                                            root.scrollTo(0,0);
                                            window.setTimeout(()=>{
                                                root!.removeAttribute('style');
                                            },100);

                                        })
                                        .catch((err)=>console.error('update individual product fetch error:', err.message));

                                }


                            });




                        }
                        




                    })
                    .catch((err)=>console.error('check product exists fetch error:', err.message));



            }
        
    }

    function formatFilePathName(filePathName:string, imgCategory:string):string {

        switch(imgCategory){
            case 'search':
                return filePathName.search(/\//i) === -1 ? `/images/search/${filePathName}` : filePathName;
            case 'mobile':
                return filePathName.search(/\//i) === -1 ? `/images/mobile/${filePathName}` : filePathName;
            case 'miniCart':
                return filePathName.search(/\//i) === -1 ? `/images/mini-cart/${filePathName}` : filePathName;
            default:
                throw new Error('no image category was found.');
        }
    }

    function getUnitOfMeasureListPrice(price:number, uom:string):number {
        return (uom === 'lb') ? price / 1.105 : price / 16;
    }

    function addProductDescription(descriptionId:number, productId:number, description:string):ProductDescription {
        return new ProductDescription({
                            descriptionId:descriptionId,
                            productId:productId,
                            description:description
                        });
    }

    function getNextNumberId(oid:string):number {
        let id = window.parseInt(oid, 10);
        return ++id;
    }

    
    function getProductWeight(productWeightId:number, weightRange:string, productId:number):ProductWeight {
        const wrs = weightRange.split(' ');
        const index1 = wrs[0].indexOf('l');
        const amt1 = wrs[0].slice(0,index1);
        const sw = parseFloat(amt1);
        const index2 = wrs[2].indexOf('l');
        const amt2 = wrs[2].slice(0,index2);
        const ew = parseFloat(amt2);
        //console.log('sw:', sw);
        //console.log('ew:', ew);
        let wi = 0;
        if((sw === 0.25 && ew === 5)
            || (sw === 0.25 && ew === 10)){
            wi = 0.25;
        } else {
            wi = 1;
        }

        return new ProductWeight({
                    weightId:productWeightId,
                    productId:productId,
                    startWeight:sw,
                    endWeight:ew,
                    weightIncrement:wi
                });
    }

    function getSearchFilterTerms(sfgs:SearchFilterGroup[], productId:number, lastSearchFilterTermId:number, formAction:string):SearchFilterTerm[] {
        let sfts:SearchFilterTerm[] = [];
        sfgs.forEach((sfg)=>{
                            sfg.searchFilters.forEach((sf)=>{
                                sf.searchTerms.forEach((st)=>{
                                    const sft = new SearchFilterTerm({
                                                searchFilterTermId:(formAction === 'addProduct') ? ++lastSearchFilterTermId : 0,
                                                productId:productId,
                                                searchFilterAreaType:sfg.groupCategoryName,
                                                searchFilterArea:sfg.groupFilterName,
                                                searchFilterTermName:sf.filterName,
                                                searchFilterTermValue:st.searchTermName
                                            });

                                    sfts.push(sft);
                                });
                            })
                            

                    });

        //console.log('sfts:', sfts);
        
        return sfts;

    }

    
    function getPromitionProducts(pps:ProductPromotion[], lastPromotionProductId:number, productId:number, formAction:string):PromotionProduct[]{

        
        return pps.map((pp)=>{
            //console.log('ppId:', pp.productPromotionId);
                    return new PromotionProduct({
                                    promotionProductId:(formAction === 'addProduct') ? ++lastPromotionProductId : 0,
                                    productId:productId,
                                    productPromotionId:pp.productPromotionId
                                });

                });
                
    }

    function getVerificationProducts(pvs:ProductVerification[], lastVerificationProductId:number, productId:number, formAction:string):VerificationProduct[] {
        return pvs.map((vp)=>{
            return new VerificationProduct({
                            verificationProductId:(formAction === 'addProduct') ? ++lastVerificationProductId : 0,
                            productId:productId,
                            productVerificationId:vp.productVerificationId
                        });

        });
    }
    
    
    
    

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Department Name':
                                //dnLabelRef.current!.classList.add('product-info-error');
                                dSelectElementRef.current!.dSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Department Category Name':
                                //dcnLabelRef.current!.classList.add('product-info-error');
                                dcSelectElementRef.current!.dcSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Category Name':
                                //pcnLabelRef.current!.classList.add('product-info-error');
                                pcSelectElementRef.current!.pcSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Name':
                                pnLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Description':
                                pdLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Product Qty Limit':
                                pqlLabelRef.current!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no product info errors found!');
                        }
                    }
                });

        //set pricing errors
        formError.pricingErrors.forEach((pe)=>{
            if(pe.hasError){
                //console.log('pe.area:', pe.area);
                switch(pe.area){
                    case 'Price':
                        priceLabelRef.current!.classList.add('pricing-error');
                        break;
                    case 'Unit Of Measure Price':
                        uomPriceLabelRef.current!.classList.add('pricing-error');
                        break;
                    case 'Unit Of Measure':
                        //uomLabelRef.current!.classList.add('pricing-error');
                        uomLabelSelectRef.current!.classList.add('pricing-error');
                        break;
                    case 'Weight':
                        //weightLabelRef.current!.classList.add('pricing-error');
                        weightLabelSelectRef.current!.classList.add('pricing-error');
                        break;
                    case 'Weight Range':
                        //wrLabelRef.current!.classList.add('pricing-error');
                        wrLabelSelectRef.current!.classList.add('pricing-error');
                        break;
                    default:
                        throw new Error('no pricing errors found!');
                }
            }
        });

        //set image errors
        formError.imageErrors.forEach((ie)=>{
            if(ie.hasError){
                //console.log('ie.area:', ie.area);
                switch(ie.area){
                    case 'Product Search Image':
                        //console.log('found');
                        pisLabelRef.current!.classList.add('image-error');
                        break;
                    case 'Product Solo Image':
                        pisoloLabelRef.current!.classList.add('image-error');
                        break;
                    case 'Product Mobile Image':
                        pimLabelRef.current!.classList.add('image-error');
                        break;
                    case 'Product Mini Cart Image':
                        pimcLabelRef.current!.classList.add('image-error');
                        break;
                    default:
                        throw new Error('no image errors found!');
                }
            }
        });
    }


    function addDBFormLabelErrors(dbError:DBError): void {
        //set product info errors
        if(dbError.productNameError!.hasError){
            pnLabelRef.current!.classList.add('product-info-error');
        }
        
        //set image errors
        dbError.imageErrors.forEach((ie)=>{
            if(ie.hasError){
                //console.log('ie.area:', ie.area);
                switch(ie.area){
                    case 'Product Search Image':
                        //console.log('found');
                        pisLabelRef.current!.classList.add('image-error');
                        break;
                    case 'Product Solo Image':
                        pisoloLabelRef.current!.classList.add('image-error');
                        break;
                    case 'Product Mobile Image':
                        pimLabelRef.current!.classList.add('image-error');
                        break;
                    case 'Product Mini Cart Image':
                        pimcLabelRef.current!.classList.add('image-error');
                        break;
                    default:
                        throw new Error('no image errors found!');
                }
            }
        });

    }


    function selectSearchFilterGroups(fes:HTMLFormControlsCollection, sfat:string, sfgType:string):SearchFilterGroup[]{
        let searchFilterGroupsSelected:SearchFilterGroup[] = [];


        //console.log('sfgns:', searchFilterGroupsNames);

        //check if category name exists in existing search filter groups
        if(sfgType === 'existing' && !existingSearchFilterGroupsNames.includes(sfat)){
            return searchFilterGroupsSelected;

        } 

        //check if category name exists in available search filter groups
        if(sfgType === 'available' && !searchFilterGroupsNames.includes(sfat)){
            return searchFilterGroupsSelected;
        }

        

        //original version
        //const d = fes.namedItem(`${sfat}`) as HTMLInputElement;
        const d = (sfgType === 'existing') ? fes.namedItem(`existing-${sfat}`) as HTMLInputElement : fes.namedItem(`${sfat}`) as HTMLInputElement;
        if(d.checked){
            //console.log('dept:', d.value);
            const sgcn = d.dataset['searchGroupCategoryName'];
            const sgfn = d.dataset['searchGroupFilterName'];
            const sfg = new SearchFilterGroup({
                                            groupCategoryName:sgcn,
                                            groupFilterName:sgfn
                                                });
            searchFilterGroupsSelected.push(sfg);
            //console.log('sfg:', sfg);
        }

        //original version
        //const dsfs = fes.namedItem(`${sfat} Search Filter`);
        const dsfs = (sfgType === 'existing') ? fes.namedItem(`existing-${sfat} Search Filter`) : fes.namedItem(`${sfat} Search Filter`);
        if(dsfs !== null){
            if(dsfs instanceof RadioNodeList){            
                Array.from(dsfs as RadioNodeList).forEach((sf)=>{
                    const dsf = sf as HTMLInputElement;
                    if(dsf.checked){
                        //console.log('sf:', dsf.value);
                        const sgcn = dsf.dataset['searchGroupCategoryName'];
                        const sgfn = dsf.dataset['searchGroupFilterName'];
                        const id = window.parseInt(dsf.dataset['searchFilterId'] as string, 10);
                        //console.log('sf id:', id);
                        const sf = new SearchFilter({
                                            searchFilterAreaType:sgcn,
                                            searchFilterArea:sgfn,
                                            filterName:dsf.value,
                                            searchFilterId:id
                                        });
                        //sfgDepartmentSearchFilters.push(sf);
                        //setSFGDepartmentSearchFilters(sfgDepartmentSearchFilters);
                        const usfgs = searchFilterGroupsSelected.map((sfgs)=>{
                                                        if(sfgs.groupCategoryName === sgcn){
                                                            sfgs.searchFilters.push(sf);
                                                        }
                                                        return sfgs;
                                                    });

                        //setSearchFilterGroupsSelected(usfgs);
                        searchFilterGroupsSelected = usfgs;
                        //console.log('usfgs:', usfgs);
                    }
                });

            } else {

                const dsf = dsfs as HTMLInputElement;
                if(dsf.checked){
                    //console.log('sf:', dsf.value);
                    const sgcn = dsf.dataset['searchGroupCategoryName'];
                    const sgfn = dsf.dataset['searchGroupFilterName'];
                    const id = window.parseInt(dsf.dataset['searchFilterId'] as string, 10);
                    //console.log('sf id:', id);
                    const sf = new SearchFilter({
                                        searchFilterAreaType:sgcn,
                                        searchFilterArea:sgfn,
                                        filterName:dsf.value,
                                        searchFilterId:id
                                    });
                    //sfgDepartmentSearchFilters.push(sf);
                    //setSFGDepartmentSearchFilters(sfgDepartmentSearchFilters);
                    const usfgs = searchFilterGroupsSelected.map((sfgs)=>{
                                                    if(sfgs.groupCategoryName === sgcn){
                                                        sfgs.searchFilters.push(sf);
                                                    }
                                                    return sfgs;
                                                });

                    //setSearchFilterGroupsSelected(usfgs);
                    searchFilterGroupsSelected = usfgs;
                    //console.log('usfgs:', usfgs);
                }

            }
        }
        //console.log('Department Search Terms:', fes.namedItem('Department Search Term'));
        //original version
        //const dsts = fes.namedItem(`${sfat} Search Term`);
        const dsts = (sfgType === 'existing') ? fes.namedItem(`existing-${sfat} Search Term`) : fes.namedItem(`${sfat} Search Term`);
        if(dsts !== null){
            if(dsts instanceof RadioNodeList){
                Array.from(dsts as RadioNodeList).forEach((st)=>{
                    const dst = st as HTMLInputElement;
                    if(dst.checked){
                        //console.log('dept st:', dst.value);
                        const sfn = dst.dataset['searchFilterName'];
                        const sgcn = dst.dataset['searchGroupCategoryName'];
                        const sgfn = dst.dataset['searchGroupFilterName'];
                        const id = window.parseInt(dst.dataset['searchTermId'] as string, 10);
                        //console.log('st id:', id);
    
                        const usfgs = searchFilterGroupsSelected.map((sfgs)=>{
                                                        if(sfgs.groupCategoryName === sgcn && sfgs.searchFilters.length > 0){
                                                            const usfs = sfgs.searchFilters.map((sf)=>{
                                                                            if(sf.filterName === sfn){
                                                                                const st = new SearchTerm({
                                                                                                        searchFilterAreaType:sgcn,
                                                                                                        searchFilterArea:sgfn,
                                                                                                        searchFilterName:sfn,
                                                                                                        searchTermName:dst.value,
                                                                                                        searchTermId:id
                                                                                                    });
                                                                                sf.searchTerms.push(st);
                                                                            }
    
                                                                            return sf;
                                                                        });
                                                            sfgs.searchFilters = usfs;
                                                        }
    
                                                        return sfgs;
                                                    });
    
                        searchFilterGroupsSelected = usfgs;
                        //console.log('sfgs:', usfgs);
                    }
                    
                });

            } else {

                const dst = dsts as HTMLInputElement;
                if(dst.checked){
                    //console.log('dept st:', dst.value);
                    const sfn = dst.dataset['searchFilterName'];
                    const sgcn = dst.dataset['searchGroupCategoryName'];
                    const sgfn = dst.dataset['searchGroupFilterName'];
                    const id = window.parseInt(dst.dataset['searchTermId'] as string, 10);
                    //console.log('st id:', id);

                    const usfgs = searchFilterGroupsSelected.map((sfgs)=>{
                                                    if(sfgs.groupCategoryName === sgcn && sfgs.searchFilters.length > 0){
                                                        const usfs = sfgs.searchFilters.map((sf)=>{
                                                                        if(sf.filterName === sfn){
                                                                            const st = new SearchTerm({
                                                                                                    searchFilterAreaType:sgcn,
                                                                                                    searchFilterArea:sgfn,
                                                                                                    searchFilterName:sfn,
                                                                                                    searchTermName:dst.value,
                                                                                                    searchTermId:id
                                                                                                });
                                                                            sf.searchTerms.push(st);
                                                                        }

                                                                        return sf;
                                                                    });
                                                        sfgs.searchFilters = usfs;
                                                    }

                                                    return sfgs;
                                                });

                    searchFilterGroupsSelected = usfgs;
                    //console.log('sfgs:', usfgs);
                }

            }

        }


        return searchFilterGroupsSelected;
    }

    function checkSearchFilterGroupErrors(form:HTMLFormElement, sfgType:string):SFGErrorInfo {

        const fes = form.elements as HTMLFormControlsCollection;
        let needSearchFilter:boolean = false;
        let needSearchTerm:boolean = false;


        const dssfgs = selectSearchFilterGroups(fes, 'Department', sfgType);
        if(dssfgs.length > 0){
            //console.log('dssfgs:', dssfgs);
            if(dssfgs[0].searchFilters.length === 0){
                needSearchFilter = true;
                //console.log('group category name:', dssfgs[0].groupCategoryName);
                //console.log('group filter name:', dssfgs[0].groupFilterName);
                //console.log('elems:', (sfgType === 'existing') ? form.getElementsByClassName(`existing-${dssfgs[0].groupFilterName}`) : form.getElementsByClassName(dssfgs[0].groupFilterName));
                //original version
                //const sfElems = Array.from(form.getElementsByClassName(dssfgs[0].groupFilterName));
                const sfElems = (sfgType === 'existing') ? Array.from(form.getElementsByClassName(`existing-${dssfgs[0].groupFilterName}`)) : Array.from(form.getElementsByClassName(dssfgs[0].groupFilterName));
                sfElems.forEach((el)=>{
                    //console.log('el test d:', el);
                    const input = (el.firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement;
                    const sgcn = input.dataset['searchGroupCategoryName'];
                    const sgfn = input.dataset['searchGroupFilterName'];
                    if(sgcn === dssfgs[0].groupCategoryName && sgfn === dssfgs[0].groupFilterName){
                        //console.log('el test d:', el);
                        el.classList.add('no-sfs-selected');
                        //sfelist.push(el);
                    }
                    //el.classList.add('no-sfs-selected');
                    //sfelist.push(el);
                });

                
                /*
                window.setTimeout(()=>{
                    sfElems.forEach((el)=>{
                        el.classList.remove('no-sfs-selected');
                    });
                },3000);
                */
                


            } else {
                dssfgs[0].searchFilters.forEach((sf)=>{
                                        if(sf.searchTerms.length === 0){
                                            needSearchTerm = true;
                                            //original version
                                            //const sfElems = Array.from(form.getElementsByClassName(dssfgs[0].groupFilterName));
                                            const sfElems = (sfgType === 'existing') ? Array.from(form.getElementsByClassName(`existing-${dssfgs[0].groupFilterName}`)) : Array.from(form.getElementsByClassName(dssfgs[0].groupFilterName));
                                            sfElems.forEach((el)=>{
                                                //original version
                                                //const stElems = Array.from(el.getElementsByClassName(`${sf.filterName}`));
                                                const stElems = (sfgType === 'existing') ? Array.from(el.getElementsByClassName(`existing-${formatSearchFilterName(sf.filterName, dssfgs[0].groupFilterName)}`)) : Array.from(el.getElementsByClassName(`${formatSearchFilterName(sf.filterName, dssfgs[0].groupFilterName)}`));
                                                stElems.forEach((el, i, elems)=>{
                                                    const input = (el.firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement;
                                                    const sgcn = input.dataset['searchGroupCategoryName'];
                                                    const sgfn = input.dataset['searchGroupFilterName'];
                                                    if(sgcn === dssfgs[0].groupCategoryName && sgfn === dssfgs[0].groupFilterName){
                                                        //console.log('el test d:', el);
                                                        el.classList.add('no-sts-selected');
                                                        //sfelist.push(el);
                                                        const numberOfSearchTerms = elems.length;
                                                        const ah = 0;//6 / numberOfSearchTerms;
                                                        (el.firstElementChild as HTMLDivElement).style.height = `${50 - ah}px`;
                                                    }
                                                    //el.classList.add('no-sts-selected');
                                                    //stelist.push(el);
                                                    //const numberOfSearchTerms = elems.length;
                                                    //const ah = 6 / numberOfSearchTerms;
                                                    //(el.firstElementChild as HTMLDivElement).style.height = `${24 - ah}px`;
                                                    //stahList.push(el.firstElementChild!);
                                                });

                                                
                                                /*
                                                window.setTimeout(()=>{
                                                    stElems.forEach((el)=>{
                                                        el.classList.remove('no-sts-selected');
                                                        (el.firstElementChild as HTMLDivElement).style.height = '50px';
                                                    });
                                                },3000);
                                                */
                                                
                                            });
                                        }
                                    });
            }


        } else {
            //console.log('no department sfg');
        }
        const dcssfgs = selectSearchFilterGroups(fes, 'Department Category', sfgType);
        if(dcssfgs.length > 0){
            //console.log('dcssfgs:', dcssfgs);
            if(dcssfgs[0].searchFilters.length === 0){
                needSearchFilter = true;
                //console.log('group category name:', dcssfgs[0].groupCategoryName);
                //console.log('group filter name:', dcssfgs[0].groupFilterName);
                //console.log('elems:', (sfgType === 'existing') ? form.getElementsByClassName(`existing-${dcssfgs[0].groupFilterName}`) : form.getElementsByClassName(dcssfgs[0].groupFilterName));
                //original version
                //const elems = Array.from(form.getElementsByClassName(dcssfgs[0].groupFilterName));
                const elems = (sfgType === 'existing') ? Array.from(form.getElementsByClassName(`existing-${dcssfgs[0].groupFilterName}`)) : Array.from(form.getElementsByClassName(dcssfgs[0].groupFilterName));
                elems.forEach((el)=>{
                    //console.log('has element:', el.classList);
                    const input = (el.firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement;
                    const sgcn = input.dataset['searchGroupCategoryName'];
                    const sgfn = input.dataset['searchGroupFilterName'];
                    if(sgcn === dcssfgs[0].groupCategoryName && sgfn === dcssfgs[0].groupFilterName){
                        el.classList.add('no-sfs-selected');
                        //sfelist.push(el);
                    }
                    //console.log('el test dc:', el, dcssfgs[0].groupCategoryName, sgcn);
                    //el.classList.add('no-sfs-selected');
                    
                });


                /*
                window.setTimeout(()=>{
                    elems.forEach((el)=>{
                        el.classList.remove('no-sfs-selected');
                    });
                },3000);
                */
                

            } else {
                dcssfgs[0].searchFilters.forEach((sf)=>{
                                        if(sf.searchTerms.length === 0){
                                            needSearchTerm = true;
                                            //original version
                                            //const sfElems = Array.from(form.getElementsByClassName(dcssfgs[0].groupFilterName));
                                            const sfElems = (sfgType === 'existing') ? Array.from(form.getElementsByClassName(`existing-${dcssfgs[0].groupFilterName}`)) : Array.from(form.getElementsByClassName(dcssfgs[0].groupFilterName));
                                            sfElems.forEach((el)=>{
                                                //original version
                                                //const stElems = Array.from(el.getElementsByClassName(`${sf.filterName}`));
                                                const stElems = (sfgType === 'existing') ? Array.from(el.getElementsByClassName(`existing-${formatSearchFilterName(sf.filterName, dcssfgs[0].groupFilterName)}`)) : Array.from(el.getElementsByClassName(`${formatSearchFilterName(sf.filterName, dcssfgs[0].groupFilterName)}`));
                                                stElems.forEach((el, i, elems)=>{
                                                    const input = (el.firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement;
                                                    const sgcn = input.dataset['searchGroupCategoryName'];
                                                    const sgfn = input.dataset['searchGroupFilterName'];
                                                    if(sgcn === dcssfgs[0].groupCategoryName && sgfn === dcssfgs[0].groupFilterName){
                                                        //console.log('el test dc:', el);
                                                        el.classList.add('no-sts-selected');
                                                        //stelist.push(el);
                                                        const numberOfSearchTerms = elems.length;
                                                        const ah = 0;//7 / numberOfSearchTerms;
                                                        (el.firstElementChild as HTMLDivElement).style.height = `${50 - ah}px`;
                                                        //stahList.push(el.firstElementChild!);
                                                    }
                                                });


                                                /*
                                                window.setTimeout(()=>{
                                                    stElems.forEach((el)=>{
                                                        el.classList.remove('no-sts-selected');
                                                        (el.firstElementChild as HTMLDivElement).style.height = '50px';
                                                    });
                                                },3000);
                                                */
                                                
                                            });
                                        }
                                    });
            }


        } else {
            //console.log('no department category sfg');
        }
        const pcssfgs = selectSearchFilterGroups(fes, 'Product Category', sfgType);
        if(pcssfgs.length > 0){
            //console.log('pcssfgs:', pcssfgs);
            if(pcssfgs[0].searchFilters.length === 0){
                needSearchFilter = true;
                //console.log('group category name:', pcssfgs[0].groupCategoryName);
                //console.log('group filter name:', pcssfgs[0].groupFilterName);
                //console.log('elems:', (sfgType === 'existing') ? form.getElementsByClassName(`existing-${pcssfgs[0].groupFilterName}`) : form.getElementsByClassName(pcssfgs[0].groupFilterName));
                //original version
                //const elems = Array.from(form.getElementsByClassName(pcssfgs[0].groupFilterName));
                const elems = (sfgType === 'existing') ? Array.from(form.getElementsByClassName(`existing-${pcssfgs[0].groupFilterName}`)) : Array.from(form.getElementsByClassName(pcssfgs[0].groupFilterName));
                elems.forEach((el)=>{
                    //console.log('el test pc:', el);
                    const input = (el.firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement;
                    const sgcn = input.dataset['searchGroupCategoryName'];
                    const sgfn = input.dataset['searchGroupFilterName'];
                    if(sgcn === pcssfgs[0].groupCategoryName && sgfn === pcssfgs[0].groupFilterName){
                        el.classList.add('no-sfs-selected');
                        //sfelist.push(el);
                    }
                    //el.classList.add('no-sfs-selected');
                    
                });

                /*
                window.setTimeout(()=>{
                    elems.forEach((el)=>{
                        el.classList.remove('no-sfs-selected');
                    });
                },3000);
                */
                

            } else {
                pcssfgs[0].searchFilters.forEach((sf)=>{
                                        if(sf.searchTerms.length === 0){
                                            //console.log('t1:');
                                            needSearchTerm = true;
                                            //original version
                                            //const sfElems = Array.from(form.getElementsByClassName(pcssfgs[0].groupFilterName));
                                            const sfElems = (sfgType === 'existing') ? Array.from(form.getElementsByClassName(`existing-${pcssfgs[0].groupFilterName}`)) : Array.from(form.getElementsByClassName(pcssfgs[0].groupFilterName));
                                            sfElems.forEach((el)=>{
                                                //original version
                                                //const stElems = Array.from(el.getElementsByClassName(`${sf.filterName}`));
                                                const stElems = (sfgType === 'existing') ? Array.from(el.getElementsByClassName(`existing-${formatSearchFilterName(sf.filterName, pcssfgs[0].groupFilterName)}`)) : Array.from(el.getElementsByClassName(`${formatSearchFilterName(sf.filterName, pcssfgs[0].groupFilterName)}`));
                                                stElems.forEach((el, i, elems)=>{
                                                    const input = (el.firstElementChild as HTMLDivElement).firstElementChild as HTMLInputElement;
                                                    const sgcn = input.dataset['searchGroupCategoryName'];
                                                    const sgfn = input.dataset['searchGroupFilterName'];
                                                    if(sgcn === pcssfgs[0].groupCategoryName && sgfn === pcssfgs[0].groupFilterName){
                                                        el.classList.add('no-sts-selected');
                                                        //stelist.push(el);
                                                        const numberOfSearchTerms = elems.length;
                                                        const ah = 0;//7 / numberOfSearchTerms;
                                                        (el.firstElementChild as HTMLDivElement).style.height = `${50 - ah}px`;
                                                        //stahList.push(el.firstElementChild!);
                                                    }
                                                });

                                                /*
                                                window.setTimeout(()=>{
                                                    stElems.forEach((el)=>{
                                                        el.classList.remove('no-sts-selected');
                                                        (el.firstElementChild as HTMLDivElement).style.height = '50px';
                                                    });
                                                },3000);
                                                */
                                                
                                            });
                                        }
                                    });
            }



        } else {
            //console.log('no product category sfg');
        }
        
        //add error messages
        if(needSearchFilter && needSearchTerm){
            //add search filter and search term error message
            /*
            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
            const ew = sfgemc.offsetWidth;
            sfgemc.style.marginLeft = `-${ew / 2}px`;
            sfgemc.style.top = '0px';
            
            //remove search term and search filter error message
            window.setTimeout(()=>{               

                sfgemc.style.top = '-125px';
            },3000);
            */

            //const em = {msg:'Need a search filter and a search term', type:'both'};
            //setSfgErrorMessage(em);
            (sfgType === 'existing') ? setExistingSearchFilterGroupsSelected([]) : setSearchFilterGroupsSelected([]);

            return (sfgType === 'existing') 
                    ? {existingSfgErrors:true, existingArea:'both', existingSfgs:[]} 
                    : {sfgErrors:true, area:'both', sfgs:[]};


        } else if(needSearchFilter && !needSearchTerm) {
            //add search filter error message
            /*
            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
            const ew = sfgemc.offsetWidth;
            sfgemc.style.marginLeft = `-${ew / 2}px`;
            sfgemc.style.top = '0px';

            //remove search filter error message
            window.setTimeout(()=>{

                sfgemc.style.top = '-125px';
            },3000);
            */

            //const em = {msg:'Need a search filter', type:'searchFilter'};
            //setSfgErrorMessage(em);
            (sfgType === 'existing') ? setExistingSearchFilterGroupsSelected([]) : setSearchFilterGroupsSelected([]);

            return (sfgType === 'existing') 
                    ? {existingSfgErrors:true, existingArea:'searchFilter', existingSfgs:[]}
                    : {sfgErrors:true, area:'searchFilter', sfgs:[]};

        } else if(needSearchTerm && !needSearchFilter){
            //add search term error message
            /*
            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
            const ew = sfgemc.offsetWidth;
            sfgemc.style.marginLeft = `-${ew / 2}px`;
            sfgemc.style.top = '0px';
            

            window.setTimeout(()=>{
                

                sfgemc.style.top = '-125px';

            },3000);
            */

            //const em = {msg:'Need a search term', type:'searchTerm'};
            //setSfgErrorMessage(em);

            (sfgType === 'existing') ? setExistingSearchFilterGroupsSelected([]) : setSearchFilterGroupsSelected([]);

            return (sfgType === 'existing') 
                    ? {existingSfgErrors:true, existingArea:'searchTerm', existingSfgs:[]}
                    :{sfgErrors:true, area:'searchTerm', sfgs:[]};

        }

        (sfgType === 'existing') 
        ? setExistingSearchFilterGroupsSelected([...dssfgs, ...dcssfgs, ...pcssfgs])
        : setSearchFilterGroupsSelected([...dssfgs, ...dcssfgs, ...pcssfgs]);

        return (sfgType === 'existing')
                ? {existingSfgErrors:false, existingArea:'none', existingSfgs:[...dssfgs, ...dcssfgs, ...pcssfgs]}
                : {sfgErrors:false, area:'none', sfgs:[...dssfgs, ...dcssfgs, ...pcssfgs]};

    }

    function handleFileUpload(e:React.ChangeEvent, imgCategory:string):void {
        //console.log('file upload:', (e.target as HTMLInputElement).files![0].size, (e.target as HTMLInputElement).files![0].size / (1024 ** 2));
        const target = e.target as HTMLInputElement;
        const fileList = target.files;
        const files = Array.from(fileList!);

        if(fileList![0].size / (1024 ** 2) <= 1.5){
            addThumbNailImage(files, target);

            if(imgCategory === 'search'){
                setShowSearchFileLimitError(false);
            }

            if(imgCategory === 'mobile'){
                setShowMobileFileLimitError(false);
            }

            if(imgCategory === 'miniCart'){
                setShowMiniCartFileLimitError(false);
            }
            

        } else {
            if(imgCategory === 'search'){
                setShowSearchFileLimitError(true);
            }

            if(imgCategory === 'mobile'){
                setShowMobileFileLimitError(true);
            }

            if(imgCategory === 'miniCart'){
                setShowMiniCartFileLimitError(true);
            }
        }

        
    }

    function imageClickHandler(e:React.MouseEvent):void {
        //console.log('e.target:', e.target);
        e.preventDefault();
        //e.stopPropagation();
        const target = e.target as HTMLLabelElement;
        if(target.classList.contains('no-color-label-link')
            || target.classList.contains('product-image-search-label-link')){
            //console.log('true');
            imageSearchRef.current!.value = '';
            imageSearchRef.current!.click();
        } else if(target.classList.contains('no-color-label-link')
             || target.classList.contains('product-image-solo-label-link')){
            //console.log('true');
            imageSoloRef.current!.value = '';
            imageSoloRef.current!.click();
        } else if(target.classList.contains('no-color-label-link')
             || target.classList.contains('product-image-mobile-label-link')){
            //console.log('true');
            imageMobileRef.current!.value = '';
            imageMobileRef.current!.click();
        } else {
            //console.log('true');
            //last file element in list
            imageMiniCartRef.current!.value = '';
            imageMiniCartRef.current!.click();
        }
        //eElement.click();
    }

    function addThumbNailImage(files:File[], target:HTMLInputElement):void {
        let fileImgs:ThumbnailImage[] = [];

        files.forEach((f)=>{
            const img = new Image();
            img.src = URL.createObjectURL(f);
            //img.height = 40;
            //img.width = 40;
            //not using this function because I need a local copy so
            //the image is found when deleting from list
            //would revoke them in general if I did need to reference a local copy
            //before saving the image to the database
            /*
            img.onload = function(){
                URL.revokeObjectURL(img.src);
            }*/
            
            fileImgs.push({name:f.name, img:img})
        });

        if(target === imageSearchRef.current!){
            setSearchThumbnailImages([...fileImgs]);
            setProductSearchImages([...files]);

        } else if(target === imageSoloRef.current!){
            setSoloThumbnailImages([...fileImgs]);
            setProductSoloImages([...files]);

        } else if(target === imageMobileRef.current!){
            setMobileThumbnailImages([...fileImgs]);
            setProductMobileImages([...files]);

        } else {
            //mini cart ref
            setMiniCartThumbnailImages([...fileImgs]);
            setProductMiniCartImages([...files]);
            
        }


                
    }

    function removeImageFromList2(listName:string):void {
        switch(listName){
            case 'search':
                //console.log('passed');
                //original version
                //setSearchThumbnailImages(stis);
                setSearchThumbnailImages([]);

                //original version
                setProductSearchImages([]);

                break;
            case 'solo':
                //original version
                //setSoloThumbnailImages(sotis);
                setSoloThumbnailImages([]);

                //original version
                //setProductSoloImages(sois);
                setProductSoloImages([]);

                break;
            case 'mobile':
                
                //original version
                //setMobileThumbnailImages(mtis);
                setMobileThumbnailImages([]);

                //original version
                //setProductMobileImages(mis);
                setProductMobileImages([]);

                break;
            case 'miniCart':
                
                //original version
                //setMiniCartThumbnailImages(mctis);
                setMiniCartThumbnailImages([]);

                //original version
                //setProductMiniCartImages(mcis);
                setProductMiniCartImages([]);

                break;
            default:
                throw new Error('no list name found!');

        }
    }

    function removeImageFromList(img:HTMLImageElement, listName:string, fileNameSelected:string):void {
        //console.log('img 6-7:', img, `/images/${listName}/${fileNameSelected}`, fileNameSelected);
        
        const imageToDelete = {filePath:`/images/${(listName === 'miniCart') ? 'mini-cart' : listName}/${fileNameSelected}`, directory:(listName === 'miniCart') ? 'mini-cart' : listName};

        fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({msg})=>{

                    switch(listName){
                        case 'search':
                            //console.log('passed');
                            const stis = searchThumbnailImages.filter((i)=>{
                                                return i.img !== img;
                                            });
        
                            //original version
                            //setSearchThumbnailImages(stis);
                            setSearchThumbnailImages([]);
        
                            const sis = productSearchImages.filter((f)=>{
                                            return f.name !== fileNameSelected;
                                        });
        
                            //original version
                            setProductSearchImages([]);
        
                            break;
                        case 'solo':
                            const sotis = soloThumbnailImages.filter((i)=>{
                                return i.img !== img;
                            });
                            //original version
                            //setSoloThumbnailImages(sotis);
                            setSoloThumbnailImages([]);
        
                            const sois = productSoloImages.filter((f)=>{
                                            return f.name !== fileNameSelected;
                                        });
                            //original version
                            //setProductSoloImages(sois);
                            setProductSoloImages([]);

                            break;
                        case 'mobile':
                            const mtis = mobileThumbnailImages.filter((i)=>{
                                            return i.img !== img;
                                        });
                            
                            //original version
                            //setMobileThumbnailImages(mtis);
                            setMobileThumbnailImages([]);
        
                            const mis = productMobileImages.filter((f)=>{
                                            return f.name !== fileNameSelected;
                                        });
        
                            //original version
                            //setProductMobileImages(mis);
                            setProductMobileImages([]);

                            break;
                        case 'miniCart':
                            const mctis = miniCartThumbnailImages.filter((i)=>{
                                return i.img !== img;
                            });
                            
                            //original version
                            //setMiniCartThumbnailImages(mctis);
                            setMiniCartThumbnailImages([]);
        
                            const mcis = productMiniCartImages.filter((f)=>{
                                            return f.name !== fileNameSelected;
                                        }); 
        
                            //original version
                            //setProductMiniCartImages(mcis);
                            setProductMiniCartImages([]);
        
                            break;
                        default:
                            throw new Error('no list name found!');
        
                    }



                    //console.log('image removed from form:', msg);
                })
                .catch((err)=>console.error('remove image from public folder error:', err.message));

    }

    function removeImagefromDatabase(searchFilterAreaId:number, filePathDirectorySelected:string):Promise<Response> {
        //original version
        //const imageToDelete = {filePath:`${filePathSelected}`, directory:`${filePathSelectedDirectory}`};

        const imageToDelete = {searchFilterAreaId, filePathDirectorySelected};


        return fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    //body:JSON.stringify(imageToDelete),
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })

    }

    function thumbnailImageLoadHandler(e:React.SyntheticEvent<HTMLImageElement>, imgCategory:string):void {
        //clear previous dimensions
        e.currentTarget.style.width = '';
        e.currentTarget.style.height = '';

        //console.log('img20:', e.currentTarget.clientWidth, e.currentTarget.clientHeight);
        const liElement = e.currentTarget.closest('.product-image-list-item') as HTMLLIElement;

        const iw = e.currentTarget.clientWidth;
        const ih = e.currentTarget.clientHeight;

        if(imgCategory === 'mobile'){
            //console.log('passed', iw, ih);
            setMobileImageWidth(iw);
            setMobileImageHeight(ih);
        }

        if(iw === ih){
            e.currentTarget.style.width = '40px';
            e.currentTarget.style.height = '40px';
        }

        if(iw < ih){
            e.currentTarget.style.height = '40px';
            e.currentTarget.style.width = 'auto';
        }

        if(iw > ih){
            e.currentTarget.style.width = '40px';
            e.currentTarget.style.height = 'auto';
        }

        e.currentTarget.style.visibility = 'visible';

        liElement.style.overflow = 'none';
        liElement.style.height = 'unset';
        

    }

    function setupThumbnailImages(tis:ThumbnailImage[], listName:string):JSX.Element {
        //console.log('tis:', tis, listName);
        if(tis.length === 0){
            return  <div className="product-image-search-list-container">No images selected</div>
        } else {
            return  <div className="product-image-search-list-container">
                        <ul className="product-image-search-list">
                            {tis.map((ti, k)=>{
                                return <li key={k} className="product-image-list-item">
                                            <img src={ti.img.src} onLoad={(e:React.SyntheticEvent<HTMLImageElement>)=>thumbnailImageLoadHandler(e, listName)} 
                                                className="product-thumbnail-image" /*height={40} width={40}*//>
                                            <span className="image-list-item-url-container">
                                                <span>{ti.name}</span>
                                                <span className="image-list-item-close-btn" onClick={(e:React.MouseEvent)=>removeImageFromList2(listName/*ti.img, listName, ti.name*/)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </span>
                                            </span>
                                        </li>
                            })}
                        </ul>
                    </div>
        }

    }

    function handleProductName(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        //console.log('value.length:', value.length);

        if(value.length <= 150){
            setProductName(value);
            setShowProductNameLimitError(false);

        } else {
            setShowProductNameLimitError(true);
        }

        
    }

    function handleProductDescription(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        //console.log('value.length:', value.length);

        if(value.length <= 2000){
            setProductDescription(value);
            setShowProductDescriptionLimitError(false);

        } else {
            setShowProductDescriptionLimitError(true);
        }

        
    }

    function setupFormAction(showEditProduct:boolean):string {
        return (showEditProduct) 
                ? 'updateProduct'
                : 'addProduct'
    }

    function productPriceHandler(e:React.ChangeEvent):void {
        const price = (e.target as HTMLInputElement).value;
        //console.log('price:', price);
        const m = price.match(/\./i);
        if(m !== null && price.slice(m.index! + 1).length > 2){
            return;
        }
        //save this number to the database
        //use this for add product form
        const p = Number(price).toFixed(2);

        //console.log('tp:', Number(p), price);

        if(Number(p) <= 9999.99){
            setProductPrice(price);    
            setShowPriceLimitError(false);

        } else {
            setShowPriceLimitError(true);
        }

        
    }

    function UOMProductPriceHandler(e:React.ChangeEvent):void {
        const price = (e.target as HTMLInputElement).value;
        //console.log('price:', price);
        const m = price.match(/\./i);
        if(m !== null && price.slice(m.index! + 1).length > 2){
            return;
        }
        //save this number to the database
        //use this for add product form
        const p = Number(price).toFixed(2);
        
        if(Number(p) <= 9999.99){
            setUOMProductPrice(price);
            setShowUOMPriceLimitError(false);

        } else {
            setShowUOMPriceLimitError(true);
        }
        
    }

    function priceKeydownHandler(e:React.KeyboardEvent):void {
        //console.log('key:', e.key);
        if(e.key === 'e' || e.key === 'E'){
            e.preventDefault();
        }
    }

    function productQtyLimitHandler(e:React.ChangeEvent):void {
        const qty = (e.target as HTMLInputElement).value;
        //console.log('qty:', qty, Number(qty));

        if(Number(qty) <= 99){
            setProductQtyLimit(qty);
            setShowProductQtyLimitError(false);

        } else {
            setShowProductQtyLimitError(true);
        }

        
    }

    function productQtyLimitKeydownHandler(e:React.KeyboardEvent):void {
        //console.log('key:', e.key);
        if(e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === '-' || e.key === '+'){
            e.preventDefault();
        }
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowNoSearchResultsFound(false);
    }


    if(isLoading){
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>
    } else
    
    //if(showAddProduct){
    if(!showEditProduct || (showEditProduct && editProductLoaded)){
        //console.log('passed');
        return <div>{
                     (showEditProduct)
                        ? <h1 className="add-product-heading">Edit Product</h1>
                        : <h1 className="add-product-heading">Add Product</h1>
                    }
                    <form ref={formRef} className="add-dpt-category-form" onSubmit={(e:React.FormEvent)=>handleFormSubmit(e, setupFormAction(showEditProduct))}>
                    <fieldset className="add-product-fieldset-group">
                        <legend>Product Info</legend>
                        <div className="add-product-input-form-group">
                            <label ref={dnLabelRef} htmlFor="dpt-name">Department Name</label>
                            <StoreDepartmentSelectControl ref={dSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={departmentNameSelected}
                                    optionNames={departmentNames}
                                    showErrorMsg={showDeptNameErrorMsg}
                                    errorMsg={deptNameErrorMsg}
                                    selectId={"dpt-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={dcnLabelRef} htmlFor="dpt-category-name">Department Category Name</label>
                            <DepartmentCategorySelectControl ref={dcSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={departmentCategoryNameSelected}
                                    optionNames={departmentCategoryNames}
                                    showErrorMsg={showDeptCategoryNameErrorMsg}
                                    errorMsg={deptCategoryNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptCategoryNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={pcnLabelRef} htmlFor="product-category-name">Product Category Name</label>
                            <ProductCategorySelectControl ref={pcSelectElementRef}
                                    defaultValue={'none'}
                                    optionNameSelected={productCategoryNameSelected}
                                    optionNames={productCategoryNames}
                                    showErrorMsg={showProductCategoryNameErrorMsg}
                                    errorMsg={productCategoryNameErrorMsg}
                                    selectId={"product-category-name"}
                                    handleOptionNameSelection={handleProductCategoryNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label ref={pscnLabelRef} htmlFor="product-sub-category-name">Product Sub Category Name</label>
                            <ProductSubCategorySelectControl ref={pscSelectElementRef}
                                    defaultValue={'none'}
                                    optionNameSelected={productSubCategoryNameSelected}
                                    optionNames={productSubCategoryNames}
                                    showErrorMsg={showProductSubCategoryNameErrorMsg}
                                    errorMsg={productSubCategoryNameErrorMsg}
                                    selectId={"product-sub-category-name"}
                                    handleOptionNameSelection={handleProductSubCategoryNameSelection}
                            />
                        </div>
                        <div className="add-product-input-form-group">
                            <label htmlFor="product-name">Product Name</label>
                            <input ref={pnLabelRef} name="product-name" type="text" id="product-name"
                                className="form-input-control" value={productName} onChange={handleProductName}/>
                            {
                                showProductNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (150)</p>
                                :
                                null
                            }
                            
                        </div>
                        <div className="add-product-input-form-group">
                            <label htmlFor="product-description">Product Description</label>
                            <textarea ref={pdLabelRef} name="product-description" rows={2} cols={50} id="product-description"
                                className="form-input-control text-description" value={productDescription} onChange={handleProductDescription}></textarea>
                            {
                                showProductDescriptionLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (2000)</p>
                                :
                                null
                            }

                        </div>
                        <div className="add-product-input-form-group">
                            <label htmlFor="product-qty-limit">Product Qty Limit:</label>
                            <input ref={pqlLabelRef} type="number" name="product-qty-limit" className="product-qty-limit form-input-control" value={productQtyLimit} 
                                        id="product-qty-limit"
                                        min={minimumProductQty} step={stepIncrementProductQty}
                                        onChange={productQtyLimitHandler} onKeyDown={productQtyLimitKeydownHandler}/>
                            
                            {
                                showProductQtyLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum amount allowed (99)</p>
                                :
                                null
                            }

                        </div>
                        </fieldset>
                        <fieldset className="add-product-fieldset-group">
                            <legend>Pricing</legend>
                            <div className="add-product-pricing-form-group add-product-input-form-group">
                                <div>Price</div>
                                <div className="product-price-layout-wrapper">
                                    <div ref={priceLabelRef} className="product-price-search-dollar-amount-wrapper">
                                        <span className="total-price-dollar-sign">$</span>
                                        {/*<input className="total-price-amount-dollars" name="total-price-amount-dollars" type="number" value={dollarAmountSelected} onChange={handleDollarAmount}/>
                                        <span className="total-price-decimal-container"><span className="total-price-decimal"></span></span>
                                        <input className="total-price-amount-cents" name="total-price-amount-cents" type="number" value={centAmountSelected} onChange={handleCentAmount}/>*/}
                                        <input type="number" name="price-search-amount" className="product-price-search-amt" value={productPrice} 
                                                    id="product-price-amt"
                                                    min={minimumPrice} step={stepIncrementAmt}
                                                    onChange={productPriceHandler} onKeyDown={priceKeydownHandler}/>
                                    </div>
                                    {
                                        showPriceLimitError
                                        ?
                                        <p className="exceed-character-limit-error">The maximum amount allowed (9999.99)</p>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="add-product-pricing-form-group add-product-input-form-group">
                                <div>Unit of Measure Price</div>
                                <div className="product-price-layout-wrapper">
                                    <div ref={uomPriceLabelRef} className="product-price-search-dollar-amount-wrapper">
                                        <span className="total-price-dollar-sign">$</span>
                                        {/*<input className="total-price-amount-dollars" name="total-price-amount-dollars" type="number" value={dollarAmountSelected} onChange={handleDollarAmount}/>
                                        <span className="total-price-decimal-container"><span className="total-price-decimal"></span></span>
                                        <input className="total-price-amount-cents" name="total-price-amount-cents" type="number" value={centAmountSelected} onChange={handleCentAmount}/>*/}
                                        <input type="number" name="price-search-amount" className="product-price-search-amt" value={uomProductPrice} 
                                                    id="product-unit-price-amt"
                                                    min={minimumUOMPrice} step={stepIncrementUOMAmt}
                                                    onChange={UOMProductPriceHandler} onKeyDown={priceKeydownHandler}/>
                                    </div>
                                    {
                                        showUOMPriceLimitError
                                        ?
                                        <p className="exceed-character-limit-error">The maximum amount allowed (9999.99)</p>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="add-product-input-form-group">
                                <label ref={uomLabelRef} htmlFor="unit-of-measure">Unit of Measure</label>
                                <StoreStructureOptionSelect
                                        ref={uomLabelSelectRef}
                                        defaultValue={'none'}
                                        optionNameSelected={unitOfMeasureSelected}
                                        optionNames={unitOfMeasureNames}
                                        showErrorMsg={showUnitOfMeasureErrorMsg}
                                        errorMsg={unitOfMeasureErrorMsg}
                                        selectId={"unit-of-measure"}
                                        handleOptionNameSelection={handleUnitOfMeasureSelection}
                                />
                            </div>
                            <div className="add-product-input-form-group">
                                <label ref={weightLabelRef} htmlFor="weight-by">Weight</label>
                                <StoreStructureOptionSelect
                                        ref={weightLabelSelectRef}
                                        defaultValue={'none'}
                                        optionNameSelected={weightBySelected}
                                        optionNames={weightByNames}
                                        showErrorMsg={showWeightByErrorMsg}
                                        errorMsg={weightByErrorMsg}
                                        selectId={"weight-by"}
                                        handleOptionNameSelection={handleWeightBySelection}
                                />
                            </div>
                            { 
                              (weightBySelected === 'pound') 
                              ?  <div className="add-product-input-form-group">
                                    <label ref={wrLabelRef} htmlFor="weight-range">Weight Range</label>
                                    <StoreStructureOptionSelect
                                            ref={wrLabelSelectRef}
                                            defaultValue={'none'}
                                            optionNameSelected={weightRangeSelected}
                                            optionNames={weightRangeNames}
                                            showErrorMsg={showWeightRangeErrorMsg}
                                            errorMsg={weightRangeErrorMsg}
                                            selectId={"weight-range"}
                                            handleOptionNameSelection={handleWeightRangeSelection}
                                    />
                                </div>
                               : null
                            }

                        </fieldset>
                        <fieldset className="add-product-fieldset-group">
                            <legend>Images</legend>
                            <div className="add-product-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={pisLabelRef} htmlFor="product-image-search" onClick={imageClickHandler}><span className="product-image-search-label-link">Select Images <span className="no-color-label-link">(product search results)</span></span></label>
                                </div>
                                {
                                    showSearchFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageSearchRef} id="product-image-search" className="product-image-search" name="product-image-search" type="file" onChange={(e:React.ChangeEvent)=>handleFileUpload(e, 'search')}/>
                                {setupThumbnailImages(searchThumbnailImages, 'search')}

                            </div>
                            {/*<div className="add-product-input-form-group">
                                <label ref={pisoloLabelRef} htmlFor="product-image-solo" onClick={imageClickHandler}><span className="product-image-solo-label-link">Select Images <span className="no-color-label-link">(product page)</span></span></label>
                                <input ref={imageSoloRef} id="product-image-solo" className="product-image-solo" name="product-image-solo" type="file" multiple onChange={handleFileUpload}/>
                                {setupThumbnailImages(soloThumbnailImages, 'solo')}
                            </div>*/}
                            <div className="add-product-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={pimLabelRef} htmlFor="product-image-mobile" onClick={imageClickHandler}><span className="product-image-mobile-label-link">Select Images <span className="no-color-label-link">(product page zoom)</span></span></label>
                                </div>
                                {
                                    showMobileFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageMobileRef} id="product-image-mobile" className="product-image-mobile" name="product-image-mobile" type="file" onChange={(e:React.ChangeEvent)=>handleFileUpload(e, 'mobile')}/>
                                {setupThumbnailImages(mobileThumbnailImages, 'mobile')}

                            </div>
                            <div className="add-product-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={pimcLabelRef} htmlFor="product-image-mini-cart" onClick={imageClickHandler}><span className="product-image-mini-cart-label-link">Select Images <span className="no-color-label-link">(product shopping cart)</span></span></label>
                                </div>
                                {
                                    showMiniCartFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageMiniCartRef} id="product-image-mini-cart" className="product-image-mini-cart" name="product-image-mini-cart" type="file" onChange={(e:React.ChangeEvent)=>handleFileUpload(e, 'miniCart')}/>
                                {setupThumbnailImages(miniCartThumbnailImages, 'miniCart')}

                            </div>
                        </fieldset>
                        <DBErrorMessage ref={dbErrorMsgRef} dbError={dbError}/>
                        <FormErrorMessage ref={sfgErrorMsgRef} formError={formError} />
                        {/*<ProductPromotionControl ref={ppCheckboxControlsRef} options={productPromotions} title={productPromotionsTitle} handleOptionNameSelection={handleProductPromotions} />*/}
                        {<ProductPromotionControl2 options={productPromotions} title={productPromotionsTitle} 
                           selectedOptions={productPromotionsSelected} handleOptionNameSelection={handleProductPromotions} />}
                        {/*<ProductVerificationControl ref={pvCheckboxControlsRef} options={productVerifications} title={productVerificationsTitle} handleOptionNameSelection={handleProductVerifications}/>*/}
                        {<ProductVerificationControl2 options={productVerifications} title={productVerificationsTitle}
                            selectedOptions={productVerificationsSelected} handleOptionNameSelection={handleProductVerifications}/>}
                        {(searchFilterGroups.length > 0)
                         ?<CheckboxBuilder options={searchFilterGroups} title={searchFilterGroupsTitle}
                                getFormControlMeasurement={getFormControlMeasurement} handleOptionNameSelection={(e:React.ChangeEvent)=>handleSearchFilterGroups(e, 'available')} />
                        : <fieldset className="available-sfg-fieldset">
                            <legend>{searchFilterGroupsTitle}</legend>
                            <div>No search filter groups are selected.</div>
                          </fieldset>
                        }
                        {(existingSearchFilterGroups.length > 0)
                         ?<ExistingSGCheckboxControl options={existingSearchFilterGroups} title={existingSearchFilterGroupsTitle}
                                getFormControlMeasurement={getFormControlMeasurement} handleOptionNameSelection={(e:React.ChangeEvent)=>handleSearchFilterGroups(e, 'existing')} />
                        : <fieldset className="existing-sfg-fieldset">
                            <legend>{existingSearchFilterGroupsTitle}</legend>
                            <div>No search filter groups are selected.</div>
                          </fieldset>
                        }
                        {
                            (showEditProduct)
                            ?   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-save-btn" type="submit" value="Save"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('editProduct')} value="Cancel"/>

                                    {
                                        (showProcessingRequestMsg)
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            :   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-add-btn" type="submit" value="Add"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('addProduct', true)} value="Cancel"/>

                                    {
                                        (showProcessingRequestMsg)
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                   
                        }
                        {
                            showNoSearchResultsFound 
                            ? (<div className="add-product-sub-category-form-info-message-container">
                                <GenericInfoMessage infoMessage={genericInfoMessage} 
                                    showCloseBtn={true} showExtraMsgContainer={true}
                                    closeInfoMsgHandler={closeInfoMsgHandler}/>
                                </div>)
                            : null
                        }

                    </form>
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    <LoaderAnimation/>
                </div>
    }
}


export default AddProductStoreStructure;