import React from 'react';
//import {Router} from '@gatsbyjs/reach-router';
import { Routes, Route } from "react-router-dom";
import HomePage from './HomePage';
import StoreLocator from './StoreLocator';
import StoreDetails from './StoreDetails';
import PageNotFound from './PageNotFound';
import './ExploreStore.css';

interface Props {
    //path?:string;
    //children:React.ReactElement[];
}

/*previous layout in index.tsx for routing
                <ExploreStore path="/">
                    <HomePage path="/"/>
                    <StoreLocator path="store-locator"/>
                    <StoreDetails path="store-details/:storeName" />
                </ExploreStore>
                */

const ExploreStore:React.FunctionComponent<Props> = (props: Props) => {
    //const { children } = props;

    return (
        <div>
            <Routes>
                {/*<Route path="/" element={<HomePage/>}/>*/}
                <Route path="store-locator" element={<StoreLocator/>}/>
                <Route path="store-details/:storeName/:storeLocationId" element={<StoreDetails/>}/>
                <Route path="*" element={<PageNotFound showNavSearch={true}/>}/>
            </Routes>
        </div>
    );
}

export default ExploreStore;