import { LoaderFunctionArgs } from "react-router-dom";
import Sbat from './shopByAreaType';
import ShopProductParams from './shopProductParams';

async function shopLoader({request, params}:LoaderFunctionArgs):Promise<[Sbat, string]> {

    try {
        if(params['*']!.length === 0){
            //console.log('r2:', params['storeDepartmentId']);
            let p = params['storeDepartmentId'] as string;
            if(p[0] === '<'){
                p = p.slice(1);
            }
            
            if(p[p.length - 1] === '>'){
                p = p.slice(0,-1);
            }

            const up = window.parseInt(p);
            if(up && up > 0){
                //console.log('r2:', up);
                const resp1 = await fetch(`https://server.kando-proto-3.com/get-shop-department-params/${up}`, {
                                        method:'GET',
                                        mode:'cors',
                                        headers:{
                                            'content-type':'application/json'
                                        }
                                    });

                const r1 =  await resp1.json();

                if(r1.type === 'noMatch'){
                    return [new Sbat({type:'pageNotFound'}), 'page-not-found'];

                } else {
                    //return [new Sbat({...r1}), r1.storeDepartmentName];
                    return [new Sbat({...r1}), request.url];
                }


            } else {
                //need to return bad data and show page not found component
                //need to implement this after testing is successful
                return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
            }

        } else {
            let ps = params['*']!.split('/');
            switch(ps.length){
                case 2:
                    //department category
                    const p2 = ps.map((p)=>{
                                        if(p[0] === '<'){
                                            p = p.slice(1);
                                        }
                                        
                                        if(p[p.length - 1] === '>'){
                                            p = p.slice(0,-1);
                                        }
                                
                                        const up = window.parseInt(p);
                                        if(up && up > 0){
                                            //console.log('r2:', up);
                                            return up;
                                        } else {
                                            //need to return bad data and show page not found component
                                            //need to implement this after testing is successful
                                            return 0;
                                        }
                                });

                    //console.log('r2:', p2);
                    if(p2[0] === 0 || p2[1] === 0){
                        return [new Sbat({type:'pageNotFound'}), 'page-not-found'];

                    } else {
                        const resp2 = await fetch(`https://server.kando-proto-3.com/get-shop-department-category-params/${p2[0]}/${p2[1]}`, {
                            method:'GET',
                            mode:'cors',
                            headers:{
                                'content-type':'application/json'
                            }
                        });

                        const r2 =  await resp2.json();

                        if(r2.type === 'noMatch'){
                            return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
            
                        } else {
                            //return [new Sbat({...r2}), r2.departmentCategoryName];
                            return [new Sbat({...r2}), request.url];
                        }
                        
                    }


                case 3:
                    //product category
                    const [pcn, dcId, pcId] = ps;
                    const p3 = [dcId, pcId].map((p)=>{
                                        if(p[0] === '<'){
                                            p = p.slice(1);
                                        }
                                        
                                        if(p[p.length - 1] === '>'){
                                            p = p.slice(0,-1);
                                        }
                                
                                        const up = window.parseInt(p);
                                        if(up && up > 0){
                                            //console.log('r2:', up);
                                            return up;
                                        } else {
                                            //need to return bad data and show page not found component
                                            //need to implement this after testing is successful
                                            return 0;
                                        }
                                });

                    //console.log('r2:', p3);
                    if(p3[0] === 0 || p3[1] === 0){
                        return [new Sbat({type:'pageNotFound'}), 'page-not-found'];

                    } else {
                        const resp3 = await fetch(`https://server.kando-proto-3.com/get-shop-product-category-params/${p3[0]}/${p3[1]}`, {
                            method:'GET',
                            mode:'cors',
                            headers:{
                                'content-type':'application/json'
                            }
                        });

                        const r3 =  await resp3.json();

                        if(r3.type === 'noMatch'){
                            return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
            
                        } else {
                            //return [new Sbat({...r3}), r3.productCategoryName];
                            return [new Sbat({...r3}), request.url];
                        }
                        

                    }


                default:
                    //need to return bad data and show page not found component
                    //need to implement this after testing is successful
                    return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
            }

        }

    } catch(err){
        //console.log('shop loader error:', err);
        return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
    }

};


export async function productLoader({request, params}:LoaderFunctionArgs):Promise<ShopProductParams> {
                const pId = params.productId as string;
                //console.log('r1:', pId);
                if(pId.length === 0){
                    return new ShopProductParams({type:'pageNotFound'});

                } else {           
                    const upId = window.parseInt(pId);
                    if(upId && upId > 0){
                        //console.log('r2:', upId);
                        const resp1 = await fetch(`https://server.kando-proto-3.com/get-shop-product-params/${upId}`, {
                                                method:'GET',
                                                mode:'cors',
                                                headers:{
                                                    'content-type':'application/json'
                                                }
                                            });
            
                        const r1 =  await resp1.json();
            
                        if(r1.type === 'noMatch'){
                            return new ShopProductParams({type:'pageNotFound'});
            
                        } else {
                            //console.log('r1:', r1);
                            return new ShopProductParams({...r1});
                        }

                    } else {
                        return new ShopProductParams({type:'pageNotFound'});
                    }
                }
                
        }

export default shopLoader;