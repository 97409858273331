import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import Product from './product';
//import {Link, navigate, useMatch} from '@gatsbyjs/reach-router';
import { Link, useNavigate, useMatch, useLocation } from 'react-router-dom';
import ShopProductDetail from './ShopProductDetail';
import ShopProductMiniCart from './ShopProductMiniCart';
import addDepartmentCategories, {addSearchFilters} from './helper-add-department-categories';
import HighlightDepartmentCategoryText from './HighlightDepartmentCategoryText';
import helperHighlightCategory from './helper-highlight-category';
import helperHighlightDepartment from './helper-highlight-department';
import {sortByAtoZ, sortByZtoA, sortByNewest, sortByRatings, 
        sortByPriceHightoLow, sortByPriceLowtoHigh} from './helper-sort-by-option';
import PriceSlider3 from './PriceSlider3';
import ShopLoadDetails from './redux/shopLoadDetails';
//import {AddShopLoadDetails, addShopLoadDetails, AddProductKeywordSearch, addProductKeywordSearch} from './redux/actions';
import {addShopLoadDetails, selectShopLoadDetails} from './redux/features/shopLoadDetails/shopLoadDetailsSlice';
import {addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import helperSetOptionValue, {helperSetOptionValueNow} from './helper-set-option-value';
import SearchFilter, {SearchTerm} from './searchFilter';
import RelatedProduct from './RelatedProduct';
import cloneDeep from 'lodash/cloneDeep';
import SearchFilterSidebar from './SearchFilterSidebar';
import {checkSearchFiltersSelected, updateSearchFilters2} from './helper-search-filter-setup';
import PriceRange from './priceRange';
import ProductKeywordSearch from './redux/productKeywordSearch';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import LoaderAnimation from './LoaderAnimation';
import ScrollToTopButton from './ScrollToTopButton';
import formatLinkString from './helper-format-link-string';
import './ShopDepartmentCategory.css';


interface Props {
    path?:string;
    storeDepartment:StoreDepartment;
    storeDepartmentCategory:DepartmentCategory;
    storeDepartmentName:string;
    departmentCategoryName:string;
    storeDepartmentId:string;
    departmentCategoryId:string;
    keywordSearchType:string;
    departmentCategoryProducts:Product[];
    sortByOptionValue:string;
    sbatProductMobileNavFilterHandler:()=>void;
    sbatProductMobileNavSortByOptionHandler:(sbov:string)=>void;
    loadNavLink:(e:React.MouseEvent, pathname:string, showLoader?:boolean)=>void;
}

interface ProductSelection {
    id:number;
}


const ShopByAreaTypeDepartmentCategory:React.FunctionComponent<Props> = (props:Props)=>{
    //console.log('match:', useMatch(`/shop${props.path}`));
    const location = useLocation();
    //const {addShopLoadDetails, addProductKeywordSearch} = props;
    //const dispatch = useAppDispatch();
    //const shopLoadDetailsRedux = useAppSelector(selectShopLoadDetails);
    //console.log('sldr:', shopLoadDetailsRedux);
    const {sbatProductMobileNavFilterHandler, sbatProductMobileNavSortByOptionHandler, loadNavLink} = props;

    const [storeDepartment, setStoreDepartment] = useState<StoreDepartment | null>(null);
    const [storeDepartmentName, setStoreDepartmentName] = useState(props.storeDepartmentName);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(props.departmentCategoryName);
    const [storeDepartmentCategory, setStoreDepartmentCategory] = useState<DepartmentCategory | null>(null);
    const [departmentCategoryProductTotal, setDepartmentCategoryProductTotal] = useState(0);
    const [isSpecialDepartmentCategory, setIsSpecialDepartmentCategory] = useState(false);
    const [departmentCategoryProducts, setDepartmentCategoryProducts] = useState<Product[] | []>([]);
    const [sortByOptionValue, setSortByOptionValue] = useState(props.sortByOptionValue);
    const [showDepartmentCategoryProducts, setShowDepartmentCategoryProducts] = useState(false);

    const [startSliderAmount, setStartSliderAmount] = useState(0);
    const [endSliderAmount, setEndSliderAmount] = useState(0);
    const [showProductCategoryProducts, setShowProductCategoryProducts] = useState(false);
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState('0%');
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState('100%');
    const [previousSlider1PostionLeftAmount, setPreviousSlider1PostionLeftAmount] = useState(0);
    const [previousSlider2PostionLeftAmount, setPreviousSlider2PostionLeftAmount] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(0);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(100);
    const [slider1ZIndex, setSlider1ZIndex] = useState(0);
    const [slider2ZIndex, setSlider2ZIndex] = useState(0);
    const [keywordSearch, setKeywordSearch] = useState(props.departmentCategoryName);
    const [keywordSearchType, setKeywordSearchType] = useState('results');
    const [productCategoryName, setProductCategoryName] = useState('none');

    //const [shopLoadDetails, setShopLoadDetails] = useState<ShopLoadDetails>(shopLoadDetailsRedux!);

    const [controller, setController] = useState<AbortController>(new AbortController());
    const [showPriceSlider, setShowPriceSlider] = useState(false);

    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [initialSearchFilters, setInitialSearchFilters] = useState<SearchFilter[]>([]);
    const [searchTermNamesSelected, setSearchTermNamesSelected] = useState<string[]>([]);
    const [searchFilterNamesSelected, setSearchFilterNamesSelected] = useState<string[]>([]);
    const [searchTermIsSelected, setSearchTermIsSelected] = useState(false);
    const [showSpecialProductCategoryShortcut, setShowSpecialProductCategoryShortcut] = useState(true);

    const [storeDepartmentId, setStoreDepartmentId] = useState(props.storeDepartmentId);
    const [departmentCategoryId, setDepartmentCategoryId] = useState(props.departmentCategoryId);

    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    const [hasProductCategories, setHasProductCategories] = useState<boolean>(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(60);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Product[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No store locations have been added.');

    const [showMiniCart, setShowMiniCart] = useState(false);
    const showMiniCartRef = useRef<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);


    //console.log('shopDepartment path:', props.storeDepartmentName);
    //console.log('dcn test:', departmentCategoryName);
    //console.log('slider1ZIndex:', slider1ZIndex);
    //console.log('slider2ZIndex:', slider2ZIndex);
    //console.log('sortByOptionValue:', sortByOptionValue);

    //console.log('products:', departmentCategoryProducts);
   
    //console.log('sdId:', storeDepartmentId);
    //console.log('dcId:', departmentCategoryId);
    //console.log('sdn:', storeDepartmentName);
    //console.log('sdn:', props.storeDepartmentName);
    //console.log('r714:', departmentCategoryName);
    //console.log('sld7:', props.storeDepartmentName, props.storeDepartmentId, props.departmentCategoryName, props.departmentCategoryId);

    //console.log('sd7:', storeDepartment, 'sdc:', storeDepartmentCategory);
    //console.log('sbatd:', 'sd:', props.storeDepartment, 'sdn:', props.storeDepartmentName, 'sdid:', props.storeDepartmentId, 'dc:', props.storeDepartmentCategory, 'dcps:', props.departmentCategoryProducts, 'kst:', props.keywordSearchType);
    
    //const [productSelection, setProductSelection] = useState<ProductSelection>(getPreviousProductSelection(sessionStorage.getItem('sbat-product-selection')));
    //const departmentCategoryProductContainerRefs = useRef<HTMLDivElement[]>([]);


    /*previous version
    useEffect(()=>{
        console.log('useEffect() called:', departmentCategoryId, props.departmentCategoryId);

        setShowDepartmentCategoryProducts(false);
        console.log('sdid1:', storeDepartmentId);
        console.log('dcid:', departmentCategoryId);
        setSortByOptionValue('Best Match');

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        
    },[props.departmentCategoryId!]);
    */

    useEffect(()=>{
        setSortByOptionValue(props.sortByOptionValue);
        //setDepartmentCategoryProducts(props.departmentCategoryProducts);
        setDepartmentCategoryProductTotal(props.departmentCategoryProducts.length);
        setStoreDepartment(props.storeDepartment);
        setStoreDepartmentCategory(props.storeDepartmentCategory);
        setKeywordSearchType(props.keywordSearchType);
        /*previous version
        helperSetOptionValue(
            props.sortByOptionValue, 
            props.departmentCategoryProducts, 
            false,
            setDepartmentCategoryProducts, 
            setShowDepartmentCategoryProducts
            );
            */
        const hsovps = helperSetOptionValueNow(props.sortByOptionValue, props.departmentCategoryProducts);
        setDepartmentCategoryProducts(hsovps);
        setShowDepartmentCategoryProducts(true);
        getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, hsovps);

        //setDepartmentProducts([]);
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        if(window.innerWidth >= 768 && !showMiniCartRef.current){
            showMiniCartRef.current = true;
            setShowMiniCart(true);
        }

        if(window.innerWidth < 768 && showMiniCartRef.current){
            showMiniCartRef.current = false;
            setShowMiniCart(false);
        }
        
        
    },[props.departmentCategoryProducts]);


    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();

        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }


    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }

    /*
    function getDepartmentCategoryProductContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && departmentCategoryProductContainerRefs.current.length > 0){
            departmentCategoryProductContainerRefs.current.length = 0;
        }
    
        if(el !== null && departmentCategoryProductContainerRefs.current.length === 0){
            departmentCategoryProductContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = departmentCategoryProductContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                departmentCategoryProductContainerRefs.current.push(el);
            }

        }

        console.log('dcpcrs:', departmentCategoryProductContainerRefs.current);
        
        const foundIdElement = departmentCategoryProductContainerRefs.current.filter((el)=>{
                                    return el.id === productSelection.id.toString();
                                });
        
        if(foundIdElement.length === 1){
            foundIdElement[0].scrollIntoView({behavior:'smooth'});
        }
        
    }

    function getPreviousProductSelection(previousProductSelection:string|null):ProductSelection {
        if(previousProductSelection === null){
            return {id:0};

        } else {
            const pps = JSON.parse(previousProductSelection);
            console.log('pps:', pps);
            return {id:pps};
        }
    }

    function setProductSelectionId(productSelectionId:number):void {
        sessionStorage.setItem('sbat-product-selection', `${productSelectionId}`);
    }
    */


    function clearAllExistingImages():void {
        //console.log('irs before:', imageRefs.current);
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('irs after:', imageRefs.current);
    }


    /*
    useEffect(()=>{
        window.addEventListener('resize', shopByAreaTypeResizeHandler)
        return cleanupShopByAreaTypeResizeHandler;
    },[])

    function cleanupShopByAreaTypeResizeHandler():void {
        window.removeEventListener('resize', shopByAreaTypeResizeHandler);
    }

    function shopByAreaTypeResizeHandler(e:Event):void {
        if(window.innerWidth >= 768 && !showMiniCartRef.current){
            showMiniCartRef.current = true;
            setShowMiniCart(true);
        }

        if(window.innerWidth < 768 && showMiniCartRef.current){
            showMiniCartRef.current = false;
            setShowMiniCart(false);
        }
    }
    */


   function getSliderAmount(products:Product[]):void {
       //console.log('p:', products);
        const sortedProducts = products.sort(sortByPriceHightoLow);
        const highestPricedProduct:Product = sortedProducts[0] as Product;
        const lowestPricedProduct:Product = sortedProducts[sortedProducts.length - 1] as Product;
        //console.log('high price:', highestPricedProduct.eachPrice);
        //console.log('low price:', lowestPricedProduct.eachPrice);
        if((highestPricedProduct.eachPrice - lowestPricedProduct.eachPrice) > 1){
            setShowPriceSlider(true);
            //console.log('yes');
        } else {
            setShowPriceSlider(false);
            //console.log('no');
        }
        setStartSliderAmount(lowestPricedProduct.eachPrice);
        setEndSliderAmount(highestPricedProduct.eachPrice);
        setPreviousSlider1PostionLeftAmount(lowestPricedProduct.eachPrice);
        setPreviousSlider2PostionLeftAmount(highestPricedProduct.eachPrice);
        if(lowestPricedProduct.eachPrice === highestPricedProduct.eachPrice){
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('0%');
        } else {
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('100%');
        }

    }


    //change these two function to fit for department products and install above and below.
    function addDepartmentCategoryProducts(departmentCategoryProducts:Product[]):Product[] {
        setDepartmentCategoryProductTotal(departmentCategoryProducts.length);
        return addProducts(departmentCategoryProducts);
    }

    function addProducts(products:Product[]):Product[]{
        //setDepartmentCategoryProductTotal(products.length);
        return products.map((product)=>{
            return new Product({...product});
        });
    }

    function getStoreDepartmentCategory(departmentCategoryName:string, departmentCategories:DepartmentCategory[]):/*void*/ DepartmentCategory {
        //console.log('dcn:', departmentCategoryName);
        const foundDepartmentCategory = departmentCategories.find((departmentCategory)=>{
            return departmentCategory.departmentCategoryName === departmentCategoryName;
        }) as DepartmentCategory;

        //console.log('dc:', foundDepartmentCategory);
        //console.log('dc:', foundDepartmentCategory.productCategories.length);

        setStoreDepartmentCategory(foundDepartmentCategory);

        //load initial unsaved search filters with this function
        getSearchFilters(foundDepartmentCategory);

        return foundDepartmentCategory;
    
    }

    function getSearchFilters(departmentCategory:DepartmentCategory):void {
        //console.log('dc:', departmentCategory);
        let searchFilters:SearchFilter[] = [];
        departmentCategory.searchFilters.forEach((searchFilter)=>{
            //console.log('sfn:', searchFilter.filterName);
            if(searchFilter.showFilter){
                searchFilters.push(searchFilter);
            }
        });
        //console.log('sfs:', searchFilters);
        const searchFiltersBeforeUpdate = cloneDeep(searchFilters);

        let departmentCategoryProducts:Product[] = [];
        departmentCategory.productCategories.forEach((productCategory)=>{
            productCategory.products.forEach((product)=>{
                departmentCategoryProducts.push(product);
                //console.log('product:', product);
                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                    //console.log('sft:', searchFilterTerm);
                    searchFilters.forEach((searchFilter, index)=>{
                        //console.log('fn:', searchFilter.filterName);
                        //console.log('sftn:', searchFilterTerm.searchFilterTermName);
                        if(searchFilter.filterName === searchFilterTerm.searchFilterTermName){
                            //console.log('pass:', searchFilter.filterName);
                            searchFilter.searchTerms.forEach((searchTerm, index)=>{
                                //console.log('fn:', searchFilter.filterName);
                                if(searchTerm.searchTermName === searchFilterTerm.searchFilterTermValue){
                                    searchTerm.searchTermQty += 1;
                                }
                                
                            });
                            
                        }

                    });
                    //console.log('sfs:', searchFilters);
                    
                })
                
            });
        });

        //console.log('sfbu:', searchFiltersBeforeUpdate);

        
        if(checkSearchFiltersSelected(searchFiltersBeforeUpdate)){
            setInitialSearchFilters([...searchFilters]);
            const [updatedSearchFilters, productsSelected] = updateSearchFilters2(departmentCategoryProducts, searchFiltersBeforeUpdate, searchFilters);
            //console.log('usfs:', updatedSearchFilters);
    
            if(productsSelected.length === 0){
                //console.log('test');
                setKeywordSearchType('noResults');
                setSearchTermIsSelected(true);
            } else {
                setKeywordSearchType('results');
            }
            
            setSearchFilters([...updatedSearchFilters]);
            setDepartmentCategoryProducts(productsSelected);
            //console.log('p.length:', productsSelected.length);
            setDepartmentCategoryProductTotal(productsSelected.length);
            setShowSpecialProductCategoryShortcut(false);

            //return true;
            
        } else {

            const fsfs = removeZeroQtySearchTerms(searchFilters);

            const ufsfs = removeZeroQtySearchFilters(fsfs);

            //console.log('sfs:', ufsfs);
            setInitialSearchFilters([...ufsfs]);
            setSearchFilters([...ufsfs]);
            setShowSpecialProductCategoryShortcut(true);
    
        }
        
        
    }

    function removeZeroQtySearchTerms(sfs:SearchFilter[]):SearchFilter[] {
        sfs.forEach((sf)=>{
            const sts = sf.searchTerms.filter((st)=>{
                            if(st.searchTermQty === 0){
                                //console.log('stn:', st.searchTermName, 'stQty:', st.searchTermQty);
                            }
                            return st.searchTermQty !== 0;
                        });

                sf.searchTerms = sts;
            });

        //console.log('usfs 5-3:', sfs);
        return sfs;
    }

    function removeZeroQtySearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        const fsfs = sfs.filter((sf)=>{
                        return sf.searchTerms.length > 0;
                    });
        //console.log('fsfs:', fsfs);
        return fsfs;
    }


    function sortByOptionSelected(
                    e:React.ChangeEvent, 
                    departmentCategoryProducts:Product[],
                    selectedStoreDepartmentName:string,
                    selectedDepartmentCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedDepartmentCategoryId:number
                    ):void {
        //console.log('sortByOptionSelection:', (e.target as HTMLSelectElement).value);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        
        setShowDepartmentCategoryProducts(false);
        setSortByOptionValue((e.target as HTMLSelectElement).value);
        const selectedOption:string =  (e.target as HTMLSelectElement).value;
        helperSetOptionValue(
                    selectedOption, 
                    departmentCategoryProducts, 
                    true,
                    //selectedStoreDepartmentName,
                    //selectedDepartmentCategoryName,
                    setDepartmentCategoryProducts, 
                    setShowDepartmentCategoryProducts,
                    //addShopLoadDetails!
                    );

        sbatProductMobileNavSortByOptionHandler(selectedOption);

        getAnyItemList(listChangerBtnPosition, departmentCategoryProducts, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, departmentCategoryProducts);
               
        
    }


    function setupSpecialProductCategorySearchShortcut(storeDepartmentCategory:DepartmentCategory):JSX.Element | null {
        //console.log('sdc:', storeDepartmentCategory.productCategories.length);
        if(storeDepartmentCategory.showProductCategoryShortcut){
            return <ul className="shop-special-store-department-product-category-search-shortcut">
                        {
                            storeDepartmentCategory.productCategories.map((productCategory, index)=>{
                                return <li key={index}>
                                            <Link to={`/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${storeDepartmentCategory.departmentId}/${productCategory.productCategoryId}`}
                                                className="shop-special-store-department-product-category-search-shortcut-item-link"
                                                onClick={(e:React.MouseEvent)=>handleProductCategorySearch(e, productCategory, `/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${storeDepartmentCategory.departmentId}/${productCategory.productCategoryId}`)}>
                                                <div className="shop-special-store-department-product-category-search-shortcut-image-container">
                                                    <img className="shop-special-store-department-product-category-search-shortcut-image"
                                                        alt={productCategory.productCategoryName}
                                                        src={`https://server.kando-proto-3.com/${productCategory.productCategoryImage}`}/>
                                                </div>
                                                <div className="shop-special-store-department-product-category-search-shortcut-title">
                                                    {productCategory.productCategoryName}
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>

        } else {
            return null;
        }

    }

    function handleProductCategorySearch(e:React.MouseEvent, productCategory:ProductCategory, pathname:string /*keywordSearch:string, productCategoryName:string*/):void {
        //console.log('productCategory result:', productCategory);
        e.preventDefault();
        if(decodeURIComponent(location.pathname) === decodeURIComponent(pathname)){
            return;

        } else {
            loadNavLink(e, pathname, true);
            return;
        }

        //everthing below in this function is from the previous version

        setKeywordSearchType('results');
        setProductCategoryName(productCategory.productCategoryName);
        setSortByOptionValue(props.sortByOptionValue);
        setShowDepartmentCategoryProducts(false);
        //window.scrollTo(0,0);
            
        if(productCategory.showProductCategoryNameSearch){
            //console.log('spcns');
            e.preventDefault();
            
            //finish this area customizing search filters that are selected    
            Promise.all([getShopDepartmentCategorySearchFiltersSelectedPromise(departmentCategoryName!, productCategory.productCategoryName),
                        getShopProductCategoryNameSearchPromise(productCategory.productCategoryName)
            ])
            .then(([departmentCategory, departmentCategoryProducts])=>{
                //console.log('isfs:', initialSearchFilters);
                //console.log('departmentCategory:', departmentCategory);
                getSearchFilters(departmentCategory);

                //console.log('dcp:', departmentCategoryProducts);
                setDepartmentCategoryProductTotal(departmentCategoryProducts.length);
                setDepartmentCategoryProducts(departmentCategoryProducts);

                //get start and end price amounts from each product
                getSliderAmount(departmentCategoryProducts);
                setSliderPosition(0);
                setPreviousSlider1TotalDistance(0);
                setPreviousSlider2TotalDistance(100);

                setShowDepartmentCategoryProducts(true);
            })
            .catch((err)=>console.error('Promise.all() error:', err));
            

        } else if(productCategory.showProductCategoryKeywordSearch) {
            //console.log('spcks');
            e.preventDefault();
            fetch(`http://localhost:9500/shop-product-category-keyword-search/${productCategory.productCategoryName}`, {
                headers:{
                    'Accept':'application/json'
                }
            })
            .then((response)=>response.json())
            .then((departmentCategoryProducts)=>{
                //console.log('dcp:', departmentCategoryProducts);
                setDepartmentCategoryProductTotal(departmentCategoryProducts.length);
                setDepartmentCategoryProducts(departmentCategoryProducts);

            })
            .catch(err=>console.log('error:', err));
        
        } else {
            //console.log('final else');
            //console.log('test passed');            
            return;
        }

    }

    function getShopDepartmentCategorySearchFiltersSelectedPromise(
                                                selectedDepartmentCategoryName:string,
                                                selectedProductCategoryName:string
                                                ):Promise<DepartmentCategory>{
        return new Promise((resolve, reject)=>{
                    fetch(`https://server.kando-proto-3.com/shop-department-category-search-filters-selected/${selectedDepartmentCategoryName}/${selectedProductCategoryName}`, {
                        method:'GET',
                        headers:{
                            'Accept':'application/json'
                        }
                    })
                    .then((resp)=>resp.json())
                    .then((departmentCategory)=>{
                        //console.log('dc:', departmentCategory);
                        resolve(departmentCategory);
                    })
                    .catch(err=>{
                        console.error('getShopDepartmentCategorySearchFiltersSelectedPromise error:', err);
                        reject(err);
                    });
                });
    }

    function getShopProductCategoryNameSearchPromise(selectedProductCategoryName:string):Promise<Product[]>{
        return new Promise((resolve, reject)=>{
                    fetch(`https://server.kando-proto-3.com/shop-product-category-name-search/${selectedProductCategoryName}`, {
                        method:'GET',
                        headers:{
                            'Accept':'application/json'
                        }
                    })
                    .then((resp)=>resp.json())
                    .then((departmentCategoryProducts)=>resolve(departmentCategoryProducts))
                    .catch((err)=>{
                        console.error('getShopProductCategoryNameSearchPromise error:', err);
                        reject(err);
                    });
                });
    }

    function getDepartmentCategoryProductTotal(storeDepartment:StoreDepartment, selectedDepartmentCategoryName:string):number{
        const foundDepartmentCategory = storeDepartment.departmentCategories.find((departmentCategory)=>{
            return departmentCategory.departmentCategoryName === selectedDepartmentCategoryName;
        });

        if(typeof foundDepartmentCategory !== 'undefined'){
            //console.log('r:', true);
            let departmentCategoryProducts:Product[] = [];
            foundDepartmentCategory.productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    departmentCategoryProducts.push(product);
                });
            });
            return Number(departmentCategoryProducts.length);
        } else {
            return 0;
        }

    }

    function setupDepartmentCategoryProducts(itemsList:object[], optionalCategory?:object):JSX.Element{
            //console.log('dcp:', itemsList);
            //console.log('dcpt:', departmentCategoryProductTotal);
            //console.log('kst:', keywordSearchType);
            //console.log('stis:', searchTermIsSelected);
            //console.log('storeDepartmentCategory.departmentCategoryName:', (optionalCategory as DepartmentCategory).departmentCategoryName);

            
        if(((optionalCategory as DepartmentCategory) !== null && itemsList.length === 0)
            /*|| (storeDepartmentCategory.departmentCategoryName !== departmentCategoryProducts[0].departmentCategoryName)*/){
            //console.log('sdc:', optionalCategory!);
            
            let itemsList:Product[] = [];
            (optionalCategory as DepartmentCategory).productCategories.forEach((productCategory)=>{
                productCategory.products.forEach((product)=>{
                    itemsList.push(product);
                });
            });
            //console.log('setupDepartmentCategoryProducts:', itemsList);
            setDepartmentCategoryProducts([...itemsList]);
            //get start and end price amounts from each product
            getSliderAmount([...itemsList]);
            setSliderPosition(0);
            setPreviousSlider1TotalDistance(0);
            setPreviousSlider2TotalDistance(100);
            //setShowDepartmentCategoryProducts(true);
            //setProductResultCount(departmentCategoryProducts.length);
            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper" /*id={product.productId.toString()}
                                                ref={getDepartmentCategoryProductContainerRef} onClick={()=>setProductSelectionId(product.productId)}*/>
                                                <ShopProductDetail product={product} getImageRef={getImageRef} sbtNavLink={loadNavLink}/>
                                            </div> 
                        })}
                    </div>

        } else {
           
            return <div className="shop-product-category-products-wrapper">
                        {(itemsList as Product[]).map((product, index)=>{
                                    return <div key={index} className="shop-product-detail-wrapper" /*id={product.productId.toString()}
                                                ref={getDepartmentCategoryProductContainerRef} onClick={()=>setProductSelectionId(product.productId)}*/>
                                                <ShopProductDetail product={product} getImageRef={getImageRef} sbtNavLink={loadNavLink}/>
                                            </div> 
                        })}

                        {/*<ScrollToTopButton scrollToTopCallback={scrollToTopCallback}/>*/}
                    </div>
        }

    }

    /*
    function scrollToTopCallback():void {
        const root = document.documentElement;
        root.scrollIntoView({behavior:'smooth'});
    }
    */


    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as Product[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function displayListSorter(itemsPerList:number, ps:Product[]):void {
        if(ps.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function productMobileNavHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        //shopProductsMobileNavRef.current!.style.visibility = 'visible';
        //shopProductsMobileNavRef.current!.style.transform = 'translateY(0%)';  
        sbatProductMobileNavFilterHandler();
    }


    //console.log('isdc:', isSpecialDepartmentCategory);
    //console.log('sdc:', storeDepartmentCategory);
    //console.log('dcn:', departmentCategoryName);
    //console.log('props.dcn:', props.departmentCategoryName);

    function setupShopDepartmentCategoryContainer(storeDepartment:StoreDepartment, storeDepartmentCategory:DepartmentCategory, 
                                                showDepartmentCategoryProducts:boolean, keywordSearchType:string):JSX.Element | null{

        if(storeDepartment !== null && storeDepartmentCategory !== null && keywordSearchType === 'noProductsAdded'
            && storeDepartmentCategory.isSpecialDepartmentCategory && showDepartmentCategoryProducts
            && (props.departmentCategoryName === storeDepartmentCategory.departmentCategoryName
            || props.departmentCategoryName === 'none')){            
            //console.log('test failed');
            return (<div className="shop-by-area-type-products-container">
                        {
                            showSpecialProductCategoryShortcut 
                            ? 
                            <div>
                                {setupSpecialProductCategorySearchShortcut(storeDepartmentCategory as DepartmentCategory)}
                            </div>
                            : null
                        }
                        <div className="product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{departmentCategoryProductTotal} {(departmentCategoryProductTotal === 1) ? 'result' : 'results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <fieldset className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>{
                                                                            sortByOptionSelected(
                                                                                            e, 
                                                                                            departmentCategoryProducts,
                                                                                            storeDepartmentName!,
                                                                                            departmentCategoryName!,
                                                                                            storeDepartment.departmentId,
                                                                                            storeDepartmentCategory.departmentCategoryId
                                                                                            )}}>
                                            <option value="Best Match">Best Match</option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                            <option value="Price High to Low">Price High to Low</option>
                                            <option value="Price Low to High">Price Low to High</option>
                                            <option value="Ratings">Ratings</option>
                                            <option value="Newest">Newest</option>
                                    </select>
                                </fieldset>
                            </form>
                        </div>
                        <div className="shop-product-category-products-error-message-container">
                            {/*hasProductCategories 
                                ?
                                <div className="shop-product-category-products-error-message">
                                    <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                    <div>{`There are no products setup. Please add some products to the ${departmentCategoryName} department category.`}</div>
                                </div>
                                :
                                <div className="shop-product-category-products-error-message">
                                    <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                    <div>{`There are no product categories, product subcategories or products setup for this department category.`}</div>
                                </div>
                            */}
                            <div className="shop-product-category-products-error-message">
                                <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                <div>There are no products added to this department category.</div>
                            </div>
                        </div>
                    </div>);

        } else if(storeDepartment !== null && storeDepartmentCategory !== null && keywordSearchType === 'noResults'
            && storeDepartmentCategory.isSpecialDepartmentCategory && showDepartmentCategoryProducts
            && (props.departmentCategoryName === storeDepartmentCategory.departmentCategoryName
            || props.departmentCategoryName === 'none')){            
            //console.log('test failed');
            return (<div className="shop-by-area-type-products-container">
                        {
                            showSpecialProductCategoryShortcut 
                            ? 
                            <div>
                                {setupSpecialProductCategorySearchShortcut(storeDepartmentCategory as DepartmentCategory)}
                            </div>
                            : null
                        }
                        <div className="product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{departmentCategoryProductTotal} {(departmentCategoryProductTotal === 1) ? 'result' : 'results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <fieldset className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>{
                                                                            sortByOptionSelected(
                                                                                            e, 
                                                                                            departmentCategoryProducts,
                                                                                            storeDepartmentName!,
                                                                                            departmentCategoryName!,
                                                                                            storeDepartment.departmentId,
                                                                                            storeDepartmentCategory.departmentCategoryId
                                                                                            )}}>
                                            <option value="Best Match">Best Match</option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                            <option value="Price High to Low">Price High to Low</option>
                                            <option value="Price Low to High">Price Low to High</option>
                                            <option value="Ratings">Ratings</option>
                                            <option value="Newest">Newest</option>
                                    </select>
                                </fieldset>
                            </form>
                        </div>
                        <div className="shop-product-category-products-error-message-container">
                            <div className="shop-product-category-products-error-message">
                                <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                <div>There are no products that match your search criteria. Please modify your criteria and try again.</div>
                            </div>
                        </div>
                    </div>);
                    
                
            
        
        } else if(storeDepartment !== null && storeDepartmentCategory !== null && storeDepartmentCategory.isSpecialDepartmentCategory
            && showDepartmentCategoryProducts 
            && (props.departmentCategoryName === storeDepartmentCategory.departmentCategoryName
            || props.departmentCategoryName === 'none')){
            //console.log('test failed:', storeDepartment, departmentCategoryName, departmentCategoryProducts);
            return (<div className="shop-by-area-type-products-container">
                        {
                            showSpecialProductCategoryShortcut 
                            ? 
                            <div>
                                {setupSpecialProductCategorySearchShortcut(storeDepartmentCategory as DepartmentCategory)}
                            </div>
                            : null
                        }
                        <div className="product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{departmentCategoryProductTotal} {(departmentCategoryProductTotal === 1) ? 'result' : 'results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <div className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>{
                                                                            sortByOptionSelected(
                                                                                            e, 
                                                                                            departmentCategoryProducts,
                                                                                            storeDepartmentName!,
                                                                                            departmentCategoryName!,
                                                                                            storeDepartment.departmentId,
                                                                                            storeDepartmentCategory.departmentCategoryId
                                                                                            )}}>
                                            <option value="Best Match">Best Match</option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                            <option value="Price High to Low">Price High to Low</option>
                                            <option value="Price Low to High">Price Low to High</option>
                                            <option value="Ratings">Ratings</option>
                                            <option value="Newest">Newest</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        {
                            showNoSearchResultsFound && !isLoading
                            ? 
                            <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                            :
                            null
                        }

                        {
                            !showNoSearchResultsFound && !isLoading
                            ? 
                            <ListViewer itemsList={itemsList} items={departmentCategoryProducts} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition} optionalCategory={storeDepartmentCategory}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupDepartmentCategoryProducts} />
                            :
                            null
                        }

                        {   
                            (!showNoSearchResultsFound && isLoading)
                            ?
                            <div className="sbat-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            : null
                        }

                    </div>);
            

        }  else {
            //console.log('render');
            return <div className="sbat-loader-animation-container">
                    <LoaderAnimation/>
                </div>;
        }
    

    }


    return (<div className="shop-by-area-type-products-wrapper">
                {/*put setup function for shop department category container*/}
                {setupShopDepartmentCategoryContainer(storeDepartment!, storeDepartmentCategory!, 
                                                    showDepartmentCategoryProducts, keywordSearchType)}
            </div>);




}

export default ShopByAreaTypeDepartmentCategory;
