import Product from './product';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import ProductSubCategory from './productSubCategory';
import SearchFilter, {SearchTerm} from './searchFilter';

function addProducts(products:Product[]):Product[]{
    return products.map((product)=>{
        return new Product({...product});
    });
}

function addProductCategories(productCategories:ProductCategory[]):ProductCategory[]{
    return productCategories.map((productCategory)=>{
        return new ProductCategory({
            productCategoryId:productCategory.productCategoryId,
            productCategoryName: productCategory.productCategoryName,
            productCategoryImage: productCategory.productCategoryImage,
            departmentCategoryName: productCategory.departmentCategoryName,
            departmentName: productCategory.departmentName,
            productSubCategories:addProductSubCategories(productCategory.productSubCategories),
            showProductCategoryKeywordSearch: productCategory.showProductCategoryKeywordSearch,
            showProductCategoryNameSearch: productCategory.showProductCategoryNameSearch,
            showProductSubCategoryShortcut: productCategory.showProductSubCategoryShortcut,
            showRelatedProductCategoryShortcut: productCategory.showRelatedProductCategoryShortcut,
            highlightText: productCategory.highlightText,
            products: addProducts(productCategory.products),
            searchFilters:addSearchFilters(productCategory.searchFilters)
        });
    });
}

function addProductSubCategories(productSubCategories:ProductSubCategory[]):ProductSubCategory[]{
    return productSubCategories.map((psc)=>{
                return new ProductSubCategory({...psc});
            });
}

export default function addDepartmentCategories(departmentCategories:DepartmentCategory[]):DepartmentCategory[]{
    //console.log('dcs:', departmentCategories);
    return departmentCategories.map((departmentCategory)=>{
        //console.log('dc 4-23:', departmentCategory);
        return new DepartmentCategory({
            departmentCategoryId:departmentCategory.departmentCategoryId,
            departmentId:departmentCategory.departmentId,
            departmentCategoryName: departmentCategory.departmentCategoryName,
            departmentName: departmentCategory.departmentName,
            departmentCategoryImage: departmentCategory.departmentCategoryImage,
            isSpecialDepartmentCategory:departmentCategory.isSpecialDepartmentCategory,
            showProductCategorySidebar:departmentCategory.showProductCategorySidebar,
            showProductCategoryShortcut:departmentCategory.showProductCategoryShortcut,
            highlightText:departmentCategory.highlightText,
            searchFilters:addSearchFilters(departmentCategory.searchFilters),
            productCategories: addProductCategories(departmentCategory.productCategories)
        });
    });
}

export function addSearchFilters(searchFilters:SearchFilter[]):SearchFilter[]{
    if(typeof searchFilters !== 'undefined'){
        return searchFilters.map((searchFilter)=>{
            //searchFilter.searchFilterSelected = false;
            //console.log('sf:', searchFilter);
            return new SearchFilter({
                            searchFilterId:searchFilter.searchFilterId,
                            filterName:searchFilter.filterName,
                            showFilter:searchFilter.showFilter,
                            searchFilterSelected:searchFilter.searchFilterSelected,
                            searchTerms:addSearchTerms(searchFilter.searchTerms)
                        });
        });
    } else {
        return [];
    }

}

function addSearchTerms(searchTerms:SearchTerm[]):SearchTerm[]{
    return searchTerms.map((searchTerm)=>{
        //searchTerm.searchTermSelected = false;
        //console.log('st 2-10:', searchTerm);
        return new SearchTerm({...searchTerm});
    })
}