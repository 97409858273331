export default class SubShopCategory {
    public subShopCategoryId:number;
    public subShopCategoryNameId:number;
    public shopCategoryId:number;
    public shopCategoryNameId:number;
    public subShopCategoryName:string;
    public subShopCategoryLink:string;
    constructor(options: {
        subShopCategoryId?:number,
        subShopCategoryNameId?:number,
        shopCategoryId?:number;
        shopCategoryNameId?:number,
        subShopCategoryName?:string,
        subShopCategoryLink?:string
    }={}){
        this.subShopCategoryId = options.subShopCategoryId || 0;
        this.subShopCategoryNameId = options.subShopCategoryNameId || 0;
        this.shopCategoryId = options.shopCategoryId || 0;
        this.shopCategoryNameId = options.shopCategoryNameId || 0;
        this.subShopCategoryName = options.subShopCategoryName || '';
        this.subShopCategoryLink = options.subShopCategoryLink || '';
    }
}