import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProductKeywordSearch from '../../productKeywordSearch';
import cloneDeep from 'lodash/cloneDeep';

const initialState: ProductKeywordSearch = {
    keywordSearch:'none',
    priceRangeSearch:'relatedProducts',
    storeDepartmentSearch:'none',
    departmentCategorySearch:'none',
    sentFromKeywordList:false,
    clearKeywordSearch:false,
    sameKeywordEntered:false
};

export const productKeywordSearchSlice = createSlice({
  name: 'productKeywordSearch',
  initialState,
  reducers: {
    addProductKeywordSearch: (state, action:PayloadAction<ProductKeywordSearch>) => {
      state.keywordSearch = action.payload.keywordSearch;
      state.priceRangeSearch = action.payload.priceRangeSearch;
      state.storeDepartmentSearch = action.payload.storeDepartmentSearch;
      state.departmentCategorySearch = action.payload.departmentCategorySearch;
      state.sentFromKeywordList = action.payload.sentFromKeywordList;
      state.clearKeywordSearch = action.payload.clearKeywordSearch;
      state.sameKeywordEntered = action.payload.sameKeywordEntered;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addProductKeywordSearch } = productKeywordSearchSlice.actions;

interface RootState {
  productKeywordSearch: ProductKeywordSearch
}

export const selectProductKeywordSearch = (state:RootState) =>  {
  const clone = cloneDeep(state.productKeywordSearch);
  return clone;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default productKeywordSearchSlice.reducer;