import React, {useState, useRef, useEffect} from 'react';
import './ListChanger.css';
import StoreLocation from './storeLocation';
import {getAnyItemList} from './helper-get-item-list';
import './ListSorter.css';

interface AdjContainerStyle {
    margin:string;
    width:string;
}

interface Props {
    itemsPerList:number;
    //items:StoreLocation[];
    items:object[];
    listChangerBtnPosition:number;
    getListChangerBtnPosition:(listChangerBtnPosition:number)=>void;
    showItemsPerList:(itemslist:object[])=>void;
    adjContainerStyle?:AdjContainerStyle;
}

const ListSorter:React.FunctionComponent<Props> = (props:Props)=>{
    /*
    const items:string[] = ['apple', 'banana', 'orange', 'grape', 'lemon', 'pear', 'avocado', 'cabbage', 'watermelon', 'lime',
                            'lettuce', 'potato', 'celery', 'tomato', 'kiwi', 'brocoli', 'beet', 'spinach', 'kale', 'romaine',
                            'ginger', 'parsley', 'cilantro', 'pepper', 'zuchini', 'cucumber', 'squash', 'turnip', 'hickama', 'onion'];
    const itemsPerList:number = 2;
    */
    const {items, itemsPerList, listChangerBtnPosition, getListChangerBtnPosition, showItemsPerList} = props;
    const [currentBtnPosition, setCurrentBtnPosition] = useState(listChangerBtnPosition);
    const [totalBtns, setTotalBtns] = useState(Math.ceil(items.length / itemsPerList));
    const listChangerContainer:React.RefObject<HTMLDivElement> = useRef(null);
    //const [listChangerBtns, setListChangerBtns] = useState<HTMLButtonElement[] | null>(null);
    //console.log('cbp:', currentBtnPosition, 'ipl:', itemsPerList);

    const [adjContainerStyle, setAdjContainerStyle] = useState<AdjContainerStyle|undefined>(props.adjContainerStyle);

    
    useEffect(()=>{
        //highlightFirstMainBtn(currentBtnPosition);
        //load first set of items
        /*previous version
        let lastItem:number = currentBtnPosition + itemsPerList;
        let itemList:StoreLocation[] = [];
            itemList = items.slice(currentBtnPosition, lastItem);
            //console.log('il:', itemList);
            showItemsPerList(itemList);
            */

            //use this function to replace lines 27-31
            //getAnyItemList(currentBtnPosition, items, itemsPerList, showItemsPerList);

        setTotalBtns(Math.ceil(items.length / itemsPerList));
        setCurrentBtnPosition(listChangerBtnPosition);
        
    },[itemsPerList]);


    useEffect(()=>{
        setTotalBtns(Math.ceil(items.length / itemsPerList));
        setCurrentBtnPosition(listChangerBtnPosition);
    },[listChangerBtnPosition]);

    useEffect(()=>{
        setTotalBtns(Math.ceil(items.length / itemsPerList));
        setCurrentBtnPosition(listChangerBtnPosition);
    },[items]);

    /*
    function getItemList(currentBtnPosition:number, items:StoreLocation[], itemsPerList:number):void {
        const bp = currentBtnPosition + 1;
        const lastItem = bp * itemsPerList;
        const firstItem = lastItem - itemsPerList;
        const itemList = items.slice(firstItem, lastItem);
        console.log('il:', itemList);
        showItemsPerList(itemList);

    }
    */

    function listBtnClickHandler(
        e:React.MouseEvent, 
        btnIndex:number, 
        showListItems:(btnRef: HTMLButtonElement, btnPosition: number, itemsPerList: number, items: object[])=>void):void {
        e.stopPropagation();
        showListItems(e.target as HTMLButtonElement, btnIndex, itemsPerList, items);
    }

    function setListChangerBtnClass(btnListIndex:number, currentBtnPosition:number):string {
        return currentBtnPosition === btnListIndex ? 'list-changer-main-btn-color' : 'list-changer-main-btn';
    }

    function getListBtns(items:object[], itemsPerList:number, currentBtnPosition:number):JSX.Element[] {
        let listBtns:JSX.Element[] = [];
        if(totalBtns <= 6){
            for(let i=0; i < totalBtns; i++){
                //console.log('i test:', i, currentBtnPosition);
                listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)}
                    className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);            
            }
        } else

        if(currentBtnPosition < 3 && currentBtnPosition <= (totalBtns - 3)){
            for(let i=0; i < totalBtns; i++){
                /*if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                }*/
                if(i <= 3){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)}
                        className={`${setListChangerBtnClass(i, currentBtnPosition)}`}    
                        /*className="list-changer-main-btn"*/>{i + 1}</button>);
                } else if(i > 3 && (i <= totalBtns - 2)){
                    if(i === (totalBtns - 2)){
                        listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                            /*(e:React.MouseEvent<HTMLButtonElement>)=>
                    showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                            className="list-changer-main-btn-hide">{i + 1}</button>);

                        listBtns.push(<button key="ellipsis-last" className="list-changer-ellipsis-btn">...</button>);
                    } else {
                        listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                            /*(e:React.MouseEvent<HTMLButtonElement>)=>
                    showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                            className="list-changer-main-btn-hide">{i + 1}</button>);
                    }

                }/* else if(i === (totalBtns - 2) ){
                    console.log('totalBtns:', totalBtns);
                    
                }*/ else if(i === (totalBtns - 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        } else if(currentBtnPosition < 3 && (currentBtnPosition <= (totalBtns - 2) || currentBtnPosition <= (totalBtns - 1))){
            //console.log('btn28:');
            for(let i=0; i < totalBtns; i++){
                /*if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                }*/
                if(i <= 3){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                } else if(i > 3 && (i <= totalBtns - 2)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                        className="list-changer-main-btn-hide">{i + 1}</button>);
                } else if(i === totalBtns - 1){
                    
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        } else if(currentBtnPosition === 3 && currentBtnPosition <= (totalBtns - 3)){
            for(let i=0; i < totalBtns; i++){
                /*if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                }*/
                if(i <= (currentBtnPosition + 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                } else if((i > (currentBtnPosition + 1)) && i <= (totalBtns - 3)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                        className="list-changer-main-btn-hide">{i + 1}</button>);
                } else if(i === totalBtns - 2 ){
                    listBtns.push(<button key="ellipsis-last" className="list-changer-ellipsis-btn">...</button>);
                } else if(i === totalBtns - 1){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        } else if(currentBtnPosition === 3 && (currentBtnPosition <= (totalBtns - 2))){
            for(let i=0; i < totalBtns; i++){
                /*if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                }*/
                if(i <= (currentBtnPosition + 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                } else if((i > (currentBtnPosition + 1)) && i <= (totalBtns - 2)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                        className="list-changer-main-btn-hide">{i + 1}</button>);
                } else if(i === totalBtns - 1){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        } else if(currentBtnPosition === 3 && (currentBtnPosition === (totalBtns - 1))){
            //console.log('=== 3');
            for(let i=0; i < totalBtns; i++){
                /*if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                }*/
                if(i <= (currentBtnPosition + 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                } else if((i > (currentBtnPosition + 1)) && i <= (totalBtns - 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                        className="list-changer-main-btn-hide">{i + 1}</button>);
                } else if(i === totalBtns - 1){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        } else if(currentBtnPosition >= 4 && (currentBtnPosition <= (totalBtns - 3))){
            //console.log('btn28:');
            for(let i=0; i < totalBtns; i++){
                if(i === 0){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }
                if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                }
                if(i >= ((currentBtnPosition + 1) - 3) && i <= (currentBtnPosition + 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                } else if((i >= 1 && i < ((currentBtnPosition + 1) - 3)) || 
                            i > (currentBtnPosition + 1) && i <= (totalBtns - 3)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                        className="list-changer-main-btn-hide">{i + 1}</button>);
                } else if(i === totalBtns - 2){
                    listBtns.push(<button key="ellipsis-last" className="list-changer-ellipsis-btn">...</button>);
                } else if(i === totalBtns - 1){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        } else if(currentBtnPosition >= 4 && (currentBtnPosition <= (totalBtns - 2))){
            //console.log('btn28:');
            for(let i=0; i < totalBtns; i++){
                if(i === 0){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }
                if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                } 
                if(i >= ((currentBtnPosition + 1) - 3) && i <= (currentBtnPosition + 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                } else if((i >= 1 && i < ((currentBtnPosition + 1) - 3)) || 
                            i > (currentBtnPosition + 1) && i <= (totalBtns - 2)){
                                //console.log('test 2 passed');
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                        className="list-changer-main-btn-hide">{i + 1}</button>);
                } else if(i === totalBtns - 1){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        } else if(currentBtnPosition >= 4 && (currentBtnPosition === (totalBtns - 1))){
            for(let i=0; i < totalBtns; i++){
                if(i === 0){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }
                if(i === 1){
                    listBtns.push(<button key="ellipsis-first" className="list-changer-ellipsis-btn">...</button>);
                }
                if(i >= (currentBtnPosition - 3) && i <= (currentBtnPosition + 1)){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                } else if(i >= 1 && i < (currentBtnPosition - 3)){
                    //console.log('totalBtns:', totalBtns);
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                        className="list-changer-main-btn-hide">{i + 1}</button>);
                } else if(i === totalBtns - 1){
                    listBtns.push(<button key={i} onClick={(e:React.MouseEvent)=>listBtnClickHandler(e, i, showListItems)
                        /*(e:React.MouseEvent<HTMLButtonElement>)=>
                showListItems(e.target as HTMLButtonElement, i, itemsPerList, items)*/}
                className={`${setListChangerBtnClass(i, currentBtnPosition)}`}>{i + 1}</button>);
                }

            }
        }

        return listBtns;
    }

    function highlightFirstMainBtn(btnPosition:number):void {
        if(btnPosition === 0){
            (listChangerContainer.current as HTMLDivElement).getElementsByClassName('list-changer-main-btn')[0].classList.remove('list-changer-main-btn');
            (listChangerContainer.current as HTMLDivElement).getElementsByTagName('button')[0].classList.add('list-changer-main-btn-color');
        }  
    }

    function showListItems(btnRef:HTMLButtonElement, btnPosition:number, itemsPerList:number, items:object[]):void {
        //console.log('btnRef:', btnRef);
        //remove existing css classes from clicked button
        btnRef.classList.remove('list-changer-main-btn');
        btnRef.classList.remove('list-changer-main-btn-hide');
        btnRef.classList.add('list-changer-main-btn-color');
        setCurrentBtnPosition(btnPosition);
        getListChangerBtnPosition(btnPosition);
        //console.log('btnPosition:', btnPosition);
        if(btnPosition > 0){
            btnPosition *= itemsPerList;
        }
        //console.log('btnPosition:', btnPosition);
        //load next set of items in list
        let lastItem:number = btnPosition + itemsPerList;
        let itemList:object[] = [];
            itemList = items.slice(btnPosition, lastItem);
            showItemsPerList(itemList);

        //unselect previous button clicked
        let listChangerBtns:HTMLButtonElement[] = getListChangerMainBtns();
        listChangerBtns.forEach((listChangerBtn)=>{
            if(listChangerBtn !== btnRef && listChangerBtn.className === 'list-changer-main-btn-color'){
                listChangerBtn.classList.remove('list-changer-main-btn-color');
                listChangerBtn.classList.add('list-changer-main-btn');
            }
        });

    }

    function getListChangerMainBtns(){
        let listChangerBtnList = Array.from((listChangerContainer.current as HTMLDivElement).getElementsByTagName('button'));
        return listChangerBtnList.filter((listChangerBtn)=>{
            //console.log('btnClass:', listChangerBtn.className);
            return (listChangerBtn.className === 'list-changer-main-btn' || 
                    listChangerBtn.className === 'list-changer-main-btn-hide' ||
                    listChangerBtn.className === 'list-changer-main-btn-color');
        });
    }

    function handleListChangerNextBtnClick(e:React.MouseEvent<HTMLButtonElement>):void {
        e.stopPropagation();
        let btnList = getListChangerMainBtns();
        let nextBtnRef = btnList[currentBtnPosition + 1];
        showListItems(nextBtnRef, currentBtnPosition + 1, itemsPerList, items);
    }

    function handleListChangerPreviousBtnClick(e:React.MouseEvent<HTMLButtonElement>):void {
        e.stopPropagation();
        let btnList = getListChangerMainBtns();
        let nextBtnRef = btnList[currentBtnPosition - 1];
        showListItems(nextBtnRef, currentBtnPosition - 1, itemsPerList, items);        
    }

    function setupAdjContainerStyles(style:AdjContainerStyle|undefined):AdjContainerStyle {
        if(typeof style !== 'undefined'){
            return style;

        } else {
            return {width:'', margin:''};
        }
    }


    return (
        <div ref={listChangerContainer} style={setupAdjContainerStyles(adjContainerStyle)} className="list-sorter-container">
            {((currentBtnPosition !== 0) && (totalBtns > 6)) ? <button type="button" className="list-changer-previous-btn"
                onClick={handleListChangerPreviousBtnClick}
            >Prev</button> : null}
            {getListBtns(items, itemsPerList, currentBtnPosition)}
            {(currentBtnPosition !== (totalBtns - 1)) && (totalBtns > 6) ? <button type="button" className="list-changer-next-btn"
                onClick={handleListChangerNextBtnClick}
            >Next</button> : null}
        </div>
    )
}

export default ListSorter;