export default class StoreService {
    public storeServiceId:number;
    public storeLocationId:number;
    public storeServiceAreaId:number;
    public storeServiceName:string;

    constructor(options: {
        storeServiceId?:number,
        storeLocationId?:number,
        storeServiceAreaId?:number,
        storeServiceName?:string
    }={}){
        this.storeServiceId = options.storeServiceId || 0;
        this.storeLocationId = options.storeLocationId || 0;
        this.storeServiceAreaId = options.storeServiceAreaId || 0;
        this.storeServiceName = options. storeServiceName || '';
    }
}