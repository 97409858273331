import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
//import {Link, useLocation} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from 'react-router-dom';
import UserRatings from './UserRatings';
import DepartmentCategory from './departmentCategory';
import StoreDepartment from './storeDepartment';
import Product from './product';
import ShopProductDetail from './ShopProductDetail';
import KeywordSearchResult from './keywordSearchResult';
import {sortByAtoZ, sortByZtoA, sortByNewest, sortByRatings, 
    sortByPriceHightoLow, sortByPriceLowtoHigh} from './helper-sort-by-option';
import helperHighlightDepartment from './helper-highlight-department';
import HighlightDepartmentText from './HighlightDepartmentText';
import HighlightDepartmentCategoryText from './HighlightDepartmentCategoryText';
import PriceSlider3 from './PriceSlider3';
import ShopProductMiniCart from './ShopProductMiniCart';
import ProductKeywordSearch from './redux/productKeywordSearch';
import helperSetOptionValue, {helperSetOptionValueNow} from './helper-set-option-value'
//import { addShopLoadDetails, AddShopLoadDetails } from './redux/actions';
import {selectShopLoadDetails} from './redux/features/shopLoadDetails/shopLoadDetailsSlice';
import {selectProductKeywordSearch, addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import helperHighlightCategory from './helper-highlight-category';
import ShopLoadDetails from './redux/shopLoadDetails';
import SearchFilterSidebar from './SearchFilterSidebar';
import SearchFilter from './searchFilter';
import RelatedProduct, {SearchFilterArea} from './relatedProduct';
import {checkSearchFiltersSelected, updateSearchFilters2} from './helper-search-filter-setup';
import addDepartmentCategories, {addSearchFilters} from './helper-add-department-categories';
import cloneDeep from 'lodash/cloneDeep';
import HighlightSearchFilterAreaText from './HighlightSearchFilterAreaText';
import helperHighlightSearchFilterArea , {resetSearchFilterAreaHighlight} from './helper-highlight-search-filter-area';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import Footer from './Footer';
import LoaderAnimation from './LoaderAnimation';
import GenericErrorMessage from './GenericErrorMessage';
import loadNavLink from './loadNavLinkHelper';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import ScrollToTopButton from './ScrollToTopButton';
import formatLinkString from './helper-format-link-string';
import checkSearchFilterValidity, {SearchFilterValidity} from './helper-check-search-filter-validity';
import SearchFilterErrorMessage from './SearchFilterErrorMessage';
import './RelatedProducts.css';

interface Props {
    //path:string;
    //keywordSearch?:string;
}

const RelatedProducts2: React.FunctionComponent<Props> = (props:Props) => {
    const kwSearch = getQueryParams(window.location.pathname);
    //console.log('kws:', kwSearch);
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const shopLoadDetailsRedux = useAppSelector(selectShopLoadDetails);
    const productKeywordSearchRedux = useAppSelector(selectProductKeywordSearch);

    const navigate = useNavigate();
    //const [keywordSearch, setKeywordSearch] = useState(props.keywordSearch);
    const sps = filterKeywordSearch(kwSearch);
    //console.log('kws:', kwSearch, sps);
    const [keywordSearch, setKeywordSearch] = useState(''/*searchParams.kwSearch*/);
    const [kwSearchRestrictedChars, setKwSearchRestrictedChars] = useState(sps.restrictedChars);
    const [searchParams, setSearchParams] = useState(sps);
    //console.log('pks test:', keywordSearch);
    const [keywordSearchType, setKeywordSearchType] = useState('productCategory');
    const [storeDepartments, setStoreDepartments] = useState<StoreDepartment[] | []>([]);
    //const [filteredStoreDepartmentNames, setFilteredStoreDepartmentNames] = useState<string[] | []>([]);
    //const [filteredStoreDepartments, setFilteredStoreDepartments] = useState<StoreDepartment[] | []>([]);
    const [searchFilterAreas, setSearchFilterAreas] = useState<SearchFilterArea[] | []>([]);
    const [products, setProducts] = useState<Product[] | []>([]);
    const [relatedProductTotal, setRelatedProductTotal] = useState(0);
    const [sortByOptionValue, setSortByOptionValue] = useState('Best Match');
    const [startSliderAmount, setStartSliderAmount] = useState(0);
    const [endSliderAmount, setEndSliderAmount] = useState(0);
    const [showRelatedProducts, setShowRelatedProducts] = useState(false);
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState('0%');
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState('100%');
    const [previousSlider1PostionLeftAmount, setPreviousSlider1PostionLeftAmount] = useState(0);
    const [previousSlider2PostionLeftAmount, setPreviousSlider2PostionLeftAmount] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(0);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(100);

    const [slider1ZIndex, setSlider1ZIndex] = useState(0);
    const [slider2ZIndex, setSlider2ZIndex] = useState(0);

    const [showPriceSlider, setShowPriceSlider] = useState(false);
    const [priceRangeSearch, setPriceRangeSearch] = useState('relatedProducts');
    const [storeDepartmentSearch, setStoreDepartmentSearch] = useState('none');
    const [departmentCategorySearch, setDepartmentCategorySearch] = useState('none');

    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [initialSearchFilters, setInitialSearchFilters] = useState<SearchFilter[]>([]);
    //const [searchTermNamesSelected, setSearchTermNamesSelected] = useState<string[]>([]);
    //const [searchFilterNamesSelected, setSearchFilterNamesSelected] = useState<string[]>([]);
    const [searchTermIsSelected, setSearchTermIsSelected] = useState(false);
    const [showSpecialProductCategoryShortcut, setShowSpecialProductCategoryShortcut] = useState(true);

    //const [searchFilterAreaType, setSearchFilterAreaType] = useState('Department');
    const [searchFilterAreaSelected, setSearchFilterAreaSelected] = useState<SearchFilterArea>(new SearchFilterArea({searchFilterAreaType:'Department', searchFilterAreaName:'none'}));
    const [prevSearchFilterAreaSelected, setPrevSearchFilterAreaSelected] = useState<SearchFilterArea>(new SearchFilterArea());


    const sfasRef = useRef<SearchFilterArea[]>([]);

    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(40);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Product[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No store locations have been added.');    

    const shopProductsMobileNavRef = useRef<HTMLDivElement>(null);

    const showPriceSliderRef = useRef<boolean>(false);

    const [showSearchFilters, setShowSearchFilters] = useState(false);

    const sortByBtnRefs = useRef<HTMLButtonElement[]>([]);

    const mobileNavOpen = useRef<boolean>(false);

    const [fetchErrorThrown, setFetchErrorThrown] = useState(false);

    const [productMatch, setProductMatch] = useState(false);

    const highestPriceProductAmount = useRef<number>(0);
    const lowestPriceProductAmount = useRef<number>(0);
    

    //const[shopLoadDetails, setShopLoadDetails] = useState(props.shopLoadDetails);
    //console.log('rps2:', products);
    //console.log('related products');
    //console.log('ssa:', startSliderAmount);
    //console.log('esa:', endSliderAmount);
    //console.log('sfas:', searchFilterAreas);
    //console.log('keywordSearchType:', keywordSearchType);
    //console.log('products:', relatedProductTotal);
    //console.log('sfas 5-4:', searchFilterAreaSelected);


    const relatedProductsContainerRefs = useRef<HTMLDivElement[]>([]);
    const [showMiniCart, setShowMiniCart] = useState(false);
    const miniCartHasProductsRef = useRef<boolean>(false);


    const [showFillerContent, setShowFillerContent] = useState(setupFillerConent());
    const [isLoading, setIsLoading] = useState(false);

    const [prevSearchFilterAreaChildSelected, setPrevSearchFilterAreaChildSelected] = useState('none');
    const [prevSearchFilterAreaNameSelected, setPrevSearchFilterAreaNameSelected] = useState('none');

    const [showMobileAnimationLoader, setShowMobileAnimationLoader] = useState(false);
    const showMobileAnimationLoaderRef = useRef<boolean>(false);

    const portrait = useRef<MediaQueryList[]>([]);

    const [showSearchFilterErrorMsg, setShowSearchFilterErrorMsg] = useState(false);
    const [searchFilterErrorMsg, setSearchFilterErrorMsg] = useState<SearchFilterValidity|null>(null);
    const showSearchFilterErrorMsgRef = useRef<boolean>(false);

    const [showSidebar, setShowSidebar] = useState(false);


    //console.log('kp14:');


    useEffect(()=>{
        //console.log('scr4:');
        if(window.innerWidth >= 768 && shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;
            setShowMiniCart(true);

        } else if(shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;

        } else {
            //console.log('scr4:');
            miniCartHasProductsRef.current = false;
            setShowMiniCart(false);

            if(relatedProductsContainerRefs.current.length > 0){
                //console.log('ran10');
                relatedProductsContainerRefs.current[0].style.width = '';
                relatedProductsContainerRefs.current[0].style.margin = '';
            }

        }

    },[shoppingCartRedux]);



    function getRelatedProductsContainerRef(el:HTMLDivElement|null):void {
        //console.log('el12:', el);
        if(el !== null){
            relatedProductsContainerRefs.current.length = 0;
        }

        if(el !== null && relatedProductsContainerRefs.current.length === 1){
            //console.log('rpcr:', el === relatedProductsContainerRefs.current[0]);
        }

        if(el !== null && relatedProductsContainerRefs.current.length === 0){
            relatedProductsContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = relatedProductsContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                relatedProductsContainerRefs.current.push(el);
            }

        }

        //console.log('plwrs:', relatedProductsContainerRefs.current);


        if(showMiniCart && window.innerWidth <= 1575){
            //console.log('smc:');
            relatedProductsContainerRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            relatedProductsContainerRefs.current[0].style.margin = '0 auto 0 0';

        } else {
            relatedProductsContainerRefs.current[0].style.width = '100%';
            relatedProductsContainerRefs.current[0].style.margin = '0 auto';
        }

        /*css styles for bread crumbs
        if(showMiniCart && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

    }

    function getRelatedProductsFooterContainerRef(el:HTMLDivElement|null):void {
        //console.log('el12:', el);
        if(el !== null){
            relatedProductsContainerRefs.current.length = 0;
        }

        if(el !== null && relatedProductsContainerRefs.current.length === 1){
            //console.log('rpcr:', el === relatedProductsContainerRefs.current[0]);
        }

        if(el !== null && relatedProductsContainerRefs.current.length === 0){
            relatedProductsContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = relatedProductsContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                relatedProductsContainerRefs.current.push(el);
            }

        }

        //console.log('plwrs:', relatedProductsContainerRefs.current);


        if(showMiniCart && window.innerWidth <= 1575){
            //console.log('smc:');
            relatedProductsContainerRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            relatedProductsContainerRefs.current[0].style.margin = '0 auto 0 0';

        } else {
            relatedProductsContainerRefs.current[0].style.width = '100%';
            relatedProductsContainerRefs.current[0].style.margin = '0 auto';
        }

        /*css styles for bread crumbs
        if(showMiniCart && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */
        
    }

    function getQueryParams(path:string):string {
        //console.log('tp14:', /^\/search\/q=.+/i.test(path));
        if(/^\/search\/q=.+/i.test(path)){
            const ip = path.indexOf('search/q=');
            const qp = path.slice(ip + 9);
            //console.log('qp:', qp);
            return decodeURIComponent(qp);

        } else {
            return '';
        }
        
    }


    function setupFillerConent():boolean {
        return window.innerWidth >= 1024 ? false : true;
    }

    
    useEffect(()=>{
        //console.log('kwSearch:', kwSearch, keywordSearch, window.scrollY);

        if(window.innerWidth >= 1024){
            setShowMobileAnimationLoader(false);
            showMobileAnimationLoaderRef.current = false;

        } else {
            setShowMobileAnimationLoader(true);
            showMobileAnimationLoaderRef.current = true;
        }
        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */
        

        showSearchFilterErrorMsgRef.current = false;
        setShowSidebar(false);
        

            //previous version
            //setIsProductCategoryKeyword(true);

            //const sps = filterKeywordSearch(kwSearch);

            //setKwSearchRestrictedChars('');
            //setKeywordSearch(kwSearch);

            //setIsProductCategoryKeyword(false);
            
            //original version
            //setKeywordSearch(decodeURIComponent(kwSearch));

            /*remove after testing original version
            const sps = filterKeywordSearch(kwSearch);
            setKwSearchRestrictedChars(sps.restrictedChars);
            setKeywordSearch(sps.kwSearch);
            */
            

            //console.log('tkws:', sps.kwSearch, kwSearch, keywordSearch);

           
            /*
            setProductMatch(false);

            setShowRelatedProducts(false);
            setShowSearchFilters(false);
            setShowPriceSlider(false);
            */
           

            imageRefs.current.length = 0;
            setImageRefsLoaded(false);
    
            //console.log('tkws:', kwSearch, keywordSearch);

            setProductMatch(false);

            setShowRelatedProducts(false);
            setShowSearchFilters(false);
            setShowPriceSlider(false);

            setRelatedProductTotal(0);
    
            
            if(/*keywordSearch.match(/(!|@|%|\^|\$|#|\?|_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&|<|>|\"|\')/gi) 
                ||*/ kwSearch.length === 0){
                setKeywordSearchType('noResults');
                setProducts([]);
    
                //window.scrollTo(0,0);
                setShowRelatedProducts(true);
                setRelatedProductTotal(0);
                //console.log('tkws:', kwSearch, keywordSearch);
    
                return;
            }

            if(window.innerWidth < 1024){
                setShowFillerContent(true);
            }
            

            //if(kwSearch !== keywordSearch){
                setKeywordSearch(kwSearch);
            
                //console.log('tkws:', kwSearch, keywordSearch);
        
                //reset search filter area selected
                const sfas = new SearchFilterArea({searchFilterAreaType:'Department', searchFilterAreaName:'none'});
                setSearchFilterAreaSelected(sfas);
                setPrevSearchFilterAreaSelected(new SearchFilterArea());
        
        
                resetShopSearchFiltersSearchTermsFalse()
                .then((status)=>{
                    if(status === 'success'){
                        resetRelatedSearchFiltersSearchTermsFalse()
                        .then((status)=>{
                            if(status === 'success'){
                                //console.log('status:', status);
                                setIsLoading(true);

                                resetSearchFilterAreaHighlight()
                                .then((res)=>res.json())
                                .then(({status})=>{
                                    if(status === 'all highlights removed'){
                                        //console.log('sfas10:', searchFilterAreaSelected);
                                        //previous version
                                        //getRelatedProducts(searchFilterAreaSelected, false);
                                        getRelatedProducts(sfas, false);
                                        
                                    }
                                })
                                .catch((err)=>console.error('error removing all search filter area highlight:', err.message));
                            }
                        })
                        .catch((err)=>console.error('clear related search terms and search filter error:', err.message));
                    }
                })
                .catch((err)=>console.error('clear shop search filters and search terms error:', err.message));

            //}

        

        


    //},[props.keywordSearch]);
    },[kwSearch]);
    
    //console.log('keywordSearch test13:', keywordSearch, kwSearch);

    /*
    useEffect(()=>{
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        console.log('tkws:', kwSearch, keywordSearch);

        
        if(keywordSearch.length === 0){
            setKeywordSearchType('noResults');
            setProducts([]);

            //window.scrollTo(0,0);
            setShowRelatedProducts(true);
            setRelatedProductTotal(0);
            console.log('tkws:', kwSearch, keywordSearch);

            return;
        }
        

        console.log('tkws:', kwSearch, keywordSearch);

        //reset search filter area selected
        const sfas = new SearchFilterArea({searchFilterAreaType:'Department', searchFilterAreaName:'none'});
        setSearchFilterAreaSelected(sfas);
        setPrevSearchFilterAreaSelected(new SearchFilterArea());


        resetShopSearchFiltersSearchTermsFalse()
        .then((status)=>{
            if(status === 'success'){
                resetRelatedSearchFiltersSearchTermsFalse()
                .then((status)=>{
                    if(status === 'success'){
                        console.log('status:', status);
                        resetSearchFilterAreaHighlight()
                        .then((res)=>res.json())
                        .then(({status})=>{
                            if(status === 'all highlights removed'){
                                console.log('sfas10:', searchFilterAreaSelected);
                                //previous version
                                //getRelatedProducts(searchFilterAreaSelected, false);
                                getRelatedProducts(sfas, false);
                                
                            }
                        })
                        .catch((err)=>console.error('error removing all search filter area highlight:', err.message));
                    }
                })
                .catch((err)=>console.error('clear related search terms and search filter error:', err.message));
            }
        })
        .catch((err)=>console.error('clear shop search filters and search terms error:', err.message));

        


        console.log('middle useEffect after');
 
    },[keywordSearch]);
    */

    //console.log('tkws:', kwSearch, keywordSearch);


    useEffect(()=>{
        //console.log('last useEffect before');
        fetch(`https://server.kando-proto-3.com/store-department-list`, {
            headers:{
                'Accept':'application/json'
            }
        })
        .then((resp)=>resp.json())
        .then((storeDepartments)=>{
            /*previous version
            console.log('departmentCategories:', JSON.parse(storeDepartments));
            let departmentNames:string[] = [];
            let sd:StoreDepartment[] = JSON.parse(storeDepartments);
            console.log('dc length:', sd.length);
            for(let i=0; i < sd.length; i++){
                console.log('dc value:', sd[i].departmentName);
                departmentNames.push(sd[i].departmentName);
            }
            setStoreDepartmentNames(departmentNames);
            */
            const sds = (storeDepartments as StoreDepartment[]).map((sd)=>{
                            return new StoreDepartment({...sd});
                        });
                        
            setStoreDepartments(sds);
        })
        .catch(err=>{
            setFetchErrorThrown(true);
            //console.log('error:', err)
        });

        //console.log('last useEffect after');

    },[]);


    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    function filterKeywordSearch(keyword:string):{restrictedChars:string, kwSearch:string} {
        if(keyword.length === 0){
            //setKwSearchRestrictedChars('');
            //setKeywordSearch('');            
            return ({restrictedChars:'', kwSearch:''});
        }
    
        let productName = keyword;
        //previous version
        //let restrictedChars = productName.match(/(!|@|%|\^|\$|#|\?|_|-|\+|=|\\|\/|\.|[0-9]|\(|\)|\[|\]|\*|\||}|{|&|<|>|\"|\')/gi);
        let restrictedChars = productName.match(/(\.|\+|\*|\?|\^|\$|\(|\)|\[|\]|\{|\}|\||\\)/gi);
        //console.log('restrictedChars:', restrictedChars);
        if(restrictedChars !== null){
            let productNameChars = productName.split('');
            productName = '';
            for(let i=0; i < productNameChars.length; i++){
                for(let h=0; h < restrictedChars.length; h++){
                    if(hasRestrictedChars(productNameChars, i, restrictedChars, h, 0)){
                        break;
                    } else {
                        productName += productNameChars[i];
                        break;
                    }
                }
            } 
            //console.log('newProductName:', newWord);
            //console.log('newProductName adjusted:', productName, 'kwl:', productName.length, 'rcs:', restrictedChars, 'rcsj:', restrictedChars.join(''), 'fj:', keyword);
            //window.sessionStorage.setItem('keyword', productName);
            productName = productName.length === 0 ? keyword : productName;

            //console.log('tkws:', productName, keyword);
            //setKwSearchRestrictedChars(keyword);
            //setKeywordSearch(productName);
            return ({restrictedChars:keyword, kwSearch:productName});


        } else {
            //console.log('newProductName:', productName);
            //setKwSearchRestrictedChars('');
            //setKeywordSearch(productName);
            return ({restrictedChars:'', kwSearch:productName});
            
        }

        
    }
    

    function hasRestrictedChars(productNameChars:string[], productNameCharsIndex:number, restrictedChars:string[], restrictedCharsIndex:number, position:number):boolean {
        if(position === restrictedChars.length){
            //console.log('done');
            return false;
        }
        if(productNameChars[productNameCharsIndex] === restrictedChars[restrictedCharsIndex]){
            //console.log('item match');
            return true; 
        } else {
            //console.log('no match');
            return hasRestrictedChars(productNameChars, productNameCharsIndex, restrictedChars, ++restrictedCharsIndex, ++position);
        }
    
    }
    


    useEffect(()=>{
        window.addEventListener('resize', relatedProductsResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", relatedProductsResizeHandler);

        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupRelatedProductsResizeHandler;
    },[]);

    function cleanupRelatedProductsResizeHandler():void {
        window.removeEventListener('resize', relatedProductsResizeHandler);

        portrait.current[0].removeEventListener("change", relatedProductsResizeHandler);

        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        relatedProductsResizeHandler(new Event('test'));
    }

    function relatedProductsResizeHandler(e:Event):void {
        //console.log('rp resize:', showPriceSlider, products);
        if(window.innerWidth >= 1024 && !showPriceSliderRef.current){
            //setShowPriceSlider(true);
            setupPriceSliderVisibility();
            showPriceSliderRef.current = true;
        }

        if(window.innerWidth < 1024 && showPriceSliderRef.current){
            setShowPriceSlider(false);
            showPriceSliderRef.current = false;
        }

        if(window.innerWidth >= 1024 && showMobileAnimationLoaderRef.current){
            setShowMobileAnimationLoader(false);
            showMobileAnimationLoaderRef.current = false;
        }

        if(window.innerWidth < 1024 && !showMobileAnimationLoaderRef.current){
            setShowMobileAnimationLoader(true);
            showMobileAnimationLoaderRef.current = true;
        }

        if(window.innerWidth >= 1024 && mobileNavOpen.current && shopProductsMobileNavRef.current !== null){
            shopProductsMobileNavRef.current!.style.zIndex = '';
        }

        if(window.innerWidth >= 1024 && !mobileNavOpen.current && shopProductsMobileNavRef.current !== null){
            shopProductsMobileNavRef.current!.style.zIndex = '';
        }

        if(window.innerWidth < 1024 && mobileNavOpen.current && shopProductsMobileNavRef.current !== null){
            shopProductsMobileNavRef.current!.style.zIndex = '7';
        }

        if(window.innerWidth < 1024 && !mobileNavOpen.current && shopProductsMobileNavRef.current !== null){
            shopProductsMobileNavRef.current!.style.zIndex = '';
        }



        //console.log('scr4:', miniCartHasProductsRef.current);
        if(window.innerWidth >= 768 && window.innerWidth <= 1600 && miniCartHasProductsRef.current){
            relatedProductsContainerRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            const ma = 0;//window.innerWidth > 1535 ? window.innerWidth - 1535 : 0;
            //console.log('ma:', ma);
            relatedProductsContainerRefs.current[0].style.margin = `0 auto 0 ${ma}px`;
            setShowMiniCart(true);

        } 
        
        if(window.innerWidth > 1600 && miniCartHasProductsRef.current){
            relatedProductsContainerRefs.current[0].style.width = '100%';
            relatedProductsContainerRefs.current[0].style.margin = '0 auto';
            //setShowMiniCart(true);

        }

        if(window.innerWidth >= 768 && !miniCartHasProductsRef.current){
            relatedProductsContainerRefs.current[0].style.width = '100%';
            relatedProductsContainerRefs.current[0].style.margin = '0 auto';
        }

        if(window.innerWidth < 768){
            relatedProductsContainerRefs.current[0].style.width = '100%';
            relatedProductsContainerRefs.current[0].style.margin = '0 auto';
            setShowMiniCart(false);
        }

        /*css styles for breadcrumbs
        if(miniCartHasProductsRef.current && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */


        if(showSearchFilterErrorMsgRef.current){
            setShowPriceSlider(false);
        }

        
    }

    async function resetShopSearchFiltersSearchTermsFalse():Promise<string> {
        return fetch('https://server.kando-proto-3.com/reset-shop-products-search-filter-search-terms-false', {
                    method:'PUT',
                    headers:{
                        'content-type':'applicattion/json',
                        'accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({status})=>{
                    if(status === 'success'){
                        //console.log('status:', status);
                        return status;

                    } else {
                        //console.error('unable to reset shop search filters and search terms to false');
                        return 'failed';
                        
                    }
                })
                .catch((err)=>console.error('error reseting shop search filters and serch terms to false:', err.message));
    }

    async function resetRelatedSearchFiltersSearchTermsFalse():Promise<string> {
        return fetch('https://server.kando-proto-3.com/reset-related-products-search-filter-search-terms-false', {
                    method:'PUT',
                    headers:{
                        'content-type':'applicattion/json',
                        'accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({status})=>{
                    if(status === 'success'){
                        //console.log('status:', status);
                        return status;

                    } else {
                        //console.error('unable to reset related search filters and search terms to false');
                        return 'failed';
                        
                    }
                })
                .catch((err)=>console.error('error reseting related search filters and serch terms to false:', err.message));
    }


    function clearAllSearchFilterTermSelections(e:React.MouseEvent):void {
        //setProductKeywordSearch(productKeywordSearchRedux);
        //setPriceRangeSearch(productKeywordSearchRedux.priceRangeSearch);
        //setStoreDepartmentSearch(productKeywordSearchRedux.storeDepartmentSearch);
        //setDepartmentCategorySearch(productKeywordSearchRedux.departmentCategorySearch);
        //if(productKeywordSearchRedux.keywordSearch !== 'none'){
            //setKeywordSearch(productKeywordSearchRedux.keywordSearch);
        //}

        //may need to set keyword search
        //test first
        //if needed add it below this line
        
        //console.log('keywordSearch before:', keywordSearch);

        setSearchFilters([]);
        setInitialSearchFilters([]);
        setSearchTermIsSelected(false);

        const sfas = new SearchFilterArea({searchFilterAreaType:'Department', searchFilterAreaName:'none'});
        setSearchFilterAreaSelected(sfas);
        setPrevSearchFilterAreaSelected(new SearchFilterArea());

        setSortByOptionValue('Best Match');

        setShowRelatedProducts(false);
        setShowSearchFilters(false);
        setShowPriceSlider(false);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        sortByBtnRefs.current.forEach((sbbr)=>{
            if(sbbr.textContent === 'Best Match'){
                sbbr.classList.add('highlight-sort-by-list-item-btn');

            } else {
                sbbr.classList.remove('highlight-sort-by-list-item-btn');
            }
        });

        /*
        resetSearchFilterAreaHighlight()
        .then((res)=>res.json())
        .then(({status})=>{
            if(status === 'all highlights removed'){
                getRelatedProducts(sfas);
            }
        })
        .catch((err)=>console.error('error removing all search filter area highlight:', err.message));
        */

        resetShopSearchFiltersSearchTermsFalse()
        .then((status)=>{
            if(status === 'success'){
                resetRelatedSearchFiltersSearchTermsFalse()
                .then((status)=>{
                    if(status === 'success'){
                        //console.log('status:', status);
                        resetSearchFilterAreaHighlight()
                        .then((res)=>res.json())
                        .then(({status})=>{
                            if(status === 'all highlights removed'){
                                //console.log('sfas:', searchFilterAreaSelected);
                                getRelatedProducts(searchFilterAreaSelected, true);
                            }
                        })
                        .catch((err)=>console.error('error removing all search filter area highlight:', err.message));
                    }
                })
                .catch((err)=>console.error('clear related search terms and search filter error:', err.message));
            }
        })
        .catch((err)=>console.error('clear shop search filters and search terms error:', err.message));
    }



    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }

    function clearAllExistingImages():void {        
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
    }
    
    
    function getRelatedProducts(searchFilterAreaSelected:SearchFilterArea, clearFilters:boolean):void {
        const sfat = searchFilterAreaSelected.searchFilterAreaType;
        const sfan = searchFilterAreaSelected.searchFilterAreaName;  
        //console.log('sfat:', sfat, 'sfan:', sfan, 'pks:', keywordSearch, 'ks:', kwSearch);
        
        const priceRange = {    
            status:'noFilterProducts',
            startAmount:previousSlider1PostionLeftAmount,
            endAmount:previousSlider2PostionLeftAmount
        };

        setProducts([]);
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);


        setShowSearchFilterErrorMsg(false);
        showSearchFilterErrorMsgRef.current = false;

        //original version
        //fetch(`http://localhost:9500/related-products-search/${(productKeywordSearch.keywordSearch === 'none') ? keywordSearch : productKeywordSearch.keywordSearch /*keywordSearch*/}`, {

            fetch(`https://server.kando-proto-3.com/related-products-search-mariadb/${sfat}/${sfan}`, {            
                //method:'GET',
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:JSON.stringify({priceRange, keywordSearch:kwSearch})
            })
            .then((resp)=>{
                //setProducts([]);

                if(window.innerWidth < 1024){
                    setPrevSearchFilterAreaSelected(searchFilterAreaSelected);
                    setShowRelatedProducts(false);
                    setShowSearchFilters(false);
                    setShowPriceSlider(false);
                }

                //console.log('resp:',resp);
                return resp.json();
            })
            .then((rp:RelatedProduct)=>{
                //console.log('ksr:', rp);
                //console.log('ksr sfs:', rp.searchFilters);
                //console.log('ksr kst:', rp.keywordSearchType);
                //console.log('ksr rps:', rp.products.length);
                //console.log('ksr sfas:', rp.searchFilterAreas);
                //console.log('ksr ps:', rp.products);

                const sps = filterKeywordSearch(kwSearch);
                //console.log('sps13:', sps);
                if(sps.restrictedChars.length > 0 && !rp.isProductCategory){
                    setKeywordSearch(sps.kwSearch);
                    setKwSearchRestrictedChars(sps.restrictedChars);
                    //setKeywordSearch(sps.kwSearch);
                    setSearchParams(sps);

                } else {
                    setKwSearchRestrictedChars('');
                } 


                if(rp.products.length > 0){
                    setKeywordSearchType(rp.keywordSearchType);
                    //setFilteredStoreDepartmentNames(keywordSearchResult.filteredStoreDepartmentNames);
                    //console.log('sds:', rp.searchFilterAreas);
                    //setFilteredStoreDepartments(addStoreDepartments([...keywordSearchResult.filteredStoreDepartments]));
                    setSearchFilterAreas(addSearchFilterAreas(rp.searchFilterAreas));
                    //setKeywordSearch(props.keywordSearch);
                    //setProducts(addProducts(rp.products));
                    /*previous version
                    helperSetOptionValue(
                        sortByOptionValue, 
                        addProducts(rp.products), 
                        false,
                        setProducts, 
                        setShowRelatedProducts
                        );
                        */

                    
                


                    const rps = addProducts(rp.products);
                    const sbov = clearFilters ? 'Best Match' : sortByOptionValue;
                    const hsovps = helperSetOptionValueNow(sbov, rps);

                    setProducts(hsovps);

                    setRelatedProductTotal(rp.products.length);
                    //test for search filter setup
                    getSearchFilters(rp.searchFilters, rp.products);

                    getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, hsovps);
    
                    getSliderAmount(addProducts(rp.products));
                    setSliderPosition(0);
                    setPreviousSlider1TotalDistance(0);
                    setPreviousSlider2TotalDistance(100);
                    //setEndSliderAmount(getEndSlideAmount(addProducts(keywordSearchResult.relatedProducts)));

                    //setSearchFilterAreaType(searchFilterAreaType);
                    const sfas = (searchFilterAreaSelected.searchFilterAreaType === 'Department'
                      || searchFilterAreaSelected.searchFilterAreaType === 'Department Category') 
                        ? rp.searchFilterAreas.filter((sfa)=>{
                            return sfa.searchFilterAreaName === searchFilterAreaSelected.searchFilterAreaName;
                        })
                        : rp.searchFilterAreas[0].searchFilterAreaChildren.filter((sfac)=>{
                            return sfac.searchFilterAreaName === searchFilterAreaSelected.searchFilterAreaName;
                        })
                        

                        //console.log('sfas2-10:', sfas, searchFilterAreaSelected);
                        
                    sfas.length === 0 ? setSearchFilterAreaSelected(new SearchFilterArea({...searchFilterAreaSelected})) : setSearchFilterAreaSelected(new SearchFilterArea({...sfas[0]}));

                    sfasRef.current.length = 0;
                    sfas.length === 0 ? sfasRef.current.push(new SearchFilterArea({...searchFilterAreaSelected})) : sfasRef.current.push(new SearchFilterArea({...sfas[0]}));
                    //original version
                    //setSearchFilterAreaSelected(searchFilterAreaSelected);
                    //setSearchFilterAreaSelected(sfas[0]);
    
                    //setLoadSetupSlider(true);
                    //setShowPriceSlider(true);
                    //window.scrollTo(0,0);
                    
                    setProductMatch(true);

                    setShowRelatedProducts(true);

                    setShowSearchFilters(true);


                    /*uncomment after testing if needed
                    if(window.innerWidth >= 1024){
                        setShowPriceSlider(true);
                    }
                    */

                    
                    //after setting this up may need to push setShowRelatedProducts after search filters are updated in related products db
                    //so the user can use search filters before they are set
                    updateRelatedProductsSearchTables(rp.searchFilters)
                    .then((sfs:SearchFilter[])=>{
                        //console.log('sfs:', sfs);
                        //dispatch(addProductKeywordSearch({...productKeywordSearchRedux, productMatch:true}));
                    })
                    .catch((err:Error)=>{
                        console.error('error getting search filters from related products search tables:', err.message);
                    });



                    const {valid, sfat, reason, products} = checkSearchFilterValidity(rp.searchFilters, rp.products, 'keyword search');
                    //console.log('valid8:', valid, products);
    
                    if(!valid) {
                        
                        setSearchFilters([]);
                        setShowSearchFilters(false);
                        setShowPriceSlider(false);
    
                        getSearchFilterValidity({valid, sfat, reason, products});
    
                        showSearchFilterErrorMsgRef.current = true;

                        setShowSidebar(true);
    
                    }

                   
    
                } else {
                    setKeywordSearchType('noResults');
                    setProducts([]);

                    //console.log('sfas3-23:', searchFilterAreaSelected);

                    //setSearchFilterAreaType(searchFilterAreaType);
                    setSearchFilterAreaSelected(searchFilterAreaSelected);

                    sfasRef.current.length = 0;
                    sfasRef.current.push(searchFilterAreaSelected);

                    //window.scrollTo(0,0);
                    setShowRelatedProducts(true);
                    setShowSearchFilters(true);
                    setRelatedProductTotal(rp.products.length);
                    //setShowRelatedProducts(true);
                    /*
                    if(window.innerWidth >= 1024){
                        setShowPriceSlider(true);
                    }
                    */

                }

                setIsLoading(false);
                setShowFillerContent(false);
    
            })
            .catch((err)=>{
                //console.log('error:', err)
                setFetchErrorThrown(true);
            });
    }

    function updateRelatedProductsSearchTables(searchFilters:SearchFilter[]):Promise<SearchFilter[]> {
        //console.log('sfs test:', searchFilters);
        const params = {searchFilters};

        //previous version
        //return fetch(`http://localhost:9500/update-related-products-search-tables/${JSON.stringify(searchFilters)}`, {
        return fetch(`https://server.kando-proto-3.com/update-related-products-search-tables`, {
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(params)
            })
            .then((res)=>{
                return res.json();
            })
            .catch((err:Error)=>{
                console.error('error updating related products search tables:', err.message);
            });
    }

    function addSearchFilterAreas(searchFilterAreas:SearchFilterArea[]):SearchFilterArea[] {
        return searchFilterAreas.map((sfa)=>{
            return new SearchFilterArea({
                departmentId:sfa.departmentId,
                highlightText:sfa.highlightText,
                searchFilterAreaType:sfa.searchFilterAreaType,
                searchFilterAreaName:sfa.searchFilterAreaName,
                searchFilterAreaId:sfa.searchFilterAreaId,
                searchFilterAreaChildren:adSearchFilterAreaChildren(sfa.searchFilterAreaChildren)
            });
        });
    }

    function adSearchFilterAreaChildren(searchFilterAreaChildren:SearchFilterArea[]):SearchFilterArea[] {
        return searchFilterAreaChildren.map((sfac)=>{
            return new SearchFilterArea({...sfac})
        });
    }

    function addStoreDepartments(storeDepartments:StoreDepartment[]):StoreDepartment[]{
        return storeDepartments.map((storeDepartment)=>{
            //console.log('sd:', storeDepartment);
            return new StoreDepartment({
                        departmentName:storeDepartment.departmentName,
                        departmentCategories:addDepartmentCategories(storeDepartment.departmentCategories),
                        highlightText:storeDepartment.highlightText
                });
        });
    }

    function addDepartmentCategories(departmentCategories:DepartmentCategory[]):DepartmentCategory[] {
        return departmentCategories.map((departmentCategory)=>{
            //console.log('dcht:', departmentCategory.departmentName);
            return new DepartmentCategory({
                    departmentName:departmentCategory.departmentName,        
                    departmentCategoryName:departmentCategory.departmentCategoryName,
                    highlightText:departmentCategory.highlightText
            });
        });
    }

    function addProducts(products:Product[]):Product[]{
        return products.map((product)=>{
            return new Product({...product});
        });
    }

    function setupProducts(itemsList:object[]):JSX.Element{
        //console.log('products3:', itemsList);
        return <div className="shop-product-category-products-wrapper">
                    {(itemsList as Product[]).map((product, index)=>{
                                        return <div key={index} className="shop-product-detail-wrapper">
                                        <ShopProductDetail product={product} getImageRef={getImageRef} sbtNavLink={sbtNavLink}/>
                                    </div> 
                    })}

                    {/*<ScrollToTopButton scrollToTopCallback={scrollToTopCallback} showMiniCart={showMiniCart}/>*/}
                </div>
    }

    function sbtNavLink(e:React.MouseEvent, pathname:string):void {
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        setShowFillerContent(true)        ;
        navigate(pathname);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
    }


    /*
    function scrollToTopCallback():void {
        const root = document.documentElement;
        root.scrollIntoView({behavior:'smooth'});
    }
    */


    function getSliderAmount(products:Product[]):void {
        const sortedProducts = products.sort(sortByPriceHightoLow);
        const highestPricedProduct:Product = sortedProducts[0] as Product;
        highestPriceProductAmount.current = highestPricedProduct.eachPrice;
        const lowestPricedProduct:Product = sortedProducts[sortedProducts.length - 1] as Product;
        lowestPriceProductAmount.current = lowestPricedProduct.eachPrice;
        //console.log('price difference:', highestPricedProduct.eachPrice - lowestPricedProduct.eachPrice);
        //console.log('sortedProducts.length:', sortedProducts.length, sortedProducts);
        if(sortedProducts.length > 0 && ((highestPricedProduct.eachPrice - lowestPricedProduct.eachPrice) > 1) && window.innerWidth >= 1024){
            //console.log('sps:');
            setShowPriceSlider(true);
            
            //console.log('yes');
        } else {
            setShowPriceSlider(false);
            //console.log('no');
        }
        //console.log('lpp', lowestPricedProduct.eachPrice);
        //console.log('hpp:', highestPricedProduct.eachPrice);
        setStartSliderAmount(lowestPricedProduct.eachPrice);
        setEndSliderAmount(highestPricedProduct.eachPrice);
        setPreviousSlider1PostionLeftAmount(lowestPricedProduct.eachPrice);
        setPreviousSlider2PostionLeftAmount(highestPricedProduct.eachPrice);
        if(lowestPricedProduct.eachPrice === highestPricedProduct.eachPrice){
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('0%');
        } else {
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('100%');
        }

    }

    function setupPriceSliderVisibility():void {
        if((highestPriceProductAmount.current - lowestPriceProductAmount.current) > 1){
            //console.log('sps:');
            setShowPriceSlider(true);
            
        } else {
            setShowPriceSlider(false);
        }
    }

    //finish this function 5-5-20
    async function priceSliderSearchResults(
                        keywordSearch:string,
                        startSliderAmount:number,
                        endSliderAmount:number,
                        previousSlider1PositionLeft:string,
                        previousSlider2PositionLeft:string,
                        previousSlider1PostionLeftAmount:number,
                        previousSlider2PostionLeftAmount:number,
                        sliderPosition:number,
                        previousSlider1TotalDistance:number,
                        previousSlider2TotalDistance:number,
                        selectedSlider1ZIndex:number,
                        selectedSlider2ZIndex:number,
                        sortByOptionValue:string
                        ):Promise<void> {
            //console.log('keywordSearch:', keywordSearch);
            //console.log('ssa:', startSliderAmount);
            //console.log('esa:', endSliderAmount);
            //console.log('previousSlider1PostionLeftAmount:', previousSlider1PostionLeftAmount);
            //console.log('previousSlider2PostionLeftAmount:', previousSlider2PostionLeftAmount);
            //console.log('priceRangeSearch:', priceRangeSearch);
            //console.log('psov:', sortByOptionValue);
            
            /*uncomment after testing
            setProductMatch(false);

            setShowRelatedProducts(false);
            setShowSearchFilters(false);
            setShowPriceSlider(false);
            
            */

            setProductMatch(false);

            setShowRelatedProducts(false);
            //setShowSearchFilters(false);
            setShowPriceSlider(false);

            //window.scrollTo(0,0);

            setStartSliderAmount(startSliderAmount);
            setEndSliderAmount(endSliderAmount);

            imageRefs.current.length = 0;
            setImageRefsLoaded(false);
                        
            //console.log('ssa === esa:', previousSlider1PostionLeftAmount === previousSlider2PostionLeftAmount);

            setRelatedProductTotal(0);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100); 

        //original version
        //if(startSliderAmount === endSliderAmount){
        if(previousSlider1PostionLeftAmount === previousSlider2PostionLeftAmount){
            //setKeywordSearchType('noResults');
            //setProducts([]);
            setSlider1ZIndex(selectedSlider1ZIndex);
            setSlider2ZIndex(selectedSlider2ZIndex);

        } 
        //finish this
        //else {
            //setLoadSetupSlider(true);
            //setShowPriceSlider(false);
        
        setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
        setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
        setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
        setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);            

        setSliderPosition(sliderPosition);
        setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
        setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

        const priceRange = {    
            status:'filterProducts',
            startAmount:previousSlider1PostionLeftAmount,
            endAmount:previousSlider2PostionLeftAmount
        };

        if(priceRangeSearch === 'relatedProducts'){
            //console.log('sfas2:', searchFilterAreas, searchFilterAreaSelected, sfasRef.current[0]);
            const sfat = sfasRef.current[0].searchFilterAreaType;
            const sfan = sfasRef.current[0].searchFilterAreaName;

            try {
                const status = await resetRelatedSearchFiltersSearchTermsFalse();
                if(status !== 'success'){
                    throw new Error('error trying to reset search terms and passwords.');
                }

            } catch (err:any){
                console.error('there was an error processing the response from reseting search filters and terms:', err.message);
            }
            

            //original version
            //fetch(`http://localhost:9500/related-products-price-range-search/${keywordSearch}/${startSliderAmount.toString()}/${endSliderAmount.toString()}`,{
            //last original version
            //fetch(`http://localhost:9500/related-products-price-range-search/${keywordSearch}/${previousSlider1PostionLeftAmount.toString()}/${previousSlider2PostionLeftAmount.toString()}`,{
            fetch(`https://server.kando-proto-3.com/related-products-search-mariadb/${sfat}/${sfan}`, {            
                //method:'GET',
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:JSON.stringify({priceRange, keywordSearch})
            })
            .then(res=>{
                setProducts([]);
                return res.json();
            })
            .then((keywordSearchResult/*:KeywordSearchResult*/)=>{
                //console.log('price range products:', keywordSearchResult);
                //original version
                //if(keywordSearchResult.relatedProducts.length === 0){
                if(keywordSearchResult.products.length === 0){
                    //console.log('test 5-6');
                    //original version
                    //setKeywordSearchType('noResults');
                    //setKeywordSearchType(keywordSearchResult.keywordSearchType);
                    setKeywordSearchType('noPricingResults');
                    setRelatedProductTotal(0);
                    setShowRelatedProducts(true);
                    setShowSearchFilters(true);

                    setShowPriceSlider(true);

                } else {
                    //console.log('test passed');
                    const rps = addProducts(keywordSearchResult.products);
                    //original version
                    //setOptionValue(sortByOptionValue, addProducts(keywordSearchResult.relatedProducts), false);
                    const hsovps = helperSetOptionValueNow(sortByOptionValue, rps);
                    //console.log('hsovps:', hsovps);
                    setProducts(hsovps);
                    getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, hsovps);

                    setRelatedProductTotal(keywordSearchResult.products.length);
                    //original version
                    //setProducts([...keywordSearchResult.relatedProducts]);
                    setKeywordSearchType('productCategory');
                    /*
                    addShopLoadDetails!({
                        reload:true,
                        storeDepartmentName:'none',
                        departmentCategoryName:'none'
                    });
                    */
                    getSearchFilters(keywordSearchResult.searchFilters, keywordSearchResult.products);

                    setProductMatch(true);

                    setShowSearchFilters(true);
                    setShowRelatedProducts(true);

                    setupPriceSliderVisibility();

                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);
                    
                    
                }
                

            })
            .catch(err=>console.log('price-range-search error:', err));

        } else if(priceRangeSearch === 'storeDepartment'){
            //console.log('prs:', priceRangeSearch);
            //console.log('sds:', storeDepartmentSearch);
            //console.log('dcs:', departmentCategorySearch);
            fetch(`https://server.kando-proto-3.com/related-store-department-price-range-search/${keywordSearch}/${storeDepartmentSearch}/${previousSlider1PostionLeftAmount.toString()}/${previousSlider2PostionLeftAmount.toString()}`,{
                method:'GET',
                headers:{
                    'Accept':'application/json'
                }
            })
            .then(res=>{
                setProducts([]);
                return res.json()
            })
            .then((keywordSearchResult:KeywordSearchResult)=>{
                //console.log('price range products:', keywordSearchResult.relatedProducts);
                if(keywordSearchResult.relatedProducts.length === 0){
                    //setKeywordSearchType('noResults');
                    setKeywordSearchType('noPricingResults');
                    setShowRelatedProducts(true);
                    setShowSearchFilters(true);
                    //setShowPriceSlider(true);
                } else {
                    //console.log('test passed');
                    const rps = addProducts(keywordSearchResult.relatedProducts);
                    //original version
                    //setOptionValue(sortByOptionValue, addProducts(keywordSearchResult.relatedProducts), false);
                    /*previous version
                    helperSetOptionValue(
                            sortByOptionValue, 
                            rps,
                            false,
                            setProducts, 
                            setShowRelatedProducts
                            );
                            */
                    const hsovps = helperSetOptionValueNow(sortByOptionValue, rps);

                    setProducts(hsovps);

                    getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, hsovps);

                    //original version
                    //setProducts([...keywordSearchResult.relatedProducts]);
                    setKeywordSearchType('productCategory');
                    /*
                    addShopLoadDetails!({
                        reload:true,
                        storeDepartmentName:'none',
                        departmentCategoryName:'none'
                    });
                    */
                    setShowRelatedProducts(true);
                    setShowSearchFilters(true);

                    
                }
                

            })
            .catch((err)=>console.log('price-range-search error:', err));

        } else if(priceRangeSearch === 'departmentCategory'){

            fetch(`https://server.kando-proto-3.com/related-department-category-price-range-search/${keywordSearch}/${storeDepartmentSearch}/${departmentCategorySearch}/${previousSlider1PostionLeftAmount.toString()}/${previousSlider2PostionLeftAmount.toString()}`,{
                method:'GET',
                headers:{
                    'Accept':'application/json'
                }
            })
            .then(res=>{
                setProducts([]);
                return res.json()
            })
            .then((keywordSearchResult:KeywordSearchResult)=>{
                //console.log('price range products:', keywordSearchResult.relatedProducts);
                if(keywordSearchResult.relatedProducts.length === 0){
                    //setKeywordSearchType('noResults');
                    setKeywordSearchType('noPricingResults');
                    setShowRelatedProducts(true);
                    setShowSearchFilters(true);
                    //setShowPriceSlider(true);
                } else {
                    //console.log('test passed');
                    const rps = addProducts(keywordSearchResult.relatedProducts);
                    //original version
                    //setOptionValue(sortByOptionValue, addProducts(keywordSearchResult.relatedProducts), false);
                    /*previous version
                    helperSetOptionValue(
                            sortByOptionValue, 
                            rps,
                            false,
                            setProducts, 
                            setShowRelatedProducts
                            );
                            */

                    const hsovps = helperSetOptionValueNow(sortByOptionValue, rps);
                    
                    setProducts(hsovps);

                    getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, hsovps);
                    //original version
                    //setProducts([...keywordSearchResult.relatedProducts]);
                    setKeywordSearchType('productCategory');
                    /*
                    addShopLoadDetails!({
                        reload:true,
                        storeDepartmentName:'none',
                        departmentCategoryName:'none'
                    });
                    */
                    setShowRelatedProducts(true);
                    setShowSearchFilters(true);
                    
                }
                

            })
            .catch((err)=>console.log('price-range-search error:', err));

        }
        

    }


    function getSearchFilters(sfs:SearchFilter[], relatedProducts:Product[]):void {
        //console.log('sfs:', sfs);
        //console.log('rps:', relatedProducts);
        let searchFilters:SearchFilter[] = [];
        sfs.forEach((searchFilter)=>{
                //console.log('sfn:', searchFilter.filterName);
                if(searchFilter.showFilter){
                    searchFilters.push(searchFilter);
                }
            });

        //console.log('sfs:', searchFilters);
        const searchFiltersBeforeUpdate = cloneDeep(searchFilters);

        let departmentProducts:Product[] = [];
                relatedProducts.forEach((product)=>{
                    departmentProducts.push(product);
                    product.searchFilterTerms.forEach((searchFilterTerm)=>{
                        //console.log('sft:', searchFilterTerm);
                        searchFilters.forEach((searchFilter, index)=>{
                            //console.log('fn:', searchFilter.filterName);
                            //console.log('sftn:', searchFilterTerm.searchFilterTermName);
                            if(searchFilter.filterName === searchFilterTerm.searchFilterTermName){
                                
                                //console.log('pass:', searchFilter.filterName);
                                searchFilter.searchTerms.forEach((searchTerm, index)=>{
                                    //console.log('fn:', searchFilter.filterName);
                                    if(searchTerm.searchTermName === searchFilterTerm.searchFilterTermValue){
                                        searchTerm.searchTermQty += 1;
                                    }
                                    
                                });
                                
                            }

                        });
                        //console.log('sfs:', searchFilters);
                        
                    })
                    
                });


        //console.log('dps:', departmentProducts);
        //console.log('sfs2:', searchFiltersBeforeUpdate);

        if(checkSearchFiltersSelected(searchFiltersBeforeUpdate)){
            //console.log('sfs2:', true);
            setInitialSearchFilters([...searchFilters]);
            const [updatedSearchFilters, productsSelected] = updateSearchFilters2(departmentProducts, searchFiltersBeforeUpdate, searchFilters);
            //console.log('usfs:', updatedSearchFilters);
            //console.log('ps:', productsSelected);
    
            if(productsSelected.length === 0){
                //console.log('test');
                setKeywordSearchType('noResults');
                setSearchTermIsSelected(true);
            } else {
                setKeywordSearchType('results');
            }
            
            setSearchFilters([...updatedSearchFilters]);
            setProducts(productsSelected);
            //console.log('p.length:', productsSelected.length);
            setRelatedProductTotal(productsSelected.length);
            setShowSpecialProductCategoryShortcut(false);

            //return true;
            
        } else {

            const fsfs = removeZeroQtySearchTerms(searchFilters);

            const ufsfs = removeZeroQtySearchFilters(fsfs);

            //console.log('sfs2:', ufsfs);
            setInitialSearchFilters([...ufsfs]);
            setSearchFilters([...ufsfs]);
            setShowSpecialProductCategoryShortcut(true);
        

        }
        
        
    }

    //add additional search filters here
    function removeZeroQtySearchTerms(sfs:SearchFilter[]):SearchFilter[] {
        sfs.forEach((sf)=>{
            const sts = sf.searchTerms.filter((st)=>{
                            if(st.searchTermQty === 0){
                                //console.log('stn:', st.searchTermName, 'stQty:', st.searchTermQty);
                            }
                            return st.searchTermQty !== 0;
                        });

                sf.searchTerms = sts;
            });

        //console.log('usfs 5-3:', sfs);
        return sfs;
    }

    function removeZeroQtySearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        const fsfs = sfs.filter((sf)=>{
                        return sf.searchTerms.length > 0;
                    });
        //console.log('fsfs:', fsfs);
        return fsfs;
    }

    function setupSearchFilterSidebar(searchFilterAreas:SearchFilterArea[]):JSX.Element {
        //console.log('sfas2-10:', searchFilterAreas);

        let sfaDepartmentId:number = 0;
        let searchFilterAreaId:number = 0;
        let searchFilterAreaName:string = '';
        let searchFilterAreaType:string = '';

        if(searchFilterAreas.length === 1 && searchFilterAreas[0].searchFilterAreaType === searchFilterAreaSelected.searchFilterAreaType){
            const sfa = searchFilterAreas[0];
            sfaDepartmentId = sfa.departmentId;
            searchFilterAreaId = sfa.searchFilterAreaId;
            searchFilterAreaName = sfa.searchFilterAreaName;
            searchFilterAreaType = sfa.searchFilterAreaType;

        } else if(searchFilterAreas.length === 1 && searchFilterAreas[0].searchFilterAreaType !== searchFilterAreaSelected.searchFilterAreaType){
            //this condition is for selecting a product category
            sfaDepartmentId = searchFilterAreaSelected.departmentId;
            searchFilterAreaId = searchFilterAreaSelected.searchFilterAreaId;
            searchFilterAreaName = searchFilterAreaSelected.searchFilterAreaName;
            searchFilterAreaType = searchFilterAreaSelected.searchFilterAreaType;

        } else {
            sfaDepartmentId = 0;
            searchFilterAreaId = 0;
            searchFilterAreaName = 'none';
            searchFilterAreaType = 'Department';
        }

        return <SearchFilterSidebar 
                    searchFilters={searchFilters} initialSearchFilters={initialSearchFilters} 
                    searchTermIsSelected={searchTermIsSelected} storeDepartmentId={sfaDepartmentId.toString()}
                    otherStoreAreaId={searchFilterAreaId.toString()} storeAreaName={searchFilterAreaName}
                    storeAreaType={searchFilterAreaType}
                    setShowDepartmentProducts={setShowRelatedProducts}
                    clearAllExistingImages={clearAllExistingImages}
                    setSearchFilters={setSearchFilters} 
                    /*setDepartmentCategoryProducts={setProducts}*/
                    setDepartmentCategoryProducts={updateRelatedProducts}
                    setDepartmentCategoryProductTotal={setRelatedProductTotal} 
                    setShowSpecialProductCategoryShortcut={setShowSpecialProductCategoryShortcut}
                    setSearchTermIsSelected={setSearchTermIsSelected} setKeywordSearchType={setKeywordSearchType} 
                    addSearchFilters={addSearchFilters} keywordSearch={keywordSearch}
                    startAmount={previousSlider1PostionLeftAmount}
                    endAmount={previousSlider2PostionLeftAmount}
                    setProductMatch={setProductMatch}
                    setShowRelatedProducts={setShowRelatedProducts}
                    setRelatedProductTotal={setRelatedProductTotal}
                />

    }


    function sortByOptionSelected(e:React.ChangeEvent, products:Product[]):void {
        //console.log('sortByOptionSelection:', (e.target as HTMLSelectElement).value);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        setProductMatch(false);

        setShowRelatedProducts(false);
        //window.scrollTo(0,0);
        setSortByOptionValue((e.target as HTMLSelectElement).value);
        const selectedOption:string =  (e.target as HTMLSelectElement).value;

        sortByBtnRefs.current.forEach((sbbr)=>{
            //console.log('sbbr.textContent:', sbbr.textContent, 'so:', selectedOption);
            if(sbbr.textContent === selectedOption){
                sbbr.classList.add('highlight-sort-by-list-item-btn');

            } else {
                sbbr.classList.remove('highlight-sort-by-list-item-btn');
            }
        });
        //original version
        //setOptionValue(selectedOption, products, true);
        helperSetOptionValue(
                    selectedOption,
                    products, 
                    true,
                    setProducts, 
                    setShowRelatedProducts,
                    setProductMatch
                    );
        
        //console.log('p27:', products);

        getAnyItemList(listChangerBtnPosition, products, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, products);


    }

    function getSortByBtnRef(el:HTMLButtonElement|null):void {
        //console.log('el:');
        if(el !== null && sortByBtnRefs.current.length === 0){
            sortByBtnRefs.current.push(el);

        } else if(el !== null) {
            const found = sortByBtnRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                sortByBtnRefs.current.push(el);
            }

        }

        //console.log('sbbrs:', sortByBtnRefs.current);
       
        
    }


    function sortByOptionClicked(e:React.MouseEvent, optionClicked:string, products:Product[]):void {
        //console.log('sortByOptionSelection:', optionClicked);
        const btnElement = e.target as HTMLButtonElement;;
        //add this class to the target element
        //remove this class from all other list items
        sortByBtnRefs.current.forEach((sbbr)=>{
                                    if(sbbr === btnElement){
                                        btnElement.classList.add('highlight-sort-by-list-item-btn');

                                    } else {
                                        sbbr.classList.remove('highlight-sort-by-list-item-btn');
                                    }
                                });
                                

        e.stopPropagation();

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        setShowRelatedProducts(false);
        //window.scrollTo(0,0);
        setSortByOptionValue(optionClicked);
        const selectedOption:string =  optionClicked;
        //original version
        //setOptionValue(selectedOption, products, true);
        /*previous version
        helperSetOptionValue(
                    selectedOption,
                    products, 
                    true,
                    //'none',
                    //'none',
                    setProducts, 
                    setShowRelatedProducts
                    );
                    */
        const hsovps = helperSetOptionValueNow(selectedOption, products);
        setProducts(hsovps);
        
        getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, hsovps);
        setShowRelatedProducts(true);

    }

    function productMobileNavHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        mobileNavOpen.current = true;
        shopProductsMobileNavRef.current!.style.zIndex = '7';
        shopProductsMobileNavRef.current!.style.visibility = 'visible';
        shopProductsMobileNavRef.current!.style.transform = 'translateY(0%)';

    }

    function shopProductsMobileNavCloseBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        mobileNavOpen.current = false;
        shopProductsMobileNavRef.current!.style.visibility = '';
        shopProductsMobileNavRef.current!.style.transform = '';
        shopProductsMobileNavRef.current!.style.zIndex = '';
    }

    
    function setupFilteredStoreDepartmentCategoriesSidebar(searchFilterAreaChildren:SearchFilterArea[]):JSX.Element {
        //console.log('sfac:', searchFilterAreaChildren);
        return <ul className="related-products-store-department-category-filter-list">
                    {
                        searchFilterAreaChildren.map((searchFilterAreaChild, index)=>{
                            return <li key={index}
                                        className="related-products-store-department-category-filter-list-item"
                                        onClick={(e:React.MouseEvent)=>{

                                           //console.log('sfacn:', searchFilterAreaChild.searchFilterAreaName, 'psfacn', prevSearchFilterAreaChildSelected);

                                           if(searchFilterAreaChild.searchFilterAreaName === prevSearchFilterAreaChildSelected){
                                                e.preventDefault();
                                                e.stopPropagation();
                                                return;
                                           }


                                           setPrevSearchFilterAreaChildSelected(searchFilterAreaChild.searchFilterAreaName);


                                            setProductMatch(false);
                                            setRelatedProductTotal(0);

                                            helperHighlightSearchFilterArea(e, searchFilterAreaChild, prevSearchFilterAreaSelected)
                                            .then((response)=>response.json())
                                            .then(({status})=>{
                                                //console.log('status:', status);
                                                if(status === 'highlight change'){
                                                    //console.log('status:', status);
                                                    //original version
                                                    //getRelatedStoreDepartmentProducts(filteredStoreDepartment.departmentName, productKeywordSearch.keywordSearch);

                                                    if(window.innerWidth >= 1024){
                                                        setPrevSearchFilterAreaSelected(searchFilterAreaChild);
                                                        getRelatedProducts(searchFilterAreaChild, false);
                                                        setShowRelatedProducts(false);
                                                        setShowSearchFilters(false);
                                                        setShowPriceSlider(false);

                                                    } else {
                                                        getRelatedProducts(searchFilterAreaChild, false);
                                                    }


                                                    let root = document.documentElement;
                                                    root!.setAttribute('style', 'scroll-behavior: auto');
                                                    root.scrollTo(0,0);
                                                    window.setTimeout(()=>{
                                                        root!.removeAttribute('style');
                                                    },100);

                                                } else {
                                                    //console.log('status:', status);
                                                    //getRelatedProducts(searchFilterAreaChild);
                                                    
                                                    if(window.innerWidth >= 1024){
                                                        setPrevSearchFilterAreaSelected(searchFilterAreaChild);
                                                        getRelatedProducts(searchFilterAreaChild, false);
                                                        setShowRelatedProducts(false);
                                                        setShowSearchFilters(false);
                                                        setShowPriceSlider(false);

                                                    } else {
                                                        getRelatedProducts(searchFilterAreaChild, false);
                                                    }
                                                    

                                                    let root = document.documentElement;
                                                    root!.setAttribute('style', 'scroll-behavior: auto');
                                                    root.scrollTo(0,0);
                                                    window.setTimeout(()=>{
                                                        root!.removeAttribute('style');
                                                    },100);
                                                }
                                            })
                                            .catch(err=>console.log('error:', err)); 
    
                                            
                                            }}>
                                        {/*storeDepartmentCategory.departmentCategoryName*/}
                                        <HighlightSearchFilterAreaText searchFilterArea={searchFilterAreaChild} searchFilterAreaSelected={searchFilterAreaSelected}/>
                                    </li>
                        })
                    }
                </ul>
    }
    

    function setupFilteredStoreDepartmentNamesSidebar(searchFilterAreas:SearchFilterArea[]):JSX.Element{
        //console.log('sfas14:', searchFilterAreas, 'pfas:', prevSearchFilterAreaSelected);

        return <ul className="related-products-store-department-filter-list">
                    <li className="related-products-store-department-filter-list-item" key={0}>Category</li>
                    {
                    searchFilterAreas.map((searchFilterArea, index)=>{
                        return <li className="related-products-store-department-filter-list-item" key={index + 1}
                                    onClick={(e:React.MouseEvent)=>{

                                        //console.log('sfa13:', searchFilterArea, 'psfa13:', prevSearchFilterAreaSelected);
                                        const sfan = searchFilterArea.searchFilterAreaName;
                                        const psfan = prevSearchFilterAreaSelected.searchFilterAreaName;

                                        if(sfan === psfan){
                                            e.preventDefault();
                                            return;
                                        }

                                        setPrevSearchFilterAreaChildSelected('none');

                                        setProductMatch(false);
                                        setRelatedProductTotal(0);
                                        
                                        helperHighlightSearchFilterArea(e, searchFilterArea, prevSearchFilterAreaSelected)
                                        .then((response)=>{
                                            return response.json();
                                        })
                                        .then(({status})=>{
                                            //console.log('status:', status);
                                            if(status === 'highlight change'){
                                                //console.log('status:', status);
                                                //original version
                                                //getRelatedStoreDepartmentProducts(filteredStoreDepartment.departmentName, productKeywordSearch.keywordSearch);

                                                if(window.innerWidth >= 1024){
                                                    setPrevSearchFilterAreaSelected(searchFilterArea);
                                                    getRelatedProducts(searchFilterArea, false);
                                                    setShowRelatedProducts(false);
                                                    setShowSearchFilters(false);
                                                    setShowPriceSlider(false);

                                                } else {
                                                    getRelatedProducts(searchFilterArea, false);
                                                }
                                                

                                                let root = document.documentElement;
                                                root!.setAttribute('style', 'scroll-behavior: auto');
                                                root.scrollTo(0,0);
                                                window.setTimeout(()=>{
                                                    root!.removeAttribute('style');
                                                },100);

                                            } else {
                                                //console.log('status:', status);
                                                //getRelatedProducts(searchFilterArea);
                                                
                                                if(window.innerWidth  >= 1024){
                                                    setPrevSearchFilterAreaSelected(searchFilterArea);
                                                    getRelatedProducts(searchFilterArea, false);
                                                    setShowRelatedProducts(false);
                                                    setShowSearchFilters(false);
                                                    setShowPriceSlider(false);

                                                } else {
                                                    getRelatedProducts(searchFilterArea, false);
                                                }
                                                

                                                let root = document.documentElement;
                                                root!.setAttribute('style', 'scroll-behavior: auto');
                                                root.scrollTo(0,0);
                                                window.setTimeout(()=>{
                                                    root!.removeAttribute('style');
                                                },100);
                                            }
                                        })
                                        .catch(err=>console.log('error:', err)); 

                                        
                                        }}>
                                    {/*filteredStoreDepartment.departmentName*/}
                                    <HighlightSearchFilterAreaText searchFilterArea={searchFilterArea} searchFilterAreaSelected={searchFilterAreaSelected}/>
                                    {searchFilterArea.searchFilterAreaChildren.length > 0 ?
                                        setupFilteredStoreDepartmentCategoriesSidebar(searchFilterArea.searchFilterAreaChildren)
                                        : null
                                    }
                                </li>;
                    })
                    }
                </ul>
    }

    function setupStoreDepartmentNamesList(storeDepartments:StoreDepartment[]/*storeDepartmentNames:string[]*/):JSX.Element {
        return <ul className="related-products-store-department-browse-list">
                    {
                        storeDepartments.map((storeDepartment, index)=>{
                            return <li key={index} className="related-products-store-department-browse-list-item">
                                        <Link to={`/shop/${storeDepartment.departmentName}/${storeDepartment.departmentId}`}
                                         className="related-products-store-department-browse-list-item-link"
                                            onClick={(e:React.MouseEvent)=>loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(storeDepartment.departmentName))}/${storeDepartment.departmentId}`, navigate)}>
                                             {storeDepartment.departmentName}
                                        </Link>
                                    </li>
                        })
                    }
                </ul>
    }


    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('render2:', itemsList);
        setItemsList(itemsList as Product[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);

        //window.scrollTo(0,0);
        /*need to add code with more search results
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);
        */   

    }

    function displayListSorter(itemsPerList:number, ps:Product[]):void {
        if(ps.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }


    function updateRelatedProducts(ps:Product[]):void {
        //setProducts([...ps]);

        /*previous version
        helperSetOptionValue(
            sortByOptionValue, 
            addProducts(ps), 
            false,
            setProducts, 
            setShowRelatedProducts
            );
            */

            /*
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);
            */

        const rps = addProducts(ps);

        const hsovps = helperSetOptionValueNow(sortByOptionValue, rps);
        setProducts(hsovps);
        //setHasUpdatedRelatedProducts(!hasUpdatedRelatedProducts);
        getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, hsovps);

        setProductMatch(true);
        setShowRelatedProducts(true);


        
    }


    function scrollToTopCallback():void {
        const root = document.documentElement;
        root.scrollIntoView({behavior:'smooth'});
    }


    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowSearchFilterErrorMsg(false);
    }

    function getSearchFilterValidity(sfv:SearchFilterValidity):void {
        setSearchFilterErrorMsg(sfv);
        setShowSearchFilterErrorMsg(true);
        //setShopByAreaTypeProducts([]);
        //setShowShopByAreaTypeProducts(false);
        //setShowShopByAreaTypeFilter(false);
    }



    //console.log('srp:', showRelatedProducts, 'rpt:', relatedProductTotal, 'kst:', keywordSearchType, 'ks:', keywordSearch);

    function setupRelatedProductsSearchContainer(showRelatedProducts:boolean, relatedProductTotal:number, keywordSearchType:string):JSX.Element | null {
        if(showRelatedProducts && relatedProductTotal > 0 
            && (keywordSearchType === 'productCategory' || keywordSearchType === 'productName')){
             //console.log('render2:', products, 'itemList:', itemsList);
            return (<div className="related-product-search-container">
                        <div className="related-product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{relatedProductTotal > 0 ? `${(relatedProductTotal === 1) ? relatedProductTotal + ' result' : relatedProductTotal + ' results'}` : ''}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <div className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>sortByOptionSelected(e, products)}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price High to Low</option>
                                        <option value="Price Low to High">Price Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        {
                            fetchErrorThrown
                            ?
                            <GenericErrorMessage/>
                            :
                            null
                        }

                        {
                            //products.length > 0
                            productMatch
                            ? 
                            <ListViewer itemsList={itemsList} items={products} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupProducts} />
                            :
                            null
                        }

                        {
                            !productMatch && !fetchErrorThrown
                            ? 
                            <div className="rp-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null
                        }


                    </div>);
                        

        } else if(showRelatedProducts && relatedProductTotal === 0 && keywordSearchType === 'noPricingResults'){
                //console.log('render5');
            return (<div className="related-product-search-container">
                        <div className="related-product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{relatedProductTotal > 0 ? `${relatedProductTotal} results` : '0 results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <fieldset className="sort-by-form-group">
                                <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>sortByOptionSelected(e, products)}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price High to Low</option>
                                        <option value="Price Low to High">Price Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                    </select>
                                </fieldset>
                            </form>
                        </div>
                        <div className="shop-product-category-products-error-message-container">
                            <div className="shop-product-category-products-error-message">
                                <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                <div>There are no products that match your search criteria. Please modify your criteria and try again.</div>
                            </div>
                        </div>
                        
                    </div>);
                        

        } else {
            //console.log('render4');
            return (<div className="related-product-search-container">
                        <div className="related-product-category-sort-by-heading">
                            {/*<span className="sort-by-results-heading">{relatedProductTotal > 0 ? `${relatedProductTotal} results` : ''}</span>*/}
                            <span className="sort-by-results-heading">{relatedProductTotal > 0 ? `${(relatedProductTotal === 1) ? relatedProductTotal + ' result' : relatedProductTotal + ' results'}` : ''}</span>
                            {/*<span className="sort-by-results-heading">{relatedProductTotal} results</span>*/}
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <fieldset className="sort-by-form-group">
                                <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>sortByOptionSelected(e, products)}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price High to Low</option>
                                        <option value="Price Low to High">Price Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                    </select>
                                </fieldset>
                            </form>
                        </div>
                        {
                            fetchErrorThrown
                            ?
                            <GenericErrorMessage/>
                            :
                            null
                        }

                        {
                            !productMatch && !fetchErrorThrown
                            ? 
                            <div className="rp-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null
                        }






                    </div>);
        }
    }


    
    if(showFillerContent){
            return <div className="related-products-layout-wrapper">
                        <ProductSearch/>
                        <MainNavbar2/>
                        <MenuListMobileNav/>
                        <div style={{padding:'0 16px 0'}}>
                            <div style={{padding:'108px 8px 8px'}} className="related-product-category-sort-by-heading">
                                {/*<span className="sort-by-results-heading">{relatedProductTotal > 0 ? `${relatedProductTotal} results` : ''}</span>*/}
                                <span className="sort-by-results-heading">{relatedProductTotal > 0 ? `${(relatedProductTotal === 1) ? relatedProductTotal + ' result' : relatedProductTotal + ' results'}` : ''}</span>
                                <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                    <span className="sort-by-form-btn-text">
                                        {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                        <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                        Filter
                                    </span>
                                </button>
                                <form className="sort-by-form">
                                    <fieldset className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>sortByOptionSelected(e, products)}>
                                            <option value="Best Match">Best Match</option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                            <option value="Price High to Low">Price High to Low</option>
                                            <option value="Price Low to High">Price Low to High</option>
                                            <option value="Ratings">Ratings</option>
                                            <option value="Newest">Newest</option>
                                        </select>
                                    </fieldset>
                                </form>
                            </div>

                            {
                                fetchErrorThrown
                                ?
                                <GenericErrorMessage/>
                                :
                                null
                            }

                            {
                                isLoading
                                ?
                                <div className="rp-loader-animation-container">
                                    <LoaderAnimation/>
                                </div>
                                :
                                <div className="rp-loader-animation-container">
                                    
                                </div>
                            }
                        </div>

                        <Footer/>

                </div>
    } else 

    //original version
    //if(keywordSearch !== props.keywordSearch){
    if(showRelatedProducts && /*products.length*/ relatedProductTotal === 0 && keywordSearchType === 'noResults'){
        //console.log('render3', showRelatedProducts, relatedProductTotal, keywordSearchType);
        return <div className="related-products-layout-wrapper">
                    <ProductSearch/>
                    <MainNavbar2/>
                    <MenuListMobileNav/>
                    <div ref={getRelatedProductsFooterContainerRef} className="related-products-footer-container">
                        <div className="related-products-no-results-msg-footer-wrapper">
                            <div className="related-products-no-results-msg-footer-container">
                                <h1 className="related-products-no-results-msg-heading">No results found for "{kwSearchRestrictedChars.length > 0 ? kwSearchRestrictedChars : keywordSearch}"</h1>
                                <div className="related-products-no-results-suggestion-container">
                                    <div className="rps-no-results-suggestion">Suggestions:</div>
                                    <ul className="rps-no-results-suggestion-list">
                                        <li className="rps-no-results-suggestion-list-item">Use fewer or more general keywords</li>
                                        <li className="rps-no-results-suggestion-list-item">Search for the brand name or product category</li>
                                        <li className="rps-no-results-suggestion-list-item">Check for typos or spelling mistakes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="related-products-browse-by-category-footer-wrapper">
                            <div className="related-products-browse-by-category-footer-container">
                                <h1 className="related-products-browse-by-category-heading">Browse by category</h1>
                                {setupStoreDepartmentNamesList(storeDepartments)}
                            </div>
                        </div>
                    </div>

                    <Footer/>

                    {
                        showMiniCart
                        ?
                        <ShopProductMiniCart/>
                        : null
                    }
                </div>

    } else {
        //console.log('render last:', showRelatedProducts, relatedProductTotal, keywordSearchType, keywordSearch);
        return (<div className="related-products-layout-wrapper">
                    <ProductSearch/>
                    <MainNavbar2/>
                    <MenuListMobileNav/>
                    <div ref={getRelatedProductsContainerRef} className="shop-products-container-wrapper">
                        <div className="related-products-search-keyword-heading-wrapper">
                            {
                                (productMatch || keywordSearchType === 'noPricingResults')
                                ?
                                <div className="related-products-search-keyword-heading-container">
                                    <h1 className="related-products-search-keyword-heading">"{keywordSearch}"</h1>
                                    {
                                        productMatch && kwSearchRestrictedChars.length > 0
                                        ?
                                        <div className="related-products-restricted-keyword-heading">{`0 results for ${kwSearchRestrictedChars}`}</div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                <h1 className="related-products-search-keyword-heading"></h1>
                            }
                            
                        </div>


                        <div className="shop-product-category-wrapper products-shape">
                            <div ref={shopProductsMobileNavRef} className="shop-products-mobile-nav">
                                <div className="shop-products-mobile-options-container">
                                    <button type="button" className="shop-products-mobile-options-clear-btn"
                                        onClick={clearAllSearchFilterTermSelections}>
                                        Clear all
                                    </button>
                                    <span className="shop-products-mobile-options-heading">Filter</span>
                                    <button type="button" className="shop-products-mobile-options-close-btn" onClick={shopProductsMobileNavCloseBtnHandler}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.586 12L4.293 5.707a1 1 0 011.414-1.414L12 10.586l6.293-6.293a1 1 0 111.414 1.414L13.414 12l6.293 6.293a1 1 0 01-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 01-1.414-1.414L10.586 12z" fill="currentColor"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="shop-products-mobile-search-filter-wrapper">
                                    <div className="shop-products-mobile-sort-by-list-container">
                                        <span className="shop-products-mobile-sort-by-list-heading">Sort By</span>
                                        <ul className="shop-products-mobile-sort-by-list">
                                        <li className="shop-products-mobile-sort-by-list-item">
                                            <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button highlight-sort-by-list-item-btn"
                                                onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                                'Best Match',
                                                                                                products
                                                                                                )}>
                                            Best Match</button>
                                        </li>
                                        <li className="shop-products-mobile-sort-by-list-item">
                                            <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                                onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                                'A-Z',
                                                                                                products
                                                                                                )}>
                                            A-Z</button>
                                        </li>
                                        <li className="shop-products-mobile-sort-by-list-item">
                                            <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                                onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                                'Z-A',
                                                                                                products
                                                                                                )}>
                                            Z-A</button>
                                        </li>
                                        <li className="shop-products-mobile-sort-by-list-item">
                                            <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                                onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                                'Price High to Low',
                                                                                                products
                                                                                                )}>
                                            Price High to Low</button>
                                        </li>
                                        <li className="shop-products-mobile-sort-by-list-item">
                                            <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                                onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                                'Price Low to High',
                                                                                                products
                                                                                                )}>
                                            Price Low to High</button>
                                        </li>
                                        <li className="shop-products-mobile-sort-by-list-item">
                                            <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                                onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                                'Ratings',
                                                                                                products
                                                                                                )}>
                                            Ratings</button>
                                        </li>
                                        <li className="shop-products-mobile-sort-by-list-item">
                                            <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                                onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                                'Newest',
                                                                                                products
                                                                                                )}>
                                            Newest</button>
                                        </li>
                                        </ul>

                                    </div>

                                    <div className="shop-products-mobile-search-filter-container">

                                        <div className="related-products-category-filter-container">
                                            <div className="related-product-category-filter-heading">
                                                Filter by
                                            </div>           
                                            {/*keywordSearchType === 'productCategory' ?*/ /*setupFilteredStoreDepartmentNamesSidebar(searchFilterAreas)*/ /*: null*/}
                                            {(showSearchFilters || showSidebar) ? setupFilteredStoreDepartmentNamesSidebar(searchFilterAreas) : null}

                                            {
                                                (showSearchFilters && keywordSearchType !== 'noPricingResults')
                                                ?
                                                setupSearchFilterSidebar(searchFilterAreas)
                                                :null
                                            }

                                            
                                            {
                                                showPriceSlider
                                                ? 
                                                
                                                    <PriceSlider3 
                                                    keywordSearch={keywordSearch!}
                                                    startSliderAmount={startSliderAmount} 
                                                    endSliderAmount={endSliderAmount}
                                                    previousSlider1PositionLeft={previousSlider1PositionLeft}
                                                    previousSlider2PositionLeft={previousSlider2PositionLeft}
                                                    previousSlider1PostionLeftAmount={previousSlider1PostionLeftAmount}
                                                    previousSlider2PostionLeftAmount={previousSlider2PostionLeftAmount}
                                                    priceSliderSearchResults={priceSliderSearchResults}
                                                    sliderPosition={sliderPosition}
                                                    previousSlider1TotalDistance={previousSlider1TotalDistance}
                                                    previousSlider2TotalDistance={previousSlider2TotalDistance}
                                                    slider1ZIndex={slider1ZIndex}
                                                    slider2ZIndex={slider2ZIndex}
                                                    sortByOptionValue={sortByOptionValue}
                                                    />
                                                
                                                : null
                                            }

                                            {/*
                                                !showSearchFilters && showMobileAnimationLoader
                                                ? 
                                                <div className="rp-loader-animation-container">
                                                    <LoaderAnimation/>
                                                </div>
                                                :
                                                null
                                            */}
                                            

                                        </div>

                                    </div>
                                </div>


                                <div className="shop-products-mobile-item-count-container">
                                    <button type="button" className="shop-products-mobile-view-items-btn"
                                        onClick={shopProductsMobileNavCloseBtnHandler}>{`${(relatedProductTotal > 0) ? (relatedProductTotal === 1 ? 'Show ' + relatedProductTotal + ' result' : 'Show ' + relatedProductTotal + ' results') : (window.innerWidth >= 1024) ? '' : 'Processing...'}`}</button>
                                </div>
                            </div>

                            {setupRelatedProductsSearchContainer(showRelatedProducts, relatedProductTotal, keywordSearchType)}

                        </div>

                        {
                            showSearchFilterErrorMsg 
                            ? (<div className="add-product-sub-category-form-info-message-container error-msg-top-adj">
                                <SearchFilterErrorMessage sfvMessage={searchFilterErrorMsg!} 
                                    showCloseBtn={true} showExtraMsgContainer={true} closeInfoMsgHandler={closeInfoMsgHandler}/>
                                </div>)
                            : null
                        }


                        <Footer/>


                        <ScrollToTopButton scrollToTopCallback={scrollToTopCallback} showMiniCart={showMiniCart}/>            

                    </div>
                    {
                        showMiniCart
                        ?
                        <ShopProductMiniCart/>
                        : null
                    }
                    
                </div>
            
        );

    } 

}

export default RelatedProducts2;