import SearchFilter from './searchFilter';
import Product from './product';
import SearchFilterTerm from './searchFilterTerm';

export interface SearchFilterValidity{
    sfat:string;
    valid:boolean;
    reason:string;
    products:Product[];
}

function checkSearchFilterValidity(sfs:SearchFilter[], products:Product[], sfat:string):SearchFilterValidity {  
    //check if each product is attached to at least two search filters containing a minimum of 1 search term each
    //return a list of products that don't pass the above criteria
    const ps = products.filter((p)=>{
                        if(p.searchFilterTerms.length < 2){
                            return true;
                        }

                        let sfts:SearchFilterTerm[] = [];
                        p.searchFilterTerms.forEach((sft)=>{
                            if(/*sft.searchFilterAreaType === sfat &&*/ sfts.length === 0){
                                sfts.push(sft);

                            } else {
                                if(/*sft.searchFilterAreaType === sfat &&*/ !checkSearchFilterTermMatch(sft, sfts)){
                                    sfts.push(sft);
                                }
                            }
                        });

                        if(sfts.length >= 2){
                            return false;

                        } else {
                            return true;
                        }

                    });

    if(ps.length > 0){
        return ({valid:false, sfat, reason:'product not attached to at least two search filters', products:ps});
    }

    return ({valid:true, sfat, reason:'pass', products:ps});

}


function checkSearchFilterTermMatch(sft:SearchFilterTerm, esfts:SearchFilterTerm[]):boolean {
    const matches = esfts.filter((esft)=>{
                        return esft.searchFilterTermName === sft.searchFilterTermName;
                    });

    return matches.length > 0;
}

export default checkSearchFilterValidity;