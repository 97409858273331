import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ShopCategory from '../../../shopCategory';
import cloneDeep from 'lodash/cloneDeep';

interface ProductShopCategories {
    productShopCategories:ShopCategory[];
}


const initialState: ProductShopCategories = {
    productShopCategories:[]
};

//const initialState:ShopCategory[] = [];


export const productShopCategoriesSlice = createSlice({
  name: 'productShopCategories',
  initialState,
  reducers: {
    addProductShopCategories:{
      reducer:(state, action:PayloadAction<ShopCategory[]>) => {
        state.productShopCategories = action.payload;
      },
        prepare:(productShopCategories:ShopCategory[]) => {
          //console.log('pscs:', productShopCategories);
          const clone = productShopCategories.map((psc)=>{
                        psc.subShopCategories = psc.subShopCategories.map((ssc)=>{
                                                    return Object.assign({}, ssc);
                                                });
                          return Object.assign({}, psc);
                        });
                        
          return {  payload: clone };
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const { addProductShopCategories } = productShopCategoriesSlice.actions;

interface RootState {
  //productShopCategories:ShopCategory[];
  productShopCategories:ProductShopCategories;
}

export const selectProductShopCategories = (state:RootState) =>  {
  const clone = cloneDeep(state.productShopCategories);
  return clone;

}

export default productShopCategoriesSlice.reducer;