import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import GenericErrorMessage2 from './GenericErrorMessage2';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import Footer from './Footer';
import './AppErrorMessage.css';

interface Props{}

const AppErrorMessage: React.FunctionComponent<Props> = (props:Props) => {
    return <div className="app-error-msg-container">
                <ProductSearch/>
                <MainNavbar2/>
                <div className="app-error-msg-display-container">
                    <GenericErrorMessage2/>
                </div>
                <Footer/>
            </div>
}

export default AppErrorMessage