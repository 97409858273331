import React from 'react';
import StoreLocation from './storeLocation';
import './StoreSearchMessage.css';

interface Props {
    storeLocationSearch:string;
    storeLocationSearchPhrase:string;
    storeLocationSearchMessage:string;
    //itemsList:StoreLocation[] | null;
    storeLocationList:StoreLocation[];
}

const StoreSearchMessage:React.FunctionComponent<Props> = (props:Props)=>{
    const {storeLocationSearch, storeLocationSearchPhrase, 
        storeLocationSearchMessage, storeLocationList} = props;
        
    //console.log('storeLocationList length 2-19:', storeLocationList.length);
    if(storeLocationSearchMessage === 'completed'){
        return <div className="store-search-message">{`${storeLocationList.length} stores near "${storeLocationSearch}"`}</div>;
    } else {
        return <div className="store-search-message">{`Searching for stores near "${storeLocationSearchPhrase}"`}</div>;
    }
}

export default StoreSearchMessage;