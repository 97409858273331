import React, {useState, useEffect} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import {Link, useNavigate} from 'react-router-dom';
import ProductCategory from './productCategory';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import addDepartmentCategories from './helper-add-department-categories';
import ProductSubCategory from './productSubCategory';
import Product from './product';
import { cloneDeep } from 'lodash';
import formatLinkString from './helper-format-link-string';

interface Props {
    productCategory:ProductCategory;
    storeDepartmentCategory:DepartmentCategory;
    productCatgoryProducts:Product[];
    handleProductSubCategorySearch:(e:React.MouseEvent, productSubCategory:ProductSubCategory)=>void;
    handleProductCategorySearch:(e:React.MouseEvent, productCategory:ProductCategory, pathname:string)=>void;

    /*
    storeDepartmentName?:string;
    departmentCategoryName?:string;
    productCategoryName?:string;
    */
}

const ProductCategorySearchShortcutSelector: React.FunctionComponent<Props> = (props:Props) => {
    const navigate = useNavigate();
    const {handleProductSubCategorySearch, handleProductCategorySearch} = props;
    const [productCategory, setProductCategory] = useState<ProductCategory | null>(props.productCategory);
    const [storeDepartmentCategory, setStoreDepartmentCategory] = useState<DepartmentCategory | null>(props.storeDepartmentCategory);
    const [productCategoryProducts, setProductCategoryProducts] = useState<Product[]>(props.productCatgoryProducts)
    
    useEffect(()=>{
        setStoreDepartmentCategory(props.storeDepartmentCategory);
    },[props.storeDepartmentCategory]);

    useEffect(()=>{
        setProductCategory(props.productCategory);
    },[props.productCategory]);

    useEffect(()=>{
        setProductCategoryProducts(props.productCatgoryProducts);

        addProductSubCategoryProducts(props.productCategory!, props.productCatgoryProducts);

    },[props.productCatgoryProducts]);

    function addProductSubCategoryProducts(pc:ProductCategory, products:Product[]):ProductCategory {
        //console.log('ps7:', products);
        const pcClone = cloneDeep(pc);
        const upscs = pcClone.productSubCategories.map((psc)=>{
                        //remove existing products
                        psc.products.length = 0;

                        //console.log('psc6:', psc);
                        //does the product subcategory have a search filter and search term
                        if(psc.hasMatchingFilterTerm && psc.hasProductsAttached){
                            products.forEach((p)=>{
                                //does the product have the same product subcategory name
                                if(psc.productSubCategoryName === p.productSubCategoryName){
                                    //is the product attached to the same search filter and search term
                                    psc.products.push(p);
                                }
                            });
                        }
                        

                        return psc;
                    });

        //console.log('ps7:', upscs);

        const fpscs = upscs.filter((psc)=>{
                        return psc.products.length > 0;
                    });

        //console.log('ps6:', fpscs);

        pcClone.productSubCategories = fpscs;

        return pcClone;
    }

    function setupProductSubCategorySearchShortcut(pc:ProductCategory, products:Product[]):JSX.Element | null {
        const productCategory = addProductSubCategoryProducts(pc, products);

        //console.log('pc5:', productCategory, productCategory.showProductSubCategoryShortcut, productCategory.productSubCategories.length);
        if(productCategory && productCategory.showProductSubCategoryShortcut && productCategory.productSubCategories.length > 0){
            return <ul className="shop-special-store-department-product-category-search-shortcut">
                        {
                            productCategory.productSubCategories.map((productSubCategory, index)=>{
                                return <li key={index}>
                                            <Link to={`/shop/${formatLinkString(productSubCategory.departmentName)}/${formatLinkString(productSubCategory.departmentCategoryName)}/${formatLinkString(productSubCategory.productCategoryName)}`}
                                                className="shop-special-store-department-product-category-search-shortcut-item-link"
                                                onClick={(e:React.MouseEvent)=>handleProductSubCategorySearch(e, productSubCategory)}>
                                                <div className="shop-special-store-department-product-category-search-shortcut-image-container">
                                                    <img className="shop-special-store-department-product-category-search-shortcut-image"
                                                        alt={productSubCategory.productSubCategoryName}
                                                        src={`https://server.kando-proto-3.com/${productSubCategory.productSubCategoryImage}`}/>
                                                </div>
                                                <div className="shop-special-store-department-product-category-search-shortcut-title">
                                                    {productSubCategory.productSubCategoryName}
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>

        } else {
            return null;
        }
    }

    /*previous version
    function setupProductSubCategorySearchShortcut(productCategory:ProductCategory, products:Product[]):JSX.Element | null {
        console.log('pc5:', productCategory, productCategory.showProductSubCategoryShortcut, productCategory.productSubCategories.length);
        if(productCategory && productCategory.showProductSubCategoryShortcut && productCategory.productSubCategories.length > 0){
            return <ul className="shop-special-store-department-product-category-search-shortcut">
                        {
                            productCategory.productSubCategories.map((productSubCategory, index)=>{
                                return <li key={index}>
                                            <Link to={`/shop/${productSubCategory.departmentName}/${productSubCategory.departmentCategoryName}/${productSubCategory.productCategoryName}`}
                                                className="shop-special-store-department-product-category-search-shortcut-item-link"
                                                onClick={(e:React.MouseEvent)=>handleProductSubCategorySearch(e, productSubCategory)}>
                                                <div className="shop-special-store-department-product-category-search-shortcut-image-container">
                                                    <img className="shop-special-store-department-product-category-search-shortcut-image"
                                                        src={productSubCategory.productSubCategoryImage}/>
                                                </div>
                                                <div className="shop-special-store-department-product-category-search-shortcut-title">
                                                    {productSubCategory.productSubCategoryName}
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>

        } else {
            return null;
        }
    }
    */
    
    function setupProductCategorySearchShortcut(storeDepartmentCategory:DepartmentCategory):JSX.Element | null {
        //console.log('sdc:', storeDepartmentCategory);
        //original version
        //if(storeDepartmentCategory && storeDepartmentCategory.showProductCategoryShortcut){
        if(storeDepartmentCategory /*&& storeDepartmentCategory.showProductCategoryShortcut*/){
            return <ul className="shop-special-store-department-product-category-search-shortcut">
                        {
                            storeDepartmentCategory.productCategories.map((productCategory, index)=>{
                                return <li key={index}>
                                            <Link to={`/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${storeDepartmentCategory.departmentId}/${productCategory.productCategoryId}`}
                                                className="shop-special-store-department-product-category-search-shortcut-item-link"
                                                onClick={(e:React.MouseEvent)=>handleProductCategorySearch(e, productCategory, `/shop/${encodeURIComponent(formatLinkString(productCategory.departmentName))}/${encodeURIComponent(formatLinkString(productCategory.departmentCategoryName))}/${encodeURIComponent(formatLinkString(productCategory.productCategoryName))}/${storeDepartmentCategory.departmentId}/${productCategory.productCategoryId}`)}>
                                                <div className="shop-special-store-department-product-category-search-shortcut-image-container">
                                                    <img className="shop-special-store-department-product-category-search-shortcut-image"
                                                        alt={productCategory.productCategoryName}
                                                        src={`https://server.kando-proto-3.com/${productCategory.productCategoryImage}`}/>
                                                </div>
                                                <div className="shop-special-store-department-product-category-search-shortcut-title">
                                                    {productCategory.productCategoryName}
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>

        } else {
            return null;
        }

    }

    //console.log('pc4:', productCategory, productCategory!.showProductSubCategoryShortcut, productCategory!.showRelatedProductCategoryShortcut);
    
    if(productCategory && (productCategory as ProductCategory).showProductSubCategoryShortcut){
        return setupProductSubCategorySearchShortcut(productCategory as ProductCategory, productCategoryProducts);
    } else if(productCategory && (productCategory as ProductCategory).showRelatedProductCategoryShortcut) {
        return setupProductCategorySearchShortcut(storeDepartmentCategory as DepartmentCategory);
    } else {
        return null;
    }
}

export default ProductCategorySearchShortcutSelector;