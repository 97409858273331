import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
import AddDepartmentCategory from './AddDepartmentCategory';
import DepartmentCategory from './departmentCategory';
import EditDepartmentCategory from './EditDepartmentCategory';
import ShopCategory from './shopCategory';
import SubShopCategory from './subShopCategory';
import {connect} from 'react-redux';
//import {AddProductShopCategories, addProductShopCategories} from './redux/actions';
import {selectProductShopCategories, addProductShopCategories} from './redux/features/productShopCategories/productShopCategoriesSlice';
import {useAppDispatch} from './redux/hooks';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import LoaderAnimation from './LoaderAnimation';
import transformDate from './helper-transform-date';
import FormErrorMessage, {FormError} from './FormErrorMessage';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import loadNavLink from './loadNavLinkHelper';
import GenericErrorMessage from './GenericErrorMessage';
import Footer from './Footer';
import './StoreStructureDepartmentCategories.css';


interface DepartmentCategorySource {
    source:string;
    departmentCategory:DepartmentCategory;
}

interface Props {
    //path:string;
}

const resultsPerPage = ['5', '10', '20', '30'];

const StoreStructureDepartmentCategories2: React.FunctionComponent<Props> = (props:Props) => {
    //const {addProductShopCategories} = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showAddDepartmentCategory, setShowAddDepartmentCategory] = useState(false);
    const [showEditDepartmentCategory, setShowEditDepartmentCategory] = useState(false);
    const [departmentCategories, setDepartmentCategories] = useState<DepartmentCategory[]>([]);
    const [departmentCategoryToEdit, setDepartmentCategoryToEdit] = useState<DepartmentCategory|null>(null);
    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    //search properties
    const [departmentCategoryNameSearchText, setDepartmentCategoryNameSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No department categories have been added.');

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(10);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<DepartmentCategory[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [elementName3, setElementName3] = useState('results-per-page');
    const [resultsPerPageOptionSelected, setResultsPerPageOptionSelected] = useState('10');
    const [resultsPerPageOptions, setResultsPerPageOptions] = useState<string[]>(resultsPerPage);

    const [isLoading, setIsLoading] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const storeStructureDCContainerRefs = useRef<HTMLDivElement[]>([]);
    const adminSearchFormBtnRef = useRef<HTMLButtonElement>(null);

    const dcKeywordRef = useRef<HTMLInputElement>(null);
    const dcKeywordRefs = useRef<HTMLInputElement[]>([]);

    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });

    //const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);

    const sfgErrorMsgRefs = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}[]>([]);

    const existingSearchResultsHeight = useRef<number>(0);

    const [showKeywordSearchLimitError, setShowKeywordSearchLimitError] = useState(false);

    const [temporaryClearSearchResults, setTemporaryClearSearchResults] = useState(false);

    //const formRef = useRef<HTMLFormElement>(null);

    const formRefs = useRef<HTMLFormElement[]>([]);

    const [showFooter, setShowFooter] = useState(false);

    const loaderRefs = useRef<HTMLDivElement[]>([]);

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);
    
    const isLoadingTimeoutRef = useRef<number>(0);

    useEffect(()=>{
        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);
        //window.scrollTo(0,0);
        getAllDepartmentCategories();
    },[]);


    //console.log('m16:');


    useEffect(()=>{

        if(imageRefsLoaded){

            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                //target.src = `http://server.test_app3${src}`;
                target.src = `${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    function getStoreStructureDCContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && storeStructureDCContainerRefs.current.length === 0){
            storeStructureDCContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = storeStructureDCContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                storeStructureDCContainerRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', storeStructureDCContainerRefs.current);
    }


    function getDCKeywordRef(el:HTMLInputElement|null):void {
        if(el !== null){
            dcKeywordRefs.current.length = 0;
        }

        if(el !== null && dcKeywordRefs.current.length === 0){
            dcKeywordRefs.current.push(el);

        } else if(el !== null) {
            const found = dcKeywordRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                dcKeywordRefs.current.push(el);
            }

        }

        //console.log('prkrs:', dcKeywordRefs.current);

        if(window.innerWidth >= 420){
            dcKeywordRefs.current[0]!.placeholder = 'Enter department category or department name';

        } else {
            dcKeywordRefs.current[0]!.placeholder = '';
        }

    }

    useEffect(()=>{
        window.addEventListener('resize', ssdcResizeHandler);
        return cleanupSSDCResizeHandler;
    },[]);

    function ssdcResizeHandler(e:Event):void {
        if(window.innerWidth >= 420){
            dcKeywordRefs.current[0]!.placeholder = 'Enter department category or department name';

        } else {
            dcKeywordRefs.current[0]!.placeholder = '';
        }

        if(typeof sfgErrorMsgRefs.current![0] !== 'undefined'){
            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
        }

    }

    function cleanupSSDCResizeHandler():void {
        window.removeEventListener('resize', ssdcResizeHandler);
    }

    function getFormRef(el:HTMLFormElement|null):void {
        if(formRefs.current.length > 0){
            formRefs.current.length = 0;
        }

        if(el !== null && formRefs.current.length === 0){
            formRefs.current.push(el);

        } else if(el !== null) {
            const found = formRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                formRefs.current.push(el);
            }

        }
    }

    type Ref = {
        sfgemcRef:React.RefObject<HTMLDivElement>
    };

    function getSfgErrorMsgRef(el: Ref|null):void {
        if(sfgErrorMsgRefs.current.length > 0){
            sfgErrorMsgRefs.current.length = 0;
        }

        if(el !== null && sfgErrorMsgRefs.current.length === 0){
            sfgErrorMsgRefs.current.push(el);

        } else if(el !== null) {
            const found = sfgErrorMsgRefs.current.find((ir)=>{
                                return ir.sfgemcRef === el.sfgemcRef;
                            });

            if(typeof found === 'undefined'){
                sfgErrorMsgRefs.current.push(el);
            }

        }

        //console.log('sfgErrorMsgRefs:', sfgErrorMsgRefs.current);
    }

    function getLoaderRef(el: HTMLDivElement|null):void {
        if(loaderRefs.current.length > 0){
            loaderRefs.current.length = 0;
        }

        if(el !== null && loaderRefs.current.length === 0){
            loaderRefs.current.push(el);

        } else if(el !== null) {
            const found = loaderRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                loaderRefs.current.push(el);
            }

        }

        //console.log('loaderRefs:', loaderRefs);

        if(typeof loaderRefs.current[0] !== 'undefined'){
            const yValue = formRefs.current[0].getBoundingClientRect().bottom + 66;
            loaderRefs.current[0].style.transform = `translate(-50%, ${yValue}px)`;
        }


    }

    function getAllDepartmentCategories(lcbPosition?:number):void {
        fetch(`https://server.kando-proto-3.com/get-all-department-categories-mariadb`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then((departmentCategories)=>{
            //console.log('departmentCategories:', departmentCategories);
            //console.log('atob:', productSubCategories[0].source);
            if(departmentCategories.length === 0){
                //show generic info message if no department categories are found
                setNoSearchResultsMessage(`No department categories have been added`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoading(false);

                setShowFooter(true);

            } else {

                //use this promise for show base64 images
                Promise.all(addDepartmentCategoryImages(departmentCategories))
                .then((res)=>{
                    //console.log('res 6-3:', res);
                    const result = res.map((r)=>{
                                    return r.departmentCategory
                                    });
                    //console.log('result:', result);

                    /*original version
                    const pscs = addProductSubCategories(productSubCategories);
                    setProductSubCategories(pscs);
                    */

                    const dcs = addDepartmentCategories(res);
                    setDepartmentCategories(dcs);
                    getAnyItemList(typeof lcbPosition !== 'undefined' ? lcbPosition : listChangerBtnPosition, dcs, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, dcs);
                    setIsLoading(false);

                    setShowFooter(true);

                    //additional 16px for possible table bottom scroll bar
                    //additional 57px for possible pagination container
                    //adjust item count based on items per list setting
                    //previous version
                    let nrh = 0;
                    if(itemsPerList < dcs.length){
                        nrh = (itemsPerList * 94) + 588 + 16 + 57;

                    } else {
                        nrh = (dcs.length) * 94 + 588 + 16 + 57;
                    }

                    if(nrh > 950){
                        storeStructureDCContainerRefs.current[0].style.minHeight = `${nrh}px`;
                    }
                    

                })
                .catch((err)=>{
                    //console.log('add department category images error:', err.message);
                    setShowErrorMessage(true);
                    setIsLoading(false);

                    setShowFooter(true);
                });

            }   


        })
        .catch((err)=>{
            //console.log('no department categories found fetch error:', err.message);
            setShowErrorMessage(true);
            setIsLoading(false);

            setShowFooter(true);
        });
    }

    function addDepartmentCategoryImages(dcs:DepartmentCategorySource[]):Promise<DepartmentCategorySource>[] {
        return dcs.map((dc)=>{
            return new Promise((resolve, reject)=>{
                dc.departmentCategory.departmentCategoryImage.length === 0 
                ? resolve(dc)
                : addDepartmentCategoryImage(dc, resolve);

            });
        });

    }

    function addDepartmentCategories(dcs:DepartmentCategorySource[]):DepartmentCategory[] {
        //console.log('dcs:', dcs);
        return dcs.map((dc)=>{
                    return new DepartmentCategory({...dc.departmentCategory});
                });
    }

    function resetDepartmentCategories():void {
        //reset form errors
        dcKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;
        
        setShowKeywordSearchLimitError(false);

        setDepartmentCategories([]);
        setDepartmentCategoryNameSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(true);

        setItemsList(null);
        setListChangerBtnPosition(0);

        getAllDepartmentCategories(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        storeStructureDCContainerRefs.current[0].style.minHeight = `950px`;
        
    }

    function handleAddDepartmentCategory(e:React.MouseEvent):void {
        setShowKeywordSearchLimitError(false);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setDepartmentCategoryNameSearchText('');
        setShowAddDepartmentCategory(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        storeStructureDCContainerRefs.current[0].style.minHeight = `950px`;
    }

    function handleEditDepartmentCategory(dc:DepartmentCategory):void {
        setShowKeywordSearchLimitError(false);
        setDepartmentCategoryToEdit(dc)
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setDepartmentCategoryNameSearchText('');
        setShowEditDepartmentCategory(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        storeStructureDCContainerRefs.current[0].style.minHeight = `950px`;
    }

    function addDepartmentCategoryImage(psc:DepartmentCategorySource, resolve:(value: DepartmentCategorySource | PromiseLike<DepartmentCategorySource>) => void):void {
        //add image file if it exists
        //console.log('lastindexof /:', psc.departmentCategory.departmentCategoryImage.lastIndexOf('/'));
        //console.log('lastindexof .:', psc.departmentCategory.departmentCategoryImage.lastIndexOf('.'));
        const forwardSlashPos = psc.departmentCategory.departmentCategoryImage.lastIndexOf('/');
        const periodPosition =  psc.departmentCategory.departmentCategoryImage.lastIndexOf('.');
        //console.log('file path:', psc.departmentCategory.departmentCategoryImage);
        //console.log('filename:', psc.departmentCategory.departmentCategoryImage.substring(forwardSlashPos + 1, periodPosition));
        const filename = psc.departmentCategory.departmentCategoryImage.substring(forwardSlashPos + 1, periodPosition);
        //console.log('mimeType:', psc.departmentCategory.departmentCategoryImage.substring(periodPosition + 1));
        const mime = psc.departmentCategory.departmentCategoryImage.substring(forwardSlashPos + 1, periodPosition);
        const binary = window.atob(psc.source);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        for(let i=0; i < binary.length; i++){
            view[i] = binary.charCodeAt(i);
        }

        const file = new File([view], filename, {type:mime});

        const reader = new FileReader();

        function loadHandler(){
            psc.departmentCategory.departmentCategoryImage = reader.result as string;
            resolve(psc);
            reader.removeEventListener('load', loadHandler);
        }

        reader.addEventListener('load', loadHandler);

        if(file){
            reader.readAsDataURL(file);
        }
    }

    function handleRemoveDepartmentCategory(departmentCategory:DepartmentCategory):void {
        //reset form errors
        dcKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        setShowKeywordSearchLimitError(false);


        fetch(`https://server.kando-proto-3.com/delete-individual-department-category`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(departmentCategory)
        })
        .then((res)=>{

            setIsLoadingDelete(true);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            return res.json();
        })
        .then(({departmentCategoryDeleted, pscs})=>{
            //console.log('department category deleted:', departmentCategoryDeleted);
            if(departmentCategoryDeleted){
                const updcs = departmentCategories.filter((dc)=>{
                                return dc.departmentCategoryId !== departmentCategory.departmentCategoryId;
                            });

                if(updcs.length === 0){
                    setDepartmentCategoryNameSearchText('');
                    setNoSearchResultsMessage(`All department categories have been deleted from this search results page.  
                                                Try another search results page, keyword search, reset the department category list or add more department categories.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setDepartmentCategories(updcs);
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);

                } else {

                    //console.log('r15:', (updcs.length / itemsPerList), listChangerBtnPosition);

                    if((updcs.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, updcs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, updcs);

                    } else {
                        getAnyItemList(listChangerBtnPosition, updcs, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, updcs);
                    }

                    setDepartmentCategoryNameSearchText('');
                    setDepartmentCategories(updcs);
                    //getAnyItemList(listChangerBtnPosition, updcs, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, updcs);
                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                }
                


            }


            const productShopCategories = pscs.map((psc:ShopCategory)=>{
                return addShopCategory(psc);
            });
    
            //console.log('pscs 7-11:', productShopCategories);
            dispatch(addProductShopCategories([...productShopCategories]));



        })
        .catch((err)=>{
            //console.error('delete department category from db fetch err:', err.message);
            setShowErrorMessage(true);
        });
    }

    function addShopCategory(sc:ShopCategory):ShopCategory {
        return new ShopCategory({shopCategoryId:sc.shopCategoryId,
                                shopCategoryLink:sc.shopCategoryLink,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                                });
    }

    function addSubShopCategories(sscs:SubShopCategory[]):SubShopCategory[] {
        return sscs.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });

    }

    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }

    function getUpdatedDepartmenttCategory(departmentCategory:DepartmentCategory):void {
        fetch(`https://server.kando-proto-3.com/get-updated-department-category/${departmentCategory.departmentCategoryId}`, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then(({departmentCategorySource})=>{
            //console.log('psc:', departmentCategorySource, departmentCategories);
            if(departmentCategorySource === null){
                setShowErrorMessage(true);
                setIsLoading(false);

            } else {
                Promise.resolve(                
                                new Promise((resolve, reject)=>{
                                    //console.log('test15:');
                                    addDepartmentCategoryImage(departmentCategorySource, resolve);
                }))
                .then((dcSource)=>{

                    //console.log('pcs15:', dcSource);

                    const dcs = departmentCategories.map((dc)=>{
                                    if(dc.departmentCategoryId === (dcSource as DepartmentCategorySource).departmentCategory.departmentCategoryId){
                                        return (dcSource as DepartmentCategorySource).departmentCategory;

                                    } else {
                                        return dc;
                                    }
                                });

                    

                    setDepartmentCategories(dcs);
                    getAnyItemList(listChangerBtnPosition, dcs, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, dcs);
                    setIsLoading(false);

                })
                .catch((err)=>console.log('error get updated department category image:', err.message));


            }
        })
        .catch((err)=>console.log('get updated department category error:', err.message));
    }


    function sortItemsByDateSubmitted(sortDirection:string):void {
        if(sortDirection === 'asc'){
            //console.log('sort29');
            const dcs = departmentCategories.sort(compareAscendingDates);
            setDepartmentCategories(dcs);
            getAnyItemList(listChangerBtnPosition, dcs, itemsPerList, showItemsPerList);

        } else {
            //console.log('sort29');
            const dcs = departmentCategories.sort(compareDescendingDates);
            setDepartmentCategories(dcs);
            getAnyItemList(listChangerBtnPosition, dcs, itemsPerList, showItemsPerList);
        }

    }

    function compareDescendingDates(a:DepartmentCategory, b:DepartmentCategory):number {
        const aDate = new Date(a.departmentCategoryDateAdded).getTime();
        const bDate = new Date(b.departmentCategoryDateAdded).getTime();

        return bDate - aDate;
    }

    function compareAscendingDates(a:DepartmentCategory, b:DepartmentCategory):number {
        const aDate = new Date(a.departmentCategoryDateAdded).getTime();
        const bDate = new Date(b.departmentCategoryDateAdded).getTime();

        return aDate - bDate;
    }


    function setupDepartmentCategoriesTable(itemsList:object[]):JSX.Element {
        return <div className="ssdcs-table-container">
                    <table className="ssdcs-table">
                        <thead>
                            <tr>
                                <th className="th-dc-dept">Department</th><th className="th-dc-dc">Department Category</th><th className="th-dc-img">Department Category Image</th><th className="th-dc-date"><div className="admin-date-added-header-container"><span>Date Added</span><span className="admin-date-added-svg-btn-container">
                                                                                                                                                                                                                            <button type="button" aria-label="ascending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('asc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-up" d="M200 100 L100 0 L0 100 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                            <button type="button" aria-label="descending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('desc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-down" d="M200 0 L100 100 L0 0 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                        </span></div></th><th className="th-dc-action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(itemsList as DepartmentCategory[]).map((dc)=>{
                        //console.log(`pn:${p.productName}, dn:${p.storeDepartmentName}, dcn:${p.departmentCategoryName}, pcn:${p.productCategoryName}, psc:${p.productSubCategoryName}, pda:${p.productDateAdded}`)
                                                return <tr key={dc.departmentCategoryId}>
                                                            <td><div className="admin-td">{dc.departmentName}</div></td>
                                                            <td><div className="admin-td">{dc.departmentCategoryName}</div></td>
                                                            <td className="admin-img"><img ref={getImageRef} data-alt={dc.departmentCategoryName} alt={""} data-src={dc.departmentCategoryImage} className="ssdcs-table-image"/></td>
                                                            <td><div className="admin-td">{transformDate(new Date(dc.departmentCategoryDateAdded).getTime())}</div></td>
                                                            <td className="td-action">
                                                                <div className="table-item-btn-container">
                                                                    <button className="edit-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleEditDepartmentCategory(dc)
                                                                        }}>
                                                                        Edit
                                                                    </button>
                                                                    <button className="remove-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleRemoveDepartmentCategory(dc);
                                                                        }}>
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                            })
                            }
                        </tbody>
                    </table>
                </div>
    }

    function handleCloseForm(formType:string, cancel?:boolean):void {
        
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        if(formType === 'editDepartmentCategory'){
            setDepartmentCategories([]);
            setIsLoading(true);
            getUpdatedDepartmenttCategory(departmentCategoryToEdit!);
            setShowEditDepartmentCategory(false);
        } else if(formType === 'addDepartmentCategory') {
            setDepartmentCategories([]);
            setIsLoading(true);
            if(cancel){
                getAllDepartmentCategories();
            } else {
                setListChangerBtnPosition(0);
                getAllDepartmentCategories(0);
            }

            setShowAddDepartmentCategory(false);
            setShowEditDepartmentCategory(false);
        } 


    }

    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);

        /*
        if(ssdcsContainerRef.current !== null){
            ssdcsContainerRef.current!.style.minHeight = `${360 + (94 * itemsList.length)}px`;            
        }
        */
        
        setItemsList(itemsList as DepartmentCategory[]);


        //adjust item count based on items per list setting
        let nrh = 0;
        if(itemsPerList < itemsList.length){
            nrh = (itemsPerList * 94) + 588 + 16 + 57;

        } else {
            nrh = (itemsList.length) * 94 + 588 + 16 + 57;
        }

        const mh = 950;
        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
            storeStructureDCContainerRefs.current[0].style.minHeight = `${mh}px`;
        }

        if(existingSearchResultsHeight.current === 0 && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh < mh){
            existingSearchResultsHeight.current = mh;

            storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh > existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }
        //console.log('esrs:', existingSearchResultsHeight.current);
        
        
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);   

    }

    function displayListSorter(itemsPerList:number, dcs:DepartmentCategory[]):void {
        if(dcs.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleDepartmentCategorySearchText(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        

        //console.log('value.length:', target.value.length);

        if(target.value.length <= 50){
            setDepartmentCategoryNameSearchText(target.value);
            setShowKeywordSearchLimitError(false);

        } else {
            setShowKeywordSearchLimitError(true);
        }

    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowInfoMessage(false);
    }

    function handleResultsPerPageOptionSelected(e:React.ChangeEvent):void {
        const rppoSelected = (e.target as HTMLSelectElement).value;
        setResultsPerPageOptionSelected(rppoSelected);
        const ipp = parseInt(rppoSelected, 10);
        
        const previpp = itemsPerList;
        const previousPositionInList = (previpp * (listChangerBtnPosition + 1)) - previpp + 1;
        const startPointInList = Math.floor(previousPositionInList / ipp);
        //console.log('cbp:', startPointInList, previousPositionInList / ipp, listChangerBtnPosition);

        if(ipp > departmentCategories.length){
            setItemsPerList(ipp);
            setListChangerBtnPosition(0);
            getAnyItemList(0, departmentCategories, ipp, showItemsPerList);

        } else {

            if(listChangerBtnPosition === 0){
                setItemsPerList(ipp);
                setListChangerBtnPosition(0);
                getAnyItemList(0, departmentCategories, ipp, showItemsPerList);

            } else {
                setItemsPerList(ipp);
                setListChangerBtnPosition(startPointInList - 1);
                getAnyItemList(startPointInList - 1, departmentCategories, ipp, showItemsPerList);
            }

        }
        
        displayListSorter(ipp, departmentCategories);
    }


    function handleSubmitDepartmentCategorySearch(e:React.FormEvent):void {
        e.preventDefault();

        const form = e.target as HTMLFormElement;
        //setShowLoadingSearchResults(true);
        const bp = adminSearchFormBtnRef.current!.offsetTop + adminSearchFormBtnRef.current!.offsetHeight;
        //ssdcsContainerRef.current!.style.minHeight = `${620 + bp}px`;

        //reset form errors
        dcKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //product info
        //product name selected
        const dcnst = departmentCategoryNameSearchText;
        if(dcnst.length === 0){
            setShowKeywordSearchLimitError(false);
            formError.productInfoErrors.push({hasError:true, area:'Department Category Keyword'});

        } else {
            setShowKeywordSearchLimitError(false);
        }

        //pricing info
        //department category image
        /*
        const pp = productPrice;
        if(pp.length === 0 || parseInt(pp, 10) === 0){
            formError.pricingErrors.push({hasError:true, area:'Price'});
        }
        */

        const dcnsts = formError.productInfoErrors.length > 0 ? true : false;
        //const pps = formError.pricingErrors.length > 0 ? true : false;

        if(dcnsts/* || pps*/){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            if(sfgemc.getBoundingClientRect().height > window.innerHeight){
                                sfgemc.style.height = `${window.innerHeight + 1}px`;
                                sfgemc.style.overflowY = 'scroll';
                                sfgemc.style.overscrollBehaviorY = 'contain';
                            }

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }
        
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        //setItemsList(null);

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        const searchText = departmentCategoryNameSearchText;


        /*
        const zipCodePattern = /\d+/g
        const zcr = searchText.match(zipCodePattern);

        if(zcr !== null){
            //found some numbers
            let numberString = '';
            zcr.forEach((num)=>{
                numberString += num;		
                });
                        
            console.log('zcr:', zcr);
            console.log('search number:', parseInt(numberString,10));
    
            const searchText = numberString;
            //check store numbers
            fetch(`http://localhost:9500/store-location-search-number/${searchText}`, {
                method:'GET',
                headers:{
                    'Content-Type':'text/javascript',
                    'Accept':'text/javascript'
                }
            })
            .then((res)=>res.json())
            .then((result)=>{
                console.log('result:', result);
                if(result.length === 1 && result[0].source === null && result[0].department === null){
                    //setShowLoadingSearchResults(false);
                    setShowNoSearchResultsFound(true);
                } else {
                    
                    Promise.all(addDepartmentImages(result))
                    .then((res)=>{
                        console.log('res 11-27:', res);            
                        const ds = addDepartments(res);
                        setDepartments(ds);
                        getAnyItemList(listChangerBtnPosition, ds, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, ds);
                        //setShowLoadingSearchResults(false);
                    })
                    .catch((err)=>console.error('add department images error:', err.message));
                    
                }
            })
            .catch(err=>console.error('department number search error:', err.message));
            
        } else {
            */
            //check department name            
            if(searchText.length > 0){
                let searchString = '';
    
                for(let i=0; i < searchText.length; i++){
                    if(/[\(\)\[\]\*\$\^\{\}\?\_\+\.\"\'\\|]/.test(searchText[i])){
                        searchString += `\\${searchText[i]}`;
    
                    } else {
                        searchString += `${searchText[i]}`;
                    }
                }
    
                //console.log('str26:', searchString);
                //searchString = '\\(-\\_\'\"\"\\]\\\\//\\?what\\^\\$@1';  

                const st = {searchText:searchString};
                
                const params = JSON.stringify(st);

                setTemporaryClearSearchResults(true);

                //previous version
                //fetch(`http://localhost:9500/department-category-search-name/${searchText}`, {
                fetch(`https://server.kando-proto-3.com/department-category-search-name`, {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:params
                })
                .then((res)=>{

                    setShowProcessingRequestMsg(false);
                    setItemsList(null);
                    isLoadingTimeoutRef.current = window.setTimeout(()=>{
                                                            setIsLoading(true);
                                                        },1000);
                    setIsLoadingDelete(true);

                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    return res.json();
                })
                .then((result)=>{
                    //console.log('result:', result);
                    if(result.length === 1 && result[0].source === null && result[0].departmentCategory === null){
                        //setShowLoadingSearchResults(false);
                        setNoSearchResultsMessage('No department categories matched those search parameters.');
                        setDepartmentCategories([]);
                        setShowNoSearchResultsFound(true);
                        setDepartmentCategories([]);
                        setShowInfoMessage(true);

                        setTemporaryClearSearchResults(false);

                        setShowProcessingRequestMsg(false);
                        window.clearTimeout(isLoadingTimeoutRef.current);
                        setIsLoading(false);
                        setIsLoadingDelete(false);



                        storeStructureDCContainerRefs.current[0].style.minHeight = `950px`;

                    } else {
                        
                        Promise.all(addDepartmentCategoryImages(result))
                        .then((res)=>{
                            //console.log('res 11-27:', res);            
                            const dcs = addDepartmentCategories(res);
                            setDepartmentCategories(dcs);
                            setListChangerBtnPosition(0);
                            getAnyItemList(0, dcs, itemsPerList, showItemsPerList);
                            displayListSorter(itemsPerList, dcs);

                            setTemporaryClearSearchResults(false);
                            //setShowLoadingSearchResults(false);

                            setShowProcessingRequestMsg(false);
                            window.clearTimeout(isLoadingTimeoutRef.current);
                            setIsLoading(false);
                            setIsLoadingDelete(false);



                            //adjust item count based on items per list setting
                            let nrh = 0;
                            if(itemsPerList < dcs.length){
                                nrh = (itemsPerList * 94) + 588 + 16 + 57;

                            } else {
                                nrh = (dcs.length) * 94 + 588 + 16 + 57;
                            }

                            const mh = 950;
                            if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                                storeStructureDCContainerRefs.current[0].style.minHeight = `${mh}px`;
                            }
        
                            if(existingSearchResultsHeight.current === 0 && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh < existingSearchResultsHeight.current && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh < existingSearchResultsHeight.current && nrh < mh){
                                existingSearchResultsHeight.current = mh;
        
                                storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh > existingSearchResultsHeight.current && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                storeStructureDCContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
                            //console.log('esrs:', existingSearchResultsHeight.current);

                            let root = document.documentElement;
                            root!.setAttribute('style', 'scroll-behavior: auto');
                            root.scrollTo(0,0);
                            window.setTimeout(()=>{
                                root!.removeAttribute('style');
                            },100);


                        })
                        .catch((err)=>{
                            //console.log('add department category images error:', err.message);
                            setShowErrorMessage(true);
                            setShowProcessingRequestMsg(false);
                            window.clearTimeout(isLoadingTimeoutRef.current);
                            setIsLoading(false);
                            setIsLoadingDelete(false);
                        });
                        
                    }
                    
                })
                .catch(err=>{
                    //console.log('department category name search error:', err.message);
                    setShowErrorMessage(true);
                    setShowProcessingRequestMsg(false);
                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);
                });

            } else {
                //console.log('no results found.');
                setDepartmentCategories([]);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);

                setShowProcessingRequestMsg(false);
                window.clearTimeout(isLoadingTimeoutRef.current);
                setIsLoading(false);
                setIsLoadingDelete(false);

                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);

            }
            
            
        //}


    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Department Category Keyword':
                                dcKeywordRefs.current[0]!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no department category keyword info errors found!');
                        }
                    }
                });


        //set image errors
        /*
        formError.pricingErrors.forEach((pe)=>{
            console.log('pe:', pe);
            if(pe.hasError && pe.area === 'Price'){
                console.log('pe.area:', pe.area);
                productPriceRef.current!.classList.add('pricing-error');
            } else {
                throw new Error('no price errors found!');
            }
        });
        */
    }
    

    return <div className="ssdcs-wrapper">
                <div ref={getStoreStructureDCContainerRef} className="ssdcs-container">
                    <ul className="store-details-breadcrum-container">
                        <li className="sdbi">
                            <Link to="/admin" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi">
                            <Link to="../" className="store-details-breadcrum-link"
                                onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin/store-structure', navigate)}>Store Structure</Link>
                            <span className="shop-store-department-fa-angle-right">
                                <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                            </span>
                        </li>
                        <li className="sdbi store-details-breadcrum-link-store-name">Department Categories</li>
                    </ul>


                    {
                        !showAddDepartmentCategory && !showEditDepartmentCategory
                        ?
                        <h1 className="store-structure-dpts-categories-heading">Department Categories</h1>
                        :
                        null
                    }
                    
                    {     
                        !showAddDepartmentCategory && !showEditDepartmentCategory
                        ?
                        <div>
                            <div className="admin-add-item-btn-container">
                                <button type="button" className="admin-add-item-btn" onClick={handleAddDepartmentCategory}>Add Category</button>
                                <button type="button" className="admin-reset-items-btn" onClick={resetDepartmentCategories}>Reset Categories</button>
                            </div>
                            <form ref={getFormRef} className="admin-search-form" onSubmit={handleSubmitDepartmentCategorySearch}>
                                <fieldset className="admin-search-box-container">
                                    <label className="admin-search-box-label" htmlFor="search-box">Keywords:</label>
                                    <span className="department-category-search-secondary-label">(Enter department category or department name)</span>
                                    <div className="table-keyword-search-layout-wrapper">
                                        <input id="search-box" className="admin-search-box" type="text"
                                            ref={getDCKeywordRef}
                                            placeholder={"Enter department category or department name"}
                                            value={departmentCategoryNameSearchText} onChange={handleDepartmentCategorySearchText}/>
                                        {
                                            showKeywordSearchLimitError
                                            ?
                                            <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                            :
                                            null
                                        }
                                    </div>
                                </fieldset>
                                <fieldset className="admin-search-form-btn-container">
                                    <button ref={adminSearchFormBtnRef} type="submit" className="admin-search-form-btn">Search</button>
                                </fieldset>
                                <FormErrorMessage ref={getSfgErrorMsgRef} formError={formError} />
                            </form>
                        </div>
                        :
                        null
                    }

                    {
                        !showAddDepartmentCategory && !showEditDepartmentCategory && !isLoading 
                        && showNoSearchResultsFound && showInfoMessage && !isLoadingDelete
                        ?
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                <span className="admin-list-total">{departmentCategories.length} results</span>
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-generic-info-message-container">
                                {noSearchResultsMessage}
                                {/*<GenericInfoMessage infoMessage={noSearchResultsMessage}/>*/}
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                        !showAddDepartmentCategory && !showEditDepartmentCategory && !isLoading &&
                        departmentCategories.length > 0 && !showNoSearchResultsFound && !showErrorMessage && !isLoadingDelete
                        ?
                        <div className="admin-list-wrapper">
                            <div className="admin-list-total-continer">
                                {
                                    temporaryClearSearchResults
                                    ?
                                    <span className="admin-list-total"></span>
                                    :
                                    <span className="admin-list-total">{(departmentCategories.length === 1) ? `${departmentCategories.length} result` : `${departmentCategories.length} results`}</span>
                                }
                                
                                <div className="admin-results-per-page-container">
                                    <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                    <div className="admin-results-per-page-selector-wrapper">
                                        <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                            optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                            cssStyles={{minWidth:50, backgroundPosition:5}}
                                            handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                    </div>
                                </div>
                            </div>
                            <ListViewer itemsList={itemsList} items={departmentCategories} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupDepartmentCategoriesTable} />
                        </div>
                        :
                        null
                    }

                    {
                        showAddDepartmentCategory && !showEditDepartmentCategory && !isLoading             
                        ?
                        <AddDepartmentCategory showEditDepartmentCategory={false} handleCloseForm={handleCloseForm}/>
                        :
                        null
                    }

                    {
                        !showAddDepartmentCategory && showEditDepartmentCategory && !isLoading
                        ?
                        <EditDepartmentCategory departmentCategory={departmentCategoryToEdit!} handleCloseForm={handleCloseForm}/>
                        :
                        null
                    }

                    {
                        !showAddDepartmentCategory && !showEditDepartmentCategory && isLoading && !showNoSearchResultsFound && !isLoadingDelete
                        ?
                        <div ref={getLoaderRef} className="ssds-loader-animation-container">
                            <LoaderAnimation/>
                        </div>
                        :
                        null  
                        
                    }

{
                        !showAddDepartmentCategory && !showEditDepartmentCategory && isLoadingDelete && !showNoSearchResultsFound
                        ?
                        <div className="ssds-loader2-animation-container">
                            {/*<LoaderAnimation/>*/}
                            {
                                isLoading
                                ?
                                <LoaderAnimation/>
                                :
                                null
                            }
                        </div>
                        :
                        null  
                        
                    }

                    {
                        showProcessingRequestMsg
                        ?
                        <div className="process-request-msg-fixed">
                            Processing...
                        </div>
                        :
                        null
                    }


                    {
                        showErrorMessage
                        ?
                        <div className="ssd-generic-error-msg-wrapper">
                            <GenericErrorMessage/>
                        </div>
                        :
                        null
                    }


                </div>
                {
                    showFooter
                    ?
                    <Footer/>
                    :
                    null
                }
            </div>

}


export default StoreStructureDepartmentCategories2;
