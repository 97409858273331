import React, {useState, useEffect} from 'react';
import './StoreStructureAlert.css';

interface Props {
    showStoreStructureAlert:boolean;
    storeStructureAlertMsg:string;
    handleCloseStoreStrucureAlert:(e:React.MouseEvent)=>void;
}

const StoreStructureAlert: React.FunctionComponent<Props> = (props:Props) => {
    const {showStoreStructureAlert, storeStructureAlertMsg, handleCloseStoreStrucureAlert} = props;

    function setupCSSClass(showAlert:boolean):string {
        return showAlert ? 'store-structure-alert-wrapper' : 'store-structure-alert-wrapper-hide';
    }

    return <div className={setupCSSClass(showStoreStructureAlert)}>
                <div className="store-structure-alert-container">
                    <p className="store-structure-alert-msg">{storeStructureAlertMsg}</p>
                    <button type="button" className="store-structure-alert-close-btn"
                    onClick={handleCloseStoreStrucureAlert}>Close</button>
                </div>
            </div>
}

export default StoreStructureAlert;

