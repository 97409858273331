import Product from './product';
import SearchFilter, {SearchTerm} from './searchFilter';
import cloneDeep from 'lodash/cloneDeep';
import PriceRange from './priceRange';
import {SearchFilterParams} from './redux/features/searchFilterParams/searchFilterParamsSlice';

function addProducts(products:Product[]):Product[]{
    //console.log('length:', products.length);
    //setProductCategoryProductTotal(products.length);
    return products.map((product)=>{
        //console.log('p:', product);
        return new Product({...product});
    });
}

//test for selecting a search filter search term
function checkFilterSearchTerm2(
                            e:React.MouseEvent, 
                            searchFilterSelected:string, 
                            searchTermSelected:string, 
                            initialSearchFilters:SearchFilter[], 
                            searchTermIsSelected:boolean,
                            storeDepartmentId:string,
                            otherStoreAreaId:string,
                            storeAreaName:string,
                            storeAreaType:string,
                            setShowDepartmentProducts:(value: React.SetStateAction<boolean>) => void,
                            clearAllExistingImages:()=>void,
                            setSearchFilters:(value: React.SetStateAction<SearchFilter[]>) => void,
                            //setDepartmentCategoryProducts:(value: React.SetStateAction<Product[]>) => void,
                            setDepartmentCategoryProducts:(productCategoryProducts:Product[]) => void,
                            setDepartmentCategoryProductTotal:(value: React.SetStateAction<number>) => void,
                            setShowSpecialProductCategoryShortcut:(value: React.SetStateAction<boolean>) => void,
                            setSearchTermIsSelected:(value: React.SetStateAction<boolean>) => void,
                            setKeywordSearchType:(value: React.SetStateAction<string>) => void,
                            //addDepartmentCategoryProducts:(departmentCategoryProducts: Product[])=>Product[],
                            addSearchFilters:(searchFilters: SearchFilter[])=>SearchFilter[],
                            startAmount?:number,
                            endAmount?:number,
                            updateProductCategoryShortcutSearchFilter?:(showShortcut:boolean, sfps:SearchFilterParams|null)=>void,
                            sssbatps?:React.Dispatch<React.SetStateAction<boolean>>
                            ):void {
        //console.log('cfst2:', e.target, e.currentTarget);
        //console.log('saf218:', storeAreaType, 'san:', storeAreaName, 'sdi:', storeDepartmentId, 'osai:', otherStoreAreaId, 'sfs:', searchFilterSelected, 'sts:', searchTermSelected, 'isfs:', initialSearchFilters, 'stis:', searchTermIsSelected);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();


        clearAllExistingImages();
        
        setShowDepartmentProducts(false);


        //if(typeof sssbatps !== 'undefined'){
            sssbatps!(false);
        //}
        
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        if(window.innerWidth >= 1024){
            root.scrollTo(0,264);

        } else {
            root.scrollTo(0,0);
        }
        
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100); 
        

        //add this
        //setShowDepartmentCategoryProducts(false);
        //need to updated search filter and search term that is selected in json file
        //need to add check to box if search term is selected with css
        Promise.all([getDepartmentCategoryProducts(storeAreaType, storeDepartmentId, otherStoreAreaId),
                    updateDepartmentCategorySearchFilters(
                                                storeAreaType,
                                                storeAreaName,
                                                otherStoreAreaId,
                                                searchFilterSelected,
                                                searchTermSelected
                                                /*departmentCategoryName,
                                                departmentCategoryId*/
                                                        )
        ])
        .then(([products, searchFilters])=>{
            //console.log('ps1:', products);
            //console.log('p:', addDepartmentCategoryProducts(products));
            //console.log('newps2:', getProductsByPriceSliderAmount(products, startAmount!, endAmount!), 'ps:', products, 'sa:', startAmount, 'ea:', endAmount);
            products = getProductsByPriceSliderAmount(products, startAmount!, endAmount!);

           //console.log('sfs2:', searchFilters);
           //console.log('isfs:', initialSearchFilters);
            //console.log('isfs checked:', checkSearchFiltersSelected(initialSearchFilters));
            
            //console.log('stws2:', searchTermIsSelected);
            //add this
            //setShowDepartmentCategoryProducts(true);
            
            
            //console.log('csfs:', checkSearchFiltersSelected(searchFilters), 'stws:', searchTermIsSelected);
            if(!checkSearchFiltersSelected(searchFilters) && !searchTermIsSelected){
                //console.log('isfs passed 1:', initialSearchFilters);
                const isfs = normalizeInitialSearchFilters(initialSearchFilters);
                setSearchFilters([...isfs]);
                //setSearchFilters([...initialSearchFilters]);
                //original version
                //setDepartmentCategoryProducts(addDepartmentCategoryProducts(products));
                //test version
                setDepartmentCategoryProducts(addProducts(products));
                setDepartmentCategoryProductTotal(products.length);
                setShowSpecialProductCategoryShortcut(true);
                
                setShowDepartmentProducts(true);

            
                
            } else if(!checkSearchFiltersSelected(searchFilters) && searchTermIsSelected){
                //console.log('isfs passed 2:', initialSearchFilters);
                const isfs = normalizeInitialSearchFilters(initialSearchFilters);
                setSearchFilters([...isfs]);
                //setSearchFilters([...initialSearchFilters]);
                setSearchTermIsSelected(false);

                if(products.length === 0){
                    setKeywordSearchType('noResults');
                } else {
                    setKeywordSearchType('results');
                }
                //original version
                //setDepartmentCategoryProducts(addDepartmentCategoryProducts(products));
                //test version
                setDepartmentCategoryProducts(addProducts(products));
                setDepartmentCategoryProductTotal(products.length);
                setShowSpecialProductCategoryShortcut(true);

                setShowDepartmentProducts(true);

                if(storeAreaType === 'Product Category'){
                    //console.log('test end:');
                    updateProductCategoryShortcutSearchFilter!(true, null);
                }


            } else {
                //console.log('isfs passed els:');
                searchFilters = addSearchFilters(searchFilters);
                //console.log('usfs5:', searchFilters, initialSearchFilters, products);
                const [updatedSearchFilters, productsSelected] = updateSearchFilters2(products, searchFilters, initialSearchFilters);
                //console.log('usfs5:', updatedSearchFilters);
                //console.log('ps5:', productsSelected);
                if(productsSelected.length === 0){
                    setKeywordSearchType('noResults');
                } else {
                    setKeywordSearchType('results');
                }

                setSearchFilters([...updatedSearchFilters]);
                setSearchTermIsSelected(true);
                setDepartmentCategoryProducts(productsSelected);
                setDepartmentCategoryProductTotal(productsSelected.length);
                setShowSpecialProductCategoryShortcut(false);

                setShowDepartmentProducts(true);

                if(storeAreaType === 'Product Category'){
                    //console.log('test passed:');

                    const sfps:SearchFilterParams = {
                                searchFilterSelected:searchFilterSelected,
                                searchTermSelected:searchTermSelected,
                                searchTermIsSelected:true,
                                storeDepartmentId:storeDepartmentId,
                                otherStoreAreaId:otherStoreAreaId,
                                storeAreaName:storeAreaName,
                                storeAreaType:storeAreaType,
                                keywordSearchType:(productsSelected.length === 0) ? 'noResults' : 'results',
                                showProductSubCategoryFilter:true,
                                noSearchFilterAttached:false,
                                searchFilters:updatedSearchFilters,
                                products:productsSelected
                            };

                    updateProductCategoryShortcutSearchFilter!(false, sfps);
                }

            }

        })
        .catch((err)=>console.error('promise all error:', err));

    }

    function getProductsByPriceSliderAmount(products:Product[], startAmount:number, endAmount:number):Product[]{
        const ps = products.filter((p)=>{
                        return (p.eachPrice >= startAmount && p.eachPrice <= endAmount);
                    });

        //console.log('ps 5-3:', ps);
        return ps;
        
    }

    //test for selecting a search filter search term
export function checkRelatedProductsFilterSearchTerm(
                            e:React.MouseEvent, 
                            searchFilterSelected:string, 
                            searchTermSelected:string, 
                            initialSearchFilters:SearchFilter[], 
                            searchTermIsSelected:boolean,
                            storeDepartmentId:string,
                            otherStoreAreaId:string,
                            storeAreaName:string,
                            storeAreaType:string,
                            setShowDepartmentProducts:(value: React.SetStateAction<boolean>) => void,
                            clearAllExistingImages:()=>void,
                            setSearchFilters:(value: React.SetStateAction<SearchFilter[]>) => void,
                            //setDepartmentCategoryProducts:(value: React.SetStateAction<Product[]>) => void,
                            setDepartmentCategoryProducts:(productCategoryProducts:Product[]) => void,
                            setDepartmentCategoryProductTotal:(value: React.SetStateAction<number>) => void,
                            setShowSpecialProductCategoryShortcut:(value: React.SetStateAction<boolean>) => void,
                            setSearchTermIsSelected:(value: React.SetStateAction<boolean>) => void,
                            setKeywordSearchType:(value: React.SetStateAction<string>) => void,
                            //addDepartmentCategoryProducts:(departmentCategoryProducts: Product[])=>Product[],
                            addSearchFilters:(searchFilters: SearchFilter[])=>SearchFilter[],
                            keywordSearch?:string,
                            startAmount?:number,
                            endAmount?:number,
                            setProductMatch?: React.Dispatch<React.SetStateAction<boolean>>,
                            setShowRelatedProducts?: React.Dispatch<React.SetStateAction<boolean>>,
                            setRelatedProductTotal?:(value: React.SetStateAction<number>)=>void
                            ):void {
        //console.log('crpst:', e.target);
        //console.log('saf:', storeAreaType, 'san:', storeAreaName, 'osai:', otherStoreAreaId, 'sfs:', searchFilterSelected, 'sts:', searchTermSelected, 'sa:', startAmount, 'ea:', endAmount);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const pr = {    
            status:'filterProducts',
            startAmount:startAmount!,
            endAmount:endAmount!
        };

        clearAllExistingImages();
        setShowDepartmentProducts(false);

        setProductMatch!(false);
        setShowRelatedProducts!(false);


        setRelatedProductTotal!(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        if(window.innerWidth >= 1024){
            root.scrollTo(0,112);

        } else {
            root.scrollTo(0,0);
        }
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100); 

        //add this
        //setShowDepartmentCategoryProducts(false);
        //need to updated search filter and search term that is selected in json file
        //need to add check to box if search term is selected with css
        setupRelatedProductsPromises(searchFilterSelected, searchTermSelected, storeAreaType, storeAreaName, keywordSearch as string, pr)
        .then((rp)=>{
            let searchFilters = rp[0] as SearchFilter[];

            //console.log('new p 5-3:', getProductsByPriceSliderAmount(rp[1] as Product[], startAmount!, endAmount!), 'ps:', rp[1], 'sa:', startAmount, 'ea:', endAmount);

            let products = rp[1] as Product[];
            //console.log('ps:', products);
            //console.log('p:', addDepartmentCategoryProducts(products));
            //console.log('sfs:', searchFilters);
            //console.log('isfs:', initialSearchFilters);
            //console.log('isfs checked:', checkSearchFiltersSelected(initialSearchFilters));
            
            //console.log('stws:', searchTermIsSelected);
            //add this
            //setShowDepartmentCategoryProducts(true);

            
            //console.log('csfs:', checkSearchFiltersSelected(searchFilters), 'stws:', searchTermIsSelected);
            if(!checkSearchFiltersSelected(searchFilters) && !searchTermIsSelected){
                //console.log('isfs passed 1:', initialSearchFilters);
                const isfs = normalizeInitialSearchFilters(initialSearchFilters);
                setSearchFilters([...isfs]);
                //setSearchFilters([...initialSearchFilters]);
                //original version
                //setDepartmentCategoryProducts(addDepartmentCategoryProducts(products));
                //test version
                setDepartmentCategoryProducts(addProducts(products));
                setDepartmentCategoryProductTotal(products.length);
                setShowSpecialProductCategoryShortcut(true);

                //setShowDepartmentProducts(true);
                
            } else if(!checkSearchFiltersSelected(searchFilters) && searchTermIsSelected){
                //console.log('isfs passed 2:', initialSearchFilters);
                const isfs = normalizeInitialSearchFilters(initialSearchFilters);
                setSearchFilters([...isfs]);
                //setSearchFilters([...initialSearchFilters]);
                setSearchTermIsSelected(false);

                if(products.length === 0){
                    setKeywordSearchType('noPricingResults');
                } else {
                    //original version
                    //setKeywordSearchType('results');
                    setKeywordSearchType('productCategory');
                }
                //original version
                //setDepartmentCategoryProducts(addDepartmentCategoryProducts(products));
                //test version
                setDepartmentCategoryProducts(addProducts(products));
                setDepartmentCategoryProductTotal(products.length);
                setShowSpecialProductCategoryShortcut(true);

                //setShowDepartmentProducts(true);

            } else {
                //console.log('isfs passed els:');
                searchFilters = addSearchFilters(searchFilters);
                //console.log('sfs after:', searchFilters);
                const [updatedSearchFilters, productsSelected] = updateSearchFilters2(products, searchFilters, initialSearchFilters);
                //console.log('usfs:', updatedSearchFilters);
                //console.log('ps:', productsSelected);
                if(productsSelected.length === 0){
                    setKeywordSearchType('noPricingResults');
                } else {
                    //original version
                    //setKeywordSearchType('results');
                    setKeywordSearchType('productCategory');
                }

                setSearchFilters([...updatedSearchFilters]);
                setSearchTermIsSelected(true);
                setDepartmentCategoryProducts(productsSelected);
                setDepartmentCategoryProductTotal(productsSelected.length);
                setShowSpecialProductCategoryShortcut(false);

                //setShowDepartmentProducts(true);
            }

        })
        .catch((err)=>console.error('promise all error:', err));

    }

export function normalizeInitialSearchFilters(searchFilters:SearchFilter[]):SearchFilter[]{
        const sfs = searchFilters.map((searchFilter)=>{
                        searchFilter.searchFilterSelected = false;
                        searchFilter.searchTerms =  searchFilter.searchTerms.map((searchTerm)=>{
                                                        searchTerm.searchTermSelected = false;
                                                        //searchTerm.searchTermQty = 0;
                                                        return searchTerm;
                                                    });
                        return searchFilter;
                    });
        return sfs;
    }

export function checkSearchFiltersSelected(sfs:SearchFilter[]):boolean{
         let searchFiltersSelected:string[] = [];
         sfs.forEach((sf)=>{
            if(sf.searchFilterSelected){
                searchFiltersSelected.push(sf.filterName);
            }
         });
         
         return (searchFiltersSelected.length === 0) ? false : true;
    }

export function getSelectedSearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        let ssfs:SearchFilter[] = [];
        ssfs = sfs.filter(sf=>{
            return sf.searchFilterSelected
        });
        //console.log('ssfs:', ssfs);
        return ssfs;
    }

export function getUnselectedSearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        sfs = sfs.filter(sf=>{
            return !sf.searchFilterSelected
        });
        //console.log('sfs:', sfs);
        return sfs;
    }

export function filterSearchTerms(searchTermList:SearchTerm[]):string[]{
        let updatedSearchTermList:string[] = [];
        searchTermList.forEach(searchTerm=>{
            if(searchTerm.searchTermSelected){
                //console.log('st3-19:', searchTerm);
                updatedSearchTermList.push(searchTerm.searchTermName);
            }
            
        });

        return updatedSearchTermList;
    }

export function filterSearchTerms2(ssf:SearchFilter):{sfn:string, stn:string}[]{
        let updatedSearchTermList:{sfn:string, stn:string}[] = [];

        ssf.searchTerms.forEach((searchTerm)=>{
            if(searchTerm.searchTermSelected){
                updatedSearchTermList.push({sfn:ssf.filterName, stn:searchTerm.searchTermName});
            }
        });

        /*
        searchTermList.forEach(searchTerm=>{
            if(searchTerm.searchTermSelected){
                //console.log('st3-19:', searchTerm);
                updatedSearchTermList.push(searchTerm.searchTermName);
            }
            
        });
        */

        return updatedSearchTermList;
    }

export function filterSearchTermList(list:string[][], times:number):string[][]{
        //console.log('list22:', list, list.length);
        if(list.length >= 2){
            let index = 0;
            let filteredList = [];
            let uList = [];
            let [first, second] = list;
            //console.log('f22:',first);
            //console.log('f22s:',second);
            
            for(let a=0; a < first.length; a++){
                //console.log('a:', a);
                for(let b=0; b < second.length; b++){
                    //console.log('b:', b);
                    //console.log(`${secondBatch[a]}, ${newBatch[b]}`);
                    uList.push([first[a], second[b]]);
                }
            }
    
            index = 2;
            //console.log('uList:', uList);
            while(index < times){
                //console.log('ran:', index);
                let filter = list[index];
                //console.log('f:', filter);
                for(let x=0; x < uList.length; x++){
                    for(let y=0; y < filter.length; y++){
                        filteredList.push([...uList[x], filter[y]]);
                    }
                }
                index++;
                //console.log('fl:', filteredList);
                uList = [...filteredList];
                filteredList = [];
                //console.log('ul:', uList);
                
            }
        
            //console.log('fl:', filteredList);
            //original version
            return uList;
            //return filteredList;

        } else {
            return list;
        }
    
    
    }


export function filterSearchTermList2(list:{sfn:string, stn:string}[][], times:number):{sfn:string, stn:string}[][]{
        //console.log('list22:', list, list.length);
        if(list.length >= 2){
            let index = 0;
            let filteredList = [];
            let uList = [];
            let [first, second] = list;
            //console.log('f22:',first);
            //console.log('f22s:',second);
            
            for(let a=0; a < first.length; a++){
                //console.log('a:', a);
                for(let b=0; b < second.length; b++){
                    //console.log('b:', b);
                    //console.log(`${secondBatch[a]}, ${newBatch[b]}`);
                    uList.push([{sfn:first[a].sfn, stn:first[a].stn}, {sfn:second[b].sfn, stn:second[b].stn}]);
                }
            }
    
            index = 2;
            //console.log('uList:', uList);
            while(index < times){
                //console.log('ran:', index);
                let filter = list[index];
                //console.log('f:', filter);
                for(let x=0; x < uList.length; x++){
                    for(let y=0; y < filter.length; y++){
                        filteredList.push([...uList[x], {sfn:filter[y].sfn, stn:filter[y].stn}]);
                    }
                }
                index++;
                //console.log('fl:', filteredList);
                uList = [...filteredList];
                filteredList = [];
                //console.log('ul:', uList);
                
            }
        
            //console.log('fl:', filteredList);
            //original version
            return uList;
            //return filteredList;

        } else {
            return list;
        }
    
    
    }




    //test for updating search filters that were selected
export function updateSearchFilters2(products:Product[], searchFilters:SearchFilter[], initialSearchFilters:SearchFilter[]):[SearchFilter[],Product[]] {
        //console.log('sfs:', searchFilters);
        //console.log('isfs:', initialSearchFilters);
        const ssfs:SearchFilter[] = getSelectedSearchFilters(searchFilters);
        const usfs:SearchFilter[] = getUnselectedSearchFilters(searchFilters);
        //selected products test
        //console.log('ssfs:', ssfs, 'usfs:', usfs);
        usfs.forEach((sf)=>{
            sf.searchTerms.forEach((st)=>{
                //console.log('3-19:', 'sf name:', sf.filterName, 'st name:', st.searchTermName, 'st seleted:', st.searchTermSelected);
            });
        });


        //console.log('usfs:', usfs);
        //update search term quantities in selected search filters
        //console.log('ssfs test:', ssfs[0], ssfs[1]);
        //console.log('usfs test:', usfs);
        if(ssfs.length === 2){
            //console.log('length equals 2');
            let counter:number = 0;
            //previous version
            //let searchTermList:string[][] = [];
            let searchTermList:{sfn:string, stn:string}[][] = [];
            //previous version
            //let filteredSearchTermList:string[][] = [];
            let filteredSearchTermList:{sfn:string, stn:string}[][] = [];
            let productsSelected:Product[] = [];
            //loop through the selected search filters first
            while(counter < ssfs.length){
                const ssf:SearchFilter = ssfs[counter];
                //previous version
                //searchTermList = [filterSearchTerms(ssf.searchTerms)];
                searchTermList = [filterSearchTerms2(ssf)];
                //console.log('stl first:', searchTermList[0], searchTermList.length);
                //previous version
                //filteredSearchTermList = [...filterSearchTermList(searchTermList, searchTermList.length)];
                filteredSearchTermList = [...filterSearchTermList2(searchTermList, searchTermList.length)];
                //console.log('fstl first:', filteredSearchTermList[0]);
                ssfs.forEach((sf)=>{
                    if(sf !== ssf){
                        sf.searchTerms.forEach((searchTerm)=>{
                            //console.log('st22:', searchTerm.searchFilterName, searchTerm.searchTermName, searchTerm.searchTermQty);
                            products.forEach((product)=>{
                                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                    if(searchFilterTerm.searchFilterTermValue === searchTerm.searchTermName 
                                        && searchFilterTerm.searchFilterTermName === searchTerm.searchFilterName){
                                        filteredSearchTermList.forEach((filteredSearchTerms)=>{
                                            //filteredSearchTerms.forEach((filteredSearchTerm)=>{
                                                let searchFilterTermFound:boolean = false;
                                                const increaseSearchTermQty:boolean = filteredSearchTerms.some((filteredSearchTerm)=>{
                                                    searchFilterTermFound = false;
                                                    //console.log('fst:', filteredSearchTerm);
                                                    //console.log('sftf:', searchFilterTermFound);
                                                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                                    //console.log(`sft.value:${sft.sftValue} === filterStListItem:${item}`)
                                                    //console.log(`sft.value:${searchFilterTerm.searchFilterTermValue} === filterStListItem:${filteredSearchTerm}`)
                                                    if(searchFilterTerm.searchFilterTermValue === filteredSearchTerm.stn 
                                                        && searchFilterTerm.searchFilterTermName === ssf.filterName){
                                                        //searchTerm.searchTermQty += 1;
                                                        searchFilterTermFound = true;
                                                    }
                                                });

                                                return searchFilterTermFound;
                                            });

                                            if(increaseSearchTermQty){
                                                //console.log('istq:', increaseSearchTermQty, searchTerm);
                                                if(!foundSearchTermProduct(product, productsSelected)){
                                                    productsSelected.push(product);
                                                }
                                                searchTerm.searchTermQty += 1;
                                                //console.log('st22:', searchTerm.searchFilterName, searchTerm.searchTermName, searchTerm.searchTermQty, productsSelected.length);
                                            }
            
                                        });
                                    }
                                });
                            });
                        });
                    }
                });
                counter++;
            }

            //console.log('ssfs:', ssfs, productsSelected.length);

            searchTermList.length = 0;
            filteredSearchTermList.length = 0;
            ssfs.forEach((ssf)=>{
                //previous verison
                //searchTermList.push(filterSearchTerms(ssf.searchTerms));
                searchTermList.push(filterSearchTerms2(ssf));
            });
            //console.log('stl second:', searchTermList);
            //previous version
            //filteredSearchTermList = [...filterSearchTermList(searchTermList, searchTermList.length)];
            filteredSearchTermList = [...filterSearchTermList2(searchTermList, searchTermList.length)];
            //console.log('fstl second:', filteredSearchTermList);
            //console.log('f22:', filteredSearchTermList);
            //loop through the unselected search filters last
            usfs.forEach((usf)=>{
                //console.log('usf:', usf);
                //if(usf !== ssfs[0] && ssfs[1]){
                    usf.searchTerms.forEach((searchTerm)=>{
                        products.forEach((product)=>{
                            product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                if(searchFilterTerm.searchFilterTermValue === searchTerm.searchTermName 
                                    && searchFilterTerm.searchFilterTermName === searchTerm.searchFilterName){
                                    
                                    //filteredSearchTermList.forEach((filteredSearchTerms)=>{
                                        const increaseSearchTermQty:boolean = filteredSearchTermList.some((filteredSearchTerms)=>{
                                                //console.log('fsts:', filteredSearchTerms);
                                                let searchFilterTermFound:boolean = false;
                                                const increaseSearchTermQty:boolean = filteredSearchTerms.every((filteredSearchTerm)=>{
                                                    searchFilterTermFound = false;
                                                    //console.log('fst:', filteredSearchTerm);
                                                    //console.log('sftf:', searchFilterTermFound);
                                                    product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                                        if(searchFilterTerm.searchFilterTermValue === filteredSearchTerm.stn
                                                            && searchFilterTerm.searchFilterTermName === filteredSearchTerm.sfn){
                                                            //console.log('pn22:', product.productName,'sftn:', searchFilterTerm.searchFilterTermName);

                                                            searchFilterTermFound = true;
                                                            //searchTerm.searchTermQty += 1;
                                                        }
                                                    });
                                                    return searchFilterTermFound;
                                                });

                                                /*
                                                if(increaseSearchTermQty){
                                                    console.log('istq:', increaseSearchTermQty, searchTerm);
                                                    searchTerm.searchTermQty += 1;
                                                }
                                                */

                                                return increaseSearchTermQty;
                
                                            });

                                            if(increaseSearchTermQty){
                                                //console.log('istq:', increaseSearchTermQty, searchTerm);
                                                searchTerm.searchTermQty += 1;
                                            }
                                }
                            });
                        });
                    });
                //}
            });

            const stps = getSearchTermProducts(productsSelected, ssfs);
            //console.log('stns:', searchTermNamesSelected);
            //console.log('ps:', productsSelected);
            //console.log('sfs:', searchFilters);
            //console.log('usfs:', removeZeroAmountSearchTermQtys(null, searchFilters));
            //current version
            const sfs = removeZeroAmountSearchTermQtys(null, searchFilters);
            return [sfs, stps];
            //return [...searchFilters];
            //setSearchFilters

        } else if(ssfs.length >= 3){
            //console.log('length equals 3');
            let counter:number = 0;
            //previous version
            //let searchTermList:string[][] = [];
            let searchTermList:{sfn:string, stn:string}[][] = [];
            //previous version
            //let filteredSearchTermList:string[][] = [];
            let filteredSearchTermList:{sfn:string, stn:string}[][] = [];
            let productsSelected:Product[] = [];
            //loop through the selected search filters first
            while(counter < ssfs.length){
                searchTermList.length = 0;
                filteredSearchTermList.length = 0;
                const ssf:SearchFilter = ssfs[counter];
                ssfs.forEach((sf)=>{
                    if(sf !== ssf){
                        //previous version
                        //searchTermList.push(filterSearchTerms(sf.searchTerms));
                        searchTermList.push(filterSearchTerms2(sf));
                    }
                });
                //searchTermList = [filterSearchTerms(ssf.searchTerms)];
                //console.log('stl:', searchTermList);
                //previous version
                //filteredSearchTermList = [...filterSearchTermList(searchTermList, searchTermList.length)];
                filteredSearchTermList = [...filterSearchTermList2(searchTermList, searchTermList.length)];
                //console.log('fstl:', filteredSearchTermList[0], filteredSearchTermList[1]);

                //loop through the unselected search filters last
                ssfs.forEach((sf)=>{
                    //console.log('sf:', sf);
                    if(sf === ssf){
                        //console.log('sf:', sf);
                        sf.searchTerms.forEach((searchTerm)=>{
                            products.forEach((product)=>{
                                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                    if(searchFilterTerm.searchFilterTermValue === searchTerm.searchTermName 
                                        && searchFilterTerm.searchFilterTermName === searchTerm.searchFilterName){
                                        //filteredSearchTermList.forEach((filteredSearchTerms)=>{
                                        const increaseSearchTermQty:boolean = filteredSearchTermList.some((filteredSearchTerms)=>{
                                            //console.log('fsts:', filteredSearchTerms);
                                                let searchFilterTermFound:boolean = false;
                                                const increaseSearchTermQty:boolean = filteredSearchTerms.every((filteredSearchTerm)=>{
                                                    //console.log('fst:', filteredSearchTerm);
                                                    searchFilterTermFound = false;
                                                    //console.log('fst:', filteredSearchTerm);
                                                    //console.log('sftf:', searchFilterTermFound);
                                                    product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                                        //console.log(`sft.value:${sft.sftValue} === filterStListItem:${item}`)
                                                        if(searchFilterTerm.searchFilterTermValue === filteredSearchTerm.stn
                                                            && searchFilterTerm.searchFilterTermName === filteredSearchTerm.sfn){
                                                            //console.log(`sftv:${searchFilterTerm.searchFilterTermValue} === fst:${filteredSearchTerm}`)
                                                            searchFilterTermFound = true;
                                                            //searchTerm.searchTermQty += 1;
                                                        }
                                                    });
                                                    return searchFilterTermFound;
                                                });

                                                /*
                                                if(increaseSearchTermQty){
                                                    console.log('istq:', increaseSearchTermQty, searchTerm);
                                                    searchTerm.searchTermQty += 1;
                                                }
                                                */

                                                return increaseSearchTermQty;
                
                                            });

                                            if(increaseSearchTermQty){
                                                //console.log('istq:', increaseSearchTermQty, searchTerm);
                                                if(!foundSearchTermProduct(product, productsSelected)){
                                                    //console.log('st:', searchTerm);
                                                    productsSelected.push(product);
                                                }
                                                searchTerm.searchTermQty += 1;
                                            }


                                    }
                                });
                            });
                        });
                    }
                });

                counter++;
            }

            searchTermList.length = 0;
            filteredSearchTermList.length = 0;
            ssfs.forEach((ssf)=>{
                //previous version
                //searchTermList.push(filterSearchTerms(ssf.searchTerms));
                searchTermList.push(filterSearchTerms2(ssf));
            });
            //console.log('stl second:', searchTermList);
            //previous version
            //filteredSearchTermList = [...filterSearchTermList(searchTermList, searchTermList.length)];
            filteredSearchTermList = [...filterSearchTermList2(searchTermList, searchTermList.length)];
            //console.log('fstl second:', filteredSearchTermList);
            //loop through the unselected search filters last
            usfs.forEach((usf)=>{
                //console.log('usf:', usf);
                //if(usf !== ssfs[0] && ssfs[1]){
                    usf.searchTerms.forEach((searchTerm)=>{
                        products.forEach((product)=>{
                            product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                if(searchFilterTerm.searchFilterTermValue === searchTerm.searchTermName 
                                    && searchFilterTerm.searchFilterTermName === searchTerm.searchFilterName){
                                    //filteredSearchTermList.forEach((filteredSearchTerms)=>{
                                        const increaseSearchTermQty:boolean = filteredSearchTermList.some((filteredSearchTerms)=>{
                                                let searchFilterTermFound:boolean = false;
                                                const increaseSearchTermQty:boolean = filteredSearchTerms.every((filteredSearchTerm)=>{
                                                    searchFilterTermFound = false;
                                                    //console.log('fst:', filteredSearchTerm);
                                                    //console.log('sftf:', searchFilterTermFound);
                                                    product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                                        //console.log(`sft.value:${sft.sftValue} === filterStListItem:${item}`)
                                                        if(searchFilterTerm.searchFilterTermValue === filteredSearchTerm.stn
                                                            && searchFilterTerm.searchFilterTermName === filteredSearchTerm.sfn){
                                                            //console.log(`sftv:${searchFilterTerm.searchFilterTermValue} === fst:${filteredSearchTerm}`)
                                                            searchFilterTermFound = true;
                                                            //searchTerm.searchTermQty += 1;
                                                        }
                                                    });
                                                    return searchFilterTermFound;
                                                });

                                                /*
                                                if(increaseSearchTermQty){
                                                    console.log('istq:', increaseSearchTermQty, searchTerm);
                                                    searchTerm.searchTermQty += 1;
                                                }
                                                */

                                                return increaseSearchTermQty;
                
                                            });

                                            if(increaseSearchTermQty){
                                                //console.log('istq:', increaseSearchTermQty, searchTerm);
                                                searchTerm.searchTermQty += 1;
                                            }
                                }
                            });
                        });
                    });
                //}
            });

            const stps = getSearchTermProducts(productsSelected, ssfs);
            //console.log('ps:', productsSelected);

            //console.log('usfs:', removeZeroAmountSearchTermQtys(null, searchFilters));
            //current version
            const sfs = removeZeroAmountSearchTermQtys(null, searchFilters);
            //return [...searchFilters];
            return [sfs, stps];

        } else {
            //if(ssfs.length === 1){
                //console.log('length equals 1');
                //console.log('p:', products);
                let ssf:SearchFilter = ssfs[0];
                //console.log('ssf test:', ssf);
                //console.log('usfs:', usfs);
                //console.log('ssfs:', ssfs);
                //previous version
                //let searchTermList:string[][] = [filterSearchTerms(ssf.searchTerms)];
                let searchTermList:{sfn:string, stn:string}[][] = [filterSearchTerms2(ssf)];
                //previous version
                //let filteredSearchTermList:string[][] = [...filterSearchTermList(searchTermList, searchTermList.length)];
                let filteredSearchTermList:{sfn:string, stn:string}[][] = [...filterSearchTermList2(searchTermList, searchTermList.length)];
                let productsSelected:Product[] = [];
                //console.log('stl:', searchTermList,'fstl:', filteredSearchTermList);
                //console.log('fstl:', filteredSearchTermList);
                //more than one search filter asigned to store area type
                if(usfs.length > 0){
                    usfs.forEach((usf)=>{
                        usf.searchTerms.forEach((searchTerm)=>{
                            products.forEach((product)=>{
                                //console.log('product:', product);
                                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                    
                                    if(searchFilterTerm.searchFilterTermValue === searchTerm.searchTermName
                                        && searchFilterTerm.searchFilterTermName === searchTerm.searchFilterName){
                                        //console.log('match1:', searchFilterTerm.searchFilterTermValue, searchTerm.searchTermName)
                                        filteredSearchTermList.forEach((filteredSearchTerms)=>{
                                            //console.log('fsts:', filteredSearchTerms);
                                            //filteredSearchTerms.forEach((filteredSearchTerm)=>{
                                                let searchFilterTermFound:boolean = false;
                                                const increaseSearchTermQty:boolean = filteredSearchTerms.some((filteredSearchTerm)=>{
                                                    searchFilterTermFound = false;
                                                    //console.log('fst:', filteredSearchTerm);
                                                    //console.log('sftf:', searchFilterTermFound);
                                                    product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                                        //console.log(`sft.value:${searchFilterTerm.searchFilterTermValue} === filterStListItem:${filteredSearchTerm}`)
                                                        if(searchFilterTerm.searchFilterTermValue === filteredSearchTerm.stn
                                                            && searchFilterTerm.searchFilterTermName === ssf.filterName){
                                                                //console.log('match1:', product.productName);
                                                            //console.log('pid:', product.productName, searchFilterTerm.searchFilterTermId);
                                                            //console.log('match2:', searchFilterTerm.searchFilterTermValue === searchTerm.searchTermName)
                                                            //searchTerm.searchTermQty += 1;
                                                            searchFilterTermFound = true;
                                                        }
                                                    });
                                                    return searchFilterTermFound;
                                            });
    
                                            
                                            if(increaseSearchTermQty){
                                                //console.log('istq:', increaseSearchTermQty, searchTerm);                                            
                                                if(!foundSearchTermProduct(product, productsSelected)){
                                                    productsSelected.push(product);
                                                }
                                                
                                                searchTerm.searchTermQty += 1;
                                                //console.log('sts3-19:', searchTerm.searchTermId, searchTerm.searchFilterName, searchTerm.searchTermSelected);
                                            }
                                            
            
                                        });
                                    }
                                });
                            });
                        });
                    });

                } else {
                    //one search filter is asigned to store area type
                    //console.log('ssf5:', ssf);
                    ssf.searchTerms.forEach((searchTerm)=>{
                        products.forEach((product)=>{
                            //console.log('product:', product);
                            product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                
                                if(searchFilterTerm.searchFilterTermValue === searchTerm.searchTermName 
                                    && searchFilterTerm.searchFilterTermName === searchTerm.searchFilterName){
                                    filteredSearchTermList.forEach((filteredSearchTerms)=>{
                                        //filteredSearchTerms.forEach((filteredSearchTerm)=>{
                                            let searchFilterTermFound:boolean = false;
                                            const increaseSearchTermQty:boolean = filteredSearchTerms.some((filteredSearchTerm)=>{
                                                searchFilterTermFound = false;
                                                //console.log('fst:', filteredSearchTerm);
                                                //console.log('sftf:', searchFilterTermFound);
                                                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                                                    
                                                    //console.log(`sft.value:${searchFilterTerm.searchFilterTermValue} === filterStListItem:${filteredSearchTerm}`)
                                                    if(searchFilterTerm.searchFilterTermValue === filteredSearchTerm.stn){
                                                        //searchTerm.searchTermQty += 1;
                                                        searchFilterTermFound = true;
                                                    }
                                                });
                                                return searchFilterTermFound;
                                        });

                                        
                                        if(increaseSearchTermQty){
                                            //console.log('istq:', increaseSearchTermQty, searchTerm);                                            
                                            if(!foundSearchTermProduct(product, productsSelected)){
                                                productsSelected.push(product);
                                            }
                                            
                                            searchTerm.searchTermQty += 1;
                                        }
                                        
        
                                    });
                                }
                            });
                        });
                    });
                }



    

                //console.log('ps26:', productsSelected);
                //console.log('ssf:', ssf);
                //console.log('sfs after:', searchFilters);

                //console.log('usfs:', removeZeroAmountSearchTermQtys(ssf, searchFilters));
                const sfs = cloneInitialSearchFilterSelected(ssf, searchFilters, initialSearchFilters);
                //console.log('sfs:', sfs);
                //return sfs;
                //current version
                return [removeZeroAmountSearchTermQtys(ssf, sfs), productsSelected];
                //previous version
                //return [...searchFilters];
    
            //}
        }

    }

export function cloneInitialSearchFilterSelected(searchFilterSelected:SearchFilter, searchFilters:SearchFilter[], initialSearchFilters:SearchFilter[]):SearchFilter[] {
        let initialSearchFilterFound = initialSearchFilters.find((initialSearchFilter)=>{
                                            return initialSearchFilter.filterName === searchFilterSelected.filterName;
                                        });
        if(typeof initialSearchFilterFound !== 'undefined'){
            let initialSearchFilterFoundClone = cloneDeep(initialSearchFilterFound);
            initialSearchFilterFoundClone = updateSerchTermSelectedProperty(initialSearchFilterFoundClone, searchFilterSelected);
            
            /*initial version and layout
            initialSearchFilterFound = updateSerchTermSelectedProperty(initialSearchFilterFound, searchFilterSelected);
            let initialSearchFilterFoundClone = cloneDeep(initialSearchFilterFound);
            */
            return searchFilters.map((searchFilter, index)=>{
                        if(searchFilter.filterName === initialSearchFilterFoundClone.filterName){
                            return initialSearchFilterFoundClone;
                        } else {
                            return searchFilter;
                        }
                    });

        } else {
            return searchFilters;
        }
    }

export function updateSerchTermSelectedProperty(initialSearchFilterFound:SearchFilter, searchFilterSelected:SearchFilter):SearchFilter {
        initialSearchFilterFound.searchFilterSelected = searchFilterSelected.searchFilterSelected;
        const isffs = initialSearchFilterFound.searchTerms.map((searchTerm)=>{
                        searchFilterSelected.searchTerms.forEach((st)=>{
                            if((searchTerm.searchTermName === st.searchTermName) && st.searchTermSelected){
                                searchTerm.searchTermSelected = st.searchTermSelected;
                            } else if((searchTerm.searchTermName === st.searchTermName) && !st.searchTermSelected){
                                searchTerm.searchTermSelected = st.searchTermSelected;
                            }
                        });
                        return searchTerm;  
                    });

        initialSearchFilterFound.searchTerms = isffs;
        return initialSearchFilterFound;
    }

export function removeZeroAmountSearchTermQtys(
                        searchFilterSelected:SearchFilter | null,
                        searchFilters:SearchFilter[]
                        ):SearchFilter[]{
        const sfs = searchFilters.map((searchFilter)=>{
                    if((searchFilterSelected !== null && searchFilter !== searchFilterSelected)
                        || searchFilterSelected === null){
                        const updatedSearchTerms = searchFilter.searchTerms.filter((searchTerm)=>{
                            return (searchTerm.searchTermQty >= 0 && searchTerm.searchTermSelected) 
                                    || (searchTerm.searchTermQty >= 1 && !searchTerm.searchTermSelected);
                        });
                        searchFilter.searchTerms = updatedSearchTerms;
                        return searchFilter;

                    } else {
                        return searchFilter;
                    }
        });

        return removeNoSearchTermSearchFilters(sfs);
    }

export function removeNoSearchTermSearchFilters(searchFilters:SearchFilter[]):SearchFilter[]{
        return searchFilters.filter((searchFilter)=>{
                    return searchFilter.searchTerms.length > 0;
                });
    }

export function getDepartmentCategoryProducts(storeAreaType:string, storeDepartmentId:string, otherStoreAreaId:string):Promise<Product[]> {

            return new Promise((resolve, reject)=>{
                //new version with mariadb
                //fetch(`http://localhost:9500/get-department-category-products-mariadb/${departmentCategoryName}`,{
                //fetch(`http://localhost:9500/get-department-category-products-mariadb/${storeDepartmentId}/${departmentCategoryId}`,{
                fetch(`https://server.kando-proto-3.com/get-search-filter-term-products-mariadb/${storeAreaType}/${storeDepartmentId}/${otherStoreAreaId}`,{
                //original version with .json files    
                //fetch(`http://localhost:9500/get-department-category-products/${departmentCategoryName}`,{
                    method:'GET',
                    headers:{
                        'accept':'application/json'
                    }
                })
                .then((resp)=>resp.json())
                .then((resp)=>{
                    //console.log('resp 10-26:', resp);
                    return resolve(resp);
                })
                .catch((err)=>console.error('get department category products error:', err));
            });


    }


export function updateDepartmentCategorySearchFilters(
                            storeAreaType:string,
                            storeAreaName:string,
                            storeAreaTypeIdSelected:string,
                            searchFilterNameSelected:string,
                            searchTermNameSelected:string
                            /*departmentCategoryName:string,
                            departmentCategoryId:string*/
                            ):Promise<SearchFilter[]> {

            const params = {storeAreaType, storeAreaName, storeAreaTypeIdSelected, searchFilterNameSelected, searchTermNameSelected};

            return new Promise((resolve, reject)=>{
                //current version
                //fetch(`http://localhost:9500/update-department-category-search-filters/${departmentCategoryName}/${searchFilterNameSelected}/${searchTermNameSelected}`,{
                //test version
                //fetch(`http://localhost:9500/update-department-category-search-filters-mariadb/${departmentCategoryName}/${searchFilterNameSelected}/${searchTermNameSelected}`, {
                //fetch(`http://localhost:9500/update-department-category-search-filters-mariadb/${departmentCategoryName}/${departmentCategoryId}/${searchFilterNameSelected}/${searchTermNameSelected}`, {
                //original version
                //fetch(`http://localhost:9500/update-department-category-search-filters-mariadb/${storeAreaType}/${storeAreaName}/${storeAreaTypeIdSelected}/${searchFilterNameSelected}/${searchTermNameSelected}`, {
                fetch(`https://server.kando-proto-3.com/update-department-category-search-filters-mariadb`, {
                    method:'PUT',
                    headers:{
                    'content-type':'application/json',
                    'accept':'application/json'
                    },
                    body:JSON.stringify(params)
                })
                .then((resp)=>resp.json())
                .then((resp)=>{
                    //console.log('resp218:',resp);
                    return resolve(resp);
                })
                .catch((err)=>console.error('get department category search filters error:', err));
            });


    }

export function getRelatedProducts(storeAreaType:string, searchFilterAreaName:string, keywordSearch:string, priceRange:PriceRange):Promise<Product[]> {
    //console.log('test 2-10:', 'sat:', storeAreaType, 'sfan:', searchFilterAreaName, 'ks:', keywordSearch);
    return fetch(`https://server.kando-proto-3.com/related-products-search-mariadb/${storeAreaType}/${searchFilterAreaName}`,{
        //original version with .json files    
        //fetch(`http://localhost:9500/get-department-category-products/${departmentCategoryName}`,{
            //method:'GET',
            method:'POST',
            headers:{
                'content-type':'application/json',
                'accept':'application/json'
            },
            body:JSON.stringify({priceRange, keywordSearch})
        })
        .then((resp)=>resp.json())
        .then(({products})=>{
            //console.log('rpps response 3-23:', products);
            return products;
        })
        .catch((err)=>console.error('get department category products error:', err));
}


export function setupRelatedProductsPromises(
                            searchFilterNameSelected:string, searchTermNameSelected:string,
                            storeAreaType:string, searchFilterAreaName:string, keywordSearch:string, priceRange:PriceRange
                            ):Promise<(Product[]|SearchFilter[])[]> {

        let rpSearchFilters:SearchFilter[] = [];

        const params = {searchFilterNameSelected, searchTermNameSelected};

        return new Promise((resolve, reject)=>{
                //fetch(`https://server.kando-proto-3.com/update-related-products-search-filters-mariadb/${searchFilterNameSelected}/${searchTermNameSelected}`, {
                fetch(`https://server.kando-proto-3.com/update-related-products-search-filters-mariadb`, {
                    method:'PUT',
                    headers:{
                    'accept':'application/json',
                    'content-type':'application/json'
                    },
                    body:JSON.stringify(params)
                })
                .then((resp)=>resp.json())
                .then((urpssfs)=>{
                    //console.log('urpssfs response:',urpssfs);
                    rpSearchFilters = urpssfs;
                    //original version
                    //return resolve(resp);
                    return getRelatedProducts(storeAreaType, searchFilterAreaName, keywordSearch, priceRange)
                            .then((products)=>{
                                resolve([rpSearchFilters, products]);
                            })
                            .catch((err:Error)=>{
                                console.error('related products error:', err.message);
                            })
                })
                .catch((err)=>console.error('get related products search filters error:', err));
        });



}




export function getSearchTermProducts(ps:Product[], ssfs:SearchFilter[]):Product[] {
        let productMatch:boolean = false;
        const filteredProducts = ps.filter((p)=>{            
                    const pm = ssfs.every((ssf)=>{
                                    productMatch = false;
                                    ssf.searchTerms.forEach((st)=>{
                                        if(st.searchTermSelected && st.searchTermQty > 0){
                                            p.searchFilterTerms.forEach((sft)=>{
                                                if(sft.searchFilterTermValue === st.searchTermName
                                                    && sft.searchFilterTermName === ssf.filterName){
                                                    productMatch = true;
                                                }
                                            });
                                        }
                                    });

                                    return productMatch;
                                });
                        
                            return pm;
                    });

        //console.log('fps:', filteredProducts);
        return filteredProducts;
    }

export function foundSearchTermProduct(product:Product, relatedProducts:Product[]):boolean {
        const foundProduct = relatedProducts.find((relatedProduct)=>{
            return relatedProduct === product;
        });

        return (typeof foundProduct === 'undefined') ? false : true;
    }

export default checkFilterSearchTerm2;