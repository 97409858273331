import React, {useState, useEffect, useRef} from 'react';
//import {Link, navigate, useMatch} from '@gatsbyjs/reach-router';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import {connect} from 'react-redux';
import Product from './product';
import ProductCategory from './productCategory';
import ShopProductDetail from './ShopProductDetail';
import ShopProductMiniCart from './ShopProductMiniCart';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import addDepartmentCategories, {addSearchFilters} from './helper-add-department-categories';
import ProductSubCategory from './productSubCategory';
import StoreDepartmentCategorySidebarSelector from './StoreDepartmentCategorySidebarSelector';
import StoreDepartmentCategorySidebarSelector2 from './StoreDepartmentCategorySidebarSelector2';
import ProductCategorySearchShortcutSelector from './ProductCategorySearchShortcutSelector';
import helperHighlightCategory from './helper-highlight-category';
import {sortByAtoZ, sortByZtoA, sortByNewest, sortByRatings, 
    sortByPriceHightoLow, sortByPriceLowtoHigh} from './helper-sort-by-option';
import PriceSlider3 from './PriceSlider3';
import helperSetOptionValue, {helperSetOptionValueNow} from './helper-set-option-value';
import {showGeneralProductsFilterNav, selectGeneralProductsFilterNav} from './redux/features/generalProductsFilterNav/generalProductsFilterNavSlice';
import {addSearchFilterParams, selectSearchFilterParams, SearchFilterParams} from './redux/features/searchFilterParams/searchFilterParamsSlice';
import { addSearchFilterProductVisibility } from './redux/features/searchFilterProductVisibility/searchFilterVisibilitySlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import SearchFilter from './searchFilter';
import cloneDeep from 'lodash/cloneDeep';
import checkFilterSearchTerm2, {checkSearchFiltersSelected, updateSearchFilters2} from './helper-search-filter-setup';
import PriceRange from './priceRange';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import loadNavLink from './loadNavLinkHelper';
import LoaderAnimation from './LoaderAnimation';
import './ShopProductCategory.css';



interface Props {
    //path:string;
    storeDepartment:StoreDepartment;
    storeDepartmentName:string;
    departmentCategoryName:string;
    storeDepartmentCategory:DepartmentCategory;
    productCategoryName:string;
    storeDepartmentId:string;
    productCategoryId:string;
    productCategory:ProductCategory;
    productCategoryProducts:Product[];
    keywordSearchType:string;
    sortByOptionValue:string;
    initialSearchFilters:SearchFilter[];
    sbatProductMobileNavFilterHandler:()=>void;
    sbatProductMobileNavSortByOptionHandler:(sbov:string)=>void;
    //addShopLoadDetails?:(shopLoadDetails:ShopLoadDetails)=>AddShopLoadDetails;
    loadNavLink:(e:React.MouseEvent, pathname:string, showLoader?:boolean)=>void;
    sssbatps: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
const mapDispatchToProps = {
    addShopLoadDetails
};
*/
interface ProductSelection {
    id:number;
}


const ShopByAreaTypeProductCategory: React.FunctionComponent<Props> = (props:Props) => {
    //console.log('match:', useMatch('/shop/:storeDepartmentName/:departmentCategoryName/:productCategoryName/:storeDepartmentId/:productCategoryId'));
    //const {addShopLoadDetails} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const searchFilterParamsRedux = useAppSelector(selectSearchFilterParams);
    //const generalProductsFilterNavRedux = useAppSelector(selectGeneralProductsFilterNav);
    const {sbatProductMobileNavFilterHandler, sbatProductMobileNavSortByOptionHandler, loadNavLink, sssbatps} = props;

    const [productCategories, setProductCategories] = useState<ProductCategory[] | []>([]);
    const [storeDepartmentName, setStoreDepartmentName] = useState(props.storeDepartmentName);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(props.departmentCategoryName);
    const [productCategoryName, setProductCategoryName] = useState(props.productCategoryName);
    const [productCategoryProducts, setProductCategoryProducts] = useState<Product[] | []>([]);
    const [storeDepartment, setStoreDepartment] = useState<StoreDepartment | null>(null);
    const [productCategory, setProductCategory] = useState<ProductCategory | null>(null);
    const [storeDepartmentCategory, setStoreDepartmentCategory] = useState<DepartmentCategory | null>(null);
    const [isSpecialDepartmentCategory, setIsSpecialDepartmentCategory] = useState(false);
    const [isSpecialProductCategory, setIsSpecialProductCategory] = useState(false);
    const [productCategoryProductTotal, setProductCategoryProductTotal] = useState(0);

    const [startSliderAmount, setStartSliderAmount] = useState(0);
    const [endSliderAmount, setEndSliderAmount] = useState(0);
    const [showProductCategoryProducts, setShowProductCategoryProducts] = useState(false);
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState('0%');
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState('100%');
    const [previousSlider1PostionLeftAmount, setPreviousSlider1PostionLeftAmount] = useState(0);
    const [previousSlider2PostionLeftAmount, setPreviousSlider2PostionLeftAmount] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(0);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(100);
    const [slider1ZIndex, setSlider1ZIndex] = useState(0);
    const [slider2ZIndex, setSlider2ZIndex] = useState(0);
    const [keywordSearchType, setKeywordSearchType] = useState('productCategory');
    const [keywordSearch, setKeywordSearch] = useState(props.productCategoryName);
    const [sortByOptionValue, setSortByOptionValue] = useState(props.sortByOptionValue);
    const [productCategorySearch, setProductCategorySearch] = useState('product');
    const [productSubCategorySelected, setProductSubCategorySelected] = useState<ProductSubCategory | null>(null);

    const [showPriceSlider, setShowPriceSlider] = useState(false);

    const [storeDepartmentId, setStoreDepartmentId] = useState(props.storeDepartmentId);
    const [productCategoryId, setProductCategoryId] = useState(props.productCategoryId);

    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [initialSearchFilters, setInitialSearchFilters] = useState<SearchFilter[]>(props.initialSearchFilters);
    //const [searchTermNamesSelected, setSearchTermNamesSelected] = useState<string[]>([]);
    //const [searchFilterNamesSelected, setSearchFilterNamesSelected] = useState<string[]>([]);
    const [searchTermIsSelected, setSearchTermIsSelected] = useState(false);
    const [showSpecialProductCategoryShortcut, setShowSpecialProductCategoryShortcut] = useState(true);

    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(60);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Product[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No store locations have been added.');

    const shopProductsMobileNavRef = useRef<HTMLDivElement>(null);

    const [showMiniCart, setShowMiniCart] = useState(false);
    const showMiniCartRef = useRef<boolean>(false);

    const [showProductCategorySearchShortcutSelector, setShowProductCategorySearchShortcutSelector] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    //console.log('sbatd:', 'sd:', props.storeDepartment, 'sdn:', props.storeDepartmentName, 'sdid:', props.storeDepartmentId, 'dc:', props.storeDepartmentCategory, 'pcps:', props.productCategoryProducts, 'kst:', props.keywordSearchType);

    //console.log('pcn2:', productCategoryName, props.productCategoryName);

    //const [productSelection, setProductSelection] = useState<ProductSelection>(getPreviousProductSelection(sessionStorage.getItem('sbat-product-selection')));
    //const productCategoryProductContainerRefs = useRef<HTMLDivElement[]>([]);

    /*previous version
    useEffect(()=>{
        console.log('test 1');
        setStoreDepartmentName(props.storeDepartmentName);
        setDepartmentCategoryName(props.departmentCategoryName);
        setProductCategoryName(props.productCategoryName);
        setStoreDepartmentId(props.storeDepartmentId);
        setProductCategoryId(props.productCategoryId);
        console.log('useEffect:', props.productCategoryName);
        setKeywordSearch(props.productCategoryName);
        
        //setShowProductCategoryProducts(false);
    },[props.productCategoryName]);
    */

    //console.log('sbatpc5:');

    useEffect(()=>{
        setSortByOptionValue(props.sortByOptionValue);
        setStoreDepartmentName(props.storeDepartmentName);
        setDepartmentCategoryName(props.departmentCategoryName);
        setProductCategoryName(props.productCategoryName);
        setStoreDepartmentId(props.storeDepartmentId);
        setProductCategory(props.productCategory);
        setProductCategoryId(props.productCategoryId);
        //setProductCategoryProducts(props.productCategoryProducts);
        setProductCategoryProductTotal(props.productCategoryProducts.length);
        setStoreDepartment(props.storeDepartment);
        setStoreDepartmentCategory(props.storeDepartmentCategory);
        setKeywordSearchType(props.keywordSearchType);

        //console.log('pc4:', props.productCategory);
        //getProductCategorySearchFilters(props.productCategory);
        //console.log('pcsfs:', props.productCategory.searchFilters);
        if(props.productCategory !== null){
            setInitialSearchFilters(props.initialSearchFilters);
        }
        
        //console.log('pc7:', props.productCategory, props.productCategoryProducts);
        /*previous version
        helperSetOptionValue(
            props.sortByOptionValue, 
            props.productCategoryProducts, 
            false,
            setProductCategoryProducts, 
            setShowProductCategoryProducts
            );
        */
        const hsovps = helperSetOptionValueNow(props.sortByOptionValue, props.productCategoryProducts);
        setProductCategoryProducts(hsovps);

        const prices = getLowHighProductPrice(hsovps);

        if(prices.length > 0){
            //console.log('ps7:', prices, hsovps);
            setPreviousSlider1PostionLeftAmount(prices[0]);
            setPreviousSlider2PostionLeftAmount(prices[1]);

        }

        setShowProductCategoryProducts(true);
        getAnyItemList(listChangerBtnPosition, hsovps, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, hsovps);

        //setDepartmentProducts([]);
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        
        //console.log('loaded again');

        if(window.innerWidth >= 768 && !showMiniCartRef.current){
            showMiniCartRef.current = true;
            setShowMiniCart(true);
        }

        if(window.innerWidth < 768 && showMiniCartRef.current){
            showMiniCartRef.current = false;
            setShowMiniCart(false);
        }
        
    },[props.productCategoryProducts]);


    
    useEffect(()=>{
        
        if(!searchFilterParamsRedux.showProductSubCategoryFilter){
            setShowProductCategorySearchShortcutSelector(false);

        } else {
            setShowProductCategorySearchShortcutSelector(true);
        }
        
    },[searchFilterParamsRedux.showProductSubCategoryFilter]);
    

    /*
    useEffect(()=>{
        console.log('loaded again');
        //window.scrollTo(0,0);

        //setShowProductCategoryProducts(true);
        //getAnyItemList(listChangerBtnPosition, props.productCategoryProducts, itemsPerList, showItemsPerList);
        //displayListSorter(itemsPerList, props.productCategoryProducts);
        console.log('sbatpcps:', props.productCategoryProducts);

        //imageRefs.current.length = 0;
        //setImageRefsLoaded(false);

        //test version
        //remove comments after testing
        //getDepartmentWithProductCategories(storeDepartmentId!, productCategoryId!);  


    },[productCategoryName]);
    */

    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    function getLowHighProductPrice(products:Product[]):number[] {
        const prices:number[] = [];

        products.forEach((p)=>{
            prices.push(p.eachPrice);
        });

        if(prices.length > 0){
            return [Math.min(...prices), Math.max(...prices)];

        } else {
            return [];
        }
        
    }
    

    function getDepartmentWithProductCategories(storeDepartmentId:string, productCategoryId:string):void {
        fetch(`https://server.kando-proto-3.com/shop-store-department-with-product-category-mariadb/${storeDepartmentId}/${productCategoryId}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then((dept:StoreDepartment)=>{
            //console.log('dept:', dept);
            let sd:StoreDepartment = new StoreDepartment({
                                        departmentId:dept.departmentId,
                                        departmentName:dept.departmentName,
                                        departmentImage:dept.departmentImage,
                                        departmentCategories: addDepartmentCategories(dept.departmentCategories)
                                    });
            
            setStoreDepartment(sd);
            //console.log('sd:', sd, 'dcn:', departmentCategoryName, 'sdId:', storeDepartmentId, 'pcId:', productCategoryId, 'pcs:', productCategories);
            const dc = getStoreDepartmentCategory(departmentCategoryName as string, sd.departmentCategories);
            setStoreDepartmentCategory(dc);

            const pcId = window.parseInt(productCategoryId, 10);
            const pcs = getProductCategories(dc, pcId);
            
            const pc = getProductCategory(pcId, pcs as ProductCategory[]);
            //console.log('pc7:', pc);
            if(pc !== null){
                pc.showProductSubCategoryShortcut = !!pc.showProductSubCategoryShortcut;
                setProductCategory(pc);
                getSearchFilters(pc as ProductCategory);
            }

            const ps = getPCProducts(pcs, pcId);
            //console.log('p 10-16:', addProducts(products));
            setProductCategories(pcs);
            //setProductCategoryProducts([...addProducts(products)]);
            if(ps.length === 0){
                setKeywordSearchType('noProductsAdded');
                setProductCategoryProducts([]);
                setSearchFilters([]);
                setShowPriceSlider(false);

            } else {
                setProductCategoryProducts([...ps]);

                getAnyItemList(listChangerBtnPosition, ps, itemsPerList, showItemsPerList);
                displayListSorter(itemsPerList, ps);

                //getSliderAmount(addProducts(products));
                getSliderAmount([...ps]);
                setSliderPosition(0);
                setPreviousSlider1TotalDistance(0);
                setPreviousSlider2TotalDistance(100);

                
            }


            setShowProductCategoryProducts(true);
    
        })
        .catch(err=>console.log('error:', err));
    }


    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:');
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }

    
    function clearAllExistingImages():void {
        //console.log('irs spc');
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
    }


    /*
    function getProductCategoryProductContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && productCategoryProductContainerRefs.current.length > 0){
            productCategoryProductContainerRefs.current.length = 0;
        }
    
        if(el !== null && productCategoryProductContainerRefs.current.length === 0){
            productCategoryProductContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = productCategoryProductContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                productCategoryProductContainerRefs.current.push(el);
            }

        }

        console.log('pcpcrs:', productCategoryProductContainerRefs.current);
        
        const foundIdElement = productCategoryProductContainerRefs.current.filter((el)=>{
                                    return el.id === productSelection.id.toString();
                                });
        
        console.log('fie:', foundIdElement);
        if(foundIdElement.length === 1){
            console.log('fie:');
            foundIdElement[0].scrollIntoView({behavior:'smooth'});

        }
        
    }

    function getPreviousProductSelection(previousProductSelection:string|null):ProductSelection {
        if(previousProductSelection === null){
            return {id:0};

        } else {
            const pps = JSON.parse(previousProductSelection);
            console.log('pps:', pps);
            return {id:pps};
        }
    }

    function setProductSelectionId(productSelectionId:number):void {
        sessionStorage.setItem('sbat-product-selection', `${productSelectionId}`);
    }
    */
    


    /*
    useEffect(()=>{
        window.addEventListener('resize', shopByAreaTypeResizeHandler)
        return cleanupShopByAreaTypeResizeHandler;
    },[])

    function cleanupShopByAreaTypeResizeHandler():void {
        window.removeEventListener('resize', shopByAreaTypeResizeHandler);
    }

    function shopByAreaTypeResizeHandler(e:Event):void {
        if(window.innerWidth >= 768 && !showMiniCartRef.current){
            showMiniCartRef.current = true;
            setShowMiniCart(true);
        }

        if(window.innerWidth < 768 && showMiniCartRef.current){
            showMiniCartRef.current = false;
            setShowMiniCart(false);
        }
    }

    */


    function addProductCategoryProducts(productCategories:ProductCategory[], productCategoryName:string):Product[] {
        //console.log('productCategoryName:', productCategoryName);
        let productCategory = productCategories.find((productCategory)=>{
                                                    return productCategory.productCategoryName === productCategoryName;
                                                });
                //console.log('products:', (productCategory as ProductCategory).products);
                setProductCategoryProductTotal((productCategory as ProductCategory).products.length);
            return addProducts((productCategory as ProductCategory).products);
    }

    function addProducts(products:Product[]):Product[]{
        //console.log('length:', products.length);
        //setProductCategoryProductTotal(products.length);
        return products.map((product)=>{
            //console.log('p:', product);
            return new Product({...product});
        });
    }

    function getStoreDepartmentCategory(departmentCategoryName:string, departmentCategories:DepartmentCategory[]):DepartmentCategory {
        const foundDepartmentCategory = departmentCategories.find((departmentCategory)=>{
            return departmentCategory.departmentCategoryName === departmentCategoryName;
        }) as DepartmentCategory;
        
        if(foundDepartmentCategory.isSpecialDepartmentCategory){
            setIsSpecialDepartmentCategory(true);
            return foundDepartmentCategory;
        } else {
            setIsSpecialDepartmentCategory(false);
            return foundDepartmentCategory;
        }
    
    }

    function getProductCategories(dc:DepartmentCategory, pcId:number):ProductCategory[] {
        const pcs = dc.productCategories.map((pc)=>{
                        return new ProductCategory({
                                                    ...pc,
                                                    productSubCategories:getProductSubCategories(pc.productSubCategories)
                                                });
                    });

        return pcs;
    }

    function getProductCategory(productCategoryId:number, productCategories:ProductCategory[]):ProductCategory | null {
        //console.log('test passed againg 4-9');
        const foundProductCategory = productCategories.find((productCategory)=>{
            return productCategory.productCategoryId === productCategoryId;
        }) as ProductCategory;
        
        //console.log('fpc:', foundProductCategory);
        if(typeof foundProductCategory !== 'undefined'){           
            return foundProductCategory;
        } else {
            return null;
        }
    
    }

    function getProductSubCategories(pscs:ProductSubCategory[]):ProductSubCategory[]{
        //console.log('pscs:', pscs);
        return pscs.map((psc)=>{
                    return new ProductSubCategory({...psc});
                });
    }

    function getSearchFilters(productCategory:ProductCategory):void {
        //console.log('dc:', productCategory);
        let searchFilters:SearchFilter[] = [];
        productCategory.searchFilters.forEach((searchFilter)=>{
            //console.log('sfn:', searchFilter.filterName);
            if(searchFilter.showFilter){
                searchFilters.push(searchFilter);
            }
        });
        //console.log('sfs:', searchFilters);
        const searchFiltersBeforeUpdate = cloneDeep(searchFilters);

        let productCategoryProducts:Product[] = [];
        //departmentCategory.productCategories.forEach((productCategory)=>{
            productCategory.products.forEach((product)=>{
                productCategoryProducts.push(product);
                
                product.searchFilterTerms.forEach((searchFilterTerm)=>{
                    //console.log('sft:', searchFilterTerm);
                    searchFilters.forEach((searchFilter, index)=>{
                        //console.log('fn:', searchFilter.filterName);
                        //console.log('sftn:', searchFilterTerm.searchFilterTermName);
                        if(searchFilter.filterName === searchFilterTerm.searchFilterTermName){
                            //console.log('pass:', searchFilter.filterName);
                            searchFilter.searchTerms.forEach((searchTerm, index)=>{
                                //console.log('fn:', searchFilter.filterName);
                                if(searchTerm.searchTermName === searchFilterTerm.searchFilterTermValue){
                                    searchTerm.searchTermQty += 1;
                                }
                                
                            });
                            
                        }

                    });
                    //console.log('sfs:', searchFilters);
                    
                })
                
            });
        //});

        
        if(checkSearchFiltersSelected(searchFiltersBeforeUpdate)){
            setInitialSearchFilters([...searchFilters]);
            const [updatedSearchFilters, productsSelected] = updateSearchFilters2(productCategoryProducts, searchFiltersBeforeUpdate, searchFilters);
            //console.log('usfs:', updatedSearchFilters);
    
            if(productsSelected.length === 0){
                //console.log('test');
                setKeywordSearchType('noResults');
                setSearchTermIsSelected(true);
            } else {
                setKeywordSearchType('results');
            }
            
            setSearchFilters([...updatedSearchFilters]);
            setProductCategoryProducts(productsSelected);
            //console.log('p.length:', productsSelected.length);
            setProductCategoryProductTotal(productsSelected.length);
            setShowSpecialProductCategoryShortcut(false);

            //return true;
            
        } else {

            const fsfs = removeZeroQtySearchTerms(searchFilters);
            const ufsfs = removeZeroQtySearchFilters(fsfs);

            //console.log('sfs:', ufsfs);
            setInitialSearchFilters([...ufsfs]);
            setSearchFilters([...ufsfs]);
            setShowSpecialProductCategoryShortcut(true);

        }
        
        
    }

    function removeZeroQtySearchTerms(sfs:SearchFilter[]):SearchFilter[] {
        sfs.forEach((sf)=>{
            const sts = sf.searchTerms.filter((st)=>{
                            if(st.searchTermQty === 0){
                                //console.log('stn:', st.searchTermName, 'stQty:', st.searchTermQty);
                            }
                            return st.searchTermQty !== 0;
                        });

                sf.searchTerms = sts;
            });

        //console.log('usfs 5-3:', sfs);
        return sfs;
    }

    function removeZeroQtySearchFilters(sfs:SearchFilter[]):SearchFilter[]{
        const fsfs = sfs.filter((sf)=>{
                        return sf.searchTerms.length > 0;
                    });
        //console.log('fsfs:', fsfs);
        return fsfs;
    }

    function getSliderAmount(products:Product[]):void {
        const sortedProducts = products.sort(sortByPriceHightoLow);
        const highestPricedProduct:Product = sortedProducts[0] as Product;
        const lowestPricedProduct:Product = sortedProducts[sortedProducts.length - 1] as Product;
        //console.log('high price:', highestPricedProduct.eachPrice);
        //console.log('low price:', lowestPricedProduct.eachPrice);
        if((highestPricedProduct.eachPrice - lowestPricedProduct.eachPrice) > 1){
            setShowPriceSlider(true);
            //console.log('yes');
        } else {
            setShowPriceSlider(false);
            //console.log('no');
        }
        setStartSliderAmount(lowestPricedProduct.eachPrice);
        setEndSliderAmount(highestPricedProduct.eachPrice);
        setPreviousSlider1PostionLeftAmount(lowestPricedProduct.eachPrice);
        setPreviousSlider2PostionLeftAmount(highestPricedProduct.eachPrice);
        if(lowestPricedProduct.eachPrice === highestPricedProduct.eachPrice){
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('0%');
        } else {
            setPreviousSlider1PositionLeft('0%');
            setPreviousSlider2PositionLeft('100%');
        }

    }

    //finish this function 5-5-20
    function priceSliderSearchResults(
        keywordSearch:string,
        startSliderAmount:number,
        endSliderAmount:number,
        previousSlider1PositionLeft:string,
        previousSlider2PositionLeft:string,
        previousSlider1PostionLeftAmount:number,
        previousSlider2PostionLeftAmount:number,
        sliderPosition:number,
        previousSlider1TotalDistance:number,
        previousSlider2TotalDistance:number,
        selectedSlider1ZIndex:number,
        selectedSlider2ZIndex:number
        ):void {
        //console.log('keywordSearch:', keywordSearch);
        //console.log('ssa:', startSliderAmount);
        //console.log('esa:', endSliderAmount);
        //console.log('previousSlider1PostionLeftAmount:', previousSlider1PostionLeftAmount);
        //console.log('previousSlider2PostionLeftAmount:', previousSlider2PostionLeftAmount);

        //window.scrollTo(0,0);

        setShowProductCategoryProducts(false);

        //window.scrollTo(0,0);
        setStartSliderAmount(startSliderAmount);
        setEndSliderAmount(endSliderAmount);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
                    
        //console.log('ssa === esa:', previousSlider1PostionLeftAmount === previousSlider2PostionLeftAmount);

        
        if(previousSlider1PostionLeftAmount === previousSlider2PostionLeftAmount){
            setSlider1ZIndex(selectedSlider1ZIndex);
            setSlider2ZIndex(selectedSlider2ZIndex);
        } 
        
        if(productCategorySearch === 'product') {
            setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
            setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
            setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
            setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
            setSliderPosition(sliderPosition);
            setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
            setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

            const pr = {    
                status:'filterProducts',
                startAmount:previousSlider1PostionLeftAmount,
                endAmount:previousSlider2PostionLeftAmount
            };

            getProductCategoriesPromise(productCategoryId!, pr)
            .then((storeDepartmentProductCategories)=>{
                //console.log('price range products:', keywordSearchResult.relatedProducts);
                //console.log('test:', storeDepartmentProductCategories);
                //setProducts([...keywordSearchResult.relatedProducts]);
                const pcId = parseInt(productCategoryId!, 10);
                const pc = getProductCategory(pcId, storeDepartmentProductCategories);
                
                const products:Product[] = addProductCategoryProducts(storeDepartmentProductCategories, productCategoryName as string);
                //console.log('p 5-4:', products);

                if(products.length === 0){
                    setKeywordSearchType('noResults');
                    setProductCategoryProducts([]);
                    setShowProductCategoryProducts(true);
                    //window.scrollTo(0,0);
                } else {
                    setKeywordSearchType('productCategory');
                    //original version
                    //setProductCategoryProducts(products);

                    helperSetOptionValue(
                            sortByOptionValue, 
                            products, 
                            false,
                            //storeDepartmentName!,
                            //departmentCategoryName!,
                            setProductCategoryProducts, 
                            setShowProductCategoryProducts
                            );

                    getAnyItemList(listChangerBtnPosition, products, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, products);

                    getSearchFilters(pc!);

                    setShowProductCategoryProducts(true);
                    //window.scrollTo(0,0);
                }
                

            })
            .catch(err=>console.log('price-range-search error:', err));

        } else if(productCategorySearch === 'subProductKeyword'){

            setKeywordSearchType('productCategory');
            setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
            setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
            setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
            setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
            setSliderPosition(sliderPosition);
            setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
            setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

            fetch(`https://server.kando-proto-3.com/shop-product-sub-category-keyword-search-price-range/${productSubCategorySelected!.productSubCategoryName}/${productSubCategorySelected!.productCategoryName}/${previousSlider1PostionLeftAmount.toString()}/${previousSlider2PostionLeftAmount.toString()}`, {
                
                method:'GET',
                headers:{
                    'Accept':'application/json'
                }
            })
            .then(res=>{
                //setProducts([]);
                setProductCategoryProducts([]);
                
                return res.json()
            })
            .then((subCategoryProducts)=>{
                //console.log('price range products:', keywordSearchResult.relatedProducts);
                //console.log('test:', subCategoryProducts);
                setProductCategoryProductTotal(subCategoryProducts.length);
                if(subCategoryProducts.length === 0){
                    setKeywordSearchType('noResults');
                    setProductCategoryProducts([]);
                    setShowProductCategoryProducts(true);
                    //window.scrollTo(0,0);
                } else {
                    const scps = addProducts(subCategoryProducts);
                    setKeywordSearchType('productCategory');
                    setProductCategoryProducts(scps);
                    setShowProductCategoryProducts(true);
                    setProductCategoryProductTotal(scps.length);
                    //window.scrollTo(0,0);
                    getAnyItemList(listChangerBtnPosition, scps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, scps);
                }

            })
            .catch(err=>console.log('price-range-search error:', err));


        } else if(productCategorySearch === 'subProductName'){

            setKeywordSearchType('productCategory');
            setPreviousSlider1PositionLeft(previousSlider1PositionLeft);
            setPreviousSlider2PositionLeft(previousSlider2PositionLeft);
            setPreviousSlider1PostionLeftAmount(previousSlider1PostionLeftAmount);
            setPreviousSlider2PostionLeftAmount(previousSlider2PostionLeftAmount);
            setSliderPosition(sliderPosition);
            setPreviousSlider1TotalDistance(previousSlider1TotalDistance);
            setPreviousSlider2TotalDistance(previousSlider2TotalDistance);

            fetch(`https://server.kando-proto-3.com/shop-product-sub-category-name-search-price-range/${productSubCategorySelected!.productSubCategoryName}/${productSubCategorySelected!.productCategoryName}/${previousSlider1PostionLeftAmount.toString()}/${previousSlider2PostionLeftAmount.toString()}`, {
                method:'GET',
                headers:{
                    'Accept':'application/json'
                }
            })
            .then(res=>{
                //setProducts([]);
                setProductCategoryProducts([]);
                
                return res.json()
            })
            .then((subCategoryProducts)=>{
                //console.log('price range products:', keywordSearchResult.relatedProducts);
                //console.log('test:', subCategoryProducts);
                setProductCategoryProductTotal(subCategoryProducts.length);
                if(subCategoryProducts.length === 0){
                    setKeywordSearchType('noResults');
                    setProductCategoryProducts([]);
                    setShowProductCategoryProducts(true);
                    //window.scrollTo(0,0);
                } else {
                    const scps = addProducts(subCategoryProducts);
                    setKeywordSearchType('productCategory');
                    setProductCategoryProducts(scps);
                    setShowProductCategoryProducts(true);
                    setProductCategoryProductTotal(scps.length);
                    //window.scrollTo(0,0);
                    getAnyItemList(listChangerBtnPosition, scps, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, scps);
                }

            })
            .catch(err=>console.log('price-range-search error:', err));


        }


    }


    function setupProductCategoryProducts(listItems:object[]/*, storeDepartmentName:string,
                                       departmentCategoryName:string, productCategoryName:string*/):JSX.Element{
            //console.log('setupProductCategoryProducts:', listItems);
            //console.log('test passed products:', listItems);
        return  <div className="shop-product-category-products-wrapper">
                    {(listItems as Product[]).map((product, index)=>{
                                                        return <div key={index} className="shop-product-detail-wrapper" /*id={product.productId.toString()}
                                                                    ref={getProductCategoryProductContainerRef} onClick={()=>setProductSelectionId(product.productId)}*/>
                                                                    <ShopProductDetail product={product} getImageRef={getImageRef} sbtNavLink={loadNavLink}/>
                                                                </div> 
                                                    })}
                            
                    {/*<ScrollToTopButton scrollToTopCallback={scrollToTopCallback}/>*/}
                </div>
    }

    /*
    function scrollToTopCallback():void {
        const root = document.documentElement;
        root.scrollIntoView({behavior:'smooth'});
    }
    */



    //test if a function with a closure still updated variable inside when the function itself doesn't change
    function updateProductCategoryShortcutSearchFilter(showShortcut:boolean, sfps:SearchFilterParams|null):void {
        //console.log('ps5:', sfps!.products, sfps!.keywordSearchType);
        if(showShortcut){
            //productCategory!.showProductSubCategoryShortcut = true;
            const pc = new ProductCategory({...productCategory});
            setProductCategory(pc);
            setShowProductCategoryProducts(true);

            //setShowProductCategorySearchShortcutSelector(true);

        } else {
            //productCategory!.showProductSubCategoryShortcut = false;
            const pc = new ProductCategory({...productCategory});
            setProductCategory(pc);
            setShowProductCategoryProducts(true);

            //setShowProductCategorySearchShortcutSelector(false);

            //start here tomorrow
            //need to add redux dispatch for search filter params
            if(sfps !== null){
                //console.log('sfps:', sfps);
                if(sfps.searchFilters.length > 0){
                    const usfs = sfps.searchFilters.map((sf)=>{
                                        const sfda = Object.assign({}, new Date(sf.searchFilterDateAdded));
                                        sf.searchFilterDateAdded = sfda;

                                        if(sf.searchTerms.length > 0){
                                            const usts = sf.searchTerms.map((st)=>{
                                                            return Object.assign({}, st);
                                                        });

                                            sf.searchTerms = usts;
                                            return Object.assign({}, sf);

                                        } else {
                                            return Object.assign({}, sf);
                                        }
                                    });

                    sfps.searchFilters = usfs
                }

                if(sfps.products.length > 0){
                    const ups = sfps.products.map((p)=>{
                                    return Object.assign({}, p);
                                });

                    sfps.products = ups;

                } 


                //original version
                dispatch(addSearchFilterParams({...sfps, showProductSubCategoryFilter:false}));
                //dispatch(addSearchFilterParams({...sfps}));

               
            }
            
        }
    }


    function handleProductSubCategorySearch(e:React.MouseEvent, productSubCategory:ProductSubCategory):void {
        //console.log('productSubCategorySearch:', productSubCategory, 'deptId:', storeDepartment!.departmentId, 'sfis:', searchTermIsSelected);
        e.preventDefault();
        e.stopPropagation();
        //window.scrollTo(0,0);
        
        setSortByOptionValue(props.sortByOptionValue);
        setProductSubCategorySelected(productSubCategory);

        setShowProductCategoryProducts(false);
        

        checkFilterSearchTerm2(
                                e,
                                productSubCategory.productCategorySearchFilterName,//'type',
                                productSubCategory.productCategorySearchTermName,//'pork',
                                initialSearchFilters,
                                searchTermIsSelected,
                                storeDepartment!.departmentId.toString(),
                                productSubCategory.productCategoryId.toString(),
                                productSubCategory.productCategoryName,
                                'Product Category',
                                setShowProductCategoryProducts,
                                clearAllExistingImages,
                                setSearchFilters,
                                setProductCategoryProducts,
                                setProductCategoryProductTotal,
                                setShowSpecialProductCategoryShortcut,
                                setSearchTermIsSelected,
                                setKeywordSearchType,
                                addSearchFilters,
                                previousSlider1PostionLeftAmount,
                                previousSlider2PostionLeftAmount,
                                updateProductCategoryShortcutSearchFilter,
                                sssbatps
                            );


    }


    function handleProductCategorySearch(e:React.MouseEvent, selectedProductCategory:ProductCategory, pathname:string /*keywordSearch:string, productCategoryName:string*/):void {
        //console.log('productCategory result:', selectedProductCategory);
        //console.log('spcns:', selectedProductCategory.showProductCategoryNameSearch);
        //console.log('spcks:', selectedProductCategory.showProductCategoryKeywordSearch);
        //console.log('spcn:', selectedProductCategory.productCategoryName);
        //console.log('pcn:', productCategoryName);

        if(decodeURIComponent(location.pathname) === decodeURIComponent(pathname)){
            return;

        } else {
            loadNavLink(e, pathname, true);
            return;
        }


        //everthing below in this function is from the previous version
        
        if(selectedProductCategory.productCategoryName === productCategoryName){
            console.log('true');
            setShowProductCategoryProducts(false);
            getProductCategoryProducts();
            //window.scrollTo(0,0);
        } else if(selectedProductCategory.productCategoryName !== productCategoryName){
            console.log('true');
            setShowProductCategoryProducts(false);
            //window.scrollTo(0,0);
        } 
        

    } 


    function getProductCategoriesPromise(productCategoryId:string, priceRange:PriceRange):Promise<ProductCategory[]> {
        //console.log('pcId:', productCategoryId);
        return fetch(`https://server.kando-proto-3.com/shop-get-product-categories-mariadb/${storeDepartmentId}/${productCategoryId}`, {
                                        //method:'GET',
                                        method:'POST',
                                        headers:{
                                            'Content-Type':'application/json',
                                            'Accept':'application/json'
                                        },
                                        body:JSON.stringify(priceRange)
                                    })
                .then((res)=>{
                    setProductCategoryProducts([]);
                    return res.json();
                })
                .then((productCategories)=>{
                    //console.log('pcs:', productCategories);
                    return productCategories;
                })
                .catch(err=>console.error('get product categories fetch error:', err));
    }


    function getProductCategoriesAndRelatedProducts(productCategoryId:string, departmentCategoryName:string, storeDepartmentId:string):void {
        //console.log('pcid:', productCategoryId, 'dcId:', departmentCategoryName, 'sdId:', storeDepartmentId);
        const pr = {    
            status:'noFilterProducts',
            startAmount:previousSlider1PostionLeftAmount,
            endAmount:previousSlider2PostionLeftAmount
        };

        Promise.all([getProductCategoriesPromise(productCategoryId!, pr),
                    /*getProductCategoryProductsPromise(storeDepartmentId!, productCategoryId!)*/
                    ])
        .then(([productCategories/*, products*/])=>{
            //console.log('p 10-16:', products);
            const pcs = productCategories.map((pc)=>{
                            return new ProductCategory({...pc});
                        });
            //console.log('pcs 7-15:', pcs);
            const pcId = window.parseInt(productCategoryId!, 10);
            const ps = getPCProducts(pcs, pcId);
            setProductCategoryProductTotal(ps.length);
            //console.log('p 10-16:', addProducts(products));
            setProductCategories(pcs);
            //setProductCategoryProducts([...addProducts(products)]);
            setProductCategoryProducts([...ps]);

            //getSliderAmount(addProducts(products));
            getSliderAmount([...ps]);
            setSliderPosition(0);
            setPreviousSlider1TotalDistance(0);
            setPreviousSlider2TotalDistance(100);
            setShowProductCategoryProducts(true);
        })
        .catch((err)=>console.error('Promise.all() product catategories and products error:', err));
        
    }


    function getPCProducts(productCategories:ProductCategory[], pcId:number):Product[] {
        const pc = productCategories.find((pc)=>{
                        return pc.productCategoryId === pcId;
                    });

        if(typeof pc === 'undefined'){
            throw new Error('could not find product category');
        }

        return addProducts(pc.products);

    }

    //helper to get selected product category
    //original version to get products categories and products
    //refer to getProductCategoriesAndRelatedProducts()
    function getProductCategoryProducts():void{
        //window.scrollTo(0,0);
        //original version
        fetch(`https://server.kando-proto-3.com/shop/${departmentCategoryName}/${productCategoryName}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
            })
            .then((resp)=> {
                //console.log('resp:', resp);
                //setShowProductCategoryProducts(false);
                return resp.json();
            })
            .then((storeDepartmentProductCategories)=>{
                //console.log('productCategoryProducts:', storeDepartmentProductCategories);
                //console.log('productCategoryName:', productCategoryName);
                //original version
                setProductCategories(storeDepartmentProductCategories);
                //original version
                setProductCategoryProducts(addProductCategoryProducts(storeDepartmentProductCategories, productCategoryName as string));

                //get start and end price amounts from each product
                //original version
                getSliderAmount(addProductCategoryProducts(storeDepartmentProductCategories, productCategoryName as string));
                setSliderPosition(0);
                setPreviousSlider1TotalDistance(0);
                setPreviousSlider2TotalDistance(100);
                setShowProductCategoryProducts(true);
                

            })
            .catch(err=>console.log('error 10-16:', err));
    }


    function reloadProductCategory(
                    e:React.MouseEvent, 
                    storeDepartmentName:string,
                    departmentCategoryName:string,
                    selectedProductCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedProductCategoryId:number
                        ):void {

        //console.log('spcn:', selectedProductCategoryName, productCategoryName);
        //console.log('pcn:', productCategoryName);


        if(selectedProductCategoryName === productCategoryName){
            //console.log('true:');

        } else {

        }
        

    }

    /*
    function reloadDepartmentCategory(
                    e:React.MouseEvent, 
                    storeDepartmentName:string,
                    selectedDepartmentCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedDepartmentCategoryId:number
                                ):void{

        e.preventDefault();
        e.stopPropagation();
        console.log('sdn:', storeDepartmentName);
        console.log('sdcn:', selectedDepartmentCategoryName);

        helperHighlightCategory(e, selectedDepartmentCategoryName, 'department')
        .then((response)=>response.json())
        .then(({action})=>{
            if(action === 'added'){
                console.log('added');

                loadNavLink(e,`/shop/${storeDepartmentName}/${selectedDepartmentCategoryName}/${selectedStoreDepartmentId}/${selectedDepartmentCategoryId}`, navigate);

            }
        })
        .catch(err=>console.log('error:', err));


        
    }
    */

    /*
    function hightlightProductCategoryText(
                    e:React.MouseEvent, 
                    selectedProductCategoryName:string, 
                    categoryType:string,
                    storeDepartmentName:string,
                    departmentCategoryName:string,
                    selectedStoreDepartmentId:number,
                    selectedProductCategoryId:number
                    ):void {
        e.preventDefault();
        console.log('spcn:', selectedProductCategoryName);
        console.log('pcn:', productCategoryName);

        setProductCategorySearch('product');

       setShowProductCategoryProducts(false);

        if(selectedProductCategoryName === productCategoryName){
            console.log('test passed');
            helperHighlightCategory(e, selectedProductCategoryName, categoryType)
            .then((response)=>{
                //setShowProductCategoryProducts(false);
                return response.json()
            })
            .then(({action})=>{
                if(action === 'added'){
                    console.log('added');

                   setSliderPosition(0);
                   setPreviousSlider1TotalDistance(0);
                   setPreviousSlider2TotalDistance(100);
                   getProductCategoriesAndRelatedProducts(selectedProductCategoryId.toString(), departmentCategoryName, selectedStoreDepartmentId.toString());
    
                } 
            })
            .catch(err=>console.log('error:', err));

        } else {

            helperHighlightCategory(e, selectedProductCategoryName, categoryType)
            .then((response)=>response.json())
            .then(({action})=>{
                if(action === 'added'){
                    setSliderPosition(0);
                    setPreviousSlider1TotalDistance(0);
                    setPreviousSlider2TotalDistance(100);
                    console.log('not added');
                    
                    loadNavLink(e, `/shop/${storeDepartmentName}/${departmentCategoryName}/${selectedProductCategoryName}/${selectedStoreDepartmentId}/${selectedProductCategoryId}`, navigate);
    
                }
            })
            .catch(err=>console.log('error:', err));
        }



    }
    */


    function sortByOptionSelected(
                        e:React.ChangeEvent, 
                        productCategoryProducts:Product[],
                        selectedStoreDepartmentId:number,
                        selectedDepartmentCategoryId:number
                        ):void {
        //console.log('sortByOptionSelection:', (e.target as HTMLSelectElement).value);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        setShowProductCategoryProducts(false);
        //window.scrollTo(0,0);
        setSortByOptionValue((e.target as HTMLSelectElement).value);
        const selectedOption:string =  (e.target as HTMLSelectElement).value;
        helperSetOptionValue(
                        selectedOption, 
                        productCategoryProducts, 
                        true, 
                        //storeDepartmentName!,
                        //departmentCategoryName!,
                        setProductCategoryProducts, 
                        setShowProductCategoryProducts
                        );

        sbatProductMobileNavSortByOptionHandler(selectedOption);
        
        getAnyItemList(listChangerBtnPosition, productCategoryProducts, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, productCategoryProducts);

    }


    function sortByOptionClicked(
                        e:React.MouseEvent,
                        optionsClicked:string, 
                        productCategoryProducts:Product[],
                        selectedStoreDepartmentId:number,
                        selectedDepartmentCategoryId:number
                        ):void {
        //console.log('sortByOptionClicked:', optionsClicked);

        e.stopPropagation();
        //console.log('show me:');

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        setShowProductCategoryProducts(false);
        //window.scrollTo(0,0);
        setSortByOptionValue(optionsClicked);
        const selectedOption:string = optionsClicked;
        helperSetOptionValue(
        selectedOption, 
        productCategoryProducts, 
        true, 
        //storeDepartmentName!,
        //departmentCategoryName!,
        setProductCategoryProducts, 
        setShowProductCategoryProducts
        );

        getAnyItemList(listChangerBtnPosition, productCategoryProducts, itemsPerList, showItemsPerList);
        displayListSorter(itemsPerList, productCategoryProducts);

    }

    function productMobileNavHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        //shopProductsMobileNavRef.current!.style.visibility = 'visible';
        //shopProductsMobileNavRef.current!.style.transform = 'translateY(0%)';  
        sbatProductMobileNavFilterHandler();
    }

    function shopProductsMobileNavCloseBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        shopProductsMobileNavRef.current!.style.visibility = '';
        shopProductsMobileNavRef.current!.style.transform = '';
    }


    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as Product[]);
    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function displayListSorter(itemsPerList:number, ps:Product[]):void {
        if(ps.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }


    //console.log('storeDepartmentCategory:', storeDepartmentCategory, showProductCategoryProducts, productCategories.length, productCategoryProducts.length, keywordSearchType);
    //console.log('showProductCategoryProducts:', showProductCategoryProducts);
    //console.log('productCategories.length:', productCategories.length);
    //console.log('productCategoryProducts.length:', productCategoryProducts.length);

    function setupShopProductCategoryContainer(storeDepartmentCategory:DepartmentCategory, showProductCategoryProducts:boolean, 
                                            productCategories:ProductCategory[], productCategoryProducts:Product[]):JSX.Element | null{

        //console.log('result2:', 'sdc:', storeDepartmentCategory === null, 'spcp:', showProductCategoryProducts, 'pcs:', productCategories.length, 'pcps:', productCategoryProducts.length);

        if(storeDepartmentCategory && showProductCategoryProducts /*&& productCategories.length > 0*/ && productCategoryProducts.length > 0){                                                
            //console.log('sbatpc5:');
            return <div className="shop-product-category-container">
                        <div>
                            {
                                showProductCategorySearchShortcutSelector
                                ?
                                <ProductCategorySearchShortcutSelector productCategory={productCategory as ProductCategory}
                                    productCatgoryProducts={productCategoryProducts}
                                    storeDepartmentCategory={storeDepartmentCategory as DepartmentCategory}
                                    handleProductSubCategorySearch={handleProductSubCategorySearch}
                                    handleProductCategorySearch={handleProductCategorySearch}
                                />
                                :
                                null
                            }

                        </div>
                        <div className="product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{productCategoryProductTotal} {(productCategoryProductTotal === 1) ? 'result' : 'results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <div className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>sortByOptionSelected(
                                                                                                                e, 
                                                                                                                productCategoryProducts,
                                                                                                                storeDepartmentCategory.departmentId,
                                                                                                                storeDepartmentCategory.departmentCategoryId
                                                                                                                )}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price:High to Low</option>
                                        <option value="Price Low to High">Price:Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        {
                            (showNoSearchResultsFound && !isLoading)
                            ? 
                            <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                            :
                            null
                        }
                        {
                            (!showNoSearchResultsFound && !isLoading)
                            ?
                            <ListViewer itemsList={itemsList} items={productCategoryProducts} showListSorter={showListSorter}
                                listChangerBtnPosition={listChangerBtnPosition}
                                itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupProductCategoryProducts} />
                            :
                            null
                        }

                        {
                            (!showNoSearchResultsFound && isLoading)
                            ?
                            <div className="sbat-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            : 
                            null
                            
                        }

                    </div>

        } else if(showProductCategoryProducts && productCategoryProducts.length === 0 && keywordSearchType === 'noProductsAdded'){
            //console.log('sbatpc5:');
            return  <div className="shop-product-category-container">
                        <div>
                            {/*setupProductSubCategorySearchShortcut(productCategory as ProductCategory)*/}
                            {   showProductCategorySearchShortcutSelector
                                ?
                                <ProductCategorySearchShortcutSelector productCategory={productCategory as ProductCategory}
                                productCatgoryProducts={productCategoryProducts}
                                storeDepartmentCategory={storeDepartmentCategory as DepartmentCategory}
                                handleProductSubCategorySearch={handleProductSubCategorySearch}
                                handleProductCategorySearch={handleProductCategorySearch}
                                />
                                :
                                null
                            }
                        </div>
                        <div className="product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{productCategoryProductTotal} {(productCategoryProductTotal === 1) ? 'result' : 'results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <fieldset className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>sortByOptionSelected(
                                                                                                                                    e, 
                                                                                                                                    productCategoryProducts,
                                                                                                                                    storeDepartmentCategory!.departmentId,
                                                                                                                                    storeDepartmentCategory!.departmentCategoryId
                                                                                                                                    )}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price:High to Low</option>
                                        <option value="Price Low to High">Price:Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                    </select>
                                </fieldset>
                            </form>
                        </div>
                        <div className="shop-product-category-products-error-message-container">
                            <div className="shop-product-category-products-error-message">
                                <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                <div>There are no products added to this product category.</div>
                            </div>
                        </div>
                    </div>

        }  else if(showProductCategoryProducts && productCategoryProducts.length === 0 && keywordSearchType === 'noResults'){
            //console.log('sbatpc5:');
            return (<div className="shop-product-category-container">
                        <div>
                            {/*setupProductSubCategorySearchShortcut(productCategory as ProductCategory)*/}
                            {    
                                showProductCategorySearchShortcutSelector
                                ?
                                <ProductCategorySearchShortcutSelector productCategory={productCategory as ProductCategory}
                                productCatgoryProducts={productCategoryProducts}
                                storeDepartmentCategory={storeDepartmentCategory as DepartmentCategory}
                                handleProductSubCategorySearch={handleProductSubCategorySearch}
                                handleProductCategorySearch={handleProductCategorySearch}
                                />
                                :
                                null
                            }

                        </div>
                        <div className="product-category-sort-by-heading">
                            <span className="sort-by-results-heading">{productCategoryProductTotal} {(productCategoryProductTotal === 1) ? 'result' : 'results'}</span>
                            <button type="button" className="sort-by-form-btn" onClick={productMobileNavHandler}>
                                <span className="sort-by-form-btn-text">
                                    {/*<svg className="sort-by-form-btn-svg" width="15" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="2" width="15" height="1.5" rx="0.75" fill="#717172"></rect><rect y="8" width="15" height="1.5" rx="0.75" fill="#717172"></rect><circle cx="5.25" cy="2.65" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle><circle cx="10.25" cy="8.75" r="1.7" fill="#fff" stroke="#717172" strokeWidth="1.1"></circle></svg>*/}
                                    <svg className="sort-by-form-btn-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z" fill="currentColor"></path></svg>
                                    Filter
                                </span>
                            </button>
                            <form className="sort-by-form">
                                <fieldset className="sort-by-form-group">
                                    <label className="sort-by-select-label" htmlFor="sort-by-select">Sort by</label>
                                    <select className="sort-by-select" id="sort-by-select" value={sortByOptionValue} onChange={(e:React.ChangeEvent)=>sortByOptionSelected(
                                                                                                                                    e, 
                                                                                                                                    productCategoryProducts,
                                                                                                                                    storeDepartmentCategory!.departmentId,
                                                                                                                                    storeDepartmentCategory!.departmentCategoryId
                                                                                                                                    )}>
                                        <option value="Best Match">Best Match</option>
                                        <option value="A-Z">A-Z</option>
                                        <option value="Z-A">Z-A</option>
                                        <option value="Price High to Low">Price:High to Low</option>
                                        <option value="Price Low to High">Price:Low to High</option>
                                        <option value="Ratings">Ratings</option>
                                        <option value="Newest">Newest</option>
                                    </select>
                                </fieldset>
                            </form>
                        </div>
                        <div className="shop-product-category-products-error-message-container">
                            <div className="shop-product-category-products-error-message">
                                <i className="fas fa-exclamation-triangle exclamation-triangle-size"></i> 
                                <div>There are no products that match your search criteria. Please modify your criteria and try again.</div>
                            </div>
                        </div>
                    </div>);

            
        } else {
            //console.log('null5:');
            //console.log('sbatpc5:');
            return <div className="sbat-loader-animation-container">
                        <LoaderAnimation/>
                    </div>;
        }
    

    }


    return (
            <div className="shop-by-area-type-products-wrapper">
                <div className="shop-by-areay-type-products-container">
                    {setupShopProductCategoryContainer(storeDepartmentCategory!, showProductCategoryProducts, 
                                            productCategories, productCategoryProducts)}
                </div>
            </div>
        
    );

}


export default ShopByAreaTypeProductCategory;
