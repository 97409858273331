import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {Link, Router} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product from './product';
import './ImageZoom.css';


interface Props {
    productSelected:Product
}

const ImageZoom: React.FunctionComponent<Props> = (props:Props) => {
    const [productSelected, setProductSelected] = useState(props.productSelected);
    const imageZoomRef = useRef<HTMLDivElement>(null);
    const productImageRef = useRef<HTMLImageElement>(null);
    const imageZoomLensRef = useRef<HTMLDivElement>(null);
    //const [cx, setCx] = useState(0);
    //const [cy, setCy] = useState(0);
    const cX = useRef<number>(0);
    const cY = useRef<number>(0);

    //console.log('imgzoom');

    useEffect(()=>{
        /*original version
        imageZoom(
            productImageRef.current!,
            imageZoomRef.current!,
            imageZoomLensRef.current!
        );
        */

    },[]);


    function imageZoom(
                img:HTMLImageElement, 
                result:HTMLDivElement,
                lens:HTMLDivElement
            ) {
        //var img, lens, result, cx, cy;
        let cx:number = 0;
        let cy:number = 0;
        //img = document.getElementById(imgID);
        //result = document.getElementById(resultID);
        /* Create lens: */
        //lens = document.createElement("DIV");
        //lens.setAttribute("class", "img-zoom-lens");
        /* Insert lens: */
        //img.parentElement.insertBefore(lens, img);

        if(img.height < 296){
            lens.style.width = `${img.height}px`;
            lens.style.height = `${img.height}px`;
            lens.style.top = `${(296 - img.height) / 2}px`;
        }

        if(img.width < 296){
            lens.style.width = `${img.width}px`;
            lens.style.height = `${img.width}px`;
            lens.style.left = `${(296 - img.width) / 2}px`;   
        }


        /* Calculate the ratio between result DIV and lens: */
        cx = result.offsetWidth / lens.offsetWidth;
        cy = result.offsetHeight / lens.offsetHeight;

        //console.log('img:', img.width, img.height, 'cx:', cx, 'cy:', cy, 'ow:', lens.offsetWidth, 'oh:', lens.offsetHeight, 'img:', img.src);
        //console.log('')
        /* Set background properties for the result DIV */
        //original version
        //result.style.backgroundImage = "url('" + img.src + "')";
        result.style.backgroundImage = `url("${img.src}")`;
        //result.style.backgroundImage = "url('" + '/images/shop/meat-seafood/seafood/fish/previously-frozen-sockeye-salmon-center-cut-wild-caught-002193768-180.jpg' + "')";
        //original version with actual img element dimenstions
        //result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
        result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";

        cX.current = cx;
        cY.current = cy;
        //previous version
        //setCx(cx);
        //setCy(cy);

        /* Execute a function when someone moves the cursor over the image, or the lens: */
        //lens.addEventListener("mousemove", moveLens);
        //img.addEventListener("mousemove", moveLens);
        /* And also for touch screens: */
        //lens.addEventListener("touchmove", moveLens);
        //img.addEventListener("touchmove", moveLens);
        
    }

    function moveLens(
            e:React.MouseEvent,
            img:HTMLImageElement, 
            result:HTMLDivElement,
            lens:HTMLDivElement
            ) {

                //console.log('img:', img.width, img.height, lens.offsetWidth, lens.offsetHeight);
                //console.log('cx:', cx);
                //console.log('cy:', cy);
        let pos, x, y;
        /* Prevent any other actions that may occur when moving over the image */
        e.stopPropagation();
        //e.preventDefault();
        /* Get the cursor's x and y positions: */
        pos = getCursorPos(e, img);
        /* Calculate the position of the lens: */
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);
        /* Prevent the lens from being positioned outside the image: */
        if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
        if (x < 0) {x = 0;}
        if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
        if (y < 0) {y = 0;}

        /* Set the position of the lens: */

        if(img.width < 296){
            //lens.style.width = `${img.height}px`;
            //lens.style.height = `${img.height}px`;
            lens.style.left = `${(296 - img.width) / 2}px`;

        } else {
            lens.style.left = x + "px";
        }
        

        if(img.height < 296){
            //lens.style.width = `${img.height}px`;
            //lens.style.height = `${img.height}px`;
            lens.style.top = `${(296 - img.height) / 2}px`;

        } else {
            lens.style.top = y + "px";
        }
        

        /* Display what the lens "sees": */
        //previous version
        result.style.backgroundPosition = "-" + (x * cX.current) + "px -" + (y * cY.current) + "px";
    }

    function getCursorPos(e:React.MouseEvent, img:HTMLImageElement) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /* Get the x and y positions of the image: */
        a = img.getBoundingClientRect();
        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x : x, y : y};
    }

    function hideMoveLens(
            e:React.MouseEvent,
            result:HTMLDivElement,
            lens:HTMLDivElement
            ):void {

        e.stopPropagation();
        
        lens.classList.remove('img-zoom-lens');
        lens.classList.add('hide-img-zoom-lens');
        result.classList.remove('img-zoom-result');
        result.classList.add('hide-img-zoom-result');


    }

    function showMoveLens(
                e:React.MouseEvent,
                result:HTMLDivElement,
                lens:HTMLDivElement
                ):void {
        e.stopPropagation();

        //console.log('showlens');
        result.classList.remove('hide-img-zoom-result');
        result.classList.add('img-zoom-result');                    
        lens.classList.remove('hide-img-zoom-lens');
        lens.classList.add('img-zoom-lens');

    }

    function imageLoadHandler(e:React.SyntheticEvent):void {
        imageZoom(
            productImageRef.current!,
            imageZoomRef.current!,
            imageZoomLensRef.current!
        );

    }

    return <div className="shop-product-image-zoom-container">
                <div id="myImageLens" ref={imageZoomLensRef} 
                    className="hide-img-zoom-lens"
                    onMouseLeave={(e:React.MouseEvent)=>hideMoveLens(
                                                                e,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}
                    onMouseEnter={(e:React.MouseEvent)=>showMoveLens(
                                                                e,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}  
                    onMouseMove={(e:React.MouseEvent)=>moveLens(
                                                                e,
                                                                productImageRef.current!,
                                                                imageZoomRef.current!,
                                                                imageZoomLensRef.current!
                                                                )}>                                
                </div>
                <div id="myresult" ref={imageZoomRef} className="hide-img-zoom-result"></div>
                <div className="image-zoom-img-container">
                <img id="myimage" ref={productImageRef} onLoad={imageLoadHandler}
                        className="shop-product-image" alt={productSelected.productName} src={`https://server.kando-proto-3.com/${productSelected.productMobileImage}`}
                        onMouseLeave={(e:React.MouseEvent)=>hideMoveLens(
                                                                    e,
                                                                    imageZoomRef.current!,
                                                                    imageZoomLensRef.current!
                                                                    )}
                        onMouseEnter={(e:React.MouseEvent)=>showMoveLens(
                                                                    e,
                                                                    imageZoomRef.current!,
                                                                    imageZoomLensRef.current!
                                                                    )} 
                        onMouseMove={(e:React.MouseEvent)=>moveLens(
                                                                    e,
                                                                    productImageRef.current!,
                                                                    imageZoomRef.current!,
                                                                    imageZoomLensRef.current!
                                                                    )}/>
                </div>
                <div className="shop-product-image-directions">Roll over image to zoom in</div>
            </div>;

}


export default ImageZoom;