import React, {useState, useEffect, useRef} from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import './ShopProductGroupRating.css';
import Review from './review';

interface Props {
    productReviews:Review[];
    //productReview:Review;
    showTotalReviewsMsg:boolean;
    showTotalReviews:boolean;
    starSizeCssStyle:string;
    starRatingListGroupContainerCssStyle:string;
    starRatingListCssStyle:string;
}

const ShopProductGroupRating:React.FunctionComponent<Props> = (props: Props) => {
    const [totalScore, setTotalScore] = useState(0);
    const [totalReviews, setTotalReviews] = useState(0);
    const [productReviews, setProductReviews] = useState<Review[] | []>(props.productReviews);
    //const [productReview, setProductReview] = useState<Review>(props.productReview);
    const [showTotalReviewsMsg, setShowTotalReviewsMsg] = useState(props.showTotalReviewsMsg);
    const [showTotalReviews, setShowTotalReviews] = useState(props.showTotalReviews);
    const [starSizeCssStyle, setStarSizeCssStyle] = useState(props.starSizeCssStyle);
    const [starRatingListGroupContainerCssStyle, setStarRatingListGroupContainerCssStyle] = useState(props.starRatingListGroupContainerCssStyle);
    const [starRatingListCssStyle, setStarRatingListCssStyle] = useState(props.starRatingListCssStyle);

    useEffect(()=>{
        const totalScore = calculateTotalScore(props.productReviews);
        //console.log('totalScore:', totalScore);
        setTotalScore(totalScore);

        const totalReviews = calculateTotalReviews(props.productReviews);
        //console.log('totalReviews:', totalReviews);
        setTotalReviews(totalReviews);
        
    },[props.productReviews]);

    function calculateTotalScore(productReviews:Review[]):number {
        let totalScore:number = 0;
        for(let i=0; i < productReviews.length; i++){
            totalScore += productReviews[i].rating;
        }

        return totalScore;
    }

    function calculateTotalReviews(productReviews:Review[]):number {
        let totalReviews:number = 0;
        for(let i=0; i < productReviews.length; i++){
            totalReviews += 1;
        }
        return totalReviews;
    }

    function setupProductRating(totalScore:number, totalReviews:number):JSX.Element[] {
        let starItems:JSX.Element[] = [];
        let fullStarList:JSX.Element[] = [<i className="fas fa-star"></i>];
        let halfStarList:JSX.Element[] = [<i className="fas fa-star-half-alt"></i>];
        let emptyStarList:JSX.Element[] = [<i className="far fa-star"></i>];
        let beforeDecimal:number = 0;
        let afterDecimal:number = 0;
        let afterDecimalAdded:boolean = false;

        if(totalReviews > 1){
            let totalUserRating:string = Number(totalScore / totalReviews).toFixed(2);
            //console.log('totalUserRating:', totalUserRating);
            let decimalPointIndex:number = totalUserRating.indexOf('.');
            //console.log('decimalPointIndex:', decimalPointIndex);
                beforeDecimal = Number(totalUserRating.slice(0, decimalPointIndex));
                afterDecimal = Number(totalUserRating.slice(decimalPointIndex + 1, decimalPointIndex + 2));
        } else {
            beforeDecimal = totalScore;
        }

        //console.log('beforeDecimal:', beforeDecimal);
        //console.log('afterDecimal:', afterDecimal);

        if(beforeDecimal === 0 && (afterDecimal > 0 && afterDecimal < 5)){
            for(let i=0; i < 5; i++){
                starItems.push(<i className={`far fa-star ${starSizeCssStyle}`}></i>);
            }

            return starItems.map((starItem, index)=>{
                return <li key={index} className="star-rating-item">{starItem}</li>
            });
        
        } else if(beforeDecimal === 0 && afterDecimal >= 5){
            starItems.push(<i className={`fas fa-star-half-alt star-rating-list-item-shape ${starSizeCssStyle}`}></i>);

            for(let i=1; i < 5; i++){
                starItems.push(<i className={`far fa-star ${starSizeCssStyle}`}></i>);
            }

            return starItems.map((starItem, index)=>{
                return <li key={index} className="star-rating-item">{starItem}</li>
            });

        } else if(beforeDecimal > 0 && afterDecimal === 0){
            for(let i=0; i < 5; i++){
                if(i < beforeDecimal){
                    starItems.push(<i className={`fas fa-star star-rating-list-item-shape ${starSizeCssStyle}`}></i>);
                } else {
                    starItems.push(<i className={`far fa-star ${starSizeCssStyle}`}></i>);
                }
                
            }

            return starItems.map((starItem, index)=>{
                return <li key={index} className="star-rating-item">
                            {starItem}
                        </li>
            });

        //beforeDecimal > 0 && afterDecimal > 0
        } else {
            for(let i=0; i < 5; i++){
                if(i < beforeDecimal){
                    starItems.push(<i className={`fas fa-star star-rating-list-item-shape ${starSizeCssStyle}`}></i>);
                    continue;
                }

                //if(!afterDecimalAdded) {
                //console.log('i test:', i);
                if(afterDecimal >= 5){
                    starItems.push(<i className={`fas fa-star-half-alt star-rating-list-item-shape ${starSizeCssStyle}`}></i>);

                    for(let j=(i + 1); j < 5; j++){
                        starItems.push(<i className={`far fa-star ${starSizeCssStyle}`}></i>);
                    }
                } else {

                    for(let j=i; j < 5; j++){
                        starItems.push(<i className={`far fa-star ${starSizeCssStyle}`}></i>);
                    }
                }
                   // afterDecimalAdded = true;
                //}

                //console.log('i:', i);
                //if(afterDecimal)
                //for(let j=(i + 1); j < 5; j++){
                    //starItems.push(<i className="far fa-star"></i>);
                //}

                break;
            }
    
            //console.log('starItems:', starItems);
    
            return starItems.map((starItem, index)=>{
                                return <li key={index} className="star-rating-item">{starItem}</li>
                            });
        }




    }

    function setupStarRatingAmountMsg(showTotalReviewsMsg:boolean, showTotalReviews:boolean, totalReviews:number):JSX.Element | null {
        if(!showTotalReviewsMsg && !showTotalReviews){
            return null;
        } else if(showTotalReviewsMsg && totalReviews === 0){
            return null;
        } else if(showTotalReviewsMsg && totalReviews === 1){
            return <span className="star-rating-amount-msg">{totalReviews} Review</span>;
        } else if(showTotalReviewsMsg && totalReviews > 1){
            return <span className="star-rating-amount-msg">{totalReviews} Reviews</span>;
        } else if(!showTotalReviewsMsg && totalReviews === 0){
            return null;
        } else {
            return <span className="star-rating-amount-no-msg">({totalReviews})</span>;
        }
    }

    //console.log('group rating productReviews:', productReviews);
    //star-rating-list-group-container
    //star-rating-list 
    if(totalScore !== undefined && totalReviews !== undefined){
        return (
            <div className={`${starRatingListGroupContainerCssStyle}`}>
                <ul className={`${starRatingListCssStyle}`}>
                    {setupProductRating(totalScore, totalReviews)}
                </ul>
                {/*showTotalReviewsMsg ? <span className="star-rating-amount-msg">{totalReviews} Reviews</span>
                    : <span className="star-rating-amount-no-msg">({totalReviews})</span>*/}
                {setupStarRatingAmountMsg(showTotalReviewsMsg, showTotalReviews, totalReviews)}
            </div>
        );

    } else {
        return null;
    }

}

export default ShopProductGroupRating;