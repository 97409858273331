import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux';
import {navigate} from '@reach/router';
import './ShowTotalProductsAdded.css';

interface Props {
    totalProductsAdded:number;
}

const ShowTotalProductsAdded:React.FunctionComponent<Props> = (props:Props)=>{
    const [totalProductsAdded, setTotalProductsAdded] = useState(props.totalProductsAdded);
    const scbtpaRef = useRef<HTMLDivElement>(null);
    const tpaLengthRef = useRef<number>(0);

    useEffect(()=>{
        //add resize event and cleanup function
        window.addEventListener('resize', totalProductsAddedResizeHandler);
        return cleanupTotalProductsAddedResizeHandler;
    },[]);

    function totalProductsAddedResizeHandler(e:Event):void {
        if(window.innerWidth >= 768){
            getGreaterThanEqualTo768(tpaLengthRef.current);

        } else if(window.innerWidth < 768 && window.innerWidth >= 480){
            getGreaterThan480LessThan768(tpaLengthRef.current);
        } else {
            getLessThan480(tpaLengthRef.current);
        }
    }

    function cleanupTotalProductsAddedResizeHandler():void {
        window.removeEventListener('resize', totalProductsAddedResizeHandler);
    }

    useEffect(()=>{
        const tpaLength = props.totalProductsAdded.toString().length;
        tpaLengthRef.current = tpaLength;
        //console.log('tpaLength:', tpaLength, props.totalProductsAdded);
        if(window.innerWidth >= 768){
            getGreaterThanEqualTo768(tpaLength);

        } else if(window.innerWidth < 768 && window.innerWidth >= 480){
            getGreaterThan480LessThan768(tpaLength);
        } else {
            getLessThan480(tpaLength);
        }

        setTotalProductsAdded(props.totalProductsAdded);
    },[props.totalProductsAdded]);

    function getGreaterThanEqualTo768(tpaLength:number):void {
        switch(tpaLength){
            case 1:
                scbtpaRef.current!.style.right = '13px';
                break;

            case 2:
                scbtpaRef.current!.style.right = '7px';
                break;

            case 3:
                scbtpaRef.current!.style.right = '0px';
                break;

            case 4:
                scbtpaRef.current!.style.right = '-7px';
                break;

            case 5:
                scbtpaRef.current!.style.right = '-14px';
                break;

            case 6:
                scbtpaRef.current!.style.right = '-21px';
                break;

            case 7:
                scbtpaRef.current!.style.right = '-28px';
                break;

            default:
                throw new Error('no product length found.');
        }
    }

    function getGreaterThan480LessThan768(tpaLength:number):void {
        switch(tpaLength){
            case 1:
                scbtpaRef.current!.style.right = '7px';
                break;

            case 2:
                scbtpaRef.current!.style.right = '2px';
                break;

            case 3:
                scbtpaRef.current!.style.right = '-5px';
                break;

            case 4:
                scbtpaRef.current!.style.right = '-12px';
                break;

            case 5:
                scbtpaRef.current!.style.right = '-19px';
                break;

            case 6:
                scbtpaRef.current!.style.right = '-26px';
                break;

            case 7:
                scbtpaRef.current!.style.right = '-33px';
                break;

            default:
                throw new Error('no product length found.');
        }
    }

    function getLessThan480(tpaLength:number):void {
        switch(tpaLength){
            case 1:
                scbtpaRef.current!.style.right = '1px';
                break;

            case 2:
                scbtpaRef.current!.style.right = '-4px';
                break;

            case 3:
                scbtpaRef.current!.style.right = '-11px';
                break;

            case 4:
                scbtpaRef.current!.style.right = '-18px';
                break;

            case 5:
                scbtpaRef.current!.style.right = '-25px';
                break;

            case 6:
                scbtpaRef.current!.style.right = '-32px';
                break;

            case 7:
                scbtpaRef.current!.style.right = '-39px';
                break;
            
            default:
                throw new Error('no product length found.');
        }
    }

    if(totalProductsAdded > 0){
        return <div ref={scbtpaRef} className="shopping-cart-btn-product-total-products-addded">
                    {totalProductsAdded}
                </div>
    } else {
        return null;
    }
}

export default ShowTotalProductsAdded;