export class ProductDescriptionDetail {
    public detailId:number;
    public descriptionId:number;
    public detail:string;
    constructor(options:{
        detailId?:number,
        descriptionId?:number,
        detail?:string
    }={}){
        this.detailId = options.detailId || 0;
        this.descriptionId = options.descriptionId || 0;
        this.detail = options.detail || '';
    }
}

class ProductDescription {
    public descriptionId:number;
    public productId:number;
    public description:string;
    public details:string[];
    public ingredients:string;
    public safetyWarning:string;
    constructor(options:{
        descriptionId?:number,
        productId?:number,
        description?:string,
        details?:string[],
        ingredients?:string,
        safetyWarning?:string
    }={}){
        this.descriptionId = options.descriptionId || 0;
        this.productId = options.productId || 0;
        this.description = options.description || '';
        this.details = options.details || [];
        this.ingredients = options.ingredients || '';
        this.safetyWarning = options.safetyWarning || '';
    }
}

export default ProductDescription;