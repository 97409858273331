import React, {useState, useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import './ShopProductIndividualRating.css';
import Review from './review';

interface Props {
    //productReviews:Review[];
    productReview:Review;
    className?:string;
}

const ShopProductIndividualRating:React.FunctionComponent<Props> = (props: Props) => {
    const [totalScore, setTotalScore] = useState(0);
    const [showTotalScore, setShowTotalScore] = useState(false);
    const [totalReviews, setTotalReviews] = useState(0);
    //const [productReviews, setProductReviews] = useState<Review[] | []>(props.productReviews);
    const [productReview, setProductReview] = useState<Review>(props.productReview);

    /*
    useEffect(()=>{
        const totalScore = calculateTotalScore(productReviews);
        console.log('totalScore:', totalScore);
        setTotalScore(totalScore);

        const totalReviews = calculateTotalReviews(productReviews);
        console.log('totalReviews:', totalReviews);
        setTotalReviews(totalReviews);
        
    },[]);
    */

   useEffect(()=>{
       //console.log('total score updated');
        setTotalScore(props.productReview.rating);
        setShowTotalScore(true);
    },[props.productReview.rating]);

    /*
    function calculateTotalScore(productReviews:Review[]):number {
        let totalScore:number = 0;
        for(let i=0; i < productReviews.length; i++){
            totalScore += productReviews[i].rating;
        }

        return totalScore;
    }

    function calculateTotalReviews(productReviews:Review[]):number {
        let totalReviews:number = 0;
        for(let i=0; i < productReviews.length; i++){
            totalReviews += 1;
        }
        return totalReviews;
    }
    */

    function setupProductRating(totalScore:number/*, totalReviews:number*/):JSX.Element[] {
        let starItems:JSX.Element[] = [];
        let fullStarList:JSX.Element[] = [<i className="fas fa-star"></i>];
        let halfStarList:JSX.Element[] = [<i className="fas fa-star-half-alt"></i>];
        let emptyStarList:JSX.Element[] = [<i className="far fa-star"></i>];
        let beforeDecimal:number = 0;
        //let afterDecimal:number = 0;
        //let afterDecimalAdded:boolean = false;

        /*
        if(totalReviews > 1){
            let totalUserRating:string = Number(totalScore / totalReviews).toFixed(2);
            console.log('totalUserRating:', totalUserRating);
            let decimalPointIndex:number = totalUserRating.indexOf('.');
            console.log('decimalPointIndex:', decimalPointIndex);
                beforeDecimal = Number(totalUserRating.slice(0, decimalPointIndex));
                afterDecimal = Number(totalUserRating.slice(decimalPointIndex + 1, decimalPointIndex + 2));
        } else {
            beforeDecimal = totalScore;
        }
        */

       beforeDecimal = totalScore;

        //console.log('beforeDecimal:', beforeDecimal);
       //console.log('afterDecimal:', afterDecimal);

        /*
        if(beforeDecimal === 0 && (afterDecimal > 0 && afterDecimal < 5)){
            for(let i=0; i < 5; i++){
                starItems.push(<i className="far fa-star"></i>);
            }

            return starItems.map((starItem, index)=>{
                return <li key={index}>{starItem}</li>
            });
        
        } else if(beforeDecimal === 0 && afterDecimal >= 5){
            starItems.push(<i className="fas fa-star-half-alt star-rating-list-item-shape"></i>);

            for(let i=1; i < 5; i++){
                starItems.push(<i className="far fa-star"></i>);
            }

            return starItems.map((starItem, index)=>{
                return <li key={index}>{starItem}</li>
            });

        } else*/ 
        if(beforeDecimal > 0 /*&& afterDecimal === 0*/){
            for(let i=0; i < 5; i++){
                if(i < beforeDecimal){
                    starItems.push(<i className="fas fa-star star-rating-list-item-shape-individual"></i>);
                } else {
                    starItems.push(<i className="far fa-star star-rating-list-item-shape-individual-no-fill"></i>);
                }
                
            }

            return starItems.map((starItem, index)=>{
                return <li key={index}>{starItem}</li>
            });
        } else {
            for(let i=0; i < 5; i++){
                //if(i < beforeDecimal){
                starItems.push(<i className="far fa-star"></i>);
                //}
                
            }

            return starItems.map((starItem, index)=>{
                return <li key={index}>{starItem}</li>
            });            
        }

            /*
        //beforeDecimal > 0 && afterDecimal > 0
        } else {
            for(let i=0; i < 5; i++){
                if(i < beforeDecimal){
                    starItems.push(<i className="fas fa-star star-rating-list-item-shape"></i>);
                    continue;
                }
                if(!afterDecimalAdded) {
                    if(afterDecimal >= 5){
                        starItems.push(<i className="fas fa-star-half-alt star-rating-list-item-shape"></i>);
                    }
                    afterDecimalAdded = true;
                }

                console.log('i:', i);
                for(let j=(i + 1); j < 5; j++){
                    starItems.push(<i className="far fa-star"></i>);
                }
                break;
            }
    
            console.log('starItems:', starItems);
    
            return starItems.map((starItem, index)=>{
                                return <li key={index}>{starItem}</li>
                            });
        }
        */




    }


    if(showTotalScore){
        return (
            <Col className="star-rating-list-individual-container">
                <ul className="star-rating-list">
                    {setupProductRating(totalScore)}
                </ul>
            </Col>
        );

    } else {
        return null;
    }
    

}

export default ShopProductIndividualRating;