import React, {useState, useEffect, useRef} from 'react';
import ProductCategory from './productCategory';
import AddProductCategory from './AddProductCategory';

interface Props {
    productCategory:ProductCategory;
    handleCloseForm:(formType:string)=>void;
}

const EditProductCategory: React.FunctionComponent<Props> = (props:Props) => {
    const {productCategory, handleCloseForm} = props;

    return <AddProductCategory showEditProductCategory={true} productCategoryToEdit={productCategory} handleCloseForm={handleCloseForm}/>
}

export default EditProductCategory;