import ShoppingCartCategory from './shoppingCartCategory';
import Product from './product';

export default function helperUpdateShoppingCartProductQuantity(updatedShoppingCartProductQuantity:number | string,
            shoppingCartCategories:ShoppingCartCategory[],
            selectedShoppingCartCategory:ShoppingCartCategory,
            selectedShoppingCartProduct:Product):ShoppingCartCategory[]{
    const updatedShoppingCartProducts = selectedShoppingCartCategory.shoppingCartProducts.map((shoppingCartProduct)=>{
        if(shoppingCartProduct.productName === selectedShoppingCartProduct.productName){
            //all values are cleared and there is an empty space
            if(updatedShoppingCartProductQuantity === ''){
                shoppingCartProduct.shoppingCartQuantity = updatedShoppingCartProductQuantity;    
            } else {
                shoppingCartProduct.shoppingCartQuantity = Number(updatedShoppingCartProductQuantity);
            }
            
            return shoppingCartProduct;
        } else {
            return shoppingCartProduct;
        }
    });

    selectedShoppingCartCategory.shoppingCartProducts = updatedShoppingCartProducts;
    const selectedShoppingCartCategoryFoundIndex = shoppingCartCategories.findIndex((shoppingCartCategory)=>{
        return shoppingCartCategory.storeDepartmentName === selectedShoppingCartCategory.storeDepartmentName;
    });

    shoppingCartCategories.splice(selectedShoppingCartCategoryFoundIndex, 1, selectedShoppingCartCategory);
    return shoppingCartCategories;
}