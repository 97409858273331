function formatLinkString(str:string):string {

    if(str.length === 0){
        return 'none';

    } else {
        const nstr = str.replace(/[%,\(\)\[\]\{\}\.\\\^\$\|\?\*\+\/=_!@#&\'\";:<> ]/g, '-');
        
        let ustr = nstr.replace(/-{2,}/g, '-');
        
        if(ustr[0] === '-'){
            ustr = ustr.slice(1);
        }
        
        if(ustr[ustr.length - 1] === '-'){
            ustr = ustr.slice(0,-1);
        }

        if(ustr.length === 0){
            return 's';
            
        } else {
            return ustr.toLowerCase();
        }
    }
    
}

export default formatLinkString;