import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ShopLoadDetails from '../../shopLoadDetails';
import cloneDeep from 'lodash/cloneDeep';

const initialState: ShopLoadDetails = {
    reload:false,
    storeDepartmentName:'none',
    departmentCategoryName:'none',
    storeDepartmentId:0,
    departmentCategoryId:0
};

export const shopLoadDetailsSlice = createSlice({
  name: 'shopLoadDetails',
  initialState,
  reducers: {
    addShopLoadDetails: (state, action:PayloadAction<ShopLoadDetails>) => {
      //console.log('action:', action);
      state.reload = action.payload.reload;
      state.storeDepartmentName = action.payload.storeDepartmentName;
      state.departmentCategoryName = action.payload.departmentCategoryName;
      state.storeDepartmentId = action.payload.storeDepartmentId;
      state.departmentCategoryId = action.payload.departmentCategoryId;
    },
  },
})

// Action creators are generated for each case reducer function
export const { addShopLoadDetails } = shopLoadDetailsSlice.actions;

interface RootState {
  shopLoadDetails: ShopLoadDetails;
}

export const selectShopLoadDetails = (state:RootState) =>  {
  //console.log('sldr:', state);
  const clone = cloneDeep(state.shopLoadDetails);
  return clone;
}

export default shopLoadDetailsSlice.reducer;