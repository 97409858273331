import React, {useEffect, useState, useRef} from 'react';
import './GenericInfoMessage.css';

interface Props {
    infoMessage:string;
    showCloseBtn?:boolean;
    customStyle?:string;
    closeInfoMsgHandler?:(e:React.MouseEvent)=>void;
    showExtraMsgContainer?:boolean;
    continueInfoMsgHandler?:(e:React.MouseEvent)=>void;
}

const GenericInfoMessage:React.FunctionComponent<Props> = (props:Props)=>{
    const {infoMessage, showCloseBtn, customStyle, 
            showExtraMsgContainer, closeInfoMsgHandler, continueInfoMsgHandler} = props;
            //console.log('semc:', showExtraMsgContainer);

    return (<div className={`generic-info-msg-wrapper`}>
                {
                    showCloseBtn 
                    ?
                    <span className="generic-info-msg-close-btn-container">
                        <button type="button" className="generic-info-msg-close-btn" onClick={closeInfoMsgHandler}>
                            <svg className="generic-info-close-btn-svg" width="12" height="12" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                <path className="generic-info-close-btn-path" d="M200 0 L0 200 Z" ></path>
                                <path className="generic-info-close-btn-path" d="M0 0 L200 200 Z" ></path>
                            </svg>
                        </button>
                    </span> 
                    : 
                    null
                }

                <div className="generic-info-msg-container">
                    {/*<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" 
                        aria-hidden="true" className="generic-error-msg">
                        <path fill="currentColor" d="M1024 1375v-190q0-14-9.5-23.5T992 1152H800q-13 0-22.5 9.5T768 1185v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11H786q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17H128q-34 0-63.5-17T18 1601q-37-63-2-126L784 67q17-31 47-49t65-18 65 18 47 49z"></path>
                    </svg>*/}
                    <p className="generic-info-msg">{infoMessage}</p>
                </div>
                {
                    showExtraMsgContainer
                    ?
                    <div className="generic-info-extra-msg-btn-container">
                        {/*<button className="giemc-btn" type="button" onClick={continueInfoMsgHandler}>Continue</button>*/}
                        <button className="giemc-btn" type="button" onClick={closeInfoMsgHandler}>Close</button>
                    </div>
                    : null
                }
            </div>);
}


export default GenericInfoMessage;