import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

export interface SearchFilterProductVisibility {
    showShopByAreaTypeProducts:boolean;
}

const initialState:SearchFilterProductVisibility = {
    showShopByAreaTypeProducts:true
};

export const searchFilterProductVisibilitySlice = createSlice({
  name: 'searchFilterProductVisibility',
  initialState,
  reducers: {
    addSearchFilterProductVisibility: (state, action:PayloadAction<SearchFilterProductVisibility>) => {
      state.showShopByAreaTypeProducts = action.payload.showShopByAreaTypeProducts;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addSearchFilterProductVisibility } = searchFilterProductVisibilitySlice.actions;

interface RootState {
    searchFilterProductVisibility: SearchFilterProductVisibility
}

export const selectSearchFilterProductsVisibility = (state:RootState) =>  {
  //const clone = cloneDeep(state.searchFilterParams);
  return state.searchFilterProductVisibility;
  //return clone;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default searchFilterProductVisibilitySlice.reducer;