import React from 'react';
import ListSorter from './ListSorter';
import GenericErrorMessage from './GenericErrorMessage';
import './ListViewer.css';
import LoaderAnimation from './LoaderAnimation';

interface Props {
    itemsList:object[] | null;
    items:object[] | [];
    listViewerHeading?:string;
    listSearchMessage?:string;
    listChangerBtnPosition?:number;
    itemsPerList?:number;
    showListSorter:boolean;
    showStoreLocatorErrorMsg?:boolean;
    getListChangerBtnPosition?:(listChangerBtnPosition:number)=>void;
    showItemsPerList?:(itemsList:object[])=>void;
    setupItemsList:(itemsList:object[], optionalCategory?:object)=>JSX.Element;
    optionalCategory?:object;
    adjContainerStyle?:{width:string, margin:string};

}

const ListViewer:React.FunctionComponent<Props> = (props:Props)=>{
    const {itemsPerList, items, listViewerHeading, itemsList, listSearchMessage, 
        listChangerBtnPosition, showListSorter, showStoreLocatorErrorMsg,
        getListChangerBtnPosition, showItemsPerList, setupItemsList, optionalCategory, adjContainerStyle} = props;

        //console.log('itemslist:', itemsList, 'items:', items, 'lsm:', listSearchMessage, 'lcb:', listChangerBtnPosition, 'ipl:', itemsPerList);

    if(itemsList !== null){
        return <div className="list-viewer-container-wrapper">
                    <div className="list-viewer-container">
                        {listViewerHeading ? <h2 className="list-viewer-heading">{listViewerHeading}</h2> : null }
                        { optionalCategory
                            ? setupItemsList(itemsList, optionalCategory)
                            : setupItemsList(itemsList)
                        }
                    </div>
                    {
                        
                        showListSorter
                        ?
                        <ListSorter items={items!} itemsPerList={itemsPerList!} listChangerBtnPosition={listChangerBtnPosition!} 
                                    getListChangerBtnPosition={getListChangerBtnPosition!} showItemsPerList={showItemsPerList!}
                                    adjContainerStyle={adjContainerStyle}/>
                        : null
                        
                    }
                </div>

    } else if(showStoreLocatorErrorMsg!){
        return <GenericErrorMessage/>;

    } else if(itemsList === null && listSearchMessage === 'completed'){
        return null;

    } else {
        return (<div className="list-viewer-loader-animation-container">
                        {/*<LoaderAnimation/>*/}
                </div>);
    }

}

export default ListViewer;