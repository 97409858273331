class ProductZoomImage {
    public productZoomImageId:number;
    public productId:number;
    public productZoomImage:string;
    constructor(options:{
        productZoomImageId?:number,
        productId?:number,
        productZoomImage?:string
    }={}){
        this.productZoomImageId = options.productZoomImageId || 0;
        this.productId = options.productId || 0;
        this.productZoomImage = options.productZoomImage || '';
    }
}

export default ProductZoomImage;