import React, {useState, useEffect} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AddSearchTerm from './AddSearchTerm';
import StoreStructureOptionSelect from './StoreStructureOptionSelect';
import SearchFilter, {SearchTerm} from './searchFilter';
import StoreStructureLoadingMsg from './StoreStructureLoadingMsg';
import showSearchFilterFormErrorMsgs from './helper-show-search-filter-form-error-msgs';
import isSearchFilterAreaNameSelected from './helper-is-search-filter-area-name-selected';
import StoreStructureAlert from './StoreStructureAlert';
import {getDepartmentNamesPromise, 
        getDepartmentCategoryNamesPromise, 
        getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import ProductCategory from './productCategory';
import DbResponse from './db-response';
import LoaderAnimation from './LoaderAnimation';
import './AddSearchFilter.css';

interface Props {
    setIsLoading:(value: React.SetStateAction<boolean>) => void;
    showAddSearchFilter:boolean;
    showDepartment:boolean;
    showDepartmentCategory:boolean;
    showProductCategory:boolean;
    deptNameSelected:string;
    deptCategoryNameSelected:string;
    productCategoryNameSelected:string;
    searchFilterNameSelected:string;
    searchTermNameSelected:string;
    searchFilterSelected:boolean;
    showSearchFilter:boolean;
    searchFilterAreaNameSelected:string;
    searchFilterAreaTypeSelected:string;
    handleSaveSearchFilter:(
                /*deptNameSelected:string,
                deptCategoryNameSelected:string,
                productCategoryNameSelected:string,*/
                /*searchFilterAreaTypeSelected:string,
                searchFilterAreaNameSelected:string,
                searchFilterNameSelected:string,
                searchFilterSelected:boolean,
                showSearchFilter:boolean,*/
                searchFilters:SearchFilter[]
                            )=>void;
    handleAddSearchTerm:(
                showDepartment:boolean,
                showDepartmentCategory:boolean,
                showProductCategory:boolean,
                deptNameSelected:string,
                deptCategoryNameSelected:string,
                productCategoryNameSelected:string,
                searchFilterNameSelected:string,
                searchTermNameSelected:string,
                searchFilterSelected:boolean,
                showSearchFilter:boolean,
                searchFilterAreaNameSelected:string,
                searchFilterAreaTypeSelected:string
                        )=>void;
    handleCancelSearchFilter:()=>void;
}

const AddSearchFilter: React.FunctionComponent<Props> = (props:Props) => {
    const {setIsLoading, showAddSearchFilter, handleAddSearchTerm, handleSaveSearchFilter, handleCancelSearchFilter } = props;
    const [showAddSearchTerm, setShowAddSearchTerm] = useState(false);
    const [deptNames, setDeptNames] = useState<string[]>([]);
    const [deptNameSelected, setDeptNameSelected] = useState(props.deptNameSelected);
    const [deptCategoryNames, setDeptCategoryNames] = useState<string[]>([]);
    const [deptCategoryNameSelected, setDeptCategoryNameSelected] = useState(props.deptCategoryNameSelected);
    const [productCategoryNames, setProductCategoryNames] = useState<string[]>([]);
    const [productCategoryNameSelected, setProductCategoryNameSelected] = useState(props.productCategoryNameSelected);
    const [searchFilterNameSelected, setSearchFilterNameSelected] = useState(props.searchFilterNameSelected);
    const [searchTermNameSelected, setSearchTermNameSelected] = useState(props.searchTermNameSelected);
    const [searchFilterSelected, setSearchFilterSelected] = useState(props.searchFilterSelected);
    const [showSearchFilter, setShowSearchFilter] = useState(props.showSearchFilter);
    const [showDepartment, setShowDepartment] = useState(props.showDepartment);
    const [showDepartmentCategory, setShowDepartmentCategory] = useState(props.showDepartmentCategory);
    const [showProductCategory, setShowProductCategory] = useState(props.showProductCategory);
    const [searchFilterAreaNameSelected, setSearchFilterAreaNameSelected] = useState(props.searchFilterAreaNameSelected);
    const [searchFilterAreaTypeSelected, setSearchFilterAreaTypeSelected] = useState(props.searchFilterAreaTypeSelected);
    const [searchFilterNameErrorMsg, setSearchFilterNameErrorMsg] = useState('Please add a search filter name');
    const [showSearchFilterNameErrorMsg, setShowSearchFilterNameErrorMsg] = useState(false);
    const [storeStructureAlertMsg, setStoreStructureAlertMsg] = useState('no msg');
    const [showStoreStructureAlert, setShowStoreStructureAlert] = useState(false);
    const [storeStructureLoadingMsg, setStoreStructureLoadingMsg] = useState('loading');
    const [showStoreStructureLoadingMsg, setShowStoreStructureLoadingMsg] = useState(false);
    const [showSearchFilterAreaNameErrorMsg, setShowSearchFilterAreaNameErrorMsg] = useState(false);
    const [searchFilterDeptNameErrorMsg, setSearchFilterDeptNameErrorMsg] = useState('Please add a department name');
    const [searchFilterDeptCategoryNameErrorMsg, setSearchFilterDeptCategoryNameErrorMsg] = useState('Please add a department category name');
    const [searchFilterProductCategoryNameErrorMsg, setSearchFilterProductCategoryNameErrorMsg] = useState('Please add a product category name');

    const [showSearchFilterNameLimitError, setShowSearchFilterNameLimitError] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    useEffect(()=>{
        Promise.all([getDepartmentNamesPromise(), getDepartmentCategoryNamesPromise(), getProductCategoryNamesPromise()])
        .then(([departments, departmentCategories, productCategories])=>{
            //console.log('dns:', departments);
            //console.log('dcns:', departmentCategories);
            //console.log('pcns:', productCategories);
            const dns = getDepartmentNames(departments);
            setDeptNames([...dns]);
            const dcns = getDepartmentCategorNames(departmentCategories);
            setDeptCategoryNames([...dcns]);
            const pcns = getProductCategorNames(productCategories);
            setProductCategoryNames([...pcns]);
        })
        .catch((err)=>console.error('promise all for names error:', err));

    },[]);

    function getDepartmentNames(ds:StoreDepartment[]):string[] {
        return ds.map((d)=>{
            return d.departmentName;
        })
    }

    function getDepartmentCategorNames(dcs:DepartmentCategory[]):string[] {
        return dcs.map((dc)=>{
            return dc.departmentCategoryName;
        })
    }

    function getProductCategorNames(pcs:ProductCategory[]):string[] {
        return pcs.map((pc)=>{
            return pc.productCategoryName;
        })
    }

    /*original layout
    function getDepartmentNamesPromise():Promise<string[]> {
        return new Promise((resolve, reject)=>{
            fetch(`http://localhost:9500/get-department-names-mariadb`,{
                method:'GET',
                headers:{
                    'Accept':'application/javascript'
                }
            })
            .then((res)=>res.json())
            .then((deptNames:string[])=>{
                //console.log('dns', deptNames);
                resolve(deptNames);            
            })
            .catch((err)=>{
                console.error('dept names fetch error:', err);
                reject(err);
            });
        });
    }

    function getDepartmentCategoryNamesPromise():Promise<string[]> {
        return new Promise((resolve, reject)=>{
            fetch(`http://localhost:9500/get-department-category-names-mariadb`,{
                method:'GET',
                headers:{
                    'Accept':'application/javascript'
                }
            })
            .then((res)=>res.json())
            .then((deptCategoryNames:string[])=>{
                //console.log('dcns', deptCategoryNames);
                resolve(deptCategoryNames);            
            })
            .catch((err)=>{
                console.error('dept category names fetch error:', err);
                reject(err);
            });
        });
    }

    function getProductCategoryNamesPromise():Promise<string[]> {
        return new Promise((resolve, reject)=>{
            fetch(`http://localhost:9500/get-product-category-names-mariadb`,{
                method:'GET',
                headers:{
                    'Accept':'application/javascript'
                }
            })
            .then((res)=>res.json())
            .then((productCategoryNames:string[])=>{
                //console.log('dpns', productCategoryNames);
                resolve(productCategoryNames);            
            })
            .catch((err)=>{
                console.error('product category names fetch error:', err);
                reject(err);
            });
        });
    }
    */

    function handleCloseStoreStrucureAlert(e:React.MouseEvent):void {
        setShowStoreStructureAlert(false);
    }
    
    //console.log('sfans:', searchFilterAreaNameSelected);
    function handleDeptNameSelection(e:React.ChangeEvent):void {
        //console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setShowDepartmentCategory(false);
            setShowProductCategory(false);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('Department');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(false);
        } else {
            setShowDepartmentCategory(true);
            setShowProductCategory(true);
            setDeptNameSelected(dns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dns);
            setShowSearchFilterAreaNameErrorMsg(true);
        }
    }

    function handleDeptCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('dcns:', (e.target as HTMLSelectElement).value);
        const dcns = (e.target as HTMLSelectElement).value;
        if(dcns !== 'none'){
            setShowDepartment(false);
            setShowProductCategory(false);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('Department Category');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(false);
        } else {
            setShowDepartment(true);
            setShowProductCategory(true);
            setDeptCategoryNameSelected(dcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(dcns);
            setShowSearchFilterAreaNameErrorMsg(true);
        }
    }

    function handleProductCategoryNameSelection(e:React.ChangeEvent):void {
        //console.log('pcns:', (e.target as HTMLSelectElement).value);
        const pcns = (e.target as HTMLSelectElement).value;
        if(pcns !== 'none'){
            setShowDepartment(false);
            setShowDepartmentCategory(false);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('Product Category');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(false);
        } else {
            setShowDepartment(true);
            setShowDepartmentCategory(true);
            setProductCategoryNameSelected(pcns);
            setSearchFilterAreaTypeSelected('none');
            setSearchFilterAreaNameSelected(pcns);
            setShowSearchFilterAreaNameErrorMsg(true);
        }
    }

    function handleAddSearchFilterName(e:React.ChangeEvent):void {
        const searchFilterName:string = (e.target as HTMLInputElement).value;

        //console.log('value.length:', searchFilterName.length);
            
        if(searchFilterName.length === 0){
            setShowSearchFilterNameLimitError(false);
            setShowSearchFilterNameErrorMsg(true);
            setSearchFilterNameErrorMsg('Please add a search filter name');
            setSearchFilterNameSelected(searchFilterName);

        } else if(searchFilterName.length > 0 && searchFilterName.length <= 25){
            setShowSearchFilterNameErrorMsg(false);
            setShowSearchFilterNameLimitError(false);
            setSearchFilterNameSelected(searchFilterName);

        } else {
            setShowSearchFilterNameErrorMsg(false);
            setShowSearchFilterNameLimitError(true);
        }
        
    }

    function handleSearchFilterSelected(e:React.ChangeEvent):void {
        //console.log('sfsc:', (e.target as HTMLInputElement).checked);
        setSearchFilterSelected((e.target as HTMLInputElement).checked);
    }
    

    function handleShowSearchFilter(e:React.ChangeEvent):void {
        //console.log('ssfc:', (e.target as HTMLInputElement).checked);
        setShowSearchFilter((e.target as HTMLInputElement).checked);
    }

    function handleSubmitSearchFilterForm(e:React.FormEvent):void {
        e.preventDefault();
        const showErrorMsgs:boolean = showSearchFilterFormErrorMsgs({
                                                    searchFilterNameSelected,
                                                    deptNameSelected,
                                                    deptCategoryNameSelected,
                                                    productCategoryNameSelected,
                                                    isSearchFilterAreaNameSelected,
                                                    setShowSearchFilterNameErrorMsg,
                                                    setSearchFilterNameErrorMsg,
                                                    setShowSearchFilterAreaNameErrorMsg,
                                                    setShowSearchFilterNameLimitError
                                                                            });
                                                                    
        if(showErrorMsgs){
            return;
            
        } else {

            setShowProcessingRequestMsg(true);

            const sfToAdd = new SearchFilter({
                searchFilterArea:searchFilterAreaNameSelected,
                searchFilterAreaType:searchFilterAreaTypeSelected,
                filterName:searchFilterNameSelected,
                showFilter:showSearchFilter,
                searchFilterSelected:searchFilterSelected
            });

            const searchFilter = JSON.stringify(sfToAdd);
            //setShowStoreStructureLoadingMsg(true);
            //setIsLoading(true);
            //previous version
            //fetch(`http://localhost:9500/add-search-filter-only/${searchFilter}`,{
            fetch(`https://server.kando-proto-3.com/add-search-filter-only`,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:searchFilter
            })
            .then(resp=>resp.json())
            .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                //console.log('dbrm:', dbResponseMsg);
                //console.log('dbrrs:', dbResponseRecords);

                setShowProcessingRequestMsg(false);

                //setShowStoreStructureLoadingMsg(false);
                if(dbResponseMsg === 'record found'){
                    //show alert that search filter already exits
                    //setStoreStructureAlertMsg('search filter already exists');
                    //setShowStoreStructureAlert(true);
                    setShowSearchFilterNameErrorMsg(true);
                    setSearchFilterNameErrorMsg('Search filter already exists');
                    

                    /*
                    setTimeout(()=>{
                        setStoreStructureAlertMsg('no msg');
                        setShowStoreStructureAlert(false);
                    },2000);
                    */
                
                } else {
                    
                    handleSaveSearchFilter(
                                /*searchFilterAreaTypeSelected,
                                searchFilterAreaNameSelected,
                                searchFilterNameSelected,
                                searchFilterSelected,
                                showSearchFilter,*/
                                dbResponseRecords as SearchFilter[]
                                    );
                }
                
                
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
                

            })
            .catch((err)=>console.log('error adding search filter:', err.messagge));
        }

    }

    function handleCancelAddSearchFilter(e:React.MouseEvent):void {
        handleCancelSearchFilter();
    }

    /*original version
    function isSearchFilterAreaNameSelected():boolean {
        if(deptNameSelected === 'none' && deptCategoryNameSelected === 'none' && productCategoryNameSelected === 'none'){
            return false;
        } else {
            return true;
        }
    }
    */

    /*original version
    function showSearchFilterFormErrorMsgs():boolean {
        if(searchFilterNameSelected === '' && !isSearchFilterAreaNameSelected()){
            setStoreStructureAlertMsg('add a search filter name and select a search filter area');
            setShowStoreStructureAlert(true);
            setSearchFilterNameErrorMsg('search-filter-name-error-msg');
            setShowSearchFilterAreaNameErrorMsg(true);

            setTimeout(()=>{
                setStoreStructureAlertMsg('no msg');
                setShowStoreStructureAlert(false);
            },2000);

            return true;

        } else if(searchFilterNameSelected === '' && isSearchFilterAreaNameSelected()){
            setStoreStructureAlertMsg('add a search filter name');
            setShowStoreStructureAlert(true);
            setSearchFilterNameErrorMsg('search-filter-name-error-msg');
            setShowSearchFilterAreaNameErrorMsg(false);

            setTimeout(()=>{
                setStoreStructureAlertMsg('no msg');
                setShowStoreStructureAlert(false);
            },2000);

            return true;

        } else if(searchFilterNameSelected !== '' && !isSearchFilterAreaNameSelected()){
            setStoreStructureAlertMsg('select a search filter area');
            setShowStoreStructureAlert(true);
            setSearchFilterNameErrorMsg('');
            setShowSearchFilterAreaNameErrorMsg(true);

            setTimeout(()=>{
                setStoreStructureAlertMsg('no msg');
                setShowStoreStructureAlert(false);
            },2000);

            return true;

        } else {
            return false;
        }
    }
    */

   function isSearchFilterUnique(
                    /*deptNameSelected:string,
                    deptCategoryNameSelected:string,
                    productCategoryNameSelected:string,*/
                    searchFilterAreaTypeSelected:string,
                    searchFilterAreaNameSelected:string,
                    searchFilterNameSelected:string,
                    searchFilterSelected:boolean,
                    showSearchFilter:boolean
                                ):Promise<DbResponse> {

        //console.log('sf saved');
        //finish saving search filter to database
        const sfToBeAdded = new SearchFilter({
            searchFilterArea:searchFilterAreaNameSelected,
            searchFilterAreaType:searchFilterAreaTypeSelected,
            filterName:searchFilterNameSelected,
            showFilter:showSearchFilter,
            searchFilterSelected:searchFilterSelected
        });

        const searchFilter = JSON.stringify(sfToBeAdded);
        //setShowStoreStructureLoadingMsg(true);
        //previous version
        //return fetch(`http://localhost:9500/get-search-filter-only/${searchFilterAreaTypeSelected}/${searchFilterAreaNameSelected}/${searchFilter}`,{
        return fetch(`https://server.kando-proto-3.com/get-search-filter-only`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:searchFilter
            })
            .then(resp=>resp.json())
            .then((dbResponse)=>{
                //console.log('dbr:', dbResponse);
                return dbResponse;
            })
            .catch(err=>console.error('error is search filter unique fetch:', err));
                    
    }



    if(showAddSearchFilter){
        return <div className="add-search-filter-container">
                    {/*<StoreStructureLoadingMsg
                        showStoreStructureLoadingMsg={showStoreStructureLoadingMsg}
                        storeStructureLoadingMsg={storeStructureLoadingMsg}
                    />*/}
                    <StoreStructureAlert 
                        showStoreStructureAlert={showStoreStructureAlert}
                        storeStructureAlertMsg={storeStructureAlertMsg}
                        handleCloseStoreStrucureAlert={handleCloseStoreStrucureAlert}
                    />
                    <h1 className="add-search-filter-heading">Add Search Filter</h1>
                    <form className="add-dpt-category-form" onSubmit={handleSubmitSearchFilterForm}>
                        {showDepartment ? 
                        <div className="add-search-filter-input-form-group">
                            <label htmlFor="dpt-name">Department Name</label>
                            <StoreStructureOptionSelect
                                    defaultValue='none'
                                    optionNameSelected={deptNameSelected}
                                    optionNames={deptNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterDeptNameErrorMsg}
                                    selectId={"dpt-name"}
                                    handleOptionNameSelection={handleDeptNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showDepartmentCategory ?
                        <div className="add-search-filter-input-form-group">
                            <label htmlFor="dpt-category-name">Department Category Name</label>
                            <StoreStructureOptionSelect 
                                    defaultValue='none'
                                    optionNameSelected={deptCategoryNameSelected}
                                    optionNames={deptCategoryNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterDeptCategoryNameErrorMsg}
                                    selectId={"dpt-category-name"}
                                    handleOptionNameSelection={handleDeptCategoryNameSelection}
                            />
                        </div>
                        :null
                        }
                        {showProductCategory ?
                        <div className="add-search-filter-input-form-group">
                            <label htmlFor="product-category-name">Product Category Name</label>
                            <StoreStructureOptionSelect 
                                    defaultValue='none'
                                    optionNameSelected={productCategoryNameSelected}
                                    optionNames={productCategoryNames}
                                    showErrorMsg={showSearchFilterAreaNameErrorMsg}
                                    errorMsg={searchFilterProductCategoryNameErrorMsg}
                                    selectId={"product-category-name"}
                                    handleOptionNameSelection={handleProductCategoryNameSelection}
                            />
                        </div>
                        : null
                        }
                        <div className="add-search-filter-input-form-group">
                            <label htmlFor="search-filter-name">Search Filter Name</label>
                            <input type="text" value={searchFilterNameSelected} id="search-filter-name"
                             className={showSearchFilterNameErrorMsg ? 'show-search-filter-name-input-error-border' : 'hide-search-filter-name-input-error-border'}
                             onChange={handleAddSearchFilterName}/>
                            {
                                showSearchFilterNameLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (25)</p>
                                :
                                null
                            }

                            {
                                showSearchFilterNameErrorMsg 
                                ? 
                                <div className="show-search-filter-name-error-msg">
                                    {searchFilterNameErrorMsg}
                                </div>
                                : null
                            }
                        </div>
                        {/*<div className="add-search-filter-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="search-filter-selected" id="search-filter-selected"
                             checked={searchFilterSelected} onChange={handleSearchFilterSelected}/>
                            <label htmlFor="search-filter-selected">Search Filter Selected</label>
                        </div>*/}
                        <div className="add-search-filter-checkbox-form-group">
                            <input type="checkbox" className="admin-checkbox-control" name="show-search-filter" id="show-search-filter"
                             checked={showSearchFilter} onChange={handleShowSearchFilter}/>
                            <label htmlFor="show-search-filter">Show Search Filter</label>
                        </div>
                        <div className="product-form-submit-btn-container">
                            <button type="button" className="add-search-filter-btn" onClick={(e:React.MouseEvent)=>{
                                //console.log('dns:', deptNameSelected);
                                    //console.log('dcns:', deptCategoryNameSelected);
                                    //console.log('pcns:', productCategoryNameSelected);
                                    const showErrorMsgs:boolean = showSearchFilterFormErrorMsgs({
                                                                                searchFilterNameSelected,
                                                                                deptNameSelected,
                                                                                deptCategoryNameSelected,
                                                                                productCategoryNameSelected,
                                                                                isSearchFilterAreaNameSelected,
                                                                                setShowSearchFilterNameErrorMsg,
                                                                                setSearchFilterNameErrorMsg,
                                                                                setShowSearchFilterAreaNameErrorMsg,
                                                                                setShowSearchFilterNameLimitError
                                                                                                    });
                                    if(showErrorMsgs){
                                        return;
                                    } else {

                                        isSearchFilterUnique(
                                            searchFilterAreaTypeSelected,
                                            searchFilterAreaNameSelected,
                                            searchFilterNameSelected,
                                            searchFilterSelected,
                                            showSearchFilter   
                                            )
                                            .then(({dbResponseMsg}:DbResponse)=>{
                                                //setShowStoreStructureLoadingMsg(false);
                                                if(dbResponseMsg === 'record found'){
                                                    //show alert that search filter already exits
                                                    setShowSearchFilterNameErrorMsg(true);
                                                    setSearchFilterNameErrorMsg('Search filter already exists');
                                                    /*
                                                    setStoreStructureAlertMsg('search filter already exists');
                                                    setShowStoreStructureAlert(true);
                                        
                                                    setTimeout(()=>{
                                                        setStoreStructureAlertMsg('no msg');
                                                        setShowStoreStructureAlert(false);
                                                    },2000);
                                                    */
                                                
                                                } else {
                                                    handleAddSearchTerm(
                                                        showDepartment,
                                                        showDepartmentCategory,
                                                        showProductCategory,
                                                        deptNameSelected,
                                                        deptCategoryNameSelected,
                                                        productCategoryNameSelected,
                                                        searchFilterNameSelected,
                                                        searchTermNameSelected,
                                                        searchFilterSelected,
                                                        showSearchFilter,
                                                        searchFilterAreaNameSelected,
                                                        searchFilterAreaTypeSelected
                                                                    );
                                                }
                                            })
                                            .catch((err:React.ErrorInfo)=>console.error('dbResponse fetch error:', err));

                                    }

                                }}>Add Search Term</button>
                            <button type="submit" className="add-search-filter-save-btn">Save</button>
                            <button type="button" className="add-search-filter-cancel-btn"
                                onClick={handleCancelAddSearchFilter}>
                                    Cancel
                            </button>

                            {
                                (showProcessingRequestMsg)
                                ?
                                <div className="process-request-msg">
                                    Processing...
                                </div>
                                :
                                null
                            }
                        </div>
                    </form>
                </div>
    
    } else {
        return  <div className="ssdcs-loader-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>;
    }
}


export default AddSearchFilter;