import Review from './review';
import ProductVerification from './productVerification';
import ProductPromotion from './productPromotion';
import ProductDescription from './productDescription';
import NutritionFacts from './nutritionFacts';
import SearchFilterTerm from './searchFilterTerm';
import ProductZoomImage from './productZoomImage';
import HomePageLayoutItem from './homePageLayoutItem';

class Product extends HomePageLayoutItem {
    public homePageLayoutId:number;
    public productId:number;
    public productName:string;
    public productLinkName:string;
    public totalPrice:number;
    public eachPrice:number;
    public salePrice:number;
    public showSalePrice:boolean;
    public showListPrice:boolean;
    public showSalePromotionImage:boolean;
    public showPrimoPickPromotionImage:boolean;
    public showAdPromotionImage:boolean;
    public showGlutenFreeVerification:boolean;
    public showGoLocalVerification:boolean;
    public showHeartHealthyVerification:boolean;
    public showSelectIngredientsVerification:boolean;
    public unitOfMeasureSalePrice:number;
    public unitOfMeasureListPrice:number;
    public unitOfMeasure:string;
    public showEachPrice:boolean;
    public showUnitOfMeasureSalePrice:boolean;
    public showUnitOfMeasureListPrice:boolean;
    public weightBy:string;
    public showWeightBy:boolean;
    public weightList:number[];
    public packingOption:string;
    public showPackingOption:boolean;
    public availability:string;
    public productCategoryName:string;
    public productCategoryId:number;
    public productSubCategoryName:string;
    public storeDepartmentName:string;
    public departmentCategoryName:string;
    public shoppingCartQuantity:string | number;
    public productImage:string;
    public productSoloImage:string;
    public productMiniCartImage:string;
    public productMobileImage:string;
    public productZoomImages:ProductZoomImage[];
    public showProductZoomImages:boolean;
    public productDateAdded:Date;
    public rating:number;
    public reviews:Review[];
    public productPromotions:ProductPromotion[];
    public productVerifications:ProductVerification[];
    public productDescription:ProductDescription | null;
    public nutritionFacts:NutritionFacts | null;
    public showNutritionFacts:boolean;
    public searchFilterTerms:SearchFilterTerm[];
    public productQtyLimit:number;
    public productQtyLimitExceeded:boolean;
    public selectedToFullCart:boolean;
    public mobileImageHeight:number;
    public mobileImageWidth:number;

    constructor(options:{
        homePageLayoutId?:number,
        productId?:number,
        productName?:string,
        productLinkName?:string,
        totalPrice?:number,
        eachPrice?:number,
        salePrice?:number,
        showSalePrice?:boolean,
        showListPrice?:boolean,
        showSalePromotionImage?:boolean,
        showPrimoPickPromotionImage?:boolean,
        showGlutenFreeVerification?:boolean,
        showGoLocalVerification?:boolean,
        showHeartHealthyVerification?:boolean,
        showSelectIngredientsVerification?:boolean,
        showAdPromotionImage?:boolean,
        unitOfMeasureSalePrice?:number,
        unitOfMeasureListPrice?:number,
        unitOfMeasure?:string,
        showEachPrice?:boolean,
        showUnitOfMeasureSalePrice?:boolean,
        showUnitOfMeasureListPrice?:boolean,
        weightBy?:string,
        showWeightBy?:boolean,
        weightList?:number[],
        packingOption?:string,
        showPackingOption?:boolean,
        availability?:string,
        productCategoryName?:string,
        productCategoryId?:number,
        productSubCategoryName?:string,
        storeDepartmentName?:string,
        departmentCategoryName?:string,
        shoppingCartQuantity?:string | number,
        productImage?:string,
        productSoloImage?:string,
        productMiniCartImage?:string,
        productMobileImage?:string,
        productZoomImages?:ProductZoomImage[],
        showProductZoomImages?:boolean,
        productDateAdded?:Date,
        rating?:number,
        reviews?:Review[],
        productPromotions?:ProductPromotion[],
        productVerifications?:ProductVerification[],
        productDescription?:ProductDescription | null,
        nutritionFacts?:NutritionFacts | null,
        showNutritionFacts?:boolean,
        searchFilterTerms?:SearchFilterTerm[],
        productQtyLimit?:number,
        productQtyLimitExceeded?:boolean,
        selectedToFullCart?:boolean,
        mobileImageHeight?:number,
        mobileImageWidth?:number
        
    } = {}){
        super(options);
        this.homePageLayoutId = options.homePageLayoutId || 0;
        this.productId = options.productId || 0;
        this.productName = options.productName || '';
        this.productLinkName = options.productLinkName || '';
        this.totalPrice = options.totalPrice || 0;
        this.eachPrice = options.eachPrice || 0;
        this.salePrice = options.salePrice || 0;
        this.showSalePrice = options.showSalePrice || false;
        this.showListPrice = options.showListPrice || false;
        this.showSalePromotionImage = options.showSalePromotionImage || false;
        this.showPrimoPickPromotionImage = options.showPrimoPickPromotionImage || false;
        this.showAdPromotionImage = options.showAdPromotionImage || false;
        this.showGlutenFreeVerification = options.showGlutenFreeVerification || false;
        this.showGoLocalVerification = options.showGoLocalVerification || false;
        this.showHeartHealthyVerification = options.showHeartHealthyVerification || false;
        this.showSelectIngredientsVerification = options.showSelectIngredientsVerification || false;
        this.unitOfMeasureSalePrice = options.unitOfMeasureSalePrice || 0;
        this.unitOfMeasureListPrice = options.unitOfMeasureListPrice || 0;
        this.unitOfMeasure = options.unitOfMeasure || 'lb';
        this.showEachPrice = options.showEachPrice || false;
        this.showUnitOfMeasureSalePrice = options.showUnitOfMeasureSalePrice || false;
        this.showUnitOfMeasureListPrice = options.showUnitOfMeasureListPrice || false;
        this.weightBy = options.weightBy || 'each';
        this.showWeightBy = options.showWeightBy || false;
        this.weightList = options.weightList || [];
        this.packingOption = options.packingOption || 'none';
        this.showPackingOption = options.showPackingOption || false;
        this.availability = options.availability || 'out of stock';
        this.productCategoryName = options.productCategoryName || 'none';
        this.productCategoryId = options.productCategoryId || 0;
        this.productSubCategoryName = options.productSubCategoryName || '';
        this.storeDepartmentName = options.storeDepartmentName || '';
        this.departmentCategoryName = options.departmentCategoryName || '';
        this.shoppingCartQuantity = options.shoppingCartQuantity || 0;
        this.productImage = options.productImage || '';
        this.productSoloImage = options.productSoloImage || '';
        this.productMiniCartImage = options.productMiniCartImage || '';
        this.productMobileImage = options.productMobileImage || '';
        this.productZoomImages = options.productZoomImages || [];
        this.showProductZoomImages = options.showProductZoomImages || false;
        this.productDateAdded = options.productDateAdded || new Date();
        this.rating = options.rating || 0;
        this.reviews = options.reviews || [];
        this.productPromotions = options.productPromotions || [];
        this.productVerifications = options.productVerifications || [];
        this.productDescription = options.productDescription || null;
        this.nutritionFacts = options.nutritionFacts || null;
        this.showNutritionFacts = options.showNutritionFacts || false;
        this.searchFilterTerms = options.searchFilterTerms || [];
        this.productQtyLimit = options.productQtyLimit || 99;
        this.productQtyLimitExceeded = options.productQtyLimitExceeded || false;
        this.selectedToFullCart = options.selectedToFullCart || false;
        this.mobileImageHeight = options.mobileImageHeight || 0;
        this.mobileImageWidth = options.mobileImageWidth || 0;
    }
}

export default Product;