import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import {getDepartmentNamesPromise, 
    getDepartmentCategoryNamesPromise, 
    getProductCategoryNamesPromise} from './helper-get-store-structure-area-types';
import StoreDepartment from './storeDepartment';
import DepartmentCategory from './departmentCategory';
import FormErrorMessage, {FormError, FormErrorType} from './FormErrorMessage';
import DBErrorMessage, {DBError} from './DBErrorMessage';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import ShopCategory from './shopCategory';
import SubShopCategory from './subShopCategory';
import {connect} from 'react-redux';
import StoreDepartmentSelectControl from './StoreDepartmentSelectControl';
//import {AddProductShopCategories, addProductShopCategories} from './redux/actions';
import StoreLocation, { Availability } from './storeLocation';
import LoaderAnimation from './LoaderAnimation';
import PhoneNumber from './PhoneNumber';
import './AddStoreLocation.css';

/*
const mapDispatchToProps = {
    addProductShopCategories
};
*/

interface Props {
    //showAddDepartmentCategory:boolean;
    showEditStoreLocation:boolean;
    storeLocationToEdit?:StoreLocation;
    handleCloseForm:(formType:string, cancel?:boolean)=>void;
    //addProductShopCategories?:(productShopCategories:ShopCategory[])=>AddProductShopCategories;
}

interface StoreLocationImage {
    source:string;
    path:string;
    type:string;
}

interface ThumbnailImage {
    img:HTMLImageElement;
    name:string;
}

interface EditStoreLocationInfo {
    storeLocationToEdit?:StoreLocation;
    storeLocationImages?:StoreLocationImage[];
    storeLocationNameSelected?:string;
    storeLocationToEditError?:Error;
}

interface MultipleDay {
    position:number;
    show:boolean;
}

const sns = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 
            'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 
            'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 
            'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 
            'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];



const AddStoreLocation: React.FunctionComponent<Props> = (props:Props) => {
    const {showEditStoreLocation, storeLocationToEdit ,handleCloseForm/*, addProductShopCategories*/} = props;
    const [departments, setDepartments] = useState<StoreDepartment[]>([]);
    //const [departmentNames, setDepartmentNames] = useState<string[]>([]);
    const [storeLocationNameSelected, setStoreLocationNameSelected] = useState('');
    const [streetAddressSelected, setStreetAddressSelected] = useState('');
    const [citySelected, setCitySelected] = useState('');
    const [stateSelected, setStateSelected] = useState('');
    const [zipCodeSelected, setZipCodeSelected] = useState('');
    const [storePhoneNumberSelected, setStorePhoneNumberSelected] = useState('');
    const [storeNumberSelected, setStoreNumberSelected] = useState('');
    const [locationLatitudeSelected, setLocationLatitudeSelected] = useState('');
    const [locationLongitudeSelected, setLocationLongitudeSelected] = useState('');
    const [pharmacyPhoneNumberSelected, setPharmacyPhoneNumberSelected] = useState('');
    const [showDeptNameErrorMsg, setShowDeptNameErrorMsg] = useState(false);
    const [deptNameErrorMsg, setDeptNameErrorMsg] = useState('Please add a department name.');
    const [departmentCategories, setDepartmentCategories] = useState<DepartmentCategory[]>([]);
    const [departmentCategoryNames, setDepartmentCategoryNames] = useState<string[]>([]);
    const [departmentCategoryNameSelected, setDepartmentCategoryNameSelected] = useState('');
    const [showDeptCategoryNameErrorMsg, setShowDeptCategoryNameErrorMsg] = useState(false);
    const [deptCategoryNameErrorMsg, setDeptCategoryNameErrorMsg] = useState('Please add a department category name.');
    const [storeLocationToEditId, setStoreLocationToEditId] = useState(0);
    const [showCurbsidePickup, setShowCurbsidePickup] = useState(false);
    const [storeLocationThumbnailImages, setStoreLocationThumbnailImages] = useState<ThumbnailImage[]>([]);
    const [storeLocationImages, setStoreLocationImages] = useState<File[]>([]);
    const [editStoreLocationLoaded, setEditStoreLocationLoaded] = useState(false);


    //snLabelRef
    //saLabelRef
    //saLabelRef
    //cityLabelRef
    //stateLabelRef
    //zcLabelRef
   //spnLabelRef
   //llatLabelRef
   //llongLabelRef
   //ppnLabelRef

    //department select element reference
    const dSelectElementRef = useRef<{dSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    const sSelectElementRef = useRef<{dSelectElementRef:React.RefObject<HTMLSelectElement>}>(null);
    const slnLabelRef = useRef<HTMLLabelElement>(null);
    const curbsidePickupRef = useRef<HTMLInputElement>(null);
    const relatedProductCategoryShortcutRef = useRef<HTMLInputElement>(null);
    const sliLabelRef = useRef<HTMLLabelElement>(null);
    const sstLabelRef = useRef<HTMLLabelElement>(null);
    const pstLabelRef = useRef<HTMLLabelElement>(null);
    const setLabelRef = useRef<HTMLLabelElement>(null);
    const petLabelRef = useRef<HTMLLabelElement>(null);
    const shsLabelRef = useRef<HTMLDivElement>(null);
    const phsLabelRef = useRef<HTMLDivElement>(null);     
    const shdLabelRef = useRef<HTMLLabelElement>(null);
    const snLabelRef = useRef<HTMLInputElement>(null);
    const saLabelRef = useRef<HTMLInputElement>(null);
    const cityLabelRef = useRef<HTMLInputElement>(null);
    const stateLabelRef = useRef<HTMLInputElement>(null);
    const zcLabelRef = useRef<HTMLInputElement>(null);
    const spnLabelRef = useRef<HTMLInputElement>(null);
    const storeNumberLabelRef = useRef<HTMLInputElement>(null);
    const lcLabelRef = useRef<HTMLDivElement>(null);
    const llatLabelRef = useRef<HTMLInputElement>(null);
    const llongLabelRef = useRef<HTMLInputElement>(null);
    const ppnLabelRef = useRef<HTMLInputElement>(null);
    const csLabelRef = useRef<HTMLLabelElement>(null);
    const imageStoreLocationRef = useRef<HTMLInputElement>(null);
    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });
    const [dbError, setDBError] = useState<DBError>({
                                                storeLocationNameError:{hasError:false, area:'none'},
                                                storeLocationNumberError:{hasError:false, area:'none'},
                                                latitudeCoordinateError:{hasError:false, area:'none'},
                                                longitudeCoordinateError:{hasError:false, area:'none'},
                                                imageErrors:[]
                                                    });


    const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);
    const dbErrorMsgRef = useRef<{dbemcRef:React.RefObject<HTMLDivElement>}>(null);

    const [daysOfTheWeek, setDaysOfTheWeek] = useState<string[]>([/*'Mon-Sun', 'Mon-Fri',*/ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
    const [storeDayOfTheWeekSelected, setStoreDayOfTheWeekSelected] = useState('Monday');
    const [storeDayOfTheWeek2Selected, setStoreDayOfTheWeek2Selected] = useState('Monday');
    const [storeDayOfTheWeek3Selected, setStoreDayOfTheWeek3Selected] = useState('Monday');
    const [storeDayOfTheWeek4Selected, setStoreDayOfTheWeek4Selected] = useState('Monday');
    const [storeDayOfTheWeek5Selected, setStoreDayOfTheWeek5Selected] = useState('Monday');
    const [storeDayOfTheWeek6Selected, setStoreDayOfTheWeek6Selected] = useState('Monday');
    const [storeDayOfTheWeek7Selected, setStoreDayOfTheWeek7Selected] = useState('Monday');
    const [storeMultiDayOfTheWeekSelected, setStoreMultiDayOfTheWeekSelected] = useState('Monday');
    const [storeMultiDayOfTheWeek2Selected, setStoreMultiDayOfTheWeek2Selected] = useState('Monday');
    const [storeMultiDayOfTheWeek3Selected, setStoreMultiDayOfTheWeek3Selected] = useState('Monday');
    const [storeMultiDayOfTheWeek4Selected, setStoreMultiDayOfTheWeek4Selected] = useState('Monday');
    const [storeMultiDayOfTheWeek5Selected, setStoreMultiDayOfTheWeek5Selected] = useState('Monday');
    const [storeMultiDayOfTheWeek6Selected, setStoreMultiDayOfTheWeek6Selected] = useState('Monday');
    const [storeMultiDayOfTheWeek7Selected, setStoreMultiDayOfTheWeek7Selected] = useState('Monday');
    const [pharmacyDayOfTheWeekSelected, setPharmacyDayOfTheWeekSelected] = useState('Monday');
    const [pharmacyDayOfTheWeek2Selected, setPharmacyDayOfTheWeek2Selected] = useState('Monday');
    const [pharmacyDayOfTheWeek3Selected, setPharmacyDayOfTheWeek3Selected] = useState('Monday');
    const [pharmacyDayOfTheWeek4Selected, setPharmacyDayOfTheWeek4Selected] = useState('Monday');
    const [pharmacyDayOfTheWeek5Selected, setPharmacyDayOfTheWeek5Selected] = useState('Monday');
    const [pharmacyDayOfTheWeek6Selected, setPharmacyDayOfTheWeek6Selected] = useState('Monday');
    const [pharmacyDayOfTheWeek7Selected, setPharmacyDayOfTheWeek7Selected] = useState('Monday');
    const [pharmacyMultiDayOfTheWeekSelected, setPharmacyMultiDayOfTheWeekSelected] = useState('Monday');
    const [pharmacyMultiDayOfTheWeek2Selected, setPharmacyMultiDayOfTheWeek2Selected] = useState('Monday');
    const [pharmacyMultiDayOfTheWeek3Selected, setPharmacyMultiDayOfTheWeek3Selected] = useState('Monday');
    const [pharmacyMultiDayOfTheWeek4Selected, setPharmacyMultiDayOfTheWeek4Selected] = useState('Monday');
    const [pharmacyMultiDayOfTheWeek5Selected, setPharmacyMultiDayOfTheWeek5Selected] = useState('Monday');
    const [pharmacyMultiDayOfTheWeek6Selected, setPharmacyMultiDayOfTheWeek6Selected] = useState('Monday');
    const [pharmacyMultiDayOfTheWeek7Selected, setPharmacyMultiDayOfTheWeek7Selected] = useState('Monday');
    const [storeLocationStartTime, setStoreLocationStartTime] = useState<string>('06:00');
    const [storeLocationStartTime2, setStoreLocationStartTime2] = useState<string>('06:00');
    const [storeLocationStartTime3, setStoreLocationStartTime3] = useState<string>('06:00');
    const [storeLocationStartTime4, setStoreLocationStartTime4] = useState<string>('06:00');
    const [storeLocationStartTime5, setStoreLocationStartTime5] = useState<string>('06:00');
    const [storeLocationStartTime6, setStoreLocationStartTime6] = useState<string>('06:00');
    const [storeLocationStartTime7, setStoreLocationStartTime7] = useState<string>('06:00');
    const [storeLocationStartTimeSelected, setStoreLocationStartTimeSelected] = useState<string>('6:00 AM');
    const [storeLocationStartTime2Selected, setStoreLocationStartTime2Selected] = useState<string>('noTime');
    const [storeLocationStartTime3Selected, setStoreLocationStartTime3Selected] = useState<string>('noTime');
    const [storeLocationStartTime4Selected, setStoreLocationStartTime4Selected] = useState<string>('noTime');
    const [storeLocationStartTime5Selected, setStoreLocationStartTime5Selected] = useState<string>('noTime');
    const [storeLocationStartTime6Selected, setStoreLocationStartTime6Selected] = useState<string>('noTime');
    const [storeLocationStartTime7Selected, setStoreLocationStartTime7Selected] = useState<string>('noTime');
    const [storeLocationEndTime, setStoreLocationEndTime] = useState<string>('23:00');
    const [storeLocationEndTime2, setStoreLocationEndTime2] = useState<string>('23:00');
    const [storeLocationEndTime3, setStoreLocationEndTime3] = useState<string>('23:00');
    const [storeLocationEndTime4, setStoreLocationEndTime4] = useState<string>('23:00');
    const [storeLocationEndTime5, setStoreLocationEndTime5] = useState<string>('23:00');
    const [storeLocationEndTime6, setStoreLocationEndTime6] = useState<string>('23:00');
    const [storeLocationEndTime7, setStoreLocationEndTime7] = useState<string>('23:00');
    const [storeLocationEndTimeSelected, setStoreLocationEndTimeSelected] = useState<string>('11:00 PM');
    const [storeLocationEndTime2Selected, setStoreLocationEndTime2Selected] = useState<string>('noTime');
    const [storeLocationEndTime3Selected, setStoreLocationEndTime3Selected] = useState<string>('noTime');
    const [storeLocationEndTime4Selected, setStoreLocationEndTime4Selected] = useState<string>('noTime');
    const [storeLocationEndTime5Selected, setStoreLocationEndTime5Selected] = useState<string>('noTime');
    const [storeLocationEndTime6Selected, setStoreLocationEndTime6Selected] = useState<string>('noTime');
    const [storeLocationEndTime7Selected, setStoreLocationEndTime7Selected] = useState<string>('noTime');
    const [pharmacyStartTime, setPharmacyStartTime] = useState<string>('06:00');
    const [pharmacyStartTime2, setPharmacyStartTime2] = useState<string>('06:00');
    const [pharmacyStartTime3, setPharmacyStartTime3] = useState<string>('06:00');
    const [pharmacyStartTime4, setPharmacyStartTime4] = useState<string>('06:00');
    const [pharmacyStartTime5, setPharmacyStartTime5] = useState<string>('06:00');
    const [pharmacyStartTime6, setPharmacyStartTime6] = useState<string>('06:00');
    const [pharmacyStartTime7, setPharmacyStartTime7] = useState<string>('06:00');
    const [pharmacyStartTimeSelected, setPharmacyStartTimeSelected] = useState<string>('6:00 AM');
    const [pharmacyStartTime2Selected, setPharmacyStartTime2Selected] = useState<string>('noTime');
    const [pharmacyStartTime3Selected, setPharmacyStartTime3Selected] = useState<string>('noTime');
    const [pharmacyStartTime4Selected, setPharmacyStartTime4Selected] = useState<string>('noTime');
    const [pharmacyStartTime5Selected, setPharmacyStartTime5Selected] = useState<string>('noTime');
    const [pharmacyStartTime6Selected, setPharmacyStartTime6Selected] = useState<string>('noTime');
    const [pharmacyStartTime7Selected, setPharmacyStartTime7Selected] = useState<string>('noTime');
    const [pharmacyEndTime, setPharmacyEndTime] = useState<string>('23:00');
    const [pharmacyEndTime2, setPharmacyEndTime2] = useState<string>('23:00');
    const [pharmacyEndTime3, setPharmacyEndTime3] = useState<string>('23:00');
    const [pharmacyEndTime4, setPharmacyEndTime4] = useState<string>('23:00');
    const [pharmacyEndTime5, setPharmacyEndTime5] = useState<string>('23:00');
    const [pharmacyEndTime6, setPharmacyEndTime6] = useState<string>('23:00');
    const [pharmacyEndTime7, setPharmacyEndTime7] = useState<string>('23:00');
    const [pharmacyEndTimeSelected, setPharmacyEndTimeSelected] = useState<string>('11:00 PM');
    const [pharmacyEndTime2Selected, setPharmacyEndTime2Selected] = useState<string>('noTime');
    const [pharmacyEndTime3Selected, setPharmacyEndTime3Selected] = useState<string>('noTime');
    const [pharmacyEndTime4Selected, setPharmacyEndTime4Selected] = useState<string>('noTime');
    const [pharmacyEndTime5Selected, setPharmacyEndTime5Selected] = useState<string>('noTime');
    const [pharmacyEndTime6Selected, setPharmacyEndTime6Selected] = useState<string>('noTime');
    const [pharmacyEndTime7Selected, setPharmacyEndTime7Selected] = useState<string>('noTime');
    const [numberOfStoreDays, setNumberOfStoreDays] = useState<number[]>([1]);
    const [numberOfPharmacyDays, setNumberOfPharmacyDays] = useState<number[]>([1]);
    const [numberOfLocationServices, setNumberOfLocationServices] = useState<number[]>([1]);

    const storeDowWrappers = useRef<HTMLDivElement[]>([]);
    const pharmacyDowWrappers = useRef<HTMLDivElement[]>([]);
    const [showStoreMultipleDay, setShowStoreMultipleDay] = useState(false);
    const [showStoreMultipleDay2, setShowStoreMultipleDay2] = useState(false);
    const [showStoreMultipleDay3, setShowStoreMultipleDay3] = useState(false);
    const [showStoreMultipleDay4, setShowStoreMultipleDay4] = useState(false);
    const [showStoreMultipleDay5, setShowStoreMultipleDay5] = useState(false);
    const [showStoreMultipleDay6, setShowStoreMultipleDay6] = useState(false);
    const [showStoreMultipleDay7, setShowStoreMultipleDay7] = useState(false);
    const [showPharmacyMultipleDay, setShowPharmacyMultipleDay] = useState(false);
    const [showPharmacyMultipleDay2, setShowPharmacyMultipleDay2] = useState(false);
    const [showPharmacyMultipleDay3, setShowPharmacyMultipleDay3] = useState(false);
    const [showPharmacyMultipleDay4, setShowPharmacyMultipleDay4] = useState(false);
    const [showPharmacyMultipleDay5, setShowPharmacyMultipleDay5] = useState(false);
    const [showPharmacyMultipleDay6, setShowPharmacyMultipleDay6] = useState(false);
    const [showPharmacyMultipleDay7, setShowPharmacyMultipleDay7] = useState(false);

    
    const [stateNames, setStateNames] = useState(sns);
    const [showStateNameErrorMsg, setShowStateNameErrorMsg] = useState(false);
    const [stateNameErrorMsg, setStateNameErrorMsg] = useState('Please add a state.');

    const [showStoreLocationNameLimitError, setShowStoreLocationNameLimitError] = useState(false);
    const [showStoreLocationAddressLimitError, setShowStoreLocationAddressLimitError] = useState(false);
    const [showStoreLocationCityLimitError, setShowStoreLocationCityLimitError] = useState(false);
    const [showStoreLocationZipCodeLimitError, setShowStoreLocationZipCodeLimitError] = useState(false);
    const [showStoreLocationPhoneNumberLimitError, setShowStoreLocationPhoneNumberLimitError] = useState(false);
    const [showPharmacyPhoneNumberLimitError, setShowPharmacyPhoneNumberLimitError] = useState(false);
    const [showLatitudeCoordinatesLimitError, setShowLatitudeCoordinatesLimitError] = useState(false);
    const [showLongitudeCoordinatesLimitError, setShowLongitudeCoordinatesLimitError] = useState(false);
    const [showStoreNumberLimitError, setShowStoreNumberLimitError] = useState(false);

    const [showStoreHoursMissingError, setShowStoreHoursMissingError] = useState(false);
    const [showStoreHoursOverlapError,setShowStoreHoursOverlapError] = useState(false);
    const [showPharmacyHoursMissingError, setShowPharmacyHoursMissingError] = useState(false);
    const [showPharmacyHoursOverlapError, setShowPharmacyHoursOverlapError] = useState(false);

    const [showFileLimitError, setShowFileLimitError] = useState(false);



    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const [isLoading, setIsLoading] = useState(false);


    useEffect(()=>{
        if(showEditStoreLocation){

            Promise.all([/*getDepartmentNamesPromise(),*/ getStoreLocationToEditPromise(storeLocationToEdit!)])
            .then(([/*departments,*/ editStoreLocationInfo])=>{
                //console.log('ds:', departments);
                //setupDepartmentInfo([...departments]);
                
                //console.log('editStoreLocationInfo:', editStoreLocationInfo);
                //setupDepartmentInfo(editDepartmentInfo.relatedDepartments!);
                setStoreLocationNameSelected(editStoreLocationInfo.storeLocationNameSelected!);

                //original version
                //setupProductSubCategoryEditInfo(editProductSubCategoryInfo.productSubCategoryToEdit!);
                setupStoreLocationEditInfo(editStoreLocationInfo.storeLocationToEdit!);

                setupStoreLocationImages(editStoreLocationInfo.storeLocationImages!);

            })
            .catch((err)=>console.error('promise all for names error:', err));

        } 
        
    },[]);


    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    function resizeHandler(e:Event):void {
        const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
        const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
        dbemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
        sfgemc.style.maxWidth = `${formRef.current!.getBoundingClientRect().width}px`;
    }


    function getStoreLocationToEditPromise(sl:StoreLocation):Promise<EditStoreLocationInfo>{
        return fetch(`https://server.kando-proto-3.com/get-store-location-to-edit-mariadb/${sl.storeLocationId}`, {
                    method:'GET',
                    headers:{
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({storeLocationToEdit, storeLocationImages, storeLocationNameSelected }:EditStoreLocationInfo)=>{
                    const slte = new StoreLocation({...storeLocationToEdit});
                    //console.log('slte:', slte);
                    
                    //console.log('slis:', storeLocationImages);

                    //console.log('rdns:', relatedDepartments);
                    //console.log('slns:', storeLocationNameSelected);

                    return ({
                        storeLocationToEdit:slte,
                        storeLocationImages:storeLocationImages,
                        storeLocationNameSelected
                    });
                })
                .catch((err)=>{
                    console.error('fetch store location to edit error:', err.message);
                    return ({storeLocationToEditError:new Error(err.message)})
                });

    }

    /*
    function setupDepartmentInfo(ds:StoreDepartment[]):void {
        function addDepartments(ds:StoreDepartment[]):StoreDepartment[]{
            return ds.map((d)=>{
                return new StoreDepartment({...d});
            })
        }

        function addDepartmentNames(ds:StoreDepartment[]):string[]{
            return ds.map((d)=>{
                return d.departmentName;
            })
        }

        const uds = addDepartments(ds);
        setDepartments(uds);
        const dns = addDepartmentNames(uds);
        setDepartmentNames(dns);
    }
    */

    function formatLocationCoords(coord:number):string {
        let num = coord;
        let numString = num.toString();
        let decPos = numString.indexOf('.');
        if(decPos === -1){
        	return `${numString}.00000`;
            
        } else {
            //console.log('decPos:', numString.slice(decPos + 1));
            let strAfterDecimal = numString.slice(decPos + 1);
            const strLengthAfterDecimal = strAfterDecimal.length;

            if(strLengthAfterDecimal < 5){
                switch(strLengthAfterDecimal){
                    case 0:
                        //console.log('num:', );
                        return `${numString}.00000`;
                    case 1:
                        //console.log('num:', numString + '0000');
                        return `${numString}0000`;
                    case 2:
                        //console.log('num:', numString + '000');
                        return `${numString}000`;
                    case 3:
                        //console.log('num:', numString + '00');
                        return `${numString}00`;
                    case 4:
                        //console.log('num:', numString + '0');
                        return `${numString}0`;
                    default:
                        throw new Error('no string length found.');
                }

            } else {
                return numString;
            }
        }
    }

    function setupStoreLocationEditInfo(sl:StoreLocation):void {
        //console.log('slei:',  sl.storePhone.replace(/( |-|\(|\))/g, ''), sl.pharmacyPhone.replace(/( |-|\(|\))/g, ''), sl.location.lat.toString());
        //console.log('coords10:', formatLocationCoords(sl.location.lat), formatLocationCoords(sl.location.lng));
        setStoreLocationNameSelected(sl.storeName);
        //setShowDepartmentCategoryShortcut(sl.showDepartmentCategoryShortcut);
        setStateSelected(sl.state);
        setCitySelected(sl.city);
        setZipCodeSelected(sl.zipCode);
        setStorePhoneNumberSelected(sl.storePhone.replace(/( |-|\(|\))/g, ''));
        setPharmacyPhoneNumberSelected(sl.pharmacyPhone.replace(/( |-|\(|\))/g, ''));
        setStreetAddressSelected(sl.streetAddress);
        setStoreNumberSelected(sl.storeNumber.toString());
        setLocationLatitudeSelected(formatLocationCoords(sl.location.lat));
        setLocationLongitudeSelected(formatLocationCoords(sl.location.lng));

        if(sl.storeHours.length === 1){
            const dayofWeek = sl.storeHours[0].day;
            const hours = sl.storeHours[0].hours;
            const nod = 1;
            //console.log('hours:', sl.storeHours[0].hours);
        
            addTimeToInputControls(hours, nod, 'store');

            //console.log('fdow:', formatDaysOfWeek(dayofWeek));
            const dsow = formatDaysOfWeek(dayofWeek);

            if(dsow.length === 1){
                setDayOfTheWeek('store', dsow[0], nod);

            } else {
                setDayOfTheWeek('store', dsow[0], nod);
                setMultiDayOfTheWeek('store', dsow[1], nod);
                handleShowMultipleDay(nod, 'store', false);
            }

            //setDayOfTheWeek('store', dayofWeek, nod);
            setNumberOfStoreDays([1]);
        } else {
            const shs = sl.storeHours.map((sh, index)=>{
                    const nod = index + 1;
                    const dayofWeek = sh.day;
                    const hours = sh.hours;
                    //console.log('hours:', sh.hours);

                    addTimeToInputControls(hours, nod, 'store');

                    //console.log('fdow:', formatDaysOfWeek(dayofWeek));
                    const dsow = formatDaysOfWeek(dayofWeek);
        
                    if(dsow.length === 1){
                        setDayOfTheWeek('store', dsow[0], nod);
        
                    } else {
                        setDayOfTheWeek('store', dsow[0], nod);
                        setMultiDayOfTheWeek('store', dsow[1], nod);
                        handleShowMultipleDay(nod, 'store', false);
                    }
                    
                    
                    //setDayOfTheWeek('store', dayofWeek, nod);
                    return nod;
                });

            setNumberOfStoreDays([...shs]);
        }

        if(sl.pharmacyHours.length === 1){
            const dayofWeek = sl.pharmacyHours[0].day;
            const hours = sl.pharmacyHours[0].hours;
            const nod = 1;
            //console.log('hours:', sl.pharmacyHours[0].hours);

            addTimeToInputControls(hours, nod, 'pharmacy');

            //console.log('fdow:', formatDaysOfWeek(dayofWeek));
            const dsow = formatDaysOfWeek(dayofWeek);

            if(dsow.length === 1){
                setDayOfTheWeek('pharmacy', dsow[0], nod);

            } else {
                setDayOfTheWeek('pharmacy', dsow[0], nod);
                setMultiDayOfTheWeek('pharmacy', dsow[1], nod);
                handleShowMultipleDay(nod, 'pharmacy', false);
            }

            //setDayOfTheWeek('pharmacy', dayofWeek, nod);
            setNumberOfPharmacyDays([1]);
        } else {
            const phs = sl.pharmacyHours.map((ph, index)=>{
                    const nod = index + 1;
                    const dayofWeek = ph.day;
                    const hours = ph.hours;
                    //console.log('hours:', ph.hours);
                    
                    addTimeToInputControls(hours, nod, 'pharmacy');

                    //console.log('fdow:', formatDaysOfWeek(dayofWeek));
                    const dsow = formatDaysOfWeek(dayofWeek);
        
                    if(dsow.length === 1){
                        setDayOfTheWeek('pharmacy', dsow[0], nod);
        
                    } else {
                        setDayOfTheWeek('pharmacy', dsow[0], nod);
                        setMultiDayOfTheWeek('pharmacy', dsow[1], nod);
                        handleShowMultipleDay(nod, 'pharmacy', false);
                    }

                    //setDayOfTheWeek('pharmacy', dayofWeek, nod);
                    return nod;
                });

            setNumberOfPharmacyDays([...phs]);
        }
        
        
        setShowCurbsidePickup(sl.curbsidePickup);
        setStoreLocationToEditId(sl.storeLocationId!);
        setEditStoreLocationLoaded(true);
        //need to set form a file image
        //setProductSearchImages(product.productImage);

        //psc.showProductSubCategoryKeywordSearch ? keywordSearchRef.current!.checked = true : keywordSearchRef.current!.checked = false;
        //psc.showProductSubCategoryNameSearch ? nameSearchRef.current!.checked = true : nameSearchRef.current!.checked = false;
        
        //original version
        //window.setTimeout(()=>triggerStoreDepartmentChangeEvent(psc.departmentName), 100);
    
    }

    function getAMorPMStartTime(hours:string):string {
        const r = hours.slice(5,8).trim() === 'AM' ? 'AM' : 'PM';
        return r;
    }

    function getAMorPMEndTime(hours:string):string {
        const r = hours.slice(-2) === 'PM' ? 'PM' : 'AM';
        return r;
    }

    function getFormattedStartTime(hours:string):string {
        const r = hours.slice(0,5).trim();
        return r;
    }

    function getFormattedEndTime(hours:string):string {
        const r = hours.slice(-8, -2).trim();
        return r;
    }

    function getOriginalPMTime(ft:string, timeFrame:string):string {
        if(timeFrame === 'PM' && getPMTimeRange(ft)){
            return formatPMTime(ft);

        } else {
            return ft;
        }
        
    }

    function getPMTimeRange(ft:string):boolean {
        const timeArray = ft.split(':');
        const hrs = parseInt(timeArray[0],10);
        const mins = parseInt(timeArray[1],10);
        //console.log('hrs:', hrs, 'mins:', mins);
        if(hrs < 12){
            return true;

        } else {
            return false;
        }
    }

    function formatPMTime(ft:string):string {
        const timeArray = ft.split(':');
        const hrs = parseInt(timeArray[0],10);
        const mins = parseInt(timeArray[1],10);
        //console.log('opt:', `${hrs + 2 + 10}:${mins}`);
        return `${hrs + 2 + 10}:${mins < 10 ? `0${mins}` : mins}`;
    }

    function getOriginalAMTime(ft:string, timeFrame:string):string {
        if(timeFrame === 'AM' && get12AMTimeRange(ft)){
            return format12AMTime(ft);

        } else if(timeFrame === 'AM' && ft.length === 4){
            return `0${ft}`;

        } else {
            return ft;
        }
        
    }

    function get12AMTimeRange(ft:string):boolean {
        const timeArray = ft.split(':');
        const hrs = parseInt(timeArray[0],10);
        const mins = parseInt(timeArray[1],10);
        //console.log('hrs:', hrs, 'mins:', mins);
        if(hrs === 12){
            return true;

        } else {
            return false;
        }
    }

    function format12AMTime(ft:string):string {
        const timeArray = ft.split(':');
        const hrs = parseInt(timeArray[0],10);
        const mins = parseInt(timeArray[1],10);
        
        if(mins === 0){
            return '00:00';
        } else if(mins < 10){
            return `00:0${mins}`;
        } else {
            return `00:${mins}`;
        }

    }

    function addTimeToInputControls(hours:string, nod:number, category:string):void {
        const AMPMstartTime = getAMorPMStartTime(hours);
        //console.log('hours:', AMPMstartTime);

        const AMPMendTime = getAMorPMEndTime(hours);
        //console.log('hours:', AMPMendTime);

        const formattedStartTime = getFormattedStartTime(hours);
        //console.log('hours:', formattedStartTime);
        //setStoreLocationStartTimeSelected(formattedStartTime);

        const formattedEndTime = getFormattedEndTime(hours);
        //console.log('hours:', formattedEndTime);
        //setStoreLocationEndTimeSelected(formattedEndTime);

        if(AMPMstartTime === 'AM' && formattedStartTime){
            //console.log('hours:', getOriginalAMTime(formattedStartTime, AMPMstartTime));
            const ost = getOriginalAMTime(formattedStartTime, AMPMstartTime)
            //setStoreLocationStartTime(ost);
            setTime(category, 'start', nod, ost, formattedStartTime);
        } else if(AMPMstartTime === 'PM' && formattedStartTime) {
            //console.log('hours:', getOriginalPMTime(formattedStartTime, AMPMstartTime));
            const ost = getOriginalPMTime(formattedStartTime, AMPMstartTime)
            //setStoreLocationStartTime(ost);
            setTime(category, 'start', nod, ost, formattedStartTime);
        } 
        
        if(AMPMendTime === 'AM' && formattedEndTime) {
            //console.log('hours:', getOriginalAMTime(formattedEndTime, AMPMendTime));
            const oet = getOriginalAMTime(formattedEndTime, AMPMendTime);
            //setStoreLocationEndTime(oet);
            setTime(category, 'end', nod, oet, formattedEndTime);
        
        } else if(AMPMendTime === 'PM' && formattedEndTime) {
            //console.log('hours:', getOriginalPMTime(formattedEndTime, AMPMendTime));
            const oet = getOriginalPMTime(formattedEndTime, AMPMendTime)
            //setStoreLocationEndTime(oet);
            setTime(category, 'end', nod, oet, formattedEndTime);
        }
    }



    /*
    function handleDeptNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        console.log('dns:', (e.target as HTMLSelectElement).value);
        const dns = (e.target as HTMLSelectElement).value;
        if(dns !== 'none'){
            setDepartmentNameSelected(dns);
            setShowDeptNameErrorMsg(false);
            const found = departments.filter((d)=>{
                                        return d.departmentName === dns;
                                    });

            if(found.length === 0){
            throw new Error('no department found.');
            }

            const dId = found[0].departmentId;

        } else {
            setDepartmentNameSelected(dns);
            setDepartmentCategoryNames([]);
            setDepartmentCategoryNameSelected('none');
        }
        
        
    }
    */


    /*
    function setupDepartmentInfo(dcs:DepartmentCategory[]):void {
        function addDepartmentCategories(dcs:DepartmentCategory[]):DepartmentCategory[]{
            return dcs.map((dc)=>{
                return new DepartmentCategory({...dc});
            })
        }

        function addDepartmentCategoryNames(dcs:DepartmentCategory[]):string[]{
            return dcs.map((dc)=>{
                return dc.departmentCategoryName;
            })
        }

        const udcs = addDepartmentCategories(dcs);
        setDepartmentCategories(udcs);
        const dcns = addDepartmentCategoryNames(udcs);
        setDepartmentCategoryNames(dcns);

    }
    */
   

    /*
    function relatedDepartmentCategories(departmentId:number):Promise<StoreDepartment[]> {

        return fetch(`http://localhost:9500/get-related-departments/${departmentId.toString()}`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
                }
            })
            .then((res)=>{
                return res.json();
            })
            .then((ds:StoreDepartment[])=>{
                console.log('dcs 3-28:', ds);
                setupDepartmentInfo(ds);
                return;
            })
            .catch((err)=>{
                console.error('err:', err.message)
                return err.message;
            });


    }
    */

    function setupStoreLocationImages(storeLocationImages:StoreLocationImage[]):void {
        storeLocationImages.forEach((sli)=>{
            //console.log('sli:', sli);
            //add images by category
            const si = new Image();
            //previous version
            //si.src = `${process.env.PUBLIC_URL}${sli.path}`;
            si.src = `https://server.kando-proto-3.com/${sli.path}`;
            //original version
            //setProductSubCategoryThumbnailImages([{img:si, name:psci.path}]);
            setStoreLocationThumbnailImages([{img:si, name:getFileName(sli.path)}]);
            //console.log('di.source s:', sli.source);
            const sbyteCharacters = window.atob(sli.source);
            const sbyteNumbers = new Array(sbyteCharacters.length);
            for (let i = 0; i < sbyteCharacters.length; i++) {
                sbyteNumbers[i] = sbyteCharacters.charCodeAt(i);
            }
            const sbyteArray = new Uint8Array(sbyteNumbers);
            const sBlob = new Blob([sbyteArray], {type:sli.type});
            const sFile = new File([sBlob], sli.path, {type:sli.type});
            //console.log('sFile:', sFile)
            //const sBlob = new Blob([pi.source], {type:pi.type});
            //const sFile = new File([sBlob], pi.path, {type:pi.type});
            setStoreLocationImages([sFile]);
        });


    }

    function getFileName(filePath:string):string {
        const lastForwardSlash = filePath.lastIndexOf('/');
        const fileName = filePath.slice(lastForwardSlash + 1);
        return fileName;
    }

    function handleCurbsidePickup(e:React.ChangeEvent):void {
        e.stopPropagation();
        const curbsidePickupChecked = (e.target as HTMLInputElement).checked;
        //console.log('checked:', curbsidePickupChecked);
        curbsidePickupChecked ? setShowCurbsidePickup(true) : setShowCurbsidePickup(false);

    }


    function imageClickHandler(e:React.MouseEvent):void {
        //console.log('e.target:', e.target);
        e.preventDefault();
        //e.stopPropagation();
        imageStoreLocationRef.current!.click();
    }

    function handleFileUpload(e:React.ChangeEvent):void {
        //console.log('file upload:', e.target, (e.target as HTMLInputElement).files);
        //console.log('file upload:', (e.target as HTMLInputElement).files![0].size, (e.target as HTMLInputElement).files![0].size / (1024 ** 2));
        const target = e.target as HTMLInputElement;
        const fileList = (e.target as HTMLInputElement).files;
        const files = Array.from(fileList!);

        if(fileList![0].size / (1024 ** 2) <= 1.5){
            addThumbNailImage(files);

            setShowFileLimitError(false);            

        } else {
            setShowFileLimitError(true);

        }

        //previous version
        //addThumbNailImage(files);
    }

    function addThumbNailImage(files:File[]):void {
        let fileImgs:ThumbnailImage[] = [];

        files.forEach((f)=>{
            const img = new Image();
            img.src = URL.createObjectURL(f);
            //img.height = 40;
            //img.width = 40;
            //not using this function because I need a local copy so
            //the image is found when deleting from list
            //would revoke them in general if I did need to reference a local copy
            //before saving the image to the database
            /*
            img.onload = function(){
                URL.revokeObjectURL(img.src);
            }*/
            
            fileImgs.push({name:f.name, img:img})
        });

        setStoreLocationThumbnailImages([...fileImgs]);
        setStoreLocationImages([...files]);



                
    }

    function setupStoreLocationThumbnailImages(tis:ThumbnailImage[]):JSX.Element {
        //console.log('tis:', tis);
        if(tis.length === 0){
            return  <div className="product-image-search-list-container">No images selected</div>
        } else {
            return  <div className="product-image-search-list-container">
                        <ul className="product-image-search-list">
                            {tis.map((ti, k)=>{
                                return <li key={k} className="image-list-item">
                                            <img className="store-location-thumb-nail-img" src={ti.img.src} height={40} width={135}/>
                                            <span className="image-list-item-url-container">
                                                <span>{ti.name}</span>
                                                <span className="image-list-item-close-btn" onClick={(e:React.MouseEvent)=>removeImageFromList2(/*ti.img, ti.name*/)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </span>
                                            </span>
                                        </li>
                            })}
                        </ul>
                    </div>
        }

    }

    function removeImageFromList2():void {
        //remove all thumbnails
        setStoreLocationThumbnailImages([]);

        //remove all images
        setStoreLocationImages([]);
    }

    /*
    function removeImageFromList(img:HTMLImageElement, fileNameSelected:string):void {
        console.log('img:', img, `/images/product-category/${fileNameSelected}`);
        
        const imageToDelete = {filePath:`/images/product-category/${fileNameSelected}`, directory:'product-category'};

        fetch(`http://localhost:9500/delete-form-list-image`, {
                    method:'POST',
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })
                .then((res)=>res.json())
                .then(({msg})=>{

                    const stis = departmentThumbnailImages.filter((i)=>{
                        return i.img !== img;
                    });

                    //original version
                    //setProductSubCategoryThumbnailImages(stis);
                    //remove all thumbnails
                    setDepartmentThumbnailImages([]);

                    const sis = departmentImages.filter((f)=>{
                                    return f.name !== fileNameSelected;
                                });
                                

                    //original version
                    //setProductSubCategoryImages(sis);
                    //remove all images
                    setDepartmentImages([]);


                    console.log('department image removed from form:', msg);
                })
                .catch((err)=>console.error('remove department image from public folder error:', err.message));

    }
    */

    function removeImagefromDatabase(searchFilterAreaId:number, filePathDirectorySelected:string):Promise<Response> {
        //original version
        //const imageToDelete = {filePath:`${filePathSelected}`, directory:`${filePathSelectedDirectory}`};

        const imageToDelete = {searchFilterAreaId, filePathDirectorySelected};


        return fetch(`https://server.kando-proto-3.com/delete-form-list-image`, {
                    method:'POST',
                    //body:JSON.stringify(imageToDelete),
                    body:JSON.stringify(imageToDelete),
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                })

    }

    function getNextNumberId(oid:string):number {
        let id = window.parseInt(oid, 10);
        return ++id;
    }

    function formatTimeValues(time:string):string {
        const hour = window.parseInt(time.slice(0, 2),10);
        const mins = window.parseInt(time.slice(-2),10);
        //console.log('time:', time, 'hour', hour, 'mins:', mins);
        let timeString = "";
        if(hour === 0){
            timeString = `12:${convertMinutes(mins)} AM`;
        } else if(hour === 12){
            timeString = `12:${convertMinutes(mins)} PM`;
        } else if((hour - 12) < 0){
            timeString = `${hour}:${convertMinutes(mins)} AM`;
        } else {
            timeString = `${hour - 12}:${convertMinutes(mins)} PM`;
        }

        return timeString;
    }

    
    function getHours(form:HTMLFormElement, category:string):Availability[] {
        const nods = category === 'store' ? numberOfStoreDays.length : numberOfPharmacyDays.length;
        let has = [];

        if(nods === 1){
            const st = (form.elements.namedItem(`${category}StartTime`) as RadioNodeList).value;
            const et = (form.elements.namedItem(`${category}EndTime`) as RadioNodeList).value;
            //const dow = (form.elements.namedItem(`${category}DayOfWeek`) as RadioNodeList).value;
            const ffdtns = formatFormDaysToNums(category);
            const days = formatFormNumsToDays(ffdtns);

            has.push(new Availability({
                //id:1,
                order:1,
                //day:dow,
                day:days[0],
                hours:`${formatTimeValues(st)} - ${formatTimeValues(et)}`
            }));
        }

        if(nods > 1){
            const stes = Array.from(form.elements.namedItem(`${category}StartTime`) as RadioNodeList) as HTMLInputElement[];
            const etes = Array.from(form.elements.namedItem(`${category}EndTime`) as RadioNodeList) as HTMLInputElement[];
            //const dowes = Array.from(form.elements.namedItem(`${category}DayOfWeek`) as RadioNodeList) as HTMLSelectElement[];
            const ffdtns = formatFormDaysToNums(category);
            //console.log('days:', formatFormNumsToDays(ffdtns));
            const days = formatFormNumsToDays(ffdtns);
            //console.log('dns:', formatExistingDaysToNums(days));
            const dayNums = formatExistingDaysToNums(days);
            //loop thru all store values to build availability
            for(let i=0; i < nods; i++){
                has.push(new Availability({
                    //id:getDowId(dowes[i].value, daysOfTheWeek),
                    //order:getDowId(dowes[i].value, daysOfTheWeek),
                    order:getDowOrder(dayNums[i], [1,2,3,4,5,6,7]),
                    //day:dowes[i].value,
                    day:days[i],
                    hours:`${formatTimeValues(stes[i].value)} - ${formatTimeValues(etes[i].value)}`
                }));
            }

            has.sort(sortAvailability);
        }

        return has;

    }

    function getDowId(dow:string, daysOfTheWeek:string[]):number {
        let dowId:number = 0;
        let dsotw = daysOfTheWeek.length;
        for(let i=0; i < dsotw; i++){
            if(dow === daysOfTheWeek[i]){
                dowId = i + 1;
                break;
            }
        }

        return dowId;
    }

    function getDowOrder(dns:number[], daysOfTheWeek:number[]):number {
        let dowOrder:number = 0;
        let dsotw = daysOfTheWeek.length;
        const dayNums = dns;
        for(let i=0; i < dsotw; i++){
            if(dayNums[0] === daysOfTheWeek[i] || dayNums[1] === daysOfTheWeek[i]){
                dowOrder = i + 1;
                break;
            }
        }

        return dowOrder;
    }

    function sortAvailability(a:Availability, b:Availability):number {
        //return a.id - b.id;
        return a.order - b.order;
    }

    function formatExistingDaysToNums(days:string[]):number[][] {
        const dns = days.map((day)=>{
                        let dayNums = [];
                        const d = day.split('-');
                        for(let i=0; i < d.length; i++){
                            switch(d[i]){
                                case 'Monday':
                                    dayNums.push(1);
                                    break;
                                case 'Mon':
                                    dayNums.push(1);
                                    break;                                    
                                case 'Tuesday':
                                    dayNums.push(2);
                                    break;
                                case 'Tue':
                                    dayNums.push(2);
                                    break;                                    
                                case 'Wednesday':
                                    dayNums.push(3);
                                    break;
                                case 'Wed':
                                    dayNums.push(3);
                                    break;                                    
                                case 'Thursday':
                                    dayNums.push(4);
                                    break;
                                case 'Thu':
                                    dayNums.push(4);
                                    break;    
                                case 'Friday':
                                    dayNums.push(5);
                                    break;
                                case 'Fri':
                                    dayNums.push(5);
                                    break;                                    
                                case 'Saturday':
                                    dayNums.push(6);
                                    break;
                                case 'Sat':
                                    dayNums.push(6);
                                    break;                                    
                                case 'Sunday':
                                    dayNums.push(7);
                                    break;
                                case 'Sun':
                                    dayNums.push(7);
                                    break;                                    
                                default:
                                    throw new Error('no day nums found.');
                            }
                        }

                        return dayNums;

                    });

        return dns;
    }

    function formatFormDaysToNums(category:string):number[][] {
        const dows = category === 'store' ? storeDowWrappers.current : pharmacyDowWrappers.current;
        //console.log('dows:', dows, dows[0].children);
        let days = [];

        const fdcvs = dows.map((dow)=>{
                            const dowContainers = Array.from(dow.children) as HTMLDivElement[];
                            let dowContainerValues = [];  
                            for(let i=0; i < dowContainers.length; i++){
                                if(dowContainers[i] instanceof HTMLDivElement){
                                    const day = (dowContainers[i].lastElementChild!.firstElementChild as HTMLSelectElement).value;
                                    switch(day){
                                        case 'Monday':
                                            dowContainerValues.push(1);
                                            break;
                                        case 'Tuesday':
                                            dowContainerValues.push(2);
                                            break;
                                        case 'Wednesday':
                                            dowContainerValues.push(3);
                                            break;
                                        case 'Thursday':
                                            dowContainerValues.push(4);
                                            break;
                                        case 'Friday':
                                            dowContainerValues.push(5);
                                            break;
                                        case 'Saturday':
                                            dowContainerValues.push(6);
                                            break;
                                        case 'Sunday':
                                            dowContainerValues.push(7);
                                            break;
                                        default:
                                            throw new Error('no week day found.');
                                    }
                                }
                            }


                            //console.log('dcvs:', dowContainerValues);
                            
                            /*
                            if(dowContainerValues[0] === dowContainerValues[1]){
                                //need to trigger form error when this condition is met.
                                //has same day name error
                                console.log('r:', 'has same day error');
                                return 'sameDayError';
                            } else {
                                //const dayString = dowContainerValues.join('-');
                                //console.log('r:', dayString);
                                //return dayString;
                                return dowContainerValues;
                            }
                            */

                            return dowContainerValues;

                        });

        //console.log('fdcvs:', fdcvs);
        return fdcvs;
    }

    function formatFormNumsToDays(ffds:number[][]):string[] {
        const fds = ffds.map((ffd)=>{
                            let days = [];
                            for(let i=0; i < ffd.length; i++){
                                switch(ffd[i]){
                                    case 1:
                                        days.push('Mon');
                                        break;
                                    case 2:
                                        days.push('Tue');
                                        break;
                                    case 3:
                                        days.push('Wed');
                                        break;
                                    case 4:
                                        days.push('Thu');
                                        break;
                                    case 5:
                                        days.push('Fri');
                                        break;
                                    case 6:
                                        days.push('Sat');
                                        break;
                                    case 7:
                                        days.push('Sun');
                                        break;
                                    default:
                                        throw new Error('no week day number found.');
                                }
                            }

                            return days;
                        });

        const ufds = fds.map((fd)=>{
                        if(fd.length === 1){
                            return fd[0];

                        } else {
                            return fd.join('-');
                        }
                    });

        
        return ufds;

    }


    function checkHoursFormError(ffds:number[][], category:string):boolean {
        let hasHoursFormError = false;

        const nods = category === 'store' ? numberOfStoreDays.length : numberOfPharmacyDays.length;

        if(nods > 1){
            const dowes = ffds; //Array.from(form.elements.namedItem(`${category}DayOfWeek`) as RadioNodeList) as HTMLSelectElement[];
            
            //console.log('days:', dowes);

            let dayNumbers:number[][] = [];
            for(let i=0; i < dowes.length; i++){
                if((dowes[i][0] === 1 && dowes[i][1] === 7)
                    || (dowes[i][0] === dowes[i][1])
                    || ((Math.abs(dowes[i][0] - dowes[i][1]) === 1) && (dowes[i][0] > dowes[i][1]))
                    ){
                    //show form error
                    hasHoursFormError = true;
                    break;
                }

                if(dayNumbers.length === 0){
                    dayNumbers.push(dowes[i]);

                } else {

                    //check overlapping days
                    const od = dayNumbers.find((dayNumber)=>{
                        //console.log('dn:', dayNumber);
                                    if(dayNumber.length ===1 && dowes[i].length === 1){
                                        if(dayNumber[0] === dowes[i][0]){
                                            return true;
                                        }

                                        return false;

                                    } else if(dayNumber.length === 1 && dowes[i].length > 1){
                                        //console.log('dn:', dayNumber, 'dowes:', dowes[i]);
                                        const dNum = dayNumber[0];
                                        const dowesNum1 = dowes[i][0];
                                        const dowesNum2 = dowes[i][1];

                                        if(dNum > dowesNum2 && dNum < dowesNum1){
                                            //console.log('sd false');
                                            return false;
                                        }

                                        if(dNum > dowesNum1 && dNum > dowesNum2 
                                            && dowesNum1 < dowesNum2){
                                            return false;
                                        }

                                        if(dNum < dowesNum1 && dNum < dowesNum2
                                            && dowesNum1 < dowesNum2){
                                            return false;
                                        }

                                        if(dNum < dowesNum1 && dNum < dowesNum2
                                            && dowesNum1 > dowesNum2){
                                                return true;
                                        }

                                        /*
                                        if(dNum > dowesNum1 && dNum > dowesNum2) {
                                            return true;
                                        }
                                        */


                                        if(dNum === dowesNum1 || dNum === dowesNum2){
                                            return true;
                                        }

                                        if(dNum > dowesNum1 && dNum < dowesNum2
                                            || dNum < dowesNum1 && dNum > dowesNum2){
                                                //console.log('sd true');
                                            return true;
                                        }

                                        return false;

                                    } else if(dayNumber.length > 1 && dowes[i].length === 1) {
                                        //console.log('dn:', dayNumber, 'dowes:', dowes[i]);
                                        const dNum1 = dayNumber[0];
                                        const dNum2 = dayNumber[1];
                                        const dowesNum1 = dowes[i][0];

                                        if(dowesNum1 > dNum2 && dowesNum1 < dNum1){
                                            //console.log('sd false');
                                            return false;
                                        }

                                        if(dowesNum1 > dNum1 && dowesNum1 > dNum2 
                                            && dNum1 < dNum2){
                                            return false;
                                        }

                                        if(dowesNum1 < dNum1 && dowesNum1 < dNum2
                                            && dNum1 < dNum2){
                                            return false;
                                        }

                                        if(dowesNum1 < dNum1 && dowesNum1 < dNum2
                                            && dNum1 > dNum2){
                                                return true;
                                        }

                                        /*
                                        if(dowesNum1 > dNum1 && dowesNum1 > dNum2) {
                                            return true;
                                        }
                                        */

                                        if(dowesNum1 === dNum1 || dowesNum1 === dNum2){
                                            return true;
                                        }

                                        if(dowesNum1 > dNum1 && dowesNum1 < dNum2
                                            || dowesNum1 < dNum1 && dowesNum1 > dNum2){
                                                //console.log('sd true');
                                            return true;
                                        }

                                        return false;

                                    } else {
                                        //console.log('dn:', dayNumber, 'dowes:', dowes[i]);
                                        const dNum1 = dayNumber[0];
                                        const dNum2 = dayNumber[1];
                                        const dowesNum1 = dowes[i][0];
                                        const dowesNum2 = dowes[i][1];

                                        if(dNum1 > dNum2 && dNum1 - dNum2 === 2
                                            || dowesNum1 > dowesNum2 && dowesNum1 - dowesNum2 === 2){
                                                //console.log('true');
                                            return true;
                                        }

                                        
                                        if(dowesNum1 > dNum2 && dowesNum2 < dNum1
                                            || dNum1 > dowesNum2 && dNum2 < dowesNum1){
                                                //console.log('false');
                                            return false;
                                        }
                                        


                                        if(dNum1 === dowesNum1 || dNum1 === dowesNum2
                                            || dNum2 === dowesNum1 || dNum2 === dowesNum2){
                                                //console.log('true');
                                            return true;
                                        }

                                        
                                        if(dowesNum1 > dNum1 && dowesNum1 < dNum2
                                            || dowesNum1 < dNum1 && dowesNum1 > dNum2){
                                                //console.log('true')
                                                return true;
                                            }

                                        if(dowesNum2 > dNum1 && dowesNum2 < dNum2
                                            || dowesNum2 < dNum1 && dowesNum2 > dNum2){
                                                //console.log('true');
                                                return true;
                                            }

                                        if(dNum1 > dowesNum1 && dNum1 < dowesNum2
                                            || dNum1 < dowesNum1 && dNum1 > dowesNum2){
                                                //console.log('true');
                                                return true;
                                            }

                                        if(dNum2 > dowesNum1 && dNum2 < dowesNum2
                                            || dNum2 < dowesNum1 && dNum2 > dowesNum2){
                                                //console.log('true');
                                                return true;
                                            }


                                        //console.log('false');
                                        return false;

                                    }
                                    
                                });

                    if(typeof od !== 'undefined'){
                        //show a form error
                        hasHoursFormError = true;
                        break;

                    } else {
                        dayNumbers.push(dowes[i]);
                    }
                    
                    /*
                    //check duplicate days
                    const dayFound = dayNumbers.find((dayNumber)=>{
                                                if(dayNumber.length === 1 && dowes[i].length === 1){
                                                    return (dayNumber[0] === dowes[i][0]);

                                                } else if(dayNumber.length === 1 && dowes[i].length > 1){
                                                    return (dayNumber[0] === dowes[i][0] 
                                                            || dayNumber[0] === dowes[i][1]
                                                            );

                                                } else if(dayNumber.length > 1 && dowes[i].length === 1){
                                                    return (dayNumber[0] === dowes[i][0] 
                                                            || dayNumber[1] === dowes[i][0]
                                                            );

                                                } else {
                                                    return (dayNumber[0] === dowes[i][0]
                                                            || dayNumber[0] === dowes[i][1]
                                                            || dayNumber[1] === dowes[i][0]
                                                            || dayNumber[1] === dowes[i][1]
                                                            );
                                                }
                                                
                                            });

                    if(typeof dayFound !== 'undefined'){
                        console.log('not found:', dayFound);
                        //show a form error
                        hasHoursFormError = true;
                        break;

                    }
                    */

                    //console.log('dns test:', dayNumbers);




                }


            }

            return hasHoursFormError;
        
        } else {

            const dowes = ffds;

            //console.log('days:', dowes);

            if(dowes[0][0] === dowes[0][1]){
                hasHoursFormError = true;
            }
            
            return hasHoursFormError;
        }
    }

    function checkZipCodeLength(zipCode:string):boolean {
        const zc =  zipCode.replace(/[^\d]/g, '');
        if(zc.length === 5 || zc.length === 9){
            return true;

        } else {
            return false;
        }
    }

    function checkPhoneNumber(phoneNumber:string):boolean {
        const pn = phoneNumber.replace(/[^\d]/g, '');

        if(pn.length === 10){
            return true;

        } else {
            return false;
        }
    }

    function isMissingStoreLocationHours(availability:Availability[]):boolean{
        let isMissingHours = false;
        availability.forEach((a)=>{
                    if(/NaN/gi.test(a.hours)){
                        isMissingHours = true;
                    }
                });
                
        return isMissingHours;
    }


    function handleFormSubmit(e:React.FormEvent, formAction:string):void {
        e.preventDefault();
        const form = e.target as HTMLFormElement;

        dbErrorMsgRef.current!.dbemcRef.current!.style.top = '-2000px';
        sfgErrorMsgRef.current!.sfgemcRef.current!.style.top = '-2000px';

        /*
        console.log('time sst1:', storeLocationStartTimeSelected);
        console.log('time sst2:', storeLocationStartTime2Selected);
        console.log('time sst3:', storeLocationStartTime3Selected);
        console.log('time sst4:', storeLocationStartTime4Selected);
        console.log('time sst5:', storeLocationStartTime5Selected);
        console.log('time sst6:', storeLocationStartTime6Selected);
        console.log('time sst7:', storeLocationStartTime7Selected);

        console.log('time set1:', storeLocationEndTimeSelected);
        console.log('time set2:', storeLocationEndTime2Selected);
        console.log('time set3:', storeLocationEndTime3Selected);
        console.log('time set4:', storeLocationEndTime4Selected);
        console.log('time set5:', storeLocationEndTime5Selected);
        console.log('time set6:', storeLocationEndTime6Selected);
        console.log('time set7:', storeLocationEndTime7Selected);

        console.log('time pst1:', pharmacyStartTimeSelected);
        console.log('time pst2:', pharmacyStartTime2Selected);
        console.log('time pst3:', pharmacyStartTime3Selected);
        console.log('time pst4:', pharmacyStartTime4Selected);
        console.log('time pst5:', pharmacyStartTime5Selected);
        console.log('time pst6:', pharmacyStartTime6Selected);
        console.log('time pst7:', pharmacyStartTime7Selected);

        console.log('time pet1:', pharmacyEndTimeSelected);
        console.log('time pet2:', pharmacyEndTime2Selected);
        console.log('time pet3:', pharmacyEndTime3Selected);
        console.log('time pet4:', pharmacyEndTime4Selected);
        console.log('time pet5:', pharmacyEndTime5Selected);
        console.log('time pet6:', pharmacyEndTime6Selected);
        console.log('time pet7:', pharmacyEndTime7Selected);
        */


        //rest form errors
        snLabelRef.current!.classList.remove('product-info-error');
        saLabelRef.current!.classList.remove('product-info-error');
        cityLabelRef.current!.classList.remove('product-info-error');
        //stateLabelRef.current!.classList.remove('product-info-error');
        //stateLabelRef.current!.classList.remove('product-info-error');
        zcLabelRef.current!.classList.remove('product-info-error');
        spnLabelRef.current!.classList.remove('product-info-error');
        storeNumberLabelRef.current!.classList.remove('product-info-error');
        llatLabelRef.current!.classList.remove('product-info-error');
        llongLabelRef.current!.classList.remove('product-info-error');
        ppnLabelRef.current!.classList.remove('product-info-error');
        shsLabelRef.current!.classList.remove('product-info-error');
        phsLabelRef.current!.classList.remove('product-info-error');

        sliLabelRef.current!.classList.remove('image-error');

        sSelectElementRef.current!.dSelectElementRef.current!.classList.remove('product-info-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        formError.imageErrors.length = 0;

        //product info
        //store name selected
        const slns = storeLocationNameSelected;
        if(slns.length === 0 || slns === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'Store Name'});

        } else {
            setShowStoreLocationNameLimitError(false);
        }

        //street address
        const sas = streetAddressSelected;
        if(sas.length === 0){
            //console.log('test');
            formError.productInfoErrors.push({hasError:true, area:'Street Address'});

        } else {
            setShowStoreLocationAddressLimitError(false);
        }

        //city
        const cs = citySelected;
        if(cs.length === 0 || cs === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'City'});

        } else {
            setShowStoreLocationCityLimitError(false);
        }

        //state
        const state = stateSelected;
        if(state.length === 0 || state === 'none'){
            formError.productInfoErrors.push({hasError:true, area:'State'});
        }
        //zip code
        const zcs = zipCodeSelected;
        if(zcs.length === 0 || !checkZipCodeLength(zcs)){
            formError.productInfoErrors.push({hasError:true, area:'Zip Code'});
            setShowStoreLocationZipCodeLimitError(true);

        } else {
            setShowStoreLocationZipCodeLimitError(false);
        }

        //store phone number
        const spns = storePhoneNumberSelected;
        if(spns.length === 0 || !checkPhoneNumber(spns)){
            formError.productInfoErrors.push({hasError:true, area:'Store Phone Number'});
            setShowStoreLocationPhoneNumberLimitError(true);

        } else {
            setShowStoreLocationPhoneNumberLimitError(false);
        }

        //store number
        const sns = storeNumberSelected;
        if(sns.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Store Number'});

        } else {
            setShowStoreNumberLimitError(false);
        }

        //location latitude
        const llats = locationLatitudeSelected;
        if(llats.length === 0 || !/^(-)?(\d{1,2})\.\d{5,7}$/.test(llats) || /^(-)?0[0-9]/.test(llats)){
            formError.productInfoErrors.push({hasError:true, area:'Location Latitude'});
            setShowLatitudeCoordinatesLimitError(true);

        } else {
            setShowLatitudeCoordinatesLimitError(false);
        }

        //location longitude
        const llongs = locationLongitudeSelected;
        if(llongs.length === 0 || !/^(-)?(\d{1,3})\.\d{5,7}$/.test(llongs) || /^(-)?0[0-9]/.test(llongs)){
            formError.productInfoErrors.push({hasError:true, area:'Location Longitude'});
            setShowLongitudeCoordinatesLimitError(true);

        } else {
            setShowLongitudeCoordinatesLimitError(false);
        }

        //format day/multi day into number arrays
        const fsds = formatFormDaysToNums('store');

        //console.log('hours:', getHours(form, 'store'));
        //console.log('hours:', getHours(form, 'pharmacy'));
       
        //check if store hours are missing
        const a1 = getHours(form, 'store');
        if(isMissingStoreLocationHours(a1)){
            formError.productInfoErrors.push({hasError:true, area:'Store Hours'});
            setShowStoreHoursMissingError(true);
            setShowStoreHoursOverlapError(false);

        } else 

        //store hours
        if(checkHoursFormError(fsds, 'store')){
            formError.productInfoErrors.push({hasError:true, area:'Store Hours'});
            setShowStoreHoursOverlapError(true);
            setShowStoreHoursMissingError(false);

        } else {
            setShowStoreHoursOverlapError(false);
            setShowStoreHoursMissingError(false);
        }

        //pharmacy phone number
        const ppns = pharmacyPhoneNumberSelected;
        if(ppns.length === 0 || !checkPhoneNumber(ppns)){
            formError.productInfoErrors.push({hasError:true, area:'Pharmacy Phone Number'});
            setShowPharmacyPhoneNumberLimitError(true);

        } else {
            setShowPharmacyPhoneNumberLimitError(false);
        }

        //format day/multi day into number arrays
        const fpds = formatFormDaysToNums('pharmacy');


        //check if pharmacy hours are missing
        const a2 = getHours(form, 'pharmacy');
        if(isMissingStoreLocationHours(a2)){
            formError.productInfoErrors.push({hasError:true, area:'Pharmacy Hours'});
            setShowPharmacyHoursMissingError(true);
            setShowPharmacyHoursOverlapError(false);

        } else 

        //pharmacy hours
        if(checkHoursFormError(fpds, 'pharmacy')){
            formError.productInfoErrors.push({hasError:true, area:'Pharmacy Hours'});
            setShowPharmacyHoursOverlapError(true);
            setShowPharmacyHoursMissingError(false);

        } else {
            setShowPharmacyHoursOverlapError(false);
            setShowPharmacyHoursMissingError(false);
        }


        
        //images-groups
        //department category image
        let slImages = storeLocationImages;
        if(slImages.length === 0 || slImages.length > 1){
            setShowFileLimitError(false);
            formError.imageErrors.push({hasError:true, area:'Store Location Image'});

        } else {

            setShowFileLimitError(false);

            const fn = slImages[0].name.toLowerCase();
            //const ufn = fn.replace(/[=?#+%^{}\[\]\\\/,<>"\*$@!|\(\)&~`]/gi, '');
            const ufn = fn.replace(/[\\\%,\?\]\[\)\(\*\^\$\"\'\<\>\|\+\=#@!&~`]/gi, '');
            const nfn = ufn.replace(/ /ig, '-');
    
            let blob = slImages[0].slice(0, slImages[0].size, slImages[0].type);
            const newFile = new File([blob], nfn, {type: blob.type});
            //remove original unfiltered file
            slImages.length = 0;
            slImages.push(newFile);
        }

        //console.log('slImages:', slImages);

        const pies = formError.productInfoErrors.length > 0 ? true : false;
        const ies = formError.imageErrors.length > 0 ? true : false;

        if(pies || ies){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            //console.log('fe:', formError);

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRef.current!.sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }

        setShowProcessingRequestMsg(true);


        if(formAction === 'addStoreLocation'){

            let storeLocationImageName = storeLocationImages.length > 0 ? storeLocationImages[0].name : 'none';
                //storeLocationImageName = formatFilePathName(storeLocationImageName).toLowerCase();
                storeLocationImageName = storeLocationImageName.toLowerCase();


            const slin = {
                //original version
                //productCategoryImageName:productCategoryImageName
                storeLocationImageName:storeLocationImageName
            };

            //console.log('slin:', slin);

            const urlParams = {storeLocationNameSelected, storeLocationImageName, storeNumberSelected, 
                locationLongitudeSelected, locationLatitudeSelected};

            const params = JSON.stringify(urlParams);


            //previous version
            //fetch(`http://localhost:9500/get-store-location-info/${storeLocationNameSelected}`, {
            fetch(`https://server.kando-proto-3.com/get-store-location-info`, {
                method:'POST',
                //body:JSON.stringify(slin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({storeLocationNameExists, storeLocationImageExists, lastStoreLocationId, 
                    storeLocationId, storeLocationNumberExists, latitudeCoordinateExists, longitudeCoordinateExists})=>{
                    //console.log('slne:', storeLocationNameExists);
                    //console.log('slie:', storeLocationImageExists);
                    //console.log('slnume:', storeLocationNumberExists);
                    //console.log('latce:', latitudeCoordinateExists);
                    //console.log('lngce:', longitudeCoordinateExists);
                    //console.log('lslId:', lastStoreLocationId);
                    //console.log('slId:', storeLocationId, 'storeLocationNameSelected:', storeLocationNameSelected);
                    

                    if(storeLocationNameExists || storeLocationImageExists || storeLocationNumberExists
                        || latitudeCoordinateExists || longitudeCoordinateExists){

                        setShowProcessingRequestMsg(false);
                        
                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.storeLocationNameError =  {hasError:false, area:'none'};
                        dbError.storeLocationNumberError =  {hasError:false, area:'none'};
                        dbError.latitudeCoordinateError =  {hasError:false, area:'none'};
                        dbError.longitudeCoordinateError =  {hasError:false, area:'none'};


                        if(latitudeCoordinateExists){
                            dbError.latitudeCoordinateError =  {hasError:true, area:locationLatitudeSelected};
                        }

                        dbError.latitudeCoordinateError = (dbError.latitudeCoordinateError!.hasError) 
                                                            ? dbError.latitudeCoordinateError 
                                                            : {hasError:false, area:'none'};


                        if(longitudeCoordinateExists){
                            dbError.longitudeCoordinateError =  {hasError:true, area:locationLongitudeSelected};
                        }

                        dbError.longitudeCoordinateError = (dbError.longitudeCoordinateError!.hasError) 
                                                            ? dbError.longitudeCoordinateError 
                                                            : {hasError:false, area:'none'};                                                            


                        if(storeLocationNumberExists){
                            dbError.storeLocationNumberError =  {hasError:true, area:storeNumberSelected};
                        }

                        dbError.storeLocationNumberError = (dbError.storeLocationNumberError!.hasError) 
                                                            ? dbError.storeLocationNumberError 
                                                            : {hasError:false, area:'none'};


                        if(storeLocationImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Store Location Image', fileName:storeLocationImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(storeLocationNameExists){
                            dbError.storeLocationNameError =  {hasError:true, area:storeLocationNameSelected};
                        }

                        dbError.storeLocationNameError = (dbError.storeLocationNameError!.hasError) 
                                                    ? dbError.storeLocationNameError 
                                                    : {hasError:false, area:'none'};

                        const de = {...dbError};
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
            
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;
            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);
            
                        });

                    } else {

                        const storeNumber = parseInt(storeNumberSelected,10);
                        const locationLatitude = parseFloat(locationLatitudeSelected);
                        const locationLongitude = parseFloat(locationLongitudeSelected);
                        //console.log('sn:', storeNumber, 'lat:', locationLatitude, 'lng:', locationLongitude);
                        const location = {lat:locationLatitude, lng:locationLongitude};
                        //const addressLocality = `${citySelected}, ${stateSelected} ${zipCodeSelected}`;

                        //format day/multi day into number arrays
                        //const ffds = formatFormDaysToNums('store');

                        //console.log('days:', formatFormNumsToDays(ffds));

                        //get store and pharmacy hours
                        const storeAvailability = getHours(form, 'store');
                        const pharamcyAvailability = getHours(form, 'pharmacy');
                        //console.log('avail:', storeAvailability);
                        //console.log('avail:', pharamcyAvailability);


                        //const pcId = window.parseInt(productCategoryId, 10);
                        const nextStoreLocationId = getNextNumberId(lastStoreLocationId);
                        
                        //create new department category filled with form data fields
                        const storeLocation = new StoreLocation({
                                                storeLocationId:nextStoreLocationId,
                                                storeLocationImage:slin.storeLocationImageName,
                                                storeName:slns,
                                                streetAddress:streetAddressSelected,
                                                storePhone:storePhoneNumberSelected,
                                                storeNumber:storeNumber,
                                                storeHours:storeAvailability,
                                                pharmacyHours:pharamcyAvailability,
                                                location:location,
                                                city:citySelected,
                                                state:stateSelected,
                                                zipCode:zipCodeSelected.replace(/ /g, ''),
                                                pharmacyPhone:pharmacyPhoneNumberSelected,
                                                curbsidePickup:showCurbsidePickup
                                            }); 

                        const fd = new FormData();
                        //add product info
                        fd.append('product-info', JSON.stringify(storeLocation));

                        //add store location category image
                        fd.append('store-location-image', storeLocationImages[0]);


                        
                    
                        fetch(`https://server.kando-proto-3.com/add-individual-store-location`, {
                            method:'POST',
                            body:fd
                        })
                        .then((res)=>{
                            setIsLoading(true);
                            return res.json();
                        })
                        .then((res)=>{
                            //console.log('add individual store location response:', res);
                            
                            //const pscs = res.pscs.map((psc:ShopCategory)=>{
                             //           return addShopCategory(psc);
                              //      });
                            
                            //console.log('pscs 7-11:', pscs);
                            //addProductShopCategories!([...pscs]);
                            
                            //close form update product list

                            handleCloseForm('addStoreLocation');
                        })
                        .catch((err)=>console.error('add individual store location fetch error:', err.message));
                        
                    }

                })
                .catch((err)=>{
                    console.error('store location err:', err)
                });
                
                
        } else {

            let storeLocationImageName = storeLocationImages.length > 0 ? storeLocationImages[0].name : 'none';
                //storeLocationImageName = formatFilePathName(storeLocationImageName).toLowerCase();
                storeLocationImageName = storeLocationImageName.toLowerCase();

            //console.log('result 6-7', productCategoryImageName);

            const slin = {
                //original version
                //productCategoryImageName:productCategoryImageName
                storeLocationImageName:storeLocationImageName
            };

            const urlParams = {storeLocationNameSelected, 
                                storeLocationToEditId, 
                                storeLocationImageName,
                                storeNumberSelected,
                                locationLongitudeSelected, 
                                locationLatitudeSelected};

            const params = JSON.stringify(urlParams);


            //previous version
            //fetch(`http://localhost:9500/check-store-location-exists/${storeLocationNameSelected}/${storeLocationToEditId}`, {
            fetch(`https://server.kando-proto-3.com/check-store-location-exists`, {
                method:'POST',
                //body:JSON.stringify(slin),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:params
                })
                .then((res)=>{
                    return res.json();
                })
                .then(({storeLocationNameExists, storeLocationImageExists, storeLocationNumberExists,
                    latitudeCoordinateExists, longitudeCoordinateExists, storeLocationId})=>{
                    //console.log('storeLocationNameExists:', storeLocationNameExists);
                    //console.log('storeLocationImageExists:', storeLocationImageExists);
                    //console.log('storeLocationId:', storeLocationId);


                    if(storeLocationNameExists || storeLocationImageExists || storeLocationNumberExists
                        || latitudeCoordinateExists || longitudeCoordinateExists){
                        
                        setShowProcessingRequestMsg(false);
                        
                        //clear all error messages
                        dbError.imageErrors = [];
                        dbError.storeLocationNameError =  {hasError:false, area:'none'};
                        dbError.storeLocationNumberError =  {hasError:false, area:'none'};
                        dbError.latitudeCoordinateError =  {hasError:false, area:'none'};
                        dbError.longitudeCoordinateError =  {hasError:false, area:'none'};


                        if(latitudeCoordinateExists){
                            dbError.latitudeCoordinateError =  {hasError:true, area:locationLatitudeSelected};
                        }

                        dbError.latitudeCoordinateError = (dbError.latitudeCoordinateError!.hasError) 
                                                            ? dbError.latitudeCoordinateError 
                                                            : {hasError:false, area:'none'};


                        if(longitudeCoordinateExists){
                            dbError.longitudeCoordinateError =  {hasError:true, area:locationLongitudeSelected};
                        }

                        dbError.longitudeCoordinateError = (dbError.longitudeCoordinateError!.hasError) 
                                                            ? dbError.longitudeCoordinateError 
                                                            : {hasError:false, area:'none'};   

                        if(storeLocationNumberExists){
                            dbError.storeLocationNumberError =  {hasError:true, area:storeNumberSelected};
                        }

                        dbError.storeLocationNumberError = (dbError.storeLocationNumberError!.hasError) 
                                                            ? dbError.storeLocationNumberError 
                                                            : {hasError:false, area:'none'};

                        if(storeLocationImageExists ){
                            dbError.imageErrors.push({hasError:true, area:'Store Location Image', fileName:storeLocationImages[0].name})
                        }

                        dbError.imageErrors = dbError.imageErrors.length > 0 ? dbError.imageErrors : [];

                        if(storeLocationNameExists){
                            dbError.storeLocationNameError =  {hasError:true, area:storeLocationNameSelected};
                        }

                        dbError.storeLocationNameError = (dbError.storeLocationNameError!.hasError) 
                                                    ? dbError.storeLocationNameError 
                                                    : {hasError:false, area:'none'};


                        const de = {...dbError};    
                        //console.log('de:', de);
                        setDBError(de);

                        const raf1 = window.requestAnimationFrame(()=>{

                            addDBFormLabelErrors(dbError);
        
                            //show all db errors
                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                            const ew = dbemc.offsetWidth;
                            const eh = dbemc.offsetHeight;
                            //console.log('eh:', eh);
                            //dbemc.style.marginLeft = `-${ew / 2}px`;
                            dbemc.style.maxWidth = `${form.getBoundingClientRect().width}px`;
                            dbemc.style.top = `-${eh}px`;

                            //remove comments after testing error message height
            
                            const raf2 = window.requestAnimationFrame(()=>{
                                            const dbemc = dbErrorMsgRef.current!.dbemcRef.current!;
                                            dbemc.style.top = '0px';
            
                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                dbemc.style.top = `-${eh}px`;
                                            },3000);
                                            */
            
                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                            
            
                        });
                        

                    } else {

                        removeImagefromDatabase(storeLocationId, 'store-location')
                        .then((res)=>res.json())
                        .then(({msg})=>{
                            //console.log('msg image:', msg);
                
                            if(msg === 'store-location image removed'){
                                //console.log('store location image removed from form:', msg);

                                const storeNumber = parseInt(storeNumberSelected,10);
                                const locationLatitude = parseFloat(locationLatitudeSelected);
                                const locationLongitude = parseFloat(locationLongitudeSelected);
                                //console.log('sn:', storeNumber, 'lat:', locationLatitude, 'lng:', locationLongitude);
                                const location = {lat:locationLatitude, lng:locationLongitude};
                                //const addressLocality = `${citySelected}, ${stateSelected} ${zipCodeSelected}`;
        
                                //get store and pharmacy hours
                                const storeAvailability = getHours(form, 'store');
                                const pharamcyAvailability = getHours(form, 'pharmacy');
                                //console.log('avail:', storeAvailability);
                                //console.log('avail:', pharamcyAvailability);

                                //console.log('phone:', storePhoneNumberSelected);
                                //console.log('phone:', pharmacyPhoneNumberSelected);

                                //create new department filled with form data fields
                                const storeLocation = new StoreLocation({
                                    storeLocationId:storeLocationId,
                                    storeLocationImage:slin.storeLocationImageName,
                                    storeName:slns,
                                    streetAddress:streetAddressSelected,
                                    storePhone: new RegExp(`\\(${storePhoneNumberSelected.slice(1,4)}\\)\\s${storePhoneNumberSelected.slice(6,9)}-${storePhoneNumberSelected.slice(10,14)}`).test(storePhoneNumberSelected) ? storePhoneNumberSelected : `(${storePhoneNumberSelected.slice(0,3)}) ${storePhoneNumberSelected.slice(3,6)}-${storePhoneNumberSelected.slice(6,10)}`,
                                    storeNumber:storeNumber,
                                    storeHours:storeAvailability,
                                    pharmacyHours:pharamcyAvailability,
                                    location:location,
                                    city:citySelected,
                                    state:stateSelected,
                                    zipCode:zipCodeSelected.replace(/ /g, ''),
                                    pharmacyPhone: new RegExp(`\\(${pharmacyPhoneNumberSelected.slice(1,4)}\\)\\s${pharmacyPhoneNumberSelected.slice(6,9)}-${pharmacyPhoneNumberSelected.slice(10,14)}`).test(pharmacyPhoneNumberSelected) ? pharmacyPhoneNumberSelected : `(${pharmacyPhoneNumberSelected.slice(0,3)}) ${pharmacyPhoneNumberSelected.slice(3,6)}-${pharmacyPhoneNumberSelected.slice(6,10)}`,
                                    curbsidePickup:showCurbsidePickup
                                    //showDepartmentCategoryShortcut:showDepartmentCategoryShortcut
                                });               


                                const fd = new FormData();
                                //add product info
                                fd.append('product-info', JSON.stringify(storeLocation));

                                //add product search image
                                fd.append('store-location-image', storeLocationImages[0]);

                                //console.log('product 6-7:', departmentCategory);
                                //console.log('department-category-image 6-7:', departmentCategoryImages[0]);

                                setIsLoading(true);


                                    
                                //update product in database
                                fetch(`https://server.kando-proto-3.com/update-individual-store-location`, {
                                    method:'PUT',
                                    body:fd
                                    })
                                    .then((res)=>{
                                        return res.json();
                                    })
                                    .then(({msg})=>{
                                        //console.log('update individual store response:', msg);

                                        /*
                                        const pscs = res.pscs.map((psc:ShopCategory)=>{
                                            return addShopCategory(psc);
                                        });
                                
                                        console.log('pscs 7-11:', pscs);
                                        addProductShopCategories!([...pscs]);
                                        */
                                        
                                        handleCloseForm('editStoreLocation');
                                    })
                                    .catch((err)=>console.error('update individual store location fetch error:', err.message));

                            } else {
                                throw new Error('file was not removed.');
                            }
                            
                        })
                        .catch((err)=>console.error('remove store location image from public folder error:', err.message));




                    }
                    




                })
                .catch((err)=>console.error('check store location exists fetch error:', err.message));



        }

    }

    function addShopCategory(sc:ShopCategory):ShopCategory {
        return new ShopCategory({shopCategoryId:sc.shopCategoryId,
                                shopCategoryLink:sc.shopCategoryLink,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                                });
    }

    function addSubShopCategories(sscs:SubShopCategory[]):SubShopCategory[] {
        return sscs.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });

    }

    function formatFilePathName(filePathName:string):string {
        //previous version
        //return filePathName.search(/\//i) === -1 ? `/images/store-location/${filePathName}` : filePathName;
        return filePathName.search(/\//i) === -1 ? `${filePathName}` : filePathName;
    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Store Name':
                                snLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Street Address':
                                saLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'City':
                                cityLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'State':
                                //stateLabelRef.current!.classList.add('product-info-error');
                                sSelectElementRef.current!.dSelectElementRef.current!.classList.add('product-info-error');
                                break;
                            case 'Zip Code':
                                zcLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Store Phone Number':
                                spnLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Store Number':
                                storeNumberLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Location Latitude':
                                llatLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Location Longitude':
                                llongLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Store Hours':
                                shsLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Pharmacy Phone Number':
                                ppnLabelRef.current!.classList.add('product-info-error');
                                break;
                            case 'Pharmacy Hours':
                                phsLabelRef.current!.classList.add('product-info-error');
                                break;                                                                                            
                            default:
                                throw new Error('no store location info errors found!');
                        }
                    }
                });


        //set image errors
        formError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Store Location Image'){
                //console.log('ie.area:', ie.area);
                sliLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });
    }



    function addDBFormLabelErrors(dbError:DBError): void {
        //set product info errors
        if(dbError.storeLocationNameError!.hasError){
            snLabelRef.current!.classList.add('product-info-error');
        }

        if(dbError.storeLocationNumberError!.hasError){
            storeNumberLabelRef.current!.classList.add('product-info-error');
        }

        if(dbError.latitudeCoordinateError!.hasError){
            llatLabelRef.current!.classList.add('product-info-error');
        }

        if(dbError.longitudeCoordinateError!.hasError){
            llongLabelRef.current!.classList.add('product-info-error');
        }
        
        //set image errors
        dbError.imageErrors.forEach((ie)=>{
            if(ie.hasError && ie.area === 'Store Location Image'){
                //console.log('ie.area:', ie.area);
                sliLabelRef.current!.classList.add('image-error');
            } else {
                throw new Error('no image errors found!');
            }
        });

    }


    function handleStateNameSelection(e:React.ChangeEvent):void {
        e.stopPropagation();
        //console.log('sn:', (e.target as HTMLSelectElement).value);
        const sn = (e.target as HTMLSelectElement).value;
        if(sn !== 'none'){
            setStateSelected(sn);
            setShowStateNameErrorMsg(false);
            const found = stateNames.filter((stateName)=>{
                                        return stateName === sn;
                                    });

            if(found.length === 0){
                throw new Error('No state found.');
            }


        } else {
            setStateSelected('none');
        }
        
        
    }

    function handleLocationInfo(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        //console.log('value.length:', value.length);
        switch(target.name){
            case'store-name':
                //setStoreLocationNameSelected(value);
                if(value.length <= 50){
                    setStoreLocationNameSelected(value);
                    setShowStoreLocationNameLimitError(false);
                } else {
                    setShowStoreLocationNameLimitError(true);
                }

                break;
            case'street-address':
                //setStreetAddressSelected(value);
                if(value.length <= 50){
                    setStreetAddressSelected(value);
                    setShowStoreLocationAddressLimitError(false);
                } else {
                    setShowStoreLocationAddressLimitError(true);
                }

                break;
            case'city':
                //setCitySelected(value);
                if(value.length <= 50){
                    setCitySelected(value);
                    setShowStoreLocationCityLimitError(false);
                } else {
                    setShowStoreLocationCityLimitError(true);
                }

                break;
            case'state':
                setStateSelected(value);
                break;
            case'zip-code':
                const r = value.replace(/[ -]/g, '');
                //console.log('value.length:', value.length, zipCodeSelected.length, r.length);
        
                if(/^\d*$/.test(r)){
                    if((/^\d*$/.test(r)) && (r.length >= 0) && (r.length <= 5)){
                        setZipCodeSelected(`${r}`);
                        setShowStoreLocationZipCodeLimitError(false);
        
                    } else if((/^\d*$/.test(r)) && (r.length >= 6) && (r.length <= 9)){
                        setZipCodeSelected(`${r.slice(0, 5)} - ${r.slice(5)}`);
                        setShowStoreLocationZipCodeLimitError(false);
        
                    } else {
                        setShowStoreLocationZipCodeLimitError(true);
                    }                  
        
                } else {
                    setShowStoreLocationZipCodeLimitError(true);
                }

                break;
            case'store-phone-number':
                formatPhoneNumber(value, storePhoneNumberSelected, 
                                setStorePhoneNumberSelected, setShowStoreLocationPhoneNumberLimitError);

                break;
            case'store-number':
                if(/^\d{1,5}$/.test(value) && parseInt(value) > 0){
                    setStoreNumberSelected(value);
                    setShowStoreNumberLimitError(false);
                } else {
                    setShowStoreNumberLimitError(true);
                }
                
                break;
            case'location-latitude':
                    //console.log('llv:', value);
                if(/^(-)?(\d{1,2})\.\d{1,7}$/.test(value)){
                    setLocationLatitudeSelected(value);
                    setShowLatitudeCoordinatesLimitError(false);
                    return;
                }

                if(/^(-)?(\d{1,2})\.$/.test(value)){
                    setLocationLatitudeSelected(value);
                    setShowLatitudeCoordinatesLimitError(false);
                    return;

                }            
                
                if(/^(-)?(\d{1,2})$/.test(value) && !/^(-)?0[0-9]/.test(value)){
                    const m = value.match(/^-?(\d{1,2})$/);
                    if(m !== null && (parseInt(m[0]) >= -90 && parseInt(m[0]) <= 90)){
                        //console.log('m:', m, parseInt(m[0]), (parseInt(m[0]) >= -90 && parseInt(m[0]) <= 90));
                        setLocationLatitudeSelected(value);
                        setShowLatitudeCoordinatesLimitError(false);
                        return;
                    }   
                }

                if(/^(-)?$/.test(value)){
                    setLocationLatitudeSelected(value);
                    setShowLatitudeCoordinatesLimitError(false);
                    return;
                }

            
                setShowLatitudeCoordinatesLimitError(true);

                
                break;
            case'location-longitude':
                if(/^(-)?(\d{1,3})\.\d{1,7}$/.test(value)){
                    setLocationLongitudeSelected(value);
                    setShowLongitudeCoordinatesLimitError(false);
                    return;
                }

                if(/^(-)?(\d{1,3})\.$/.test(value)){
                    setLocationLongitudeSelected(value);
                    setShowLongitudeCoordinatesLimitError(false);
                    return;

                }            
                
                if(/^(-)?(\d{1,3})$/.test(value) && !/^(-)?0[0-9]/.test(value)){
                    const m = value.match(/^-?(\d{1,3})$/);
                    if(m !== null && (parseInt(m[0]) >= -180 && parseInt(m[0]) <= 180)){
                        //console.log('m:', m, parseInt(m[0]), (parseInt(m[0]) >= -90 && parseInt(m[0]) <= 90));
                        setLocationLongitudeSelected(value);
                        setShowLongitudeCoordinatesLimitError(false);
                        return;
                    }   
                }

                if(/^(-)?$/.test(value)){
                    setLocationLongitudeSelected(value);
                    setShowLongitudeCoordinatesLimitError(false);
                    return;
                }

            
                setShowLongitudeCoordinatesLimitError(true);

               
                break;
            case'pharmacy-phone-number':
                //setPharmacyPhoneNumberSelected(value);
                formatPhoneNumber(value, pharmacyPhoneNumberSelected, 
                    setPharmacyPhoneNumberSelected, setShowPharmacyPhoneNumberLimitError);
                break;
            default:
                throw new Error('no form names found.');

        }


        
    }


    function formatPhoneNumber(value:string, phoneNumberSelected:string,
                            setPhoneNumber:(value: React.SetStateAction<string>) => void,
                            setShowPhoneNumberLimitError:(value: React.SetStateAction<boolean>) => void):void {
        const r = value.replace(/[\(\) -]/g, '');
        //console.log('value.length:', value.length, phoneNumberSelected.length, r.length);

        if(/^\d*$/.test(r)){
            if((/^\d*$/.test(r)) && (r.length === 0) && (value.length === 1) && (phoneNumberSelected.length === 2)){
                setPhoneNumber(`${r}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 1) && (value.length === 1) && (phoneNumberSelected.length === 0)){
                setPhoneNumber(`(${r}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 1) && (value.length === 2) && (phoneNumberSelected.length === 3)){
                setPhoneNumber(`(${r}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 2) && (value.length === 3) && (phoneNumberSelected.length === 2)){
                setPhoneNumber(`(${r}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 2) && (value.length === 3) && (phoneNumberSelected.length === 4)){
                setPhoneNumber(`(${r}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 3) && (value.length === 4) && (phoneNumberSelected.length === 3)){
                setPhoneNumber(`(${r})`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 3) && (value.length === 4) && (phoneNumberSelected.length === 5)){
                setPhoneNumber(`(${r}`);
                setShowPhoneNumberLimitError(false);

            }  else if((/^\d*$/.test(r)) && (r.length === 3) && (value.length === 6) && (phoneNumberSelected.length === 7)){
                setPhoneNumber(`(${r}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 4) && (value.length === 5) && (phoneNumberSelected.length === 4)){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3)}`);
                setShowPhoneNumberLimitError(false);

            }  else if((/^\d*$/.test(r)) && (r.length === 4) && (value.length === 6) && (phoneNumberSelected.length === 5)){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3)}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 4) && (value.length === 7) && (phoneNumberSelected.length === 8)){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3)}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 5) && (value.length === 8) && (phoneNumberSelected.length === 7)){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3)}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 5) && (value.length === 8) && (phoneNumberSelected.length === 9)){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3)}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 6) && (value.length === 9) && (phoneNumberSelected.length === 8)){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3)}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && (r.length === 6) && (value.length === 12) && (phoneNumberSelected.length === 13)){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3)}`);
                setShowPhoneNumberLimitError(false);

            } else if((/^\d*$/.test(r)) && ((r.length >= 7) && (r.length <= 10))){
                setPhoneNumber(`(${r.slice(0, 3)}) ${r.slice(3,6)} - ${r.slice(6,10)}`);
                setShowPhoneNumberLimitError(false);

            } else {
                setShowPhoneNumberLimitError(true);
            }                  

        } else {
            setShowPhoneNumberLimitError(true);
        }
    }


    function setupFormAction(showEditStoreLocation:boolean):string {
        return (showEditStoreLocation) 
                ? 'updateStoreLocation'
                : 'addStoreLocation'
    }

    function handleDayOfTheWeekSelection(e:React.ChangeEvent, category:string, nod:number):void {
        e.stopPropagation();
        //console.log('dotws:', (e.target as HTMLSelectElement).value);
        const dotws = (e.target as HTMLSelectElement).value;
        if(category === 'store'){
            //setStoreDayOfTheWeekSelected(dotws);
            setDayOfTheWeek(category, dotws, nod);
        } else {
            //setPharmacyDayOfTheWeekSelected(dotws);
            setDayOfTheWeek(category, dotws, nod);
        }
        
    }

    function handleMultiDayOfTheWeekSelection(e:React.ChangeEvent, category:string, nod:number):void {
        e.stopPropagation();
        //console.log('dotws:', (e.target as HTMLSelectElement).value);
        const dotws = (e.target as HTMLSelectElement).value;
        if(category === 'store'){
            //setStoreDayOfTheWeekSelected(dotws);
            setMultiDayOfTheWeek(category, dotws, nod);
        } else {
            //setPharmacyDayOfTheWeekSelected(dotws);
            setMultiDayOfTheWeek(category, dotws, nod);
        }
        
    }

    function formatDaysOfWeek(dotw:string):string[] {
        const dowArray = dotw.split('-');
        
        const dows = dowArray.map((d)=>{
                        switch(d){
                            case 'Mon':
                                return 'Monday';
                            case 'Tue':
                                return 'Tuesday';
                            case 'Wed':
                                return 'Wednesday';
                            case 'Thu':
                                return 'Thursday';
                            case 'Fri':
                                return 'Friday';
                            case 'Sat':
                                return 'Saturday';
                            case 'Sun':
                                return 'Sunday';
                            default:
                                throw new Error('no day of week found.');
                        }
                    });
            
        return dows;
        
    }


    function setDayOfTheWeek(category:string, dotws:string, nod:number){
        switch(category){
            case 'store':
                switch(nod){
                    case 1:
                        setStoreDayOfTheWeekSelected(dotws);
                        break;
                    case 2:
                        setStoreDayOfTheWeek2Selected(dotws);
                        break;
                    case 3:
                        setStoreDayOfTheWeek3Selected(dotws);
                        break;
                    case 4:
                        setStoreDayOfTheWeek4Selected(dotws);
                        break;
                    case 5:
                        setStoreDayOfTheWeek5Selected(dotws);
                        break;
                    case 6:
                        setStoreDayOfTheWeek6Selected(dotws);
                        break;
                    case 7:
                        setStoreDayOfTheWeek7Selected(dotws);
                        break;
                    default:
                        throw new Error('no store day of the week found');
                }
                break;
            
            case 'pharmacy':
                switch(nod){
                    case 1:
                        setPharmacyDayOfTheWeekSelected(dotws);
                        break;
                    case 2:
                        setPharmacyDayOfTheWeek2Selected(dotws);
                        break;
                    case 3:
                        setPharmacyDayOfTheWeek3Selected(dotws);
                        break;
                    case 4:
                        setPharmacyDayOfTheWeek4Selected(dotws);
                        break;
                    case 5:
                        setPharmacyDayOfTheWeek5Selected(dotws);
                        break;
                    case 6:
                        setPharmacyDayOfTheWeek6Selected(dotws);
                        break;
                    case 7:
                        setPharmacyDayOfTheWeek7Selected(dotws);
                        break;
                    default:
                        throw new Error('no pharmacy day of the week found');
                }
                break;

            default:
                throw new Error('no day of the week category found');
        } 
    }

    function getDayOfTheWeek(category:string, nod:number):string {
        //console.log('category:', category, 'nod:', nod);
        switch(category){
            case 'store':
                switch(nod){
                    case 1:
                        return storeDayOfTheWeekSelected;
                    case 2:
                        return storeDayOfTheWeek2Selected;
                    case 3:
                        return storeDayOfTheWeek3Selected;
                    case 4:
                        return storeDayOfTheWeek4Selected;
                    case 5:
                        return storeDayOfTheWeek5Selected;
                    case 6:
                        return storeDayOfTheWeek6Selected;
                    case 7:
                        return storeDayOfTheWeek7Selected;
                    default:
                        throw new Error('no store day of the week found');
                }
            
            case 'pharmacy':
                switch(nod){
                    case 1:
                        return pharmacyDayOfTheWeekSelected;
                    case 2:
                        return pharmacyDayOfTheWeek2Selected;
                    case 3:
                        return pharmacyDayOfTheWeek3Selected;
                    case 4:
                        return pharmacyDayOfTheWeek4Selected;
                    case 5:
                        return pharmacyDayOfTheWeek5Selected;
                    case 6:
                        return pharmacyDayOfTheWeek6Selected;
                    case 7:
                        return pharmacyDayOfTheWeek7Selected;
                    default:
                        throw new Error('no pharmacy day of the week found');
                }

            default:
                throw new Error('no day of the week category found');
        }
    }

    function setMultiDayOfTheWeek(category:string, dotws:string, nod:number){
        switch(category){
            case 'store':
                switch(nod){
                    case 1:
                        setStoreMultiDayOfTheWeekSelected(dotws);
                        break;
                    case 2:
                        setStoreMultiDayOfTheWeek2Selected(dotws);
                        break;
                    case 3:
                        setStoreMultiDayOfTheWeek3Selected(dotws);
                        break;
                    case 4:
                        setStoreMultiDayOfTheWeek4Selected(dotws);
                        break;
                    case 5:
                        setStoreMultiDayOfTheWeek5Selected(dotws);
                        break;
                    case 6:
                        setStoreMultiDayOfTheWeek6Selected(dotws);
                        break;
                    case 7:
                        setStoreMultiDayOfTheWeek7Selected(dotws);
                        break;
                    default:
                        throw new Error('no store multi day of the week found');
                }
                break;
            
            case 'pharmacy':
                switch(nod){
                    case 1:
                        setPharmacyMultiDayOfTheWeekSelected(dotws);
                        break;
                    case 2:
                        setPharmacyMultiDayOfTheWeek2Selected(dotws);
                        break;
                    case 3:
                        setPharmacyMultiDayOfTheWeek3Selected(dotws);
                        break;
                    case 4:
                        setPharmacyMultiDayOfTheWeek4Selected(dotws);
                        break;
                    case 5:
                        setPharmacyMultiDayOfTheWeek5Selected(dotws);
                        break;
                    case 6:
                        setPharmacyMultiDayOfTheWeek6Selected(dotws);
                        break;
                    case 7:
                        setPharmacyMultiDayOfTheWeek7Selected(dotws);
                        break;
                    default:
                        throw new Error('no pharmacy multi day of the week found');
                }
                break;

            default:
                throw new Error('no day of the week category found');
        } 
    }

    function getMultiDayOfTheWeek(category:string, nod:number):string {
        //console.log('category:', category, 'nod:', nod);
        switch(category){
            case 'store':
                switch(nod){
                    case 1:
                        return storeMultiDayOfTheWeekSelected;
                    case 2:
                        return storeMultiDayOfTheWeek2Selected;
                    case 3:
                        return storeMultiDayOfTheWeek3Selected;
                    case 4:
                        return storeMultiDayOfTheWeek4Selected;
                    case 5:
                        return storeMultiDayOfTheWeek5Selected;
                    case 6:
                        return storeMultiDayOfTheWeek6Selected;
                    case 7:
                        return storeMultiDayOfTheWeek7Selected;
                    default:
                        throw new Error('no store multi day of the week found');
                }
            
            case 'pharmacy':
                switch(nod){
                    case 1:
                        return pharmacyMultiDayOfTheWeekSelected;
                    case 2:
                        return pharmacyMultiDayOfTheWeek2Selected;
                    case 3:
                        return pharmacyMultiDayOfTheWeek3Selected;
                    case 4:
                        return pharmacyMultiDayOfTheWeek4Selected;
                    case 5:
                        return pharmacyMultiDayOfTheWeek5Selected;
                    case 6:
                        return pharmacyMultiDayOfTheWeek6Selected;
                    case 7:
                        return pharmacyMultiDayOfTheWeek7Selected;
                    default:
                        throw new Error('no pharmacy multi day of the week found');
                }

            default:
                throw new Error('no day of the week category found');
        }
    }

    function getTime(category:string, timePeriod:string, nod:number):string {
        switch(category){
            case 'store':
                switch(timePeriod){
                    case 'start':
                        switch(nod){
                            case 1:
                                return storeLocationStartTime;
                            case 2:
                                return storeLocationStartTime2;
                            case 3:
                                return storeLocationStartTime3;
                            case 4:
                                return storeLocationStartTime4;
                            case 5:
                                return storeLocationStartTime5;
                            case 6:
                                return storeLocationStartTime6;
                            case 7:
                                return storeLocationStartTime7;
                            default:
                                throw new Error('no store start number of days found');
                        }

                    case 'end':
                        switch(nod){
                            case 1:
                                return storeLocationEndTime;
                            case 2:
                                return storeLocationEndTime2;
                            case 3:
                                return storeLocationEndTime3;
                            case 4:
                                return storeLocationEndTime4;
                            case 5:
                                return storeLocationEndTime5;
                            case 6:
                                return storeLocationEndTime6;
                            case 7:
                                return storeLocationEndTime7;
                            default:
                                throw new Error('no store end number of days found');
                        }

                    default:
                        throw new Error('no store time period found');
                }
            
            case 'pharmacy':
                switch(timePeriod){
                    case 'start':
                        switch(nod){
                            case 1:
                                return pharmacyStartTime;
                            case 2:
                                return pharmacyStartTime2;
                            case 3:
                                return pharmacyStartTime3;
                            case 4:
                                return pharmacyStartTime4;
                            case 5:
                                return pharmacyStartTime5;
                            case 6:
                                return pharmacyStartTime6;
                            case 7:
                                return pharmacyStartTime7;
                            default:
                                throw new Error('no pharmacy start number of days found');
                        }

                    case 'end':
                        switch(nod){
                            case 1:
                                return pharmacyEndTime;
                            case 2:
                                return pharmacyEndTime2;
                            case 3:
                                return pharmacyEndTime3;
                            case 4:
                                return pharmacyEndTime4;
                            case 5:
                                return pharmacyEndTime5;
                            case 6:
                                return pharmacyEndTime6;
                            case 7:
                                return pharmacyEndTime7;
                            default:
                                throw new Error('no pharmacy end number of days found');
                        }

                    default:
                        throw new Error('no pharmacy time period found');
                }

            default:
                throw new Error('no time category found');
        }
    }

    function setTime(category:string, timePeriod:string, nod:number, time:string, timeString:string):void {
       // console.log('cat:', category);
        switch(category){
            case 'store':
                switch(timePeriod){
                    case 'start':
                        switch(nod){
                            case 1:
                                setStoreLocationStartTime(time);
                                setStoreLocationStartTimeSelected(timeString);
                                break;
                            case 2:
                                setStoreLocationStartTime2(time);
                                setStoreLocationStartTime2Selected(timeString);
                                break;
                            case 3:
                                setStoreLocationStartTime3(time);
                                setStoreLocationStartTime3Selected(timeString);
                                break;
                            case 4:
                                setStoreLocationStartTime4(time);
                                setStoreLocationStartTime4Selected(timeString);
                                break;
                            case 5:
                                setStoreLocationStartTime5(time);
                                setStoreLocationStartTime5Selected(timeString);
                                break;
                            case 6:
                                setStoreLocationStartTime6(time);
                                setStoreLocationStartTime6Selected(timeString);
                                break;
                            case 7:
                                setStoreLocationStartTime7(time);
                                setStoreLocationStartTime7Selected(timeString);
                                break;
                            default:
                                throw new Error('no store start number of days found');
                        }
                        break;

                    case 'end':
                        switch(nod){
                            case 1:
                                setStoreLocationEndTime(time);
                                setStoreLocationEndTimeSelected(timeString);
                                break;
                            case 2:
                                setStoreLocationEndTime2(time);
                                setStoreLocationEndTime2Selected(timeString);
                                break;
                            case 3:
                                setStoreLocationEndTime3(time);
                                setStoreLocationEndTime3Selected(timeString);
                                break;
                            case 4:
                                setStoreLocationEndTime4(time);
                                setStoreLocationEndTime4Selected(timeString);
                                break;
                            case 5:
                                setStoreLocationEndTime5(time);
                                setStoreLocationEndTime5Selected(timeString);
                                break;
                            case 6:
                                setStoreLocationEndTime6(time);
                                setStoreLocationEndTime6Selected(timeString);
                                break;
                            case 7:
                                setStoreLocationEndTime7(time);
                                setStoreLocationEndTime7Selected(timeString);
                                break;
                            default:
                                throw new Error('no store end number of days found');
                        }
                        break;

                    default:
                        throw new Error('no store time period found');

                }
                break;
            
            case 'pharmacy':
                switch(timePeriod){
                    case 'start':
                        switch(nod){
                            case 1:
                                setPharmacyStartTime(time);
                                setPharmacyStartTimeSelected(timeString);
                                break;
                            case 2:
                                setPharmacyStartTime2(time);
                                setPharmacyStartTime2Selected(timeString);
                                break;
                            case 3:
                                setPharmacyStartTime3(time);
                                setPharmacyStartTime3Selected(timeString);
                                break;
                            case 4:
                                setPharmacyStartTime4(time);
                                setPharmacyStartTime4Selected(timeString);
                                break;
                            case 5:
                                setPharmacyStartTime5(time);
                                setPharmacyStartTime5Selected(timeString);
                                break;
                            case 6:
                                setPharmacyStartTime6(time);
                                setPharmacyStartTime6Selected(timeString);
                                break;
                            case 7:
                                setPharmacyStartTime7(time);
                                setPharmacyStartTime7Selected(timeString);
                                break;
                            default:
                                throw new Error('no pharmacy start number of days found');
                        }
                        break;

                    case 'end':
                        switch(nod){
                            case 1:
                                setPharmacyEndTime(time);
                                setPharmacyEndTimeSelected(timeString);
                                break;
                            case 2:
                                setPharmacyEndTime2(time);
                                setPharmacyEndTime2Selected(timeString);
                                break;
                            case 3:
                                setPharmacyEndTime3(time);
                                setPharmacyEndTime3Selected(timeString);
                                break;
                            case 4:
                                setPharmacyEndTime4(time);
                                setPharmacyEndTime4Selected(timeString);
                                break;
                            case 5:
                                setPharmacyEndTime5(time);
                                setPharmacyEndTime5Selected(timeString);
                                break;
                            case 6:
                                setPharmacyEndTime6(time);
                                setPharmacyEndTime6Selected(timeString);
                                break;
                            case 7:
                                setPharmacyEndTime7(time);
                                setPharmacyEndTime7Selected(timeString);
                                break;
                            default:
                                throw new Error('no pharmacy end number of days found');
                        }
                        break;

                    default:
                        throw new Error('no pharmacy time period found');

                }
                break;

            default:
                throw new Error('no category found');
        }
    }


    function storeLocationTimeHandler(e:React.ChangeEvent, timePeriod:string, category:string, nod:number):void {
        //console.log('value:', (e.target as HTMLInputElement).value);
        const time = (e.target as HTMLInputElement).value;
        const hour = window.parseInt(time.slice(0, 2),10);
        const mins = window.parseInt(time.slice(-2),10);
        //console.log('time:', time, 'hour', hour, 'mins:', mins);
        let timeString = "";
        if(hour === 0){
            timeString = `12:${convertMinutes(mins)} AM`;
        } else if(hour === 12){
            timeString = `12:${convertMinutes(mins)} PM`;
        } else if((hour - 12) < 0){
            timeString = `${hour}:${convertMinutes(mins)} AM`;
        } else {
            timeString = `${hour - 12}:${convertMinutes(mins)} PM`;
        }
        //console.log('timeString', timeString);

        if(timePeriod === 'start' && category === 'store'){
            //console.log('value:', `${time}:00`);
            setTime(category, timePeriod, nod, time, timeString);

        } else if(timePeriod === 'end' && category === 'store') {
            //setStoreLocationEndTimeSelected(timeString);
            //setStoreLocationEndTime(time);
            setTime(category, timePeriod, nod, time, timeString);
        } else if(timePeriod === 'start' && category === 'pharmacy') {
            //setPharmacyStartTimeSelected(timeString);
            //setPharmacyStartTime(time);
            setTime(category, timePeriod, nod, time, timeString);
        } else {
            //setPharmacyEndTimeSelected(timeString);
            //setPharmacyEndTime(time);
            setTime(category, timePeriod, nod, time, timeString);
        }


    }

    function convertMinutes(mins:number):string {
        if(mins === 0){
            return '00';
        } else if(mins < 10){
            return `0${mins}`;
        } else {
            return `${mins}`;
        }
    }

    function addLocationHoursHandler(e:React.MouseEvent, nods:number[], category:string):void {
        if(nods.length < 7){
            nods.push(nods.length + 1);
            //console.log('nods:', nods);
            if(category === 'store'){
                setNumberOfStoreDays([...nods]);
                addTime(category, nods);
            } else {
                setNumberOfPharmacyDays([...nods]);
                addTime(category, nods);
            }
            
        }

        //reset element array
        storeDowWrappers.current.length = 0;
        pharmacyDowWrappers.current.length = 0;

    }

    function removeLocationHoursHandler(e:React.MouseEvent, nods:number[], category:string):void {
        if(nods.length > 1){
            nods.pop();
            //console.log('nods:', nods);
            if(category === 'store'){
                setNumberOfStoreDays([...nods]);
                resetTime(category, nods);
                resetDay(category, nods);
            } else {
                setNumberOfPharmacyDays([...nods]);
                resetTime(category, nods);
                resetDay(category, nods);
            }
        }

        //reset element array
        storeDowWrappers.current.length = 0;
        pharmacyDowWrappers.current.length = 0;

    }

    function resetMultiDay(category:string, nod:number):void {
        if(category === 'store'){
            //for(let i=0; i < nods.length; i++){
                switch(nod){
                    case 1:
                        setStoreMultiDayOfTheWeekSelected('Monday');
                        break;
                    case 2:
                        setStoreMultiDayOfTheWeek2Selected('Monday');
                        break;
                    case 3:
                        setStoreMultiDayOfTheWeek3Selected('Monday');
                        break;
                    case 4:
                        setStoreMultiDayOfTheWeek4Selected('Monday');
                        break;
                    case 5:
                        setStoreMultiDayOfTheWeek5Selected('Monday');
                        break;
                    case 6:
                        setStoreMultiDayOfTheWeek6Selected('Monday');
                        break;
                    case 6:
                        setStoreMultiDayOfTheWeek7Selected('Monday');
                        break;                        
                    default:
                        throw new Error('no store reset multi day found.');

                }
            //}

        } else {

            //for(let i=0; i < nods.length; i++){
                switch(nod){
                    case 1:
                        setPharmacyMultiDayOfTheWeekSelected('Monday');
                        break;
                    case 2:
                        setPharmacyMultiDayOfTheWeek2Selected('Monday');
                        break;
                    case 3:
                        setPharmacyMultiDayOfTheWeek3Selected('Monday');
                        break;
                    case 4:
                        setPharmacyMultiDayOfTheWeek4Selected('Monday');
                        break;
                    case 5:
                        setPharmacyMultiDayOfTheWeek5Selected('Monday');
                        break;
                    case 6:
                        setPharmacyMultiDayOfTheWeek6Selected('Monday');
                        break;
                    case 6:
                        setPharmacyMultiDayOfTheWeek7Selected('Monday');
                        break;
                    default:
                        throw new Error('no pharmacy reset multi day found.');

                }            
            //}

        }
    }

    function resetDay(category:string, nods:number[]):void {
        //console.log('nods:', nods);
        if(category === 'store'){
            //for(let i=0; i < nods.length; i++){
                switch(nods.length){
                    case 1:
                        setStoreDayOfTheWeek2Selected('Monday');
                        setStoreMultiDayOfTheWeek2Selected('Monday');
                        setShowStoreMultipleDay2(false);
                        break;
                    case 2:
                        setStoreDayOfTheWeek3Selected('Monday');
                        setStoreMultiDayOfTheWeek3Selected('Monday');
                        setShowStoreMultipleDay3(false);
                        break;
                    case 3:
                        setStoreDayOfTheWeek4Selected('Monday');
                        setStoreMultiDayOfTheWeek4Selected('Monday');
                        setShowStoreMultipleDay4(false);
                        break;
                    case 4:
                        setStoreDayOfTheWeek5Selected('Monday');
                        setStoreMultiDayOfTheWeek5Selected('Monday');
                        setShowStoreMultipleDay5(false);
                        break;
                    case 5:
                        setStoreDayOfTheWeek6Selected('Monday');
                        setStoreMultiDayOfTheWeek6Selected('Monday');
                        setShowStoreMultipleDay6(false);
                        break;
                    case 6:
                        setStoreDayOfTheWeek7Selected('Monday');
                        setStoreMultiDayOfTheWeek7Selected('Monday');
                        setShowStoreMultipleDay7(false);
                        break;
                    default:
                        throw new Error('no store reset day found.');

                }
            //}

        } else {

            //for(let i=0; i < nods.length; i++){
                switch(nods.length){
                    case 1:
                        setPharmacyDayOfTheWeek2Selected('Monday');
                        setPharmacyMultiDayOfTheWeek2Selected('Monday');
                        setShowPharmacyMultipleDay2(false);
                        break;
                    case 2:
                        setPharmacyDayOfTheWeek3Selected('Monday');
                        setPharmacyMultiDayOfTheWeek3Selected('Monday');
                        setShowPharmacyMultipleDay3(false);
                        break;
                    case 3:
                        setPharmacyDayOfTheWeek4Selected('Monday');
                        setPharmacyMultiDayOfTheWeek4Selected('Monday');
                        setShowPharmacyMultipleDay4(false);
                        break;
                    case 4:
                        setPharmacyDayOfTheWeek5Selected('Monday');
                        setPharmacyMultiDayOfTheWeek5Selected('Monday');
                        setShowPharmacyMultipleDay5(false);
                        break;
                    case 5:
                        setPharmacyDayOfTheWeek6Selected('Monday');
                        setPharmacyMultiDayOfTheWeek6Selected('Monday');
                        setShowPharmacyMultipleDay6(false);
                        break;
                    case 6:
                        setPharmacyDayOfTheWeek7Selected('Monday');
                        setPharmacyMultiDayOfTheWeek7Selected('Monday');
                        setShowPharmacyMultipleDay7(false);
                        break;
                    default:
                        throw new Error('no pharmacy reset day found.');

                }            
            //}

        }
    }

    function resetTime(category:string, nods:number[]):void {
        //console.log('nods:', nods);
        if(category === 'store'){
            //for(let i=0; i < nods.length; i++){
                switch(nods.length){
                    case 1:
                        setStoreLocationStartTime2('06:00');
                        setStoreLocationStartTime2Selected('noTime');
                        setStoreLocationEndTime2('23:00');
                        setStoreLocationEndTime2Selected('noTime');
                        break;
                    case 2:
                        setStoreLocationStartTime3('06:00');
                        setStoreLocationStartTime3Selected('noTime');
                        setStoreLocationEndTime3('23:00');
                        setStoreLocationEndTime3Selected('noTime');
                        break;
                    case 3:
                        setStoreLocationStartTime4('06:00');
                        setStoreLocationStartTime4Selected('noTime');
                        setStoreLocationEndTime4('23:00');
                        setStoreLocationEndTime4Selected('noTime');
                        break;
                    case 4:
                        setStoreLocationStartTime5('06:00');
                        setStoreLocationStartTime5Selected('noTime');
                        setStoreLocationEndTime5('23:00');
                        setStoreLocationEndTime5Selected('noTime');
                        break;
                    case 5:
                        setStoreLocationStartTime6('06:00');
                        setStoreLocationStartTime6Selected('noTime');
                        setStoreLocationEndTime6('23:00');
                        setStoreLocationEndTime6Selected('noTime');
                        break;
                    case 6:
                        setStoreLocationStartTime7('06:00');
                        setStoreLocationStartTime7Selected('noTime');
                        setStoreLocationEndTime7('23:00');
                        setStoreLocationEndTime7Selected('noTime');
                        break;
                    default:
                        throw new Error('no store reset time found.');

                }
            //}

        } else {

            //for(let i=0; i < nods.length; i++){
                switch(nods.length){
                    case 1:
                        setPharmacyStartTime2('06:00');
                        setPharmacyStartTime2Selected('noTime');
                        setPharmacyEndTime2('23:00');
                        setPharmacyEndTime2Selected('noTime');
                        break;
                    case 2:
                        setPharmacyStartTime3('06:00');
                        setPharmacyStartTime3Selected('noTime');
                        setPharmacyEndTime3('23:00');
                        setPharmacyEndTime3Selected('noTime');
                        break;
                    case 3:
                        setPharmacyStartTime4('06:00');
                        setPharmacyStartTime4Selected('noTime');
                        setPharmacyEndTime4('23:00');
                        setPharmacyEndTime4Selected('noTime');
                        break;
                    case 4:
                        setPharmacyStartTime5('06:00');
                        setPharmacyStartTime5Selected('noTime');
                        setPharmacyEndTime5('23:00');
                        setPharmacyEndTime5Selected('noTime');
                        break;
                    case 5:
                        setPharmacyStartTime6('06:00');
                        setPharmacyStartTime6Selected('noTime');
                        setPharmacyEndTime6('23:00');
                        setPharmacyEndTime6Selected('noTime');
                        break;
                    case 6:
                        setPharmacyStartTime7('06:00');
                        setPharmacyStartTime7Selected('noTime');
                        setPharmacyEndTime7('23:00');
                        setPharmacyEndTime7Selected('noTime');
                        break;
                    default:
                        throw new Error('no pharmacy reset time found.');

                }            
            //}

        }
    }

    function addTime(category:string, nods:number[]):void {
        //console.log('nods:', nods, category);
        if(category === 'store'){
            //for(let i=1; i < nods.length; i++){
                switch(nods.length){
                    case 2:
                        setStoreLocationStartTime2Selected('6:00 AM');
                        setStoreLocationEndTime2Selected('11:00 PM');
                        break;
                    case 3:
                        setStoreLocationStartTime3Selected('6:00 AM');
                        setStoreLocationEndTime3Selected('11:00 PM');
                        break;
                    case 4:
                        setStoreLocationStartTime4Selected('6:00 AM');
                        setStoreLocationEndTime4Selected('11:00 PM');
                        break;
                    case 5:
                        setStoreLocationStartTime5Selected('6:00 AM');
                        setStoreLocationEndTime5Selected('11:00 PM');
                        break;
                    case 6:
                        setStoreLocationStartTime6Selected('6:00 AM');
                        setStoreLocationEndTime6Selected('11:00 PM');
                        break;
                    case 7:
                        setStoreLocationStartTime7Selected('6:00 AM');
                        setStoreLocationEndTime7Selected('11:00 PM');
                        break;
                    default:
                        new Error('no store reset time found.');
                        break;

                }
            //}

        } else {
            
            //for(let i=1; i < nods.length; i++){
                switch(nods.length){
                    case 2:
                        setPharmacyStartTime2Selected('6:00 AM');
                        setPharmacyEndTime2Selected('11:00 PM');
                        break;
                    case 3:
                        setPharmacyStartTime3Selected('6:00 AM');
                        setPharmacyEndTime3Selected('11:00 PM');
                        break;
                    case 4:
                        setPharmacyStartTime4Selected('6:00 AM');
                        setPharmacyEndTime4Selected('11:00 PM');
                        break;
                    case 5:
                        setPharmacyStartTime5Selected('6:00 AM');
                        setPharmacyEndTime5Selected('11:00 PM');
                        break;
                    case 6:
                        setPharmacyStartTime6Selected('6:00 AM');
                        setPharmacyEndTime6Selected('11:00 PM');
                        break;
                    case 7:
                        setPharmacyStartTime7Selected('6:00 AM');
                        setPharmacyEndTime7Selected('11:00 PM');
                        break;
                    default:
                        throw new Error('no pharmacy reset time found.');

                }            
            //}

        }
    }


    function addDowWrapper(ele:HTMLDivElement|null):void {
        if(ele !== null){
            //console.log('ele className:', ele.className);
            if(storeDowWrappers.current.length === 0 && ele.className === 'store-dow-wrapper'){
                //console.log('element:', ele);
                storeDowWrappers.current.push(ele);
            } else if(pharmacyDowWrappers.current.length === 0 && ele.className === 'pharmacy-dow-wrapper'){
                pharmacyDowWrappers.current.push(ele);
            } else {

                if(ele.className === 'store-dow-wrapper'){
                    const found = storeDowWrappers.current.find(dw=>{
                        return dw === ele;
                    });

                    if(typeof found === 'undefined'){
                        storeDowWrappers.current.push(ele);
                    }

                } else {
                    const found = pharmacyDowWrappers.current.find(dw=>{
                        return dw === ele;
                    });

                    if(typeof found === 'undefined'){
                        pharmacyDowWrappers.current.push(ele);
                    }
                }

            }

            //console.log('dowWrappers store:', storeDowWrappers.current);
            //console.log('dowWrappers pharmacy:', pharmacyDowWrappers.current);
            
        }

    }

    function handleShowMultipleDay(/*e:React.MouseEvent,*/ nod:number, category:string, reset:boolean):void {
        //console.log('smd nod:', nod);
        if(category === 'store'){
            //setShowStoreMultipleDay(!showStoreMultipleDay);
            switch(nod){
                case 1:
                    setShowStoreMultipleDay(!showStoreMultipleDay);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 2:
                    setShowStoreMultipleDay2(!showStoreMultipleDay2);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 3:
                    setShowStoreMultipleDay3(!showStoreMultipleDay3);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 4:
                    setShowStoreMultipleDay4(!showStoreMultipleDay4);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 5:
                    setShowStoreMultipleDay5(!showStoreMultipleDay5);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 6:
                    setShowStoreMultipleDay6(!showStoreMultipleDay6);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 7:
                    setShowStoreMultipleDay7(!showStoreMultipleDay7);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                default:
                    throw new Error('no store multiple day set.');
            }

        } else {
            //setShowPharmacyMultipleDay(!showPharmacyMultipleDay);
            switch(nod){
                case 1:
                    setShowPharmacyMultipleDay(!showPharmacyMultipleDay);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 2:
                    setShowPharmacyMultipleDay2(!showPharmacyMultipleDay2);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 3:
                    setShowPharmacyMultipleDay3(!showPharmacyMultipleDay3);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 4:
                    setShowPharmacyMultipleDay4(!showPharmacyMultipleDay4);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 5:
                    setShowPharmacyMultipleDay5(!showPharmacyMultipleDay5);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 6:
                    setShowPharmacyMultipleDay6(!showPharmacyMultipleDay6);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                case 7:
                    setShowPharmacyMultipleDay7(!showPharmacyMultipleDay7);
                    if(reset){
                        resetMultiDay(category, nod);
                    }
                    break;
                default:
                    throw new Error('no pharmacy multiple day set.');
            }
        }
    }

    function getMultipleDay(category:string, nod:number):boolean {
        if(category === 'store'){
            switch(nod){
                case 1:
                    return showStoreMultipleDay;
                case 2:
                    return showStoreMultipleDay2;
                case 3:
                    return showStoreMultipleDay3;
                case 4:
                    return showStoreMultipleDay4;
                case 5:
                    return showStoreMultipleDay5;
                case 6:
                    return showStoreMultipleDay6;
                case 7:
                    return showStoreMultipleDay7;
                default:
                    throw new Error('no store multiple day found.');
            }

        } else {
            switch(nod){
                case 1:
                    return showPharmacyMultipleDay;
                case 2:
                    return showPharmacyMultipleDay2;
                case 3:
                    return showPharmacyMultipleDay3;
                case 4:
                    return showPharmacyMultipleDay4;
                case 5:
                    return showPharmacyMultipleDay5;
                case 6:
                    return showPharmacyMultipleDay6;
                case 7:
                    return showPharmacyMultipleDay7;
                default:
                    throw new Error('no store multiple day found.');
            }
        }

    }


    function setupCategoryDays(category:string, nod:number, showMultipleDay:boolean):JSX.Element {
        //console.log('nod:', nod);
        if(showMultipleDay){
            return <div ref={addDowWrapper} style={{display:'flex'}} className={`${category}-dow-wrapper`}>
                        <div className={`${category}-dow-container`}>
                            <label ref={shdLabelRef} className="store-location-hours-label" htmlFor={`${category}startDay${nod}`}>Start Day</label>
                            <StoreLocationSelectControl ref={dSelectElementRef}
                                elementName={category}
                                optionNameSelected={getDayOfTheWeek(category, nod)}
                                optionNames={daysOfTheWeek}
                                showErrorMsg={showDeptNameErrorMsg}
                                errorMsg={deptNameErrorMsg}
                                selectControlId={`${category}startDay${nod}`}
                                handleOptionNameSelection={(e:React.ChangeEvent)=>handleDayOfTheWeekSelection(e, category, nod)}
                            />
                        </div>
                        <div className={`${category}-dow-container`}>
                            <label ref={shdLabelRef} className="store-location-hours-label" htmlFor={`${category}endDay${nod}`}>End Day</label>
                            <StoreLocationSelectControl ref={dSelectElementRef}
                                elementName={category}
                                optionNameSelected={getMultiDayOfTheWeek(category, nod)}
                                optionNames={daysOfTheWeek}
                                showErrorMsg={showDeptNameErrorMsg}
                                errorMsg={deptNameErrorMsg}
                                selectControlId={`${category}endDay${nod}`}
                                handleOptionNameSelection={(e:React.ChangeEvent)=>handleMultiDayOfTheWeekSelection(e, category, nod)}
                            />
                        </div>
                        <button className="category-hours-multi-day-btn" type="button"
                            onClick={(e:React.MouseEvent)=>handleShowMultipleDay(/*e,*/ nod, category, true)}>Close Multi</button>
                    </div>

        } else {
            return <div ref={addDowWrapper} style={{display:'flex'}} className={`${category}-dow-wrapper`}>
                        <div className={`${category}-dow-container`}>
                            <label ref={shdLabelRef} className="store-location-hours-label" htmlFor={`${category}day${nod}`}>Day</label>
                            <StoreLocationSelectControl ref={dSelectElementRef}
                                elementName={category}
                                optionNameSelected={getDayOfTheWeek(category, nod)}
                                optionNames={daysOfTheWeek}
                                showErrorMsg={showDeptNameErrorMsg}
                                errorMsg={deptNameErrorMsg}
                                selectControlId={`${category}day${nod}`}
                                handleOptionNameSelection={(e:React.ChangeEvent)=>handleDayOfTheWeekSelection(e, category, nod)}
                            />
                        </div>
                        <button className="category-hours-multi-day-btn" type="button"
                            onClick={(e:React.MouseEvent)=>handleShowMultipleDay(/*e,*/ nod, category, true)}>Add Multi</button>
                    </div>
        }
    }


    function setupCategoryHours(nods:number[], category:string):JSX.Element {
        //console.log('nods:', nods);
        return <div className="store-location-category-hours-wrapper">
                    {
                        category === 'store'
                        ? <div>
                                <div ref={shsLabelRef} className="store-location-category-hours-label">{category} Hours:</div>
                                {
                                    showStoreHoursMissingError
                                    ?
                                    <p className="store-location-hours-missing-error">Requires a start time and end time</p>
                                    :
                                    null
                                }
                                {
                                    showStoreHoursOverlapError
                                    ?
                                    <p className="store-location-hours-overlap-error">Start time and end time overlap</p>
                                    :
                                    null
                                }
                            </div>
                        : <div>
                                <div ref={phsLabelRef} className="store-location-category-hours-label">{category} Hours:</div>
                                {
                                    showPharmacyHoursMissingError
                                    ?
                                    <p className="store-location-hours-missing-error">Requires a start time and end time</p>
                                    :
                                    null
                                }
                                {
                                    showPharmacyHoursOverlapError
                                    ?
                                    <p className="store-location-hours-overlap-error">Start time and end time overlap</p>
                                    :
                                    null
                                }
                            </div>
                    }
                    
                    {nods.map((nod)=>{
                        return  <div key={nod} className="store-location-hours-container">
                                    <div className="add-dpt-category-input-form-group store-hours-form-group">
                                        {/*<label ref={shdLabelRef} className="store-location-hours-label" htmlFor="dpt-category-name">Day</label>*/}
                                        {
                                            category === 'store'
                                            ? setupCategoryDays(category, nod, getMultipleDay(category, nod))
                                            /*<StoreLocationSelectControl ref={dSelectElementRef}
                                                elementName={category}
                                                optionNameSelected={getDayOfTheWeek(category, nod)}
                                                optionNames={daysOfTheWeek}
                                                showErrorMsg={showDeptNameErrorMsg}
                                                errorMsg={deptNameErrorMsg}
                                                handleOptionNameSelection={(e:React.ChangeEvent)=>handleDayOfTheWeekSelection(e,'store', nod)}
                                            />*/
                                            : setupCategoryDays(category, nod, getMultipleDay(category, nod))
                                            /*<StoreLocationSelectControl ref={dSelectElementRef}
                                                elementName={category}
                                                optionNameSelected={getDayOfTheWeek(category, nod)}
                                                optionNames={daysOfTheWeek}
                                                showErrorMsg={showDeptNameErrorMsg}
                                                errorMsg={deptNameErrorMsg}
                                                handleOptionNameSelection={(e:React.ChangeEvent)=>handleDayOfTheWeekSelection(e,'pharmacy', nod)}
                                            />*/
                                        }
                                    </div>
                                    <div className="store-hours-form-group">
                                        <label htmlFor={"startTime"} className="store-location-hours-label">Start Time:</label>
                                        <input type="time" name={`${category}StartTime`} id="startTime" list="storeLocationStartTime" 
                                        value={getTime(category, 'start', nod)} onChange={(e:React.ChangeEvent)=>storeLocationTimeHandler(e,'start', category, nod)}></input>
                                        <datalist id="storeLocationStartTime"></datalist>
                                    </div>
                                    <div className="store-hours-form-group">
                                        <label htmlFor={"endTime"} className="store-location-hours-label">End Time:</label>
                                        <input type="time" name={`${category}EndTime`} id="endTime" list="storeLocationEndTime"
                                            value={getTime(category, 'end', nod)} onChange={(e:React.ChangeEvent)=>storeLocationTimeHandler(e,'end', category, nod)}></input>
                                        <datalist id="storeLocationEndTime"></datalist>
                                    </div>
                                </div>
                    })}
            
                    <div className="store-location-hours-btn-container">
                        {
                            nods.length < 7
                            ? <button type="button" 
                                onClick={(e:React.MouseEvent)=>addLocationHoursHandler(e,nods, category)}
                                className="store-location-hours-btn">Add Day
                            </button>
                            : null    
                        }
                        {
                            nods.length > 1
                            ? <button type="button" 
                                onClick={(e:React.MouseEvent)=>removeLocationHoursHandler(e,nods, category)}
                                className="store-location-hours-btn store-location-hours-remove-btn">Remove Day
                              </button>
                            : null
                        }

                    </div>
                </div>

    }

    function setupCategoryServices(nolss:number[], category:string):JSX.Element {
        return <div className="store-location-category-services-container">
                    <label className="store-location-category-hours-label">{category} Services:</label>
                    {nolss.map((nols)=>{
                        return  <input key={nols} name="product-sub-category-name"
                                    className="store-location-category-service-input" 
                                    type="text" value={storeLocationNameSelected} 
                                    onChange={handleLocationInfo}/>
                    })}
            
                    <div className="store-location-hours-btn-container">
                        <button type="button" 
                            onClick={(e:React.MouseEvent)=>addLocationServicesHandler(e,nolss)}
                            className="store-location-hours-btn">Add Service
                        </button>  
                        {
                            nolss.length > 1
                            ? <button type="button" 
                                onClick={(e:React.MouseEvent)=>removeLocationServicesHandler(e,nolss)}
                                className="store-location-hours-btn store-location-hours-remove-btn">Remove Service
                              </button>
                            : null
                        }

                    </div>
                </div>

    }

    function addLocationServicesHandler(e:React.MouseEvent, nolss:number[]):void {
        nolss.push(nolss.length + 1);
        setNumberOfLocationServices([...nolss]);

    }

    function removeLocationServicesHandler(e:React.MouseEvent, nolss:number[]):void {
        if(nolss.length > 1){
            nolss.pop();
            setNumberOfLocationServices([...nolss]);
        }

    }


    function updateStoreLocationPhoneNumber(phoneNumber:string):void {
        setStorePhoneNumberSelected(phoneNumber);
    }

    function updatePharmacyPhoneNumber(phoneNumber:string):void {
        setPharmacyPhoneNumberSelected(phoneNumber);
    }


    if(isLoading){
        return  <div className="asls-loader2-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>;
    } else
    
    if(!showEditStoreLocation || (showEditStoreLocation && editStoreLocationLoaded)){
        return <div>
                    {
                        !showEditStoreLocation
                        ?
                        <h1 className="add-dpt-category-heading">Add Store Location</h1>
                        :
                        <h1 className="add-dpt-category-heading">Edit Store Location</h1>
                    }
                    
                    <form ref={formRef} className="add-dpt-category-form" onSubmit={(e:React.FormEvent)=>handleFormSubmit(e, setupFormAction(showEditStoreLocation))}>
                        <fieldset className="store-location-form-group">
                            <legend>Store Info</legend>
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="store-name">Store Name</label>
                                <input ref={snLabelRef} name="store-name" id="store-name" type="text"
                                    autoComplete="off" autoCorrect="off"
                                    className="form-input-control" value={storeLocationNameSelected} onChange={handleLocationInfo}/>
                                {
                                    showStoreLocationNameLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="street-address">Street Address</label>
                                <input ref={saLabelRef} name="street-address" id="street-address" type="text"
                                    autoComplete="off" autoCorrect="off"
                                   className="form-input-control" value={streetAddressSelected} onChange={handleLocationInfo}/>
                                {
                                    showStoreLocationAddressLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="city">City</label>
                                <input ref={cityLabelRef} name="city" id="city" type="text" autoComplete="off" autoCorrect="off"
                                    className="form-input-control" value={citySelected} onChange={handleLocationInfo}/>
                                {
                                    showStoreLocationCityLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="state">State</label>
                                {/*<input ref={stateLabelRef} name="state" id="state" type="text" 
                                    className="form-input-control" value={stateSelected} onChange={handleLocationInfo}/>*/}
                                <StoreDepartmentSelectControl ref={sSelectElementRef}
                                    defaultValue='none'
                                    optionNameSelected={stateSelected}
                                    optionNames={stateNames}
                                    showErrorMsg={showStateNameErrorMsg}
                                    errorMsg={stateNameErrorMsg}
                                    selectId={"state"}
                                    handleOptionNameSelection={handleStateNameSelection}
                                />
                            </div>
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="zip-code">Zip Code</label>
                                <input ref={zcLabelRef} name="zip-code" id="zip-code" type="text" autoComplete="off" autoCorrect="off"
                                  className="form-input-control" value={zipCodeSelected} onChange={handleLocationInfo}/>
                                {
                                    showStoreLocationZipCodeLimitError
                                    ?
                                    <p className="exceed-character-limit-error">Requires 5 digits or 9 digits without spaces or dash</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="store-phone-number">Store Phone Number</label>
                                {/*<input ref={spnLabelRef} name="store-phone-number" id="store-phone-number" type="text" 
                                    className="form-input-control" value={storePhoneNumberSelected} onChange={handleLocationInfo}/>*/}
                                <PhoneNumber ref={spnLabelRef} phoneNumber={storePhoneNumberSelected} phoneNumberId={"store-phone-number"}
                                    updateStoreLocationPhoneNumber={updateStoreLocationPhoneNumber}/>
                                {
                                    showStoreLocationPhoneNumberLimitError
                                    ?
                                    <p className="exceed-character-limit-error">Requires 10 digits without spaces, parentheses or dashes</p>
                                    :
                                    null
                                }
                            </div>
                            
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="store-number">Store Number</label>
                                <input ref={storeNumberLabelRef} name="store-number" id="store-number" type="text" autoComplete="off" autoCorrect="off"
                                    className="form-input-control" value={storeNumberSelected} onChange={handleLocationInfo}/>
                                {
                                    showStoreNumberLimitError
                                    ?
                                    <p className="exceed-character-limit-error">Requires a number 1 to 99999</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="add-product-sub-category-input-form-group">
                                <div ref={lcLabelRef}>Location Coordinates</div>
                                <div className="add-product-sub-category-input-form-group">
                                    <label htmlFor="location-latitude">Latitude</label>
                                    <input ref={llatLabelRef} name="location-latitude" id="location-latitude" type="text" 
                                        autoComplete="off" autoCorrect="off"
                                        placeholder="-90 to 90 (DD)"
                                        className="form-input-control" value={locationLatitudeSelected} onChange={handleLocationInfo}/>
                                    {
                                        showLatitudeCoordinatesLimitError
                                        ?
                                        <p className="exceed-character-limit-error">Requires a number -90 to 90 followed by a decimal point and 5 to 7 numbers</p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="add-product-sub-category-input-form-group">
                                    <label htmlFor="location-longitude">Longitude</label>
                                    <input ref={llongLabelRef} name="location-longitude" id="location-longitude" type="text" 
                                        autoComplete="off" autoCorrect="off"
                                        placeholder="-180 to 180 (DD)"
                                        className="form-input-control" value={locationLongitudeSelected} onChange={handleLocationInfo}/>
                                    {
                                        showLongitudeCoordinatesLimitError
                                        ?
                                        <p className="exceed-character-limit-error">Requires a number -180 to 180 followed by a decimal point and 5 to 7 numbers</p>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {setupCategoryHours(numberOfStoreDays, 'store')}
                        </fieldset>
                        <fieldset className="store-location-form-group">
                            <legend>Pharmacy Info</legend>
                            <div className="add-product-sub-category-input-form-group">
                                <label htmlFor="pharmacy-phone-number">Phrmacy Phone Number</label>
                                {/*<input ref={ppnLabelRef} name="pharmacy-phone-number" id="pharmacy-phone-number" type="text" 
                                   className="form-input-control" value={pharmacyPhoneNumberSelected} onChange={handleLocationInfo}/>*/}
                                <PhoneNumber ref={ppnLabelRef} phoneNumber={pharmacyPhoneNumberSelected} phoneNumberId={"pharmacy-phone-number"}
                                    updateStoreLocationPhoneNumber={updatePharmacyPhoneNumber}/>
                                {
                                    showPharmacyPhoneNumberLimitError
                                    ?
                                    <p className="exceed-character-limit-error">Requires 10 digits without spaces, parentheses or dashes</p>
                                    :
                                    null
                                }
                            </div>                            
                            {setupCategoryHours(numberOfPharmacyDays, 'pharmacy')}
                        </fieldset>

                        <div className="add-product-sub-category-input-form-group">
                                <div className="form-label-spacing-container">
                                    <label ref={sliLabelRef} htmlFor="product-image-search" onClick={imageClickHandler}><span className="product-image-search-label-link">Select Images <span className="no-color-label-link">(store location)</span></span></label>
                                </div>
                                {
                                    showFileLimitError
                                    ?
                                    <p className="exceed-character-limit-error">The maximum file size allowed (1.5MB)</p>
                                    :
                                    null
                                }
                                <input ref={imageStoreLocationRef} id="product-image-search" className="product-image-search" 
                                        name="product-image-search" type="file" onChange={handleFileUpload}/>
                                {setupStoreLocationThumbnailImages(storeLocationThumbnailImages)}
                            </div>
                        {/*<div className="add-product-sub-category-checkbox-form-group">
                            <input ref={curbsidePickupRef} type="checkbox" checked={showCurbsidePickup} name="curbside-pickup" 
                                id="curbside-pickup" onChange={handleCurbsidePickup}/>
                            <label ref={csLabelRef} htmlFor="curbside-pickup">Curbside Pickup</label>
                        </div>*/}
                        {
                            (showEditStoreLocation)
                            ?   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-save-btn" type="submit" value="Save"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('editStoreLocation')} value="Cancel"/>

                                    {
                                        showProcessingRequestMsg
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            :   <div className="product-form-submit-btn-container">
                                    <input className="product-form-submit-add-btn" type="submit" value="Add Location"/>
                                    <input className="product-form-submit-cancel-btn" type="button" onClick={(e:React.MouseEvent)=>handleCloseForm('addStoreLocation', true)} value="Cancel"/>

                                    {
                                        showProcessingRequestMsg
                                        ?
                                        <div className="process-request-msg">
                                            Processing...
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                   
                        }
                        <DBErrorMessage ref={dbErrorMsgRef} dbError={dbError}/>
                        <FormErrorMessage ref={sfgErrorMsgRef} formError={formError} />


                    </form>
                </div>
    
    } else {
        return  <div className="asls-loader-animation-container">
                    {<LoaderAnimation/>}
                </div>;
    }
}


export default AddStoreLocation;
/*
export default connect(
    null,
    mapDispatchToProps
)(AddStoreLocation);
*/