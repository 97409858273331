import React, {useState, useEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from 'react-router-dom';
import StoreDepartment from './storeDepartment';
import AddStoreDepartment from './AddStoreDepartment';
import EditStoreDepartment from './EditStoreDepartment';
import ShopCategory from './shopCategory';
import SubShopCategory from './subShopCategory';
//import {connect} from 'react-redux';
//import {AddProductShopCategories, addProductShopCategories} from './redux/actions';
//import {selectProductShopCategories, addProductShopCategories} from './redux/features/productShopCategories/productShopCategoriesSlice';
//import {useAppSelector} from './redux/hooks';
import StoreLocation from './storeLocation';
import AddStoreLocation from './AddStoreLocation';
import EditStoreLocation from './EditStoreLocation';
import GenericInfoMessage from './GenericInfoMessage';
import ListViewer from './ListViewer';
import {getAnyItemList} from './helper-get-item-list';
import LoaderAnimation from './LoaderAnimation';
import transformDate from './helper-transform-date';
import FormErrorMessage, {FormError} from './FormErrorMessage';
import StoreLocationSelectControl from './StoreLocationSelectControl';
import loadNavLink from './loadNavLinkHelper';
import GenericErrorMessage from './GenericErrorMessage';
import Footer from './Footer';
import './AdminStoreLocations.css';


interface StoreLocationSource {
    source:string;
    storeLocation:StoreLocation;
}
interface Props {
    //path:string;
}

const resultsPerPage = ['5', '10', '20', '30'];

const StoreStructureStoreLocations2: React.FunctionComponent<Props> = (props:Props) => {
    //const {addProductShopCategories} = props;
    const navigate = useNavigate();
    const [showAddStoreLocation, setShowAddStoreLocation] = useState(false);
    const [showEditStoreLocation, setShowEditStoreLocation] = useState(false);
    const [storeLocations, setStoreLocations] = useState<StoreLocation[]>([]);
    const [storeLocationToEdit, setStoreLocationToEdit] = useState<StoreLocation|null>(null);
    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);
    const [storeLocationSearchText, setStoreLocationSearchText] = useState('');
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No store locations have been added.');
    //const [showLoadingSearchResults, setShowLoadingSearchResults] = useState(false);

    //list sorter properties
    const [itemsPerList, setItemsPerList] = useState(10);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<StoreLocation[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);

    const [elementName3, setElementName3] = useState('results-per-page');
    const [resultsPerPageOptionSelected, setResultsPerPageOptionSelected] = useState('10');
    const [resultsPerPageOptions, setResultsPerPageOptions] = useState<string[]>(resultsPerPage);

    const [isLoading, setIsLoading] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const adminStoreLocationContainerRefs = useRef<HTMLDivElement[]>([]);
    const containerTopRef = useRef<number>(0);

    const slKeywordRef = useRef<HTMLInputElement>(null);
    const slKeywordRefs = useRef<HTMLInputElement[]>([]);

    const [formError, setFormError] = useState<FormError>({
                                                            productInfoErrors:[],
                                                            pricingErrors:[],
                                                            imageErrors:[],
                                                            searchFilterGroupError:{hasError:false, area:'none'},
                                                            existingSearchFilterGroupError:{hasError:false, area:'none'}
                                                        });

    //const sfgErrorMsgRef = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}>(null);

    const sfgErrorMsgRefs = useRef<{sfgemcRef:React.RefObject<HTMLDivElement>}[]>([]);


    const existingSearchResultsHeight = useRef<number>(0);

    const [showKeywordSearchLimitError, setShowKeywordSearchLimitError] = useState(false);

    const portrait = useRef<MediaQueryList[]>([]);

    const [temporaryClearSearchResults, setTemporaryClearSearchResults] = useState(false);

    //const formRef = useRef<HTMLFormElement>(null);

    const formRefs = useRef<HTMLFormElement[]>([]);

    const [showFooter, setShowFooter] = useState(false);

    const loaderRefs = useRef<HTMLDivElement[]>([]);

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);
    
    const isLoadingTimeoutRef = useRef<number>(0);


    function getAdminStoreLocationContainerRef(el:HTMLDivElement|null):void {
        if(el !== null && adminStoreLocationContainerRefs.current.length === 0){
            adminStoreLocationContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = adminStoreLocationContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                adminStoreLocationContainerRefs.current.push(el);
            }

        }

        //console.log('aprcrs:', adminStoreLocationContainerRefs.current);
    }



    useEffect(()=>{
        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */

        setIsLoading(true);
        
        getAllStoreLocations();
    },[]);

    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                                io.observe(ir);
                            });

            return ()=>io.disconnect();

        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                const alt = target.dataset.alt;
                //console.log('src:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                //target.src = `http://server.test_app3/${src}`;
                target.src = `${src}`;
                target.alt = alt!;
                observer.unobserve(target);
            }
        });
    }

    function getSLKeywordRef(el:HTMLInputElement|null):void {
        if(el !== null){
            slKeywordRefs.current.length = 0;
        }

        if(el !== null && slKeywordRefs.current.length === 0){
            slKeywordRefs.current.push(el);

        } else if(el !== null) {
            const found = slKeywordRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                slKeywordRefs.current.push(el);
            }

        }

        //console.log('prkrs:', slKeywordRefs.current);

        if(window.innerWidth >= 420){
            slKeywordRefs.current[0]!.placeholder = 'Enter zip code, city, store number or store name';

        } else {
            slKeywordRefs.current[0]!.placeholder = '';
        }

    }


    useEffect(()=>{
        window.addEventListener('resize', adminStoreLocationResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", adminStoreLocationResizeHandler);

        return cleanupAdminStoreLocationResizeHandler;
    },[]);

    function adminStoreLocationResizeHandler(e:Event):void {
        if(window.innerWidth >= 420){
            slKeywordRefs.current[0]!.placeholder = 'Enter zip code, city, store number or store name';

        } else {
            slKeywordRefs.current[0]!.placeholder = '';
        }

        if(typeof sfgErrorMsgRefs.current![0] !== 'undefined'){
            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
        }
    }

    function cleanupAdminStoreLocationResizeHandler():void {
        window.removeEventListener('resize', adminStoreLocationResizeHandler);

        portrait.current[0].removeEventListener("change", adminStoreLocationResizeHandler);
    }


    function getFormRef(el:HTMLFormElement|null):void {
        if(formRefs.current.length > 0){
            formRefs.current.length = 0;
        }

        if(el !== null && formRefs.current.length === 0){
            formRefs.current.push(el);

        } else if(el !== null) {
            const found = formRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                formRefs.current.push(el);
            }

        }
    }

    type Ref = {
        sfgemcRef:React.RefObject<HTMLDivElement>
    };

    function getSfgErrorMsgRef(el: Ref|null):void {
        if(sfgErrorMsgRefs.current.length > 0){
            sfgErrorMsgRefs.current.length = 0;
        }

        if(el !== null && sfgErrorMsgRefs.current.length === 0){
            sfgErrorMsgRefs.current.push(el);

        } else if(el !== null) {
            const found = sfgErrorMsgRefs.current.find((ir)=>{
                                return ir.sfgemcRef === el.sfgemcRef;
                            });

            if(typeof found === 'undefined'){
                sfgErrorMsgRefs.current.push(el);
            }

        }

        //console.log('sfgErrorMsgRefs:', sfgErrorMsgRefs.current);
    }

    function getLoaderRef(el: HTMLDivElement|null):void {
        if(loaderRefs.current.length > 0){
            loaderRefs.current.length = 0;
        }

        if(el !== null && loaderRefs.current.length === 0){
            loaderRefs.current.push(el);

        } else if(el !== null) {
            const found = loaderRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                loaderRefs.current.push(el);
            }

        }

        //console.log('loaderRefs:', loaderRefs);

        if(typeof loaderRefs.current[0] !== 'undefined'){
            const yValue = formRefs.current[0].getBoundingClientRect().bottom + 66;
            loaderRefs.current[0].style.transform = `translate(-50%, ${yValue}px)`;
        }


    }


    function getAllStoreLocations(lcbPosition?:number):void {
        fetch(`https://server.kando-proto-3.com/get-all-store-locations-mariadb`, {
            method:'GET',
            headers:{
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then((storeLocations)=>{
            //console.log('storeLocations 7-12:', storeLocations);

            if(storeLocations.length === 0){
                setNoSearchResultsMessage(`No store locations have been added`);
                setShowNoSearchResultsFound(true);
                setShowInfoMessage(true);
                setIsLoading(false);

                setShowFooter(true);

            } else {
                //use this promise when using base64 img
                Promise.all(addStoreLocationImages(storeLocations))
                .then((res)=>{
                    //console.log('res 11-27:', res);
                    /*
                    const result = res.map((r)=>{
                                    return r.storeLocation
                                    });
                    console.log('result:', result);
                    */

                    /*original version
                    const pscs = addProductSubCategories(productSubCategories);
                    setProductSubCategories(pscs);
                    */

                    const sls = addStoreLocations(res);
                    setStoreLocations(sls);
                    getAnyItemList(typeof lcbPosition !== 'undefined' ? lcbPosition : listChangerBtnPosition, sls, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, sls);
                    setIsLoading(false);

                    setShowFooter(true);

                    //adjust item count based on items per list setting
                    //previous version
                    let nrh = 0;
                    if(itemsPerList < sls.length){
                        nrh = (itemsPerList * 94) + 588 + 16 + 57;

                    } else {
                        nrh = (sls.length) * 94 + 588 + 16 + 57;
                    }

                    if(nrh > 950){
                        adminStoreLocationContainerRefs.current[0].style.minHeight = `${nrh}px`;
                    }
                    
                    
            
                })
                .catch((err)=>{
                    console.error('add store location images error:', err.message);
                    setIsLoading(false);
                    setShowErrorMessage(true);

                    setShowFooter(true);
                });   
            }



        })
        .catch((err)=>{
            console.error('no store location found fetch error:', err.message);
            setIsLoading(false);
            setShowErrorMessage(true);

            setShowFooter(true);
        });
    }

    function addStoreLocationImages(slss:StoreLocationSource[]):Promise<StoreLocationSource>[] {
        return slss.map((sls)=>{
            //console.log('sls:', sls);
            return new Promise((resolve, reject)=>{
                sls.storeLocation.storeLocationImage.length === 0 
                ? resolve(sls)
                : addStoreLocationImage(sls, resolve);

            });
        });

    }

    function addStoreLocations(slss:StoreLocationSource[]):StoreLocation[] {
        return slss.map((sls)=>{
                    return new StoreLocation({...sls.storeLocation});
                });
    }

    function resetStoreLocations():void {
        //reset form errors
        slKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        setShowKeywordSearchLimitError(false);

        setStoreLocations([]);
        setStoreLocationSearchText('');
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setIsLoading(true);

        setItemsList(null);
        setListChangerBtnPosition(0);

        getAllStoreLocations(0);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        adminStoreLocationContainerRefs.current[0].style.minHeight = `950px`;
        
    }


    function handleAddStoreLocation(e:React.MouseEvent):void {
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setStoreLocationSearchText('');
        setShowAddStoreLocation(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        adminStoreLocationContainerRefs.current[0].style.minHeight = `950px`;

    }

    function handleEditStoreLocation(sl:StoreLocation):void {
        setStoreLocationToEdit(sl);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        setStoreLocationSearchText('');
        setShowEditStoreLocation(true);

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        adminStoreLocationContainerRefs.current[0].style.minHeight = `950px`;

    }

    function addStoreLocationImage(sls:StoreLocationSource, resolve:(value: StoreLocationSource | PromiseLike<StoreLocationSource>) => void):void {
        //add image file if it exists
        //console.log('lastindexof /:', sls.storeLocation.storeLocationImage.lastIndexOf('/'));
        //console.log('lastindexof .:', sls.storeLocation.storeLocationImage.lastIndexOf('.'));
        const forwardSlashPos = sls.storeLocation.storeLocationImage.lastIndexOf('/');
        const periodPosition =  sls.storeLocation.storeLocationImage.lastIndexOf('.');
        //console.log('file path:', sls.storeLocation.storeLocationImage);
        //console.log('filename:', sls.storeLocation.storeLocationImage.substring(forwardSlashPos + 1, periodPosition));
        const filename = sls.storeLocation.storeLocationImage.substring(forwardSlashPos + 1, periodPosition);
        //console.log('mimeType:', sls.storeLocation.storeLocationImage.substring(periodPosition + 1));
        const mime = sls.storeLocation.storeLocationImage.substring(forwardSlashPos + 1, periodPosition);
        const binary = window.atob(sls.source);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        for(let i=0; i < binary.length; i++){
            view[i] = binary.charCodeAt(i);
        }

        const file = new File([view], filename, {type:mime});

        const reader = new FileReader();

        function loadHandler(){
            sls.storeLocation.storeLocationImage = reader.result as string;
            resolve(sls);
            reader.removeEventListener('load', loadHandler);
        }

        reader.addEventListener('load', loadHandler);

        if(file){
            reader.readAsDataURL(file);
        }
    }

    function handleRemoveStoreLocation(storeLocation:StoreLocation):void {

        //reset form errors
        slKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //setIsLoading(true);
        setShowProcessingRequestMsg(true);
        setShowKeywordSearchLimitError(false);
    

        fetch(`https://server.kando-proto-3.com/delete-individual-store-location`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(storeLocation)
        })
        .then((res)=>{

            setIsLoadingDelete(true);

            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            return res.json();
        })
        .then(({storeLocationDeleted})=>{
            //console.log('store location deleted:', storeLocationDeleted);

            if(storeLocationDeleted){
                const usls = storeLocations.filter((sl)=>{
                                return sl.storeLocationId !== storeLocation.storeLocationId;
                            });
                //console.log('usls:', usls);
                if(usls.length === 0){
                    setStoreLocationSearchText('');
                    setNoSearchResultsMessage(`All store locations have been deleted from this search results page.  
                                            Try another search results page, keyword search, reset the store location list or add more store locations.`);
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    setStoreLocations(usls);

                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                    
                } else {

                    //console.log('r15:', (usls.length / itemsPerList), listChangerBtnPosition);

                    if((usls.length / itemsPerList) === listChangerBtnPosition){
                        const lcbp = listChangerBtnPosition - 1;
                        //console.log('lcbp:', lcbp);
                        setListChangerBtnPosition(lcbp);
                        getAnyItemList(lcbp, usls, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, usls);

                    } else {
                        getAnyItemList(listChangerBtnPosition, usls, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, usls);
                    }

                    setStoreLocationSearchText('');
                    setStoreLocations(usls);

                    //setIsLoading(false);
                    setShowProcessingRequestMsg(false);
                    setIsLoadingDelete(false);
                    //getAnyItemList(listChangerBtnPosition, usls, itemsPerList, showItemsPerList);
                    //displayListSorter(itemsPerList, usls);
                }
                

                
            }

                 



        })
        .catch((err)=>{
            console.error('delete store location from db fetch err:', err.message);
            setShowErrorMessage(true);
        });
    }

    function addShopCategory(sc:ShopCategory):ShopCategory {
        return new ShopCategory({shopCategoryId:sc.shopCategoryId,
                                shopCategoryLink:sc.shopCategoryLink,
                                shopCategoryName:sc.shopCategoryName,
                                shopCategoryNameId:sc.shopCategoryNameId,
                                subShopCategories:addSubShopCategories(sc.subShopCategories)
                                });
    }

    function addSubShopCategories(sscs:SubShopCategory[]):SubShopCategory[] {
        return sscs.map((ssc)=>{
                    return new SubShopCategory({...ssc});
                });

    }

    function getImageRef(el:HTMLImageElement|null):void {
        //console.log('el:', el, imageRefs.current.length);
        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }


    
    function getUpdatedStoreLocation(storeLocation:StoreLocation):void {
        fetch(`https://server.kando-proto-3.com/get-updated-store-location/${storeLocation.storeLocationId}`, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        })
        .then((res)=>res.json())
        .then(({storeLocationSource})=>{
            //console.log('psc:', storeLocationSource, storeLocations);
            if(storeLocationSource === null){
                setShowErrorMessage(true);
                setIsLoading(false);

            } else {
                Promise.resolve(                
                                new Promise((resolve, reject)=>{
                                    //console.log('test15:');
                                    addStoreLocationImage(storeLocationSource, resolve);
                }))
                .then((locationSource)=>{

                    //console.log('pcs15:', locationSource);

                    const locs = storeLocations.map((loc)=>{
                                    if(loc.storeLocationId === (locationSource as StoreLocationSource).storeLocation.storeLocationId){
                                        return (locationSource as StoreLocationSource).storeLocation;

                                    } else {
                                        return loc;
                                    }
                                });

                    

                    setStoreLocations(locs);
                    getAnyItemList(listChangerBtnPosition, locs, itemsPerList, showItemsPerList);
                    displayListSorter(itemsPerList, locs);
                    setIsLoading(false);

                })
                .catch((err)=>console.log('error get updated store location image:', err.message));


            }
        })
        .catch((err)=>console.log('get updated store location error:', err.message));
    }


    function sortItemsByDateSubmitted(sortDirection:string):void {
        if(sortDirection === 'asc'){
            //console.log('sort29');
            const sls = storeLocations.sort(compareAscendingDates);
            setStoreLocations(sls);
            getAnyItemList(listChangerBtnPosition, sls, itemsPerList, showItemsPerList);

        } else {
            //console.log('sort29');
            const sls = storeLocations.sort(compareDescendingDates);
            setStoreLocations(sls);
            getAnyItemList(listChangerBtnPosition, sls, itemsPerList, showItemsPerList);
        }

    }

    function compareDescendingDates(a:StoreLocation, b:StoreLocation):number {
        const aDate = new Date(a.storeLocationDateAdded).getTime();
        const bDate = new Date(b.storeLocationDateAdded).getTime();

        return bDate - aDate;
    }

    function compareAscendingDates(a:StoreLocation, b:StoreLocation):number {
        const aDate = new Date(a.storeLocationDateAdded).getTime();
        const bDate = new Date(b.storeLocationDateAdded).getTime();

        return aDate - bDate;
    }


    function setupStoreLocationsTable(itemsList:object[]):JSX.Element {
        //console.log('itemsList:', itemsList);
        return  <div className="asls-table-container">
                    <table className="asls-table">
                        <thead>
                            <tr>
                                <th className="th-sl-sn">Store Number</th><th className="th-sl-sl">Store Location</th><th className="th-sl-sli">Store Location Image</th><th className="th-sl-date"><div className="admin-date-added-header-container"><span>Date Added</span><span className="admin-date-added-svg-btn-container">
                                                                                                                                                                                                                            <button type="button" aria-label="ascending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('asc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-up" d="M200 100 L100 0 L0 100 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                            <button type="button" aria-label="descending date order" className="admin-date-added-svg-btn"
                                                                                                                                                                                                                                onClick={(e:React.MouseEvent)=>sortItemsByDateSubmitted('desc')}>
                                                                                                                                                                                                                                <svg className="admin-date-added-svg" width="20" height="10" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                                                                                                    <path className="sort-down" d="M200 0 L100 100 L0 0 Z" fill={"#707070"}></path>
                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                            </button>
                                                                                                                                                                                                                        </span></div></th><th className="th-sl-action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(itemsList as StoreLocation[]).map((sl)=>{
                                //console.log('sl:', sl);
                        //console.log(`pn:${p.productName}, dn:${p.storeDepartmentName}, dcn:${p.departmentCategoryName}, pcn:${p.productCategoryName}, psc:${p.productSubCategoryName}, pda:${p.productDateAdded}`)
                                                return <tr key={sl.storeLocationId}>
                                                            <td><div className="asls-td asls-sn">{sl.storeNumber}</div></td>
                                                            <td><div className="asls-td">{sl.storeName}</div></td>
                                                            <td className="td-img"><div className="asls-image-container"><img ref={getImageRef} data-alt={sl.storeName} alt={""} data-src={sl.storeLocationImage} /*height="85" width="85"*/ /></div></td>
                                                            <td><div className="asls-td">{transformDate(new Date(sl.storeLocationDateAdded).getTime())}</div></td>
                                                            <td className="td-action">
                                                                <div className="table-item-btn-container">
                                                                    <button className="edit-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleEditStoreLocation(sl)
                                                                        }}>
                                                                        Edit
                                                                    </button>
                                                                    <button className="remove-table-item-btn"
                                                                        onClick={(e:React.MouseEvent)=>{
                                                                            handleRemoveStoreLocation(sl);
                                                                        }}>
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                            })
                            }
                        </tbody>
                    </table>
                </div>

    }

    function handleCloseForm(formType:string, cancel?:boolean):void {

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

        if(formType === 'editStoreLocation'){
            setStoreLocations([]);
            setIsLoading(true);
            setShowEditStoreLocation(false);
            //getAllStoreLocations();
            getUpdatedStoreLocation(storeLocationToEdit!);
        } else if(formType === 'addStoreLocation') {
            setStoreLocations([]);
            setIsLoading(true);
            setShowAddStoreLocation(false);
            setShowEditStoreLocation(false);
            if(cancel){
                getAllStoreLocations();
            } else {
                setListChangerBtnPosition(0);
                getAllStoreLocations(0);
            }

        }


    }

    function showItemsPerList(itemsList:object[]):void {
        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        //console.log('showItemsPerList:', itemsList);
        setItemsList(itemsList as StoreLocation[]);

        //adjust item count based on items per list setting
        let nrh = 0;
        if(itemsPerList < itemsList.length){
            nrh = (itemsPerList * 94) + 588 + 16 + 57;

        } else {
            nrh = (itemsList.length) * 94 + 588 + 16 + 57;
        }


        const mh = 950;
        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
            adminStoreLocationContainerRefs.current[0].style.minHeight = `${mh}px`;
        }

        if(existingSearchResultsHeight.current === 0 && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh < existingSearchResultsHeight.current && nrh < mh){
            existingSearchResultsHeight.current = mh;

            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }

        if(nrh > existingSearchResultsHeight.current && nrh > mh){
            existingSearchResultsHeight.current = nrh;

            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
        }
        //console.log('esrs:', existingSearchResultsHeight.current);

    }


    function getListChangerBtnPosition(listChangerBtnPosition:number):void {
        //console.log('listChangerBtnPosition:', listChangerBtnPosition);
        setListChangerBtnPosition(listChangerBtnPosition);
        /*
        const ss = window.sessionStorage.getItem('storeSearchParams');
        
        if(ss !== null){
            let ssp = JSON.parse(ss) as StoreSearchParams;
            ssp.listChangerBtnPosition = listChangerBtnPosition;
            window.sessionStorage.setItem('storeSearchParams', JSON.stringify(ssp));
        }
        */

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        setIsLoading(true);
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            setIsLoading(false);
        },100);

    }

    function displayListSorter(itemsPerList:number, sls:StoreLocation[]):void {
        if(sls.length > itemsPerList){
            setShowListSorter(true);
        } else {
            setShowListSorter(false);
        }
    }

    function handleLocationSearchText(e:React.ChangeEvent):void {
        const target = e.target as HTMLInputElement;
        
        if(target.value.length <= 50){
            setStoreLocationSearchText(target.value);
            setShowKeywordSearchLimitError(false);

        } else {
            setShowKeywordSearchLimitError(true);
        }
    }

    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowInfoMessage(false);
    }

    function handleResultsPerPageOptionSelected(e:React.ChangeEvent):void {
        const rppoSelected = (e.target as HTMLSelectElement).value;
        setResultsPerPageOptionSelected(rppoSelected);
        const ipp = parseInt(rppoSelected, 10);

        const previpp = itemsPerList;
        const previousPositionInList = (previpp * (listChangerBtnPosition + 1)) - previpp + 1;
        const startPointInList = Math.floor(previousPositionInList / ipp);
        //console.log('cbp:', startPointInList, previousPositionInList / ipp, listChangerBtnPosition);

        if(ipp > storeLocations.length){
            setItemsPerList(ipp);
            setListChangerBtnPosition(0);
            getAnyItemList(0, storeLocations, ipp, showItemsPerList);

        } else {

            if(listChangerBtnPosition === 0){
                setItemsPerList(ipp);
                setListChangerBtnPosition(0);
                getAnyItemList(0, storeLocations, ipp, showItemsPerList);

            } else {
                setItemsPerList(ipp);
                setListChangerBtnPosition(startPointInList - 1);
                getAnyItemList(startPointInList - 1, storeLocations, ipp, showItemsPerList);
            }

        }

        displayListSorter(ipp, storeLocations);


        //adjust item count based on items per list setting
        let nrh = 0;
        if(ipp < storeLocations.length){
            nrh = (ipp * 94) + 588 + 16 + 57;

        } else {
            nrh = (storeLocations.length) * 94 + 588 + 16 + 57;
        }

        if(nrh > 950){
            adminStoreLocationContainerRefs.current[0].style.minHeight = `${nrh}px`;
        }
    }


    function handleSubmitLocationSearch(e:React.FormEvent):void {
        e.preventDefault();

        const form = e.target as HTMLFormElement;

        //reset form errors
        slKeywordRefs.current[0]!.classList.remove('product-info-error');
        //productPriceRef.current!.classList.remove('pricing-error');

        //reset form error message
        formError.productInfoErrors.length = 0;
        //formError.pricingErrors.length = 0;

        const fe = sfgErrorMsgRefs.current![0].sfgemcRef.current!
        const feoh = fe.offsetHeight;
        fe.style.top = `-${feoh + 60}px`;

        //product info
        //product name selected
        const slst = storeLocationSearchText;
        if(slst.length === 0){
            formError.productInfoErrors.push({hasError:true, area:'Store Location Keyword'});

        } else {
            setShowKeywordSearchLimitError(false);
        }

        //pricing info
        //department category image
        /*
        const pp = productPrice;
        if(pp.length === 0 || parseInt(pp, 10) === 0){
            formError.pricingErrors.push({hasError:true, area:'Price'});
        }
        */

        const slsts = formError.productInfoErrors.length > 0 ? true : false;
        //const pps = formError.pricingErrors.length > 0 ? true : false;

        if(slsts /*|| pps*/){
            const fe = {...formError};
            setFormError(fe);

            const raf1 = window.requestAnimationFrame(()=>{

                            addFormLabelErrors(formError);


                            //show all form errors
                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                            const ew = sfgemc.offsetWidth;
                            const eh = sfgemc.offsetHeight;
                            //console.log('eh:', eh);
                            //sfgemc.style.marginLeft = `-${ew / 2}px`;
                            sfgemc.style.maxWidth = `${formRefs.current![0].getBoundingClientRect().width}px`;
                            sfgemc.style.top = `-${eh}px`;

                            const raf2 = window.requestAnimationFrame(()=>{
                                            const sfgemc = sfgErrorMsgRefs.current![0].sfgemcRef.current!;
                                            sfgemc.style.top = '0px';

                                            //close error message container
                                            /*
                                            window.setTimeout(()=>{
                                                sfgemc.style.top = `-${eh}px`;
                                            },3000);
                                            */

                                            window.cancelAnimationFrame(raf2);
                                        });
                            
                            window.cancelAnimationFrame(raf1);

                        });

            return;
        }
        
        //setShowLoadingSearchResults(true);
        setShowNoSearchResultsFound(false);
        setShowInfoMessage(false);
        //setItemsList(null);

        setShowProcessingRequestMsg(true);
        

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);

        const searchText = storeLocationSearchText;


        /*
        const searchText = storeLocationSearchText;
        const zipCodePattern = /\d+/g
        const zcr = searchText.match(zipCodePattern);

        if(zcr !== null){
            //found some numbers
            let numberString = '';
            zcr.forEach((num)=>{
                numberString += num;		
                });
                        
            console.log('zcr:', zcr);
            console.log('search number:', parseInt(numberString,10));
    
            const searchText = numberString;
            //check store numbers
            fetch(`http://localhost:9500/store-location-search-number/${searchText}`, {
                method:'GET',
                headers:{
                    'Content-Type':'text/javascript',
                    'Accept':'text/javascript'
                }
            })
            .then((res)=>res.json())
            .then((result)=>{
                console.log('result:', result);
                
                if(result.length === 1 && result[0].source === null && result[0].storeLocation === null){
                    console.log('sl12');
                    //setShowLoadingSearchResults(false);
                    setStoreLocations([]);
                    setNoSearchResultsMessage('No store locations matched those search parameters.');
                    setShowNoSearchResultsFound(true);
                    setShowInfoMessage(true);
                    //document.documentElement.scrollIntoView({behavior:'smooth'});

                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    adminStoreLocationContainerRefs.current[0].style.minHeight = `900px`;

                } else {
                    
                    Promise.all(addStoreLocationImages(result))
                    .then((res)=>{
                        console.log('res 11-27:', res);            
                        const sls = addStoreLocations(res);
                        setStoreLocations(sls);
                        setListChangerBtnPosition(0);
                        getAnyItemList(0, sls, itemsPerList, showItemsPerList);
                        displayListSorter(itemsPerList, sls);
                        //setShowLoadingSearchResults(false);

                        //adjust item count based on items per list setting
                        let nrh = 0;
                        if(itemsPerList < sls.length){
                            nrh = (itemsPerList * 94) + 588 + 16 + 57;

                        } else {
                            nrh = (sls.length) * 94 + 588 + 16 + 57;
                        }


                        const mh = 900;
                        if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                            adminStoreLocationContainerRefs.current[0].style.minHeight = `${mh}px`;
                        }
    
                        if(existingSearchResultsHeight.current === 0 && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh < existingSearchResultsHeight.current && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh < existingSearchResultsHeight.current && nrh < mh){
                            existingSearchResultsHeight.current = mh;
    
                            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
    
                        if(nrh > existingSearchResultsHeight.current && nrh > mh){
                            existingSearchResultsHeight.current = nrh;
    
                            adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                        }
                        console.log('esrs:', existingSearchResultsHeight.current);
                        //document.documentElement.scrollIntoView({behavior:'smooth'});

                        let root = document.documentElement;
                        root!.setAttribute('style', 'scroll-behavior: auto');
                        root.scrollTo(0,0);
                        window.setTimeout(()=>{
                            root!.removeAttribute('style');
                        },100);
                        
                    })
                    .catch((err)=>{
                        console.error('add store location images error:', err.message);
                        setShowErrorMessage(true);
                    });
                    
                }
                
            })
            .catch(err=>{
                console.error('store location number search error:', err.message);
                setShowErrorMessage(true);
            });
            
        } else {
            */
            //found other text
            if(searchText.length > 0){
                let searchString = '';
    
                for(let i=0; i < searchText.length; i++){
                    if(/[\(\)\[\]\*\$\^\{\}\?\_\+\.\"\'\\|]/.test(searchText[i])){
                        searchString += `\\${searchText[i]}`;
    
                    } else {
                        searchString += `${searchText[i]}`;
                    }
                }
    
                //console.log('str26:', searchString);
                //searchString = '\\(-\\_\'\"\"\\]\\\\//\\?what\\^\\$@1';  

                const st = {searchText:searchString};
                
                const params = JSON.stringify(st);

                setTemporaryClearSearchResults(true);

                //previous version
                //fetch(`http://localhost:9500/store-location-search-city/${searchText}`, {
                fetch(`https://server.kando-proto-3.com/store-location-search-city`, {
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    },
                    body:params
                })
                .then((res)=>{

                    setShowProcessingRequestMsg(false);
                    setItemsList(null);
                    isLoadingTimeoutRef.current = window.setTimeout(()=>{
                                                            setIsLoading(true);
                                                        },1000);
                    setIsLoadingDelete(true);
    
                    let root = document.documentElement;
                    root!.setAttribute('style', 'scroll-behavior: auto');
                    root.scrollTo(0,0);
                    window.setTimeout(()=>{
                        root!.removeAttribute('style');
                    },100);

                    return res.json();
                })
                .then((result)=>{
                    //console.log('result:', result);
                    if(result.length === 1 && result[0].source === null && result[0].storeLocation === null){
                        //setShowLoadingSearchResults(false);
                        setNoSearchResultsMessage('No store locations matched those search parameters.');
                        setShowNoSearchResultsFound(true);
                        setStoreLocations([]);
                        setShowInfoMessage(true);

                        setTemporaryClearSearchResults(false);
                        //document.documentElement.scrollIntoView({behavior:'smooth'});
                        window.clearTimeout(isLoadingTimeoutRef.current);
                        setIsLoading(false);
                        setIsLoadingDelete(false);

                        let root = document.documentElement;
                        root!.setAttribute('style', 'scroll-behavior: auto');
                        root.scrollTo(0,0);
                        window.setTimeout(()=>{
                            root!.removeAttribute('style');
                        },100);

                        adminStoreLocationContainerRefs.current[0].style.minHeight = `950px`;


                    } else {
                        
                        Promise.all(addStoreLocationImages(result))
                        .then((res)=>{
                            //console.log('res 11-27:', res);            
                            const sls = addStoreLocations(res);
                            setStoreLocations(sls);
                            setListChangerBtnPosition(0);
                            getAnyItemList(0, sls, itemsPerList, showItemsPerList);
                            displayListSorter(itemsPerList, sls);

                            setTemporaryClearSearchResults(false);
                            //setShowLoadingSearchResults(false);
                            window.clearTimeout(isLoadingTimeoutRef.current);
                            setIsLoading(false);
                            setIsLoadingDelete(false);

                            //adjust item count based on items per list setting
                            let nrh = 0;
                            if(itemsPerList < sls.length){
                                nrh = (itemsPerList * 94) + 588 + 16 + 57;

                            } else {
                                nrh = (sls.length) * 94 + 588 + 16 + 57;
                            }


                            const mh = 950;
                            if(existingSearchResultsHeight.current === 0 && nrh <= mh){
                                adminStoreLocationContainerRefs.current[0].style.minHeight = `${mh}px`;
                            }
        
                            if(existingSearchResultsHeight.current === 0 && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh < existingSearchResultsHeight.current && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh < existingSearchResultsHeight.current && nrh < mh){
                                existingSearchResultsHeight.current = mh;
        
                                adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
        
                            if(nrh > existingSearchResultsHeight.current && nrh > mh){
                                existingSearchResultsHeight.current = nrh;
        
                                adminStoreLocationContainerRefs.current[0].style.minHeight = `${existingSearchResultsHeight.current}px`;
                            }
                            //console.log('esrs:', existingSearchResultsHeight.current);
                            //document.documentElement.scrollIntoView({behavior:'smooth'});

                            let root = document.documentElement;
                            root!.setAttribute('style', 'scroll-behavior: auto');
                            root.scrollTo(0,0);
                            window.setTimeout(()=>{
                                root!.removeAttribute('style');
                            },100);

                        })
                        .catch((err)=>{
                            console.error('add store location images error:', err.message);
                            setShowErrorMessage(true);
                            window.clearTimeout(isLoadingTimeoutRef.current);
                            setIsLoading(false);
                            setIsLoadingDelete(false);
                        });
                        
                    }

                    //document.documentElement.scrollIntoView({behavior:'smooth'});                    
                })
                .catch(err=>{
                    console.error('store location city search error:', err.message);
                    setShowErrorMessage(true);
                    window.clearTimeout(isLoadingTimeoutRef.current);
                    setIsLoading(false);
                    setIsLoadingDelete(false);
                });

            } else {
                //console.log('no results found.');
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                root.scrollTo(0,0);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
            }
            
            
        //}


    }

    function addFormLabelErrors(formError:FormError): void {
        //set product info errors
        formError.productInfoErrors.forEach((pie)=>{
            //console.log('pie.area:', pie.area);
                    if(pie.hasError){
                        switch(pie.area){
                            case 'Store Location Keyword':
                                slKeywordRefs.current[0]!.classList.add('product-info-error');
                                break;
                            default:
                                throw new Error('no store location keyword info errors found!');
                        }
                    }
                });


        //set image errors
        /*
        formError.pricingErrors.forEach((pe)=>{
            console.log('pe:', pe);
            if(pe.hasError && pe.area === 'Price'){
                console.log('pe.area:', pe.area);
                productPriceRef.current!.classList.add('pricing-error');
            } else {
                throw new Error('no price errors found!');
            }
        });
        */
    }

    {/*
    if(!showAddStoreLocation && !showEditStoreLocation && storeLocations.length === 0 && !isLoading){
        return <div className="admin-store-location-wrapper">
                    <div ref={getAdminStoreLocationContainerRef} className="admin-store-location-container">
                        <h3 className="store-structure-dpts-categories-heading">StoreLocations</h3>
                        {<p>No store locations have been added.</p>}
                        <button type="button" onClick={handleAddStoreLocation}>Add StoreLocation</button>
                        <GenericInfoMessage infoMessage={noSearchResultsMessage} showCloseBtn={false}/>
                    </div>
                    <Footer/>
                </div>
    } else if(!showAddStoreLocation && !showEditStoreLocation && storeLocations.length > 0) {*/}
        return <div className="admin-store-location-wrapper">
                    <div ref={getAdminStoreLocationContainerRef} className="admin-store-location-container">
                        <ul className="store-details-breadcrum-container">
                            <li className="asbi">
                                <Link to="/admin" className="store-details-breadcrum-link" 
                                    onClick={(e:React.MouseEvent)=>loadNavLink(e, '/admin', navigate)}>Admin Tools</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="asbi store-details-breadcrum-link-store-name">Store Locations</li>
                        </ul>
                        
                        {
                            !showAddStoreLocation && !showEditStoreLocation
                            ?
                            <h1 className="store-structure-dpts-categories-heading">Store Locations</h1>
                            :
                            null
                        }
                        
                        {
                            !showAddStoreLocation && !showEditStoreLocation
                            ?
                            <div>
                                <div className="admin-add-item-btn-container">
                                    <button type="button" className="admin-add-item-btn" onClick={handleAddStoreLocation}>Add Location</button>
                                    <button type="button" className="admin-reset-items-btn" onClick={resetStoreLocations}>Reset Locations</button>
                                </div>
                                <form ref={getFormRef} className="admin-search-form" onSubmit={handleSubmitLocationSearch}>
                                    <fieldset className="admin-search-box-container">
                                        <label className="admin-search-box-label" htmlFor="search-box">Keywords:</label>
                                        <span className="store-location-search-secondary-label">(Enter zip code, city, store number or store name)</span>
                                        <div className="table-keyword-search-layout-wrapper">
                                            <input id="search-box" className="admin-search-box" type="text"
                                                ref={getSLKeywordRef}
                                                placeholder={"Enter zip code, city, store number or store name"}
                                                value={storeLocationSearchText} onChange={handleLocationSearchText}/>
                                            {
                                                showKeywordSearchLimitError
                                                ?
                                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                                :
                                                null
                                            }
                                        </div>
                                    </fieldset>
                                    <fieldset className="admin-search-form-btn-container">
                                        <button type="submit" className="admin-search-form-btn">Search</button>
                                    </fieldset>
                                    <FormErrorMessage ref={getSfgErrorMsgRef} formError={formError} />
                                </form>
                            </div>
                            :
                            null
                        }

                        {
                            !showAddStoreLocation && !showEditStoreLocation && !isLoading
                            && showNoSearchResultsFound && showInfoMessage && !isLoadingDelete
                            ? 
                            <div className="admin-list-wrapper">
                                <div className="admin-list-total-continer">
                                    <span className="admin-list-total">{storeLocations.length} results</span>
                                    <div className="admin-results-per-page-container">
                                        <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                        <div className="admin-results-per-page-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                                optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                                cssStyles={{minWidth:50, backgroundPosition:5}}
                                                handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="pr-generic-info-message-container">
                                    {noSearchResultsMessage}
                                    {/*<GenericInfoMessage infoMessage={noSearchResultsMessage}/>*/}
                                </div>
                            </div>
                            :
                            null
                        }

                        {
                            !showAddStoreLocation && !showEditStoreLocation && !isLoading
                            && storeLocations.length > 0 && !showNoSearchResultsFound && !isLoadingDelete
                            ?
                            <div className="admin-list-wrapper">
                                <div className="admin-list-total-continer">
                                    {
                                        temporaryClearSearchResults
                                        ?
                                        <span className="admin-list-total"></span>
                                        :
                                        <span className="admin-list-total">{(storeLocations.length === 1) ? `${storeLocations.length} result` : `${storeLocations.length} results`}</span>
                                    }
                                    
                                    <div className="admin-results-per-page-container">
                                        <label className="admin-results-per-page-label" htmlFor="quantity">Qty</label>
                                        <div className="admin-results-per-page-selector-wrapper">
                                            <StoreLocationSelectControl elementName={elementName3} optionNameSelected={resultsPerPageOptionSelected}
                                                optionNames={resultsPerPageOptions} showErrorMsg={false} errorMsg={'none'} selectControlId={"quantity"}
                                                cssStyles={{minWidth:50, backgroundPosition:5}}
                                                handleOptionNameSelection={handleResultsPerPageOptionSelected}/>
                                        </div>
                                    </div>
                                </div>

                                {
                                    !showErrorMessage
                                    ?
                                    <ListViewer itemsList={itemsList} items={storeLocations} showListSorter={showListSorter}
                                        listChangerBtnPosition={listChangerBtnPosition}
                                        itemsPerList={itemsPerList} showItemsPerList={showItemsPerList}
                                        getListChangerBtnPosition={getListChangerBtnPosition} setupItemsList={setupStoreLocationsTable} />
                                    :
                                    null
                                }

                            </div>
                            :
                            null
                        }

                        {
                            showAddStoreLocation && !showEditStoreLocation && !isLoading
                            ?
                            <AddStoreLocation showEditStoreLocation={false} handleCloseForm={handleCloseForm}/>    
                            :
                            null
                        }

                        {
                            !showAddStoreLocation && showEditStoreLocation && !isLoading
                            ?
                            <EditStoreLocation storeLocation={storeLocationToEdit!} handleCloseForm={handleCloseForm}/>
                            :
                            null
                        }

                        {
                            showErrorMessage
                            ?
                            <div className="aprs-generic-error-msg-wrapper">
                                <GenericErrorMessage/>
                            </div>
                            :
                            null
                        }

                        {
                            !showAddStoreLocation && !showEditStoreLocation && isLoading && !isLoadingDelete
                            ?
                            <div ref={getLoaderRef} className="asls-loader-animation-container">
                                <LoaderAnimation/>
                            </div>
                            :
                            null
                        }

                        {
                            !showAddStoreLocation && !showEditStoreLocation && isLoadingDelete
                            ?
                            <div className="asls-loader2-animation-container">
                                {/*<LoaderAnimation/>*/}
                                {
                                    isLoading
                                    ?
                                    <LoaderAnimation/>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }

                        {
                            showProcessingRequestMsg
                            ?
                            <div className="process-request-msg-fixed">
                                Processing...
                            </div>
                            :
                            null
                        }

                    </div>
                    {
                        showFooter
                        ?
                        <Footer/>
                        :
                        null
                    }
                </div>
                {/*
    } else if(showAddStoreLocation && !showEditStoreLocation) {
        return <div className="admin-store-location-wrapper">
                    <div ref={getAdminStoreLocationContainerRef} className="admin-store-location-container">
                        <AddStoreLocation showEditStoreLocation={false} handleCloseForm={handleCloseForm}/>
                    </div>
                    <Footer/>
                </div>
    } else if(!showAddStoreLocation && showEditStoreLocation){
        return  <div className="admin-store-location-wrapper">
                    <div ref={getAdminStoreLocationContainerRef} className="admin-store-location-container">
                        <EditStoreLocation storeLocation={storeLocationToEdit!} handleCloseForm={handleCloseForm}/>
                    </div>
                    <Footer/>
                </div>
    } else {
        return <div className="asls-loader-animation-container">
                    <LoaderAnimation/>
                </div>
    }
    */}
}


export default StoreStructureStoreLocations2;