import React, {useRef} from 'react';
import SearchFilter from './searchFilter';
import checkFilterSearchTerm2, {checkRelatedProductsFilterSearchTerm} from './helper-search-filter-setup';
import Product from './product';
import { Link } from "react-router-dom";
import {SearchFilterParams} from './redux/features/searchFilterParams/searchFilterParamsSlice';
import {selectProductKeywordSearch, addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import './SearchFilterSidebar.css';

interface Props {
    searchFilters:SearchFilter[];
    initialSearchFilters:SearchFilter[];
    searchTermIsSelected:boolean;
    storeDepartmentId:string;
    otherStoreAreaId:string;
    storeAreaName:string;
    storeAreaType:string;
    setShowDepartmentProducts:(value: React.SetStateAction<boolean>) => void;
    clearAllExistingImages:()=>void;
    setSearchFilters:(value: React.SetStateAction<SearchFilter[]>) => void;
    //setDepartmentCategoryProducts:(value: React.SetStateAction<Product[]>) => void;
    setDepartmentCategoryProducts:(productCategoryProducts:Product[], kst?:string) => void;
    setDepartmentCategoryProductTotal:(value: React.SetStateAction<number>) => void;
    setShowSpecialProductCategoryShortcut:(value: React.SetStateAction<boolean>) => void;
    setSearchTermIsSelected:(value: React.SetStateAction<boolean>) => void;
    setKeywordSearchType:(value: React.SetStateAction<string>) => void;
    //addDepartmentCategoryProducts:(departmentCategoryProducts: Product[])=>Product[];
    addSearchFilters:(searchFilters: SearchFilter[])=>SearchFilter[];
    keywordSearch?:string;
    startAmount?:number;
    endAmount?:number;
    updateProductCategoryShortcutSearchFilter?:(showShortcut:boolean, sfps:SearchFilterParams|null)=>void;
    sssbatps?:React.Dispatch<React.SetStateAction<boolean>>;
    setProductMatch?:React.Dispatch<React.SetStateAction<boolean>>;
    setShowRelatedProducts?:React.Dispatch<React.SetStateAction<boolean>>;
    setRelatedProductTotal?:(value: React.SetStateAction<number>)=>void;
}

const SearchFilterSidebar:React.FunctionComponent<Props> = (props:Props)=>{
    const {searchFilters, initialSearchFilters, searchTermIsSelected, storeDepartmentId, 
        otherStoreAreaId, storeAreaName, storeAreaType, setShowDepartmentProducts, clearAllExistingImages, setSearchFilters,
         setDepartmentCategoryProducts, setDepartmentCategoryProductTotal,
        setShowSpecialProductCategoryShortcut, setSearchTermIsSelected, setKeywordSearchType,
        /*addDepartmentCategoryProducts,*/ addSearchFilters, keywordSearch, startAmount, endAmount, updateProductCategoryShortcutSearchFilter,
        sssbatps, setProductMatch, setShowRelatedProducts, setRelatedProductTotal} = props;

        const sfListItemContainerRefs = useRef<HTMLLIElement[]>([]);

//console.log('san:', storeAreaName);
//console.log('sfsb2:', searchFilters, searchTermIsSelected);
//console.log('isfsb:', initialSearchFilters);
//console.log('caies:', clearAllExistingImages, addSearchFilters);
//console.log('sa:', startAmount, 'ea:', endAmount);
    function setupSearchFilters(searchFilters:SearchFilter[]):JSX.Element[] {
        //return searchFilters.map((searchFilter, index)=>{
            //console.log('sfs.length:', searchFilters.length);
        return searchFilters.map((searchFilter, index)=>{
                return <li key={index} className="shop-store-department-sidebar-list-refinement">
                            <h1 className="search-filter-sidebar-list-heading">{searchFilter.filterName.replace(/\w?/i, `${searchFilter.filterName[0].toUpperCase()}`)}</h1>
                            <ul className="search-filter-list">
                                {searchFilter.searchTerms.map((searchTerm, index)=>{
                                    //console.log('sts:', searchTerm.searchTermName, searchTerm.searchTermSelected);
                                    return  <li ref={getsfListItemContainerRef} key={index} className="search-filter-list-item">
                                                <form className="search-term-filter-form">
                                                    <Link to="/" className="search-term-filter-form-link" onClick={(e:React.MouseEvent)=>{
                                                                //console.log('ct28:', e.currentTarget, 't:', e.target);
                                                                e.preventDefault();

                                                                if(window.innerWidth < 1024){
                                                                    const linkElement = e.currentTarget as HTMLAnchorElement;
                                                                    sfListItemContainerRefs.current.forEach((sfli)=>{
                                                                        if(linkElement.closest('.search-filter-list-item') === sfli){
                                                                            sfli.style.backgroundColor = '#F7F7F7';
    
                                                                        } else {
                                                                            sfli.style.backgroundColor = '';
                                                                        }
                                                                    });
                                                                }


                                                                (keywordSearch !== undefined)
                                                                ? checkRelatedProductsFilterSearchTerm(
                                                                                    e,
                                                                                    searchFilter.filterName,
                                                                                    searchTerm.searchTermName,
                                                                                    initialSearchFilters, 
                                                                                    searchTermIsSelected,
                                                                                    storeDepartmentId,
                                                                                    otherStoreAreaId,
                                                                                    storeAreaName,
                                                                                    storeAreaType,
                                                                                    setShowDepartmentProducts,
                                                                                    clearAllExistingImages,
                                                                                    setSearchFilters,
                                                                                    setDepartmentCategoryProducts,
                                                                                    setDepartmentCategoryProductTotal,
                                                                                    setShowSpecialProductCategoryShortcut,
                                                                                    setSearchTermIsSelected,
                                                                                    setKeywordSearchType,
                                                                                    /*addDepartmentCategoryProducts,*/
                                                                                    addSearchFilters,
                                                                                    keywordSearch,
                                                                                    startAmount,
                                                                                    endAmount,
                                                                                    setProductMatch,
                                                                                    setShowRelatedProducts,
                                                                                    setRelatedProductTotal
                                                                                )
                                                                : checkFilterSearchTerm2(
                                                                                    e,
                                                                                    searchFilter.filterName,
                                                                                    searchTerm.searchTermName,
                                                                                    initialSearchFilters, 
                                                                                    searchTermIsSelected,
                                                                                    storeDepartmentId,
                                                                                    otherStoreAreaId,
                                                                                    storeAreaName,
                                                                                    storeAreaType,
                                                                                    setShowDepartmentProducts,
                                                                                    clearAllExistingImages,
                                                                                    setSearchFilters,
                                                                                    setDepartmentCategoryProducts,
                                                                                    setDepartmentCategoryProductTotal,
                                                                                    setShowSpecialProductCategoryShortcut,
                                                                                    setSearchTermIsSelected,
                                                                                    setKeywordSearchType,
                                                                                    /*addDepartmentCategoryProducts,*/
                                                                                    addSearchFilters,
                                                                                    startAmount,
                                                                                    endAmount,
                                                                                    updateProductCategoryShortcutSearchFilter,
                                                                                    sssbatps
                                                                                    )
                                                            }}>
                                                    <span className="search-term-filter-label-container">
                                                        {searchTerm.showSearchTermImg ? <img className="search-term-filter-img" src={searchTerm.searchTermImg} /> : null}
                                                        <div className="search-term-filter-label">{searchTerm.searchTermName}</div>
                                                    </span>
                                                    <span>{searchTerm.searchTermQty}</span>
                                                    {/*<input type="radio" name="hasCouponFilter" className="search-term-filter-radio-btn"/>*/}
                                                    {searchTerm.searchTermSelected ? 
                                                            <div className="search-term-filter-radio-btn-container search-term-filter-radio-btn-container-checked"
                                                                /*onClick={(e:React.MouseEvent)=>{
                                                                    (keywordSearch !== undefined)
                                                                    ? checkRelatedProductsFilterSearchTerm(
                                                                                        e,
                                                                                        searchFilter.filterName,
                                                                                        searchTerm.searchTermName,
                                                                                        initialSearchFilters, 
                                                                                        searchTermIsSelected,
                                                                                        storeDepartmentId,
                                                                                        otherStoreAreaId,
                                                                                        storeAreaName,
                                                                                        storeAreaType,
                                                                                        setShowDepartmentProducts,
                                                                                        clearAllExistingImages,
                                                                                        setSearchFilters,
                                                                                        setDepartmentCategoryProducts,
                                                                                        setDepartmentCategoryProductTotal,
                                                                                        setShowSpecialProductCategoryShortcut,
                                                                                        setSearchTermIsSelected,
                                                                                        setKeywordSearchType,
                                                                                        
                                                                                        addSearchFilters,
                                                                                        keywordSearch,
                                                                                        startAmount,
                                                                                        endAmount,
                                                                                        setProductMatch,
                                                                                        setShowRelatedProducts,
                                                                                        setRelatedProductTotal
                                                                                    )
                                                                    : checkFilterSearchTerm2(
                                                                                        e,
                                                                                        searchFilter.filterName,
                                                                                        searchTerm.searchTermName,
                                                                                        initialSearchFilters, 
                                                                                        searchTermIsSelected,
                                                                                        storeDepartmentId,
                                                                                        otherStoreAreaId,
                                                                                        storeAreaName,
                                                                                        storeAreaType,
                                                                                        setShowDepartmentProducts,
                                                                                        clearAllExistingImages,
                                                                                        setSearchFilters,
                                                                                        setDepartmentCategoryProducts,
                                                                                        setDepartmentCategoryProductTotal,
                                                                                        setShowSpecialProductCategoryShortcut,
                                                                                        setSearchTermIsSelected,
                                                                                        setKeywordSearchType,
                                                                                        
                                                                                        addSearchFilters,
                                                                                        startAmount,
                                                                                        endAmount,
                                                                                        updateProductCategoryShortcutSearchFilter,
                                                                                        sssbatps
                                                                                        )
                                                                }}*/>
                                                                <span className="check-shape-container">
                                                                    <svg width="16" height="16" className="check-shape" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M4.111 11.642l10.213-9.379a1 1 0 111.352 1.474l-10.888 10a1 1 0 01-1.353 0L.324 10.878a1 1 0 011.352-1.473l2.435 2.236z" fill="currentColor"></path></svg>
                                                                </span>
                                                                <input type="checkbox" id="checkbox-selected" className="search-term-filter-checkbox-control"/>
                                                            </div>
                                                    : <div className="search-term-filter-radio-btn-container">
                                                        <span className="checkmark-add">
                                                            <svg width="16" height="16" className="check-shape" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M4.111 11.642l10.213-9.379a1 1 0 111.352 1.474l-10.888 10a1 1 0 01-1.353 0L.324 10.878a1 1 0 011.352-1.473l2.435 2.236z" fill="currentColor"></path></svg>
                                                        </span>
                                                        <input type="checkbox" id="checkbox-not-selected" className="search-term-filter-checkbox-control"/>
                                                            {/*<i className="fas fa-check check-shape"></i>*/}
                                                            {/*<svg width="16" height="16" className="check-shape" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M4.111 11.642l10.213-9.379a1 1 0 111.352 1.474l-10.888 10a1 1 0 01-1.353 0L.324 10.878a1 1 0 011.352-1.473l2.435 2.236z" fill="currentColor"></path></svg>*/}
                                                        </div>
                                                    }
                                                    </Link>
                                                </form>
                                            </li>
                                })}
                                
                            </ul>

                            {
                                (searchFilter.searchTerms.length > 5)
                                ?
                                <button type="button" className="search-filter-sidebar-show-all-results-btn"
                                    onClick={showResultsHandler}>
                                    <span className="search-filter-sidebar-show-all-results-btn-text-container">
                                        <span className="search-filter-sidebar-show-all-results-btn-text">Show all</span>
                                                {/*<svg height="512" width="448" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="search-filter-sidebar-show-all-results-btn-direction-arrow">
                                                    <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
                                                    </path>
                                                </svg>*/}

                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="search-filter-sidebar-show-all-results-btn-direction-arrow"><path fillRule="evenodd" clipRule="evenodd" d="M1.65 4.24a1 1 0 00-1.3 1.52l7 6a1 1 0 001.3 0l7-6a1 1 0 00-1.3-1.52L8 9.684 1.65 4.24z" fill="currentColor"></path></svg>
                                    </span>
                                </button>
                                :
                                null
                            }

                        </li>
                });

    }


    function getsfListItemContainerRef(el:HTMLLIElement|null):void {
        if(el !== null){
            //radioBtnContainerRefs.current.length = 0;
        }
    
        if(el !== null && sfListItemContainerRefs.current.length === 0){
            sfListItemContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = sfListItemContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                sfListItemContainerRefs.current.push(el);
            }

        }

        //console.log('rbcrs:', sfListItemContainerRefs.current);
    }




    function showResultsHandler(e:React.MouseEvent):void {
        const btnElement = e.currentTarget as HTMLButtonElement;
        //console.log('be:', btnElement);
        const searchFilterListElement = btnElement.previousElementSibling as HTMLUListElement;
        //console.log('sfle:', searchFilterListElement);
        const btnTextElement = btnElement.querySelector('.search-filter-sidebar-show-all-results-btn-text') as HTMLSpanElement;
        const svgElement = btnElement.querySelector('.search-filter-sidebar-show-all-results-btn-direction-arrow') as SVGElement;

        if(svgElement.classList.contains('search-filter-sidebar-direction-arrow-position')){
            searchFilterListElement.style.maxHeight = '';
            btnTextElement.textContent = 'Show all';

        } else {
            searchFilterListElement.style.maxHeight = '100%';
            btnTextElement.textContent = 'Show less';
        }

        svgElement.classList.toggle('search-filter-sidebar-direction-arrow-position');

    }

    if(searchFilters.length > 0){
        return <ul className="search-filter-sidebar-container">
                    {setupSearchFilters(searchFilters)}
                </ul>

    } else {
        return null;
    }


}

export default SearchFilterSidebar;