import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import ProductKeywordSearch from '../../productKeywordSearch';
import cloneDeep from 'lodash/cloneDeep';

interface RelatedProductsFilterNav {
    keywordSearch:string;
}

const initialState:RelatedProductsFilterNav = {
  keywordSearch:''
};

export const relatedProductsFilterNavSlice = createSlice({
  name: 'relatedProductsFilterNav',
  initialState,
  reducers: {
    showRelatedProductsFilterNav: (state, action:PayloadAction<RelatedProductsFilterNav>) => {
      state.keywordSearch = action.payload.keywordSearch;
    }
  }
})

// Action creators are generated for each case reducer function
export const { showRelatedProductsFilterNav } = relatedProductsFilterNavSlice.actions;

interface RootState {
  relatedProductsFilterNav: RelatedProductsFilterNav;
}

export const selectRelatedProductsFilterNav = (state:RootState) =>  {
  //const clone = cloneDeep(state.productKeywordSearch);
  return state.relatedProductsFilterNav;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default relatedProductsFilterNavSlice.reducer;