import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminLogin {
    showAdminLogin:boolean;
}

const initialState:AdminLogin = {
    showAdminLogin:false,
};

export const adminLoginSlice = createSlice({
  name: 'adminLogin',
  initialState,
  reducers: {
    showAdminLogin: (state, action:PayloadAction<AdminLogin>) => {
      state.showAdminLogin = action.payload.showAdminLogin;
    }
  }
})

// Action creators are generated for each case reducer function
export const { showAdminLogin } = adminLoginSlice.actions;

interface RootState {
  adminLogin: AdminLogin
}

export const selectAdminLogin = (state:RootState) =>  {
  //const clone = cloneDeep(state.productKeywordSearch);
  return state.adminLogin;
}


export default adminLoginSlice.reducer;