import React, {useState, useEffect} from 'react';
import './HomePageLayoutSelector.css';

export interface ItemTypeOption {
    value:string;
}

interface Props {
    label:string;
    options:ItemTypeOption[];
    optionSelected:string;
    optionId:string;
    cssStyles?:{minWidth:number, backgroundPosition:number};
    callback:(e:React.ChangeEvent, layoutAction:string)=>void;
}

const HomePageLayoutTypeSelector:React.FunctionComponent<Props> = (props:Props)=>{
    const {optionId, cssStyles, callback} = props;
    const [itemOptionSelected, setItemOptionSelected] = useState(props.optionSelected);
    const [itemOptions, setItemOptions] = useState<ItemTypeOption[]>(props.options);
    const [itemOptionSelectorLabel, setItemOptionSelectorLabel] = useState(props.label);

    useEffect(()=>{
        setItemOptionSelected(props.optionSelected);
    },[props.optionSelected]);

    function setupItemSelecterItemOptionList(options:ItemTypeOption[]):JSX.Element[] {
        return options.map((o, i)=>{
                    return <option key={i} value={o.value}>{o.value}</option>
                });

    }

    /*
    function itemSelectorChangeHandler(e:React.ChangeEvent):void {
        const optionSelected = (e.target as HTMLSelectElement).value;
        setItemOptionSelected(optionSelected);
    }
    */

    return (<div className="hplb-item-selector-wrapper">
                <label className="hplb-item-selector-label" htmlFor={optionId}>{itemOptionSelectorLabel}</label>
                <select 
                    style={{minWidth:`${(typeof cssStyles !== 'undefined' ? cssStyles.minWidth : 120 )}px`, backgroundPosition:`calc(100% - ${(typeof cssStyles !== 'undefined' ? cssStyles.backgroundPosition : 14 )}px`}}    
                    className="hplb-item-selector" id={optionId} value={itemOptionSelected} onChange={(e:React.ChangeEvent)=>callback(e, optionId)}>
                    {setupItemSelecterItemOptionList(itemOptions)}
                </select>
            </div>);
}

export default HomePageLayoutTypeSelector;