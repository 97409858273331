/* previous version
export interface NutritionServing {
    aps?:string;
    dv?:string;
    sn?:string;
}
*/

export class NutritionServing {
    public nutritionServingId:number;
    public nutritionFactId:number;
    public sn:string;
    public aps:string;
    public dv:string;
    constructor(options:{
        nutritionServingId?:number,
        nutritionFactId?:number,
        sn?:string,
        aps?:string,
        dv?:string
    }={}){
        this.nutritionServingId = options.nutritionServingId || 0;
        this.nutritionFactId = options.nutritionFactId || 0;
        this.sn = options.sn || '';
        this.aps = options.aps || '';
        this.dv = options.dv || '';
    }
}

export class VitaminServing {
    public vitaminId:number;
    public nutritionFactId:number;
    public aps:string;
    public dv:string;
    public sn:string;
    constructor(options:{
        vitaminId?:number,
        nutritionFactId?:number;
        aps?:string,
        dv?:string,
        sn?:string
    }={}){
        this.vitaminId = options.vitaminId || 0;
        this.nutritionFactId = options.nutritionFactId || 0;
        this.aps = options.aps || '';
        this.dv = options.dv || '';
        this.sn = options.sn || '';
    }
}

class NutritionFacts {
    public nutritionFactId:number;
    public productId:number;
    public productName:string;
    public servingSize:string;
    public servingsPerContainer:string;
    public calories:number;
    public caloriesFromFat:number;
    public totalFat:NutritionServing | null;
    public saturatedFat:NutritionServing | null;
    public transFat:NutritionServing | null;
    public cholesterol:NutritionServing | null;
    public sodium:NutritionServing | null;
    public potassium:NutritionServing | null;
    public totalCarbohydrate:NutritionServing | null;
    public dietaryFiber:NutritionServing | null;
    public sugars:NutritionServing | null;
    public sugarAlcohol:NutritionServing | null;
    public protein:NutritionServing | null;
    public vitamins:VitaminServing[];
    constructor(options:{
        nutritionFactId?:number,
        productId?:number,
        productName?:string,
        servingSize?:string,
        servingsPerContainer?:string,
        calories?:number,
        caloriesFromFat?:number,
        totalFat?:NutritionServing | null,
        saturatedFat?:NutritionServing | null,
        transFat?:NutritionServing | null,
        cholesterol?:NutritionServing | null,
        sodium?:NutritionServing | null,
        potassium?:NutritionServing | null,
        totalCarbohydrate?:NutritionServing | null,
        dietaryFiber?:NutritionServing | null,
        sugars?:NutritionServing | null,
        sugarAlcohol?:NutritionServing | null,
        protein?:NutritionServing | null,
        vitamins?:VitaminServing[]
    }={}){
        this.nutritionFactId = options.nutritionFactId || 0;
        this.productId = options.productId || 0;
        this.productName = options.productName || '';
        this.servingSize = options.servingSize || '';
        this.servingsPerContainer = options.servingsPerContainer || '';
        this.calories = options.calories || 0;
        this.caloriesFromFat = options.caloriesFromFat || 0;
        this.totalFat = options.totalFat || null;
        this.saturatedFat = options.saturatedFat || null;
        this.transFat = options.transFat || null;
        this.cholesterol = options.cholesterol || null;
        this.sodium = options.sodium || null;
        this.potassium = options.potassium || null;
        this.totalCarbohydrate = options.totalCarbohydrate || null;
        this.dietaryFiber = options.dietaryFiber || null;
        this.sugars = options.sugars || null;
        this.sugarAlcohol = options.sugarAlcohol || null;
        this.protein = options.protein || null;
        this.vitamins = options.vitamins || [];
    }
}

export default NutritionFacts;