import React, {useImperativeHandle, useRef, useEffect, forwardRef} from 'react';
import SearchFilter, {SearchFilterGroup} from './searchFilter';
import ProductVerification from './productVerification';
import ProductPromotion from './productPromotion';
import './CheckboxBuilder.css';


interface Props {
    options:ProductPromotion[];
    selectedOptions:ProductPromotion[];
    title:string;
    handleOptionNameSelection:(e:React.ChangeEvent)=>void;
}

/*
type Ref = {
    ppCheckboxControlsRef:React.RefObject<HTMLInputElement[]>;
};
*/

const ProductPromotionControl2: React.FunctionComponent<Props> = (props:Props) => {
//const ProductPromotionControl2 = forwardRef<Ref, Props>((props, ref)=>{
    const {options, selectedOptions, title, handleOptionNameSelection} = props;
    //console.log('options:', options);

    const controlsRef = useRef<HTMLInputElement[]>([]);
    /*
    useImperativeHandle(ref, ()=>({
        
        get ppCheckboxControlsRef(){
            return controlsRef;
        }
        
        })
    );
    */

    useEffect(()=>{

        checkOptions(selectedOptions);
        
    },[selectedOptions]);
    

    function addCheckboxControl(el:HTMLInputElement):void {
        if(el !== null && controlsRef.current.length === 0){
            controlsRef.current.push(el);
        }

        if(el !== null && controlsRef.current.length > 0){
            const found = controlsRef.current.filter((c)=>{
                                return c === el;
                            });

            if(found.length === 0){
                controlsRef.current.push(el);
            }
        }

        //console.log('pp controls:', ppCheckboxControls.current);

    }

    function checkOptions(sos:ProductPromotion[]):void {
        sos.forEach((pp)=>{
            controlsRef.current.forEach((control)=>{
                //console.log('control:', control);
                const controlId = window.parseInt(control.id, 10);
                if(pp.productPromotionId === controlId){
                    control.checked = true;
                }
            });
        });
    }

    function setupPPCheckboxControls(options:ProductPromotion[]):JSX.Element[] {
        return options.map((pp, i)=>{
                            return  <div key={i} className="add-product-checkbox-form-group product-promotion-container">
                                        <input ref={addCheckboxControl} className="admin-checkbox-control" type="checkbox" name={`checkbox ${i}`} id={`${pp.productPromotionId} ${pp.productPromotionName}`} value={`${pp.productPromotionName}`} 
                                             onChange={handleOptionNameSelection}/>
                                        <label className="product-promotion-label" htmlFor={`${pp.productPromotionId} ${pp.productPromotionName}`}>{pp.productPromotionName}</label>
                                    </div>
                        });
    }

    return (
        <fieldset className="promotions-fieldset">
            <legend>{title}</legend>
            {setupPPCheckboxControls(options)}
        </fieldset>
    );


}

export default ProductPromotionControl2;