import React, {useEffect, useState, useRef} from 'react';
import {SearchFilterValidity} from './helper-check-search-filter-validity';
import './GenericInfoMessage.css';

interface Props {
    sfvMessage:SearchFilterValidity;
    showCloseBtn?:boolean;
    customStyle?:string;
    closeInfoMsgHandler?:(e:React.MouseEvent)=>void;
    showExtraMsgContainer?:boolean;
    continueInfoMsgHandler?:(e:React.MouseEvent)=>void;
}

const SearchFilterErrorMessage:React.FunctionComponent<Props> = (props:Props)=>{
    const {sfvMessage, showCloseBtn, customStyle, 
            showExtraMsgContainer, closeInfoMsgHandler, continueInfoMsgHandler} = props;
            //console.log('sfvm:', sfvMessage);

    function setupSearchFilterMessage(sfv:SearchFilterValidity):React.ReactNode {
        //need to show Error message of the products that don't meet the above condition
        //return 'No products were attached to at least two search filters. A product must be attached to a minimum of two search filters to appear in the search results';
        return <div>
                    <p>{`To use the search filters for this ${sfv.sfat.toLowerCase()} these products must be attached to a minimum of two search filters.`}</p>
                    <ul className="generic-info-msg-list">
                        {sfv.products.map((p, i)=>{
                            return <li key={i} className="generic-info-msg-list-item">{p.productName}</li>
                        })}
                    </ul>
                </div>

    }
    

    return (<div className={`generic-info-msg-wrapper`}>
                {
                    showCloseBtn 
                    ?
                    <span className="generic-info-msg-close-btn-container">
                        <button type="button" className="generic-info-msg-close-btn" onClick={closeInfoMsgHandler}>
                            <svg className="generic-info-close-btn-svg" width="12" height="12" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                <path className="generic-info-close-btn-path" d="M200 0 L0 200 Z" ></path>
                                <path className="generic-info-close-btn-path" d="M0 0 L200 200 Z" ></path>
                            </svg>
                        </button>
                    </span> 
                    : 
                    null
                }

                <div className="generic-info-msg-container">
                    <div className="generic-info-msg">{setupSearchFilterMessage(sfvMessage)}</div>
                </div>
                {
                    showExtraMsgContainer
                    ?
                    <div className="generic-info-extra-msg-btn-container">
                        {/*<button className="giemc-btn" type="button" onClick={continueInfoMsgHandler}>Continue</button>*/}
                        <button className="giemc-btn" type="button" onClick={closeInfoMsgHandler}>Close</button>
                    </div>
                    : null
                }
            </div>);
}


export default SearchFilterErrorMessage;