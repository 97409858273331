import React, {useEffect, useState, useRef} from 'react';
//import {Link, navigate, useMatch, useParams, useLocation} from '@gatsbyjs/reach-router';
import { useLocation, Link, useNavigate, useParams, useMatch, NavigateFunction, useLoaderData } from 'react-router-dom';
import ShopDepartment3 from './ShopDepartment3';
import ShopDepartmentCategory3 from './ShopDepartmentCategory3';
import ShopProductCategory2 from './ShopProductCategory2';
import ShopByAreaTypeFilter from './ShopByAreaTypeFilter';
import helperHighlightDepartment from './helper-highlight-department';
import Product from './product';
import addDepartmentCategories, {addSearchFilters} from './helper-add-department-categories';
import {addProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import { addSearchFilterParams } from './redux/features/searchFilterParams/searchFilterParamsSlice';
//import { resetSBAT, selectSBAT } from './redux/features/shopByAreaType/shopByAreaTypeSlice';
import {selectMobileNav} from './redux/features/mobileNav/mobileNavSlice';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import StoreDepartment from './storeDepartment';
import ProductCategory from './productCategory';
import DepartmentCategory from './departmentCategory';
import ProductSubCategory from './productSubCategory';
import SearchFilter from './searchFilter';
import {getAnyItemList} from './helper-get-item-list';
import {sortByAtoZ, sortByZtoA, sortByNewest, sortByRatings, 
    sortByPriceHightoLow, sortByPriceLowtoHigh} from './helper-sort-by-option';
import checkFilterSearchTerm2, {checkSearchFiltersSelected, updateSearchFilters2} from './helper-search-filter-setup';
import cloneDeep from 'lodash/cloneDeep';
import ShopByAreaTypeStoreDepartment from './ShopByAreaTypeStoreDepartment';
import ShopByAreaTypeDepartmentCategory from './ShopByAreaTypeDepartmentCategory';
import ShopByAreaTypeProductCategory from './ShopByAreaTypeProductCategory';
import helperSetOptionValue from './helper-set-option-value';
import Sbat from './shopByAreaType';
import ShopProductMiniCart from './ShopProductMiniCart';
import LoaderAnimation from './LoaderAnimation';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import PageNotFound from './PageNotFound';
import ScrollToTopButton from './ScrollToTopButton';
import formatLinkString from './helper-format-link-string';
import {SearchFilterValidity} from './helper-check-search-filter-validity';
import SearchFilterErrorMessage from './SearchFilterErrorMessage';
import './ShopByAreaType.css';

interface TypeMatch {
        [param: string]: string;
        uri: string;
        path: string;
}

/*
interface ShopByAreaType {
    type:string;
    storeDepartmentName?:string;
    departmentCategoryName?:string;
    productCategoryName?:string;
    storeDepartmentId?:string;
    departmentCategoryId?:string;
    productCategoryId?:string;
}
*/

interface Props {
    //path:string;
}

const ShopByAreaType:React.FunctionComponent<Props> = (props:Props)=>{
    //console.log('request:');
    //console.log('result:', props.path);
    const ld = useLoaderData() as (Sbat|string)[];

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const mobileNavRedux = useAppSelector(selectMobileNav);
    //const sbatRedux = useAppSelector(selectSBAT);
    const shopByDepartment = useMatch('/shop/:storeDepartmentName/:storeDepartmentId');
    const shopByDepartmentCategory = useMatch('/shop/:storeDepartmentName/:departmentCategoryName/:storeDepartmentId/:departmentCategoryId');
    const shopByProductCategory = useMatch('/shop/:storeDepartmentName/:departmentCategoryName/:productCategoryName/:storeDepartmentId/:productCategoryId');

    const isPageNotFound = useRef<boolean>(false);
    //const [shopByAreaType, setShopByAreaType] = useState<Sbat>(getShopByAreaType(shopByDepartment, shopByDepartmentCategory, shopByProductCategory));
    
    //previous version
    //const [shopByAreaType, setShopByAreaType] = useState<Sbat>(getParameters(useLocation().pathname)[0]);
    const [shopByAreaType, setShopByAreaType] = useState<Sbat>(ld[0] as Sbat);

    isPageNotFound.current = (ld[1] === 'page-not-found') ? true : false;

    const [sbatRouteChanged, setSbatRouteChanged] = useState<boolean>(false);
    const [showShopByAreaTypeFilter, setShowShopByAreaTypeFilter] = useState(false);
    const [storeDepartments, setStoreDepartments] = useState<StoreDepartment[] | []>([]);
    const [storeDepartment, setStoreDepartment] = useState<StoreDepartment | null>(null);
    const [storeDepartmentId, setStoreDepartmentId] = useState(shopByAreaType.storeDepartmentId);
    const [storeDepartmentName, setStoreDepartmentName] = useState(shopByAreaType.storeDepartmentName);
    const [showShopStoreDepartment, setShowShopStoreDepartment] = useState(false);

    const [sortByOptionValue, setSortByOptionValue] = useState('Best Match');

    const [controller, setController] = useState<AbortController>(new AbortController());
    const [keywordSearchType, setKeywordSearchType] = useState('results');
    const [startSliderAmount, setStartSliderAmount] = useState(0);
    const [endSliderAmount, setEndSliderAmount] = useState(0);
    const [previousSlider1PositionLeft, setPreviousSlider1PositionLeft] = useState('0%');
    const [previousSlider2PositionLeft, setPreviousSlider2PositionLeft] = useState('100%');
    const [previousSlider1PostionLeftAmount, setPreviousSlider1PostionLeftAmount] = useState(0);
    const [previousSlider2PostionLeftAmount, setPreviousSlider2PostionLeftAmount] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [previousSlider1TotalDistance, setPreviousSlider1TotalDistance] = useState(0);
    const [previousSlider2TotalDistance, setPreviousSlider2TotalDistance] = useState(100);
    const [slider1ZIndex, setSlider1ZIndex] = useState(0);
    const [slider2ZIndex, setSlider2ZIndex] = useState(0);
    const [keywordSearch, setKeywordSearch] = useState(shopByAreaType.storeDepartmentName);
    const [showPriceSlider, setShowPriceSlider] = useState(false);
    const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
    const [initialSearchFilters, setInitialSearchFilters] = useState<SearchFilter[]>([]);
    const [searchTermIsSelected, setSearchTermIsSelected] = useState(false);
    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);
    const [hasDepartmentCategories, setHasDepartmentCategories] = useState<boolean>(false);
    const [itemsPerList, setItemsPerList] = useState(40);
    const [listChangerBtnPosition, setListChangerBtnPosition] = useState(0);
    const [itemsList, setItemsList] = useState<Product[] | null>(null);    
    const [showListSorter, setShowListSorter] = useState(true);
    const [showNoSearchResultsFound, setShowNoSearchResultsFound] = useState(false);
    const [noSearchResultsMessage, setNoSearchResultsMessage] = useState('No store locations have been added.');

    /***department category properties***/
    const [showSpecialProductCategoryShortcut, setShowSpecialProductCategoryShortcut] = useState(true);
    const [departmentCategoryId, setDepartmentCategoryId] = useState(shopByAreaType.departmentCategoryId);
    const [hasProductCategories, setHasProductCategories] = useState<boolean>(false);

    /***product category properties***/
    const [productCategories, setProductCategories] = useState<ProductCategory[] | []>([]);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(shopByAreaType.departmentCategoryName);
    const [productCategoryName, setProductCategoryName] = useState(shopByAreaType.productCategoryName);
    const [productCategory, setProductCategory] = useState<ProductCategory | null>(null);
    const [storeDepartmentCategory, setStoreDepartmentCategory] = useState<DepartmentCategory | null>(null);
    const [isSpecialDepartmentCategory, setIsSpecialDepartmentCategory] = useState(false);
    const [productCategorySearch, setProductCategorySearch] = useState('product');
    const [productSubCategorySelected, setProductSubCategorySelected] = useState<ProductSubCategory | null>(null);
    const [productCategoryId, setProductCategoryId] = useState(shopByAreaType.productCategoryId);

    const [shopByAreaTypeProducts, setShopByAreaTypeProducts] = useState<Product[]>([]);
    const [showShopByAreaTypeProducts, setShowShopByAreaTypeProducts] = useState<boolean>(false);
    const [shopByAreaTypeProductTotal, setShopByAreaTypeProductTotal] = useState(0);

    const SbatSidebarMobileNavRef = useRef<HTMLDivElement>(null);
    const sortByBtnRefs = useRef<HTMLButtonElement[]>([]);

    const [clearAllSearchFilterTerms, setClearAllSearchFilterTerms] = useState(false);
    const [reloadFilteredProducts, setReloadFilteredProducts] = useState(false);

    const mobileNavOpen = useRef<boolean>(false);

    const mobileSearchFilterWrapperRefs = useRef<HTMLDivElement[]>([]);

    const portrait = useRef<MediaQueryList[]>([]);

    const mobileNavRef = useRef<{menuListMobileNavRef:HTMLElement, menuListNavWrapperRef:HTMLDivElement, backgroundShadeContainerRef:HTMLDivElement}>(null);

    //const [showMobileAnimationLoader, setShowMobileAnimationLoader] = useState(false);

/*
    const {storeDepartmentName:sdn, departmentCategoryName:dcn, 
            productCategoryName:pcn, storeDepartmentId:sdID, productCategoryId:pcID} = useParams();
            */

    //previous version      
    //const [sbat, sbatRoute] = getParameters(useLocation().pathname);
    const [sbat, sbatRoute] = ld;

    //console.log('did2:', sbat);
    //console.log('sbatRoute:', sbatRoute);


    //console.log('gp2:', getParameters(useLocation().pathname));
            
    //console.log('sbat2:', shopByAreaType);
    //console.log('cleanup:', 'sd2:', storeDepartment, SbatSidebarMobileNavRef, 'params:', useLocation());


    const sbatWrapperRefs = useRef<HTMLDivElement[]>([]);
    const [showMiniCart, setShowMiniCart] = useState(false);
    const miniCartHasProductsRef = useRef<boolean>(false);

    const [showLoaderAnimation, setShowLoaderAnimation] = useState(true);

    const [showSearchFilterErrorMsg, setShowSearchFilterErrorMsg] = useState(false);
    const [searchFilterErrorMsg, setSearchFilterErrorMsg] = useState<SearchFilterValidity|null>(null);
    const showSearchFilterErrorMsgRef = useRef<boolean>(false);


    useEffect(()=>{
        //console.log('route changed');
        //console.log('scr4:');
        
        if(window.innerWidth >= 768 && shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;
            setShowMiniCart(true);

        } else if(shoppingCartRedux.shoppingCartCategories.length > 0){
            //console.log('scr4:');
            miniCartHasProductsRef.current = true;

        } else {
            //console.log('scr4:');
            miniCartHasProductsRef.current = false;
            setShowMiniCart(false);

            if(sbatWrapperRefs.current.length > 0){
                //console.log('ran10');
                sbatWrapperRefs.current[0].style.width = '';
                sbatWrapperRefs.current[0].style.margin = '';
            }

        }
        


    },[shoppingCartRedux]);


    function getMobileSearchFilterWrapperRef(el:HTMLDivElement|null):void {           
        if(el !== null && mobileSearchFilterWrapperRefs.current.length === 0){
            mobileSearchFilterWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = mobileSearchFilterWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                mobileSearchFilterWrapperRefs.current.push(el);
            }

        }

        //console.log('msfcrs:', mobileSearchFilterWrapperRefs.current);
    }


    function getSbatWrapperRef(el:HTMLDivElement|null):void {
    
        if(el !== null && sbatWrapperRefs.current.length === 0){
            sbatWrapperRefs.current.push(el);

        } else if(el !== null) {
            const found = sbatWrapperRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                sbatWrapperRefs.current.push(el);
            }

        }

        //console.log('plwrs:', sbatWrapperRefs.current);


        if(showMiniCart && window.innerWidth <= 1575){
            //console.log('smc:');
            sbatWrapperRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
            sbatWrapperRefs.current[0].style.margin = '0 auto 0 0';

        } else {
            sbatWrapperRefs.current[0].style.width = '100%';
            sbatWrapperRefs.current[0].style.margin = '0 auto';
        }

        /*css styles for bread crumbs
        if(showMiniCart && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

    }

    

    function getParameters(path:string):[Sbat, string] {
        const r = path.slice(5);
        //console.log('str:', decodeURIComponent(r));
        const ur = decodeURIComponent(r);
        //console.log('ur:', ur);
        const matches = ur.matchAll(/(\/<)/g);
        let ips:number[] = [];
        for(const match of matches){
            //console.log('m31:', match);
            if(typeof match.index !== 'undefined'){
                ips.push(match.index);
            }
            
        }
        
        //console.log('ips:', ips);


        /*previous version
        const match = r.match(/\//g) as RegExpMatchArray;
        console.log('match:', match);
        const myRe = /\//g;
        let counter = 0;
        let ips = [];

        while(counter < match.length){
            ips.push(myRe.exec(ur)!.index);
            counter++;
            
        }
        */

        interface PT {
            [index:string]:string;
        }

        let params:string[] = [];

        switch(ips.length){
            case 2:
                //console.log('ips:', ips);
                for(let i=0; i < ips.length; i++){
                    switch(i){
                        case 0:
                            const param1 = ur.slice(ips[i] + 1, ips[1]);
                            params.push(param1);
                            break;
                        case 1:
                            const param2 = ur.slice(ips[1] + 1, ips[2]);
                            params.push(param2);
                            break;
                        default:
                            //throw new Error('no index position found.');
                            isPageNotFound.current = true;
                            return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
                    }
                    
                }
                break;

            case 4:
                //console.log('ips:', ips);
                for(let i=0; i < ips.length; i++){
                    switch(i){
                        case 0:
                            const param1 = ur.slice(ips[i] + 1, ips[1]);
                            params.push(param1);
                            break;
                        case 1:
                            const param2 = ur.slice(ips[1] + 1, ips[2]);
                            params.push(param2);
                            break;
                        case 2:
                            const param3 = ur.slice(ips[2] + 1, ips[3]);
                            params.push(param3);
                            break;
                        case 3:
                            const param4 = ur.slice(ips[3] + 1);
                            params.push(param4);
                            break;
                        default:
                            //throw new Error('no index position found.');
                            isPageNotFound.current = true;
                            return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
                    }
                    
                }

                break;

            case 5:
                //console.log('ips:', ips);
                for(let i=0; i < ips.length; i++){
                    switch(i){
                        case 0:
                            const param1 = ur.slice(ips[i] + 1, ips[1]);
                            params.push(param1);
                            break;
                        case 1:
                            const param2 = ur.slice(ips[1] + 1, ips[2]);
                            params.push(param2);
                            break;
                        case 2:
                            const param3 = ur.slice(ips[2] + 1, ips[3]);
                            params.push(param3);
                            break;
                        case 3:
                            const param4 = ur.slice(ips[3] + 1, ips[4]);
                            params.push(param4);
                            break;
                        case 4:
                            const param5 = ur.slice(ips[4] + 1);
                            params.push(param5);
                            break;
                        default:
                            //throw new Error('no index position found.');
                            isPageNotFound.current = true;
                            return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
                    }
                    
                }

                break;

            default:
                //throw new Error('no index position found.');
                isPageNotFound.current = true;
                return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
        }

        //console.log('params:', params);
        //remove extra forward slashes, "<" and ">"
        params = params.map((param)=>{
                    const fp = param.replace(/(^<)|(>$)/g, '');
                    return fp.replace(/\/\//g, '/');
                });

       //console.log('params:', params);
       //console.log('params:', params.join('/'));
       switch(params.length){
            case 2:
                const rps2 = {
                    storeDepartmentName:params[0],
                    storeDepartmentId:params[1]
                };

                const sb1 = new Sbat({
                    type:'department',
                    storeDepartmentName:params[0],
                    storeDepartmentId:params[1]
                });

                if(!(parseInt(sb1.storeDepartmentId) > 0)){
                    isPageNotFound.current = true;
                    return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
                }

                return [sb1, params.join('/')];

            case 4:
                const rps4 = {
                    storeDepartmentName:params[0],
                    departmentCategoryName:params[1],
                    storeDepartmentId:params[2],
                    departmentCategoryId:params[3]
                };
                const sb2 = new Sbat({
                    type:'departmentCategory',
                    storeDepartmentName:params[0],
                    departmentCategoryName:params[1],
                    storeDepartmentId:params[2],
                    departmentCategoryId:params[3]
                });

                //console.log('params:', parseInt(sb2.storeDepartmentId) > 0, parseInt(sb2.departmentCategoryId) > 0);

                if(!(parseInt(sb2.storeDepartmentId) > 0)
                   || !(parseInt(sb2.departmentCategoryId) > 0)){
                    //console.log('params:');
                    isPageNotFound.current = true;
                    return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
                }

                return [sb2, params.join('/')];

            case 5:
                const rps5 = {
                    storeDepartmentName:params[0],
                    departmentCategoryName:params[1],
                    productCategoryName:params[2],
                    storeDepartmentId:params[3],
                    productCategoryId:params[4]
                    
                };
                const sb3 = new Sbat({
                    type:'productCategory',
                    storeDepartmentName:params[0],
                    departmentCategoryName:params[1],
                    productCategoryName:params[2],
                    storeDepartmentId:params[3],
                    productCategoryId:params[4]
                });

                if(!(parseInt(sb3.storeDepartmentId) > 0)
                   || !(parseInt(sb3.productCategoryId) > 0)){
                    isPageNotFound.current = true;
                    return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
                }

                return [sb3, params.join('/')];

            default:
                //throw new Error('no params found.');
                isPageNotFound.current = true;
                return [new Sbat({type:'pageNotFound'}), 'page-not-found'];
       }


    }

   function getShopByAreaType(sbd:TypeMatch|null, sbdc:TypeMatch|null, sbpc:TypeMatch|null):Sbat {
    //console.log('sbd:', sbd, 'sbdc:', sbdc, 'sbpc:', sbpc);
        if(sbd !== null){
            return new Sbat({
                        type:'department',
                        storeDepartmentId:sbd.storeDepartmentId,
                        storeDepartmentName:sbd.storeDepartmentName
                    });

        } else if(sbdc !== null){
            return new Sbat({
                        type:'departmentCategory',
                        storeDepartmentName:sbdc.storeDepartmentName,
                        departmentCategoryName:sbdc.departmentCategoryName,
                        storeDepartmentId:sbdc.storeDepartmentId,
                        departmentCategoryId:sbdc.departmentCategoryId
                    });

        } else {
            return new Sbat({
                        type:'productCategory',
                        storeDepartmentName:sbpc!.storeDepartmentName,
                        departmentCategoryName:sbpc!.departmentCategoryName,
                        productCategoryName:sbpc!.productCategoryName,
                        storeDepartmentId:sbpc!.storeDepartmentId,
                        productCategoryId:sbpc!.productCategoryId
                    });
        }
   }

   //use this hook to detect whether the route has changed
   //if it has create a new shop by area type with the new route values
   //then set route has changed property to the opposite value (true then false vice verca);
   //set show shop by area type filter to false
   //if the next hook is triggered set show shop by area type filter to true
    useEffect(()=>{
        //console.log('r28:', mobileNavRedux);
        //console.log('route changed:', shopByAreaType, sbat, ld, mobileNavRef.current);
        if(mobileNavRedux.closeShopByTypeNav && mobileNavRef.current !== null){
            mobileNavRef.current!.menuListMobileNavRef.style.transition = 'transform 0s ease';
            mobileNavRef.current!.menuListMobileNavRef.style.transform = 'translateX(0px)';

            mobileNavRef.current!.menuListNavWrapperRef.style.width = '0%';
            mobileNavRef.current!.backgroundShadeContainerRef.classList.add('background-shade-container-mobile-hide');

            //console.log('test7:', mobileNavRef.current!.menuListMobileNavRef, mobileNavRef.current!.menuListMobileNavRef.getElementsByClassName('show-smdsmlc'));
            const tels = Array.from(mobileNavRef.current!.menuListMobileNavRef.getElementsByClassName('show-smdsmlc'));
            tels.forEach((tel)=>{
                tel.classList.remove('show-smdsmlc');
                const ae = tel.previousElementSibling as HTMLAnchorElement;
                const aeChildSVG = Array.from(ae.getElementsByClassName('rotate-svg-dropdown-arrow'))[0] as SVGElement;
                aeChildSVG.classList.remove('rotate-svg-dropdown-arrow');
            });
        }

        const nsbat = new Sbat({...sbat as Sbat});

        //previous version
        //setShopByAreaType(new Sbat({...sbat as Sbat}));
        setShopByAreaType(nsbat);

        setStoreDepartmentName(nsbat.storeDepartmentName);
        setStoreDepartmentId(nsbat.storeDepartmentId);
        setDepartmentCategoryName(nsbat.departmentCategoryName);
        setDepartmentCategoryId(nsbat.departmentCategoryId);
        setProductCategoryName(nsbat.productCategoryName);
        setProductCategoryId(nsbat.productCategoryId);

        //setShowShopByAreaTypeProducts(false);

        //previous version
        //setShowShopByAreaTypeFilter(true);

        setShowSearchFilterErrorMsg(false);
        showSearchFilterErrorMsgRef.current = false;


        //new version
        if(showShopByAreaTypeFilter){
            //setShowShopByAreaTypeFilter(false);
            setShowShopByAreaTypeProducts(false);

            setShopByAreaTypeProducts([]);
            if(window.innerWidth < 1024){
                setShopByAreaTypeProducts([]);
                //setShowMobileAnimationLoader(true);
            }

            setSbatRouteChanged(!sbatRouteChanged);

        } else {
            setShowShopByAreaTypeFilter(true);
        }

        //window.scrollTo(0,0);
        setSortByOptionValue('Best Match');
        sortByBtnRefs.current.forEach((sbbr)=>{
            if(sbbr.textContent === 'Best Match'){
                sbbr.classList.add('highlight-sort-by-list-item-btn');

            } else {
                sbbr.classList.remove('highlight-sort-by-list-item-btn');
            }
        });

        dispatch(addProductKeywordSearch!({
            keywordSearch:'',
            priceRangeSearch:'relatedProducts',
            storeDepartmentSearch:'none',
            departmentCategorySearch:'none',
            sentFromKeywordList:false,
            clearKeywordSearch:true
                }));
        
    },[sbatRoute]);

    
    useEffect(()=>{
        //console.log('route reloaded:');
        
        /*
        setSortByOptionValue('Best Match');
        sortByBtnRefs.current.forEach((sbbr)=>{
            if(sbbr.textContent === 'Best Match'){
                sbbr.classList.add('highlight-sort-by-list-item-btn');

            } else {
                sbbr.classList.remove('highlight-sort-by-list-item-btn');
            }
        });
        */
        
        setShowShopByAreaTypeFilter(true);      


    },[sbatRouteChanged]);
    
    
    useEffect(()=>{
        //console.log('route changed');
        setShowShopByAreaTypeFilter(true);

    },[clearAllSearchFilterTerms]);


    /*
    useEffect(()=>{
        //if(isLoaded){
            console.log('route changed');
            //setShowShopByAreaTypeProducts(true);
            //setIsLoaded(true);
        //}

    },[reloadFilteredProducts]);
    */

    //console.log('route changed', showShopByAreaTypeProducts, shopByAreaTypeProducts);


   function loadStoreDepartment(e:React.MouseEvent, sbat:Sbat):void {
        e.stopPropagation();
        e.preventDefault();
        
        helperHighlightDepartment(e, sbat.storeDepartmentName!)
        .then((response)=>{
            //setShowDepartmentCategoryProducts(false);
            return response.json();
        })
        .then(({action})=>{
            if(action === 'added'){
                //console.log('added');
                let root = document.documentElement;
                root!.setAttribute('style', 'scroll-behavior: auto');
                setShowShopByAreaTypeProducts(false);
                setShowLoaderAnimation(true);
                setShowShopByAreaTypeFilter(false);
                setShopByAreaTypeProducts([]);
                navigate(`/shop/${encodeURIComponent(formatLinkString(sbat.storeDepartmentName))}/${sbat.storeDepartmentId}`);
                window.setTimeout(()=>{
                    root!.removeAttribute('style');
                },100);
                
            
        }})
        .catch(err=>console.log('error:', err));
    }


    function loadNavLink(e:React.MouseEvent, pathname:string):void {
        e.preventDefault();
        //previous version
        //if(decodeURIComponent(location.pathname) === decodeURIComponent(pathname)){
        if(decodeURIComponent(location.pathname) === decodeURIComponent(pathname)){
            return;

        } else {
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            
            setShowShopByAreaTypeProducts(false);

            if(window.innerWidth >= 1024){
                //setShowShopByAreaTypeFilter(false);
                //setShopByAreaTypeProducts([]);
            }

            
            navigate(pathname);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            dispatch(addSearchFilterParams({keywordSearchType:'',
                                            otherStoreAreaId:'0',
                                            products:[],
                                            showProductSubCategoryFilter:true,
                                            noSearchFilterAttached:false,
                                            searchFilterSelected:'',
                                            searchFilters:[],
                                            searchTermIsSelected:false,
                                            storeAreaName:'',
                                            storeAreaType:'',
                                            storeDepartmentId:'0',
                                            searchTermSelected:''
                                            }));

        }
        
    }


    function loadProductNavLink(e:React.MouseEvent, pathname:string, showLoader?:boolean):void {
        e.preventDefault();
        //previous version
        //if(decodeURIComponent(location.pathname) === decodeURIComponent(pathname)){
        if(decodeURIComponent(location.pathname) === decodeURIComponent(pathname)){
            return;

        } else {
            let root = document.documentElement;
            root!.setAttribute('style', 'scroll-behavior: auto');
            root.scrollTo(0,0);
            
            setShowShopByAreaTypeProducts(false);

            if(showLoader){
                setShowLoaderAnimation(true);
            } else {
                setShowLoaderAnimation(false);
            }
            
            setShowShopByAreaTypeFilter(false);
            setShopByAreaTypeProducts([]);

            
            navigate(pathname);
            window.setTimeout(()=>{
                root!.removeAttribute('style');
            },100);

            dispatch(addSearchFilterParams({keywordSearchType:'',
                                            otherStoreAreaId:'0',
                                            products:[],
                                            showProductSubCategoryFilter:true,
                                            noSearchFilterAttached:false,
                                            searchFilterSelected:'',
                                            searchFilters:[],
                                            searchTermIsSelected:false,
                                            storeAreaName:'',
                                            storeAreaType:'',
                                            storeDepartmentId:'0',
                                            searchTermSelected:''
                                            }));

        }
        
    }


   function setupShopByAreaType(sbat:Sbat):JSX.Element | null {
        //console.log('sf23:');
        if(sbat.type === 'department'){
            return <ShopByAreaTypeStoreDepartment storeDepartmentName={shopByAreaType.storeDepartmentName!} 
                    storeDepartmentId={shopByAreaType.storeDepartmentId!} keywordSearchType={keywordSearchType} 
                    departmentProducts={shopByAreaTypeProducts} storeDepartment={storeDepartment!}
                    sortByOptionValue={sortByOptionValue}
                    sbatProductMobileNavFilterHandler={sbatProductMobileNavFilterHandler}
                    sbatProductMobileNavSortByOptionHandler={sbatProductMobileNavSortByOptionHandler}
                    loadNavLink={loadProductNavLink} />    
            //return <ShopDepartment3 storeDepartmentName={shopByAreaType.storeDepartmentName!} storeDepartmentId={shopByAreaType.storeDepartmentId!}/>

        } else if(sbat.type === 'departmentCategory'){
            return <ShopByAreaTypeDepartmentCategory storeDepartmentName={shopByAreaType.storeDepartmentName!} 
                    departmentCategoryName={shopByAreaType.departmentCategoryName!} storeDepartmentId={shopByAreaType.storeDepartmentId!}
                    departmentCategoryId={shopByAreaType.departmentCategoryId!} storeDepartment={storeDepartment!}
                    storeDepartmentCategory={storeDepartmentCategory!} departmentCategoryProducts={shopByAreaTypeProducts}
                    keywordSearchType={keywordSearchType} sortByOptionValue={sortByOptionValue}
                    sbatProductMobileNavFilterHandler={sbatProductMobileNavFilterHandler}
                    sbatProductMobileNavSortByOptionHandler={sbatProductMobileNavSortByOptionHandler}
                    loadNavLink={loadProductNavLink}/>
            /*return <ShopDepartmentCategory3 storeDepartmentName={shopByAreaType.storeDepartmentName!} 
                        departmentCategoryName={shopByAreaType.departmentCategoryName!} storeDepartmentId={shopByAreaType.storeDepartmentId!}
                        departmentCategoryId={shopByAreaType.departmentCategoryId!}/>*/

        } else if(sbat.type === 'productCategory'){
            return <ShopByAreaTypeProductCategory storeDepartmentName={shopByAreaType.storeDepartmentName!} 
                    departmentCategoryName={shopByAreaType.departmentCategoryName!} storeDepartmentId={shopByAreaType.storeDepartmentId!}
                    productCategoryName={shopByAreaType.productCategoryName!} productCategory={productCategory!} productCategoryId={shopByAreaType.productCategoryId!}
                    storeDepartment={storeDepartment!} storeDepartmentCategory={storeDepartmentCategory!} 
                    productCategoryProducts={shopByAreaTypeProducts} keywordSearchType={keywordSearchType}
                    sortByOptionValue={sortByOptionValue} initialSearchFilters={initialSearchFilters}
                    sbatProductMobileNavFilterHandler={sbatProductMobileNavFilterHandler}
                    sbatProductMobileNavSortByOptionHandler={sbatProductMobileNavSortByOptionHandler}
                    loadNavLink={loadProductNavLink} sssbatps={setShowShopByAreaTypeProducts}/>
            /*return <ShopProductCategory2 storeDepartmentName={shopByAreaType.storeDepartmentName!} 
                    departmentCategoryName={shopByAreaType.departmentCategoryName!} storeDepartmentId={shopByAreaType.storeDepartmentId!}
                    productCategoryName={shopByAreaType.productCategoryName!} productCategoryId={shopByAreaType.productCategoryId!}/>*/
        } else {
                return null;
        }
   }

   function setupShopByAreaTypeBreadCrumbs(shopByAreaType:Sbat):JSX.Element | null {
        if(shopByAreaType.type === 'department'){
            return <div className="shop-by-area-type-breadcrumb-wrapper">
                        <ul className="shop-product-category-breadcrum-container">
                            <li className="spcbid">
                                <Link to="/" className="shop-store-department-home-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, '/')}>Home</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbid">
                                <Link to="/shop" className="shop-store-department-overview-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, '/shop/overview')}>
                                    Shop
                                </Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbid">
                                {shopByAreaType.storeDepartmentName}
                            </li>
                        </ul>
                        <div>
                            <h1 className="shop-store-department-heading">{shopByAreaType.storeDepartmentName}</h1>
                        </div>
                    </div>

        } else if(shopByAreaType.type === 'departmentCategory'){
            return  <div className="shop-by-area-type-breadcrumb-wrapper">
                        <ul className="shop-product-category-breadcrum-container">
                            <li className="spcbidc">
                                <Link to="/" className="shop-store-department-home-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, '/')}>Home</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbidc">
                                <Link to="/shop" className="shop-store-department-overview-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, '/shop/overview')}>
                                    Shop
                                </Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbidc">
                                <Link to={`/shop/${encodeURIComponent(formatLinkString(shopByAreaType.storeDepartmentName))}/${shopByAreaType.storeDepartmentId}`} className="shop-store-department-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadStoreDepartment(e, shopByAreaType)}>
                                    {shopByAreaType.storeDepartmentName}
                                </Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbidc">
                                {shopByAreaType.departmentCategoryName}
                            </li>
                        </ul>
                        <div>
                            <h1 className="shop-store-department-heading">{shopByAreaType.departmentCategoryName}</h1>
                        </div>
                    </div>

        } else if(shopByAreaType.type === 'productCategory'){
            return <div className="shop-by-area-type-breadcrumb-wrapper">
                        <ul className="shop-product-category-breadcrum-container">
                            <li className="spcbipc">
                                <Link to="/" className="shop-store-department-home-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, '/')}>Home</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbipc"> 
                                <Link to="/shop" className="shop-store-department-overview-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, '/shop/overview')}>Shop</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbipc">
                                <Link to={`/shop/${encodeURIComponent(formatLinkString(shopByAreaType.storeDepartmentName))}/${shopByAreaType.storeDepartmentId}`} className="shop-store-department-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, `/shop/${encodeURIComponent(formatLinkString(shopByAreaType.storeDepartmentName))}/${shopByAreaType.storeDepartmentId}`, true)}>{shopByAreaType.storeDepartmentName}</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbipc">
                                <Link to={`/shop/${encodeURIComponent(formatLinkString(shopByAreaType.storeDepartmentName))}/${encodeURIComponent(formatLinkString(shopByAreaType.departmentCategoryName))}/${shopByAreaType.storeDepartmentId}/${shopByAreaType!.departmentCategoryId}`} className="shop-product-category-department-name-breadcrum-link"
                                    onClick={(e:React.MouseEvent)=>loadProductNavLink(e, `/shop/${encodeURIComponent(formatLinkString(shopByAreaType.storeDepartmentName))}/${encodeURIComponent(formatLinkString(shopByAreaType.departmentCategoryName))}/${shopByAreaType.storeDepartmentId}/${shopByAreaType!.departmentCategoryId}`, true)}>{shopByAreaType.departmentCategoryName}</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="spcbipc">
                                {/*<Link to={`/shop/${storeDepartmentName}/${departmentCategoryName}/${productCategoryName}/${storeDepartment!.departmentId}/${productCategory!.productCategoryId}`} className="shop-product-category-name-breadcrum-link"
                                    onClick={handleClickProductCategoryBreadCrumLink}>{productCategoryName}</Link>*/}
                                {shopByAreaType.productCategoryName}
                            </li>
                        </ul>
                        <div>
                            <h1 className="shop-store-department-heading">{shopByAreaType.productCategoryName}</h1>
                        </div>
                    </div>
        } else {
            return null;
        }
    }


    function getShopByAreaTypeProducts(products:Product[], kst:string):void {
        //console.log('sbatps:', products, kst);

        /*
        if(window.innerWidth < 1024){
            setShowMobileAnimationLoader(false);
        }
        */
        

        setShopByAreaTypeProducts(products);
        setShowShopByAreaTypeProducts(true);
    
        /*previous version
        if(products.length > 0){
            setKeywordSearchType('results');

        } else {
            setKeywordSearchType('noResults');
        }
        */
       setKeywordSearchType(kst);


        //setReloadFilteredProducts(!reloadFilteredProducts);
        //sbatContainerRef.current!.scrollIntoView(true);
        //window.scrollTo(0,0);
        
        
        /*
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        */
        

        /*
        let root = document.documentElement;
        root.scrollTo({top:0, left:0, behavior:'smooth'});
        */
        
        
    }

    function getStoreDepartment(sd:StoreDepartment|null):void {
        if(sd === null){
            setShopByAreaType(new Sbat({type:'pageNotFound'}));
            setStoreDepartment(sd);
            isPageNotFound.current = true;

        } else {
            setStoreDepartment(sd);
            isPageNotFound.current = false;
        }
        
    }

    function getStoreDepartmentCategory(dc:DepartmentCategory|null):void {
        if(dc === null){
            //console.log('dc5:', dc);
            setShopByAreaType(new Sbat({type:'pageNotFound'}));
            setStoreDepartmentCategory(dc);
            isPageNotFound.current = true;

        } else {
            setStoreDepartmentCategory(dc);
            //previous version has the next to lines uncommented
            //shopByAreaType.departmentCategoryId = dc.departmentCategoryId.toString();
            //setShopByAreaType(shopByAreaType);
            isPageNotFound.current = false;
        }

    }

    function getProductCategory(pc:ProductCategory|null):void {
        if(pc === null){
            setShopByAreaType(new Sbat({type:'pageNotFound'}));
            setProductCategory(pc);
            isPageNotFound.current = true;

        } else {
            setProductCategory(pc);
            isPageNotFound.current = false;
        }
        
    }

    function getInitialSearchFilters(sfs:SearchFilter[]):void {
        setInitialSearchFilters(sfs);
    }

    function getKeywordSearchType(kst:string):void {
        setKeywordSearchType(kst);
    }

    function getSortByBtnRef(el:HTMLButtonElement|null):void {
        //console.log('el:');
        if(el !== null && sortByBtnRefs.current.length === 0){
            sortByBtnRefs.current.push(el);

        } else if(el !== null) {
            const found = sortByBtnRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                sortByBtnRefs.current.push(el);
            }

        }

        //console.log('sbbrs:', sortByBtnRefs.current);
       
        
    }

    function sortByOptionClicked(
                        e:React.MouseEvent,
                        optionsClicked:string, 
                        productCategoryProducts:Product[],
                        //selectedStoreDepartmentId:number,
                        //selectedDepartmentCategoryId:number
                        ):void {
        //console.log('sortByOptionClicked:', optionsClicked);
        const btnElement = e.target as HTMLButtonElement;;
        //add this class to the target element
        //remove this class from all other list items
        sortByBtnRefs.current.forEach((sbbr)=>{
                                    if(sbbr === btnElement){
                                        btnElement.classList.add('highlight-sort-by-list-item-btn');

                                    } else {
                                        sbbr.classList.remove('highlight-sort-by-list-item-btn');
                                    }
                                });

        e.stopPropagation();

        imageRefs.current.length = 0;
        setImageRefsLoaded(false);
        
        //setShowProductCategoryProducts(false);
        setShowShopByAreaTypeProducts(false);
        window.scrollTo(0,0);
        setSortByOptionValue(optionsClicked);
        const selectedOption:string = optionsClicked;
        helperSetOptionValue(
        selectedOption, 
        productCategoryProducts, 
        true, 
        //storeDepartmentName!,
        //departmentCategoryName!,
        //setProductCategoryProducts,
        setShopByAreaTypeProducts,
        //setShowProductCategoryProducts
        setShowShopByAreaTypeProducts
        );

        //getAnyItemList(listChangerBtnPosition, productCategoryProducts, itemsPerList, showItemsPerList);
        //displayListSorter(itemsPerList, productCategoryProducts);

    }

    useEffect(()=>{
        //setIsLoaded(true);
        window.addEventListener('resize', resize2Handler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", resize2Handler);

        //window.screen.orientation.addEventListener('change', screenOrientationChangeHandler);

        return cleanupResizeHandler;
    },[]);

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resize2Handler);
        //window.screen.orientation.removeEventListener('change', screenOrientationChangeHandler);
        portrait.current[0].removeEventListener("change", resize2Handler);
    }

    function screenOrientationChangeHandler(e:Event):void {
        resize2Handler(new Event('test'));
    }

    function resize2Handler(e:Event):void {
        //console.log('rp resize:', isPageNotFound.current);

        /*
        if(window.innerWidth >= 1024){
            SbatSidebarMobileNavRef.current!.style.zIndex = '';
        }

        if(window.innerWidth < 1024){
            SbatSidebarMobileNavRef.current!.style.zIndex = '5';
        }
        */

        if(!isPageNotFound.current){

            if(window.innerWidth >= 1024 && mobileNavOpen.current){
                SbatSidebarMobileNavRef.current!.style.zIndex = '';
            }

            if(window.innerWidth >= 1024 && !mobileNavOpen.current){
                SbatSidebarMobileNavRef.current!.style.zIndex = '';
            }

            if(window.innerWidth < 1024 && mobileNavOpen.current){
                SbatSidebarMobileNavRef.current!.style.zIndex = '7';
            }

            if(window.innerWidth < 1024 && !mobileNavOpen.current){
                SbatSidebarMobileNavRef.current!.style.zIndex = '';
            }


            //console.log('scr4:', miniCartHasProductsRef.current);
            if(window.innerWidth >= 768 && window.innerWidth <= 1600 && miniCartHasProductsRef.current){
                sbatWrapperRefs.current[0].style.width = `${document.documentElement.clientWidth - 78}px`;
                const ma = 0;//window.innerWidth > 1535 ? window.innerWidth - 1535 : 0;
                //console.log('ma:', ma);
                sbatWrapperRefs.current[0].style.margin = `0 auto 0 ${ma}px`;
                setShowMiniCart(true);

            } 
            
            if(window.innerWidth > 1600 && miniCartHasProductsRef.current){
                sbatWrapperRefs.current[0].style.width = '100%';
                sbatWrapperRefs.current[0].style.margin = '0 auto';
                //setShowMiniCart(true);

            }

            if(window.innerWidth >= 768 && !miniCartHasProductsRef.current){
                sbatWrapperRefs.current[0].style.width = '100%';
                sbatWrapperRefs.current[0].style.margin = '0 auto';
            }

            if(window.innerWidth < 768){
                sbatWrapperRefs.current[0].style.width = '100%';
                sbatWrapperRefs.current[0].style.margin = '0 auto';
                setShowMiniCart(false);
            }

        }

        /*css styles for breadcrumbs
        if(miniCartHasProductsRef.current && window.innerWidth >= 768 && window.innerWidth <= 795){
            productBreadcrumContainerRef.current!.style.fontSize = '15px';

        } else {
            productBreadcrumContainerRef.current!.style.fontSize = '';
        }
        */

        if(showSearchFilterErrorMsgRef.current){
            setShowPriceSlider(false);
        }
        
    }

    function clearAllSearchFilterTermSelections(e:React.MouseEvent):void {
        e.stopPropagation();
        setShowShopByAreaTypeProducts(false);
        setShowShopByAreaTypeFilter(false);
        setClearAllSearchFilterTerms(!clearAllSearchFilterTerms);
        setSortByOptionValue('Best Match');
        sortByBtnRefs.current.forEach((sbbr)=>{
            if(sbbr.textContent === 'Best Match'){
                sbbr.classList.add('highlight-sort-by-list-item-btn');

            } else {
                sbbr.classList.remove('highlight-sort-by-list-item-btn');
            }
        });
    }

    function sbatProductMobileNavSortByOptionHandler(sbov:string):void {
        //console.log('sbov:', sbov);
        //set option value class first
        sortByBtnRefs.current.forEach((sbbr)=>{
                                //console.log('sbov:', sbov, 'sbbr:', sbbr.textContent, sbov === sbbr.value); 
                                if(sbbr.textContent === sbov){
                                    sbbr.classList.add('highlight-sort-by-list-item-btn');

                                } else {
                                    sbbr.classList.remove('highlight-sort-by-list-item-btn');
                                }
                            });

        setSortByOptionValue(sbov);
    }

    function sbatProductMobileNavFilterHandler():void {
        mobileNavOpen.current = true;
        SbatSidebarMobileNavRef.current!.style.zIndex = '7';
        SbatSidebarMobileNavRef.current!.style.visibility = 'visible';
        SbatSidebarMobileNavRef.current!.style.transform = 'translateY(0%)';
    }

    function shopProductsMobileNavCloseBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        mobileNavOpen.current = false;
        SbatSidebarMobileNavRef.current!.style.visibility = '';
        SbatSidebarMobileNavRef.current!.style.transform = '';
        SbatSidebarMobileNavRef.current!.style.zIndex = '';
    
    }

    function scrollToTopCallback():void {
        const root = document.documentElement;
        root.scrollIntoView({behavior:'smooth'});
    }


    function closeInfoMsgHandler(e:React.MouseEvent):void {
        setShowSearchFilterErrorMsg(false);
        showSearchFilterErrorMsgRef.current = false;
    }

    function getSearchFilterValidity(sfv:SearchFilterValidity):void {
        setSearchFilterErrorMsg(sfv);
        setShowSearchFilterErrorMsg(true);
        showSearchFilterErrorMsgRef.current = true;
        //setShopByAreaTypeProducts([]);
        //setShowShopByAreaTypeProducts(false);
        //setShowShopByAreaTypeFilter(false);
    }


    return (
        <div className="shop-by-area-type-layout-wrapper">
            <ProductSearch/>
            <MainNavbar2 loadNavLink={loadNavLink}/>
            <MenuListMobileNav ref={mobileNavRef} loadNavLink={loadNavLink}/>
            {
                shopByAreaType.type !== 'pageNotFound'
                ?
            <div ref={getSbatWrapperRef} className="shop-by-area-type-wrapper">
                {
                    //showShopByAreaTypeProducts
                    showShopByAreaTypeFilter
                    ?
                    setupShopByAreaTypeBreadCrumbs(shopByAreaType)
                    :null
                }


                    <div className="shop-by-area-type-container">
                        
                        <div ref={SbatSidebarMobileNavRef} className="sbat-side-bar-wrapper">
                            <div className="shop-products-mobile-options-container">
                                <button type="button" className="shop-products-mobile-options-clear-btn"
                                    onClick={clearAllSearchFilterTermSelections}>Clear all</button>
                                <span className="shop-products-mobile-options-heading">Filter</span>
                                <button type="button" className="shop-products-mobile-options-close-btn" onClick={shopProductsMobileNavCloseBtnHandler}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.586 12L4.293 5.707a1 1 0 011.414-1.414L12 10.586l6.293-6.293a1 1 0 111.414 1.414L13.414 12l6.293 6.293a1 1 0 01-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 01-1.414-1.414L10.586 12z" fill="currentColor"></path>
                                    </svg>
                                </button>
                            </div>
                            <div ref={getMobileSearchFilterWrapperRef} className="shop-products-mobile-search-filter-wrapper">
                                <div className="shop-products-mobile-sort-by-list-container">
                                    <span className="shop-products-mobile-sort-by-list-heading">Sort By</span>
                                    <ul className="shop-products-mobile-sort-by-list">
                                    <li className="shop-products-mobile-sort-by-list-item">
                                        <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button highlight-sort-by-list-item-btn"
                                            onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                            'Best Match',
                                                                                            shopByAreaTypeProducts,
                                                                                            //storeDepartmentCategory!.departmentId,
                                                                                            //storeDepartmentCategory!.departmentCategoryId
                                                                                            )}>
                                        Best Match</button>
                                    </li>
                                    <li className="shop-products-mobile-sort-by-list-item">
                                        <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                            onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                            'A-Z',
                                                                                            shopByAreaTypeProducts,
                                                                                            //storeDepartmentCategory!.departmentId,
                                                                                            //storeDepartmentCategory!.departmentCategoryId
                                                                                            )}>
                                        A-Z</button>
                                    </li>
                                    <li className="shop-products-mobile-sort-by-list-item">
                                        <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                            onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                            'Z-A',
                                                                                            shopByAreaTypeProducts,
                                                                                            //storeDepartmentCategory!.departmentId,
                                                                                            //storeDepartmentCategory!.departmentCategoryId
                                                                                            )}>
                                        Z-A</button>
                                    </li>
                                    <li className="shop-products-mobile-sort-by-list-item">
                                        <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                            onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                            'Price High to Low',
                                                                                            shopByAreaTypeProducts,
                                                                                            //storeDepartmentCategory!.departmentId,
                                                                                            //storeDepartmentCategory!.departmentCategoryId
                                                                                            )}>
                                        Price High to Low</button>
                                    </li>
                                    <li className="shop-products-mobile-sort-by-list-item">
                                        <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                            onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                            'Price Low to High',
                                                                                            shopByAreaTypeProducts,
                                                                                            //storeDepartmentCategory!.departmentId,
                                                                                            //storeDepartmentCategory!.departmentCategoryId
                                                                                            )}>
                                        Price Low to High</button>
                                    </li>
                                    <li className="shop-products-mobile-sort-by-list-item">
                                        <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                            onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                            'Ratings',
                                                                                            shopByAreaTypeProducts,
                                                                                            //storeDepartmentCategory!.departmentId,
                                                                                            //storeDepartmentCategory!.departmentCategoryId
                                                                                            )}>
                                        Ratings</button>
                                    </li>
                                    <li className="shop-products-mobile-sort-by-list-item">
                                        <button type="button" ref={getSortByBtnRef} className="shop-products-mobile-sort-by-list-item-button"
                                            onClick={(e:React.MouseEvent)=>sortByOptionClicked(e,
                                                                                            'Newest',
                                                                                            shopByAreaTypeProducts,
                                                                                            //storeDepartmentCategory!.departmentId,
                                                                                            //storeDepartmentCategory!.departmentCategoryId
                                                                                            )}>
                                        Newest</button>
                                    </li>
                                    </ul>

                                </div>

                                
                                {
                                    showShopByAreaTypeFilter
                                    ?
                                    <div className="shop-products-mobile-search-filter-container">
                                        <ShopByAreaTypeFilter storeDepartmentName={storeDepartmentName/*shopByAreaType.storeDepartmentName*/} storeDepartmentId={storeDepartmentId/*shopByAreaType.storeDepartmentId*/}
                                            departmentCategoryName={departmentCategoryName/*shopByAreaType.departmentCategoryName*/} departmentCategoryId={departmentCategoryId/*shopByAreaType.departmentCategoryId!*/}
                                            productCategoryName={productCategoryName/*shopByAreaType.productCategoryName!*/} productCategoryId={productCategoryId/*shopByAreaType.productCategoryId!*/}
                                            shopByAreaType={shopByAreaType} getShopByAreaTypeProducts={getShopByAreaTypeProducts}
                                            getStoreDepartment={getStoreDepartment} getStoreDepartmentCategory={getStoreDepartmentCategory}
                                            getProductCategoryForSBAT={getProductCategory}
                                            getProductCategorySearchFiltersForSBAT={getInitialSearchFilters} 
                                            getKeywordSearchType={getKeywordSearchType}
                                            sortByOptionValue={sortByOptionValue} loadNavLink={loadNavLink}
                                            sssbatps={setShowShopByAreaTypeProducts}
                                            getSearchFilterValidity={getSearchFilterValidity} />
                                    
                                    </div>
                                    : null
                                }

                                {/*
                                    showMobileAnimationLoader
                                    ?
                                    <div className="sbat-loader-animation-container">
                                        {<LoaderAnimation/>}
                                    </div>
                                    :
                                    null
                                */}

                            </div>
                            <div className="shop-products-mobile-item-count-container">
                                <button type="button" className="shop-products-mobile-view-items-btn"
                                    onClick={shopProductsMobileNavCloseBtnHandler}>{`${(shopByAreaTypeProducts.length > 0) ? (shopByAreaTypeProducts.length === 1 ? 'Show ' + shopByAreaTypeProducts.length + ' result' : 'Show ' + shopByAreaTypeProducts.length + ' results') : 'Processing...'}`}</button>
                            </div>
                        </div>

                        {
                            showShopByAreaTypeProducts
                            //!showAnimationLoader
                            ?
                            setupShopByAreaType(shopByAreaType)
                            :
                            null
                        }

{/*<div className="sbat-loader-animation-background-container"></div>*/}

                        {
                            showShopByAreaTypeProducts
                            ?
                            <ScrollToTopButton scrollToTopCallback={scrollToTopCallback} showMiniCart={showMiniCart}/>
                            :
                            null
                        }
                        

                    </div>

                    {
                        !showShopByAreaTypeProducts
                        ?
                        <div className="sbat-loader-animation-container">
                            {
                                showLoaderAnimation
                                ?
                                <LoaderAnimation/>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }

                    {
                        showSearchFilterErrorMsg 
                        ? (<div className="add-product-sub-category-form-info-message-container">
                            <SearchFilterErrorMessage sfvMessage={searchFilterErrorMsg!} 
                                showCloseBtn={true} showExtraMsgContainer={true} closeInfoMsgHandler={closeInfoMsgHandler}/>
                            </div>)
                        : null
                    }
                
                {/*<Footer/>*/}
            </div>
            :
            null
        }

            {
                shopByAreaType.type === 'pageNotFound'
                ?
                <PageNotFound/>
                :
                null
            }            


            {
                showMiniCart
                ?
                <ShopProductMiniCart/>
                : null
            }
        </div>
        );

    
}


export default ShopByAreaType;