import React, {useState, useEffect} from 'react';
import {Link} from '@reach/router';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Review from './review';
import './ApproveRejectProductReview.css';
import {addReviews} from './helper-add-reviews';
import transformDate from './helper-transform-date';
import CustomerRelation from './customerRelation';
import getNotApprovedProductReviews from './helper-get-not-approved-product-reviews';
import ApproveRejectProductReviewLoadingMsg from './ApproveRejectProductReviewLoadingMsg';
import LoaderAnimation from './LoaderAnimation';
import DbResponse from './db-response';
import GenericErrorMessage from './GenericErrorMessage';

interface Props {
    productReview:Review;
    showProductReview:boolean;
    handleHideProductReview:()=>void;
    handleProductReviewSubmit:(updatedProductReviews:Review[], action:string)=>void;
    handleCloseFormSubmit:(e:React.MouseEvent)=>void;
}

const ApproveRejectProductReview:React.FunctionComponent<Props> = (props:Props) => {
    const {handleHideProductReview, handleProductReviewSubmit, handleCloseFormSubmit} = props;
    const [productReview, setProductReview] = useState<Review | null>(props.productReview);
    const [customerRelationResponse, setCustomerRelationResponse] = useState(setupCustomerRelationResponse(props.productReview));
    const [showProductReview, setShowProductReview] = useState(props.showProductReview);
    const [showProductReviewLoadingMsg, setShowProductReviewLoadingMsg] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [showProcessingRequestMsg, setShowProcessingRequestMsg] = useState(false);

    //console.log('ApproveRejectProductReview props:', productReview);
    useEffect(()=>{
        //console.log('ApproveRejectProductReview first useEffect()');
        setProductReview(props.productReview);
        setShowProductReview(props.showProductReview);

    },[props.productReview]);
    
    useEffect(()=>{
        //console.log('ApproveRejectProductReview second useEffect()');
        //console.log('props.showProductReview:', props.showProductReview);
        setShowProductReview(props.showProductReview);
    },[props.showProductReview]);
    

    function setupCustomerRelationResponse(productReview:Review):string {
        if(productReview.customerRelation !== null){
            return productReview.customerRelation.response;
        } else {
            return '';
        }
    }

    function handleCustomerRelationResponse(e:React.ChangeEvent):void {
        setCustomerRelationResponse((e.target as HTMLInputElement).value);
    }

    function handleHideProductReviewForm():void {
        //console.log('handleHideProductReviewForm');
        handleHideProductReview();
        setCustomerRelationResponse('');
    }

    function handleRejectFormSubmit(e:React.MouseEvent):void {
        setCustomerRelationResponse('');
        removeDatebaseProductReview(productReview!);
        setShowProductReviewLoadingMsg(true);
    }

    function removeDatebaseProductReview(productReview:Review):void {
        //original version with .json file
        //fetch(`http://localhost:9500/delete-product-review/${JSON.stringify(productReview)}`,{
        //test version with mariadb
        fetch(`https://server.kando-proto-3.com/delete-product-review-mariadb/${productReview.reviewId.toString()}`,{
            method:'DELETE',
            headers:{
                'Accept':'application/json'
            }
        })
        .then(res=>res.json())
        .then(({dbResponseMsg}:DbResponse)=>{
            if(dbResponseMsg === 'record deleted'){
                setCustomerRelationResponse('');
                //setTimeout(()=>{
                    /*previous version when you returned all results with maintaining item count or page position
                    getNotApprovedProductReviews()
                    .then(res => res.json())
                    .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                        console.log('dbResponseMsg:', dbResponseMsg);
                        const notApprovedReviews:Review[] = dbResponseRecords as Review[];
                        if(dbResponseMsg === 'no record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(notApprovedReviews);
                        } else if(dbResponseMsg === 'record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(addReviews(notApprovedReviews));
                        }
                    })
                    .catch(err=>console.log('error:', err));
                    */
                //},3000);

                setShowProductReviewLoadingMsg(false);
                setShowProductReview(false);
                handleProductReviewSubmit([productReview], 'delete');

            }   
            
        })
        .catch(err=>console.log('error:', err));
    }

    function handleApproveFormSubmit(e:React.MouseEvent, action:string):void {
        //console.log('test worked');
        const btnSelected:string = (e.target as HTMLButtonElement).id;
        //console.log('btnSelected:', btnSelected);
        if(customerRelationResponse.length > 0 && btnSelected === 'approve-btn'){

            setShowProcessingRequestMsg(true);

            //productReview!.approved = true;
            /*
            (productReview as Review).customerRelation = new CustomerRelation({
                                                                response:customerRelationResponse
                                                                });
            */
            const crr = new CustomerRelation({
                            reviewId:productReview!.reviewId,
                            response:customerRelationResponse
                        });
            
            Promise.all([updateDatebaseProductReviewPromise(productReview!.reviewId),
                        addDatebaseCustomerRelationResponsePromise(crr)])
            .then((dbResponses)=>{
                //console.log('response with r and crr:', dbResponses);
                const [reviewDbResonseMsg, crrDbResponseMsg] = dbResponses;
                if(reviewDbResonseMsg === 'record updated' && crrDbResponseMsg === 'record added'){
                    setShowProductReviewLoadingMsg(true);
                    //make this conditional
                    setCustomerRelationResponse('');

                    getUpdatedApprovedProductReview(productReview!)
                    .then((res)=>res.json())
                    .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                        const approvedReviews:Review[] = dbResponseRecords as Review[];
                        if(dbResponseMsg === 'no record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(approvedReviews, action);
                        } else if(dbResponseMsg === 'record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(addReviews(approvedReviews), action);
                        }
                    })
                    .catch((err)=>{
                        console.log('error getting updated approved product review', err.message);
                        setShowErrorMessage(true);
                    });

                
                    /*previous version when returned all results without maintaining the current list order and amount
                    getNotApprovedProductReviews()
                    .then(res => res.json())
                    .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                        console.log('dbResponseMsg:', dbResponseMsg, dbResponseRecords);
                        const notApprovedReviews:Review[] = dbResponseRecords as Review[];
                        if(dbResponseMsg === 'no record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(notApprovedReviews);
                        } else if(dbResponseMsg === 'record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(addReviews(notApprovedReviews));
                        }

                    })
                    .catch(err=>{
                        console.log('error:', err);
                        setShowErrorMessage(true);
                    });
                    */


                    

                } else {
                    throw new Error('no records adjusted');
                }

            })
            .catch((err)=>{
                console.error('promise.all() error:', err);
                setShowErrorMessage(true);
            });
            //const updatedProductReview = Object.assign({}, productReview);
            //console.log('ur:', updatedProductReview);
            //updateDatebaseProductReview(productReview!.reviewId);
            //setShowProductReviewLoadingMsg(true);

        } else {
            //productReview!.approved = true;
            //const updatedProductReview = Object.assign({}, productReview);
            //updateDatebaseProductReview(productReview!.reviewId);
            Promise.all([updateDatebaseProductReviewPromise(productReview!.reviewId)])
            .then((dbResponse)=>{
                //console.log('response with r:', dbResponse);

                const [reviewDbResonseMsg] = dbResponse;
                if(reviewDbResonseMsg === 'record updated'){
                    setShowProductReviewLoadingMsg(true);
                    //make this conditional
                    setCustomerRelationResponse('');
                    
                    getUpdatedApprovedProductReview(productReview!)
                    .then((res)=>res.json())
                    .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                        const approvedReviews:Review[] = dbResponseRecords as Review[];
                        if(dbResponseMsg === 'no record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(approvedReviews, action);
                        } else if(dbResponseMsg === 'record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(addReviews(approvedReviews), action);
                        }
                    })
                    .catch((err)=>{
                        console.log('error getting updated approved product review', err.message);
                        setShowErrorMessage(true);
                    });

                
                    /*previous version same reason as above
                    getNotApprovedProductReviews()
                    .then(res => res.json())
                    .then(({dbResponseMsg, dbResponseRecords}:DbResponse)=>{
                        console.log('dbResponseMsg:', dbResponseMsg);
                        const notApprovedReviews:Review[] = dbResponseRecords as Review[];
                        if(dbResponseMsg === 'no record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(notApprovedReviews);
                        } else if(dbResponseMsg === 'record found'){
                            setShowProductReviewLoadingMsg(false);
                            setShowProductReview(false);
                            handleProductReviewSubmit(addReviews(notApprovedReviews));
                        }
    
                    })
                    .catch(err=>{
                        console.log('error:', err);
                        setShowErrorMessage(true);
                    });
                    */



                } else {
                    throw new Error('no record added');
                }

            })
            .catch((err)=>{
                console.error('promise.all() error:', err);
                setShowErrorMessage(true);
            });
            //setShowProductReviewLoadingMsg(true);
        }

    }

    function updateDatebaseProductReviewPromise(productReviewId:number):Promise<string> {
        return new Promise((resolve, reject)=>{
                    fetch(`https://server.kando-proto-3.com/update-product-review-mariadb/${productReviewId.toString()}`,{
                        method:'PUT',
                        headers:{
                            'Accept':'application/json'
                        }
                    })
                    .then(res=>res.json())
                    .then(({dbResponseMsg}:DbResponse)=>{
                        return resolve(dbResponseMsg);
                    })
                    .catch((err)=>{
                        console.error('update product review error:', err);
                        return reject(err);
                    })
                });
    }

    function addDatebaseCustomerRelationResponsePromise(customerRelationResponse:CustomerRelation):Promise<string> {

        const params = JSON.stringify(customerRelationResponse);


        return new Promise((resolve, reject)=>{
                    //previous version
                    //fetch(`http://localhost:9500/add-customer-relation-response-mariadb/${JSON.stringify(customerRelationResponse)}`,{
                    fetch(`https://server.kando-proto-3.com/add-customer-relation-response-mariadb`,{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json',
                            'Accept':'application/json'
                        },
                        body:params
                    })
                    .then(res=>res.json())
                    .then(({dbResponseMsg}:DbResponse)=>{
                        return resolve(dbResponseMsg);
                    })
                    .catch((err)=>{
                        console.error('add customer relation response error:', err);
                        return reject(err);
                    })
                });
    }


    function getUpdatedApprovedProductReview(productReview:Review):Promise<Response> {
        return fetch(`https://server.kando-proto-3.com/get-updated-approved-product-review/${productReview.reviewId.toString()}`, {
                    method:'GET',
                    headers:{
                        'Content-Type':'application/json',
                        'Accept':'application/json'
                    }
                });

    }


    function updateDatebaseProductReview(productReviewId:number):void {
        //original version with .json file
        //fetch(`http://localhost:9500/update-product-review/${JSON.stringify(productReview)}`,{
        //test version with mariadb
        fetch(`https://server.kando-proto-3.com/update-product-review-mariadb/${productReviewId.toString()}`,{
            method:'PUT',
            headers:{
                'Accept':'application/json'
            }
        })
        .then(res=>res.json())
        .then(({action})=>{
            if(action === 'updated'){
                setCustomerRelationResponse('');
                getNotApprovedProductReviews()
                .then(res => res.json())
                .then((notApprovedProductReviews:Review[])=>{
                    //console.log('pr test test:', notApprovedProductReviews);
                    setShowProductReviewLoadingMsg(false);
                    setShowProductReview(false);
                    handleProductReviewSubmit(addReviews(notApprovedProductReviews), 'update');

                })
                .catch(err=>{
                    console.log('error:', err);
                    //setShowErrorMessage(true);
                });
            }
           
        })
        .catch(err=>{
            console.log('error:', err);
            //setShowErrorMessage(true);
        });
    }

    function formatFormLabel(key:string):string {
        switch(key){
            case 'productName':
                return 'Product Name';
            case 'rating':
                return 'Rating';
            case 'title':
                return 'Title';
            case 'userName':
                return 'Username';
            case 'submitted':
                return 'Submitted';
            case 'comment':
                return 'Comment';
           default:
                return 'none';
        }
    }


    function setupProductReviewForm(productReview:Review):JSX.Element[]{
        //console.log('productReview:', productReview);
        return Object.entries(productReview)
                    .filter(([key, value])=>{
                        //console.log('key:', key, 'value:', value);
                        switch(key){
                            case 'productName':
                            case 'rating':
                            case 'title':
                            case 'userName':
                            case 'submitted':
                            case 'comment':
                                return true;
                            default:
                                return false;
                        }
                    })
                    .map(([key, value], index)=>{
                        //console.log('key:', key, 'value:', value);
                        if(key === 'submitted'){
                            return <div key={index} className="approve-reject-product-review-form-control-container">
                                        <label htmlFor={key} className="approve-reject-product-review-form-control-label">{formatFormLabel(key)}</label>
                                        <input type="text" className="approve-reject-product-review-form-control"
                                                autoComplete="off" autoCorrect="off"
                                                id={key} value={transformDate(value)} disabled={true}/>
                                    </div> 
                        } else if(key === 'comment'){
                            //console.log('value:', value);
                                return <div key={index} className="approve-reject-product-review-form-control-container">
                                            <label htmlFor={key}>{formatFormLabel(key)}</label>
                                            <textarea style={{resize:'none'}} rows={2} className="approve-reject-product-review-form-control"
                                                    autoComplete="off" autoCorrect="off"
                                                    id={key} value={value} disabled={true}/>
                                        </div> 
                        } else {
                            //console.log('value:', value);
                            return <div key={index} className="approve-reject-product-review-form-control-container">
                                        <label htmlFor={key}>{formatFormLabel(key)}</label>
                                        <input type="text" className="approve-reject-product-review-form-control"
                                                autoComplete="off" autoCorrect="off"
                                                id={key} value={value} disabled={true}/>
                                    </div> 
                        }
                        
                    })
                    .concat(<div key={6} className="approve-reject-product-review-form-control-container">
                                <label htmlFor="response">Response</label>
                                <textarea style={{resize:'none'}} rows={4}
                                            className="approve-reject-product-review-form-control"
                                            autoComplete="off" autoCorrect="off"
                                        id="response" value={customerRelationResponse} onChange={handleCustomerRelationResponse}/>
                            </div>)
                    .concat(setupProductReviewBtns(productReview));
    }

    function setupProductReviewBtns(productReview:Review):JSX.Element{
        if(productReview.approved){
            return <div key={7} className="approve-reject-product-review-form-control-container-btns">
                    <button type="button" id="approve-btn" onClick={(e:React.MouseEvent)=>handleApproveFormSubmit(e,'updated')}
                    className="approve-reject-product-review-approve-btn">Save</button>
                    <button type="button" id="close-btn" onClick={handleCloseFormSubmit}
                    className="approve-reject-product-review-close-btn">Close</button>
                    {
                        (showProcessingRequestMsg)
                        ?
                        <div className="process-request-msg">
                            Processing...
                        </div>
                        :
                        null
                    }
                </div>

        } else {
            return <div key={7} className="approve-reject-product-review-form-control-container-btns">
                        <button type="button" id="approve-btn" onClick={(e:React.MouseEvent)=>handleApproveFormSubmit(e, 'approved')}
                        className="approve-reject-product-review-approve-btn">Approve</button>
                        <button type="button" id="reject-btn" onClick={handleRejectFormSubmit}
                        className="approve-reject-product-review-reject-btn">Reject</button>
                        <button type="button" id="close-btn" onClick={handleCloseFormSubmit}
                        className="approve-reject-product-review-close-btn">Close</button>
                        {
                            (showProcessingRequestMsg)
                            ?
                            <div className="process-request-msg">
                                Processing...
                            </div>
                            :
                            null
                        }
                    </div>
        }
    }


    if(showProductReview){
        //console.log('passed');
        /*
        return <Modal size="lg" show={showProductReview} centered onHide={handleHideProductReviewForm}>
                    <Modal.Header closeButton>
                        <Modal.Title className="approve-reject-product-review-title">
                            Approve/Reject Product Review
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ApproveRejectProductReviewLoadingMsg showProductReviewLoadingMsg={showProductReviewLoadingMsg} />
                        <form>
                            {setupProductReviewForm((productReview as Review))}
                        </form>
                    </Modal.Body>
                </Modal>
                */

        return <div className="approve-reject-product-reviews-form-container">
                    {/*<ApproveRejectProductReviewLoadingMsg showProductReviewLoadingMsg={showProductReviewLoadingMsg} />*/}
                    <h2 className="admin-product-reviews-heading">Product Review</h2>
                    {
                        showErrorMessage
                        ?
                        <div className="aprs-generic-error-msg-wrapper">
                            <GenericErrorMessage/>
                        </div>
                        :
                        <form className="approve-reject-product-reviews-form">
                            {setupProductReviewForm((productReview as Review))}
                        </form>
                    }

                </div>

    
    } else {
        return   <div className="aprs-loaeder-animation-container">
                    {/*<LoaderAnimation/>*/}
                </div>;
    }


}

export default ApproveRejectProductReview;