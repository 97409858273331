import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@gatsbyjs/reach-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ShopProductReviewsDetail.css';
import Review from './review';
import ShopProductIndividualRating from './ShopProductIndividualRating';
import transformDate from './helper-transform-date';
import CustomerRelationResponse from './CustomerRelationResponse';
import CustomerRelation from './customerRelation';

interface Props {
    productReview:Review;
    key?:string | number | undefined;
}

const ShopProductReviewsDetail:React.FunctionComponent<Props> = (props:Props)=>{
    const [productReview, setProductReview] = useState<Review | null>(props.productReview);

    useEffect(()=>{
        setProductReview(props.productReview);
    },[props.productReview])
    /*
    function transformDate(milliseconds:number):string {
        const date:Date = new Date(milliseconds);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    }
    */

    return <Col className="shop-product-reviews-detail-container">
                <ShopProductIndividualRating productReview={productReview as Review}/>
                <Col className="shop-product-reviews-detail-title-date-container">
                    <div className="shop-product-reviews-detail-title">{(productReview as Review).title}</div>
                    <div className="shop-product-reviews-detail-date">{transformDate((productReview as Review).submitted)}</div>
                </Col>
                <Col className="shop-product-reviews-detail-comment-container">
                    <p className="shop-product-reviews-detail-comment">{(productReview as Review).comment}</p>
                </Col>
                <Col className="shop-product-reviews-detail-user-comment-container">
                    <div className="shop-product-reviews-detail-user-comment">
                        by <span className="shop-product-reviews-detail-user-comment-link">
                                {(productReview as Review).userName}
                            </span>
                    </div>
                </Col>
                <CustomerRelationResponse customerRelation={((productReview as Review).customerRelation as CustomerRelation)}/>
            </Col>
}

export default ShopProductReviewsDetail;