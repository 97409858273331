import React, {useEffect, useRef} from 'react';
import { throttle, DebouncedFunc } from 'lodash';
import './ScrollToTopButton.css';

interface Props {
    scrollToTopCallback:()=>void;
    showMiniCart:boolean;
}


const ScrollToTopButton:React.FunctionComponent<Props> = (props:Props)=>{
    const {scrollToTopCallback, showMiniCart} = props;
    const scrollToTopButtonRef = useRef<HTMLButtonElement>(null);
    const isScrolling = useRef<boolean>(false);
    const callbackFunction = useRef<DebouncedFunc<(e:Event)=>void>>(throttle(scrollToTopScrollHandler, 100));

    useEffect(()=>{
        if(showMiniCart){
            scrollToTopButtonRef.current!.style.right = '110px';

        } else {
            scrollToTopButtonRef.current!.style.right = '';
        }

    },[showMiniCart]);


    useEffect(()=>{
        window.addEventListener('scroll', callbackFunction.current);
        return cleanupScrollToTopButtonScrollHandler;
    },[]);

    function cleanupScrollToTopButtonScrollHandler():void {
        window.removeEventListener('scroll', callbackFunction.current);
    }

    function scrollToTopScrollHandler(e:Event):void {
        if(!isScrolling.current){
            isScrolling.current = true;

            window.requestAnimationFrame(rafHandler);

        }
    }

    function rafHandler():void {
        //console.log('pos:', window.scrollY);

        if(window.scrollY >= 600){
            //show button
            scrollToTopButtonRef.current!.classList.add('stt-button-show');
        
        } else {
            //hide button
            scrollToTopButtonRef.current!.classList.remove('stt-button-show');
        }

        isScrolling.current = false;

    }

    function scrollToTopOfPage(e:React.MouseEvent):void {
        e.stopPropagation();
        scrollToTopCallback();
    }


    return <button ref={scrollToTopButtonRef} type="button" className="stt-button" onClick={scrollToTopOfPage}>
                <span className="stt-svg-wrapper">
                    <svg height="512" width="448" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="stt-svg-element">
                        <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>Top
                </span>
            </button>
}

export default ScrollToTopButton;