import React, {useState, useEffect} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import './KeywordList.css';
//import { navigate } from '@gatsbyjs/reach-router';
import { useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
//import {addProductKeywordSearch, AddProductKeywordSearch} from './redux/actions';
import { addProductKeywordSearch, selectProductKeywordSearch } from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {useAppSelector, useAppDispatch} from './redux/hooks';
import ProductKeywordSearch from './redux/productKeywordSearch';

interface Props {
    keywords:string[];
    showKeywords:boolean;
    keywordSearchTerm:string;
    keywordSearchContainerWidth:number;
    //addProductKeywordSearch?:(productKeywordSearch:ProductKeywordSearch)=>AddProductKeywordSearch;
}

/*
const mapDispatchToProps = {
    addProductKeywordSearch
};
*/

const KeywordList:React.FunctionComponent<Props> = (props: Props) => {
    //const { addProductKeywordSearch } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const productKeywordSearchRedux = useAppSelector(selectProductKeywordSearch);
    const { keywords, showKeywords, keywordSearchTerm, keywordSearchContainerWidth } = props;
    const [highlightedKeywords, setHighlightedKeywords] = useState<JSX.Element[]>([]);
    useEffect(()=>{

        //highlight keyword search terms
        let hightlightedKeywordsList =  keywords.map((keyword, index)=>{
                return highlightKeywordSearchLetters(keyword, keywordSearchTerm);
        });


        setHighlightedKeywords(hightlightedKeywordsList);
 
        

    },[keywords]);
    

    //console.log('keywords:', keywords);
    function highlightKeywordSearchLetters(keyword:string, keywordSearchTerm:string):JSX.Element {
        let re = new RegExp(`${keywordSearchTerm}`, 'ig');
        let keywordSearchTermLength:number = keywordSearchTerm.length;
        let beforeKeywordSearchTerm:string = '';
        let actualKeywordSearchTerm:string = '';
        let afterKeywordSearchTerm:string = '';
        let keywordFoundIndex:number = keyword.search(re);

        //console.log('keywordSearchTerm:', keywordSearchTerm, keywordFoundIndex);

        if(keywordFoundIndex !== -1){
            for(let i=0; i < keyword.length; i++){
                if(i < keywordFoundIndex){
                    beforeKeywordSearchTerm += keyword[i];
                } else if(i === keywordFoundIndex){
                    for(let k=0; k < keywordSearchTermLength; k++){
                        actualKeywordSearchTerm += keyword[k + i];
                    }
                } else if(i > keywordFoundIndex + (keywordSearchTermLength - 1)){
                    afterKeywordSearchTerm += keyword[i];
                }
            }

            return <div className="keyword-shape-wrapper">
                        <div className="keyword-shape-container">
                            <span className="keyword-no-list-item-shape-color">{beforeKeywordSearchTerm}</span>
                            <span className="keyword-list-item-shape-color">{actualKeywordSearchTerm}</span>
                            <span className="keyword-no-list-item-shape-color">{afterKeywordSearchTerm}</span>
                        </div>
                    </div>
            
        } else {

            return <div>{keyword}</div>;
        }   
          
        
    }
    

    function encodeKeyword(kw:string){
        let ekw = '';
        //console.log('kw:', kw.split(''));
        kw.split('').forEach((w)=>{
            if(w !== '/'){
                ekw += encodeURIComponent(w);
            } else {
                ekw += w;
            }
        });
        
        return ekw;
    }
    

    function handleKeywordSearch(e:React.MouseEvent, keywordSearch:string):void {
        //console.log('ks:', keywordSearch);

        
        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);
        

        document.body.classList.remove('adj-mobile-search');
        

        if(productKeywordSearchRedux.keywordSearch === keywordSearch.trim()){
            e.preventDefault();
            e.stopPropagation();

            dispatch(addProductKeywordSearch({
                                keywordSearch:keywordSearch.trim(),
                                priceRangeSearch:'relatedProducts',
                                storeDepartmentSearch:'none',
                                departmentCategorySearch:'none',
                                sentFromKeywordList:true,
                                clearKeywordSearch:false,
                                sameKeywordEntered:true
                                    }));

            return;

        } else {
            
            dispatch(addProductKeywordSearch({
                                keywordSearch:keywordSearch.trim(),
                                priceRangeSearch:'relatedProducts',
                                storeDepartmentSearch:'none',
                                departmentCategorySearch:'none',
                                sentFromKeywordList:true,
                                clearKeywordSearch:false,
                                sameKeywordEntered:false
                                    }));

            //previous version
            //navigate(`/search/${keywordSearch}`);
            navigate(`/search/q=${encodeKeyword(keywordSearch.trim())}`);
        }


        

    }
    

    if(showKeywords && keywords.length > 0){
        return (
            <ul className="keyword-list-shape ksm-list-shape">
                {
                    highlightedKeywords.map((highlightedKeyword:JSX.Element, index:number)=>{
                        return <li className="keyword-list-item-shape ksm-list-item-shape" key={index}
                                onClick={(e:React.MouseEvent)=>{
                                    //console.log('called');
                                handleKeywordSearch(e, keywords[index])
                                }}>{highlightedKeyword}</li>
                    })
                }
            </ul>
    
        );
    } else {
        return null;
    }

}

export default KeywordList;
/*
export default connect(
    null,//mapStateToProps,
    mapDispatchToProps
)(KeywordList);
*/