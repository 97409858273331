import React, {useState, useEffect, useRef} from 'react';
import StoreDepartment from './storeDepartment';
import AddStoreDepartment from './AddStoreDepartment';
import AddStoreLocation from './AddStoreLocation';
import StoreLocation from './storeLocation';

interface Props {
    storeLocation:StoreLocation;
    handleCloseForm:(formType:string)=>void;
}

const EditStoreLocation: React.FunctionComponent<Props> = (props:Props) => {
    const {storeLocation, handleCloseForm} = props;

    return <AddStoreLocation showEditStoreLocation={true} storeLocationToEdit={storeLocation} handleCloseForm={handleCloseForm}/>
}

export default EditStoreLocation;