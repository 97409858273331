import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
//import {Link, useNavigate, Router} from '@gatsbyjs/reach-router';
import { useLocation, Link, useNavigate, useLoaderData, Outlet } from 'react-router-dom';
import {addProductKeywordSearch, selectProductKeywordSearch} from './redux/features/productKeywordSearch/productKeywordSearchSlice';
import {selectAdminLogin, showAdminLogin} from './redux/features/adminLogin/adminLoginSlice';
import {useAppDispatch} from './redux/hooks';
import AdminProductReviews2 from './AdminProductReviews2';
import HomePageLayoutBuilder2 from './HomePageLayoutBuilder2';
import AdminStoreLocations2 from './AdminStoreLocations2';
import StoreStructure from './StoreStructure';
import LoaderAnimation from './LoaderAnimation';
import MainNavbar2 from './MainNavbar2';
import ProductSearch from './ProductSearch';
import MenuListMobileNav from './MenuListMobileNav';
import Footer from './Footer';
import './Admin.css';


interface Props {
    //path:string;
    //children:React.ReactElement[];
}

const Admin: React.FunctionComponent<Props> = (props:Props) => {
    //const {children} = props;
    const dispatch = useAppDispatch();
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const [mainHeaderName, setMainHeaderName] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const adminNavItemListRefs = useRef<HTMLUListElement[]>([]);
    const adminNavItemListContainerRefs = useRef<HTMLDivElement[]>([]);
    const adminMoreOverviewBtnContainerRefs = useRef<HTMLDivElement[]>([]);
    
    const [pagePosition, setPagePosition] = useState<number>(getPreviousScrollPosition(sessionStorage.getItem('admin-scroll-position')));

    const portrait = useRef<MediaQueryList[]>([]);

    const isLoggedIn = useLoaderData();

    const [showFooter, setShowFooter] = useState(false);

    //console.log('load9:');

    useEffect(()=>{
        //console.log('comp:');

        //checkLoginStatus()
        //.then((loggedIn)=>{
            //if(!loggedIn){
                //remove comments after updating image paths
            if(!isLoggedIn){
            //if(false){
                navigate('/admin-login/admin');

            } else {

                if((pathname === '/admin' || pathname === '/admin/')
                && (pagePosition !== getPreviousScrollPosition(sessionStorage.getItem('admin-scroll-position')))){
                    const psp = getPreviousScrollPosition(sessionStorage.getItem('admin-scroll-position'));
                    //console.log('psp:', psp);
                    setPagePosition(psp);

                    window.setTimeout(()=>{
                        window.scrollTo({top:psp, left:0, behavior:'smooth'});
                    },0);
                    

                    setShowFooter(true);
    
                } else {
                    
                    if((pathname === '/admin' || pathname === '/admin/')){

                        window.setTimeout(()=>{
                            window.scrollTo({top:pagePosition, left:0, behavior:'smooth'});
                        },0);
                        

                        setShowFooter(true);
                    }
                    
                }
                
                setMainHeaderName(getMainHeaderName(pathname));
                setIsLoading(false);
        
                dispatch(addProductKeywordSearch!({
                    keywordSearch:'',
                    priceRangeSearch:'relatedProducts',
                    storeDepartmentSearch:'none',
                    departmentCategorySearch:'none',
                    sentFromKeywordList:false,
                    clearKeywordSearch:true
                        }));

                dispatch(showAdminLogin({showAdminLogin:true}));
            }

            dispatch(addProductKeywordSearch!({
                keywordSearch:'',
                priceRangeSearch:'relatedProducts',
                storeDepartmentSearch:'none',
                departmentCategorySearch:'none',
                sentFromKeywordList:false,
                clearKeywordSearch:true
                    }));
        //})
        //.catch((err)=>console.log('check login status error:', err.message));

        //console.log('load9:');

    },[pathname]);

    function checkLoginStatus():Promise<boolean> {
        return fetch('https://server.kando-proto-3.com/check-admin-login-status', {
                    method:'GET',
                    mode:'cors',
                    credentials:'include',
                    headers:{
                        'Content-Type':'application/json',
                    }
                })
                .then((res)=>res.json())
                .then(({loginStatus})=>{
                    //console.log('loginStatus:', loginStatus);
                    if(loginStatus === 'loggedIn'){
                        return true;

                    } else {
                        return false;
                    }
                })
                .catch((err)=>{
                    //console.log('check login status error:', err.message);
                    return false
                });
    }
    

    function getMainHeaderName(pathname:string):string {
        //console.log('mhn:', pathname);
        if((pathname === '/admin' || pathname === '/admin/')){
            return 'Admin Tools';

        } else {
            return 'other';
        }
    }


    useEffect(()=>{
        window.addEventListener('resize', adminResizeHandler);

        portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", adminResizeHandler);

        return cleanupAdminResizeHandler;
    },[]);

    function cleanupAdminResizeHandler():void {
        window.addEventListener('resize', adminResizeHandler);
        sessionStorage.removeItem('admin-scroll-position');

        portrait.current[0].removeEventListener("change", adminResizeHandler);
    }

    function adminResizeHandler(e:Event):void {
        adminNavItemListContainerRefs.current.forEach((anilcr, i)=>{        
            if(adminMoreOverviewBtnContainerRefs.current[i].textContent === 'more overview'){
                anilcr.style.height = '';

            } else {
                anilcr.style.height = `${adminNavItemListRefs.current[i].offsetHeight + 48}px`;
            }
            
        });

    }

    function getPreviousScrollPosition(prevScrollPosition:string|null):number {
        if(prevScrollPosition === null){
            return 0;

        } else {
            const psp = parseInt(prevScrollPosition, 10);
            return psp;
        }
    }

    function getAdminMoreOverviewBtnContainerRef(el:HTMLDivElement|null):void {   
        if(el !== null && adminMoreOverviewBtnContainerRefs.current.length === 0){
            adminMoreOverviewBtnContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = adminMoreOverviewBtnContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                adminMoreOverviewBtnContainerRefs.current.push(el);
            }

        }

        //console.log('amobcrs:', adminMoreOverviewBtnContainerRefs.current);

    }


    function getAdminNavItemListContainerRef(el:HTMLDivElement|null):void {   
        if(el !== null && adminNavItemListContainerRefs.current.length === 0){
            adminNavItemListContainerRefs.current.push(el);

        } else if(el !== null) {
            const found = adminNavItemListContainerRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                adminNavItemListContainerRefs.current.push(el);
            }

        }

        //console.log('anilcrs:', adminNavItemListContainerRefs.current);

        adminNavItemListContainerRefs.current.forEach((anilcr, i)=>{
            if(anilcr.offsetHeight < adminNavItemListRefs.current[i].offsetHeight){
                //add more overview btn
                adminMoreOverviewBtnContainerRefs.current[i].classList.remove('admin-more-overview-btn-container-hide');
                adminMoreOverviewBtnContainerRefs.current[i].classList.add('admin-more-overview-btn-container-show');
            }
        });


    }

    function getAdminNavItemListRef(el:HTMLUListElement|null):void {   
        if(el !== null && adminNavItemListRefs.current.length === 0){
            adminNavItemListRefs.current.push(el);

        } else if(el !== null) {
            const found = adminNavItemListRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                adminNavItemListRefs.current.push(el);
            }

        }

        //console.log('anilrs:', adminNavItemListRefs.current);

           
    }


    function moreOverviewBtnHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        
        const btn = e.currentTarget as HTMLButtonElement;
        const btnText = btn.textContent;

        if(btnText === 'more overview'){
            const parent = btn.closest('.anli') as HTMLLIElement;
            
            const anil = parent.querySelector('.admin-nav-item-list') as HTMLUListElement;

            const lc = parent.querySelector('.admin-nav-item-list-container') as HTMLDivElement;
            lc.style.transition = 'height 1s ease-in-out';
            lc.style.height = `${anil.getBoundingClientRect().height + 48}px`;
            btn.textContent = 'less overview';

            //console.log('posy:', window.scrollY, parent.getBoundingClientRect().top);

        } else {
            const parent = btn.closest('.anli') as HTMLLIElement;
            
            const anil = parent.querySelector('.admin-nav-item-list') as HTMLUListElement;

            const lc = parent.querySelector('.admin-nav-item-list-container') as HTMLDivElement;
            lc.style.transition = 'height 1s ease-in-out .3s';
            lc.style.height = '';
            btn.textContent = 'more overview';

            //console.log('posy:', window.scrollY, parent.getBoundingClientRect().top);

            if(parent.getBoundingClientRect().top < 0){
                parent.scrollIntoView();
            }
            
        }
        
    }

    function loadAdminTool(e:React.MouseEvent, adminToolName:string):void {
        e.preventDefault();
        //console.log('posy14:', window.scrollY);

        window.sessionStorage.setItem('admin-scroll-position', `${window.scrollY}`);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        setMainHeaderName(`${adminToolName}`);
        setIsLoading(true);

        setShowFooter(false);
        
        window.setTimeout(()=>{
            root!.removeAttribute('style');
            //setIsLoading(false);
        },100);
        

        switch(adminToolName){
            case 'home':
                navigate('/');
                break;                
            case 'storeStructure':
                navigate('store-structure');
                break;
            case 'homePageLayouts':
                navigate('home-page-layouts');
                break;
            case 'storeLocations':
                navigate('store-locations');
                break;
            case 'productReviews':
                navigate('product-reviews');
                break;
            default:
                throw new Error('No admin tool found.');
        }
        
        

    }

    function setupStoreStructureOverview():JSX.Element {
        return <li className="anli">
                    <h2 className="admin-nav-list-item-heading">Store Structure</h2>
                    <div className="admin-access-btn-container">
                        <button type="button" className="admin-access-btn">
                            <Link to="store-structure" className="admin-nav-list-item-link" onClick={(e:React.MouseEvent)=>loadAdminTool(e, 'storeStructure')}>Access</Link>
                        </button>
                    </div>
                                         
                    <div ref={getAdminNavItemListContainerRef} className="admin-nav-item-list-container">
                        <div ref={getAdminMoreOverviewBtnContainerRef} className="admin-more-overview-btn-container-hide">
                            <button type="button" className="admin-more-overview-btn"
                              onClick={moreOverviewBtnHandler}>more overview</button>
                        </div>
                        <ul ref={getAdminNavItemListRef} className="admin-nav-item-list">
                            <li>
                                <p>Add, edit, search, sort and delete (departments, department categories, product categories, product sub categories, products, search filters and search terms).</p>
                            </li>
                            <li>
                                <p>Add images for all search filter area types listed above.</p>
                            </li>
                            <li>
                                <p>Department and department category names are automatically added to main navbar when created.</p>
                            </li>
                            <li>
                                <p>Product category shortcuts are available for department categories and product categories.</p>
                            </li>
                            <li>
                                <p>Product sub category shortcuts are available for product categories.</p>
                            </li>
                            <li>
                                <p>Set quantity limits for products.</p>
                            </li>
                            <li>
                                <p>Create search filters by type, area, name and add search terms.</p>
                            </li>
                            <li>
                                <p>Create search terms by type, area, name and attach to search filters.</p>
                            </li>
                            <li>
                                <p>Error handling to prevent duplication (names and images).</p>
                            </li>
                            <li>
                                <p>Error handling to highlight missed form fields.</p>
                            </li>
                            <li>
                                <p>Error handling to prevent product quantites from being exceeded.</p>
                            </li>
                            <li>
                                <p>Error handling to prevent order size from being exceeded.</p>
                            </li>
                            <li>
                                <p>View data in tabular form with pagination.</p>
                            </li>

                        </ul>
                    </div>
                            
                        
                    
                </li>
    }


    function setupHomePageLayoutOverview():JSX.Element {
        return  <li className="anli">
                    <h2 className="admin-nav-list-item-heading">Home Page Layouts</h2>
                    <div className="admin-access-btn-container">
                        <button type="button" className="admin-access-btn">
                            <Link to="home-page-layouts" className="admin-nav-list-item-link" onClick={(e:React.MouseEvent)=>loadAdminTool(e, 'homePageLayouts')}>Access</Link>
                        </button>
                    </div>

                    <div ref={getAdminNavItemListContainerRef} className="admin-nav-item-list-container">
                        <div ref={getAdminMoreOverviewBtnContainerRef} className="admin-more-overview-btn-container-hide">
                            <button type="button" className="admin-more-overview-btn"
                                onClick={moreOverviewBtnHandler}>more overview</button>
                        </div>
                        <ul ref={getAdminNavItemListRef} className="admin-nav-item-list">
                            <li>
                                <p>Add, edit, organize and delete all layouts.</p>
                            </li>
                            <li>
                                <p>Four types of layouts (banner, carousel, card-box, 3 image slider and 4 image slider).</p>
                            </li>
                            <li>
                                <p>Image warehouse to select, sort and track images by category (marketing banners, marketing pictures and products) and view selections in a carousel.</p>
                            </li>
                            <li>
                                <p>A layout indicator is present in the upper left corner of each image (checkbox for exising and NEW keyword for just added).</p>
                            </li>
                            <li>
                                <p>Add a heading and caption to layouts.</p>
                            </li>
                            <li>
                                <p>Add an image(s) to each layout.</p>
                            </li>
                            <li>
                                <p>Set the order of each image in a layout.</p>
                            </li>
                            <li>
                                <p>Arrange the layouts on the page.</p>
                            </li>
                            <li>
                                <p>View banner layouts in different dimensions (mobile and desktop).</p>
                            </li>
                            <li>
                                <p>Implement full length banners.</p>
                            </li>
                            <li>
                                <p>Position banner headings (top-center, top-left and left-center)</p>
                            </li>
                            <li>
                                <p>Change banner heading font-size (regular and large).</p>
                            </li>
                            <li>
                                <p>Split banner heading (1 line, 2 line or 3 lines).</p>
                            </li>
                            <li>
                                <p>Switch banner color (heading, caption, background and button).</p>
                            </li>
                            <li>
                                <p>Error handling to reclassify images that were temperarly replaced by new images and the user navigated away from the page before saving.</p>
                            </li>
                            <li>
                                <p>Error handling to keep unrelated layout types from overlapping (banner and carousel).</p>
                            </li>
                            <li>
                                <p>Error handling to keep image count accurate so layouts are displayed properly.</p>
                            </li>
                            <li>
                                <p>Error handling to prevent duplicate images from being images from being added in the image warehouse.</p>
                            </li>
                            <li>
                                <p>Error handling to prevent images counts from being exceeded in the image warehouse.</p>
                            </li>
                        </ul>
                    </div>

                </li>
    }


    function setupStoreLocationOverview():JSX.Element {
        return  <li className="anli">
                    <h2 className="admin-nav-list-item-heading">Store Locations</h2>
                    <div className="admin-access-btn-container">
                        <button type="button" className="admin-access-btn">
                            <Link to="store-locations" className="admin-nav-list-item-link" onClick={(e:React.MouseEvent)=>loadAdminTool(e, 'storeLocations')}>Access</Link>
                        </button>
                    </div>

                    <div  ref={getAdminNavItemListContainerRef} className="admin-nav-item-list-container">
                        <div ref={getAdminMoreOverviewBtnContainerRef} className="admin-more-overview-btn-container-hide">
                            <button type="button" className="admin-more-overview-btn"
                                onClick={moreOverviewBtnHandler}>more overview</button>
                        </div>
                        <ul ref={getAdminNavItemListRef} className="admin-nav-item-list">
                            <li>
                                <p>Add, edit, search and delete store locations.</p>
                            </li>
                            <li>
                                <p>Add store locations (store name, address, phone number, store number, location coordinates).</p>
                            </li>
                            <li>
                                <p>Add store hours (single day and multi day with start and end time).</p>
                            </li>
                            <li>
                                <p>Location error handling to prevent duplication (store name and image)</p>
                            </li>
                            <li>
                                <p>Form error handling to enforce all fields are completed.</p>
                            </li>
                            <li>
                                <p>Hours of operation error handling to prevent overlapping days and times (start and end).</p>
                            </li>
                            <li>
                                <p>Add store image</p>
                            </li>
                            <li>
                                <p>Filter locations by zip code, city or store number</p>
                            </li>
                            <li>
                                <p>Sort by date added</p>
                            </li>
                        </ul>
                    </div>

                </li>
    }


    function setupProductReviewOverview():JSX.Element {
        return  <li className="anli">
                    <h2 className="admin-nav-list-item-heading">Product Reviews</h2>
                    <div className="admin-access-btn-container">
                        <button type="button" className="admin-access-btn">
                            <Link to="product-reviews" className="admin-nav-list-item-link" onClick={(e:React.MouseEvent)=>loadAdminTool(e, 'productReviews')}>Access</Link>
                        </button>
                    </div>
                    
                    <div ref={getAdminNavItemListContainerRef} className="admin-nav-item-list-container">
                        <div ref={getAdminMoreOverviewBtnContainerRef} className="admin-more-overview-btn-container-hide">
                            <button type="button" className="admin-more-overview-btn"
                                onClick={moreOverviewBtnHandler}>more overview</button>
                        </div>
                        <ul ref={getAdminNavItemListRef} className="admin-nav-item-list">
                            <li>
                                <p>Approve, reject, search, sort and respond to customer reviews.</p>
                            </li>
                            <li>
                                <p>Search product reviews by product name, product category, department category and department name.</p>
                            </li>
                            <li>
                                <p>Filter search results by product rating</p>
                            </li>
                            <li>
                                <p>Sort by date submitted</p>
                            </li>
                        </ul>
                    </div>
                    
                        
                    
                </li>
    }

    function setupAdminNavList():JSX.Element {
        return  <div className="admin-nav-list-container">
                    <ul className="admin-nav-list">
                        {setupStoreStructureOverview()}
                        {setupHomePageLayoutOverview()}
                        {setupStoreLocationOverview()}
                        {setupProductReviewOverview()}
                    </ul>
                </div>

    }

    /*
    previous child route setup
                        <Routes>
                        <Route path="product-reviews" element={<AdminProductReviews2/>}/>
                        <Route path="home-page-layouts" element={<HomePageLayoutBuilder2/>}/>
                        <Route path="store-locations" element={<AdminStoreLocations2/>}/>
                        <Route path="/store-structure/*" element={<StoreStructure/>}/>
                    </Routes>
                    */
    
    return <div className="admin-wrapper">
                <ProductSearch/>
                <MainNavbar2/>
                <MenuListMobileNav/>

                {
                    mainHeaderName === 'Admin Tools'
                    ?               
                    <div className="admin-layout-wrapper">
                        <ul className="store-details-breadcrum-container">
                            <li className="abi">
                                <Link to="/" className="store-details-breadcrum-link" 
                                    onClick={(e:React.MouseEvent)=>loadAdminTool(e, 'home')}>Home</Link>
                                <span className="shop-store-department-fa-angle-right">
                                    <svg className="svg-right-angle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.24 14.35a1 1 0 101.52 1.3l6-7a1 1 0 000-1.3l-6-7a1 1 0 10-1.52 1.3L9.684 8 4.24 14.35z" fill="currentColor"></path></svg>
                                </span>
                            </li>
                            <li className="abi store-details-breadcrum-link-store-name">Admin Tools</li>
                        </ul>

                        <h1 className="admin-heading">{mainHeaderName}</h1> 

                        {setupAdminNavList()}
                    </div>
                    :
                    null
                                    
                }
                
                {
                    isLoading
                    ?
                    <div className="admin-loader-animation-container">
                        {/*<LoaderAnimation/>*/}
                    </div>
                    :
                    <Outlet/>
                }
                
                {
                    showFooter
                    ?
                    <Footer/>
                    :
                    null
                }
           </div>
}

export default Admin;