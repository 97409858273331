import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

interface SBAT {
    resetVariables:boolean;
}

const initialState:SBAT = {
    resetVariables:false
};

export const shopByAreaTypeSlice = createSlice({
  name: 'shopByAreaType',
  initialState,
  reducers: {
    resetSBAT: (state, action:PayloadAction<SBAT>) => {
      state.resetVariables = action.payload.resetVariables;
    }
  }
})

// Action creators are generated for each case reducer function
export const { resetSBAT } = shopByAreaTypeSlice.actions;

interface RootState {
    shopByAreaType: SBAT
}

export const selectSBAT = (state:RootState) =>  {
  //const clone = cloneDeep(state.productKeywordSearch);
  return state.shopByAreaType;
  /*
  return  {
    keywordSearch:state.productKeywordSearch.keywordSearch,
    priceRangeSearch:state.productKeywordSearch.priceRangeSearch,
    storeDepartmentSearch:state.productKeywordSearch.storeDepartmentSearch,
    departmentCategorySearch:state.productKeywordSearch.departmentCategorySearch
  };
  */
}


export default shopByAreaTypeSlice.reducer;