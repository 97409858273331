import React, {useState, useEffect} from 'react';
import Product from './product';
import ShoppingCart from './shoppingCart';
import {selectShoppingCart} from './redux/features/shoppingCart/shoppingCartSlice';
import {useAppSelector} from './redux/hooks';
import './ShoppingCartItemLimitErrorMessage.css';

interface Props {
    product:Product;
    shopByAreaType:boolean;
}

const ShoppingCartItemLimitErrorMessage: React.FunctionComponent<Props> = (props:Props) => {
    const shoppingCartRedux = useAppSelector(selectShoppingCart);
    const [product, setProduct] = useState(props.product);
    const [showShoppingCartItemLimitErrorMessage, setShowShoppingCartItemLimitErrorMessage] = useState(false);
    const [shopByAreaType, setShopByAreaType] = useState(props.shopByAreaType);

    useEffect(()=>{

        if(shoppingCartRedux.shoppingCartCategories.length > 0){
            const scc = shoppingCartRedux.shoppingCartCategories.filter((scc)=>{
                return scc.storeDepartmentName === product.storeDepartmentName;
            })[0];

            //console.log('scc2:', scc);
            //the shopping cart category exists
            if(typeof scc !== 'undefined'){

                const scp = scc.shoppingCartProducts.filter((scp)=>{
                    //console.log('scp:', scp.productId, scp.productQtyLimit, scp.productQtyLimitExceeded);
                    return scp.productId === product.productId;
                })[0];

                //console.log('scp2:', scp);

                const fcs = shoppingCartRedux.fullCartSelections.filter((fcs)=>{
                                                                    return fcs.productId === product.productId;
                                                                })[0];
                //add selectedToFullCart property to Product
                //add this to the conditions below to only trigger this message
                //for products that have this proerty set to true.

                if(typeof scp !== 'undefined'){
                    //this product was already added to mini cart
                    if(shoppingCartRedux.itemLimitExceeded 
                        && !shoppingCartRedux.showItemLimtExceededErrorMsg
                        && !scp.productQtyLimitExceeded
                        && typeof fcs !== 'undefined' && fcs.selectedToFullCart){
                        setShowShoppingCartItemLimitErrorMessage(true);
        
                    } else {
                        setShowShoppingCartItemLimitErrorMessage(false);
                    }

                } else {
                    //this product was not added to mini cart
                    if(shoppingCartRedux.itemLimitExceeded && !shoppingCartRedux.showItemLimtExceededErrorMsg
                        && typeof fcs !== 'undefined' && fcs.selectedToFullCart){
                        setShowShoppingCartItemLimitErrorMessage(true);
        
                    } else {
                        setShowShoppingCartItemLimitErrorMessage(false);
                    }
                }

            } else {
                //the shopping cart category does not exist
                const fcs = shoppingCartRedux.fullCartSelections.filter((fcs)=>{
                    return fcs.productId === product.productId;
                })[0];

                if(shoppingCartRedux.itemLimitExceeded && !shoppingCartRedux.showItemLimtExceededErrorMsg
                    && typeof fcs !== 'undefined' && fcs.selectedToFullCart){
                    setShowShoppingCartItemLimitErrorMessage(true);
    
                } else {
                    setShowShoppingCartItemLimitErrorMessage(false);
                }
            }


        }

    },[shoppingCartRedux]);


    function setupShoppingCartItemLimitCSSClass(sbat:boolean):string{
        if(sbat){
            return 'shopping-cart-item-limit-error-msg';
        } else {
            return 'shopping-cart-item-limit-error-msg-individual';
        }
    }

    function setupShoppingCartItemLimitMsg(sbat:boolean):string|JSX.Element{
        if(sbat){
            return `Cart limit reached.`;
        } else {
            return <div className="product-qty-limit-msg-container">
                        <img src={'https://server.kando-proto-3.com/oops.png'} className="product-qty-limit-msg-img"/>
                        <span className="product-qty-limit-msg">Cart limit reached.</span>
                    </div>;
        }
    }


    if(showShoppingCartItemLimitErrorMessage){          
        //return <div className="shopping-cart-item-limit-error-msg">Cart limit reached.</div>;
        return <div className={`${setupShoppingCartItemLimitCSSClass(shopByAreaType)}`}>{setupShoppingCartItemLimitMsg(shopByAreaType)}</div>;

    } else {
        return null;
    }

}

export default ShoppingCartItemLimitErrorMessage;