import StoreLocation from './storeLocation';

export default function addMyStoreName(storeLocation:StoreLocation):string {
    //console.log('storeLocation test 2-26:', storeLocation);
    //if(storeLocation === null){
    if(storeLocation.storeName.length === 0){
        return '';
    } else {
        return storeLocation.storeName;
    }
}