import React, {useState, useEffect, useRef} from 'react';
import ImageSlider3 from './ImageSlider3';
import SliderType from './sliderType';
import BannerBox from './BannerBox';
import BannerBox2 from './BannerBox2';
import Banner from './banner';
import CardBox from './CardBox';
import Card from './card';
import HomePageLayout from './homePageLayout';
import MarketingBanner from './marketingBanner';
import MarketingPicture from './marketingPicture';
import {Link, navigate} from '@gatsbyjs/reach-router';
import Product from './product';
import ShopProductDetail from './ShopProductDetail';
import HomePageLayoutItem from './homePageLayoutItem';
import {getHeading, convertHeadingType} from './helper-home-page-layout';
import ProductDisplay from './ProductDisplay';
import SliderDisplay from './SliderDisplay';
import GenericInfoMessage from './GenericInfoMessage';
import './AddHomePageLayout.css';

interface Props {
    heading:string;
    caption:string;
    headingPositionDesktop?:string;
    headingPositionMobile?:string;
    headingSizeDesktop?:string;
    headingSizeMobile?:string;
    headingTypeDesktop?:string;
    headingTypeMobile?:string;
    fullLengthBannerCheckboxChecked?:boolean;
    headingColor?:string;
    captionColor?:string;
    buttonBackgroundColor?:string;
    buttonColor?:string;
    showBannerBoxFields:boolean;
    homePageLayoutType:string;
    homePageLayoutItems:HomePageLayoutItem[];
    existingHomePageLayoutItems:HomePageLayoutItem[];
    layoutAction:string;
    updateSelectedHeading:(heading:string)=>void;
    updateSelectedCaption:(caption:string)=>void;
    updateSelectedHeadingPositionDesktop?:(headingPositionDesktop:string)=>void;
    updateSelectedHeadingPositionMobile?:(headingPositionMobile:string)=>void;
    updateSelectedHeadingSizeDesktop?:(headingSizeDesktop:string)=>void;
    updateSelectedHeadingSizeMobile?:(headingSizeMobile:string)=>void;
    updateSelectedHeadingTypeDesktop?:(headingTypeDesktop:string)=>void;
    updateSelectedHeadingTypeMobile?:(headingTypeMobile:string)=>void;
    updateFullLengthBannerCheckboxChecked?:(fullLengthBannerCheckboxChecked:boolean)=>void;
    updateSelectedCaptionColor?:(captionColor:string)=>void;
    updateSelectedHeadingColor?:(headingColor:string)=>void;
    updateSelectedBackgroundColor?:(buttonBackgroundColor:string)=>void;
    updateSelectedButtonColor?:(buttonColor:string)=>void;
    updateExistingHomePageLayoutItems?:(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string)=>void;
    setShowHomePageLayoutItemList?: (value: React.SetStateAction<boolean>) => void;
}

const AddHomePageLayout:React.FunctionComponent<Props> = (props:Props)=>{
    const {updateSelectedHeading, updateSelectedCaption, 
           updateSelectedHeadingPositionDesktop, updateSelectedHeadingPositionMobile,
           updateSelectedHeadingSizeDesktop, updateSelectedHeadingSizeMobile,
           updateSelectedHeadingTypeDesktop, updateSelectedHeadingTypeMobile,
           updateFullLengthBannerCheckboxChecked, updateSelectedCaptionColor,
           updateSelectedHeadingColor, updateSelectedBackgroundColor, updateSelectedButtonColor,
           updateExistingHomePageLayoutItems, setShowHomePageLayoutItemList} = props;
    const [heading, setHeading] = useState(props.heading);
    const [caption, setCaption] = useState(props.caption);
    const [headingPositionDesktopOptionSelected, setHeadingPositionDesktopOptionSelected] = useState(props.headingPositionDesktop);
    const [headingPositionOptions, setHeadingPositionOptions] = useState<string[]>(['top-center', 'left-center', 'left-top']);
    const [headingSizeDesktopOptionSelected, setHeadingSizeDesktopOptionSelected] = useState(props.headingSizeDesktop);
    const [headingSizeOptions, setHeadingSizeOptions] = useState<string[]>(['regular', 'large']);
    const [headingTypeDesktopOptionSelected, setHeadingTypeDesktopOptionSelected] = useState(props.headingTypeDesktop);
    const [headingTypeOptions, setHeadingTypeOptions] = useState<string[]>(['no-split', 'split-2'/*, 'split-3'*/]);
    //const [headingPositionDesktop, setHeadingPositionDesktop] = useState(props.headingPositionDesktop);
    //const [headingPositionMobile, setHeadingPositionMobile] = useState(props.headingPositionMobile);
    const [headingPositionMobileOptionSelected, setHeadingPositionMobileOptionSelected] = useState(props.headingPositionMobile);
    const [headingSizeMobileOptionSelected, setHeadingSizeMobileOptionSelected] = useState(props.headingSizeMobile);
    const [headingTypeMobileOptionSelected, setHeadingTypeMobileOptionSelected] = useState(props.headingTypeMobile);
    const [fullLengthBannerCheckboxChecked, setFullLengthBannerCheckboxChecked] = useState(props.fullLengthBannerCheckboxChecked);
    const [showBannerBoxFields, setShowBannerBoxFields] = useState(props.showBannerBoxFields);
    const [homePageLayoutType, setHomePageLayoutType] = useState(props.homePageLayoutType);
    const [homePageLayoutItems, setHomePageLayoutItems] = useState<HomePageLayoutItem[]>(props.homePageLayoutItems);
    const [existingHomePageLayoutItems, setExistingHomePageLayoutItems] = useState<HomePageLayoutItem[]>(props.existingHomePageLayoutItems);
    const [headingColorSelected, setHeadingColorSelected] = useState(/*'#414142'*/props.headingColor);
    const [captionColorSelected, setCaptionColorSelected] = useState(/*'#414142'*/props.captionColor);
    const [buttonBackgroundColorSelected, setButtonBackgroundColorSelected] = useState(/*'#007DB3'*/props.buttonBackgroundColor);
    const [buttonColorSelected, setButtonColorSelected] = useState(/*'#FFFFFF'*/props.buttonColor);
    const [bannerView, setBannerView] = useState('desktop');
    const [layoutAction, setLayoutAction] = useState(props.layoutAction);

    const imageRefs = useRef<HTMLImageElement[]>([]);
    const [imageRefsLoaded, setImageRefsLoaded] = useState(false);

    const [infoMessage, setInfoMessage] = useState('No images have been added.');

    const [showHomePageLayoutHeadingLimitError, setShowHomePageLayoutHeadingLimitError] = useState(false);
    const [showHomePageLayoutCaptionLimitError, setShowHomePageLayoutCaptionLimitError] = useState(false);

    const ahplWrapperRef = useRef<HTMLDivElement[]>([]);


    useEffect(()=>{

        if(props.showBannerBoxFields !== showBannerBoxFields){
            setShowBannerBoxFields(props.showBannerBoxFields);
        }

    },[props.showBannerBoxFields]);

    useEffect(()=>{
        //console.log('hplts:', homePageLayoutType);
        if(props.homePageLayoutType !== homePageLayoutType){
            //console.log('hplts:', props.homePageLayoutType);
            setHomePageLayoutType(props.homePageLayoutType);
        }

    },[props.homePageLayoutType]);


    useEffect(()=>{
        //console.log('hplis:', homePageLayoutItems);
        if(props.homePageLayoutItems !== homePageLayoutItems){
            //console.log('passed');
            setHomePageLayoutItems(props.homePageLayoutItems);
        }

    },[props.homePageLayoutItems]);

    useEffect(()=>{
        //console.log('ehplis:', props.existingHomePageLayoutItems);
        if(props.existingHomePageLayoutItems !== existingHomePageLayoutItems){
            imageRefs.current.length = 0;
            setImageRefsLoaded(false);
            setExistingHomePageLayoutItems(props.existingHomePageLayoutItems);

        }

    },[props.existingHomePageLayoutItems]);


    useEffect(()=>{
        //console.log('loaded 7-12:', imageRefsLoaded, imageRefs.current);
        if(imageRefsLoaded){
            //console.log('loaded 7-12:', imageRefs.current);
            const options = {
                root:null,
                rootMargin:'0px',
                threshold:0.0
            };

            //console.log('imageRefs loaded:', imageRefs.current);
            const io = new IntersectionObserver(ioHandler, options);
            imageRefs.current.forEach((ir)=>{
                //console.log('loaded:', ir);
                                io.observe(ir);
                            });

            return ()=>io.disconnect();
        }


    },[imageRefsLoaded]);

    function ioHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver):void {
        //console.log('entries:', entries);
        entries.forEach((entry)=>{
            if(entry.isIntersecting){
                //console.log('entry');
                const target = entry.target as HTMLImageElement;
                const src = target.dataset.src;
                //console.log('src:', src);
                //console.log('loaded:', src);
                //previous version
                //target.src = `${process.env.PUBLIC_URL}${src}`;
                target.src = `https://server.kando-proto-3.com/${src}`;
                observer.unobserve(target);
            }
        });
    }

    function getImageRef(el:HTMLImageElement|null):void {
        
        if(el !== null){
            //console.log('el:', el);
        }

        if(el !== null && imageRefs.current.length === 0){
            imageRefs.current.push(el);

        } else if(el !== null) {
            const found = imageRefs.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                imageRefs.current.push(el);
            }

        }

        //console.log('iref:', imageRefs.current);
        //console.log('loaded:', imageRefs.current);
        if(imageRefs.current.length > 0){
            setImageRefsLoaded(true);
        }
        
        
    }



    function addHeadingChangeHandler(e:React.ChangeEvent):void {
        const heading = (e.target as HTMLInputElement).value;

        if(heading.length <= 50){
            setHeading(heading);
            updateSelectedHeading(heading);
            setShowHomePageLayoutHeadingLimitError(false);
        } else {
            setShowHomePageLayoutHeadingLimitError(true);
        }
    }

    function addCaptionChangeHandler(e:React.ChangeEvent):void {
        const caption = (e.target as HTMLInputElement).value;

        if(caption.length <= 50){
            setCaption(caption);
            updateSelectedCaption(caption);
            setShowHomePageLayoutCaptionLimitError(false);
        } else {
            setShowHomePageLayoutCaptionLimitError(true);
        }
    }

    function setupHeadingBtnPositionOptions(btnOptions:string[]):JSX.Element[]{
        return btnOptions.map((bo, i)=>{
                            return <option key={i} value={bo}>{bo}</option>
                        });
    }

    function headingPositionDesktopOptionChangeHandler(e:React.ChangeEvent):void {
        const positionSelected = (e.target as HTMLSelectElement).value;
        setHeadingPositionDesktopOptionSelected(positionSelected);
        updateSelectedHeadingPositionDesktop!(positionSelected);
    }

    function headingPositionMobileOptionChangeHandler(e:React.ChangeEvent):void {
        const positionSelected = (e.target as HTMLSelectElement).value;
        setHeadingPositionMobileOptionSelected(positionSelected);
        updateSelectedHeadingPositionMobile!(positionSelected);
    }

    function headingSizeDesktopOptionChangeHandler(e:React.ChangeEvent):void {
        const sizeSelected = (e.target as HTMLSelectElement).value;
        setHeadingSizeDesktopOptionSelected(sizeSelected);
        updateSelectedHeadingSizeDesktop!(sizeSelected);
    }

    function headingSizeMobileOptionChangeHandler(e:React.ChangeEvent):void {
        const sizeSelected = (e.target as HTMLSelectElement).value;
        setHeadingSizeMobileOptionSelected(sizeSelected);
        updateSelectedHeadingSizeMobile!(sizeSelected);
    }

    function setupHeadingSizeOptions(sizeOptions:string[]):JSX.Element[]{
        return sizeOptions.map((so, i)=>{
                            return <option key={i} value={so}>{so}</option>
                        });
    }

    function headingTypeDesktopOptionChangeHandler(e:React.ChangeEvent):void {
        const typeSelected = (e.target as HTMLSelectElement).value;
        setHeadingTypeDesktopOptionSelected(typeSelected);
        updateSelectedHeadingTypeDesktop!(typeSelected);
    }

    function headingTypeMobileOptionChangeHandler(e:React.ChangeEvent):void {
        const typeSelected = (e.target as HTMLSelectElement).value;
        setHeadingTypeMobileOptionSelected(typeSelected);
        updateSelectedHeadingTypeMobile!(typeSelected);
    }

    function fullLengthBannerCheckboxChangeHandler(e:React.ChangeEvent):void {
        const checkboxChecked = (e.target as HTMLInputElement).checked;
        //console.log('cc:', checkboxChecked);
        setFullLengthBannerCheckboxChecked(checkboxChecked);
        updateFullLengthBannerCheckboxChecked!(checkboxChecked);
    }

    function setupHeadingTypeOptions(typeOptions:string[]):JSX.Element[]{
        return typeOptions.map((to, i)=>{
                            return <option key={i} value={to}>{to}</option>
                        });
    }

    function setupSliderCallback(items:object[]):JSX.Element[] {
        return (items as MarketingPicture[]).map((item, i)=>{
                        return <SliderDisplay key={i} item={item} type={homePageLayoutType} layoutAction={layoutAction}
                                    updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}/>
                    });
    }

    function setupProductsCallback(dps:object[]):JSX.Element[] {
        //console.log('dpscallback');
        return (dps as Product[]).map((dp, i)=>{
            //console.log('dp:', dp);
            return <ProductDisplay key={i} product={dp} layoutAction={layoutAction} getImageRef={getImageRef}
                        updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}/>
        });

    }

    function setupHomePageLayoutType(
                                items:HomePageLayoutItem[], 
                                existingItems:HomePageLayoutItem[],
                                hplt:string,
                                bannerView:string,
                                layoutAction:string
                                ):JSX.Element {
                                    //console.log('hplis:', items, 'eitems:', existingItems);

        //combine existing and non existing items
        items = items.concat(existingItems);
        //console.log('items:', items, hplt);
        //console.log('loaded:', items, existingItems);
        

    
        switch(hplt){
            case 'slider3':
                //finish mapping hptypes to slider types
                const st3 = new SliderType({type:'slider', distance:3, heading:'none', caption:'none', scrollEventPadding:16});
                return <ImageSlider3 sliderType={st3} items={items} setupItemsCallback={setupSliderCallback}/>
            case 'slider4':
                const st4 = new SliderType({type:'slider', distance:4, heading:'none', caption:'none', scrollEventPadding:16});
                return <ImageSlider3 sliderType={st4} items={items} setupItemsCallback={setupSliderCallback}/>
            case 'carousel':
                const stc = new SliderType({type:'carousel', heading:'none', caption:'none', scrollEventPadding:16});
                //previous version
                {/*return <ImageSlider3 sliderType={stc} items={items} setupItemsCallback={setupProductsCallback}/>*/}
                return <div className="hpld-carousel-wrapper">
                            <ImageSlider3 sliderType={stc} items={items} setupItemsCallback={setupProductsCallback}/>
                            <div className="hpld-carousel-btn-overlay"></div>
                        </div>
            case 'card-box':
                //console.log('heading:', heading, 'caption:', caption);
                const cbh = heading;
                const cbc = caption;
                const cards = (items as MarketingPicture[]).map((item)=>{
                                    return new Card({...item,
                                                    cardId:item.marketingPictureId,
                                                    homePageLayoutId:item.homePageLayoutId});
                                });
                return <CardBox heading={cbh} caption={cbc} layoutAction={layoutAction} cards={cards} 
                                updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems}/>
            case 'banner-box':
                //console.log('heading:', heading, 'caption:', caption);
                const bbh = heading;
                const bbc = caption;
                const bbhpd = headingPositionDesktopOptionSelected;
                const bbhpm = headingPositionMobileOptionSelected;
                const bbhsd = headingSizeDesktopOptionSelected;
                const bbhsm = headingSizeMobileOptionSelected;
                const bbhtd = headingTypeDesktopOptionSelected;
                const bbhtm = headingTypeMobileOptionSelected;
                const bbfl = fullLengthBannerCheckboxChecked;
                const bbhc = headingColorSelected;
                const bbcc = captionColorSelected;
                const bbbbc = buttonBackgroundColorSelected;
                const bbbc = buttonColorSelected;
                let b = (items as MarketingBanner[])[0];
                //console.log('bv:', bannerView);
                const {heading1, heading2} = getHeading(b, bbhtd!, bbhtm!, bannerView);
                //console.log('h1:', heading1, 'h2:', heading2);
                const banner = new Banner({
                                            bannerId:b.marketingBannerId,
                                            homePageLayoutId:b.homePageLayoutId,
                                            selectedForRemoval:b.selectedForRemoval,
                                            heading1:heading1,
                                            heading2:heading2,
                                            caption:b.caption,
                                            desktopSource:b.desktopSource,
                                            mobileSource:b.mobileSource,
                                            headingPositionDesktop:bbhpd,
                                            headingPositionMobile:bbhpm,
                                            headingSizeDesktop:bbhsd,
                                            headingSizeMobile:bbhsm,
                                            headingTypeDesktop:convertHeadingType(bbhtd!),
                                            headingTypeMobile:convertHeadingType(bbhtm!),
                                            fullLength:bbfl,
                                            headingColor:bbhc,
                                            captionColor:bbcc,
                                            buttonBackgroundColor:bbbbc,
                                            buttonColor:bbbc,
                                            paddingBottomDesktop:b.paddingBottomDesktop,
                                            paddingBottomMobile:b.paddingBottomMobile
                                            });
                return <BannerBox2 banner={banner} layoutAction={layoutAction} editBanner={true} bannerView={bannerView}
                        updateExistingHomePageLayoutItems={updateExistingHomePageLayoutItems} />
            default:
                throw new Error('no page layout type found.');
        }
    }


    /*these three function were add to helper-home-page-layout.ts
    function convertHeadingType(type:string):string{
        switch(type){
            case 'no-split':
                return 'noSplit';
            case 'split-2':
            case 'split-3':
                return 'split';
            default:
                throw new Error('no heading type found.');

        }
    }
    
    function getHeading(mb:MarketingBanner, headingTypeDesktopOptionSelected:string, 
                        headingTypeMobileOptionSelected:string, bannerView:string):{heading1:string, heading2:string} {
                            console.log('htdos:', headingTypeDesktopOptionSelected);
        switch(bannerView){
            case 'desktop':
                switch(headingTypeDesktopOptionSelected){
                    case 'no-split':
                        return headingSplit(mb, 1);
                    case 'split-2':
                        return headingSplit(mb, 2);
                    case 'split-3':
                        return headingSplit(mb, 3);
                    default:
                        throw new Error('no heading type desktop option selected found.');
                }
            case 'mobile':
                switch(headingTypeMobileOptionSelected){
                    case 'no-split':
                        return headingSplit(mb, 1);
                    case 'split-2':
                        return headingSplit(mb, 2);
                    case 'split-3':
                        return headingSplit(mb, 3);
                    default:
                        throw new Error('no heading type mobile option selected found.');
                }
            default:
                throw new Error('no banner view found.');
        }

    }

    function headingSplit(mb:MarketingBanner, numberOfHeadings:number):{heading1:string, heading2:string} {
        if(numberOfHeadings === 1){
            return {heading1:mb.heading, heading2:'none'};

        } else {
            const heading = mb.heading;
            const headingWords = heading.split(' ');
            let h1:string[] = [];
            let h2:string[] = [];

            for(let i=0; i < headingWords.length; i++){
                if(i < numberOfHeadings){
                    h1.push(headingWords[i]);

                } else {
                    h2.push(headingWords[i]);
                }
            }
            
            const heading1 = h1.join(' ');
            const heading2 = h2.join(' ');

            return {heading1, heading2};
        }

    }
    */
    

    function headingColorPickerHandler(e:React.ChangeEvent):void {
        const color = (e.target as HTMLInputElement).value;
        setHeadingColorSelected(color);
        updateSelectedHeadingColor!(color);
    }

    function captionColorPickerHandler(e:React.ChangeEvent):void {
        const color = (e.target as HTMLInputElement).value;
        setCaptionColorSelected(color);
        updateSelectedCaptionColor!(color);
    }

    function buttonBackgroundColorPickerHandler(e:React.ChangeEvent):void {
        const color = (e.target as HTMLInputElement).value;
        setButtonBackgroundColorSelected(color);
        updateSelectedBackgroundColor!(color);
    }

    function buttonColorPickerHandler(e:React.ChangeEvent):void {
        const color = (e.target as HTMLInputElement).value;
        //console.log('color:', color);
        setButtonColorSelected(color);
        updateSelectedButtonColor!(color);
    }

    function btnViewHandler(e:React.MouseEvent):void {
        if(bannerView === 'desktop'){
            setBannerView('mobile');

        } else {
            setBannerView('desktop');
        }
    }

    function showHomePageLayoutItemList(e:React.MouseEvent):void {
        setShowHomePageLayoutItemList!(true);

        let root = document.documentElement;
        root!.setAttribute('style', 'scroll-behavior: auto');
        root.scrollTo(0,0);
        window.setTimeout(()=>{
            root!.removeAttribute('style');
        },100);

    }


    function getAHPLwrapperRef(el:HTMLDivElement|null):void {
        if(el !== null && ahplWrapperRef.current.length > 0){
            ahplWrapperRef.current.length = 0;
        }

        if(el !== null && ahplWrapperRef.current.length === 0){
            ahplWrapperRef.current.push(el);

        } else if(el !== null) {
            const found = ahplWrapperRef.current.find((ir)=>{
                                return ir === el;
                            });

            if(typeof found === 'undefined'){
                ahplWrapperRef.current.push(el);
            }

        }

        //console.log('ahplWrapperRef:', ahplWrapperRef.current);

        //console.log('hplib:', ahplWrapperRef.current[0].closest('.hplb-layout-container'));

        const hplbLayoutContainer = ahplWrapperRef.current[0].closest('.hplb-layout-container') as HTMLDivElement;
        hplbLayoutContainer.style.minHeight = '';

    }


    return <div ref={getAHPLwrapperRef} className="ahpl-wrapper">
                {
                    !showBannerBoxFields
                    ?
                    <form className="ahpl-form-container">
                        <fieldset className="banner-form-fieldset">
                            <label className="banner-form-label" htmlFor="heading-input">Heading</label>
                            <input type="text" className="form-input-control" id="heading-input" value={heading} onChange={addHeadingChangeHandler} required={true}/>
                            {
                                showHomePageLayoutHeadingLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                :
                                null
                            }
                        </fieldset>    

                        <fieldset className="banner-form-fieldset">
                            <label className="banner-form-label" htmlFor="caption-input">Caption</label>
                            <input type="text" className="form-input-control" id="caption-input" value={caption} onChange={addCaptionChangeHandler}/>
                            {
                                showHomePageLayoutCaptionLimitError
                                ?
                                <p className="exceed-character-limit-error">The maximum characters allowed (50)</p>
                                :
                                null
                            }
                        </fieldset>
                    </form>
                    :
                    <form className="ahpl-banner-form-container">
                        <fieldset className="ahpl-form-fieldset">
                            <label className="banner-form-label" htmlFor="hpd-select">Heading Position Desktop</label>
                            <select className="banner-form-select-control" value={headingPositionDesktopOptionSelected}
                                id="hpd-select" onChange={headingPositionDesktopOptionChangeHandler}>
                                {setupHeadingBtnPositionOptions(headingPositionOptions)}
                            </select>
                        </fieldset>

                        <fieldset className="ahpl-form-fieldset">
                            <label className="banner-form-label" htmlFor="hsd-select">Heading Size Desktop</label>
                            <select className="banner-form-select-control" value={headingSizeDesktopOptionSelected}
                                id="hsd-select" onChange={headingSizeDesktopOptionChangeHandler}>
                                {setupHeadingSizeOptions(headingSizeOptions)}
                            </select>
                        </fieldset>

                        <fieldset className="ahpl-form-fieldset">
                            <label className="banner-form-label" htmlFor="htd-select">Heading Type Desktop</label>
                            <select className="banner-form-select-control" value={headingTypeDesktopOptionSelected}
                                id="htd-select" onChange={headingTypeDesktopOptionChangeHandler}>
                                {setupHeadingTypeOptions(headingTypeOptions)}
                            </select>
                        </fieldset>

                        <fieldset className="ahpl-form-fieldset">
                            <label className="banner-form-label" htmlFor="hpm-select">Heading Position Mobile</label>
                            <select className="banner-form-select-control" value={headingPositionMobileOptionSelected}
                                id="hpm-select" onChange={headingPositionMobileOptionChangeHandler}>
                                {setupHeadingBtnPositionOptions(headingPositionOptions)}
                            </select>
                        </fieldset>

                        <fieldset className="ahpl-form-fieldset">
                            <label className="banner-form-label" htmlFor="hsm-select">Heading Size Mobile</label>
                            <select className="banner-form-select-control" value={headingSizeMobileOptionSelected}
                                id="hsm-select" onChange={headingSizeMobileOptionChangeHandler}>
                                {setupHeadingSizeOptions(headingSizeOptions)}
                            </select>
                        </fieldset>

                        <fieldset className="ahpl-form-fieldset">
                            <label className="banner-form-label" htmlFor="htm-select">Heading Type Mobile</label>
                            <select className="banner-form-select-control" value={headingTypeMobileOptionSelected}
                                id="htm-select" onChange={headingTypeMobileOptionChangeHandler}>
                                {setupHeadingTypeOptions(headingTypeOptions)}
                            </select>
                        </fieldset>

                        <div className="ahpl-form-color-container">
                            <fieldset className="ahpl-form-fieldset">
                                <label className="banner-form-label" htmlFor="hcp-input">Heading Color Picker</label>
                                <input type="color" value={headingColorSelected} id="hcp-input" onChange={headingColorPickerHandler} />
                            </fieldset>

                            <fieldset className="ahpl-form-fieldset">
                                <label className="banner-form-label" htmlFor="ccp-input">Caption Color Picker</label>
                                <input type="color" value={captionColorSelected} id="ccp-input" onChange={captionColorPickerHandler} />
                            </fieldset>

                            <fieldset className="ahpl-form-fieldset">
                                <label className="banner-form-label" htmlFor="bbcp-input">Button Background Color Picker</label>
                                <input type="color" value={buttonBackgroundColorSelected}
                                id="bbcp-input" onChange={buttonBackgroundColorPickerHandler} />
                            </fieldset>

                            <fieldset className="ahpl-form-fieldset">
                                <label className="banner-form-label" htmlFor="bcp-input">Button Color Picker</label>
                                <input type="color" value={buttonColorSelected} id="bcp-input" onChange={buttonColorPickerHandler} />
                            </fieldset>
                        </div>

                        <fieldset className="ahpl-form-fieldset-checkbox">
                            <input className="banner-form-checkbox-control" type="checkbox" checked={fullLengthBannerCheckboxChecked}
                                id="full-length-checkbox" 
                                onChange={fullLengthBannerCheckboxChangeHandler}/>
                            <label className="banner-form-label-checkbox" htmlFor="full-length-checkbox">Full Length</label>
                        </fieldset>
                    </form>
                }
                <div className="add-home-page-layout-image-container">
                    {
                        ((homePageLayoutItems.length > 0 || existingHomePageLayoutItems.length> 0) && homePageLayoutType === 'banner-box')
                        ?
                        <div className="ahplic-view-banner-btn-container">
                            <button type="button" className="ahplic-view-banner-btn" 
                                onClick={btnViewHandler}>{bannerView === 'desktop' ? 'mobile view' : 'desktop view'}</button>
                        </div>
                        : null
                    }

                    {
                        (homePageLayoutItems.length > 0 || existingHomePageLayoutItems.length > 0)
                        ? setupHomePageLayoutType(homePageLayoutItems, existingHomePageLayoutItems, homePageLayoutType, bannerView, layoutAction)
                        :
                        <div className="ahpl-no-img-msg-wrapper">
                            <div className="hplib-empty-item-slider-msg-container">
                                <h2 className="hplib-empty-item-slider-msg-heading">No images have been added.</h2>  
                                <p className="hplib-empty-item-slider-msg">Add an image to get started.</p>
                            </div>
                            <div className="hplib-empty-item-slider-img-container">
                                <img className="hplib-empty-item-slider-img" src="https://server.kando-proto-3.com/image-outline-icon.svg"/>
                            </div>
                            <div className="ahpl-add-image-btn-container">
                                <button type="button" className="ahpl-add-image-btn"
                                    onClick={showHomePageLayoutItemList}>Add Image</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
}

export default AddHomePageLayout;


