import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@gatsbyjs/reach-router';
import './ShopProductReviewsDetails.css';
import Review from './review';
import ShopProductGroupRating from './ShopProductGroupRating';
import ShopProductReviewsDetail from './ShopProductReviewsDetail';

interface Props {
    productReviews:Review[] | [];
}

const ShopProductReviewsDetails:React.FunctionComponent<Props> = (props:Props)=>{
    //const productReviews = props.productReviews;
    const [productReviews, setProductReviews] = useState<Review[] | []>(props.productReviews);
    const [selectedValue, setSelectedValue] = useState('Sort by');
    const [quantity, setQuantity] = useState(4);
    const [productReviewsShown, setProductReviewsShown] = useState('minimal');

    //console.log('productReviews test:', productReviews);
    
    
    useEffect(()=>{
        //console.log('prs:', props.productReviews);
        setProductReviews(props.productReviews.sort(sortReviewsDateNewestFirst));
    },[props.productReviews.length > 0])
    
    

    function addShopProductReviewOptions():JSX.Element[]{
        const options:string[] = ['Sort by', 'Date Newest First', 
                                    'Date Oldest First', 'Rating - Low to High', 'Rating - High to Low'];
        return options.map((option, index)=>{
                        if(option === 'Sort by'){
                            return <option key={index} value={option} disabled={true}>{option}</option>;
                        } else {
                            return <option key={index} value={option}>{option}</option>;
                        }
                        
                    });
    }

    function handleSelectedValue(e:React.ChangeEvent):void {
        e.stopPropagation();
        const sortOrder:string = (e.target as HTMLSelectElement).value;
        setSelectedValue(sortOrder);
        setProductReviews(sortProductReviews(productReviews, sortOrder));
    }

    function sortProductReviews(productReviews:Review[], sortOrder:string):Review[]{
        //console.log('sortOrder:', sortOrder);
        switch(sortOrder){
            case 'Date Newest First':
                return productReviews.sort(sortReviewsDateNewestFirst);
            case 'Date Oldest First':
                return productReviews.sort(sortReviewsDateOldestFirst);
            case 'Rating - Low to High':
                return productReviews.sort(sortReviewsRatingLowHigh);
            case 'Rating - High to Low':
                return productReviews.sort(sortReviewsRatingHighLow);
            default:
                throw new Error('no sort order');
        }

    }

    function sortReviewsDateNewestFirst(a:Review, b:Review):number{
        return b.submitted - a.submitted;  
    }

    function sortReviewsDateOldestFirst(a:Review, b:Review):number{
        return a.submitted - b.submitted;
    }

    function sortReviewsRatingLowHigh(a:Review, b:Review):number{
        return a.rating - b.rating;   
    }

    function sortReviewsRatingHighLow(a:Review, b:Review):number{
        return b.rating - a.rating;   
    }

    function setupShopProductReviewsDetails(productReviews:Review[], amountShown:string, quantity:number = 4):JSX.Element[]{
        if(amountShown === 'minimal'){
            let prs:JSX.Element[] = [];
            let prsFiltered:JSX.Element[] = [];
            prs = productReviews.map((productReview, index)=>{
                return <ShopProductReviewsDetail key={index} productReview={productReview} />
            });
    
            return prsFiltered = prs.filter((productReview, index)=>{
                        return index < quantity;
                    });
        } else {
            return productReviews.map((productReview, index)=>{
                        return <ShopProductReviewsDetail key={index} productReview={productReview} />
                    });
        }

    }

    function handleShowAllShopProductReviewsDetails(e:React.MouseEvent):void {
        setProductReviewsShown('all');
    }

    //console.log('productReviews:', productReviews);
    if(productReviews.length > 0){
        return <div className="shop-product-reviews-details-wrapper">
                    <div className="shop-product-reviews-select-btn-container">
                        <div className="shop-product-reviews-group-rating-container">
                            <ShopProductGroupRating 
                                productReviews={productReviews} 
                                showTotalReviewsMsg={true}
                                showTotalReviews={true}
                                starSizeCssStyle={'product-reviews-details-rating-star-shape'}
                                starRatingListGroupContainerCssStyle={'star-rating-list-group-container-product-reviews-details-options'}
                                starRatingListCssStyle={'star-rating-list-product-reviews-details-options'}/>
                        </div>
                        <div className="shop-product-reviews-details-select-container">
                            <form className="shop-product-reviews-details-select-form">
                                <select className="shop-product-reviews-details-select" value={selectedValue} onChange={handleSelectedValue}>
                                    {addShopProductReviewOptions()}
                                </select>
                            </form>
                        </div>
                    </div>
                    {setupShopProductReviewsDetails(productReviews, productReviewsShown)}
                    {(productReviewsShown === 'minimal' && productReviews.length > 4) ? <div className="shop-product-reviews-details-load-more-reviews-btn-container">
                        <button type="button" onClick={handleShowAllShopProductReviewsDetails} className="col-xl-10 col-lg-10 shop-product-reviews-details-load-more-reviews-btn">Load more reviews</button>
                    </div>: null}
                </div>
    
    } else {
        return <div className="shop-product-reviews-details-wrapper">
                    <div className="shop-product-review-ratings-heading">Be the first to write a review!</div>
                    <div>
                        <div className="shop-product-review-ratings-sub-heading">Wondering what to say?</div>
                        <ul>
                            <li>Include usage tips, key benefits, and helpful suggestions.</li>
                            <li>Did you have a good or bad experience?</li>
                            <li>Would you recommend this to friends or family?</li>
                            <li>Be respectful to others.</li>
                        </ul>
                    </div>
                </div>
    }

}

export default ShopProductReviewsDetails;