import React, { useState, useEffect, useRef } from 'react';
import {Link, navigate} from '@gatsbyjs/reach-router';
import HomePageLayoutItem from './homePageLayoutItem';
import MarketingPicture from './marketingPicture';
import './SliderDisplay.css';

interface Props {
    key?:string | number | undefined;
    item:MarketingPicture;
    layoutAction:string;
    type:string;
    updateExistingHomePageLayoutItems?:(existingHomePageLayoutItem:HomePageLayoutItem, homePageLayoutType:string)=>void;
}

const SliderDisplay:React.FunctionComponent<Props> = (props: Props) => {
    const {updateExistingHomePageLayoutItems} = props;
    const [item, setItem] = useState(props.item);
    const [type, setType] = useState(props.type);
    const [layoutAction, setLayoutAction] = useState(props.layoutAction);
    const [itemChecked, setItemChecked] = useState(setupItemCheckbox(props.item));

    //console.log('item:', item, 'props.item:', props.item, props.item === item);

    
    useEffect(()=>{
        if(props.item !== item){
            //console.log('card:');
            setItem(props.item);
        }
    },[props.item]);

    useEffect(()=>{
        if(props.type !== type){
            //console.log('card:');
            setType(props.type);
        }
    },[props.type]);
    
    

    function setupItemCheckbox(item:MarketingPicture):boolean {
        //console.log('item:', item.homePageLayoutId, type);
        return item.homePageLayoutId > 0 ? true : false;
    }

    function itemCheckboxHandler(e:React.ChangeEvent):void {
        //const checkboxChecked = (e.target as HTMLInputElement).checked;
        //setCardChecked(checkboxChecked);
        const p = new MarketingPicture({...item})
        updateExistingHomePageLayoutItems!(p, type);
    }


    return <div style={{position:'relative'}} className="slider-display-wrapper">
                {
                    (layoutAction === 'edit' && item.homePageLayoutId > 0 && !item.selectedForRemoval)
                    ?
                    <form className="hplid-checkbox-form">
                        <input className="hplid-checkbox-control" type="checkbox" id={`${item.marketingPictureId}`} value={item.marketingPictureId} 
                            checked={itemChecked} onChange={itemCheckboxHandler}/>
                    </form>
                    : null
                }
                {
                    (layoutAction === 'edit' && (item.homePageLayoutId === 0 || item.selectedForRemoval))
                    ?
                    <div className="ahpl-new-item-label">New</div>
                    : null
                }
                <div className="ahpl-slider-image-wrapper">
                    <div className="ahpl-slider-image-container">
                        <img className="ahpl-slider-image" alt={item.heading}
                            src={`https://server.kando-proto-3.com/${item.desktopSource}`}/>
                    </div>
                    <div className="ahpl-heading-caption-wrapper">
                        {   
                            item.heading !== 'none' 
                            ? 
                            <div className="ahpl-heading-container">
                                <div className="ahpl-heading">{item.heading}</div>
                            </div> 
                            : null
                        }
                        {
                            item.caption !== 'none' 
                            ? 
                            <div className="ahpl-caption-container">
                                <div className="ahpl-caption">{item.caption}</div>
                            </div> 
                            : null
                        }
                    </div>
                </div>
            </div>

}

export default SliderDisplay;