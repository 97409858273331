import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ShoppingCart from '../../../shoppingCart';
import cloneDeep from 'lodash/cloneDeep';

const initialState: ShoppingCart = {
  shoppingCartCategories:[],
  shoppingCartProductAdded:false,
  shoppingCartProductRemoved:false,
  showShoppingCart:false,
  itemLimitExceeded:false,
  showItemLimtExceededErrorMsg:false,
  showEmptyCartAlertMsg:false,
  fullCartSelections:[],
  itemLimit:50

};

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    addProductsToMiniCart:{
        reducer:(state, action:PayloadAction<ShoppingCart>) => {
            state.shoppingCartCategories = action.payload.shoppingCartCategories;
            state.shoppingCartProductAdded = action.payload.shoppingCartProductAdded;
            state.shoppingCartProductRemoved = action.payload.shoppingCartProductRemoved;
            state.showShoppingCart = action.payload.showShoppingCart;
            state.itemLimitExceeded = action.payload.itemLimitExceeded;
            state.showItemLimtExceededErrorMsg = action.payload.showItemLimtExceededErrorMsg;
            state.showEmptyCartAlertMsg = action.payload.showEmptyCartAlertMsg;
            state.fullCartSelections = action.payload.fullCartSelections;
        },
        prepare:(shoppingCart:ShoppingCart) => {
            const sc = cloneDeep(shoppingCart);
            return {  payload: sc };
        }
    }
  }
});

// Action creators are generated for each case reducer function
export const { addProductsToMiniCart } = shoppingCartSlice.actions;

interface RootState {
  shoppingCart:ShoppingCart;
}

export const selectShoppingCart = (state:RootState) =>  {
  const clone = cloneDeep(state.shoppingCart);
  //console.log('clone:', clone);
  return clone;

}

export default shoppingCartSlice.reducer