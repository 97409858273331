import StoreLocation from './storeLocation';

export function getAnyItemList(currentBtnPosition:number, items:object[], itemsPerList:number, 
                                showItemsPerList:(itemslist:object[])=>void):void {
            const bp = currentBtnPosition + 1;
            const lastItem = bp * itemsPerList;
            const firstItem = lastItem - itemsPerList;
            const itemList = items.slice(firstItem, lastItem);
            //console.log('il:', itemList);
            showItemsPerList(itemList);
                

}

function getItemList(currentBtnPosition:number, items:StoreLocation[], itemsPerList:number, 
                    showItemsPerList:(itemslist:StoreLocation[])=>void):void {
    const bp = currentBtnPosition + 1;
    const lastItem = bp * itemsPerList;
    const firstItem = lastItem - itemsPerList;
    const itemList = items.slice(firstItem, lastItem);
    //console.log('il:', itemList);
    showItemsPerList(itemList);

}

export default getItemList;