import Product from './product';
import ShoppingCartCategory from './shoppingCartCategory';

export default class ShoppingCart {
    shoppingCartCategories:ShoppingCartCategory[];
    //shoppingCartProducts:Product[] | [];
    shoppingCartProductAdded:boolean;
    shoppingCartProductRemoved:boolean;
    //shoppingCartStoreDepartmentNames:string[] | [];
    showShoppingCart:boolean;
    itemLimitExceeded:boolean;
    showItemLimtExceededErrorMsg:boolean;
    showEmptyCartAlertMsg:boolean;
    fullCartSelections:Product[];
    itemLimit:number;

    constructor(options:{
        shoppingCartCategories?:ShoppingCartCategory[],
        //shoppingCartProducts?:Product[],
        shoppingCartProductAdded?:boolean,
        shoppingCartProductRemoved?:boolean,
        showShoppingCart?:boolean,
        itemLimitExceeded?:boolean,
        showItemLimtExceededErrorMsg?:boolean,
        showEmptyCartAlertMsg?:boolean,
        fullCartSelections?:Product[],
        itemLimit?:number
        //shoppingCartStoreDepartmentNames?:string[]
    }={}){
        this.shoppingCartCategories = options.shoppingCartCategories || [];
        //this.shoppingCartProducts = options.shoppingCartProducts || [];
        this.shoppingCartProductAdded = options.shoppingCartProductAdded || false;
        this.shoppingCartProductRemoved = options.shoppingCartProductRemoved || false;
        this.showShoppingCart = options.showShoppingCart || false;
        this.itemLimitExceeded = options.itemLimitExceeded || false;
        this.showItemLimtExceededErrorMsg = options.showItemLimtExceededErrorMsg || false;
        this.showEmptyCartAlertMsg = options.showEmptyCartAlertMsg || false;
        this.fullCartSelections = options.fullCartSelections || [];
        this.itemLimit = options.itemLimit || 5;
        //this.shoppingCartStoreDepartmentNames = options.shoppingCartStoreDepartmentNames || []
    }
}