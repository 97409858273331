import React, {useImperativeHandle, useRef, forwardRef} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AddSearchTerm from './AddSearchTerm';
import './StoreStructureOptionSelect.css';

interface Props {
    defaultValue:string;
    optionNameSelected:string;
    optionNames:string[];
    showErrorMsg:boolean;
    errorMsg:string;
    selectId:string;
    cssStyles?:{minWidth:number, backgroundPosition:number};
    handleOptionNameSelection:(e:React.ChangeEvent)=>void;
}

type Ref = {
    dSelectElementRef:React.RefObject<HTMLSelectElement>;
};

//const StoreDepartmentSelectControl: React.FunctionComponent<Props> = (props:Props) => {
const StoreDepartmentSelectControl = forwardRef<Ref, Props>((props, ref)=>{    
    const {optionNameSelected, optionNames, defaultValue, showErrorMsg, errorMsg, selectId, cssStyles, handleOptionNameSelection} = props;
        //console.log('ons:', optionNameSelected);

    const controlRef = useRef<HTMLSelectElement>(null);

    useImperativeHandle(ref, ()=>({
        
        get dSelectElementRef(){
            return controlRef;
        }
        
        })
    );

    /*
    function addOptionControl(el:HTMLOptionElement):void {
        if(el !== null && controlsRef.current.length === 0){
            controlsRef.current.push(el);
        }

        if(el !== null && controlsRef.current.length > 0){
            const found = controlsRef.current.filter((c)=>{
                                return c === el;
                            });

            if(found.length === 0){
                controlsRef.current.push(el);
            }
        }

        //console.log('pp controls:', ppCheckboxControls.current);
    }
    */


    function setupOptions(optionNames:string[]):JSX.Element[]{
        return optionNames.map((optionNames, i)=>{
                            return <option key={i + 1} value={optionNames}>{optionNames}</option>;                    
                        });
    }

    if(optionNames.length >= 0){
        return  <div className="store-structure-option-select-container">
                    <select ref={controlRef} 
                        style={{minWidth:`${(typeof cssStyles !== 'undefined' ? cssStyles.minWidth : 120 )}px`, backgroundPosition:`calc(100% - ${(typeof cssStyles !== 'undefined' ? cssStyles.backgroundPosition : 14 )}px`}}
                    className={showErrorMsg ? 'show-store-structure-option-select-error-border': 'hide-store-structure-option-select-no-error-border'}
                     value={optionNameSelected} id={selectId} onChange={(e:React.ChangeEvent)=>handleOptionNameSelection(e)}>
                        <option key={0} value={defaultValue}>{defaultValue}</option>
                        {setupOptions(optionNames)}
                    </select>
                    <div className={showErrorMsg ? 'show-store-structure-option-select-error-msg': 'hide-store-structure-option-select-error-msg' }>
                        {errorMsg}
                    </div>
                </div>
    } else {
        return null;
    }

});

export default StoreDepartmentSelectControl;