import React from 'react';
import Product from './product';
import {sortByAtoZ, sortByZtoA, sortByNewest, sortByRatings, 
    sortByPriceHightoLow, sortByPriceLowtoHigh} from './helper-sort-by-option';
//import {addShopLoadDetails, AddShopLoadDetails} from './redux/actions';
//import ShopLoadDetails from './redux/shopLoadDetails';

export default function helperSetOptionValue(
                    selectedOption:string, 
                    products:Product[], 
                    showSetTimeout:boolean,
                    //selectedStoreDepartmentName:string,
                    //selectedDepartmentCategoryName:string,
                    setProducts:(value:React.SetStateAction<Product[] | []>)=>void,
                    setShowRelatedProducts:(value:React.SetStateAction<boolean>)=>void,
                    setProductMatch?: React.Dispatch<React.SetStateAction<boolean>>
                    //addShopLoadDetails?:(shopLoadDetails:ShopLoadDetails)=>AddShopLoadDetails
                    ):void {
    //console.log('hsov called:', selectedOption, 'p:', products);
    switch(selectedOption){
        case 'Best Match':
            setProducts([...products]);
            if(showSetTimeout){
                setTimeout(()=>{
                    if(typeof setProductMatch !== 'undefined'){
                        setProductMatch!(true);
                    }
                    setShowRelatedProducts(true);
                },0);
            }
            return;
        case 'A-Z':
            //add products to this
            setProducts([...(products.sort(sortByAtoZ))]);
            //console.log('result:', [...products.sort(sortByAtoZ)]);
            if(showSetTimeout){
                setTimeout(()=>{
                    if(typeof setProductMatch !== 'undefined'){
                        setProductMatch!(true);
                    }
                    setShowRelatedProducts(true);
                },0);
            }
            return;
        case 'Z-A':
            //add products to this
            setProducts([...products.sort(sortByZtoA)]);
            if(showSetTimeout){
                setTimeout(()=>{
                    if(typeof setProductMatch !== 'undefined'){
                        setProductMatch!(true);
                    }
                    setShowRelatedProducts(true);
                },0);
            }
            return;
        case 'Price High to Low':
            //add products to this
            setProducts([...products.sort(sortByPriceHightoLow)]);
            if(showSetTimeout){
                setTimeout(()=>{
                    if(typeof setProductMatch !== 'undefined'){
                        setProductMatch!(true);
                    }
                    setShowRelatedProducts(true);
                },0);
            }
            return;
        case 'Price Low to High':
            //add products to this
            setProducts([...products.sort(sortByPriceLowtoHigh)]);
            if(showSetTimeout){
                setTimeout(()=>{
                    if(typeof setProductMatch !== 'undefined'){
                        setProductMatch!(true);
                    }
                    setShowRelatedProducts(true);
                },0);
            }
            return;
        case 'Ratings':
            //add products to this
            //console.log('p27:', products.sort(sortByRatings));
            setProducts([...products.sort(sortByRatings)]);
            if(showSetTimeout){
                setTimeout(()=>{
                    if(typeof setProductMatch !== 'undefined'){
                        setProductMatch!(true);
                    }
                    setShowRelatedProducts(true);
                },0);
            }
            return;
        case 'Newest':
            //console.log('newest:', products);
            //add products to this
            setProducts([...products.sort(sortByNewest)]);
            if(showSetTimeout){
                setTimeout(()=>{
                    if(typeof setProductMatch !== 'undefined'){
                        setProductMatch!(true);
                    }
                    setShowRelatedProducts(true);
                },0);
            }
            return;
        default: throw new Error('no sort match found.');
            
    }
}

export function helperSetOptionValueNow(
                        selectedOption:string, 
                        products:Product[], 
                        //showSetTimeout:boolean,
                        //selectedStoreDepartmentName:string,
                        //selectedDepartmentCategoryName:string,
                        //setProducts:(value:React.SetStateAction<Product[] | []>)=>void,
                        //setShowRelatedProducts:(value:React.SetStateAction<boolean>)=>void//,
                        //addShopLoadDetails?:(shopLoadDetails:ShopLoadDetails)=>AddShopLoadDetails
                        ):Product[] {
                    //console.log('hsov called:', selectedOption, 'p:', products);
                    switch(selectedOption){
                        case 'Best Match':
                        //setProducts([...products]);
                        return products;
                    case 'A-Z':
                        //add products to this
                        //setProducts([...(products.sort(sortByAtoZ))]);
                        //console.log('result:', [...products.sort(sortByAtoZ)]);
                        return products.sort(sortByAtoZ);
                    case 'Z-A':
                        //add products to this
                        //setProducts([...products.sort(sortByZtoA)]);
                        return products.sort(sortByZtoA);
                    case 'Price High to Low':
                        //add products to this
                        //setProducts([...products.sort(sortByPriceHightoLow)]);
                        return products.sort(sortByPriceHightoLow);
                    case 'Price Low to High':
                        //add products to this
                        //setProducts([...products.sort(sortByPriceLowtoHigh)]);
                        return products.sort(sortByPriceLowtoHigh);
                    case 'Ratings':
                        //add products to this
                        //setProducts([...products.sort(sortByRatings)]);
                        return products.sort(sortByRatings);
                    case 'Newest':
                        //console.log('newest:', products);
                        //add products to this
                        //setProducts([...products.sort(sortByNewest)]);
                        return products.sort(sortByNewest);
                    default: throw new Error('no sort match found.');

                    }
}