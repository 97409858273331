import React, {useState, useEffect, useRef} from 'react';
//import {Link} from '@gatsbyjs/reach-router';
import { Link, useNavigate } from "react-router-dom";
//import Col from 'react-bootstrap/Col';
import Product from './product';
import Review from './review';
import ShopProductGroupRating from './ShopProductGroupRating';
import getApprovedProductReviews from './helper-get-approved-product-reviews';
import {addReviews} from './helper-add-reviews';
import ShopProductDetailCheckoutBtn from './ShopProductDetailCheckoutBtn';
import ProductQtyLimitErrorMessage from './ProductQtyLimitErrorMessage';
import ShoppingCartItemLimitErrorMessage from './ShoppingCartItemLimitErrorMessage';
import loadNavLink from './loadNavLinkHelper';
import formatLinkString from './helper-format-link-string';
import './ShopProductDetail.css';

interface Props {
    /*
    storeDepartmentName:string;
    departmentCategoryName:string;
    productCategoryName:string;
    */
    product:Product;
    getImageRef:(el:HTMLImageElement|null)=>void;
    key?:string | number | undefined;
    sbtNavLink?:(e:React.MouseEvent, pathname:string)=>void;
    hpCallback?:()=>void;
}

const ShopProductDetail: React.FunctionComponent<Props> = (props:Props) => {
    /*
    const [storeDepartmentName, setStoreDepartmentName] = useState(props.storeDepartmentName);
    const [departmentCategoryName, setDepartmentCategoryName] = useState(props.departmentCategoryName);
    const [productCategoryName, setProductCategoryName] = useState(props.productCategoryName);
    */

    const navigate = useNavigate();

    const {getImageRef, sbtNavLink, hpCallback} = props;
    const [product, setProduct] = useState<Product>(props.product);
    const [productReviews, setProductReviews] = useState<Review[] | []>([]);
    const [controller, setController] = useState<AbortController>(new AbortController());

    
    //console.log('p10:', product, props.product);

    useEffect(()=>{
        setProduct(props.product);
        //console.log('product test:', props.product);
    },[props.product]);

    useEffect(()=>{
        Promise.all([getApprovedProductReviews(product.productId.toString(), controller.signal)])
        .then(([reviews])=>{
            //console.log('reviews test:', reviews);
            if(reviews === null){
                throw new Error('get approved product reviews error');
            }
            const productReviews = addReviews(reviews);
            //console.log('productReviews:', productReviews);
            setProductReviews(productReviews);

        })
        .catch(err=>{
            console.error('error shopProductDetail:', err);
        });

    },[product]);

    useEffect(()=>{
        return function cleanUp(){
            //setShopDepartmentState = false;
            controller.abort();
        }
    },[]);

    function loadRelatedProduct(e:React.MouseEvent):void {
        //console.log('loadRelatedProduct event:', e);
    }

    function handleAddProductToList(product:Product):void {
        //console.log('product added to list:', product.productName);
        //finish this function so product can be added to list
    }

    function addProductPriceLabel(product:Product):JSX.Element/*string*/ {
        if(product.weightBy === 'each' && !product.showSalePrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)} each`;
            return <div className="shop-product-detail-price">
                        ${product.eachPrice.toFixed(2)} each
                    </div>;

        } else if(product.weightBy === 'pound' && !product.showSalePrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)}/lb`;
            return <div className="shop-product-detail-price">
                        ${product.eachPrice.toFixed(2)} lb
                    </div>;

        } else if(product.weightBy === 'pound' && product.showSalePrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)}/lb`;
            return <div className="shop-product-detail-sale-price">
                        ${product.salePrice.toFixed(2)} lb
                    </div>;

        } else {
            return <div className="shop-product-detail-sale-price">
                        ${product.salePrice.toFixed(2)} each
                    </div>;
        }
    }

    function addProductListPriceLabel(product:Product):JSX.Element {
        if(product.weightBy === 'each' && product.showSalePrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)} each`;
            return <div className="shop-product-detail-list-price">
                        ${product.eachPrice.toFixed(2)} each
                    </div>;

        } else if(product.weightBy === 'pound' && product.showSalePrice){
            //original version
            //return `$${product.eachPrice.toFixed(2)}/lb`;
            return <div className="shop-product-detail-list-price">
                        ${product.eachPrice.toFixed(2)}/lb
                    </div>;
        } else {
            return <div className="shop-product-detail-list-price"></div>;
        }
    }

    function addProductUnitOfMeasurePriceLabel(product:Product):JSX.Element | null {
        if(product.weightBy === 'each' && product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureSalePrice.toFixed(2)}/oz)
                    </div>;

        } else if(product.weightBy === 'each' && product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'lb'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureSalePrice.toFixed(2)}/lb)
                    </div>;

        } else if(product.weightBy === 'pound' && product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureSalePrice.toFixed(2)}/oz)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)}/oz)
                    </div>;

        } else if(product.weightBy === 'each' && !product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'lb'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)}/lb)
                    </div>;

        } else if(product.weightBy === 'pound' && !product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'oz'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)}/oz)
                    </div>;
        }  else if(product.weightBy === 'each' && !product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'ct'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)}/ct)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'sheet'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)}/sheet)
                    </div>;
        } else if(product.weightBy === 'each' && !product.showUnitOfMeasureSalePrice && product.unitOfMeasure === 'pack'){
            return <div className="shop-product-detail-unit-of-measure-price">
                        (${product.unitOfMeasureListPrice.toFixed(2)}/pack)
                    </div>;
        } else {
            return null;

        } 
    }

    //<Col className="shop-product-detail-promotion-image-container">
    //</Col>
    /*original version only added one item
    function addProductPromotionImage(product:Product):JSX.Element | null {
        console.log('p:', product);
        if(product.productPromotions.length > 0){
            return <img className="shop-product-detail-promotion-image" 
                    src={`${process.env.PUBLIC_URL}${product.productPromotions[0].productPromotionImage}`} />   
        } else {
            return null;
        }

    }
    */

    //current version list all items
    function addProductPromotionImage(product:Product):JSX.Element | null {
        //console.log('p:', product);
        if(product.productPromotions.length > 0){
            return <ul className="shop-product-detail-promotion-image-list-container">
            {        product.productPromotions.map((productPromotion, index)=>{
                //console.log('pp:', productPromotion);
                        return <li key={index} className="shop-product-detail-promotion-image-container">
                                    <img className="shop-product-detail-promotion-image"
                                        alt={productPromotion.productPromotionName}
                                        src={`https://server.kando-proto-3.com/${productPromotion.productPromotionImage}`}/>
                                </li>
            })}
            </ul>
        } else {
            return null;
        }

    }

    function addProductVerificationImage(product:Product):JSX.Element | null {
        //console.log('pv 3-7:', product);
        //console.log('pvsl:', product.productVerifications.length);
        if(product.productVerifications.length > 0){
            return <ul className="shop-product-detail-verification-image-list-container">
            {        product.productVerifications.map((productVerification, index)=>{
                //console.log('pv:', productVerification);
                        return <li key={index} className="shop-product-detail-verification-image-container">
                                    <img className="shop-product-detail-verification-image"
                                        alt={productVerification.productVerificationName}
                                        src={`https://server.kando-proto-3.com/${productVerification.productVerificationImage}`}/>
                                </li>
            })}
            </ul>

        } else {
            return null;
        }


    }


    //delete img src after testing on line #221
    //src={`${process.env.PUBLIC_URL}${product.productImage}`}
    
    //original version
    //to={`/shop/${product.storeDepartmentName}/${product.departmentCategoryName}/${product.productCategoryName}/${product.productName}`}>
    if(product !== null){
        //console.log('loaded4:', product.productImage, sbtNavLink);

        return  <div className="shop-product-category-product-container">
                    <Link className="shop-product-detail-product-link" 
                        to={`/shop/${encodeURIComponent(formatLinkString(product.storeDepartmentName))}/${encodeURIComponent(formatLinkString(product.departmentCategoryName))}/${encodeURIComponent(formatLinkString(product.productCategoryName))}/${encodeURIComponent(formatLinkString(product.productName))}/did/${product.productId.toString()}`}
                        onClick={(e:React.MouseEvent)=>{
                                e.preventDefault();
                                if(typeof sbtNavLink !== 'undefined'){
                                    sbtNavLink(e, `/shop/${encodeURIComponent(formatLinkString(product.storeDepartmentName))}/${encodeURIComponent(formatLinkString(product.departmentCategoryName))}/${encodeURIComponent(formatLinkString(product.productCategoryName))}/${encodeURIComponent(formatLinkString(product.productName))}/did/${product.productId.toString()}`);
                                } else {

                                    if(typeof hpCallback !== 'undefined'){
                                        hpCallback();
                                    }

                                    loadNavLink(e, `/shop/${encodeURIComponent(formatLinkString(product.storeDepartmentName))}/${encodeURIComponent(formatLinkString(product.departmentCategoryName))}/${encodeURIComponent(formatLinkString(product.productCategoryName))}/${encodeURIComponent(formatLinkString(product.productName))}/did/${product.productId.toString()}`, navigate)    
                                }
                            }}>
                        <div className="shop-product-detail-product-image-container" /*onClick={loadRelatedProduct}*/>
                            {addProductPromotionImage(product)}
                            {addProductVerificationImage(product)}
                            <img ref={getImageRef} data-src={product.productImage} data-alt={product.productName} alt={""} className="shop-product-detail-product-image" /*height="180" width="180"*/ />
                        </div>
                        <div className="shop-product-details-container">
                            <div className="shop-product-detail-rating-container">
                                <ShopProductGroupRating 
                                    productReviews={productReviews}
                                    showTotalReviewsMsg={false}
                                    showTotalReviews={true}
                                    starSizeCssStyle={'product-detail-rating-star-shape'}
                                    starRatingListGroupContainerCssStyle={'star-rating-list-group-container'}
                                    starRatingListCssStyle={'star-rating-list'}/>
                            </div>
                            {/*<div className="shop-product-detail-list-price-container">
                                {addProductListPriceLabel(product)}
                            </div>*/}
                            <div className="shop-product-detail-price-container">
                                {/*${product.eachPrice.toFixed(2)} each*/}
                                {addProductPriceLabel(product)}
                                {addProductUnitOfMeasurePriceLabel(product)}
                            </div>
                            <div className="shop-product-category-product-name">{product.productName}{(product.packingOption !== 'none') ? `, ${product.packingOption}` : null}</div>
                        </div>
                    </Link>
                        <div className="shop-product-detail-btns-container">
                            <ProductQtyLimitErrorMessage product={product} shopByAreaType={true}/>
                            <ShoppingCartItemLimitErrorMessage product={product} shopByAreaType={true}/>
                            <ShopProductDetailCheckoutBtn product={product} showShopProductDetailCheckoutBtn={true}/>
                            <div className="shop-product-detail-add-to-list-btn-container">
                                <button type="button" className="shop-product-detail-add-to-list-btn" 
                                    onClick={(e:React.MouseEvent)=>handleAddProductToList(product)}>Add to list</button>
                            </div>
                        </div>
                </div>
    
    } else {
        return null;
    }
}

export default ShopProductDetail;