export class PromotionProduct {
            public promotionProductId:number;
            public productPromotionId:number;
            public productId:number;
            public productPromotionName:string;
            public productPromotionImage:string;

            constructor(options:{
                promotionProductId?:number,
                productPromotionId?:number,
                productId?:number,
                productPromotionName?:string,
                productPromotionImage?:string
            }={}){
                this.promotionProductId = options.promotionProductId || 0;
                this.productPromotionId = options.productPromotionId || 0;
                this.productId = options.productId || 0;
                this.productPromotionName = options.productPromotionName || '';
                this.productPromotionImage = options.productPromotionImage || '';
            }
        }


class ProductPromotion {
    public productPromotionId:number;
    public productPromotionName:string;
    public productPromotionImage:string;

    constructor(options:{
        productPromotionId?:number,
        productPromotionName?:string,
        productPromotionImage?:string
    }={}){
        this.productPromotionId = options.productPromotionId || 0;
        this.productPromotionName = options.productPromotionName || '';
        this.productPromotionImage = options.productPromotionImage || '';
    }
}

export default ProductPromotion;