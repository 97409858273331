import React, {useState, useEffect, useRef} from 'react';
import {Link} from '@reach/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './EditShopProductReviewUserName.css';

interface Props {
    userName:string;
    updateUserName:(userName:string)=>void;
}

const EditShopProductReviewUserName:React.FunctionComponent<Props> = (props:Props)=>{
    const {updateUserName} = props;
    const [userName, setUserName] = useState(props.userName);
    const [userNameEdit, setUserNameEdit] = useState('');
    const [showUserNameEditForm, setShowUserNameEditForm] = useState(false);
    const [userNameErrorClass, setUserNameErrorClass] = useState('');

    const reviewUserNameControlRef = useRef<HTMLInputElement>(null);

    function handleShowUserNameEditForm(e:React.MouseEvent):void {
        setUserNameEdit('');
        setShowUserNameEditForm(true);
    }

    function handleUserNameChange(e:React.ChangeEvent):void {
        setUserNameEdit((e.target as HTMLInputElement).value);
    }

    function handleSubmitUserName(e:React.FormEvent):void {
        e.preventDefault();
        if(userNameEdit === ''){
            reviewUserNameControlRef.current!.placeholder = 'Please add a username.';
            setUserNameErrorClass('edit-shop-product-review-username-form-control-error');
            return;

        } else {
            updateUserName(userNameEdit);
            setUserName(userNameEdit);
            setUserNameErrorClass('');
            setShowUserNameEditForm(false);
        }
    }

    function cancelUserNameChangeHandler(e:React.MouseEvent):void {
        setUserNameErrorClass('');
        setShowUserNameEditForm(false);
    }

    function userNameFocusHandler(e:React.FocusEvent):void {
        if(userNameErrorClass.length === 0){
            reviewUserNameControlRef.current!.classList.add('edit-shop-product-review-username-form-control-focus');
        }
        reviewUserNameControlRef.current!.placeholder = '';
    }

    function userNameBlurHandler(e:React.FocusEvent):void {
        if(userNameEdit.length === 0 && userNameErrorClass === 'edit-shop-product-review-username-form-control-error'){
            reviewUserNameControlRef.current!.placeholder = 'Please add a username.';
        }

        if(userNameEdit.length === 0 && userNameErrorClass.length === 0){
            reviewUserNameControlRef.current!.classList.remove('edit-shop-product-review-username-form-control-focus');
            reviewUserNameControlRef.current!.placeholder = 'Type your nickname here.';
        }
    }

    if(showUserNameEditForm){
        return <Col xl={12} lg={12} className="edit-shop-product-review-username-container">
                    <form onSubmit={handleSubmitUserName}>
                        <input ref={reviewUserNameControlRef} type="text" className={`edit-shop-product-review-username-form-control ${userNameErrorClass}`}
                         placeholder="Type your nickname here." onChange={handleUserNameChange}
                         onFocus={userNameFocusHandler} onBlur={userNameBlurHandler} value={userNameEdit}/>
                        <button type="submit" className="edit-shop-product-review-username-form-control-save-btn">Save</button>
                        <button type="button" className="edit-shop-product-review-username-form-control-save-btn"
                            onClick={cancelUserNameChangeHandler}>Cancel</button>
                    </form>
                </Col>    
    } else {
        return <Col xl={12} lg={12} className="edit-shop-product-review-username-container">
                    <span className="edit-shop-product-review-username">{userName}</span>
                    <div className="edit-shop-product-review-username-btn" onClick={handleShowUserNameEditForm}>edit nickname</div>
                </Col>
    }


}

export default EditShopProductReviewUserName;