import React, {useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DepartmentCategory from './departmentCategory';

interface Props {
    departmentCategory:DepartmentCategory;
}

const HighlightDepartmentCategoryText:React.FunctionComponent<Props> = (props:Props)=>{
    const {departmentCategory} = props;
    //console.log('dc7:', departmentCategory);
    //console.log('highlightText7:', departmentCategory.highlightText);

    if(departmentCategory.highlightText){
        return <div className="highlight-department-category-text">{departmentCategory.departmentCategoryName}</div>;

    } else {
        return <div className="no-highlight-department-category-text">{departmentCategory.departmentCategoryName}</div>;

    }

}

export default HighlightDepartmentCategoryText;