import Review from './review';

export default async function getApprovedProductReviews(productId:string, signal:AbortSignal):Promise<Review[]|null> {
    //original version
    //return fetch(`http://localhost:9500/approved-product-reviews/${productCategoryName}/${productName}`, {
    //test version
    try {
        const resp = await fetch(`https://server.kando-proto-3.com/approved-product-reviews-mariadb/${productId}`, {
                                method:'GET',
                                headers:{
                                    'Accept':'application/json'
                                }//,
                                //signal:signal
                            });

        const reviews = resp.json();
        return reviews;

    } catch(err){
        //console.log('approve product reviews fetch error:', err);
        return null;
    }
    

}
