import React from 'react';

export interface SearchFilterFormErrorMessage {
            //searchTermNameSelected?:string;
            searchFilterNameSelected:string;
            deptNameSelected:string;
            deptCategoryNameSelected:string;
            productCategoryNameSelected:string;
            isSearchFilterAreaNameSelected:(
                deptNameSelected:string,
                deptCategoryNameSelected:string,
                productCategoryNameSelected:string
                            )=>boolean;
            //setStoreStructureAlertMsg:(value: React.SetStateAction<string>)=>void;
            //setShowStoreStructureAlert:(value: React.SetStateAction<boolean>)=>void;
            setShowSearchFilterAreaNameErrorMsg:(value: React.SetStateAction<boolean>)=>void;
            setSearchFilterNameErrorMsg?:(value: React.SetStateAction<string>)=>void;
            setShowSearchFilterNameErrorMsg?:(value: React.SetStateAction<boolean>)=>void;
            setShowSearchFilterNameLimitError?:(value: React.SetStateAction<boolean>)=>void;
            //setSearchTermNameErrorMsg?:(value: React.SetStateAction<string>)=>void;
}

function showSearchFilterFormErrorMsgs(options:SearchFilterFormErrorMessage):boolean {
    const {searchFilterNameSelected, deptNameSelected,deptCategoryNameSelected, 
            productCategoryNameSelected, isSearchFilterAreaNameSelected, setShowSearchFilterAreaNameErrorMsg, 
            setSearchFilterNameErrorMsg, setShowSearchFilterNameErrorMsg, setShowSearchFilterNameLimitError} = options;
            
    const searchFilterAreaNameSelected = isSearchFilterAreaNameSelected(
                                                            deptNameSelected,
                                                            deptCategoryNameSelected,
                                                            productCategoryNameSelected
                                                                        );   
        //console.log('sfns:', searchFilterNameSelected);
        //console.log('sfans', searchFilterAreaNameSelected);
        //initialy reset all error filters
        /*
        setShowStoreStructureAlert(false);
        setShowSearchFilterAreaNameErrorMsg(false);
        setSearchFilterNameErrorMsg!('');
        setSearchTermNameErrorMsg!('');
        */
        //1
    if(searchFilterNameSelected.length === 0 && !searchFilterAreaNameSelected){
        //console.log('passed1');
        //setStoreStructureAlertMsg('select a search filter area and add search filter name');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(true);
        setShowSearchFilterNameErrorMsg!(true);
        setSearchFilterNameErrorMsg!('Please add a search filter name');

        /*
        setTimeout(()=>{
            setStoreStructureAlertMsg('no msg');
            setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //2
    } else if(searchFilterNameSelected.length > 0 && !searchFilterAreaNameSelected){
                    //console.log('passed2');
        //setStoreStructureAlertMsg('select search filter area');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(true);
        setShowSearchFilterNameErrorMsg!(false);
        setShowSearchFilterNameLimitError!(false);
        //setSearchFilterNameErrorMsg!('');       

        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;
        //3
    } else if(searchFilterNameSelected.length === 0 && searchFilterAreaNameSelected){
        //console.log('passed3');
        //setStoreStructureAlertMsg('add search filter name');
        //setShowStoreStructureAlert(true);
        setShowSearchFilterAreaNameErrorMsg(false);
        setShowSearchFilterNameErrorMsg!(true);
        setSearchFilterNameErrorMsg!('Please add a search filter name');       

        /*
        setTimeout(()=>{
        setStoreStructureAlertMsg('no msg');
        setShowStoreStructureAlert(false);
        },2000);
        */

        return true;

    } else {
        setShowSearchFilterNameLimitError!(false);
        return false;
    }
}

export default showSearchFilterFormErrorMsgs;